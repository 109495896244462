import { useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";
import { sessionHeaderConst } from "pages/auth/sessions/data";

import { getSessionList } from "redux/session/session.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/session/session.slice";
import { sessionTypeEnum, sessionStatusEnum } from "redux/session/session.const";
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const SearchSessions = () => {
    const { sessionList, filterProps } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()

    const [searchText, setSearchText] = useState("")

    const header = Object.values(sessionHeaderConst)?.filter((header) => header?.value !== sessionHeaderConst.ACTIVITY.value)?.map((head) => head?.label)

    const body = sessionList?.data?.results?.map((session) => ({
        [sessionHeaderConst?.SESSION_ID?.label]: session?.id,
        [sessionHeaderConst?.SESSION_NAME?.label]: session?.session?.name,
        [sessionHeaderConst?.SESSION_TYPE?.label]: sessionTypeEnum[session?.session?.type?.toUpperCase()]?.label,
        [sessionHeaderConst?.STATUS?.label]: sessionStatusEnum[session?.status?.toUpperCase()]?.label,
        [sessionHeaderConst?.DATE?.label]: dayjs(session?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A')
    }))
    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-sessions",
            sheet: "session",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const handleFilterSearch = (event) => {
        setSearchText(event?.target?.value)
    }

    const onHandleSearch = () => {
        dispatcher(getSessionList({ search: searchText, page: filterProps?.page || 1, records: filterProps?.records || 25 }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-sessions"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"search here..."}
                        value={searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={onHandleSearch}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchSessions;