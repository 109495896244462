import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { GiCheckMark } from "react-icons/gi";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { MdAdd } from "react-icons/md";

import AccordionItem from 'components/accordion/AccordionItem';

import SaveAndCancelBtn from "pages/auth/editCompanyOnboarding/components/saveAndCancelBtn/SaveAndCancelBtn";

import {
    getFAQListByCompanyId,
    createFAQ,
    updateFAQByFAQId,
    deleteFAQByFAQId
} from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
    setClearFAQList,
    setClearFAQPayload,
    setFAQPayload
} from "redux/onboarding/onboarding.slice";

const EditCompanyFAQ = () => {
    const { FAQList, FAQPayload } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { companyId } = useParams()

    const [isAddNewFAQBoxEnabled, setIsAddNewFAQBoxEnabled] = useState(false)
    const [isFAQSelected, setIsFAQSelected] = useState(false)
    const [selectedFAQ, setSelectedFAQ] = useState(null)

    useEffect(() => {
        if (companyId) {
            dispatcher(getFAQListByCompanyId(Number(companyId)))
        }

        return () => {
            dispatcher(setClearFAQList())
        }
    }, [companyId])

    const handleAddFAQ = () => {
        if (selectedFAQ) return;
        if (FAQList?.FAQList?.length >= 5) {
            alert("This is a pro feature! buy our plan to unlock it")
            return;
        }
        dispatcher(setClearFAQPayload())
        setIsAddNewFAQBoxEnabled(!isAddNewFAQBoxEnabled)
    }

    const handleEditFAQ = () => {
        if (!selectedFAQ) return;
        dispatcher(setFAQPayload(selectedFAQ))
        setIsAddNewFAQBoxEnabled(true)
    }

    const handleDeleteFAQ = () => {
        if (!selectedFAQ) return;
        dispatcher(deleteFAQByFAQId(selectedFAQ))
        setIsFAQSelected(false)
        setSelectedFAQ(null)
    }

    const handleEditDeleteFAQ = (myFAQ) => {
        if (isFAQSelected && selectedFAQ === myFAQ) {
            setSelectedFAQ(null)
            setIsFAQSelected(false)
        } else {
            setSelectedFAQ(myFAQ)
            setIsFAQSelected(!isFAQSelected)
        }
    }

    const onSubmitFAQ = () => {
        if (FAQPayload?.FAQPayload?.length === 0 || FAQPayload?.FAQPayload?.answer?.length === 0) {
            alert("FAQ cannot be empty!")
            return;
        }
        if (FAQList?.FAQList?.length > 5) {
            alert("This is a pro feature! buy our plan to unlock it")
            return;
        }
        if (FAQPayload?.FAQPayload?.FAQId) {
            dispatcher(updateFAQByFAQId(FAQPayload?.FAQPayload))
        }
        if (!FAQPayload?.FAQPayload?.FAQId) {
            dispatcher(createFAQ({
                ...FAQPayload?.FAQPayload,
                FAQId: (Math.floor(Math.random() * 10) + 1)
            }))
        }
        setIsAddNewFAQBoxEnabled(false)
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"FAQ"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"bg-white p-5 space-y-3"}>
                    <div className={"flex justify-start items-center gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"Frequently Asked Questions?"}
                        </span>
                        <div className={"flex items-center justify-center gap-12"}>
                            <div className={"flex justify-center items-center gap-5"}>
                                <div
                                    className={cn(
                                        "flex item-center justify-center px-2 py-0.5 rounded-md",
                                        "font-buttons font-normal text-base",
                                        !selectedFAQ && "hover:bg-primary-dark bg-primary-main text-text-50 cursor-pointer",
                                        selectedFAQ && "bg-primary-light text-text-400"
                                    )}
                                    onClick={handleAddFAQ}
                                >
                                    <MdAdd className={"text-xl"} />
                                    <span className={"whitespace-nowrap"}>
                                        {"Add FAQs"}
                                    </span>
                                </div>
                                <div
                                    className={cn(
                                        "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                        "font-buttons font-normal text-base text-text-800 bg-background-darkLight",
                                        "hover:bg-background-medium hover:text-text-900"
                                    )}
                                    onClick={() => {
                                        setIsAddNewFAQBoxEnabled(false)
                                        setSelectedFAQ(null)
                                    }}
                                >
                                    <span className={""}>
                                        {"Cancel"}
                                    </span>
                                </div>
                            </div>
                            <div className={"flex items-center justify-center gap-1.5"}>
                                <FiEdit className={cn(
                                    "text-xl",
                                    selectedFAQ && " text-text-700 hover:text-text-900 cursor-pointer",
                                    !selectedFAQ && "text-text-300"
                                )}
                                    onClick={handleEditFAQ}
                                />
                                <MdOutlineDeleteOutline className={cn(
                                    "text-2xl",
                                    selectedFAQ && " text-red-500 hover:text-red-700 cursor-pointer",
                                    !selectedFAQ && "text-red-300"
                                )}
                                    onClick={handleDeleteFAQ}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"grid grid-cols-12 py-5"}>
                        <div className={"col-span-12 md:(col-start-2 col-span-10) lg:(col-start-1 col-span-12)"}>
                            <div className={"mx-10 flex flex-col items-center gap-3"}>
                                {FAQList?.FAQList?.length > 0 &&
                                    FAQList?.FAQList?.map((itemFAQ, index) => (
                                        <div className={"w-full flex items-center justify-start gap-3"}>
                                            <div
                                                className={"relative w-4 h-4 border-2 border-text-500 hover:border-text-700 rounded cursor-pointer"}
                                                onClick={() => handleEditDeleteFAQ(itemFAQ)}
                                            >
                                                {selectedFAQ === itemFAQ &&
                                                    <div className={"absolute -top-0.5 -right-0.5 flex items-center justify-center"}>
                                                        <GiCheckMark className={"text-sm text-primary-dark"} />
                                                    </div>
                                                }
                                            </div>
                                            <AccordionItem
                                                key={index}
                                                index={index}
                                                itemFAQ={itemFAQ}
                                            />
                                        </div>
                                    ))}
                                {FAQList?.FAQList?.length === 0 &&
                                    <div className={"font-bodyPri font-normal text-text-800 text-ms tracking-wide"}>
                                        {"No FAQ's Found!"}
                                    </div>
                                }
                            </div>
                            {isAddNewFAQBoxEnabled &&
                                <div className={"w-full flex flex-col items-start justify-center gap-5 py-5 px-10"}>
                                    <input
                                        type={"text"}
                                        placeholder={"Enter Question"}
                                        value={FAQPayload?.FAQPayload?.question}
                                        className={cn(
                                            "w-full px-2 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                            "font-bodyPri font-normal text-text-800 text-base",
                                            "placeholder:text-text-500 placeholder:text-sm"
                                        )}
                                        onChange={(event) => dispatcher(setFAQPayload({
                                            ...FAQPayload?.FAQPayload,
                                            question: event.target.value
                                        }))}
                                    />
                                    <textarea
                                        type={"text"}
                                        placeholder={"Enter Answer"}
                                        rows={4}
                                        value={FAQPayload?.FAQPayload?.answer}
                                        className={cn(
                                            "w-full px-3 py-1.5 resize-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                            "font-bodyPri font-normal text-text-800 text-base",
                                            "placeholder:text-text-500 placeholder:text-sm"
                                        )}
                                        onChange={(event) => dispatcher(setFAQPayload({
                                            ...FAQPayload?.FAQPayload,
                                            answer: event.target.value
                                        }))}
                                    />
                                    <SaveAndCancelBtn
                                        onCancel={() => setIsAddNewFAQBoxEnabled(false)}
                                        onSave={() => onSubmitFAQ()}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCompanyFAQ