import { lmsArticleFilterProps } from "./lmsArticle.const";

export const LMS_ARTICLE_INITIAL_STATE= {
    lmsArticleList: {
        isLoading: false,
        data: null,
        message: null
    },
    deleteLmsArticleDetail: {
        isLoading: false,
        message: null
    },

    filterProps: lmsArticleFilterProps
}