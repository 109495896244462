import { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { FaEye, FaLayerGroup } from "react-icons/fa";

import PageHeader from 'components/pageHeader/PageHeader';
import ToolTipView from 'components/toolTip';
import SideOverPanel from "components/common-components/SideOverPanel";
import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { pageHeading, bookingHeaderConst, subRowHeader, sortBookingHighToLow, sortBookingLowToHigh, searchParamsInfo } from "pages/auth/bookings/data";
import MyBookingSearch from "pages/auth/bookings/components/BookingSearch";
import BookingSideOverContent from "pages/auth/bookings/detail/index";

import { getBookingList } from 'redux/booking/booking.request';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { dataEntriesConst } from 'redux/local/local.const';
import { resetBookingList, setClearFilterProps } from "redux/booking/booking.slice";
import { bookingStatusEnum, bookingOfferingTypeEnum } from 'redux/booking/booking.const';
import { courseType } from "redux/course/course.const";

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";

const UserContainer = ({ bookingDetail }) => {
  return (
    <div className={"flex items-center justify-start gap-0.5"}>
      <Link to={`${pagesInfo?.VIEW_USER?.pagePath}/${bookingDetail?.user?.id}`}
        className={"text-primary-main hover:text-primary-dark hover:underline"}
      >
        {bookingDetail?.user?.id}
      </Link>
      <div>
        {". " + bookingDetail?.user?.firstName + " " + bookingDetail?.user?.lastName.charAt(0)}
      </div>
    </div>
  )
}

const OfferingTypeContainer = ({ userOffering }) => {

  const redirectUrl = (userOffering?.offeringType === bookingOfferingTypeEnum?.APPOINTMENT?.value)
    ? `${pagesInfo?.VIEW_USER?.pagePath}/${userOffering?.user?.id}/user-appointment`
    : `${pagesInfo?.VIEW_COURSE?.pagePath}/${userOffering?.course?.id}`

  return (
    <div className={"flex items-center justify-start gap-0.5"}>
      <Link to={redirectUrl}
        className={"text-primary-main hover:text-primary-dark hover:underline"}
      >
        {(userOffering?.offeringType === bookingOfferingTypeEnum?.APPOINTMENT?.value)
          ? userOffering?.appointment?.id
          : userOffering?.course?.id
        }
      </Link>
      <div className={""}>
        {(userOffering?.offeringType === bookingOfferingTypeEnum?.APPOINTMENT?.value)
          ? `. ${bookingOfferingTypeEnum[userOffering?.offeringType?.toUpperCase()]?.label} (${courseType?.ONE_ON_ONE?.label})`
          : `. ${bookingOfferingTypeEnum[userOffering?.offeringType?.toUpperCase()]?.label} (${courseType[userOffering?.course?.type?.toUpperCase()]?.label})`
        }
      </div>
    </div>
  )
}

const BookingsPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { bookingList } = useAppState((state) => state.booking)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()

  const [sideOverPanel, setSideOverPanel] = useState(false)
  const [showEntries, setShowEntries] = useState(dataEntriesConst?.TWENTY_FIVE?.value)
  const [activeSortHeader, setActiveSortHeader] = useState({
    activeSortKey: null,
    sortType: null
  })

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.BOOKINGS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {

    dispatcher(getBookingList({ page: searchQueryParams.get(searchParamsInfo?.page?.key) || "1", records: showEntries }))

    return () => {
      dispatcher(resetBookingList())
      dispatcher(setClearFilterProps())
    }
  }, [location.search, showEntries])

  useEffect(() => {
    if (!!searchQueryParams.get(searchParamsInfo.bookingId.key)) {
      setSideOverPanel(true)
    }
  }, [searchQueryParams.get(searchParamsInfo.bookingId.key)])

  const sortHeader = (activeSort, upDownArrow) => {
    setActiveSortHeader({
      activeSortKey: activeSort,
      sortType: upDownArrow
    })
  }

  const sortBookingList = (booking1, booking2) => {
    if (activeSortHeader?.sortType?.upArrow) {
      return sortBookingLowToHigh(activeSortHeader, booking1, booking2)
    }
    if (activeSortHeader?.sortType?.downArrow) {
      return sortBookingHighToLow(activeSortHeader, booking1, booking2)
    }
  }

  const onHandleOpenSidePanel = (bookingId) => {
    searchQueryParams.set(searchParamsInfo?.bookingId?.key, bookingId)
    navigate(`${location.pathname}?${searchQueryParams.toString()}`)
    setSideOverPanel(true)
  }

  const onHandleCloseSidePanel = (isShow) => {
    searchQueryParams.delete(searchParamsInfo?.bookingId?.key)
    navigate(`${location.pathname}`)
    setSideOverPanel(false)
  }

  const BookingSessionCount = memo(({ sessionCount }) => {

    return (
      <>
        {sessionCount
          ? (sessionCount > 1)
            ? <span className={"flex items-center justify-start gap-2"}>
              <span className={"font-bodyPri font-normal"}>
                {sessionCount}
              </span>
              <ToolTipView content={"Multi-Session Package: Extended learning in one booking."}>
                <button className={""}>
                  <FaLayerGroup className={"text-lg text-text-800"} />
                </button>
              </ToolTipView>
            </span>
            : sessionCount
          : "-"
        }
      </>
    )
  })

  const rows = useMemo(() => {
    if (!!bookingList?.data?.result) {
      return bookingList?.data?.result?.slice()?.sort(sortBookingList).map((booking, index) => [
        booking?.id,
        <BookingSessionCount sessionCount={booking?.bookingPrice?.sessionCount} />,
        <UserContainer bookingDetail={booking} />,

        <OfferingTypeContainer userOffering={booking} />,

        `${booking?.bookingPrice?.masterCurrency?.code} ${parseFloat(booking?.bookingPrice?.totalAmount / 100).toFixed(2)}` || "-",
        bookingStatusEnum[booking?.status?.toUpperCase()]?.label || "-",
        dayjs(booking?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        <div className="flex space-x-1" key={index}>
          <button
            className="p-1 bg-primary-main text-white rounded-md"
            onClick={() => onHandleOpenSidePanel(booking?.id)}
          >
            <FaEye className="text-xl hover:scale-110" />
          </button>
        </div>,
        dayjs(booking?.updatedAt).tz(timeZone).format("DD MMM, YYYY")
      ]);
    } else return [];
  }, [bookingList?.data?.result])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen">
      <SideOverPanel
        child={<BookingSideOverContent />}
        isOpen={sideOverPanel}
        onClose={onHandleCloseSidePanel}
      />
      <div className={"space-y-3"}>
        <PageHeader pageHeading={pageHeading} />
        <MyBookingSearch showEntries={showEntries} setShowEntries={setShowEntries} />
        <Table
          headers={Object.values(bookingHeaderConst)}
          subRowHeader={subRowHeader}
          rows={rows}
          rowHeaderColor={"bg-background-medium"}
          alternateRowColor={"bg-primary-light"}
          sortHeader={sortHeader}
          isLoading={bookingList?.isLoading}
          message={(bookingList?.message || (bookingList?.data?.pagination?.records === 0)) && (bookingList?.message || "No booking found!")}
        />

        {!bookingList?.isLoading && (bookingList?.data && bookingList?.data?.pagination?.totalPages > 1) &&
          <div className={"w-full flex justify-center items-center py-3"}>
            <Pagination
              page={bookingList?.data?.pagination?.page}
              totalPages={bookingList?.data?.pagination?.totalPages}
              onChangePage={(page) => navigate(pagesInfo.BOOKINGS.pagePath + "?pn=" + page)}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default BookingsPage;
