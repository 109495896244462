import { useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';

import { FaSpinner } from 'react-icons/fa';

// this component is for showing grouped container
const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: "#0049b7",
  backgroundColor: "#e3f2fd"
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default function SearchSelector({
  key,
  isLoading = false,
  isShowDropdownLabel = false,
  size = "small",
  options,
  selectedValue,
  showGroupedOptions = false,
  placeholder = "Select Any",
  dropdownLabel = "Select Any",
  disabled = false,
  onHandleSelect,
}) {
  const [open, setOpen] = useState(false);

  const groupedOptions = showGroupedOptions && options?.map((option) => {
    const firstLetter = option?.label[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  return (
    <Autocomplete
      id={key}
      sx={{ width: 300 }}
      size={size}
      open={open}
      placeholder={placeholder}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      getOptionLabel={(option) => option.label}
      value={selectedValue}
      options={showGroupedOptions
        ? groupedOptions?.sort((a, b) => -b?.firstLetter?.localeCompare(a?.firstLetter))
        : options
      }
      groupBy={(option) => option.firstLetter}
      onChange={(event, selectedOption) => onHandleSelect(selectedOption)}
      loading={isLoading}
      disabled={disabled}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={isShowDropdownLabel && dropdownLabel}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <FaSpinner className={"animate-spin text-lg text-primary-dark"} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}