import { useState } from "react";

import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";

import { BsThreeDotsVertical } from "react-icons/bs";

const useStyles = makeStyles({
    root: {},
});

const ThreeDotMenu = ({
    buttonLabel = <BsThreeDotsVertical className={"cursor-pointer"} size={24} color="gray" />,
    menuItems = [],
    onHandleSelect = () => { }
}) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (ele) => {
        setAnchorEl(null);
        onHandleSelect(ele)
    };

    return (
        <div>
            <div onClick={handleClick} className={"cursor-pointer"}>
                {buttonLabel}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                classes={{
                    root: classes.root,
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {Object.values(menuItems).map((ele, idx) =>
                    <MenuItem key={idx} onClick={() => handleClose(ele)} value={ele.label}>
                        {ele.label}
                    </MenuItem>
                )
                }
            </Menu>
        </div>
    );
};

export default ThreeDotMenu;
