import { memo, useMemo, useState } from 'react';
import { toast } from "react-toastify";

import MuiDatePicker from 'components/MUIComponents/MuiDatePicker';

import { actionButtons, sortDateTimeSlots, sortTimeSlots } from './data';
import TimeRange from './TimeRange';
import ActionIconButtons from './ActionIconButtons';

import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';
import { getTimeSlotWithDuration, validateIsStartBeforeEnd, validateIsTimeSlotExist, validateIsTimeSlotValid } from 'utils/availability.utils';

const NonRecurringSchedule = memo(({
    isLoading = false,
    isDisabled = false,
    selectedTimeZone = timeZone,
    dateTimeSlots = [],
    dateTimeSlot,
    saveDateTimeSlots
}) => {

    const timeZoneOffset = getTimeZoneOffset(selectedTimeZone)

    const localDay = useMemo(() => dayjs(dateTimeSlot?.date + timeZoneOffset, "YYYY-MM-DDZ")?.tz(selectedTimeZone)?.format('dddd'), [dateTimeSlot?.date])

    const [selectedTimeSlot] = useState({
        startTime: dayjs().tz(selectedTimeZone).format('09:[00:00]'),
        endTime: dayjs().tz(selectedTimeZone).format('09:[30:00]')
    });

    const onHandleAddTimeSlot = async () => {
        if (isLoading || isDisabled) return;

        let newSelectedTimeSlot = selectedTimeSlot
        if (!dateTimeSlot || dateTimeSlot?.timeSlots?.length === 0) {
            let startEndTimeSlot = selectedTimeSlot
            const totalTimeSlots = 0
            newSelectedTimeSlot = getTimeSlotWithDuration({ selectedTimeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: 30, totalTimeSlots })
        }

        if (dateTimeSlot?.timeSlots?.length > 0) {
            let startEndTimeSlot = dateTimeSlot?.timeSlots?.slice()?.sort(sortTimeSlots)?.slice(-1)[0]
            const totalTimeSlots = dateTimeSlot?.timeSlots?.length
            newSelectedTimeSlot = getTimeSlotWithDuration({ selectedTimeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: 30, totalTimeSlots })
        }

        if (!validateIsStartBeforeEnd({ selectedTimeZone, localDay, selectedTimeSlot: newSelectedTimeSlot })) {
            alert("End time should be after the start time!")
            return;
        }

        const timeSlots = dateTimeSlot?.timeSlots
        if (validateIsTimeSlotExist({ selectedTimeZone, localDay, selectedTimeSlot: newSelectedTimeSlot, timeSlots })) {
            alert("Slot already exist!")
            return;
        }

        let dateTime = []
        if (dateTimeSlots?.length > 0) {
            dateTime = dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTime) => (
                dateTime?.date === dateTimeSlot?.date ? { ...dateTime, timeSlots: [...timeSlots, newSelectedTimeSlot] } : dateTime
            ))
        } else {
            dateTime?.push({ date: dateTimeSlot?.date, timeSlots: [newSelectedTimeSlot] })
        }
        let newAvailSlots = [...dateTime]
        await saveDateTimeSlots(newAvailSlots)
    }

    const updateTimeSlot = async (oldTimeSlot, updatedTimeSlot) => {
        if (isLoading || isDisabled) return;

        let newTimeSlot = updatedTimeSlot

        
        if (!validateIsStartBeforeEnd({ selectedTimeZone, localDay, selectedTimeSlot: updatedTimeSlot })) {
            if (dateTimeSlot?.timeSlots?.length > 1) {
                let startEndTimeSlot = dateTimeSlot?.timeSlots?.slice()?.sort(sortTimeSlots)?.slice(-1)[0]
                const totalTimeSlots = dateTimeSlot?.timeSlots?.length
                newTimeSlot = getTimeSlotWithDuration({ selectedTimeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: 30, totalTimeSlots })
            } else {
                newTimeSlot = getTimeSlotWithDuration({ selectedTimeZone, localDay, timeSlot: newTimeSlot, sessionDuration: 30, totalTimeSlots: 0 })
            }
            newTimeSlot = validateIsTimeSlotValid({ selectedTimeSlot, localDay, selectedTimeSlot: newTimeSlot })
            // alert("End time should be after the start time!")
            // return;
        }

        let timeSlots = dateTimeSlot?.timeSlots.filter(slot => oldTimeSlot.startTime !== slot.startTime)
        if (validateIsTimeSlotExist({ selectedTimeZone, localDay, selectedTimeSlot: newTimeSlot, timeSlots })) {
            alert("Slot already exist!")
            return;
        }

        timeSlots = dateTimeSlot?.timeSlots?.slice()?.sort(sortTimeSlots)?.map(slot => (
            oldTimeSlot.startTime === slot.startTime && oldTimeSlot.endTime === slot.endTime ? newTimeSlot : slot
        ))

        let dateTime = dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTime) => (
            dateTime?.date === dateTimeSlot?.date ? { ...dateTime, timeSlots } : dateTime
        ))

        let newAvailSlots = [...dateTime]
        await saveDateTimeSlots(newAvailSlots)
    }

    const onHandleDeleteTimeSlot = async (selectedTimeSlot) => {
        if (isLoading || isDisabled) return;

        const timeSlots = dateTimeSlot?.timeSlots?.slice()?.sort(sortDateTimeSlots)?.filter(slot => slot.startTime !== selectedTimeSlot.startTime)

        let dateTime = dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTime) => (
            (dateTime?.date === dateTimeSlot?.date) ? { ...dateTime, timeSlots } : dateTime
        ))
        let newAvailSlots = [...dateTime]
        await saveDateTimeSlots(newAvailSlots)

    }

    const onHandleUpdateDate = async (date) => {
        if (isLoading || isDisabled) return;

        let selectedDayjs = dayjs(date?.format("YYYY-MM-DD") + timeZoneOffset, "YYYY-MM-DDZ")?.tz(selectedTimeZone)

        const filteredDateTimeSlots = dateTimeSlots?.filter((dateTimeSlot) => dayjs(dateTimeSlot?.date + timeZoneOffset, "YYYY-MM-DDZ")?.tz(selectedTimeZone) === selectedDayjs)
        if (filteredDateTimeSlots?.length > 0) {
            toast.warn("Same dates are added more than once!")
            return;
        }

        const updatedDate = selectedDayjs?.format("YYYY-MM-DD")

        let dateTime = dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTime) => (
            dateTime?.date === dateTimeSlot?.date ? { ...dateTime, date: updatedDate } : dateTime
        ))

        let newAvailSlots = [...dateTime]

        await saveDateTimeSlots(newAvailSlots)
    }

    return (
        <div className={"grid grid-cols-4 w-full gap-5"}>
            <div className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-1 pt-1.5"}>
                <MuiDatePicker
                    label={"Select Date"}
                    value={dateTimeSlot?.date ? dayjs(dateTimeSlot?.date)?.tz(selectedTimeZone) : null}
                    onChange={(date) => onHandleUpdateDate(date)}
                    slotProps={{ textField: { size: "small" } }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#9e9e9e",
                                height: 50,
                            },
                            "&:hover fieldset": {
                                borderColor: "#2196f3",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            fontWeight: '700',
                            color: '#212121',
                        },
                    }}
                    format={"DD-MM-YYYY"}
                    color={"primary"}
                    disablePast={true}
                    disabled={isDisabled}
                    helperText={"SelectDate"}
                    margin={"dense"}
                    minDate={dayjs().tz(selectedTimeZone)}
                />
            </div>

            {!!dateTimeSlot?.timeSlots?.length &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 space-y-3 pl-0 md:pl-16"}>
                    {dateTimeSlot?.timeSlots?.slice()?.sort(sortTimeSlots)?.map((timeSlot, index) => (
                        <TimeRange
                            key={index}
                            isLoading={isLoading}
                            isDisabled={isDisabled}
                            selectedTimeZone={selectedTimeZone}
                            localDay={localDay}
                            timeSlot={timeSlot}
                            updateTimeSlot={updateTimeSlot}
                            deleteTimeSlot={onHandleDeleteTimeSlot}
                        />
                    ))}
                </div>
            }

            {(!dateTimeSlot || (dateTimeSlot?.timeSlots?.length === 0)) &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 flex items-center justify-center pt-1.5"}>
                    <div className={"w-full p-1 font-bodyPri font-bold text-base text-text-800 tracking-wide text-center"}>
                        {"-"}
                    </div>
                </div>
            }

            <div className={"col-start-4 col-span-full sm:col-start-4 sm:col-span-full"}>
                <div className={"w-full flex flex-row items-start justify-start space-x-2"}>
                    <ActionIconButtons
                        isLoading={isLoading}
                        isDisabled={isDisabled}
                        iconButtonList={Object.values(actionButtons)?.filter((iconButton) => iconButton?.value === actionButtons.ADD.value)}
                        onHandleIconButton={onHandleAddTimeSlot}
                    />
                </div>
            </div>
        </div>
    )
})

export default NonRecurringSchedule;