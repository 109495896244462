import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/withdrawal/withdrawal.const";

class WithdrawalService {
    getWithdrawalList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.WITHDRAWAL_LIST),
            { params: query }
        )

        return response;
    }

    getWithdrawalDetailByWithdrawalId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.WITHDRAWAL_DETAIL, params)
        )

        return response;
    }

    approveWithdrawalByWithdrawalId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.APPROVE_WITHDRAWAL, params),
            body
        )
        return response
    }

    rejectWithdrawalByWithdrawalId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.REJECT_WITHDRAWAL, params),
            body
        )

        return response
    }
}

export default new WithdrawalService;