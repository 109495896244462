
import { useAppState } from "hooks/useStore";

const CourseDetailErrorMsg = () => {
    const { courseDetail, addCourseDetail } = useAppState((state) => state.course)


    return (courseDetail?.message || addCourseDetail?.errorMsg) ? (
        <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
            {courseDetail?.message || addCourseDetail?.errorMsg}
        </div>
    ) : null
}

export default CourseDetailErrorMsg;