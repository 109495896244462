import { dayjs } from "utils/dateTime.utils";

import {
    UserImage,
    SearchUserId,
    SearchUserName,
    SearchEmail,
    SearchPhone,
    // FilterUserStatus,
    SearchDate,
    ButtonAction,
    SearchModifiedDate
} from "./components/UserFilterStatistics"

export const pageHeading = {
    heading: "Users",
    subHeading: "Find the list of users!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const usersHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    PHONE: {
        label: "Phone",
        value: "phone"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    MODIFIED_DATE: {
        label: "Modified Date",
        value: "modified_date"
    }
}

export const subRowHeader = [
    <UserImage />,
    <SearchUserId />,
    <SearchUserName />,
    <SearchEmail />,
    <SearchPhone />,
    // <FilterUserStatus />,
    <SearchDate />,
    <ButtonAction />,
    <SearchModifiedDate />
]

export const usersMenuData = {
    // EDIT_USER: {
    //     label: "Edit User",
    //     value: "edit_user"
    // },
    ADD_BOOKING: {
        label: "Add Booking",
        value: "add_booking"
    },
    LOGIN_AS: {
        label: "Login As",
        value: "login_as"
    }
}

export const sortUserLowToHigh = (activeSortHeader, user1, user2) => {
    if (activeSortHeader?.activeSortKey === usersHeaderConst.USER_ID.value) {
        return Number(user1?.userId) - Number(user2?.userId)
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst.NAME.value) {
        return user1?.firstName?.toLowerCase()?.localeCompare(user2?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst.PHONE.value) {
        return user1?.mobileNo?.localeCompare(user2?.mobileNo)
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst?.EMAIL.value) {
        return user1?.email?.toLowerCase()?.localeCompare(user2?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst?.DATE?.value) {
        return dayjs(user2?.createdAt).isSameOrBefore(user1?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(user2?.createdAt).isSameOrBefore(user1?.createdAt) ? 1 : -1
    }
}

export const sortUserHighToLow = (activeSortHeader, user1, user2) => {
    if (activeSortHeader?.activeSortKey === usersHeaderConst.USER_ID.value) {
        return Number(user2?.id) - Number(user1?.id)
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst.NAME.value) {
        return user2?.firstName?.toLowerCase()?.localeCompare(user1?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst.PHONE.value) {
        return user2?.mobileNo?.localeCompare(user1?.mobileNo)
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst?.EMAIL.value) {
        return user2?.email?.toLowerCase()?.localeCompare(user1?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst?.DATE?.value) {
        return dayjs(user1?.createdAt).isSameOrBefore(user2?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === usersHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(user1?.createdAt).isSameOrBefore(user2?.createdAt) ? 1 : -1
    }
}
