import { memo, useState } from 'react';
import { toast } from 'react-toastify';

import { FaSpinner } from 'react-icons/fa';

import { motion } from 'framer-motion';

import ToolTipView from 'components/toolTip/index';

import { outsideIcons, socialKey } from 'pages/auth/editUser/data';

import linkTreeService from 'redux/linkTree/linkTree.service';

import { useAppState } from 'hooks/useStore';

const AddLink = memo(({isLoading = false, linkTreeItem, linkList, setLinkList }) => {
    const { socialList } = useAppState((s) => s.master)

    const [addNewLinkDetail, setAddNewLinkDetail] = useState({
        isLoading: false,
        data: null,
        message: null
    })

    const reorder = (social) => {
        const order = socialKey?.indexOf(social?.key)
        return { ...social, order: order < 0 ? socialList?.data?.length - 1 : order }
    }

    const sortSocial = (social1, social2) => social1.order >= social2.order ? 1 : -1

    const onHandleAddNewLink = async (item) => {
        if (addNewLinkDetail?.isLo || linkList?.isLoading) return;

        setAddNewLinkDetail((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                body: {
                    link_tree_id: linkTreeItem?.id,
                    social_key: item?.key,
                    title: item?.name,
                }
            }
            const response = await linkTreeService.addLink(requestData)
            if (response.status === 201) {
                setLinkList((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState?.data,
                        result: prevState?.data?.result?.map((treeItem) => (treeItem?.id === linkTreeItem?.id)
                            ? { ...treeItem, links: [response?.data?.data?.result, ...treeItem?.links] }
                            : treeItem
                        )
                    }
                }))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            setAddNewLinkDetail((prevState) => ({ ...prevState, message: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!" }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            setAddNewLinkDetail((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    const SocialItems = () => {
        return !!socialList?.data?.length && (
            <div className='grid grid-cols-3 gap-5 p-5'>
                {socialList?.data?.filter(item => !outsideIcons.includes(item.key)).map(reorder).sort(sortSocial)?.map((item) => (
                    <motion.button
                        key={item?.id}
                        whileHover={{ scale: 1.09 }}
                        onClick={() => onHandleAddNewLink(item)}
                        disabled={addNewLinkDetail?.isLoading}
                        className='w-20 h-20 p-2 gap-0.5 flex flex-col justify-center items-center bg-white hover:bg-background-light rounded-lg hover:shadow cursor-pointer'
                    >
                        <img
                            className='w-10 h-10 aspect-square'
                            src={item?.picture_url}
                            alt={item?.name}
                        />
                        <p className='whitespace-nowrap text-sm'>{item?.name}</p>
                    </motion.button>
                ))}
            </div>
        )
    }

    return !!socialList?.data?.length && (
        <div className='flex flex-col gap-3 md:gap-0 md:flex-row w-full justify-between'>
            <div className='w-fit grid grid-cols-3 lg:grid-cols-7 md:grid-cols-4 gap-5'>
                <>
                    {socialList?.data?.filter(item => outsideIcons.includes(item.key)).map(reorder).sort(sortSocial)?.map((item) => (
                        <motion.button
                            whileHover={{ scale: 1.09 }}
                            key={item?.id}
                            disabled={addNewLinkDetail?.isLoading}
                            onClick={() => onHandleAddNewLink(item)}
                            className='w-20 h-20 p-2 gap-0.5 flex flex-col justify-center items-center bg-white hover:bg-background-light rounded-lg hover:shadow cursor-pointer'
                        >
                            <img
                                className='w-10 h-10 aspect-square'
                                src={item?.picture_url}
                                alt={item?.name}
                            />
                            <p className='whitespace-nowrap text-sm'>{item?.name}</p>
                        </motion.button>
                    ))}
                    <ToolTipView
                        content={<SocialItems />}
                        allowHTML={true}
                        theme={"light"}
                        interactive={true}
                        placement='bottom-end'
                        hideOnClick={false}
                    >
                        <motion.div
                            whileHover={{ scale: 1.09 }}
                            className='w-20 h-20 p-2 gap-0.5 flex flex-col justify-center items-center bg-white hover:bg-background-light rounded-lg hover:shadow cursor-pointer'
                        >
                            <img
                                className='w-10 h-10 aspect-square'
                                src={"https://img.icons8.com/?size=600&id=44028&format=png"}
                                alt={'other'}
                            />
                            <p className='whitespace-nowrap text-sm'>{'More'}</p>
                        </motion.div>
                    </ToolTipView>
                    <div className='flex justify-center items-center md:hidden'>
                        {(addNewLinkDetail?.isLoading || isLoading) &&
                            <FaSpinner className={"text-xl text-primary-dark animate-spin"} />
                        }
                    </div>
                </>
            </div>
            <div className='hidden md:block'>
                {(addNewLinkDetail?.isLoading || isLoading) &&
                    <FaSpinner className={"text-xl text-primary-dark animate-spin"} />
                }
            </div>
        </div>
    )
})


export default AddLink
