import { useState } from "react";

import { ToggleWithText } from "components/common-components/Toggle";

const CompaniesWaitList = () => {
    const [isTutorWaitListEnabled, setIsTutorWaitListEnabled] = useState(false)

    return (
        <div className={"w-full px-5 py-10 bg-white rounded-md"}>
            {/* {tutorWaitList?.tutorWaitList && */}
            <ToggleWithText
                title={"Companies Wait List"}
                description={"Pause company recruitment process"}
                onChange={(isToggle) => setIsTutorWaitListEnabled(isToggle)}
                value={isTutorWaitListEnabled}
            />
            {/* } */}
        </div>
    )
}

export default CompaniesWaitList