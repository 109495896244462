import { cn } from "utils/cn.utils";


const WalletCard = ({ walletItem }) => {

  return (
    <div className={cn(
      "w-72 h-40 my-3 flex flex-col items-center justify-between gap-5 p-5 cursor-pointer",
      "rounded-3xl shadow-3xl border-b-8 border-l-8 group",
      "bg-primary-main border-primary-dark"
    )}>
      <div className="w-full">
        <span className={'font-bodyPri font-normal text-2xl text-text-50 text-left'}>
          {walletItem.title}
        </span>
      </div>
      <div className="w-full">
        <div className={cn(
          "font-bodyPri font-bold text-2xl text-text-50 text-right space-x-1",
          "group-hover:scale-110 group-hover:transition  ease-in-out",
          "duration-300 delay-150 group-hover:-translate-x-10 group-hover:-translate-y-3"
        )}>
          <span>{"USD"}</span>
          <span>{parseFloat(walletItem.amount / 100).toFixed(2)}</span>
        </div>
        {/* <div className={"font-bodyPri font-normal text-sm text-text-50 text-right space-x-1"}>
          <span>{"Approx."}</span>
          <span>{"INR"}</span>
          <span>{parseFloat(walletItem.amount / 100).toFixed(2)}</span>
        </div> */}
      </div>
    </div>
  );
}

export default WalletCard;