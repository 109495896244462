import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

// import { MdAdd } from "react-icons/md";

import FullPageLoader from "components/loader/FullPageLoader";
import { OptionSelector } from "components/common-components/Select";

import { videoCallTableHeaders, videoCallStatusConst } from "pages/auth/editTutorOnboard/data";
import AddUserSchedule from "./AddUserSchedule";
import SkipVideoCall from "./SkipVideoCall";

import { getOnboardingInterviewDetailByOnboardingId, updateOnboardingInterviewDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingInterviewDetail } from "redux/onboarding/onboarding.slice"
import { sessionStatusEnum } from "redux/session/session.const";

import { dayjs, timeZone } from "utils/dateTime.utils";
import { interviewCallStatus } from "redux/onboarding/onboarding.const";

const EditVideoCallSchedule = () => {
    const { onboardingInterviewDetail, addOnboardingInterviewDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [isAddSchedule, setIsAddSchedule] = useState(true);
    const [isOpenAddScheduleContainer, setIsOpenAddScheduleContainer] = useState(false);
    const [isSkipVideoCallEnabled, setIsSkipVideoCallEnabled] = useState(false)
    const [videoCallScheduleStatus, setVideoCallScheduleStatus] = useState(videoCallStatusConst?.PENDING?.value)

    useEffect(() => {
        if (onboardingId) {
            const requestData = {
                onboardingId: Number(onboardingId)
            }
            dispatcher(getOnboardingInterviewDetailByOnboardingId(requestData))
        }

        return () => {
            dispatcher(setClearOnboardingInterviewDetail())
        }
    }, [onboardingId])

    useEffect(() => {
        if (onboardingInterviewDetail?.onboardingInterviewDetail?.status) {
            setVideoCallScheduleStatus(onboardingInterviewDetail?.onboardingInterviewDetail?.status)
        }
    }, [onboardingInterviewDetail?.onboardingInterviewDetail?.status])

    const handleAddSchedule = () => {
        setIsSkipVideoCallEnabled(false)
        setIsOpenAddScheduleContainer(true)
        setIsAddSchedule(false)
    }

    const onHandleSave = (onboardingId) => {
        // if (!onboardingInterviewDetail?.onboardingInterviewDetail?.status || (videoCallScheduleStatus === onboardingInterviewDetail?.onboardingInterviewDetail?.status)) return;

        const requestData = {
            onboardingId: Number(onboardingId),
            body: {
                status: videoCallScheduleStatus
            }
        }
        dispatcher(updateOnboardingInterviewDetailByOnboardingId(requestData))
    }

    const handleSkipVideoCall = () => {
        setIsAddSchedule(false)
        setIsSkipVideoCallEnabled(true)
    }

    if (onboardingInterviewDetail?.isLoading || addOnboardingInterviewDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingInterviewDetail?.isLoading || addOnboardingInterviewDetail?.isLoading} />
        )
    }

    return (
        <div className="space-y-5">
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-5 py-3",
                !onboardingInterviewDetail?.onboardingInterviewDetail?.status && "bg-yellow-100",
                `bg-${interviewCallStatus[onboardingInterviewDetail?.onboardingInterviewDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Attend Video Call"}
            </div>

            {onboardingInterviewDetail?.onboardingInterviewDetail &&
                <div className={"flex flex-col items-start justify-start gap-8 px-5"}>
                    <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-8"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                            {"Status:"}
                        </span>
                        <div className={"w-52"}>
                            <OptionSelector
                                options={Object.values(videoCallStatusConst)}
                                className={""}
                                onChange={(option) => setVideoCallScheduleStatus(option?.value)}
                                value={videoCallScheduleStatus}
                            />
                        </div>
                    </div>
                    <div className={"flex items-center justify-start gap-5 px-5"}>
                        <div
                            className={cn(
                                "w-24 px-3 py-1 flex justify-center items-center rounded-lg font-bodyPri font-normal text-base",
                                "border border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                                // (!onboardingInterviewDetail?.onboardingInterviewDetail?.status || (videoCallScheduleStatus === onboardingInterviewDetail?.onboardingInterviewDetail?.status)) && "border border-text-300 text-text-300"
                            )}
                            onClick={() => onHandleSave(onboardingInterviewDetail?.onboardingInterviewDetail?.onboardingId)}
                        >
                            {"Save"}
                        </div>
                        <div
                            className={cn(
                                "w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                "border border-primary-main text-primary-main font-bodyPri font-normal text-base",
                                "hover:bg-primary-main hover:text-text-50"
                            )}
                        // onClick={() => dispatcher(getPersonalDetailByUserId(Number(userId)))}
                        >
                            {"Cancel"}
                        </div>
                    </div>
                </div>
            }


            {onboardingInterviewDetail?.onboardingInterviewDetail?.interviews?.length > 0 &&
                <div className={cn(
                    "min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow overflow-x-auto overflow-y-hidden",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>
                    <table className={"w-full border-separate rounded-lg"}>
                        <thead>
                            <tr>
                                {videoCallTableHeaders.map((head, index) => (
                                    <th
                                        key={index}
                                        className={cn(
                                            "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                            "font-bold whitespace-nowrap border border-gray-200"
                                        )}
                                    >
                                        {head}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="">
                            {onboardingInterviewDetail?.onboardingInterviewDetail?.interviews?.map((interview, index) => (
                                <tr
                                    className={''}
                                    key={index}>
                                    <td className="border border-gray-200">
                                        <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate capitalize'>
                                            {interview?.recruiter?.firstName + " " + interview?.recruiter?.lastName.charAt(0)}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className='px-5 py-2 font-bodyPri font-normal text-text-900 text-base text-center truncate'>
                                            {interview?.session ? dayjs(interview?.session?.startDateTime)?.tz(timeZone)?.format("ddd, DD MMM YYYY") : "-"}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className='px-5 py-2 font-bodyPri font-normal text-text-900 text-base text-center truncate'>
                                            {interview?.session ? dayjs(interview?.session?.startDateTime)?.tz(timeZone).format("HH:MM A") : "-"}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className={`px-5 py-2 font-bodyPri font-normal text-base text-${sessionStatusEnum[interview?.session?.status?.toUpperCase()]?.darkColor}`}>
                                            {interview?.session?.status ? sessionStatusEnum[interview?.session?.status?.toUpperCase()]?.label : "-"}
                                        </span>
                                    </td>
                                    {/* <td className={"border border-gray-200"}>
                                    <span
                                        className={`px-5 py-2 font-bodyPri font-normal text-base text-primary-main cursor-pointer underline`}
                                        onClick={() => updateVideoCallStatus(onboardingInterviewDetail?.onboardingInterviewDetail?.onboardingId)}
                                    >
                                        {"Update"}
                                    </span>
                                </td>
                                <td className={"border border-gray-200 px-5 py-2"}>
                                    <MdOutlineDelete className={"text-red-500 text-xl"} />
                                </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {onboardingInterviewDetail?.errorMsg &&
                <div className={"w-full font-bodyPri font-normal text-red-500 text-sm px-5"}>
                    {onboardingInterviewDetail?.errorMsg}
                </div>
            }

            <div className={cn(
                "flex items-center justify-start gap-5",
                isOpenAddScheduleContainer && "hidden"
            )}>
                {isAddSchedule &&
                    <div
                        className={cn(
                            "px-3 py-0.5 flex justify-center items-center rounded-lg cursor-pointer whitespace-nowrap",
                            "border border-primary-dark text-primary-dark font-bodyPri font-normal text-base",
                            "hover:bg-secondary-dark hover:text-text-50 hover:border-secondary-dark"
                        )}
                        onClick={handleAddSchedule}
                    >
                        {/* <MdAdd className={"text-lg text-text-700 group-hover:text-text-900"} /> */}
                        <span className={"font-bodyPri font-normal text-base"}>
                            {"Add Video Call"}
                        </span>
                    </div>
                }
                {(isAddSchedule && !isOpenAddScheduleContainer && !isSkipVideoCallEnabled && !onboardingInterviewDetail?.onboardingInterviewDetail) &&
                    <span className={"font-bodyPri font-normal text-text-900 text-md"}>
                        {"Or"}
                    </span>
                }
                {((!isOpenAddScheduleContainer && !isSkipVideoCallEnabled) && !onboardingInterviewDetail?.onboardingInterviewDetail) &&
                    <div
                        className={cn(
                            "px-3 py-0.5 flex justify-center items-center rounded-lg cursor-pointer whitespace-nowrap",
                            "border border-red-500 text-red-500 font-bodyPri font-normal text-base",
                            "hover:bg-red-700 hover:text-text-50"
                        )}
                        onClick={handleSkipVideoCall}
                    >
                        {"Skip Video Call"}
                    </div>
                }
            </div>
            {isOpenAddScheduleContainer &&
                <AddUserSchedule
                    setIsAddSchedule={setIsAddSchedule}
                    setIsOpenAddScheduleContainer={setIsOpenAddScheduleContainer}
                />
            }
            {isSkipVideoCallEnabled &&
                <SkipVideoCall setIsSkipVideoCallEnabled={setIsSkipVideoCallEnabled} setIsAddSchedule={setIsAddSchedule} />
            }
        </div>
    )
}

export default EditVideoCallSchedule;