import { useState, useEffect } from "react";

import { FaEye } from "react-icons/fa";

import Table from "components/table/Table";
import Pagination from "components/pagination/Pagination";
import SideOverPanel from "components/common-components/SideOverPanel";

import WithdrawalSideOverContent from "pages/auth/withdrawal/components/withdrawalSideOverPanel/WithdrawalSideOverContent";
import { withdrawalHeaderConst, subRowHeader, sortWithdrawalHighToLow, sortWithdrawalLowToHigh } from "pages/auth/withdrawal/data";

import { getWithdrawalList } from 'redux/withdrawal/withdrawal.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import {
    setWithdrawalDetailPayload,
    setClearWithdrawalList,
    setClearWithdrawalDetail,
    setClearTxnDetailPayload,
    setFilterProps,
    resetUpdateWithdrawalDetail,
} from "redux/withdrawal/withdrawal.slice";
import { modalConst } from "redux/local/local.const";
import { masterCurrencyCodeEnum } from "redux/master/master.const";
import { payoutType, withdrawalStatus } from "redux/withdrawal/withdrawal.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const DisplayWithdrawalTable = () => {
    const { modal } = useAppState((state) => state.local)
    const { withdrawalList, withdrawalDetail, updateWithdrawalDetail, filterProps } = useAppState((state) => state.withdrawal)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })
    const [sideOverPanel, setSideOverPanel] = useState(false)

    useEffect(() => {
        if (updateWithdrawalDetail?.data) {
            setSideOverPanel(false)
            dispatcher(resetUpdateWithdrawalDetail())
            dispatcher(setModal({
                modal,
                [modalConst.SUBMIT_COMMENT_MODAL.stateKey]: false
            }))
        }
    }, [updateWithdrawalDetail?.data])

    useEffect(() => {
        dispatcher(getWithdrawalList({ page: filterProps?.page, records: filterProps?.records }))

        return () => {
            dispatcher(setClearWithdrawalList())
        }
    }, [filterProps])

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortWithdrawalList = (withdrawal1, withdrawal2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortWithdrawalLowToHigh(activeSortHeader, withdrawal1, withdrawal2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortWithdrawalHighToLow(activeSortHeader, withdrawal1, withdrawal2)
        }
    }

    const onHandleOpenSidePanel = (withdrawalId) => {
        dispatcher(setClearWithdrawalDetail())
        dispatcher(setClearTxnDetailPayload())
        dispatcher(setWithdrawalDetailPayload({
            ...withdrawalDetail?.withdrawalDetailPayload,
            withdrawalId: withdrawalId,
        }));
        setSideOverPanel(true)
    }

    const rows = withdrawalList?.filteredWithdrawalList?.results?.slice(0)?.sort(sortWithdrawalList)?.map((withdrawal, index) => [
        withdrawal?.withdrawalId || "-",
        withdrawal?.user?.userId || "-",
        withdrawal?.gatewayPaymentId || "-",
        withdrawal?.virtualTxnId || "-",
        payoutType[withdrawal?.payoutType?.toUpperCase()]?.label || "-",
        `${masterCurrencyCodeEnum[withdrawal?.masterCurrency?.code?.toUpperCase()]?.label} ${(withdrawal?.amount / 100).toFixed(2)}` || "-",
        withdrawalStatus[withdrawal?.status?.toUpperCase()]?.label || "-",
        dayjs(withdrawal?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A') || "-",
        <div
            key={index}
            className={"flex justify-center items-center"}
        >
            <button
                className="px-2 py-1 bg-primary-main text-text-50 rounded-md"
                onClick={() => onHandleOpenSidePanel(withdrawal?.withdrawalId)}
            >
                <FaEye className="text-xl hover:scale-110" />
            </button>
        </div>,
    ]);

    return (
        <div className={""}>
            {sideOverPanel &&
                <SideOverPanel
                    child={<WithdrawalSideOverContent />}
                    isOpen={sideOverPanel}
                    onClose={(isShow) => setSideOverPanel(isShow)}
                />
            }
            <Table
                headers={Object.values(withdrawalHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={withdrawalList?.isLoading}
                message={(withdrawalList?.errorMsg || (withdrawalList?.filteredWithdrawalList?.records === 0)) && (withdrawalList?.errorMsg || "No withdrawals Found!")}
            />
            {(withdrawalList?.filteredWithdrawalList && withdrawalList?.filteredWithdrawalList?.records > 0) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={withdrawalList?.filteredWithdrawalList?.page}
                        totalPages={withdrawalList?.filteredWithdrawalList?.totalPages}
                        onChangePage={(page) => dispatcher(setFilterProps({
                            filterProps: { ...filterProps, page: page, records: filterProps?.records }
                        }))}
                    />
                </div>
            }
        </div>
    )
}

export default DisplayWithdrawalTable