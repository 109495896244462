import { useEffect } from 'react'

import { PageContentStyle } from './HomePageStyle';

import { pagesInfo } from 'utils/pagesInfo';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import HomeHero from 'pages/global/home/homeHero/HomeHero';

const HomePage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.HOME))
  }, [dispatcher, currentPageInfo])

  return (
    <PageContentStyle>
      <HomeHero />
    </PageContentStyle>
  )
}

export default HomePage