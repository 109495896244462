import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import LmsResourceService from "redux/edulyteLms/lmsResource/lmsResource.service";

import {
    setLmsResourceListLoading,
    setLmsResourceListData,
    setLmsResourceListMessage,

    setDestroyLmsResourceDetailLoading,
    setDestroyLmsResourceDetailMessage
} from "redux/edulyteLms/lmsResource/lmsResource.slice";
import { lmsResourceRedux } from "./lmsResource.const";

export const getLmsResourceList = createAsyncThunk(
    `${lmsResourceRedux?.sliceName}/${lmsResourceRedux?.getLmsResourceList?.requestName}`,
    async (query, {dispatch}) => {
        dispatch(setLmsResourceListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await LmsResourceService.getLmsResourceList(requestData)
            if (response.status === 200) {
                dispatch(setLmsResourceListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setLmsResourceListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setLmsResourceListLoading(false))
        }
    }
)

export const deleteLmsResourceDetail = createAsyncThunk(
    `${lmsResourceRedux?.sliceName}/${lmsResourceRedux?.getLmsResourceList?.requestName}`,
    async (resourceId, {dispatch}) => {
        dispatch(setDestroyLmsResourceDetailLoading(true))

        try {
            const requestData = {
                params: { resourceId: resourceId }
            }
            const response = await LmsResourceService.deleteLmsResourceDetail(requestData)
            if (response.status === 204) {
                toast.success(response.data.message || 'Deleted Successfully')
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setDestroyLmsResourceDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setDestroyLmsResourceDetailLoading(false))
        }
    }
)