import { createSlice } from "@reduxjs/toolkit";
import { LMS_COURSE_INITIAL_STATE } from "./lmsCourse.initialState";

const lmsCourse = createSlice({
    name: "Lms Course",
    initialState: LMS_COURSE_INITIAL_STATE,
    reducers: {
        setLmsCourseListLoading: (state, { payload }) => {
            state.lmsCourseList.isLoading = payload
        },
        setLmsCourseListData: (state, { payload }) => {
            state.lmsCourseList.data = payload
            state.lmsCourseList.message = LMS_COURSE_INITIAL_STATE.lmsCourseList.message
        },
        setLmsCourseListMessage: (state, { payload }) => {
            state.lmsCourseList.message = payload
            state.lmsCourseList.data = LMS_COURSE_INITIAL_STATE.lmsCourseList.data
        },
        resetLmsCourseList: (state) => {
            state.lmsCourseList = LMS_COURSE_INITIAL_STATE.lmsCourseList
        },

        /* Lms Course detail slice */
        setLmsCourseDetailLoading: (state, { payload }) => {
            state.lmsCourseDetail.isLoading = payload
        },
        setLmsCourseDetailData: (state, { payload }) => {
            state.lmsCourseDetail.data = payload
            state.lmsCourseDetail.message = LMS_COURSE_INITIAL_STATE.lmsCourseDetail.message
        },
        setLmsCourseDetailMessage: (state, { payload }) => {
            state.lmsCourseDetail.message = payload
            state.lmsCourseDetail.data = LMS_COURSE_INITIAL_STATE.lmsCourseDetail.data
        },
        resetLmsCourseDetail: (state) => {
            state.lmsCourseDetail = LMS_COURSE_INITIAL_STATE.lmsCourseDetail
        },

        /* lms Course Update */
        setModifyLmsCourseDetailLoading: (state, { payload }) => {
            state.modifyLmsCourseDetail.isLoading = payload
        },
        setModifyLmsCourseDetailData: (state, { payload }) => {
            state.modifyLmsCourseDetail.data = payload
            state.modifyLmsCourseDetail.message = LMS_COURSE_INITIAL_STATE.modifyLmsCourseDetail.message
        },
        setModifyLmsCourseDetailMessage: (state, { payload }) => {
            state.modifyLmsCourseDetail.message = payload
            state.modifyLmsCourseDetail.data = LMS_COURSE_INITIAL_STATE.modifyLmsCourseDetail.data
        },
        resetModifyLmsCourseDetail: (state) => {
            state.modifyLmsCourseDetail = LMS_COURSE_INITIAL_STATE.modifyLmsCourseDetail
        },

        // modify lms course detail payload
        setModifyLmsCourseDetailPayload: (state, {payload}) => {
            state.modifyLmsCourseDetail.payload = payload
        },
        resetModifyLmsCourseDetailPayload: (state) => {
            state.modifyLmsCourseDetail.payload = LMS_COURSE_INITIAL_STATE.modifyLmsCourseDetail.payload
        },

        // delete lms course detail
        setDestroyLmsCourseDetailLoading: (state, {payload}) => {
            state.destroyLmsCourseDetail.isLoading = payload
        },
        setDestroyLmsCourseDetailMessage: (state, {payload}) => {
            state.destroyLmsCourseDetail.message= payload
        },
        resetDestroyLmsCourseDetail: (state) => {
            state.destroyLmsCourseDetail = LMS_COURSE_INITIAL_STATE.destroyLmsCourseDetail
        },

        // reducers for filter course list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = LMS_COURSE_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setLmsCourseListLoading,
    setLmsCourseListData,
    setLmsCourseListMessage,
    resetLmsCourseList,

    setLmsCourseDetailLoading,
    setLmsCourseDetailData,
    setLmsCourseDetailMessage,
    resetLmsCourseDetail,

    setModifyLmsCourseDetailLoading,
    setModifyLmsCourseDetailData,
    setModifyLmsCourseDetailMessage,
    resetModifyLmsCourseDetail,

    setModifyLmsCourseDetailPayload,
    resetModifyLmsCourseDetailPayload,

    setDestroyLmsCourseDetailLoading,
    setDestroyLmsCourseDetailMessage,
    resetDestroyLmsCourseDetail,

    setFilterProps,
    resetFilterProps
} = lmsCourse.actions;

export default lmsCourse.reducer;