import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from 'pages/auth/edulyteLms/lmsCourse/lmsCourse.data';
import SearchLmsCourse from 'pages/auth/edulyteLms/lmsCourse/components/SearchLmsCourse';
import DisplayLmsCourse from "pages/auth/edulyteLms/lmsCourse/components/DisplayLmsCourse";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const LMSCoursePage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.LMS_COURSE))
  }, [dispatcher, currentPageInfo])

  return (
    <div className={'h-full w-full p-3 mx-auto min-h-screen space-y-3'}>
      <PageHeader pageHeading={pageHeading} />
      <SearchLmsCourse />
      <DisplayLmsCourse />
    </div>
  )
}

export default LMSCoursePage;