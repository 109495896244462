import { useState, useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ModalContainer from 'components/modalContainer/ModalContainer';
import NavBar from 'components/navbar/NavBar';
import SideNavBar from 'components/sideNavBar/SideNavBar';

import { PageStyle, PageContainerStyle, BarContainer } from './Router.style';

import { getMasterCurrencyRateList, getMasterTimezoneDetailByTimeZone } from 'redux/master/master.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { timeZone } from 'utils/dateTime.utils';


const LayoutRouter = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const [visibility, setVisibility] = useState({ isVisibleSideBar: false })

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [location.pathname]);


    useEffect(() => {
        dispatcher(getMasterCurrencyRateList())
        dispatcher(getMasterTimezoneDetailByTimeZone(timeZone))
    }, [])

    return (
        <PageStyle>
            <ToastContainer
                position={"bottom-right"}
                hideProgressBar={false}
                autoClose={2000}
                closeOnClick={true}
                pauseOnFocusLoss={true}
                pauseOnHover={true}
                draggable={true}
            />
            <ModalContainer />
            <BarContainer pageName={pageName}>
                <NavBar visibility={visibility} setVisibility={setVisibility} />
            </BarContainer>
            <PageContainerStyle pageName={pageName}>
                <SideNavBar visible={visibility.isVisibleSideBar} />
                <Outlet />
            </PageContainerStyle>
        </PageStyle>
    )
}

export default LayoutRouter;