import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import { classTypeTooltip, groupRepeatSession, oneOnOneRepeatSession, repeatTooltip } from "pages/auth/editCourseDetail/data";
import OneOnOneAvailabilityInputs from '../commonComponents/OneOnOneAvailabilityInputs';
import GroupAvailabilityInputs from '../commonComponents/GroupAvailabilityInputs';
import EditCourseTooltip from 'pages/auth/editCourseDetail/EditCourseTooltip';
import RepeatWeeklySchedule from './repeatWeeklySchedule/RepeatWeeklySchedule';
import DoesNotRepeatSchedule from './doesNotRepeatSchedule/DoesNotRepeatSchedule';

import { updateCourseDetail, getCourseAvailabilityDetail, createCourseAvailabilityDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetAddAvailabilityDetail, resetModifyAvailabilityDetail } from 'redux/availability/availability.slice';
import { setAddCourseAvailDetailPayload, setClearAddCourseAvailDetail, setClearAddCourseAvailDetailPayload, setClearCourseAvailDetail } from 'redux/course/course.slice';
import { courseIsRecurringEnum, courseEndTypeEnum, courseType, weekAvailTimeSlots } from 'redux/course/course.const';

import { timeZone } from 'utils/dateTime.utils';
import { pagesInfo } from 'utils/pagesInfo';

const AddScheduleDetail = () => {
    const { addAvailabilityDetail, modifyAvailabilityDetail } = useAppState((state) => state.availability)
    const { courseDetail, courseAvailDetail, addCourseAvailDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [isAddCourseScheduleEnabled, setIsAddCourseScheduleEnabled] = useState(courseAvailDetail?.courseAvailDetail ? true : false)

    const isOneOnOneCourseType = courseDetail?.courseDetail?.type === courseType?.ONE_ON_ONE?.value
    const isGroupCourseType = courseDetail?.courseDetail?.type === courseType?.GROUP?.value
    const repeatType = isGroupCourseType ? groupRepeatSession : oneOnOneRepeatSession

    useEffect(() => {
        if (courseDetail?.courseDetail?.courseAvailability?.id) {
            const requestDataPayload = {
                courseAvailabilityId: courseDetail?.courseDetail?.courseAvailability?.id,
                query: { timeZone: timeZone }
            }
            dispatcher(getCourseAvailabilityDetail(requestDataPayload))
        }

        return () => {
            dispatcher(setClearCourseAvailDetail())
            dispatcher(setClearAddCourseAvailDetail())
            dispatcher(resetAddAvailabilityDetail())
            dispatcher(resetModifyAvailabilityDetail())
        }
    }, [courseDetail?.courseDetail])

    useEffect(() => {
        if (courseAvailDetail?.courseAvailDetail) {
            dispatcher(setClearAddCourseAvailDetailPayload())
            // dispatcher(setAddCourseAvailDetailPayload(courseAvailDetail?.courseAvailDetail?.availability))
            if (courseDetail?.courseDetail?.type === courseType?.ONE_ON_ONE?.value) {
                dispatcher(setAddCourseAvailDetailPayload(courseAvailDetail?.courseAvailDetail?.availability))
            } else {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    userId: courseAvailDetail?.courseAvailDetail?.availability?.user?.userId,
                    name: courseAvailDetail?.courseAvailDetail?.availability?.name,
                    description: courseAvailDetail?.courseAvailDetail?.availability?.description,
                    type: courseAvailDetail?.courseAvailDetail?.availability?.type,
                    isRecurring: courseAvailDetail?.courseAvailDetail?.availability?.isRecurring,
                    minTimeBeforeAvailStart: (isOneOnOneCourseType)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.minTimeBeforeAvailStart : addCourseAvailDetail?.addCourseAvailDetailPayload?.minTimeBeforeAvailStart,
                    advanceSlotPeriodDays: (isOneOnOneCourseType)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.advanceSlotPeriodDays : addCourseAvailDetail?.addCourseAvailDetailPayload?.advanceSlotPeriodDays,
                    duration: (isGroupCourseType)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.duration / 15 : addCourseAvailDetail?.addCourseAvailDetailPayload?.duration,
                    weeklyTimeSlots: (courseAvailDetail?.courseAvailDetail?.availability?.isRecurring === courseIsRecurringEnum?.RECURRING?.value)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.weeklyTimeSlots : addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots,
                    startDateTime: courseAvailDetail?.courseAvailDetail?.availability?.startDateTime
                        ? courseAvailDetail?.courseAvailDetail?.availability?.startDateTime : addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime,
                    endType: courseAvailDetail?.courseAvailDetail?.availability?.endType
                        ? courseAvailDetail?.courseAvailDetail?.availability?.endType : courseEndTypeEnum?.FOREVER?.value,
                    endDateTime: (courseAvailDetail?.courseAvailDetail?.availability?.endType === courseEndTypeEnum?.ON_DATE?.value)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.endDateTime : addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime,
                    weeklyCycle: (courseAvailDetail?.courseAvailDetail?.availability?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.weeklyCycle : addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyCycle,
                    dateTimeSlots: (courseAvailDetail?.courseAvailDetail?.availability?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value)
                        ? courseAvailDetail?.courseAvailDetail?.availability?.dateTimeSlots : addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots
                }))
            }
            setIsAddCourseScheduleEnabled(true)
        }
    }, [courseAvailDetail?.courseAvailDetail])


    useEffect(() => {
        if (addAvailabilityDetail?.data?.result || modifyAvailabilityDetail?.data?.result) {
            const availabilityId = addAvailabilityDetail?.data?.result?.id || modifyAvailabilityDetail?.data?.result?.id
            if (!courseDetail?.courseDetail?.courseAvailability?.id) {
                dispatcher(createCourseAvailabilityDetail(
                    {
                        course: courseDetail?.courseDetail?.id,
                        availability: availabilityId
                    }
                ))
            }

            if (courseDetail?.courseDetail?.courseAvailability?.id) {
                const requestDataPayload = {
                    courseAvailabilityId: courseDetail?.courseDetail?.courseAvailability?.id,
                    query: { timeZone: timeZone }
                }
                dispatcher(getCourseAvailabilityDetail(requestDataPayload))
            }
            if (courseDetail?.courseDetail?.step <= 3) {
                dispatcher(updateCourseDetail(courseDetail?.courseDetail?.id, { step: courseDetail?.courseDetail?.step + 1 }))
            }

            const queryStepCount = 4
            navigate(`${pagesInfo?.EDIT_COURSE?.pagePath}/${courseDetail?.courseDetail?.id}/edit/set-pricing?step=${queryStepCount}`)
            dispatcher(resetAddAvailabilityDetail())
            dispatcher(resetModifyAvailabilityDetail())
        }
    }, [modifyAvailabilityDetail?.data?.result, addAvailabilityDetail?.data?.result])

    const onHandleAddSchedule = () => {
        if (courseAvailDetail?.isLoading ||addAvailabilityDetail?.isLoading ||  modifyAvailabilityDetail?.isLoading) return;

        dispatcher(setClearCourseAvailDetail())
        dispatcher(setClearAddCourseAvailDetail())
        dispatcher(setClearAddCourseAvailDetailPayload())

        if (isOneOnOneCourseType && !courseAvailDetail?.courseAvailDetail) {
            dispatcher(createCourseAvailabilityDetail({ course: courseDetail?.courseDetail?.id }))
        }
        setIsAddCourseScheduleEnabled(true)
    }

    const onHandleRepeat = (option) => {
        if (isOneOnOneCourseType) return;

        if (!courseDetail?.courseDetail?.courseAvailability?.id) {
            if (option?.value === courseIsRecurringEnum?.RECURRING?.value) {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    weeklyTimeSlots: addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots,
                    dateTimeSlots: []
                }))
            } else {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    dateTimeSlots: addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots,
                    weeklyTimeSlots: []
                }))
            }
        }

        if (courseDetail?.courseDetail?.courseAvailability?.id) {
            if (option?.value === courseIsRecurringEnum?.RECURRING?.value) {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    weeklyTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.weeklyTimeSlots,
                    dateTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.dateTimeSlots || []
                }))
            } else {
                dispatcher(setAddCourseAvailDetailPayload({
                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                    dateTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.dateTimeSlots,
                    weeklyTimeSlots: courseAvailDetail?.courseAvailDetail?.availability?.weeklyTimeSlots || weekAvailTimeSlots
                }))
            }
        }

        dispatcher(setAddCourseAvailDetailPayload({
            ...addCourseAvailDetail?.addCourseAvailDetailPayload,
            isRecurring: option?.value
        }))
    }

    return (
        <div className={cn(
            "w-full h-full bg-white rounded-lg select-none",
            isAddCourseScheduleEnabled && "min-h-[60vh]"
        )}>
            <div className={"w--full px-5 py-3 bg-background-darkLight rounded-lg"}>
                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Schedule"}
                </div>
            </div>
            {(addCourseAvailDetail?.isLoading || courseAvailDetail?.isLoading) &&
                <ComponentLoader isLoading={addCourseAvailDetail?.isLoading || courseAvailDetail?.isLoading} />
            }
            {(addCourseAvailDetail?.errorMsg || addAvailabilityDetail?.message || modifyAvailabilityDetail?.message || courseAvailDetail?.errorMsg) &&
                <div className={"w-full mt-3 flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                    {addCourseAvailDetail?.errorMsg || addAvailabilityDetail?.message || modifyAvailabilityDetail?.message || courseAvailDetail?.errorMsg}
                </div>
            }
            {(!isAddCourseScheduleEnabled && !addCourseAvailDetail?.isLoading && !courseAvailDetail?.isLoading) &&
                <div className={cn(
                    "w-full px-3 py-3 mt-3 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
                    "font-bodyPri font-normal text-text-900 cursor-pointer"
                )}
                    onClick={onHandleAddSchedule}
                >
                    <MdAdd className={"text-xl text-text-900"} />
                    <span className={"text-xl"}>
                        {"Add Schedule"}
                    </span>
                </div>
            }
            {(isAddCourseScheduleEnabled && !addCourseAvailDetail?.isLoading && !courseAvailDetail?.isLoading) &&
                <form className={"w-full grid grid-cols-12 gap-5 p-3 md:px-10 md:py-5"}>

                    {(isOneOnOneCourseType) &&
                        <div className={"col-start-1 col-span-full bg-complementary-light px-2 py-1"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                {"Applicable to all 1on1 sessions, this is the standard weekly schedule from your preferences. Updating this will affect all your 1on1 sessions."}
                            </span>
                        </div>
                    }

                    {/* course type container */}
                    <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-full lg:col-start-1 lg:col-span-3"}>
                        <div className={"flex items-center justify-start gap-1"}>
                            <EditCourseTooltip tooltip={classTypeTooltip} translateX={0} />
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {"Class Type:"}
                                <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
                            </span>
                        </div>
                    </div>
                    <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-full lg:col-start-4 lg:col-span-full md:w-80 flex flex-col items-start justify-start gap-1"}>
                        <input
                            type="text"
                            className={cn(
                                "w-full px-2 py-1 bg-background-light input-number-spin-none focus:outline-none border-2 border-text-300 rounded-md",
                                "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                            )}
                            placeholder={"Course Type..."}
                            value={courseType[courseDetail?.courseDetail?.type?.toUpperCase()]?.label}
                            disabled={true}
                        />
                        <Link
                            to={pagesInfo?.COURSES?.pagePath}
                            className={"w-full flex justify-end font-bodyPri font-normal text-primary-dark text-xs tracking-wide hover:underline"}
                        >
                            {"Choose another template"}
                        </Link>
                    </div>

                    {/* minimum notice period container */}
                    {isOneOnOneCourseType &&
                        <div className={"col-start-1 col-span-6"}>
                            <OneOnOneAvailabilityInputs />
                        </div>
                    }

                    {/* session duration container */}
                    {isGroupCourseType &&
                        <div className={"col-start-1 col-span-full"}>
                            <GroupAvailabilityInputs scheduleDuration={addCourseAvailDetail?.addCourseAvailDetailPayload?.duration} />
                        </div>
                    }


                    {/* repeat container */}
                    <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-3"}>
                        <div className={"flex items-center justify-start gap-1"}>
                            <EditCourseTooltip tooltip={repeatTooltip} translateX={0} />
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {(isGroupCourseType)
                                    ? "Repeat:"
                                    : "Weekly availability:"
                                }
                                <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
                            </span>
                        </div>
                    </div>
                    {isOneOnOneCourseType &&
                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-full lg:col-start-4 lg:col-span-full md:w-80 flex flex-col items-start justify-start gap-1"}>
                            <input
                                type="text"
                                className={cn(
                                    "w-full px-2 py-1 bg-background-light input-number-spin-none focus:outline-none border-2 border-text-300 rounded-md",
                                    "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                                )}
                                value={"Default Weekly Schedule"}
                                disabled={true}
                            />
                        </div>
                    }
                    {isGroupCourseType &&
                        <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full md:w-80"}>
                            <OptionSelector
                                className={"w-full"}
                                options={Object.values(repeatType)}
                                value={addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring}
                                onChange={(option) => onHandleRepeat(option)}
                                required={true}
                            />
                        </div>
                    }


                    {(addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.RECURRING?.value) &&
                        <div className={"col-start-1 col-span-full"}>
                            <RepeatWeeklySchedule setIsAddCourseScheduleEnabled={setIsAddCourseScheduleEnabled} />
                        </div>
                    }
                    {(addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value) &&
                        <div className={"col-start-1 col-span-full"}>
                            <DoesNotRepeatSchedule
                                setIsAddCourseScheduleEnabled={setIsAddCourseScheduleEnabled} />
                        </div>
                    }
                </form>
            }
        </div>
    )
}

export default AddScheduleDetail;