import Tippy from "@tippyjs/react";
import "tippy.js/animations/shift-toward-extreme.css";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/material.css";
import "tippy.js/themes/translucent.css";
import "tippy.js/themes/light-border.css";

function ToolTipView({
    content = "",
    animation = "shift-toward-extreme",
    placement = "top",
    theme = "",
    children,
    ...rest
}) {

    return (
        <Tippy
            content={content}
            animation={animation}
            placement={placement}
            theme={theme}
            {...rest}
        >
            {children}
        </Tippy>
    );
}

export default ToolTipView;