import { cn } from "utils/cn.utils";

import { useLocation } from "react-router-dom";

import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";

import { OptionSelector } from "components/common-components/Select";
import { tutorOnboardingHeaderConst } from "pages/auth/onboarding/data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearFilterProps, setFilterProps } from "redux/onboarding/onboarding.slice";
import { onBoardingStatus, onboardingTypeConst } from 'redux/onboarding/onboarding.const';
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";
import { getOnboardingList } from "redux/onboarding/onboarding.request";
import { generateQueryParams } from "utils/generators.utils";

const TutorOnboardingSearch = ({ showEntries, setShowEntries, activeTab, setActiveTab }) => {
    const { onboardingList, filterProps } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const header = Object.values(tutorOnboardingHeaderConst)
        .filter((headers) => headers?.value !== tutorOnboardingHeaderConst.ACTIVITY.value && headers?.value !== tutorOnboardingHeaderConst?.IMAGE?.value)
        .map((head) => head?.label)

    const body = onboardingList?.filteredOnboardingList?.results?.map((tutor) => ({
        [tutorOnboardingHeaderConst?.ONB_ID?.label]: tutor?.onboardingId,
        [tutorOnboardingHeaderConst?.USER_ID?.label]: tutor?.user?.userId,
        [tutorOnboardingHeaderConst?.NAME?.label]: tutor?.user?.firstName + " " + tutor?.user?.lastName?.charAt(0),
        [tutorOnboardingHeaderConst?.EMAIL?.label]: tutor?.user?.email?.toLowerCase(),
        [tutorOnboardingHeaderConst?.PHONE?.label]: "+" + tutor?.user?.isdCode + tutor?.user?.mobileNo,
        [tutorOnboardingHeaderConst?.STATUS?.label]: onBoardingStatus[tutor?.status?.toUpperCase()]?.label,
        [tutorOnboardingHeaderConst?.DATE?.label]: dayjs(tutor?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        [tutorOnboardingHeaderConst?.ACTION?.label]: "-",
        [tutorOnboardingHeaderConst?.MODIFIED_DATE?.label]: dayjs(tutor?.updatedAt).tz(timeZone).format('DD MMM, YYYY')
    }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-onboarding",
            sheet: "Onboarding",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value },
        }))
    }
    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={showEntries}
                    onChange={(option) => {
                        dispatcher(setClearFilterProps())
                        setShowEntries(option.value)
                    }}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <OptionSelector
                options={Object.values(onboardingTypeConst)}
                className={"w-full"}
                value={activeTab}
                onChange={(option) => {
                    const query = generateQueryParams(location.search)
                    dispatcher(getOnboardingList({ page: query.pn || 1, records: showEntries, onboardingType: option.value }))
                    setActiveTab(option.value)
                }}
            />
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-1.5 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-onboarding"}
                    className={cn(
                        "px-1.5 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here ..."}
                        value={filterProps.searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={(event) => handleFilterSearch(event)}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TutorOnboardingSearch