export const USER_BADGE_INITIAL_STATE = {
    badgeList: {
        isLoading: false,
        badgeList: null,
        filteredBadgeList: null,
        errorMsg: null
    },
    userBadgeList: {
        isLoading: false,
        userBadgeList: null,
        filteredUserBadgeList: null,
        errorMsg: null
    },
    addUserBadgeDetail: {
        isLoading: false,
        addUserBadgeDetail: null,
        errorMsg: null
    }
}