import React from 'react'

const EditBackgroundCheck = () => {
    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"BACKGROUND_CHECK"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"bg-white p-5 space-y-3"}>
                    <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                        {"Background check"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default EditBackgroundCheck