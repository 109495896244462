import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/lead/lead.const";

class LeadService {
    getLeadList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.LEAD_LIST,
            { params: query }
        )

        return response;
    }

    getLeadDetailByLeadId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.LEAD_DETAIL, params)
        )

        return response;
    }

    updateLeadDetailByLeadId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.LEAD_DETAIL, params),
            body
        )

        return response;
    }

    deleteLeadDetailByLeadId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.LEAD_DETAIL, params)
        )

        return response;
    }
}

export default new LeadService();