import { dayjs } from "utils/dateTime.utils";
import {
  SearchArticleId,
  SearchArticleTitle,
  SearchTutor,
  FilterArticleStatus,
  FilterArticleVisibility,
  SearchDate,
  ButtonAction
} from "./components/LmsArticleFilterStatistics";

export const pageHeading = {
  heading: "Articles",
  subHeading: "Find the list of articles!",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const lmsArticleHeaderConst = {
  ARTICLE_ID: {
    label: "Article ID",
    value: "article_id"
  },
  ARTICLE_TITLE: {
    label: "Article Title",
    value: "article_title"
  },
  OWNER_TUTOR: {
    label: "Owner Tutor",
    value: "owner_tutor"
  },
  STATUS: {
    label: "Status",
    value: "status"
  },
  VISIBILITY: {
    label: "Visibility",
    value: "visibility"
  },
  DATE: {
    label: "Date",
    value: "date"
  },
  ACTIVITY: {
    label: "Activity",
    value: "activity"
  },
}

export const subRowHeader = [
  <SearchArticleId />,
  <SearchArticleTitle />,
  <SearchTutor />,
  <FilterArticleStatus />,
  <FilterArticleVisibility />,
  <SearchDate />,
  <ButtonAction />,
]

export const sortArticleLowToHigh = (activeSortHeader, article1, article2) => {
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.ARTICLE_ID?.value) {
    return (Number(article1?.article_id) - Number(article2?.article_id))
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.ARTICLE_TITLE?.value) {
    return article1?.title?.toLowerCase()?.localeCompare(article2?.title?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.OWNER_TUTOR?.value) {
    return article1?.article_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(article2?.article_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.STATUS?.value) {
    return article1?.article_setting?.status?.toLowerCase()?.localeCompare(article2?.article_setting?.status?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.VISIBILITY?.value) {
    return article1?.article_setting?.visibility?.toLowerCase()?.localeCompare(article2?.article_setting?.visibility?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.DATE?.value) {
    return dayjs(article1?.createdAt).isSameOrBefore(article2?.createdAt) ? 1 : -1
  }
}

export const sortArticleHighToLow = (activeSortHeader, article1, article2) => {
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.ARTICLE_ID?.value) {
    return (Number(article2?.article_id) - Number(article1?.article_id))
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.ARTICLE_TITLE?.value) {
    return article2?.title?.toLowerCase()?.localeCompare(article1?.title?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.OWNER_TUTOR?.value) {
    return article2?.article_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(article1?.article_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.STATUS?.value) {
    return article2?.article_setting?.status?.toLowerCase()?.localeCompare(article1?.article_setting?.status?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.VISIBILITY?.value) {
    return article2?.article_setting?.visibility?.toLowerCase()?.localeCompare(article1?.article_setting?.visibility?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsArticleHeaderConst?.DATE?.value) {
    return dayjs(article2?.createdAt).isSameOrBefore(article1?.createdAt) ? 1 : -1
  }
}