
import { walletTabBtnConst } from "../../data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetAddCreditToWallet, setAddCreditPayload } from "redux/wallet/wallet.slice";
import { modalConst } from "redux/local/local.const";
import { addCreditToWalletPayload } from "redux/wallet/wallet.const";

const EditUserWallet = () => {
    const { modal } = useAppState((state) => state.local)
    const { userDetail } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const onHandleWalletBtn = (walletBtn) => {
        if (walletBtn.value === walletTabBtnConst.ADD_CREDIT.value) {
            dispatcher(resetAddCreditToWallet())
            dispatcher(setAddCreditPayload({
                ...addCreditToWalletPayload,
                userDetail: {
                    userId: userDetail?.userDetail?.userId,
                    firstName: userDetail?.userDetail?.firstName,
                    lastName: userDetail?.userDetail?.lastName,
                    profilePicUrl: userDetail?.userDetail?.profilePicUrl,
                    email: userDetail?.userDetail?.email
                }
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_USER_WALLET_CREDIT_MODAL.stateKey]: true
            }))
        }
    }

    return (
        <div>
            <div className={"w-full py-3 space-y-3"}>
                <div className="w-full flex item-start justify-center gap-1">
                    <span className="font-subHeading font-medium text-2xl text-text--800">
                        {"User Wallet"}
                    </span>
                </div>
                <div className="h-0.5 bg-gray-300 w-full"></div>
            </div>

            <div className={"w-full flex flex-wrap items-center justify-start gap-5 p-5"}>
                {Object.values(walletTabBtnConst).map((walletBtn) => (
                    <div
                        className={"w-fit flex items-center justify-center gap-1 py-0.5 px-2 border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"}
                        onClick={() => onHandleWalletBtn(walletBtn)}
                    >
                        {walletBtn?.icon &&
                            <span className={""}>
                                {walletBtn?.icon}
                            </span>
                        }
                        <span className={"font-bodyPri font-normal text-base group-hover:text-text-50"}>
                            {walletBtn?.label}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EditUserWallet;