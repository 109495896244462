import { cn } from "utils/cn.utils";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddAvailabilityDetail_Payload } from 'redux/availability/availability.slice';

const OneOnOneAvailabilityInputs = () => {
    const { addAvailabilityDetail } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    return (
        <div className={"grid grid-cols-2 gap-5 w-full"}>

            {/* minimum notice period */}
            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-1 font-bodyPri font-normal text-text-900 text-base"}>
                {"Minimum notice period required, before appointment can be booked:"}
                <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
            </div>

            <div className={"col-start-1 col-span-full md:col-start-2 md:col-span-1 w-full md:w-80 inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                <input
                    type="number"
                    className={cn(
                        "w-full px-1 py-1 bg-transparent input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                    )}
                    placeholder={"Price..."}
                    value={(addAvailabilityDetail?.payload?.recurringAvailability?.minTimeBeforeAvailStart / 60)?.toString()}
                    onChange={(e) => dispatcher(setAddAvailabilityDetail_Payload({
                        ...addAvailabilityDetail?.payload,
                        recurringAvailability: { ...addAvailabilityDetail?.payload?.recurringAvailability, minTimeBeforeAvailStart: Number(e.target.value) * 60 }
                    }))}
                    required={true}
                />
                <p className={cn("whitespace-nowrap font-bodyPri tracking-wide")}>
                    {"Hour(s)"}
                </p>
            </div>

            {/* maximum time container */}
            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-1 font-bodyPri font-normal text-text-900 text-base"}>
                {"Maximum time in advance that an appointment can be booked:"}
                <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
            </div>

            <div className={"col-start-1 col-span-full md:col-start-2 md:col-span-1 w-full md:w-80 inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                <input
                    type="number"
                    className={cn(
                        "w-full px-1 py-1 bg-transparent input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                    )}
                    placeholder={"Days..."}
                    value={addAvailabilityDetail?.payload?.recurringAvailability?.advanceSlotPeriodDays?.toString()}
                    onChange={(e) => dispatcher(setAddAvailabilityDetail_Payload({
                        ...addAvailabilityDetail?.payload,
                        recurringAvailability: { ...addAvailabilityDetail?.payload?.recurringAvailability, advanceSlotPeriodDays: Number(e.target.value) }
                    }))}
                    required={true}
                />
                <p className={cn("whitespace-nowrap font-bodyPri tracking-wide")}>
                    {"Day(s)"}
                </p>
            </div>
        </div>
    )
}

export default OneOnOneAvailabilityInputs;