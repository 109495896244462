import { tempCourseType } from "redux/template/template.const";

const tempCoursePayload = {
    templateId: null,
    tutorId: null
}

export const TEMPLATE_INITIAL_STATE = {
    tempCourseList: {
        isLoading: false,
        tempCourseList: null,
        errorMsg: null,
        filteredTempCourseList: null,
    },
    tempCourse:{
        isLoading: false,
        tempCourse: null,
        tempCoursePayload: tempCoursePayload,
        errorMsg: null
    },

    filterProps: {
        tempCourseType: tempCourseType.ALL.value
    }
}