import { createSlice } from "@reduxjs/toolkit";

import { WALLET_INITIAL_STATE } from "redux/wallet/wallet.initialState";
import {
    getWalletTxnList,
    getWalletConfirmationByVirtualTxnId,

    getMyWithdrawalListByUserId,
    getUserWithdrawalListByUserId,
    createUserWithdrawalDetailByUserId
} from "redux/wallet/wallet.request";

const wallet = createSlice({
    name: "wallet",
    initialState: WALLET_INITIAL_STATE,
    reducers: {
        // Actions for add credit to wallet
        setAddCreditToWalletLoading: (state, { payload }) => {
            state.addCreditToWallet.isLoading = payload
        },
        setAddCreditToWalletData: (state, { payload }) => {
            state.addCreditToWallet.data = payload
        },
        setAddCreditToWalletMessage: (state, { payload }) => {
            state.addCreditToWallet.message = payload
            state.addCreditToWallet.data = WALLET_INITIAL_STATE.addCreditToWallet.data
        },
        resetAddCreditToWallet: (state) => {
            state.addCreditToWallet = WALLET_INITIAL_STATE.addCreditToWallet
        },
        setAddCreditPayload: (state, { payload }) => {
            state.addCreditToWallet.payload = payload
        },
        resetAddCreditPayload: (state) => {
            state.addCreditToWallet.payload = WALLET_INITIAL_STATE.addCreditToWallet.payload
        },

        // Reducer for wallet confirmation
        setClearWalletConfirmation: (state) => {
            state.walletConfirmation = WALLET_INITIAL_STATE.walletConfirmation
        },

        // reducers for wallet txn list
        setClearWalletTxnList: (state) => {
            state.walletTxnList.isLoading = WALLET_INITIAL_STATE.walletTxnList.isLoading
            state.walletTxnList.walletTxnList = WALLET_INITIAL_STATE.walletTxnList.walletTxnList
            state.walletTxnList.errorMsg = WALLET_INITIAL_STATE.walletTxnList.errorMsg
        },

        // reducers for my Withdrawal list
        setClearMyWithdrawalList: (state) => {
            state.myWithdrawalList = WALLET_INITIAL_STATE.myWithdrawalList
        },

        // reducers for user withdrawal list
        setClearUserWithdrawalList: (state) => {
            state.userWithdrawalList = WALLET_INITIAL_STATE.userWithdrawalList
        },

        // reducers for user withdrawal detail
        setClearAddUserWithdrawalDetail: (state) => {
            state.addUserWithdrawalDetail = WALLET_INITIAL_STATE.addUserWithdrawalDetail
        },

        // reducers for add user withdrawal detail payload
        setAddUserWithdrawalDetailPayload: (state, { payload }) => {
            state.addUserWithdrawalDetail.userWithdrawalDetailPayload = payload
        },
        setClearAddUserWithdrawalDetailPayload: (state) => {
            state.addUserWithdrawalDetail.userWithdrawalDetailPayload = WALLET_INITIAL_STATE.addUserWithdrawalDetail.userWithdrawalDetailPayload
        },
    },
    extraReducers: {
        // extra-reducers for wallet txn list
        [getWalletTxnList.pending]: (state) => {
            state.walletTxnList.isLoading = true
        },
        [getWalletTxnList.fulfilled]: (state, { payload }) => {
            state.walletTxnList.isLoading = false
            state.walletTxnList.walletTxnList = payload
            state.walletTxnList.errorMsg = WALLET_INITIAL_STATE.walletTxnList.errorMsg
        },
        [getWalletTxnList.rejected]: (state, { payload }) => {
            state.walletTxnList.isLoading = false
            state.walletTxnList.errorMsg = payload
            state.walletTxnList.walletTxnList = WALLET_INITIAL_STATE.walletTxnList.walletTxnList
        },

        // extra reducers for wallet confirmation
        [getWalletConfirmationByVirtualTxnId.pending]: (state) => {
            state.walletConfirmation.isLoading = true
        },
        [getWalletConfirmationByVirtualTxnId.fulfilled]: (state, { payload }) => {
            state.walletConfirmation.isLoading = false
            state.walletConfirmation.walletConfirmation = payload
            state.walletConfirmation.errorMsg = WALLET_INITIAL_STATE.walletConfirmation.errorMsg
        },
        [getWalletConfirmationByVirtualTxnId.rejected]: (state, { payload }) => {
            state.walletConfirmation.isLoading = false
            state.walletConfirmation.errorMsg = payload
            state.walletConfirmation.walletConfirmation = WALLET_INITIAL_STATE.walletConfirmation.walletConfirmation
        },

        // reducers for my withdrawal list
        [getMyWithdrawalListByUserId.pending]: (state) => {
            state.myWithdrawalList.isLoading = true
        },
        [getMyWithdrawalListByUserId.fulfilled]: (state, { payload }) => {
            state.myWithdrawalList.isLoading = false
            state.myWithdrawalList.myWithdrawalList = payload
            state.myWithdrawalList.filteredMyWithdrawalList = payload
            state.myWithdrawalList.errorMsg = WALLET_INITIAL_STATE.myWithdrawalList.errorMsg
        },
        [getMyWithdrawalListByUserId.rejected]: (state, { payload }) => {
            state.myWithdrawalList.isLoading = false
            state.myWithdrawalList.errorMsg = payload
            state.myWithdrawalList.myWithdrawalList = WALLET_INITIAL_STATE.myWithdrawalList.myWithdrawalList
            state.myWithdrawalList.filteredMyWithdrawalList = WALLET_INITIAL_STATE.myWithdrawalList.filteredMyWithdrawalList
        },

        // reducers for user withdrawal list
        [getUserWithdrawalListByUserId.pending]: (state) => {
            state.userWithdrawalList.isLoading = true
        },
        [getUserWithdrawalListByUserId.fulfilled]: (state, { payload }) => {
            state.userWithdrawalList.isLoading = false
            state.userWithdrawalList.userWithdrawalList = payload
            state.userWithdrawalList.filteredUserWithdrawalList = payload
            state.userWithdrawalList.errorMsg = WALLET_INITIAL_STATE.userWithdrawalList.errorMsg
        },
        [getUserWithdrawalListByUserId.rejected]: (state, { payload }) => {
            state.userWithdrawalList.isLoading = false
            state.userWithdrawalList.errorMsg = payload
            state.userWithdrawalList.userWithdrawalList = WALLET_INITIAL_STATE.userWithdrawalList.userWithdrawalList
            state.userWithdrawalList.filteredMyWithdrawalList = WALLET_INITIAL_STATE.userWithdrawalList.filteredUserWithdrawalList
        },

        // reducers for user withdrawal details
        [createUserWithdrawalDetailByUserId.pending]: (state) => {
            state.addUserWithdrawalDetail.isLoading = true
        },
        [createUserWithdrawalDetailByUserId.fulfilled]: (state, { payload }) => {
            state.addUserWithdrawalDetail.isLoading = false
            state.addUserWithdrawalDetail.addUserWithdrawalDetail = payload
            state.addUserWithdrawalDetail.errorMsg = WALLET_INITIAL_STATE.addUserWithdrawalDetail.errorMsg
        },
        [createUserWithdrawalDetailByUserId.rejected]: (state, { payload }) => {
            state.addUserWithdrawalDetail.isLoading = false
            state.addUserWithdrawalDetail.errorMsg = payload
            state.addUserWithdrawalDetail.addUserWithdrawalDetail = WALLET_INITIAL_STATE.addUserWithdrawalDetail.addUserWithdrawalDetail
        }
    }
})

export const {
    setAddCreditToWalletLoading,
    setAddCreditToWalletData,
    setAddCreditToWalletMessage,
    resetAddCreditToWallet,
    setAddCreditPayload,
    resetAddCreditPayload,

    setWalletConfirmationLoading,
    setWalletConfirmation,
    setWalletConfirmationErrorMsg,
    setClearWalletConfirmation,

    setWalletTxnListLoading,
    setWalletTxnList,
    setWalletTxnListErrorMsg,
    setClearWalletTxnList,

    setClearMyWithdrawalList,
    setClearUserWithdrawalList,
    setClearAddUserWithdrawalDetail,

    setAddUserWithdrawalDetailPayload,
    setClearAddUserWithdrawalDetailPayload
} = wallet.actions

export default wallet.reducer