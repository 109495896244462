import EditTutorDetail from "pages/auth/editTutor/components/editTutorDetail/EditTutorDetail";
import EditTutorLanguageDetails from "pages/auth/editTutor/components/EditTutorLanguageDetails";
import EditTutorSubject from "pages/auth/editTutor/components/EditTutorSubject";
import EditTutorLesson from "pages/auth/editTutor/components/EditTutorLesson";
import EditTutorPrice from "pages/auth/editTutor/components/editTutorPrice";
import EditTutorOther from "pages/auth/editTutor/components/EditTutorOther";
import EditTutorConfigPage from "pages/auth/editTutor/components/editTutorConfig/EditTutorConfigPage";

import { userRoles } from "redux/auth/auth.const";

export const editTutorHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    TUTOR_ID: {
        label: "Tutor ID",
        value: "tutor_id"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    PHONE: {
        label: "Phone",
        value: "phone"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTION: {
        label: "Action",
        value: "action"
    }
}

export const languageLevelConst = {
    A1_BEGINNER: {
        label: "Beginner",
        value: "a1_beginner"
    },
    A2_ELEMENTARY: {
        label: "Elementary",
        value: "a2_elementary"
    },
    B1_INTERMEDIATE: {
        label: "Intermediate",
        value: "b1_intermediate"
    },
    B2_UPPER_INTERMEDIATE: {
        label: "Upper Intermediate",
        value: "b2_upper_intermediate"
    },
    C1_ADVANCE: {
        label: "Advance",
        value: "c1_advance"
    },
    C2_PROFICIENT: {
        label: "Proficient",
        value: "c2_proficiency"
    },
    NATIVE: {
        label: "Native",
        value: "native"
    }
}

export const profileEditBtnConst = {
    ELEVATOR_PITCH: {
        value: "elevatorPitch"
    },
    BIO: {
        value: "bio"
    },
    TEACHING_STYLE_BIO: {
        value: "teachingStyleBio"
    },
    VISIBILITY: {
        value: "visibility"
    },
    SPEAKS: {
        value: "speaks"
    },
    QUALIFICATIONS: {
        value: "qualifications"
    },
    SKILLS: {
        value: "skills"
    },
    SUBJECT_TOPIC: {
        value: "subjectAndTopics"
    },
    PRICE: {
        value: "price"
    },
    SCHEDULE: {
        value: "schedule"
    }
}

export const lessonPreferenceToolTip = "This is used to automate actions for yourself. Toggle button to switch on the automate action."

export const pricingPreferenceToolTip = "Set default price for your trial classes, One-on-One and group classes.Use your judgment and not set your price very low or very high.Offer discounts to students who book One-on-One sessions in a package. They can book sessions depending on your available time slots in your calendar."

export const tutorOtherPreferenceToolTip = "We will contact you if you opt for some extra work, subject to available opportunities."

export const tutorSubjectPreferenceToolTip = "Choose topic and level of students you would like to teach.Use plus sign to add a new topic and student level."

export const tutorDetailTooltipConst = {
    elevatorPitchTooltip: "For your public profile, have a snappy one- line for Me in a sentence field.It's like an elevator pitch with a few words. A few words to define you as an educator.",
    aboutMeTooltip: "Include your compelling / aspirational story or background, what you teach and why in the About me field, as your potential learners will keenly go through it.",
    languageTooltip: "Write language(s) of instructions. Drag, drop and save to organise their display order.",
    teachingStyleTooltip: "Write briefly about your creative, innovative teaching style.",
    skillsToolTip: "Write 3-5 skills your learners will acquire in this class. Drag, drop and save to organise their display order.",
    videoTooltip: "You have the option to upload a new, short video specifically for this class, or you can choose to keep your default introductory video.",
    trialLessonTooltip: "Set hourly fee for your trial sessions here. This is what learners will pay. Please make sure to account for fees and taxes.",
    oneOnOneChargeSessionTooltip: "Set your default hourly fee you are willing to accept after platform fee and taxes. You will be able to set fees for your classes while creating them. This input will be used for internal decision making.",
    GroupChargeSessionTooltip: "Default per session fee for 2 or more students in a group. This is the fee you are willing to accept after platform fee and taxes. You will be able to set fees for your classes while creating them. This input will be used for internal decision making.",
    bulkBookingTooltip: "Offer discounts to learners who book 5 sessions or more in one order(package of lessons).This input will be used for all packaged classes."
}

export const languageLevelReducer = (state, action) => {
    switch (action.type) {
        case "SHOW_ADD_BTN":
            return { ...state, isShowAddBtn: action.payload }
        case "SHOW_DROPDOWNS":
            return { ...state, isShowDropdowns: action.payload }
        case "LANGUAGE_AND_LEVEL_LIST":
            return { ...state, languageAndLevelList: action.payload }
        case 'SELECTED_LANGUAGE':
            return { ...state, language: action.payload }
        case 'SELECTED_LANGUAGE_LEVEL':
            return { ...state, languageLevel: action.payload }
        case 'IS_UPDATE_ENABLED':
            return { ...state, isUpdateEnabled: action.payload }
        case 'TUTOR_LANGUAGE_ID':
            return { ...state, tutorLanguageId: action.payload }
        case "INITIAL_STATE":
            return {
                ...state,
                language: {
                    language: "",
                    id: null
                },
                languageLevel: {
                    languageLevel: "",
                    id: null
                },
                isUpdateEnabled: false,
                tutorLanguageId: null
            }
        default:
            return state;
    }
}

export const subjectTopicReducer = (state, action) => {
    switch (action.type) {
        case "SHOW_ADD_BTN":
            return { ...state, isShowAddBtn: action.payload }
        case "SHOW_DROPDOWNS":
            return { ...state, isShowDropdowns: action.payload }
        case "CATEGORY_AND_PROFICIENCY_LIST":
            return { ...state, categoryProficiencyList: action.payload }
        case "SELECTED_CATEGORY":
            return { ...state, category: action.payload }
        case "SELECTED_PROFICIENCY":
            return { ...state, proficiency: action.payload }
        case "INITIAL_STATE":
            return {
                ...state,
                category: { category: "", id: null },
                proficiency: { proficiency: "", id: null }
            }
        default:
            return state;
    }
}

const lessonPrefConst = {
    autoConfirmLesson: {
        label: "Automatically Confirm Lessons",
        description: "Using this option would confirm all lesson request automatically."
    },
    instantBookNewStudent: {
        label: "Accept Lesson Request from current student",
        description: "If green, we will confirm all new requests/ bookings automatically from any student you met at least once."
    },
    instantBookCurrentStudent: {
        label: "Accept Lesson Request from new student",
        description: "If Green, we will confirm all new requests/ bookings automatically from new students."
    },
    allowNewStudent: {
        label: "Instant Booking From Current Student",
        description: "If Green, your current students can book start a meeting with you after making payment.You must be online and not in a meeting."
    },
    allowCurrentStudent: {
        label: "Instant Booking From New Student",
        description: "If Green, your new students can book start a meeting with you after making payment. You must be online and not in a meeting."
    }
}

export const setGetLessonPref = (lessonPref) => (
    Object.keys(lessonPref)?.map((lesson) => ({
        key: lesson,
        label: lessonPrefConst[lesson]?.label,
        description: lessonPrefConst[lesson]?.description,
        value: lessonPref[lesson]
    }))
)

const otherPrefConst = {
    contentCreation: {
        label: "Count me in for content creation work",
        description: "You will create your own content."
    },
    paidInfluencer: {
        label: "Count me in for paid influencer work",
        description: "We will get in touch for any influencer work."
    },
    personalisedReco: {
        label: "Personal recommendation",
        description: "Allow personal recommendation."
    }
}

export const setGetOtherPref = (otherPref) => (
    Object.keys(otherPref).map((other) => ({
        key: other,
        label: otherPrefConst[other].label,
        description: otherPrefConst[other].description,
        value: otherPref[other]
    }))
)

export const tutorConfigActionType = {
    ADMIN_FIXED_PCT_COMMISSION: {
        value: "ADMIN_FIXED_PCT_COMMISSION"
    },
    ADMIN_VARIABLE_PCT_COMMISSION: {
        value: "ADMIN_VARIABLE_PCT_COMMISSION"
    },
    CURRENCY_CODE: {
        value: "CURRENCY_CODE"
    },
    FIXED_PRICE: {
        value: "FIXED_PRICE"
    }
}

export const tutorConfigReducer = (state, action) => {
    switch (action.type) {
        case tutorConfigActionType?.ADMIN_FIXED_PCT_COMMISSION?.value:
            return { ...state, adminFixedPctCommission: action?.payload }
        case tutorConfigActionType?.ADMIN_VARIABLE_PCT_COMMISSION?.value:
            return { ...state, adminVariablePctCommission: action?.payload }
        case tutorConfigActionType?.CURRENCY_CODE?.value:
            return { ...state, currencyCode: action?.payload }
        case tutorConfigActionType?.FIXED_PRICE?.value:
            return { ...state, fixedPrice: action?.payload }
        default:
            return state;
    }
}

export const setLocalAppointmentDetail = async (responseData) => {
    let payload = {}

    if (responseData?.title) {
        payload["title"] = responseData?.title
    }
    if (responseData?.subtitle) {
        payload["subtitle"] = responseData?.subtitle
    }
    if (responseData?.appointment_availability?.availability) {
        payload["availability"] = responseData?.appointment_availability?.availability
    }
    if (responseData?.appointment_availability?.duration) {
        payload["duration"] = responseData?.appointment_availability?.duration
    }
    if (responseData?.appointment_price?.price_model) {
        payload["price_model"] = responseData?.appointment_price?.price_model
    }
    if (responseData?.appointment_price?.masterCurrency) {
        payload["masterCurrency"] = responseData?.appointment_price?.masterCurrency
    }
    if (responseData?.appointment_price?.price) {
        payload["price"] = responseData?.appointment_price?.price
    }
    if (responseData?.appointment_price?.discount_pct) {
        payload["discount_pct"] = responseData?.appointment_price?.discount_pct
    }
    if (responseData?.description) {
        payload["description"] = responseData?.description
    }
    if (responseData?.visibility) {
        payload["visibility"] = responseData?.visibility
    }

    return payload;
}

export const similarAppointmentDetail = (responseData, localPayload) => {
    return (!localPayload?.title || !localPayload?.duration || (localPayload?.duration < 30)) || !localPayload?.availability?.id || !localPayload?.price_model
        || (responseData && (JSON.stringify({
            title: responseData?.title,
            subtitle: responseData?.subtitle,
            duration: responseData?.appointment_availability?.duration,
            availabilityId: responseData?.appointment_availability?.availability?.id,
            description: responseData?.description,
            duration: responseData?.appointment_availability?.duration,
            masterCurrency: responseData?.appointment_price?.masterCurrency,
            price_model: responseData?.appointment_price?.price_model,
            price: responseData?.appointment_price?.price || 0,
            discount_pct: responseData?.appointment_price?.discount_pct || 0,
            visibility: responseData?.visibility
        }) === JSON.stringify({
            title: localPayload?.title,
            subtitle: localPayload?.subtitle,
            duration: localPayload?.duration,
            availabilityId: localPayload?.availability?.id,
            description: localPayload?.description,
            duration: localPayload?.duration,
            masterCurrency: localPayload?.masterCurrency,
            price_model: localPayload?.price_model,
            price: localPayload?.price,
            discount_pct: localPayload?.discount_pct,
            visibility: localPayload?.visibility
        })))
}

export const sessionDurationConst = {
    THIRTY: {
        label: "30 min",
        value: 30
    },
    FORTY_FIVE: {
        label: "45 min",
        value: 45
    },
    SIXTY: {
        label: "60 min",
        value: 60
    },
    CUSTOM: {
        label: "Custom",
        value: "custom"
    }
}

export const myPriceKeyConst = {
    TITLE: {
        value: "title"
    },
    SUB_TITLE: {
        value: "subtitle"
    },
    AVAILABILITY: {
        value: "availability"
    },
    DESCRIPTION: {
        value: "description"
    },
    DURATION: {
        value: "duration"
    },
    VISIBILITY: {
        value: "visibility"
    },
    PRICE_MODEL: {
        value: "price_model"
    },
    PRICE: {
        value: "price"
    },
    DISCOUNT_PCT: {
        value: "discount_pct"
    }
}

export const appointmentDetailTooltips = {
    title: "Enter the name of your appointment or event",
    subtitle: "Add a brief description or tagline for your appointment.",
    duration: "Specify the length of each appointment or session",
    schedule: "Select an existing schedule or create a new one.",
    description: "Provide additional details or special instructions for attendees",
    price: "Set the cost for each appointment or session, if applicable.",
}

export const validateAppointmentDetailPayload = async (localPayload, isFlatFeePriceModel = false) => {
    let requestPayload = {};
    let errorMsg = "";

    if (localPayload?.duration < 30) {
        errorMsg = "Invalid session duration!"
        return { requestPayload, errorMsg };
    }

    if (localPayload?.discount_pct > 100) {
        errorMsg = "Invalid discount percentage!"
        return { requestPayload, errorMsg };
    }

    if (isFlatFeePriceModel) {
        if (!localPayload?.price || (localPayload?.price < 0)) {
            errorMsg = "Please enter valid price for flat fee model!"
            return { requestPayload, errorMsg };
        }
        if (localPayload?.discount_pct < 0) {
            errorMsg = "Please enter discount percent for flat fee model!"
            return { requestPayload, errorMsg };
        }
    }

    if (localPayload?.title) {
        requestPayload["title"] = localPayload?.title
    }
    if (localPayload?.subtitle) {
        requestPayload["subtitle"] = localPayload?.subtitle
    }
    if (localPayload?.availability) {
        requestPayload["availability_id"] = localPayload?.availability?.id
    }
    if (localPayload?.duration) {
        requestPayload["duration"] = localPayload?.duration
    }
    if (localPayload?.price_model) {
        requestPayload["price_model"] = localPayload?.price_model
    }
    if (localPayload?.visibility) {
        requestPayload["visibility"] = localPayload?.visibility
    }
    if (localPayload?.description) {
        requestPayload["description"] = localPayload?.description
    }
    if (isFlatFeePriceModel && (localPayload?.price > 0)) {
        requestPayload["price"] = localPayload?.price
    }
    if (isFlatFeePriceModel && (localPayload?.discount_pct >= 0)) {
        requestPayload["discount_pct"] = localPayload?.discount_pct
    }

    return { requestPayload, errorMsg };
}

export const editTutorNavigationOptions = {
    TUTOR_DETAILS: {
        label: "Tutor Details",
        value: "tutor-details",
        pagePath: "/tutor-details",
        routePath: "tutor-details",
        component: EditTutorDetail,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
    TUTOR_LANGUAGES: {
        label: "Tutor Languages",
        value: "tutor-languages",
        pagePath: "/tutor-languages",
        routePath: "tutor-languages",
        component: EditTutorLanguageDetails,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
    SUBJECT: {
        label: "Subject/Topic",
        value: "subject",
        pagePath: "/subject",
        routePath: "subject",
        component: EditTutorSubject,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
    LESSON: {
        label: "Lesson",
        value: "lesson",
        pagePath: "/lesson",
        routePath: "lesson",
        component: EditTutorLesson,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
    PRICE: {
        label: "Price",
        value: "price",
        pagePath: "/price",
        routePath: "price",
        component: EditTutorPrice,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
    OTHER: {
        label: "Other",
        value: "other",
        pagePath: "/other",
        routePath: "other",
        component: EditTutorOther,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
    TUTOR_CONFIG: {
        label: "Tutor Config",
        value: "tutor-config",
        pagePath: "/tutor-config",
        routePath: "tutor-config",
        component: EditTutorConfigPage,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
}