import { courseFilterProps, courseEndTypeEnum, tempCourseType, weekAvailTimeSlots, addCourseTutorDetailPayload } from "redux/course/course.const";
import { dayjs } from "utils/dateTime.utils";

const START_DATE_TIME = dayjs(new Date().setDate(new Date().getDate() + 8))?.format("YYYY-MM-DD HH:mm:ss")

const courseTemplateDetailPayload = {
    templateId: null,
    tutorId: null,
    type: tempCourseType?.ALL?.value
}

const courseDetailPayload = {
    isApproved: false,
    isRejected: false,
    status: null,
}

const addCourseAvailDetailPayload = {
    userId: null,
    name: null,
    description: null,
    type: null,
    minTimeBeforeAvailStart: 720,
    advanceSlotPeriodDays: 30,
    duration: 2,
    isRecurring: null,
    timeZone: null,
    weeklyTimeSlots: weekAvailTimeSlots,
    startDateTime: START_DATE_TIME,
    endType: courseEndTypeEnum?.ON_DATE?.value,
    endDateTime: dayjs(new Date(START_DATE_TIME).setDate(new Date(START_DATE_TIME).getDate() + 14))?.format("YYYY-MM-DD HH:mm:ss"),
    weeklyCycle: null,
    dateTimeSlots: []
}

export const COURSE_INITIAL_STATE = {
    courseList: {
        isLoading: false,
        data: null,
        message: null
    },

    publicCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    selectedPublicCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    courseTemplateList: {
        isLoading: false,
        data: null,
        message: null
    },

    courseTemplateDetail: {
        isLoading: false,
        data: null,
        payload: courseTemplateDetailPayload,
        message: null
    },

    courseDetail: {
        isLoading: false,
        courseDetail: null,
        courseDetailPayload: courseDetailPayload,
        errorMsg: null
    },
    addCourseDetail: {
        isLoading: false,
        addCourseDetail: null,
        errorMsg: null
    },

    courseTagList: {
        isLoading: false,
        courseTagList: null,
        errorMsg: null
    },
    addCourseTagDetail: {
        isLoading: false,
        addCourseTagDetail: null,
        errorMsg: null
    },

    courseProfList: {
        isLoading: false,
        courseProfList: null,
        errorMsg: null
    },
    addCourseProfDetail: {
        isLoading: false,
        addCourseProfDetail: null,
        errorMsg: null
    },

    courseLanguageList: {
        isLoading: null,
        courseLanguageList: null,
        errorMsg: null
    },
    addCourseLanguageDetail: {
        isLoading: false,
        addCourseLanguageDetail: null,
        errorMsg: null
    },

    courseAvailDetail: {
        isLoading: false,
        courseAvailDetail: null,
        errorMsg: null
    },
    addCourseAvailDetail: {
        isLoading: false,
        addCourseAvailDetail: null,
        addCourseAvailDetailPayload: addCourseAvailDetailPayload,
        errorMsg: null
    },

    coursePriceDetail: {
        isLoading: false,
        coursePriceDetail: null,
        errorMsg: null
    },
    addCoursePriceDetail: {
        isLoading: false,
        addCoursePriceDetail: null,
        errorMsg: null
    },

    // course comment list
    courseCommentList: {
        isLoading: false,
        data: null,
        message: null
    },
    addCourseCommentDetail: {
        isLoading: false,
        message: null
    },

    // course preview detail
    coursePreviewDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // next course session detail
    nextCourseSessionDetail: {
        isLoading: false,
        data: null,
        payload: null,
        message: null
    },

    // course tutor list
    courseTutorList: {
        isLoading: false,
        data: null,
        message: null
    },
    courseTutorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addCourseTutorDetail: {
        isLoading: false,
        data: null,
        payload: addCourseTutorDetailPayload,
        message: null
    },
    updateCourseTutor: {
        isLoading: false,
        data: null,
        message: null
    },
    deleteCourseTutor: {
        isLoading: false,
        message: null
    },

    // course config detail
    courseConfigDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addCourseConfig: {
        isLoading: false,
        message: null
    },
    updateCourseConfig: {
        isLoading: false,
        message: null
    },
    deleteCourseConfig: {
        isLoading: false,
        message: null
    },

    cloneCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    filterProps: courseFilterProps
}