import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import bookingService from "redux/booking/booking.service";

import {
    setBookingListLoading,

    setBookingDetailLoading,
    setBookingDetailData,
    setBookingDetailMessage,

    setAddBookingDetailLoading,
    setAddBookingDetailData,
    setAddBookingDetailMessage,

    setAddBookingSessionDetailLoading,
    setAddBookingSessionDetailData,
    setAddBookingSessionDetailMessage,
} from "./booking.slice";
import { bookingRedux } from "redux/booking/booking.const";

export const getBookingList = createAsyncThunk(
    `${bookingRedux?.sliceName}/${bookingRedux.BookingList.requestName}`,
    async (query, { dispatch, rejectWithValue }) => {
        dispatch(setBookingListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await bookingService.getBookingList(requestData)
            if (response.status === 200) {
                return response?.data?.data;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
            return rejectWithValue(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        } finally {
            dispatch(setBookingListLoading(false))
        }
    }
);

export const getBookingDetail = createAsyncThunk(
    `${bookingRedux?.sliceName}/${bookingRedux?.bookingDetail?.requestName}`,
    async (bookingDetailPayload, { dispatch }) => {
        dispatch(setBookingDetailLoading(true))

        try {
            const requestData = {
                params: { bookingId: bookingDetailPayload?.bookingId },
                query: bookingDetailPayload?.query
            }
            const response = await bookingService.getBookingDetail(requestData)
            if (response.status === 200) {
                dispatch(setBookingDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error)
            dispatch(setBookingDetailMessage(error?.response?.data?.message || error?.response?.data?.error))
        } finally {
            dispatch(setBookingDetailLoading(false))
        }
    }
)

export const createBookingDetail = createAsyncThunk(
    `${bookingRedux?.sliceName}/${bookingRedux?.addBookingDetail?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddBookingDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await bookingService.createBookingDetail(requestData)
            if (response.status === 201) {
                dispatch(getBookingList({ page: 1, records: 25 }))
                dispatch(setAddBookingDetailData(response.data.data))
                toast.success(response.data.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
            dispatch(setAddBookingDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddBookingDetailLoading(false))

        }
    }
)

export const createBookingSessionDetail = createAsyncThunk(
    `${bookingRedux?.sliceName}/${bookingRedux?.updateBooking?.requestName}`,
    async (bookingDetailPayload, { dispatch }) => {
        dispatch(setAddBookingSessionDetailLoading(true))

        try {
            const requestData = {
                params: { bookingId: bookingDetailPayload?.bookingId },
                body: bookingDetailPayload?.body
            }
            const response = await bookingService.createBookingSessionDetail(requestData)
            if (response.status === 201) {
                dispatch(setAddBookingSessionDetailData(response.data.data))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
            dispatch(setAddBookingSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddBookingSessionDetailLoading(false))
        }
    }
)