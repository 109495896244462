import { createSlice } from "@reduxjs/toolkit";

import { LMS_QUESTION_INITIAL_STATE } from "./lmsQuestion.initialState";

const lmsQuestion = createSlice({
    name: "lms question",
    initialState: LMS_QUESTION_INITIAL_STATE,
    reducers: {
        // lms question list
        setLmsQuestionListLoading: (state, { payload }) => {
            state.lmsQuestionList.isLoading = payload
        },
        setLmsQuestionListData: (state, { payload }) => {
            state.lmsQuestionList.data = payload
            state.lmsQuestionList.message = LMS_QUESTION_INITIAL_STATE.lmsQuestionList.message
        },
        setLmsQuestionListMessage: (state, { payload }) => {
            state.lmsQuestionList.message = payload
            state.lmsQuestionList.data = LMS_QUESTION_INITIAL_STATE.lmsQuestionList.data
        },
        resetLmsQuestionList: (state) => {
            state.lmsQuestionList = LMS_QUESTION_INITIAL_STATE.lmsQuestionList
        },

        // destroy lms question detail
        setDestroyLmsQuestionDetailLoading: (state, {payload}) => {
            state.destroyLmsQuestionDetail.isLoading  = payload
        },
        setDestroyLmsQuestionDetailMessage: (state, {payload}) => {
            state.destroyLmsQuestionDetail.message = payload
        },
        resetDestroyLmsQuestionDetail: (state) => {
            state.destroyLmsQuestionDetail = LMS_QUESTION_INITIAL_STATE.destroyLmsQuestionDetail
        },

        // reducers for filter question list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = LMS_QUESTION_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setLmsQuestionListLoading,
    setLmsQuestionListData,
    setLmsQuestionListMessage,
    resetLmsQuestionList,

    setDestroyLmsQuestionDetailLoading,
    setDestroyLmsQuestionDetailMessage,
    resetDestroyLmsQuestionDetail,

    setFilterProps,
    resetFilterProps
} = lmsQuestion.actions

export default lmsQuestion.reducer