import { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import { FaEye } from 'react-icons/fa';

import PageHeader from 'components/pageHeader/PageHeader';
import FullPageLoader from "components/loader/FullPageLoader";
import SideOverPanel from "components/common-components/SideOverPanel";
import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { pageHeading, orderToolTip, orderHeaderConst, subRowHeader, sortOrderLowToHigh, sortOrderHighToLow } from "pages/auth/orderList/data";

import SearchOrders from './components/SearchOrders';
import OrderSideOverContent from './components/orderSideOverContent/OrderSideOverContent';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getOrderList } from 'redux/order/order.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setOrderDetailPayload, resetOrderList } from 'redux/order/order.slice';
import { isOrderPaidEnum, orderDetailPayload, orderStatusEnum, orderTypeEnum } from 'redux/order/order.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from 'utils/dateTime.utils';
import { currencyRateConversion, generateQueryParams } from 'utils/generators.utils';
import { dataEntriesConst } from 'redux/local/local.const';

const OrderListPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((state) => state.local)
    const { orderList, orderDetail } = useAppState((s) => s.order)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [showEntries, setShowEntries] = useState(dataEntriesConst.TWENTY_FIVE.value)
    const [sideOverPanel, setSideOverPanel] = useState(false)
    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.ORDERS))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        const query = generateQueryParams(location.search)
        dispatcher(getOrderList({ page: query.pn || "1", records: showEntries }))

        return () => {
            dispatcher(resetOrderList())
        }
    }, [location.search, showEntries])

    const onHandleViewOrderDetail = (orderId) => {
        if (orderList?.isLoading || orderDetail?.isLoading) return;

        dispatcher(setOrderDetailPayload({
            ...orderDetailPayload,
            orderId: orderId
        }))
        setSideOverPanel(true)
    }

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortOrderList = (order1, order2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortOrderLowToHigh(activeSortHeader, order1, order2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortOrderHighToLow(activeSortHeader, order1, order2)
        }
    }

    const UserContainer = ({ user }) => {
        return user ? (
            <div className={"flex items-center justify-start gap-0.5"}>
                <Link to={`${pagesInfo?.USERS?.pagePath}/${user?.userId}`}
                    className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
                >
                    {user?.userId + ". "}
                </Link>
                <div className={""}>
                    {user?.first_name + " " + user?.last_name?.charAt(0) + "."}
                </div>
            </div>
        ) : "-"
    }

    const orderRows = orderList?.data?.results?.slice(0, showEntries)?.sort(sortOrderList)?.map((orderItem) => [
        orderItem?.id || "-",
        <UserContainer user={orderItem?.user} />,
        orderTypeEnum[orderItem?.type?.toUpperCase()]?.label || "-",
        currencyRateConversion(locals?.currencyCode, orderItem?.total_amount) || "-",
        isOrderPaidEnum[orderItem?.is_paid?.toUpperCase()]?.label || "-",
        orderStatusEnum[orderItem?.status?.toUpperCase()]?.label || "-",
        dayjs(orderItem?.createdAt)?.tz(timeZone)?.format('dddd, MMM DD, YYYY h:mm A') || "-",
        <div className="flex">
            <button
                className={"p-1 text-sky-400"}
                onClick={() => onHandleViewOrderDetail(orderItem?.id)}
            >
                <FaEye size={20} className={"hover:scale-110"} />
            </button>
        </div>
    ])

    return (
        <div className={"h-full w-full p-5 mx-auto min-h-screen"}>
            <SideOverPanel
                child={<OrderSideOverContent />}
                isOpen={sideOverPanel}
                onClose={(isShow) => setSideOverPanel(isShow)}
            />
            <div className={"space-y-3"}>
                <PageHeader
                    pageHeading={pageHeading}
                    toolTipText={orderToolTip}
                    translateX={"translate-x-5"}
                    translateY={"-translate-y-[20%]"}
                />
                <SearchOrders showEntries={showEntries} setShowEntries={setShowEntries} />
                {orderList?.isLoading &&
                    <FullPageLoader isLoading={orderList?.isLoading} />
                }
                {!orderList?.isLoading &&
                    <div className={'overflow-hidden'}>
                        <Table
                            headers={Object.values(orderHeaderConst)}
                            subRowHeader={subRowHeader}
                            rows={(orderList?.data?.records > 0) ? orderRows : []}
                            rowHeaderColor={"bg-background-medium"}
                            alternateRowColor={"bg-primary-light"}
                            sortHeader={sortHeader}
                        />
                    </div>
                }

                {(orderList?.data && (orderList?.data?.records > 0)) &&
                    <div className={"w-full flex justify-center items-center"}>
                        <Pagination
                            page={orderList?.data?.page}
                            totalPages={orderList?.data?.totalPages}
                            onChangePage={(page) => navigate(`${pagesInfo.ORDERS.pagePath}` + "?pn=" + page)}
                        />
                    </div>
                }
                {orderList?.message &&
                    <div className={"w-full h-full p-5 bg-white flex items-center justify-center font-bodyPri font-semibold text-text-800 text-md"}>
                        {orderList?.message || "No Booking Found!"}
                    </div>
                }
            </div>
        </div>
    );
}

export default OrderListPage;