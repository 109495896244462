import { lmsResourceFilterProps } from "redux/edulyteLms/lmsResource/lmsResource.const";

export const LMS_RESOURCE_INITIAL_STATE = {
    lmsResourceList: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyLmsResourceDetail: {
        isLoading: false,
        message: null,
    },

    filterProps: lmsResourceFilterProps
}