import { dayjs } from "utils/dateTime.utils";

import {
    SearchQuestionId,
    SearchQuestionTitle,
    FilterQuestionType,
    SearchTutor,
    SearchDate,
    ButtonAction,
} from "./components/LmsQuestionFilterStatistics";

export const pageHeading = {
    heading: "Questions",
    subHeading: "Find the list of questions!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const lmsQuestionHeaderConst = {
    QUESTION_ID: {
        label: "Question ID",
        value: "question_id"
    },
    QUESTION_TITLE: {
        label: "Question Title",
        value: "question_title"
    },
    QUESTION_TYPE: {
        label: "Question Type",
        value: "question_type"
    },
    OWNER_TUTOR: {
        label: "Owner Tutor",
        value: "owner_tutor"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
}

export const subRowHeader = [
    <SearchQuestionId />,
    <SearchQuestionTitle />,
    <FilterQuestionType />,
    <SearchTutor />,
    <SearchDate />,
    <ButtonAction />,
]

export const sortQuestionLowToHigh = (activeSortHeader, question1, question2) => {
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.QUESTION_ID?.value) {
        return (Number(question1?.id) - Number(question2?.id))
    }
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.QUESTION_TITLE?.value) {
        return question1?.title?.toLowerCase()?.localeCompare(question2?.title?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.QUESTION_TYPE?.value) {
        return question1?.type?.toLowerCase()?.localeCompare(question2?.type?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.OWNER_TUTOR?.value) {
        return question1?.question_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(question2?.question_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.DATE?.value) {
        return dayjs(question1?.createdAt).isSameOrBefore(question2?.createdAt) ? 1 : -1
    }
}

export const sortQuestionHighToLow = (activeSortHeader, question1, question2) => {
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.QUESTION_ID?.value) {
        return (Number(question2?.id) - Number(question1?.id))
    }
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.QUESTION_TITLE?.value) {
        return question2?.title?.toLowerCase()?.localeCompare(question1?.title?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.QUESTION_TYPE?.value) {
        return question2?.type?.toLowerCase()?.localeCompare(question1?.type?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.OWNER_TUTOR?.value) {
        return question2?.question_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(question1?.question_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuestionHeaderConst?.DATE?.value) {
        return dayjs(question2?.createdAt).isSameOrBefore(question1?.createdAt) ? 1 : -1
    }
}