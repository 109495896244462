import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
import { OptionSelector } from 'components/common-components/Select';

// import { uploadDocumentToS3 } from 'redux/document/document.request';
import { uploadFileToS3 } from 'redux/storage/storage.request';
import { updateOnboardingDocDetailByOnboardingDocId } from 'redux/onboarding/onboarding.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
// import { setUploadDocumentPayload, setClearUploadDocument } from 'redux/document/document.slice';
import { resetUploadFilePayload, resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { setAddOnboardingDocDetailPayload, setClearAddOnboardingDocDetail } from 'redux/onboarding/onboarding.slice';
import { docType, childDocumentStatus } from 'redux/onboarding/onboarding.const';
import { modalConst } from 'redux/local/local.const';

const UploadDocumentModal = () => {
    const { modal } = useAppState((state) => state.local)
    // const { uploadDocument } = useAppState((state) => state.document)
    const { uploadFile } = useAppState((state) => state.storage)
    const { addOnboardingDocDetail, categoryList } = useAppState((s) => s.onboarding)

    const dispatcher = useAppDispatcher()

    const [fileDetail, setFileDetail] = useState(null)

    useEffect(() => {
        if (addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl) {
            setFileDetail({
                file: { name: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl },
                fileUrl: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl,
                status: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.status
            })
        }
    }, [addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl])

    // use effect for close modal
    useEffect(() => {
        if (addOnboardingDocDetail?.addOnboardingDocDetail) {
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: false,
            }))
            dispatcher(setClearAddOnboardingDocDetail())
        }
    }, [addOnboardingDocDetail?.addOnboardingDocDetail])

    const onHandleCategory = (option) => {
        dispatcher(setAddOnboardingDocDetailPayload({
            ...addOnboardingDocDetail?.addOnboardingDocDetailPayload,
            fileCategory: option?.value
        }))
    }

    const onHandleFileUpload = (event) => {
        let selectedFile = event.target.files[0];
        if (!selectedFile || selectedFile.length === 0) {
            alert("file not selected!")
            return;
        }
        if (selectedFile?.size < 2 * 1048576) {
            const url = URL.createObjectURL(selectedFile)
            setFileDetail({
                ...fileDetail,
                fileList: event?.target?.files,
                file: selectedFile,
                fileUrl: url,
            })
            return;
        }
        if (selectedFile?.size > 2 * 1048576) {
            toast.error("File size too large!")
            return;
        }
    }

    const onUploadDocument = (event) => {
        event.preventDefault()
        if (addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && fileDetail?.fileUrl === addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl) {
            const requestData = {
                onboardingDocId: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId,
                body: {
                    docType: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory || Object.values(docType)[0]?.value,
                    docName: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName,
                    docUrl: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl,
                    status: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.status
                }
            }
            dispatcher(updateOnboardingDocDetailByOnboardingDocId(requestData))
            dispatcher(resetUploadFile())
        }
        if (fileDetail?.fileUrl !== addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl) {
            // dispatcher(setUploadFilePayload({
            //     ...uploadFile?.payload,
            //     fileName: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName,
            //     fileType: fileDetail?.file?.type,
            //     file: fileDetail?.file,
            //     fileCategory: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory?.replaceAll("[^a-zA-Z0-9]", "-") || addOnboardingDocDetail?.addOnboardingDocDetailPayload?.categoryList[0]?.value
            // }))
            const requestDataPayload = {
                fileDetail: fileDetail?.fileList,
                fileOwnerId: uploadFile?.payload?.userId
            }

            dispatcher(uploadFileToS3(requestDataPayload))
        }
    }

    return (
        <form onSubmit={onUploadDocument} className={"space-y-5 overflow-hidden"}>
            <div className={"min-h-[24rem] block w-full border border-text-400 space-y-8"}>
                <div className='grid grid-cols-12 gap-5 p-3'>
                    <div className='col-start-0 col-span-full md:col-start-0 md:col-span-6 space-y-2'>
                        <label className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Category"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </label>
                        <OptionSelector
                            options={addOnboardingDocDetail?.addOnboardingDocDetailPayload?.categoryList}
                            placeholder={"Please pick a type"}
                            value={addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory}
                            onChange={(option) => onHandleCategory(option)}
                            required={true}
                        />
                    </div>
                    <div className={"col-start-0 col-span-full md:col-start-0 md:col-span-6 space-y-2"}>
                        <label className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"File Name"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </label>
                        <input
                            type={"text"}
                            rows={2}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            minLength={5}
                            maxLength={50}
                            placeholder={categoryList?.categoryList?.isVideoUploadEnabled ? "example.mp4, example.mkv" : "example.pdf, example.docx"}
                            value={addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName}
                            onChange={(e) => dispatcher(setAddOnboardingDocDetailPayload({
                                ...addOnboardingDocDetail?.addOnboardingDocDetailPayload,
                                fileName: e.target.value
                            }))}
                            required={true}
                            disabled={(addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId && fileDetail?.fileUrl === addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileUrl) && true}
                        />
                    </div>
                    <div className={'col-start-0 col-span-full md:col-start-1 md:col-span-full space-y-2'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm whitespace-nowrap space-x-1'>
                            <span>{"Select File"}</span>
                            <span>{"(maximum 3 MB each)"}</span>
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <label className={'w-full relative inline-flex mx-auto shadow-sm'}>
                            <input
                                type={"file"}
                                style={{ display: "none" }}
                                accept={".doc, .docx,.pdf, application/pdf, application/msword"}
                                onChange={onHandleFileUpload}
                            />
                            <input
                                type={"text"}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-l-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                placeholder={"No File Chosen"}
                                required={true}
                                value={fileDetail?.file?.name}
                            />
                            <span className='p-2 rounded-r-md bg-primary-light border-2 border-primary-light hover:(text-text-50 border-secondary-dark) cursor-pointer font-buttons font-normal text-sm text-primary-dark whitespace-nowrap'
                            >
                                {"Choose a file.."}
                            </span>
                        </label>
                    </div>
                    {fileDetail?.file?.size &&
                        <div className={"col-start-1 col-span-full font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                            {`Selected document size is ${(fileDetail?.file?.size / 1048576)?.toFixed(0)} MB`}
                        </div>
                    }
                    <div className={"col-start-0 col-span-full md:col-start-1 md:col-span-full space-y-2"}>
                        <label className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Status"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </label>
                        <div className={"h-44"}>
                            <OptionSelector
                                className={"w-full"}
                                options={Object.values(childDocumentStatus)}
                                value={fileDetail?.status}
                                onChange={(option) => {
                                    setFileDetail({
                                        ...fileDetail,
                                        status: option.value
                                    })
                                    dispatcher(setAddOnboardingDocDetailPayload({
                                        ...addOnboardingDocDetail?.addOnboardingDocDetailPayload,
                                        status: option.value
                                    }))
                                }}
                            />
                        </div>
                    </div>
                </div>
                {(uploadFile?.message || addOnboardingDocDetail?.errorMsg) &&
                    <div className={"w-full flex items-end justify-center font-bodyPri font-normal text-red-500 text-base"}>
                        {uploadFile?.message || addOnboardingDocDetail?.errorMsg}
                    </div>
                }
            </div>
            <div className={"flex justify-end"}>
                <button
                    type={"submit"}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        (!fileDetail?.fileUrl) && "text-text-300 border border-text-300 cursor-not-allowed",
                        (fileDetail) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        (uploadFile?.isLoading || addOnboardingDocDetail?.isLoading) && "border border-secondary-main bg-secondary-main text-text-50"
                    )}>
                    {(uploadFile?.isLoading || addOnboardingDocDetail?.isLoading) &&
                        <ButtonLoader isLoading={uploadFile?.isLoading || addOnboardingDocDetail?.isLoading} />
                    }
                    {(!uploadFile?.isLoading && !addOnboardingDocDetail?.isLoading) && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default UploadDocumentModal;