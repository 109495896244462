// public pages
import HomePage from "pages/global/home/HomePage";
import PageNotFoundPage from "pages/global/pageNotFound/PageNotFoundPage";
import UnauthorizedPage from "pages/global/unauthorized/UnauthorizedPage";

// private pages
import AddBookingPage from "pages/auth/addBooking/AddBookingPage";
import AppointmentsPage from "pages/auth/appointments/AppointmentsPage";
import BookingsPage from "pages/auth/bookings/list/index";
import CoursesPage from "pages/auth/courses";
import CreateCoursePreviewPage from "pages/auth/createCoursePreview/CreateCoursePreview";
import DashboardPage from "pages/auth/dashboard/DashboardPage";
import EditCompanyOnboardingPage from "pages/auth/editCompanyOnboarding/EditCompanyOnboardingPage";
import EditCourseDetailPage from "pages/auth/editCourseDetail/EditCourseDetailPage";
import EditTutorOnboardPage from "pages/auth/editTutorOnboard/EditTutorOnboard";
import EditTutorPage from "pages/auth/editTutor/EditTutorPage";
import EditUserPage from "pages/auth/editUser/EditUserPage";
import LeadsPage from "pages/auth/leads/LeadsPage";
import LMSCoursePage from "pages/auth/edulyteLms/lmsCourse/LMSCoursePage";
import LMSVideoLecturePage from "pages/auth/edulyteLms/lmsVideoLecture/LMSVideoLecturePage";
import LMSQuizPage from "pages/auth/edulyteLms/lmsQuiz/LMSQuizPage";
import LMSArticlePage from "pages/auth/edulyteLms/lmsArticle/LMSArticlePage";
import LMSQuestionPage from "pages/auth/edulyteLms/lmsQuestion/LMSQuestionPage";
import LMSResourcePage from "pages/auth/edulyteLms/lmsResource/LMSResourcePage";
import MessagePage from "pages/auth/message";
import OnboardingPage from "pages/auth/onboarding/OnboardingPage";
import OrderListPage from "pages/auth/orderList/OrderListPage";
import OtherSettingsFeatures from "pages/auth/otherSettings/OtherSettings";
import ProductPage from "pages/auth/product/ProductPage";
import ProfilePage from 'pages/auth/profile';
import ReferralPage from "pages/auth/referral/ReferralPage";
import SessionsPage from "pages/auth/sessions/SessionsPage";
import Subscriptions from "pages/auth/subscriptions";
import ToolsPage from "pages/auth/tools";
import TutorsPage from "pages/auth/tutors/TutorsPage";
import UsersPage from "pages/auth/users/Users";
import ViewCompanyOnboardingPage from "pages/auth/viewCompanyOnboarding";
import ViewCourseDetailPage from "pages/auth/viewCourseDetail/ViewCourseDetail";
import ViewLeadPage from "pages/auth/viewLeads/ViewLeadPage";
import ViewTutorPage from "pages/auth/viewTutor/ViewTutorPage";
import ViewTutorOnboardPage from "pages/auth/viewTutorOnboard/ViewTutorOnboard";
import ViewUserPage from "pages/auth/viewUser/ViewUserPage";
import WalletPage from "pages/auth/wallet/WalletPage";
import WalletConfirmationPage from "pages/auth/walletConfirmation/WalletConfirmationPage";
import WalletTxnPage from "pages/auth/walletTxn/WalletTxnPage";
import WithdrawalPage from "pages/auth/withdrawal/WithdrawalPage";

import { userRoles } from "redux/auth/auth.const";

export const pagesInfo = {
    HOME: {
        pageName: 'HOME_PAGE',
        pagePath: '/',
        routePath: '/',
        component: HomePage,
        roles: [],
    },
    PAGE_NOT_FOUND: {
        pageName: 'PAGE_NOT_FOUND_PAGE',
        pagePath: '/page-not-found',
        routePath: 'page-not-found',
        component: PageNotFoundPage,
        roles: [],
    },

    // Private pages
    ADD_BOOKING: {
        pageName: 'ADD_BOOKING',
        pagePath: '/add-booking',
        routePath: 'add-booking',
        component: AddBookingPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    APPOINTMENTS: {
        pageName: 'APPOINTMENTS',
        pagePath: '/appointments',
        routePath: 'appointments',
        component: AppointmentsPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    BOOKINGS: {
        pageName: 'BOOKINGS_PAGE',
        pagePath: '/bookings',
        routePath: 'bookings',
        component: BookingsPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    COURSES: {
        pageName: 'COURSES_PAGE',
        pagePath: '/classes',
        routePath: 'classes',
        component: CoursesPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    CREATE_COURSE_PREVIEW: {
        pageName: 'COURSE_PREVIEW',
        pagePath: '/class',
        routePath: '/class/:courseId/preview',
        component: CreateCoursePreviewPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    DASHBOARD: {
        pageName: 'DASHBOARD_PAGE',
        pagePath: '/',
        routePath: '/',
        component: DashboardPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    EDIT_COMPANY: {
        pageName: 'EDIT_COMPANY_PAGE',
        pagePath: '/company-onboarding',
        routePath: 'company-onboarding/:companyId/edit',
        component: EditCompanyOnboardingPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    EDIT_COURSE: {
        pageName: 'EDIT_COURSE_PAGE',
        pagePath: '/classes',
        routePath: 'classes/:courseId/edit/*',
        component: EditCourseDetailPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    EDIT_TUTOR: {
        pageName: 'EDIT_TUTOR',
        pagePath: '/tutors',
        routePath: 'tutors/:tutorId/edit/*',
        component: EditTutorPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    EDIT_TUTOR_ONBOARD: {
        pageName: 'EDIT_TUTOR_ONBOARD',
        pagePath: '/onboarding',
        routePath: 'onboarding/:onboardingId/edit/*',
        component: EditTutorOnboardPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    EDIT_USER: {
        pageName: 'EDIT_USER_PAGE',
        pagePath: '/users',
        routePath: 'users/:userId/edit/*',
        component: EditUserPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    LMS_COURSE: {
        pageName: 'LMS_COURSE_PAGE',
        pagePath: '/lms-courses',
        routePath: 'lms-courses',
        component: LMSCoursePage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    LMS_VIDEO_LECTURE: {
        pageName: 'LMS_VIDEO_LECTURE_PAGE',
        pagePath: '/lms-lectures',
        routePath: 'lms-lectures',
        component: LMSVideoLecturePage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    LMS_QUIZ: {
        pageName: 'LMS_QUIZ_PAGE',
        pagePath: '/lms-quizzes',
        routePath: 'lms-quizzes',
        component: LMSQuizPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    LMS_ARTICLE: {
        pageName: 'LMS_ARTICLE_PAGE',
        pagePath: '/lms-articles',
        routePath: 'lms-articles',
        component: LMSArticlePage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    LMS_QUESTION: {
        pageName: 'LMS_QUESTION_PAGE',
        pagePath: '/lms-questions',
        routePath: 'lms-questions',
        component: LMSQuestionPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    LMS_RESOURCE: {
        pageName: 'LMS_RESOURCE_PAGE',
        pagePath: '/lms-resources',
        routePath: 'lms-resources',
        component: LMSResourcePage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    LEADS: {
        pageName: 'LEADS_PAGE',
        pagePath: '/leads',
        routePath: 'leads',
        component: LeadsPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    MESSAGES: {
        pageName: 'MESSAGES_PAGE',
        pagePath: '/message',
        routePath: 'message',
        component: MessagePage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    ONBOARDING: {
        pageName: 'ONBOARDING',
        pagePath: '/onboarding',
        routePath: 'onboarding',
        component: OnboardingPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    ORDERS: {
        pageName: 'ORDERS',
        pagePath: '/orders',
        routePath: 'orders',
        component: OrderListPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    OTHER_SETTINGS: {
        pageName: 'OTHER_SETTINGS',
        pagePath: '/platform-settings',
        routePath: 'platform-settings/*',
        component: OtherSettingsFeatures,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    PRODUCT: {
        pageName: 'PRODUCT_PAGE',
        pagePath: '/product',
        routePath: 'product',
        component: ProductPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    PROFILE: {
        pageName: 'PROFILE_PAGE',
        pagePath: '/profile',
        routePath: 'profile',
        component: ProfilePage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    REFERRAL: {
        pageName: 'REFERRAL_PAGE',
        pagePath: '/referral',
        routePath: 'referral',
        component: ReferralPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    SESSIONS: {
        pageName: 'SESSIONS_PAGE',
        pagePath: '/sessions',
        routePath: 'sessions',
        component: SessionsPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    SUBSCRIPTIONS: {
        pageName: 'SUBSCRIPTIONS_PAGE',
        pagePath: '/subscriptions',
        routePath: 'subscriptions',
        component: Subscriptions,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    TOOLS: {
        pageName: 'TOOLS_PAGE',
        pagePath: '/tools',
        routePath: 'tools',
        component: ToolsPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    TUTORS: {
        pageName: 'TUTORS_PAGE',
        pagePath: '/tutors',
        routePath: 'tutors',
        component: TutorsPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    UNAUTHORIZED: {
        pageName: 'UNAUTHORIZED_PAGE',
        pagePath: '/unauthorized',
        routePath: 'unauthorized',
        component: UnauthorizedPage,
        roles: Object.keys(userRoles)
    },
    USERS: {
        pageName: 'USERS_PAGE',
        pagePath: '/users',
        routePath: 'users',
        component: UsersPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    VIEW_COMPANY_ONBOARDING: {
        pageName: 'View_COMPANY_ONBOARDING',
        pagePath: '/onboarding',
        routePath: 'onboarding/company/:companyId',
        component: ViewCompanyOnboardingPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    VIEW_COURSE: {
        pageName: 'VIEW_COURSE',
        pagePath: '/classes',
        routePath: 'classes/:courseId/*',
        component: ViewCourseDetailPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    VIEW_LEAD: {
        pageName: 'VIEW_LEAD',
        pagePath: '/leads',
        routePath: 'leads/:leadId',
        component: ViewLeadPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    VIEW_TUTOR: {
        pageName: 'VIEW_TUTOR',
        pagePath: '/tutors',
        routePath: 'tutors/:tutorId/*',
        component: ViewTutorPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    VIEW_TUTOR_ONBOARD: {
        pageName: 'VIEW_TUTOR_ONBOARD',
        pagePath: '/onboarding',
        routePath: 'onboarding/:onboardingId/*',
        component: ViewTutorOnboardPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    VIEW_USER: {
        pageName: 'VIEW_USER',
        pagePath: '/users',
        routePath: 'users/:userId/*',
        component: ViewUserPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    WALLET: {
        pageName: 'WALLET_PAGE',
        pagePath: '/wallet',
        routePath: 'wallet',
        component: WalletPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    WALLET_CONFIRMATION: {
        pageName: 'WALLET_CONFIRMATION_PAGE',
        pagePath: '/wallet/confirmation',
        routePath: 'wallet/confirmation',
        component: WalletConfirmationPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    WALLET_TXNS: {
        pageName: 'WALLET_TXNS_PAGE',
        pagePath: '/wallet-txns',
        routePath: 'wallet-txns',
        component: WalletTxnPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    WITHDRAWALS: {
        pageName: 'WITHDRAWALS_PAGE',
        pagePath: '/withdrawals',
        routePath: 'withdrawals',
        component: WithdrawalPage,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    }
}

export const sideTopBarPagesName = [
    pagesInfo.APPOINTMENTS.pageName,
    pagesInfo.BOOKINGS.pageName,
    pagesInfo.COURSES.pageName,
    pagesInfo.DASHBOARD.pageName,
    pagesInfo.EDIT_COURSE.pageName,
    pagesInfo.EDIT_TUTOR.pageName,
    pagesInfo.EDIT_TUTOR_ONBOARD.pageName,
    pagesInfo.EDIT_USER.pageName,
    pagesInfo.LEADS.pageName,
    pagesInfo.LMS_COURSE.pageName,
    pagesInfo.LMS_VIDEO_LECTURE.pageName,
    pagesInfo.LMS_QUIZ.pageName,
    pagesInfo.LMS_ARTICLE.pageName,
    pagesInfo.LMS_QUESTION.pageName,
    pagesInfo.LMS_RESOURCE.pageName,
    pagesInfo.MESSAGES.pageName,
    pagesInfo.ONBOARDING.pageName,
    pagesInfo.ORDERS.pageName,
    pagesInfo.OTHER_SETTINGS.pageName,
    pagesInfo.PRODUCT.pageName,
    pagesInfo.PROFILE.pageName,
    pagesInfo.REFERRAL.pageName,
    pagesInfo.SESSIONS.pageName,
    pagesInfo.SUBSCRIPTIONS.pageName,
    pagesInfo.TOOLS.pageName,
    pagesInfo.TUTORS.pageName,
    pagesInfo.USERS.pageName,
    pagesInfo.VIEW_COURSE.pageName,
    pagesInfo.VIEW_LEAD.pageName,
    pagesInfo.VIEW_TUTOR.pageName,
    pagesInfo.VIEW_TUTOR_ONBOARD.pageName,
    pagesInfo.VIEW_USER.pageName,
    pagesInfo.WALLET.pageName,
    pagesInfo.WALLET_TXNS.pageName,
    pagesInfo.WITHDRAWALS.pageName,
]

export const edulyteAppPagesInfo = {
    DASHBOARD: {
        pagePath: "/dashboard"
    },
    EDIT_LMS_COURSE: {
        pagePath: '/lms/courses'
    },
    EDIT_LMS_QUIZ: {
        pagePath: '/lms/quizzes'
    },
    EDIT_LMS_QUESTION: {
        pagePath: '/lms/questions'
    },
    EDIT_LMS_ARTICLE: {
        pagePath: '/lms/articles'
    },
    EDIT_LMS_LECTURE: {
        pagePath: '/lms/lectures'
    },
    EDIT_LMS_RESOURCE: {
        pagePath: '/lms/resources'
    },
    EDIT_PRODUCT: {
        pagePath: '/products'
    },
    EDIT_APPOINTMENT: {
        pagePath: '/appointments/{appointmentId}/edit'
    },
    PUBLIC_APPOINTMENT: {
        pagePath: '/appointment/{slug}'
    },
    CALENDAR: {
        pagePath: "/calendar"
    },
    CALENDAR_MONTH: {
        pagePath: "/calendar/month"
    }
}