import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from "components/common-components/Select";

import ViewTutorHeader from "pages/auth/viewTutor/commonComponents/ViewTutorHeader";

import { getTutorConfigDetail } from "redux/tutor/tutor.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { masterCurrencyCodeEnum } from "redux/master/master.const";
import { resetTutorConfigDetail, resetUpdateTutorConfigDetail } from "redux/tutor/tutor.slice";

const ViewTutorConfigPage = () => {
    const { tutorConfigDetail, updateTutorConfig } = useAppState((state) => state.tutor)


    const dispatcher = useAppDispatcher()
    const { tutorId } = useParams()

    useEffect(() => {
        return () => {
            dispatcher(resetTutorConfigDetail())
            dispatcher(resetUpdateTutorConfigDetail())
        }
    }, [])

    useEffect(() => {
        if (tutorId) {
            dispatcher(getTutorConfigDetail(Number(tutorId)))
        }
    }, [tutorId])

    return (
        <div className={""}>
            <ViewTutorHeader title={"Tutor Config"} />
            
            <div className={"w-full p-5 space-y-5"}>

                {(tutorConfigDetail?.isLoading || updateTutorConfig?.isLoading) &&
                    <ComponentLoader isLoading={tutorConfigDetail?.isLoading || updateTutorConfig?.isLoading} />
                }

                {((!tutorConfigDetail?.isLoading && !updateTutorConfig?.isLoading) && (tutorConfigDetail?.message || updateTutorConfig?.message)) &&
                    <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                        {tutorConfigDetail?.message || updateTutorConfig?.message}
                    </div>
                }
                {((!tutorConfigDetail?.isLoading && !updateTutorConfig?.isLoading) && tutorConfigDetail?.data) &&
                    <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                {"Fixed Admin Commission (in %)"}
                            </span>
                            <input
                                type={"number"}
                                value={tutorConfigDetail?.data?.adminFixedPctCommission?.toString()}
                                className={cn(
                                    "w-full md:w-72 px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                disabled={true}
                            />
                        </div>

                        {/* variable admin  commission pct */}
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                {"Variable Admin Commission (in %)"}
                            </span>
                            <input
                                type={"number"}
                                value={tutorConfigDetail?.data?.adminVariablePctCommission?.toString()}
                                className={cn(
                                    "w-full md:w-72 px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                disabled={true}
                            />
                        </div>

                        {/* currency code */}
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                {"Currency"}
                            </span>
                            <OptionSelector
                                options={Object.values(masterCurrencyCodeEnum)}
                                className={"w-full md:w-72"}
                                value={tutorConfigDetail?.data?.masterCurrency?.code}
                                disabled={true}
                            />
                        </div>


                        {/* fixed price */}
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                {"Fixed Price"}
                            </span>
                            <input
                                type={"number"}
                                value={tutorConfigDetail?.data?.fixedPrice?.toString()}
                                className={cn(
                                    "w-full md:w-72 px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                disabled={true}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewTutorConfigPage;