export const url = {
    GET_LINK_IN_BIO_LIST: "linktrees/linktrees",
    CREATE_LINK_IN_BIO: "linktrees/linktrees",

    ADD_LINK: "linktrees/links",
    UPDATE_LINK: "/linktrees/links/{linkId}",
    REORDER_LINK: "linktrees/links/{linkTreeId}/order",
    DELETE_LINK: "/linktrees/links/{linkId}",
}

export const DEFAULT_LINK_IN_BIO_LIST_PAGE = 1;
export const DEFAULT_LINK_IN_BIO_LIST_RECORDS = 10;