import { cn } from "utils/cn.utils";

import SaveAndCancelBtn from "pages/auth/editCompanyOnboarding/components/saveAndCancelBtn/SaveAndCancelBtn";

import { getCompanyPublicProfileByCompanyId, updateCompanyPublicProfileByCompanyId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setCompanyPublicProfile } from "redux/onboarding/onboarding.slice";

const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700

const EditAbout = () => {
    const { companyPublicProfile } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()

    const handleSaveCompanyAbout = () => {
        dispatcher(updateCompanyPublicProfileByCompanyId(companyPublicProfile?.companyPublicProfile))
    }

    const handleCancelButton = () => {
        dispatcher(getCompanyPublicProfileByCompanyId())
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"ABOUT"}>
            <div className={"relative max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"p-5 space-y-3 bg-white"}>
                    <div className={"flex items-center justify-start gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"About"}
                        </span>
                    </div>

                    <div className="w-full flex flex-col items-start justify-start gap-2">
                        <div className="w-full flex flex-col justify-center gap-1">
                            <textarea
                                className={cn(
                                    "w-full px-3 py-2 resize-none rounded-md border-2 border-divider-medium focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                rows={5}
                                minLength={MIN_CHARACTER_LENGTH}
                                maxLength={MAX_CHARACTER_LENGTH}
                                value={companyPublicProfile?.companyPublicProfile?.bio}
                                onChange={(event) => dispatcher(setCompanyPublicProfile({
                                    ...companyPublicProfile?.companyPublicProfile,
                                    bio: event.target.value
                                }))}
                            />
                        </div>
                        <div className={"w-full flex justify-between"}>
                            <span className="font-bodyPri font-normal tracking-wide text-red-500 text-xs">
                                {"Please enter between 50 and 700 characters"}
                            </span>
                            <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                                {companyPublicProfile?.companyPublicProfile?.bio?.length + "/" + MAX_CHARACTER_LENGTH}
                            </span>
                        </div>

                        <SaveAndCancelBtn
                            onCancel={() => handleCancelButton()}
                            onSave={() => handleSaveCompanyAbout()}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EditAbout;