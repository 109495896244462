import { useEffect } from 'react'
import { toast } from "react-toastify";

import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';

import { getDateTimeSlotFromDay, validateDateTimeSlots } from 'components/modals/editUserAvailabilityModal/data';
import RecurringAvailabilitySchedule from 'components/modals/editUserAvailabilityModal/recurringAvailabilitySchedule/RecurringAvailabilitySchedule';
import NonRecurringAvailabilitySchedule from 'components/modals/editUserAvailabilityModal/nonRecurringAvailabilitySchedule/NonRecurringAvailabilitySchedule';

import { getAvailabilityDetail, createAvailabilityDetail, updateAvailabilityDetail } from 'redux/availability/availability.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddAvailabilityDetail_Payload } from 'redux/availability/availability.slice';
import { availabilityEndTypeEnum, availabilityIsRecurringEnum, availabilityTypeEnum } from 'redux/availability/availability.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const EditUserAvailabilityModal = () => {
    const { availabilityDetail, addAvailabilityDetail } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const isRecurringAvailabilitySimilar = JSON.stringify(availabilityDetail?.data?.result) === JSON.stringify(addAvailabilityDetail?.payload?.recurringAvailability)
    const isNonRecurringAvailabilitySimilar = JSON.stringify(availabilityDetail?.data?.result) === JSON.stringify(addAvailabilityDetail?.payload?.nonRecurringAvailability)

    const isOneOnOneAvailType = availabilityDetail?.data?.result?.type === availabilityTypeEnum?.ONE_ON_ONE?.value
    const isGroupAvailType = availabilityDetail?.data?.result?.type === availabilityTypeEnum?.GROUP?.value
    const isRecurringAvailability = availabilityDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.RECURRING?.value
    const isNonRecurringAvailability = availabilityDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.NON_RECURRING?.value

    useEffect(() => {
        if (addAvailabilityDetail?.payload?.availabilityId) {
            const requestDataPayload = {
                availabilityId: addAvailabilityDetail?.payload?.availabilityId,
                query: { timeZone: timeZone }
            }
            dispatcher(getAvailabilityDetail(requestDataPayload))
        }
    }, [addAvailabilityDetail?.payload?.availabilityId])

    useEffect(() => {
        if (availabilityDetail?.data?.result) {
            if (availabilityDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.RECURRING?.value) {
                dispatcher(setAddAvailabilityDetail_Payload({ ...addAvailabilityDetail?.payload, recurringAvailability: availabilityDetail?.data?.result }))
            } else {
                dispatcher(setAddAvailabilityDetail_Payload({ ...addAvailabilityDetail?.payload, nonRecurringAvailability: availabilityDetail?.data?.result }))
            }
        }
    }, [availabilityDetail?.data?.result])

    const onHandleReset = () => {
        if (addAvailabilityDetail?.payload?.availabilityId) {
            const requestDataPayload = {
                availabilityId: addAvailabilityDetail?.payload?.availabilityId,
                query: { timeZone: timeZone }
            }
            dispatcher(getAvailabilityDetail(requestDataPayload))
        } else {
            alert("Something went wrong!")
        }
    }

    const onHandleSubmit = async () => {
        if (addAvailabilityDetail?.isLoading || isRecurringAvailabilitySimilar || isNonRecurringAvailabilitySimilar) return;

        if (isOneOnOneAvailType && isRecurringAvailability) {
            if (!addAvailabilityDetail?.payload?.recurringAvailability?.minTimeBeforeAvailStart) {
                toast.error("Please enter minimum notice period!")
                return;
            }
            if (!addAvailabilityDetail?.payload?.recurringAvailability?.advanceSlotPeriodDays) {
                toast.error("Please enter maximum advance booking time!")
                return;
            }
        }

        if (isGroupAvailType && isNonRecurringAvailability) {
            if (!addAvailabilityDetail?.payload?.nonRecurringAvailability?.duration || addAvailabilityDetail?.payload?.nonRecurringAvailability?.duration < 1) {
                toast.error("Please enter session duration!")
                return;
            }
            if (addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots?.length === 0) {
                alert("Please add date time slots!")
                return;
            }

            const isDateTimeSlotsValid = await validateDateTimeSlots(addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots)

            if (!isDateTimeSlotsValid) {
                alert("Each date must have a time slot!")
                return;
            }
        }

        if (isGroupAvailType && isRecurringAvailability) {
            if (!addAvailabilityDetail?.payload?.recurringAvailability?.duration || addAvailabilityDetail?.payload?.recurringAvailability?.duration < 1) {
                toast.error("Please enter session duration!")
                return;
            }
        }

        if ((addAvailabilityDetail?.payload?.recurringAvailability?.endType === availabilityEndTypeEnum?.ON_DATE?.value) && !addAvailabilityDetail?.payload?.recurringAvailability?.endDateTime) {
            toast.error("Please enter end date for the course!")
            return;
        }
        if ((addAvailabilityDetail?.payload?.recurringAvailability?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && !addAvailabilityDetail?.payload?.recurringAvailability?.weeklyCycle) {
            toast.error("Please enter weekly cycle count!")
            return;
        }


        let newStartDateTime = addAvailabilityDetail?.payload?.recurringAvailability?.startDateTime
        let newEndDateTime = addAvailabilityDetail?.payload?.recurringAvailability?.endDateTime

        newStartDateTime = await getDateTimeSlotFromDay(addAvailabilityDetail?.payload?.recurringAvailability?.weeklyTimeSlots)


        if (isGroupAvailType && isRecurringAvailability) {

            if (addAvailabilityDetail?.payload?.recurringAvailability?.startDateTime) {
                const startDayName = dayjs(addAvailabilityDetail?.payload?.recurringAvailability?.startDateTime)?.tz(timeZone)?.format('dddd')

                const startDayTimeSlot = addAvailabilityDetail?.payload?.recurringAvailability?.weeklyTimeSlots[startDayName?.slice(0, 3)?.toUpperCase()]
                if (startDayTimeSlot?.length === 0) {
                    alert("You must have a session on the start date. Please update")
                    return;
                } else {
                    const startDateTimeFormat = dayjs(addAvailabilityDetail?.payload?.recurringAvailability?.startDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + startDayTimeSlot[0]?.startTime
                    newStartDateTime = dayjs(startDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
                }
            }

            if (addAvailabilityDetail?.payload?.recurringAvailability?.endType === availabilityEndTypeEnum?.ON_DATE?.value) {
                const endDayName = dayjs(addAvailabilityDetail?.payload?.recurringAvailability?.endDateTime)?.tz(timeZone)?.format('dddd')

                const endDayTimeSlot = addAvailabilityDetail?.payload?.recurringAvailability?.weeklyTimeSlots[endDayName?.slice(0, 3)?.toUpperCase()]
                if (endDayTimeSlot?.length === 0) {
                    alert("You must have a session on the end date. Please update")
                    return;
                } else {
                    const endDateTimeFormat = dayjs(addAvailabilityDetail?.payload?.recurringAvailability?.endDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + endDayTimeSlot[0]?.endTime
                    newEndDateTime = dayjs(endDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
                }
            }
        }

        const body = {
            userId: availabilityDetail?.data?.result?.user?.id,
            name: availabilityDetail?.data?.result?.name,
            description: availabilityDetail?.data?.result?.description,
            type: availabilityDetail?.data?.result?.type,
            isRecurring: availabilityDetail?.data?.result?.isRecurring,
            timeZone: timeZone
        }

        if (isGroupAvailType) {
            body["duration"] = addAvailabilityDetail?.payload?.nonRecurringAvailability?.duration * 15
            if (isNonRecurringAvailability) {
                body["dateTimeSlots"] = addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots
            }
        }

        if (isOneOnOneAvailType && isRecurringAvailability) {
            body["minTimeBeforeAvailStart"] = addAvailabilityDetail?.payload?.recurringAvailability?.minTimeBeforeAvailStart
            body["advanceSlotPeriodDays"] = addAvailabilityDetail?.payload?.recurringAvailability?.advanceSlotPeriodDays
        }

        if (isRecurringAvailability) {
            body["weeklyTimeSlots"] = addAvailabilityDetail?.payload?.recurringAvailability?.weeklyTimeSlots
            body["startDateTime"] = newStartDateTime
            body["endType"] = addAvailabilityDetail?.payload?.recurringAvailability?.endType
            if (addAvailabilityDetail?.payload?.recurringAvailability?.endType === availabilityEndTypeEnum?.ON_DATE?.value) {
                body["endDateTime"] = newEndDateTime
            }
            if (addAvailabilityDetail?.payload?.recurringAvailability?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) {
                body["weeklyCycle"] = addAvailabilityDetail?.payload?.recurringAvailability?.weeklyCycle
            }
        }


        if (availabilityDetail?.data?.result?.id && (isGroupAvailType || isOneOnOneAvailType)) {
            const requestDataPayload = {
                availabilityId: availabilityDetail?.data?.result?.id,
                body: body
            }
            dispatcher(updateAvailabilityDetail(requestDataPayload))
        }

        if (!availabilityDetail?.data?.result?.id && isOneOnOneAvailType) {
            dispatcher(createAvailabilityDetail(body))
        }

    }

    if (availabilityDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={availabilityDetail?.isLoading} className={"w-full min-h-[24rem]"} />
        )
    }

    return (
        <div className="space-y-5 overflow-x-hidden">
            <div className={cn(
                "flex flex-col w-full max-h-[48rem] overflow-hidden overflow-y-scroll space-y-5 pl-2 pr-5 transition ease-in-out delay-150 duration-300",
                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
            )}>

                <div className={"w-full flex items-center justify-center"}>
                    <div className={"flex flex-col items-start justify-start gap-0.5"}>
                        <span className={"truncate text-center font-bodyPri font-medium text-lg text-text-900"}>
                            {availabilityDetail?.data?.result?.name.substring(0, 50) + (availabilityDetail?.data?.result?.name.length > 50 ? "..." : "")}
                        </span>
                        <div className={"h-0.5 w-full border border-divider-medium"}></div>
                    </div>
                </div>

                {availabilityDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.RECURRING?.value &&
                    <RecurringAvailabilitySchedule />
                }
                {availabilityDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.NON_RECURRING?.value &&
                    <NonRecurringAvailabilitySchedule />
                }
            </div>
            <div className={"w-full flex items-center justify-between gap-5"}>
                {addAvailabilityDetail?.message &&
                    <span className={"w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-left truncate"}>
                        {addAvailabilityDetail?.message}
                    </span>
                }
                <div className={"w-full flex justify-end gap-5"}>
                    <span
                        className={cn(
                            "w-28 py-1 flex justify-center items-center rounded-full",
                            "font-buttons font-normal text-base",
                            "text-divider-lightDark border border-divider-lightDark hover:bg-divider-lightDark hover:text-text-50 cursor-pointer",
                            availabilityDetail?.isLoading && "bg-divider-lightDark"
                        )}
                        onClick={onHandleReset}
                    >
                        {availabilityDetail?.isLoading && <ButtonLoader isLoading={availabilityDetail?.isLoading} />}
                        {!availabilityDetail?.isLoading && "Reset"}
                    </span>
                    <span
                        className={cn(
                            "w-28 py-1 flex justify-center items-center rounded-full",
                            "font-buttons font-normal text-base",
                            (isRecurringAvailabilitySimilar || isNonRecurringAvailabilitySimilar) && "bg-text-300 text-white cursor-not-allowed",
                            (!isRecurringAvailabilitySimilar && !isNonRecurringAvailabilitySimilar) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                            addAvailabilityDetail?.isLoading && "bg-secondary-main"
                        )}
                        onClick={onHandleSubmit}
                    >
                        {addAvailabilityDetail?.isLoading && <ButtonLoader isLoading={addAvailabilityDetail?.isLoading} />}
                        {!addAvailabilityDetail?.isLoading && "Submit"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default EditUserAvailabilityModal;