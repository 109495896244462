import { dayjs } from "utils/dateTime.utils";

import {
    TutorImage,
    SearchTutorId,
    SearchUserId,
    SearchTutorName,
    SearchEmail,
    FilterTutorStatus,
    FilterTutorVisibility,
    SearchDate,
    ButtonAction
} from "pages/auth/tutors/components/TutorFilterStatistics";

export const pageHeading = {
    heading: "Tutors",
    subHeading: "Find the list of tutors!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-tutors.svg"
}

export const tutorHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    TUTOR_ID: {
        label: "Tutor ID",
        value: "tutor_id"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    TUTOR_NAME: {
        label: "Tutor Name",
        value: "tutor_name"
    },
    // PHONE: {
    //     label: "Phone",
    //     value: "phone"
    // },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    VISIBILITY: {
        label: "Visibility",
        value: "visibility"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const subRowHeader = [
    <TutorImage />,
    <SearchTutorId />,
    <SearchUserId />,
    <SearchTutorName />,
    <SearchEmail />,
    <FilterTutorStatus />,
    <FilterTutorVisibility />,
    <SearchDate />,
    <ButtonAction />
]

export const tutorMenuData = {
    ADD_CLASS: {
        label: "Add Class",
        value: "add_class"
    },
    LOGIN_AS: {
        label: "Login As",
        value: "login_as"
    }
}

export const sortTutorLowToHigh = (activeSortHeader, tutor1, tutor2) => {
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.TUTOR_ID?.value) {
        return (Number(tutor1?.id) - Number(tutor2?.id))
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.USER_ID?.value) {
        return (Number(tutor1?.user?.id) - Number(tutor2?.user?.id))
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.TUTOR_NAME?.value) {
        return tutor1?.user?.firstName?.toLowerCase()?.localeCompare(tutor2?.user?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.EMAIL?.value) {
        return tutor1?.user?.email?.toLowerCase()?.localeCompare(tutor2?.user?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.STATUS?.value) {
        return tutor1?.status?.toLowerCase()?.localeCompare(tutor2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.VISIBILITY?.value) {
        return tutor1?.visibility?.toLowerCase()?.localeCompare(tutor2?.visibility?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.DATE?.value) {
        return dayjs(tutor2?.createdAt).isSameOrBefore(tutor1?.createdAt) ? 1 : -1
    }
}

export const sortTutorHighToLow = (activeSortHeader, tutor1, tutor2) => {
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.TUTOR_ID?.value) {
        return (Number(tutor2?.id) - Number(tutor1?.id))
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.USER_ID?.value) {
        return (Number(tutor2?.user?.id) - Number(tutor1?.user?.id))
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.TUTOR_NAME?.value) {
        return tutor2?.user?.firstName?.toLowerCase()?.localeCompare(tutor1?.user?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.PHONE?.value) {
        return tutor2?.mobileNo?.localeCompare(tutor1?.mobileNo)
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.EMAIL?.value) {
        return tutor2?.user?.email?.toLowerCase()?.localeCompare(tutor1?.user?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.STATUS?.value) {
        return tutor2?.status?.toLowerCase()?.localeCompare(tutor1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.VISIBILITY?.value) {
        return tutor2?.visibility?.toLowerCase()?.localeCompare(tutor1?.visibility?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorHeaderConst?.DATE?.value) {
        return dayjs(tutor1?.createdAt).isSameOrBefore(tutor2?.createdAt) ? 1 : -1
    }
}