import { withdrawalFilterProps } from "redux/withdrawal/withdrawal.const"

const withdrawalDetailPayload = {
    withdrawalId: null
}
const txnDetailPayload = {
    isApproved: false,
    isRejected: false,
    isGetApproval: false,
    isGetRejection: false
}

export const WITHDRAWAL_INITIAL_STATE = {
    withdrawalList: {
        isLoading: false,
        withdrawalList: null,
        filteredWithdrawalList: null,
        errorMsg: null
    },
    withdrawalDetail: {
        isLoading: false,
        withdrawalDetail: null,
        withdrawalDetailPayload: withdrawalDetailPayload,
        errorMsg: null
    },
    txnDetail: {
        isLoading: false,
        txnDetail: null,
        txnDetailPayload: txnDetailPayload,
        errorMsg: null,
    },

    updateWithdrawalDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    filterProps: withdrawalFilterProps
}