import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ComponentLoader from 'components/loader/ComponentLoader';

import ViewUserHeader from 'pages/auth/viewUser/commonComponents/header/ViewUserHeader';

import { getUserBadgeListByUserId } from "redux/badge/badge.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearUserBadgeList } from "redux/badge/badge.slice";
import { badgeVisibility } from 'redux/badge/badge.const';

const ViewUserBadges = () => {
    const { userBadgeList } = useAppState((state) => state.badge)

    const dispatcher = useAppDispatcher()
    const { userId } = useParams()

    useEffect(() => {
        if (userId) {
            dispatcher(getUserBadgeListByUserId(Number(userId)))
        }

        return () => {
            dispatcher(setClearUserBadgeList())
        }
    }, [userId])

    return (
        <div className={"w-full h-full space-y-3"}>

            <ViewUserHeader title={"User Badges"} />

            {userBadgeList?.isLoading &&
                <ComponentLoader isLoading={userBadgeList?.isLoading} />
            }
            
            {!userBadgeList?.isLoading &&
                <div className={"w-full sm:w-[60%] p-5 space-y-5"}>
                    {(userBadgeList?.filteredUserBadgeList?.length > 0) && userBadgeList?.filteredUserBadgeList?.map((badge, index) => (
                        <div
                            key={index}
                            className={"w-full flex flex-row items-start justify-between gap-5 border border-text-400 rounded-lg px-5 py-2"}>
                            <div className={"flex items-center justify-start gap-5"}>
                                <div className={"relative w-16 h-16 rounded-full sm:w-24 sm:h-24"}>
                                    <img
                                        src={badge?.badge?.badgePicUrl}
                                        alt={badge?.badge?.tooltip}
                                        className={"w-full h-full rounded-full p-2 object-cover"}
                                    />
                                </div>

                                <div className="flex flex-col justify-center items-center md:justify-start md:items-start gap-2">

                                    <div className={"flex items-center justify-start gap-2 font-bodyPri font-normal tracking-wide"}>
                                        <span className={"text-text-700 text-base"}>
                                            {"Badge Name:"}
                                        </span>
                                        <span className="text-text-900 text-base capitalize">
                                            {badge?.badge?.badge || "-"}
                                        </span>
                                    </div>

                                    <div className={"flex items-center justify-start gap-2 font-bodyPri font-normal tracking-wide"}>
                                        <span className="text-text-700 text-base">
                                            {"badge Category:"}
                                        </span>
                                        <span className="text-text-900 text-base capitalize">
                                            {badge?.badge?.badgeCategory?.badgeCategory}
                                        </span>
                                    </div>

                                    <div className={"flex items-center justify-start gap-2 font-bodyPri font-normal tracking-wide"}>
                                        <span className="text-text-700 text-base">
                                            {"Visibility:"}
                                        </span>
                                        <span className={"text-text-900 text-base"}>
                                            {badgeVisibility[badge?.visibility?.toUpperCase()]?.label}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {(userBadgeList?.errorMsg || userBadgeList?.filteredUserBadgeList?.length === 0) &&
                        <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {userBadgeList?.errorMsg || "No Badges Added!"}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default ViewUserBadges