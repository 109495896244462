import { useLocation } from "react-router-dom";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { companyOnboardingHeaderConst } from "pages/auth/onboarding/data";

import { getOnboardingList } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/onboarding/onboarding.slice";
import { onboardingTypeConst } from "redux/onboarding/onboarding.const";
import { companyOnboardingStatus } from "redux/company/company.const";
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";
import { generateQueryParams } from "utils/generators.utils";

const CompanyOnboardingSearch = ({ showEntries, setShowEntries, activeTab, setActiveTab }) => {
    const { companyList, filterProps } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const header = Object.values(companyOnboardingHeaderConst)?.filter((header) => header?.value !== companyOnboardingHeaderConst.ACTION.value)?.map((head) => head?.label)

    const body = companyList?.companyList?.map((company) => ({
        [companyOnboardingHeaderConst?.COMPANY_ID?.label]: company?.companyId,
        [companyOnboardingHeaderConst?.COMPANY_NAME?.label]: company?.firstName,
        [companyOnboardingHeaderConst?.EMAIL?.label]: company?.email?.toLowerCase(),
        [companyOnboardingHeaderConst?.STATUS?.label]: companyOnboardingStatus[company?.status?.toUpperCase()].label,
        [companyOnboardingHeaderConst?.DATE?.label]: dayjs(company?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A')
    }))
    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-comapnies",
            sheet: "company",
            tablePayload: {
                header,
                body: companyList?.companyList || []
            }
        })
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value },
        }))
    }
    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 p-5 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={showEntries}
                    onChange={(option) => setShowEntries(option.value)}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <OptionSelector
                options={Object.values(onboardingTypeConst)}
                className={"w-full"}
                value={activeTab}
                onChange={(option) => {
                    const query = generateQueryParams(location.search)
                    dispatcher(getOnboardingList({ page: query.pn || 1, records: showEntries, onboardingType: option.value }))
                    setActiveTab(option.value)
                }}
            />
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={companyList?.companyList || []}
                    filename={"Admin Edulyte Panel-comapnies"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here ..."}
                        value={filterProps.searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={(event) => handleFilterSearch(event)}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyOnboardingSearch