import { memo, useCallback, useEffect, useState } from 'react';

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';

import FilterBox from 'components/pageFilter/FilterBox';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setFilterProps } from 'redux/availability/availability.slice';
import { DEFAULT_AVAILABILITY_LIST_PAGE } from 'redux/availability/availability.const';

import { cn } from 'utils/cn.utils';

const AvailabilityNameFilter = memo(({ filterItem, activeFilter, setActiveFilter }) => {
    const { filterProps } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const [titlePayload, setTitlePayload] = useState({
        title: "",
        message: ""
    })

    useEffect(() => {
        if (!!filterProps?.name) {
            setTitlePayload({
                ...titlePayload,
                title: filterProps?.name?.replaceAll("-", " "),
                message: ""
            })
        } else {
            setTitlePayload({
                message: "",
                title: ""
            })
        }
    }, [filterProps?.name])

    const onHandleMouseLeave = () => {
        if (!filterProps?.name) {
            setTitlePayload({
                message: "",
                title: ""
            })
        }
        setActiveFilter(null)
    }

    const onHandleKeyDown = (event) => {
        if ((event.keyCode === 13) && titlePayload?.title) {
            onHandleApplyFilter()
        }
    }

    const onHandleApplyFilter = useCallback(() => {
        if (!titlePayload?.title) {
            setTitlePayload({
                ...titlePayload,
                message: "Please enter title!"
            })
            return;
        }
        setTitlePayload({
            ...titlePayload,
            message: ""
        })
        dispatcher(setFilterProps({
            filterProps: {
                ...filterProps,
                page: DEFAULT_AVAILABILITY_LIST_PAGE,
                name: titlePayload?.title?.replaceAll(" ", "-")
            }
        }))
        setActiveFilter(null)
    }, [titlePayload, filterProps])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setTitlePayload({
            ...titlePayload,
            title: "",
            message: ""
        })

        dispatcher(setFilterProps({ filterProps: { ...filterProps, name: "" } }))
        setActiveFilter(null)
    }, [filterProps])

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={!!filterProps?.name
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            BoxItemContent={() => {
                return !!filterProps?.name && (
                    <>
                        <div className={"h-[20px] border border-divider-lightDark"} />
                        <span className={"font-bodyPri font-normal text-sm text-primary-dark line-clamp-1 capitalize"}>
                            {filterProps?.name?.replaceAll("-", " ")}
                        </span>
                    </>
                )
            }}
            customBoxClassName={!!filterProps?.name && "!bg-text-200 !border-solid"}
            activeContainerClassName={""}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By Name"}
                </span>
                <input
                    type={'search'}
                    placeholder={"Search"}
                    className={cn(
                        "w-full flex-1 px-2 py-1 border border-text-500 rounded-md focus:outline-none focus:border focus:border-primary-dark bg-transparent",
                        "font-bodyPri font-normal text-text-800 text-sm"
                    )}
                    value={titlePayload?.title}
                    onChange={(e) => setTitlePayload({
                        ...titlePayload,
                        message: "",
                        title: e.target.value
                    })}
                    autoFocus={true}
                    onKeyDown={onHandleKeyDown}
                />
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!titlePayload?.title) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
});

export default AvailabilityNameFilter;