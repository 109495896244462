import { useState } from "react";
import { Link } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";

import { useAppState } from "hooks/useStore";
import { courseType } from "redux/course/course.const";

import { pagesInfo } from "utils/pagesInfo";
import { cn } from "utils/cn.utils";

const SessionDetailCourse = () => {
    const { sessionDetail } = useAppState((state) => state.session)

    const [isShow, setIsShow] = useState(false)

    return !!sessionDetail?.data?.result?.session_course && (
        <div className={"space-y-3"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Course"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col items-start justify-start gap-3 transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>

                {/* course id container */}
                <div className={"flex items-center justify-start gap-3"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Class ID:"}
                    </span>
                    <Link
                        to={`${pagesInfo?.VIEW_COURSE?.pagePath}/${sessionDetail?.data?.result?.session_course?.id}`}
                        className={"font-bodyPri font-normal text-primary-main text-base hover:text-primary-dark hover:underline"}
                    >
                        {sessionDetail?.data?.result?.session_course?.id}
                    </Link>
                </div>

                {/* course title container */}
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Class Title:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_course?.title || "-"}
                    </span>
                </div>

                {/* course title description container */}
                <div className={"flex items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Class Sub Header:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_course?.subTitle || "-"}
                    </span>
                </div>

                {/* segment container */}
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Segment:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_course?.segment || "-"}
                    </span>
                </div>

                {/* category container */}
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Category:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_course?.category || "-"}
                    </span>
                </div>

                {/* course type container */}
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Class Type:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {courseType[sessionDetail?.data?.result?.session_course?.type?.toUpperCase()]?.label || "-"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SessionDetailCourse;