import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/tools/tools.data';

import PageHeader from 'components/pageHeader/PageHeader';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';
 

const EducatorToolsPage = () => {
    const dispatcher = useAppDispatcher()
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TOOLS))
    }, [dispatcher, currentPageInfo])

    return (
        <div className="h-full w-full p-3 mx-auto min-h-screen space-y-5">
            <PageHeader pageHeading={pageHeading} />
        </div>
    );
};

export default EducatorToolsPage;
