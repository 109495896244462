import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import LmsQuestionService from "redux/edulyteLms/lmsQuestion/lmsQuestion.service";
import {
    setLmsQuestionListLoading,
    setLmsQuestionListData,
    setLmsQuestionListMessage,
    
    setDestroyLmsQuestionDetailLoading,
    setDestroyLmsQuestionDetailMessage,
} from "redux/edulyteLms/lmsQuestion/lmsQuestion.slice";
import { lmsQuestionRedux } from "./lmsQuestion.const";

export const getLmsQuestionList = createAsyncThunk(
    `${lmsQuestionRedux?.sliceName}/${lmsQuestionRedux?.lmsQuestionList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setLmsQuestionListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await LmsQuestionService.getLmsQuestionList(requestData)
            if (response.status === 200) {
                dispatch(setLmsQuestionListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setLmsQuestionListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setLmsQuestionListLoading(false))
        }
    }
)

export const deleteLmsQuestionDetail = createAsyncThunk(
    `${lmsQuestionRedux?.sliceName}/${lmsQuestionRedux?.lmsQuestionList?.requestName}`,
    async (questionId, {dispatch}) => {
        dispatch(setDestroyLmsQuestionDetailLoading(true))

        try {
            const requestData = {
                params: {questionId: questionId}
            }
            const response = await LmsQuestionService.deleteLmsQuestionDetail(requestData)
            if (response.status === 204) {
                dispatch(getLmsQuestionList({page:1, records: 25}))
                toast.success(response.data.message || "Deleted Successfully")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setDestroyLmsQuestionDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setDestroyLmsQuestionDetailLoading(false))
        }
    }
)