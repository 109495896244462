import { useEffect } from 'react';

import PageHeader from "components/pageHeader/PageHeader";

import { pageHeading } from "pages/auth/dashboard/data";
import SelectDate from "pages/auth/dashboard/components/SelectDate";
import { DashboardStatistics } from "pages/auth/dashboard/components/DashboardStatistics";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';

const DashboardPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.DASHBOARD))
    }, [dispatcher, currentPageInfo])

    return (
        <div className="h-full w-full p-3 mx-auto min-h-screen">
            <div className="grid grid-cols-12 gap-3">
                <div className='col-span-full'>
                    <PageHeader pageHeading={pageHeading} />
                </div>
                <div className={"col-span-full flex justify-end"}>
                    <SelectDate />
                </div>
                <div className="col-span-full space-y-3">
                    <DashboardStatistics />
                </div>
            </div>
        </div>
    );
};

export default DashboardPage