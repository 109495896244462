import * as React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaTimesCircle } from "react-icons/fa";
import { cn } from "utils/cn.utils";

type tConst = {
  top: string;
  right: string;
  bottom: string;
  left: string;
}
const enterFromConst: tConst | any = {
  top: "translate-y-0",
  right: "translate-x-full",
  bottom: "translate-y-full",
  left: "translate-x-0"
}
const enterToConst: tConst | any = {
  top: "translate-y-0",
  right: "translate-x-0",
  bottom: "translate-y-full",
  left: "translate-x-full"
}
const leaveFromConst: tConst | any = {
  top: "translate-y-0",
  right: "translate-x-0",
  bottom: "translate-y-full",
  left: "translate-x-full"
}
const leaveToConst: tConst | any = {
  top: "translate-y-0",
  right: "translate-x-full",
  bottom: "translate-y-full",
  left: "translate-x-0"
}
export default function SideOverPanel({
  child,
  isOpen,
  onClose,
  enterFrom = "right",
  enterTo = "right",
  leaveFrom = "right",
  leaveTo = "right"
}: {
  isOpen: boolean;
  child: React.ReactNode;
  onClose: (state: boolean) => void | undefined | null;
  enterFrom?: string;
  enterTo?: string;
  leaveFrom?: string;
  leaveTo?: string;
}) {

  const handleOnClose = () => {
    onClose(false)
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-hidden"
        open={isOpen}
        onClose={handleOnClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={"div"}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-black opacity-50" />
          </Transition.Child>
          <div className={cn(
            "fixed inset-y-0 right-0 flex max-w-full",
            (enterTo === "left" && onClose) && "pr-10",
            (enterTo === "right" && onClose) && "pl-10",
          )}>
            <Transition.Child
              as={"div"}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom={enterFromConst[enterFrom]}
              enterTo={enterToConst[enterTo]}
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom={leaveFromConst[leaveTo]}
              leaveTo={leaveToConst[leaveFrom]}
            >
              <div className="relative w-full">
                <Transition.Child
                  as={"div"}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {(enterTo === "right" && onClose) &&
                    <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={handleOnClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <FaTimesCircle className="w-6 h-6" aria-hidden="true" />
                      </button>
                    </div>
                  }
                </Transition.Child>
                <div className="flex flex-col w-full h-full overflow-y-scroll bg-white shadow-xl">
                  {child}
                </div>
                {(enterTo === "left" && onClose) &&
                  <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={handleOnClose}
                    >
                      <span className="sr-only">Close panel</span>
                      <FaTimesCircle className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                }
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
