import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';

import ThreeDotMenu from "components/threeDotMenu/ThreeDotMenu";
import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { courseHeaderConst, subRowHeader, sortCourseLowToHigh, sortCourseHighToLow, courseMenuData } from 'pages/auth/courses/data';

import { getCourseList, updateCourseListByCourseId, createCloneCourseDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal, setStickyNotePayload } from 'redux/local/local.slice';
import { setClearCourseList, resetCloneCourseDetail } from 'redux/course/course.slice';
import { setAddBookingDetailPayload, resetAddBookingDetail, resetAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { courseType, courseStatus, coursePriceType } from 'redux/course/course.const';
import { modalConst, stickyNoteCommentsType } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const OnboardingActivity = ({ course, handleStickyNote }) => {
    const { modal, locals } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addBookingDetail } = useAppState((state) => state.booking)
    const { cloneCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const from = `${pagesInfo?.EDIT_COURSE?.pagePath}/${cloneCourseDetail?.data?.id}/edit?step=${cloneCourseDetail?.data?.step}`

    useEffect(() => {
        if (cloneCourseDetail?.data) {
            dispatcher(resetCloneCourseDetail())
            navigate(from, { replace: true })
        }
    }, [cloneCourseDetail?.data])


    const onHandleSelect = (option) => {
        switch (option.value) {
            // case courseMenuData.EDIT_USER.value:
            //     break;
            case courseMenuData.ADD_BOOKING.value:
                dispatcher(resetAddBookingDetail())
                dispatcher(resetAddBookingDetailPayload())
                dispatcher(setAddBookingDetailPayload({
                    ...addBookingDetail?.payload,
                    appointmentId: null,
                    courseId: course?.id,
                    userId: course?.tutor?.user?.id
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.ADD_BOOKING_MODAL.stateKey]: true
                }))
                break;
            case courseMenuData.CLONE_COURSE.value:
                if (user?.user?.roles?.find((role) => role.role === locals.userRole)) {
                    if ([courseStatus?.PUBLISHED?.value]?.includes(course?.status)) {
                        const requestDataPayload = {
                            courseId: course?.id,
                            body: { tutorId: course?.tutor?.id }
                        }
                        dispatcher(createCloneCourseDetail(requestDataPayload))
                    } else {
                        toast.warn("Course is not published yet!")
                    }

                } else {
                    toast.warn("Access Denied!")
                }

                break;
            case courseMenuData.PUBLIC_VIEW.value:
                if ([courseStatus?.PUBLISHED?.value]?.includes(course?.status)) {
                    window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${course?.slug}`)
                } else {
                    toast.warn("Course is not published yet!")
                }
            default:
                break;
        }
    }

    return (
        <div className={"flex justify-start items-center"}>
            <Link
                to={`${pagesInfo.COURSES.pagePath}/${course?.id}`}
                className={"px-2 py-1 bg-primary-main text-white rounded-l-md"}
            >
                <FaEye className="text-xl hover:scale-110" />
            </Link>
            <Link
                to={`${pagesInfo.COURSES.pagePath}/${course?.id}/edit`}
                className={"px-2 py-1 bg-background-lightDark text-white"}
            >
                <FiEdit className="text-xl hover:scale-110" />
            </Link>
            <ThreeDotMenu menuItems={courseMenuData} onHandleSelect={(option) => onHandleSelect(option)} />
        </div>
    )
}

const TutorContainer = ({ course }) => {
    return course ? (
        <div className={"flex items-center justify-start gap-0.5"}>
            <Link to={`${pagesInfo?.VIEW_TUTOR?.pagePath}/${course?.tutor?.id}`}
                className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
            >
                {course?.tutor?.id + ". "}
            </Link>
            <div className={""}>
                {course?.tutor?.user?.firstName + " " + course?.tutor?.user?.lastName?.charAt(0)}
            </div>
        </div>
    ) : "-"
}

const DisplayCourseTable = () => {
    const { modal, stickyNotePayload } = useAppState((state) => state.local)
    const { courseList, filterProps } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        dispatcher(getCourseList({ page: filterProps?.page || 1, records: filterProps?.records }))

        return () => {
            dispatcher(setClearCourseList())
        }
    }, [filterProps?.records])

    useEffect(() => {
        if (stickyNotePayload.commentsType === stickyNoteCommentsType.COURSE.value && stickyNotePayload?.comments) {
            dispatcher(updateCourseListByCourseId({
                courseId: stickyNotePayload?.id,
                body: {
                    courseType: stickyNotePayload?.metaData?.courseType,
                    comments: stickyNotePayload?.comments
                }
            }))
            setTimeout(() => {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.STICKY_NOTE_MODAL.stateKey]: false
                }))
            }, 2000)
        }
    }, [stickyNotePayload?.comments])

    const handleStickyNote = ({ course }) => {
        dispatcher(setStickyNotePayload({
            ...stickyNotePayload,
            isLoading: false,
            title: stickyNoteCommentsType.COURSE.title,
            commentsType: stickyNoteCommentsType.COURSE.value,
            id: course.id,
            comments: "",
            note: course.comment,
            metaData: {
                courseType: course?.type
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.STICKY_NOTE_MODAL.stateKey]: true
        }))
    }

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortCourseList = (course1, course2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortCourseLowToHigh(activeSortHeader, course1, course2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortCourseHighToLow(activeSortHeader, course1, course2)
        }
    }

    const rows = courseList?.data?.results?.slice(0)?.sort(sortCourseList)?.map((course, index) => [
        course?.id,
        course?.title
            ? (course?.title?.length > 30)
                ? course?.title?.slice(0, 30) + "...."
                : course?.title
            : "-",
        course?.slug
            ? (course?.slug?.length > 30)
                ? course?.slug?.slice(0, 30) + "...."
                : course?.slug
            : "-",
        <TutorContainer course={course} />,
        `${course?.category} (${course?.segment})` || "-",
        courseType[course?.type?.toUpperCase()]?.label || "-",
        courseStatus[course?.status?.toUpperCase()]?.label || "-",
        dayjs(course?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        course?.publishedAt ? dayjs(course?.publishedAt).tz(timeZone).format('DD MMM, YYYY') : "-",
        <OnboardingActivity course={course} handleStickyNote={handleStickyNote} />,
        "-"
    ]);

    return (
        <>
            <Table
                headers={Object.values(courseHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={courseList?.isLoading}
                message={(courseList?.message || (courseList?.data?.records === 0)) && (courseList?.message || "No courses found")}
            />
            {!courseList?.isLoading && (courseList?.data && (courseList?.data?.totalPages > 1)) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={courseList?.data?.page}
                        totalPages={courseList?.data?.totalPages}
                        onChangePage={(page) => dispatcher(getCourseList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </>
    )
}

export default DisplayCourseTable