import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from 'pages/auth/users/data';
import UsersSearch from 'pages/auth/users/components/UsersSearch';
import DisplayUsers from 'pages/auth/users/components/DisplayUsers';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const UsersPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.USERS))
    }, [dispatcher, currentPageInfo])

    return (
        <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3'>
            <PageHeader pageHeading={pageHeading} />
            <UsersSearch />
            <DisplayUsers />
        </div>
    );
};

export default UsersPage;
