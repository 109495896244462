import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";

import { useAppState } from "hooks/useStore";
import { onBoardingStatus, onboardingActionRequired } from "redux/onboarding/onboarding.const";

const ApproveTutor = () => {
    const { onboardingDetail } = useAppState((state) => state.onboarding)

    return (
        <div className={"space-y-5"}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-3 py-3",
                onboardingDetail?.onboardingDetail?.status && `bg-${onBoardingStatus[onboardingDetail?.onboardingDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Approve Tutor"}
            </div>
            {(onboardingDetail?.isLoading) &&
                <ComponentLoader isLoading={onboardingDetail?.isLoading} />
            }
            {(onboardingDetail?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingDetail?.errorMsg}
                </div>
            }
            {onboardingDetail?.onboardingDetail &&
                <>
                    {onboardingDetail?.onboardingDetail?.status &&
                        <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-3 md:flex-row md:items-center md:justify-start px-3">
                            <span className="text-text-600">
                                {"Status:"}
                            </span>
                            <div className={cn(
                                "font-bodyPri font-normal text-base",
                                `text-${onBoardingStatus[onboardingDetail?.onboardingDetail?.status?.toUpperCase()]?.darkColor}`
                            )}>
                                {onBoardingStatus[onboardingDetail?.onboardingDetail?.status?.toUpperCase()]?.label}
                            </div>
                        </div>
                    }
                    <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-3 md:flex-row md:items-center md:justify-start px-3">
                        <span className="text-text-600">
                            {"Comments:"}
                        </span>
                        <div className={cn(
                            "font-bodyPri font-normal text-base"
                        )}>
                            {onboardingDetail?.onboardingDetail?.comments}
                        </div>
                    </div>
                    {onboardingDetail?.onboardingDetail?.actionRequired &&
                        <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-3 md:flex-row md:items-center md:justify-start px-3">
                            <span className="text-text-600">
                                {"Action Require By:"}
                            </span>
                            <div className={cn(
                                "font-bodyPri font-normal text-base"
                            )}>
                                {onboardingActionRequired[onboardingDetail?.onboardingDetail?.actionRequired.toUpperCase()].label}
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default ApproveTutor;