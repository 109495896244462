import { useState, useEffect } from "react";
import { cn } from "utils/cn.utils";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { sessionStatusEnum } from "redux/session/session.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const SessionDetailCountDown = () => {
    const { sessionDetail } = useAppState(s => s.session)

    const dispatcher = useAppDispatcher()

    const [isSessionLinkActive, setIsSessionLinkActive] = useState(false)
    const [isSessionStarted, setIsSessionStarted] = useState(false)
    const [isSessionEnded, setIsSessionEnded] = useState(false)
    const [countDownTimer, setCountDownTimer] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    })

    useEffect(() => {
        let interval
        if (sessionDetail?.data?.result && [sessionStatusEnum.SCHEDULED.value, sessionStatusEnum.ON_GOING.value, sessionStatusEnum.COMPLETED.value].includes(sessionDetail?.data?.result?.status)) {
            const nowTimeStamp = parseInt(dayjs().tz(timeZone).valueOf() / 1000)
            const startTimeStamp = parseInt(dayjs(sessionDetail?.data?.result?.startDateTime).tz(timeZone).valueOf() / 1000)
            const endTimeStamp = parseInt(dayjs(sessionDetail?.data?.result?.endDateTime).tz(timeZone).valueOf() / 1000)
            const deltaTimeStampToActiveLink = startTimeStamp - (nowTimeStamp + 600)
            const deltaTimeStampToStart = startTimeStamp - nowTimeStamp
            const deltaTimeStampToEnd = endTimeStamp - nowTimeStamp
            setIsSessionLinkActive(deltaTimeStampToActiveLink < 0)
            setIsSessionStarted(deltaTimeStampToStart < 0)
            setIsSessionEnded(deltaTimeStampToEnd < 0)

            interval = setInterval(() => {
                if (!isSessionStarted) {
                    setCountDownTimer({
                        ...countDownTimer,
                        seconds: parseInt(deltaTimeStampToStart) % 60,
                        minutes: parseInt(deltaTimeStampToStart / 60) % 60,
                        hours: parseInt(deltaTimeStampToStart / (60 * 60)) % 24,
                        days: parseInt(deltaTimeStampToStart / (60 * 60 * 24)),
                    })
                }
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    })

    return sessionDetail?.data?.result &&
        [sessionStatusEnum.SCHEDULED.value, sessionStatusEnum.ON_GOING.value].includes(sessionDetail?.data?.result?.status) ? (
        <div className={"flex flex-col items-center justify-center gap-2"}>
            <div className={"font-bodyPri font-medium text-base text-text-900"}>
                {isSessionEnded
                    ? "Meeting Ended"
                    : isSessionStarted
                        ? "Meeting Started"
                        : "Meeting Starts In"
                }
            </div>
            <div className={"w-full flex items-center justify-evenly gap-2"}>
                {Object.keys(countDownTimer).map((key, index) => (
                    <span key={index} className={cn(
                        "flex flex-col items-center justify-center w-16 py-2",
                        "rounded-lg border-2 border-divider-medium shadow"
                    )}>
                        <span className={"font-bodyPri font-semibold text-lg text-primary-dark"}>
                            {String(countDownTimer[key]).padStart(2, '0')}
                        </span>
                        <span className={"font-bodyPri font-normal text-xs text-text-900 capitalize"}>
                            {key}
                        </span>
                    </span>
                ))}
            </div>
        </div>
    ) : null
}

export default SessionDetailCountDown;