import { useMemo } from 'react';

import { FaInfoCircle } from 'react-icons/fa';

import { motion } from "framer-motion";

import ButtonLoader from 'components/loader/ButtonLoader';
import ToolTipView from 'components/toolTip';

import { statusToolTip } from 'pages/auth/sessions/data';

import { updateStartSessionDetail } from 'redux/session/session.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { sessionStatusEnum } from "redux/session/session.const";

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const SessionDetailStatus = () => {
    const { sessionDetail, modifyStartSessionDetail } = useAppState(s => s.session)

    const dispatcher = useAppDispatcher()

    const eventLogStatusObject = useMemo(() => sessionDetail?.data?.result?.session_logs?.find(session => session?.status === sessionDetail?.data?.result?.status), [sessionDetail?.data?.result?.session_logs])

    const onHandleStartSession = () => {
        if (modifyStartSessionDetail?.isLoading) return;

        if (![sessionStatusEnum.SCHEDULED.value].includes(sessionDetail?.data?.result?.status)) return;

        const requestPayload = {
            sessionId: sessionDetail?.data?.result?.id,
            body: {}
        }
        dispatcher(updateStartSessionDetail(requestPayload))
    }

    return sessionDetail?.data?.result?.status ? (
        <div className={"w-full flex flex-col gap-3"}>
            <div className={"w-full flex items-center justify-between gap-1"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-base text-text-800"}>
                        {"Status: "}
                    </span>
                    <div className={cn(
                        "flex items-center justify-center gap-1 border-2 rounded-lg px-2 py-0.5",
                        "font-bodyPri font-medium text-base",
                        `border-${sessionStatusEnum[sessionDetail?.data?.result?.status?.toUpperCase()]?.darkColor}`,
                        `text-${sessionStatusEnum[sessionDetail?.data?.result?.status?.toUpperCase()]?.darkColor}`,
                    )}>
                        <span className={""}>
                            {sessionStatusEnum[sessionDetail?.data?.result?.status?.toUpperCase()]?.label}
                        </span>
                    </div>
                    <ToolTipView
                        content={statusToolTip[sessionDetail?.data?.result?.status?.toUpperCase()]}
                        disabled={!sessionDetail?.data?.result?.status}
                    >
                        <button className={"cursor-pointer"}>
                            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                        </button>
                    </ToolTipView>
                </div>

                <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                    {dayjs(eventLogStatusObject?.createdAt).tz(timeZone).fromNow()}
                </span>
            </div>
            {[sessionStatusEnum.SCHEDULED.value].includes(sessionDetail?.data?.result?.status) &&
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                    onClick={onHandleStartSession}
                    className={cn(
                        "flex items-center justify-center px-3 py-0.5",
                        "rounded-lg border-2 border-primary-dark",
                        "font-buttons font-medium text-base cursor-pointer",
                        !modifyStartSessionDetail?.isLoading && "text-primary-dark hover:text-text-50 hover:bg-primary-dark",
                        modifyStartSessionDetail?.isLoading && "bg-primary-dark"
                    )}>
                    {modifyStartSessionDetail?.isLoading &&
                        <ButtonLoader isLoading={modifyStartSessionDetail?.isLoading} />
                    }
                    {!modifyStartSessionDetail?.isLoading &&
                        <span className={"font-buttons font-normal text-base"}>
                            {"Start Session"}
                        </span>
                    }
                </motion.button>
            }
        </div>
    ) : null
}

export default SessionDetailStatus;