import { createSlice } from "@reduxjs/toolkit";

import { PRODUCT_INITIAL_STATE } from "./product.initialState";

const product = createSlice({
    name: "product",
    initialState: PRODUCT_INITIAL_STATE,
    reducers: {
        // product list
        setProductListLoading: (state, { payload }) => {
            state.productList.isLoading = payload
        },
        setProductListData: (state, { payload }) => {
            state.productList.data = payload
            state.productList.message = PRODUCT_INITIAL_STATE.productList.message
        },
        setProductListMessage: (state, { payload }) => {
            state.productList.message = payload
            state.productList.data = PRODUCT_INITIAL_STATE.productList.data
        },
        resetProductList: (state) => {
            state.productList = PRODUCT_INITIAL_STATE.productList
        },

        // destroy product detail
        setDestroyProductDetailLoading: (state, { payload }) => {
            state.destroyProductDetail.isLoading = payload
        },
        setDestroyProductDetailMessage: (state, { payload }) => {
            state.destroyProductDetail.message = payload
        },
        resetDestroyProductDetail: (state) => {
            state.destroyProductDetail = PRODUCT_INITIAL_STATE.destroyProductDetail
        },

        // reducers for filter product List
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = PRODUCT_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setProductListLoading,
    setProductListData,
    setProductListMessage,
    resetProductList,

    setDestroyProductDetailLoading,
    setDestroyProductDetailMessage,
    resetDestroyProductDetail,

    setFilterProps,
    resetFilterProps
} = product.actions;

export default product.reducer;