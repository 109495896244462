
import { useAppState } from 'hooks/useStore';
import { courseType, courseStatus } from "redux/course/course.const";

import { cn } from "utils/cn.utils";

const SubmitFormBar = () => {
    const { coursePreviewDetail } = useAppState((state) => state.course)

    return (
        <div className="w-full px-2">
            <div
                className={cn(
                    "w-full shadow-lg rounded-md m-2",
                    `flex flex-col md:flex-row items-center justify-center px-3 py-3 mx-auto bg-${courseStatus[coursePreviewDetail?.data?.status?.toUpperCase()]?.darkColor}, bg-white`
                )}
            >
                <p className="text-xl font-medium text-text-800 font-bodyPri tracking-wide">
                    {`Preview: Your ${courseType[coursePreviewDetail?.data?.type?.toUpperCase()]?.label} Class`}
                </p>
                {/* <div className="flex mt-2 space-x-2 md:m-0 font-medium text-text-800 font-bodyPri tracking-wide">
                    <button
                        className={cn(
                            "text-center py-1 px-4  text-primary-main border-2 border-primary-main hover:bg-primary-main hover:text-text-50 rounded-lg",
                            "hover:scale-95 transition-transform",
                            "whitespace-nowrap "
                        )}
                        onClick={onSubmit}
                    >
                        {"Submit"}
                    </button>
                </div> */}
            </div>
        </div>
    );
}

export default SubmitFormBar;