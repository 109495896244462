export const url = {
    BADGE_LIST: "/badges/badges",
    USER_BADGE_LIST: "/badges/user-badges/users/{userId}",
    USER_BADGE_DETAIL: "/badges/user-badges/{userBadgeId}",
    ORDER_USER_BADGE_LIST: "/badges/user-badges/users/{userId}/order"
}

export const badgeRedux = {
    sliceName: "badge",
    badgeList: {
        requestName: "getBadgeList"
    },
    userBadgeList: {
        requestName: "getUserBadgeListByUserId"
    },
    createUserBadgeDetail: {
        requestName: "createUserBadgeDetailByUserId"
    },
    updateUserBadgeDetail: {
        requestName: "updateUserBadgeDetailByUserBadgeId"
    },
    orderUserBadgeList: {
        requestName: "updateUserBadgeListOrderByUserId"
    },
    deleteUserBadgeDetail: {
        requestName: "deleteUserBadgeDetailByUserBadgeId"
    }
}

export const badgeVisibility = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    HIDDEN: {
        label: "Hidden",
        value: "hidden"
    },
    BLOCK: {
        label: "Block",
        value: "block"
    }
}