import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { lmsArticleHeaderConst } from "../lmsArticle.data";

import { getLmsArticleList } from "redux/edulyteLms/lmsArticle/lmsArticle.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/edulyteLms/lmsArticle/lmsArticle.slice";
import { lmsArticleStatusEnum, lmsArticleVisibilityEnum } from "redux/edulyteLms/lmsArticle/lmsArticle.const";
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const SearchLmsArticle = () => {
    const { lmsArticleList, filterProps } = useAppState((state) => state.lms.lmsArticle)

    const dispatcher = useAppDispatcher()

    const header = Object.values(lmsArticleHeaderConst)?.filter((header) => header?.value !== lmsArticleHeaderConst.ACTIVITY.value)?.map((head) => head?.label)

    const body = lmsArticleList?.data?.results?.map((article) => ({
        [lmsArticleHeaderConst?.ARTICLE_ID?.label]: article?.article_id,
        [lmsArticleHeaderConst?.ARTICLE_TITLE?.label]: article?.title,
        [lmsArticleHeaderConst?.OWNER_TUTOR?.label]: article?.article_settings?.owner_tutor?.tutor?.id,
        [lmsArticleHeaderConst?.STATUS?.label]: lmsArticleStatusEnum[article?.article_settings?.status?.toUpperCase()]?.label,
        [lmsArticleHeaderConst?.VISIBILITY?.label]: lmsArticleVisibilityEnum[article?.article_settings?.visibility?.toUpperCase()]?.label,
        [lmsArticleHeaderConst?.DATE?.label]: dayjs(article?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A')
    }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-lms article",
            sheet: "lms article",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value },
        }))
    }

    const onHandleSearchArticle = () => {
        dispatcher(getLmsArticleList({ search: filterProps?.searchText, page: filterProps?.page || 1, records: filterProps?.records || 25 }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-events"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80",
                            "font-bodyPri font-normal text-text-900 text-base"
                        )}
                        placeholder={"search here..."}
                        value={filterProps?.searchText}
                        onChange={handleFilterSearch}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={onHandleSearchArticle}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchLmsArticle;