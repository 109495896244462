import { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import PreferenceIconButtons from "pages/auth/editUser/commonComponents/buttons/PreferenceIconButtons";

import { availabilityIconButtons } from "pages/auth/editUser/data";

import { deleteAvailabilityDetail } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { availabilityEndTypeEnum, availabilityIsRecurringEnum } from "redux/availability/availability.const";

import { pagesInfo } from "utils/pagesInfo";
import { dayjs, getTimeZoneOffset, timeZone } from "utils/dateTime.utils";
import { cn } from "utils/cn.utils";

const AvailabilityCard = memo(({ userAvailability }) => {
    const { userDetail } = useAppState((state) => state.user)
    const { destroyAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const timeZoneOffset = useMemo(() => getTimeZoneOffset(timeZone), [timeZone])

    const onHandleActionButtons = useCallback((selectedButton) => {
        if (destroyAvailabilityDetail?.isLoading) return;

        if (selectedButton?.value === availabilityIconButtons.EDIT.value) {
            navigate(`${pagesInfo.EDIT_USER.pagePath}/${userDetail?.userDetail?.userId}/edit/user-availability/${userAvailability?.id}/edit`)
        } else if (selectedButton?.value === availabilityIconButtons.DELETE.value) {
            if (!window.confirm("Are you sure?. you want to delete this availability detail")) return;
            dispatcher(deleteAvailabilityDetail(userAvailability?.id))
        }
    }, [userAvailability, destroyAvailabilityDetail?.isLoading])

    return (
        <div className={cn("border-2 rounded-md",
            userAvailability?.default && "border-primary-dark"
        )}>
            <div className={"relative w-full p-4 bg-primary-light rounded-lg"}>
                <div className={"flex flex-col gap-1.5"}>
                    <div className={"flex items-start justify-between"}>
                        <span
                            className={"font-bodyPri font-medium text-lg text-text-900 hover:text-primary-dark hover:underline cursor-pointer line-clamp-1"}
                            onClick={() => onHandleActionButtons(availabilityIconButtons.EDIT)}
                        >
                            {`${userAvailability?.id}. ${userAvailability?.name}`}
                        </span>
                        <div className={"flex items-center justify-start gap-1.5 -mt-1.5 md:-mt-3"}>
                            <PreferenceIconButtons
                                activeLoading={destroyAvailabilityDetail?.isLoading && availabilityIconButtons.DELETE}
                                iconButtonList={userAvailability?.default
                                    ? Object.values(availabilityIconButtons)?.filter((iconButton) => (iconButton?.value !== availabilityIconButtons.DELETE.value))
                                    : Object.values(availabilityIconButtons)
                                }
                                onHandleIconButton={onHandleActionButtons}
                            />
                        </div>
                    </div>
                    <span className={"font-bodyPri font-normal text-sm tracking-wide line-clamp-1"}>
                        {userAvailability?.description}
                    </span>
                </div>
            </div>

            <div className="px-2 divide-y divide-blue-200 rounded-b-xl">
                <div className={"w-full p-4 flex flex-col gap-3 md:flex-row md:items-center md:justify-start md:gap-1"}>
                    {userAvailability?.isRecurring &&
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {availabilityIsRecurringEnum[userAvailability?.isRecurring?.toUpperCase()]?.label}
                        </span>
                    }
                    <hr className={"w-[16px] rotate-90 border border-text-800 hidden md:block text-lg text-text-800"} />
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {dayjs(userAvailability?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("DD/MM/YYYY")}
                        </span>
                        <span className={""}>{"-"}</span>
                        {([availabilityEndTypeEnum.FOREVER.value]?.includes(userAvailability?.endType)) &&
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {availabilityEndTypeEnum.FOREVER.label}
                            </span>
                        }
                        {[availabilityEndTypeEnum.WEEKLY_CYCLE.value]?.includes(userAvailability?.endType) &&
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {availabilityEndTypeEnum.WEEKLY_CYCLE.label}
                            </span>
                        }
                        {([availabilityEndTypeEnum.ON_DATE.value]?.includes(userAvailability?.endType) && userAvailability?.endDateTime) &&
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {dayjs(userAvailability?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("DD/MM/YYYY")}
                            </span>
                        }
                    </div>
                </div>
                {!userAvailability?.startDateTime &&
                    <div className="py-5">
                        <p className="font-bodyPri font-semibold text-xl text-text-500 text-center">
                            {"No Slot Found"}
                        </p>
                    </div>
                }
            </div>
        </div>
    );
});

export default AvailabilityCard;

export const AvailabilityCardLoader = ({ isLoading = false }) => {

    return isLoading ? (
        <div className={"border-2 rounded-md bg-slate-300 animate-pulse"}>
            <div className={"relative w-full p-4 bg-white rounded-lg"}>
                <div className={"flex flex-col gap-1.5"}>
                    <div className={"flex items-start justify-between"}>
                        <span className={"w-[80vw] md:w-[40vw] h-8 bg-slate-200 animate-pulse font-bodyPri font-medium text-lg text-text-900 hover:text-primary-dark hover:underline cursor-pointer line-clamp-1"}>
                        </span>
                        <div className={"flex items-center justify-start gap-1.5 -mt-1.5 md:-mt-3"}>
                            <span className={"w-24 h-10 bg-slate-200 animate-pulse font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            </span>
                            <span className={"w-24 h-10 bg-slate-200 animate-pulse font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            </span>
                        </div>
                    </div>
                    <span className={"w-[80vw] md:w-[40vw] h-8 bg-slate-200 animate-pulse font-bodyPri font-normal text-sm tracking-wide line-clamp-1"}>
                    </span>
                </div>
            </div>

            <div className="px-2 divide-y divide-blue-200 rounded-b-xl">
                <div className={"w-full p-4 flex flex-col gap-3 md:flex-row md:items-center md:justify-start md:gap-1"}>
                    <span className={"w-24 h-10 bg-slate-200 animate-pulse font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    </span>

                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={"w-24 h-10 bg-slate-200 animate-pulse font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        </span>
                        <span className={"w-24 h-10 bg-slate-200 animate-pulse font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}