import { createSlice } from "@reduxjs/toolkit";

import { ONBOARDING_INITIAL_STATE } from "redux/onboarding/onboarding.initialState";
import {
    getOnboardingList,
    getUserOnboardingListByUserId,
    getOnboardingDetailByOnboardingId
} from "redux/onboarding/onboarding.request";

const onboarding = createSlice({
    name: "onboarding",
    initialState: ONBOARDING_INITIAL_STATE,
    reducers: {

        // reducers for tutor onboarding list
        setTutorOnboardingListLoading: (state, { payload }) => {
            state.tutorOnboardingList.isLoading = payload
        },
        setTutorOnboardingList: (state, { payload }) => {
            state.tutorOnboardingList.tutorOnboardingList = payload
            state.tutorOnboardingList.filteredTutorOnboardingList = payload
            state.tutorOnboardingList.errorMsg = ONBOARDING_INITIAL_STATE.tutorOnboardingList.errorMsg
        },
        setTutorOnboardingListErrorMsg: (state, { payload }) => {
            state.tutorOnboardingList.errorMsg = payload
            state.tutorOnboardingList.tutorOnboardingList = ONBOARDING_INITIAL_STATE.tutorOnboardingList.tutorOnboardingList
            state.tutorOnboardingList.filteredTutorOnboardingList = ONBOARDING_INITIAL_STATE.tutorOnboardingList.filterTutorOnboardingList
        },
        setClearTutorOnboardingList: (state) => {
            state.tutorOnboardingList = ONBOARDING_INITIAL_STATE.tutorOnboardingList
        },

        // reducers for get onboarding list
        setClearOnboardingList: (state) => {
            state.onboardingList = ONBOARDING_INITIAL_STATE.onboardingList
        },

        // reducers for tutor onboarding detail
        setTutorOnboardingDetailLoading: (state, { payload }) => {
            state.tutorOnboardingDetail = payload
        },
        setTutorOnboardingDetail: (state, { payload }) => {
            state.tutorOnboardingDetail.tutorOnboardingDetail = payload
            state.tutorOnboardingDetail.errorMsg = ONBOARDING_INITIAL_STATE.tutorOnboardingDetail.errorMsg
        },
        setTutorOnboardingDetailErrorMsg: (state, { payload }) => {
            state.tutorOnboardingDetail.errorMsg = payload
            state.tutorOnboardingDetail.tutorOnboardingDetail = ONBOARDING_INITIAL_STATE.tutorOnboardingDetail.tutorOnboardingDetail
        },
        setClearOnboardingDetail: (state) => {
            state.onboardingDetail = ONBOARDING_INITIAL_STATE.onboardingDetail
        },

        // reducers for onboarding user detail
        setOnboardingUserDetailLoading: (state, { payload }) => {
            state.onboardingUserDetail.isLoading = payload
        },
        setOnboardingUserDetail: (state, { payload }) => {
            state.onboardingUserDetail.onboardingUserDetail = payload
            state.onboardingUserDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingUserDetail.errorMsg
        },
        setOnboardingUserDetailErrorMsg: (state, { payload }) => {
            state.onboardingUserDetail.errorMsg = payload
            state.onboardingUserDetail.onboardingUserDetail = ONBOARDING_INITIAL_STATE.onboardingUserDetail.onboardingUserDetail
        },
        setClearOnboardingUserDetail: (state) => {
            state.onboardingUserDetail = ONBOARDING_INITIAL_STATE.onboardingUserDetail
        },

        // reducers for update user onboarding user detail
        setAddOnboardingUserDetailLoading: (state, { payload }) => {
            state.addOnboardingUserDetail.isLoading = payload
        },
        setAddOnboardingUserDetail: (state, { payload }) => {
            state.addOnboardingUserDetail.addOnboardingUserDetail = payload
            state.addOnboardingUserDetail.errorMsg = ONBOARDING_INITIAL_STATE.addOnboardingUserDetail.errorMsg
        },
        setAddOnboardingUserDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingUserDetail.isLoading = payload
        },
        setClearAddOnboardingUserDetail: (state) => {
            state.addOnboardingUserDetail = ONBOARDING_INITIAL_STATE.addOnboardingUserDetail
        },

        // user onboarding question detail reducers
        setUserOnboardingQuestionDetailLoading: (state, { payload }) => {
            state.userOnboardingQuestionDetail.isLoading = payload
        },
        setUserOnboardingQuestionDetail: (state, { payload }) => {
            state.userOnboardingQuestionDetail.userOnboardingQuestionDetail = payload
            state.userOnboardingQuestionDetail.errorMsg = ONBOARDING_INITIAL_STATE.userOnboardingQuestionDetail.errorMsg
        },
        setUserOnboardingQuestionDetailErrorMs: (state, { payload }) => {
            state.userOnboardingQuestionDetail.errorMsg = payload
            state.userOnboardingQuestionDetail.userOnboardingQuestionDetail = ONBOARDING_INITIAL_STATE.userOnboardingQuestionDetail.userOnboardingQuestionDetail
        },
        setClearUserOnboardingQuestionDetail: (state) => {
            state.userOnboardingQuestionDetail = ONBOARDING_INITIAL_STATE.userOnboardingQuestionDetail
        },

        // update user onboarding question detail
        setAddUserOnboardingQuestionDetailLoading: (state, { payload }) => {
            state.addUserOnboardingQuestionDetail.isLoading = payload
        },
        setAddUserOnboardingQuestionDetail: (state, { payload }) => {
            state.addUserOnboardingQuestionDetail.addUserOnboardingQuestionDetail = payload
            state.addUserOnboardingQuestionDetail.errorMsg = ONBOARDING_INITIAL_STATE.addUserOnboardingQuestionDetail.errorMsg
        },
        setAddUserOnboardingQuestionDetailErrorMsg: (state, { payload }) => {
            state.addUserOnboardingQuestionDetail.errorMsg = payload
            state.addUserOnboardingQuestionDetail = ONBOARDING_INITIAL_STATE.addUserOnboardingQuestionDetail
        },
        setClearAddUserOnboardingQuestionDetail: (state) => {
            state.addUserOnboardingQuestionDetail = ONBOARDING_INITIAL_STATE.addUserOnboardingQuestionDetail
        },

        // reducers for document list
        setOnboardingDocListLoading: (state, { payload }) => {
            state.onboardingDocList.isLoading = payload
        },
        setOnboardingDocList: (state, { payload }) => {
            state.onboardingDocList.onboardingDocList = payload
            state.onboardingDocList.errorMsg = ONBOARDING_INITIAL_STATE.onboardingDocList.errorMsg
        },
        setOnboardingDocListErrorMsg: (state, { payload }) => {
            state.onboardingDocList.errorMsg = payload
            state.onboardingDocList.onboardingDocList = ONBOARDING_INITIAL_STATE.onboardingDocList.onboardingDocList
        },
        setClearOnboardingDocList: (state, { payload }) => {
            state.onboardingDocList = ONBOARDING_INITIAL_STATE.onboardingDocList
        },

        // Add Document reducers
        setAddOnboardingDocDetailLoading: (state, { payload }) => {
            state.addOnboardingDocDetail.isLoading = payload
        },
        setAddOnboardingDocDetail: (state, { payload }) => {
            state.addOnboardingDocDetail.addOnboardingDocDetail = payload
            state.addOnboardingDocDetail.errorMsg = ONBOARDING_INITIAL_STATE.addOnboardingDocDetail.errorMsg
        },
        setAddOnboardingDocDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingDocDetail.errorMsg = payload
            state.addOnboardingDocDetail.addOnboardingDocDetail = ONBOARDING_INITIAL_STATE.addOnboardingDocDetail.addOnboardingDocDetail
        },
        setClearAddOnboardingDocDetail: (state) => {
            state.addOnboardingDocDetail = ONBOARDING_INITIAL_STATE.addOnboardingDocDetail
        },

        setAddOnboardingDocDetailPayload: (state, { payload }) => {
            state.addOnboardingDocDetail.addOnboardingDocDetailPayload = payload
        },
        setClearAddOnboardingDocDetailPayload: (state) => {
            state.addOnboardingDocDetail.addOnboardingDocDetailPayload = ONBOARDING_INITIAL_STATE.addOnboardingDocDetail.addOnboardingDocDetailPayload
        },

        // onboarding interview detail reducers
        setOnboardingInterviewDetailLoading: (state, { payload }) => {
            state.onboardingInterviewDetail.isLoading = payload
        },
        setOnboardingInterviewDetail: (state, { payload }) => {
            state.onboardingInterviewDetail.onboardingInterviewDetail = payload
            state.onboardingInterviewDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingInterviewDetail.errorMsg
        },
        setOnboardingInterviewDetailErrorMsg: (state, { payload }) => {
            state.onboardingInterviewDetail.errorMsg = payload
            state.onboardingInterviewDetail.onboardingInterviewDetail = ONBOARDING_INITIAL_STATE.onboardingInterviewDetail.onboardingInterviewDetail
        },
        setClearOnboardingInterviewDetail: (state) => {
            state.onboardingInterviewDetail = ONBOARDING_INITIAL_STATE.onboardingInterviewDetail
        },

        // add onboarding Interview detail reducers
        setAddOnboardingInterviewDetailLoading: (state, { payload }) => {
            state.addOnboardingInterviewDetail.isLoading = payload
        },
        setAddOnboardingInterviewDetail: (state, { payload }) => {
            state.addOnboardingInterviewDetail.addOnboardingInterviewDetail = payload
            state.addOnboardingInterviewDetail.errorMsg = ONBOARDING_INITIAL_STATE.addOnboardingInterviewDetail.errorMsg
        },
        setAddOnboardingInterviewDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingInterviewDetail.errorMsg = payload
        },
        setClearAddOnboardingInterviewDetail: (state) => {
            state.addOnboardingInterviewDetail = ONBOARDING_INITIAL_STATE.addOnboardingInterviewDetail
        },

        // onboarding tutor pref detail reducers
        setOnboardingTutorPrefDetailLoading: (state, { payload }) => {
            state.onboardingTutorPrefDetail.isLoading = payload
        },
        setOnboardingTutorPrefDetail: (state, { payload }) => {
            state.onboardingTutorPrefDetail.onboardingTutorPrefDetail = payload
            state.onboardingTutorPrefDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingTutorPrefDetail.errorMsg
        },
        setOnboardingTutorPrefDetailErrorMsg: (state, { payload }) => {
            state.onboardingTutorPrefDetail.errorMsg = payload
            state.onboardingTutorPrefDetail.onboardingTutorPrefDetail = ONBOARDING_INITIAL_STATE.onboardingTutorPrefDetail.onboardingTutorPrefDetail
        },
        setClearOnboardingTutorPrefDetail: (state) => {
            state.onboardingTutorPrefDetail = ONBOARDING_INITIAL_STATE.onboardingTutorPrefDetail
        },

        // update tutor onboarding pref detail
        setAddOnboardingTutorPrefDetailLoading: (state, { payload }) => {
            state.addOnboardingTutorPrefDetail.isLoading = payload
        },
        setAddOnboardingTutorPrefDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingTutorPrefDetail.errorMsg = payload
        },

        // onboarding tutor trial course detail
        setOnboardingTutorTrialCourseDetailLoading: (state, { payload }) => {
            state.onboardingTutorTrialCourseDetail.isLoading = payload
        },
        setOnboardingTutorTrialCourseDetail: (state, { payload }) => {
            state.onboardingTutorTrialCourseDetail.onboardingTutorTrialCourseDetail = payload
            state.onboardingTutorTrialCourseDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingTutorTrialCourseDetail.errorMsg
        },
        setOnboardingTutorTrialCourseDetailErrorMsg: (state, { payload }) => {
            state.onboardingTutorTrialCourseDetail.errorMsg = payload
            state.onboardingTutorTrialCourseDetail.onboardingTutorTrialCourseDetail = ONBOARDING_INITIAL_STATE.onboardingTutorTrialCourseDetail.onboardingTutorTrialCourseDetail
        },
        setClearOnboardingTutorTrialCourseDetail: (state) => {
            state.onboardingTutorTrialCourseDetail = ONBOARDING_INITIAL_STATE.onboardingTutorTrialCourseDetail
        },

        // update onboarding tutor trial course
        setAddOnboardingTutorTrialCourseDetailLoading: (state, { payload }) => {
            state.addOnboardingTutorTrialCourseDetail.isLoading = payload
        },
        setAddOnboardingTutorTrialCourseDetail: (state, { payload }) => {
            state.addOnboardingTutorTrialCourseDetail.addOnboardingTutorTrialCourseDetail = payload
            state.addOnboardingTutorTrialCourseDetail.errorMsg = ONBOARDING_INITIAL_STATE.addOnboardingTutorTrialCourseDetail.errorMsg
        },
        setAddOnboardingTutorTrialCourseDetailErrorMsg: (state, { payload }) => {
            state.addOnboardingTutorTrialCourseDetail.errorMsg = payload
            state.addOnboardingTutorTrialCourseDetail.addOnboardingTutorTrialCourseDetail = ONBOARDING_INITIAL_STATE.addOnboardingTutorTrialCourseDetail.addOnboardingTutorTrialCourseDetail
        },
        setClearAddOnboardingTutorTrialCourseDetail: (state) => {
            state.addOnboardingTutorTrialCourseDetail = ONBOARDING_INITIAL_STATE.addOnboardingTutorTrialCourseDetail
        },

        // onboarding comment list reducers
        setOnboardingCommentListLoading: (state, { payload }) => {
            state.onboardingCommentList.isLoading = payload
        },
        setOnboardingCommentListData: (state, { payload }) => {
            state.onboardingCommentList.data = payload
            state.onboardingCommentList.message = ONBOARDING_INITIAL_STATE.onboardingCommentList.message
        },
        setOnboardingCommentListMessage: (state, { payload }) => {
            state.onboardingCommentList.message = payload
            state.onboardingCommentList.data = ONBOARDING_INITIAL_STATE.onboardingCommentList.data
        },
        resetOnboardingCommentList: (state) => {
            state.onboardingCommentList = ONBOARDING_INITIAL_STATE.onboardingCommentList
        },

        // reducers for add onboarding comments
        setAddOnboardingCommentDetailLoading: (state, { payload }) => {
            state.addOnboardingCommentDetail.isLoading = payload
        },
        setAddOnboardingCommentDetailMessage: (state, { payload }) => {
            state.addOnboardingCommentDetail.message = payload
        },
        resetAddOnboardingCommentDetail: (state) => {
            state.addOnboardingCommentDetail = ONBOARDING_INITIAL_STATE.addOnboardingCommentDetail
        },


        // reducers for professional references
        setProfessionalReferencesLoading: (state, { payload }) => {
            state.referenceList.isLoading = payload
        },
        setProfessionalReferences: (state, { payload }) => {
            state.referenceList.referenceList = payload
            state.referenceList.errorMsg = ONBOARDING_INITIAL_STATE.referenceList.errorMsg
        },
        setProfessionalReferencesErrorMsg: (state, { payload }) => {
            state.referenceList.errorMsg = payload
            state.referenceList.referenceList = ONBOARDING_INITIAL_STATE.referenceList.referenceList
        },
        setClearProfessionalReferences: (state) => {
            state.referenceList = ONBOARDING_INITIAL_STATE.referenceList
        },

        // reducers for background detail
        setBackgroundDetailLoading: (state, { payload }) => {
            state.backgroundDetail.isLoading = payload
        },
        setBackgroundDetail: (state, { payload }) => {
            state.backgroundDetail.backgroundDetail = payload
            state.backgroundDetail.errorMsg = ONBOARDING_INITIAL_STATE.backgroundDetail.errorMsg
        },
        setBackgroundDetailErrorMsg: (state, { payload }) => {
            state.backgroundDetail.errorMsg = payload
            state.backgroundDetail.backgroundDetail = ONBOARDING_INITIAL_STATE.backgroundDetail.backgroundDetail
        },

        // reducers for company onboarding list
        setCompanyOnboardingLoading: (state, { payload }) => {
            state.companyOnboardingList.isLoading = payload
        },
        setCompanyOnboarding: (state, { payload }) => {
            state.companyOnboardingList.companyOnboardingList = payload
            state.companyOnboardingList.filteredCompanyOnboardingList = payload
            state.companyOnboardingList.errorMsg = ONBOARDING_INITIAL_STATE.companyOnboardingList.errorMsg
        },
        setCompanyOnboardingErrorMsg: (state, { payload }) => {
            state.companyOnboardingList.errorMsg = payload
            state.companyOnboardingList.companyOnboardingList = ONBOARDING_INITIAL_STATE.companyOnboardingList.errorMsg
        },
        setClearCompanyOnboardingList: (state) => {
            state.companyOnboardingList = ONBOARDING_INITIAL_STATE.companyOnboardingList
        },

        // reducers for company public profile
        setCompanyPublicProfileLoading: (state, { payload }) => {
            state.companyPublicProfile.isLoading = payload
        },
        setCompanyPublicProfile: (state, { payload }) => {
            state.companyPublicProfile.companyPublicProfile = payload
            state.companyPublicProfile.errorMsg = ONBOARDING_INITIAL_STATE.companyPublicProfile.errorMsg
        },
        setCompanyPublicProfileErrorMsg: (state, { payload }) => {
            state.companyPublicProfile.errorMsg = payload
            state.companyPublicProfile.companyPublicProfile = ONBOARDING_INITIAL_STATE.companyPublicProfile.companyPublicProfile
        },
        setClearCompanyPublicProfile: (state) => {
            state.companyPublicProfile = ONBOARDING_INITIAL_STATE.companyPublicProfile
        },

        // reducers for update company public profile
        setAddCompanyPublicProfileLoading: (state, { payload }) => {
            state.addCompanyPublicProfile.isLoading = payload
        },
        setAddCompanyPublicProfileErrorMsg: (state, { payload }) => {
            state.addCompanyPublicProfile.errorMsg = payload
        },
        setClearAddCompanyPublicProfile: (state) => {
            state.addCompanyPublicProfile = ONBOARDING_INITIAL_STATE.addCompanyPublicProfile
        },

        // reducers for company course list
        setCompanyCourseListLoading: (state, { payload }) => {
            state.companyCourseList.isLoading = payload
        },
        setCompanyCourseList: (state, { payload }) => {
            state.companyCourseList.companyCourseList = payload
            state.companyCourseList.errorMsg = ONBOARDING_INITIAL_STATE.companyCourseList.errorMsg
        },
        setCompanyCourseListErrorMsg: (state, { payload }) => {
            state.companyCourseList.errorMsg = payload
            state.companyCourseList.companyCourseList = ONBOARDING_INITIAL_STATE.companyCourseList.companyCourseList
        },
        setClearCompanyCourseList: (state) => {
            state.companyCourseList = ONBOARDING_INITIAL_STATE.companyCourseList
        },

        // reducers for employee list
        setEmployeeListLoading: (state, { payload }) => {
            state.employeeList.isLoading = payload
        },
        setEmployeeList: (state, { payload }) => {
            state.employeeList.employeeList = payload
            state.employeeList.errorMsg = ONBOARDING_INITIAL_STATE.employeeList.errorMsg
        },
        setEmployeeListErrorMsg: (state, { payload }) => {
            state.employeeList.errorMsg = payload
            state.employeeList.employeeList = ONBOARDING_INITIAL_STATE.employeeList.employeeList
        },
        setClearEmployeeList: (state) => {
            state.employeeList = ONBOARDING_INITIAL_STATE.employeeList
        },

        // reducers for add employee
        setAddEmployeeLoading: (state, { payload }) => {
            state.addEmployee.isLoading = payload
        },
        setAddEmployeeErrorMsg: (state, { payload }) => {
            state.addEmployee.errorMsg = payload
        },
        setClearAddEmployee: (state) => {
            state.addEmployee = ONBOARDING_INITIAL_STATE.addEmployee
        },

        // reducers for edit employee detail payload
        setEmployeeDetailPayload: (state, { payload }) => {
            state.employeeDetailPayload.employeeDetailPayload = payload
        },
        setClearEmployeeDetailPayload: (state, { payload }) => {
            state.employeeDetailPayload = ONBOARDING_INITIAL_STATE.employeeDetailPayload
        },

        // reducers for company review list
        setCompanyReviewListLoading: (state, { payload }) => {
            state.companyReviewList.isLoading = payload
        },
        setCompanyReviewList: (state, { payload }) => {
            state.companyReviewList.companyReviewList = payload
            state.companyReviewList.errorMsg = ONBOARDING_INITIAL_STATE.companyReviewList.errorMsg
        },
        setCompanyReviewListErrorMsg: (state, { payload }) => {
            state.companyReviewList.errorMsg = payload
            state.companyReviewList.companyReviewList = ONBOARDING_INITIAL_STATE.companyReviewList.companyReviewList
        },
        setClearCompanyReviewList: (state) => {
            state.companyReviewList = ONBOARDING_INITIAL_STATE.companyReviewList
        },

        // reducers for important links list
        setImportantLinksListLoading: (state, { payload }) => {
            state.importantLinksList.isLoading = payload
        },
        setImportantLinksList: (state, { payload }) => {
            state.importantLinksList.importantLinksList = payload
            state.importantLinksList.errorMsg = ONBOARDING_INITIAL_STATE.importantLinksList.errorMsg
        },
        setImportantLinksListErrorMsg: (state, { payload }) => {
            state.importantLinksList.errorMsg = payload
            state.importantLinksList.importantLinksList = ONBOARDING_INITIAL_STATE.importantLinksList.importantLinksList
        },
        setClearImportantLinksList: (state) => {
            state.importantLinksList = ONBOARDING_INITIAL_STATE.importantLinksList
        },

        // reducers for edit important links
        setAddImportantLinksLoading: (state, { payload }) => {
            state.addImportantLinks.isLoading = payload
        },
        setAddImportantLinksErrorMsg: (state, { payload }) => {
            state.addImportantLinks.errorMsg = payload
        },
        setClearAddImportantLinks: (state, { payload }) => {
            state.addImportantLinks = ONBOARDING_INITIAL_STATE.addImportantLinks
        },

        // reducers for links detail payload
        setImportantLinksDetailPayload: (state, { payload }) => {
            state.importantLinksDetailPayload.importantLinksDetailPayload = payload
        },
        setClearImportantLinksDetailPayload: (state) => {
            state.importantLinksDetailPayload = ONBOARDING_INITIAL_STATE.importantLinksDetailPayload
        },

        // reducers for company document list
        setCompanyDocumentListLoading: (state, { payload }) => {
            state.companyDocumentList.isLoading = payload
        },
        setCompanyDocumentList: (state, { payload }) => {
            state.companyDocumentList.companyDocumentList = payload
            state.companyDocumentList.errorMsg = ONBOARDING_INITIAL_STATE.companyDocumentList.errorMsg
        },
        setCompanyDocumentListErrorMsg: (state, { payload }) => {
            state.companyDocumentList.errorMsg = payload
            state.companyDocumentList.companyDocumentList = ONBOARDING_INITIAL_STATE.companyDocumentList.companyDocumentList
        },
        setClearCompanyDocumentList: (state) => {
            state.companyDocumentList = ONBOARDING_INITIAL_STATE.companyDocumentList
        },

        // reducers for edit, update, delete document list
        setAddCompanyDocumentLoading: (state, { payload }) => {
            state.addCompanyDocument.isLoading = payload
        },
        setAddCompanyDocumentErrorMsg: (state, { payload }) => {
            state.addCompanyDocument.errorMsg = payload
        },
        setClearAddCompanyDocument: (state) => {
            state.addCompanyDocument = ONBOARDING_INITIAL_STATE.addCompanyDocument
        },

        // reducers for document payload
        setCompanyDocumentPayload: (state, { payload }) => {
            state.companyDocumentPayload.companyDocumentPayload = payload
        },
        setClearCompanyDocumentPayload: (state) => {
            state.companyDocumentPayload = ONBOARDING_INITIAL_STATE.companyDocumentPayload
        },

        // reducers for similar company list
        setSimilarCompanyListLoading: (state, { payload }) => {
            state.similarCompanyList.isLoading = payload
        },
        setSimilarCompanyList: (state, { payload }) => {
            state.similarCompanyList.similarCompanyList = payload
            state.similarCompanyList.errorMsg = ONBOARDING_INITIAL_STATE.similarCompanyList.errorMsg
        },
        setSimilarCompanyListErrorMsg: (state, { payload }) => {
            state.similarCompanyList.errorMsg = payload
            state.similarCompanyList.similarCompanyList = ONBOARDING_INITIAL_STATE.similarCompanyList.similarCompanyList
        },
        setClearSimilarCompanyList: (state) => {
            state.similarCompanyList = ONBOARDING_INITIAL_STATE.similarCompanyList
        },

        // reducers for company FAQs
        setFAQListLoading: (state, { payload }) => {
            state.FAQList.isLoading = payload
        },
        setFAQList: (state, { payload }) => {
            state.FAQList.FAQList = payload
            state.FAQList.errorMsg = ONBOARDING_INITIAL_STATE.FAQList.errorMsg
        },
        setFAQListErrorMsg: (state, { payload }) => {
            state.FAQList.errorMsg = payload
            state.FAQList.FAQList = ONBOARDING_INITIAL_STATE.FAQList.FAQList
        },
        setClearFAQList: (state) => {
            state.FAQList = ONBOARDING_INITIAL_STATE.FAQList
        },

        // reducers for edit FAQ's
        setAddFAQLoading: (state, { payload }) => {
            state.addFAQ.isLoading = payload
        },
        setAddFAQErrorMsg: (state, { payload }) => {
            state.addFAQ.errorMsg = payload
        },
        setClearAddFAQ: (state) => {
            state.addFAQ = ONBOARDING_INITIAL_STATE.addFAQ
        },

        // reducers for FAQ payload
        setFAQPayload: (state, { payload }) => {
            state.FAQPayload.FAQPayload = payload
        },
        setClearFAQPayload: (state) => {
            state.FAQPayload = ONBOARDING_INITIAL_STATE.FAQPayload
        },


        // reducers for filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        setClearFilterProps: (state) => {
            state.filterProps = ONBOARDING_INITIAL_STATE.filterProps
        }
    },

    extraReducers: {
        // extra reducers for get onboarding list
        [getOnboardingList.pending]: (state) => {
            state.onboardingList.isLoading = true
        },
        [getOnboardingList.fulfilled]: (state, { payload }) => {
            state.onboardingList.isLoading = false
            state.onboardingList.onboardingList = payload
            state.onboardingList.filteredOnboardingList = payload
            state.onboardingList.errorMsg = ONBOARDING_INITIAL_STATE.onboardingList.errorMsg
        },
        [getOnboardingList.rejected]: (state, { payload }) => {
            state.onboardingList.isLoading = false
            state.onboardingList.errorMsg = payload
            state.onboardingList.filteredOnboardingList = ONBOARDING_INITIAL_STATE.onboardingList.filteredOnboardingList
            state.onboardingList.onboardingList = ONBOARDING_INITIAL_STATE.onboardingList.onboardingList
        },

        // extra reducers for user onboarding list
        [getUserOnboardingListByUserId.pending]: (state) => {
            state.userOnboardingList.isLoading = true
        },
        [getUserOnboardingListByUserId.fulfilled]: (state, { payload }) => {
            state.userOnboardingList.isLoading = false
            state.userOnboardingList.userOnboardingList = payload
            state.userOnboardingList.errorMsg = ONBOARDING_INITIAL_STATE.userOnboardingList.errorMsg
        },
        [getUserOnboardingListByUserId.rejected]: (state, { payload }) => {
            state.userOnboardingList.isLoading = false
            state.userOnboardingList.errorMsg = payload
            state.userOnboardingList.userOnboardingList = ONBOARDING_INITIAL_STATE.userOnboardingList.userOnboardingList
        },

        // extra reducers for onboarding detail
        [getOnboardingDetailByOnboardingId.pending]: (state) => {
            state.onboardingDetail.isLoading = true
        },
        [getOnboardingDetailByOnboardingId.fulfilled]: (state, { payload }) => {
            state.onboardingDetail.isLoading = false
            state.onboardingDetail.onboardingDetail = payload
            state.onboardingDetail.errorMsg = ONBOARDING_INITIAL_STATE.onboardingDetail.errorMsg
        },
        [getOnboardingDetailByOnboardingId.rejected]: (state, { payload }) => {
            state.onboardingDetail.isLoading = false
            state.onboardingDetail.errorMsg = payload
            state.onboardingDetail.onboardingDetail = ONBOARDING_INITIAL_STATE.onboardingDetail.onboardingDetail
        },
    }
})

export const {
    setTutorOnboardingListLoading,
    setTutorOnboardingList,
    setTutorOnboardingListErrorMsg,
    setClearTutorOnboardingList,

    setClearOnboardingList,

    setTutorOnboardingDetailLoading,
    setTutorOnboardingDetail,
    setTutorOnboardingDetailErrorMsg,
    setClearOnboardingDetail,

    setPersonalDetailLoading,
    setPersonalDetail,
    setPersonalDetailErrorMsg,
    setClearPersonalDetail,


    setOnboardingUserDetailLoading,
    setOnboardingUserDetail,
    setOnboardingUserDetailErrorMsg,
    setClearOnboardingUserDetail,

    setAddOnboardingUserDetailLoading,
    setAddOnboardingUserDetail,
    setAddOnboardingUserDetailErrorMsg,
    setClearAddOnboardingUserDetail,

    setUserOnboardingQuestionDetailLoading,
    setUserOnboardingQuestionDetail,
    setUserOnboardingQuestionDetailErrorMsg,
    setClearUserOnboardingQuestionDetail,

    setAddUserOnboardingQuestionDetailLoading,
    setAddUserOnboardingQuestionDetail,
    setAddUserOnboardingQuestionDetailErrorMsg,
    setClearAddUserOnboardingQuestionDetail,

    setOnboardingDocListLoading,
    setOnboardingDocList,
    setOnboardingDocListErrorMsg,
    setClearOnboardingDocList,

    setAddOnboardingDocDetailLoading,
    setAddOnboardingDocDetail,
    setAddOnboardingDocDetailErrorMsg,
    setClearAddOnboardingDocDetail,

    setAddOnboardingDocDetailPayload,
    setClearAddOnboardingDocDetailPayload,

    setInterviewDetailLoading,
    setInterviewDetail,
    setInterviewDetailErrorMsg,
    setClearInterviewDetail,

    setOnboardingInterviewDetailLoading,
    setOnboardingInterviewDetail,
    setOnboardingInterviewDetailErrorMsg,
    setClearOnboardingInterviewDetail,

    setAddOnboardingInterviewDetailLoading,
    setAddOnboardingInterviewDetail,
    setAddOnboardingInterviewDetailErrorMsg,
    setClearAddOnboardingInterviewDetail,

    setOnboardingTutorPrefDetailLoading,
    setOnboardingTutorPrefDetail,
    setOnboardingTutorPrefDetailErrorMsg,
    setClearOnboardingTutorPrefDetail,

    setAddOnboardingTutorPrefDetailLoading,
    setAddOnboardingTutorPrefDetailErrorMsg,

    setOnboardingTutorTrialCourseDetailLoading,
    setOnboardingTutorTrialCourseDetail,
    setOnboardingTutorTrialCourseDetailErrorMsg,
    setClearOnboardingTutorTrialCourseDetail,

    setAddOnboardingTutorTrialCourseDetailLoading,
    setAddOnboardingTutorTrialCourseDetail,
    setAddOnboardingTutorTrialCourseDetailErrorMsg,
    setClearAddOnboardingTutorTrialCourseDetail,

    setOnboardingCommentListLoading,
    setOnboardingCommentListData,
    setOnboardingCommentListMessage,
    resetOnboardingCommentList,

    setAddOnboardingCommentDetailLoading,
    setAddOnboardingCommentDetailMessage,
    resetAddOnboardingCommentDetail,

    setProfessionalReferencesLoading,
    setProfessionalReferences,
    setProfessionalReferencesErrorMsg,
    setClearProfessionalReferences,

    setBackgroundDetailLoading,
    setBackgroundDetail,
    setBackgroundDetailErrorMsg,

    setCompanyOnboardingLoading,
    setCompanyOnboarding,
    setCompanyOnboardingErrorMsg,
    setClearCompanyOnboardingList,

    setCompanyPublicProfileLoading,
    setCompanyPublicProfile,
    setCompanyPublicProfileErrorMsg,
    setClearCompanyPublicProfile,

    setAddCompanyPublicProfileLoading,
    setAddCompanyPublicProfileErrorMsg,
    setClearAddCompanyPublicProfile,

    setCompanyCourseListLoading,
    setCompanyCourseList,
    setCompanyCourseListErrorMsg,
    setClearCompanyCourseList,

    setEmployeeListLoading,
    setEmployeeList,
    setEmployeeListErrorMsg,
    setClearEmployeeList,

    setAddEmployeeLoading,
    setAddEmployeeErrorMsg,

    setEmployeeDetailPayload,
    setClearEmployeeDetailPayload,

    setCompanyReviewListLoading,
    setCompanyReviewList,
    setCompanyReviewListErrorMsg,
    setClearCompanyReviewList,

    setImportantLinksListLoading,
    setImportantLinksList,
    setImportantLinksListErrorMsg,
    setClearImportantLinksList,

    setAddImportantLinksLoading,
    setAddImportantLinksErrorMsg,
    setClearAddImportantLinks,

    setImportantLinksDetailPayload,
    setClearImportantLinksDetailPayload,

    setCompanyDocumentListLoading,
    setCompanyDocumentList,
    setCompanyDocumentListErrorMsg,
    setClearCompanyDocumentList,

    setAddCompanyDocumentLoading,
    setAddCompanyDocumentErrorMsg,

    setCompanyDocumentPayload,
    setClearCompanyDocumentPayload,

    setSimilarCompanyListLoading,
    setSimilarCompanyList,
    setSimilarCompanyListErrorMsg,
    setClearSimilarCompanyList,

    setFAQListLoading,
    setFAQList,
    setFAQListErrorMsg,
    setClearFAQList,

    setAddFAQLoading,
    setAddFAQErrorMsg,

    setFAQPayload,
    setClearFAQPayload,

    setFilterProps,
    setClearFilterProps
} = onboarding.actions

export default onboarding.reducer