import { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation, Link, Navigate } from "react-router-dom";

import { FaEye, FaRegComment } from 'react-icons/fa';
import { BsCheck2All } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

import FullPageLoader from 'components/loader/FullPageLoader';
import SideOverPanel from 'components/common-components/SideOverPanel';
import DetailedView from 'components/detailedView/DetailedView';
import NavigationOptions from "components/navigationOptions/NavigationOptions";

import { PageContentStyle } from "pages/auth/editTutorOnboard/EditTutorOnboard.style";
import EditOnboardingSidePanel from 'pages/auth/editTutorOnboard/components/editOnboardingCommentSidePanel/EditOnboardingSidePanel';
import { editOnboardingNavigationOptions, editOnboardingHeaderConst } from 'pages/auth/editTutorOnboard/data';

import { getOnboardingDetailByOnboardingId } from "redux/onboarding/onboarding.request";
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearOnboardingDetail } from 'redux/onboarding/onboarding.slice';
import { onBoardingStatus } from 'redux/onboarding/onboarding.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";

const TutorImageContainer = ({ profilePicUrl }) => {
    return (
        <div className={"w-14 h-14 overflow-hidden rounded-full"}>
            <img
                src={profilePicUrl}
                className={"w-full h-full object-cover"}
                alt={"Profile_Picture"}
            />
        </div>
    )
}

const TutorEmailContainer = ({ email, isEmailVerified }) => {
    return (
        <div className={"flex flex-col items-start justify-start sm:flex-row sm:justify-center sm:items-center gap-1"}>
            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                {email}
            </span>
            {isEmailVerified &&
                <BsCheck2All className={"text-green-500 text-base"} />
            }
            {!isEmailVerified &&
                <TiDeleteOutline className={"text-red-500 text-base"} />
            }
        </div>
    )
}

const TutorMobileNoContainer = ({ mobileNo, isdCode, isMobileNoVerified }) => {
    return (isdCode && mobileNo) ? (
        <div className={"flex flex-col items-start justify-start sm:flex-row sm:justify-center sm:items-center gap-1"}>
            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                {"+" + isdCode + " - " + mobileNo}
            </span>
            {isMobileNoVerified &&
                <BsCheck2All className={"text-green-500 text-sm"} />
            }
            {!isMobileNoVerified &&
                <TiDeleteOutline className={"text-red-500 text-sm"} />
            }
        </div>
    ) : "-"
}

const EditTutorOnboardPage = ({ allowedRoles }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((state) => state.local)
    const { session } = useAppState((state) => state.auth)
    const { user } = useAppState((state) => state.user)
    const { onboardingDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()
    const location = useLocation()

    const [sideOverPanel, setSideOverPanel] = useState(false)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_TUTOR_ONBOARD))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingDetailByOnboardingId(onboardingId))
        }

        return () => {
            dispatcher(setClearOnboardingDetail())
        }
    }, [onboardingId])

    const onHandleShowComments = (event) => {
        event.preventDefault()
        setSideOverPanel(true)
    }

    const rows = [
        <TutorImageContainer profilePicUrl={onboardingDetail?.onboardingDetail?.user?.profilePicUrl} />,
        onboardingDetail?.onboardingDetail?.onboardingId,
        <Link
            to={`${pagesInfo?.VIEW_USER?.pagePath}/${onboardingDetail?.onboardingDetail?.user?.userId}`}
            className={"hover:text-primary-main hover:underline cursor-pointer"}
        >
            {onboardingDetail?.onboardingDetail?.user?.userId}
        </Link>,
        onboardingDetail?.onboardingDetail?.user?.firstName + " " + onboardingDetail?.onboardingDetail?.user?.lastName?.charAt(0),
        <TutorEmailContainer
            email={onboardingDetail?.onboardingDetail?.user?.email}
            isEmailVerified={onboardingDetail?.onboardingDetail?.user?.isEmailVerified}
        />,
        <TutorMobileNoContainer
            mobileNo={onboardingDetail?.onboardingDetail?.user?.mobileNo}
            isdCode={onboardingDetail?.onboardingDetail?.user?.isdCode}
            isMobileNoVerified={onboardingDetail?.onboardingDetail?.user?.isMobileNoVerified}
        />,
        onBoardingStatus[onboardingDetail?.onboardingDetail?.status?.toUpperCase()]?.label,
        dayjs(onboardingDetail?.onboardingDetail?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A'),
        <Link
            to={`${pagesInfo?.VIEW_TUTOR_ONBOARD?.pagePath}/${onboardingDetail?.onboardingDetail?.onboardingId}`}
            className="px-2 py-1 text-text-700"
        >
            <FaEye className={"text-xl hover:scale-110"} />
        </Link>
    ]

    if (onboardingDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingDetail?.isLoading} />
        )
    }

    if (onboardingDetail?.errorMsg) {
        return (
            <div className={"w-screen h-screen flex items-center justify-center bg-white font-bodyPri font-normal text-red-500 text-base"}>
                {onboardingDetail?.errorMsg}
            </div>
        )
    }

    return (
        <PageContentStyle>
            <SideOverPanel
                child={<EditOnboardingSidePanel />}
                isOpen={sideOverPanel}
                onClose={(isShow) => setSideOverPanel(isShow)}
            />
            <div className={"w-full h-full min-h-screen mx-auto p-5 space-y-3"}>
                <DetailedView
                    headers={Object.values(editOnboardingHeaderConst)}
                    rows={rows}
                    rowHeaderColor={"bg-background-medium"}
                    rowColor={`bg-${onBoardingStatus[onboardingDetail?.onboardingDetail?.status?.toUpperCase()]?.lightColor}`}
                />
                <div className={"w-full flex flex-col items-start justify-start space-y-3 md:space-y-0 md:space-x-3 md:flex-row"}>
                    <div className={"sticky top-[5rem] w-full md:w-fit"}>
                        <NavigationOptions
                            options={editOnboardingNavigationOptions}
                            excludedNavOptions={["Comments"]}
                            onHandleButtonClick={(event) => onHandleShowComments(event)}
                        />
                    </div>
                    <div className={"w-full rounded-lg p-5 bg-white"}>
                        {session?.isLoggedIn && user?.user?.roles?.find((role) => allowedRoles?.includes(role?.role) && role.role === locals.userRole)
                            ? <Outlet />
                            : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: location }} replace />
                        }
                    </div>
                </div>
            </div>
        </PageContentStyle>
    )
}

export default EditTutorOnboardPage;