import { useAppState } from "hooks/useStore";
import { masterCurrencyCodeEnum } from "redux/master/master.const";
import { payoutType } from "redux/withdrawal/withdrawal.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const WithdrawalRequestDetail = () => {
    const { withdrawalDetail } = useAppState((state) => state.withdrawal)

    return withdrawalDetail?.withdrawalDetail && (
        <div className={"my-3 space-y-3"}>
            <div className={"w-full font-bodyPri font-medium text-text-900 text-lg"}>
                {"Withdrawal Request Details:"}
            </div>
            <ul className={"w-full flex flex-col items-start justify-start gap-3 list-disc list-inside"}>
                <li className={"flex justify-start items-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Net Payout:"}
                    </span>
                    <span
                        className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                        {masterCurrencyCodeEnum[withdrawalDetail?.withdrawalDetail?.masterCurrency?.code?.toUpperCase()]?.label + " " + (withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.effectiveAmount / 100).toFixed(2)}
                    </span>
                </li>
                <li className={"flex justify-start items-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Requested Amount:"}
                    </span>
                    <span
                        className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                        {masterCurrencyCodeEnum[withdrawalDetail?.withdrawalDetail?.masterCurrency?.code?.toUpperCase()]?.label + " " + (withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.requestedAmount / 100).toFixed(2)}
                    </span>
                </li>
                <li className={"flex justify-start items-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Processing Fee:"}
                    </span>
                    <span
                        className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                        {masterCurrencyCodeEnum[withdrawalDetail?.withdrawalDetail?.masterCurrency?.code?.toUpperCase()]?.label + " " + (withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.processingFee?.toFixed(2))}
                    </span>
                </li>
                <li className={"flex justify-start items-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Payout Method:"}
                    </span>
                    <span className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                        {payoutType[withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.payoutType?.toUpperCase()]?.label}
                    </span>
                </li>
                {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.accountHolderName &&
                    <li className={"flex justify-start items-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                            {"Account Holder Name:"}
                        </span>
                        <span
                            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.accountHolderName}
                        </span>
                    </li>
                }
                {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.bankAccountNumber &&
                    <li className={"flex justify-start items-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                            {"Bank Account Number:"}
                        </span>
                        <span
                            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.bankAccountNumber}
                        </span>
                    </li>
                }
                {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.bankIfscCode &&
                    <li className={"flex justify-start items-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                            {"Bank Code:"}
                        </span>
                        <span
                            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.bankIfscCode}
                        </span>
                    </li>
                }
                {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.upiId &&
                    <li className={"flex justify-start items-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                            {"UPI ID:"}
                        </span>
                        <span
                            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.upiId}
                        </span>
                    </li>
                }
                {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.payId &&
                    <li className={"flex justify-start items-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                            {"Pay ID:"}
                        </span>
                        <span
                            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.payId}
                        </span>
                    </li>
                }

                {/* {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.payPal &&
                    <li className={"flex justify-start items-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                            {"PayPal ID:"}
                        </span>
                        <span
                            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.payPal}
                        </span>
                    </li>
                } */}
                <li className={"flex justify-start items-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Requested Date:"}
                    </span>
                    <span
                        className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                        {dayjs(withdrawalDetail?.withdrawalDetail?.createdAt)?.tz(timeZone)?.format('dddd, MMM DD, YYYY h:mm A')}
                    </span>
                </li>
            </ul>
        </div>
    )
}

export default WithdrawalRequestDetail