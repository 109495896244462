import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";

import { FiEdit } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';
import { MdOutlineStickyNote2 } from 'react-icons/md';

import FullPageLoader from 'components/loader/FullPageLoader';
import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { tutorOnboardingHeaderConst, subRowHeader, sortTutorOnboardingHighToLow, sortTutorOnboardingLowToHigh } from "pages/auth/onboarding/data";

import { getOnboardingList, updateOnboardingListByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { onBoardingStatus, onboardingActionRequired } from 'redux/onboarding/onboarding.const';

import { setClearTutorOnboardingList } from 'redux/onboarding/onboarding.slice';
import { setModal, setStickyNotePayload } from 'redux/local/local.slice';
import { modalConst, stickyNoteCommentsType } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { generateQueryParams } from 'utils/generators.utils';

const TutorImageContainer = ({ profilePicUrl }) => {
    return (
        <div className={"w-10 h-10 overflow-hidden rounded-full"}>
            <img
                src={profilePicUrl}
                className={"w-full h-full object-cover"}
                alt={"Profile_Picture"}
            />
        </div>
    )
}

const OnboardingActivity = ({ tutor, handleStickyNote }) => {
    return (
        <div className={"flex justify-start items-center"}>
            <Link
                to={`${pagesInfo?.VIEW_TUTOR_ONBOARD?.pagePath}/${tutor?.onboardingId}`}
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
            >
                <FaEye className={"text-xl hover:scale-110"} />
            </Link>
            <Link
                to={`${pagesInfo?.EDIT_TUTOR_ONBOARD?.pagePath}/${tutor?.onboardingId}/edit`}
                className={"px-2 py-1 bg-background-lightDark text-white"}
            >
                <FiEdit className={"text-xl hover:scale-110"} />
            </Link>
            <div
                className={"px-2 py-1 bg-secondary-main text-white rounded-r-md cursor-pointer"}
                onClick={() => handleStickyNote({ tutor: tutor })}
            >
                <MdOutlineStickyNote2 className={"text-xl hover:scale-110"} />
            </div>
        </div>
    )
}

const TutorOnboardingTable = ({ showEntries }) => {
    const { modal, stickyNotePayload } = useAppState((state) => state.local)
    const { onboardingList } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        const query = generateQueryParams(location.search)
        dispatcher(getOnboardingList({ page: query.pn || 1, records: showEntries }))

        return () => {
            dispatcher(setClearTutorOnboardingList())
        }
    }, [location.search, showEntries])

    useEffect(() => {
        if (stickyNotePayload.commentsType === stickyNoteCommentsType.ONBOARDING.value && stickyNotePayload?.comments) {
            dispatcher(updateOnboardingListByOnboardingId({ onboardingId: stickyNotePayload?.id, body: { comments: stickyNotePayload?.comments } }))
            setTimeout(() => {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.STICKY_NOTE_MODAL.stateKey]: false
                }))
            }, 2000)
        }
    }, [stickyNotePayload?.comments])

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortTutorOnboardingList = (tutor1, tutor2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortTutorOnboardingLowToHigh(activeSortHeader, tutor1, tutor2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortTutorOnboardingHighToLow(activeSortHeader, tutor1, tutor2)
        }
    }

    const handleStickyNote = ({ tutor }) => {
        dispatcher(setStickyNotePayload({
            ...stickyNotePayload,
            isLoading: false,
            title: stickyNoteCommentsType.ONBOARDING.title,
            commentsType: stickyNoteCommentsType.ONBOARDING.value,
            id: tutor.onboardingId,
            comments: "",
            note: tutor.comments
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.STICKY_NOTE_MODAL.stateKey]: true
        }))
    }

    const rows = onboardingList?.filteredOnboardingList?.results?.slice(0)?.sort(sortTutorOnboardingList)?.map((tutor, index) => [
        <TutorImageContainer profilePicUrl={tutor?.user?.profilePicUrl} />,
        tutor?.onboardingId || "-",
        tutor?.user?.userId || "-",
        tutor?.user?.firstName + " " + tutor?.user?.lastName?.charAt(0),
        tutor?.user?.email
            ? (tutor?.user?.email?.length > 24)
                ? tutor?.user?.email?.slice(0, 24) + " ..."
                : tutor?.user?.email
            : "-",
        (tutor?.user?.isdCode && tutor?.user?.mobileNo) && "+" + tutor?.user?.isdCode + tutor?.user?.mobileNo,
        onBoardingStatus[tutor?.status?.toUpperCase()]?.label,
        dayjs(tutor?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        <OnboardingActivity tutor={tutor} handleStickyNote={handleStickyNote} />,
        onboardingActionRequired[tutor?.actionRequired.toUpperCase()].label,
        dayjs(tutor?.updatedAt).tz(timeZone).format('DD MMM, YYYY')
    ]);

    if (onboardingList?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingList?.isLoading} />
        )
    }

    return (
        <>
            <Table
                headers={Object.values(tutorOnboardingHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
            />
            {(onboardingList.filteredOnboardingList && onboardingList.filteredOnboardingList?.records > 0) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={onboardingList.filteredOnboardingList?.page}
                        totalPages={onboardingList.filteredOnboardingList?.totalPages}
                        onChangePage={(page) => {
                            navigate(pagesInfo.ONBOARDING.pagePath + "?pn=" + page)
                        }}
                    />
                </div>
            }
            {(onboardingList?.errorMsg || onboardingList.filteredOnboardingList?.records === 0) &&
                <div className={"flex justify-center items-center font-bodyPri font-normal text-text-800 text-base"}>
                    {onboardingList?.errorMsg}
                </div>
            }
        </>
    )
}

export default TutorOnboardingTable