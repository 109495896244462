import { createAsyncThunk } from "@reduxjs/toolkit";

import categoryService from "redux/category/category.service";

import { categoryRedux } from "redux/category/category.const";

export const getAllCategoryList = createAsyncThunk(
    `${categoryRedux.sliceName}/${categoryRedux.requestName}`,
    async (_, { rejectWithValue }) => {

        try {
            const response = await categoryService.getAllCategoryList();
            return response;
        } catch (error) {
            console.error(error)
            return rejectWithValue([], error)
        }
    }
);
