import { cn } from "utils/cn.utils";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddCourseAvailDetailPayload } from 'redux/course/course.slice';
import { courseIsRecurringEnum } from 'redux/course/course.const';

import { getWeeklyAvailTimeSlotsWithDuration, getDateTimeSlotsWithDuration } from 'utils/availability.utils';
import { timeZone, weekdays } from 'utils/dateTime.utils';

const GroupAvailabilityInputs = ({ scheduleDuration }) => {
    const { addCourseAvailDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const onHandleSessionDuration = async (duration) => {
        const weeklyAvail = addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots
        const dateTimeAvailabilities = addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots
        const sessionDuration = duration * 15
        if (addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum?.RECURRING?.value) {
            const newWeeklyAvail = getWeeklyAvailTimeSlotsWithDuration({ timeZone, weekdays, weeklyAvail, sessionDuration })

            dispatcher(setAddCourseAvailDetailPayload({
                ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                duration: duration,
                weeklyTimeSlots: newWeeklyAvail,
            }))
        } else {
            const newDateTimeSlotAvail = await getDateTimeSlotsWithDuration(timeZone, dateTimeAvailabilities, sessionDuration)
            dispatcher(setAddCourseAvailDetailPayload({
                ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                duration: duration,
                dateTimeSlots: newDateTimeSlotAvail,
            }))
        }
    }

    return (
        <div className={"grid grid-cols-12 gap-5 w-full"}>
            <div className={"col-start-1 col-span-2 pt-2"}>
                <span className={"font-bodyPri font-normal text-text-900 text-base sm:whitespace-nowrap"}>
                    {"Session Duration:"}
                    <span className={"font-bodyPri font-normal text-red-500 text-base"}>{"*"}</span>
                </span>
            </div>

            <div className={"col-start-4 col-span-full flex flex-col items-start justify-start gap-1"}>
                <div className={"flex items-center justify-start gap-3"}>
                    <input
                        type={"number"}
                        className={cn(
                            "w-16 px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400 bg-complementry-light",
                            "font-bodyPri font-normal text-text-900 text-lg",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        minValue={2}
                        defaultValue={2}
                        value={scheduleDuration?.toString()}
                        onChange={(event) => onHandleSessionDuration(event?.target?.value)}
                        required={true}
                    />
                    <span className={"font-bodyPri font-normal text-text-800 text-base whitespace-nowrap"}>
                        {"x 15 min. ="}
                    </span>
                    <div className={"inline-flex items-center justify-start gap-1 font-bodyPri font-bold text-text-800 text-lg"}>
                        <span className={""}>
                            {scheduleDuration <= 3
                                ? scheduleDuration * 15
                                : (parseFloat("" + (scheduleDuration * 15 / 60))?.toFixed(2))
                            }
                        </span>
                        <span className={""}>
                            {scheduleDuration <= 3
                                ? " Minutes"
                                : " Hour(s)"
                            }
                        </span>
                    </div>
                </div>
                {scheduleDuration < 1 &&
                    <div className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {"session duration can not be 0"}
                    </div>
                }
            </div>
        </div>

    )
}

export default GroupAvailabilityInputs;