import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";

import ProductService from "./product.service";
import {
    setProductListLoading,
    setProductListData,
    setProductListMessage,

    setDestroyProductDetailLoading,
    setDestroyProductDetailMessage,
} from "redux/product/product.slice";
import { productRedux } from "./product.const";

export const getProductList = createAsyncThunk(
    `${productRedux?.sliceName}/${productRedux?.productList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setProductListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await ProductService.getProductList(requestData)
            if (response.status === 200) {
                dispatch(setProductListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setProductListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setProductListLoading(false))
        }
    }
)

// delete product detail
export const deleteProductDetail = createAsyncThunk(
    `${productRedux?.sliceName}/${productRedux?.productList?.requestName}`,
    async (productId, { dispatch }) => {
        dispatch(setDestroyProductDetailLoading(true))

        try {
            const requestData = {
                params: { productId: productId }
            }
            const response = await ProductService.deleteProductDetail(requestData)
            if (response.status === 204) {
                dispatch(getProductList({ page: 1, records: 25 }))
                toast.success(response.data.message || "Deleted Successfully")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setDestroyProductDetailMessage(error.response.data.message || error.response.data.error || error))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setDestroyProductDetailLoading(false))
        }
    }
)