import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ToggleWithText } from "components/common-components/Toggle";
import ComponentLoader from "components/loader/ComponentLoader";

import { setGetOtherPref } from "pages/auth/viewUser/data";
import ViewUserHeader from "pages/auth/viewUser/commonComponents/header/ViewUserHeader";

import { getUserOtherPrefDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserOtherPref } from "redux/user/user.slice";

const ViewUserOtherPref = () => {
    const { userOtherPref } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher();
    const { userId } = useParams()

    useEffect(() => {
        if (userId) {
            dispatcher(getUserOtherPrefDetailByUserId(Number(userId)))
        }

        return () => {
            dispatcher(setClearUserOtherPref())
        }
    }, [userId])

    const updateOtherStatus = (key, value) => {
        return;
    }

    return (
        <div className="w-full space-y-3">

            <ViewUserHeader title={"Other Preferences"} />

            {userOtherPref?.isLoading &&
                <ComponentLoader isLoading={userOtherPref?.isLoading} />
            }

            {!userOtherPref?.isLoading &&
                <div className="w-full p-5 space-y-2 divide-y divide-blue-50">
                    {userOtherPref?.userOtherPref &&
                        setGetOtherPref(userOtherPref?.userOtherPref).map((other, index) => (
                            <ToggleWithText
                                key={index}
                                description={other.description}
                                onChange={(isToggle) => updateOtherStatus(other.key, isToggle)}
                                title={other.label}
                                value={other.value}
                            />
                        ))}
                    {userOtherPref?.errorMsg &&
                        <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                            {userOtherPref?.errorMsg}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default ViewUserOtherPref