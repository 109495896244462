import { useEffect } from 'react';

import { PageContentStyle, CompanySection } from 'pages/auth/editCompanyOnboarding/EditCompanyOnboarding.style';

import { tabsList } from 'pages/auth/editCompanyOnboarding/data';
import EditHero from 'pages/auth/editCompanyOnboarding/components/editHero/EditHero';
import EditCompanyInformation from 'pages/auth/editCompanyOnboarding/components/editCompanyInformation/EditCompanyInformation';
import Tabs from "pages/auth/editCompanyOnboarding/components/tabs/Tabs";
import EditAbout from "pages/auth/editCompanyOnboarding/components/editAbout/EditAbout";
import EditCourse from "pages/auth/editCompanyOnboarding/components/editCourse/EditCourse";
import EditPeople from "pages/auth/editCompanyOnboarding/components/editPeople/EditPeople";
import EditImportantLinks from "pages/auth/editCompanyOnboarding/components/editImportantLinks/EditImportantLinks";
import EditDocumentVerification from 'pages/auth/editCompanyOnboarding/components/editDocumentVerification/EditDocumentVerification';
import EditCompanyFAQ from "pages/auth/editCompanyOnboarding/components/editCompanyFAQ/EditCompanyFAQ";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';
import EditBackgroundCheck from 'pages/auth/editCompanyOnboarding/components/editBackgroundCheck/EditBackgroundCheck';

const EditCompanyOnboardingPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    const containerIDs = ["HOME", "ABOUT", "COURSES", "PEOPLE", "LINKS", "DOCUMENTS", "BACKGROUND_CHECK"]

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_COMPANY))
    }, [dispatcher, currentPageInfo])

    return (
        <PageContentStyle>
            <EditHero />
            <EditCompanyInformation />
            <CompanySection>
                <Tabs tabsList={tabsList} containerIDs={containerIDs} />
                <EditAbout />
                <EditCourse />
                <EditPeople />
                <EditImportantLinks />
                <EditDocumentVerification />
                <EditBackgroundCheck />
                <EditCompanyFAQ />
            </CompanySection>
        </PageContentStyle>
    )
}

export default EditCompanyOnboardingPage