import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CurrentDateTime from "pages/auth/viewCourseDetail/components/confirmSchedulePage/commonComponents/CurrentDateTime";
import { sortDateTimeSlots } from "pages/auth/viewCourseDetail/data";
import DoesNotRepeatScheduleCard from "./DoesNotRepeatScheduleCard";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddCourseAvailDetailPayload } from "redux/course/course.slice";
import { courseIsRecurringEnum } from "redux/course/course.const";

import { dayjs, timeZone } from 'utils/dateTime.utils';

const DoesNotRepeatSchedule = () => {
    const { addCourseAvailDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const todayDate = new Date()
    const dateAfterSevenDays = todayDate.setDate(todayDate.getDate() + 8)

    const [current, setCurrent] = useState(dayjs().tz(timeZone))
    const [selectedDateTimeSlot, setSelectedDateTimeSlot] = useState({
        date: dayjs(dateAfterSevenDays)?.tz(timeZone)?.format("YYYY-MM-DD"),
        timeSlots: []
    })
    const [isShowDatePicker, setIsShowDatePicker] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(dayjs().tz(timeZone))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        if ((addCourseAvailDetail?.addCourseAvailDetailPayload?.isRecurring === courseIsRecurringEnum.NON_RECURRING?.value) && (addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.length === 0)) {
            dispatcher(setAddCourseAvailDetailPayload({
                ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                dateTimeSlots: [selectedDateTimeSlot]
            }))
        }
    }, [addCourseAvailDetail?.addCourseAvailDetailPayload])

    return (
        <div className={"grid grid-cols-12 gap-5 w-full"}>

            <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full w-full space-y-3"}>
                <CurrentDateTime />
                <div className={"w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl mx-auto space-y-3"}>
                    {addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.length > 0 &&
                        addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTimeSlot, index) => (
                            <DoesNotRepeatScheduleCard
                                key={index}
                                dateTimeSlots={addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots}
                                dateTimeSlot={dateTimeSlot}
                            />
                        ))}
                </div>
                {(addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.length === 0 || !addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots) &&
                    <div className={"space-y-3"}>
                        <DoesNotRepeatScheduleCard
                            dateTimeSlots={addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots}
                            dateTimeSlot={selectedDateTimeSlot}
                        />
                    </div>
                }
            </div>

            <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full"}>
                {!isShowDatePicker &&
                    <span className={"font-bodyPri font-normal text-primary-main text-md cursor-pointer"} onClick={() => setIsShowDatePicker(true)}>
                        {"Add a date"}
                    </span>
                }
                {isShowDatePicker &&
                    <DatePicker
                        className={"font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm focus:outline-none"}
                        dateFormat={"dd/MM/yyyy"}
                        autoFocus={true}
                        value={new Date()}
                        selected={new Date()}
                        minDate={dateAfterSevenDays}
                        placeholderText={"Select Date"}
                        scrollableYearDropdown={true}
                        showYearDropdown={true}
                        yearDropdownItemNumber={80}
                        showMonthDropdown={true}
                        disabled={true}
                    />
                }
            </div>
        </div>
    )
}

export default DoesNotRepeatSchedule;