import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Accordion from 'components/accordion/Accordion';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { getFAQListByCompanyId } from 'redux/onboarding/onboarding.request';

const CompanyFAQ = () => {
  const { FAQList } = useAppState((state) => state.onboarding)

  const dispatcher = useAppDispatcher()
  const { companyId } = useParams()

  useEffect(() => {
    if (companyId) {
      dispatcher(getFAQListByCompanyId(Number(companyId)))
    }
  }, [companyId])

  return (
    <div className={"flex items-center justify-center px-5 md:px-0"}>
      <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
        <div className={"bg-white space-y-3 p-5"}>
          <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
            {"Frequently Asked Questions?"}
          </span>

          <div className={"grid grid-cols-12 w-full py-5"}>
            <div className={"col-span-12 md:(col-start-2 col-span-10) lg:(col-start-1 col-span-12)"}>
              {FAQList?.FAQList?.length > 0 &&
                <Accordion
                  title={''}
                  dataFAQ={FAQList?.FAQList}
                />
              }
              {FAQList?.FAQList?.length === 0 &&
                <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                  {"No FAQs added yet!"}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyFAQ