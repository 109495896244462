import format from 'string-format';

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/availability/availability.const";

class AvailabilityService {

    getAvailabilityList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_AVAILABILITY_LIST),
            { params: query }
        )

        return response;
    }

    createAvailabilityDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_AVAILABILITY_DETAIL),
            body
        )

        return response;
    }

    getAvailabilityDetail = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_AVAILABILITY_DETAIL, params),
            { params: query }
        )

        return response;
    }

    updateAvailabilityDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_AVAILABILITY_DETAIL, params),
            body
        )

        return response;
    }

    deleteAvailabilityDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_AVAILABILITY_DETAIL, params)
        )

        return response;
    }

    getAvailableSlotDetail = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_AVAILABLE_SLOT_DETAIL, params),
            { params: query }
        )

        return response;
    }
}

export default new AvailabilityService()