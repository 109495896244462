import { memo } from 'react';

import { BsInfoCircle } from 'react-icons/bs';

import ToolTipView from 'components/toolTip';

const ViewTutorHeader = memo(({ title = "", tooltipText = "" }) => {

    return (
        <div className={"w-full py-3 space-y-3"}>
            <div className="flex justify-center w-full">
                <div className="flex items-center justify-center gap-1.5">
                    <span className="font-subHeading font-medium text-2xl text-text-800">
                        {title}
                    </span>
                    {!!tooltipText &&
                        <ToolTipView content={tooltipText}>
                            <button className={""}>
                                <BsInfoCircle className="inline text-md text-text-900 hover:text-text-900" />
                            </button>
                        </ToolTipView>
                    }
                </div>
            </div>
            <div className="h-0.5 bg-gray-300 w-full"></div>
        </div>
    );
})

export default ViewTutorHeader;