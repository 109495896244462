import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    MY_PROFILE: "/users/me",
    USER_LIST: "/users/users",
    USER_DETAIL: "/users/{userId}/details",
    USER_CULTURE_DETAIL: "/users/{userId}/cultures",
    USER_EDUCATION_LIST: "/users/{userId}/educations",
    EDUCATION_DETAIL: "/users/educations/{userEducationId}",
    USER_OTHER_PREF_DETAIL: "/users/{userId}/other-prefs",
    USER_SOCIAL_LIST: "users/{userId}/socials",
    USER_SOCIAL_DETAIL: "users/socials/{userSocialId}",
    USER_ADDRESS_LIST: "users/{userId}/addresses",
    USER_ADDRESS_DETAIL: "users/addresses/{userAddressId}",

    CREATE_USER_DETAIL: "users/users"
}

export const userRedux = {
    sliceName: "user",
    myProfile: {
        requestName: "getMyProfile"
    },
    userList: {
        requestName: "getUserList"
    },
    userEducationList: {
        requestName: "getUserEducationListByUserId"
    },
    userOtherPrefDetail: {
        requestName: "getUserOtherPrefDetailByUserId"
    },
    createUser: {
        requestName: "createUserDetail"
    }
}

export const addUserDetailPayload = {
    firstName: "",
    lastName: "",
    email: "",
    isEmailVerified: false,
    timeZone: "",
    mobileNo: "",
    isMobileNoVerified: false,
    password: "",
    isPromotionActive: false,
    referralCode: "",
    promoCode: "",
    isSendWelcomeEmail: true,
}

export const filterName = {
    ALL_USERS: {
        value: "all_users"
    }
}

export const genderOptions = {
    MALE: {
        label: "Male",
        value: "male"
    },
    FEMALE: {
        label: "Female",
        value: "female"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const titleOptions = {
    MR: {
        label: "Mr",
        value: "Mr",
    },
    MS: {
        label: "Ms",
        value: "Ms"
    },
    MRS: {
        label: "Mrs",
        value: "Mrs"
    },
    MISS: {
        label: "Miss",
        value: "Miss"
    },
    Dr: {
        label: "Dr",
        value: "Dr"
    },
    SIR: {
        label: "Sir",
        value: "Sir"
    }
}

export const blueTickTypeEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const addressTypeConst = {
    HOME: {
        label: "Home",
        value: "home"
    },
    WORK: {
        label: "Work",
        value: "work"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const userStatus = {
    ALL: {
        label: "All",
        value: "all"
    },
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "Inactive",
        value: "inactive"
    },
    BANNED: {
        label: "Banned",
        value: "banned"
    }

}

export const userRoleStatus = {
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "Inactive",
        value: "inactive"
    },
    BLOCk: {
        label: "Block",
        value: "block"
    }
}

export const userFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",

    userId: "",
    userName: "",
    mobileNo: "",
    email: "",
    userStatus: userStatus.ALL.value,
    date: "",
    userRole: "",
    page: 1,
    records: dataEntriesConst?.TWENTY_FIVE?.value
}