import { BsInfoCircle } from 'react-icons/bs';

import MuiDatePicker from 'components/MUIComponents/MuiDatePicker';

import { availabilityDetailBtnConst } from "pages/auth/editUser/data";

import { useAppState } from 'hooks/useStore';
import { availabilityEndTypeEnum } from 'redux/availability/availability.const';

import { cn } from 'utils/cn.utils';
import { dayjs, timeZone } from 'utils/dateTime.utils';

const StartEndDateSchedule = () => {
    const { modifyAvailabilityDetail } = useAppState((s) => s.availability)

    return (
        <div className={"w-full flex flex-col gap-y-5 sm:gap-y-10"}>
            <div className={"w-full flex flex-col gap-y-5 sm:flex-row sm:justify-start sm:gap-x-10 sm:gap-y-0"}>
                <span className={"font-bodyPri font-medium text-gray-900"}>
                    {`${availabilityDetailBtnConst.endType.label}:`}
                    <span className={"font-bodyPri font-normal text-red-500 text-sm sm:text-base"}>{"*"}</span>
                </span>
                <div className={"flex flex-col gap-y-5 sm:flex-row sm:items-center sm:justify-start sm:gap-x-10 sm:gap-y-0"}>
                    {Object.values(availabilityEndTypeEnum).map((endType, index) => (
                        <div
                            key={index}
                            className={"flex items-center justify-start gap-2 cursor-pointer"}
                            onClick={() => { return }}
                        >
                            <input
                                type={"radio"}
                                className={""}
                                name={availabilityDetailBtnConst.endType.key}
                                value={endType?.value}
                                checked={[endType?.value]?.includes(modifyAvailabilityDetail.payload.endType)}
                                disabled={true}
                            />
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {endType?.label}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            {[availabilityEndTypeEnum.ON_DATE.value]?.includes(modifyAvailabilityDetail.payload.endType) &&
                <div className={"space-y-2"}>
                    <div className={"w-full md:w-[25rem]"}>
                        <MuiDatePicker
                            label={availabilityDetailBtnConst.endDateTime.label}
                            value={modifyAvailabilityDetail?.payload?.endDateTime ? dayjs(modifyAvailabilityDetail?.payload?.endDateTime).tz(timeZone) : null}
                            onChange={(date) => { return }}
                            slotProps={{ textField: { size: "small" } }}
                            disabled={true}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#9e9e9e",
                                        height: 50,
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#2196f3",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    fontWeight: '700',
                                    color: '#212121',
                                },
                            }}
                            format={"DD-MM-YYYY"}
                            color={"primary"}
                            disablePast={true}
                            helperText={"SelectDate"}
                            margin={"dense"}
                            minDate={dayjs().tz(timeZone)}
                        />
                    </div>
                    <div className={"flex items-center justify-start gap-2"}>
                        <BsInfoCircle className={"text-text-700 text-lg"} />
                        <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                            {"You must have a session on the end date."}
                        </span>
                    </div>
                </div>
            }
            {[availabilityEndTypeEnum.WEEKLY_CYCLE.value]?.includes(modifyAvailabilityDetail.payload.endType) &&
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                        {"After"}
                    </span>
                    <input
                        type={"number"}
                        placeholder={"Enter weekly cycles"}
                        className={cn(
                            "w-24 px-3 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-900 text-lg",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        name={availabilityDetailBtnConst.weeklyCycle.key}
                        value={modifyAvailabilityDetail?.payload?.weeklyCycle ? modifyAvailabilityDetail?.payload?.weeklyCycle?.toString() : ""}
                        onChange={(event) => { return }}
                        disabled={true}
                    />
                    <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                        {availabilityEndTypeEnum?.WEEKLY_CYCLE?.label}
                    </span>
                </div>
            }
        </div>
    )
}

export default StartEndDateSchedule;