import SetPriceDetailsSection from './components/SetPriceDetailsContainer';

const SetPrice = () => {

    return (
        <div className={"space-y-5"}>
            <SetPriceDetailsSection />
        </div>
    )
}

export default SetPrice;