import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cn } from "utils/cn.utils";

import { GrAddCircle } from "react-icons/gr";

import ScheduleTimeRange from 'pages/auth/viewCourseDetail/components/confirmSchedulePage/commonComponents/ScheduleTimeRange';

import { sortTimeSlots } from 'pages/auth/viewCourseDetail/data';

import { dayjs } from "utils/dateTime.utils";

const DoesNotRepeatScheduleCard = ({ dateTimeSlot }) => {

    const todayDate = new Date()
    const dateAfterSevenDays = todayDate.setDate(todayDate.getDate() + 7)

    return (
        <div className={"grid grid-cols-4 w-full gap-5"}>
            <div className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-1 mt-1"}>
                <DatePicker
                    className={cn(
                        "w-fit px-3 py-1 flex items-center justify-start border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm focus:outline-none"
                    )}
                    dateFormat={"dd/MM/yyyy"}
                    value={dayjs(dateTimeSlot?.date)?.toDate()}
                    selected={dayjs(dateTimeSlot?.date)?.toDate()}
                    minDate={dateAfterSevenDays}
                    placeholderText={"Select Date"}
                    scrollableYearDropdown={true}
                    showYearDropdown={true}
                    yearDropdownItemNumber={80}
                    showMonthDropdown={true}
                    disabled={true}
                />
            </div>

            {dateTimeSlot?.timeSlots?.length > 0 &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 space-y-3"}>
                    {dateTimeSlot?.timeSlots?.slice()?.sort(sortTimeSlots)?.map((timeSlot, index) => (
                        <ScheduleTimeRange
                            key={index}
                            timeSlot={timeSlot}
                        />
                    ))}
                </div>
            }

            {(!dateTimeSlot || dateTimeSlot?.timeSlots?.length === 0) &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 flex items-center justify-center"}>
                    <div className={"w-full p-1 font-bodyPri font-bold text-base text-text-800 tracking-wide text-center"}>
                        {"-"}
                    </div>
                </div>
            }

            <div className={"col-start-4 col-span-full sm:col-start-4 sm:col-span-full flex items-start justify-start mt-2.5"}>
                <div className={"flex items-center justify-start space-x-3"}>
                    <span className={"relative has-tooltip inline-flex items-center justify-center cursor-pointer"}>
                        <GrAddCircle className={"text-xl text-text-600 hover:text-text-800"} />
                        <span
                            className={cn(
                                "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip absolute -top-5",
                                "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap"
                            )}
                        >
                            {"Add a session"}
                        </span>
                    </span>
                </div>
            </div>
            <div className={"col-start-1 col-span-full h-0.5 w-full border border-divider-medium"}></div>
        </div>
    )
}

export default DoesNotRepeatScheduleCard