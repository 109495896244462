import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import PrivateRouter from 'routers/PrivateRouter';
import LayoutRouter from "routers/LayoutRouter";
import PersistLogin from "routers/PersistLoginRouter";

import EditSelectTemplatePage from "pages/auth/editCourseDetail/components/EditSelectTemplatePage";
import EditBasicInputPage from "pages/auth/editCourseDetail/components/basicInputPage/EditBasicInputPage";
import EditConfirmSchedulePage from "pages/auth/editCourseDetail/components/confirmSchedulePage/EditConfirmSchedulePage";
import EditSetPrice from "pages/auth/editCourseDetail/components/setPricePage/EditSetPrice";
import EditPreviewAndStatusPage from "pages/auth/editCourseDetail/components/previewAndStatusPage/EditPreviewAndStatusPage";
import EditCourseSessionPage from "pages/auth/editCourseDetail/components/courseSessionPage/EditCourseSessionPage";
import EditCourseTutorPage from "pages/auth/editCourseDetail/components/editCourseTutor/EditCourseTutorPage";
import EditCourseConfigurationPage from "pages/auth/editCourseDetail/components/editCourseConfig/EditCourseConfigurationPage";

import SelectTemplatePage from "pages/auth/viewCourseDetail/components/SelectTemplatePage";
import BasicInputPage from "pages/auth/viewCourseDetail/components/basicInputPage/BasicInputPage";
import ConfirmSchedulePage from "pages/auth/viewCourseDetail/components/confirmSchedulePage/ConfirmSchedulePage";
import SetPrice from "pages/auth/viewCourseDetail/components/setPricePage/SetPrice";
import PreviewAndStatusPage from "pages/auth/viewCourseDetail/components/previewAndStatusPage/PreviewAndStatusPage";
import CourseSessionPage from "pages/auth/viewCourseDetail/components/courseSessionPage/CourseSessionPage";
import ViewCourseTutorPage from "pages/auth/viewCourseDetail/components/viewCourseTutors/ViewCourseTutorsPage";
import ViewCourseConfigurationPage from "pages/auth/viewCourseDetail/components/viewCourseConfi/ViewCourseConfigurationPage";

import EditPersonalDetail from "pages/auth/editTutorOnboard/components/editPersonalDetail/EditPersonalDetail";
import EditQuestionsAnswers from "pages/auth/editTutorOnboard/components/editQuestionAnswers/EditQuestionAnswers";
import EditIntroVideo from "pages/auth/editTutorOnboard/components/editIntroVideo/EditIntroVideo";
import EditDocumentVerification from "pages/auth/editTutorOnboard/components/editDocumentsUpload/EditUploadDocument";
import EditVideoCallSchedule from "pages/auth/editTutorOnboard/components/editVideoCallSchedule/EditVideoCallSchedule";
import TutorPreferences from "pages/auth/editTutorOnboard/components/tutorPreferences/TutorPreferences";
import EditTrialClass from "pages/auth/editTutorOnboard/components/editTrialClass/EditTrialClass";
import VerifyTutor from "pages/auth/editTutorOnboard/components/verifyTutor/VerifyTutor";

import ViewUserDetails from "pages/auth/viewTutorOnboard/components/personalDetail/ViewUserDetails";
import ViewRelevantQuestions from "pages/auth/viewTutorOnboard/components/questionAndAnswer/ViewRelevantQuestions";
import ViewIntroVideo from "pages/auth/viewTutorOnboard/components/viewIntroVideo/ViewIntroVideo";
import DocumentVerification from "pages/auth/viewTutorOnboard/components/documentVerification/DocumentVerification";
import AttendVideoCall from "pages/auth/viewTutorOnboard/components/attendVideoCall/AttendVideoCall";
import ViewTutorPreferences from "pages/auth/viewTutorOnboard/components/tutorPreferences/TutorPreferences";
import CreateTrialClass from "pages/auth/viewTutorOnboard/components/createTrialClass/CreateTrialClass";
import ApproveTutor from "pages/auth/viewTutorOnboard/components/approveTutor/ApproveTutor";

import { editUserNavigationOptions } from "pages/auth/editUser/data";
import { viewUserNavigationOptions } from "pages/auth/viewUser/data";

import { editTutorNavigationOptions } from "pages/auth/editTutor/data";
import { viewTutorNavigationOptions } from "pages/auth/viewTutor/data";

import { otherSettingsNavigationOptions } from "pages/auth/otherSettings/data";

import { pagesInfo } from 'utils/pagesInfo';

const InitRouter = () => {

    const location = useLocation()

    return (
        <Routes>
            <Route path={"/"} element={<LayoutRouter />}>
                <Route element={<PersistLogin />}>

                    {/* public routes */}
                    <Route path={"/dashboard*"} element={<Navigate to={"/"} state={{ from: location }} replace />} />

                    {/* private routes */}
                    <Route element={<PrivateRouter allowedRoles={pagesInfo.DASHBOARD.roles} />}>
                        <Route path={pagesInfo.DASHBOARD.routePath} exact element={<pagesInfo.DASHBOARD.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.ADD_BOOKING.roles} />}>
                        <Route path={pagesInfo.ADD_BOOKING.routePath} exact element={<pagesInfo.ADD_BOOKING.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.APPOINTMENTS.roles} />}>
                        <Route path={pagesInfo.APPOINTMENTS.routePath} exact element={<pagesInfo.APPOINTMENTS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.BOOKINGS.roles} />}>
                        <Route path={pagesInfo.BOOKINGS.routePath} exact element={<pagesInfo.BOOKINGS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.COURSES.roles} />}>
                        <Route path={pagesInfo.COURSES.routePath} exact element={<pagesInfo.COURSES.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.CREATE_COURSE_PREVIEW.roles} />}>
                        <Route path={pagesInfo.CREATE_COURSE_PREVIEW.routePath} exact element={<pagesInfo.CREATE_COURSE_PREVIEW.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.DASHBOARD.roles} />}>
                        <Route path={pagesInfo.DASHBOARD.routePath} exact element={<pagesInfo.DASHBOARD.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_COMPANY.roles} />}>
                        <Route path={pagesInfo.EDIT_COMPANY.routePath} exact element={<pagesInfo.EDIT_COMPANY.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_COURSE.roles} />}>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="select-template" element={<EditSelectTemplatePage />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="basic-inputs" element={<EditBasicInputPage />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="confirm-schedule" element={<EditConfirmSchedulePage />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="set-pricing" element={<EditSetPrice />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="class-preview" element={<EditPreviewAndStatusPage />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="sessions" element={<EditCourseSessionPage />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="tutors" element={<EditCourseTutorPage />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="configs" element={<EditCourseConfigurationPage />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="" element={<Navigate to={"select-template"} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_TUTOR.roles} />}>
                        <Route path={pagesInfo.EDIT_TUTOR.routePath} exact element={<pagesInfo.EDIT_TUTOR.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path={editTutorNavigationOptions.TUTOR_DETAILS.routePath} element={<editTutorNavigationOptions.TUTOR_DETAILS.component />} />
                            <Route path={editTutorNavigationOptions.TUTOR_LANGUAGES.routePath} element={<editTutorNavigationOptions.TUTOR_LANGUAGES.component />} />
                            <Route path={editTutorNavigationOptions.SUBJECT.routePath} element={<editTutorNavigationOptions.SUBJECT.component />} />
                            <Route path={editTutorNavigationOptions.LESSON.routePath} element={<editTutorNavigationOptions.LESSON.component />} />
                            <Route path={editTutorNavigationOptions.PRICE.routePath} element={<editTutorNavigationOptions.PRICE.component />} />
                            <Route path={editTutorNavigationOptions.OTHER.routePath} element={<editTutorNavigationOptions.OTHER.component />} />
                            <Route path={editTutorNavigationOptions.TUTOR_CONFIG.routePath} element={<editTutorNavigationOptions.TUTOR_CONFIG.component />} />
                            <Route path="" element={<Navigate to={editTutorNavigationOptions.TUTOR_DETAILS.routePath} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_TUTOR_ONBOARD.roles} />}>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="user-details" element={<EditPersonalDetail />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="relevant-questions" element={<EditQuestionsAnswers />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="intro-video" element={<EditIntroVideo />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="verify-documents" element={<EditDocumentVerification />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="video-call" element={<EditVideoCallSchedule />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="tutor-pref" element={<TutorPreferences />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="trial-class" element={<EditTrialClass />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="verify-tutor" element={<VerifyTutor />} />
                        </Route>
                        <Route path={pagesInfo.EDIT_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.EDIT_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="" element={<Navigate to={"user-details"} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_USER.roles} />}>
                        <Route path={pagesInfo.EDIT_USER.routePath} exact element={<pagesInfo.EDIT_USER.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path={editUserNavigationOptions.GENERAL.routePath} exact element={<editUserNavigationOptions.GENERAL.component />} />
                            <Route path={editUserNavigationOptions.ADDRESS.routePath} exact element={<editUserNavigationOptions.ADDRESS.component />} />
                            <Route path={editUserNavigationOptions.CULTURE.routePath} exact element={<editUserNavigationOptions.CULTURE.component />} />
                            <Route path={editUserNavigationOptions.EDUCATION.routePath} exact element={<editUserNavigationOptions.EDUCATION.component />} />
                            <Route path={editUserNavigationOptions.SOCIAL.routePath} exact element={<editUserNavigationOptions.SOCIAL.component />} />
                            <Route path={editUserNavigationOptions.BADGES.routePath} exact element={<editUserNavigationOptions.BADGES.component />} />
                            <Route path={editUserNavigationOptions.AVAILABILITY_LIST.routePath} exact element={<editUserNavigationOptions.AVAILABILITY_LIST.component />} />
                            <Route path={editUserNavigationOptions.CREATE_AVAILABILITY.routePath} exact element={<editUserNavigationOptions.CREATE_AVAILABILITY.component />} />
                            <Route path={editUserNavigationOptions.AVAILABILITY_DETAIL.routePath} exact element={<editUserNavigationOptions.AVAILABILITY_DETAIL.component />} />
                            <Route path={editUserNavigationOptions.WALLET.routePath} exact element={<editUserNavigationOptions.WALLET.component />} />
                            <Route path={editUserNavigationOptions.OTHER_PREFERENCES.routePath} exact element={<editUserNavigationOptions.OTHER_PREFERENCES.component />} />
                            <Route path="" element={<Navigate to={editUserNavigationOptions.GENERAL.routePath} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.LEADS.roles} />}>
                        <Route path={pagesInfo.LEADS.routePath} exact element={<pagesInfo.LEADS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.LMS_COURSE.roles} />}>
                        <Route path={pagesInfo.LMS_COURSE.routePath} exact element={<pagesInfo.LMS_COURSE.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.LMS_VIDEO_LECTURE.roles} />}>
                        <Route path={pagesInfo.LMS_VIDEO_LECTURE.routePath} exact element={<pagesInfo.LMS_VIDEO_LECTURE.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.LMS_QUIZ.roles} />}>
                        <Route path={pagesInfo.LMS_QUIZ.routePath} exact element={<pagesInfo.LMS_QUIZ.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.LMS_ARTICLE.roles} />}>
                        <Route path={pagesInfo.LMS_ARTICLE.routePath} exact element={<pagesInfo.LMS_ARTICLE.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.LMS_QUESTION.roles} />}>
                        <Route path={pagesInfo.LMS_QUESTION.routePath} exact element={<pagesInfo.LMS_QUESTION.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.LMS_RESOURCE.roles} />}>
                        <Route path={pagesInfo.LMS_RESOURCE.routePath} exact element={<pagesInfo.LMS_RESOURCE.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.MESSAGES.roles} />}>
                        <Route path={pagesInfo.MESSAGES.routePath} exact element={<pagesInfo.MESSAGES.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.ONBOARDING.roles} />}>
                        <Route path={pagesInfo.ONBOARDING.routePath} exact element={<pagesInfo.ONBOARDING.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.ORDERS.roles} />}>
                        <Route path={pagesInfo.ORDERS.routePath} exact element={<pagesInfo.ORDERS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.OTHER_SETTINGS.roles} />}>
                        <Route path={pagesInfo.OTHER_SETTINGS.routePath} exact element={<pagesInfo.OTHER_SETTINGS.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path={otherSettingsNavigationOptions.GENERAL.routePath} element={<otherSettingsNavigationOptions.GENERAL.component />} />
                            <Route path={otherSettingsNavigationOptions.STRIPE_SETTINGS.routePath} element={<otherSettingsNavigationOptions.STRIPE_SETTINGS.component />} />
                            <Route path="" element={<Navigate to={otherSettingsNavigationOptions.GENERAL.routePath} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.PRODUCT.roles} />}>
                        <Route path={pagesInfo.PRODUCT.routePath} exact element={<pagesInfo.PRODUCT.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.REFERRAL.roles} />}>
                        <Route path={pagesInfo.REFERRAL.routePath} exact element={<pagesInfo.REFERRAL.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.SESSIONS.roles} />}>
                        <Route path={pagesInfo.SESSIONS.routePath} exact element={<pagesInfo.SESSIONS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.SUBSCRIPTIONS.roles} />}>
                        <Route path={pagesInfo.SUBSCRIPTIONS.routePath} exact element={<pagesInfo.SUBSCRIPTIONS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.TOOLS.roles} />}>
                        <Route path={pagesInfo.TOOLS.routePath} exact element={<pagesInfo.TOOLS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.TUTORS.roles} />}>
                        <Route path={pagesInfo.TUTORS.routePath} exact element={<pagesInfo.TUTORS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.USERS.roles} />}>
                        <Route path={pagesInfo.USERS.routePath} exact element={<pagesInfo.USERS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.VIEW_COMPANY_ONBOARDING.roles} />}>
                        <Route path={pagesInfo.VIEW_COMPANY_ONBOARDING.routePath} exact element={<pagesInfo.VIEW_COMPANY_ONBOARDING.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.VIEW_COURSE.roles} />}>

                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component />}>
                            <Route path="select-template" element={<SelectTemplatePage />} />
                        </Route>

                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component />}>
                            <Route path="basic-inputs" element={<BasicInputPage />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component />}>
                            <Route path="confirm-schedule" element={<ConfirmSchedulePage />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component />}>
                            <Route path="set-pricing" element={<SetPrice />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component />}>
                            <Route path="class-preview" element={<PreviewAndStatusPage />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="class-sessions" element={<CourseSessionPage />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="tutors" element={<ViewCourseTutorPage />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="configs" element={<ViewCourseConfigurationPage />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_COURSE.routePath} exact element={<pagesInfo.VIEW_COURSE.component />}>
                            <Route path="" element={<Navigate to={"select-template"} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.VIEW_LEAD.roles} />}>
                        <Route path={pagesInfo.VIEW_LEAD.routePath} exact element={<pagesInfo.VIEW_LEAD.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.VIEW_TUTOR.roles} />}>
                        <Route path={pagesInfo.VIEW_TUTOR.routePath} exact element={<pagesInfo.VIEW_TUTOR.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path={viewTutorNavigationOptions.TUTOR_DETAILS.routePath} element={<viewTutorNavigationOptions.TUTOR_DETAILS.component />} />
                            <Route path={viewTutorNavigationOptions.TUTOR_LANGUAGES.routePath} element={<viewTutorNavigationOptions.TUTOR_LANGUAGES.component />} />
                            <Route path={viewTutorNavigationOptions.SUBJECT.routePath} element={<viewTutorNavigationOptions.SUBJECT.component />} />
                            <Route path={viewTutorNavigationOptions.LESSON.routePath} element={<viewTutorNavigationOptions.LESSON.component />} />
                            <Route path={viewTutorNavigationOptions.PRICE.routePath} element={<viewTutorNavigationOptions.PRICE.component />} />
                            <Route path={viewTutorNavigationOptions.OTHER.routePath} element={<viewTutorNavigationOptions.OTHER.component />} />
                            <Route path={viewTutorNavigationOptions.TUTOR_CONFIG.routePath} element={<viewTutorNavigationOptions.TUTOR_CONFIG.component />} />
                            <Route path={""} element={<Navigate to={viewTutorNavigationOptions.TUTOR_DETAILS.routePath} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.VIEW_TUTOR_ONBOARD.roles} />}>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="user-details" element={<ViewUserDetails />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="relevant-questions" element={<ViewRelevantQuestions />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="intro-video" element={<ViewIntroVideo />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="verify-documents" element={<DocumentVerification />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="video-call" element={<AttendVideoCall />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="tutor-pref" element={<ViewTutorPreferences />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="trial-class" element={<CreateTrialClass />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="verify-tutor" element={<ApproveTutor />} />
                        </Route>
                        <Route path={pagesInfo.VIEW_TUTOR_ONBOARD.routePath} exact element={<pagesInfo.VIEW_TUTOR_ONBOARD.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path="" element={<Navigate to={"user-details"} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.VIEW_USER.roles} />}>
                        <Route path={pagesInfo.VIEW_USER.routePath} exact element={<pagesInfo.VIEW_USER.component allowedRoles={['super_admin', 'admin']} />}>
                            <Route path={viewUserNavigationOptions.GENERAL.routePath} element={<viewUserNavigationOptions.GENERAL.component />} />
                            <Route path={viewUserNavigationOptions.ADDRESS.routePath} element={<viewUserNavigationOptions.ADDRESS.component />} />
                            <Route path={viewUserNavigationOptions.CULTURE.routePath} element={<viewUserNavigationOptions.CULTURE.component />} />
                            <Route path={viewUserNavigationOptions.EDUCATION.routePath} element={<viewUserNavigationOptions.EDUCATION.component />} />
                            <Route path={viewUserNavigationOptions.SOCIAL.routePath} element={<viewUserNavigationOptions.SOCIAL.component />} />
                            <Route path={viewUserNavigationOptions.BADGES.routePath} element={<viewUserNavigationOptions.BADGES.component />} />
                            <Route path={viewUserNavigationOptions.AVAILABILITY_LIST.routePath} element={<viewUserNavigationOptions.AVAILABILITY_LIST.component />} />
                            <Route path={viewUserNavigationOptions.AVAILABILITY_DETAIL.routePath} element={<viewUserNavigationOptions.AVAILABILITY_DETAIL.component />} />
                            {/* <Route path={viewUserNavigationOptions.WALLET.routePath} element={<viewUserNavigationOptions.WALLET-component />} /> */}
                            <Route path={viewUserNavigationOptions.OTHER_PREFERENCES.routePath} element={<viewUserNavigationOptions.OTHER_PREFERENCES.component />} />
                            <Route path={""} element={<Navigate to={viewUserNavigationOptions.GENERAL.routePath} state={{ from: location }} replace />} />
                        </Route>
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.WALLET_CONFIRMATION.roles} />}>
                        <Route path={pagesInfo.WALLET_CONFIRMATION.routePath} exact element={<pagesInfo.WALLET_CONFIRMATION.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.WALLET_TXNS.roles} />}>
                        <Route path={pagesInfo.WALLET_TXNS.routePath} exact element={<pagesInfo.WALLET_TXNS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.WITHDRAWALS.roles} />}>
                        <Route path={pagesInfo.WITHDRAWALS.routePath} exact element={<pagesInfo.WITHDRAWALS.component />} />
                    </Route>

                    <Route element={<PrivateRouter allowedRoles={pagesInfo.UNAUTHORIZED.roles} />}>
                        <Route path={pagesInfo.UNAUTHORIZED.routePath} exact element={<pagesInfo.UNAUTHORIZED.component />} />
                    </Route>

                </Route>
            </Route>
        </Routes>
    )
}

export default InitRouter;