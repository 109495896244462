import { useState } from 'react';

import {
    Container,
    LargeNavBar,
    SideBarHamburgerButton,
    MediumNavBar,
    SmallNavBar,
    HamburgerButton,
    LogoContainer,
    LogoAnchorLink,
} from './NavBarStyle';

import SideOverPanel from 'components/common-components/SideOverPanel';
import LeftNavLinks from './LeftNavLinks';
import RightNavLinks from './RightNavLinks';
import HamburgerMenu from './HamburgerMenu';

import { useAppState } from 'hooks/useStore';

import { pagesInfo, sideTopBarPagesName } from 'utils/pagesInfo';

const NavBar = ({ visibility, setVisibility }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;

    const [isScrolled, setIsScrolled] = useState(false);
    const [isMediumHamburger, setIsMediumHamburger] = useState(false);

    window.onscroll = () => {
        setIsScrolled(window.pageYOffset > 5 ? true : false);
        return () => window.onscroll = null;
    }

    return (
        <>
            <Container className={"group"} isScrolled={isScrolled}>
                {sideTopBarPagesName.includes(pageName) &&
                    <SideBarHamburgerButton onClick={() => setVisibility({ ...visibility, isVisibleSideBar: !visibility.isVisibleSideBar })} />
                }
                <LargeNavBar>
                    <LeftNavLinks
                        isScrolled={isScrolled}
                    />
                    {/* {pageName !== pagesInfo.HOME.pageName &&
                        <CenterNavLinks />
                    } */}
                    <RightNavLinks
                        isScrolled={isScrolled}
                    />
                </LargeNavBar>
                <MediumNavBar>
                    <LeftNavLinks
                        isScrolled={isScrolled}
                    />
                    <HamburgerButton
                        onClick={() => setIsMediumHamburger(true)}
                        isScrolled={isScrolled}
                    />
                </MediumNavBar>
                <SmallNavBar>
                    <LogoContainer to={pagesInfo.HOME.pagePath}
                        isScrolled={isScrolled}
                    >
                        {/* {'Edulyte'} */}

                        <LogoAnchorLink href={"https://www.edulyte.com"}
                            isScrolled={isScrolled}
                        >
                            {'Edulyte'}
                        </LogoAnchorLink>
                    </LogoContainer>
                    <HamburgerButton
                        onClick={() => setIsMediumHamburger(true)}
                        isScrolled={isScrolled}
                    />
                </SmallNavBar>
            </Container>

            <SideOverPanel
                child={<HamburgerMenu setIsMediumHamburger={setIsMediumHamburger} />}
                isOpen={isMediumHamburger || false}
                onClose={() => setIsMediumHamburger(false)}
            />
        </>
    )
}

export default NavBar;