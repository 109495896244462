export function validateEmail(email) {
    // var re = /\S+@\S+\.\S+/;
    // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  
  export function validateMobileNo(mobileNo) {
    // var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,10}$/im;
    var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,10}$/im;
    return re.test(mobileNo);
  }
  
export function validatePassword(password) {
  // var re = /^(?=.*\d)(?=(.*\W){1})(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,20}$/;
  var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@$%&*])[A-Za-z\d!@$%&*]{8,}$/;
  return re.test(password);
}
  
  export function validateZipCode(zipCode) {
    var re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return re.test(zipCode)
  }
  
  export function validatePostalCode(postalCode) {
    var re = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/
    return re.test(postalCode)
  }
  