import { Link } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import RatingStars from 'components/ratingStars/RatingStars';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import { menuItemsConst } from 'pages/auth/editCourseDetail/data';

import { getTutorDetail } from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetTutorDetail } from 'redux/tutor/tutor.slice';
import { resetAddCourseTutorDetail, setAddCourseTutorDetailPayload } from 'redux/course/course.slice';
import { modalConst } from 'redux/local/local.const';
import {
    addCourseTutorDetailPayload,
    courseFeeTypeEnum,
    courseTutorPermissionEnum,
    courseTutorRoleEnum,
    courseTutorStatus,
    courseTutorVisibilityTypeEnum
} from 'redux/course/course.const';

import { pagesInfo } from "utils/pagesInfo";

const CourseTutorCard = ({ courseTutor }) => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const onHandleSelectMenuItem = (menuItem) => {
        switch (menuItem.value) {
            case menuItemsConst?.VIEW?.value:
                break;

            case menuItemsConst?.EDIT?.value:
                dispatcher(resetTutorDetail())
                dispatcher(resetAddCourseTutorDetail())
                dispatcher(getTutorDetail(courseTutor?.tutor?.id))

                const body = {
                    courseTutorId: courseTutor?.id,
                    courseId: courseTutor?.course,
                    tutorId: courseTutor?.tutor?.id,
                    isEditCourseTutorEnabled: true,
                    visibility: courseTutor?.visibility,
                    permission: courseTutor?.permission,
                    tutorRole: courseTutor?.tutorRole,
                    status: courseTutor?.status,
                    feeModel: courseTutor?.feeModel,
                    isAutoPay: courseTutor?.isAutoPay,
                    feeType: courseTutor?.feeType,
                    currencyCode: courseTutor?.masterCurrency?.code
                }
                if (courseTutor?.feeType === courseFeeTypeEnum?.FIXED?.value) {
                    body["amount"] = courseTutor?.amount
                }

                if (courseTutor?.feeType === courseFeeTypeEnum?.PERCENTAGE?.value) {
                    body["percentage"] = courseTutor?.percentage
                }
                dispatcher(setAddCourseTutorDetailPayload({
                    ...addCourseTutorDetailPayload,
                    ...body
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.ADD_COURSE_TUTOR_MODAL.stateKey]: true
                }))
                break;

            case menuItemsConst?.DELETE?.value:
                break;

            default:
                break;
        }
    }

    return (
        <div className={"bg-white rounded-lg px-5 py-3 w-full shadow-[0_0_10px_1px_rgba(0,0,0,0.1)] border-2 border-divider-darkLight"}>
            <div className={"w-full flex items-start justify-between gap-3"}>
                <div className={'w-full flex flex-col items-start justify-start gap-5 px-5'}>

                    <div className={"grid grid-cols-4 w-full gap-5"}>
                        <div className={'col-start-1 col-span-full md:col-start-1 md:col-span-2 lg:col-start-1 lg:col-span-1 relative w-20 h-20 rounded-full'}>
                            <Link to={`${pagesInfo.VIEW_TUTOR.pagePath}/${courseTutor?.tutor?.user?.id}`}>
                                <img src={courseTutor?.tutor?.user?.profilePicUrl} alt={"profile-pic"}
                                    className={"w-full h-full rounded-full object-cover"}
                                />
                            </Link>
                            <div className={'relative has-tooltip'}>
                                <div className={"absolute -top-7.5 bottom-0.5 right-0.5 w-5 h-5 rounded-full border-2 border-white overflow-hidden"}>
                                    <img
                                        src={`https://flagcdn.com/16x12/${courseTutor?.tutor?.user?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                        alt={"country-flag"}
                                        className='w-full h-full object-cover overflow-hidden'
                                    />
                                </div>
                                <span
                                    className={cn(
                                        "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-1 -right-0",
                                        "flex flex-col items-center justify-start overflow-hidden"
                                    )}
                                >
                                    {courseTutor?.tutor?.user?.fromCountry?.country}
                                </span>
                            </div>
                        </div>

                        <div className={'col-start-1 col-span-full md:col-start-3 md:col-span-1 lg:col-start-2 lg:col-span-1 flex flex-col items-center justify-center gap-2 pl-0 lg:pl-10'}>
                            <div className={'flex items-center gap-3'}>
                                <Link to={`${pagesInfo.VIEW_TUTOR.pagePath}/${courseTutor?.tutor?.user?.id}`}>
                                    <span className={'font-medium text-xl truncate capitalize'}>
                                        {courseTutor?.tutor?.user?.firstName} {courseTutor?.tutor?.user?.lastName.charAt(0) + "."}
                                    </span>
                                </Link>
                            </div>
                            <div className={"flex flex-col items-center justify-center gap-1"}>
                                <RatingStars
                                    rating={courseTutor?.tutor?.rating?.avgRating}
                                    fullRating={5}
                                />
                                <div className={"font-bodyPri font-normal text-xs text-complementary-main"}>
                                    {courseTutor?.tutor?.rating?.avgRating?.toFixed(1)}({courseTutor?.tutor?.rating?.totalStars?.count})
                                </div>
                            </div>
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 flex items-center justify-start gap-2 text-base font-bodyPri tracking-wide"}>
                            <span className={"text-text-600"}>
                                {"Status: "}
                            </span>
                            <span className={'font-medium whitespace-nowrap'}>
                                {courseTutorStatus[courseTutor?.status?.toUpperCase()]?.label}
                            </span>
                        </div>

                        <div className={"col-start-1 col-span-1 md:col-start-4 md:col-span-1 lg:col-start-3 lg:col-span-1 flex items-center justify-start gap-2 text-base font-bodyPri tracking-wide"}>
                            <span className={"text-text-600"}>
                                {"Visibility: "}
                            </span>
                            <span className={'font-medium whitespace-nowrap'}>
                                {courseTutorVisibilityTypeEnum[courseTutor?.visibility?.toUpperCase()]?.label}
                            </span>
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 flex items-center justify-start gap-2 text-base font-bodyPri tracking-wide"}>
                            <span className={"text-text-600"}>
                                {"Permission: "}
                            </span>
                            <span className={'font-medium'}>
                                {courseTutorPermissionEnum[courseTutor?.permission?.toUpperCase()]?.label}
                            </span>
                        </div>

                        <div className={"col-start-1 col-span-1 md:col-start-4 md:col-span-1 lg:col-start-3 lg:col-span-1 flex items-center justify-start gap-2 text-base font-bodyPri tracking-wide"}>
                            <span className={"text-text-600"}>
                                {"Role: "}
                            </span>
                            <span className={'font-medium whitespace-nowrap'}>
                                {courseTutorRoleEnum[courseTutor?.tutorRole?.toUpperCase()]?.label}
                            </span>
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 flex items-center justify-start gap-2 text-base font-bodyPri tracking-wide"}>
                            <span className={"whitespace-nowrap text-text-600"}>
                                {"Fee Type: "}
                            </span>
                            <span className={'font-medium'}>
                                {courseFeeTypeEnum[courseTutor?.feeType?.toUpperCase()]?.label}
                            </span>
                        </div>

                        {(courseTutor?.feeType === courseFeeTypeEnum?.FIXED?.value) &&
                            <div className={"col-start-1 col-span-1 md:col-start-4 md:col-span-1 lg:col-start-3 lg:col-span-1 flex items-center justify-start gap-2 text-base font-bodyPri tracking-wide"}>
                                <span className={"text-text-600"}>
                                    {"Amount: "}
                                </span>
                                <span className={'font-medium whitespace-nowrap'}>
                                    {courseTutor?.amount}
                                </span>
                            </div>
                        }
                        {(courseTutor?.feeType === courseFeeTypeEnum?.PERCENTAGE?.value) &&
                            <div className={"col-start-1 col-span-1 md:col-start-4 md:col-span-1 lg:col-start-3 lg:col-span-1 flex items-center justify-start gap-2 text-base font-bodyPri tracking-wide"}>
                                <span className={"whitespace-nowrap text-text-600 truncate"}>
                                    {"Payout Percent: "}
                                </span>
                                <span className={'font-medium whitespace-nowrap'}>
                                    {`${courseTutor?.percentage}%`}
                                </span>
                            </div>
                        }

                    </div>
                </div>
                <div className={"w-full flex justify-end"}>
                    <ThreeDotMenu
                        menuItems={Object.values(menuItemsConst)}
                        onHandleSelect={onHandleSelectMenuItem}
                    />
                </div>
            </div>
        </div>
    )
}

export default CourseTutorCard;