import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { documentTableHeader } from "pages/auth/viewCompanyOnboarding/data";

import { getCompanyDocumentListByCompanyId } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCompanyDocumentList } from "redux/onboarding/onboarding.slice";
import { companyDocumentsCategory, companyOnboardingStatus } from "redux/onboarding/onboarding.const";

const DocumentVerification = () => {
    const { companyDocumentList } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { companyId } = useParams()

    useEffect(() => {
        if (companyId) {
            dispatcher(getCompanyDocumentListByCompanyId(Number(companyId)))
        }

        return () => {
            setClearCompanyDocumentList()
        }
    }, [companyId])

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"DOCUMENTS"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"p-5 bg-white space-y-3"}>
                    <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                        {"Document Verification"}
                    </span>
                    <div className={"p-5"}>
                        {companyDocumentList?.companyDocumentList?.documents?.length > 0 &&
                            <table className="w-full table-fixed">
                                <thead>
                                    <tr>
                                        {documentTableHeader.map((head, index) => (
                                            <th
                                                key={index}
                                                className={cn(
                                                    "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                                    "font-bold whitespace-nowrap border border-gray-200"
                                                )}
                                            >
                                                {head}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {companyDocumentList?.companyDocumentList?.documents?.map((data, idx) => (
                                        <tr
                                            className={''}
                                            key={idx}
                                        >
                                            <td className="border border-gray-200">
                                                <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                                                    {companyDocumentsCategory[data?.fileCategory.toUpperCase()].label}
                                                </span>
                                            </td>
                                            <td className={"border border-gray-200"}>
                                                <a
                                                    href={data?.fileUrl}
                                                    target={"_blank"}
                                                    className='px-5 py-2 font-bodyPri font-medium text-secondary-main text-base text-center underline truncate cursor-pointer'>
                                                    {data?.fileName}
                                                </a>
                                            </td>
                                            <td className={"border border-gray-200"}>
                                                <span className='px-5 py-2 font-bodyPri font-normal text-text-900 text-base italic text-center'>
                                                    {companyOnboardingStatus[data?.status?.toUpperCase()]?.label}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                        {companyDocumentList?.companyDocumentList?.documents?.length === 0 &&
                            <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                                {"No documents uploaded yet!"}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentVerification