import { weeklyTimeSlot, chooseTimeSlotAction, bookingType, bookingOfferingType, userBookingTimeZoneConst } from "redux/booking/booking.const"
import { bookingFilterProps } from "redux/booking/booking.const"

const addBookingPayload = {
    course: null,
    coursePrice: 0,
    purchasedPrice: 0,
    coursePctDiscount: 0,
    volumePctDiscount: 0,
    tutorPctDiscount: 0,
    offerPctDiscount: 0,
    sessionDuration: 60,
    noOfSessions: 5,
    confirm: false,
    chooseTimeSlotAction: chooseTimeSlotAction.BOOKING.value,
    startDate: null,
    endDate: null,
    weeklyTimeSlot: weeklyTimeSlot,
    bookingType: bookingType.REGULAR.value,
    isWalletAdded: true,
    walletAmountToPay: 0,
    gatewayAmountToPay: 0,
}

const addBookingDetailPayload = {
    userId: null,
    offeringType: bookingOfferingType.COURSE.value,
    courseId: null,
    appointmentId: null,
    duration: 30,
    sessionCount: 1,
    price: 0,
    discountPct: 0,
    volumeDiscountPct: 0,
    timeZone: userBookingTimeZoneConst?.USER_TIMEZONE?.value
}

const chooseTimeSlotPayload = {
    chooseTimeSlotAction: chooseTimeSlotAction.BOOKING.value,
    actionItem: {
        courseId: null,
        eventId: null,
        bookingId: null,
    },
    sessionDuration: 60,
    noOfSessions: 5,
    startDate: null,
    endDate: null,
    weeklyTimeSlot: weeklyTimeSlot,
}

export const BOOKING_INITIAL_STATE = {
    bookingList: {
        isLoading: false,
        data: null,
        message: null
    },
    
    bookingDetail: {
        isLoading: false,
        payload: null,
        data: null,
        message: null
    },

    addBookingDetail: {
        isLoading: false,
        data: null,
        payload: addBookingDetailPayload,
        message: null
    },

    // booking session detail
    addBookingSessionDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // OLD
    addBooking: {
        isLoading: false,
        addBookingPayload: addBookingPayload,
        chooseTimeSlotPayload: chooseTimeSlotPayload,
        addBooking: null,
        errorMsg: null
    },

    filterProps: bookingFilterProps
}