import { createSlice } from "@reduxjs/toolkit";

import { LMS_LECTURE_INITIAL_STATE } from "./lmsLecture.initialState";

const lmsLecture = createSlice({
    name: "lms lecture",
    initialState: LMS_LECTURE_INITIAL_STATE,
    reducers: {
        /* tutor lms lecture */
        // list
        setLmsLectureListLoading: (state, { payload }) => {
            state.lmsLectureList.isLoading = payload
        },
        setLmsLectureListData: (state, { payload }) => {
            state.lmsLectureList.data = payload
            state.lmsLectureList.message = LMS_LECTURE_INITIAL_STATE.lmsLectureList.message
        },
        setLmsLectureListMessage: (state, { payload }) => {
            state.lmsLectureList.message = payload
            state.lmsLectureList.data = LMS_LECTURE_INITIAL_STATE.lmsLectureList.data
        },
        resetLmsLectureList: (state) => {
            state.lmsLectureList = LMS_LECTURE_INITIAL_STATE.lmsLectureList
        },

        // destroy
        setDestroyLmsLectureDetailLoading: (state, { payload }) => {
            state.destroyLmsLectureDetail.isLoading = payload
        },
        setDestroyLmsLectureDetailMessage: (state, { payload }) => {
            state.destroyLmsLectureDetail.message = payload
        },
        resetDestroyLmsLectureDetail: (state) => {
            state.destroyLmsLectureDetail = LMS_LECTURE_INITIAL_STATE.destroyLmsLectureDetail
        },

        // filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
        },
        resetFilterProps: (state) => {
            state.filterProps = LMS_LECTURE_INITIAL_STATE.filterProps
        }
    }
})

export const {
    /* lms lecture */
    setLmsLectureListLoading,
    setLmsLectureListData,
    setLmsLectureListMessage,
    resetLmsLectureList,

    setDestroyLmsLectureDetailLoading,
    setDestroyLmsLectureDetailMessage,
    resetDestroyLmsLectureDetail,

    setFilterProps,
    resetFilterProps
} = lmsLecture.actions;

export default lmsLecture.reducer;