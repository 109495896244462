import { useEffect } from "react";
import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";

import { getAppointmentDetail } from "redux/appointment/appointment.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetAppointmentDetail } from "redux/appointment/appointment.slice";
import { resetAddBookingDetail, setAddBookingDetailPayload } from "redux/booking/booking.slice";
import { appointmentPriceModelEnum } from "redux/appointment/appointment.const";

const BookingAppointmentTypeOffering = ({ setIsIdVerified }) => {
    const { appointmentDetail } = useAppState((state) => state.appointment)
    const { addBookingDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (appointmentDetail?.data?.result) {
            setIsIdVerified(true)
        }
    }, [appointmentDetail?.data?.result])

    const onHandleChangeAppointmentId = (event) => {
        dispatcher(resetAppointmentDetail())
        dispatcher(resetAddBookingDetail())
        setIsIdVerified(false)
        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetail?.payload,
            appointmentId: event?.target?.value
        }))
    }

    const onHandleVerifyAppointment = () => {
        if (!addBookingDetail?.payload?.appointmentId || appointmentDetail?.isLoading) return;

        dispatcher(resetAppointmentDetail())

        const requestDataPayload = {
            appointmentId: addBookingDetail?.payload?.appointmentId
        }
        dispatcher(getAppointmentDetail(requestDataPayload))
    }

    return (
        <div className={"grid grid-cols-12 gap-5"}>

            {/* course id */}
            <span className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-3 font-bodyPri font-normal text-text-800 text-base md:whitespace-nowrap"}>
                {"Appointment ID:"}
            </span>
            <div className={"col-start-1 col-span-full sm:col-start-4 sm:col-span-full flex flex-col items-start justify-start gap-0.5"}>
                <input
                    type={"number"}
                    className={cn(
                        "w-full px-2 py-1 input-number-spin-none border-2 border-divider-medium rounded-lg focus:outline-none focus:border-2 focus:border-divider-darkLight",
                        "font-bodyPri font-normal text-text-900 text-base"
                    )}
                    value={addBookingDetail?.payload?.appointmentId}
                    onChange={onHandleChangeAppointmentId}
                />
                <div className={"w-full flex justify-end"}>
                    <span
                        className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide cursor-pointer"}
                        onClick={onHandleVerifyAppointment}
                    >
                        {"Verify Appointment ID"}
                    </span>
                </div>
            </div>

            {appointmentDetail?.data?.result &&
                <>
                    <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-800 text-base"}>
                        {"Duration:"}
                    </span>
                    <span className={"col-start-4 col-span-full font-bodyPri font-normal text-text-800 text-base"}>
                        {(appointmentDetail?.data?.result?.appointment_availability?.duration < 60)
                            ? appointmentDetail?.data?.result?.appointment_availability?.duration + " Minutes"
                            : (appointmentDetail?.data?.result?.appointment_availability?.duration / 60) + " Hour(s)"
                        }
                    </span>
                    <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-800 text-base"}>
                        {"Session Count:"}
                    </span>
                    <span className={"col-start-4 col-span-full font-bodyPri font-normal text-text-800 text-base"}>
                        {1}
                    </span>
                    <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-800 text-base"}>
                        {"Price Model:"}
                    </span>
                    <span className={"col-start-4 col-span-full font-bodyPri font-normal text-text-800 text-base"}>
                        {appointmentPriceModelEnum[appointmentDetail?.data?.result?.appointment_price?.price_model?.toUpperCase()]?.label}
                    </span>
                    <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-800 text-base"}>
                        {"Price:"}
                    </span>
                    <div className={"col-start-4 col-span-full font-bodyPri font-normal text-text-800 text-base"}>
                        {(appointmentDetail?.data?.result?.appointment_price?.price_model === appointmentPriceModelEnum.FLAT_FEE.value) && (
                            <span className='font-bodyPri'>
                                {!isNaN(appointmentDetail?.data?.result?.appointment_price?.price)
                                    ? appointmentDetail?.data?.result?.appointment_price?.masterCurrency?.code + " " + parseFloat((appointmentDetail?.data?.result?.appointment_price?.price - ((appointmentDetail?.data?.result?.appointment_price?.price * appointmentDetail?.data?.result?.appointment_price?.discount_pct) / 100)) / 100).toFixed(2)
                                    : `${appointmentDetail?.data?.result?.appointment_price?.masterCurrency?.code} 0.00`
                                }
                            </span>
                        )}
                        {(appointmentDetail?.data?.result?.appointment_price?.price_model === appointmentPriceModelEnum.FREE.value) && (
                            <div className='space-y-1'>
                                <span className='font-bodyPri'>
                                    {"FREE"}
                                </span>
                            </div>
                        )}
                    </div>
                </>
            }

            {appointmentDetail?.isLoading &&
                <ComponentLoader
                    isLoading={appointmentDetail?.isLoading}
                    className={"col-start-1 col-span-full w-full flex items-center justify-center"}
                />
            }
            {appointmentDetail?.message &&
                <span className={"w-full col-start-1 col-span-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {appointmentDetail?.message}
                </span>
            }
        </div>
    )
}

export default BookingAppointmentTypeOffering