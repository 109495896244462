import { manualStripePaymentPayload } from "redux/payment/payment.const";

const makePaymentPayload = {
    paymentType: null,
    currency: null,
    amountToPay: 0,
    processingPctFee: 0,
    totalAmountToPay: 0,
    description: null,
    paymentMethodId: null,
    gatewayName: null,
    firstName: '',
    lastName: '',
    email: '',
}


export const PAYMENT_INITIAL_STATE = {
    makePayment: {
        isLoading: false,
        makePaymentPayload: makePaymentPayload,
        makePayment: null,
        makePaymentConfirm: null,
        errorMsg: null
    },

    // manual stripe payment
    manualStripePayment: {
        isLoading: false,
        data: null,
        payload: manualStripePaymentPayload,
        message: null
    }
}