import { Link } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { useAppState } from 'hooks/useStore';
import { JourneyConst, leadStatus } from 'redux/lead/lead.const';

const LeadBasicDetail = () => {
    const { leadDetail } = useAppState((state) => state.lead)

    const onHandleOpenLink = () => {
        window.open(leadDetail?.leadDetail?.actionPageLink, "_blank")
    }

    return (
        <>
            <div className={"flex items-center justify-between gap-3"}>
                <div className={"flex items-center justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Lead ID:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {leadDetail?.leadDetail?.leadId}
                    </span>
                </div>
                <div className={"flex items-center justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Status:"}
                    </span>
                    <span className={cn(
                        "font-bodyPri font-normal text-text-800 text-base tracking-wide",
                        `text-${leadStatus[leadDetail?.leadDetail?.status?.toUpperCase()]?.darkColor}`
                    )}>
                        {leadStatus[leadDetail?.leadDetail?.status?.toUpperCase()]?.label}
                    </span>
                </div>
            </div>
            <div className={"w-full grid grid-cols-2 gap-5"}>
                <div className={"col-span-1 flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-sm tracking-wide"}>
                        {"Class For: "}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                        {JourneyConst[leadDetail?.leadDetail?.classFor?.toUpperCase()]?.label || "-"}
                    </span>
                </div>
                <div className={"col-span-full flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-sm tracking-wide"}>
                        {"TimeZone: "}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                        {leadDetail?.leadDetail?.timeZone || "-"}
                    </span>
                </div>
                <div className={"col-span-full flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-sm tracking-wide"}>
                        {"Action Page Link: "}
                    </span>
                    <div
                        onClick={onHandleOpenLink}
                        className={"font-bodyPri font-normal text-sm tracking-wide cursor-pointer text-primary-main hover:underline"}>
                        {leadDetail?.leadDetail?.actionPageLink || "-"}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeadBasicDetail