import { toast } from 'react-toastify';

import { createAsyncThunk } from '@reduxjs/toolkit';

import paymentService from 'redux/payment/payment.service';

import {
    setMakePaymentLoading,
    setMakePayment,
    setMakePaymentErrorMsg,

    setManualStripePaymentLoading,
    setManualStripePaymentData,
    setManualStripePaymentMessage,
} from 'redux/payment/payment.slice';
import { paymentRedux } from "redux/payment/payment.const";


export const addMakePayment = () => async (dispatch, getState) => {
    dispatch(setMakePaymentLoading(true))
    const { makePaymentPayload } = getState()?.payment?.makePayment

    try {
        const requestData = {
            body: {
                firstName: makePaymentPayload?.firstName,
                lastName: makePaymentPayload?.lastName,
                email: makePaymentPayload?.email,
                currency: makePaymentPayload?.currency,
                amount: parseInt(makePaymentPayload?.totalAmountToPay),
                description: makePaymentPayload?.description
            }
        }
        const response = await paymentService.addMakePayment(requestData)
        if (response.status === 200) {
            dispatch(setMakePayment(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setMakePaymentErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setMakePaymentLoading(false))
    }
}

// request for manual stripe payment
export const manualPaymentIntentStripe = createAsyncThunk(
    `${paymentRedux?.sliceName}/${paymentRedux?.manualStripePaymentIntent?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setManualStripePaymentLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await paymentService.manualPaymentIntentStripe(requestData)
            if (response.status === 200) {
                dispatch(setManualStripePaymentData(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setManualStripePaymentMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setManualStripePaymentLoading(false))
        }
    }
)