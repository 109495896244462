import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';
import { OptionSelector } from 'components/common-components/Select';

import EditUserHeader from 'pages/auth/editUser/commonComponents/header/EditUserHeader';
import SaveAndCancelBtn from "pages/auth/editUser/commonComponents/buttons/SaveAndCancelBtn";

import { getMasterCountryList } from 'redux/master/master.request';
import { sendForgetPasswordLink } from 'redux/auth/auth.request';
import { getUserDetailByUserId, updateUserDetailByUserId } from 'redux/user/user.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCountryList } from 'redux/master/master.slice';
import { resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { setUserDetail } from 'redux/user/user.slice';
import { setModal } from 'redux/local/local.slice';
import { resetForgetPassword } from 'redux/auth/auth.slice';
import { modalConst } from 'redux/local/local.const';
import { titleOptions, genderOptions, blueTickTypeEnum } from 'redux/user/user.const';
import { fileCategories } from 'redux/storage/storage.const';

import { validateEmail, validateMobileNo } from 'utils/validation-functions';
import { dayjs } from "utils/dateTime.utils";
import { IoIosCheckmark } from 'react-icons/io';
import FloatingLabelSelect from 'components/floating/floatingLabelSelect';

const EditUserInformation = () => {
    const { countryList } = useAppState((state) => state.master)
    const { modal } = useAppState((state) => state.local)
    const { uploadFile } = useAppState((state) => state.storage)
    const { forgetPassword } = useAppState((state) => state.auth)
    const { userDetail, addUserDetail } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const { userId } = useParams()

    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })
    const [birthDate, setBirthDate] = useState(null)

    useEffect(() => {
        if (userDetail?.userDetail?.dateOfBirth) {
            setBirthDate(dayjs(userDetail?.userDetail?.dateOfBirth)?.toDate())
        }
    }, [userDetail?.userDetail?.dateOfBirth])

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
            dispatcher(resetForgetPassword())
        }
    }, [])
    useEffect(() => {
        if (countryList?.countryList) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.isdCode === userDetail?.userDetail?.isdCode
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList])

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0) {
            dispatcher(setUserDetail({
                ...userDetail?.userDetail,
                profilePicUrl: uploadFile?.data[0]?.fileUrl
            }))
            dispatcher(resetUploadFile())
        }

    }, [uploadFile?.data])


    const handleUploadImage = () => {
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories?.profilePicture?.value,
            userId: userDetail?.userDetail?.userId
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
        }))
    }

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    const onHandleSendPasswordLink = () => {
        if (!window.confirm("Are you sure? email a link to reset your password.")) return;

        const body = {
            email: userDetail?.userDetail?.email
        }
        dispatcher(sendForgetPasswordLink(body))
    }

    const onSave = () => {
        if (userDetail?.userDetail?.email && !validateEmail(userDetail?.userDetail?.email)) {
            alert("Please enter valid email!")
            return;
        }
        if (userDetail?.userDetail?.mobileNo && !validateMobileNo(userDetail?.userDetail?.mobileNo)) {
            alert("Please enter valid mobile number!")
            return;
        }

        const body = {}

        if (userDetail?.userDetail?.profilePicUrl) {
            body["profilePicUrl"] = userDetail?.userDetail?.profilePicUrl
        }
        if (userDetail?.userDetail?.title) {
            body["title"] = userDetail?.userDetail?.title
        }
        if (userDetail?.userDetail?.firstName) {
            body["firstName"] = userDetail?.userDetail?.firstName
        }
        if (userDetail?.userDetail?.lastName) {
            body["lastName"] = userDetail?.userDetail?.lastName
        }
        if (userDetail?.userDetail?.preferredName) {
            body["preferredName"] = userDetail?.userDetail?.preferredName
        }
        if (userDetail?.userDetail?.email) {
            body["email"] = userDetail?.userDetail?.email
        }
        if (userDetail?.userDetail?.isEmailVerified) {
            body["isEmailVerified"] = userDetail?.userDetail?.isEmailVerified
        }
        if (myCountry?.isdCode && userDetail?.userDetail?.mobileNo) {
            body["mobileNo"] = myCountry?.isdCode + "-" + userDetail?.userDetail?.mobileNo
        }
        if (userDetail?.userDetail?.isMobileNoVerified) {
            body["isMobileNoVerified"] = userDetail?.userDetail?.isMobileNoVerified
        }
        if (userDetail?.userDetail?.blueTick) {
            body["blueTick"] = userDetail?.userDetail?.blueTick
        }
        if (userDetail?.userDetail?.gender) {
            body["gender"] = userDetail?.userDetail?.gender
        }
        if (birthDate) {
            if (birthDate > new Date()) {
                toast.warn("Invalid Date!")
                return;
            }
            if ((dayjs(new Date())?.format("YYYY") - dayjs(birthDate)?.format("YYYY")) < 18) {
                toast.warn("Minimum age should be 18 years!")
                return;
            }
            body["dateOfBirth"] = dayjs(birthDate)?.format("YYYY-MM-DD")
        }
        dispatcher(updateUserDetailByUserId(Number(userId), body))
    }

    return (
        <div className={"space-y-3"}>

            <EditUserHeader title={"User Information"} />

            {/* profile picture section */}
            <div className={"w-full grid grid-cols-12 gap-5 p-5"}>
                <div className={"col-start-0 col-span-full font-bodyPri font-semibold text-lg text-text-800 tracking-wide whitespace-nowrap"}>
                    {"Profile Photo"}
                </div>
                <div className={"col-start-0 col-span-2 w-32 h-32 rounded-full overflow-hidden"}>
                    <img
                        src={userDetail?.userDetail?.profilePicUrl}
                        className={"w-full h-full object-cover"}
                    />
                </div>
                <div className={"col-start-0 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-full flex flex-col justify-start items-start gap-8"}>
                    <div className={"flex flex-col items-start justify-center gap-1 font-bodyPri font-normal text-text-800 text-base"}>
                        <span>
                            {"This will be displayed to other users when they view your profile."}
                        </span>
                        <span>
                            {"Max Size: 2MB"}
                        </span>
                    </div>
                    <div
                        className={cn
                            ("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                "border border-secondary-main text-secondary-main",
                                "hover:bg-secondary-main hover:text-text-50"
                            )}
                        onClick={handleUploadImage}
                    >
                        {"Upload"}
                    </div>
                </div>
            </div>

            {/* edit user details section */}
            <div className={"grid grid-cols-12 gap-5 p-5"}>

                {/* user id section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"User ID:"}
                </span>
                <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {userDetail?.userDetail?.userId}
                    </span>
                </div>

                {/* edit title section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Title:"}
                </span>
                <div className={"w-52 col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <OptionSelector
                        options={Object.values(titleOptions)}
                        className={"w-full"}
                        value={userDetail?.userDetail?.title}
                        onChange={(option) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            title: option.value
                        }))}
                    />
                </div>
                {/* edit first name section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"First Name:"}
                </span>
                <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <input
                        type={"text"}
                        value={userDetail?.userDetail?.firstName}
                        className={cn(
                            "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            firstName: e.target.value
                        }))}
                    />
                </div>

                {/* edit last name section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Last Name:"}
                </span>
                <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <input
                        type={"text"}
                        value={userDetail?.userDetail?.lastName}
                        className={cn(
                            "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            lastName: e.target.value
                        }))}
                    />
                </div>

                {/* preferred name section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Preferred Name:"}
                </span>
                <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <input
                        type={"text"}
                        value={userDetail?.userDetail?.preferredName}
                        className={cn(
                            "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            preferredName: e.target.value
                        }))}
                    />
                </div>

                {/* edit email section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Email:"}
                </span>
                <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <input
                        type={"text"}
                        value={userDetail?.userDetail?.email}
                        className={cn(
                            "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            email: e.target.value
                        }))}
                    />
                </div>

                {/* edit verify email */}
                <div className={"col-start-1 col-span-full flex items-center justify-start gap-3"}>
                    <input
                        type={"checkbox"}
                        onClick={(e) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            isEmailVerified: e.target.checked
                        }))}
                        checked={userDetail?.userDetail?.isEmailVerified}
                    />
                    <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                        {"Verify Email"}
                    </span>
                </div>

                {/* edit mobile no. section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Mobile No.:"}
                </span>
                <div className={cn(
                    "w-52 col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full flex items-center justify-start gap-1",
                    "py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400"
                )}>
                    <FloatingLabelSelect
                        labelItem={`phone_country`}
                        showLabel={false}
                        searchable={true}
                        options={countryList?.countryList?.map((country) => ({
                            label: `${country?.country} (+${country?.isdCode})`,
                            value: country?.masterCountryId,
                            country: country
                        })) || []}
                        onHandleSelect={(selectedOption) => setMyCountry(selectedOption?.country)}
                        value={
                            <div className={"flex items-center gap-1"}>
                                <img
                                    src={`https://flagcdn.com/16x12/${myCountry?.countryDomain}.png`.toLowerCase()}
                                    className={"w-8 h-5 object-cover"}
                                />
                                <span className={"text-sm"}>{` (+${myCountry?.isdCode})`}</span>
                            </div>
                        }
                        OptionChild={({ option }) => (
                            <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                                <div className="flex items-center gap-3">
                                    <img
                                        src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()}
                                        className={"w-10 h-6 object-cover"}
                                    />
                                    {option?.label}
                                </div>
                                {(option?.value === myCountry?.masterCountryId)
                                    && <IoIosCheckmark size={20} color="green" />
                                }
                            </div>
                        )}
                        label={"Country"}
                        dropdownWrapperClassName={"w-[16rem]"}
                        dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                        customBtnStyle={"border !border-none hover:border-text-400 focus-within:!outline-none focus-within:!ring-0 px-1 !h-7"}
                    />
                    <input
                        type={"number"}
                        value={userDetail?.userDetail?.mobileNo}
                        className={cn(
                            "w-full input-number-spin-none focus:outline-none",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            mobileNo: e.target.value
                        }))}
                    />
                </div>

                {/* edit verify mobile number */}
                <div className={"col-start-1 col-span-full flex items-center justify-start gap-3"}>
                    <input
                        type={"checkbox"}
                        onClick={(e) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            isMobileNoVerified: e.target.checked
                        }))}
                        checked={userDetail?.userDetail?.isMobileNoVerified}
                    />
                    <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                        {"Verify Mobile No."}
                    </span>
                </div>

                {/* reset password */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Password:"}
                </span>
                <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    {forgetPassword?.isLoading &&
                        <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                    }
                    {!forgetPassword?.isLoading &&
                        <div className={"flex flex-col items-start justify-start gap-3"}>
                            <span
                                className={cn(
                                    "px-2 py-0.5 rounded-md border border-primary-dark hover:border-primary-dark hover:bg-primary-dark hover:text-text-50",
                                    "font-bodyPri font-normal text-primary-dark text-base tracking-wide cursor-pointer"
                                )}
                                onClick={onHandleSendPasswordLink}
                            >
                                {"Send Reset Password Link"}
                            </span>
                            {forgetPassword?.message &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                    {forgetPassword?.message}
                                </span>
                            }
                        </div>
                    }
                </div>

                {/* blue tick container */}
                <div className={"col-start-1 col-span-full flex items-center justify-start gap-3"}>
                    <input
                        type={"checkbox"}
                        onChange={(e) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            blueTick: (userDetail?.userDetail?.blueTick === blueTickTypeEnum?.YES?.value)
                                ? blueTickTypeEnum?.NO?.value
                                : blueTickTypeEnum?.YES?.value
                        }))}
                        checked={(userDetail?.userDetail?.blueTick === blueTickTypeEnum?.YES?.value) ? true : false}
                    />
                    <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                        {"Verified Account"}
                    </span>
                </div>

                {/* edit gender section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Gender:"}
                </span>
                <div className={"w-52 col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <OptionSelector
                        options={Object.values(genderOptions)}
                        className={"w-full"}
                        value={userDetail?.userDetail?.gender}
                        onChange={(option) => dispatcher(setUserDetail({
                            ...userDetail?.userDetail,
                            gender: option.value
                        }))}
                    />
                </div>

                {/* edit date of birth section */}
                <span className='col-start-1 col-span-2 text-text-700 font-normal font-bodyPri whitespace-nowrap'>
                    {"Date of Birth:"}
                </span>
                <div className={"w-52 col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full space-y-2"}>
                    <DatePicker
                        className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        dateFormat={"dd/MM/yyyy"}
                        value={birthDate || new Date()}
                        selected={birthDate || new Date()}
                        maxDate={new Date()}
                        placeholderText={"Select Date"}
                        scrollableYearDropdown={true}
                        showYearDropdown={true}
                        yearDropdownItemNumber={80}
                        showMonthDropdown={true}
                        onChange={(date) => setBirthDate(date)}
                    />
                    <div className="flex items-start justify-start gap-1">
                        <span className='font-bodyPri font-medium text-text-900 text-xs text-start w-full'>
                            {"Private to you"}
                        </span>
                    </div>
                </div>

                {/* edit referral section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Referral Code:"}
                </span>
                <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <input
                        type={"text"}
                        value={userDetail?.userDetail?.referralCode}
                        className={cn(
                            "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        disabled={true}
                    />
                </div>

                {/* source url section */}
                <span className={"col-start-0 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Source URL:"}
                </span>
                <div className={"col-start-0 col-span-full md:col-start-3 md:col-span-full lg:col-start-3 lg:col-span-full"}>
                    <input
                        type={"text"}
                        value={userDetail?.userDetail?.source_url}
                        className={cn(
                            "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        disabled={true}
                    />
                </div>
            </div>

            <div className={"flex items-center justify-start"}>
                <SaveAndCancelBtn
                    isLoading={addUserDetail?.isLoading}
                    onSave={onSave}
                    onCancel={() => dispatcher(getUserDetailByUserId(Number(userId)))}
                />
            </div>
        </div>
    )
}

export default EditUserInformation;