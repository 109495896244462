import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { FiEdit } from 'react-icons/fi';
import { FaEye } from "react-icons/fa";

import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import { tutorHeaderConst, subRowHeader, sortTutorHighToLow, sortTutorLowToHigh, tutorMenuData } from 'pages/auth/tutors/data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { getTutorList } from 'redux/tutor/tutor.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { setClearTutorList } from 'redux/tutor/tutor.slice';
import { modalConst } from 'redux/local/local.const';
import { tutorStatus, tutorVisibilityEnum } from "redux/tutor/tutor.const";

import { edulyteAppPagesInfo, pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const TutorImageContainer = ({ profilePicUrl }) => {

    return (
        <div className={"w-14 h-14 overflow-hidden rounded-full"}>
            <img
                src={profilePicUrl}
                className={"w-full h-full object-cover"}
            />
        </div>
    )
}

const DisplayTutors = () => {
    const { modal } = useAppState((state) => state.local)
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { tutorList, filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        dispatcher(getTutorList({ page: filterProps?.page || 1, records: filterProps?.records }))

        return () => {
            dispatcher(setClearTutorList())
        }
    }, [filterProps?.records])

    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo.DASHBOARD.pagePath}`, "_self")
        }
    }, [adminLoginAs?.data])

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortTutorList = (course1, course2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortTutorLowToHigh(activeSortHeader, course1, course2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortTutorHighToLow(activeSortHeader, course1, course2)
        }
    }

    const onHandleSelect = (option, userId) => {
        switch (option.value) {
            case tutorMenuData.ADD_CLASS.value:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.ADD_COURSE_MODAL.stateKey]: true
                }))
                break;
            case tutorMenuData.LOGIN_AS.value:
                dispatcher(createAdminLoginAsDetail({ user_id: userId, source_url: window.location.href }));
            default:
                break;
        }
    }


    const rows = tutorList?.filteredTutorList?.results?.slice(0)?.sort(sortTutorList).map((tutor, index) => [
        <TutorImageContainer profilePicUrl={tutor?.user?.profilePicUrl} />,
        tutor?.id,
        tutor?.user?.id,
        tutor?.user?.firstName + " " + tutor?.user?.lastName?.charAt(0),
        tutor?.user?.email
            ? (tutor?.user?.email?.length > 24)
                ? tutor?.user?.email?.slice(0, 24) + " ...."
                : tutor?.user?.email
            : "-",
        tutorStatus[tutor?.status?.toUpperCase()]?.label || "-",
        tutorVisibilityEnum[tutor?.visibility?.toUpperCase()]?.label || "-",
        dayjs(tutor?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        <div
            key={index}
            className="flex justify-start items-center"
        >
            <Link
                to={`${pagesInfo?.VIEW_TUTOR.pagePath}/${tutor?.id}`}
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
            >
                <FaEye className="text-xl hover:scale-110" />
            </Link>
            <Link
                to={`${pagesInfo?.EDIT_TUTOR.pagePath}/${tutor?.id}/edit`}
                className="px-2 py-1 bg-background-lightDark text-white rounded-r-md"
            >
                <FiEdit className="text-xl hover:scale-110" />
            </Link>
            <ThreeDotMenu menuItems={tutorMenuData} onHandleSelect={(option) => onHandleSelect(option, tutor?.user?.id)} />
        </div>,
    ]);

    return (
        <>
            <Table
                headers={Object.values(tutorHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={tutorList?.isLoading}
                message={(tutorList?.errorMsg || (tutorList?.filteredTutorList?.records === 0)) && (tutorList?.errorMsg || "No tutors found")}
            />
            {(tutorList?.filteredTutorList && (tutorList?.filteredTutorList?.totalPages > 1)) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={tutorList?.filteredTutorList?.page}
                        totalPages={tutorList?.filteredTutorList?.totalPages}
                        onChangePage={(page) => dispatcher(getTutorList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </>
    )
}

export default DisplayTutors