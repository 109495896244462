// import { toast } from "react-toastify";

import orderService from "redux/order/order.service";
import {
    setOrderListLoading,
    setOrderListData,
    setOrderListMessage,

    setOrderDetailLoading,
    setOrderDetailData,
    setOrderDetailMessage,
} from "redux/order/order.slice";

// user order list
export const getOrderList = (query) => async (dispatch) => {
    dispatch(setOrderListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await orderService.getOrderList(requestData)
        if (response.status === 200) {
            dispatch(setOrderListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOrderListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setOrderListLoading(false))
    }
}

// user order detail
export const getOrderDetail = (orderId) => async (dispatch) => {
    dispatch(setOrderDetailLoading(true))

    try {
        const requestData = {
            params: { orderId: orderId }
        }
        const response = await orderService.getOrderDetail(requestData)
        if (response.status === 200) {
            dispatch(setOrderDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setOrderDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setOrderDetailLoading(false))
    }
}