import { orderFilterProps, orderDetailPayload } from "./order.const";

export const ORDER_INITIAL_STATE = {
    orderList: {
        isLoading: false,
        data: null,
        message: null
    },
    orderDetail: {
        isLoading: false,
        data: null,
        payload: orderDetailPayload,
        message: null
    },
    filterProps: orderFilterProps,
}