import './styles/global.css';

import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import store from "./redux/store";

import InitRouter from './routers/Router';

const App = () => {

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path={"/*"} element={<InitRouter />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;