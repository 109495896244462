import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getWalletTransactionList } from "redux/walletTxn/walletTxn.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { dateRangesLabels } from "redux/local/local.const";
import { setClearFilterProps, setFilterProps } from "redux/walletTxn/walletTxn.slice";
import {
    filterName,
    amountImpact,
    txnType,
    walletTxnActions,
    transactionStatus
} from "redux/walletTxn/walletTxn.const";

import { dayjs } from "utils/dateTime.utils";

export const SearchUserId = () => {
    const { walletTxnList, filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"UserId"}
            value={filterProps?.user}
            placeholder={"Search User ID"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, user: e.target.value }
            }))}
        />
    )
}

export const SearchUserName = () => {
    const { walletTxnList, filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"Search Name"}
            value={filterProps.userName}
            placeholder={"Search Name"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, userName: e.target.value },
                list: walletTxnList?.walletTxnList,
                filterName: filterName.WALLET_TRANSACTIONS.value
            }))}
        />
    )
}

export const SearchAmount = () => {
    const { filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"Search Amount"}
            value={filterProps?.amount}
            placeholder={"Search Amount"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, amount: e.target.value }
            }))}
            disabled={true}
        />
    )
}

export const SearchWalletTxnId = () => {
    const { filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            value={filterProps?.walletTxnId}
            placeholder={"Search Wallet Txn ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, walletTxnId: e.target.value }
            }))}
        />
    )
}

export const SearchVirtualTxnId = () => {
    const { filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            value={filterProps?.virtualTxnId}
            placeholder={"Search Virtual Txn ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, virtualTxnId: e.target.value }
            }))}
        />
    )
}

export const FilterAmountImpact = () => {
    const { filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(amountImpact)}
            value={filterProps.amountImpact}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, amountImpact: option.value }
            }))}
        />
    )
}
export const FilterTxnType = () => {
    const { filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(txnType)}
            value={filterProps.txnType}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, txnType: option.value }
            }))}
        />
    )
}

export const FilterWalletAction = () => {
    const { filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(walletTxnActions)}
            value={filterProps.walletAction}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, walletAction: option.value }
            }))}
        />
    )
}

export const FilterTransactionStatus = () => {
    const { walletTxnList, filterProps } = useAppState((state) => state.walletTxn)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(transactionStatus)}
            value={filterProps.status}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, action: option.value },
                list: walletTxnList?.walletTxnList,
                filterName: filterName.WALLET_TRANSACTIONS.value
            }))}
        />
    )
}

export const SearchDate = () => {
    const { filterProps } = useAppState((state) => state.course)
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (filterProps?.startDate && filterProps?.endDate) {
            setSelectedDateRange({
                startDate: dayjs(filterProps?.startDate)?.format("DD/MMM/YYYY"),
                endDate: dayjs(filterProps?.endDate)?.format("DD/MM/YYYY")
            })
        }
    }, [filterProps])

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "w-full py-0.5 flex items-center justify-center text-center gap-1 bg-white",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
                disabled={true}
            />
        </DateRangePicker>
    )
}

export const ButtonAction = () => {
    const { filterProps } = useAppState((state) => state.walletTxn)

    const dispatcher = useAppDispatcher()

    const handleApply = () => {
        const requestDataPayload = {
            page: 1,
            records: 25
        }
        if (filterProps?.walletTxnId) {
            requestDataPayload["walletTxnId"] = filterProps?.walletTxnId
        }
        if (filterProps?.virtualTxnId) {
            requestDataPayload["virtualTxnId"] = filterProps?.virtualTxnId
        }
        if (filterProps?.user) {
            if (isNaN(filterProps?.user)) {
                requestDataPayload["userFullName"] = filterProps?.user
            } else {
                requestDataPayload["userId"] = filterProps?.user
            }
        }
        if (filterProps?.amount) {
            requestDataPayload["amount"] = filterProps?.amount
        }
        if (filterProps?.amountImpact) {
            requestDataPayload["amountImpact"] = filterProps?.amountImpact
        }
        if (filterProps?.txnType) {
            requestDataPayload["txnType"] = filterProps?.txnType
        }
        if (filterProps?.walletAction) {
            requestDataPayload["action"] = filterProps?.walletAction
        }
        // if(filterProps?.startDate) {
        //     requestDataPayload["minCreatedAt"] = dayjs(filterProps?.startDate)?.format("YYYY-MM-DD HH:mm:ss")
        // }
        // if(filterProps?.endDate) {
        //     requestDataPayload["maxCreatedAt"] = dayjs(filterProps?.endDate)?.format("YYYY-MM-DD HH:mm:ss")
        // }
        dispatcher(getWalletTransactionList(requestDataPayload))
    }

    const handleClear = () => {
        const requestDataPayload = {
            page: 1,
            records: 25
        }
        dispatcher(getWalletTransactionList(requestDataPayload))
        dispatcher(setClearFilterProps())
    }

    return (
        <div className="w-full flex items-center justify-center gap-1">
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-primary-main text-text-50 hover:bg-primary-dark",
                )}
                onClick={handleApply}
            >
                {"Apply"}
            </span>
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-background-lightDark text-text-50 hover:bg-background-dark",
                )}
                onClick={handleClear}
            >
                {"Clear"}
            </span>
        </div>
    )
}