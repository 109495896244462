import PreviewCourseDetails from "./components/PreviewCourseDetails";

const PreviewAndStatusPage = () => {
    return (
        <div className={"space-y-5"}>
            <PreviewCourseDetails />
        </div>
    )
}

export default PreviewAndStatusPage