
import { ImCheckmark } from 'react-icons/im';

import { bookingTutorDetails, selectedCourseData } from "pages/auth/addBooking/data";
import BookingTutorCard from './bookingTutorCard/BookingTutorCard';
import BookingCheckoutCard from './bookingCheckout/BookingCheckoutCard';

const BookingTabs = () => {

    return (
        <div className="w-full md:w-[60rem] p-5 bg-white rounded-lg flex items-center justify-center gap-5 cursor-pointer">
            <span className={"flex items-center justify-center rounded-full p-3 bg-complementary-main"}>
                <ImCheckmark className={"text-2xl text-text-50"} />
            </span>
            <span className={"font-bodyPri font-semibold text-primary-dark text-2xl whitespace-nowrap"}>
                {"Confirm & Pay"}
            </span>
        </div>
    )
}

const AddBookingPage = () => {

    return (
        <div className={"w-full h-full lg:h-screen bg-gradient-to-bl from-secondary-main/30 via-primary-main/30 to-primary-main/40"}>
            <div className={"px-5 py-10 space-y-5 mx-auto container md:px-0"}>
                <div className={"flex items-center justify-center gap-5"}>
                    <BookingTabs />
                </div>
                <div className={"flex flex-col-reverse items-center justify-center md:flex-row gap-8"}>
                    <BookingTutorCard tutorDetail={bookingTutorDetails} />
                    <BookingCheckoutCard data={selectedCourseData} />
                </div>

            </div>
        </div>
    )
}

export default AddBookingPage