import { BiCustomize } from "react-icons/bi";
import { MdOutlineLocationOn } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoVideocamOutline } from "react-icons/io5";
import { SiGooglemeet } from "react-icons/si";

import { weeklyTimeSlot } from "redux/booking/booking.const";
import { dataEntriesConst } from "redux/local/local.const";

export const url = {
    GET_SESSION_LIST: "sessions/sessions",
    GET_SESSION_DETAIL: "sessions/sessions/{sessionId}",

    UPDATE_START_SESSION_DETAIL: "sessions/sessions/{sessionId}/start",
    UPDATE_SCHEDULE_SESSION_DETAIL: "sessions/sessions/{sessionId}/schedule",
    UPDATE_DECLINE_SESSION_DETAIL: "sessions/sessions/{sessionId}/decline",
    UPDATE_RESCHEDULE_SESSION_DETAIL: "sessions/sessions/{sessionId}/reschedule",
    UPDATE_COMPLETE_SESSION_DETAIL: "sessions/sessions/{sessionId}/complete",

    GET_SESSION_COURSE_LIST: "sessions/courses",
    GET_SESSION_APPOINTMENT_LIST: "sessions/appointments",

    ADD_SESSION_USER_DETAIL: "sessions/sessions/{sessionId}/session-users",
    UPDATE_SESSION_USER_DETAIL: "sessions/sessions/{sessionId}/session-users/{sessionUserId}",
}

export const sessionRedux = {
    sliceName: "session",
    sessionList: {
        requestName: "getSessionList"
    },
    sessionDetail: {
        requestName: "getSessionDetail"
    },
    startSessionDetail: {
        requestName: "updateStartSessionDetail"
    },
    scheduleSessionDetail: {
        requestName: "updateScheduleSessionDetail"
    },
    declineSessionDetail: {
        requestName: "updateDeclineSessionDetail"
    },
    rescheduleSessionDetail: {
        requestName: "updateRescheduleSessionDetail"
    },
    completeSessionDetail: {
        requestName: "updateCompleteSessionDetail"
    },
    sessionCourseList: {
        requestName: "getSessionCourseList"
    },
    sessionAppointmentList: {
        requestName: "getSessionAppointmentList"
    },
    addSessionUserDetail: {
        requestName: "createSessionUserDetail"
    },
    updateSessionUserDetail: {
        requestName: "updateSessionUserDetail"
    }
}
export const sessionDetailPayload = {
    sessionId: null
}

export const addSessionUserDetailPayload = {
    sessionId: null,
    note: '',
    startDateTime: null,
    endDateTime: null,
    weeklyTimeSlot: weeklyTimeSlot,
    availabilityId: null,
    title: "",
    duration: 60,
    schedule: []
}

export const modifySessionUserDetailPayload = {
    sessionId: null,
    userId: null,
    sessionUserId: null,
    role: "",
    userType: "",
    status: "",
    comment: "",
    roleList: [],
    isEditSessionUserEnabled: false,
}

export const sessionTypeEnum = {
    APPOINTMENT: {
        label: "Appointment",
        value: "appointment"
    },
    COURSE: {
        label: "Course",
        value: "course"
    },
    INTERVIEW: {
        label: "Interview",
        value: "interview"
    }
}

export const sessionCourseTypeEnum = {
    ALL: {
        label: "All",
        value: "all"
    },
    ONE_ON_ONE: {
        label: "One on One",
        value: "one_on_one"
    },
    GROUP: {
        label: "Group",
        value: "group"
    }
}

export const sessionUserTypeEnum = {
    PARTICIPANT: {
        label: "Participant",
        value: "participant"
    },
    MODERATOR: {
        label: "Moderator",
        value: "moderator"
    }
}

export const sessionStatusEnum = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    NOT_MAPPED: {
        label: "Not Mapped",
        value: "not_mapped",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    DECLINED: {
        label: "Declined",
        value: "declined",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    RESCHEDULED: {
        label: "Rescheduled",
        value: "rescheduled",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
    },
    SCHEDULED: {
        label: "Scheduled",
        value: "scheduled",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    ON_GOING: {
        label: "On Going",
        value: "on_going",
        lightColor: 'yellow-100',
        darkColor: 'yellow-500',
    },
    COMPLETED: {
        label: "Completed",
        value: "completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const sessionLocationTypeEnum = {
    custom: {
        key: "custom",
        label: "Custom",
        icon: BiCustomize,
    },
    in_person_meeting: {
        key: "in_person_meeting",
        label: "In Person Meeting",
        icon: MdOutlineLocationOn,
    },
    phone_call: {
        key: "phone_call",
        label: "Phone Call",
        icon: FiPhoneCall,
    },
    invitee: {
        key: "invitee",
        label: "Ask Invitee",
        icon: AiOutlineQuestionCircle,
    },
    google_meet: {
        key: "google_meet",
        label: "Google Meet",
        icon: SiGooglemeet,
    },
    edulyte_meet: {
        key: "edulyte_meet",
        label: "Edulyte Meet",
        icon: IoVideocamOutline,
    },
}

export const sessionLocationPhoneCallTypeEnum = {
    i_will_call: {
        label: "",
        key: "i_will_call",
        title: "I will call my invitee",
        subtitle: "Edulyte will require your invitee’s phone number before scheduling."
    },
    call_me: {
        label: "",
        key: "call_me",
        title: "My invitee should call me",
        subtitle: "Edulyte will provide your number after the call has been scheduled."
    }
}

export const appointmentSessionStatusActions = {
    PARTICIPANT: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
            // RESCHEDULED: {
            //     label: 'Reschedule',
            //     value: 'rescheduled'
            // },
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            // RESCHEDULED: {
            //     label: 'Reschedule',
            //     value: 'rescheduled'
            // }
        },
        RESCHEDULED: {
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            },
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
    },
    MODERATOR: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            },
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            }
        },
        RESCHEDULED: {
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            ON_GOING: {
                label: "On Going",
                value: "on_going"
            },
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            },
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            },
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            },
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
    }
}

export const courseSessionStatusActions = {
    PARTICIPANT: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
        },
        RESCHEDULED: {
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            }
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
    },
    MODERATOR: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        RESCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            ON_GOING: {
                label: "On Going",
                value: "on_going"
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
    }
}

export const interviewSessionStatusActions = {
    PARTICIPANT: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            }
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            }
        },
        RESCHEDULED: {
            RESCHEDULED: {
                label: 'Reschedule',
                value: 'rescheduled'
            }
        },
        SCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            }
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
    },
    MODERATOR: {
        PENDING: {
            PENDING: {
                label: 'Pending',
                value: 'pending'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        DECLINED: {
            DECLINED: {
                label: 'Declined',
                value: 'declined'
            },
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        RESCHEDULED: {
            SCHEDULED: {
                label: 'Schedule',
                value: 'scheduled'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        SCHEDULED: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            },
            RESCHEDULED: {
                label: 'Rescheduled',
                value: 'rescheduled'
            }
        },
        ON_GOING: {
            ON_GOING: {
                label: 'On Going',
                value: 'on_going'
            },
            RESCHEDULE: {
                label: "Reschedule",
                value: "reschedule"
            }
        },
        CANCELLED: {
            CANCELLED: {
                label: 'Cancelled',
                value: 'cancelled'
            }
        },
        COMPLETED: {
            COMPLETED: {
                label: 'Completed',
                value: 'completed'
            }
        },
    },
}

export const sessionFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    sessionId: "",
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    page: 1,
    records: dataEntriesConst?.TWENTY_FIVE?.value
}