import { memo, useMemo, useState, useCallback } from 'react';

import { IoIosCheckmark } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import ToolTipView from 'components/toolTip/index';

import AppointmentDropdown from "./AppointmentDropdown";
import { myPriceKeyConst, sessionDurationConst, appointmentDetailTooltips } from 'pages/auth/editTutor/data';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyAppointmentDetailPayload } from 'redux/appointment/appointment.slice';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { appointmentPriceModelEnum, appointmentVisibilityEnum } from 'redux/appointment/appointment.const';

import { cn } from "utils/cn.utils";
import { debounce } from 'utils/generators.utils';

const AppointmentPriceDetail = memo(({ appointmentDuration }) => {
    const { locals } = useAppState((state) => state.local)
    const { appointmentDetail, modifyAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const [isCustomDurationEnabled, setIsCustomDurationEnabled] = useState(appointmentDuration > 60)
    const [sessionDuration, setSessionDuration] = useState(appointmentDuration > 60 ? sessionDurationConst.CUSTOM.value : appointmentDuration)
    const [customDuration, setCustomDuration] = useState(appointmentDuration)

    const isFlatFeePriceModel = useMemo(() => [appointmentPriceModelEnum?.FLAT_FEE?.value]?.includes(modifyAppointmentDetail?.payload?.price_model), [modifyAppointmentDetail?.payload?.price_model])

    const onHandleChangeText = (key, value) => {
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            [key]: value
        }))
    }

    const getDefaultCurrencyCode = useCallback(() => {
        let currencyCode = [masterCurrencyCodeEnum.AUD.value, masterCurrencyCodeEnum.INR.value]?.includes(locals?.currencyCode)
            ? locals?.currencyCode
            : masterCurrencyCodeEnum.USD.value
        return { code: currencyCode }
    }, [locals?.currencyCode])

    const onHandleSelectPriceModel = useCallback((key, value) => {
        let payload = {
            [key]: value
        }
        if (value === appointmentPriceModelEnum.FLAT_FEE.value) {
            payload["masterCurrency"] = appointmentDetail?.data?.result?.appointment_price?.masterCurrency || getDefaultCurrencyCode()
            payload["price"] = appointmentDetail?.data?.result?.appointment_price?.price || 1000
            payload["discount_pct"] = appointmentDetail?.data?.result?.appointment_price?.discount_pct || 0
        } else {
            payload["masterCurrency"] = appointmentDetail?.data?.result?.appointment_price?.masterCurrency || null
            payload["price"] = appointmentDetail?.data?.result?.appointment_price?.price || 0
            payload["discount_pct"] = appointmentDetail?.data?.result?.appointment_price?.discount_pct || 0
        }
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            ...payload,
        }))
    }, [modifyAppointmentDetail?.payload, appointmentDetail?.data?.result])

    const onHandleSelectCurrencyCode = useCallback((option) => {
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            masterCurrency: {
                ...modifyAppointmentDetail?.payload?.masterCurrency,
                code: option?.value
            }
        }))
    }, [modifyAppointmentDetail?.payload])

    const onHandleChangeDuration = useCallback((key, value) => {
        setCustomDuration(value)

        const delayFunction = debounce.debounce(onHandleChangeText, 0)
        delayFunction(key, value)
    }, [customDuration, modifyAppointmentDetail?.payload])

    const onHandleSelectDuration = useCallback((selectedOption) => {
        if ([sessionDurationConst.THIRTY.value, sessionDurationConst.FORTY_FIVE.value, sessionDurationConst.SIXTY.value]?.includes(selectedOption?.value)) {
            setIsCustomDurationEnabled(false)

            setSessionDuration(selectedOption?.value)

            const delayFunction = debounce.debounce(onHandleChangeText)
            delayFunction(myPriceKeyConst.DURATION.value, selectedOption?.value)
        } else {
            setSessionDuration(null)
            setCustomDuration(30)

            const delayFunction = debounce.debounce(onHandleChangeText, 0)
            delayFunction(myPriceKeyConst.DURATION.value, 30)
            setIsCustomDurationEnabled(true)
        }
    }, [modifyAppointmentDetail?.payload, sessionDuration, customDuration])


    return (
        <div className={"grid grid-cols-12 gap-5 w-full"}>

            <div className='col-start-1 col-span-full lg:col-start-1 lg:col-span-6 space-y-2'>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={'font-bodyPri font-medium text-text-900 text-base'}>
                        {"Session Duration *"}
                    </span>
                    <ToolTipView content={appointmentDetailTooltips.duration}>
                        <button className={""}>
                            <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                        </button>
                    </ToolTipView>
                </div>
                <AppointmentDropdown
                    labelItem={`appointment_duration`}
                    showLabel={false}
                    options={Object.values(sessionDurationConst)}
                    onHandleSelect={onHandleSelectDuration}
                    value={[sessionDurationConst.THIRTY.value, sessionDurationConst.FORTY_FIVE.value, sessionDurationConst.SIXTY.value]?.includes(sessionDuration) ? sessionDuration + " min" : sessionDurationConst.CUSTOM.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                {option?.label}
                            </span>
                            {(option?.value === sessionDuration)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    dropdownWrapperClassName={"w-full"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                />
                {isCustomDurationEnabled &&
                    <div className={"flex flex-col items-start justify-start gap-1"}>
                        <div className={"flex items-center justify-start gap-3"}>
                            <input
                                type={"number"}
                                name={myPriceKeyConst.DURATION.value}
                                className={cn(
                                    "w-16 h-10 px-3 input-number-spin-none text-text-900 bg-white rounded bg-transparent outline-none placeholder:text-text-500",
                                    "border border-divider-lightDark hover:border-primary-main font-bodyPri cursor-text",
                                    "focus:ring-2 focus:ring-primary-main focus:border-none transition duration-200",
                                    (!customDuration || (customDuration < 30)) && "focus:ring-red-500 border-red-500 hover:border-red-500",
                                )}
                                autoFocus={true}
                                minValue={2}
                                defaultValue={2}
                                value={(customDuration / 15)?.toString()}
                                onChange={(event) => onHandleChangeDuration(event?.target?.name, Number(event?.target?.value * 15))}
                                required={true}
                            />
                            <span className={"font-bodyPri font-normal text-text-800 text-base whitespace-nowrap"}>
                                {"x 15 min. ="}
                            </span>
                            <div className={"inline-flex items-center justify-start gap-1 font-bodyPri font-bold text-text-800 text-lg"}>
                                <span className={""}>
                                    {((customDuration / 15) <= 3)
                                        ? (customDuration)
                                        : (parseFloat("" + (customDuration / 60))?.toFixed(2))
                                    }
                                </span>
                                <span className={""}>
                                    {((customDuration / 15) <= 3)
                                        ? " min"
                                        : " hr"
                                    }
                                </span>
                            </div>
                        </div>
                        {(customDuration < 30) &&
                            <div className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                {"The duration must be at least 30 minutes."}
                            </div>
                        }
                    </div>
                }
            </div>

            {/* price model container */}
            <div className={'col-start-1 col-span-full lg:col-start-7 lg:col-span-full space-y-2'}>
                <span className='font-bodyPri font-medium text-text-900 text-base'>
                    {"Price Model *"}
                </span>
                <FloatingLabelSelect
                    labelItem={`appointment_price_model`}
                    showLabel={false}
                    options={Object.values(appointmentPriceModelEnum)}
                    onHandleSelect={(option) => onHandleSelectPriceModel(myPriceKeyConst?.PRICE_MODEL?.value, option?.value)}
                    value={appointmentPriceModelEnum[modifyAppointmentDetail?.payload?.price_model?.toUpperCase()]?.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                {option?.label}
                            </span>
                            {(option?.value === modifyAppointmentDetail?.payload?.price_model)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    dropdownWrapperClassName={"w-full"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                />
            </div>

            {/* visibility container */}
            <div className={'col-start-1 col-span-full lg:col-start-1 lg:col-span-6 space-y-2'}>
                <span className='font-bodyPri font-medium text-text-900 text-base'>
                    {"Visibility"}
                </span>
                <FloatingLabelSelect
                    labelItem={`appointment_visibility`}
                    showLabel={false}
                    options={Object.values(appointmentVisibilityEnum)}
                    onHandleSelect={(option) => onHandleChangeText(myPriceKeyConst?.VISIBILITY?.value, option?.value)}
                    value={appointmentVisibilityEnum[modifyAppointmentDetail?.payload?.visibility?.toUpperCase()]?.label}
                    OptionChild={({ option }) => (
                        <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                {option?.label}
                            </span>
                            {(option?.value === modifyAppointmentDetail?.payload?.visibility)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    dropdownWrapperClassName={"w-full"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                />
            </div>

            {isFlatFeePriceModel &&
                <>
                    {/* currency Code */}
                    <div className={'col-start-1 col-span-full lg:col-start-7 lg:col-span-full space-y-2'}>
                        <span className={'font-bodyPri font-normal text-text-900 text-base'}>
                            {"Currency Code "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <FloatingLabelSelect
                            labelItem={`appointment_currency_code`}
                            showLabel={false}
                            options={Object.values(masterCurrencyCodeEnum)}
                            onHandleSelect={onHandleSelectCurrencyCode}
                            value={masterCurrencyCodeEnum[modifyAppointmentDetail?.payload?.masterCurrency?.code?.toUpperCase()]?.label}
                            OptionChild={({ option }) => (
                                <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                                    <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                        {option?.label}
                                    </span>
                                    {(option?.value === modifyAppointmentDetail?.payload?.masterCurrency?.code)
                                        && <IoIosCheckmark size={20} color="green" />
                                    }
                                </div>
                            )}
                            dropdownWrapperClassName={"w-full"}
                            dropDownContainerClassName={"w-full max-h-44 overflow-y-auto scrollbar-thin"}
                        />
                    </div>

                    {/* price container */}
                    <div className='col-start-1 col-span-full lg:col-start-1 lg:col-span-6 space-y-2'>
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={'font-bodyPri font-medium text-text-900 text-base'}>
                                {"Price *"}
                            </span>
                            <ToolTipView content={appointmentDetailTooltips.price}>
                                <button className={""}>
                                    <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                                </button>
                            </ToolTipView>
                        </div>
                        <div className={cn(
                            "h-10 px-3 flex items-center text-text-900 bg-white border rounded border-divider-lightDark",
                            "hover:border-primary-main focus-within:ring-2 focus-within:ring-primary-main focus-within:border-none",
                        )}>
                            <input
                                type={"number"}
                                className={cn(
                                    "flex-1 px-3 input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={(modifyAppointmentDetail?.payload?.price / 100)?.toString()}
                                onChange={(event) => {
                                    let safe_number = Number(event?.target?.value)
                                    if (isNaN(safe_number)) {
                                        safe_number = 0
                                    }
                                    onHandleChangeText(myPriceKeyConst?.PRICE?.value, safe_number * 100)
                                }}
                                required={isFlatFeePriceModel}
                            />
                        </div>
                    </div>

                    {/* discount pct container */}
                    <div className='col-start-1 col-span-full lg:col-start-7 lg:col-span-full space-y-2'>
                        <span className={'font-bodyPri font-medium text-text-900 text-base'}>
                            {"Discount (in percentage)"}
                        </span>
                        <input
                            type={"number"}
                            className={cn(
                                "w-full h-10 px-3 input-number-spin-none text-text-900 bg-white border rounded border-divider-lightDark",
                                "hover:border-primary-main focus:outline-none focus:ring-2 focus:ring-primary-main focus:border-none",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            value={modifyAppointmentDetail?.payload?.discount_pct?.toString()}
                            onChange={(event) => {
                                let safe_number = Number(event?.target?.value)
                                if (isNaN(safe_number)) {
                                    safe_number = 0
                                }
                                if (safe_number <= 100) {
                                    onHandleChangeText(myPriceKeyConst?.DISCOUNT_PCT?.value, safe_number)
                                }
                            }}
                            required={true}
                        />
                    </div>
                </>
            }
        </div>
    )
})

export default AppointmentPriceDetail;