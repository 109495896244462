import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import SelectSearch, { fuzzySearch } from 'react-select-search';

import { OptionSelector } from "components/common-components/Select";
import ComponentLoader from "components/loader/ComponentLoader";
import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/viewCourseDetail/viewCourse.style';
import { inputSections } from "pages/auth/viewCourseDetail/data";

import { getAllCategoryList } from "redux/category/category.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCategoryList } from "redux/category/category.slice";
import { courseType, visibilityType } from "redux/course/course.const";

const CourseDetailsSection = ({ clickedItem, setClickedItem }) => {
    const { categoryList } = useAppState((state) => state.category)
    const { courseDetail, addCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [courseDetailData, setCourseDetailData] = useState(courseDetail?.courseDetail)

    useEffect(() => {
        dispatcher(getAllCategoryList())

        return () => {
            dispatcher(setClearCategoryList())
        }
    }, [])

    const selectedSegment = categoryList?.categoryList?.find((category) => category?.category === courseDetailData?.category)

    const isOpen = inputSections?.COURSE_DETAILS?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_DETAILS?.value)) {
            setClickedItem(inputSections?.COURSE_DETAILS?.value)
        } else {
            setClickedItem(null)
        }
    }

    return (
        <div className={"w-full h-full select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Details"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {(courseDetail?.isLoading || addCourseDetail?.isLoading) &&
                    <ComponentLoader isLoading={courseDetail?.isLoading || addCourseDetail?.isLoading} />
                }
                {(courseDetail?.errorMsg || addCourseDetail?.errorMsg) &&
                    <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                        {courseDetail?.errorMsg || addCourseDetail?.errorMsg}
                    </div>
                }
                {/* class title container */}
                {!addCourseDetail?.isLoading &&
                    <div className={"w-full flex flex-col items-start justify-start gap-3 px-3"}>
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <div className={"w-full flex items-center justify-start gap-1"}>
                                <span className="font-bodyPri font-normal text-base text-text-900 tracking-wide">
                                    {"Class Slug:"}
                                </span>
                            </div>
                            <input
                                type={"text"}
                                maxLength={80}
                                value={courseDetailData?.slug}
                                className={cn(
                                    "w-full px-3 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-200"
                                )}
                                disabled={true}
                            />
                        </div>
                        <div className={"w-full flex flex-col items-start justify-center gap-5 md:gap-2"}>
                            <span className="font-bodyPri font-normal text-base text-text-900 tracking-wide">
                                {"Class Name:"}
                                <span className={"text-lg text-red-500"}>{"*"}</span>
                            </span>
                            <input
                                type={"text"}
                                maxLength={80}
                                value={courseDetailData?.title}
                                className={cn(
                                    "w-full px-3 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => setCourseDetailData({
                                    ...courseDetailData,
                                    title: e.target.value
                                })}
                                disabled={true}
                            />
                        </div>

                        {/* class title container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-5 md:gap-2"}>
                            <span className={"font-bodyPri font-normal text-base text-text-900 tracking-wide"}>
                                {"Class Sub Heading:"}
                                <span className={"text-lg text-red-500"}>{"*"}</span>
                            </span>
                            <textarea
                                type={"text"}
                                value={courseDetailData?.subTitle}
                                rows={3}
                                maxLength={250}
                                className={cn(
                                    "w-full px-3 py-1.5 resize-y border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => setCourseDetailData({
                                    ...courseDetailData,
                                    subTitle: e.target.value
                                })}
                                disabled={true}
                            />
                        </div>

                        {/* segment container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2"}>
                            <span className={"text-text-900 lg:whitespace-nowrap"}>
                                {"Segment:"}
                                <span className={"text-lg text-red-500"}>{"*"}</span>
                            </span>
                            <input
                                type={"text"}
                                value={selectedSegment?.segment}
                                className={cn(
                                    "w-full sm:w-60 px-3 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                disabled={true}
                            />
                        </div>

                        {/* category list container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-5 md:gap-2"}>
                            <span className={"font-bodyPri font-normal text-base text-text-900 tracking-wide"}>
                                {"Category:"}
                                <span className={"text-lg text-red-500"}>{"*"}</span>
                            </span>
                            {categoryList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {categoryList?.categoryList &&
                                <div className={cn(
                                    "w-full sm:w-60 sm:px-3 sm:py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}>
                                    <SelectSearch
                                        options={categoryList?.categoryList?.map((category) => ({
                                            name: category.category,
                                            value: category.category,
                                        }))}
                                        search
                                        autoFocus={false}
                                        placeholder={"Select Category"}
                                        filterOptions={fuzzySearch}
                                        value={courseDetailData?.category}
                                        disabled={true}
                                    />
                                </div>
                            }
                            {(categoryList?.errorMsg) &&
                                <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                    {categoryList?.errorMsg}
                                </div>
                            }
                        </div>

                        {/* visibility container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-5 md:gap-3"}>
                            <span className={"font-bodyPri font-normal text-base text-text-900 tracking-wide"}>
                                {"Visibility:"}
                                <span className={"text-lg text-red-500"}>{"*"}</span>
                            </span>
                            <div className={"w-full sm:w-60 h-32 border-2 border-divider-medium rounded-md"}>
                                <OptionSelector
                                    options={Object.values(visibilityType)}
                                    className={"w-full"}
                                    value={courseDetailData?.visibility}
                                    required={true}
                                />
                            </div>
                        </div>

                        {/* class type container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2"}>
                            <span className={"font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                                {"Class Type:"}
                                <span className={"text-lg text-red-500"}>{"*"}</span>
                            </span>
                            <input
                                type={"text"}
                                value={courseType[courseDetailData?.type?.toUpperCase()]?.label}
                                className={cn(
                                    "w-full sm:w-60 px-3 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                disabled={true}
                            />
                        </div>
                    </div>
                }
            </CollapsableContainer>
        </div>
    )
}

export default CourseDetailsSection