import { useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getLeadList } from "redux/lead/lead.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearFilterProps, setFilterProps } from "redux/lead/lead.slice";
import { contactTime, JourneyConst, leadStatus, leadStrength, leadActionRequired } from "redux/lead/lead.const";
import { dateRangesLabels } from "redux/local/local.const";

import { dayjs } from "utils/dateTime.utils";

export const SearchLeadId = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            value={filterProps?.leadId}
            placeholder={"Search lead ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, leadId: e.target.value }
            }))}
        />
    )
}

export const SearchLeadName = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            value={filterProps.user}
            placeholder={"Search Name"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, user: e.target.value }
            }))}
        />
    )
}

export const SearchEmail = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            value={filterProps.email}
            placeholder={"Search Email"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, email: e.target.value }
            }))}
        />
    )
}

export const SearchMobileNo = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            value={filterProps.mobileNo}
            placeholder={"Search MobileNo"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, mobileNo: e.target.value }
            }))}
        />
    )
}

export const FilterLeadType = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(JourneyConst)}
            value={filterProps.leadType}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, leadType: option.value }
            }))}
        />
    )
}

export const FilterContactTime = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(contactTime)}
            value={filterProps.contactTime}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, contactTime: option.value }
            }))}
        />
    )
}

export const FilterLeadStrength = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(leadStrength)}
            value={filterProps.leadStrength}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, leadStrength: option.value }
            }))}
        />
    )
}

export const FilterLeadStatus = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(leadStatus)}
            value={filterProps.status}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, status: option.value }
            }))}
        />
    )
}

export const SearchDate = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            onApply={(event, picker) => handleApply(picker)}
        // onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "px-1 py-0.5 flex items-center justify-center gap-1 bg-white",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
                disabled={true}
            />
        </DateRangePicker>
    )
}

export const ButtonAction = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    const handleApply = () => {
        const requestDataPayload = {
            page: 1,
            records: 25
        }
        if (filterProps?.leadId) {
            requestDataPayload["leadId"] = filterProps?.leadId
        }
        if (filterProps?.user) {
            if (isNaN(filterProps?.user)) {
                requestDataPayload["userName"] = filterProps?.user
            } else {
                requestDataPayload["userId"] = filterProps?.userId
            }
        }
        if (filterProps?.email) {
            requestDataPayload["email"] = filterProps?.email
        }
        if (filterProps?.mobileNo) {
            requestDataPayload["mobileNo"] = filterProps?.mobileNo
        }
        if (filterProps?.leadType) {
            requestDataPayload["leadType"] = filterProps?.leadType
        }
        if (filterProps?.contactTime) {
            requestDataPayload["contactTime"] = filterProps?.contactTime
        }
        if (filterProps?.status) {
            requestDataPayload["status"] = filterProps?.status
        }
        dispatcher(getLeadList(requestDataPayload))
    }

    const handleClear = () => {
        dispatcher(setClearFilterProps())
        dispatcher(getLeadList({ page: 1, records: 25 }))
    }

    return (
        <div className="w-full flex items-center justify-center gap-1">
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-primary-main text-text-50 hover:bg-primary-dark",
                )}
                onClick={handleApply}
            >
                {"Apply"}
            </span>
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-background-lightDark text-text-50 hover:bg-background-dark",
                )}
                onClick={handleClear}
            >
                {"Clear"}
            </span>
        </div>
    )
}

export const FilterTaskAction = () => {
    const { filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(leadActionRequired)}
            value={filterProps.leadActionRequired}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, leadAction: option.value }
            }))}
        />
    )
}