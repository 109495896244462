import { userRoles } from "redux/auth/auth.const";

export const toggleBtnConst = {
    PERSONAL_DETAIL: {
        value: "personal_detail"
    },
    QUESTIONS_AND_ANSWERS: {
        value: "questions_and_answers"
    },
    DOCUMENT_VERIFICATION: {
        value: "document_verification"
    },
    VIDEO_CALL: {
        value: "video_call"
    },
    TUTOR_PREFERENCES: {
        value: "tutor_preferences"
    },
    CREATE_TRIAL_CLASS: {
        value: "create_trial_class"
    },
    PROFESSIONAL_REFERENCE: {
        value: "professional_reference"
    },
    BACKGROUND_CHECK: {
        value: "background_check"
    }
}

export const questionType = {
    SINGLE_TEXT: {
        value: "single_text"
    },
    MULTIPLE_TEXT: {
        value: "multiple_text"
    },
    SINGLE_CHOICE: {
        value: "single_choice"
    },
    MULTIPLE_CHOICE: {
        value: "multiple_choice"
    }
}

export const documentTableHeader = [
    "Category",
    "File Name",
    "Status"
]

export const videoCallTableHeaders = [
    "Interviewer",
    "Date",
    "Start Time",
    "Status"
]

export const viewOnboardingHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    ONBOARDING_ID: {
        label: "Onboarding ID",
        value: "onboarding_id"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    PHONE: {
        label: "Phone",
        value: "phone"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTION: {
        label: "Action",
        value: "action"
    }
}

export const viewNavigationOptions = [
    { label: "User Details", to: "/user-details", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Relevant Questions", to: "/relevant-questions", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Intro Video", to: "/intro-video", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Documents", to: "/verify-documents", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Tutor Preferences", to: "/tutor-pref", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Video Call", to: "/video-call", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Approve Tutor", to: "/verify-tutor", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Comments", to: "/", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
];