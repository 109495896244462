import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { createOnboardingInterviewDetailByOnboardingId } from 'redux/onboarding/onboarding.request';

import { useAppDispatcher } from 'hooks/useStore';

const SkipVideoCall = ({ setIsSkipVideoCallEnabled, setIsAddSchedule }) => {
    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [comments, setComments] = useState("")

    const onSubmit = (event) => {
        event.preventDefault()

        const requestData = {
            onboardingId: Number(onboardingId),
            body: {
                comments: comments,
                isInterviewSkip: true
            }
        }
        dispatcher(createOnboardingInterviewDetailByOnboardingId(requestData))
        setIsSkipVideoCallEnabled(false)
        setIsAddSchedule(true)
    }

    return (
        <form className={"flex flex-col items-start justify-start gap-3"} onSubmit={onSubmit}>
            <label className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Comments "}
                <span className={"text-red-500 text-sm"}>
                    {"*"}
                </span>
            </label>
            <div>
                <textarea
                    className={"w-full px-3 py-2 resize-none rounded-lg shadow border-2 border-divider-medium focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                    rows={3}
                    cols={38}
                    maxLength={200}
                    onChange={(event) => setComments(event?.target?.value)}
                    value={comments}
                    required={true}
                />
            </div>
            <div className={"flex items-start justify-start gap-5"}>
                <button
                    type={"submit"}
                    className={cn(
                        "w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                        "border border-secondary-main text-secondary-main",
                        "hover:bg-secondary-main hover:text-text-50"
                    )}
                >
                    {"Save"}
                </button>
                <button
                    className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                        "border border-primary-main text-primary-main",
                        "hover:bg-primary-main hover:text-text-50"
                    )}
                    onClick={() => {
                        setIsSkipVideoCallEnabled(false)
                        setIsAddSchedule(true)
                    }}
                >
                    {"Cancel"}
                </button>
            </div>
        </form>
    )
}

export default SkipVideoCall