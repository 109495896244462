import { useMemo } from 'react';

import { FaInfoCircle } from 'react-icons/fa';

import ToolTipView from 'components/toolTip';

import { statusToolTip } from 'pages/auth/sessions/data';

import { useAppState } from 'hooks/useStore';
import { sessionStatusEnum } from "redux/session/session.const";

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const SessionDetailStatus = () => {
    const { sessionDetail } = useAppState(s => s.session)

    const eventLogStatusObject = useMemo(() => sessionDetail?.data?.result?.session_logs?.find(session => session?.status === sessionDetail?.data?.result?.status), [sessionDetail?.data?.result])

    return sessionDetail?.data?.result?.status ? (
        <div className={"flex items-center justify-between gap-1"}>
            <div className={"flex items-center justify-start gap-2"}>
                <span className={"font-bodyPri font-normal text-base text-text-800"}>
                    {"Status: "}
                </span>
                <div className={cn(
                    "flex items-center justify-center gap-1 border-2 rounded-lg px-2 py-0.5",
                    "font-bodyPri font-medium text-base",
                    `border-${sessionStatusEnum[sessionDetail?.data?.result?.status?.toUpperCase()]?.darkColor}`,
                    `text-${sessionStatusEnum[sessionDetail?.data?.result?.status?.toUpperCase()]?.darkColor}`,
                )}>
                    <span className={""}>
                        {sessionStatusEnum[sessionDetail?.data?.result?.status?.toUpperCase()]?.label}
                    </span>
                </div>
                <ToolTipView
                    content={statusToolTip[sessionDetail?.data?.result?.status?.toUpperCase()]}
                    disabled={!sessionDetail?.data?.result?.status}
                >
                    <button className={"cursor-pointer"}>
                        <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                    </button>
                </ToolTipView>
            </div>

            <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                {dayjs(eventLogStatusObject?.createdAt).tz(timeZone).fromNow()}
            </span>
        </div>
    ) : null
}

export default SessionDetailStatus