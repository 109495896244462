import { createSlice } from "@reduxjs/toolkit";

import { LMS_RESOURCE_INITIAL_STATE } from "redux/edulyteLms/lmsResource/lmsResource.initialState";

const lmsResource = createSlice({
    name: "lms resource",
    initialState: LMS_RESOURCE_INITIAL_STATE,
    reducers: {
        setLmsResourceListLoading: (state, { payload }) => {
            state.lmsResourceList.isLoading = payload
        },
        setLmsResourceListData: (state, { payload }) => {
            state.lmsResourceList.data = payload
            state.lmsResourceList.message = LMS_RESOURCE_INITIAL_STATE.lmsResourceList.message
        },
        setLmsResourceListMessage: (state, { payload }) => {
            state.lmsResourceList.message = payload
            state.lmsResourceList.data = LMS_RESOURCE_INITIAL_STATE.lmsResourceList.data
        },
        resetLmsResourceList: (state) => {
            state.lmsResourceList = LMS_RESOURCE_INITIAL_STATE.lmsResourceList
        },

        // reducers for destroy lms resource
        setDestroyLmsResourceDetailLoading: (state, { payload }) => {
            state.destroyLmsResourceDetail.isLoading = payload
        },
        setDestroyLmsResourceDetailMessage: (state, { payload }) => {
            state.destroyLmsResourceDetail.message = payload
        },
        resetDestroyLmsResourceDetail: (state) => {
            state.destroyLmsResourceDetail = LMS_RESOURCE_INITIAL_STATE.destroyLmsResourceDetail
        },

        // filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
        },
        resetFilterProps: (state) => {
            state.filterProps = LMS_RESOURCE_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setLmsResourceListLoading,
    setLmsResourceListData,
    setLmsResourceListMessage,
    resetLmsResourceList,

    setDestroyLmsResourceDetailLoading,
    setDestroyLmsResourceDetailMessage,
    resetDestroyLmsResourceDetail,

    setFilterProps,
    resetFilterProps
} = lmsResource.actions

export default lmsResource.reducer;