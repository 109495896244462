import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import DefaultService from "redux/default/default.service";

import { userDefaultRedux } from "redux/default/default.const";

export const getUserDefaultDetailsByUserId = createAsyncThunk(
    `${userDefaultRedux?.sliceName}/${userDefaultRedux?.userDefaultDetails?.requestName}`,
    async (userId, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await DefaultService.getUserDefaultDetailsByUserId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const updateUserDefaultDetailsByUserId = createAsyncThunk(
    `${userDefaultRedux?.sliceName}/${userDefaultRedux?.userDefaultDetails?.requestName}`,
    async (userDefaultData, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { userId: userDefaultData?.userId },
                body: userDefaultData?.body
            }

            const response = await DefaultService.updateUserDefaultDetailsByUserId(requestData)
            if (response.status === 200) {
                toast.success(response.data.message)
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)