import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";

import OnboardingService from "redux/onboarding/onboarding.service";
import {
    setTutorOnboardingListLoading,
    setTutorOnboardingList,

    setTutorOnboardingDetailLoading,
    setTutorOnboardingDetail,

    setInterviewDetailLoading,
    setInterviewDetail,
    setInterviewDetailErrorMsg,

    setProfessionalReferencesLoading,
    setProfessionalReferences,
    setProfessionalReferencesErrorMsg,

    setBackgroundDetailLoading,
    setBackgroundDetail,
    setBackgroundDetailErrorMsg,

    setCompanyOnboardingLoading,
    setCompanyOnboarding,
    setCompanyOnboardingErrorMsg,

    setCompanyPublicProfileLoading,
    setCompanyPublicProfile,
    setCompanyPublicProfileErrorMsg,

    setAddCompanyPublicProfileLoading,
    setAddCompanyPublicProfileErrorMsg,

    setCompanyCourseListLoading,
    setCompanyCourseList,
    setCompanyCourseListErrorMsg,

    setEmployeeListLoading,
    setEmployeeList,
    setEmployeeListErrorMsg,

    setAddEmployeeLoading,
    setAddEmployeeErrorMsg,

    setCompanyReviewListLoading,
    setCompanyReviewList,
    setCompanyReviewListErrorMsg,

    setImportantLinksListLoading,
    setImportantLinksList,
    setImportantLinksListErrorMsg,

    setAddImportantLinksLoading,
    setAddImportantLinksErrorMsg,

    setSimilarCompanyListLoading,
    setSimilarCompanyList,
    setSimilarCompanyListErrorMsg,

    setCompanyDocumentListLoading,
    setCompanyDocumentList,
    setCompanyDocumentListErrorMsg,

    setAddCompanyDocumentLoading,
    setAddCompanyDocumentErrorMsg,

    setFAQListLoading,
    setFAQList,
    setFAQListErrorMsg,

    setAddFAQLoading,
    setAddFAQErrorMsg,

    setOnboardingUserDetailLoading,
    setOnboardingUserDetail,
    setOnboardingUserDetailErrorMsg,

    setAddOnboardingUserDetailLoading,
    setAddOnboardingUserDetailErrorMsg,

    setUserOnboardingQuestionDetailLoading,
    setUserOnboardingQuestionDetail,
    setUserOnboardingQuestionDetailErrorMsg,

    setAddUserOnboardingQuestionDetailLoading,
    setAddUserOnboardingQuestionDetailErrorMsg,

    setOnboardingDocListLoading,
    setOnboardingDocList,
    setOnboardingDocListErrorMsg,

    setAddOnboardingDocDetailLoading,
    setAddOnboardingDocDetail,
    setAddOnboardingDocDetailErrorMsg,

    setOnboardingInterviewDetailLoading,
    setOnboardingInterviewDetail,
    setOnboardingInterviewDetailErrorMsg,

    setAddOnboardingInterviewDetailLoading,
    setAddOnboardingInterviewDetail,
    setAddOnboardingInterviewDetailErrorMsg,

    setOnboardingTutorPrefDetailLoading,
    setOnboardingTutorPrefDetail,
    setOnboardingTutorPrefDetailErrorMsg,

    setOnboardingTutorTrialCourseDetailLoading,
    setOnboardingTutorTrialCourseDetail,
    setOnboardingTutorTrialCourseDetailErrorMsg,

    setAddOnboardingTutorPrefDetailLoading,
    setAddOnboardingTutorPrefDetailErrorMsg,

    setAddOnboardingTutorTrialCourseDetailLoading,
    setAddOnboardingTutorTrialCourseDetailErrorMsg,

    setOnboardingCommentListLoading,
    setOnboardingCommentListData,
    setOnboardingCommentListMessage,

    setAddOnboardingCommentDetailLoading,
    setAddOnboardingCommentDetailMessage,
} from "redux/onboarding/onboarding.slice";
import { onboardingRedux } from "redux/onboarding/onboarding.const";

import {
    tutorOnboardingList,
    videoInterviewList,
    professionalReferencesList,
    backgroundDetailList,
    companyOnboardingList,
} from "pages/auth/onboarding/data";

import {
    companyProfiles,
    courseList,
    employeeData,
    reviewsList,
    importantLinksList,
    companyDocumentListData,
    similarCompanyList,
    dataFAQ
} from "pages/auth/viewCompanyOnboarding/data";

import { dayjs } from "utils/dateTime.utils";

export const getOnboardingList = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingList?.requestName}`,
    async (query, { rejectWithValue }) => {
        try {
            const requestData = {
                query: query
            }
            const response = await OnboardingService?.getOnboardingList(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const getUserOnboardingListByUserId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.userOnboardingList?.requestName}`,
    async (userId, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await OnboardingService.getUserOnboardingListByUserId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const getOnboardingDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingDetail?.requestName}`,
    async (onboardingId, { rejectWithValue }) => {

        try {
            const requestData = {
                params: { onboardingId: onboardingId }
            }
            const response = await OnboardingService?.getOnboardingDetailByOnboardingId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            return rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const updateOnboardingDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingDetail?.requestName}`,
    async (onboardingDetail, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { onboardingId: onboardingDetail?.onboardingId },
                body: onboardingDetail?.body
            }
            const response = await OnboardingService?.updateOnboardingDetailByOnboardingId(requestData)
            if (response.status === 200) {
                toast.success(response.data.message)
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
        }
    }
)

export const updateOnboardingListByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingList?.requestName}`,
    async (onboardingDetail, { getState, rejectWithValue }) => {
        try {
            const { onboardingList } = getState().onboarding
            const requestData = {
                params: { onboardingId: onboardingDetail?.onboardingId },
                body: onboardingDetail?.body
            }
            const response = await OnboardingService?.updateOnboardingDetailByOnboardingId(requestData)
            if (response.status === 200) {
                toast.success(response.data.message)
                const results = onboardingList?.onboardingList?.results?.map(onboarding => onboarding?.onboardingId === onboardingDetail?.onboardingId ? response.data.data : onboarding)
                return { ...onboardingList?.onboardingList, results }
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
        }
    }
)

export const getOnboardingUserDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingUserDetail?.requestName}`,
    async (onboardingId, { dispatch }) => {
        dispatch(setOnboardingUserDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: onboardingId }
            }
            const response = await OnboardingService.getOnboardingUserDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setOnboardingUserDetail(response.data.data))
                // return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setOnboardingUserDetailErrorMsg(error.response.data.message || error.response.data.error))
            // rejectWithValue(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setOnboardingUserDetailLoading(false))
        }
    }
)

export const updateOnboardingUserDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingUserDetail?.requestName}`,
    async (userDetail, { dispatch }) => {
        dispatch(setAddOnboardingUserDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: userDetail?.onboardingId },
                body: userDetail?.body
            }
            const response = await OnboardingService.updateOnboardingUserDetailByOnboardingId(requestData)
            if (response.status === 200) {
                // dispatch(updateOnboardingDetailByOnboardingId(
                //     Number(userDetail?.onboardingId),
                //     {

                //     }
                //     ))
                dispatch(setOnboardingUserDetail(response.data.data))
                toast.success(response.data.message)
                // return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddOnboardingUserDetailErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setAddOnboardingUserDetailLoading(false))
        }
    }
)

export const getUserOnboardingQuestionDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.userOnboardingQuestionDetail?.requestName}`,
    async (onboardingId, { dispatch }) => {
        dispatch(setUserOnboardingQuestionDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: onboardingId }
            }
            const response = await OnboardingService.getUserOnboardingQuestionDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setUserOnboardingQuestionDetail(response.data.data))
                // return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setUserOnboardingQuestionDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setUserOnboardingQuestionDetailLoading(false))
        }
    }
)

export const updateUserOnboardingQuestionDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.userOnboardingQuestionDetail?.requestName}`,
    async (questionDetail, { dispatch }) => {
        dispatch(setAddUserOnboardingQuestionDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: questionDetail?.onboardingId },
                body: questionDetail?.body
            }
            const response = await OnboardingService.updateUserOnboardingQuestionDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setUserOnboardingQuestionDetail(response.data.data))
                toast.success(response.data.message)
                // return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddUserOnboardingQuestionDetailErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setAddUserOnboardingQuestionDetailLoading(false))
        }
    }
)

export const getOnboardingDocListByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingDocList?.requestName}`,
    async (onboardingDetails, { dispatch }) => {
        dispatch(setOnboardingDocListLoading(true))

        try {
            const requestData = {
                params: { onboardingId: onboardingDetails?.onboardingId },
                query: { ...onboardingDetails?.query }
            }
            const response = await OnboardingService.getOnboardingDocListByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setOnboardingDocList(response.data.data))
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setOnboardingDocListErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setOnboardingDocListLoading(false))
        }
    }
)

export const createOnboardingDocDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingDocList?.requestName}`,
    async (onboardingDocDetail, { getState, dispatch }) => {
        dispatch(setAddOnboardingDocDetailLoading(true))

        try {
            const { onboardingDocList } = getState().onboarding
            const requestData = {
                params: { onboardingId: onboardingDocDetail?.onboardingId },
                body: onboardingDocDetail?.body
            }
            const response = await OnboardingService.createOnboardingDocDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setAddOnboardingDocDetail(response.data.data))
                dispatch(setOnboardingDocList([...onboardingDocList?.onboardingDocList, response.data.data]))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
            dispatch(setAddOnboardingDocDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setAddOnboardingDocDetailLoading(false))
        }
    }
)

export const updateOnboardingDocDetailByOnboardingDocId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingDocList?.requestName}`,
    async (onboardingDocDetail, { getState, dispatch }) => {
        dispatch(setAddOnboardingDocDetailLoading(true))

        try {
            const { onboardingDocList } = getState().onboarding
            const requestData = {
                params: { onboardingDocId: onboardingDocDetail?.onboardingDocId },
                body: onboardingDocDetail?.body
            }
            const response = await OnboardingService.updateOnboardingDocDetailByOnboardingDocId(requestData)
            if (response.status === 200) {
                dispatch(setAddOnboardingDocDetail(response.data.data))
                const filteredDocumentList = onboardingDocList?.onboardingDocList?.filter((documents) => documents?.onboardingDocId !== onboardingDocDetail?.onboardingDocId)
                dispatch(setOnboardingDocList([...filteredDocumentList, response.data.data]))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddOnboardingDocDetailErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setAddOnboardingDocDetailLoading(false))
        }
    }
)

export const deleteOnboardingDocDetailByOnboardingDocId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingDocList?.requestName}`,
    async (onboardingDocId, { getState, dispatch }) => {
        dispatch(setAddOnboardingDocDetailLoading(true))

        try {
            const { onboardingDocList } = getState().onboarding
            const requestData = {
                params: { onboardingDocId: onboardingDocId }
            }
            const response = await OnboardingService.deleteOnboardingDocDetailByOnboardingDocId(requestData)
            if (response.status === 200) {
                const filteredList = onboardingDocList?.onboardingDocList?.filter((document) => document.onboardingDocId !== onboardingDocId)
                dispatch(setOnboardingDocList(filteredList))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddOnboardingDocDetailErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setAddOnboardingDocDetailLoading(false))
        }
    }
)

export const getOnboardingInterviewDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingInterviewDetail?.requestName}`,
    async (onboardingDetail, { dispatch }) => {
        dispatch(setOnboardingInterviewDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: onboardingDetail?.onboardingId }
            }
            const response = await OnboardingService.getOnboardingInterviewDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setOnboardingInterviewDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setOnboardingInterviewDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setOnboardingInterviewDetailLoading(false))
        }
    }
)

export const createOnboardingInterviewDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingInterviewDetail?.requestName}`,
    async (onboardingInterviewDetail, { dispatch }) => {
        dispatch(setAddOnboardingInterviewDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: onboardingInterviewDetail?.onboardingId },
                body: onboardingInterviewDetail?.body
            }
            const response = await OnboardingService.createOnboardingInterviewDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setAddOnboardingInterviewDetail(response.data.data))
                dispatch(setOnboardingInterviewDetail(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
            dispatch(setAddOnboardingInterviewDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setAddOnboardingInterviewDetailLoading(false))
        }
    }
)

export const updateOnboardingInterviewDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingInterviewDetail.requestName}`,
    async (interviewDetail, { dispatch }) => {
        dispatch(setAddOnboardingInterviewDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: interviewDetail?.onboardingId },
                body: interviewDetail?.body
            }
            const response = await OnboardingService.updateOnboardingInterviewDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setAddOnboardingInterviewDetail(response.data.data))
                dispatch(setOnboardingInterviewDetail(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
            dispatch(setAddOnboardingInterviewDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setAddOnboardingInterviewDetailLoading(false))
        }
    }
)

export const getOnboardingTutorPrefDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingTutorPrefDetail?.requestName}`,
    async (onboardingId, { dispatch }) => {
        dispatch(setOnboardingTutorPrefDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: onboardingId }
            }
            const response = await OnboardingService.getOnboardingTutorPrefDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setOnboardingTutorPrefDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setOnboardingTutorPrefDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setOnboardingTutorPrefDetailLoading(false))
        }
    }
)

export const updateOnboardingTutorPrefDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingTutorPrefDetail.requestName}`,
    async (tutorPrefDetail, { dispatch }) => {
        dispatch(setAddOnboardingTutorPrefDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: tutorPrefDetail?.onboardingId },
                body: tutorPrefDetail?.body
            }
            const response = await OnboardingService.updateOnboardingTutorPrefDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setOnboardingTutorPrefDetail(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
            dispatch(setAddOnboardingTutorPrefDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setAddOnboardingTutorPrefDetailLoading(false))
        }
    }
)

export const getOnboardingTutorTrialCourseDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingTutorTrialCourseDetail?.requestName}`,
    async (onboardingId, { dispatch }) => {
        dispatch(setOnboardingTutorTrialCourseDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: onboardingId }
            }
            const response = await OnboardingService.getOnboardingTutorTrialCourseDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setOnboardingTutorTrialCourseDetail(response.data.data))
                // return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setOnboardingTutorTrialCourseDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setOnboardingTutorTrialCourseDetailLoading(false))
        }
    }
)

export const updateOnboardingTutorTrialCourseDetailByOnboardingId = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingTutorTrialCourseDetail?.requestName}`,
    async (trialCourseDetail, { dispatch }) => {
        dispatch(setAddOnboardingTutorTrialCourseDetailLoading(true))

        try {
            const requestData = {
                params: { onboardingId: trialCourseDetail?.onboardingId },
                body: trialCourseDetail?.body
            }
            const response = await OnboardingService.updateOnboardingTutorTrialCourseDetailByOnboardingId(requestData)
            if (response.status === 200) {
                dispatch(setOnboardingTutorTrialCourseDetail(response.data.data))
                toast.success(response.data.message)
                // return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddOnboardingTutorTrialCourseDetailErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setAddOnboardingTutorTrialCourseDetailLoading(false))
        }
    }
)

// request for onboarding comment list
export const getOnboardingCommentList = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingCommentList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setOnboardingCommentListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await OnboardingService.getOnboardingCommentList(requestData)
            if (response.status === 200) {
                dispatch(setOnboardingCommentListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setOnboardingCommentListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setOnboardingCommentListLoading(false))
        }
    }
)

// request for add onboarding comment detail
export const createOnboardingCommentDetail = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.onboardingCommentDetail?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddOnboardingCommentDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await OnboardingService.createOnboardingCommentDetail(requestData)
            if (response.status === 200) {
                dispatch(getOnboardingCommentList({ onboardingId: body?.onboardingId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddOnboardingCommentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddOnboardingCommentDetailLoading(false))
        }
    }
)

export const getTutorOnboardingList = () => async (dispatch) => {
    dispatch(setTutorOnboardingListLoading(true))

    try {
        dispatch(setTutorOnboardingList(tutorOnboardingList))
    } catch (error) {
        console.error(error.message)
    } finally {
        dispatch(setTutorOnboardingListLoading(false))
    }
}

export const getTutorOnboardingDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorOnboardingDetailLoading(true))

    try {
        const response = tutorOnboardingList?.find((item) => item.userId === userId)
        dispatch(setTutorOnboardingDetail(response))
    } catch (error) {
        console.error(error.message)
    } finally {
        dispatch(setTutorOnboardingDetailLoading(false))
    }
}

export const geInterviewDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setInterviewDetailLoading(true))
    try {
        const response = videoInterviewList.find((interview) => interview?.userId === userId)
        dispatch(setInterviewDetail(response))
    } catch (error) {
        console.error(error.message)
        dispatch(setInterviewDetailErrorMsg(error.message))
    } finally {
        dispatch(setInterviewDetailLoading(false))
    }
}

export const getProfessionalReferencesByUserId = (userId) => async (dispatch) => {
    dispatch(setProfessionalReferencesLoading(true))

    try {
        const response = professionalReferencesList?.find((user) => user?.userId === userId)
        dispatch(setProfessionalReferences(response))
    } catch (error) {
        dispatch(setProfessionalReferencesErrorMsg(error.message))
    } finally {
        dispatch(setProfessionalReferencesLoading(false))
    }
}

export const geBackgroundDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setBackgroundDetailLoading(true))

    try {
        const response = backgroundDetailList?.find((user) => user?.userId === userId)
        dispatch(setBackgroundDetail(response))
    } catch (error) {
        dispatch(setBackgroundDetailErrorMsg(error.message))
    } finally {
        dispatch(setBackgroundDetailLoading(false))
    }
}

export const getCompanyOnboardingList = (activePage) => async (dispatch) => {
    dispatch(setCompanyOnboardingLoading(true))

    try {
        const response = companyOnboardingList.find((item) => item.currentPage === activePage)
        dispatch(setCompanyOnboarding(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCompanyOnboardingErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCompanyOnboardingLoading(false))
    }
}

export const getCompanyPublicProfileByCompanyId = (companyId) => async (dispatch, getState) => {
    dispatch(setCompanyPublicProfileLoading(true))

    try {
        const response = companyProfiles?.find((profile) => profile?.companyId === companyId)
        dispatch(setCompanyPublicProfile(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCompanyPublicProfileErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCompanyPublicProfileLoading(false))
    }
}

export const updateCompanyPublicProfileByCompanyId = (companyPublicProfile) => async (dispatch, getState) => {
    dispatch(setAddCompanyPublicProfileLoading(true))

    try {
        dispatch(setCompanyPublicProfile(companyPublicProfile))
        toast.success("profile updated successfully!")
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddCompanyPublicProfileErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddCompanyPublicProfileLoading(false))
    }
}

export const getCourseListByCompanyId = (companyId) => async (dispatch) => {
    dispatch(setCompanyCourseListLoading(true))

    try {
        const filteredCourseList = courseList.filter((course) => course?.companyId === companyId)
        dispatch(setCompanyCourseList(filteredCourseList))
        // const response = await courseService.getCourseList(requestData)
        // if (response.status === 200) {
        //     dispatch(setCourseList(response.data))
        // } else {
        //     dispatch(setCourseListErrorMsg(response.statusText))
        // }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCompanyCourseListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCompanyCourseListLoading(false))
    }
}

export const getEmployeeListByCompanyId = (query, companyId) => async (dispatch, getState) => {
    dispatch(setEmployeeListLoading(true))

    try {
        let response = {}

        let filteredEmployeeList = employeeData?.filter((employees) => employees?.companyId === companyId)

        const { activePage, itemsPerPage } = query
        const { employeeList } = getState().onboarding
        response["totalPages"] = Math.ceil(filteredEmployeeList?.length / itemsPerPage)
        response["activePage"] = activePage
        response["itemsPerPage"] = itemsPerPage
        response["list"] = filteredEmployeeList?.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
        const myData = {
            ...employeeList?.employeeList,
            totalPages: response.totalPages,
            activePage: response.activePage,
            itemsPerPage: response.itemsPerPage,
            list: employeeList?.employeeList
                ? [...employeeList?.employeeList?.list, ...response?.list]
                : response?.list,
        }
        dispatch(setEmployeeList(myData))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setEmployeeListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setEmployeeListLoading(false))
    }
}

export const createEmployeeByCompanyId = (body) => async (dispatch, getState) => {
    dispatch(setAddEmployeeLoading(true))

    try {
        const { employeeList } = getState().onboarding
        const response = {
            ...employeeList?.employeeList,
            list: [...employeeList?.employeeList?.list, body]
        }
        dispatch(setEmployeeList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddEmployeeErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddEmployeeLoading(false))
    }
}

export const updateEmployeeDetailByEmployeeId = (employee) => async (dispatch, getState) => {
    dispatch(setAddEmployeeLoading(true))

    try {
        const { employeeList } = getState().onboarding
        const filteredEmployeeList = employeeList?.employeeList?.list?.filter((people) => people?.employeeId !== employee?.employeeId)
        const response = {
            ...employeeList?.employeeList,
            list: [...filteredEmployeeList, employee]
        }
        dispatch(setEmployeeList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddEmployeeErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddEmployeeLoading(false))
    }
}

export const deleteEmployeeByEmployeeId = (employeeId) => async (dispatch, getState) => {
    dispatch(setAddEmployeeLoading(true))

    try {
        const { employeeList } = getState().onboarding
        const filteredEmployeeList = employeeList?.employeeList?.list?.filter((employee) => employee?.employeeId !== employeeId)
        const response = {
            ...employeeList?.employeeList,
            list: filteredEmployeeList
        }
        dispatch(setEmployeeList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddEmployeeErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddEmployeeLoading(false))
    }
}



export const getCompanyReviewList = () => async (dispatch) => {
    dispatch(setCompanyReviewListLoading(true))

    try {
        const response = reviewsList
        dispatch(setCompanyReviewList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCompanyReviewListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCompanyReviewListLoading(false))
    }
}

export const getImportantLinksByCompanyId = (companyId) => async (dispatch) => {
    dispatch(setImportantLinksListLoading(true))

    try {
        const filteredImportantLinksList = importantLinksList.filter((links) => links?.companyId === companyId)
        dispatch(setImportantLinksList(filteredImportantLinksList))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setImportantLinksListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setImportantLinksListLoading(false))
    }
}

export const createImportantLinks = (link) => async (dispatch, getState) => {
    dispatch(setAddImportantLinksLoading(true))

    try {
        const { importantLinksList } = getState().onboarding
        dispatch(setImportantLinksList([...importantLinksList?.importantLinksList, link]))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddImportantLinksErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddImportantLinksLoading(false))
    }
}

export const updateImportantLinksByLinkId = (link) => async (dispatch, getState) => {
    dispatch(setAddImportantLinksLoading(true))

    try {
        const { importantLinksList } = getState().onboarding
        const filteredImportantLinksList = importantLinksList?.importantLinksList?.filter((links) => links?.linkId !== link?.linkId)
        dispatch(setImportantLinksList([...filteredImportantLinksList, link]))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddImportantLinksErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddImportantLinksLoading(false))
    }
}

export const deleteImportantLinkByLinkId = (linkId) => async (dispatch, getState) => {
    dispatch(setAddImportantLinksLoading(true))

    try {
        const { importantLinksList } = getState().onboarding
        const filteredImportantLinksList = importantLinksList?.importantLinksList?.filter((links) => links?.linkId !== linkId)
        dispatch(setImportantLinksList(filteredImportantLinksList))

    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddImportantLinksErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddImportantLinksLoading(false))
    }
}

// request for get document list
export const getCompanyDocumentListByCompanyId = (companyId) => async (dispatch) => {
    dispatch(setCompanyDocumentListLoading())

    try {
        const filteredCompanyDocumentList = companyDocumentListData.find((document) => document?.companyId === companyId)
        const response = filteredCompanyDocumentList
        dispatch(setCompanyDocumentList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCompanyDocumentListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCompanyDocumentListLoading(false))
    }
}

export const createCompanyDocument = (companyDocument) => async (dispatch, getState) => {
    dispatch(setAddCompanyDocumentLoading(true))

    try {
        const { companyDocumentList } = getState().onboarding
        dispatch(setCompanyDocumentList({
            ...companyDocumentList?.companyDocumentList,
            documents: [...companyDocumentList?.companyDocumentList?.documents, companyDocument]
        }))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddCompanyDocumentErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddCompanyDocumentLoading(false))
    }
}

export const updateCompanyDocument = (companyDocument) => async (dispatch, getState) => {
    dispatch(setAddCompanyDocumentLoading(true))

    try {
        const { companyDocumentList } = getState().onboarding
        const filteredCompanyDocumentList = companyDocumentList?.companyDocumentList?.documents?.filter((document) => document?.documentId !== companyDocument?.documentId)
        const response = [...filteredCompanyDocumentList, companyDocument]
        dispatch(setCompanyDocumentList({ ...companyDocumentList?.companyDocumentList, documents: response }))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddCompanyDocumentErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddCompanyDocumentLoading(false))
    }
}

export const deleteCompanyDocumentByDocumentId = (documentId) => async (dispatch, getState) => {
    dispatch(setAddCompanyDocumentLoading(true))

    try {
        const { companyDocumentList } = getState().onboarding
        const filteredDocumentList = companyDocumentList?.companyDocumentList?.documents?.filter((document) => document?.documentId !== documentId)
        dispatch(setCompanyDocumentList({ ...companyDocumentList?.companyDocumentList, documents: filteredDocumentList }))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddCompanyDocumentErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddCompanyDocumentLoading(false))
    }
}

export const getSimilarCompanyList = () => async (dispatch) => {
    dispatch(setSimilarCompanyListLoading(true))

    try {
        const response = similarCompanyList
        dispatch(setSimilarCompanyList(response))
    } catch (error) {
        console.error(error?.response?.data.message || error?.response?.data?.error)
        dispatch(setSimilarCompanyListErrorMsg(error?.response?.data.message || error?.response?.data?.error))
    } finally {
        dispatch(setSimilarCompanyListLoading(false))
    }
}

// request for get faq list
export const getFAQListByCompanyId = (companyId) => async (dispatch, getState) => {
    dispatch(setFAQListLoading(true))

    try {
        const filteredFAQsList = dataFAQ.filter((faq) => faq?.companyId === companyId)
        dispatch(setFAQList(filteredFAQsList))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setFAQListErrorMsg(false))
    } finally {
        dispatch(setFAQListLoading(false))
    }
}

export const createFAQ = (body) => async (dispatch, getState) => {
    dispatch(setAddFAQLoading(true))

    try {
        const { FAQList } = getState().onboarding
        const response = [...FAQList?.FAQList, body]
        dispatch(setFAQList(response))
        toast.success("FAQ added successfully!")
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFAQErrorMsg(false))
    } finally {
        dispatch(setAddFAQLoading(false))
    }
}

export const updateFAQByFAQId = (selectedFAQ) => async (dispatch, getState) => {
    dispatch(setAddFAQLoading(true))

    try {
        const { FAQList } = getState().onboarding
        const filteredFAQList = FAQList?.FAQList?.filter((FAQ) => FAQ?.FAQId !== selectedFAQ?.FAQId)
        dispatch(setFAQList([...filteredFAQList, selectedFAQ]))
        toast.success("FAQ updated successfully!")
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFAQErrorMsg(false))
    } finally {
        dispatch(setAddFAQLoading(false))
    }
}

export const deleteFAQByFAQId = (selectedFAQ) => async (dispatch, getState) => {
    dispatch(setAddFAQLoading(true))

    try {
        const { FAQList } = getState().onboarding
        const filteredFAQList = FAQList?.FAQList?.filter((FAQ) => FAQ?.FAQId !== selectedFAQ?.FAQId)
        dispatch(setFAQList(filteredFAQList))
        toast.success("FAQ deleted successfully!")
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFAQErrorMsg(false))
    } finally {
        dispatch(setAddFAQLoading(false))
    }
}

export const getTutorOnboardingFilteredList = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.tutorOnboardingFilteredList?.requestName}`,
    async (filterProps, { dispatch, getState, rejectWithValue }) => {

        try {
            const { tutorOnboardingList } = getState().onboarding
            const filteredList = tutorOnboardingList?.tutorOnboardingList
                .filter((tutor) => (
                    (tutor?.userId === Number(filterProps?.userId))
                ))
                .filter((tutor) => (
                    (tutor.firstName.toLowerCase().includes(filterProps?.userName.toLowerCase())
                        || tutor.lastName.toLowerCase().includes(filterProps?.userName.toLowerCase()))
                ))
                .filter((tutor) => (
                    (tutor?.email?.toLowerCase().includes(filterProps?.email?.toLowerCase()))
                ))
                .filter((tutor) => (
                    (tutor?.mobileNo === Number(filterProps?.mobileNo))
                ))
                .filter((tutor) => (
                    (tutor?.status?.includes(filterProps?.status))
                ))
                .filter((tutor) => (
                    (dayjs(tutor?.createdAt?.format("DD/MM/YYYY"))?.includes(filterProps?.date)?.format("DD/MM/YYYY"))
                ))
            dispatch(setTutorOnboardingList(filteredList))
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddFAQErrorMsg(false))
        } finally {
            dispatch(setAddFAQLoading(false))
        }
    }
)

export const getCompanyOnboardingFilteredList = createAsyncThunk(
    `${onboardingRedux?.sliceName}/${onboardingRedux?.companyOnboardingFilteredList?.requestName}`,
    async (filterProps, { dispatch, getState, rejectWithValue }) => {
        try {
            const { companyOnboardingList } = getState().onboarding
            const filteredList = companyOnboardingList?.companyOnboardingList
                .filter((company) => (
                    (company?.companyId === Number(filterProps?.companyId))
                ))
                .filter((company) => (
                    (company.firstName.toLowerCase().includes(filterProps?.userName.toLowerCase())
                        || company.lastName.toLowerCase().includes(filterProps?.userName.toLowerCase()))
                ))
                .filter((company) => (
                    (company?.email?.toLowerCase().includes(filterProps?.email?.toLowerCase()))
                ))
                .filter((company) => (
                    (company?.status?.includes(filterProps?.status))
                ))
                .filter((company) => (
                    (dayjs(company?.createdAt?.format("DD/MM/YYYY"))?.includes(filterProps?.date)?.format("DD/MM/YYYY"))
                ))
            dispatch(setTutorOnboardingList(filteredList))
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)