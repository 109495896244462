import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { NavLink as RouterLink } from 'react-router-dom';

import { School } from '@mui/icons-material';
import { WifiProtectedSetupOutlined } from '@mui/icons-material';
import { FaChalkboardTeacher } from 'react-icons/fa';

import { sideTopBarPagesName } from 'utils/pagesInfo';

export const Container = motion(styled.div`
    ${tw`
        hidden
    `}
    ${({ pageName }) => sideTopBarPagesName.includes(pageName) && tw`
        lg:flex sticky top-0 z-40 h-screen w-full bg-primary-dark overflow-hidden overflow-y-auto
        scrollbar scrollbar-thin scrollbar-track-primary-dark scrollbar-thumb-rounded-full
        hover:scrollbar-thumb-divider-medium
    `}
    ::-webkit-scrollbar {
        ${tw`w-2.5`}
    }
`);

export const Content = tw.div``;

export const ListItems = styled.div`
    ${tw`
        flex flex-col justify-center text-text-50 gap-2 py-2
    `}
    .active {
        ${tw`
            bg-white text-primary-dark shadow-xl
        `}
    }
`;

export const ListItem = tw.div`
    w-full flex flex-row items-center h-8 cursor-pointer select-none rounded-r-full pr-8
    hover:(bg-primary-light !text-primary-dark scale-125)
`;

export const ItemLink = tw(RouterLink)`
    w-full flex flex-row items-center h-8 cursor-pointer select-none rounded-r-full pr-5
    hover:(bg-primary-light !text-primary-dark scale-125) 
`;

export const ItemIcon = tw.span`
    text-2xl px-5 flex items-center h-full
`;

export const ItemName = motion(tw.span`
    inline-block overflow-hidden flex flex-nowrap items-center h-full
    font-bodyPri font-bold text-base whitespace-nowrap
`);

export const RoleSwitchIcon = motion(tw(WifiProtectedSetupOutlined)``);

export const AdminIcon = motion(tw(School)``);

export const SuperAdminIcon = motion(tw(FaChalkboardTeacher)`
    text-2xl
`);

// sub menu styles
export const SubMenuItemLink = tw(RouterLink)`
    w-full flex flex-row items-center h-8 cursor-pointer select-none rounded-r-full pl-16
    hover:(bg-primary-light !text-primary-dark scale-125)
`;

export const SubMenuItemName = motion(tw.span`
    inline-block overflow-hidden flex flex-nowrap items-center h-full
    font-bodyPri font-normal text-base whitespace-nowrap
`);