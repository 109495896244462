import { combineReducers } from 'redux';

import lmsCourseSlice from './lmsCourse/lmsCourse.slice';
import lmsQuizSlice from './lmsQuiz/lmsQuiz.slice';
import lmsQuestionSlice from './lmsQuestion/lmsQuestion.slice';
import lmsArticleSlice from './lmsArticle/lmsArticle.slice';
import lmsLectureSlice from './lmsLecture/lmsLecture.slice';
import lmsResourceSlice from './lmsResource/lmsResource.slice';

const lmsSlice = combineReducers({
    lmsCourse: lmsCourseSlice,
    lmsQuiz: lmsQuizSlice,
    lmsQuestion: lmsQuestionSlice,
    lmsArticle: lmsArticleSlice,
    lmsLecture: lmsLectureSlice,
    lmsResource: lmsResourceSlice,
})

export default lmsSlice;