import { createSlice } from "@reduxjs/toolkit";

import { LMS_QUIZ_INITIAL_STATE } from "./lmsQuiz.initialState";

const lmsQuiz = createSlice({
    name: 'Lms Quiz',
    initialState: LMS_QUIZ_INITIAL_STATE,
    reducers: {
        // lms quiz list
        setLmsQuizListLoading: (state, { payload }) => {
            state.lmsQuizList.isLoading = payload
        },
        setLmsQuizListData: (state, { payload }) => {
            state.lmsQuizList.data = payload
            state.lmsQuizList.message = LMS_QUIZ_INITIAL_STATE.lmsQuizList.message
        },
        setLmsQuizListMessage: (state, { payload }) => {
            state.lmsQuizList.message = payload
            state.lmsQuizList.data = LMS_QUIZ_INITIAL_STATE.lmsQuizList.data
        },
        resetLmsQuizList: (state) => {
            state.lmsQuizList = LMS_QUIZ_INITIAL_STATE.lmsQuizList
        },

        // delete lms quiz detail
        setDestroyLmsQuizDetailLoading: (state, {payload}) => {
            state.destroyLmsQuizDetail.isLoading = payload
        },
        setDestroyLmsQuizDetailMessage: (state, {payload}) => {
            state.destroyLmsQuizDetail.message = payload
        },
        resetDestroyLmsQuizDetail: (state) => {
            state.destroyLmsQuizDetail = LMS_QUIZ_INITIAL_STATE.destroyLmsQuizDetail
        },

        // reducers for filter quiz list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = LMS_QUIZ_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setLmsQuizListData,
    setLmsQuizListLoading,
    setLmsQuizListMessage,
    resetLmsQuizList,

    setDestroyLmsQuizDetailLoading,
    setDestroyLmsQuizDetailMessage,
    resetDestroyLmsQuizDetail,

    setFilterProps,
    resetFilterProps
} = lmsQuiz.actions

export default lmsQuiz.reducer;