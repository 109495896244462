import format from 'string-format';

import { baseApiInstance, baseCredApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/auth/auth.const";


class AuthService {
    login = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.LOGIN,
            body
        )

        return response;
    }

    getToken = async () => {
        const response = await baseCredApiInstance().get(
            url.GET_TOKEN
        )

        return response;
    }

    createSignUp = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.SIGN_UP,
            body
        )

        return response;
    }

    logout = async () => {
        const response = await baseAuthApiInstance().post(
            url.LOGOUT
        )

        return response;
    }

    createVerifyReferral = async ({ params }) => {
        const response = await baseApiInstance().get(
            format(url.CREATE_VERIFY_REFERRAL, params)
        )
        return response;
    }

    sendForgetPasswordLink = async ({ body }) => {
        const response = await baseApiInstance().post(
            format(url.FORGET_PASSWORD),
            body
        )

        return response;
    }

    createVerifyUser = async ({ body }) => {
        const response = await baseApiInstance().post(
            format(url.ADD_VERIFY_USER),
            body
        )

        return response;
    }

    createAdminLoginAsDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADMIN_LOGIN_AS),
            body
        );

        return response;
    }
}

export default new AuthService()