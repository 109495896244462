import { cn } from "utils/cn.utils";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';

import { currencyRateConversion } from 'utils/generators.utils';

const SessionCount = ({ count }) => {
    const { locals } = useAppState((s) => s.local)
    const { addBookingDetail } = useAppState((s) => s.booking)

    const dispatcher = useAppDispatcher()

    const getPrice = () => {
        let sellingPrice = 0

        let price = addBookingDetail?.payload?.offering?.coursePrice?.price
        let discountPct = addBookingDetail?.payload?.offering?.coursePrice?.discountPct
        let duration = addBookingDetail?.payload?.duration
        let volumeDiscountPct = addBookingDetail?.payload?.offering?.volumeDiscountPct

        sellingPrice = parseFloat(price / 4.0) * (duration / 15)
        sellingPrice = parseFloat(sellingPrice * ((100 - discountPct) / 100))

        if (count === 5) {
            sellingPrice = parseFloat(sellingPrice * ((100 - volumeDiscountPct) / 100))
        }

        return sellingPrice * count
    }

    return (
        <div className={"flex flex-col items-center gap-2"}>
            <div onClick={() => dispatcher(setAddBookingDetailPayload({ ...addBookingDetail?.payload, sessionCount: count }))}
                className={cn(
                    "flex items-center justify-center gap-1 px-5 py-1 w-fit rounded-lg cursor-pointer",
                    "font-bodyPri font-normal text-sm",
                    addBookingDetail?.payload?.sessionCount === count
                        ? "bg-primary-dark text-text-50"
                        : "bg-primary-light text-primary-dark"
                )}
            >
                {count + (count > 1 ? " sessions" : "session")}
            </div>
            <div className={cn(
                "font-bodyPri font-normal text-sm text-center",
                addBookingDetail?.payload?.sessionCount === count
                    ? "text-primary-dark"
                    : "text-text-800"
            )}>{currencyRateConversion(locals?.currencyCode, getPrice())}
            </div>
            {(count === 5 && addBookingDetail?.payload?.offering?.volumeDiscountPct > 0) &&
                <div className={cn(
                    "text-xs text-text-800 text-center"
                )}>
                    {parseFloat(addBookingDetail?.payload?.offering?.volumeDiscountPct).toFixed(2) + "% OFF"}
                </div>
            }
        </div>
    )
}

export default SessionCount;