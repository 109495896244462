import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";
import sessionService from "redux/session/session.service";

import {
    setSessionListLoading,
    setSessionListData,
    setSessionListMessage,

    setSessionDetailLoading,
    setSessionDetailData,
    setSessionDetailMessage,

    setModifyStartSessionDetailLoading,
    setModifyStartSessionDetailData,
    setModifyStartSessionDetailMessage,

    setModifyScheduleSessionDetailLoading,
    setModifyScheduleSessionDetailData,
    setModifyScheduleSessionDetailMessage,

    setModifyDeclineSessionDetailLoading,
    setModifyDeclineSessionDetailData,
    setModifyDeclineSessionDetailMessage,

    setModifyRescheduleSessionDetailLoading,
    setModifyRescheduleSessionDetailData,
    setModifyRescheduleSessionDetailMessage,

    setModifyCompleteSessionDetailLoading,
    setModifyCompleteSessionDetailData,
    setModifyCompleteSessionDetailMessage,

    setAddSessionUserDetailLoading,
    setAddSessionUserDetailData,
    setAddSessionUserDetailMessage,

    setModifySessionUserDetailLoading,
    setModifySessionUserDetailData,
    setModifySessionUserDetailMessage,

    setSessionCourseListLoading,
    setSessionCourseListData,
    setSessionCourseListMessage,

    setSessionAppointmentListLoading,
    setSessionAppointmentListData,
    setSessionAppointmentListMessage,
} from "redux/session/session.slice";
import { sessionRedux } from "redux/session/session.const";

// get session list
export const getSessionList = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.sessionList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setSessionListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await sessionService.getSessionList(requestData)
            if (response.status === 200) {
                dispatch(setSessionListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
            dispatch(setSessionListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        } finally {
            dispatch(setSessionListLoading(false))
        }
    }
)

// get session detail
export const getSessionDetail = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.sessionDetail?.requestName}`,
    async (sessionDetailPayload, { dispatch }) => {
        dispatch(setSessionDetailLoading(true))

        try {
            const requestData = {
                params: { sessionId: sessionDetailPayload?.sessionId }
            }
            const response = await sessionService.getSessionDetail(requestData)
            if (response.status === 200) {
                dispatch(setSessionDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
            dispatch(setSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        } finally {
            dispatch(setSessionDetailLoading(false))
        }
    }
)

// start session user detail
export const updateStartSessionDetail = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.startSessionDetail?.requestName}`,
    async (sessionUserDetailPayload, { dispatch }) => {
        dispatch(setModifyStartSessionDetailLoading(true))

        try {
            const requestData = {
                params: { sessionId: sessionUserDetailPayload?.sessionId },
                body: sessionUserDetailPayload?.body
            }
            const response = await sessionService.updateStartSessionDetail(requestData)
            if (response.status === 200) {
                dispatch(setModifyStartSessionDetailData(response.data.data))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
            dispatch(setModifyStartSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        } finally {
            dispatch(setModifyStartSessionDetailLoading(false))
        }
    }
)

// schedule session user detail
export const updateScheduleSessionDetail = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.scheduleSessionDetail?.requestName}`,
    async (sessionUserDetailPayload, { dispatch }) => {
        dispatch(setModifyScheduleSessionDetailLoading(true))

        try {
            const requestData = {
                params: { sessionId: sessionUserDetailPayload?.sessionId },
                body: sessionUserDetailPayload?.body
            }
            const response = await sessionService.updateScheduleSessionDetail(requestData)
            if (response.status === 200) {
                dispatch(setModifyScheduleSessionDetailData(response.data.data))
                toast.success(response.data.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
            dispatch(setModifyScheduleSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        } finally {
            dispatch(setModifyScheduleSessionDetailLoading(false))
        }
    }
)

// decline session user detail
export const updateDeclineSessionDetail = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.declineSessionDetail?.requestName}`,
    async (sessionUserDetailPayload, { dispatch }) => {
        dispatch(setModifyDeclineSessionDetailLoading(true))

        try {
            const requestData = {
                params: { sessionId: sessionUserDetailPayload?.sessionId },
                body: sessionUserDetailPayload?.body
            }
            const response = await sessionService.updateDeclineSessionDetail(requestData)
            if (response.status === 200) {
                dispatch(setModifyDeclineSessionDetailData(response.data.data))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
            dispatch(setModifyDeclineSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
            toast.error(error.response.message)
        } finally {
            dispatch(setModifyDeclineSessionDetailLoading(false))
        }
    }
)

// reschedule session user detail
export const updateRescheduleSessionDetail = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.rescheduleSessionDetail?.requestName}`,
    async (sessionUserDetailPayload, { dispatch }) => {
        dispatch(setModifyRescheduleSessionDetailLoading(true))

        try {
            const requestData = {
                params: { sessionId: sessionUserDetailPayload?.sessionId },
                body: sessionUserDetailPayload?.body
            }
            const response = await sessionService.updateRescheduleSessionDetail(requestData)
            if (response.status === 200) {
                dispatch(setModifyRescheduleSessionDetailData(response.data.data))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
            dispatch(setModifyRescheduleSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        } finally {
            dispatch(setModifyRescheduleSessionDetailLoading(false))
        }
    }
)

// complete session user detail
export const updateCompleteSessionDetail = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.completeSessionDetail?.requestName}`,
    async (sessionUserDetailPayload, { dispatch }) => {
        dispatch(setModifyCompleteSessionDetailLoading(true))

        try {
            const requestData = {
                params: { sessionId: sessionUserDetailPayload?.sessionId },
                body: sessionUserDetailPayload?.body
            }
            const response = await sessionService.updateCompleteSessionDetail(requestData)
            if (response.status === 200) {
                dispatch(setModifyCompleteSessionDetailData(response.data.data))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
            dispatch(setModifyCompleteSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong")
        } finally {
            dispatch(setModifyCompleteSessionDetailLoading(false))
        }
    }
)

// session course list
export const getSessionCourseList = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.sessionCourseList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setSessionCourseListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await sessionService.getSessionCourseList(requestData)
            if (response.status === 200) {
                dispatch(setSessionCourseListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
            dispatch(setSessionCourseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        } finally {
            dispatch(setSessionCourseListLoading(false))
        }
    }
)

// get session appointment list
export const getSessionAppointmentList = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.sessionAppointmentList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setSessionAppointmentListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await sessionService.getSessionAppointmentList(requestData)
            if (response.status === 200) {
                dispatch(setSessionAppointmentListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
            dispatch(setSessionAppointmentListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        } finally {
            dispatch(setSessionAppointmentListLoading(false))
        }
    }
)

// create session user detail
export const createSessionUserDetail = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.addSessionUserDetail?.requestName}`,
    async (payload, { dispatch }) => {
        dispatch(setAddSessionUserDetailLoading(true))

        try {
            const requestData = {
                params: { sessionId: payload?.sessionId },
                body: payload?.body
            }
            const response = await sessionService.createSessionUserDetail(requestData)
            if (response.status === 201) {
                dispatch(setAddSessionUserDetailData(response.data.data))
                toast.success(response.data.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            dispatch(setAddSessionUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            dispatch(setAddSessionUserDetailLoading(false))
        }
    }
)

// update session user detail
export const updateSessionUserDetail = createAsyncThunk(
    `${sessionRedux?.sliceName}/${sessionRedux?.updateSessionUserDetail.requestName}`,
    async (payload, { dispatch }) => {
        dispatch(setModifySessionUserDetailLoading(true))

        try {
            const requestData = {
                params: { sessionId: payload?.sessionId, sessionUserId: payload?.sessionUserId },
                body: payload?.body
            }
            const response = await sessionService.updateSessionUserDetail(requestData)
            if (response.status === 200) {
                dispatch(setModifySessionUserDetailData(response.data.data))
                toast.success(response.data.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            dispatch(setModifySessionUserDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            dispatch(setModifySessionUserDetailLoading(false))
        }
    }
)