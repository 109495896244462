import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { MdEdit } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";

import ButtonLoader from "components/loader/ButtonLoader";
import InputOtp from "components/inputOtp/InputOtp";

import { createSendOtp, createVerifyOtp } from 'redux/notification/notification.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearVerifyOtp, setClearSendOtp } from 'redux/notification/notification.slice';
import { modalConst } from "redux/local/local.const";
import { otpStatus } from "redux/notification/notification.const";

import { validateEmail } from 'utils/validation-functions';

const ValidationBarEmailVerifyModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [isOpenEmailOtpContainer, setIsOpenEmailOtpContainer] = useState(false)
    const [emailAddress, setEmailAddress] = useState(user?.user?.email)
    const [isEmailEditMode, setIsEmailEditMode] = useState(false)

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_EMAIL_VERIFY_MODAL.stateKey]: false
            }))
            toast.success("Email verified successfully!")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            toast.warning("Something went wrong")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            setIsOtpError(true)
            toast.error("Incorrect code!")
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    const handleEditEmail = () => {
        if (isEmailEditMode) {
            if (validateEmail(emailAddress)) {
                setEmailAddress(emailAddress?.toLowerCase())
                setIsEmailEditMode(!isEmailEditMode)
            }
            else {
                toast.warning("Enter Valid Email!")
            }
        }
        else {
            setIsEmailEditMode(!isEmailEditMode)
        }
    }

    const handleEmailVerification = async () => {
        if (isOpenEmailOtpContainer) {
            if (myOtp?.length < 4) return;
            dispatcher(setClearVerifyOtp())
            if (sendOtp?.sendOtp?.otpNotificationId) {
                dispatcher(createVerifyOtp({
                    otpNotificationId: sendOtp?.sendOtp?.otpNotificationId,
                    otp: myOtp
                }))
            }
            else {
                toast.error("Incorrect Code")
                setIsOtpError(true)
            }
        }
        if (!isOpenEmailOtpContainer) {
            if (isEmailEditMode) return;
            if (validateEmail(emailAddress) && !isEmailEditMode) {
                dispatcher(createSendOtp({
                    otpDeliveryMethod: "email",
                    email: emailAddress
                }))
                toast.success("Code Sent Successfully")
                setIsOpenEmailOtpContainer(true)
                return;
            }
            if (!emailAddress) {
                toast.warning("Please Enter Email for Verification")
                setIsEmailEditMode(true)
            }
        }
    }

    return (
        <>
            <div className='space-y-5'>
                <div className='flex items-center justify-center gap-3'>
                    <span className='font-bodyPri font-normal text-text-700 text-base whitespace-nowrap'>
                        {"Email Address:"}
                        <span className='text-red-500'>{" * "}</span>
                    </span>
                    {!isEmailEditMode &&
                        <span className='font-bodySec font-normal text-text-900 text-base'>
                            {emailAddress}
                        </span>
                    }
                    {isEmailEditMode &&
                        <input
                            type={"text"}
                            className={cn("w-full px-3 py-1 border-2 border-secondary-main border-dashed focus:outline-none",
                                "font-bodySec font-normal text-text-900 text-base"
                            )}
                            value={emailAddress}
                            autoFocus={true}
                            onChange={(e) => setEmailAddress(e.target.value)}
                        />
                    }
                    <div className='cursor-pointer' onClick={handleEditEmail}>
                        {!isEmailEditMode && <MdEdit className='text-2xl text-text-500' />}
                        {isEmailEditMode && <AiFillCheckCircle className='text-2xl text-secondary-main' />}
                    </div>
                </div>
                {isOpenEmailOtpContainer &&
                    <InputOtp
                        email={emailAddress}
                        setMyOtp={setMyOtp}
                        isOtpError={isOtpError}
                        setIsOtpError={setIsOtpError}
                    />
                }
                <div className={"flex items-center justify-center"}>
                    <div className={cn(
                        "py-1.5 rounded-lg",
                        "text-text-50 font-buttons font-medium text-base w-full text-center cursor-pointer",
                        (!isEmailEditMode && !isOpenEmailOtpContainer) && "bg-primary-main hover:bg-secondary-dark",
                        (isEmailEditMode || (isOpenEmailOtpContainer && myOtp?.length < 4)) && "bg-divider-medium",
                        isOpenEmailOtpContainer && myOtp?.length === 4 && "bg-primary-main hover:bg-secondary-dark"
                    )}
                        onClick={handleEmailVerification}
                    >
                        {verifyOtp?.isLoading && <ButtonLoader isLoading={verifyOtp?.isLoading} />}
                        {!verifyOtp?.isLoading && isOpenEmailOtpContainer && "Verify Code"}
                        {!verifyOtp?.isLoading && !isOpenEmailOtpContainer && "Send Code"}
                    </div>
                </div>
            </div>
            {user?.user?.isEmailVerified &&
                <div className='flex flex-col justify-center items-center space-y-2'>
                    <BsCheckLg className='text-4xl text-green-500' />
                    <span className='font-bodyPri font-semibold text-lg text-text-900'>
                        {"Email Verified Successfully!"}
                    </span>
                </div>
            }
        </>
    )
}

export default ValidationBarEmailVerifyModal