import { OptionSelector } from "components/common-components/Select";

import { getAppointmentList } from "redux/appointment/appointment.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {  setFilterProps } from 'redux/appointment/appointment.slice';
import { dataEntriesConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";

const AppointmentsSearch = () => {
    const { appointmentList, filterProps } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value }
        }))
    }

    const onHandleSearch = () => {
        if (!filterProps?.searchText || appointmentList?.isLoading) return;

        dispatcher(getAppointmentList({ search: filterProps?.searchText, page: 1, records: dataEntriesConst.TWENTY_FIVE.value }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>

            <div className={""}>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here..."}
                        value={filterProps.searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={onHandleSearch}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AppointmentsSearch;