import { memo, useCallback, useEffect, useState } from 'react';

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';

import FilterBox from 'components/pageFilter/FilterBox';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setFilterProps } from 'redux/availability/availability.slice';
import { DEFAULT_AVAILABILITY_LIST_PAGE } from 'redux/availability/availability.const';

import { cn } from 'utils/cn.utils';

const AvailabilityIdFilter = memo(({ filterItem, activeFilter, setActiveFilter }) => {
    const { filterProps } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const [availabilityIdPayload, setAvailabilityIdPayload] = useState({
        id: "",
        message: ""
    })

    useEffect(() => {
        if (!!filterProps?.id) {
            setAvailabilityIdPayload({
                ...availabilityIdPayload,
                id: filterProps?.id,
                message: ""
            })
        } else {
            setAvailabilityIdPayload({
                message: "",
                id: ""
            })
        }
    }, [filterProps?.id])

    const onHandleMouseLeave = () => {
        if (!filterProps?.id) {
            setAvailabilityIdPayload({
                message: "",
                id: ""
            })
        }
        setActiveFilter(null)
    }

    const onHandleKeyDown = (event) => {
        if ((event.keyCode === 13) && availabilityIdPayload?.id) {
            onHandleApplyFilter()
        }
    }

    const onHandleApplyFilter = useCallback(() => {
        if (!availabilityIdPayload?.id) {
            setAvailabilityIdPayload({
                ...availabilityIdPayload,
                message: "Please enter availability id!"
            })
            return;
        }
        setAvailabilityIdPayload({
            ...availabilityIdPayload,
            message: ""
        })
        dispatcher(setFilterProps({ filterProps: { ...filterProps, page: DEFAULT_AVAILABILITY_LIST_PAGE, id: availabilityIdPayload?.id } }))
        setActiveFilter(null)
    }, [availabilityIdPayload, filterProps])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setAvailabilityIdPayload({
            ...availabilityIdPayload,
            id: "",
            message: ""
        })

        dispatcher(setFilterProps({ filterProps: { ...filterProps, id: null } }))
        setActiveFilter(null)
    }, [filterProps])

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={!!filterProps?.id
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            boxItems={!!filterProps?.id && [filterProps?.id]}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By ID"}
                </span>
                <input
                    type={'number'}
                    placeholder={"Search"}
                    className={cn(
                        "w-full flex-1 px-2 py-1 input-number-spin-none border border-text-500 rounded-md focus:outline-none focus:border focus:border-primary-dark bg-transparent",
                        "font-bodyPri font-normal text-text-800 text-sm"
                    )}
                    value={availabilityIdPayload?.id}
                    onChange={(e) => setAvailabilityIdPayload({
                        ...availabilityIdPayload,
                        message: "",
                        id: e.target.value
                    })}
                    autoFocus={true}
                    onKeyDown={onHandleKeyDown}
                />
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!availabilityIdPayload?.id) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
});

export default AvailabilityIdFilter;