import { useEffect } from "react";
import { Outlet, useParams, useLocation, Link, Navigate } from "react-router-dom";

import { FaEye } from 'react-icons/fa';
import { BsCheck2All } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

import FullPageLoader from 'components/loader/FullPageLoader';
import DetailedView from 'components/detailedView/DetailedView';
import PageNavigationOptions from "components/navigationOptions/PageNavigationOptions";

import { PageContentStyle } from "pages/auth/editTutor/EditTutor.style";
import { editTutorNavigationOptions, editTutorHeaderConst } from "pages/auth/editTutor/data";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorDetail } from "redux/tutor/tutor.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearUserDetail } from "redux/user/user.slice";
import { tutorStatus } from "redux/tutor/tutor.const";

import { pagesInfo } from "utils/pagesInfo";
import { dayjs, timeZone } from "utils/dateTime.utils";

const TutorImageContainer = ({ profilePicUrl }) => {
  return (
    <div className={"w-14 h-14 overflow-hidden rounded-full"}>
      <img
        src={profilePicUrl}
        className={"w-full h-full object-cover"}
        alt={"Profile_Picture"}
      />
    </div>
  )
}

const TutorEmailContainer = ({ email, isEmailVerified }) => {
  return email ? (
    <div className={"flex flex-col items-start justify-start sm:flex-row sm:justify-center sm:items-center gap-1"}>
      <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
        {email}
      </span>
      {isEmailVerified &&
        <BsCheck2All className={"text-green-500 text-base"} />
      }
      {!isEmailVerified &&
        <TiDeleteOutline className={"text-red-500 text-base"} />
      }
    </div>
  ) : "-"
}

const TutorMobileNoContainer = ({ mobileNo, isdCode, isMobileNoVerified }) => {
  return (isdCode && mobileNo) ? (
    <div className={"flex flex-col items-start justify-start sm:flex-row sm:justify-center sm:items-center gap-1"}>
      <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
        {"+" + isdCode + " - " + mobileNo}
      </span>
      {isMobileNoVerified &&
        <BsCheck2All className={"text-green-500 text-sm"} />
      }
      {!isMobileNoVerified &&
        <TiDeleteOutline className={"text-red-500 text-sm"} />
      }
    </div>
  ) : "-"
}

const EditTutorPage = ({ allowedRoles }) => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((state) => state.local)
  const { session } = useAppState((state) => state.auth)
  const { user } = useAppState((state) => state.user)
  const { tutorDetail } = useAppState((state) => state.tutor)

  const dispatcher = useAppDispatcher()
  const { tutorId } = useParams()
  const location = useLocation()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_TUTOR))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    if (tutorId) {
      dispatcher(getTutorDetail(Number(tutorId)))
    }

    return () => {
      dispatcher(setClearUserDetail())
    }
  }, [tutorId])

  const rows = [
    <TutorImageContainer profilePicUrl={tutorDetail?.data?.user?.profilePicUrl} />,
    tutorDetail?.data?.id || "-",
    <Link
      to={`${pagesInfo?.VIEW_USER?.pagePath}/${tutorDetail?.data?.user?.id}`}
      className={"hover:text-primary-main hover:underline cursor-pointer"}
    >
      {tutorDetail?.data?.user?.id}
    </Link>,
    tutorDetail?.data?.user?.firstName + " " + tutorDetail?.data?.user?.lastName?.charAt(0),
    <TutorEmailContainer
      email={tutorDetail?.data?.user?.email}
      isEmailVerified={tutorDetail?.data?.user?.isEmailVerified}
    />,
    <TutorMobileNoContainer
      mobileNo={tutorDetail?.data?.user?.mobileNo}
      isdCode={tutorDetail?.data?.user?.isdCode}
      isMobileNoVerified={tutorDetail?.data?.user?.isMobileNoVerified}
    />,
    tutorStatus[tutorDetail?.data?.status?.toUpperCase()]?.label || "-",
    dayjs(tutorDetail?.data?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
    <Link
      to={`${pagesInfo.VIEW_TUTOR.pagePath}/${tutorDetail?.data?.id}`}
      className={"px-2 py-1 text-text-700"}
    >
      <FaEye className={"text-xl hover:scale-110"} />
    </Link>
  ]

  if (tutorDetail?.isLoading) {
    return (
      <FullPageLoader isLoading={tutorDetail?.isLoading} />
    )
  }

  if (tutorDetail?.message) {
    return (
      <div className={"w-screen h-screen flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
        {tutorDetail?.message}
      </div>
    )
  }

  return (
    <PageContentStyle>
      <div className={"w-full h-full min-h-screen mx-auto p-5 space-y-3"}>
        <DetailedView
          headers={Object.values(editTutorHeaderConst)}
          rows={rows}
          rowHeaderColor={"bg-background-medium"}
          rowColor={`bg-${tutorStatus[tutorDetail?.data?.status?.toUpperCase()]?.lightColor}`}
        />
        <div className={"w-full flex flex-col items-start justify-start space-y-3 md:space-y-0 md:space-x-3 md:flex-row"}>
          <div className={"sticky top-[5rem] z-30 w-full md:w-fit"}>
            <PageNavigationOptions options={Object.values(editTutorNavigationOptions)} />
          </div>
          <div className={"w-full rounded-lg p-5 bg-white"}>
            {session?.isLoggedIn && user?.user?.roles?.find((role) => allowedRoles?.includes(role?.role) && role.role === locals.userRole)
              ? <Outlet />
              : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: location }} replace />
            }
          </div>
        </div>
      </div>
    </PageContentStyle>
  )
}

export default EditTutorPage