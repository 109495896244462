import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from 'pages/auth/edulyteLms/lmsVideoLecture/lmsVideoLecture.data';
import SearchLmsVideoLecture from 'pages/auth/edulyteLms/lmsVideoLecture/components/SearchLmsVideoLecture';
import DisplayLmsVideoLecture from "pages/auth/edulyteLms/lmsVideoLecture/components/DisplayLmsVideoLecture";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const LMSVideoLecturePage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.LMS_VIDEO_LECTURE))
    }, [dispatcher, currentPageInfo])

    return (
        <div className={'h-full w-full p-3 mx-auto min-h-screen space-y-3'}>
            <PageHeader pageHeading={pageHeading} />
            <SearchLmsVideoLecture />
            <DisplayLmsVideoLecture />
        </div>
    )
}

export default LMSVideoLecturePage;