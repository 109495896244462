import { Link, useNavigate, useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";
import { OptionSelector } from "components/common-components/Select";


export default function NavigationOptions({
    options,
    excludedNavOptions = [],
    onHandleButtonClick,
}) {
    const params = useParams();
    const pathParam = params["*"];
    const selectOptions = options?.map((option) => ({
        label: option.label,
        value: option.to,
        roles: option.roles
    }));
    const navigate = useNavigate();

    const onHandleNavigation = (event, option) => {
        event.preventDefault()
        if (!excludedNavOptions?.includes(option?.label)) {
            navigate(`.${option.to}`)
        } else {
            onHandleButtonClick(event)
        }
    }

    return (
        <div className="w-full h-fit p-5 bg-white rounded-lg shadow-sm md:w-60">
            <div className="md:hidden">
                <OptionSelector
                    options={selectOptions}
                    onChange={(op) => {
                        navigate(`.${op.value}`);
                    }}
                    value={`/${pathParam}`}
                />
            </div>

            <div className="flex-col hidden gap-3 md:flex">
                {options.map((option, index) => (
                    <Link
                        key={index}
                        to={`.${option.to}`}
                        onClick={(event) => onHandleNavigation(event, option)}
                        className={cn(
                            "px-3 py-2 font-bodyPri font-normal text-base text-center rounded-md",
                            pathParam === option.to.slice(1)
                                ? "bg-primary-dark text-text-50 hover:bg-prima ry-dark hover:text-text-50"
                                : "text-text-900 hover:bg-primary-light hover:text-primary-dark"
                        )}
                    >
                        {option.label}
                    </Link>
                ))}
            </div>
        </div>
    );
}
