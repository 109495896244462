import { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from "components/common-components/Select";
import SaveAndCancelBtn from 'components/buttons/SaveAndCancelBtn';

import { tutorConfigActionType, tutorConfigReducer } from "pages/auth/editTutor/data";
import EditTutorHeader from "pages/auth/editTutor/commonComponents/EditTutorHeader";

import { getTutorConfigDetail, updateTutorConfigDetail } from "redux/tutor/tutor.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetTutorConfigDetail, resetUpdateTutorConfigDetail } from "redux/tutor/tutor.slice";
import { masterCurrencyCodeEnum } from "redux/master/master.const";

const EditTutorConfigPage = () => {
    const { tutorConfigDetail, updateTutorConfig } = useAppState((state) => state.tutor)


    const dispatcher = useAppDispatcher()
    const { tutorId } = useParams()

    const [state, dispatch] = useReducer(tutorConfigReducer, {
        adminFixedPctCommission: tutorConfigDetail?.data?.adminFixedPctCommission || 0,
        adminVariablePctCommission: tutorConfigDetail?.data?.adminVariablePctCommission || 0,
        currencyCode: tutorConfigDetail?.data?.masterCurrency?.code || masterCurrencyCodeEnum?.USD?.value,
        fixedPrice: tutorConfigDetail?.data?.fixedPrice || 0
    })

    const tutorConfigDefaultData = {
        adminFixedPctCommission: tutorConfigDetail?.data?.adminFixedPctCommission,
        adminVariablePctCommission: tutorConfigDetail?.data?.adminVariablePctCommission,
        currencyCode: tutorConfigDetail?.data?.masterCurrency?.code,
        fixedPrice: tutorConfigDetail?.data?.fixedPrice
    }
    const tutorConfigPayload = {
        adminFixedPctCommission: state?.adminFixedPctCommission,
        adminVariablePctCommission: state?.adminVariablePctCommission,
        currencyCode: state?.currencyCode,
        fixedPrice: state?.fixedPrice
    }

    const isCourseConfigSimilar = JSON.stringify(tutorConfigPayload) === JSON.stringify(tutorConfigDefaultData)

    useEffect(() => {
        return () => {
            dispatcher(resetTutorConfigDetail())
            dispatcher(resetUpdateTutorConfigDetail())
        }
    }, [])

    useEffect(() => {
        if (tutorId) {
            dispatcher(getTutorConfigDetail(Number(tutorId)))
        }
    }, [tutorId])


    useEffect(() => {
        if (tutorConfigDetail?.data) {
            onHandleResetConfig()
        }
    }, [tutorConfigDetail?.data])

    const onHandleUpdateTutorConfig = (key, value) => {
        dispatch({
            type: key,
            payload: value
        })
    }

    const onHandleSave = () => {
        if (tutorConfigDetail?.isLoading || updateTutorConfig?.isLoading) return;

        const body = {}

        if (state.adminFixedPctCommission >= 0) {
            body["adminFixedPctCommission"] = state.adminFixedPctCommission
        }
        if (state.adminVariablePctCommission >= 0) {
            body["adminVariablePctCommission"] = state.adminVariablePctCommission
        }
        if (state.currencyCode) {
            body["currencyCode"] = state.currencyCode
        }
        if (state.fixedPrice >= 0) {
            body["fixedPrice"] = state.fixedPrice
        }

        const requestDataPayload = {
            tutorId: tutorConfigDetail?.data?.tutorId,
            body: body
        }
        dispatcher(updateTutorConfigDetail(requestDataPayload))
    }

    const onHandleResetConfig = () => {
        dispatch({
            type: tutorConfigActionType?.ADMIN_FIXED_PCT_COMMISSION?.value,
            payload: tutorConfigDetail?.data?.adminFixedPctCommission
        })
        dispatch({
            type: tutorConfigActionType?.ADMIN_VARIABLE_PCT_COMMISSION?.value,
            payload: tutorConfigDetail?.data?.adminVariablePctCommission
        })
        dispatch({
            type: tutorConfigActionType?.CURRENCY_CODE?.value,
            payload: tutorConfigDetail?.data?.masterCurrency?.code
        })
        dispatch({
            type: tutorConfigActionType?.FIXED_PRICE?.value,
            payload: tutorConfigDetail?.data?.fixedPrice
        })
    }

    return (
        <>
            <EditTutorHeader title={"Tutor Config"} />

            <div className={"w-full p-5 space-y-5"}>
                {(tutorConfigDetail?.isLoading || updateTutorConfig?.isLoading) &&
                    <ComponentLoader isLoading={tutorConfigDetail?.isLoading || updateTutorConfig?.isLoading} />
                }

                {((!tutorConfigDetail?.isLoading && !updateTutorConfig?.isLoading) && (tutorConfigDetail?.message || updateTutorConfig?.message)) &&
                    <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                        {tutorConfigDetail?.message || updateTutorConfig?.message}
                    </div>
                }
                {((!tutorConfigDetail?.isLoading && !updateTutorConfig?.isLoading) && tutorConfigDetail?.data) &&
                    <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                {"Fixed Admin Commission (in %)"}
                            </span>
                            <input
                                type={"number"}
                                value={state?.adminFixedPctCommission?.toString()}
                                className={cn(
                                    "w-full md:w-72 px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                onChange={(e) => onHandleUpdateTutorConfig(tutorConfigActionType?.ADMIN_FIXED_PCT_COMMISSION?.value, Number(e.target.value))}
                            />
                        </div>

                        {/* variable admin  commission pct */}
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                {"Variable Admin Commission (in %)"}
                            </span>
                            <input
                                type={"number"}
                                value={state?.adminVariablePctCommission?.toString()}
                                className={cn(
                                    "w-full md:w-72 px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                onChange={(e) => onHandleUpdateTutorConfig(tutorConfigActionType?.ADMIN_VARIABLE_PCT_COMMISSION?.value, Number(e.target.value))}
                            />
                        </div>

                        {/* currency code */}
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                {"Currency"}
                            </span>
                            <OptionSelector
                                options={Object.values(masterCurrencyCodeEnum)}
                                className={"w-full md:w-72"}
                                value={state.currencyCode}
                                onChange={(option) => onHandleUpdateTutorConfig(tutorConfigActionType?.CURRENCY_CODE?.value, option?.value)}
                            />
                        </div>


                        {/* fixed price */}
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                {"Fixed Price"}
                            </span>
                            <input
                                type={"number"}
                                value={state?.fixedPrice?.toString()}
                                className={cn(
                                    "w-full md:w-72 px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                onChange={(e) => onHandleUpdateTutorConfig(tutorConfigActionType?.FIXED_PRICE?.value, Number(e.target.value))}
                            />
                        </div>


                        <div className={"w-full md:w-72 flex items-center justify-center gap-5 pt-5"}>
                            <SaveAndCancelBtn
                                isShowBtn={true}
                                isDisabled={isCourseConfigSimilar}
                                onHandleSave={onHandleSave}
                                onHandleCancel={onHandleResetConfig}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default EditTutorConfigPage;