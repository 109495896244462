import { billingCycles, subscriptionType } from "redux/chargeBee/chargeBee.const"

const addUserItemDetailPayload = {
    userItemId: null,
    productFamily: "",
    name: "",
    description: "",
    type: subscriptionType?.PLAN?.value
}

const addItemPriceDetailPayload = {
    itemId: null,
    itemPriceId: null,
    name: "",
    description: "",
    priceModel: "",
    currencyCode: "",
    price: "",
    billingCycle: billingCycles?.FOREVER?.value,
    period: "",
    periodUnit: "",
    showDescriptionInInvoices: false,
    showDescriptionInQuotes: false
}

export const CHARGEBEE_INITIAL_STATE = {
    userItemList: {
        isLoading: false,
        userItemList: null,
        filteredUserItemList: null,
        errorMsg: null
    },

    userItemDetail: {
        isLoading: false,
        userItemDetail: null,
        errorMsg: null
    },
    addUserItemDetail: {
        isLoading: false,
        addUserItemDetail: null,
        addUserItemDetailPayload: addUserItemDetailPayload,
        errorMsg: null
    },

    itemPriceList: {
        isLoading: false,
        itemPriceList: null,
        filteredItemPriceList: null,
        errorMsg: null
    },

    itemPriceDetail: {
        isLoading: false,
        itemPriceDetail: null,
        errorMsg: null
    },
    addItemPriceDetail: {
        isLoading: false,
        addItemPriceDetail: null,
        addItemPriceDetailPayload: addItemPriceDetailPayload,
        errorMsg: null
    }
}