import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import WithdrawalService from "redux/withdrawal/withdrawal.service";

import { setUpdateWithdrawalDetail } from "redux/withdrawal/withdrawal.slice";

import { withdrawalRedux } from "redux/withdrawal/withdrawal.const";

export const getWithdrawalList = createAsyncThunk(
    `${withdrawalRedux?.sliceName}/${withdrawalRedux?.withdrawalList?.requestName}`,
    async (query, { rejectWithValue }) => {
        try {
            const requestData = {
                query: { ...query }
            }
            const response = await WithdrawalService.getWithdrawalList(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const getWithdrawalDetailByWithdrawalId = createAsyncThunk(
    `${withdrawalRedux?.sliceName}/${withdrawalRedux?.withdrawalDetail?.requestName}`,
    async (withdrawalId, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { withdrawalId: withdrawalId }
            }
            const response = await WithdrawalService.getWithdrawalDetailByWithdrawalId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const approveWithdrawalByWithdrawalId = createAsyncThunk(
    `${withdrawalRedux?.sliceName}/${withdrawalRedux?.withdrawalDetail?.requestName}`,
    async (withdrawalPayload, { dispatch, getState, rejectWithValue }) => {
        try {
            const { filterProps } = getState().withdrawal
            const requestData = {
                params: { withdrawalId: withdrawalPayload?.withdrawalId },
                body: withdrawalPayload?.withdrawalGateWayDetail
            }
            const response = await WithdrawalService.approveWithdrawalByWithdrawalId(requestData)
            if (response.status === 200) {
                dispatch(setUpdateWithdrawalDetail(response.data.data))
                dispatch(getWithdrawalList({ page: filterProps?.page, records: filterProps?.records }))
                toast.success(response.data.message)
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error || "Something Went wrong!")
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const rejectWithdrawalByWithdrawalId = createAsyncThunk(
    `${withdrawalRedux?.sliceName}/${withdrawalRedux?.withdrawalDetail?.requestName}`,
    async (withdrawalPayload, { dispatch, getState, rejectWithValue }) => {
        try {
            const { filterProps } = getState().withdrawal
            const requestData = {
                params: { withdrawalId: withdrawalPayload?.withdrawalId },
                body: { comments: withdrawalPayload?.withdrawalGateWayDetail?.comments }
            }
            const response = await WithdrawalService.rejectWithdrawalByWithdrawalId(requestData)
            if (response.status === 200) {
                dispatch(setUpdateWithdrawalDetail(response.data.data))
                dispatch(getWithdrawalList({ page: filterProps?.page, records: filterProps?.records }))
                toast.success(response.data.message)
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        }
    }
)

export const getWithdrawalFilteredList = createAsyncThunk(
    `${withdrawalRedux?.sliceName}/${withdrawalRedux?.withdrawalFilteredList?.requestName}`,
    async (filterProps, { dispatch, getState, rejectWithValue }) => {
        try {

        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)