import { createSlice } from "@reduxjs/toolkit";

import { ORDER_INITIAL_STATE } from "./order.initialState";

const order = createSlice({
    name: "order",
    initialState: ORDER_INITIAL_STATE,
    reducers: {
        // order list
        setOrderListLoading: (state, { payload }) => {
            state.orderList.isLoading = payload
        },
        setOrderListData: (state, { payload }) => {
            state.orderList.data = payload
            state.orderList.message = ORDER_INITIAL_STATE.orderList.message
        },
        setOrderListMessage: (state, { payload }) => {
            state.orderList.message = payload
            state.orderList.data = ORDER_INITIAL_STATE.orderList.data
        },
        resetOrderList: (state) => {
            state.orderList = ORDER_INITIAL_STATE.orderList
        },

        // order detail
        setOrderDetailLoading: (state, { payload }) => {
            state.orderDetail.isLoading = payload
        },
        setOrderDetailData: (state, { payload }) => {
            state.orderDetail.data = payload
            state.orderDetail.message = ORDER_INITIAL_STATE.orderDetail.message
        },
        setOrderDetailMessage: (state, { payload }) => {
            state.orderDetail.message = payload
            state.orderDetail.data = ORDER_INITIAL_STATE.orderDetail.data
        },
        resetOrderDetail: (state) => {
            state.orderDetail = ORDER_INITIAL_STATE.orderDetail
        },

        // order detail payload
        setOrderDetailPayload: (state, { payload }) => {
            state.orderDetail.payload = payload
        },
        resetOrderDetailPayload: (state) => {
            state.orderDetail.payload = ORDER_INITIAL_STATE.orderDetail.payload
        },

        // filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = ORDER_INITIAL_STATE.filterProps
        },
    }
})

export const {
    setOrderListLoading,
    setOrderListData,
    setOrderListMessage,
    resetOrderList,

    setOrderDetailLoading,
    setOrderDetailData,
    setOrderDetailMessage,
    resetOrderDetail,
    setOrderDetailPayload,
    resetOrderDetailPayload,

    setFilterProps,
    resetFilterProps,
} = order.actions;

export default order.reducer;