import format from 'string-format';

import { baseAuthApiInstance } from 'apiInstances/axios.apiInstance';
import { url } from "redux/walletTxn/walletTxn.const";

class WalletTxnService {
    getWalletTransactionList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.WALLET_TRANSACTION_LIST),
            { params: query }
        )

        return response;
    }
}

export default new WalletTxnService()