import { useEffect } from "react";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import { createUserWithdrawalDetailByUserId } from "redux/wallet/wallet.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearAddUserWithdrawalDetail } from "redux/wallet/wallet.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

const ConfirmPayoutModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addUserWithdrawalDetail } = useAppState((state) => state.wallet)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (addUserWithdrawalDetail?.addUserWithdrawalDetail) {
            dispatcher(setModal({
                ...modal,
                [modalConst.CONFIRM_PAYOUT_MODAL.stateKey]: false,
                [modalConst.WITHDRAWAL_REQUEST_SUBMIT_MODAL.stateKey]: true
            }))
        }

        return () => {
            dispatcher(setClearAddUserWithdrawalDetail())
        }

    }, [addUserWithdrawalDetail?.addUserWithdrawalDetail])

    const handleSubmitButton = () => {
        const requestData = {
            userId: user?.user?.userId,
            body: {
                amount: +parseInt(addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount * 100)?.toFixed(2)
            }
        }
        dispatcher(createUserWithdrawalDetailByUserId(requestData))
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[16rem] block space-y-5 pt-5"}>
                <div className={"grid grid-cols-12 gap-5"}>
                    <span className={"col-start-2 col-span-5 font-bodyPri font-normal text-text-900 text-md text-center whitespace-nowrap"}>
                        {"Requested Payout"}
                    </span>
                    <span className={cn(
                        "col-start-7 col-span-full px-3 py-1 flex justify-center items-center rounded-md bg-primary-light",
                        "font-bodyPri font-normal text-primary-dark text-md whitespace-nowrap"
                    )}>
                        {"USD " + " " + parseInt(addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount)?.toFixed(2)}
                    </span>
                </div>
                <div className={"grid grid-cols-12 gap-5"}>
                    <span className={"col-start-2 col-span-5 font-bodyPri font-normal text-text-900 text-md text-center"}>
                        {"New Balance"}
                    </span>
                    <span className={cn(
                        "col-start-7 col-span-full px-3 py-1 flex justify-center items-center rounded-md bg-primary-light",
                        "font-bodyPri font-normal text-primary-dark text-md whitespace-nowrap"
                    )}>
                        {"USD " + " " + parseInt((user?.user?.netWalletCredit - (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount * 100)) / 100).toFixed(2)}
                    </span>
                </div>
                <div className={"py-5 space-y-3"}>
                    <div className={"flex items-center justify-center font-bodyPri font-normal text-text-700 text-lg"}>
                        {"Payout Method"}
                    </div>
                    {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.withdrawMethod?.bankAccountNumber &&
                        <div className={"flex items-center justify-center gap-1.5"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                                {"Account Number:"}
                            </span>
                            <span className={"font-bodyPri font-medium text-text-900 text-md"}>
                                {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.withdrawMethod?.bankAccountNumber}
                            </span>
                        </div>
                    }
                    {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.withdrawMethod?.payId &&
                        <div className={"flex items-center justify-center font-bodyPri font-medium text-text-900 text-xl"}>
                            {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.withdrawMethod?.payId}
                        </div>
                    }
                    {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.withdrawMethod?.upiId &&
                        <div className={"flex items-center justify-center font-bodyPri font-medium text-text-900 text-xl"}>
                            {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.withdrawMethod?.upiId}
                        </div>
                    }
                    {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.withdrawMethod?.paypalId &&
                        <div className={"flex items-center justify-center font-bodyPri font-medium text-text-900 text-xl"}>
                            {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.withdrawMethod?.paypalId}
                        </div>
                    }
                </div>
            </div>
            {addUserWithdrawalDetail?.errorMsg &&
                <div className={"flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                    {addUserWithdrawalDetail?.errorMsg}
                </div>
            }
            <div className={"flex justify-between items-center gap-3"}>
                <div>
                    {addUserWithdrawalDetail?.isLoading &&
                        <div className={"flex items-center justify-start gap-1"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                {"Please wait ..."}
                            </span>
                            <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                        </div>
                    }
                </div>
                <div className={""}>
                    <span
                        className={cn(
                            "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                            "font-bodyPri font-normal text-base text-secondary-main",
                            "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                        )}
                        onClick={handleSubmitButton}
                    >
                        {"Submit"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ConfirmPayoutModal