import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/appointment/appointment.const";

class Appointment {
    getAppointmentList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_APPOINTMENT_LIST),
            { params: query }
        )

        return response;
    }

    getAppointmentDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_APPOINTMENT_DETAIL, params)
        )

        return response;
    }

    updateAppointmentDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_APPOINTMENT_DETAIL, params),
            body
        )

        return response;
    }

    deleteAppointmentDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_APPOINTMENT_DETAIL, params)
        )

        return response;
    }
}

export default new Appointment()