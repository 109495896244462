import { toast } from 'react-toastify';

import { createAsyncThunk } from '@reduxjs/toolkit';

import WalletService from 'redux/wallet/wallet.service';
import { 
    setAddCreditToWalletLoading,
    setAddCreditToWalletData, 
    setAddCreditToWalletMessage
} from './wallet.slice';
import { walletRedux } from "redux/wallet/wallet.const";

import { getMyProfile } from 'redux/user/user.request';

export const getWalletTxnList = createAsyncThunk(
    `${walletRedux?.sliceName}/${walletRedux?.walletTxnList?.requestName}`,
    async (_, { rejectWithValue }) => {

        try {
            const response = await WalletService.getWalletTxnList()
            if (response.status === 200) {
                return response.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.message)
            return rejectWithValue(error.message)
        }
    }
)


// add credit to wallet by user id (new api)
export const createCreditToWallet = createAsyncThunk(
    `${walletRedux?.sliceName}/${walletRedux?.addCreditToWallet?.requestName}`,
    async (payload, { dispatch }) => {
        dispatch(setAddCreditToWalletLoading(true))

        try {
            const requestData = {
                params: { userId: payload?.userId },
                body: payload?.body
            }
            const response = await WalletService.createCreditToWallet(requestData)
            if (response.status === 200) {
                dispatch(setAddCreditToWalletData(response.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddCreditToWalletMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddCreditToWalletLoading(false))
        }
    }
)

export const getWalletConfirmationByVirtualTxnId = createAsyncThunk(
    `${walletRedux?.sliceName}/${walletRedux?.walletConfirmationByVirtualTxnId?.requestName}`,
    async (virtualTxnId, { rejectWithValue }) => {

        try {
            const requestData = {
                params: { virtualTxnId: virtualTxnId }
            }

            const response = await WalletService.getWalletConfirmationByVirtualTxnId(requestData)
            if (response.status === 200) {
                return response.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.message)
            rejectWithValue(error.message)
        }
    }
)

export const getMyWithdrawalListByUserId = createAsyncThunk(
    `${walletRedux?.sliceName}/${walletRedux?.myWithdrawalList?.requestName}`,
    async (withdrawalData, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { userId: withdrawalData?.userId },
                query: { ...withdrawalData?.query }
            }
            const response = await WalletService.getUserWithdrawalListByUserId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const getUserWithdrawalListByUserId = createAsyncThunk(
    `${walletRedux?.sliceName}/${walletRedux?.userWithdrawalList?.requestName}`,
    async (withdrawalData, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { userId: withdrawalData?.userId },
                query: { status: withdrawalData?.status, ...withdrawalData?.query }
            }
            const response = await WalletService.getUserWithdrawalListByUserId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const createUserWithdrawalDetailByUserId = createAsyncThunk(
    `${walletRedux?.sliceName}/${walletRedux?.createUserWithdrawalDetail?.requestName}`,
    async (withdrawalDetailData, { dispatch, getState, rejectWithValue }) => {
        try {
            const { user } = getState().user
            const requestData = {
                params: { userId: withdrawalDetailData?.userId },
                body: withdrawalDetailData?.body
            }
            const response = await WalletService.createUserWithdrawalDetailByUserId(requestData)
            if (response.status === 200) {
                const withdrawalData = {
                    userId: user?.user?.userId,
                    query: { page: 1, records: 10 }
                }
                dispatch(getMyWithdrawalListByUserId(withdrawalData))
                dispatch(getMyProfile())
                toast.success(response.data.message)
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.message)
            rejectWithValue(error.response.data.message || error.response.data.message)
        }
    }
)