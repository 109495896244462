import { useEffect } from "react";
import { useParams } from "react-router-dom";

import FullPageLoader from "components/loader/FullPageLoader";
import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";

import { getOnboardingDocListByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingDocList } from "redux/onboarding/onboarding.slice";
import { videoType } from "redux/onboarding/onboarding.const";

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const ViewIntroVideo = () => {
    const { onboardingDocList } = useAppState((s) => s.onboarding)
    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const filteredDocumentList = onboardingDocList?.onboardingDocList?.filter((document) => document?.docType === videoType?.INTRO_VIDEO?.value)

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingDocListByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingDocList())
        }
    }, [onboardingId])

    const handleRefreshButton = () => {
        dispatcher(getOnboardingDocListByOnboardingId(Number(onboardingId)))
    }

    if (onboardingDocList?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingDocList?.isLoading} />
        )
    }

    if (onboardingDocList?.errorMsg) {
        return (
            <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-center text-red-500 text-sm"}>
                {onboardingDocList?.errorMsg}
            </div>
        )
    }
    return (
        <div className={"space-y-5 px-5 py-3"}>
            <div className={"flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg"}>
                {"Intro Video Verification"}
            </div>

            <div className={"w-full sm:w-[28rem] md:w-[30rem] h-full overflow-hidden rounded-lg"}>
                <MuxVideoPlayer
                    playbackId={filteredDocumentList ? filteredDocumentList[0]?.docUrl : MUX_DEMO_PLAYBACK_ID}
                    onHandleRefresh={handleRefreshButton}
                    isShowRefreshButton={true}
                    isShowButtons={false}
                />
            </div>
        </div>
    )
}

export default ViewIntroVideo