import referralServices from "redux/referral/referral.service";
import {
    setReferralListLoading,
    setReferralListData,
    setReferralListMessage,
} from "redux/referral/referral.slice";

export const getReferralList = (query) => async (dispatch) => {
    dispatch(setReferralListLoading(true))

    try {

        const requestData = {
            query: query
        }

        const response = await referralServices.getReferralList(requestData)
        if (response.status === 200) {
            dispatch(setReferralListData(response.data.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setReferralListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setReferralListLoading(false))
    }
}