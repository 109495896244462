import { cn } from "utils/cn.utils";
import style from "./styles.module.css"

const ButtonLoader = ({
    className = "",
    styles = {},
    dotStyles = {},
    dotCount = 3,
    duration = 600
}) => {

    let dotElements = document.querySelectorAll("span.dot")
    dotElements.forEach((element, index) => {
        element.style.animationDuration = duration + "ms"
        element.style.animationDelay = (index * (duration / dotElements?.length)) + "ms"
    })

    return (
        <div className={cn(className)}>
            <div className={style.btnLoader} style={styles}>
                {new Array(dotCount).fill("").map((_, index) => (
                    <span
                        key={index}
                        className={`${style.btnLoaderDot} dot`}
                        style={dotStyles}
                    ></span>
                ))}
            </div>
        </div>
    )
}

export default ButtonLoader;