import { memo, useState } from 'react';

import { IoIosCheckmark } from 'react-icons/io';

import InputTextSwitch from 'components/InputTextSwitch/inputTextSwitch';
import FloatingLabelSelect from 'components/floating/floatingLabelSelect';

import { useAppState } from 'hooks/useStore';

import { cn } from 'utils/cn.utils';

const LinkTreeCard = memo(({ linkItem }) => {
    const { socialList } = useAppState((s) => s.master)

    const [modifyLinkDetail, setModifyLinkDetail] = useState({
        isLoading: false,
        data: linkItem,
        message: null
    });

    return (
        <div className='bg-white w-full flex items-center gap-3 rounded-lg border p-3'>
            <div className='w-full flex flex-col gap-0.5'>
                <div className='w-full flex items-center'>
                    <FloatingLabelSelect
                        labelItem={"social"}
                        options={socialList?.data?.map((socialContent) => ({
                            label: socialContent?.name,
                            value: socialContent?.key,
                            picture_url: socialContent?.picture_url,
                            color: socialContent?.color
                        })) || []}
                        customBtnStyle={"border-transparent hover:border-transparent hover:shadow"}
                        dropdownWrapperClassName={"w-60"}
                        dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                        searchable={true}
                        showLabel={false}
                        onHandleSelect={(option) => { return }}
                        value={<img src={modifyLinkDetail?.data?.social?.picture_url} className='w-5 h-5 aspect-square' />}
                        OptionChild={({ option }) => (
                            <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                                <div className={"flex items-center gap-1"}>
                                    <div className={"w-6 h-6 overflow-hidden rounded-full"}>
                                        <img
                                            src={option?.picture_url}
                                            className={`mr-1 ${option?.color} w-full h-full object-cover`}
                                            alt={option?.value}
                                        />
                                    </div>
                                    {option?.label}
                                </div>
                                {option?.value === modifyLinkDetail?.data?.social?.key && <IoIosCheckmark size={20} color="green" />}
                            </div>
                        )}
                        isDisabled={true}
                    />
                    <InputTextSwitch
                        textStyle={"text-text-900 font-medium line-clamp-1"}
                        placeholder="Link Title"
                        text={<p>{(modifyLinkDetail?.data?.title?.length === 0) ? "Add link title" : modifyLinkDetail?.data?.title}</p>}
                        value={modifyLinkDetail?.data?.title}
                        onChange={(e) => { return }}
                        disabled={true}
                    />
                </div>
                <InputTextSwitch
                    textStyle={cn(
                        "text-text-900 text-sm font-medium line-clamp-1",
                        (modifyLinkDetail?.data?.link?.length === 0) ? "text-text-500" : ""
                    )}
                    placeholder={"Add URL"}
                    text={<p>{(modifyLinkDetail?.data?.link?.length === 0) ? "Add URL" : modifyLinkDetail?.data?.link}</p>}
                    value={modifyLinkDetail?.data?.link}
                    onChange={(e) => { return }}
                    disabled={true}
                />
            </div>
        </div>
    )
})

export default LinkTreeCard;

export const LinkTreeCardLoader = () => {
    return (
        <div className='bg-white w-full flex items-center gap-5 rounded-lg border p-3'>
            <div className='h-10 w-5 rounded animate-pulse bg-slate-200'></div>
            <div className='w-full flex flex-col gap-3'>
                <div className='flex gap-5 w-full'>
                    <div className='h-8 w-8 rounded-full animate-pulse bg-slate-200'></div>
                    <div className='h-8 w-full rounded animate-pulse bg-slate-200'></div>
                </div>
                <div className='h-6 w-full rounded animate-pulse bg-slate-200'></div>
            </div>
            <div className='w-20 flex flex-col items-end gap-2'>
                <div className='h-6 w-6 rounded-full animate-pulse bg-slate-200'></div>
                <div className='h-6 w-8 rounded-full animate-pulse bg-slate-200'></div>
            </div>
        </div>
    )
}
