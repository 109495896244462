import { cn } from "utils/cn.utils";

import { FaRegComment } from "react-icons/fa";
import { FcGraduationCap } from "react-icons/fc";

import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";

const BookingTutorCard = ({ tutorDetail }) => {
    return (
        <div className={"w-fit h-[44rem] bg-white rounded-lg shadow-all space-y-5 p-5"}>
            <div className={"w-full font-bodyPri font-bold text-text-900 text-lg text-center tracking-wide leading-6"}>
                {"Your Tutor"}
            </div>
            <div className={"w-full flex flex-col items-start justify-start gap-10"}>
                <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                    <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>
                    <div className={"flex items-start justify-start gap-5"}>
                        <div className={"relative w-40 h-40 rounded-full sm:w-36 sm:h-36"}>
                            <img
                                src={tutorDetail?.profilePicUrl}
                                alt={"profileImg"}
                                className={"w-full h-full rounded-full p-2 object-cover"}
                            />
                            <div className={"relative has-tooltip"}>
                                <div className={'absolute -top-9 bottom-5 right-5 w-6 h-6 rounded-full overflow-hidden border-2 border-white'}>
                                    <img
                                        src={`https://flagcdn.com/16x12/${tutorDetail?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                        alt={"country-flag"}
                                        className='w-full h-full object-cover'
                                    />
                                </div>
                                <span
                                    className={cn(
                                        "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-2 -right-0",
                                        "flex flex-col items-center justify-start overflow-hidden"
                                    )}
                                >
                                    {tutorDetail?.fromCountry?.country}
                                </span>
                            </div>
                        </div>
                        <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {tutorDetail?.firstName + " " + tutorDetail?.lastName?.charAt(0)?.toUpperCase() + "."}
                        </div>
                    </div>
                    <div className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {tutorDetail?.elevatorPitch}
                    </div>
                    <div className={"w-full"}>
                        <MuxVideoPlayer
                            playbackId={tutorDetail?.videoUrl}
                            isShowButtons={false}
                            isShowDownloadButton={false}
                        />
                    </div>
                </div>
                <div className={"flex flex-col items-start justify-start gap-3"}>
                    <div className={"flex text-base font-bodyPri tracking-wide gap-2"}>
                        <FaRegComment size={24} color="#CCC" />
                        <span className={"text-text-600"}>
                            {"Speaks:"}
                        </span>
                        {tutorDetail?.languages.slice(0, 2).map((language, index) => (
                            <span
                                key={index}
                                className={"flex justify-center items-center gap-1 text-medium font-medium"}
                            >
                                {index !== 0 &&
                                    <p>{"|"}</p>
                                }
                                {language?.language}
                            </span>
                        ))}
                    </div>
                    <div className={"flex text-base font-bodyPri tracking-wide gap-2 overflow-hidden"}>
                        <FcGraduationCap size={24} color="#CCC" />
                        <span className={"text-text-600"}>
                            {"Teaches:"}
                        </span>
                        <span className={"text-medium font-medium"}>
                            {tutorDetail?.categories.slice(0, 2).join(" | ")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingTutorCard;