import { companyOnboardingFilterProps } from "redux/company/company.const";

export const COMPANY_INITIAL_STATE = {

    companyOnboardingList: {
        isLoading: false,
        companyOnboardingList: null,
        filteredCompanyOnboarding: null,
        errorMsg: null
    },

    companyPublicProfile: {
        isLoading: false,
        companyPublicProfile: null,
        errorMsg: null
    },

    companyCourseList: {
        isLoading: false,
        companyCourseList: null,
        errorMsg: null
    },

    employeeList: {
        isLoading: false,
        employeeList: null,
        errorMsg: null
    },

    companyReviewList: {
        isLoading: false,
        companyReviewList: null,
        errorMsg: null
    },

    similarCompanyList: {
        isLoading: false,
        similarCompanyList: null,
        errorMsg: null
    },

    importantLinksList: {
        isLoading: false,
        importantLinksList: null,
        errorMsg: null
    },

    FAQList: {
        isLoading: false,
        FAQList: null,
        errorMsg: null
    },

    filterProps: companyOnboardingFilterProps
}