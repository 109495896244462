import { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';

import { getCourseAvailabilityDetail } from 'redux/course/course.request';
import { getSessionCourseList } from 'redux/session/session.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import {
    resetNextCourseSessionDetail,
    setNextCourseSessionDetailPayload,
    resetNextCourseSessionDetailPayload,
    setClearCourseAvailDetail
} from 'redux/course/course.slice';
import { resetSessionCourseList } from 'redux/session/session.slice';
import { modalConst } from 'redux/local/local.const';
import { courseIsRecurringEnum, courseType } from 'redux/course/course.const';
import { sessionStatusEnum } from 'redux/session/session.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { generateQueryParams } from "utils/generators.utils";
import { pagesInfo } from 'utils/pagesInfo';

const EditCourseSessionPage = () => {
    const { modal } = useAppState((state) => state.local)
    const { courseDetail, courseAvailDetail, nextCourseSessionDetail } = useAppState((state) => state.course)
    const { sessionCourseList } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const isGroupCourse = courseDetail?.courseDetail?.type === courseType?.GROUP?.value
    const isCourseRecurring = courseAvailDetail?.courseAvailDetail?.availability?.isRecurring === courseIsRecurringEnum?.RECURRING?.value

    useEffect(() => {
        if (nextCourseSessionDetail?.data) {
            dispatcher(resetNextCourseSessionDetailPayload())
            dispatcher(resetNextCourseSessionDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_NEXT_COURSE_SESSION_MODAL.stateKey]: false
            }))
        }
    }, [nextCourseSessionDetail?.data])

    useEffect(() => {
        return () => {
            dispatcher(resetNextCourseSessionDetail())
            dispatcher(resetSessionCourseList())
            dispatcher(setClearCourseAvailDetail())
        }
    }, [])

    useEffect(() => {
        if (courseDetail?.courseDetail?.id) {
            const query = generateQueryParams(location.search)
            dispatcher(getSessionCourseList({ page: query?.pn || "1", records: 10, courseId: courseDetail?.courseDetail?.id }))
        }
    }, [courseDetail?.courseDetail?.id, location.search])


    useEffect(() => {
        if (courseDetail?.courseDetail?.courseAvailability?.id) {
            const requestDataPayload = {
                courseAvailabilityId: courseDetail?.courseDetail?.courseAvailability?.id,
                query: { timeZone: timeZone }
            }
            dispatcher(getCourseAvailabilityDetail(requestDataPayload))
        }
    }, [courseDetail?.courseDetail])

    const onHandleAddNextSession = () => {
        if (!isGroupCourse && !isCourseRecurring) return;

        dispatcher(resetNextCourseSessionDetail())
        dispatcher(setNextCourseSessionDetailPayload({ courseId: courseDetail?.courseDetail?.id }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_NEXT_COURSE_SESSION_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={cn(
            "w-full h-full bg-white rounded-lg select-none",
            "min-h-[60vh]"
        )}>
            <div className={"w-full px-5 py-3 bg-background-darkLight rounded-lg"}>
                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Sessions"}
                </div>
            </div>

            <div className={"w-full p-5 space-y-5"}>

                {/* add next session button */}
                {(isGroupCourse && isCourseRecurring) &&
                    <div className={"flex justify-end"}>
                        <div
                            className={"flex items-center justify-center gap-1 py-0.5 px-2 border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"}
                            onClick={onHandleAddNextSession}
                        >
                            <MdAdd className={"text-lg group-hover:text-text-50"} />
                            <span className={"font-bodyPri font-normal text-base group-hover:text-text-50"}>
                                {"Add Session(s)"}
                            </span>
                        </div>
                    </div>
                }

                <ComponentLoader isLoading={sessionCourseList?.isLoading} />
                {sessionCourseList?.data?.records > 0 &&
                    sessionCourseList?.data?.results?.map((sessionCourse, index) => (
                        <div
                            key={index}
                            className={`w-full border border-${sessionStatusEnum[sessionCourse?.session?.status?.toUpperCase()]?.darkColor} rounded-lg px-5 py-2 space-y-3`}>

                            <div className={"flex items-center justify-start gap-1"}>
                                <span className={"font-bodyPri font-semibold text-text-900 text-lg"}>
                                    {sessionCourse?.session?.id + "."}
                                </span>
                                <span className={"w-full font-bodyPri font-semibold text-text-900 text-lg tracking-wide text-left truncate border-b bordespanider-medium"}>
                                    {sessionCourse?.session?.name?.length > 60
                                        ? sessionCourse?.session?.name?.slice(0, 60) + " ..."
                                        : sessionCourse?.session?.name
                                    }
                                </span>
                            </div>

                            <div className={"grid grid-cols-4 w-full gap-3"}>
                                <div className={"col-start-1 col-span-2 space-x-1"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"Duration:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                        {sessionCourse?.session?.duration <= 60
                                            ? sessionCourse?.session?.duration + " min"
                                            : parseFloat(sessionCourse?.session?.duration / 60)?.toFixed(2) + " hrs"
                                        }
                                    </span>
                                </div>
                                <div className={"col-start-3 col-span-2 space-x-1"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"Status:"}
                                    </span>
                                    <span className={`font-bodyPri font-medium text-base tracking-wide text-${sessionStatusEnum[sessionCourse?.session?.status?.toUpperCase()]?.darkColor}`}>
                                        {sessionStatusEnum[sessionCourse?.session?.status?.toUpperCase()]?.label}
                                    </span>
                                </div>

                                <div className={"col-start-1 col-span-2 space-x-1"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"Start Date:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                        {dayjs(sessionCourse?.session?.startDateTime)?.tz(timeZone)?.format("ddd, DD MMM YY hh:mm a")}
                                    </span>
                                </div>
                                <div className={"col-start-3 col-span-2 space-x-1"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"End Date:"}
                                    </span>
                                    <span className={`font-bodyPri font-medium text-base tracking-wide text-text-900`}>
                                        {dayjs(sessionCourse?.session?.endDateTime)?.tz(timeZone)?.format("ddd, DD MMM YY hh:mm a")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}

                {(sessionCourseList?.data && sessionCourseList?.data?.records > 0) && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={sessionCourseList?.data?.page}
                            totalPages={sessionCourseList?.data?.totalPages}
                            onChangePage={(page) => navigate(`${pagesInfo?.EDIT_COURSE?.pagePath}/${courseDetail?.courseDetail?.id}/edit/class-sessions?pn=${page}`)}
                        />
                    </div>
                )}

                {(!sessionCourseList?.isLoading && (!sessionCourseList?.data || sessionCourseList?.data?.records === 0 || sessionCourseList?.message)) &&
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {sessionCourseList?.message || "No sessions found!"}
                    </span>
                }
            </div>
        </div>
    )
}

export default EditCourseSessionPage;