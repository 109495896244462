import format from 'string-format'
import { baseAuthApiInstance } from 'apiInstances/axios.apiInstance'
import { url } from './lmsCourse.const'


class LmsCourseService {

    static lmsCourseService = new LmsCourseService()

    // Lms course services
    getLmsCourseList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_COURSE_LIST),
            { params: query }

        )
        return response;
    }

    updateLmsCourseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LMS_COURSE_DETAIL, params),
            body
        )

        return response;
    }
    
    deleteLmsCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_COURSE_DETAIL, params)
        )

        return response;
    }
}

export default LmsCourseService.lmsCourseService;