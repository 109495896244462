import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import { MdOutlineDragIndicator } from 'react-icons/md';
import { BsInfoCircleFill } from 'react-icons/bs';

import ComponentLoader from 'components/loader/ComponentLoader';

import { tutorDetailTooltipConst } from 'pages/auth/viewTutor/data';
import ViewTutorHeader from 'pages/auth/viewTutor/commonComponents/ViewTutorHeader';

import { getTutorLanguageList } from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetTutorLanguageList } from 'redux/tutor/tutor.slice';
import { languageLevelConst } from 'redux/master/master.const';
import { tutorLanguageMessageType } from "redux/tutor/tutor.const";

const ViewTutorLanguages = () => {
    const { tutorLanguageList } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const { tutorId } = useParams()


    useEffect(() => {
        return () => {
            dispatcher(resetTutorLanguageList())
        }
    }, [])

    useEffect(() => {
        if (tutorId) {
            dispatcher(getTutorLanguageList({ tutorId: Number(tutorId) }))
        }
    }, [tutorId])

    return (
        <div className={"w-full min-h-[28rem] flex flex-col items-start justify-start space-y-5"}>

            <ViewTutorHeader title={"Languages I speak"} tooltipText={tutorDetailTooltipConst?.languageTooltip} />

            {tutorLanguageList?.isLoading &&
                <ComponentLoader isLoading={tutorLanguageList?.isLoading} />
            }
            {(!tutorLanguageList?.isLoading && tutorLanguageList?.message) &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {tutorLanguageList?.message}
                    </span>
                </div>
            }
            {tutorLanguageList?.data?.message &&
                <div className={"w-full px-2 py-2 sm:py-4 flex items-center justify-center gap-3 bg-white rounded-lg"}>
                    {tutorLanguageList?.data?.message?.type === tutorLanguageMessageType?.INFO?.value &&
                        <span className={cn(`text-${tutorLanguageMessageType?.INFO?.darkColor}`)}>
                            <BsInfoCircleFill className={"text-xl sm:text-2xl"} />
                        </span>
                    }
                    <span className={"font-bodyPri font-normal text-text-900 text-base lg:text-lg"}>
                        {tutorLanguageList?.data?.message?.text}
                    </span>
                </div>
            }

            {(!tutorLanguageList?.isLoading) && (tutorLanguageList?.data?.results?.length > 0) &&
                <div className={"w-full px-3 py-3 border border-divider-medium rounded-lg space-y-3"}>
                    {tutorLanguageList?.data?.results?.length > 0 &&
                        tutorLanguageList?.data?.results?.map((language, index) => (
                            <div key={index} className={"grid grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-2"}>
                                <div className={"col-start-1 col-span-1 flex items-center justify-start gap-3"}>
                                    <span className={""}>
                                        <MdOutlineDragIndicator className={"text-lg text-text-800"} />
                                    </span>
                                    <div className={"w-fit px-3 py-1 flex items-center justify-start gap-1.5 border border-divider-lightDark rounded-lg overflow-hidden"}>
                                        <span className={"font-bodyPri font-medium text-text-900 text-sm tracking-wide"}>
                                            {language?.masterLanguage?.language}
                                        </span>
                                        <span className={"font-bodyPri font-normal text-sm text-text-700 tracking-wide whitespace-normal sm:whitespace-nowrap"}>
                                            ({languageLevelConst[language?.masterLanguageLevel?.languageLevel?.toUpperCase()]?.label})
                                        </span>
                                    </div>
                                </div>
                                <div className={"col-start-1 col-span-full h-0.5 border border-divider-medium"}>
                                </div>
                            </div>
                        ))}
                </div>
            }
        </div>
    )
}

export default ViewTutorLanguages;