import { dataEntriesConst } from "redux/local/local.const";

export const url = {
    WITHDRAWAL_LIST: "/withdrawals",
    WITHDRAWAL_DETAIL: "/withdrawals/{withdrawalId}",
    APPROVE_WITHDRAWAL: "/withdrawals/{withdrawalId}/approve",
    REJECT_WITHDRAWAL: "/withdrawals/{withdrawalId}/reject"
}

export const withdrawalRedux = {
    sliceName: "withdrawal",
    withdrawalList: {
        requestName: "getWithdrawalList"
    },
    withdrawalDetail: {
        requestName: "getWithdrawalDetailByWithdrawalId"
    },
    approveWithdrawal: {
        requestName: "approveWithdrawalByWithdrawalId"
    },
    rejectWithdrawal: {
        requestName: "rejectWithdrawalByWithdrawalId"
    },
    withdrawalFilteredList: {
        requestName: "getWithdrawalFilteredList"
    }
}

export const filterName = {
    WITHDRAWAL: {
        value: "withdrawal"
    }
}

export const gatewayNameConst = {
    RAZORPAY: {
        label: "Razor Pay",
        value: "razorpay"
    },
    STRIPE: {
        label: "Stripe",
        value: "stripe"
    },
    BANK: {
        label: "Bank",
        value: "bank"
    },
    WISE: {
        label: "Wise",
        value: "wise"
    }
}

export const payoutType = {
    All: {
        label: "All",
        value: "all"
    },
    BANK: {
        label: "Bank",
        value: "bank"
    },
    PAY: {
        label: "Pay",
        value: "pay"
    },
    UPI: {
        label: "UPI",
        value: "upi"
    },
    PAYPAL: {
        label: "PayPal",
        value: "paypal"
    },
    CREDIT_CARD: {
        label: "Credit Card",
        value: "credit_card"
    },
    DEBIT_CARD: {
        label: "Debit Card",
        value: "debit_card"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}
export const withdrawalStatus = {
    ALL: {
        label: "All",
        value: "all",
        color: ""
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        color: "text-orange-500"
    },
    APPROVED: {
        label: "Approved",
        value: "approved",
        color: "text-green-500"
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        color: ""
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        color: "text-red-500"
    }
}

export const withdrawalFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    sortBy: "",
    searchText: "",
    userId: "",
    withdrawalId: "",
    gatewayFundId: "",
    gatewayPaymentId: "",
    virtualPaymentId: "",
    payoutType: payoutType.All.value,
    withdrawalAmount: "",
    withdrawalStatus: withdrawalStatus.ALL.value,
    comment: "",
    date: "",
    page: 1,
    records: dataEntriesConst?.TWENTY_FIVE?.value
}

// withdrawalApproved: "",
// balanceAfterApproval: "",
// gatewayPaymentId: "",
// virtualPaymentId: "",
// gatewayFundId: "",
// payoutType: "",
// withdrawalStatus: "",
// comment: "",
// txnDate: "",