const parts = new WeakMap();
export class NodeTemplatePart {
    constructor(node, expression) {
        this.expression = expression;
        parts.set(this, [node]);
        node.textContent = '';
    }
    get value() {
        return parts
            .get(this)
            .map(node => node.textContent)
            .join('');
    }
    set value(string) {
        this.replace(string);
    }
    get previousSibling() {
        return parts.get(this)[0].previousSibling;
    }
    get nextSibling() {
        return parts.get(this)[parts.get(this).length - 1].nextSibling;
    }
    replace(...nodes) {
        const normalisedNodes = nodes.map(node => {
            if (typeof node === 'string')
                return new Text(node);
            return node;
        });
        if (!normalisedNodes.length)
            normalisedNodes.push(new Text(''));
        parts.get(this)[0].before(...normalisedNodes);
        for (const part of parts.get(this))
            part.remove();
        parts.set(this, normalisedNodes);
    }
}
