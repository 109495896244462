export const url = {
    LEAD_LIST: "/leads/",
    CREATE_LEAD_DETAIL: "/leads/",
    LEAD_DETAIL: "/leads/{leadId}"
}

export const leadRedux = {
    sliceName: "lead",
    leadList: {
        requestName: "getLeadList"
    },
    leadDetail: {
        requestName: {
            requestName: "getLeadDetailByLeadId"
        }
    }
}

export const title = {
    MR: {
        label: "Mr",
        value: "Mr"
    },
    MS: {
        label: "Ms",
        value: "Ms"
    },
    MRS: {
        label: "Mrs",
        value: "Mrs"
    },
    MISS: {
        label: "Miss",
        value: "Miss"
    },
    DR: {
        label: "Dr",
        value: "Dr"
    },
    SIR: {
        label: "Sir",
        value: "Sir"
    }
}

export const genderOptions = {
    MALE: {
        label: "Male",
        value: "male"
    },
    FEMALE: {
        label: "Female",
        value: "female"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const JourneyConst = {
    ME: {
        label: "Me",
        value: "me"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const contactTime = {
    MORNING: {
        label: "Morning",
        value: "morning",
        imgUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/need-a-tutor-modal/morning.png"
    },
    AFTERNOON: {
        label: "Afternoon",
        value: "afternoon",
        imgUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/need-a-tutor-modal/afternoon.png"
    },
    EVENING: {
        label: "Evening",
        value: "evening",
        imgUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/need-a-tutor-modal/evening.png"
    },
    ANYTIME: {
        label: "Anytime",
        value: "anytime",
        imgUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/need-a-tutor-modal/anytime.png"
    },
}

export const leadStrength = {
    VERY_WEAK: {
        label: "Very Weak",
        value: "very_weak"
    },
    WEAK: {
        label: "Weak",
        value: "weak"
    },
    NORMAL: {
        label: "Normal",
        value: "normal"
    },
    STRONG: {
        label: "Strong",
        value: "strong"
    },
    VERY_STRONG: {
        label: "Very Strong",
        value: "very_strong"
    }
}

export const leadStatus = {
    NEW: {
        label: "New",
        value: "new",
        lightColor: "orange-100",
        darkColor: "orange-500"
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    ATTEMPTED: {
        label: "Attempted",
        value: "attempted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    QUALIFIED: {
        label: "Qualified",
        value: "qualified",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    NOT_QUALIFIED: {
        label: "Not Qualified",
        value: "not_qualified",
        lightColor: "gray-100",
        darkColor: "gray-500"
    },
    CONVERTED: {
        label: "Converted",
        value: "converted",
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const leadActionRequired = {
    NA: {
        label: "NA",
        value: "na",
    },
    USER: {
        label: "USER",
        value: "user",
    },
    ADMIN: {
        label: "ADMIN",
        value: "admin",
    },
}


export const leadFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    courseId: "",
    segment: "",
    category: "",
    tutorName: "",
    courseTitle: "",
    price: "",
    startDate: "",
    endDate: "",
    actionRequired: leadActionRequired.NA.value,
}