import { tutorFilterProps } from "redux/tutor/tutor.const";

export const TUTOR_INITIAL_STATE = {
    tutorList: {
        isLoading: false,
        tutorList: null,
        filteredTutorList: null,
        errorMsg: null
    },

    tutorUserProfile: {
        isLoading: false,
        tutorUserProfile: null,
        errorMsg: null
    },

    tutorDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    updateTutorDetail: {
        isLoading: false,
        data: null,
        message: null,
    },

    tutorUserPrice: {
        isLoading: false,
        tutorUserPrice: null,
        errorMsg: null
    },
    addTutorUserPrice: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserLesson: {
        isLoading: false,
        tutorUserLesson: null,
        errorMsg: null
    },
    addTutorUserLesson: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserLanguageList: {
        isLoading: false,
        tutorUserLanguageList: null,
        errorMsg: null
    },

    addTutorLanguageDetail: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserCategoryList: {
        isLoading: false,
        tutorUserCategoryList: null,
        errorMsg: null
    },
    addTutorCategoryDetail: {
        isLoading: false,
        errorMsg: null
    },

    // new modified tutor api

    // new tutor language api
    tutorLanguageList: {
        isLoading: false,
        data: null,
        message: null
    },
    addTutorLanguageDetail: {
        isLoading: false,
        message: null
    },
    updateTutorLanguage: {
        isLoading: false,
        message: null
    },
    updateTutorLanguageOrder: {
        isLoading: false,
        message: null
    },
    deleteTutorLanguage: {
        isLoading: false,
        message: null
    },

    // new modified tutor tag api
    tutorTagList: {
        isLoading: false,
        data: null,
        message: null
    },
    addTutorTagDetail: {
        isLoading: false,
        message: null
    },
    updateTutorTagOrder: {
        isLoading: false,
        message: null
    },
    deleteTutorTag: {
        isLoading: false,
        message: null
    },

    // tutor category api
    tutorCategoryList: {
        isLoading: false,
        data: null,
        message: null
    },
    addTutorCategoryDetail: {
        isLoading: false,
        message: null
    },
    updateTutorCategory: {
        isLoading: false,
        message: null
    },
    updateTutorCategoryOrder: {
        isLoading: false,
        message: null
    },
    deleteTutorCategory: {
        isLoading: false,
        message: null
    },

    tutorConfigDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    updateTutorConfig: {
        isLoading: false,
        message: null
    },

    filterProps: tutorFilterProps
}