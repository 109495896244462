import { createAsyncThunk } from '@reduxjs/toolkit';

import WalletTxnService from './walletTxn.service';

import {
    setWalletTxnListPayload,
    setWalletTxnDetail
} from "redux/walletTxn/walletTxn.slice";
import { walletTxnRedux } from "redux/walletTxn/walletTxn.const";

export const getWalletTransactionList = createAsyncThunk(
    `${walletTxnRedux?.sliceName}/${walletTxnRedux?.walletTxnList?.requestName}`,
    async (query, { rejectWithValue }) => {
        try {
            const requestData = {
                query: query
            }
            const response = await WalletTxnService.getWalletTransactionList(requestData)
            if (response.status === 200) {
                return response.data.data
            } else throw new Error(response)
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)