import { dayjs } from "utils/dateTime.utils";

import {
    SearchLeadId,
    SearchLeadName,
    SearchEmail,
    SearchMobileNo,
    FilterLeadType,
    FilterContactTime,
    // FilterLeadStrength,
    FilterLeadStatus,
    SearchDate,
    ButtonAction,
    FilterTaskAction
} from "pages/auth/leads/components/LeadFilterStatistics";

export const pageHeading = {
    heading: "Leads",
    subHeading: "Find the list of leads!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const leadHeaderConst = {
    LEAD_ID: {
        label: "Lead ID",
        value: "lead_id"
    },
    USER: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    MOBILE_NO: {
        label: "Mobile No",
        value: "mobile_no"
    },
    LEAD_TYPE: {
        label: "Lead Type",
        value: "lead_type"
    },
    CONTACT_TIME: {
        label: "Contact Time",
        value: "contact_time"
    },
    // LEAD_STRENGTH: {
    //     label: "Lead Strength",
    //     value: "lead_strength"
    // },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    ACTION: {
        label: "Action",
        value: "action"
    }
}

export const subRowHeader = [
    <SearchLeadId />,
    <SearchLeadName />,
    <SearchEmail />,
    <SearchMobileNo />,
    <FilterLeadType />,
    <FilterContactTime />,
    // <FilterLeadStrength />,
    <FilterLeadStatus />,
    <SearchDate />,
    <ButtonAction />,
    <FilterTaskAction />
]

export const sortLeadLowToHigh = (activeSortHeader, lead2, lead1) => {
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.LEAD_ID?.value) {
        return (Number(lead1?.leadId) - Number(lead2?.leadId))
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.USER?.value) {
        return lead1?.firstName?.toLowerCase()?.localeCompare(lead2?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.LEAD_TYPE?.value) {
        return lead1?.classFor?.toLowerCase()?.localeCompare(lead2?.classFor?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.CONTACT_TIME?.value) {
        return lead1?.contactTime?.toLowerCase()?.localeCompare(lead2?.contactTime?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.STATUS?.value) {
        return lead1?.status?.toLowerCase()?.localeCompare(lead2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.DATE?.value) {
        return dayjs(lead2?.createdAt).isSameOrBefore(lead1?.createdAt) ? 1 : -1
    }
}

export const sortLeadHighToLow = (activeSortHeader, lead1, lead2) => {
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.LEAD_ID?.value) {
        return (Number(lead2?.eventId) - Number(lead1?.eventId))
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.USER?.value) {
        return lead2?.firstName?.toLowerCase()?.localeCompare(lead1?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.EMAIL?.value) {
        return lead2?.email?.toLowerCase()?.localeCompare(lead1?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.MOBILE_NO?.value) {
        return lead2?.mobileNo?.toLowerCase()?.localeCompare(lead1?.mobileNo?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.LEAD_TYPE?.value) {
        return lead2?.classFor?.toLowerCase()?.localeCompare(lead1?.classFor?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.STATUS?.value) {
        return lead2?.status?.toLowerCase()?.localeCompare(lead1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === leadHeaderConst?.DATE?.value) {
        return dayjs(lead1?.createdAt).isSameOrBefore(lead2?.createdAt) ? 1 : -1
    }
}