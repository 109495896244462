import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import UserService from "redux/user/user.service";

import {
    setUserDetailLoading,
    setUserDetail,
    setUserDetailErrorMsg,

    setAddUserDetailLoading,
    setAddUserDetailErrorMsg,

    setAddNewUserDetailLoading,
    setAddNewUserDetailData,
    setAddNewUserDetailMessage,

    setUserCultureDetailLoading,
    setUserCultureDetail,
    setUserCultureDetailErrorMsg,

    setAddUserCultureDetailLoading,
    setAddUserCultureDetailErrorMsg,

    setUserEducationListLoading,
    setUserEducationList,
    setUserEducationListErrorMsg,

    setAddUserEducationDetailLoading,
    seAddUserEducationDetailErrorMsg,

    setUserOtherPrefLoading,
    setUserOtherPref,
    setUserOtherPrefErrorMsg,

    setAddUserOtherPrefLoading,
    setAddUserOtherPrefErrorMsg,


    setUserSocialMediaLinksLoading,
    setUserSocialMediaLinks,
    setUserSocialMediaLinksErrorMsg,

    setAddUserSocialMediaLinksLoading,
    setAddUserSocialMediaLinksErrorMsg,

    setUserAddressListLoading,
    setUserAddressList,
    setUserAddressListErrorMsg,

    setAddUserAddressDetailLoading,
    setAddUserAddressDetailErrorMsg
} from "redux/user/user.slice";
import { userRedux } from "redux/user/user.const";

import { setSessionLoggedIn } from 'redux/auth/auth.slice';

import { setUserLocals } from "redux/user/user.utils";

export const getMyProfile = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.myProfile?.requestName}`,
    async (_, { dispatch, rejectWithValue }) => {

        try {
            const response = await UserService.getMyProfile()
            if (response.status === 200) {
                await setUserLocals(response.data.data)
                dispatch(setSessionLoggedIn())
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const getUserList = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.userList?.requestName}`,
    async (query, { rejectWithValue }) => {

        try {
            const requestData = {
                query: query
            }
            const response = await UserService.getUserList(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.message)
            rejectWithValue(error.response.data.message || error.response.data.message)
        }
    }
)

export const getUserDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await UserService.getUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserDetailLoading(false))
    }
}

export const updateUserDetailByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await UserService.updateUserDetailByUserId(requestData)
        if (response.status === 200) {
            // dispatch(getUserDetailByUserId(userId))
            dispatch(setUserDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserDetailLoading(false))
    }
}

export const getUserCultureDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setUserCultureDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await UserService.getUserCultureDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserCultureDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUserCultureDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setUserCultureDetailLoading(false))
    }
}

export const updateUserCultureDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserCultureDetailLoading(true))

    try {
        const { userDetail } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await UserService.updateUserCultureDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserCultureDetail(response.data.data))
            dispatch(setUserDetail({
                ...userDetail?.userDetail,
                userCulture: response.data.data
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddUserCultureDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddUserCultureDetailLoading(false))
    }
}

export const getUserEducationListByUserId = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.userEducationList?.requestName}`,
    async (userId, { dispatch }) => {
        dispatch(setUserEducationListLoading(true))

        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await UserService.getUserEducationListByUserId(requestData)
            if (response.status === 200) {
                return response.data.data
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setUserEducationListErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setUserEducationListLoading(false))
        }
    }
)

export const createUserEducationDetailByUserId = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.userEducationList?.requestName}`,
    async (userEducationDetail, { dispatch, getState }) => {
        dispatch(setAddUserEducationDetailLoading(true))

        try {
            const { userDetail } = getState().user
            const requestData = {
                params: { userId: userEducationDetail?.userId },
                body: userEducationDetail?.body
            }
            const response = await UserService.createUserEducationDetailByUserId(requestData)
            if (response.status === 200) {
                dispatch(getUserEducationListByUserId(userDetail?.userDetail?.userId))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddUserCultureDetailErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddUserEducationDetailLoading(false))
        }
    }
)

export const updateEducationDetailByUserEducationId = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.userEducationList?.requestName}`,
    async (userEducationDetail, { dispatch, getState }) => {
        dispatch(setAddUserEducationDetailLoading(true))

        try {
            const { userDetail } = getState().user
            const requestData = {
                params: { userEducationId: userEducationDetail?.userEducationId },
                body: userEducationDetail?.body
            }
            const response = await UserService.updateEducationDetailByUserEducationId(requestData)
            if (response.status === 200) {
                dispatch(getUserEducationListByUserId(userDetail?.userDetail?.userId))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(seAddUserEducationDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
        } finally {
            dispatch(setAddUserEducationDetailLoading(false))
        }
    }
)

export const deleteEducationDetailByUserEducationId = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.userEducationList?.requestName}`,
    async (userEducationId, { dispatch, getState }) => {
        dispatch(setAddUserEducationDetailLoading(true))

        try {
            const { userEducationList } = getState().user
            const requestData = {
                params: { userEducationId: userEducationId },
            }
            const response = await UserService.deleteEducationDetailByUserEducationId(requestData)
            if (response.status === 200) {
                dispatch(setUserEducationList(
                    userEducationList?.userEducationList?.filter((userEducation) => (
                        userEducation.userEducationId !== userEducationId
                    ))
                ))
                toast.success(response.data.message)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(seAddUserEducationDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
        } finally {
            dispatch(setAddUserEducationDetailLoading(false))
        }
    }
)

export const getUserOtherPrefDetailByUserId = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.userOtherPrefDetail?.requestName}`,
    async (userId, { dispatch }) => {
        dispatch(setUserOtherPrefLoading(true))

        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await UserService.getUserOtherPrefDetailByUserId(requestData)
            if (response.status === 200) {
                dispatch(setUserOtherPref(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setUserOtherPrefErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setUserOtherPrefLoading(false))
        }
    }
)

export const updateUserOtherPrefDetailByUserId = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.userOtherPrefDetail?.requestName}`,
    async (userOtherPrefDetail, { dispatch }) => {
        dispatch(setAddUserOtherPrefLoading(true))

        try {
            const requestData = {
                params: { userId: userOtherPrefDetail?.userId },
                body: userOtherPrefDetail?.body
            }
            const response = await UserService?.updateUserOtherPrefDetailByUserId(requestData)
            if (response.status === 200) {
                dispatch(setUserOtherPref(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddUserOtherPrefErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setAddUserOtherPrefLoading(false))
        }
    }
)

// request for user social media presence
export const getUserSocialListByUserId = (userId) => async (dispatch) => {
    dispatch(setUserSocialMediaLinksLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await UserService.getUserSocialListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserSocialMediaLinks(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserSocialMediaLinksErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserSocialMediaLinksLoading(false))
    }
}

export const createUserSocialDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserSocialMediaLinksLoading(true))

    try {
        const { userSocialMediaLinks } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await UserService.createUserSocialDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserSocialMediaLinks([...userSocialMediaLinks?.userSocialMediaLinks, response.data.data]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserSocialMediaLinksErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserSocialMediaLinksLoading(false))
    }
}

export const updateSocialDetailByUserSocialId = (userSocialId, body) => async (dispatch, getState) => {
    dispatch(setAddUserSocialMediaLinksLoading(true))

    try {
        const { userSocialMediaLinks } = getState().user
        const requestData = {
            params: { userSocialId: userSocialId },
            body: body
        }
        const response = await UserService.updateSocialDetailByUserSocialId(requestData)
        if (response.status === 200) {
            const filteredList = userSocialMediaLinks?.userSocialMediaLinks?.filter((userSocial) => userSocial?.userSocialId !== userSocialId)
            dispatch(setUserSocialMediaLinks([...filteredList, response.data.data]))
            toast.success(response.data.message)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserSocialMediaLinksErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserSocialMediaLinksLoading(false))
    }
}

// request for user addresses
export const getUserAddressListByUserId = (userId) => async (dispatch) => {
    dispatch(setUserAddressListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await UserService.getUserAddressListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserAddressList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserAddressListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserAddressListLoading(false))
    }
}

export const createUserAddressDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserAddressDetailLoading(true))

    try {
        const { userAddressList } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await UserService.createUserAddressDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserAddressList([...userAddressList?.userAddressList, response.data.data]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserAddressDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserAddressDetailLoading(false))
    }
}

export const updateAddressDetailByUserAddressId = (userAddressId, body) => async (dispatch, getState) => {
    dispatch(setAddUserAddressDetailLoading(true))

    try {
        const { userAddressList } = getState().user
        const requestData = {
            params: { userAddressId: userAddressId },
            body: body
        }
        const response = await UserService.updateAddressDetailByUserAddressId(requestData)
        if (response.status === 200) {
            const filteredAddressList = userAddressList?.userAddressList?.filter((userAddress) => userAddress?.userAddressId !== userAddressId)
            dispatch(setUserAddressList([...filteredAddressList, response.data.data]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserAddressDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserAddressDetailLoading(false))
    }
}

export const deleteAddressDetailByUserAddressId = (userAddressId, body) => async (dispatch, getState) => {
    dispatch(setAddUserAddressDetailLoading(true))

    try {
        const { userAddressList } = getState().user
        const requestData = {
            params: { userAddressId: userAddressId },
            body: body
        }
        const response = await UserService.deleteAddressDetailByUserAddressId(requestData)
        if (response.status === 200) {
            const filteredUserAddressList = userAddressList?.userAddressList?.filter((userAddress) => userAddress?.userAddressId !== userAddressId)
            dispatch(setUserAddressList(filteredUserAddressList))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserAddressDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserAddressDetailLoading(false))
    }
}

// request for create user detail
export const createUserDetail = createAsyncThunk(
    `${userRedux?.sliceName}/${userRedux?.createUser?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddNewUserDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await UserService.createUserDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddNewUserDetailData(response.data.data))
                toast.success(response.data.message)
                dispatch(getUserList({ page: 1, records: 25 }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddNewUserDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddNewUserDetailLoading(false))
        }
    }
)