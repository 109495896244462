import { memo, useState } from 'react';
import { toast } from 'react-toastify';

import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdOutlineDragIndicator } from "react-icons/md";
import { IoIosCheckmark } from 'react-icons/io';
import { FaSpinner } from 'react-icons/fa';

import Switch from '@mui/material/Switch';

import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';
import InputTextSwitch from 'components/InputTextSwitch/inputTextSwitch';
import ToolTipView from 'components/toolTip/index';
import FloatingLabelSelect from 'components/floating/floatingLabelSelect';

import { menuItemsConst } from 'pages/auth/editUser/data';

import linkTreeService from 'redux/linkTree/linkTree.service';

import { useAppState } from 'hooks/useStore';

import { cn } from 'utils/cn.utils';
import { debounce } from 'utils/generators.utils';

const LinkTreeCard = memo(({ treeItem, linkItem, setLinkList }) => {
    const { socialList } = useAppState((s) => s.master)

    const [modifyLinkDetail, setModifyLinkDetail] = useState({
        isLoading: false,
        data: linkItem,
        message: null
    });

    const onHandleChangeLinkDetail = (key, value) => {
        if (!value) return;

        setModifyLinkDetail((prevState) => ({
            ...prevState,
            data: {
                ...prevState?.data,
                [key]: value
            }
        }));
        const delayFunction = debounce.debounce(onHandleSubmitLinkDetail)
        delayFunction(key, value)
    };

    const onHandleUpdateLinkDetail = (key, value) => {
        setModifyLinkDetail((prevState) => ({
            ...prevState,
            data: {
                ...prevState?.data,
                [key]: value
            }
        }));
        onHandleSubmitLinkDetail(key, value)
    };

    const onHandleSelectSocial = (key, value) => {
        const social = socialList?.data?.find(social => social?.key === value)
        setModifyLinkDetail((prevState) => ({
            ...prevState,
            data: {
                ...prevState?.data,
                social: social
            },
        }));
        onHandleSubmitLinkDetail(key, value)
    }

    const onHandleSelectMenuOption = async (option) => {
        if (option?.value === menuItemsConst?.DELETE?.value) {
            setModifyLinkDetail((prevState) => ({ ...prevState, isLoading: true }))

            try {
                const requestData = {
                    params: { linkId: linkItem?.id }
                }
                const response = await linkTreeService.deleteLink(requestData)
                if (response.status === 200) {
                    setLinkList((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState?.data,
                            result: prevState?.data?.result?.map((item) => (item?.id === treeItem?.id)
                                ? { ...item, links: item?.links?.filter((currentLink) => currentLink?.id !== linkItem?.id) }
                                : item
                            )
                        }
                    }))
                    toast.success(response?.data?.data?.message)
                } else {
                    throw new Error(response)
                }
            } catch (error) {
                console.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
                setModifyLinkDetail((prevState) => ({ ...prevState, message: error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!' }))
                toast.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
            } finally {
                setModifyLinkDetail((prevState) => ({ ...prevState, isLoading: false }))
            }
        }
    }

    const onHandleSubmitLinkDetail = async (key, value) => {
        setModifyLinkDetail((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { linkId: linkItem?.id },
                body: { [key]: value }
            }
            const response = await linkTreeService.updateLink(requestData)
            if (response.status === 200) {
                setLinkList((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState?.data,
                        result: prevState?.data?.result?.map((item) => (item?.id === treeItem?.id)
                            ? {
                                ...item, links: item?.links?.map((currentLink) => {
                                    if (currentLink?.id === linkItem?.id) {
                                        return response?.data?.data?.result
                                    } else return currentLink;
                                })
                            }
                            : item
                        )
                    }
                }))
                // setModifyLinkDetail((prevState) => ({ ...prevState, data: { ...prevState?.data, [key]: value } }))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
            setModifyLinkDetail((prevState) => ({ ...prevState, message: error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!' }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
        } finally {
            setModifyLinkDetail((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    return (
        <div className='bg-white w-full flex items-center gap-3 rounded-lg border p-3'>
            <div className='w-fit'>
                <MdOutlineDragIndicator className='text-xl hover:text-text-900 text-text-700 cursor-move' />
            </div>
            <div className='w-full flex flex-col gap-0.5'>
                <div className='w-full flex items-center'>
                    <FloatingLabelSelect
                        labelItem={"social"}
                        options={socialList?.data?.map((socialContent) => ({
                            label: socialContent?.name,
                            value: socialContent?.key,
                            picture_url: socialContent?.picture_url,
                            color: socialContent?.color
                        })) || []}
                        customBtnStyle={"border-transparent hover:border-transparent hover:shadow"}
                        dropdownWrapperClassName={"w-60"}
                        dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                        searchable={true}
                        showLabel={false}
                        onHandleSelect={(option) => onHandleSelectSocial("social_key", option.value)}
                        value={<img src={modifyLinkDetail?.data?.social?.picture_url} className='w-5 h-5 aspect-square' />}
                        OptionChild={({ option }) => (
                            <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                                <div className={"flex items-center gap-1"}>
                                    <div className={"w-6 h-6 overflow-hidden rounded-full"}>
                                        <img
                                            src={option?.picture_url}
                                            className={`mr-1 ${option?.color} w-full h-full object-cover`}
                                            alt={option?.value}
                                        />
                                    </div>
                                    {option?.label}
                                </div>
                                {option?.value === modifyLinkDetail?.data?.social?.key && <IoIosCheckmark size={20} color="green" />}
                            </div>
                        )}
                    />
                    <InputTextSwitch
                        textStyle={"text-text-900 font-medium line-clamp-1"}
                        placeholder="Link Title"
                        text={<p>{(modifyLinkDetail?.data?.title?.length === 0) ? "Add link title" : modifyLinkDetail?.data?.title}</p>}
                        value={modifyLinkDetail?.data?.title}
                        onChange={(e) => onHandleChangeLinkDetail("title", e.target.value)}
                    />
                </div>
                <InputTextSwitch
                    textStyle={cn(
                        "text-text-900 text-sm font-medium line-clamp-1",
                        (modifyLinkDetail?.data?.link?.length === 0) ? "text-text-500" : ""
                    )}
                    placeholder={"Add URL"}
                    text={<p>{(modifyLinkDetail?.data?.link?.length === 0) ? "Add URL" : modifyLinkDetail?.data?.link}</p>}
                    value={modifyLinkDetail?.data?.link}
                    onChange={(e) => onHandleChangeLinkDetail("link", e.target.value)}
                />
            </div>
            <div className='w-20 flex flex-col items-end justify-end gap-1'>
                <div className={"flex items-center justify-start gap-1.5"}>
                    <ThreeDotMenu
                        buttonLabel={<BsThreeDotsVertical className="hover:text-secondary-dark" />}
                        menuItems={Object.values(menuItemsConst)}
                        onHandleSelect={(option) => onHandleSelectMenuOption(option)}
                    />
                    {(modifyLinkDetail?.isLoading && (modifyLinkDetail?.data?.id === linkItem?.id)) &&
                        <FaSpinner className={"text-xl text-primary-dark animate-spin"} />
                    }
                </div>

                <ToolTipView content={modifyLinkDetail?.active ? "Show" : "Hide"}>
                    <Switch checked={modifyLinkDetail?.active} onChange={() => onHandleUpdateLinkDetail("active", !modifyLinkDetail?.active)} />
                </ToolTipView>
            </div>
        </div>
    )
})

export default LinkTreeCard;

export const LinkTreeCardLoader = () => {
    return (
        <div className='bg-white w-full flex items-center gap-5 rounded-lg border p-3'>
            <div className='h-10 w-5 rounded animate-pulse bg-slate-200'></div>
            <div className='w-full flex flex-col gap-3'>
                <div className='flex gap-5 w-full'>
                    <div className='h-8 w-8 rounded-full animate-pulse bg-slate-200'></div>
                    <div className='h-8 w-full rounded animate-pulse bg-slate-200'></div>
                </div>
                <div className='h-6 w-full rounded animate-pulse bg-slate-200'></div>
            </div>
            <div className='w-20 flex flex-col items-end gap-2'>
                <div className='h-6 w-6 rounded-full animate-pulse bg-slate-200'></div>
                <div className='h-6 w-8 rounded-full animate-pulse bg-slate-200'></div>
            </div>
        </div>
    )
}
