export const url = {
    GET_ORDER_LIST: "/orders/orders",
    GET_ORDER_DETAIL: "/orders/orders/{orderId}",
}

export const orderTypeEnum = {
    PRODUCT: {
        label: "Product",
        value: "product"
    }
}

export const orderStatusEnum = {
    // ALL: {
    //     value: "all",
    //     label: "All",
    //     lightColor: 'orange-100',
    //     darkColor: 'orange-500',
    // },
    PENDING: {
        value: "pending",
        label: "Pending",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PROCESSING: {
        value: "processing",
        label: "Processing",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    CONFIRMED: {
        value: "confirmed",
        label: "Confirmed",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    // COMPLETED: {
    //     value: "completed",
    //     label: "Completed",
    //     lightColor: 'green-100',
    //     darkColor: 'green-500',
    // },
    REFUNDED: {
        value: "refunded",
        label: "Refunded",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    FAILED: {
        value: "failed",
        label: "Failed",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    CANCELLED: {
        value: "cancelled",
        label: "Cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const isOrderPaidEnum = {
    PAID: {
        label: "Paid",
        value: "paid"
    },
    UNPAID: {
        label: "Un Paid",
        value: "unpaid"
    }
}

export const orderDetailPayload = {
    orderId: null
}

export const orderFilterProps = {
    searchText: "",
    orderId: "",
    userName: "",
    orderType: "",
    orderAmount: "",
    isPaid: "",
    orderStatus: "",
    date: ""
}