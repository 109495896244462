import { cn } from "utils/cn.utils";

import {
    Container,
    Content,
    ListItems,
    ListItem,
    ItemIcon,
    ItemName,
    // AdminIcon,
    // SuperAdminIcon,
    RoleSwitchIcon
} from './SideNavBarStyle';

import SideBarItem from './SideBarItem';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';

import { navLinkList } from 'utils/sideAndTopNavLink.utils';

const SideNavBar = ({ visible = true }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal, locals } = useAppState((state) => state.local)
    const pageName = currentPageInfo.pageName;

    const rotateIconVariants = { student: { rotate: 0 }, teacher: { rotate: 180 } }

    const containerVariants = { close: { maxWidth: '6.5rem' }, open: { maxWidth: '14rem' } }
    const itemNameVariants = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }
    const dispatcher = useAppDispatcher()

    const handleRoleSwitch = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
        }))
    }

    return (
        <Container pageName={pageName}
            variants={containerVariants}
            initial={'open'}
            animate={visible ? 'open' : 'close'}
            transition={transition}
        >
            <Content>
                <ListItems>
                    <ListItem
                        className={"relative has-tooltip"}
                        onClick={handleRoleSwitch}
                    >
                        <ItemIcon>
                            <RoleSwitchIcon
                                variants={rotateIconVariants}
                                initial={userRoles.ADMIN.value}
                                animate={(locals.userRole === userRoles.SUPER_ADMIN.value) ? userRoles.SUPER_ADMIN.value : userRoles.ADMIN.value}
                                transition={transition}
                            />
                            {/* {locals.userRole === userRoles.ADMIN.value && <AdminIcon />}
                            {locals.userRole === userRoles.SUPER_ADMIN.value && <SuperAdminIcon />} */}
                        </ItemIcon>
                        {locals &&
                            <ItemName
                                variants={itemNameVariants}
                                initial={'open'}
                                animate={visible ? 'open' : 'close'}
                                transition={transition}
                            >
                                {userRoles[locals.userRole.toUpperCase()].label}
                            </ItemName>
                        }
                        {!visible &&
                            <span
                                className={cn(
                                    "w-fit px-2 py-1 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip",
                                    "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap translate-y-8",
                                    visible && "translate-x-[80%]"
                                )}
                            >
                                {"Switch Your Role"}
                            </span>
                        }
                    </ListItem>
                    {navLinkList.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && (
                        <SideBarItem key={index} dataItem={dataItem} visible={visible} />
                    ))}
                </ListItems>
            </Content>
        </Container>
    )
}

export default SideNavBar;
