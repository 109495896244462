import { createSlice } from "@reduxjs/toolkit";

import { USER_BADGE_INITIAL_STATE } from "redux/badge/badge.initialState";

import { getBadgeList, getUserBadgeListByUserId } from "./badge.request";


const badge = createSlice({
    name: "badge",
    initialState: USER_BADGE_INITIAL_STATE,
    reducers: {
        setClearBadgeList: (state) => {
            state.getBadgeList = USER_BADGE_INITIAL_STATE.getBadgeList
        },

        setUserBadgeList: (state, { payload }) => {
            state.userBadgeList.userBadgeList = payload
            state.userBadgeList.filteredUserBadgeList = payload
            state.userBadgeList.errorMsg = USER_BADGE_INITIAL_STATE.userBadgeList.errorMsg
        },
        setClearUserBadgeList: (state) => {
            state.userBadgeList = USER_BADGE_INITIAL_STATE.userBadgeList
        },

        // reducers for add user badge detail
        setAddUserBadgeDetailLoading: (state, { payload }) => {
            state.addUserBadgeDetail.isLoading = payload
        },
        setAddUserBadgeDetail: (state, { payload }) => {
            state.addUserBadgeDetail.addUserBadgeDetail = payload
            state.addUserBadgeDetail.errorMsg = USER_BADGE_INITIAL_STATE.addUserBadgeDetail.errorMsg
        },
        setAddUserBadgeDetailErrorMsg: (state, { payload }) => {
            state.addUserBadgeDetail.errorMsg = payload
            state.addUserBadgeDetail.addUserBadgeDetail = USER_BADGE_INITIAL_STATE.addUserBadgeDetail.addUserBadgeDetail
        },
        setClearAddUserBadgeDetail: (state) => {
            state.addUserBadgeDetail = USER_BADGE_INITIAL_STATE.addUserBadgeDetail
        }
    },
    extraReducers: {
        //extra reducers for badge list
        [getBadgeList.pending]: (state) => {
            state.badgeList.isLoading = true
        },
        [getBadgeList.fulfilled]: (state, { payload }) => {
            state.badgeList.isLoading = false
            state.badgeList.badgeList = payload
            state.badgeList.filteredBadgeList = payload
            state.badgeList.errorMsg = USER_BADGE_INITIAL_STATE.badgeList.errorMsg
        },
        [getBadgeList.rejected]: (state, { payload }) => {
            state.badgeList.isLoading = false
            state.badgeList.errorMsg = payload
            state.badgeList.badgeList = USER_BADGE_INITIAL_STATE.badgeList.badgeList
            state.badgeList.filteredBadgeList = USER_BADGE_INITIAL_STATE.badgeList.filteredBadgeList
        },

        // extra-reducers for user badge list
        [getUserBadgeListByUserId.pending]: (state) => {
            state.userBadgeList.isLoading = true
        },
        [getUserBadgeListByUserId.fulfilled]: (state, { payload }) => {
            state.userBadgeList.isLoading = false
            state.userBadgeList.userBadgeList = payload
            state.userBadgeList.filteredUserBadgeList = payload
            state.userBadgeList.errorMsg = USER_BADGE_INITIAL_STATE.userBadgeList.errorMsg
        },
        [getUserBadgeListByUserId.rejected]: (state, { payload }) => {
            state.userBadgeList.isLoading = false
            state.userBadgeList.errorMsg = payload
            state.userBadgeList.userBadgeList = USER_BADGE_INITIAL_STATE.userBadgeList.userBadgeList
            state.userBadgeList.filteredUserBadgeList = USER_BADGE_INITIAL_STATE.userBadgeList.filteredUserBadgeList
        }
    }
})

export const {
    setClearBadgeList,

    setUserBadgeList,
    setClearUserBadgeList,

    setAddUserBadgeDetailLoading,
    setAddUserBadgeDetail,
    setAddUserBadgeDetailErrorMsg,
    setClearAddUserBadgeDetail
} = badge.actions

export default badge.reducer;