import { Container } from "pages/global/home/homeHero/HomeHeroStyle"

const HOME_HERO_IMAGE = 'https://edulyte-website-image.s3.ap-south-1.amazonaws.com/newBackgnd.png';

const HomeHero = () => {
    return (
        <Container>
            <img
                src={HOME_HERO_IMAGE}
                alt={"home-hero"}
            />
        </Container>
    )
}

export default HomeHero