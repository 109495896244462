import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";

class LmsQuestionService {
    getLmsQuestionList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.GET_LMS_QUESTION_LIST,
            { params: query }
        )

        return response;
    }

    deleteLmsQuestionDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUESTION_DETAIL, params)
        )

        return response;
    }
}

export default new LmsQuestionService();