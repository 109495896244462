import { useEffect, useReducer } from "react";
import { cn } from "utils/cn.utils";

import SaveAndCancelBtn from 'components/buttons/SaveAndCancelBtn';
import SearchSelector from 'components/searchSelector/SearchSelector';
import ComponentLoader from 'components/loader/ComponentLoader';

import { courseConfigActionType, courseConfigReducer } from "pages/auth/editCourseDetail/data";

import { updateCourseConfigDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';

const CourseConfiguration = () => {
    const { courseTutorList, courseConfigDetail, updateCourseConfig } = useAppState((state) => state.course)


    const dispatcher = useAppDispatcher()

    const [state, dispatch] = useReducer(courseConfigReducer, {
        fixedAdminCommissionPct: courseConfigDetail?.data?.fixedAdminCommissionPct || 0,
        variableAdminCommissionPct: courseConfigDetail?.data?.variableAdminCommissionPct || 0,
        ownerCourseTutorId: courseConfigDetail?.data?.ownerCourseTutor?.id || 0,
        defaultVisibleCourseTutorId: courseConfigDetail?.data?.defaultVisibleCourseTutor?.id || 0,
        defaultFeeCourseTutorId: courseConfigDetail?.data?.defaultFeeCourseTutor?.id || 0
    })

    const ownerCourseTutorLabel = courseTutorList?.data?.filter((courseTutor) => courseTutor?.id === state.ownerCourseTutorId)
    const defaultVisibleCourseTutorLabel = courseTutorList?.data?.filter((courseTutor) => courseTutor?.id === state.defaultVisibleCourseTutorId)
    const defaultFeeCourseTutorLabel = courseTutorList?.data?.filter((courseTutor) => courseTutor?.id === state.defaultFeeCourseTutorId)

    const courseConfigDefaultData = {
        fixedAdminCommissionPct: courseConfigDetail?.data?.fixedAdminCommissionPct,
        variableAdminCommissionPct: courseConfigDetail?.data?.variableAdminCommissionPct,
        ownerCourseTutorId: courseConfigDetail?.data?.ownerCourseTutor?.id,
        defaultVisibleCourseTutorId: courseConfigDetail?.data?.defaultVisibleCourseTutor?.id,
        defaultFeeCourseTutorId: courseConfigDetail?.data?.defaultFeeCourseTutor?.id
    }
    const courseConfigPayload = {
        fixedAdminCommissionPct: state?.fixedAdminCommissionPct,
        variableAdminCommissionPct: state?.variableAdminCommissionPct,
        ownerCourseTutorId: state?.ownerCourseTutorId,
        defaultVisibleCourseTutorId: state?.defaultVisibleCourseTutorId,
        defaultFeeCourseTutorId: state?.defaultFeeCourseTutorId
    }

    const isCourseConfigSimilar = JSON.stringify(courseConfigPayload) === JSON.stringify(courseConfigDefaultData)


    useEffect(() => {
        if (courseConfigDetail?.data) {
            onHandleResetConfig()
        }
    }, [courseConfigDetail?.data])

    const onHandleUpdateTutorConfig = (key, value) => {
        dispatch({
            type: key,
            payload: value
        })
    }

    const onHandleSave = () => {
        if (courseTutorList?.isLoading || courseConfigDetail?.isLoading || updateCourseConfig?.isLoading) return;
        const body = {
            fixedAdminCommissionPct: state.fixedAdminCommissionPct || 0,
            variableAdminCommissionPct: state.variableAdminCommissionPct || 0
        }

        // if (state.fixedAdminCommissionPct) {
        //     body["fixedAdminCommissionPct"] = state.fixedAdminCommissionPct
        // }
        // if (state.variableAdminCommissionPct) {
        //     body["variableAdminCommissionPct"] = state.variableAdminCommissionPct
        // }
        if (state.ownerCourseTutorId) {
            body["ownerCourseTutorId"] = state.ownerCourseTutorId
        }
        if (state.defaultVisibleCourseTutorId) {
            body["defaultVisibleCourseTutorId"] = state.defaultVisibleCourseTutorId
        }
        if (state.defaultFeeCourseTutorId) {
            body["defaultFeeCourseTutorId"] = state.defaultFeeCourseTutorId
        }

        const requestDataPayload = {
            courseConfigId: courseConfigDetail?.data?.id,
            body: body
        }
        dispatcher(updateCourseConfigDetail(requestDataPayload))
    }

    const onHandleResetConfig = () => {
        dispatch({
            type: courseConfigActionType?.FIXED_ADMIN_COMMISSION_PCT?.value,
            payload: courseConfigDetail?.data?.fixedAdminCommissionPct
        })
        dispatch({
            type: courseConfigActionType?.VARIABLE_ADMIN_COMMISSION_PCT?.value,
            payload: courseConfigDetail?.data?.variableAdminCommissionPct
        })
        dispatch({
            type: courseConfigActionType?.OWNER_COURSE_TUTOR_ID?.value,
            payload: courseConfigDetail?.data?.ownerCourseTutor?.id
        })
        dispatch({
            type: courseConfigActionType?.DEFAULT_VISIBLE_OWNER_COURSE_TUTOR_ID?.value,
            payload: courseConfigDetail?.data?.defaultVisibleCourseTutor?.id
        })
        dispatch({
            type: courseConfigActionType?.DEFAULT_FEE_COURSE_TUTOR_ID?.value,
            payload: courseConfigDetail?.data?.defaultFeeCourseTutor?.id
        })
    }

    return (
        <div className={"w-full p-5 space-y-5"}>
            {(courseConfigDetail?.isLoading || updateCourseConfig?.isLoading) &&
                <ComponentLoader isLoading={courseConfigDetail?.isLoading || updateCourseConfig?.isLoading} />}
            {((!courseConfigDetail?.isLoading && !updateCourseConfig?.isLoading) && courseConfigDetail?.message) &&
                <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                    {courseConfigDetail?.message}
                </div>
            }
            {((!courseConfigDetail?.isLoading && !updateCourseConfig?.isLoading) && courseConfigDetail?.data) &&
                <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                            {"Fixed Admin Commission (in %)"}
                        </span>
                        <input
                            type={"number"}
                            value={state?.fixedAdminCommissionPct?.toString()}
                            className={cn(
                                "w-full md:w-72 px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            onChange={(e) => onHandleUpdateTutorConfig(courseConfigActionType?.FIXED_ADMIN_COMMISSION_PCT?.value, Number(e.target.value))}
                        />
                    </div>

                    {/* variable admin  commission pct */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                            {"Variable Admin Commission (in %)"}
                        </span>
                        <input
                            type={"number"}
                            value={state?.variableAdminCommissionPct?.toString()}
                            className={cn(
                                "w-full md:w-72 px-3 py-2 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            onChange={(e) => onHandleUpdateTutorConfig(courseConfigActionType?.VARIABLE_ADMIN_COMMISSION_PCT?.value, Number(e.target.value))}
                        />
                    </div>

                    {/* owner course tutor id container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                            {"Owner"}
                        </span>

                        <SearchSelector
                            isLoading={courseTutorList?.isLoading}
                            options={courseTutorList?.data?.map((courseTutor) => ({
                                label: `${courseTutor?.tutor?.user?.firstName} ${courseTutor?.tutor?.user?.lastName?.charAt(0)}`,
                                value: courseTutor?.id
                            })) || []}
                            placeholder={"select Owner"}
                            showGroupedOptions={true}
                            selectedValue={{ label: ownerCourseTutorLabel?.length > 0 ? `${ownerCourseTutorLabel[0]?.tutor?.user?.firstName} ${ownerCourseTutorLabel[0]?.tutor?.user?.lastName?.charAt(0)}` : "", value: state?.ownerCourseTutorId }}
                            onHandleSelect={(option) => onHandleUpdateTutorConfig(courseConfigActionType?.OWNER_COURSE_TUTOR_ID?.value, option?.value)}
                        />
                    </div>

                    {/* default visible course tutor id container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                            {"Default Tutor"}
                        </span>
                        <SearchSelector
                            isLoading={courseTutorList?.isLoading}
                            options={courseTutorList?.data?.map((courseTutor) => ({
                                label: `${courseTutor?.tutor?.user?.firstName} ${courseTutor?.tutor?.user?.lastName?.charAt(0)}`,
                                value: courseTutor?.id
                            })) || []}
                            placeholder={"select Default Tutor"}
                            showGroupedOptions={true}
                            selectedValue={{ label: defaultVisibleCourseTutorLabel?.length > 0 ? `${defaultVisibleCourseTutorLabel[0]?.tutor?.user?.firstName} ${defaultVisibleCourseTutorLabel[0]?.tutor?.user?.lastName?.charAt(0)}` : "" || "", value: state?.defaultVisibleCourseTutorId }}
                            onHandleSelect={(option) => onHandleUpdateTutorConfig(courseConfigActionType?.DEFAULT_VISIBLE_OWNER_COURSE_TUTOR_ID?.value, option?.value)}
                        />
                    </div>

                    {/* default fee course tutor id container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                            {"Payout(s)"}
                        </span>
                        <SearchSelector
                            isLoading={courseTutorList?.isLoading}
                            options={courseTutorList?.data?.map((courseTutor) => ({
                                label: `${courseTutor?.tutor?.user?.firstName} ${courseTutor?.tutor?.user?.lastName?.charAt(0)}`,
                                value: courseTutor?.id
                            })) || []}
                            placeholder={"select Course Tutor"}
                            showGroupedOptions={true}
                            selectedValue={{ label: defaultFeeCourseTutorLabel?.length > 0 ? `${defaultFeeCourseTutorLabel[0]?.tutor?.user?.firstName} ${defaultFeeCourseTutorLabel[0]?.tutor?.user?.lastName?.charAt(0)}` : "", value: state?.defaultFeeCourseTutorId }}
                            onHandleSelect={(option) => onHandleUpdateTutorConfig(courseConfigActionType?.DEFAULT_FEE_COURSE_TUTOR_ID?.value, option?.value)}
                        />
                    </div>

                    <div className={"w-full md:w-72 flex items-center justify-center gap-5 pt-5"}>
                        <SaveAndCancelBtn
                            isShowBtn={true}
                            isDisabled={isCourseConfigSimilar}
                            onHandleSave={onHandleSave}
                            onHandleCancel={onHandleResetConfig}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default CourseConfiguration;