import { BsFillStarFill } from "react-icons/bs";

import { dayjs } from "utils/dateTime.utils";

const ReviewCard = (props) => {

    return (
        <div className="h-fit w-full col-span-1 bg-gray-100 p-5 space-y-3 rounded-lg">
            <div className="flex gap-3">
                <BsFillStarFill size={20} color="#FDCC0D" />
                <span>{props.rating}</span>
                <span className="font-bodyPri font-normal text-text-700 text-base">
                    {"TESTIMONIAL"}
                </span>
            </div>

            <div className="font-bodyPri font-normal text-text-800 text-base italic">
                {props.content}
            </div>
            <div className="flex items-center gap-3">
                <div className="w-10 h-10">
                    <img
                        src={props.profileImg}
                        alt="profileImg"
                        className="w-full h-full rounded-full object-cover" />
                </div>
                <div className="w-full">
                    <div className="flex gap-1 font-bodyPri font-medium text-base text-text-700">
                        <span>{props.fName}</span>
                        <span>{props.lName}</span>
                    </div>
                    <div className={"w-full flex items-center justify-between gap-3"}>
                        <span className="font-bodyPri font-normal text-text-600 text-sm">
                            {props?.lessonCount + " " + "Lessons"}
                        </span>
                        <span className="text-text-600 text-sm">
                            {dayjs(props.createdAt).local().format("MMM D, YY HH:MM A").toString()}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard;