import { useState } from 'react';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cn } from "utils/cn.utils";

import { GrAddCircle } from "react-icons/gr";

import ScheduleTimeRange from 'pages/auth/editCourseDetail/components/confirmSchedulePage/commonComponents/ScheduleTimeRange';

import { useAppState } from 'hooks/useStore';
import { courseType } from 'redux/course/course.const';

import {
    getTimeSlotWithDuration,
    getGroupTimeSlotWithDuration,
    validateIsStartBeforeEnd,
    validateIsTimeSlotExist
} from 'utils/availability.utils';
import { dayjs, timeZone } from 'utils/dateTime.utils';
import { sortDateTimeSlots, sortTimeSlots } from 'pages/auth/editCourseDetail/data';

const DoesNotRepeatScheduleCard = ({
    dateTimeSlots,
    dateTimeSlot,
    saveDateTimeSlots
}) => {
    const { addAvailabilityDetail, modifyAvailabilityDetail } = useAppState((state) => state.availability)
    const { courseDetail, addCourseAvailDetail } = useAppState((state) => state.course)

    const todayDate = new Date()
    const dateAfterSevenDays = todayDate.setDate(todayDate.getDate() + 7)

    const localDay = dayjs(dateTimeSlot?.date)?.format('dddd')

    const [selectedTimeSlot, setSelectedTimeSlot] = useState({
        startTime: dayjs().tz(timeZone).format('09:[00:00]'),
        endTime: dayjs().tz(timeZone).format('09:[30:00]')
    });

    const onHandleAddTimeSlot = async () => {
        if (addAvailabilityDetail?.isLoading || modifyAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;

        // if (isGroupCourseType && underReviewOrPublishedStatus) return;

        let newSelectedTimeSlot = selectedTimeSlot
        if (!dateTimeSlot || dateTimeSlot?.timeSlots?.length === 0) {
            let startEndTimeSlot = selectedTimeSlot
            const totalTimeSlots = 0
            newSelectedTimeSlot = getTimeSlotWithDuration({ timeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15, totalTimeSlots })
        }

        if (dateTimeSlot?.timeSlots?.length > 0) {
            let startEndTimeSlot = dateTimeSlot?.timeSlots?.slice()?.sort(sortTimeSlots)?.slice(-1)[0]
            const totalTimeSlots = dateTimeSlot?.timeSlots?.length
            newSelectedTimeSlot = getTimeSlotWithDuration({ timeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15, totalTimeSlots })
        }

        if (!validateIsStartBeforeEnd({ timeZone, localDay, selectedTimeSlot: newSelectedTimeSlot })) {
            alert("End time should be after the start time!")
            return;
        }

        const timeSlots = dateTimeSlot?.timeSlots
        if (validateIsTimeSlotExist({ timeZone, localDay, selectedTimeSlot: newSelectedTimeSlot, timeSlots })) {
            alert("Slot is already exist!")
            return;
        }

        let dateTime = []
        if (dateTimeSlots?.length > 0) {
            dateTime = dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTime) => (
                dateTime?.date === dateTimeSlot?.date ? { ...dateTime, timeSlots: [...timeSlots, newSelectedTimeSlot] } : dateTime
            ))
        } else {
            dateTime?.push({ date: dateTimeSlot?.date, timeSlots: [newSelectedTimeSlot] })
        }

        let newAvailSlots = [...dateTime]
        await saveDateTimeSlots(newAvailSlots)
    }

    const updateTimeSlot = async (oldTimeSlot, updatedTimeSlot) => {
        if (addAvailabilityDetail?.isLoading || modifyAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;
        // if (isGroupCourseType && underReviewOrPublishedStatus) return;

        let newTimeSlot = updatedTimeSlot
        if (courseDetail?.courseDetail?.type === courseType?.GROUP?.value) {
            newTimeSlot = getGroupTimeSlotWithDuration({ timeZone, localDay, timeSlot: updatedTimeSlot, sessionDuration: addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15, totalTimeSlots: 1 })
        }

        if (!validateIsStartBeforeEnd({ timeZone, localDay, selectedTimeSlot: newTimeSlot })) {
            alert("End time should be after the start time!")
            return;
        }

        let timeSlots = dateTimeSlot?.timeSlots.filter(slot => oldTimeSlot.startTime !== slot.startTime)
        if (validateIsTimeSlotExist({ timeZone, localDay, selectedTimeSlot: newTimeSlot, timeSlots })) {
            alert("Slot already exist!")
            return;
        }

        timeSlots = dateTimeSlot?.timeSlots?.slice()?.sort(sortTimeSlots)?.map(slot => (
            oldTimeSlot.startTime === slot.startTime && oldTimeSlot.endTime === slot.endTime ? newTimeSlot : slot
        ))

        let dateTime = dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTime) => (
            dateTime?.date === dateTimeSlot?.date ? { ...dateTime, timeSlots } : dateTime
        ))

        let newAvailSlots = [...dateTime]
        await saveDateTimeSlots(newAvailSlots)
    }

    const onHandleDeleteTimeSlot = async (selectedTimeSlot) => {
        if (addAvailabilityDetail?.isLoading || modifyAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;
        // if (isGroupCourseType && underReviewOrPublishedStatus) return;

        const timeSlots = dateTimeSlot?.timeSlots?.slice()?.sort(sortDateTimeSlots)?.filter(slot => slot.startTime !== selectedTimeSlot.startTime)

        let dateTime = dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTime) => (
            dateTime?.date === dateTimeSlot?.date ? { ...dateTime, timeSlots } : dateTime
        ))
        let newAvailSlots = [...dateTime]
        await saveDateTimeSlots(newAvailSlots)

    }

    const onHandleUpdateDate = async (date) => {
        // if (isGroupCourseType && underReviewOrPublishedStatus) return;

        const filteredDateTimeSlots = addCourseAvailDetail?.addCourseAvailDetailPayload?.dateTimeSlots?.filter((dateTimeSlot) => dateTimeSlot?.date === dayjs(date)?.format("YYYY-MM-DD"))
        if (filteredDateTimeSlots?.length > 0) {
            toast.warn("Same dates are added more than once!")
            return;
        }

        const updatedDate = dayjs(date)?.tz(timeZone)?.format("YYYY-MM-DD")

        let dateTime = dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTime) => (
            dateTime?.date === dateTimeSlot?.date ? { ...dateTime, date: updatedDate } : dateTime
        ))

        let newAvailSlots = [...dateTime]

        await saveDateTimeSlots(newAvailSlots)
    }

    return (
        <div className={"grid grid-cols-4 w-full gap-5"}>
            <div className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-1 mt-1"}>
                <DatePicker
                    className={cn(
                        "w-fit px-3 py-1 flex items-center justify-start border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm focus:outline-none"
                    )}
                    dateFormat={"dd/MM/yyyy"}
                    value={dayjs(dateTimeSlot?.date)?.toDate()}
                    selected={dayjs(dateTimeSlot?.date)?.toDate()}
                    minDate={dateAfterSevenDays}
                    placeholderText={"Select Date"}
                    scrollableYearDropdown={true}
                    showYearDropdown={true}
                    yearDropdownItemNumber={80}
                    showMonthDropdown={true}
                    onChange={(date) => onHandleUpdateDate(date)}
                />
            </div>

            {dateTimeSlot?.timeSlots?.length > 0 &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 space-y-3"}>
                    {dateTimeSlot?.timeSlots?.slice()?.sort(sortTimeSlots)?.map((timeSlot, index) => (
                        <ScheduleTimeRange
                            key={index}
                            timeSlot={timeSlot}
                            updateTimeSlot={updateTimeSlot}
                            deleteTimeSlot={onHandleDeleteTimeSlot}
                        />
                    ))}
                </div>
            }

            {(!dateTimeSlot || dateTimeSlot?.timeSlots?.length === 0) &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 flex items-center justify-center"}>
                    <div className={"w-full p-1 font-bodyPri font-bold text-base text-text-800 tracking-wide text-center"}>
                        {"-"}
                    </div>
                </div>
            }

            <div className={"col-start-4 col-span-full sm:col-start-4 sm:col-span-full flex items-start justify-start mt-2.5"}>
                <div className={"flex items-center justify-start space-x-3"}>
                    <span
                        onClick={onHandleAddTimeSlot}
                        className={"relative has-tooltip inline-flex items-center justify-center cursor-pointer"}>
                        <GrAddCircle className={"text-xl text-text-600 hover:text-text-800"} />
                        <span
                            className={cn(
                                "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip absolute -top-5",
                                "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap"
                            )}
                        >
                            {"Add a session"}
                        </span>
                    </span>
                </div>
            </div>
            <div className={"col-start-1 col-span-full h-0.5 w-full border border-divider-medium"}></div>
        </div>
    )
}

export default DoesNotRepeatScheduleCard