import { userRoles } from 'redux/auth/auth.const';

const SuperAdminIconImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/role-image/student-role-image.png';
const AdminIconImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/role-image/student-role-image.png';

export const switchRoleOption = {
    SUPER_ADMIN: {
        ...userRoles.SUPER_ADMIN,
        roleImageUrl: SuperAdminIconImageUrl
    },
    ADMIN: {
        ...userRoles.ADMIN,
        roleImageUrl: AdminIconImageUrl
    },
}