import { appointmentFilterProps, modifyAppointmentDetailPayload } from "redux/appointment/appointment.const";

export const APPOINTMENT_INITIAL_STATE = {
    appointmentList: {
        isLoading: false,
        data: null,
        message: null,
    },

    appointmentDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyAppointmentDetail: {
        isLoading: false,
        data: null,
        payload: modifyAppointmentDetailPayload,
        message: null
    },
    destroyAppointmentDetail: {
        isLoading: false,
        message: null
    },

    filterProps: appointmentFilterProps
}