import {
    companyDocumentsCategory,
    companyOnboardingStatus,
    docType,
    tutorOnboardingFilterProps
} from "redux/onboarding/onboarding.const";

const addOnboardingDocDetailPayload = {
    documentId: null,
    fileCategory: null,
    fileName: "",
    file: null,
    fileUrl: "",
    categoryList: Object.values(docType),
    status: null
}

const employeeDetailPayload = {
    employeeId: null,
    firstName: "",
    lastName: "",
    profilePicUrl: "",
    about: "",
    designation: ""
}

const importantLinksDetailPayload = {
    linkId: null,
    link: "",
    name: "",
    logoUrl: ""
}

const companyDocumentPayload = {
    documentId: null,
    fileCategory: companyDocumentsCategory.DIGITAL_SIGNATURE_CERTIFICATE.value,
    fileName: '',
    file: '',
    fileType: '',
    fileUrl: '',
    status: companyOnboardingStatus.NOT_SUBMITTED.value
}

export const FAQPayload = {
    question: "",
    answer: ""
}

export const ONBOARDING_INITIAL_STATE = {
    onboardingList: {
        isLoading: false,
        onboardingList: null,
        filteredOnboardingList: null,
        errorMsg: null
    },

    userOnboardingList: {
        isLoading: false,
        userOnboardingList: null,
        errorMsg: null
    },

    onboardingDetail: {
        isLoading: false,
        onboardingDetail: null,
        errorMsg: null
    },

    onboardingUserDetail: {
        isLoading: false,
        onboardingUserDetail: null,
        errorMsg: null
    },
    addOnboardingUserDetail: {
        isLoading: false,
        addOnboardingUserDetail: null,
        errorMsg: null
    },

    userOnboardingQuestionDetail: {
        isLoading: false,
        userOnboardingQuestionDetail: null,
        errorMsg: null,
    },
    addUserOnboardingQuestionDetail: {
        isLoading: false,
        addUserOnboardingQuestionDetail: null,
        errorMsg: null
    },

    onboardingDocList: {
        isLoading: false,
        onboardingDocList: null,
        errorMsg: null
    },
    addOnboardingDocDetail: {
        isLoading: false,
        addOnboardingDocDetail: null,
        addOnboardingDocDetailPayload: addOnboardingDocDetailPayload,
        errorMsg: null
    },

    onboardingInterviewDetail: {
        isLoading: false,
        onboardingInterviewDetail: null,
        errorMsg: null
    },
    addOnboardingInterviewDetail: {
        isLoading: false,
        addOnboardingInterviewDetail: null,
        errorMsg: null
    },

    onboardingTutorPrefDetail: {
        isLoading: false,
        onboardingTutorPrefDetail: null,
        errorMsg: null
    },
    addOnboardingTutorPrefDetail: {
        isLoading: false,
        errorMsg: null
    },

    onboardingTutorTrialCourseDetail: {
        isLoading: false,
        onboardingTutorTrialCourseDetail: null,
        errorMsg: null
    },
    addOnboardingTutorTrialCourseDetail: {
        isLoading: false,
        addOnboardingTutorTrialCourseDetail: null,
        errorMsg: null
    },

    // onboarding comment list
    onboardingCommentList: {
        isLoading: false,
        data: null,
        message: null
    },
    addOnboardingCommentDetail: {
        isLoading: false,
        message: null
    },


    tutorOnboardingList: {
        isLoading: false,
        tutorOnboardingList: null,
        filteredTutorOnboardingList: null,
        errorMsg: null
    },

    tutorOnboardingDetail: {
        isLoading: false,
        tutorOnboardingDetail: null,
        errorMsg: null
    },

    personalDetail: {
        isLoading: false,
        personalDetail: null,
        errorMsg: null
    },

    questionList: {
        isLoading: false,
        questionList: null,
        errorMsg: null
    },

    answerList: {
        isLoading: false,
        answerList: null,
        errorMsg: null
    },

    interviewDetail: {
        isLoading: false,
        interviewDetail: null,
        errorMsg: null
    },

    referenceList: {
        isLoading: false,
        referenceList: null,
        errorMsg: null
    },

    backgroundDetail: {
        isLoading: false,
        backgroundDetail: null,
        errorMsg: null
    },

    // state for company onboarding
    companyOnboardingList: {
        isLoading: false,
        companyOnboardingList: null,
        filteredCompanyOnboarding: null,
        errorMsg: null
    },

    // state for company public profile
    companyPublicProfile: {
        isLoading: false,
        companyPublicProfile: null,
        errorMsg: null
    },
    addCompanyPublicProfile: {
        isLoading: false,
        errorMsg: null
    },

    // state for company course list
    companyCourseList: {
        isLoading: false,
        companyCourseList: null,
        errorMsg: null
    },

    // state for employee list
    employeeList: {
        isLoading: false,
        employeeList: null,
        errorMsg: null
    },
    addEmployee: {
        isLoading: false,
        errorMsg: null
    },
    employeeDetailPayload: {
        employeeDetailPayload: employeeDetailPayload
    },

    // state for company review list
    companyReviewList: {
        isLoading: false,
        companyReviewList: null,
        errorMsg: null
    },

    // state for important links
    importantLinksList: {
        isLoading: false,
        importantLinksList: null,
        errorMsg: null
    },
    addImportantLinks: {
        isLoading: false,
        errorMsg: null
    },
    importantLinksDetailPayload: {
        importantLinksDetailPayload: importantLinksDetailPayload
    },

    // state for company document list
    companyDocumentList: {
        isLoading: false,
        companyDocumentList: null,
        errorMsg: null
    },
    addCompanyDocument: {
        isLoading: false,
        errorMsg: null
    },
    companyDocumentPayload: {
        companyDocumentPayload: companyDocumentPayload
    },

    // state for similar company list
    similarCompanyList: {
        isLoading: false,
        similarCompanyList: null,
        errorMsg: null
    },

    // state for FAQ list
    FAQList: {
        isLoading: false,
        FAQList: null,
        errorMsg: null
    },
    addFAQ: {
        isLoading: false,
        errorMsg: null
    },
    FAQPayload: {
        FAQPayload: FAQPayload
    },

    filterProps: tutorOnboardingFilterProps
}