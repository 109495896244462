import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";
import { BsCheck2All } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch, { fuzzySearch } from "react-select-search";

import FullPageLoader from "components/loader/FullPageLoader";
import ButtonLoader from 'components/loader/ButtonLoader';
import { OptionSelector } from "components/common-components/Select";
import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';

import { genderOptions, titleOptions, userSocialMediaLinksConst, addressTypeConst } from "pages/auth/editTutorOnboard/data";

import {
    getMasterCountryList,
    // getMasterStateList,
    // getMasterCityList,
} from 'redux/master/master.request';
// import { uploadDocumentToS3 } from "redux/document/document.request";
import { uploadFileToS3 } from "redux/storage/storage.request";
import { getOnboardingUserDetailByOnboardingId, updateOnboardingUserDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
    setClearCountryList,
    // setClearStateList,
    // setClearCityList,
} from 'redux/master/master.slice';
// import { setClearUploadDocument, setUploadDocumentPayload } from "redux/document/document.slice";
import { resetUploadFile } from "redux/storage/storage.slice";
import { setClearOnboardingUserDetail, setOnboardingUserDetail } from "redux/onboarding/onboarding.slice";
import { userDetailStatus } from "redux/onboarding/onboarding.const";
import { DEMO_PROFILE } from "redux/storage/storage.const";

import {
    validateEmail,
    validateMobileNo,
} from 'utils/validation-functions';
import { dayjs } from "utils/dateTime.utils";

const pattern = /[a-zA-z]{1}/;

const EditPersonalDetail = () => {
    const { countryList, stateList, cityList } = useAppState((state) => state.master)
    // const { uploadDocument } = useAppState(s => s.document)
    const { uploadFile } = useAppState(s => s.storage)
    const { onboardingUserDetail, addOnboardingUserDetail, onboardingDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [selectedFile, setSelectedFile] = useState(null)
    const [profilePicture, setProfilePicture] = useState("")
    const [emailErrorText, setEmailErrorText] = useState('')
    const [mobilePhoneErrorText, setMobilePhoneErrorText] = useState('')
    const [homePhoneErrorText, setHomePhoneErrorText] = useState('')
    const [workPhoneErrorText, setWorkPhoneErrorText] = useState('')
    const [birthDate, setBirthDate] = useState(new Date())
    const [personalDetailStatus, setPersonalDetailStatus] = useState(userDetailStatus.PENDING.value)

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
            dispatcher(resetUploadFile())
        }
    }, [])

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingUserDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingUserDetail())
        }
    }, [onboardingId])

    useEffect(() => {
        if (onboardingUserDetail?.onboardingUserDetail?.status) {
            setPersonalDetailStatus(onboardingUserDetail?.onboardingUserDetail?.status)
        }
    }, [onboardingUserDetail?.onboardingUserDetail?.status])

    // validations for email and mobile number
    useEffect(() => {
        if (onboardingUserDetail?.onboardingUserDetail?.email && !validateEmail(onboardingUserDetail?.onboardingUserDetail?.email)) setEmailErrorText('Enter valid Email!');
        else setEmailErrorText('');

        if (onboardingUserDetail?.onboardingUserDetail?.mobileNo && !validateMobileNo(onboardingUserDetail?.onboardingUserDetail?.mobileNo)) setMobilePhoneErrorText('Enter valid MobileNo!');
        else setMobilePhoneErrorText('');
    }, [onboardingUserDetail?.onboardingUserDetail?.mobileNo, onboardingUserDetail?.onboardingUserDetail?.email])

    // validation for home phone and work phone
    useEffect(() => {
        if (onboardingUserDetail?.onboardingUserDetail?.homePhone && !validateMobileNo(onboardingUserDetail?.onboardingUserDetail?.homePhone)) {
            setHomePhoneErrorText('Enter Valid Phone Number')
        }
        else setHomePhoneErrorText('')
        if (onboardingUserDetail?.onboardingUserDetail?.workPhone && !validateMobileNo(onboardingUserDetail?.onboardingUserDetail?.workPhone)) {
            setWorkPhoneErrorText('Enter Valid Phone Number')
        }
        else setWorkPhoneErrorText('')
    }, [onboardingUserDetail?.onboardingUserDetail?.homePhone, onboardingUserDetail?.onboardingUserDetail?.workPhone])

    useEffect(() => {
        if (onboardingUserDetail?.onboardingUserDetail?.dateOfBirth) {
            setBirthDate(dayjs(onboardingUserDetail?.onboardingUserDetail?.dateOfBirth)?.toDate())
        }
    }, [onboardingUserDetail?.onboardingUserDetail?.dateOfBirth])

    // use effect for profile picture
    useEffect(() => {
        if (selectedFile) {
            setProfilePicture(selectedFile?.fileUrl)
        }
        if (!selectedFile && onboardingUserDetail?.onboardingUserDetail?.profilePicUrl) {
            setProfilePicture(onboardingUserDetail?.onboardingUserDetail?.profilePicUrl)
        }
        if (!selectedFile && !onboardingUserDetail?.onboardingUserDetail?.profilePicUrl) {
            setProfilePicture(DEMO_PROFILE)
        }
    }, [selectedFile, onboardingUserDetail?.onboardingUserDetail])

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0) {
            dispatcher(setOnboardingUserDetail({
                ...onboardingUserDetail?.onboardingUserDetail,
                profilePicUrl: uploadFile?.data[0]?.fileUrl
            }))
            setSelectedFile(null)
        }

    }, [uploadFile?.data])


    const onSelectCountry = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option)
        dispatcher(setOnboardingUserDetail({
            ...onboardingUserDetail?.onboardingUserDetail,
            country: {
                country: selectedCountry?.country,
                countryDomain: selectedCountry?.countryDomain,
                masterCountryId: selectedCountry?.masterCountryId
            }
        }))
    }

    const onSelectState = (option) => {
        const selectedState = stateList?.stateList?.find((state) => state?.masterStateId === option)
        dispatcher(setOnboardingUserDetail({
            ...onboardingUserDetail?.onboardingUserDetail,
            state: {
                state: selectedState?.state,
                stateDomain: selectedState?.stateDomain,
                masterStateId: selectedState?.masterStateId
            }
        }))
    }

    const onSelectCity = (option) => {
        const selectedState = cityList?.cityList?.find((state) => state?.masterCityId === option)
        dispatcher(setOnboardingUserDetail({
            ...onboardingUserDetail?.onboardingUserDetail,
            city: {
                city: selectedState?.city,
                cityDomain: selectedState?.cityDomain,
                masterCityId: selectedState?.masterCityId,
            }
        }))
    }

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.isdCode === option.value)
        dispatcher(setOnboardingUserDetail({
            ...onboardingUserDetail?.onboardingUserDetail,
            isdCode: selectedCountry?.isdCode
        }))
    }

    const handleOnFileChange = (event) => {
        const files = event.target.files;
        if (!files || files.length === 0) {
            alert("Image not selected!")
            return;
        }

        const file = files[0];
        if (file?.size <= 2 * 1048576) {
            setSelectedFile({
                fileList: event.target.files,
                file: file,
                fileUrl: URL.createObjectURL(file)
            })
        } else {
            toast.error("File size is too large!")
        }
    }

    const onHandleUpload = () => {
        // dispatcher(setUploadFilePayload({
        //     ...uploadFile?.payload,
        //     fileCategory: fileCategories?.profilePicture?.value,
        //     fileName: selectedFile?.file.name,
        //     fileType: selectedFile?.file?.type,
        //     file: selectedFile?.file
        // }))
        const requestDatPayload = {
            fileDetail: selectedFile?.fileList,
            fileOwnerId: onboardingDetail?.onboardingDetail?.user?.userId
        }

        dispatcher(uploadFileToS3(requestDatPayload))
    }

    const onHandleSave = (event) => {
        event.preventDefault()

        // if (personalDetailStatus === onboardingUserDetail?.onboardingUserDetail?.status) return;

        const body = {
            profilePicUrl: onboardingUserDetail?.onboardingUserDetail?.profilePicUrl,
            title: onboardingUserDetail?.onboardingUserDetail?.title,
            firstName: onboardingUserDetail?.onboardingUserDetail?.firstName,
            lastName: onboardingUserDetail?.onboardingUserDetail?.lastName,
            preferredName: onboardingUserDetail?.onboardingUserDetail?.preferredName,
            gender: onboardingUserDetail?.onboardingUserDetail?.gender,
            dateOfBirth: dayjs(birthDate).format("YYYY-MM-DD"),
            homePhone: onboardingUserDetail?.onboardingUserDetail?.homePhone,
            workPhone: onboardingUserDetail?.onboardingUserDetail?.workPhone,
            addressType: onboardingUserDetail?.onboardingUserDetail?.addressType || addressTypeConst?.HOME?.value,
            street: onboardingUserDetail?.onboardingUserDetail?.street,
            masterCountryId: onboardingUserDetail?.onboardingUserDetail?.country?.masterCountryId,
            masterStateId: onboardingUserDetail?.onboardingUserDetail?.state?.masterStateId,
            masterCityId: onboardingUserDetail?.onboardingUserDetail?.city?.masterCityId,
            zipCode: onboardingUserDetail?.onboardingUserDetail?.zipCode,
            facebookProfileLink: onboardingUserDetail?.onboardingUserDetail?.facebookProfileLink,
            instagramProfileLink: onboardingUserDetail?.onboardingUserDetail?.instagramProfileLink,
            twitterProfileLink: onboardingUserDetail?.onboardingUserDetail?.twitterProfileLink,
            linkedinProfileLink: onboardingUserDetail?.onboardingUserDetail?.linkedinProfileLink,
            status: personalDetailStatus
        }
        const requestData = {
            onboardingId: Number(onboardingId),
            body: body
        }

        if (!onboardingUserDetail?.onboardingUserDetail?.isEmailVerified) {
            body["email"] = onboardingUserDetail?.onboardingUserDetail?.email
        }

        if (!onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified) {
            body["mobileNo"] = onboardingUserDetail?.onboardingUserDetail?.isdCode + "-" + onboardingUserDetail?.onboardingUserDetail?.mobileNo
        }

        // if (onboardingUserDetail?.onboardingUserDetail?.status === userDetailStatus?.PENDING?.value || onboardingUserDetail?.onboardingUserDetail?.status === userDetailStatus?.REJECTED?.value) {
        //     body["status"] = userDetailStatus?.SUBMITTED?.value
        // }

        if (!body?.title) {
            toast.error("Please select title!")
            return;
        }
        if (!body?.gender) {
            toast.error("Please select gender!")
            return;
        }

        if (body?.facebookProfileLink && !body?.facebookProfileLink?.includes(userSocialMediaLinksConst?.FACEBOOK?.socialLinkUrl)) {
            toast.error("Invalid facebook link!")
            return;
        }
        if (body?.instagramProfileLink && !body?.instagramProfileLink?.includes(userSocialMediaLinksConst?.INSTAGRAM?.socialLinkUrl)) {
            toast.error("Invalid instagram link!")
            return;
        }
        if (body?.twitterProfileLink && !body?.twitterProfileLink?.includes(userSocialMediaLinksConst?.TWITTER?.socialLinkUrl)) {
            toast.error("Invalid twitter link!")
            return;
        }
        if (body?.linkedinProfileLink && !body?.linkedinProfileLink?.includes(userSocialMediaLinksConst?.LINKEDIN?.socialLinkUrl)) {
            toast.error("Invalid Linkedin link!")
            return;
        }
        dispatcher(updateOnboardingUserDetailByOnboardingId(requestData))
    }

    if (onboardingUserDetail?.isLoading || addOnboardingUserDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingUserDetail?.isLoading || addOnboardingUserDetail?.isLoading} />
        )
    }

    if (onboardingUserDetail?.errorMsg || addOnboardingUserDetail?.errorMsg) {
        return (
            <div className={"w-full h-full flex items-center justify-center"}>
                {onboardingUserDetail?.errorMsg || addOnboardingUserDetail?.errorMsg}
            </div>
        )
    }

    return (
        <form className={"overflow-auto"} onSubmit={onHandleSave}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-3 py-3",
                `bg-${userDetailStatus[onboardingUserDetail?.onboardingUserDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Personal Details"}
            </div>

            {/* personal detail status */}
            <div className={"flex items-center justify-start gap-5 px-3 py-3"}>
                <span className={"font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Status:"}
                </span>
                <span className={cn(
                    `font-bodyPri font-normal text-base text-${userDetailStatus[onboardingUserDetail?.onboardingUserDetail?.status?.toUpperCase()]?.darkColor}`
                )}>
                    {userDetailStatus[onboardingUserDetail?.onboardingUserDetail?.status?.toUpperCase()]?.label}
                </span>
            </div>

            <div className={"grid grid-cols-12 gap-5 p-5"}>

                {/* edit Photo */}
                <div className={"col-start-0 col-span-full font-bodyPri font-semibold text-lg text-text-800 tracking-wide whitespace-nowrap"}>
                    {"Profile Photo"}
                </div>
                <div className={"col-start-1 col-span-2 w-24 h-24 rounded-full overflow-hidden"}>
                    <img
                        src={profilePicture}
                        className={"w-full h-full object-cover"}
                    />
                </div>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-6 flex flex-col justify-start items-start gap-2"}>
                    <div className={"flex flex-col items-start justify-center gap-1 font-bodyPri font-normal text-text-800 text-sm"}>
                        <span>
                            {"This will be displayed to other users when they view your profile."}
                        </span>
                        <span>
                            {"Max Size: 2MB"}
                        </span>
                    </div>
                    {!selectedFile &&
                        <label className={cn(
                            "px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                            "border border-text-500 text-text-500 text-sm",
                            "hover:bg-text-500 hover:text-text-50"
                        )}>
                            <input
                                className={'border border-yellow-300'}
                                type={"file"}
                                id={"file"}
                                name={"image"}
                                style={{ display: "none" }}
                                multiple={false}
                                accept={"image/x-png, image/png, image/jpg, image/jpeg, image/gif"}
                                title={'upload'}
                                onChange={handleOnFileChange}
                            />
                            {"Choose File"}
                        </label>
                    }
                    {selectedFile &&
                        <div className={"flex justify-start items-center gap-3"}>
                            <span
                                className={cn(
                                    "px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                    !uploadFile?.isLoading && "border border-primary-main text-primary-main text-sm hover:bg-primary-main hover:text-text-50",
                                    uploadFile?.isLoading && "bg-primary-main"
                                )}
                                onClick={onHandleUpload}
                            >
                                {uploadFile?.isLoading &&
                                    <ButtonLoader isLoading={uploadFile?.isLoading} />
                                }
                                {!uploadFile?.isLoading &&
                                    "Upload"
                                }
                            </span>
                            <span
                                className={cn(
                                    "px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                    "border border-text-500 text-text-500 text-sm",
                                    "hover:bg-text-500 hover:text-text-50"
                                )}
                                onClick={() => setSelectedFile(null)}
                            >
                                {"Cancel"}
                            </span>
                        </div>
                    }
                </div>

                {/* edit title */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Title:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <OptionSelector
                        options={Object.values(titleOptions)}
                        placeholder={"Please pick a type"}
                        required={true}
                        value={onboardingUserDetail?.onboardingUserDetail?.title}
                        onChange={(option) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            title: option.value
                        }))
                        }
                    />
                </div>

                {/* edit first name section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"First Name:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        value={onboardingUserDetail?.onboardingUserDetail?.firstName}
                        className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base capitalize",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => {
                            dispatcher(setOnboardingUserDetail({
                                ...onboardingUserDetail?.onboardingUserDetail,
                                firstName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                            }))
                        }}
                        required={true}
                    />
                </div>

                {/* edit last name section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Last Name:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        value={onboardingUserDetail?.onboardingUserDetail?.lastName}
                        className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base capitalize",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            lastName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                        }))}
                        required={true}
                    />
                </div>

                {/* edit preferred name section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Preferred Name:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        value={onboardingUserDetail?.onboardingUserDetail?.preferredName}
                        className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base capitalize",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            preferredName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                        }))}
                        required={true}
                    />
                </div>

                {/* select gender section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Gender:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <OptionSelector
                        options={Object.values(genderOptions)}
                        placeholder={"Please pick a type"}
                        required={true}
                        value={onboardingUserDetail?.onboardingUserDetail?.gender}
                        onChange={(option) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            gender: option.value
                        }))
                        }
                    />
                </div>

                {/* edit date of birth section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Date of Birth:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <DatePicker
                        className={cn(
                            "w-full px-2 py-1  border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        placeholder={"Search date"}
                        value={birthDate}
                        required={true}
                        selected={birthDate}
                        onChange={(date) => setBirthDate(date)}
                    />
                </div>

                {/* edit email section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Email:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        value={onboardingUserDetail?.onboardingUserDetail?.email}
                        className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        required={true}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            email: (e.target.value).toLowerCase()
                        }))}
                        disabled={onboardingUserDetail?.onboardingUserDetail?.isEmailVerified}
                    />
                </div>
                <div className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-2 md:col-start-7 md:col-span-2 text-text-600 text-sm pt-2"}>
                    {onboardingUserDetail?.onboardingUserDetail?.isEmailVerified
                        && <span className="flex items-center justify-center gap-1">
                            {"(Verified)"}
                            <BsCheck2All className={"text-green-500 text-lg"} />
                        </span>
                    }
                    {!onboardingUserDetail?.onboardingUserDetail?.isEmailVerified
                        && <span className="flex items-center justify-center gap-1 whitespace-nowrap">
                            {"(Not Verified)"}
                            <TiDeleteOutline className={"text-red-500 text-lg"} />
                        </span>
                    }
                </div>
                {emailErrorText &&
                    <span className="font-bodyPri font-normal text-xs text-red-500">
                        {emailErrorText}
                    </span>
                }

                {/* edit mobile no. section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Mobile Phone:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <div className={cn(
                        "px-2 py-1 border-2 border-divider-medium bg-white rounded-md focus:outline-none flex items-center gap-3",
                        "text-text-900 text-base font-bodyPri"
                    )}>
                        {!pattern.test(onboardingUserDetail?.onboardingUserDetail?.mobileNo) && countryList?.isLoading &&
                            <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                        }
                        {(!pattern.test(onboardingUserDetail?.onboardingUserDetail?.mobileNo) && countryList?.countryList) &&
                            <IsdCodeSelector
                                options={countryList?.countryList?.map((country) => ({
                                    label: country?.isdCode,
                                    value: country?.isdCode,
                                    country: country
                                }))}
                                value={onboardingUserDetail?.onboardingUserDetail?.isdCode}
                                onChange={(option) => {
                                    if (!onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified) {
                                        onSelectIsdCode(option)
                                    }
                                }}
                            />
                        }
                        <input
                            type={"number"}
                            placeholder={"e.g. 0123456789"}
                            value={onboardingUserDetail?.onboardingUserDetail?.mobileNo}
                            className={cn(
                                "w-full input-number-spin-none focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            required={true}
                            onChange={(e) => dispatcher(setOnboardingUserDetail({
                                ...onboardingUserDetail?.onboardingUserDetail,
                                mobileNo: e.target.value
                            }))}
                            disabled={onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified}
                        />
                    </div>
                    {mobilePhoneErrorText &&
                        <span className="col-start-1 col-span-full font-bodyPri font-normal text-xs text-red-500">
                            {mobilePhoneErrorText}
                        </span>
                    }
                </div>
                <div className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-2 md:col-start-7 md:col-span-2 text-text-600 text-sm pt-2"}>
                    {onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified
                        && <span className="flex items-center justify-center gap-1 whitespace-nowrap">
                            {"(Verified)"}
                            <BsCheck2All className={"text-green-500 text-lg"} />
                        </span>
                    }
                    {!onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified
                        && <span className="flex items-center justify-center gap-1 whitespace-nowrap">
                            {"(Not Verified)"}
                            <TiDeleteOutline className={"text-red-500 text-lg"} />
                        </span>
                    }
                </div>

                {/* edit home phone section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Home Phone:"}
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <div className={""}>
                        <input
                            type={"number"}
                            placeholder={"e.g. (01) 10203040"}
                            value={onboardingUserDetail?.onboardingUserDetail?.homePhone}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => dispatcher(setOnboardingUserDetail({
                                ...onboardingUserDetail?.onboardingUserDetail,
                                homePhone: e.target.value
                            }))}
                        />
                    </div>
                    {homePhoneErrorText &&
                        <span className="font-bodyPri font-normal text-xs text-red-500">
                            {homePhoneErrorText}
                        </span>
                    }
                </div>

                {/* edit work phone section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Work Phone:"}
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <div className={""}>
                        <input
                            type={"number"}
                            placeholder={"e.g. (01) 10203040"}
                            value={onboardingUserDetail?.onboardingUserDetail?.workPhone}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => dispatcher(setOnboardingUserDetail({
                                ...onboardingUserDetail?.onboardingUserDetail,
                                workPhone: e.target.value
                            }))}
                        />
                    </div>
                    {workPhoneErrorText &&
                        <span className="font-bodyPri font-normal text-xs text-red-500">
                            {workPhoneErrorText}
                        </span>
                    }
                </div>

                {/* edit street address section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Address Type:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <OptionSelector
                        options={Object.values(addressTypeConst)}
                        value={onboardingUserDetail?.onboardingUserDetail?.addressType}
                        onChange={(option) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            addressType: option?.value
                        }))}
                    />
                </div>

                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Street Address:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        value={onboardingUserDetail?.onboardingUserDetail?.street}
                        className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            street: e.target.value
                        }))}
                    />
                </div>

                {/* edit country section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Country:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                {countryList?.isLoading &&
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                            {"Please wait ..."}
                        </span>
                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    </div>
                }
                <div className={cn(
                    "col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4",
                    "border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400"
                )}>
                    <SelectSearch
                        options={countryList?.countryList?.map((country) => ({
                            name: country?.country,
                            value: country?.masterCountryId,
                            domain: country?.countryDomain
                        })) || []}
                        search
                        autoFocus={false}
                        required={true}
                        placeholder={"Select Country"}
                        filterOptions={fuzzySearch}
                        value={onboardingUserDetail?.onboardingUserDetail?.country?.masterCountryId}
                        onChange={(option) => onSelectCountry(option)}
                    />
                </div>
                {countryList?.errorMsg &&
                    <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                        {countryList?.errorMsg}
                    </div>
                }

                {/* edit state section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"State:"}
                </span>
                {stateList?.isLoading &&
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                            {"Please wait ..."}
                        </span>
                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    </div>
                }
                <div className={cn(
                    "border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"
                )}>
                    <SelectSearch
                        options={stateList?.stateList?.map((state) => ({
                            name: state?.state,
                            value: state?.masterStateId,
                        })) || []}
                        search
                        autoFocus={false}
                        placeholder={"Select State"}
                        filterOptions={fuzzySearch}
                        value={onboardingUserDetail?.onboardingUserDetail?.state?.masterStateId}
                        onChange={(option) => onSelectState(option)}
                    />
                </div>
                {stateList?.errorMsg &&
                    <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                        {stateList?.errorMsg}
                    </div>
                }

                {/* edit city section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"City:"}
                </span>
                <div className={cn(
                    "border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"
                )}>
                    <SelectSearch
                        options={cityList?.cityList?.map((city) => ({
                            name: city?.city,
                            value: city?.masterCityId,
                        })) || []}
                        search
                        placeholder={"Select City"}
                        filterOptions={fuzzySearch}
                        value={onboardingUserDetail?.onboardingUserDetail?.city?.masterCityId}
                        onChange={(option) => onSelectCity(option)}
                    />
                </div>
                {cityList?.errorMsg &&
                    <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                        {cityList?.errorMsg}
                    </div>
                }

                {/* edit postal/ZIP code section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Postal/ZIP Code:"}
                    <span className="text-red-500">
                        {"*"}
                    </span>
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        placeholder={"e.g. CM203RX"}
                        value={onboardingUserDetail?.onboardingUserDetail?.zipCode}
                        className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        required={true}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            zipCode: e.target.value
                        }))}
                    />
                </div>

                {/* social media presence */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Facebook:"}
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        placeholder={"https://www.facebook.com/your_facebook_name"}
                        value={onboardingUserDetail?.onboardingUserDetail?.facebookProfileLink}
                        className={cn(
                            "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            facebookProfileLink: e.target.value
                        }))}
                    />
                </div>

                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Twitter:"}
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        placeholder={"https://www.twitter.com/your_twitter_name"}
                        value={onboardingUserDetail?.onboardingUserDetail?.twitterProfileLink}
                        className={cn(
                            "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            twitterProfileLink: e.target.value
                        }))}
                    />
                </div>

                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Linkedin:"}
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        placeholder={"https://www.linkedin.com/yourlinkedinname"}
                        value={onboardingUserDetail?.onboardingUserDetail?.linkedinProfileLink}
                        className={cn(
                            "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            linkedinProfileLink: e.target.value
                        }))}
                    />
                </div>

                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Instagram:"}
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <input
                        type={"text"}
                        placeholder={"https://www.instagram.com/your_instagram_name"}
                        value={onboardingUserDetail?.onboardingUserDetail?.instagramProfileLink}
                        className={cn(
                            "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(e) => dispatcher(setOnboardingUserDetail({
                            ...onboardingUserDetail?.onboardingUserDetail,
                            instagramProfileLink: e.target.value
                        }))}
                    />
                </div>

                {/* update status section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Status:"}
                </span>
                <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                    <div className={"h-28"}>
                        <OptionSelector
                            options={Object.values(userDetailStatus)}
                            className={"w-full"}
                            onChange={(option) => setPersonalDetailStatus(option?.value)}
                            value={personalDetailStatus}
                        />
                    </div>
                </div>

                <div className={"col-start-0 col-span-full flex items-center justify-start gap-5 mt-1.5"}>
                    <button
                        type={"submit"}
                        disabled={personalDetailStatus === onboardingUserDetail?.onboardingUserDetail?.status}
                        className={cn(
                            "w-24 px-3 py-1 flex justify-center items-center rounded-lg",
                            "border border-secondary-main font-buttons text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                            // personalDetailStatus === onboardingUserDetail?.onboardingUserDetail?.status && "text-text-300 border border-text-300"
                        )}>
                        {"Save"}
                    </button>
                    <div
                        className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                            "border border-primary-main text-primary-main",
                            "hover:bg-primary-main hover:text-text-50"
                        )}
                    // onClick={() => dispatcher(getPersonalDetailByUserId(Number(userId)))}
                    >
                        {"Cancel"}
                    </div>
                </div>
            </div>
            {onboardingUserDetail?.errorMsg || addOnboardingUserDetail?.errorMsg &&
                <div className={"w-full h-full text-center flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingUserDetail?.errorMsg || addOnboardingUserDetail?.errorMsg}
                </div>
            }
        </form>
    )
}

export default EditPersonalDetail;