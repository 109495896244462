import { useEffect } from 'react';

import SearchSelector from "components/searchSelector/SearchSelector";

import ViewUserHeader from 'pages/auth/viewUser/commonComponents/header/ViewUserHeader';

import { getMasterCountryList, getMasterCurrencyList, getMasterLanguageList, getMasterTimezoneList } from "redux/master/master.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCountryList, setClearCurrencyList, setClearLanguageList, setClearTimeZoneList } from 'redux/master/master.slice';
import { timeFormatConst } from "redux/master/master.const";

const ViewUserCulture = () => {
  const { languageList, timeZoneList, currencyList, countryList } = useAppState((state) => state.master)
  const { userDetail } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getMasterLanguageList())
    dispatcher(getMasterTimezoneList())
    dispatcher(getMasterCurrencyList())
    dispatcher(getMasterCountryList())

    return () => {
      dispatcher(setClearLanguageList())
      dispatcher(setClearTimeZoneList())
      dispatcher(setClearCurrencyList())
      dispatcher(setClearCountryList())
    }
  }, [])

  return (
    <div className={""}>

      <ViewUserHeader title={"User Culture"} />

      <div className={"grid grid-cols-12 gap-3 md:gap-5 lg:gap-8 px-4 py-10"}>
        {/* language container */}
        <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base lg:whitespace-nowrap"}>
          {"Display Language"}
        </span>
        <SearchSelector
          isLoading={languageList?.isLoading}
          options={languageList?.languageList?.map((language) => ({
            label: language?.language,
            value: language?.language
          }))?.filter((language) => language?.label === "English") || []}
          placeholder={"Select Language"}
          showGroupedOptions={true}
          selectedValue={{ label: userDetail?.userDetail?.userCulture?.displayLanguage?.displayLanguage || "", value: userDetail?.userDetail?.userCulture?.displayLanguage?.masterLanguageId }}
          onHandleSelect={(option) => { return }}
          disabled={true}
        />

        {/* timezone container */}
        <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
          {"Timezone"}
        </span>
        <SearchSelector
          isLoading={timeZoneList?.isLoading}
          options={timeZoneList?.timeZoneList?.map((timezone) => ({
            label: `${timezone?.timeZone} (${timezone?.country})`,
            value: timezone?.masterTimeZoneId
          })) || []}
          placeholder={"Select Timezone"}
          showGroupedOptions={true}
          selectedValue={{ label: userDetail?.userDetail?.userCulture?.timeZone?.timeZone || "", value: userDetail?.userDetail?.userCulture?.timeZone?.masterTimeZoneId }}
          onChange={(option) => { return }}
          disabled={true}
        />


        {/* currency container */}
        <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
          {"Currency"}
        </span>
        <SearchSelector
          isLoading={currencyList?.isLoading}
          options={currencyList?.currencyList?.map((currency) => ({
            label: `${currency?.code} (${currency?.country})`,
            value: currency?.masterCurrencyId
          })) || []}
          showGroupedOptions={true}
          selectedValue={{ label: userDetail?.userDetail?.userCulture?.currency?.currency || "", value: userDetail?.userDetail?.userCulture?.currency?.masterCurrencyId }}
          onHandleSelect={(option) => { return; }}
          disabled={true}
        />

        {/* time format container */}
        <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
          {"Time Format"}
        </span>
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 flex items-center justify-start gap-8"}>
          <div className={"flex items-center justify-center gap-1 cursor-pointer"}>
            <input
              type={"radio"}
              className={"border-2 border-text-100 hover:border-text-700 cursor-pointer"}
              id={"time_format1"}
              name={"time_format"}
              checked={userDetail?.userDetail?.userCulture?.timeFormat === timeFormatConst?.HRS_12?.value && true}
              disabled={true}
            />
            <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wider"}>
              {timeFormatConst.HRS_12.label}
            </span>
          </div>
          <div className={"flex items-center justify-center gap-1 cursor-pointer"}>
            <input
              type={"radio"}
              className={"border-2 border-text-100 hover:border-text-700 cursor-pointer"}
              id={"time_format2"}
              name={"time_format"}
              checked={userDetail?.userDetail?.userCulture?.timeFormat === timeFormatConst?.HRS_24?.value && true}
              disabled={true}
            />
            <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wider"}>
              {timeFormatConst.HRS_24.label}
            </span>
          </div>
        </div>

        <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
          {"From:"}
        </span>

        <SearchSelector
          id={"fromCountryList"}
          isLoading={countryList?.isLoading}
          options={countryList?.countryList?.map((country) => ({
            label: country?.country,
            value: country?.masterCountryId,
          })) || []}
          showGroupedOptions={true}
          placeholder={"Select Country"}
          selectedValue={{ label: userDetail?.userDetail?.userCulture?.fromCountry?.country || "", value: userDetail?.userDetail?.userCulture?.fromCountry?.masterCountryId }}
          onHandleSelect={(option) => { return; }}
          disabled={true}
        />

        {/* view living in country section */}
        <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
          {"Living In:"}
        </span>

        <SearchSelector
          id={"livingInCountryList"}
          isLoading={countryList?.isLoading}
          options={countryList?.countryList?.map((country) => ({
            label: country?.country,
            value: country?.masterCountryId,
          })) || []}
          showGroupedOptions={true}
          placeholder={"Select Country"}
          selectedValue={{ label: userDetail?.userDetail?.userCulture?.liveInCountry?.country || "", value: userDetail?.userDetail?.userCulture?.liveInCountry?.masterCountryId }}
          onHandleSelect={(option) => { return }}
          disabled={true}
        />

        <div className={"col-start-1 col-span-full w-full h-[6rem]"}></div>
      </div>
    </div>
  )
}

export default ViewUserCulture