import { logInPayload, signUpPayload } from "redux/auth/auth.const";

export const AUTH_INITIAL_STATE = {
    session: {
        isLoading: true,
        isLoggedIn: false,
        accessToken: null,
        message: null
    },

    logIn: {
        isLoading: false,
        data: null,
        payload: logInPayload,
        message: null
    },

    logout: {
        isLoading: false,
        logout: null,
        errorMsg: null
    },

    verifyReferral: {
        isLoading: false,
        data: null,
        message: null
    },

    forgetPassword: {
        isLoading: false,
        message: null,
    },

    verifyUser: {
        isLoading: false,
        data: null,
        message: null
    },

    adminLoginAs: {
        isLoading: false,
        data: null,
        message: null
    }
}