import { useEffect, useMemo, useState } from "react";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import ButtonLoader from "components/loader/ButtonLoader";
import { OptionSelector } from 'components/common-components/Select';

import { getUserDetailByUserId } from "redux/user/user.request";
import { createSessionUserDetail, updateSessionUserDetail } from "redux/session/session.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearUserDetail } from "redux/user/user.slice";
import { resetAddSessionUserDetail, resetModifySessionUserDetail, setModifySessionUserDetailPayload } from "redux/session/session.slice";
import { modalConst } from "redux/local/local.const";
import { sessionUserTypeEnum } from "redux/session/session.const";

const AddSessionUserModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { userDetail } = useAppState((state) => state.user)
    const { addSessionUserDetail, modifySessionUserDetail } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()

    const [isUserVerified, setIsUserVerified] = useState(modifySessionUserDetail?.payload?.isEditSessionUserEnabled)

    const isFormValid = useMemo(() => modifySessionUserDetail?.payload?.sessionId && modifySessionUserDetail?.payload?.userId && modifySessionUserDetail?.payload?.userType, [modifySessionUserDetail?.payload])

    useEffect(() => {
        return () => {
            dispatcher(setClearUserDetail())
            dispatcher(resetAddSessionUserDetail())
            dispatcher(resetModifySessionUserDetail())
        }
    }, [])

    useEffect(() => {
        if (addSessionUserDetail?.data?.result || modifySessionUserDetail?.data?.result) {
            dispatcher(resetAddSessionUserDetail())
            dispatcher(resetModifySessionUserDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_SESSION_USER_MODAL.stateKey]: false
            }))
        }
    }, [addSessionUserDetail?.data?.result, modifySessionUserDetail?.data?.result])

    useEffect(() => {
        if (userDetail?.userDetail) {
            setIsUserVerified(true)
        }
    }, [userDetail?.userDetail])

    const onHandleVerifyTutor = () => {
        if (userDetail?.isLoading || addSessionUserDetail?.isLoading || modifySessionUserDetail?.isLoading) return;

        if (!modifySessionUserDetail?.payload?.userId) {
            alert("Please enter user ID")
            return;
        }
        dispatcher(getUserDetailByUserId(modifySessionUserDetail?.payload?.userId))
    }

    const onHandleSessionUserDetailForm = (event) => {
        event.preventDefault();

        if (!isFormValid) return;

        if (!modifySessionUserDetail?.payload?.userId && !modifySessionUserDetail?.payload?.isEditSessionUserEnabled) {
            alert("Please enter user ID!")
            return;
        }
        if (!isUserVerified && !modifySessionUserDetail?.payload?.isEditSessionUserEnabled) {
            alert("Please verify user!")
            return;
        }
        if (!modifySessionUserDetail?.payload?.userType) {
            alert("Please select user type!")
            return;
        }

        const body = {
            userType: modifySessionUserDetail?.payload?.userType
        }
        if (modifySessionUserDetail?.payload?.comment?.length > 0) {
            body["comment"] = modifySessionUserDetail?.payload?.comment
        }
        if (modifySessionUserDetail?.payload?.isEditSessionUserEnabled) {
            const requestDataPayload = {
                sessionId: modifySessionUserDetail?.payload?.sessionId,
                sessionUserId: modifySessionUserDetail?.payload?.sessionUserId,
                body: body
            }
            dispatcher(updateSessionUserDetail(requestDataPayload))
        } else {
            const requestDataPayload = {
                sessionId: modifySessionUserDetail?.payload?.sessionId,
                body: {
                    userId: modifySessionUserDetail?.payload?.userId,
                    ...body
                }
            }
            dispatcher(createSessionUserDetail(requestDataPayload))
        }

    }

    return (
        <form onSubmit={onHandleSessionUserDetailForm} className={"space-y-5 overflow-hidden"}>
            <div className={"min-h-[24rem] block w-full border border-text-400 space-y-3"}>
                <div className={"space-y-1"}>
                    <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                        {"Session User Detail"}
                    </div>
                    <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
                        {"(Marked with * is mandatory field)"}
                    </span>
                </div>

                <div className="grid grid-cols-2 gap-5 px-2">

                    {/* course id container */}
                    <div className={'col-start-1 col-span-full sm:col-start-1 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Session ID"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"number"}
                            placeholder={"e.g. 123"}
                            value={modifySessionUserDetail?.payload?.sessionId?.toString()}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            required
                            disabled={true}
                        />
                    </div>

                    {/* user id container */}
                    <div className={'col-start-1 col-span-full sm:col-start-2 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"User ID"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <div className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md flex items-center justify-between gap-3",
                            "font-bodyPri font-normal text-text-800 text-base",
                            modifySessionUserDetail?.payload?.isEditSessionUserEnabled && "bg-text-100"
                        )}>
                            <input
                                type={"number"}
                                placeholder={"e.g. 123"}
                                value={modifySessionUserDetail?.payload?.userId?.toString()}
                                className={cn(
                                    "w-full px-1.5 input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                onChange={(e) => {
                                    if (modifySessionUserDetail?.payload?.isEditSessionUserEnabled) return;
                                    if (isUserVerified) {
                                        setIsUserVerified(false)
                                    }
                                    dispatcher(setModifySessionUserDetailPayload({
                                        ...modifySessionUserDetail?.payload,
                                        userId: Number(e.target.value)
                                    }))
                                }}
                                required
                                disabled={modifySessionUserDetail?.payload?.isEditSessionUserEnabled}
                            />
                            {userDetail?.isLoading &&
                                <FaSpinner className={"animate-spin text-primary-dark text-base"} />
                            }
                            {(!userDetail?.isLoading && isUserVerified) &&
                                <span className={"px-1 font-bodyPri font-normal text-xs text-green-500"}>
                                    {"Verified"}
                                </span>
                            }
                            {(!userDetail?.isLoading && !isUserVerified) &&
                                <span
                                    className={cn(
                                        "px-1 py-0.5 border border-secondary-dark hover:bg-secondary-dark hover:text-text-50 rounded-md",
                                        "font-bodyPri font-normal text-xs text-secondary-dark cursor-pointer"
                                    )}
                                    onClick={onHandleVerifyTutor}
                                >
                                    {"Verify"}
                                </span>
                            }
                        </div>
                        {(!userDetail?.isLoading && isUserVerified) &&
                            <div className={"w-full border border-divider-medium shadow-lg rounded-lg px-3 mt-1.5 py-2 flex items-start justify-start gap-3"}>
                                <div className={"relative w-10 h-10 overflow-hidden"}>
                                    <img
                                        src={userDetail?.userDetail?.profilePicUrl}
                                        alt={"tutor-profile-pic"}
                                        className={"w-full h-full rounded-full"}
                                    />
                                </div>
                                <div className={"flex flex-col items-start justify-start gap-1"}>
                                    <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                                        {`${userDetail?.userDetail?.firstName} ${userDetail?.userDetail?.lastName?.charAt(0)}.`}
                                    </span>
                                    <span className={"max-w-[16rem] font-bodyPri font-normal text-text-800 text-sm tracking-wide truncate"}>
                                        {userDetail?.userDetail?.email}
                                    </span>
                                </div>
                            </div>
                        }
                        {userDetail?.errorMsg &&
                            <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                {"Invalid User ID"}
                            </span>
                        }
                    </div>

                    {/* Permission container */}
                    <div className={'col-start-1 col-span-full'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"User Type"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(sessionUserTypeEnum)}
                            className={"w-full"}
                            value={modifySessionUserDetail?.payload?.userType}
                            onChange={(option) => dispatcher(setModifySessionUserDetailPayload({
                                ...modifySessionUserDetail?.payload,
                                userType: option?.value
                            }))}
                        />
                    </div>

                    {/* comment container */}
                    <div className={"w-full col-start-1 col-span-full"}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Comment"}
                        </span>
                        <textarea
                            className={cn(
                                "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                                "outline-none border-2 border-divider-medium focus:shadow-all-md focus:border-divider-lightDark",
                                "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                            )}
                            type={"text"}
                            placeholder="Write your comments ...."
                            value={modifySessionUserDetail?.payload?.comment}
                            onChange={(event) => dispatcher(setModifySessionUserDetailPayload({
                                ...modifySessionUserDetail?.payload,
                                comment: event?.target?.value
                            }))}
                            rows={4}
                        />
                    </div>
                </div>
            </div>

            <div className={"flex items-center justify-between gap-3"}>
                <div className={""}>
                    {(addSessionUserDetail?.message || modifySessionUserDetail?.message) &&
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {addSessionUserDetail?.message || modifySessionUserDetail?.message}
                        </span>
                    }
                </div>
                <button
                    type={"submit"}
                    disabled={!isFormValid}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        !isFormValid && "bg-text-300 text-white cursor-not-allowed",
                        (!modifySessionUserDetail?.isLoading && !addSessionUserDetail?.isLoading && isFormValid) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        ((modifySessionUserDetail?.isLoading || addSessionUserDetail?.isLoading) && isFormValid) && "bg-secondary-main"
                    )}>
                    {(modifySessionUserDetail?.isLoading || addSessionUserDetail?.isLoading)
                        && <ButtonLoader isLoading={modifySessionUserDetail?.isLoading || addSessionUserDetail?.isLoading} />
                    }
                    {(!addSessionUserDetail?.isLoading && !modifySessionUserDetail?.isLoading) && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default AddSessionUserModal;
