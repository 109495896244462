import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { ArrowForwardIos } from '@mui/icons-material';

import { PageContent } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)`w-full`;

export const ArrowToggleIcon = motion(styled(ArrowForwardIos)``);

export const OnboardingCollapsableContainer = motion(styled.section`
    ${tw`
        px-5 bg-white rounded-lg overflow-hidden
        font-bodyPri font-normal text-base text-text-800
    `}
    ${({ isOpen }) => isOpen && tw`
        py-3
    `}
`);