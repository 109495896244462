import { createSlice } from "@reduxjs/toolkit";
import { NOTIFICATION_INITIAL_STATE } from "redux/notification/notification.initialState";

import { createSendOtp, createVerifyOtp } from "redux/notification/notification.request";

export const notification = createSlice({
    name: "notification",
    initialState: NOTIFICATION_INITIAL_STATE,
    reducers: {
        // reducers for notification
        setClearSendOtp: (state) => {
            state.sendOtp = NOTIFICATION_INITIAL_STATE.sendOtp
        },

        // verify otp reducers
        setClearVerifyOtp: (state) => {
            state.verifyOtp = NOTIFICATION_INITIAL_STATE.verifyOtp
        },
    },
    extraReducers: {
        // extra reducers for notification
        [createSendOtp?.pending]: (state) => {
            state.sendOtp.isLoading = true
        },
        [createSendOtp?.fulfilled]: (state, { payload }) => {
            state.sendOtp.isLoading = false
            state.sendOtp.sendOtp = payload
            state.sendOtp.errorMsg = NOTIFICATION_INITIAL_STATE.sendOtp.errorMsg
        },
        [createSendOtp?.rejected]: (state, { payload }) => {
            state.sendOtp.isLoading = false
            state.sendOtp.errorMsg = payload
            state.sendOtp.sendOtp = NOTIFICATION_INITIAL_STATE.sendOtp.sendOtp
        },

        // extra reducers for verify otp
        [createVerifyOtp?.pending]: (state) => {
            state.verifyOtp.isLoading = true
        },
        [createVerifyOtp?.fulfilled]: (state, { payload }) => {
            state.verifyOtp.isLoading = false
            state.verifyOtp.verifyOtp = payload
            state.verifyOtp.errorMsg = NOTIFICATION_INITIAL_STATE.verifyOtp.errorMsg
        },
        [createVerifyOtp?.rejected]: (state, { payload }) => {
            state.verifyOtp.isLoading = false
            state.verifyOtp.errorMsg = payload
            state.verifyOtp.verifyOtp = NOTIFICATION_INITIAL_STATE.verifyOtp.verifyOtp
        }
    }
})

export const {
    setClearSendOtp,

    setClearVerifyOtp,
} = notification.actions
export default notification.reducer;