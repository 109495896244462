import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import FullPageLoader from "components/loader/FullPageLoader";
import { OptionSelector } from "components/common-components/Select";

import { questionType } from "pages/auth/editTutorOnboard/data";
import QuestionsAnswersContainer from "./components/QuestionsAnswersContainer";

import { getUserOnboardingQuestionDetailByOnboardingId, updateUserOnboardingQuestionDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserOnboardingQuestionDetail } from "redux/onboarding/onboarding.slice";
import { relevantQuestionStatus } from "redux/onboarding/onboarding.const";

const EditQuestionsAnswers = () => {
    const { userOnboardingQuestionDetail, addUserOnboardingQuestionDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [questionAnswerStatus, setQuestionAnswerStatus] = useState(relevantQuestionStatus?.PENDING?.value)

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getUserOnboardingQuestionDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearUserOnboardingQuestionDetail())
        }
    }, [onboardingId])

    useEffect(() => {
        if (userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status) {
            setQuestionAnswerStatus(userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status)
        }
    }, [userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status])

    const onHandleSave = () => {
        // if (questionAnswerStatus === userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status) return;

        const filteredResponse = userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.filter((question) => question?.response === "")
        if (filteredResponse?.length > 0) {
            toast.error("All fields are required!")
            return;
        }
        const singleTextQuestion = userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.filter((question) => question?.questionType === questionType?.SINGLE_TEXT?.value && question?.response?.length < 20)
        if (singleTextQuestion?.length > 0) {
            toast.warn("Please enter between 20 and 500 characters")
            return;
        }

        const body = {
            status: questionAnswerStatus,
            questions: userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.map((question) => ({
                questionResponseId: question?.questionResponseId,
                response: question?.response
            }))
        }

        const requestData = {
            onboardingId: Number(onboardingId),
            body: body
        }
        dispatcher(updateUserOnboardingQuestionDetailByOnboardingId(requestData))
    }

    if (userOnboardingQuestionDetail?.isLoading || addUserOnboardingQuestionDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={userOnboardingQuestionDetail?.isLoading || addUserOnboardingQuestionDetail?.isLoading} />
        )
    }

    if (userOnboardingQuestionDetail?.errorMsg || addUserOnboardingQuestionDetail?.errorMsg) {
        return (
            <div className={"w-full h-full flex items-center justify-center text-center font-bodyPri font-normal text-red-500 text-sm"}>
                {userOnboardingQuestionDetail?.errorMsg || addUserOnboardingQuestionDetail?.errorMsg}
            </div>
        )
    }

    return (
        <div className={"overflow-auto"}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-3 py-3",
                `bg-${relevantQuestionStatus[userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Relevant Question Answers"}
            </div>

            <div className={"w-full space-y-8 px-3 py-5"}>
                <div className={"w-full flex items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-base"}>
                        {"Status:"}
                    </span>
                    <span
                        className={cn(
                            `font-bodyPri font-normal text-base text-${relevantQuestionStatus[userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status?.toUpperCase()]?.darkColor}`
                        )}>
                        {relevantQuestionStatus[userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status?.toUpperCase()]?.label}
                    </span>
                </div>
                {userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.map((questionDetail, index) => (
                    <QuestionsAnswersContainer questionDetail={questionDetail} key={index} />
                ))}
                <div className={"h-36"}>
                    <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-3"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                            {"Status:"}
                        </span>
                        <div className={"w-52"}>
                            <OptionSelector
                                options={Object.values(relevantQuestionStatus)}
                                className={""}
                                onChange={(option) => setQuestionAnswerStatus(option?.value)}
                                value={questionAnswerStatus}
                            />
                        </div>
                    </div>
                </div>
                <div className={"flex items-center justify-start gap-5"}>
                    <div
                        className={cn(
                            "w-24 px-3 py-1 flex justify-center items-center rounded-lg",
                            "border border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                            // questionAnswerStatus === userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status && "text-text-300 border border-text-300"
                        )}
                        onClick={onHandleSave}
                    >
                        {"Save"}
                    </div>
                    <div
                        className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                            "border border-primary-main text-primary-main",
                            "hover:bg-primary-main hover:text-text-50"
                        )}
                    // onClick={() => dispatcher(getPersonalDetailByUserId(Number(userId)))}
                    >
                        {"Cancel"}
                    </div>
                </div>
            </div>
            {(userOnboardingQuestionDetail?.errorMsg || addUserOnboardingQuestionDetail?.errorMsg) &&
                <div className={"w-full h-full flex justify-center items-center font-bodyPri font-normal text-center text-red-500 text-sm"}>
                    {userOnboardingQuestionDetail?.errorMsg || addUserOnboardingQuestionDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default EditQuestionsAnswers