import { baseApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/notification/notification.const"


class NotificationService {
    createSendOtp = async ({body}) => {
        const response = await baseApiInstance().post(
            url.SEND_OTP,
            body
        )

        return response
    }

    createVerifyOtp = async ({body}) => {
        const response = await baseApiInstance().post(
            url.VERIFY_OTP,
            body
        )

        return response
    }
}

export default new NotificationService()