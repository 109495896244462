import { dayjs } from "utils/dateTime.utils";

import {
    SearchCourseId,
    // SearchSegment,
    SearchCourseTitle,
    SearchCourseSlug,
    SearchTutor,
    SearchCategory,
    // SearchPrice,
    FilterCourseType,
    // FilterPriceType,
    FilterCourseStatus,
    SearchDate,
    SearchPublishedDate,
    ButtonAction,
    FilterActionRequired
} from "pages/auth/courses/components/CourseStatistics";

export const pageHeading = {
    heading: "Classes",
    subHeading: "Find the list of classes!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const courseHeaderConst = {
    CLASS_ID: {
        label: "Class ID",
        value: "class_id"
    },
    CLASS_TITLE: {
        label: "Class Title",
        value: "class_title"
    },
    CLASS_SLUG: {
        label: "Class Slug",
        value: "class_slug"
    },
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    // SEGMENT: {
    //     label: "Segment",
    //     value: "segment"
    // },
    CATEGORY: {
        label: "Category",
        value: "category"
    },
    // PRICE: {
    //     label: "Price",
    //     value: "price"
    // },
    CLASS_TYPE: {
        label: "Class Type",
        value: "class_type"
    },
    // PRICE_TYPE: {
    //     label: "Price Type",
    //     value: "price_type"
    // },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    PUBLISHED_DATE: {
        label: "Published Date",
        value: "published_date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    ACTION: {
        label: "Action",
        value: "action"
    }
}

export const headerElement = {
    COURSE_ID: {
        label: "Class ID",
        value: "class_id"
    },
    COURSE_TITLE: {
        label: "Course Title",
        value: "course_title"
    },
    COURSE_SLUG: {
        label: "Course Slug",
        value: "course_slug"
    },
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    // SEGMENT: {
    //     label: "Segment",
    //     value: "segment"
    // },
    CATEGORY: {
        label: "Category",
        value: "category"
    },
    // PRICE: {
    //     label: "Price",
    //     value: "price"
    // },
    COURSE_TYPE: {
        label: "Course Type",
        value: "course_type"
    },
    // PRICE_TYPE: {
    //     label: "Price Type",
    //     value: "price_type"
    // },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    PUBLISHED_DATE: {
        label: "Published Date",
        value: "published_date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    ACTION: {
        label: "Action",
        value: "action"
    }
}

export const courseMenuData = {
    ADD_BOOKING: {
        label: "Add Booking",
        value: "add_booking"
    },
    CLONE_COURSE: {
        label: "Clone Class",
        value: "clone_class"
    },
    PUBLIC_VIEW: {
        label: "Public View",
        value: "public_view"
    }
}

export const subRowHeader = [
    <SearchCourseId />,
    <SearchCourseTitle />,
    <SearchCourseSlug />,
    <SearchTutor />,
    // <SearchSegment />,
    <SearchCategory />,
    // <SearchPrice />,
    <FilterCourseType />,
    // <FilterPriceType />,
    <FilterCourseStatus />,
    <SearchDate />,
    <SearchPublishedDate />,
    <ButtonAction />,
    <FilterActionRequired />
]

export const sortCourseLowToHigh = (activeSortHeader, course1, course2) => {
    if (activeSortHeader?.activeSortKey === courseHeaderConst.CLASS_ID.value) {
        return (Number(course1?.id) - Number(course2?.id))
    }
    // if (activeSortHeader?.activeSortKey === courseHeaderConst.SEGMENT.value) {
    //     return course1?.segment?.toLowerCase()?.localeCompare(course2?.segment?.toLowerCase())
    // }
    if (activeSortHeader?.activeSortKey === courseHeaderConst.CATEGORY.value) {
        return course1?.category?.toLowerCase()?.localeCompare(course2?.category?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === courseHeaderConst?.TUTOR.value) {
        return (Number(course1?.tutor?.id) - Number(course2?.tutor?.id))
    }
    if (activeSortHeader.activeSortKey === courseHeaderConst.CLASS_TITLE.value) {
        return course1?.title?.toLowerCase()?.localeCompare(course2?.title?.toLowerCase())
    }
    if (activeSortHeader.activeSortKey === courseHeaderConst.CLASS_SLUG.value) {
        return course1?.slug?.toLowerCase()?.localeCompare(course2?.slug?.toLowerCase())
    }
    // if (activeSortHeader.activeSortKey === courseHeaderConst.PRICE.value) {
    //     return Number(((course1?.coursePrice?.price - (course1?.coursePrice?.price * course1?.discountPct) / 100) / 100)) - Number(((course2?.coursePrice?.price - (course2?.coursePrice?.price * course2?.discountPct) / 100) / 100))
    // }
    if (activeSortHeader.activeSortKey === courseHeaderConst.CLASS_TYPE.value) {
        return course1?.type?.toLowerCase()?.localeCompare(course2?.type?.toLowerCase())
    }
    // if (activeSortHeader.activeSortKey === courseHeaderConst.BOOKING_TYPE.value) {
    //     return course1?.bookingType?.toLowerCase()?.localeCompare(course2?.bookingType?.toLowerCase())
    // }
    if (activeSortHeader.activeSortKey === courseHeaderConst.STATUS.value) {
        return course1?.status?.toLowerCase()?.localeCompare(course2?.status?.toLowerCase())
    }
    if (activeSortHeader.activeSortKey === courseHeaderConst.DATE.value) {
        return dayjs(course2?.createdAt)?.isBefore(dayjs(course1?.createdAt)) ? 1 : -1
    }
    if (activeSortHeader.activeSortKey === courseHeaderConst.PUBLISHED_DATE.value) {
        return dayjs(course2?.publishedAt)?.isBefore(dayjs(course1?.publishedAt)) ? 1 : -1
    }
}

export const sortCourseHighToLow = (activeSortHeader, course1, course2) => {
    if (activeSortHeader?.activeSortKey === courseHeaderConst.CLASS_ID.value) {
        return (Number(course2?.id) - Number(course1?.id))
    }
    // if (activeSortHeader?.activeSortKey === courseHeaderConst.SEGMENT.value) {
    //     return course2?.segment?.toLowerCase()?.localeCompare(course1?.segment?.toLowerCase())
    // }
    if (activeSortHeader?.activeSortKey === courseHeaderConst.CATEGORY.value) {
        return course2?.category?.localeCompare(course1?.category)
    }
    if (activeSortHeader?.activeSortKey === courseHeaderConst?.TUTOR.value) {
        return (Number(course2?.tutor?.id) - Number(course1?.tutor?.id))
    }
    if (activeSortHeader.activeSortKey === courseHeaderConst.CLASS_TITLE.value) {
        return course2?.title?.toLowerCase()?.localeCompare(course1?.title?.toLowerCase())
    }
    if (activeSortHeader.activeSortKey === courseHeaderConst.CLASS_SLUG.value) {
        return course2?.slug?.toLowerCase()?.localeCompare(course1?.slug?.toLowerCase())
    }
    // if (activeSortHeader.activeSortKey === courseHeaderConst.PRICE.value) {
    //     return Number(((course2?.coursePrice?.price - (course2?.coursePrice?.price * course2?.discountPct) / 100) / 100)) - Number(((course1?.coursePrice?.price - (course1?.coursePrice?.price * course1?.discountPct) / 100) / 100))
    // }
    if (activeSortHeader.activeSortKey === courseHeaderConst.CLASS_TYPE.value) {
        return course2?.type?.toLowerCase()?.localeCompare(course1?.type?.toLowerCase())
    }
    // if (activeSortHeader.activeSortKey === courseHeaderConst.BOOKING_TYPE.value) {
    //     return course2?.bookingType?.toLowerCase()?.localeCompare(course1?.bookingType?.toLowerCase())
    // }
    if (activeSortHeader.activeSortKey === courseHeaderConst.STATUS.value) {
        return course2?.status?.toLowerCase()?.localeCompare(course1?.status?.toLowerCase())
    }
    if (activeSortHeader.activeSortKey === courseHeaderConst.DATE.value) {
        return dayjs(course1?.createdAt)?.isBefore(dayjs(course2?.createdAt)) ? 1 : -1
    }
    if (activeSortHeader.activeSortKey === courseHeaderConst.PUBLISHED_DATE.value) {
        return dayjs(course1?.publishedAt)?.isBefore(dayjs(course2?.publishedAt)) ? 1 : -1
    }
}