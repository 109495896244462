import { useEffect, useMemo } from 'react';
import { cn } from "utils/cn.utils";

import { BiTimeFive } from 'react-icons/bi';
import { MdAccessTimeFilled } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';

import { durations, sessionCount } from './data';
import SessionCount from './SessionCount';

import { getPublicCourseDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetAddBookingDetail, setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { setClearPublicCourseDetail } from 'redux/course/course.slice';
import { coursePricingModelTypeEnum, courseType } from 'redux/course/course.const';

import { currencyRateConversion } from 'utils/generators.utils';
import { bookingOfferingTypeEnum } from 'redux/booking/booking.const';

const BookingCourseTypeOffering = ({ setIsIdVerified }) => {
    const { locals } = useAppState((state) => state.local)
    const { publicCourseDetail } = useAppState((state) => state.course)
    const { addBookingDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (publicCourseDetail?.data) {
            dispatcher(setAddBookingDetailPayload({
                ...addBookingDetail?.payload,
                offering: publicCourseDetail?.data,
                duration: 30,
                sessionCount: publicCourseDetail?.data?.coursePrice?.sessionCount,
                price: publicCourseDetail?.data?.coursePrice?.price || 0,
                discountPct: publicCourseDetail?.data?.coursePrice?.discountPct || 0,
                volumeDiscountPct: publicCourseDetail?.data?.volumeDiscountPct || 0
            }))
            setIsIdVerified(true)
        }
    }, [publicCourseDetail?.data])

    const isOneOnOneCourseType = useMemo(() => (publicCourseDetail?.data?.type === courseType?.ONE_ON_ONE?.value), [publicCourseDetail?.data?.type])

    const priceUtils = useMemo(() => {
        let priceUnitText = ""
        let purchasePrice = 0
        let priceText = ""
        let delPriceText = ""
        let discountPctText = ""

        if ((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COURSE.value)
            && addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value) {
            priceUnitText = coursePricingModelTypeEnum[addBookingDetail?.payload?.offering?.coursePrice?.priceModel.toUpperCase()].label
            let price = addBookingDetail?.payload?.offering?.coursePrice?.price || 0
            if (addBookingDetail?.payload?.offering?.coursePrice?.priceModel === coursePricingModelTypeEnum.PER_HOUR.value) {
                price = ((price / 4) * (addBookingDetail?.payload?.duration / 15)) * addBookingDetail?.payload?.sessionCount
            } else if (addBookingDetail?.payload?.offering?.coursePrice?.priceModel === coursePricingModelTypeEnum.PER_SESSION.value) {
                price = price * addBookingDetail?.payload?.sessionCount
            }
            purchasePrice = (price - ((price * (addBookingDetail?.payload?.offering?.coursePrice?.discountPct || 0)) / 100))
            purchasePrice = addBookingDetail?.payload?.sessionCount < 5 ? purchasePrice : purchasePrice - ((purchasePrice * (addBookingDetail?.payload?.offering?.volumeDiscountPct || 0)) / 100)
            priceText = (price === 0) ? "FREE" : currencyRateConversion(locals?.currencyCode, purchasePrice)
            delPriceText = (price === 0) ? "" : currencyRateConversion(locals?.currencyCode, price)
            discountPctText = (price === 0) ? "" : addBookingDetail?.payload?.offering?.coursePrice?.discountPct + "% off" + (addBookingDetail?.payload?.sessionCount >= 5 && addBookingDetail?.payload?.offering?.volumeDiscountPct > 0 ? " + " + addBookingDetail?.payload?.offering?.volumeDiscountPct + "% off" : "")
        } else if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COURSE.value
            && addBookingDetail?.payload?.offering?.type === courseType.GROUP.value) {
            priceUnitText = coursePricingModelTypeEnum[addBookingDetail?.payload?.offering?.coursePrice?.priceModel.toUpperCase()].label
            let price = (addBookingDetail?.payload?.offering?.coursePrice?.price || 0)
            if (addBookingDetail?.payload?.offering?.coursePrice?.priceModel === coursePricingModelTypeEnum.PER_HOUR.value) {
                price = ((price / 4) * (addBookingDetail?.payload?.offering?.basicDetail?.duration / 15)) * addBookingDetail?.payload?.sessionCount
            } else if (addBookingDetail?.payload?.offering?.coursePrice?.priceModel === coursePricingModelTypeEnum.PER_SESSION.value) {
                price = price * addBookingDetail?.payload?.sessionCount
            }
            purchasePrice = (price - ((price * (addBookingDetail?.payload?.offering?.coursePrice?.discountPct || 0)) / 100))
            priceText = (price === 0) ? "FREE" : currencyRateConversion(locals?.currencyCode, purchasePrice)
            delPriceText = (price === 0) ? "" : currencyRateConversion(locals?.currencyCode, price)
            discountPctText = (price === 0) ? "" : addBookingDetail?.payload?.offering?.coursePrice?.discountPct + "% off"
        }

        return { priceUnitText, purchasePrice, priceText, delPriceText, discountPctText }
    }, [addBookingDetail?.payload, locals?.currencyCode, publicCourseDetail?.data])

    const onHandleChangeCourseId = (event) => {
        dispatcher(setClearPublicCourseDetail())
        dispatcher(resetAddBookingDetail())
        setIsIdVerified(false)
        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetail?.payload,
            courseId: event?.target?.value
        }))
    }

    const onHandleVerifyCourse = () => {
        if (!addBookingDetail?.payload?.courseId || publicCourseDetail?.isLoading) return;

        dispatcher(setClearPublicCourseDetail())
        const requestDataPayload = {
            courseId: String(addBookingDetail?.payload?.courseId)
        }
        dispatcher(getPublicCourseDetail(requestDataPayload))
    }
    return (
        <div className={"grid grid-cols-12 gap-5"}>

            {/* course id */}
            <span className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-3 font-bodyPri font-normal text-text-800 text-base"}>
                {"Class ID / Slug:"}
            </span>
            <div className={"col-start-1 col-span-full sm:col-start-4 sm:col-span-full flex items-start justify-between gap-3"}>
                <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                    <input
                        type={"text"}
                        className={cn(
                            "w-full px-2 py-1 border-2 border-divider-medium rounded-lg focus:outline-none focus:border-2 focus:border-divider-darkLight",
                            "font-bodyPri font-normal text-text-900 text-base"
                        )}
                        value={addBookingDetail?.payload?.courseId}
                        onChange={onHandleChangeCourseId}
                        required={true}
                    />

                    <div className={"w-full flex justify-end"}>
                        <span
                            className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide cursor-pointer"}
                            onClick={onHandleVerifyCourse}
                        >
                            {"Verify Class ID"}
                        </span>
                    </div>
                </div>
            </div>

            {/* course title */}
            {publicCourseDetail?.data &&
                <>
                    <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                        {"Class Title:"}
                    </span>
                    <span className={"col-start-1 col-span-full sm:col-start-4 sm:col-span-full font-bodyPri font-normal text-text-900 text-base tracking-wide line-clamp-1"}>
                        {publicCourseDetail?.data?.title}
                    </span>

                    {/* course type */}
                    <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                        {"Class Type:"}
                    </span>
                    <span className={"col-start-4 col-span-full font-bodyPri font-normal text-text-900 text-base"}>
                        {`${courseType[publicCourseDetail?.data?.type?.toUpperCase()]?.label}`}
                    </span>

                    {/* one on one duration */}
                    {(isOneOnOneCourseType) &&
                        <>
                            <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                                {"Duration:"}
                            </span>
                            <div className={"col-start-1 col-span-full sm:col-start-4 sm:col-span-full flex items-center justify-start gap-3"}>
                                {durations.map((duration, index) => (
                                    <span key={index} onClick={() => dispatcher(setAddBookingDetailPayload({ ...addBookingDetail?.payload, duration: duration }))}
                                        className={cn(
                                            "flex items-center justify-center gap-1 px-2 py-0.5 rounded-lg cursor-pointer",
                                            "font-bodyPri font-normal text-sm whitespace-nowrap",
                                            (addBookingDetail?.payload?.duration === duration)
                                                ? "bg-primary-dark text-text-50"
                                                : "bg-primary-light text-primary-dark"
                                        )}
                                    >
                                        {(addBookingDetail?.payload?.duration === duration)
                                            ? <MdAccessTimeFilled className={"text-sm"} />
                                            : <BiTimeFive className={"text-sm"} />
                                        }
                                        {duration + " min"}
                                    </span>
                                ))}
                            </div>
                        </>
                    }

                    {/* one on one session count */}
                    {(isOneOnOneCourseType) &&
                        <>
                            <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                                {"Session Count:"}
                            </span>
                            <div className={"col-start-1 col-span-full sm:col-start-4 sm:col-span-full flex items-center justify-start gap-3"}>
                                {sessionCount.map((count, index) => (
                                    <SessionCount key={index} count={count} />
                                ))}
                            </div>
                        </>
                    }

                    {/* course price */}
                    <span className={"col-start-1 col-span-3 font-bodyPri font-normal text-text-900 text-base"}>
                        {"Price:"}
                    </span>
                    <span className={"col-start-4 col-span-full font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                        {currencyRateConversion(locals?.currencyCode, priceUtils.purchasePrice)}
                    </span>
                </>
            }

            {publicCourseDetail?.isLoading &&
                <ComponentLoader
                    isLoading={publicCourseDetail?.isLoading}
                    className={"col-start-1 col-span-full w-full flex items-center justify-center"}
                />
            }
            {publicCourseDetail?.message &&
                <span className={"w-full col-start-1 col-span-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {publicCourseDetail?.message}
                </span>
            }
        </div>
    )
}

export default BookingCourseTypeOffering