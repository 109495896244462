import { toast } from 'react-toastify';
import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';

import BookingCourseTypeOffering from './BookingCourseTypeOffering';
import BookingAppointmentTypeOffering from './BookingAppointmentTypeOffering';

import { getUserCultureDetailByUserId, getUserDetailByUserId } from 'redux/user/user.request';
import { getPublicCourseDetail } from 'redux/course/course.request';
import { createBookingDetail } from 'redux/booking/booking.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAppointmentDetail } from 'redux/appointment/appointment.slice';
import { setClearUserDetail, setClearUserCultureDetail } from 'redux/user/user.slice';
import { resetAddBookingDetail, setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { setClearPublicCourseDetail } from 'redux/course/course.slice';
import { modalConst } from 'redux/local/local.const';
import { bookingOfferingType, bookingPriceType, userBookingTimeZoneConst } from 'redux/booking/booking.const';
import { courseType } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone } from 'utils/dateTime.utils';

const AddBookingModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { appointmentDetail } = useAppState((state) => state.appointment)
    const { userDetail, userCultureDetail } = useAppState((state) => state.user)
    const { publicCourseDetail } = useAppState((state) => state.course)
    const { addBookingDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    const [isIdVerified, setIsIdVerified] = useState(false)

    const isOneOnOneCourseType = useMemo(() => publicCourseDetail?.data?.type === courseType?.ONE_ON_ONE?.value, [publicCourseDetail?.data?.type])

    useEffect(() => {
        return () => {
            dispatcher(setClearUserDetail())
            dispatcher(setClearUserCultureDetail())
            dispatcher(setClearPublicCourseDetail())
            dispatcher(resetAppointmentDetail())
        }
    }, [])

    useEffect(() => {
        if (addBookingDetail?.payload?.userId) {
            dispatcher(getUserDetailByUserId(addBookingDetail?.payload?.userId))
            dispatcher(getUserCultureDetailByUserId(addBookingDetail?.payload?.userId))
        }
    }, [])


    useEffect(() => {
        if (addBookingDetail?.payload?.courseId) {
            const requestDataPayload = {
                courseId: String(addBookingDetail?.payload?.courseId)
            }
            dispatcher(getPublicCourseDetail(requestDataPayload))
        }
    }, [])

    useEffect(() => {
        if (addBookingDetail?.data) {
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_BOOKING_MODAL.stateKey]: false,
                [modalConst.BOOKING_SUMMARY_MODAL.stateKey]: true
            }))
        }
    }, [addBookingDetail?.data])

    const onHandleChangeUser = (event) => {
        dispatcher(resetAddBookingDetail())
        dispatcher(resetAppointmentDetail())
        dispatcher(setClearPublicCourseDetail())
        dispatcher(setClearUserDetail())
        setIsIdVerified(false)
        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetail?.payload,
            userId: Number(event?.target?.value)
        }))
    }

    const onHandleVerifyUser = () => {
        if (userDetail?.isLoading || !addBookingDetail?.payload?.userId) return;

        setIsIdVerified(false)
        dispatcher(setClearUserDetail())
        dispatcher(setClearUserCultureDetail())
        dispatcher(setClearPublicCourseDetail())
        dispatcher(resetAppointmentDetail())

        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetail?.payload,
            courseId: null,
            appointmentId: null,
            offeringType: bookingOfferingType?.COURSE?.value
        }))

        dispatcher(getUserDetailByUserId(addBookingDetail?.payload?.userId))
        dispatcher(getUserCultureDetailByUserId(addBookingDetail?.payload?.userId))
    }

    const onHandleTimeZoneType = (value) => {
        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetail?.payload,
            timeZone: value
        }))
    }

    const onHandleOfferingType = (bookingOffering) => {
        if (bookingOffering === bookingOfferingType?.COURSE?.value) {
            dispatcher(resetAppointmentDetail())
        }
        if (bookingOffering === bookingOfferingType?.APPOINTMENT?.value) {
            dispatcher(setClearPublicCourseDetail())
        }
        dispatcher(resetAddBookingDetail())
        setIsIdVerified(false)
        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetail?.payload,
            courseId: null,
            appointmentId: null,
            offeringType: bookingOffering
        }))
    }

    const onHandleSubmit = (event) => {
        event.preventDefault()

        if (!isIdVerified || addBookingDetail?.isLoading || publicCourseDetail?.isLoading || appointmentDetail?.isLoading) return;

        if ((addBookingDetail?.payload?.offeringType === bookingOfferingType?.APPOINTMENT?.value) && !addBookingDetail?.payload?.appointmentId) {
            toast.warn("Please enter appointment ID!")
            return;
        }

        if ((addBookingDetail?.payload?.offeringType === bookingOfferingType?.COURSE?.value) && !addBookingDetail?.payload?.courseId) {
            toast.warn("Please enter course ID!")
            return;
        }

        const requestDataPayload = {
            userId: userDetail?.userDetail?.userId,
            offeringType: addBookingDetail?.payload?.offeringType,
        }

        if (addBookingDetail?.payload?.offeringType === bookingOfferingType?.APPOINTMENT?.value) {
            if (addBookingDetail?.payload?.timeZone === userBookingTimeZoneConst?.USER_TIMEZONE?.value) {
                requestDataPayload["timeZone"] = userCultureDetail?.userCultureDetail?.timeZone?.timeZone
            } else {
                requestDataPayload["timeZone"] = timeZone
            }
            requestDataPayload["appointmentId"] = Number(addBookingDetail?.payload?.appointmentId)
        } else if (addBookingDetail?.payload?.offeringType === bookingOfferingType?.COURSE?.value) {
            requestDataPayload["courseId"] = publicCourseDetail?.data?.id
            if (isOneOnOneCourseType) {
                requestDataPayload["duration"] = addBookingDetail?.payload?.duration
                requestDataPayload["sessionCount"] = addBookingDetail?.payload?.sessionCount
            }
            if (addBookingDetail?.payload?.timeZone === userBookingTimeZoneConst?.USER_TIMEZONE?.value) {
                requestDataPayload["timeZone"] = userCultureDetail?.userCultureDetail?.timeZone?.timeZone
            } else {
                requestDataPayload["timeZone"] = timeZone
            }
        }
        dispatcher(createBookingDetail(requestDataPayload))
    }

    return (
        <form className={"space-y-5 overflow-x-hidden"} onSubmit={onHandleSubmit}>
            <div className={"min-h-[24rem] block w-full space-y-5"}>
                <div className={"grid grid-cols-12 gap-5 w-full"}>
                    <span className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-4 font-bodyPri font-normal text-text-800 text-base"}>
                        {"Student User ID:"}
                    </span>
                    <div className={"col-start-1 col-span-full sm:col-start-5 sm:col-span-full flex flex-col items-start justify-start gap-0.5"}>
                        <input
                            type={"number"}
                            className={cn(
                                "w-full px-2 py-1 input-number-spin-none border-2 border-divider-medium rounded-lg focus:outline-none focus:border-2 focus:border-divider-darkLight",
                                "font-bodyPri font-normal text-text-900 text-base"
                            )}
                            value={addBookingDetail?.payload?.userId?.toString()}
                            onChange={onHandleChangeUser}
                            required={true}
                        />
                        <div className={"w-full flex justify-end"}>
                            <span
                                className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide cursor-pointer"}
                                onClick={onHandleVerifyUser}
                            >
                                {"Verify User ID"}
                            </span>
                        </div>
                    </div>
                    {userDetail?.isLoading &&
                        <ComponentLoader isLoading={userDetail?.isLoading} className={"col-start-1 col-span-full flex items-center justify-center"} />
                    }
                    {userDetail?.userDetail &&
                        <div className={cn(
                            "col-start-1 col-span-full sm:col-start-4 sm:col-span-full px-2 py-1 border-2 border-text-300 rounded-lg flex items-center justify-start gap-5"
                        )}>

                            <div className={"relative w-16 h-16 rounded-full overflow-hidden"}>
                                <img
                                    src={userDetail?.userDetail?.profilePicUrl}
                                    alt={"profileImg"}
                                    className={"w-full h-full object-cover"}
                                />
                                <div className={"relative has-tooltip"}>
                                    <div className={'absolute -top-5 right-0 w-6 h-6 rounded-full overflow-hidden border-2 border-white'}>
                                        <img
                                            src={`https://flagcdn.com/16x12/${userDetail?.userDetail?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                            alt={"country-flag"}
                                            className={'w-full h-full object-cover'}
                                        />
                                    </div>
                                    <span
                                        className={cn(
                                            "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs truncate rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-2 -right-0",
                                            "flex flex-col items-center justify-start overflow-hidden"
                                        )}
                                    >
                                        {userDetail?.userDetail?.fromCountry?.country}
                                    </span>
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-center md:justify-start md:items-start gap-2">

                                <div className={"flex flex-wrap items-center justify-start gap-4"}>
                                    <Link
                                        to={`${pagesInfo?.VIEW_USER?.pagePath}/${userDetail?.userDetail?.userId}`}
                                        className={"flex items-center justify-start gap-2 font-bodyPri font-normal text-sm text-primary-main focus:outline-none hover:text-primary-dark hover:underline decoration-primary-dark outline-offset-2 tracking-wide"}
                                    >
                                        {"User ID: "}{userDetail?.userDetail?.userId || "-"}
                                    </Link>
                                </div>

                                <div className={"flex items-center justify-start gap-2 font-bodyPri font-normal tracking-wide"}>
                                    <span className="text-text-700 text-base">
                                        {"Name:"}
                                    </span>
                                    <div className="flex items-center justify-start gap-2 font-bodyPri font-normal text-text-900 text-base capitalize">
                                        {userDetail?.userDetail?.firstName + " " + userDetail?.userDetail?.lastName.charAt(0) + "."}
                                        {(userDetail?.userDetail?.onlineStatus === "online") &&
                                            <div
                                                className={"w-1.5 h-1.5 rounded-full bg-green-500"}>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    {userDetail?.errorMsg &&
                        <div className={"col-start-1 col-span-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {userDetail?.errorMsg}
                        </div>
                    }

                    {userDetail?.userDetail && (appointmentDetail?.data?.result || isOneOnOneCourseType) &&
                        <span className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-3 font-bodyPri font-normal text-text-800 text-base"}>
                            {"Time Zone:"}
                        </span>
                    }

                    {userDetail?.userDetail && (appointmentDetail?.data?.result || isOneOnOneCourseType) &&
                        <div className={"col-start-4 col-span-full flex items-center justify-evenly gap-5"}>
                            {Object.values(userBookingTimeZoneConst)?.map((timeZoneConst, index) => (
                                <div
                                    key={index}
                                    className={"flex items-start justify-start gap-1.5 cursor-pointer"}
                                    onClick={() => onHandleTimeZoneType(timeZoneConst?.value)}
                                >
                                    <input
                                        type={"radio"}
                                        className={"w-4 h-4 mt-1.5 border border-text-800 rounded-full focus:outline-none cursor-pointer"}
                                        value={timeZoneConst?.value}
                                        onChange={() => onHandleTimeZoneType(timeZoneConst?.value)}
                                        defaultChecked={timeZoneConst?.value === userBookingTimeZoneConst?.USER_TIMEZONE?.value}
                                        checked={timeZoneConst?.value === addBookingDetail?.payload?.timeZone}
                                        required={true}
                                    />
                                    <div className={"flex flex-col items-start justify-start md:whitespace-nowrap"}>
                                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide md:whitespace-nowrap"}>
                                            {`${timeZoneConst?.label}`}
                                        </span>
                                        {(timeZoneConst?.value === userBookingTimeZoneConst?.USER_TIMEZONE?.value) &&
                                            <span className={"font-bodyPri font-normal text-text-800 text-xs tracking-wide"}>
                                                {`(${userCultureDetail?.userCultureDetail?.timeZone?.timeZone})`}
                                            </span>
                                        }
                                        {(timeZoneConst?.value === userBookingTimeZoneConst?.YOUR_TIMEZONE?.value) &&
                                            <span className={"font-bodyPri font-normal text-text-800 text-xs tracking-wide"}>
                                                {`(${timeZone})`}
                                            </span>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    {userDetail?.userDetail &&
                        <span className={"col-start-1 col-span-full sm:col-start-1 sm:col-span-3 font-bodyPri font-normal text-text-800 text-base"}>
                            {"Offering Type:"}
                        </span>
                    }
                    {userDetail?.userDetail &&
                        <div className={"col-start-4 col-span-full flex items-center justify-evenly gap-5"}>
                            {Object.values(bookingOfferingType)?.map((offering, index) => (
                                <div
                                    key={index}
                                    className={"flex items-center justify-center gap-1.5 cursor-pointer"}
                                    onClick={() => onHandleOfferingType(offering?.value)}
                                >
                                    <input
                                        type={"radio"}
                                        className={"w-4 h-4 border border-text-800 rounded-full focus:outline-none cursor-pointer"}
                                        value={offering?.value}
                                        onChange={() => onHandleOfferingType(offering?.value)}
                                        defaultChecked={offering?.value === bookingOfferingType?.COURSE?.value}
                                        checked={offering?.value === addBookingDetail?.payload?.offeringType}
                                        required={true}
                                    />
                                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                        {offering?.label}
                                    </span>
                                </div>
                            ))}
                        </div>
                    }

                    {(userDetail?.userDetail && (addBookingDetail?.payload?.offeringType === bookingOfferingType?.COURSE?.value)) &&
                        <div className={"col-start-1 col-span-full w-full"}>
                            <BookingCourseTypeOffering setIsIdVerified={setIsIdVerified} />
                        </div>
                    }
                    {(userDetail?.userDetail && (addBookingDetail?.payload?.offeringType === bookingOfferingType?.APPOINTMENT?.value)) &&
                        <div className={"col-start-1 col-span-full w-full"}>
                            <BookingAppointmentTypeOffering setIsIdVerified={setIsIdVerified} />
                        </div>
                    }
                </div>
                {addBookingDetail?.message &&
                    <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {addBookingDetail?.message}
                    </div>
                }
            </div>

            {/* submit & cancel btn */}
            <div className={"flex justify-end px-5"}>
                <button
                    type={"submit"}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full font-buttons font-normal text-base",
                        addBookingDetail?.isLoading && "bg-secondary-main",
                        isIdVerified && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        !isIdVerified && "border border-text-300 text-text-300 cursor-not-allowed",
                    )}>
                    {addBookingDetail?.isLoading && <ButtonLoader isLoading={addBookingDetail?.isLoading} />}
                    {!addBookingDetail?.isLoading && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default AddBookingModal