import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./lmsLecture.const";

class LmsLectureService {

    static lmsLectureService = new LmsLectureService()

    /* tutor lms lecture service */
    getLmsLectureList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_LECTURE_LIST),
            { params: query }
        )

        return response;
    }
    destroyLmsLectureDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_LECTURE_DETAIL, params)
        )

        return response;
    }
}

export default LmsLectureService.lmsLectureService;