import { useNavigate, useLocation } from 'react-router-dom';

import { MdCancel } from 'react-icons/md';

import { Container } from "pages/auth/walletConfirmation/WalletConfirmationPageStyle";

import { pagesInfo } from 'utils/pagesInfo';


const FailedWalletConfirmation = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const from = location.state?.from?.pathname || pagesInfo.DASHBOARD.pagePath;

    const pageRedirect = () => {
        navigate(from, { replace: true })
    }

    return (
        <Container className={"!shadow-all-md"}>
            <MdCancel className={"text-8xl text-red-500"} />
            <div className={"flex flex-col items-center gap-2"}>
                <span className={"font-bodyPri font-medium text-md text-text-800"}>
                    {"Transaction was unsuccessful."}
                </span>
                <span className={"font-bodyPri font-semibold text-lg text-red-500 tracking-wider"}>
                    {"Failed"}
                </span>
            </div>
            <div onClick={pageRedirect}>
                <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
                    {"Try Again"}
                </span>
            </div>
        </Container>
    )
}

export default FailedWalletConfirmation