import { createSlice } from "@reduxjs/toolkit";

import { APPOINTMENT_INITIAL_STATE } from "redux/appointment/appointment.initialState";

const appointment = createSlice({
    name: "appointment",
    initialState: APPOINTMENT_INITIAL_STATE,
    reducers: {
        // appointmentList
        setAppointmentListLoading: (state, { payload }) => {
            state.appointmentList.isLoading = payload
        },
        setAppointmentListData: (state, { payload }) => {
            state.appointmentList.data = payload
            state.appointmentList.message = APPOINTMENT_INITIAL_STATE.appointmentList.message
        },
        setAppointmentListMessage: (state, { payload }) => {
            state.appointmentList.message = payload
            state.appointmentList.data = APPOINTMENT_INITIAL_STATE.appointmentList.data
        },
        resetAppointmentList: (state) => {
            state.appointmentList = APPOINTMENT_INITIAL_STATE.appointmentList
        },

        // appointment detail
        setAppointmentDetailLoading: (state, { payload }) => {
            state.appointmentDetail.isLoading = payload
        },
        setAppointmentDetailData: (state, { payload }) => {
            state.appointmentDetail.data = payload
            state.appointmentDetail.message = APPOINTMENT_INITIAL_STATE.appointmentDetail.message
        },
        setAppointmentDetailMessage: (state, { payload }) => {
            state.appointmentDetail.message = payload
            state.appointmentDetail.data = APPOINTMENT_INITIAL_STATE.appointmentDetail.data
        },
        resetAppointmentDetail: (state) => {
            state.appointmentDetail = APPOINTMENT_INITIAL_STATE.appointmentDetail
        },

        // modify appointment detail
        setModifyAppointmentDetailLoading: (state, { payload }) => {
            state.modifyAppointmentDetail.isLoading = payload
        },
        setModifyAppointmentDetailData: (state, { payload }) => {
            state.modifyAppointmentDetail.data = payload
            state.modifyAppointmentDetail.message = APPOINTMENT_INITIAL_STATE.modifyAppointmentDetail.message
        },
        setModifyAppointmentDetailMessage: (state, { payload }) => {
            state.modifyAppointmentDetail.message = payload
            state.modifyAppointmentDetail.data = APPOINTMENT_INITIAL_STATE.modifyAppointmentDetail.data
        },
        resetModifyAppointmentDetail: (state) => {
            state.modifyAppointmentDetail = APPOINTMENT_INITIAL_STATE.modifyAppointmentDetail
        },

        // appointment detail payload
        setModifyAppointmentDetailPayload: (state, { payload }) => {
            state.modifyAppointmentDetail.payload = payload
        },
        resetModifyAppointmentDetailPayload: (state) => {
            state.modifyAppointmentDetail.payload = APPOINTMENT_INITIAL_STATE.modifyAppointmentDetail.payload
        },

        // delete appointment detail
        setDestroyAppointmentDetailLoading: (state, { payload }) => {
            state.destroyAppointmentDetail.isLoading = payload
        },
        setDestroyAppointmentDetailMessage: (state, { payload }) => {
            state.destroyAppointmentDetail.message = payload
        },
        resetDestroyAppointmentDetail: (state) => {
            state.destroyAppointmentDetail = APPOINTMENT_INITIAL_STATE.destroyAppointmentDetail
        },

        // filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = APPOINTMENT_INITIAL_STATE.filterProps
        },
    }
})

export const {
    setAppointmentListLoading,
    setAppointmentListData,
    setAppointmentListMessage,
    resetAppointmentList,

    setAppointmentDetailLoading,
    setAppointmentDetailData,
    setAppointmentDetailMessage,
    resetAppointmentDetail,

    setModifyAppointmentDetailLoading,
    setModifyAppointmentDetailData,
    setModifyAppointmentDetailMessage,
    resetModifyAppointmentDetail,

    setModifyAppointmentDetailPayload,
    resetModifyAppointmentDetailPayload,

    setDestroyAppointmentDetailLoading,
    setDestroyAppointmentDetailMessage,
    resetDestroyAppointmentDetail,

    // filter props
    setFilterProps,
    resetFilterProps,
} = appointment.actions

export default appointment.reducer