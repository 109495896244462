import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    GET_PRODUCT_LIST: "products/products",

    DELETE_PRODUCT_DETAIL: 'products/products/{productId}'
}

export const productRedux = {
    sliceName: "product",
    productList: {
        requestName: "getProductList"
    }
}

export const productTypeEnum = {
    NONE: {
        label: "None",
        value: "none"
    },
    LIVE_COURSE: {
        label: "Live course",
        value: "live_course"
    },
    LMS_COURSE: {
        label: "Lms course",
        value: "lms_course"
    },
    EVENTLY: {
        label: "Evently",
        value: "evently"
    },
}

export const productPriceTypeEnum = {
    ONE_TIME: {
        label: "One Time",
        value: "one_time"
    },
    RECURRING: {
        label: "Recurring",
        value: "recurring"
    }
}

export const productPriceStatusEnum = {
    DRAFT: {
        label: "Draft",
        value: "draft"
    },
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "InActive",
        value: "inactive"
    }
}

export const productPriceModelEnum = {
    FREE: {
        label: "Free",
        value: "free"
    },
    STANDARD: {
        label: "Standard",
        value: "standard"
    },
    PACKAGE: {
        label: "Package",
        value: "package"
    },
    GRADUATED: {
        label: "Graduated",
        value: "graduated"
    },
    VOLUME: {
        label: "Volume",
        value: "volume"
    },
    STAIRSTEP: {
        label: "Stair Step",
        value: "stairstep"
    },
    CUSTOM: {
        label: "Custom",
        value: "custom"
    }
}

export const productPriceDiscountTypeEnum = {
    NONE: {
        label: "None",
        value: "none"
    },
    PERCENTAGE: {
        label: "Percentage",
        value: "percentage"
    },
    FLAT: {
        label: "Flat",
        value: "flat"
    }
}

export const productSettingsStatusEnum = {
    DRAFT: {
        label: "Draft",
        value: "draft"
    },
    PUBLISHED: {
        label: "Published",
        value: "published"
    },
    INACTIVE: {
        label: "In active",
        value: "inactive"
    }
}

export const productSettingsVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const productAssetEnum = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    VIDEO: {
        label: "Video",
        value: "video"
    },
    YOUTUBE: {
        label: "YouTube",
        value: "youtube"
    }
}

export const productFilterProps = {
    searchText: "",
    sortBy: "",
    productId: "",
    productTitle: "",
    productSlug: "",
    productType: "",
    date: "",
    modifiedDate: "",
    page: 1,
    records: dataEntriesConst?.TWENTY_FIVE?.value
}