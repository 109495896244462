import { useEffect, useCallback } from "react";

import Pagination from "components/pagination/Pagination";

import { getAvailabilityListPayload } from "pages/auth/editUser/data";

import AvailabilityFilters from "./filters";
import AvailabilityCard, { AvailabilityCardLoader } from "./AvailabilityCard";

import { getAvailabilityList } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetAvailabilityList, resetFilterProps, setFilterProps } from "redux/availability/availability.slice";

// import { timeZone } from "utils/dateTime.utils";

const EditUserAvailabilityListPage = () => {
    // const { locals } = useAppState((state) => state.local)
    const { userDetail } = useAppState((state) => state.user)
    const { availabilityList, filterProps } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        return () => {
            dispatcher(resetFilterProps())
            dispatcher(resetAvailabilityList())
        }
    }, [])

    useEffect(() => {
        if (userDetail?.userDetail?.userId) {
            getUserAvailabilityList()
        }
    }, [userDetail?.userDetail?.userId, filterProps])

    const getUserAvailabilityList = async () => {
        // const currentTimeZone = [availabilityTimeZoneConst.USER.value]?.includes(filterProps?.timeZone)
        //     ? userDetail?.userDetail?.userCulture?.timeZone?.timeZone
        //     : [availabilityTimeZoneConst.LOCAL.value]?.includes(filterProps?.timeZone)
        //         ? locals?.timeZone
        //             ? locals?.timeZone
        //             : timeZone
        //         : timeZone
        let requestDataPayload = await getAvailabilityListPayload(filterProps)
        requestDataPayload["userId"] = userDetail?.userDetail?.userId
        dispatcher(getAvailabilityList({ ...requestDataPayload }))
    }

    const onHandleChangePage = useCallback((page) => {
        dispatcher(setFilterProps({ filterProps: { ...filterProps, page: page } }))
    }, [userDetail?.userDetail?.userId, filterProps])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <AvailabilityFilters />
            <div className={"w-full flex flex-col max-w-4xl mx-auto container gap-5 sm:gap-10"}>
                {new Array(5).fill("").map((_, index) => <AvailabilityCardLoader key={index} isLoading={availabilityList?.isLoading} />)}
                {(!availabilityList?.isLoading && (availabilityList?.message || !availabilityList?.data?.result || (availabilityList?.data?.result?.length === 0))) &&
                    <div className={"flex items-center justify-center w-full p-5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                            {availabilityList?.message || "No Availability Added!"}
                        </span>
                    </div>
                }
                {(!availabilityList?.isLoading && !!availabilityList?.data?.result?.length) && availabilityList?.data?.result?.map((userAvailability, index) => (
                    <AvailabilityCard key={index} userAvailability={userAvailability} />
                ))}
                {!availabilityList?.isLoading && (availabilityList?.data && (availabilityList?.data?.pagination?.totalPages > 1)) && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={availabilityList?.data?.pagination?.page}
                            totalPages={availabilityList?.data?.pagination?.totalPages}
                            onChangePage={onHandleChangePage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default EditUserAvailabilityListPage;