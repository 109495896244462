import { useEffect } from "react";

import ComponentLoader from "components/loader/ComponentLoader";
import { ToggleWithText } from "components/common-components/Toggle";
import AdminTooltip from "components/toolTip/ToolTip";

import ViewTutorHeader from "pages/auth/viewTutor/commonComponents/ViewTutorHeader";
import { setGetOtherPref, tutorOtherPreferenceToolTip } from "pages/auth/viewTutor/data";

import { getUserOtherPrefDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserOtherPref } from "redux/user/user.slice";

export default function ViewTutorOther() {
    const { userOtherPref } = useAppState((s) => s.user)
    const { tutorDetail } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher();

    useEffect(() => {
        if (tutorDetail?.data?.user?.id) {
            dispatcher(getUserOtherPrefDetailByUserId(tutorDetail?.data?.user?.id))
        }

        return () => {
            dispatcher(setClearUserOtherPref())
        }
    }, [tutorDetail?.data])

    const updateOtherStatus = (key, value) => {
        return;
    }

    return (
        <div className="">

            <ViewTutorHeader title={"Other Preferences"} tooltipText={tutorOtherPreferenceToolTip} />

            <div className="px-4 py-10 space-y-2 divide-y divide-blue-50">
                {userOtherPref?.isLoading &&
                    <ComponentLoader isLoading={userOtherPref?.isLoading} />
                }
                {(!userOtherPref?.isLoading && userOtherPref?.userOtherPref) &&
                    setGetOtherPref(userOtherPref?.userOtherPref).map((other, index) => (
                        <ToggleWithText
                            key={index}
                            description={other.description}
                            onChange={(isToggle) => updateOtherStatus(other.key, isToggle)}
                            title={other.label}
                            value={other.value}
                        />
                    ))}
                {(!userOtherPref?.isLoading && userOtherPref?.errorMsg) &&
                    <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                        {userOtherPref?.errorMsg}
                    </div>
                }
            </div>
        </div>
    );
}
