import { cn } from "utils/cn.utils";
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CurrentDateTime from "pages/auth/viewCourseDetail/components/confirmSchedulePage/commonComponents/CurrentDateTime";
import ViewCourseTooltip from 'pages/auth/viewCourseDetail/ViewCourseTooltip';
import ScheduleCard from "pages/auth/viewCourseDetail/components/confirmSchedulePage/components/repeatWeeklySchedule copy/ScheduleCard";
import { endsTooltip, startDateTooltip } from "pages/auth/viewCourseDetail/data";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddCourseAvailDetailPayload } from 'redux/course/course.slice';
import { courseType, courseEndTypeEnum } from "redux/course/course.const";

import { dayjs, timeZone, weekdays } from 'utils/dateTime.utils';

const RepeatWeeklySchedule = () => {
    const { courseDetail, addCourseAvailDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [current, setCurrent] = useState(dayjs().tz(timeZone))

    const isGroupCourseType = courseDetail?.courseDetail?.type === courseType?.GROUP?.value
    const isOneOnOneCourseType = courseDetail?.courseDetail?.type === courseType?.ONE_ON_ONE?.value
    // const underReviewOrPublishedStatus = [courseStatus?.UNDER_REVIEW?.value, courseStatus?.PUBLISHED?.value]?.includes(courseDetail?.courseDetail?.status)

    var todayDate = new Date();
    var dateAfterSevenDays = todayDate.setDate(todayDate.getDate() + 8);
    var dateAfterFourteenDays = new Date(dateAfterSevenDays).setDate(new Date(dateAfterSevenDays).getDate() + 14);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(dayjs().tz(timeZone))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })


    return (
        <div className={"grid grid-cols-12 gap-5 w-full"}>
            <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full space-y-3"}>
                <CurrentDateTime />
                <div className={"w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl mx-auto space-y-3"}>
                    {(addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots) && weekdays?.map((day, index) => (
                        <ScheduleCard
                            key={index}
                            localDay={day}
                            weeklyAvailability={addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyTimeSlots}
                        />
                    ))}
                </div>
            </div>

            {isGroupCourseType &&
                <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3"}>
                    <div className={"flex items-center justify-start gap-1"}>
                        <ViewCourseTooltip tooltip={startDateTooltip} translateX={0} />
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Start Date:"}
                            <span className={"text-lg text-red-500"}>{"*"}</span>
                        </span>
                    </div>
                </div>
            }
            {isGroupCourseType &&
                <div className={cn(
                    "col-start-1 col-span-full md:col-start-4 md:col-span-full",
                    "w-full md:w-80 px-3 py-1 border-2 border-divider-medium rounded-md mx-6",
                    (isOneOnOneCourseType) && "bg-text-100"
                )}>
                    <DatePicker
                        className={"w-full font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm focus:outline-none"}
                        dateFormat={"dd/MM/yyyy"}
                        value={addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime ? dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime)?.toDate() : dateAfterSevenDays}
                        selected={addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime ? dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.startDateTime)?.toDate() : dateAfterSevenDays}
                        minDate={dateAfterSevenDays}
                        placeholderText={"Select Start Date"}
                        scrollableYearDropdown={true}
                        showYearDropdown={true}
                        // showTimeSelect={true}
                        // timeIntervals={15}
                        yearDropdownItemNumber={80}
                        showMonthDropdown={true}
                        required={true}
                        disabled={true}
                    />
                </div>
            }
            {isGroupCourseType &&
                <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3"}>
                    <div className={"flex items-center justify-start gap-1"}>
                        <ViewCourseTooltip tooltip={endsTooltip} translateX={0} />
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Ends:"}
                            <span className={"text-lg text-red-500"}>{"*"}</span>
                        </span>
                    </div>
                </div>
            }
            {isGroupCourseType &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full flex flex-col items-start justify-start gap-3"}>

                    {/* never radio button */}
                    <div className={"flex items-center justify-start gap-3"}>
                        <input
                            type={"radio"}
                            className={cn(
                                courseDetail?.courseDetail?.type !== courseType?.ONE_ON_ONE?.value && "cursor-pointer",
                                isOneOnOneCourseType && "cursor-not-allowed"
                            )}
                            value={courseEndTypeEnum?.FOREVER?.value}
                            checked={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.FOREVER?.value) && true}
                            disabled={true}
                        />
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {courseEndTypeEnum?.FOREVER?.label}
                        </span>
                    </div>

                    {/* select a date radio button */}
                    <div className={"flex items-center justify-start gap-3"}>
                        <input
                            type={"radio"}
                            className={cn(
                                courseDetail?.courseDetail?.type !== courseType?.ONE_ON_ONE?.value && "cursor-pointer",
                                isOneOnOneCourseType && "cursor-not-allowed"
                            )}
                            value={courseEndTypeEnum?.ON_DATE?.value}
                            checked={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) && true}
                            onClick={(event) => {
                                if (isOneOnOneCourseType) return;
                                // if (isGroupCourseType && underReviewOrPublishedStatus) return;

                                dispatcher(setAddCourseAvailDetailPayload({
                                    ...addCourseAvailDetail?.addCourseAvailDetailPayload,
                                    endType: event?.target?.value
                                }))
                            }}
                        // disabled={(isOneOnOneCourseType) || (isGroupCourseType && underReviewOrPublishedStatus)}
                        />
                        {(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType !== courseEndTypeEnum?.ON_DATE?.value) &&
                            <span className={"px-2 py-1 bg-divider-darkLight rounded-lg font-bodyPri font-normal text-text-900 text-base"}>
                                {"DD MMM YYYY"}
                            </span>
                        }
                        {(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) &&
                            <DatePicker
                                className={cn(
                                    "w-full md:w-80 px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                dateFormat={"dd/MM/yyyy"}
                                value={addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime ? dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime)?.toDate() : dateAfterFourteenDays}
                                selected={addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime ? dayjs(addCourseAvailDetail?.addCourseAvailDetailPayload?.endDateTime)?.toDate() : dateAfterFourteenDays}
                                minDate={dateAfterFourteenDays}
                                placeholderText={"Select End Date"}
                                scrollableYearDropdown={true}
                                showYearDropdown={true}
                                // showTimeSelect={true}
                                // timeIntervals={15}
                                yearDropdownItemNumber={80}
                                showMonthDropdown={true}
                                required={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.ON_DATE?.value) && true}
                                disabled={true}
                            />
                        }
                    </div>

                    <div className={"flex items-center justify-start"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"OR"}
                        </span>
                    </div>

                    {/* weekly cycles radio Button */}
                    <div className={"flex items-center justify-start gap-3"}>
                        <input
                            type={"radio"}
                            className={cn(
                                courseDetail?.courseDetail?.type !== courseType?.ONE_ON_ONE?.value && "cursor-pointer",
                                isOneOnOneCourseType && "cursor-not-allowed"
                            )}
                            value={courseEndTypeEnum?.WEEKLY_CYClE?.value}
                            checked={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value) && true}
                            disabled={true}
                        />
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {"After"}
                            </span>
                            <input
                                type={"number"}
                                placeholder={"Enter weekly cycles"}
                                className={cn(
                                    "w-16 px-3 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-900 text-lg",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={addCourseAvailDetail?.addCourseAvailDetailPayload?.weeklyCycle?.toString()}
                                required={(addCourseAvailDetail?.addCourseAvailDetailPayload?.endType === courseEndTypeEnum?.WEEKLY_CYClE?.value) && true}
                                disabled={true}
                            />
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {courseEndTypeEnum?.WEEKLY_CYClE?.label}
                            </span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default RepeatWeeklySchedule;

// AiOutlineEnter