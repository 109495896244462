import { createSlice } from "@reduxjs/toolkit";

import { OFFERING_INITIAL_STATE } from "redux/offering/offering.initialState";

export const offering = createSlice({
    name: "offering",
    initialState: OFFERING_INITIAL_STATE,
    reducers: {
        // reducers for offering list
        setOfferingListLoading: (state, { payload }) => {
            state.offeringList.isLoading = payload
        },
        setOfferingList: (state, { payload }) => {
            state.offeringList.offeringList = payload
            state.offeringList.filteredOfferingList = payload
            state.offeringList.errorMsg = OFFERING_INITIAL_STATE.offeringList.errorMsg
        },
        setOfferingListErrorMsg: (state, { payload }) => {
            state.offeringList.errorMsg = payload
            state.offeringList.offeringList = OFFERING_INITIAL_STATE.offeringList.offeringList
            state.offeringList.filteredOfferingList = OFFERING_INITIAL_STATE.offeringList.filteredOfferingList
        },
        setClearOfferingList: (state) => {
            state.offeringList = OFFERING_INITIAL_STATE.offeringList
        },

        // reducers for offering course detail by course id
        setOfferingCourseLoading: (state, { payload }) => {
            state.offeringCourse.isLoading = payload
        },
        setOfferingCourse: (state, { payload }) => {
            state.offeringCourse.offeringCourse = payload
            state.offeringCourse.errorMsg = OFFERING_INITIAL_STATE.offeringCourse.errorMsg
        },
        setOfferingCourseErrorMsg: (state, { payload }) => {
            state.offeringCourse.errorMsg = payload
            state.offeringCourse.offeringCourse = OFFERING_INITIAL_STATE.offeringCourse.offeringCourse
        },
        setClearOfferingCourse: (state) => {
            state.offeringCourse = OFFERING_INITIAL_STATE.offeringCourse
        },

        // reducers for add offering/course
        setAddOfferingLoading: (state, { payload }) => {
            state.addOffering.isLoading = payload
        },
        setAddOffering: (state, { payload }) => {
            state.addOffering.addOffering = payload
            state.addOffering.errorMsg = OFFERING_INITIAL_STATE.addOffering.errorMsg
        },
        setAddOfferingErrorMsg: (state, { payload }) => {
            state.addOffering.errorMsg = payload
            state.addOffering.addOffering = OFFERING_INITIAL_STATE.addOffering.addOffering
        },
        setClearAddOffering: (state) => {
            state.addOffering = OFFERING_INITIAL_STATE.addOffering
        },

        // reducers for offering course payload
        setOfferingCoursePayload: (state, {payload}) => {
            state.offeringCourse.offeringCoursePayload = payload
        },
        setClearOfferingCoursePayload: (state) => {
            state.offeringCourse.offeringCoursePayload = OFFERING_INITIAL_STATE.offeringCourse.offeringCoursePayload
        }
    }
})

export const {
    setOfferingListLoading,
    setOfferingList,
    setOfferingListErrorMsg,
    setClearOfferingList,

    setOfferingCourseLoading,
    setOfferingCourse,
    setOfferingCourseErrorMsg,
    setClearOfferingCourse,

    setOfferingCoursePayload,
    setClearOfferingCoursePayload,
    
    setAddOfferingLoading,
    setAddOffering,
    setAddOfferingErrorMsg,
    setClearAddOffering,

} = offering.actions
export default offering.reducer;