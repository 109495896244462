import { useEffect } from 'react';
import { useParams, Outlet, Navigate, useLocation, Link } from "react-router-dom";

import { FaEye } from "react-icons/fa";
import { BsCheck2All } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

import FullPageLoader from 'components/loader/FullPageLoader';
import DetailedView from 'components/detailedView/DetailedView';
import PageNavigationOptions from 'components/navigationOptions/PageNavigationOptions';

import { editUserHeaderConst, editUserNavigationOptions } from "pages/auth/editUser/data";
import { PageContentStyle } from 'pages/auth/editUser/EditUserPage.style';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getUserDetailByUserId } from 'redux/user/user.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearUserDetail } from 'redux/user/user.slice';
import { userStatus } from "redux/user/user.const";

import { dayjs, timeZone } from "utils/dateTime.utils";
import { pagesInfo } from 'utils/pagesInfo';

const TutorImageContainer = ({ profilePicUrl }) => {
  return (
    <div className={"w-14 h-14 overflow-hidden rounded-full"}>
      <img
        src={profilePicUrl}
        className={"w-full h-full object-cover"}
        alt={"Profile_Picture"}
      />
    </div>
  )
}

const TutorEmailContainer = ({ email, isEmailVerified }) => {
  return (
    <div className={"flex flex-col items-start justify-start sm:flex-row sm:justify-center sm:items-center gap-1"}>
      <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
        {email}
      </span>
      {isEmailVerified &&
        <BsCheck2All className={"text-green-500 text-base"} />
      }
      {!isEmailVerified &&
        <TiDeleteOutline className={"text-red-500 text-base"} />
      }
    </div>
  )
}

const TutorMobileNoContainer = ({ mobileNo, isdCode, isMobileNoVerified }) => {
  return (isdCode && mobileNo) ? (
    <div className={"flex flex-col items-start justify-start sm:flex-row sm:justify-center sm:items-center gap-1"}>
      <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
        {"+" + isdCode + " - " + mobileNo}
      </span>
      {isMobileNoVerified &&
        <BsCheck2All className={"text-green-500 text-sm"} />
      }
      {!isMobileNoVerified &&
        <TiDeleteOutline className={"text-red-500 text-sm"} />
      }
    </div>
  ) : "-"
}

const EditUserPage = ({ allowedRoles }) => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((state) => state.local)
  const { session } = useAppState((state) => state.auth)
  const { user, userDetail } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()
  const { userId } = useParams()
  const location = useLocation()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_USER))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    if (userId) {
      dispatcher(getUserDetailByUserId(Number(userId)))
    }

    return () => {
      dispatcher(setClearUserDetail())
    }
  }, [userId])

  const rows = [
    <TutorImageContainer profilePicUrl={userDetail?.userDetail?.profilePicUrl} />,
    userDetail?.userDetail?.userId,
    userDetail?.userDetail?.firstName + " " + userDetail?.userDetail?.lastName?.charAt(0),
    <TutorEmailContainer
      email={userDetail?.userDetail?.email}
      isEmailVerified={userDetail?.userDetail?.isEmailVerified}
    />,
    <TutorMobileNoContainer
      mobileNo={userDetail?.userDetail?.mobileNo}
      isdCode={userDetail?.userDetail?.isdCode}
      isMobileNoVerified={userDetail?.userDetail?.isMobileNoVerified}
    />,
    dayjs(userDetail?.userDetail?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
    <Link
      to={`${pagesInfo?.VIEW_USER?.pagePath}/${userDetail?.userDetail?.userId}`}
      className={"px-2 py-1 text-text-700"}
    >
      <FaEye className={"text-xl hover:scale-110"} />
    </Link>
  ]

  if (userDetail?.isLoading) {
    return (
      <FullPageLoader isLoading={userDetail?.isLoading} />
    )
  }

  if (userDetail?.errorMsg) {
    return (
      <div className={"w-full h-full font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
        {userDetail?.errorMsg}
      </div>
    )
  }

  return (
    <PageContentStyle>
      <div className={"w-full h-full min-h-screen mx-auto p-5 space-y-3"}>
        <DetailedView
          headers={Object.values(editUserHeaderConst)}
          rows={rows}
          rowHeaderColor={"bg-background-medium"}
          rowColor={`bg-${userStatus[userDetail?.userDetail?.status?.toUpperCase()]?.lightColor}`}
        />
        <div className={"w-full flex flex-col items-start justify-start space-y-3 md:space-y-0 md:space-x-3 md:flex-row"}>
          <div className={"sticky top-[5rem] w-full md:w-fit"}>
            <PageNavigationOptions options={Object.values(editUserNavigationOptions)?.filter((navigationOption) => !!navigationOption?.isShowNavigation)} />
          </div>
          <div className={"w-full rounded-lg p-5 bg-white"}>
            {session?.isLoggedIn && user?.user?.roles?.find((role) => allowedRoles?.includes(role?.role) && role.role === locals.userRole)
              ? <Outlet />
              : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: location }} replace />
            }
          </div>
        </div>
      </div>
    </PageContentStyle>
  )
}

export default EditUserPage;