import { useEffect } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/viewCourseDetail/viewCourse.style';
import { inputSections } from "pages/auth/viewCourseDetail/data";

import { getMasterLanguageList } from 'redux/master/master.request';
import { getCourseLanguageList } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearLanguageList } from 'redux/master/master.slice';
import { setClearAddCourseLanguageDetail, setClearCourseLanguageList } from 'redux/course/course.slice';

const CourseLanguageSection = ({ clickedItem, setClickedItem }) => {
    const { courseDetail, courseLanguageList } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (clickedItem === inputSections?.COURSE_LANGUAGE?.value) {
            dispatcher(getMasterLanguageList())
            dispatcher(getCourseLanguageList({ course: courseDetail?.courseDetail?.id }))
        }

        return () => {
            dispatcher(setClearLanguageList())
            dispatcher(setClearCourseLanguageList())
            dispatcher(setClearAddCourseLanguageDetail())
        }
    }, [clickedItem])

    const isOpen = inputSections?.COURSE_LANGUAGE?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_LANGUAGE?.value)) {
            setClickedItem(inputSections?.COURSE_LANGUAGE?.value)
        } else {
            setClickedItem(null)
        }
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Language of instruction"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {courseLanguageList?.isLoading &&
                    <ComponentLoader isLoading={courseLanguageList?.isLoading} />
                }
                {courseLanguageList?.ErrorMsg &&
                    <div className={"flex items-center justify-start font-bodyPri font-normal text-red-500 text-base"}>
                        {courseLanguageList?.errorMsg}
                    </div>
                }
                {(!courseLanguageList?.isLoading && courseLanguageList?.courseLanguageList?.results?.length > 0) &&
                    <div className={"w-full flex flex-col items-start justify-start gap-2 my-3"}>
                        <div className={"flex flex-wrap items-start justify-start gap-3"}>
                            {courseLanguageList?.courseLanguageList?.results?.map((courseLanguage, idx) => (
                                <div
                                    key={idx}
                                    className={'w-fit px-3 py-1 border-2 border-text-300 flex items-center justify-start gap-1.5 rounded-xl'}
                                >
                                    <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                        {courseLanguage?.masterLanguage?.language}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {(!courseLanguageList?.isLoading && (!courseLanguageList?.courseLanguageList || courseLanguageList?.courseLanguageList?.results?.length === 0)) &&
                    <div className={"font-bodyPri font-normal text-text-900 text-lg tracking-wide"}>
                        {"No Language Found!"}
                    </div>
                }
            </CollapsableContainer >
        </div >
    )
}

export default CourseLanguageSection;