import { createAsyncThunk } from "@reduxjs/toolkit";

import storageService from "redux/storage/storage.service";
import {
    setUploadFileLoading,
    setUploadFileData,
    setUploadFileMessage
} from "redux/storage/storage.slice";
import { storageRedux } from "redux/storage/storage.const";

import { generateFormData } from "redux/storage/storage.utils";

export const uploadFileToS3 = createAsyncThunk(
    `${storageRedux?.sliceName}/${storageRedux?.uploadFileToS3?.requestName}`,
    async (uploadFileDetailPayload, { dispatch }) => {
        dispatch(setUploadFileLoading(true));

        try {
            const formData = await generateFormData(uploadFileDetailPayload?.fileDetail, uploadFileDetailPayload?.fileOwnerId)

            const requestData = {
                body: formData
            }
            const response = await storageService.uploadFileToS3(requestData)
            if (response.status === 200) {
                dispatch(setUploadFileData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setUploadFileMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setUploadFileLoading(false));
        }
    }
)