import { availabilityFilterProps, modifyAvailabilityDetailPayload, addAvailabilityDetailPayload } from "redux/availability/availability.const";

export const AVAILABILITY_INITIAL_STATE = {
    availabilityList: {
        isLoading: false,
        data: null,
        message: null
    },
    availabilityDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    addAvailabilityDetail: {
        isLoading: false,
        data: null,
        payload: addAvailabilityDetailPayload,
        message: null
    },
    modifyAvailabilityDetail: {
        isLoading: false,
        data: null,
        payload: modifyAvailabilityDetailPayload,
        message: null
    },
    destroyAvailabilityDetail: {
        isLoading: false,
        message: null
    },

    // available slot detail
    availableSlotDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    filterProps: availabilityFilterProps,
}