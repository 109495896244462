import { setAndGetLocalStorage } from "redux/local/local.utils"
import { localsConst } from 'redux/local/local.const';


export const LOCAL_INITIAL_STATE = {
    locals: {
        userRole: setAndGetLocalStorage(localsConst.USER_ROLE.value),
        displayLanguage: setAndGetLocalStorage(localsConst.DISPLAY_LANGUAGE.value),
        currencyCode: setAndGetLocalStorage(localsConst.CURRENCY_CODE.value),
        timeZone: setAndGetLocalStorage(localsConst.TIME_ZONE.value),
        timeFormat: setAndGetLocalStorage(localsConst.TIME_FORMAT.value)
    },

    pages: {
        isThankYou: false
    },

    shareSocial: {
        socialAction: null,
        url: ""
    },

    stickyNotePayload: {
        isLoading: false,
        title: "Edit Comments",
        commentsType: '',
        id: null,
        comments: "",
        note: "",
    },

    modal: {
        isShowRoleSwitchModal: false,
        isShowSubmitCommentModal: false,
        isShowSubmitCourseModal: false,
        isShowImagePickerModal: false,
        isShowCompanyPeopleModal: false,
        isShowPaymentMethodModal: false,
        isShowAddCreditModal: false,
        isShowWithdrawMoneyModal: false,
        isShowConfirmPayoutModal: false,
        isShowWithdrawalRequestSubmitModal: false,
        isShowValidationBarEmailVerifyModal: false,
        isShowValidationBarMobileVerifyModal: false,
        isShowStickyNoteModal: false,
        isShowUploadVideoModal: false,
        isShowUploadDocumentModal: false,
        isShowSearchPhotoModal: false,
        isShowAddCourseModal: false,
        isShowAddSubscriptionPlanModal: false,
        isShowAddBookingModal: false,
        isShowBookingSummaryModal: false,
        isShowAddBookingSessionModal: false,
        isShowRescheduleTimeSlotModal: false,
        isShowAddUserDetailModal: false,
        isShowViewUserAvailabilityModal: false,
        isShowEditUserAvailabilityModal: false,
        isShowAddNextCourseSessionModal: false,
        isShowAddCourseTutorModal: false,
        isShowAddSessionUserModal: false,
        isShowManualPaymentDetailModal: false,
        isShowAddUserWalletCreditModal: false,
        isShowWalletCreditSuccessModal: false,
    },
}