import { memo } from 'react';

import { cn } from "utils/cn.utils";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = {
    modules: {
        toolbar: [
            // [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            // [{ size: [] }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            ['link'],
            // [{ 'color': [] }, { 'background': [] }],
            // ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    theme: 'snow',
    formats: [
        // 'header', 'font', 'size',
        'bold', 'italic', 'underline',
        // 'list', 'bullet', 'indent',
        'link',
        // 'color', 'background'
    ]
}

const QuillEditor = memo(({
    className = "",
    placeholder = "Write something...",
    editorToolModules = Editor.modules,
    editorToolFormats = Editor.formats,
    readOnly = false,
    editorText = "",
    onHandleChangeText,
    ...rest
}) => {

    return (
        <ReactQuill
            id={"editor"}
            className={cn("h-fit rounded-lg border border-text-500", className)}
            placeholder={placeholder}
            modules={editorToolModules}
            formats={editorToolFormats}
            theme={"snow"}
            readOnly={readOnly}
            value={editorText}
            onChange={onHandleChangeText}
            {...rest}
        />
    )
});

export default QuillEditor;