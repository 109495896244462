import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import FullPageLoader from 'components/loader/FullPageLoader';
import ButtonLoader from 'components/loader/ButtonLoader';
import { OptionSelector } from 'components/common-components/Select';

import { getUserItemDetailByUserItemId, createUserItemDetail, updateUserItemDetailByUserItemId } from "redux/chargeBee/chargeBee.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddUserItemDetailPayload, setClearUserItemDetail, setClearAddUserItemDetail } from "redux/chargeBee/chargeBee.slice"
import { productFamily, subscriptionType } from 'redux/chargeBee/chargeBee.const';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const AddSubscriptionPlanModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userItemDetail, addUserItemDetail } = useAppState((state) => state.chargeBee)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (addUserItemDetail?.addUserItemDetailPayload?.userItemId) {
            dispatcher(getUserItemDetailByUserItemId(addUserItemDetail?.addUserItemDetailPayload?.userItemId))
        }

        // return () => {
        //     dispatcher(setClearUserItemDetail())
        // }
    }, [addUserItemDetail?.addUserItemDetailPayload?.userItemId])

    useEffect(() => {
        if (userItemDetail?.userItemDetail) {
            dispatcher(setAddUserItemDetailPayload({
                userItemId: addUserItemDetail?.addUserItemDetailPayload?.userItemId,
                productFamily: userItemDetail?.userItemDetail?.item?.item_family_id,
                name: userItemDetail?.userItemDetail?.item?.name,
                description: userItemDetail?.userItemDetail?.item?.description,
                type: userItemDetail?.userItemDetail?.item?.type
            }))
        }
    }, [userItemDetail?.userItemDetail])

    useEffect(() => {
        if (addUserItemDetail?.addUserItemDetail) {
            dispatcher(setClearAddUserItemDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_SUBSCRIPTION_PLAN_MODAL.stateKey]: false
            }))
        }
    }, [addUserItemDetail?.addUserItemDetail])

    const onSubmit = (event) => {
        event.preventDefault()

        // if (!addUserItemDetail?.addUserItemDetailPayload?.productFamily) {
        //     alert("Please select product family!")
        //     return;
        // }

        const body = {
            userId: user?.user?.userId,
            itemFamilyId: productFamily?.EDULYTE?.value,
            name: addUserItemDetail?.addUserItemDetailPayload?.name,
            description: addUserItemDetail?.addUserItemDetailPayload?.description,
            type: addUserItemDetail?.addUserItemDetailPayload?.type
        }

        if (addUserItemDetail?.addUserItemDetailPayload?.userItemId) {
            dispatcher(updateUserItemDetailByUserItemId(addUserItemDetail?.addUserItemDetailPayload?.userItemId, body))
            return;
        }

        dispatcher(createUserItemDetail(body))
    }

    if (userItemDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={userItemDetail?.isLoading} className={"w-full h-[28rem]"} />
        )
    }

    return (
        <form className={"space-y-5 overflow-x-hidden"} onSubmit={onSubmit}>
            <div className={"min-h-[24rem] block pt-5 space-y-5"}>
                <div className={"w-full flex flex-col items-start justify-start gap-5"}>

                    {/* product family container */}
                    {/* <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"Product Family"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <span className={"w-full"}>
                            <OptionSelector
                                options={Object.values(productFamily)}
                                className={"w-full"}
                                value={addUserItemDetail?.addUserItemDetailPayload?.productFamily}
                                onChange={(option) => dispatcher(setAddUserItemDetailPayload({
                                    ...addUserItemDetail?.addUserItemDetailPayload,
                                    productFamily: option.value
                                }))}
                                required={true}
                            />
                        </span>
                    </div> */}

                    {/* name container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"Name"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            placeholder={"Enter plan name"}
                            value={addUserItemDetail?.addUserItemDetailPayload?.name}
                            onChange={(event) => dispatcher(setAddUserItemDetailPayload({
                                ...addUserItemDetail?.addUserItemDetailPayload,
                                name: event?.target?.value
                            }))}
                            required={true}
                        />
                    </div>

                    {/* description container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"Description"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <textarea
                            type={"text"}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            placeholder={"Enter plan Description"}
                            rows={5}
                            minLength={20}
                            maxLength={500}
                            value={addUserItemDetail?.addUserItemDetailPayload?.description}
                            onChange={(event) => dispatcher(setAddUserItemDetailPayload({
                                ...addUserItemDetail?.addUserItemDetailPayload,
                                description: event?.target?.value
                            }))}
                            required={true}
                        />
                    </div>

                    {/* type container */}
                    {/* <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                            {"Type"}
                        </span>
                        <input
                            type={"text"}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            value={subscriptionType[addUserItemDetail?.addUserItemDetailPayload?.type?.toUpperCase()]?.label}
                            disabled={true}
                        />
                    </div> */}
                </div>
                {addUserItemDetail?.errorMsg &&
                    <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {addUserItemDetail?.errorMsg}
                    </div>
                }
            </div>

            <div className={"flex justify-end"}>
                {/* <div
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        "text-text-600 border border-text-600 hover:bg-text-600 hover:text-text-50",
                    )}
                    onClick={() => dispatcher(setClearAddUserItemDeta)}
                    >
                    {"Reset"}
                </div> */}
                <button
                    type={"submit"}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        !addUserItemDetail?.isLoading && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        addUserItemDetail?.isLoading && "bg-secondary-main"
                    )}>
                    {addUserItemDetail?.isLoading && <ButtonLoader isLoading={addUserItemDetail?.isLoading} />}
                    {!addUserItemDetail?.isLoading && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default AddSubscriptionPlanModal;