import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from './data';
import ReferralSearch from './components/ReferralSearch';
import DisplayReferralTable from './components/DisplayReferralTable';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';


const ReferralPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.REFERRAL))
  }, [dispatcher, currentPageInfo])


  return (
    <div className={"h-full w-full p-3 mx-auto min-h-screen space-y-3"}>
      <PageHeader pageHeading={pageHeading} />
      <ReferralSearch />
      <DisplayReferralTable />
    </div>
  );
};

export default ReferralPage;
