import { useEffect, useState } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from 'pages/auth/onboarding/data';
import TutorOnboardingSearch from 'pages/auth/onboarding/components/tutorOnboarding/TutorOnboardingSearch';
import CompanyOnboardingSearch from './components/companyOnboarding/CompanyOnboardingSearch';
import TutorOnboardingTable from 'pages/auth/onboarding/components/tutorOnboarding/TutorOnboardingTable';
import CompanyOnboardingTable from "pages/auth/onboarding/components/companyOnboarding/CompanyOnboardingTable";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { onboardingTypeConst } from 'redux/onboarding/onboarding.const';
import { dataEntriesConst } from "redux/local/local.const";

import { pagesInfo } from 'utils/pagesInfo';

const OnboardingPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  const [showEntries, setShowEntries] = useState(dataEntriesConst?.TWENTY_FIVE?.value)
  const [activeTab, setActiveTab] = useState(onboardingTypeConst.TUTOR.value)

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.ONBOARDING))
  }, [dispatcher, currentPageInfo])

  return (
    <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3'>
      <PageHeader pageHeading={pageHeading} />

      {activeTab === onboardingTypeConst.TUTOR.value &&
        <>
          <TutorOnboardingSearch showEntries={showEntries} setShowEntries={setShowEntries} activeTab={activeTab} setActiveTab={setActiveTab} />
          <TutorOnboardingTable showEntries={showEntries} />
        </>
      }

      {activeTab === onboardingTypeConst.COMPANY.value &&
        <>
          <CompanyOnboardingSearch showEntries={showEntries} setShowEntries={setShowEntries} activeTab={activeTab} setActiveTab={setActiveTab} />
          <CompanyOnboardingTable showEntries={showEntries} />
        </>
      }
    </div>
  )
}

export default OnboardingPage