import { payoutType } from "redux/payout/payout.const";
import { withdrawalStatus } from "redux/withdrawal/withdrawal.const";

import { dayjs } from "utils/dateTime.utils";

const WithdrawItem = ({ withdrawalItem }) => {
    return (
        <div className={"space-y-1"}>
            <div className="flex flex-row items-center justify-between">
                <span className={"font-bodyPri font-medium text-base text-text-900 truncate"}>
                    {payoutType[withdrawalItem?.payoutType?.toUpperCase()]?.label}
                </span>
                <div className={"flex items-center justify-center gap-1"}>
                    <span className={'font-bodyPri font-normal text-sm text-primary-main'}>
                        {"USD "}
                    </span>
                    <span className={'font-bodyPri font-normal text-sm text-primary-main'}>
                        {parseFloat(withdrawalItem.amount / 100).toFixed(2)}
                    </span>
                </div>
            </div>
            <div className={"flex flex-row items-center justify-between"}>
                <span className={"font-bodyPri font-base text-sm text-text-900"}>
                    {dayjs(withdrawalItem.createdAt).local().format('DD/MM/YY hh:mm a').toString()}
                </span>
                <span className={'font-bodyPri font-normal text-sm text-text-900 capitalize'}>
                    {withdrawalStatus[withdrawalItem.status?.toUpperCase()]?.label + " " + `(${withdrawalItem?.isPaid})`}
                </span>
            </div>
        </div>
    )
}

export default WithdrawItem;