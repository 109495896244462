import { useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getBookingList } from "redux/booking/booking.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearFilterProps, setFilterProps } from "redux/booking/booking.slice";
import { bookingStatusEnum, bookingOfferingTypeEnum } from "redux/booking/booking.const";
import { dateRangesLabels } from "redux/local/local.const";

import { dayjs } from "utils/dateTime.utils";

export const SearchBookingId = () => {
    const { filterProps } = useAppState((state) => state.booking)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"bookingId"}
            value={filterProps?.bookingId}
            placeholder={"Search Booking ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, bookingId: e.target.value },
            }))}
        />
    )
}

export const SearchSessionCount = () => {

    return (
        <input
            type={"number"}
            name={"sessionCount"}
            value={""}
            placeholder={"Search Session Count"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            disabled={true}
        />
    )
}

export const SearchUser = () => {
    const { filterProps } = useAppState((state) => state.booking)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            value={filterProps?.userId}
            placeholder={"Search User ID"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, userId: e.target.value },
            }))}
        />
    )
}

export const SearchOfferingType = () => {
    const { filterProps } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(bookingOfferingTypeEnum)}
            value={filterProps.offeringType}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, offeringType: option.value }
            }))
            }
        />
    )
}

export const SearchBookingPrice = () => {
    const { filterProps } = useAppState((state) => state.booking)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"bookingId"}
            value={filterProps?.price}
            placeholder={"Search Price"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, price: e.target.value },
            }))}
        />
    )
}

export const FilterStatus = () => {
    const { filterProps } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(bookingStatusEnum)}
            value={filterProps.status}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, status: option.value }
            }))}
        />
    )
}

export const SearchCreatedDate = () => {
    const { filterProps } = useAppState((state) => state.course)
    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            // onCallback={(start, end, label) => console.log(start?._d, end?._d, label)}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "w-full py-0.5 flex items-center justify-center text-center gap-1 bg-white",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
                disabled={true}
            />
        </DateRangePicker>
    )
}

export const SearchUpdatedDate = () => {
    const { filterProps } = useAppState((state) => state.course)
    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            // onCallback={(start, end, label) => console.log(start?._d, end?._d, label)}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "w-full py-0.5 flex items-center justify-center text-center gap-1 bg-white",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
                disabled={true}
            />
        </DateRangePicker>
    )
}

export const ButtonAction = () => {
    const { filterProps } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    const handleApply = () => {

        const requestDataPayload = {
            page: 1,
            records: 25
        }
        if (filterProps?.bookingId) {
            requestDataPayload["id"] = filterProps?.bookingId
        }
        if (filterProps?.userId) {
            if (isNaN(filterProps?.userId)) {
                requestDataPayload["userFullName"] = filterProps?.userId
            } else {
                requestDataPayload["userId"] = filterProps?.userId
            }
        }
        if (filterProps?.offeringType) {
            requestDataPayload["offeringType"] = filterProps?.offeringType
        }
        if (filterProps?.bookingPrice) {
            requestDataPayload["price"] = filterProps?.bookingPrice
        }
        if (filterProps?.status) {
            requestDataPayload["status"] = filterProps?.status
        }
        dispatcher(getBookingList(requestDataPayload))
    }

    const handleClear = () => {
        dispatcher(getBookingList({ page: 1, records: 25 }))
        dispatcher(setClearFilterProps())
    }

    return (
        <div className="w-full flex items-center justify-center gap-1">
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-primary-main text-text-50 hover:bg-primary-dark",
                )}
                onClick={handleApply}
            >
                {"Apply"}
            </span>
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-background-lightDark text-text-50 hover:bg-background-dark",
                )}
                onClick={handleClear}
            >
                {"Clear"}
            </span>
        </div>
    )
}