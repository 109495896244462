import { useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getTutorList } from "redux/tutor/tutor.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearFilterProps, setFilterProps } from "redux/tutor/tutor.slice";
import { dateRangesLabels } from "redux/local/local.const";
import { tutorStatus, tutorVisibilityEnum } from "redux/tutor/tutor.const";

import { dayjs } from "utils/dateTime.utils";

export const TutorImage = () => {
    return (
        <div className="w-full h-full flex items-center justify-center font-bodyPri font-semibold text-text-900 text-base">
            {"#"}
        </div>
    );
}

export const SearchTutorId = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"tutorId"}
            value={filterProps?.tutorId}
            placeholder={"Search Tutor ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, tutorId: e.target.value }
            }))}
        />
    )
}

export const SearchUserId = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"userId"}
            value={filterProps?.userId}
            placeholder={"Search User ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, userId: e.target.value }
            }))}
        />
    )
}

export const SearchTutorName = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            value={filterProps.tutorName}
            placeholder={"Search Name"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, tutorName: e.target.value }
            }))}
        />
    )
}

export const SearchPhone = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"Search Phone"}
            value={filterProps?.mobileNo}
            placeholder={"Search Mobile No"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, mobileNo: e.target.value }
            }))}
            disabled={true}
        />
    )
}

export const SearchEmail = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"Email"}
            value={filterProps?.email}
            placeholder={"Search Email"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, email: e.target.value }
            }))}
        />
    )
}

export const FilterTutorStatus = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(tutorStatus)}
            value={filterProps.status}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, status: option.value }
            }))}
        />
    )
}

export const FilterTutorVisibility = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(tutorVisibilityEnum)}
            value={filterProps.visibility}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, visibility: option.value }
            }))}
        />
    )
}

export const SearchDate = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "w-full py-0.5 flex items-center justify-center text-center gap-1 bg-text-300",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
                disabled={true}
            />
        </DateRangePicker>
    )
}

export const ButtonAction = () => {
    const { filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    const handleApply = () => {
        const requestDataPayload = {
            page: filterProps?.page || 1,
            records: filterProps?.records || 25
        }
        if (filterProps?.tutorId) {
            requestDataPayload["tutorId"] = filterProps?.tutorId
        }
        if (filterProps?.userId) {
            requestDataPayload["userId"] = filterProps?.userId
        }
        if (filterProps?.tutorName) {
            requestDataPayload["tutorName"] = filterProps?.tutorName
        }
        if (filterProps?.email) {
            requestDataPayload["email"] = filterProps?.email
        }
        if (filterProps?.mobileNo) {
            requestDataPayload["mobileNo"] = filterProps?.mobileNo
        }
        if (filterProps?.status) {
            requestDataPayload["status"] = filterProps?.status
        }
        if (filterProps?.visibility) {
            requestDataPayload["visibility"] = filterProps?.visibility
        }

        dispatcher(getTutorList(requestDataPayload))
    }

    const handleClear = () => {
        dispatcher(setClearFilterProps())
        dispatcher(getTutorList({ page: 1, records: 25 }))
    }

    return (
        <div className="w-full flex items-center justify-center gap-1">
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-primary-main text-text-50 hover:bg-primary-dark",
                )}
                onClick={handleApply}
            >
                {"Apply"}
            </span>
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-background-lightDark text-text-50 hover:bg-background-dark",
                )}
                onClick={handleClear}
            >
                {"Clear"}
            </span>
        </div>
    )
}