import { useState } from 'react';

import SetPriceDetailsSection from './components/SetPriceDetailsContainer';

const SetPrice = () => {
    const [clickedItem, setClickedItem] = useState('')

    return (
        <div className={"space-y-5"}>
            <SetPriceDetailsSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
        </div>
    )
}

export default SetPrice;