import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";
// import { AiFillCheckCircle } from "react-icons/ai";
// import { AiOutlineClose } from "react-icons/ai";

import SelectSearch, { fuzzySearch } from 'react-select-search';

import { OptionSelector } from "components/common-components/Select";
import ComponentLoader from "components/loader/ComponentLoader";

import SmallCourseBtn from "pages/auth/editCourseDetail/SmallCourseBtn";
import CreateCourseTooltip from "pages/auth/editCourseDetail/EditCourseTooltip";
import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/editCourseDetail/editCourseDetail.style';
import { editBtnConst, inputSections, titleToolTip, subTitleToolTip, visibilityTooltip, classTypeTooltip } from "pages/auth/editCourseDetail/data";

import { getAllCategoryList } from "redux/category/category.request";
import { updateCourseDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearAddCourseDetail } from "redux/course/course.slice";
import { setClearCategoryList } from "redux/category/category.slice";
import { courseType, visibilityType } from "redux/course/course.const";

const CourseDetailsSection = ({ clickedItem, setClickedItem }) => {
    const { categoryList } = useAppState((state) => state.category)
    const { courseDetail, addCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [courseDetailData, setCourseDetailData] = useState(courseDetail?.courseDetail)

    useEffect(() => {
        dispatcher(getAllCategoryList())

        return () => {
            dispatcher(setClearCategoryList())
        }
    }, [])

    const selectedSegment = categoryList?.categoryList?.find((category) => category?.category === courseDetailData?.category)

    const isCourseTitleSimilar = JSON.stringify(courseDetailData?.title) === JSON.stringify(courseDetail?.courseDetail?.title)
    const isCourseTitleDesSimilar = JSON.stringify(courseDetailData?.subTitle) === JSON.stringify(courseDetail?.courseDetail?.subTitle)
    const isVisibilitySimilar = JSON.stringify(courseDetailData?.visibility) === JSON.stringify(courseDetail?.courseDetail?.visibility)
    const isCategorySimilar = JSON.stringify(courseDetailData?.category) === JSON.stringify(courseDetail?.courseDetail?.category)
    const isStudentCapacitySimilar = JSON.stringify(courseDetailData?.studentCapacity) === JSON.stringify(courseDetail?.courseDetail?.studentCapacity)

    const isOpen = inputSections?.COURSE_DETAILS?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_DETAILS?.value)) {
            setClickedItem(inputSections?.COURSE_DETAILS?.value)
        } else {
            setClickedItem(null)
        }
    }

    const onHandleRestCourseDetail = (editBtnValue) => {
        if (isCourseTitleSimilar && isCourseTitleDesSimilar && isVisibilitySimilar && isCategorySimilar) return;

        setCourseDetailData({
            ...courseDetailData,
            [editBtnValue]: courseDetail?.courseDetail[editBtnValue]
        })
    }

    const handleUpdateCourseDetail = (editBtnValue) => {
        if (isCourseTitleSimilar && isCourseTitleDesSimilar && isVisibilitySimilar && isCategorySimilar && isStudentCapacitySimilar) return;


        if ((editBtnValue === editBtnConst?.courseTitle?.value) && courseDetailData?.title?.length > 80) {
            toast.warn("Class title can have maximum 80 characters!")
            return;
        }

        if ((editBtnValue === editBtnConst?.studentCapacity?.value) && courseDetailData?.type === courseType?.ONE_ON_ONE?.value) return;
        const requestDataPayload = {
            id: courseDetail?.courseDetail?.id,
            body: {
                [editBtnValue]: courseDetailData[editBtnValue]
            }
        }
        dispatcher(setClearAddCourseDetail())
        dispatcher(updateCourseDetail(requestDataPayload))
    }

    // if (addCourseDetail?.isLoading) {
    //     return (
    //         <ComponentLoader isLoading={addCourseDetail?.isLoading} />
    //     )
    // }

    return (
        <div className={"w-full h-full select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Details"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {(courseDetail?.isLoading || addCourseDetail?.isLoading) &&
                    <ComponentLoader isLoading={courseDetail?.isLoading || addCourseDetail?.isLoading} />
                }
                {/* class title container */}
                {!addCourseDetail?.isLoading &&
                    <div className={"w-full flex flex-col items-start justify-start gap-3 px-3"}>
                        <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                            <div className={"w-full flex items-center justify-start gap-1"}>
                                <span className="font-bodyPri font-normal text-base text-text-900 tracking-wide">
                                    {"Class Slug:"}
                                </span>
                            </div>
                            <input
                                type={"text"}
                                maxLength={80}
                                value={courseDetailData?.slug}
                                className={cn(
                                    "w-full px-3 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-200"
                                )}
                                disabled={true}
                            />
                        </div>

                        {/* class title */}
                        <div className={"w-full flex flex-col items-start justify-center gap-5 md:gap-2"}>
                            <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-1 md:gap-3"}>
                                <div className={"flex items-center justify-start gap-1"}>
                                    <CreateCourseTooltip tooltip={titleToolTip} translateX={0} translateY={0} />
                                    <span className="font-bodyPri font-normal text-base text-text-900 tracking-wide">
                                        {"Class Name:"}
                                        <span className={"text-lg text-red-500"}>{"*"}</span>
                                    </span>
                                </div>
                                <SmallCourseBtn
                                    isShowCancelBtn={true}
                                    isCancelBtnDisabled={isCourseTitleSimilar}
                                    isSaveBtnDisabled={isCourseTitleSimilar}
                                    onSave={() => handleUpdateCourseDetail(editBtnConst.courseTitle.value)}
                                    onCancel={() => onHandleRestCourseDetail(editBtnConst.courseTitle.value)}
                                />
                            </div>
                            <input
                                type={"text"}
                                maxLength={80}
                                value={courseDetailData?.title}
                                className={cn(
                                    "w-full px-3 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => setCourseDetailData({
                                    ...courseDetailData,
                                    title: e.target.value
                                })}
                            />
                        </div>

                        {/* class title container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-5 md:gap-2"}>
                            <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-1 md:gap-3"}>
                                <div className={"flex items-center justify-start gap-1"}>
                                    <CreateCourseTooltip tooltip={subTitleToolTip} translateX={0} translateY={0} />
                                    <span className={"font-bodyPri font-normal text-base text-text-900 tracking-wide"}>
                                        {"Class Sub Heading:"}
                                        <span className={"text-lg text-red-500"}>{"*"}</span>
                                    </span>

                                </div>
                                <SmallCourseBtn
                                    isShowCancelBtn={true}
                                    isCancelBtnDisabled={isCourseTitleDesSimilar}
                                    isSaveBtnDisabled={isCourseTitleDesSimilar}
                                    onSave={() => handleUpdateCourseDetail(editBtnConst.courseTitleDescription.value)}
                                    onCancel={() => onHandleRestCourseDetail(editBtnConst.courseTitleDescription.value)}
                                />
                            </div>
                            <textarea
                                type={"text"}
                                value={courseDetailData?.subTitle}
                                rows={3}
                                maxLength={250}
                                className={cn(
                                    "w-full px-3 py-1.5 resize-y border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => setCourseDetailData({
                                    ...courseDetailData,
                                    subTitle: e.target.value
                                })}
                            />
                        </div>

                        {/* category list container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-5 md:gap-2"}>
                            <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-1 md:gap-3"}>
                                <span className={"font-bodyPri font-normal text-base text-text-900 tracking-wide"}>
                                    {"Category:"}
                                    <span className={"text-lg text-red-500"}>{"*"}</span>
                                </span>
                                <SmallCourseBtn
                                    // isLoading={addCourseDetail?.isLoading}
                                    isShowCancelBtn={true}
                                    isCancelBtnDisabled={isCategorySimilar}
                                    isSaveBtnDisabled={isCategorySimilar}
                                    onSave={() => handleUpdateCourseDetail(editBtnConst.category.value)}
                                    onCancel={() => onHandleRestCourseDetail(editBtnConst.category.value)}
                                />
                            </div>
                            {categoryList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {categoryList?.categoryList &&
                                <div className={cn(
                                    "w-full sm:w-60 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}>
                                    <SelectSearch
                                        options={categoryList?.categoryList?.map((category) => ({
                                            name: category.category,
                                            value: category.category,
                                        }))}
                                        search
                                        autoFocus={false}
                                        placeholder={"Select Category"}
                                        filterOptions={fuzzySearch}
                                        value={courseDetailData?.category}
                                        onChange={(option) => setCourseDetailData({
                                            ...courseDetailData,
                                            category: option
                                        })
                                        }
                                        required={true}
                                    />
                                </div>
                            }
                            {(categoryList?.errorMsg) &&
                                <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                    {categoryList?.errorMsg}
                                </div>
                            }
                        </div>


                        {/* segment container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2"}>
                            <span className={"text-text-600 lg:whitespace-nowrap"}>
                                {"Segment:"}
                            </span>
                            <input
                                type={"text"}
                                value={selectedSegment?.segment}
                                className={cn(
                                    "w-full sm:w-60 px-3 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-600 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                disabled={true}
                            />
                        </div>

                        {/* visibility container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-5 md:gap-3"}>
                            <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-1 md:gap-3"}>
                                <div className={"flex items-center justify-start gap-1"}>
                                    <CreateCourseTooltip tooltip={visibilityTooltip} translateX={0} translateY={100} />
                                    <span className={"font-bodyPri font-normal text-base text-text-900 tracking-wide"}>
                                        {"Visibility:"}
                                        <span className={"text-lg text-red-500"}>{"*"}</span>
                                    </span>
                                </div>
                                <SmallCourseBtn
                                    isShowCancelBtn={true}
                                    isCancelBtnDisabled={isVisibilitySimilar}
                                    isSaveBtnDisabled={isVisibilitySimilar}
                                    onSave={() => handleUpdateCourseDetail(editBtnConst.visibility.value)}
                                    onCancel={() => onHandleRestCourseDetail(editBtnConst.visibility.value)}
                                />
                            </div>
                            <div className={"w-full sm:w-60 h-32 border-2 border-divider-medium rounded-md"}>
                                <OptionSelector
                                    options={Object.values(visibilityType)}
                                    className={"w-full"}
                                    value={courseDetailData?.visibility}
                                    onChange={(option) => setCourseDetailData({
                                        ...courseDetailData,
                                        visibility: option?.value
                                    })}
                                    required={true}
                                />
                            </div>
                        </div>

                        {/* class type container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2"}>
                            <div className={"flex items-center justify-start gap-1"}>
                                <CreateCourseTooltip tooltip={classTypeTooltip} translateX={0} translateY={100} />
                                <span className={"font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                                    {"Class Type:"}
                                    <span className={"text-lg text-red-500"}>{"*"}</span>
                                </span>
                            </div>
                            <input
                                type={"text"}
                                value={courseType[courseDetailData?.type?.toUpperCase()]?.label}
                                className={cn(
                                    "w-full sm:w-60 px-3 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                disabled={true}
                            />
                        </div>

                        {/* participants container */}
                        <div className={"w-full flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2"}>
                            <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-1 md:gap-3"}>
                                <span className={"font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                                    {"Participants:"}
                                </span>

                                <SmallCourseBtn
                                    isShowCancelBtn={true}
                                    isCancelBtnDisabled={isStudentCapacitySimilar}
                                    isSaveBtnDisabled={isStudentCapacitySimilar}
                                    onSave={() => handleUpdateCourseDetail(editBtnConst.studentCapacity.value)}
                                    onCancel={() => onHandleRestCourseDetail(editBtnConst.studentCapacity.value)}
                                />
                            </div>
                            <div className={cn(
                                "w-full sm:w-60 px-3 py-1.5 flex items-center justify-between gap-3 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base overflow-hidden",
                                "placeholder:text-text-500 placeholder:text-sm",
                                (courseDetailData?.type === courseType?.ONE_ON_ONE?.value) && "bg-text-100"
                            )}>
                                <input
                                    type={"number"}
                                    value={courseDetailData?.studentCapacity?.toString()}
                                    className={cn(
                                        "w-[90%] px-2 focus:outline-none input-number-spin-none",
                                        "font-bodyPri font-normal text-text-800 text-base",
                                        "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                    )}
                                    onChange={(event) => {
                                        if (courseDetailData?.type === courseType?.ONE_ON_ONE?.value) return;
                                        const participantCount = Number(event?.target?.value)
                                        setCourseDetailData({
                                            ...courseDetailData,
                                            studentCapacity: participantCount > 0 ? participantCount : 0
                                        })
                                    }}
                                    max={20}
                                    maxLength={2}
                                    disabled={(courseDetailData?.type === courseType?.ONE_ON_ONE?.value) && true}
                                />
                                <span className={"w-[10%] font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                                    {"max"}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </CollapsableContainer>
        </div>
    )
}

export default CourseDetailsSection