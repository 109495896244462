import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import ButtonLoader from "components/loader/ButtonLoader";
import { OptionSelector } from 'components/common-components/Select';

import { getTutorDetail } from "redux/tutor/tutor.request";
import { createCourseTutorDetail, updateCourseTutorDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetTutorDetail } from "redux/tutor/tutor.slice";
import { resetAddCourseTutorDetail, resetUpdateCourseTutorDetail, setAddCourseTutorDetailPayload } from "redux/course/course.slice";
import { modalConst } from "redux/local/local.const";
import { masterCurrencyCodeEnum } from "redux/master/master.const";
import {
    courseFeeTypeEnum,
    courseTutorAutoPayEnum,
    courseTutorFeeModelEnum,
    courseTutorPermissionEnum,
    courseTutorRoleEnum,
    courseTutorStatus,
    courseTutorVisibilityTypeEnum
} from "redux/course/course.const";

const AddCourseTutorModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { tutorDetail } = useAppState((state) => state.tutor)
    const { addCourseTutorDetail, updateCourseTutor } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()

    const [isTutorVerified, setIsTutorVerified] = useState(addCourseTutorDetail?.payload?.isEditCourseTutorEnabled)

    useEffect(() => {
        return () => {
            dispatcher(resetTutorDetail())
            dispatcher(resetAddCourseTutorDetail())
            dispatcher(resetAddCourseTutorDetail())
            dispatcher(resetUpdateCourseTutorDetail())
        }
    }, [])


    useEffect(() => {
        if (addCourseTutorDetail?.data || updateCourseTutor?.data) {
            dispatcher(resetAddCourseTutorDetail())
            dispatcher(resetUpdateCourseTutorDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_COURSE_TUTOR_MODAL.stateKey]: false
            }))
        }
    }, [addCourseTutorDetail?.data || updateCourseTutor?.data])

    useEffect(() => {
        if (tutorDetail?.data) {
            setIsTutorVerified(true)
        }
    }, [tutorDetail?.data])

    const onHandleVerifyTutor = () => {
        if (tutorDetail?.isLoading) return;

        if (!addCourseTutorDetail?.payload?.tutorId) {
            alert("Please enter tutor ID")
            return;
        }
        dispatcher(getTutorDetail(addCourseTutorDetail?.payload?.tutorId))
    }

    const onHandleCourseTutorDetailForm = (event) => {
        event.preventDefault();

        if (!addCourseTutorDetail?.payload?.courseId) {
            alert("Please enter course ID!")
            return;
        }
        if (!addCourseTutorDetail?.payload?.tutorId && !addCourseTutorDetail?.payload?.isEditCourseTutorEnabled) {
            alert("Please enter tutor ID!")
            return;
        }
        if (!isTutorVerified && !addCourseTutorDetail?.payload?.isEditCourseTutorEnabled) {
            alert("Please verify tutor!")
            return;
        }
        if (!addCourseTutorDetail?.payload?.visibility) {
            alert("Please select visibility!")
            return;
        }
        if (!addCourseTutorDetail?.payload?.permission) {
            alert("Please select tutor permission!")
            return;
        }
        if (!addCourseTutorDetail?.payload?.tutorRole) {
            alert("Please select tutor Role!")
            return;
        }
        if (!addCourseTutorDetail?.payload?.status) {
            alert("Please select tutor status!")
            return;
        }
        if (!addCourseTutorDetail?.payload?.feeModel) {
            alert("Please select course fee model!")
            return;
        }
        if (!addCourseTutorDetail?.payload?.isAutoPay) {
            alert("Please select auto pay!")
            return;
        }
        if (!addCourseTutorDetail?.payload?.feeType) {
            alert("Please select course fee type!")
            return;
        }

        if (addCourseTutorDetail?.payload?.feeType === courseFeeTypeEnum?.FIXED?.value) {
            if (!addCourseTutorDetail?.payload?.amount) {
                alert("Please enter valid amount value!")
                return;
            }
            if (!addCourseTutorDetail?.payload?.currencyCode) {
                alert("Please select course currency type!")
                return;
            }
        }
        if (addCourseTutorDetail?.payload?.feeType === courseFeeTypeEnum?.PERCENTAGE?.value) {
            if (!addCourseTutorDetail?.payload?.percentage || addCourseTutorDetail?.payload?.percentage < 0 || addCourseTutorDetail?.payload?.percentage > 100) {
                alert("Please enter valid percentage value!")
                return;
            }
        }

        const body = {
            visibility: addCourseTutorDetail?.payload?.visibility,
            permission: addCourseTutorDetail?.payload?.permission,
            tutorRole: addCourseTutorDetail?.payload?.tutorRole,
            status: addCourseTutorDetail?.payload?.status,
            feeModel: addCourseTutorDetail?.payload?.feeModel,
            isAutoPay: addCourseTutorDetail?.payload?.isAutoPay,
            feeType: addCourseTutorDetail?.payload?.feeType,
        }

        if (addCourseTutorDetail?.payload?.feeType === courseFeeTypeEnum?.FIXED?.value) {
            body["currencyCode"] = addCourseTutorDetail?.payload?.currencyCode
            body["amount"] = addCourseTutorDetail?.payload?.amount
        }
        if (addCourseTutorDetail?.payload?.feeType === courseFeeTypeEnum?.PERCENTAGE?.value) {
            body["percentage"] = addCourseTutorDetail?.payload?.percentage
        }

        if (addCourseTutorDetail?.payload?.isEditCourseTutorEnabled) {
            const requestDataPayload = {
                courseTutorId: addCourseTutorDetail?.payload?.courseTutorId,
                body: body
            }
            dispatcher(updateCourseTutorDetail(requestDataPayload))
        } else {
            body["courseId"] = addCourseTutorDetail?.payload?.courseId
            body["tutorId"] = addCourseTutorDetail?.payload?.tutorId
            dispatcher(createCourseTutorDetail(body))
        }

    }

    return (
        <form onSubmit={onHandleCourseTutorDetailForm} className={"space-y-5 overflow-hidden"}>
            <div className={"min-h-[38rem] block w-full border border-text-400 space-y-3 pb-3"}>
                <div className={"space-y-1"}>
                    <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                        {"Tutor Details"}
                    </div>
                    <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
                        {"(Marked with * is mandatory field)"}
                    </span>
                </div>

                <div className="grid grid-cols-2 gap-5 px-2">

                    {/* course id container */}
                    <div className={'col-start-1 col-span-full sm:col-start-1 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Class ID"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"number"}
                            placeholder={"e.g. 123"}
                            value={addCourseTutorDetail?.payload?.courseId?.toString()}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            required
                            disabled={true}
                        />
                    </div>

                    {/* tutor id container */}
                    <div className={'col-start-1 col-span-full sm:col-start-2 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Tutor ID"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <div className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md flex items-center justify-between gap-3",
                            "font-bodyPri font-normal text-text-800 text-base",
                            addCourseTutorDetail?.payload?.isEditCourseTutorEnabled && "bg-text-100"
                        )}>
                            <input
                                type={"number"}
                                placeholder={"e.g. 123"}
                                value={addCourseTutorDetail?.payload?.tutorId?.toString()}
                                className={cn(
                                    "w-full px-1.5 input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                onChange={(e) => {
                                    if (addCourseTutorDetail?.payload?.isEditCourseTutorEnabled) return;
                                    if (isTutorVerified) {
                                        setIsTutorVerified(false)
                                    }
                                    dispatcher(setAddCourseTutorDetailPayload({
                                        ...addCourseTutorDetail?.payload,
                                        tutorId: Number(e.target.value)
                                    }))
                                }}
                                required
                                disabled={addCourseTutorDetail?.payload?.isEditCourseTutorEnabled}
                            />
                            {tutorDetail?.isLoading &&
                                <FaSpinner className={"animate-spin text-primary-dark text-base"} />
                            }
                            {(!tutorDetail?.isLoading && isTutorVerified) &&
                                <span
                                    className={"px-1 font-bodyPri font-normal text-xs text-green-500"}
                                >
                                    {"Verified"}
                                </span>
                            }
                            {(!tutorDetail?.isLoading && !isTutorVerified) &&
                                <span
                                    className={cn(
                                        "px-1 py-0.5 border border-secondary-dark hover:bg-secondary-dark hover:text-text-50 rounded-md",
                                        "font-bodyPri font-normal text-xs text-secondary-dark cursor-pointer"
                                    )}
                                    onClick={onHandleVerifyTutor}
                                >
                                    {"Verify"}
                                </span>
                            }
                        </div>
                        {(!tutorDetail?.isLoading && isTutorVerified) &&
                            <div className={"w-full border border-divider-medium shadow-lg rounded-lg px-3 mt-1.5 py-2 flex items-start justify-start gap-3"}>
                                <div className={"relative w-10 h-10 overflow-hidden"}>
                                    <img
                                        src={tutorDetail?.data?.user?.profilePicUrl}
                                        alt={"tutor-profile-pic"}
                                        className={"w-full h-full rounded-full"}
                                    />
                                </div>
                                <div className={"flex flex-col items-start justify-start gap-1"}>
                                    <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                                        {`${tutorDetail?.data?.user?.firstName} ${tutorDetail?.data?.user?.lastName?.charAt(0)}.`}
                                    </span>
                                    <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide truncate"}>
                                        {tutorDetail?.data?.elevatorPitch}
                                    </span>
                                </div>
                            </div>
                        }
                        {tutorDetail?.message &&
                            <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                {tutorDetail?.message}
                            </span>
                        }
                    </div>

                    {/* visibility container */}
                    <div className={'col-start-1 col-span-full sm:col-start-1 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Visibility"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(courseTutorVisibilityTypeEnum)}
                            className={"w-full"}
                            value={addCourseTutorDetail?.payload?.visibility}
                            onChange={(option) => dispatcher(setAddCourseTutorDetailPayload({
                                ...addCourseTutorDetail?.payload,
                                visibility: option?.value
                            }))}
                        />
                    </div>

                    {/* Permission container */}
                    <div className={'col-start-1 col-span-full sm:col-start-2 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Permission"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(courseTutorPermissionEnum)}
                            className={"w-full"}
                            value={addCourseTutorDetail?.payload?.permission}
                            onChange={(option) => dispatcher(setAddCourseTutorDetailPayload({
                                ...addCourseTutorDetail?.payload,
                                permission: option?.value
                            }))}
                        />
                    </div>

                    {/* tutor role container */}
                    <div className='col-start-1 col-span-full sm:col-start-1 sm:col-span-1'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Tutor Role"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(courseTutorRoleEnum)}
                            className={"w-full"}
                            value={addCourseTutorDetail?.payload?.tutorRole}
                            onChange={(option) => dispatcher(setAddCourseTutorDetailPayload({
                                ...addCourseTutorDetail?.payload,
                                tutorRole: option?.value
                            }))}
                        />
                    </div>

                    {/* status container */}
                    <div className='col-start-1 col-span-full sm:col-start-2 sm:col-span-1'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Status"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(courseTutorStatus)}
                            className={"w-full"}
                            value={addCourseTutorDetail?.payload?.status}
                            onChange={(option) => dispatcher(setAddCourseTutorDetailPayload({
                                ...addCourseTutorDetail?.payload,
                                status: option?.value
                            }))}
                        />
                    </div>

                    {/* fee model container */}
                    <div className='col-start-1 col-span-full sm:col-start-1 sm:col-span-1'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Fee Model"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(courseTutorFeeModelEnum)}
                            className={"w-full"}
                            value={addCourseTutorDetail?.payload?.feeModel}
                            onChange={(option) => dispatcher(setAddCourseTutorDetailPayload({
                                ...addCourseTutorDetail?.payload,
                                feeModel: option?.value
                            }))}
                        />
                    </div>

                    {/* fee model container */}
                    <div className='col-start-1 col-span-full sm:col-start-2 sm:col-span-1'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Auto Pay"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(courseTutorAutoPayEnum)}
                            className={"w-full"}
                            value={addCourseTutorDetail?.payload?.isAutoPay}
                            onChange={(option) => dispatcher(setAddCourseTutorDetailPayload({
                                ...addCourseTutorDetail?.payload,
                                isAutoPay: option?.value
                            }))}
                        />
                    </div>

                    {/* fee type container */}
                    <div className='col-start-1 col-span-full sm:col-start-1 sm:col-span-1'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Fee Type"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(courseFeeTypeEnum)}
                            className={"w-full"}
                            value={addCourseTutorDetail?.payload?.feeType}
                            onChange={(option) => dispatcher(setAddCourseTutorDetailPayload({
                                ...addCourseTutorDetail?.payload,
                                feeType: option?.value
                            }))}
                        />
                    </div>

                    {/* currency code container */}
                    {(addCourseTutorDetail?.payload?.feeType === courseFeeTypeEnum?.FIXED?.value) &&
                        <div className={'col-start-1 col-span-full sm:col-start-2 sm:col-span-1'}>
                            <span className={'font-bodyPri font-normal text-text-900 text-sm'}>
                                {"Currency code"}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <OptionSelector
                                options={Object.values(masterCurrencyCodeEnum)}
                                className={"w-full"}
                                value={addCourseTutorDetail?.payload?.currencyCode}
                                onChange={(option) => dispatcher(setAddCourseTutorDetailPayload({
                                    ...addCourseTutorDetail?.payload,
                                    currencyCode: option?.value
                                }))}
                            />
                        </div>
                    }


                    {/* amount container */}
                    {(addCourseTutorDetail?.payload?.feeType === courseFeeTypeEnum?.FIXED?.value) &&
                        <div className='col-start-1 col-span-full sm:col-start-1 sm:col-span-1'>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Amount"}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                value={parseFloat(addCourseTutorDetail?.payload?.amount / 100)?.toString()}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => dispatcher(setAddCourseTutorDetailPayload({
                                    ...addCourseTutorDetail?.payload,
                                    amount: Number(e.target.value) * 100
                                }))}
                                required
                            />
                        </div>
                    }

                    {/* percentage container */}
                    {(addCourseTutorDetail?.payload?.feeType === courseFeeTypeEnum?.PERCENTAGE?.value) &&
                        <div className={cn(
                            (addCourseTutorDetail?.payload?.feeType !== courseFeeTypeEnum?.PERCENTAGE?.value) && 'col-start-1 col-span-full sm:col-start-1 sm:col-span-1',
                            (addCourseTutorDetail?.payload?.feeType === courseFeeTypeEnum?.PERCENTAGE?.value) && ""
                        )}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Percentage"}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                value={(addCourseTutorDetail?.payload?.percentage)?.toString()}
                                min={0}
                                max={100}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => dispatcher(setAddCourseTutorDetailPayload({
                                    ...addCourseTutorDetail?.payload,
                                    percentage: Number(e.target.value)
                                }))}
                                required
                            />
                        </div>
                    }

                </div>
            </div>

            <div className={"flex items-center justify-between gap-3"}>
                <div className={""}>
                    {addCourseTutorDetail?.message &&
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {addCourseTutorDetail?.message}
                        </span>
                    }
                </div>
                <button
                    type={"submit"}
                    // disabled={!isFormValid}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        // !isFormValid && "bg-text-300 text-white cursor-not-allowed",
                        (!addCourseTutorDetail?.isLoading) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        addCourseTutorDetail?.isLoading && "bg-secondary-main"
                    )}>
                    {(addCourseTutorDetail?.isLoading || updateCourseTutor?.isLoading)
                        && <ButtonLoader isLoading={addCourseTutorDetail?.isLoading || updateCourseTutor?.isLoading} />}
                    {(!addCourseTutorDetail?.isLoading && !updateCourseTutor?.isLoading) && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default AddCourseTutorModal;
