import { useState } from "react";
import { toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CurrentDateTime from 'components/modals/editUserAvailabilityModal/commonComponents/CurrentDateTime';
import OneOnOneAvailabilityInputs from 'components/modals/editUserAvailabilityModal/commonComponents/OneOnOneAvailabilityInputs';
import GroupAvailabilityInputs from 'components/modals/editUserAvailabilityModal/commonComponents/GroupAvailabilityInputs';

import NonRecurringAvailabilityCard from 'components/modals/editUserAvailabilityModal/nonRecurringAvailabilitySchedule/components/NonRecurringAvailabilityCard';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddAvailabilityDetail_Payload } from 'redux/availability/availability.slice';
import { availabilityTypeEnum } from 'redux/availability/availability.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const NonRecurringAvailabilitySchedule = () => {
    const { availabilityDetail, addAvailabilityDetail } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const todayDate = new Date()
    const dateAfterSevenDays = todayDate.setDate(todayDate.getDate() + 8)

    const [isShowDatePicker, setIsShowDatePicker] = useState(false)
    const [selectedDateTimeSlot, setSelectedDateTimeSlot] = useState({
        date: dayjs(dateAfterSevenDays)?.tz(timeZone)?.format("YYYY-MM-DD"),
        timeSlots: []
    })

    const isOneOnOneAvailType = availabilityDetail?.data?.result?.type === availabilityTypeEnum?.ONE_ON_ONE?.value
    const isGroupAvailType = availabilityDetail?.data?.result?.type === availabilityTypeEnum?.GROUP?.value

    const sortDateTimeSlots = (dateTime1, dateTime2) => {
        return dateTime2?.date < dateTime1?.date ? 1 : -1
    }

    const saveDateTimeSlots = async (dateTimeAvails) => {
        if (addAvailabilityDetail?.isLoading) return;

        dispatcher(setAddAvailabilityDetail_Payload({
            ...addAvailabilityDetail?.payload,
            nonRecurringAvailability: {
                ...addAvailabilityDetail?.payload?.nonRecurringAvailability,
                dateTimeSlots: dateTimeAvails
            }
        }))
    }

    const onHandleAddDate = (date) => {

        if (addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots?.length > 0) {
            const filteredDateTimeSlots = addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.filter((dateTimeSlot) => dateTimeSlot?.date === dayjs(date)?.format("YYYY-MM-DD"))
            if (filteredDateTimeSlots?.length > 0) {
                toast.warn("Same dates are added more than once!")
                return;
            }
        }
        dispatcher(setAddAvailabilityDetail_Payload({
            ...addAvailabilityDetail?.payload,
            nonRecurringAvailability: {
                ...addAvailabilityDetail?.payload?.nonRecurringAvailability,
                dateTimeSlots: [
                    ...addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots,
                    {
                        date: dayjs(date)?.format("YYYY-MM-DD"), timeSlots: []
                    }
                ]
            }
        }))
        setIsShowDatePicker(false)
    }


    return (
        <div className={"w-full flex flex-col items-start justify-start gap-5"}>
            {isOneOnOneAvailType &&
                <OneOnOneAvailabilityInputs />
            }
            {isGroupAvailType &&
                <GroupAvailabilityInputs scheduleDuration={addAvailabilityDetail?.payload?.nonRecurringAvailability?.duration / 15} />
            }

            <CurrentDateTime />
            <div className={"flex flex-wrap items-center justify-start gap-5"}>
                {(addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots?.length > 0)
                    && addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots?.map((dateTimeSlot, index) => (
                        <NonRecurringAvailabilityCard
                            key={index}
                            dateTimeSlot={dateTimeSlot}
                            dateTimeSlotsAvails={addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots}
                            saveDateTimeSlots={saveDateTimeSlots}
                        />
                    ))

                }
                {(addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots?.length === 0 || !addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots) &&
                    <NonRecurringAvailabilityCard
                        dateTimeSlot={selectedDateTimeSlot}
                        dateTimeSlots={addAvailabilityDetail?.payload?.nonRecurringAvailability?.dateTimeSlots}
                        saveDateTimeSlots={saveDateTimeSlots}
                    />
                }
            </div>

            {/* add date */}
            <div className={"col-start-1 col-span-full lg:col-start-4 lg:col-span-full"}>
                {!isShowDatePicker &&
                    <span className={"font-bodyPri font-normal text-primary-main text-md cursor-pointer"} onClick={() => setIsShowDatePicker(true)}>
                        {"Add a date"}
                    </span>
                }
                {isShowDatePicker &&
                    <DatePicker
                        className={"font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500 placeholder:text-sm focus:outline-none"}
                        dateFormat={"dd/MM/yyyy"}
                        autoFocus={true}
                        value={new Date()}
                        selected={new Date()}
                        minDate={dateAfterSevenDays}
                        placeholderText={"Select Date"}
                        scrollableYearDropdown={true}
                        showYearDropdown={true}
                        yearDropdownItemNumber={80}
                        showMonthDropdown={true}
                        onChange={(date) => onHandleAddDate(date)}
                    />
                }
            </div>
        </div>
    )
}

export default NonRecurringAvailabilitySchedule;