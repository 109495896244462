import { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";

import { FaTimesCircle } from 'react-icons/fa';
import { MdAdd, MdOutlineDragIndicator } from 'react-icons/md';

import { Draggable } from 'react-drag-reorder';

import ComponentLoader from 'components/loader/ComponentLoader';
import DashedBorderButton from 'components/buttons/dashedBorderButton';
import SaveCancelBtn from 'components/buttons/SaveAndCancelBtn';
import SearchSelector from 'components/searchSelector/SearchSelector';

import { profileEditBtnConst, subjectTopicReducer } from 'pages/auth/editTutor/data';
import EditTutorHeader from 'pages/auth/editTutor/commonComponents/EditTutorHeader';

import { getAllCategoryList } from 'redux/category/category.request';
import { getMasterProficiencyList } from 'redux/master/master.request';
import {
  createTutorCategoryDetail,
  deleteTutorCategoryDetail,
  getTutorCategoryList,
  updateTutorCategoryListOrder
} from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCategoryList } from 'redux/category/category.slice';
import { setClearProfList } from 'redux/master/master.slice';
import {
  resetAddTutorCategoryDetail,
  resetDeleteTutorCategoryDetail,
  resetTutorCategoryList,
  resetUpdateTutorCategoryListOrder
} from 'redux/tutor/tutor.slice';


const EditTutorSubject = () => {
  const { profList } = useAppState((state) => state.master)
  const { categoryList } = useAppState((state) => state.category)
  const { tutorCategoryList, addTutorCategoryDetail, updateTutorCategoryOrder, deleteTutorCategory } = useAppState((state) => state.tutor)

  const [editBtnConst, setEditBtnConst] = useState(null)

  const dispatcher = useAppDispatcher()
  const { tutorId } = useParams()

  const [state, dispatch] = useReducer(subjectTopicReducer, {
    isShowAddBtn: false,
    isShowDropdowns: false,
    categoryProficiencyList: [],
    category: {
      category: "",
      id: null
    },
    proficiency: {
      proficiency: "",
      id: null
    }
  })

  const loadingElement = tutorCategoryList?.isLoading || addTutorCategoryDetail?.isLoading || updateTutorCategoryOrder?.isLoading || deleteTutorCategory?.isLoading
  const isTutorCategorySimilar = JSON.stringify(state.categoryProficiencyList) === JSON.stringify(tutorCategoryList?.data?.results)

  useEffect(() => {
    return () => {
      dispatcher(resetTutorCategoryList())
      dispatcher(resetAddTutorCategoryDetail())
      dispatcher(resetUpdateTutorCategoryListOrder())
      dispatcher(resetDeleteTutorCategoryDetail())
    }
  }, [])

  useEffect(() => {
    dispatcher(getMasterProficiencyList())
    dispatcher(getAllCategoryList())

    return () => {
      dispatcher(setClearProfList())
      dispatcher(setClearCategoryList())
    }
  }, [])

  useEffect(() => {
    if (Number(tutorId)) {
      dispatcher(getTutorCategoryList({ tutorId: Number(tutorId) }))
    }
  }, [tutorId])

  useEffect(() => {
    if (tutorCategoryList?.data?.results?.length > 0) {
      dispatch({
        type: "CATEGORY_AND_PROFICIENCY_LIST",
        payload: tutorCategoryList?.data?.results
      })
      dispatch({
        type: "SHOW_ADD_BTN",
        payload: true
      })
    }
  }, [tutorCategoryList?.data])

  const updateTutorCategoryProfOrder = () => {
    if (loadingElement) return;


    if (tutorCategoryList?.data?.results?.length >= 1 && !isTutorCategorySimilar) {
      const body = {
        order: state.categoryProficiencyList?.map((tutorCatProf) => ({
          id: tutorCatProf?.id
        }))
      }
      const requestDataPayload = {
        query: { tutorId: Number(tutorId) },
        body: body
      }

      dispatcher(updateTutorCategoryListOrder(requestDataPayload))

    }
  }

  const onHandleSave = () => {
    if (!isTutorCategorySimilar) {
      updateTutorCategoryProfOrder()
    }
    if (state.isShowDropdowns) {
      if (!state?.category?.id || !state?.proficiency?.id) {
        toast.warn("category and proficiency are required fields")
        return;
      }

      const filteredCategoryAndProfList = tutorCategoryList?.data?.results?.filter((categoryProf) => (
        categoryProf?.category?.id === state?.category?.id
      ))

      if (filteredCategoryAndProfList?.length > 0) {
        toast.warn("Duplicate Entries are not allowed")
        return;
      }
      const requestDataPayload = {
        tutorId: Number(tutorId),
        category: state.category.category,
        proficiency: state.proficiency.proficiency
      }
      dispatcher(createTutorCategoryDetail(requestDataPayload))
    }

    dispatch({
      type: "INITIAL_STATE",
      payload: null
    })

    dispatch({
      type: "SHOW_DROPDOWNS",
      payload: false
    })
    dispatch({
      type: "SHOW_ADD_BTN",
      payload: true
    })

    setEditBtnConst(null)

  }

  const onHandleCancel = () => {

    dispatcher(resetAddTutorCategoryDetail())
    dispatcher(resetUpdateTutorCategoryListOrder())
    dispatcher(resetDeleteTutorCategoryDetail())

    dispatch({
      type: "INITIAL_STATE",
      payload: null
    })

    dispatch({
      type: "SHOW_DROPDOWNS",
      payload: false
    })
    dispatch({
      type: "SHOW_ADD_BTN",
      payload: true
    })
    setEditBtnConst(null)
  }

  const onHandleAddNewSubject = () => {

    dispatcher(resetAddTutorCategoryDetail())
    dispatcher(resetUpdateTutorCategoryListOrder())
    dispatcher(resetDeleteTutorCategoryDetail())

    if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SUBJECT_TOPIC?.value) {
      setEditBtnConst(profileEditBtnConst?.SUBJECT_TOPIC?.value)
    }
    if (state.categoryProficiencyList?.length >= 1) {
      dispatch({
        type: "SHOW_ADD_BTN",
        payload: false
      })
      dispatch({
        type: "SHOW_DROPDOWNS",
        payload: true
      })
    } else {
      dispatch({
        type: "SHOW_ADD_BTN",
        payload: false
      })
      dispatch({
        type: "SHOW_DROPDOWNS",
        payload: true
      })
    }
  }

  const getChangedPos = (currentPos, newPos) => {
    if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SUBJECT_TOPIC?.value) {
      setEditBtnConst(profileEditBtnConst?.SUBJECT_TOPIC?.value)
    }
    var updatedList = [...state.categoryProficiencyList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(currentPos, 1);
    // Add dropped item
    updatedList.splice(newPos, 0, reorderedItem);
    // Update State
    dispatch({
      type: "CATEGORY_AND_PROFICIENCY_LIST",
      payload: updatedList
    })
  }

  const removeSelectedCategoryProf = (categoryProf) => {
    if (loadingElement) return;

    dispatcher(deleteTutorCategoryDetail(categoryProf?.id))
  }

  const onHandleSelection = (key, option) => {
    if (categoryList?.isLoading || profList?.isLoading || loadingElement) return;

    if (key === "SelectCategory") {
      const selectedCategory = categoryList?.categoryList?.find((category) => category?.categoryId === option)
      const newSelectedCategory = {
        category: selectedCategory?.category,
        id: selectedCategory?.categoryId
      }
      dispatch({
        type: "SELECTED_CATEGORY",
        payload: newSelectedCategory
      })
    } else {
      const selectedProficiency = profList?.profList?.find((proficiency) => proficiency?.masterProficiencyId === option)
      const newSelectedProficiency = {
        proficiency: selectedProficiency?.proficiency,
        id: selectedProficiency?.masterProficiencyId
      }
      dispatch({
        type: "SELECTED_PROFICIENCY",
        payload: newSelectedProficiency
      })
    }
  }

  return (
    <div className={'w-full flex flex-col items-start justify-start gap-1'}>

      <EditTutorHeader title={"Subjects & Topics, I teach: Levels of Mastery"} />

      {tutorCategoryList?.isLoading &&
        <ComponentLoader isLoading={tutorCategoryList?.isLoading} />
      }
      {(!tutorCategoryList?.isLoading && tutorCategoryList?.message) &&
        <div className={"flex items-center justify-center"}>
          <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
            {tutorCategoryList?.message}
          </span>
        </div>
      }

      <DashedBorderButton
        isShowBtn={!tutorCategoryList?.isLoading && ((!editBtnConst || editBtnConst !== profileEditBtnConst?.SUBJECT_TOPIC?.value) && (!tutorCategoryList?.data || tutorCategoryList?.data?.results?.length === 0))}
        btnTitle={"Add Subject(s)"}
        onHandleAdd={onHandleAddNewSubject}
      />

      {(!tutorCategoryList?.isLoading && (editBtnConst === profileEditBtnConst?.SUBJECT_TOPIC?.value || tutorCategoryList?.data?.results?.length > 0)) &&
        <div className={"w-full px-3 py-3 border border-divider-medium rounded-lg space-y-3"}>
          {loadingElement &&
            <ComponentLoader isLoading={loadingElement} />
          }
          {(!loadingElement && tutorCategoryList?.data?.results?.length > 0) &&
            <div className={"space-y-3 divide-y divide-text-200 px-3 py-0.5 md:py-1"}>
              <Draggable onPosChange={getChangedPos}>
                {tutorCategoryList?.data?.results?.length > 0 &&
                  tutorCategoryList?.data?.results?.map((tutorCategory, index) => (
                    <div key={index} className={"grid grid-cols-12 gap-5"}>
                      <span className={"col-start-1 col-span-1 self-start"}>
                        <MdOutlineDragIndicator className={"text-lg text-text-800"} />
                      </span>
                      <span className={"col-start-2 col-span-4 font-bodyPri font-medium text-text-700 text-base tracking-wide truncate"}>
                        {tutorCategory?.category?.category}
                      </span>
                      <span className={"col-start-7 col-span-4 font-bodyPri font-medium text-text-900 text-base tracking-wide truncate"}>
                        {tutorCategory?.masterProficiency?.proficiency}
                      </span>
                      <span className={"col-start-11 col-span-1"}>
                        <FaTimesCircle
                          className={"text-lg text-red-700 cursor-pointer"}
                          onClick={() => removeSelectedCategoryProf(tutorCategory)}
                        />
                      </span>
                    </div>
                  ))}
              </Draggable>
            </div>
          }

          {state.isShowAddBtn &&
            <div
              className={"w-fit flex items-center justify-start gap-2 px-2 py-1 bg-divider-darkLight rounded-lg cursor-pointer"}
              onClick={onHandleAddNewSubject}
            >
              <MdAdd className={"text-base text-text-700"} />
              <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
                {"Add subject/topic"}
              </span>
            </div>

          }
          {state.isShowDropdowns &&
            <div className={"flex flex-col items-start justify-start md:flex-row md:items-center md:justify-start gap-5"}>
              <SearchSelector
                isLoading={categoryList?.isLoading}
                options={categoryList?.categoryList?.map((category) => ({
                  label: category?.category,
                  value: category?.categoryId
                })) || []}
                showGroupedOptions={true}
                placeholder={"Select Category"}
                selectedValue={{ label: state?.category?.category || "", value: state?.category?.id }}
                onHandleSelect={(option) => onHandleSelection("SelectCategory", option?.value)}
              />
              {(!categoryList?.isLoading && categoryList?.errorMsg) &&
                <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                  {categoryList?.errorMsg}
                </span>
              }
              <SearchSelector
                isLoading={profList?.isLoading}
                options={profList?.profList?.map((proficiency) => ({
                  label: proficiency.proficiency,
                  value: proficiency.masterProficiencyId
                })) || []}
                search
                showGroupedOptions={false}
                autoFocus={false}
                placeholder={"Select Level"}
                value={state?.proficiency?.id}
                selectedValue={{ label: state?.proficiency?.proficiency || "", value: state?.proficiency?.id }}
                onHandleSelect={(option) => onHandleSelection("SelectProficiency", option?.value)}
              />

              {(!profList?.isLoading && profList?.errorMsg) &&
                <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                  {profList?.errorMsg}
                </span>
              }

            </div>
          }

          {(addTutorCategoryDetail?.message || updateTutorCategoryOrder?.message || deleteTutorCategory?.message) &&
            <div className={"w-full flex items-center justify-start gap-3"}>
              <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                {addTutorCategoryDetail?.message || updateTutorCategoryOrder?.message || deleteTutorCategory?.message}
              </span>
            </div>
          }
        </div>
      }
      {!tutorCategoryList?.isLoading &&
        <div className={"w-full flex items-end justify-start p-5"}>
          <SaveCancelBtn
            isShowBtn={editBtnConst === profileEditBtnConst?.SUBJECT_TOPIC?.value || loadingElement}
            onHandleSave={onHandleSave}
            onHandleCancel={onHandleCancel}
          />
        </div>
      }
    </div>
  )
}

export default EditTutorSubject;