import { ArrowForwardIos, Menu } from '@mui/icons-material';
import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';

import { PageContent } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)`
    w-full
`;

export const AccordionContainer = tw.div`
    w-full cursor-pointer select-none w-full h-full bg-white rounded-lg
`;

export const Question = motion(tw.header`
    flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg
`);

export const QuestionText = tw.span`
    font-subHeading font-medium text-lg text-text-900
`;

export const ArrowToggleIcon = motion(styled(ArrowForwardIos)``);

export const CollapsableContainer = motion(styled.section`
    ${tw`
        px-10 bg-white rounded-lg overflow-hidden
        font-bodyPri font-normal text-base text-text-800
    `}
    ${({ isOpen }) => isOpen && tw`
        py-3
    `}
`);

export const HamburgerButton = styled(Menu)`
    ${tw`
        flex justify-start text-3xl cursor-pointer
        group-hover:text-white hover:text-complementary-light!
    `}
    ${({ isScrolled }) => (isScrolled) && tw`
        text-white
    `}
`;