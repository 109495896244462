import { dayjs } from "utils/dateTime.utils";

import {
    SearchBookingId,
    SearchSessionCount,
    SearchUser,
    SearchOfferingType,
    SearchBookingPrice,
    FilterStatus,
    SearchCreatedDate,
    ButtonAction,
    SearchUpdatedDate
} from "pages/auth/bookings/components/FilterBookingStatistics";

export const pageHeading = {
    heading: "Bookings",
    subHeading: "Browse all bookings and take actions!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/bookings.svg"
}

export const bookingHeaderConst = {
    BOOKING_ID: {
        label: "Booking ID",
        value: "booking_id"
    },
    SESSION_COUNT: {
        label: "Session Count",
        value: "session_count"
    },
    USER: {
        label: "User",
        value: "user"
    },
    OFFERING_TYPE: {
        label: "Offering Type",
        value: "offering_type"
    },
    PRICE: {
        label: "Price",
        value: "price"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    UPDATED_DATE: {
        label: "Updated Date",
        value: "updated_date"
    }
}

export const bookingMenuData = {
    ADD_BOOKING: {
        label: "Add Booking",
        value: "add_booking"
    }
}

export const subRowHeader = [
    <SearchBookingId />,
    <SearchSessionCount />,
    <SearchUser />,
    <SearchOfferingType />,
    <SearchBookingPrice />,
    <FilterStatus />,
    <SearchCreatedDate />,
    <ButtonAction />,
    <SearchUpdatedDate />
];

export const sortBookingLowToHigh = (activeSortHeader, booking1, booking2) => {
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.BOOKING_ID?.value) {
        return (Number(booking1?.id) - Number(booking2?.id))
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.USER?.value) {
        return (Number(booking1?.user?.id) - Number(booking2?.user?.id))
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.OFFERING_TYPE?.value) {
        return booking1?.offeringType?.toLowerCase()?.localeCompare(booking2?.offeringType?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.PRICE?.value) {
        return (Number(booking1?.bookingPrice?.totalAmount) - Number(booking2?.bookingPrice?.totalAmount))
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.STATUS?.value) {
        return booking1?.status?.toLowerCase()?.localeCompare(booking2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.DATE?.value) {
        return dayjs(booking2?.createdAt).isSameOrBefore(booking1?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.UPDATED_DATE?.value) {
        return dayjs(booking2?.updatedAt).isSameOrBefore(booking1?.updatedAt) ? 1 : -1
    }
}

export const sortBookingHighToLow = (activeSortHeader, booking1, booking2) => {
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.BOOKING_ID?.value) {
        return (Number(booking2?.id) - Number(booking1?.id))
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.USER?.value) {
        return (Number(booking2?.user?.id) - Number(booking1?.user?.id))
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.OFFERING_TYPE?.value) {
        return booking2?.offeringType?.toLowerCase()?.localeCompare(booking1?.offeringType?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.PRICE?.value) {
        return (Number(booking2?.bookingPrice?.totalAmount) - Number(booking1?.bookingPrice?.totalAmount))
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.STATUS?.value) {
        return booking2?.status?.toLowerCase()?.localeCompare(booking1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.DATE?.value) {
        return dayjs(booking1?.createdAt).isSameOrBefore(booking2?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === bookingHeaderConst?.UPDATED_DATE?.value) {
        return dayjs(booking1?.updatedAt).isSameOrBefore(booking2?.updatedAt) ? 1 : -1
    }
}

export const bookingLogConst = {
    PENDING: {
        title: "Pending",
    },
    PROCESSING: {
        title: "Processing"
    },
    CONFIRMED: {
        title: "Confirmed",
    },
    COMPLETED: {
        title: "Completed",
    },
    CANCELLED: {
        title: "Cancelled",
    },
    REFUNDED: {
        title: "Refunded",
    }
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    bookingId: {
        key: "bookingId"
    }
}