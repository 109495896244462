import { AiOutlineEnter } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { GrAddCircle } from "react-icons/gr";
import { MdAdd } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

import EditUserInformation from "pages/auth/editUser/components/editUserInformation/EditUserInformation";
import EditUserAddress from "pages/auth/editUser/components/EditUserAddress";
import EditUserCulture from "pages/auth/editUser/components/EditUserCulture";
import EditUserEducation from "pages/auth/editUser/components/EditUserEducation";
import EditUserSocial from "pages/auth/editUser/components/editUserSocial/index";
import EditUserBadges from "pages/auth/editUser/components/EditUserBadges";
import EditUserAvailabilityListPage from "pages/auth/editUser/components/availability/list";
import AvailabilityDetailPage from "pages/auth/editUser/components/availability/detail";
import CreateAvailabilityDetailPage from "pages/auth/editUser/components/availability/create";
import EditUserWallet from "pages/auth/editUser/components/editUserWallet/EditUserWallet";
import EditOtherPreferences from "pages/auth/editUser/components/EditOtherPreferences";

import { userRoles } from "redux/auth/auth.const";
import { DEFAULT_AVAILABILITY_LIST_PAGE, DEFAULT_AVAILABILITY_LIST_RECORDS, availabilityEndTypeEnum, availabilityIsRecurringEnum, availabilityTypeEnum, weekAvailTimeSlots } from "redux/availability/availability.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

export const editUserHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    PHONE: {
        label: "Phone",
        value: "phone"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

// social tab consts
export const socialKey = [
    "facebook",
    "twitterx",
    "linkedin",
    "instagram",
    "whatsapp",
    "edulyte",
    "web-link",
    "pinterest",
    "snapchat",
    "spotify",
    "amazon",
    "threads",
]

export const outsideIcons = ["facebook", "twitterx", "whatsapp", "instagram", "linkedin", "web-link"];

export const menuItemsConst = {
    DELETE: {
        label: "Delete",
        value: "delete"
    },
}

// wallet tab const
export const walletTabBtnConst = {
    ADD_CREDIT: {
        label: "Add Credit",
        value: "add_credit",
        icon: <MdAdd className={"text-lg group-hover:text-text-50"} />
    }
}

export const weeklyScheduleToolTip = "Add or update your general weekly schedule for seven days by the hour.Use plus sign to add hours for any given day.Click on pencil icon to modify time table/hours on any day.Click on red cross icon to delete hours.Please don't forget to save after making changes."

const otherPrefConst = {
    contentCreation: {
        label: "Count me in for content creation work",
        description: "You will create your own content."
    },
    paidInfluencer: {
        label: "Count me in for paid influencer work",
        description: "We will get in touch for any influencer work."
    },
    personalisedReco: {
        label: "Personal recommendation",
        description: "Allow personal recommendation."
    }
}

export const setGetOtherPref = (otherPref) => (
    Object.keys(otherPref).map((other) => ({
        key: other,
        label: otherPrefConst[other].label,
        description: otherPrefConst[other].description,
        value: otherPref[other]
    }))
)

export const availabilityIconButtons = {
    EDIT: {
        value: "edit",
        tooltip: "Edit",
        icon: <FiEdit className={"text-xl text-text-700 hover:text-primary-dark cursor-pointer"} />
    },
    DELETE: {
        value: "delete",
        tooltip: "Delete",
        icon: <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
    }
}

export const availabilityTimeZoneConst = {
    LOCAL: {
        label: "Local",
        value: "local"
    },
    USER: {
        label: "User",
        value: "user"
    }
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    id: {
        key: "id"
    },
    name: {
        key: "name"
    },
    isRecurring: {
        key: "isRecurring"
    },
    date: {
        key: "date"
    },
    minCreatedAt: {
        key: "minCreatedAt"
    },
    maxCreatedAt: {
        key: "maxCreatedAt"
    },
    timeZone: {
        key: "timeZone"
    }
}

export const availabilityFilters = {
    [searchParamsInfo.id.key]: {
        key: "id",
        label: "ID"
    },
    [searchParamsInfo.name.key]: {
        key: "name",
        label: "Name"
    },
    [searchParamsInfo.isRecurring.key]: {
        key: "isRecurring",
        label: "Recurring"
    },
    [searchParamsInfo.date.key]: {
        key: "date",
        label: "Created On"
    },
    [searchParamsInfo.timeZone.key]: {
        key: "timeZone",
        label: "TimeZone"
    },
}

export const getAvailabilityListPayload = async (filterPayload) => {

    let requestDataPayload = {
        page: filterPayload?.page || DEFAULT_AVAILABILITY_LIST_PAGE,
        records: DEFAULT_AVAILABILITY_LIST_RECORDS,
        timeZone: timeZone
    }

    if (filterPayload?.id) {
        requestDataPayload[searchParamsInfo.id.key] = Number(filterPayload?.id)
    }
    if (filterPayload?.name) {
        requestDataPayload[searchParamsInfo.name.key] = filterPayload?.name.replaceAll("-", " ")
    }
    if (filterPayload?.isRecurring) {
        requestDataPayload[searchParamsInfo.isRecurring.key] = filterPayload?.isRecurring.replaceAll("-", "_")
    }
    if (filterPayload?.minCreatedAt) {
        requestDataPayload[searchParamsInfo.minCreatedAt.key] = dayjs(filterPayload?.minCreatedAt).tz(timeZone).format("YYYY-MM-DD")
    }
    if (filterPayload?.maxCreatedAt) {
        requestDataPayload[searchParamsInfo.maxCreatedAt.key] = dayjs(filterPayload?.maxCreatedAt).tz(timeZone).format("YYYY-MM-DD")
    }

    return requestDataPayload;
}

export const availabilityDetailBtnConst = {
    name: {
        label: "Name",
        key: "name"
    },
    description: {
        label: "Description",
        key: "description"
    },
    minTimeBeforeAvailStart: {
        label: "Minimum notice period required, before appointment can be booked",
        key: "minTimeBeforeAvailStart"
    },
    advanceSlotPeriodDays: {
        label: "Maximum time in advance that an appointment can be booked",
        key: "advanceSlotPeriodDays"
    },
    isRecurring: {
        label: "Repeat",
        key: "isRecurring"
    },
    startDateTime: {
        label: "Start Date",
        key: "startDateTime"
    },
    timeZone: {
        label: "Time Zone",
        key: "timeZone"
    },
    endType: {
        label: "Ends",
        key: "endType"
    },
    endDateTime: {
        label: "End Date",
        key: "endDateTime"
    },
    weeklyCycle: {
        label: "Weekly Cycle",
        key: "weeklyCycle"
    }
}

export const weeklyScheduleActionButtons = {
    DELETE: {
        value: "delete",
        tooltip: "delete",
        icon: <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
    },
    ADD: {
        value: "add",
        tooltip: "Add",
        icon: <GrAddCircle className={"text-xl text-text-700 hover:text-primary-dark"} />
    },
    APPLY_TO_ALL: {
        value: "apply_to_all",
        tooltip: "Apply to All",
        icon: <AiOutlineEnter className={"text-xl text-text-700 hover:text-primary-dark"} />
    }
}

export const validateAvailabilityDetail = (responseData, requestPayload) => {
    let isDataSimilarOrInvalid = false

    const responsePayload = {
        name: responseData?.name,
        isRecurring: responseData?.isRecurring,
        weeklyTimeSlots: responseData?.weeklyTimeSlots || weekAvailTimeSlots,
        dateTimeSlots: responseData?.dateTimeSlots || [],
        startDateTime: responseData?.startDateTime || null,
        endType: responseData?.endType || null,
        weeklyCycle: responseData?.weeklyCycle || null,
        endDateTime: responseData?.endDateTime || null,
        description: responseData?.description || "",
        minTimeBeforeAvailStart: responseData?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: responseData?.advanceSlotPeriodDays
    }

    const localPayload = {
        name: requestPayload?.name,
        isRecurring: requestPayload?.isRecurring,
        weeklyTimeSlots: requestPayload?.weeklyTimeSlots || weekAvailTimeSlots,
        dateTimeSlots: requestPayload?.dateTimeSlots || [],
        startDateTime: requestPayload?.startDateTime || null,
        endType: requestPayload?.endType || null,
        weeklyCycle: requestPayload?.weeklyCycle || null,
        endDateTime: requestPayload?.endDateTime || null,
        description: requestPayload?.description || "",
        minTimeBeforeAvailStart: requestPayload?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: requestPayload?.advanceSlotPeriodDays
    }

    if (!requestPayload?.name) {
        isDataSimilarOrInvalid = true
    }
    if (!requestPayload?.isRecurring) {
        isDataSimilarOrInvalid = true
    }
    if ([availabilityIsRecurringEnum.RECURRING.value]?.includes(requestPayload?.isRecurring)) {
        if ((requestPayload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) && !requestPayload?.endDateTime) {
            isDataSimilarOrInvalid = true
        }
        if ((requestPayload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && !requestPayload?.weeklyCycle) {
            isDataSimilarOrInvalid = true
        }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(requestPayload?.isRecurring) && (requestPayload?.dateTimeSlots?.length === 0)) {
        isDataSimilarOrInvalid = true
    }
    isDataSimilarOrInvalid = JSON.stringify(responsePayload) === JSON.stringify(localPayload)
    return isDataSimilarOrInvalid;
}

export const getSelectedTimeZone = (selectedTimeZone, userTimeZone) => {
    if (selectedTimeZone?.toLowerCase() === userTimeZone?.toLowerCase()) {
        return availabilityTimeZoneConst.USER
    } else {
        return availabilityTimeZoneConst.LOCAL
    }
}

export const setUserAvailabilityDetailPayload = async (availabilityData, selectedTimeZone = timeZone) => {
    let requestPayload = {
        timeZone: selectedTimeZone
    }

    if (availabilityData?.user?.userId) {
        requestPayload["userId"] = availabilityData?.user?.userId
    }
    if (availabilityData?.name) {
        requestPayload["name"] = availabilityData?.name
    }
    if (availabilityData?.description) {
        requestPayload["description"] = availabilityData?.description
    }
    if (availabilityData?.minTimeBeforeAvailStart) {
        requestPayload["minTimeBeforeAvailStart"] = availabilityData?.minTimeBeforeAvailStart
    }
    if (availabilityData?.advanceSlotPeriodDays) {
        requestPayload["advanceSlotPeriodDays"] = availabilityData?.advanceSlotPeriodDays
    }
    if (availabilityData?.type) {
        requestPayload["type"] = availabilityData?.type
    }
    if (availabilityData?.isRecurring) {
        requestPayload["isRecurring"] = availabilityData?.isRecurring
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(availabilityData?.isRecurring)) {
        requestPayload["dateTimeSlots"] = availabilityData?.dateTimeSlots
        requestPayload["endType"] = null
        requestPayload["startDateTime"] = null
        requestPayload["endDateTime"] = null
        requestPayload["weeklyCycle"] = null
    }
    if ([availabilityIsRecurringEnum.RECURRING.value]?.includes(availabilityData?.isRecurring)) {
        if (availabilityData?.weeklyTimeSlots) {
            requestPayload["weeklyTimeSlots"] = availabilityData?.weeklyTimeSlots
        }
        if (availabilityData?.startDateTime) {
            requestPayload["startDateTime"] = availabilityData?.startDateTime || dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
        }
        if (availabilityData?.endType) {
            requestPayload["endType"] = availabilityData?.endType || availabilityEndTypeEnum.FOREVER.value
        }
        if ([availabilityEndTypeEnum.ON_DATE.value]?.includes(availabilityData?.endType)) {
            requestPayload["endDateTime"] = availabilityData?.endDateTime || null
        }
        if ([availabilityEndTypeEnum.WEEKLY_CYCLE.value]?.includes(availabilityData?.endType)) {
            requestPayload["weeklyCycle"] = availabilityData?.weeklyCycle || null
        }
    }
    return requestPayload;
}

export const validateUserAvailabilityDetail = async (payload) => {
    let requestPayload = {}
    let errorMsg = null

    if (!payload?.name) {
        errorMsg = "Please enter name!"
        return { errorMsg, requestPayload }
    }
    if (!payload?.minTimeBeforeAvailStart) {
        errorMsg = "Please enter minimum availability time!"
        return { errorMsg, requestPayload }
    }
    if (!payload?.advanceSlotPeriodDays) {
        errorMsg = "Please enter maximum advance booking time!"
        return { errorMsg, requestPayload }
    }

    if ((payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) && !payload?.endDateTime) {
        errorMsg = "Please enter end date for the availability!"
        return { errorMsg, requestPayload }
    }
    if ((payload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) && !payload?.weeklyCycle) {
        errorMsg = "Please enter weekly cycle count!"
        return { errorMsg, requestPayload }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(payload?.isRecurring) && (payload?.dateTimeSlots?.length === 0)) {
        errorMsg = "Please add date time slots!"
        return { errorMsg, requestPayload }
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(payload?.isRecurring)) {
        const filteredTimeSlots = payload?.dateTimeSlots?.filter((item) => item?.timeSlots?.length === 0)
        if (!!filteredTimeSlots?.length) {
            errorMsg = "Please add date time slots!"
            return { errorMsg, requestPayload }
        }
    }

    requestPayload = {
        name: payload?.name,
        timeZone: payload?.timeZone || timeZone,
        type: availabilityTypeEnum?.ONE_ON_ONE?.value,
        minTimeBeforeAvailStart: payload?.minTimeBeforeAvailStart,
        advanceSlotPeriodDays: payload?.advanceSlotPeriodDays,
        isRecurring: payload?.isRecurring,
    }
    if (payload?.description) {
        requestPayload["description"] = payload?.description
    }
    if ([availabilityIsRecurringEnum?.NON_RECURRING?.value]?.includes(payload?.isRecurring)) {
        requestPayload["dateTimeSlots"] = payload?.dateTimeSlots
    }
    if ([availabilityIsRecurringEnum?.RECURRING?.value]?.includes(payload?.isRecurring)) {
        let newStartDateTime = payload?.startDateTime
        let newEndDateTime = payload?.endDateTime

        // newStartDateTime = await getDateTimeSlotFromDay(payload)

        const startDayName = dayjs(newStartDateTime)?.tz(timeZone)?.format('dddd')

        const startDayTimeSlot = payload?.weeklyTimeSlots[startDayName?.slice(0, 3)?.toUpperCase()]
        if (startDayTimeSlot?.length === 0) {
            errorMsg = "You must have a session on the start date. Please update"
            return { requestPayload, errorMsg };
        } else {
            const startDateTimeFormat = dayjs(newStartDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + startDayTimeSlot[0]?.startTime
            newStartDateTime = dayjs(startDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
        }

        if (payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) {
            const endDayName = dayjs(payload?.endDateTime)?.tz(timeZone)?.format('dddd')

            const endDayTimeSlot = payload?.weeklyTimeSlots[endDayName?.slice(0, 3)?.toUpperCase()]
            if (endDayTimeSlot?.length === 0) {
                errorMsg = "You must have a session on the end date. Please update"
                return { requestPayload, errorMsg };
            } else {
                const endDateTimeFormat = dayjs(payload?.endDateTime)?.tz(timeZone)?.format("YYYY-MM-DD") + " " + endDayTimeSlot[0]?.endTime
                newEndDateTime = dayjs(endDateTimeFormat)?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
            }
        }
        requestPayload["weeklyTimeSlots"] = payload?.weeklyTimeSlots
        requestPayload["startDateTime"] = newStartDateTime
        requestPayload["endType"] = payload?.endType
        if (payload?.endType === availabilityEndTypeEnum?.ON_DATE?.value) {
            requestPayload["endDateTime"] = newEndDateTime
        }
        if (payload?.endType === availabilityEndTypeEnum?.WEEKLY_CYCLE?.value) {
            requestPayload["weeklyCycle"] = payload?.weeklyCycle
        }
    }

    return { requestPayload, errorMsg }
}

export const sortTimeSlots = (timeSlot1, timeSlot2) => {
    return timeSlot2?.startTime < timeSlot1?.startTime ? 1 : -1
}

export const sortDateTimeSlots = (dateTime1, dateTime2) => {
    return dateTime2?.date < dateTime1?.date ? 1 : -1
}

export const editUserNavigationOptions = {
    GENERAL: {
        label: "General",
        value: "general",
        pagePath: "/user-details",
        routePath: "user-details",
        component: EditUserInformation,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    ADDRESS: {
        label: "Address",
        value: "address",
        pagePath: "/user-address",
        routePath: "user-address",
        component: EditUserAddress,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    CULTURE: {
        label: "Culture",
        value: "culture",
        pagePath: "/user-culture",
        routePath: "user-culture",
        component: EditUserCulture,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    EDUCATION: {
        label: "Education",
        value: "education",
        pagePath: "/user-education",
        routePath: "user-education",
        component: EditUserEducation,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    SOCIAL: {
        label: "Social",
        value: "social",
        pagePath: "/user-social",
        routePath: "user-social",
        component: EditUserSocial,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    BADGES: {
        label: "Badges",
        value: "badges",
        pagePath: "/user-badges",
        routePath: "user-badges",
        component: EditUserBadges,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    AVAILABILITY_LIST: {
        label: "Availability",
        value: "availability",
        pagePath: "/user-availability",
        routePath: "user-availability",
        component: EditUserAvailabilityListPage,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    CREATE_AVAILABILITY: {
        label: "Create Availability",
        value: "availability",
        pagePath: "/user-availability",
        routePath: "user-availability/create",
        component: CreateAvailabilityDetailPage,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: false
    },
    AVAILABILITY_DETAIL: {
        label: "Availability",
        value: "availability",
        pagePath: "/user-availability",
        routePath: "user-availability/:availabilityId/edit",
        component: AvailabilityDetailPage,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: false
    },
    WALLET: {
        label: "Wallet",
        value: "wallet",
        pagePath: "/wallet",
        routePath: "wallet",
        component: EditUserWallet,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    OTHER_PREFERENCES: {
        label: "Other Preferences",
        value: "other-preferences",
        pagePath: "/other-preferences",
        routePath: "other-preferences",
        component: EditOtherPreferences,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
}