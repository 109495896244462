import { useCallback, useEffect, useState } from "react";

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";

import FilterBox from 'components/pageFilter/FilterBox';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setFilterProps } from "redux/availability/availability.slice";
import { DEFAULT_AVAILABILITY_LIST_PAGE, availabilityIsRecurringEnum } from "redux/availability/availability.const";

import { cn } from "utils/cn.utils";

const AvailabilityRecurringFilter = ({ filterItem, activeFilter, setActiveFilter }) => {
    const { filterProps } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const [recurringFilterPayload, setRecurringFilterPayload] = useState({
        selectedType: null,
        message: ""
    })

    useEffect(() => {
        if (!!filterProps?.isRecurring) {
            setRecurringFilterPayload({
                ...recurringFilterPayload,
                selectedType: filterProps?.isRecurring?.replaceAll("-", "_"),
                message: ""
            })
        } else {
            setRecurringFilterPayload({
                message: "",
                selectedType: null
            })
        }
    }, [filterProps?.isRecurring])

    const onHandleMouseLeave = () => {
        if (!filterProps?.isRecurring) {
            setRecurringFilterPayload({
                message: "",
                selectedType: null
            })
        }
        setActiveFilter(null)
    }

    const onHandleSelectOption = useCallback((selectedType) => {
        setRecurringFilterPayload({
            ...recurringFilterPayload,
            selectedType: selectedType,
            message: ""
        })
    }, [recurringFilterPayload])

    const onHandleApplyFilter = useCallback(() => {
        if (!recurringFilterPayload?.selectedType) {
            setRecurringFilterPayload({
                ...recurringFilterPayload,
                message: "Please select availability type!"
            })
            return;
        }
        setRecurringFilterPayload({
            ...recurringFilterPayload,
            message: ""
        })
        dispatcher(setFilterProps({ filterProps: { ...filterProps, page: DEFAULT_AVAILABILITY_LIST_PAGE, isRecurring: recurringFilterPayload?.selectedType?.replaceAll("_", "-") } }))
        setActiveFilter(null)
    }, [recurringFilterPayload, filterProps])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setRecurringFilterPayload({
            ...recurringFilterPayload,
            selectedType: null,
            message: ""
        })

        dispatcher(setFilterProps({ filterProps: { ...filterProps, isRecurring: "" } }))
        setActiveFilter(null)
    }, [filterProps])

    const getBoxItemFilter = () => {
        if (!!filterProps?.isRecurring) {
            let boxFilterItems = []
            if (filterProps?.isRecurring === availabilityIsRecurringEnum.RECURRING.value) {
                boxFilterItems = [availabilityIsRecurringEnum.RECURRING.label]
            } else {
                boxFilterItems = [filterProps?.isRecurring?.replaceAll("-", " ")]
            }
            return boxFilterItems;
        }
    }

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={!!filterProps?.isRecurring
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            boxItems={getBoxItemFilter()}
            activeContainerClassName={"-translate-x-10 sm:translate-x-0"}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By Recurring"}
                </span>
                <div className={"space-y-5"}>
                    {Object.values(availabilityIsRecurringEnum)?.map((item, index) => (
                        <div
                            key={index}
                            className={"w-full flex items-center justify-start gap-3 group cursor-pointer"}
                            onClick={() => onHandleSelectOption(item?.value)}
                        >
                            <input
                                type={"radio"}
                                checked={(recurringFilterPayload?.selectedType === item?.value) && true}
                            />
                            <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide line-clamp-1"}>
                                {item?.label}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!recurringFilterPayload?.selectedType) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
}

export default AvailabilityRecurringFilter;