import { useState } from "react";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { createCompanyDocument, updateCompanyDocument } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setCompanyDocumentPayload } from "redux/onboarding/onboarding.slice";
import { companyDocumentsCategory, companyOnboardingStatus } from "redux/onboarding/onboarding.const";

const UploadCompanyDocument = ({ setIsAddDocument, setIsOpenAddDocxContainer }) => {
    const { companyDocumentPayload } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()

    const onHandleFileUpload = (event) => {
        let selectedFile = event.target.files[0];
        const url = URL.createObjectURL(selectedFile)
        dispatcher(setCompanyDocumentPayload({
            ...companyDocumentPayload?.companyDocumentPayload,
            file: selectedFile.name,
            fileUrl: url,
            fileType: selectedFile.type
        }))
    }

    const onSubmitDocument = (event) => {
        event.preventDefault()
        if (!companyDocumentPayload?.companyDocumentPayload?.documentId) {
            dispatcher(createCompanyDocument({
                ...companyDocumentPayload?.companyDocumentPayload,
                documentId: (Math.floor(Math.random() * 10) * 1),
                status: companyOnboardingStatus.SUBMITTED.value
            }))
        }
        if (companyDocumentPayload?.companyDocumentPayload?.documentId) {
            dispatcher(updateCompanyDocument(companyDocumentPayload?.companyDocumentPayload))
        }
        setIsOpenAddDocxContainer(false)
        setIsAddDocument(true)
    }

    return (
        <form
            onSubmit={onSubmitDocument}
            className="space-y-5 w-full border border-text-400 p-3">
            <div className='grid grid-cols-12 gap-5'>
                <div className='col-start-0 col-span-6 space-y-2'>
                    <label className='font-bodyPri font-normal text-text-900 text-sm'>
                        {"Category"}
                        <span className="text-red-500 text-lg">{"*"}
                        </span>
                    </label>
                    <OptionSelector
                        options={Object.values(companyDocumentsCategory)}
                        placeholder={"Please pick a type"}
                        value={companyDocumentPayload?.companyDocumentPayload?.fileCategory}
                        onChange={(option) => dispatcher(setCompanyDocumentPayload({
                            ...companyDocumentPayload?.companyDocumentPayload,
                            fileCategory: option.value
                        }))}
                        required={true}
                    />
                </div>
                <div className={"col-start-0 col-span-6 space-y-2"}>
                    <label className='font-bodyPri font-normal text-text-900 text-sm'>
                        {"File Name"}
                        <span className="text-red-500 text-lg">
                            {"*"}
                        </span>
                    </label>
                    <input
                        type={"text"}
                        rows={2}
                        className={cn(
                            "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        minLength={5}
                        maxLength={50}
                        placeholder={"tell us something about the document"}
                        value={companyDocumentPayload?.companyDocumentPayload?.fileName}
                        onChange={(e) => dispatcher(setCompanyDocumentPayload({
                            ...companyDocumentPayload?.companyDocumentPayload,
                            fileName: e.target.value
                        }))}
                        required={true}
                    />
                </div>
                <div className='col-start-0 col-span-6 space-y-2'>
                    <span className='font-bodyPri font-normal text-text-900 text-sm whitespace-nowrap space-x-1'>
                        <span>
                            {"Select File"}
                        </span>
                        <span>
                            {"(maximum 3 MB each)"}
                        </span>
                        <span className="text-red-500 text-lg">
                            {"*"}
                        </span>
                    </span>
                    <label className='relative inline-flex mx-auto shadow-sm'>
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept=".doc, .docx,.pdf, application/pdf, application/msword"
                            onChange={onHandleFileUpload}
                        />
                        <input
                            type={"text"}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-l-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            placeholder={"No File Chosen"}
                            required
                            value={companyDocumentPayload?.companyDocumentPayload?.file}
                        />
                        <span className='p-2 rounded-r-md bg-primary-light border-2 border-primary-light hover:(text-text-50 border-secondary-dark) cursor-pointer font-buttons font-normal text-sm text-primary-dark whitespace-nowrap'
                        >
                            {"Choose a file.."}
                        </span>
                    </label>
                    {/* {fileError &&
              <span className='font-bodyPri font-normal text-text-900 text-sm tracking-wide'>
                {"No File Choosen"}
              </span>
            } */}
                </div>
            </div>
            <div className={"flex items-start justify-start gap-5"}>
                <button
                    type={"submit"}
                    className={cn
                        ("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                            "border border-secondary-main text-secondary-main",
                            "hover:bg-secondary-main hover:text-text-50"
                        )}
                >
                    {"Save"}
                </button>
                <div
                    className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                        "border border-primary-main text-primary-main",
                        "hover:bg-primary-main hover:text-text-50"
                    )}
                    onClick={() => {
                        setIsOpenAddDocxContainer(false)
                        setIsAddDocument(true)
                    }}
                >
                    {"Cancel"}
                </div>
            </div>
        </form>
    )
}

export default UploadCompanyDocument