import { cn } from "utils/cn.utils";
import { useState, useEffect } from 'react';
import { toast } from "react-toastify";

import { MdEdit } from 'react-icons/md';
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";

import ButtonLoader from "components/loader/ButtonLoader";
import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';
import InputOtp from "components/inputOtp/InputOtp";

import { getMasterCountryList } from 'redux/master/master.request';
import { createSendOtp, createVerifyOtp } from 'redux/notification/notification.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from "redux/local/local.slice";
import { setClearCountryList } from 'redux/master/master.slice';
import { setClearVerifyOtp, setClearSendOtp } from 'redux/notification/notification.slice';
import { modalConst } from "redux/local/local.const";
import { otpStatus } from "redux/notification/notification.const";

import { validateMobileNo } from 'utils/validation-functions';

const ValidationBarMobileVerifyModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { user } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)

    const [isOpenMobileOtpContainer, setIsOpenMobileOtpContainer] = useState(false)
    const [isMobileEditMode, setIsMobileEditMode] = useState(false)
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })
    const [mobileDetail, setMobileDetail] = useState({
        countryCode: user?.user?.isdCode,
        mobileNo: user?.user?.mobileNo
    })

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]: false
            }))
            toast.success("Mobile Number verified successfully!")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            toast.warning("Something went wrong")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            setIsOtpError(true)
            toast.error("Incorrect code!")
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    const handleEditMobileNo = () => {
        if (isMobileEditMode) {
            if (validateMobileNo(mobileDetail?.mobileNo)) {
                setMobileDetail({
                    countryCode: myCountry?.isdCode,
                    mobileNo: mobileDetail?.mobileNo
                })
                setIsMobileEditMode(!isMobileEditMode)
            }
            else {
                toast.warning("Enter Valid Mobile Number!")
            }
        }
        else {
            setIsMobileEditMode(!isMobileEditMode)
        }
    }

    const handleMobileVerification = async () => {
        if (isOpenMobileOtpContainer) {
            if (myOtp?.length < 4) return;
            dispatcher(setClearVerifyOtp())
            if (sendOtp?.sendOtp?.otpNotificationId) {
                dispatcher(createVerifyOtp({
                    otpNotificationId: sendOtp?.sendOtp?.otpNotificationId,
                    otp: myOtp
                }))
            }
            else {
                toast.error("Incorrect Code")
                setIsOtpError(true)
            }
        }
        if (!isOpenMobileOtpContainer) {
            if (isMobileEditMode) return;
            if (validateMobileNo(mobileDetail?.mobileNo) && !isMobileEditMode) {
                dispatcher(createSendOtp({
                    otpDeliveryMethod: "mobile",
                    mobileNo: mobileDetail?.mobileNo
                }))
                toast.success("Code Sent Successfully")
                setIsOpenMobileOtpContainer(true)
                return;
            }
            if (!mobileDetail?.mobileNo) {
                toast.warning("Please Enter mobile number for Verification")
                setIsMobileEditMode(true)
            }
        }
    }

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    return (
        <>
            <div className='space-y-5'>
                <div className='flex items-center justify-center gap-3'>
                    <span className='font-bodyPri font-normal text-text-700 text-base whitespace-nowrap'>
                        {"Mobile No. : "}
                    </span>
                    {!isMobileEditMode &&
                        <span className='font-bodySec font-normal text-text-900 text-base'>
                            {"+" + mobileDetail?.countryCode + " - " + mobileDetail?.mobileNo}
                        </span>
                    }
                    {isMobileEditMode &&
                        <div className={cn(
                            "w-full flex items-center justify-start px-2 py-1.5 input-number-spin-none border-2 border-secondary-main border-dashed focus:outline-none",
                            "font-bodySec font-normal text-text-900 text-base overflow-hidden"
                        )}>
                            {countryList?.isLoading &&
                                <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                            }
                            {countryList?.countryList &&
                                <IsdCodeSelector
                                    options={countryList?.countryList?.map((country) => ({
                                        label: country?.isdCode,
                                        value: country?.masterCountryId,
                                        country: country
                                    }))}
                                    value={myCountry?.masterCountryId}
                                    onChange={(option) => onSelectIsdCode(option)}
                                />
                            }
                            <input
                                type={"number"}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none focus:outline-none",
                                    "font-bodySec font-normal text-text-900 text-base"
                                )}
                                value={mobileDetail?.mobileNo}
                                autoFocus={true}
                                onChange={(e) => setMobileDetail({
                                    ...mobileDetail,
                                    mobileNo: e.target.value
                                })}
                            />
                        </div>
                    }
                    <div className='cursor-pointer' onClick={handleEditMobileNo}>
                        {!isMobileEditMode && <MdEdit className='text-2xl text-text-500' />}
                        {isMobileEditMode && <AiFillCheckCircle className='text-2xl text-secondary-main' />}
                    </div>
                </div>
                {isOpenMobileOtpContainer &&
                    <InputOtp
                        email={mobileDetail?.mobileNo}
                        setMyOtp={setMyOtp}
                        isOtpError={isOtpError}
                        setIsOtpError={setIsOtpError}
                    />
                }
                <div className={cn(
                    "py-1.5 rounded-lg",
                    "text-text-50 font-buttons font-medium text-base w-full text-center cursor-pointer",
                    (isMobileEditMode || (isOpenMobileOtpContainer && myOtp?.length < 4)) && "bg-primary-light",
                    ((!isMobileEditMode && !isOpenMobileOtpContainer) || (isOpenMobileOtpContainer && myOtp?.length === 4)) && "bg-primary-main hover:bg-secondary-dark"
                )}
                    onClick={handleMobileVerification}
                >
                    {verifyOtp?.isLoading && <ButtonLoader isLoading={verifyOtp?.isLoading} />}
                    {(!verifyOtp?.isLoading && isOpenMobileOtpContainer) && "Verify Code"}
                    {(!verifyOtp?.isLoading && !isOpenMobileOtpContainer) && "Send Code"}
                </div>
            </div>
            {user?.user?.isMobileNoVerified &&
                <div className='flex flex-col justify-center items-center space-y-2'>
                    <BsCheckLg className='text-4xl text-green-500' />
                    <span className='font-bodyPri font-semibold text-lg text-text-900'>
                        {"Mobile No. Verified Successfully!"}
                    </span>
                </div>
            }
        </>
    )
}

export default ValidationBarMobileVerifyModal