import { dataEntriesConst } from "redux/local/local.const";

export const url = {
    GET_APPOINTMENT_LIST: "appointments/appointments",

    GET_APPOINTMENT_DETAIL: "appointments/appointments/{appointmentId}",
    UPDATE_APPOINTMENT_DETAIL: "appointments/appointments/{appointmentId}",
    DELETE_APPOINTMENT_DETAIL: "appointments/appointments/{appointmentId}"
}

export const appointmentRedux = {
    sliceName: "appointment",
    appointmentList: {
        requestName: "getAppointmentList"
    },
    appointmentDetail: {
        requestName: "getAppointmentDetail"
    }
}

export const appointmentPriceModelEnum = {
    FREE: {
        label: "Free",
        value: "free"
    },
    FLAT_FEE: {
        label: "Flat Fee",
        value: "flat_fee"
    }
}

export const appointmentStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
}

export const appointmentVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const modifyAppointmentDetailPayload = {
    id: null,
    slug: "",
    title: "",
    subtitle: "",
    duration: 30,
    price: 0,
    price_model: "",
    discount_pct: 0,
    status: "",
    description: "",
    availability: null,
    user: null,
    masterCurrency: null,
    visibility: null,
}

export const appointmentFilterProps = {
    searchText: "",
    id: "",
    title: "",
    slug: "",
    priceModel: "",
    user: "",
    availabilityId: "",
    status: "",
    startDate: "",
    endDate: "",
    page: 1,
    records: dataEntriesConst?.TWENTY_FIVE?.value
}