import { useNavigate } from "react-router-dom";

import {
    SubMenuItem,
    ItemName
} from './NavBarStyle';

const HamburgerSubMenu = ({ menuItem, setIsMediumHamburger }) => {

    const navigate = useNavigate()

    const onHandleSubMenuItems = (event) => {
        setIsMediumHamburger(false)
        if (menuItem?.pathType === 'href') {
            window.open(menuItem?.path, "_blank")
        } else {
            if (menuItem?.value === 'buy_gift_card') {
                event.preventDefault()
                alert("Coming Soon")
                return;
            } else if (menuItem?.value === "product-lms-courses") {
                navigate("/lms-courses")
            } else {
                navigate(menuItem?.path)
            }
        }
        event.preventDefault();
    }


    return (
        <SubMenuItem onClick={onHandleSubMenuItems}>
            <ItemName >
                {menuItem?.name}
            </ItemName >
        </SubMenuItem>
    )
}

export default HamburgerSubMenu;