import { useNavigate } from "react-router-dom";

import { cn } from "utils/cn.utils";

import { SubMenuItemLink, SubMenuItemName } from "./SideNavBarStyle";

const SideBarSubMenuItem = ({ menuItem, visible }) => {

    const navigate = useNavigate()

    const itemNameVariants = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }

    const onHandleSubMenuItems = (event) => {
        if (menuItem?.pathType === 'href') {
            window.open(menuItem?.path, "_blank")
        } else {
            if (menuItem?.value === 'buy_gift_card') {
                event.preventDefault()
                alert("Coming Soon")
                return;
            } else if (menuItem?.value === "product-lms-courses") {
                navigate("/lms-courses")
            } else {
                navigate(menuItem?.path)
            }
        }
        event.preventDefault();
    }

    return (
        <SubMenuItemLink
            to={menuItem?.path}
            onClick={onHandleSubMenuItems}
            className={"relative has-tooltip"}>
            <SubMenuItemName
                variants={itemNameVariants}
                initial={'open'}
                animate={visible ? 'open' : 'close'}
                transition={transition}
            >
                {menuItem?.name}
            </SubMenuItemName>
            {!visible &&
                <span
                    className={cn(
                        "w-fit px-2 py-1 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -translate-y-8",
                        "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap",
                        !visible && "-translate-x-[60%]"
                    )}
                >
                    {menuItem?.name}
                </span>
            }

        </SubMenuItemLink>
    )
}

export default SideBarSubMenuItem;