import { createSlice } from "@reduxjs/toolkit";

import { LEAD_INITIAL_STATE } from "redux/lead/lead.initialState";
import { getLeadDetailByLeadId, getLeadList } from "./lead.request";

const lead = createSlice({
    name: "lead",
    initialState: LEAD_INITIAL_STATE,
    reducers: {
        setAddLeadDetailLoading: (state, { payload }) => {
            state.addLeadDetail.isLoading = payload
        },
        setAddLeadDetail: (state, { payload }) => {
            state.addLeadDetail.addLeadDetail = payload
            state.addLeadDetail.errorMsg = LEAD_INITIAL_STATE.addLeadDetail.errorMsg
        },
        setAddLeadDetailErrorMsg: (state, { payload }) => {
            state.addLeadDetail.errorMsg = payload
            state.addLeadDetail.addLeadDetail = LEAD_INITIAL_STATE.addLeadDetail.addLeadDetail
        },
        setClearAddLeadDetail: (state) => {
            state.addLeadDetail = LEAD_INITIAL_STATE.addLeadDetail
        },

        setAddLeadDetailPayload: (state, { payload }) => {
            state.addLeadDetail.addLeadDetailPayload = payload
        },
        setClearAddLeadDetailPayload: (state) => {
            state.addLeadDetail.addLeadDetailPayload = LEAD_INITIAL_STATE.addLeadDetail.addLeadDetailPayload
        },

        setClearLeadList: (state) => {
            state.leadList = LEAD_INITIAL_STATE.leadList
        },

        setClearLeadDetail: (state) => {
            state.leadDetail = LEAD_INITIAL_STATE.leadDetail
        },

        // reducers for lead detail payload
        setLeadDetailPayload: (state, { payload }) => {
            state.leadDetail.payload = payload
        },
        resetLeadDetailPayload: (state) => {
            state.leadDetail = LEAD_INITIAL_STATE.leadDetail
        },

        // reducers for filtering the list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
        },
        setClearFilterProps: (state) => {
            state.filterProps = LEAD_INITIAL_STATE.filterProps
        }
    },

    extraReducers: {
        // extra reducers for get lead list
        [getLeadList.pending]: (state, { payload }) => {
            state.leadList.isLoading = true
        },
        [getLeadList.fulfilled]: (state, { payload }) => {
            state.leadList.isLoading = false
            state.leadList.leadList = payload
            state.leadList.filteredLeadList = payload
            state.leadList.errorMsg = LEAD_INITIAL_STATE.leadList.errorMsg
        },
        [getLeadList.rejected]: (state, { payload }) => {
            state.leadList.isLoading = false
            state.leadList.errorMsg = payload
            state.leadList.leadList = LEAD_INITIAL_STATE.leadList.leadList
            state.leadList.filteredLeadList = LEAD_INITIAL_STATE.leadList.filteredLeadList
        },

        // extra reducers for lead detail
        [getLeadDetailByLeadId.pending]: (state) => {
            state.leadDetail.isLoading = true
        },
        [getLeadDetailByLeadId.fulfilled]: (state, { payload }) => {
            state.leadDetail.isLoading = false
            state.leadDetail.leadDetail = payload
        },
        [getLeadDetailByLeadId.rejected]: (state, { payload }) => {
            state.leadDetail.isLoading = false
            state.leadDetail.errorMsg = payload
            state.leadDetail.leadDetail = LEAD_INITIAL_STATE.leadDetail.leadDetail
        }
    }
})

export const {
    setAddLeadDetailLoading,
    setAddLeadDetail,
    setAddLeadDetailErrorMsg,
    setClearAddLeadDetail,

    setAddLeadDetailPayload,
    setClearAddLeadDetailPayload,

    setClearLeadList,
    setClearLeadDetail,

    setLeadDetailPayload,
    resetLeadDetailPayload,

    setFilterProps,
    setClearFilterProps
} = lead.actions

export default lead.reducer
