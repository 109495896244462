import { dayjs } from "utils/dateTime.utils";

import {
  SearchReferralId,
  SearchRefereeUser,
  SearchBeneficiaryUser,
  SearchReferralPrice,
  FilterReferralStatus,
  SearchDate,
  SearchPublishedDate,
  ButtonAction,
} from "./components/ReferralStatistics"

export const pageHeading = {
  heading: "Referrals",
  subHeading: "Find the list of referrals!",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const referralHeaderConst = {
  REFERRAL_ID: {
    label: "Referral ID",
    value: "referral_id"
  },
  REFEREE_USER: {
    label: "Referee User",
    value: "referee_user"
  },
  BENEFICIARY_USER: {
    label: "Beneficiary User",
    value: "beneficiary_user"
  },
  AMOUNT: {
    label: "Amount",
    value: "amount"
  },
  STATUS: {
    label: "Status",
    value: "status"
  },
  DATE: {
    label: "Date",
    value: "date"
  },
  PUBLISHED_DATE: {
    label: "Published Date",
    value: "published_date"
  },
  ACTIVITY: {
    label: "Activity",
    value: "activity"
  }
}

export const subRowHeader = [
  <SearchReferralId />,
  <SearchRefereeUser />,
  <SearchBeneficiaryUser />,
  <SearchReferralPrice />,
  <FilterReferralStatus />,
  <SearchDate />,
  <SearchPublishedDate />,
  <ButtonAction />
]

export const sortReferralLowToHigh = (activeSortHeader, referral1, referral2) => {
  if (activeSortHeader?.activeSortKey === referralHeaderConst.REFERRAL_ID.value) {
    return (Number(referral1?.id) - Number(referral2?.id))
  }
  if (activeSortHeader?.activeSortKey === referralHeaderConst.REFEREE_USER.value) {
    return referral1?.refereeUser?.firstName?.toLowerCase()?.localeCompare(referral2?.refereeUser?.firstName?.toLowerCase())
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.BENEFICIARY_USER.value) {
    return referral1?.beneficiaryUser?.firstName?.toLowerCase()?.localeCompare(referral2?.beneficiaryUser?.firstName?.toLowerCase())
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.AMOUNT.value) {
    return Number(referral1?.amount) - Number(referral2?.amount)
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.STATUS.value) {
    return referral1?.status?.toLowerCase()?.localeCompare(referral2?.status?.toLowerCase())
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.DATE.value) {
    return dayjs(referral2?.createdAt)?.isBefore(dayjs(referral1?.createdAt)) ? 1 : -1
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.PUBLISHED_DATE.value) {
    return dayjs(referral2?.publishedAt)?.isBefore(dayjs(referral1?.publishedAt)) ? 1 : -1
  }
}

export const sortReferralHighToLow = (activeSortHeader, referral1, referral2) => {
  if (activeSortHeader?.activeSortKey === referralHeaderConst.REFERRAL_ID.value) {
    return (Number(referral2?.id) - Number(referral1?.id))
  }
  if (activeSortHeader?.activeSortKey === referralHeaderConst.REFEREE_USER.value) {
    return referral2?.refereeUser?.firstName?.toLowerCase()?.localeCompare(referral1?.refereeUser?.firstName?.toLowerCase())
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.BENEFICIARY_USER.value) {
    return referral2?.beneficiaryUser?.firstName?.toLowerCase()?.localeCompare(referral1?.beneficiaryUser?.firstName?.toLowerCase())
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.AMOUNT.value) {
    return Number(referral2?.amount) - Number(referral1?.amount)
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.STATUS.value) {
    return referral2?.status?.toLowerCase()?.localeCompare(referral1?.status?.toLowerCase())
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.DATE.value) {
    return dayjs(referral1?.createdAt)?.isBefore(dayjs(referral2?.createdAt)) ? 1 : -1
  }
  if (activeSortHeader.activeSortKey === referralHeaderConst.PUBLISHED_DATE.value) {
    return dayjs(referral1?.publishedAt)?.isBefore(dayjs(referral2?.publishedAt)) ? 1 : -1
  }
}