import { useState } from "react";

import CourseDetailsSection from "pages/auth/viewCourseDetail/components/basicInputPage/components/CourseDetailsSection";
import CourseDescriptionSection from "pages/auth/viewCourseDetail/components/basicInputPage/components/CourseDescriptionSection";
import CourseVideoSection from "pages/auth/viewCourseDetail/components/basicInputPage/components/CourseVideoSection";
import CourseThumbnailSection from "pages/auth/viewCourseDetail/components/basicInputPage/components/CourseThumbnailSection";
import CourseSkillsSection from "pages/auth/viewCourseDetail/components/basicInputPage/components/CourseSkillsSection";
import CourseLanguageSection from "pages/auth/viewCourseDetail/components/basicInputPage/components/CourseLanguageSection";
import CourseProficiencySection from "pages/auth/viewCourseDetail/components/basicInputPage/components/CourseProficiencySection";
import CourseTutorsSection from "pages/auth/viewCourseDetail/components/basicInputPage/components/CourseTutorsSection";

import { inputSections } from "pages/auth/viewCourseDetail/data";

const BasicInputPage = () => {
    const [clickedItem, setClickedItem] = useState(inputSections?.COURSE_DETAILS?.value);

    return (
        <div className={"space-y-5"}>
            <CourseDetailsSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
            <CourseDescriptionSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
            <CourseVideoSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
            <CourseSkillsSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
            <CourseThumbnailSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
            <CourseLanguageSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
            <CourseProficiencySection clickedItem={clickedItem} setClickedItem={setClickedItem} />
            <CourseTutorsSection clickedItem={clickedItem} setClickedItem={setClickedItem} />
        </div>
    )
}

export default BasicInputPage