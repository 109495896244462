import { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';

import { getSessionCourseList } from 'redux/session/session.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetSessionCourseList } from 'redux/session/session.slice';
import { sessionStatusEnum } from 'redux/session/session.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { generateQueryParams } from "utils/generators.utils";
import { pagesInfo } from 'utils/pagesInfo';

const EditCourseSessionPage = () => {
    const { courseDetail } = useAppState((state) => state.course)
    const { sessionCourseList } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (courseDetail?.courseDetail?.id) {
            const query = generateQueryParams(location.search)
            dispatcher(getSessionCourseList({ page: query?.pn || "1", records: 10, courseId: courseDetail?.courseDetail?.id }))
        }

        return () => {
            dispatcher(resetSessionCourseList())
        }
    }, [courseDetail?.courseDetail?.id, location.search])

    return (
        <div className={cn(
            "w-full h-full bg-white rounded-lg select-none",
            "min-h-[60vh]"
        )}>
            <div className={"w-full px-5 py-3 bg-background-darkLight rounded-lg"}>
                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Sessions"}
                </div>
            </div>

            <div className={"w-full p-5 space-y-5"}>

                <ComponentLoader isLoading={sessionCourseList?.isLoading} />
                {sessionCourseList?.data?.records > 0 &&
                    sessionCourseList?.data?.results?.map((sessionCourse, index) => (
                        <div
                            key={index}
                            className={`w-full border border-${sessionStatusEnum[sessionCourse?.session?.status?.toUpperCase()]?.darkColor} rounded-lg px-5 py-2 space-y-3`}>

                            <div className={"flex items-center justify-start gap-1"}>
                                <span className={"font-bodyPri font-semibold text-text-900 text-lg"}>
                                    {sessionCourse?.session?.id + "."}
                                </span>
                                <span className={"w-full font-bodyPri font-semibold text-text-900 text-lg tracking-wide text-left truncate border-b bordespanider-medium"}>
                                    {sessionCourse?.session?.name?.length > 60
                                        ? sessionCourse?.session?.name?.slice(0, 60) + " ..."
                                        : sessionCourse?.session?.name
                                    }
                                </span>
                            </div>

                            <div className={"grid grid-cols-4 w-full gap-3"}>
                                <div className={"col-start-1 col-span-2 space-x-1"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"Duration:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                        {sessionCourse?.session?.duration <= 60
                                            ? sessionCourse?.session?.duration + " min"
                                            : parseFloat(sessionCourse?.session?.duration / 60)?.toFixed(2) + " hrs"
                                        }
                                    </span>
                                </div>
                                <div className={"col-start-3 col-span-2 space-x-1"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"Status:"}
                                    </span>
                                    <span className={`font-bodyPri font-medium text-base tracking-wide text-${sessionStatusEnum[sessionCourse?.session?.status?.toUpperCase()]?.darkColor}`}>
                                        {sessionStatusEnum[sessionCourse?.session?.status?.toUpperCase()]?.label}
                                    </span>
                                </div>

                                <div className={"col-start-1 col-span-2 space-x-1"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"Start Date:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                        {dayjs(sessionCourse?.session?.startDateTime)?.tz(timeZone)?.format("ddd, DD MMM YY hh:mm a")}
                                    </span>
                                </div>
                                <div className={"col-start-3 col-span-2 space-x-1"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"End Date:"}
                                    </span>
                                    <span className={`font-bodyPri font-medium text-base tracking-wide text-text-900`}>
                                        {dayjs(sessionCourse?.session?.endDateTime)?.tz(timeZone)?.format("ddd, DD MMM YY hh:mm a")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}

                {(sessionCourseList?.data && sessionCourseList?.data?.records > 0) && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={sessionCourseList?.data?.page}
                            totalPages={sessionCourseList?.data?.totalPages}
                            onChangePage={(page) => navigate(`${pagesInfo?.VIEW_COURSE?.pagePath}/${courseDetail?.courseDetail?.id}/class-sessions?pn=${page}`)}
                        />
                    </div>
                )}

                {(!sessionCourseList?.isLoading && (!sessionCourseList?.data || sessionCourseList?.data?.records === 0 || sessionCourseList?.message)) &&
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {sessionCourseList?.message || "No sessions found!"}
                    </span>
                }
            </div>
        </div>
    )
}

export default EditCourseSessionPage;