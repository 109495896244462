import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import { MdOutlineDragIndicator } from 'react-icons/md';
import { BsInfoCircleFill } from 'react-icons/bs';

import ViewTutorHeader from 'pages/auth/viewTutor/commonComponents/ViewTutorHeader';

import ComponentLoader from 'components/loader/ComponentLoader';
import { getTutorCategoryList } from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetTutorCategoryList } from 'redux/tutor/tutor.slice';
import { tutorLanguageMessageType } from 'redux/tutor/tutor.const';


const ViewTutorSubject = () => {
    const { tutorCategoryList } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const { tutorId } = useParams()


    useEffect(() => {
        return () => {
            dispatcher(resetTutorCategoryList())
        }
    }, [])

    useEffect(() => {
        if (tutorId) {
            dispatcher(getTutorCategoryList({ tutorId: Number(tutorId) }))
        }
    }, [tutorId])

    return (
        <div className='w-full flex flex-col items-start justify-start gap-1'>

            <ViewTutorHeader title={"Subjects & Topics, I teach: Levels of Mastery"} />

            {tutorCategoryList?.isLoading &&
                <ComponentLoader isLoading={tutorCategoryList?.isLoading} />
            }
            {(!tutorCategoryList?.isLoading && tutorCategoryList?.message) &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {tutorCategoryList?.message}
                    </span>
                </div>
            }
            {tutorCategoryList?.data?.message &&
                <div className={"w-full px-2 py-2 sm:py-4 flex items-center justify-center gap-3 bg-white rounded-lg"}>
                    {tutorCategoryList?.data?.message?.type === tutorLanguageMessageType?.INFO?.value &&
                        <span className={cn(`text-${tutorLanguageMessageType?.INFO?.darkColor}`)}>
                            <BsInfoCircleFill className={"text-xl sm:text-2xl"} />
                        </span>
                    }
                    <span className={"font-bodyPri font-normal text-text-900 text-base lg:text-lg"}>
                        {tutorCategoryList?.data?.message?.text}
                    </span>
                </div>
            }

            {(tutorCategoryList?.data?.results?.length > 0) &&
                <div className={"space-y-3 divide-y divide-text-200 px-3 py-0.5 md:py-1"}>
                    {tutorCategoryList?.data?.results?.length > 0 &&
                        tutorCategoryList?.data?.results?.map((tutorCategory, index) => (
                            <div key={index} className={"grid grid-cols-12 gap-5"}>
                                <span className={"col-start-1 col-span-1 self-start"}>
                                    <MdOutlineDragIndicator className={"text-lg text-text-800"} />
                                </span>
                                <span className={"col-start-2 col-span-4 font-bodyPri font-medium text-text-700 text-base tracking-wide truncate"}>
                                    {tutorCategory?.category?.category}
                                </span>
                                <span className={"col-start-7 col-span-4 font-bodyPri font-medium text-text-900 text-base tracking-wide truncate"}>
                                    {tutorCategory?.masterProficiency?.proficiency}
                                </span>

                            </div>
                        ))}
                </div>
            }
        </div>
    )
}

export default ViewTutorSubject;