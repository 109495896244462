import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';

import ReactDateRangePicker from 'components/datePickers/ReactDateRangePicker';
import FilterBox from 'components/pageFilter/FilterBox';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setFilterProps } from 'redux/availability/availability.slice';
import { DEFAULT_AVAILABILITY_LIST_PAGE } from 'redux/availability/availability.const';

import { cn } from 'utils/cn.utils';
import { dayjs, getTimeZoneOffset, timeZone } from "utils/dateTime.utils";

const initialDatePayload = {
    isDatePickerActive: false,
    startDate: dayjs(dayjs().subtract(29, 'days'))?.tz(timeZone)?.format("MM/DD/YYYY"),
    endDate: dayjs()?.tz(timeZone)?.format("MM/DD/YYYY"),
    message: ""
}
const AvailabilityDateFilter = memo(({ filterItem, activeFilter, setActiveFilter }) => {

    const { filterProps } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const [datePayload, setDatePayload] = useState({ ...initialDatePayload })

    const timeZoneOffset = useMemo(() => getTimeZoneOffset(timeZone), [timeZone])

    useEffect(() => {
        if (!!filterProps?.minCreatedAt && !!filterProps?.maxCreatedAt) {
            setDatePayload({
                ...datePayload,
                isDatePickerActive: false,
                startDate: filterProps?.minCreatedAt,
                endDate: filterProps?.maxCreatedAt,
                message: ""
            })
        } else {
            setDatePayload(initialDatePayload)
        }
    }, [filterProps?.minCreatedAt, filterProps?.maxCreatedAt])

    const onHandleSelectedDate = (dateObject) => {
        setDatePayload({
            ...datePayload,
            isDatePickerActive: false,
            startDate: dayjs(dayjs(dateObject?.startDate).format("YYYY-MM-DD") + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format("MM/DD/YYYY"),
            endDate: dayjs(dayjs(dateObject?.endDate).format("YYYY-MM-DD") + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format("MM/DD/YYYY")
        })
    }

    const onHandleMouseLeave = () => {
        if (datePayload?.isDatePickerActive) return;

        if (!filterProps?.minCreatedAt || !filterProps?.maxCreatedAt) {
            setDatePayload(initialDatePayload)
        }
        setActiveFilter(null)
    }

    const onHandleApplyFilter = useCallback(() => {
        if (!datePayload?.startDate || !datePayload?.endDate) {
            setDatePayload({
                ...datePayload,
                message: "Please select date range"
            })
            return;
        }
        setDatePayload({
            ...datePayload,
            message: ""
        })
        dispatcher(setFilterProps({ filterProps: { ...filterProps, page: DEFAULT_AVAILABILITY_LIST_PAGE, minCreatedAt: datePayload?.startDate, maxCreatedAt: datePayload?.endDate } }))
        setActiveFilter(null)
    }, [datePayload, filterProps])

    const onHandleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setDatePayload(initialDatePayload)

        dispatcher(setFilterProps({ filterProps: { ...filterProps, minCreatedAt: null, maxCreatedAt: null } }))
        setActiveFilter(null)
    }, [filterProps])

    return (
        <FilterBox
            boxTitle={filterItem?.label}
            boxIcon={(!!filterProps?.minCreatedAt && !!filterProps?.maxCreatedAt)
                ? <button onClick={onHandleClearFilter}>
                    <AiOutlineCloseCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
                : <button>
                    <AiOutlinePlusCircle className={"text-lg text-text-800 group-hover:text-primary-light"} />
                </button>
            }
            isActive={activeFilter === filterItem.key}
            onHandleToggleBox={() => setActiveFilter(filterItem.key)}
            onHandleMouseLeave={onHandleMouseLeave}
            BoxItemContent={() => {
                return (!!filterProps?.minCreatedAt && !!filterProps?.maxCreatedAt) && (
                    <>
                        <div className={"h-[20px] border border-divider-lightDark"} />
                        <span className={"font-bodyPri font-normal text-primary-dark text-base"}>
                            {`${dayjs(filterProps?.minCreatedAt)?.tz(timeZone)?.format("MM/DD/YYYY")}-${dayjs(filterProps?.maxCreatedAt)?.format("MM/DD/YYYY")}`}
                        </span>
                    </>
                )
            }}
            customBoxClassName={(!!filterProps?.minCreatedAt && !!filterProps?.maxCreatedAt) && "!bg-text-200 !border-solid"}
            activeContainerClassName={"-translate-x-28 sm:translate-x-0"}
        >
            <div className={"w-60 md:w-72 p-3 flex flex-col gap-5"}>
                <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                    {"Filter By Date"}
                </span>
                <ReactDateRangePicker
                    selectedDateRange={{
                        startDate: datePayload?.startDate,
                        endDate: datePayload?.endDate
                    }}
                    onHandleSelectedRange={onHandleSelectedDate}
                    onHandleCancel={initialDatePayload}
                    onHandleInputClick={() => setDatePayload({
                        ...datePayload,
                        isDatePickerActive: true
                    })}
                    onHandleBlurInput={() => setDatePayload({
                        ...datePayload,
                        isDatePickerActive: false
                    })}
                />
                <div className={cn(
                    "w-full py-1 flex items-center justify-center rounded-md border bg-primary-dark cursor-pointer hover:opacity-90",
                    (!datePayload?.startDate || !datePayload?.endDate) && "bg-text-300 text-text-300 hover:!bg-text-300 cursor-not-allowed"
                )}
                    onClick={onHandleApplyFilter}
                >
                    <span className={"font-bodyPri font-medium text-text-50 text-sm tracking-wide "}>
                        {"Apply Filters"}
                    </span>
                </div>
            </div>
        </FilterBox>
    )
});

export default AvailabilityDateFilter;