import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { bookingHeaderConst } from "pages/auth/bookings/data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { resetAddBookingDetail, resetAddBookingDetailPayload } from "redux/booking/booking.slice";
import { setFilterProps } from "redux/booking/booking.slice";
import { dataEntriesConst, modalConst } from 'redux/local/local.const';
import { bookingStatusEnum } from 'redux/booking/booking.const';
import { courseType } from "redux/course/course.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const MyBookingSearch = ({ showEntries, setShowEntries }) => {
    const { modal } = useAppState((state) => state.local)
    const { bookingList, filterProps } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    const header = Object.values(bookingHeaderConst)?.filter((header) => header?.value !== bookingHeaderConst?.ACTIVITY?.value)?.map((head) => head?.label)

    const body = bookingList?.bookingList?.results?.map((booking) => ({
        [bookingHeaderConst?.BOOKING_ID?.label]: booking?.bookingId,
        [bookingHeaderConst?.CATEGORY?.label]: booking?.category,
        [bookingHeaderConst?.TUTOR_NAME?.label]: booking?.tutor?.firstName + " " + booking?.tutor?.lastName,
        [bookingHeaderConst?.CLASS_TITLE?.label]: booking?.course?.courseTitle,
        [bookingHeaderConst?.PRICE?.label]: "USD " + parseFloat('' + (booking?.purchasedPrice / 100)).toFixed(2),
        [bookingHeaderConst?.CLASS_TYPE?.label]: courseType[booking?.course?.courseType?.toUpperCase()]?.label,
        [bookingHeaderConst?.STATUS?.label]: bookingStatusEnum[booking?.status?.toUpperCase()]?.label,
        [bookingHeaderConst?.DATE?.label]: dayjs(booking?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A')
    }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-bookings",
            sheet: "booking",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const onHandleAddBooking = () => {
        dispatcher(resetAddBookingDetail())
        dispatcher(resetAddBookingDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_BOOKING_MODAL.stateKey]: true
        }))
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value }
        }));
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={showEntries}
                    onChange={(option) => setShowEntries(option.value)}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light !text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-bookings"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={onHandleAddBooking}
                >
                    {"Add Booking"}
                </span>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type="text"
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here..."}
                        value={filterProps.searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={(event) => handleFilterSearch(event)}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MyBookingSearch;
