import { useEffect, useCallback, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";

import { Draggable } from "react-drag-reorder";

import { cn } from "utils/cn.utils";

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

import ComponentLoader from 'components/loader/ComponentLoader';
import SaveAndCancelBtn from 'components/buttons/SaveAndCancelBtn';
import DashedBorderButton from 'components/buttons/dashedBorderButton';

import { profileEditBtnConst } from 'pages/auth/editTutor/data';

import {
    createTutorTagDetail,
    deleteTutorTagDetail,
    getTutorTagList,
    updateTutorTagListOrder
} from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
    resetTutorTagList,
    resetAddTutorTagDetail,
    resetUpdateTutorTagListOrder,
    resetDeleteTutorTagDetail,
} from 'redux/tutor/tutor.slice';

const skillReducer = (state, action) => {
    switch (action.type) {
        case "SKILL_LIST":
            return { ...state, skillList: action.payload }
        case "INPUT_SKILL":
            return { ...state, inputSkill: action.payload }
        case "INITIAL_STATE":
            return { ...state, inputSkill: "" }

        default:
            return state
    }
}

const EditTutorSkills = ({ editBtnConst, setEditBtnConst }) => {
    const { tutorTagList, addTutorTagDetail, updateTutorTagOrder, deleteTutorTag } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const { tutorId } = useParams()

    const [state, dispatch] = useReducer(skillReducer, {
        skillList: [],
        inputSkill: ""
    })

    const loadingElement = tutorTagList?.isLoading || addTutorTagDetail?.isLoading || updateTutorTagOrder?.isLoading || deleteTutorTag?.isLoading
    const isSkillsSimilar = JSON.stringify(state.skillList) === JSON.stringify(tutorTagList?.data?.results)

    useEffect(() => {
        return () => {
            // dispatcher(resetTutorTagList())
            dispatcher(resetAddTutorTagDetail())
            dispatcher(resetUpdateTutorTagListOrder())
            dispatcher(resetDeleteTutorTagDetail())
        }
    }, [])

    useEffect(() => {
        if (tutorId) {
            dispatcher(getTutorTagList({ tutorId: Number(tutorId) }))
        }

        return () => {
            dispatcher(resetTutorTagList())
        }
    }, [tutorId])

    useEffect(() => {
        if (tutorTagList?.data?.results?.length > 0) {
            dispatch({
                type: "SKILL_LIST",
                payload: tutorTagList?.data?.results
            })

        }
    }, [tutorTagList?.data])

    const onHandleSave = () => {
        if (addTutorTagDetail?.isLoading) return;

        if (tutorTagList?.data?.results?.length >= 1 && !isSkillsSimilar) {
            const requestDatPayload = {
                query: { tutorId: Number(tutorId) },
                body: {
                    order: state.skillList?.map((skill) => ({
                        id: skill?.id
                    }))
                }

            }
            dispatcher(updateTutorTagListOrder(requestDatPayload))

        }

        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })
        setEditBtnConst(null)
    }

    const onHandleCancel = () => {

        dispatcher(resetAddTutorTagDetail())
        dispatcher(resetUpdateTutorTagListOrder())
        dispatcher(resetDeleteTutorTagDetail())

        dispatch({
            type: "INITIAL_STATE",
            payload: null
        })
        setEditBtnConst(null)
    }

    const onHandleAddNewSkill = () => {
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SKILLS?.value) {
            setEditBtnConst(profileEditBtnConst?.SKILLS?.value)
        }
        dispatch({
            type: "SKILL_LIST",
            payload: []
        })
        dispatch({
            type: "INPUT_SKILL",
            payload: ""
        })
    }

    const onHandleAddSkill = () => {
        if (editBtnConst && editBtnConst !== profileEditBtnConst?.SKILLS?.value) return;

        if (!state.inputSkill || state.inputSkill?.split(" ")?.length > 3) {
            toast.warn("Skills can have upto 3 words")
            return;
        }
        const skillTag = state.inputSkill.trim()
        const filteredSkills = state.skillList?.filter((skill) => skill?.masterTag?.tag === skillTag)

        if (filteredSkills?.length > 0) {
            toast.warn("Duplicate skills are not allowed!")
            return;
        }
        const requestDatPayload = {
            tutorId: Number(tutorId),
            tag: skillTag
        }
        dispatcher(createTutorTagDetail(requestDatPayload))
        dispatch({
            type: "INPUT_SKILL",
            payload: ""
        })
        setEditBtnConst(null)
        const element = document.getElementById("inputSkill")
        element.focus()
    }

    const onHandleKeyDown = (e) => {
        if (editBtnConst && editBtnConst !== profileEditBtnConst?.SKILLS?.value) return;

        if ((e.keyCode === 9 || e.keyCode === 13) && state.inputSkill.trim()) {
            onHandleAddSkill()
        }
    }

    const onHandleRemoveSkill = useCallback(
        (tutorSkillId) => {
            dispatcher(deleteTutorTagDetail(tutorSkillId))
        },
        [dispatcher, state.skillList]
    );

    const getChangedPos = (currentPos, newPos) => {
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SKILLS?.value) {
            setEditBtnConst(profileEditBtnConst?.SKILLS?.value)
        }
        var updatedList = [...state.skillList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State
        dispatch({
            type: "SKILL_LIST",
            payload: updatedList
        })
    }

    const onHandleChange = (value) => {
        if (!editBtnConst || editBtnConst !== profileEditBtnConst?.SKILLS?.value) {
            setEditBtnConst(profileEditBtnConst?.SKILLS?.value)
        }
        dispatch({
            type: "INPUT_SKILL",
            payload: value
        })
    }

    return (
        <div className='w-full flex flex-col items-start justify-start gap-1'>
            <div className={"w-full flex items-center justify-between gap-2"}>
                <div className='flex flex-col justify-center items-start sm:flex-row sm:items-center gap-2'>
                    <span className='font-bodyPri font-medium text-text-900 text-base tracking-wide'>
                        {"Skills I Teach: Unlocking Knowledge"}
                    </span>
                    <span className={cn(
                        "text-xs text-text-800 font-bodyPri font-normal tracking-wide",
                        tutorTagList?.data?.results?.length < 5 && "text-red-500"
                    )}>
                        {"(add at least 5 skills)"}
                    </span>
                </div>
            </div>
            {tutorTagList?.isLoading &&
                <ComponentLoader isLoading={tutorTagList?.isLoading} />
            }
            {tutorTagList?.message &&
                <div className={"flex items-center justify-start font-bodyPri font-normal text-red-500 text-base"}>
                    {tutorTagList?.errorMsg}
                </div>
            }

            <DashedBorderButton
                isShowBtn={(!tutorTagList?.isLoading && (!editBtnConst || editBtnConst !== profileEditBtnConst?.SKILLS?.value) && (!tutorTagList?.data?.results || tutorTagList?.data?.results?.length === 0))}
                btnTitle={"Add Skill(s)"}
                onHandleAdd={onHandleAddNewSkill}
            />

            {(!tutorTagList?.isLoading && (editBtnConst === profileEditBtnConst?.SKILLS?.value || tutorTagList?.data?.results?.length > 0)) &&
                <div className={"w-full px-3 py-3 border border-divider-medium rounded-lg space-y-3"}>
                    <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                        {loadingElement &&
                            <ComponentLoader isLoading={loadingElement} className='w-full h-fit' />
                        }
                        {!loadingElement &&
                            <div className={"flex flex-wrap gap-2"}>
                                <Draggable onPosChange={getChangedPos}>
                                    {tutorTagList?.data?.results?.length > 0 &&
                                        tutorTagList?.data?.results?.map((skill, index) => (
                                            <div key={index} className="w-max flex items-center px-3 py-1 bg-primary-light rounded-lg font-bodyPri font-normal text-text-900 text-sm whitespace-normal sm:whitespace-nowrap">
                                                <span className="">
                                                    {skill?.masterTag?.tag}
                                                </span>
                                                <button className="pl-3 cursor-pointer">
                                                    <FaTimes
                                                        className={"font-light text-secondary-dark"}
                                                        onClick={() => onHandleRemoveSkill(skill?.id)}
                                                    />
                                                </button>
                                            </div>
                                        ))}
                                </Draggable>

                                <div className={"flex items-center justify-start gap-3"}>
                                    <input
                                        id={"inputSkill"}
                                        type={"text"}
                                        className={cn(
                                            "px-3 py-1 border-2 border-divider-medium rounded-lg focus:outline-none focus:border-2 focus:border-divider-lightDark",
                                            "font-bodyPri font-normal text-text-700 text-sm placeholder:text-text-400 placeholder:text-sm"
                                        )}
                                        placeholder={"e.g. English"}
                                        value={state?.inputSkill && state?.inputSkill?.charAt(0)?.toUpperCase() + (state?.inputSkill?.slice(1))}
                                        onChange={(e) => onHandleChange(e.target.value)}
                                        onKeyDown={onHandleKeyDown}
                                    // disabled={editBtnConst !== profileEditBtnConst?.SKILLS?.value}
                                    />
                                    <AiOutlineCheckCircle
                                        className={"text-2xl text-text-800 cursor-pointer"}
                                        onClick={onHandleAddSkill}
                                    />
                                </div>
                            </div>
                        }


                        {addTutorTagDetail?.message &&
                            <div className={"flex items-center justify-center my-2"}>
                                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                    {addTutorTagDetail?.message}
                                </span>
                            </div>
                        }
                    </div>
                </div>
            }
            {!tutorTagList?.isLoading &&
                <SaveAndCancelBtn
                    isShowBtn={(editBtnConst === profileEditBtnConst?.SKILLS?.value) || loadingElement}
                    onHandleSave={onHandleSave}
                    onHandleCancel={onHandleCancel}
                />
            }
        </div>
    )
}

export default EditTutorSkills;