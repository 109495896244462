import{ useEffect } from 'react';

import { pageHeading } from 'pages/auth/subscriptions/subscriptions.data';

import PageHeader from 'components/pageHeader/PageHeader';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';


const SubscriptionsPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.SUBSCRIPTIONS))
  }, [dispatcher, currentPageInfo])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
    </div>
  );
};

export default SubscriptionsPage;
