import { sessionDetailPayload, addSessionUserDetailPayload, sessionFilterProps, modifySessionUserDetailPayload } from "redux/session/session.const";

export const SESSION_INITIAL_STATE = {
    // session list
    sessionList: {
        isLoading: false,
        data: null,
        message: null
    },

    // session detail
    sessionDetail: {
        isLoading: false,
        data: null,
        payload: sessionDetailPayload,
        message: null
    },
    modifySessionDetail: {
        isLoading: false, // no update state for update (no use)
        payload: null,
        message: null // no update state for update (no use)
    },

    modifyStartSessionDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyScheduleSessionDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyDeclineSessionDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyRescheduleSessionDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyCompleteSessionDetail: {
        isLoading: false,
        data: null,
        message: null
    },

    // session course list
    sessionCourseList: {
        isLoading: false,
        data: null,
        message: null
    },

    // session appointment list
    sessionAppointmentList: {
        isLoading: false,
        data: null,
        message: null
    },

    addSessionUserDetail: {
        isLoading: false,
        data: null,
        payload: addSessionUserDetailPayload,
        message: null
    },
    modifySessionUserDetail: {
        isLoading: false,
        data: null,
        payload: modifySessionUserDetailPayload,
        message: null
    },

    filterProps: sessionFilterProps
}