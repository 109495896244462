import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import lmsArticleService from "redux/edulyteLms/lmsArticle/lmsArticle.service";

import {
    setLmsArticleListLoading,
    setLmsArticleListData,
    setLmsArticleListMessage,

    setDeleteLmsArticleDetailLoading,
    setDeleteLmsArticleDetailMessage,
} from "redux/edulyteLms/lmsArticle/lmsArticle.slice";
import { lmsArticleRedux } from "./lmsArticle.const";

export const getLmsArticleList = createAsyncThunk(
    `${lmsArticleRedux?.sliceName}/${lmsArticleRedux?.getLmsArticleList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setLmsArticleListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await lmsArticleService.getLmsArticleList(requestData)
            if (response.status === 200) {
                dispatch(setLmsArticleListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setLmsArticleListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setLmsArticleListLoading(false))
        }
    }
)

// delete lms article detail
export const deleteLmsArticleDetail = createAsyncThunk(
    `${lmsArticleRedux?.sliceName}/${lmsArticleRedux?.getLmsArticleList?.requestName}`,
    async (articleId, { dispatch }) => {
        dispatch(setDeleteLmsArticleDetailLoading(true))

        try {
            const requestData = {
                params: { articleId: articleId }
            }
            const response = await lmsArticleService.deleteLmsArticleDetail(requestData)
            if (response.status === 204) {
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setDeleteLmsArticleDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setDeleteLmsArticleDetailLoading(false))
        }
    }
)