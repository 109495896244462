import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import SelectSearch, { fuzzySearch } from "react-select-search";

import { BsCameraFill } from "react-icons/bs";

import SaveAndCancelBtn from "pages/auth/editCompanyOnboarding/components/saveAndCancelBtn/SaveAndCancelBtn";

import {
    getCompanyPublicProfileByCompanyId,
    updateCompanyPublicProfileByCompanyId
} from "redux/onboarding/onboarding.request";

import { getMasterCityList, getMasterCountryList, getMasterStateList } from "redux/master/master.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearCompanyPublicProfile, setCompanyPublicProfile } from "redux/onboarding/onboarding.slice";

import { setCityList, setClearCountryList, setStateList } from "redux/master/master.slice";

import { getYouTubeVideoIdFromUrl } from "utils/generators.utils";
import { FaSpinner } from "react-icons/fa";

const EditCompanyInformation = () => {
    const { countryList, stateList, cityList } = useAppState((state) => state.master)
    const { companyPublicProfile } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { companyId } = useParams()

    const [inputVideoUrl, setInputVideoUrl] = useState("")


    useEffect(() => {
        dispatcher(getCompanyPublicProfileByCompanyId(Number(companyId)))

        return () => {
            dispatcher(setClearCompanyPublicProfile())
        }
    }, [])

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (companyPublicProfile?.companyPublicProfile?.country) {
            dispatcher(getMasterStateList(companyPublicProfile?.companyPublicProfile?.country))
        } else {
            dispatcher(setStateList([]))
        }
    }, [companyPublicProfile?.companyPublicProfile?.country])

    useEffect(() => {
        if (companyPublicProfile?.companyPublicProfile?.state) {
            dispatcher(getMasterCityList(companyPublicProfile?.companyPublicProfile?.state))
        } else {
            dispatcher(setCityList([]))
        }
    }, [companyPublicProfile?.companyPublicProfile?.state])

    useEffect(() => {
        setInputVideoUrl(`https://www.youtube.com/embed/${companyPublicProfile?.companyPublicProfile?.videoUrl}`)
    }, [companyPublicProfile?.companyPublicProfile?.videoUrl])

    const handleSaveCompanyProfile = () => {
        const youtubeVideoId = getYouTubeVideoIdFromUrl(inputVideoUrl)
        dispatcher(updateCompanyPublicProfileByCompanyId({
            ...companyPublicProfile?.companyPublicProfile,
            videoUrl: youtubeVideoId
        }))
    }

    const handleCancelButton = () => {
        dispatcher(getCompanyPublicProfileByCompanyId(Number(companyId)))
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0 pb-1"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full bg-white p-5"}>
                <div className={"relative w-full h-20"}>
                    <div className={"w-full absolute -top-24 z-30 flex justify-between items-center"}>
                        <div className={cn(
                            "relative w-32 h-fit bg-white shadow-all",
                            !companyPublicProfile?.companyPublicProfile?.companyLogo && "p-2"
                        )}>
                            {companyPublicProfile?.companyPublicProfile?.companyLogo &&
                                <img
                                    src={companyPublicProfile?.companyPublicProfile?.companyLogo}
                                    className={"w-full h-full object-cover"}
                                    alt={"company-logo"}
                                />
                            }
                            {!companyPublicProfile?.companyPublicProfile?.companyLogo &&
                                <span className={"font-bodyPri font-semibold text-text-800 text-md"}>
                                    {companyPublicProfile?.companyPublicProfile?.companyName.replaceAll("_", " ")}
                                </span>
                            }
                            <div className={cn(
                                "absolute rounded-full bg-white p-1.5 shadow-all",
                                companyPublicProfile?.companyPublicProfile?.companyLogo && "top-24 -right-1",
                                !companyPublicProfile?.companyPublicProfile?.companyLogo && "top-12 -right-1"
                            )}>
                                <BsCameraFill className={"text-xl"} />
                            </div>
                        </div>
                        <div className={"h-40"}>
                            <div className={"relative hidden md:flex flex-col items-start justify-center"}>

                                <div className={"absolute top-0 right-0 w-full flex flex-col items-start justify-start gap-3"}>
                                    <input
                                        type={"text"}
                                        placeholder={"url"}
                                        autoFocus={true}
                                        className={cn(
                                            "w-full px-3 py-1 border-2 border-divider-medium rounded-t-lg focus:outline-none focus:border-2 focus:border-text-400",
                                            "font-bodyPri font-normal text-text-800 text-base",
                                            "placeholder:text-text-500 placeholder:text-sm"
                                        )}
                                        value={inputVideoUrl}
                                        onChange={(e) => setInputVideoUrl(e.target.value)}
                                    />
                                </div>

                                <div className="rounded-t-lg overflow-hidden w-96 h-40">
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${companyPublicProfile?.companyPublicProfile?.videoUrl}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                                    </iframe>
                                </div>

                                <div className={"w-full px-2 py-2 flex justify-center items-center gap-5 bg-white rounded-b-lg shadow-xl"}>
                                    <div
                                        className={cn(
                                            "w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                                "border border-text-800 text-text-800",
                                                "font-buttons font-normal text-base",
                                                "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                            )}
                                    >
                                        {"Message"}
                                    </div>
                                    <div
                                        className={cn(
                                            "w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                                "border border-text-800 text-text-800",
                                                "font-buttons font-normal text-base",
                                                "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                            )}>
                                        {"More"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"flex flex-col items-start justify-center gap-5"}>

                    {/* company name container */}
                    <div className={"w-full sm:w-3/4 md:w-2/3 lg:w-1/2 flex flex-col items-start justify-start gap-1.5"}>
                        <span className={"font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                            {"Company Name:"}
                        </span>
                        <input
                            type={"text"}
                            placeholder={"Company Name"}
                            autoFocus={true}
                            minLength={0}
                            maxLength={20}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base capitalize",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            value={companyPublicProfile?.companyPublicProfile?.companyName}
                            onChange={(e) => dispatcher(setCompanyPublicProfile({
                                ...companyPublicProfile?.companyPublicProfile,
                                companyName: e.target.value
                            }))}
                        />
                    </div>

                    {/* elevator pitch container */}
                    <div className={"w-full sm:w-3/4 md:w-2/3 lg:w-1/2 flex flex-col items-start justify-start gap-1.5"}>
                        <span className={"font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                            {"Elevator Pitch:"}
                        </span>
                        <input
                            type={"text"}
                            autoFocus={true}
                            minLength={0}
                            maxLength={50}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base capitalize",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            value={companyPublicProfile?.companyPublicProfile?.elevatorPitch}
                            onChange={(e) => dispatcher(setCompanyPublicProfile({
                                ...companyPublicProfile?.companyPublicProfile,
                                elevatorPitch: e.target.value
                            }))}
                        />
                    </div>

                    {/* email container */}
                    <div className={"w-full sm:w-3/4 md:w-2/3 lg:w-1/2 flex flex-col items-start justify-start gap-1.5"}>
                        <span className={"font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                            {"Email:"}
                        </span>
                        <input
                            type={"text"}
                            autoFocus={true}
                            minLength={0}
                            maxLength={50}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            value={companyPublicProfile?.companyPublicProfile?.email}
                            onChange={(e) => dispatcher(setCompanyPublicProfile({
                                ...companyPublicProfile?.companyPublicProfile,
                                email: e.target.value
                            }))}
                        />
                    </div>

                    {/* location container */}
                    <div className={"w-full sm:w-3/4 md:w-2/3 lg:w-1/2 flex flex-col justify-center items-start gap-1.5"}>
                        <span className={"font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                            {"Country:"}
                        </span>
                        {countryList?.isLoading &&
                            <div className={"flex items-center justify-start gap-0.5"}>
                                <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                    {"Please wait ..."}
                                </span>
                                <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                            </div>
                        }
                        {(!countryList?.isLoading && (countryList?.countryList || [])) &&
                            <div className={cn(
                                "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base capitalize",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}>
                                <SelectSearch
                                    options={countryList?.countryList?.map((country) => ({
                                        name: country?.country,
                                        value: country?.masterCountryId
                                    }))}
                                    search
                                    filterOptions={fuzzySearch}
                                    placeholder={"---Select Country---"}
                                    value={companyPublicProfile?.companyPublicProfile?.country}
                                    onChange={(option) => dispatcher(setCompanyPublicProfile({
                                        ...companyPublicProfile?.companyPublicProfile,
                                        country: option
                                    }))}
                                />

                            </div>
                        }
                        {countryList?.errorMsg &&
                            <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                                {countryList?.errorMsg}
                            </span>
                        }
                    </div>
                    <div className={"w-full sm:w-3/4 md:w-2/3 lg:w-1/2 flex flex-col justify-center items-start gap-1.5"}>
                        <span className={"font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                            {"State:"}
                        </span>
                        {stateList?.isLoading &&
                            <div className={"flex items-center justify-start gap-0.5"}>
                                <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                    {"Please wait ..."}
                                </span>
                                <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                            </div>
                        }
                        {(!stateList?.isLoading && (stateList?.stateList || [])) &&
                            <div className={cn(
                                "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base capitalize",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}>
                                <SelectSearch
                                    options={stateList?.stateList?.map((state) => ({
                                        name: state?.state,
                                        value: state?.masterStateId
                                    }))}
                                    search
                                    filterOptions={fuzzySearch}
                                    placeholder={"---Select State---"}
                                    value={companyPublicProfile?.companyPublicProfile?.state}
                                    onChange={(option) => dispatcher(setCompanyPublicProfile({
                                        ...companyPublicProfile?.companyPublicProfile,
                                        state: option
                                    }))}
                                />
                            </div>
                        }
                    </div>
                    <div className={"w-full sm:w-3/4 md:w-2/3 lg:w-1/2 flex flex-col justify-center items-start gap-1.5"}>
                        <span className={"font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                            {"City:"}
                        </span>
                        {cityList?.isLoading &&
                            <div className={"flex items-center justify-start gap-0.5"}>
                                <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                    {"Please wait ..."}
                                </span>
                                <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                            </div>
                        }
                        {cityList?.cityList &&
                            <div className={cn(
                                "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base capitalize",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}>
                                <SelectSearch
                                    options={cityList?.cityList?.map((city) => ({
                                        name: city?.city,
                                        value: city?.masterCityId
                                    }))}
                                    search
                                    filterOptions={fuzzySearch}
                                    placeholder={"---Select City---"}
                                    value={companyPublicProfile?.companyPublicProfile?.city}
                                    onChange={(option) => dispatcher(setCompanyPublicProfile({
                                        ...companyPublicProfile?.companyPublicProfile,
                                        city: option
                                    }))}
                                />
                            </div>
                        }
                    </div>

                    <SaveAndCancelBtn
                        onCancel={() => handleCancelButton()}
                        onSave={() => handleSaveCompanyProfile()}
                    />

                    {/* company video container */}
                    <div className={"relative md:hidden flex flex-col items-start justify-center mt-5"}>
                        <div className={"absolute top-0 right-0 w-full flex flex-col items-start justify-start gap-3"}>
                            <input
                                type={"text"}
                                placeholder={"url"}
                                autoFocus={true}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-t-lg focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={inputVideoUrl}
                                onChange={(e) => setInputVideoUrl(e.target.value)}
                            />
                        </div>

                        <div className="rounded-t-lg overflow-hidden h-40 w-full">
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${companyPublicProfile?.companyPublicProfile?.videoUrl}`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                            </iframe>
                        </div>
                        <div className={"w-full px-2 py-2 flex justify-center items-center gap-5 bg-white rounded-b-lg shadow-xl"}>
                            <div
                                className={cn
                                    ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                        "border border-text-800 text-text-800",
                                        "font-buttons font-normal text-base",
                                        "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                    )}
                            >
                                {"Message"}
                            </div>
                            <div
                                className={cn
                                    ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                        "border border-text-800 text-text-800",
                                        "font-buttons font-normal text-base",
                                        "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                    )}>
                                {"More"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditCompanyInformation