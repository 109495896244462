import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { MdAdd, MdOutlineDeleteOutline } from 'react-icons/md';

import { EditDocumentTableHeader } from "pages/auth/editCompanyOnboarding/data";
import UploadCompanyDocument from "pages/auth/editCompanyOnboarding/components/editDocumentVerification/UploadCompanyDocument";

import { deleteCompanyDocumentByDocumentId, getCompanyDocumentListByCompanyId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { companyOnboardingStatus, companyDocumentsCategory } from "redux/onboarding/onboarding.const";
import { FiEdit } from "react-icons/fi";
import { setClearCompanyDocumentPayload, setCompanyDocumentPayload } from "redux/onboarding/onboarding.slice";

const EditDocumentVerification = () => {
    const { companyDocumentList } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { companyId } = useParams()

    const [isAddDocument, setIsAddDocument] = useState(true)
    const [isOpenAddDocxContainer, setIsOpenAddDocxContainer] = useState(false)

    useEffect(() => {
        dispatcher(getCompanyDocumentListByCompanyId(Number(companyId)))
    }, [])

    const handleEditDocument = (document) => {
        dispatcher(setCompanyDocumentPayload(document))
        setIsAddDocument(false)
        setIsOpenAddDocxContainer(true)
    }

    const handleDeleteDocument = (document) => {
        dispatcher(deleteCompanyDocumentByDocumentId(document?.documentId))
    }

    const handleAddDocument = () => {
        dispatcher(setClearCompanyDocumentPayload())
        setIsOpenAddDocxContainer(true)
        setIsAddDocument(false)
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"DOCUMENTS"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"p-5 bg-white space-y-3"}>
                    <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                        {"Document Verification"}
                    </span>
                    <div className={"p-5 space-y-5"}>
                        {companyDocumentList?.companyDocumentList?.documents?.length > 0 &&
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        {EditDocumentTableHeader.map((head, index) => (
                                            <th
                                                key={index}
                                                className={cn(
                                                    "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                                    "font-bold whitespace-nowrap border border-gray-200"
                                                )}
                                            >
                                                {head}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {companyDocumentList?.companyDocumentList?.documents?.map((data, idx) => (
                                        <tr
                                            className={''}
                                            key={idx}
                                        >
                                            <td className="border border-gray-200">
                                                <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                                                    {companyDocumentsCategory[data?.fileCategory.toUpperCase()].label}
                                                </span>
                                            </td>
                                            <td className={"border border-gray-200"}>
                                                <a
                                                    href={data?.fileUrl}
                                                    target={"_blank"}
                                                    className='px-5 py-2 font-bodyPri font-medium text-secondary-main text-base text-center underline truncate cursor-pointer'>
                                                    {data?.fileName}
                                                </a>
                                            </td>
                                            <td className={"border border-gray-200"}>
                                                <span className='px-5 py-2 font-bodyPri font-normal text-text-900 text-base italic text-center'>
                                                    {companyOnboardingStatus[data?.status?.toUpperCase()]?.label}
                                                </span>
                                            </td>
                                            <td className={"border border-gray-200"}>
                                                <span className={"w-full flex justify-start items-center px-5"}>
                                                    <FiEdit
                                                        className={"text-xl cursor-pointer text-divider-lightDark hover:text-divider-dark"}
                                                        onClick={() => handleEditDocument(data)}
                                                    />
                                                </span>
                                            </td>
                                            <td className={"border border-gray-200"}>
                                                <span className={"w-full flex justify-start items-center px-5"}>
                                                    <MdOutlineDeleteOutline
                                                        className={"text-2xl text-red-300 cursor-pointer hover:text-red-500"}
                                                        onClick={() => handleDeleteDocument(data)}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                        {companyDocumentList?.companyDocumentList?.documents?.length === 0 &&
                            <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                                {"No documents uploaded yet!"}
                            </span>
                        }
                        {isAddDocument &&
                            <div
                                className={"flex items-center justify-start rounded-lg cursor-pointer group"}
                                onClick={handleAddDocument}
                            >
                                <MdAdd className={"text-lg text-text-700 group-hover:text-text-900"} />
                                <span className={"font-bodyPri font-normal text-base text-text-700 group-hover:text-text-900"}>
                                    {"Add Document"}
                                </span>
                            </div>
                        }
                        {isOpenAddDocxContainer &&
                            <UploadCompanyDocument
                                setIsAddDocument={setIsAddDocument}
                                setIsOpenAddDocxContainer={setIsOpenAddDocxContainer}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditDocumentVerification