
import CurrentDateTime from 'components/modals/editUserAvailabilityModal/commonComponents/CurrentDateTime';
import OneOnOneAvailabilityInputs from 'components/modals/editUserAvailabilityModal/commonComponents/OneOnOneAvailabilityInputs';
import GroupAvailabilityInputs from 'components/modals/editUserAvailabilityModal/commonComponents/GroupAvailabilityInputs';

import AvailabilityCard from 'components/modals/editUserAvailabilityModal/recurringAvailabilitySchedule/components/RecurringAvailabilityCard';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddAvailabilityDetail_Payload } from 'redux/availability/availability.slice';
import { availabilityTypeEnum } from 'redux/availability/availability.const';

import { weekdays } from 'utils/dateTime.utils';

const RecurringAvailabilitySchedule = () => {
    const { availabilityDetail, addAvailabilityDetail } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const isOneOnOneAvailType = availabilityDetail?.data?.result?.type === availabilityTypeEnum?.ONE_ON_ONE?.value
    const isGroupAvailType = availabilityDetail?.data?.result?.type === availabilityTypeEnum?.GROUP?.value


    const saveWeeklyAvail = async (weeklyAvail) => {
        if (addAvailabilityDetail?.isLoading) return;

        dispatcher(setAddAvailabilityDetail_Payload({
            ...addAvailabilityDetail?.payload,
            recurringAvailability: { ...addAvailabilityDetail?.payload?.recurringAvailability, weeklyTimeSlots: weeklyAvail }
        }))
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-5"}>
            {isOneOnOneAvailType &&
                <OneOnOneAvailabilityInputs />
            }
            {isGroupAvailType &&
                <GroupAvailabilityInputs scheduleDuration={addAvailabilityDetail?.payload?.recurringAvailability?.duration / 15} />
            }

            <CurrentDateTime />
            <div className={"flex flex-wrap items-center justify-start gap-5"}>
                {addAvailabilityDetail?.payload?.recurringAvailability?.weeklyTimeSlots && weekdays?.map((day, index) => (
                    <AvailabilityCard
                        key={index}
                        localDay={day}
                        weeklyAvailability={addAvailabilityDetail?.payload?.recurringAvailability?.weeklyTimeSlots}
                        saveWeeklyAvail={saveWeeklyAvail}
                    />
                ))}
            </div>
        </div>
    )
}

export default RecurringAvailabilitySchedule