import { dataEntriesConst } from "redux/local/local.const";

export const url = {
    WALLET_TRANSACTION_LIST: "/wallets/transactions"
}


export const walletTxnRedux = {
    sliceName: "walletTxn",
    walletTxnList: {
        requestName: "getWalletTxnList"
    },
    walletTxnFilteredList: {
        requestName: "getWalletTxnFilteredList"
    }
}

export const filterName = {
    WALLET_TXN: {
        value: "wallet_txn"
    }
}

export const transactionStatus = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'text-gray-100',
        darkColor: 'text-gray-500',
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: 'text-orange-100',
        darkColor: 'text-orange-500',
    },
    SUCCESS: {
        label: "Success",
        value: "success",
        lightColor: 'text-green-100',
        darkColor: 'text-green-500',
    },
    FAILED: {
        label: "Failed",
        value: "failed",
        lightColor: "text-red-100",
        darkColor: "text-red-500"
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        lightColor: 'text-gray-100',
        darkColor: 'text-gray-500',
    },
}

export const amountImpact = {
    CREDIT: {
        label: "Credit",
        value: "credit"
    },
    DEBIT: {
        label: "Debit",
        value: "debit"
    }
}

export const walletTxnActions = {
    MONEY_ADDED: {
        label: "Money Added",
        value: 'money_added',
        action: 'money_added'
    },
    MONEY_WITHDRAWN: {
        label: 'Money Withdrawn',
        value: 'money_withdrawn',
        action: 'money_withdrawn'
    },
    PROCESSING_FEE: {
        label: 'Processing Fee',
        value: 'processing_fee',
        action: 'processing_fee'
    },
    COURSE_PURCHASED: {
        label: 'Course Purchased',
        value: 'course_purchased',
        action: 'course_purchased'
    },
    COURSE_REFUNDED: {
        label: 'Course Refund',
        value: 'course_refunded',
        action: 'course_refunded'
    },
    COURSE_COMPLETED: {
        label: 'Course Completed',
        value: 'course_completed',
        action: 'course_completed'
    },
    EARNING_FROM_COURSE: {
        label: 'Earning From Course',
        value: 'earning_from_course',
        action: 'earning_from_course'
    },
    REFERRAL_SENT: {
        label: 'Referral Sent',
        value: 'referral_sent',
        action: 'referral_sent'
    },
    REFERRAL_APPLIED: {
        label: 'Referral Applied',
        value: 'referral_applied',
        action: 'referral_applied'
    },
    PROMO_ADDED: {
        label: 'Promo Added',
        value: 'promo_added',
        action: 'promo_added'
    },
    PROMO_SENT: {
        label: 'Promo Sent',
        value: 'promo_sent',
        action: 'promo_sent'
    },
    PROMO_APPLIED: {
        label: 'Promo Applied',
        value: 'promo_applied',
        action: 'promo_applied'
    },
    PRODUCT_PURCHASED: {
        label: "Product Purchased",
        value: "product_purchased",
        action: "product_purchased"
    },
    PRODUCT_PAYMENT: {
        label: "Product Payment",
        value: "product_payment",
        action: "product_payment"
    },
    PRODUCT_SOLD: {
        label: "Product Sold",
        value: "product_sold",
        action: "product_sold"
    },
    GPT_TOKENS_PURCHASED: {
        label: "AI Tokens Purchased",
        value: "gpt_tokens_purchased",
        action: "gpt_tokens_purchased"
    },
    GPT_TOKENS_SOLD: {
        label: "Gpt Tokens Sold",
        value: "gpt_tokens_sold",
        action: "gpt_tokens_sold"
    },
    OTHER: {
        label: 'Other',
        value: 'other',
        action: 'other'
    },
}

export const txnType = {
    WALLET: {
        value: 'wallet',
        label: 'Wallet'
    },
    PENDING: {
        value: 'pending',
        label: 'Pending'
    },
    PROMO: {
        value: 'promo',
        label: 'Promo'
    },
    AUD_WALLET: {
        value: "aud_wallet",
        label: "AUD Wallet"
    },
    INR_WALLET: {
        value: "inr_wallet",
        label: "INR Wallet"
    }
}

export const walletTxnFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    search: "",
    walletTxnId: "",
    virtualTxnId: "",
    user: "",
    amountImpact: "",
    txnType: "",
    walletAction: "",
    startDate: "",
    endDate: "",
    records: dataEntriesConst?.TWENTY_FIVE?.value,
    page: 1
}