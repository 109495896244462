import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

import FullPageLoader from "components/loader/FullPageLoader";
import ComponentLoader from "components/loader/ComponentLoader";
import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";
import SkillTag from "components/skillTag/SkillTag";

import ViewTutorHeader from "pages/auth/viewTutor/commonComponents/ViewTutorHeader";

import { getTutorDetail, getTutorTagList } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetTutorTagList } from "redux/tutor/tutor.slice";

const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700

const ViewTutorDetail = () => {
    const { tutorDetail, tutorTagList } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const { tutorId } = useParams()

    useEffect(() => {
        if (tutorId) {
            dispatcher(getTutorTagList({ tutorId: Number(tutorId) }))
        }

        return () => {
            dispatcher(resetTutorTagList())
        }
    }, [tutorId])

    const onHandleRefresh = () => {
        dispatcher(getTutorDetail(Number(tutorId)))
    }

    if (tutorDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={tutorDetail?.isLoading} />
        )
    }

    if (tutorDetail?.message) {
        return (
            <div className={"w-screen h-screen flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                {tutorDetail?.message}
            </div>
        )
    }

    return (
        <div className={"space-y-3"}>
            <ViewTutorHeader title={"Tutor Details"} />

            <div className="space-y-5 p-5">

                {/* video section */}
                <div className={"w-full md:w-[25rem] h-full rounded-lg space-y-1"}>
                    <MuxVideoPlayer
                        playbackId={tutorDetail?.data?.videoUrl ? tutorDetail?.data?.videoUrl : MUX_DEMO_PLAYBACK_ID}
                        isShowRefreshButton={true}
                        isShowButtons={false}
                        onHandleRefresh={onHandleRefresh}
                    />
                </div>

                {/* one liner section */}
                <div className={"flex flex-col items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                        {"Me in one sentence"}
                    </span>
                    <input
                        type={"text"}
                        value={tutorDetail?.data?.elevatorPitch}
                        className={cn(
                            "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-text-800 text-base",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        disabled={true}
                        autoFocus={true}
                    />
                </div>

                {/* About me section */}
                <div className={"flex flex-col items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                        {"About"}
                    </span>
                    <textarea
                        className={cn(
                            "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-lg text-text-600 tracking-wide resize-y"
                        )}
                        rows={5}
                        minLength={MIN_CHARACTER_LENGTH}
                        maxLength={MAX_CHARACTER_LENGTH}
                        value={tutorDetail?.data?.bio
                            ? tutorDetail?.data?.bio?.length > 700
                                ? tutorDetail?.data?.bio?.slice(0, 700)
                                : tutorDetail?.data?.bio
                            : ""
                        }
                        disabled={true}
                    />
                    <div className={"w-full flex justify-between"}>
                        {(tutorDetail?.data?.bio?.length < MIN_CHARACTER_LENGTH || tutorDetail?.data?.bio?.length > MAX_CHARACTER_LENGTH) &&
                            <span className="w-full font-bodyPri font-normal tracking-wide text-red-500 text-base">
                                {"Please enter between 50 and 700 characters"}
                            </span>
                        }
                        <span className="w-full flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                            {(tutorDetail?.data?.bio?.length > 700 ? 700 : tutorDetail?.data?.bio?.length) + "/" + MAX_CHARACTER_LENGTH}
                        </span>
                    </div>
                </div>

                {/* teaching style */}
                <div className={"flex flex-col items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                        {"Teaching style"}
                    </span>
                    <textarea
                        className={cn(
                            "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                            "font-bodyPri font-normal text-lg text-text-600 tracking-wide resize-y"
                        )}
                        rows={5}
                        minLength={MIN_CHARACTER_LENGTH}
                        maxLength={MAX_CHARACTER_LENGTH}
                        value={tutorDetail?.data?.teachingStyleBio
                            ? tutorDetail?.data?.teachingStyleBio?.length > 700
                                ? tutorDetail?.data?.teachingStyleBio?.slice(0, 700)
                                : tutorDetail?.data?.teachingStyleBio
                            : ""
                        }
                        disabled={true}
                    />
                    <div className={"w-full flex justify-between"}>
                        {(tutorDetail?.data?.teachingStyleBio?.length < MIN_CHARACTER_LENGTH || tutorDetail?.data?.teachingStyleBio?.length > MAX_CHARACTER_LENGTH) &&
                            <span className="w-full font-bodyPri font-normal tracking-wide text-red-500 text-base">
                                {"Please enter between 50 and 700 characters"}
                            </span>
                        }
                        <span className="w-full flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                            {(tutorDetail?.data?.teachingStyleBio?.length?.length > 700 ? 700 : tutorDetail?.data?.teachingStyleBio?.length) + "/" + MAX_CHARACTER_LENGTH}
                        </span>
                    </div>
                </div>

                {/* skills section */}
                <div className={"col-start-1 col-span-12 flex flex-col items-start justify-start gap-2"}>
                    <div className={"flex flex-col justify-center items-start md:flex-row md:items-center gap-2"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {"Skills To Teach"}
                        </span>
                        <span className={cn(
                            "text-xs text-text-800 font-bodyPri font-normal tracking-wide",
                            tutorTagList?.data?.results?.length < 5 && "text-red-500"
                        )}>
                            {"(add at least 5 skills)"}
                        </span>
                    </div>

                    {tutorTagList?.isLoading &&
                        <ComponentLoader isLoading={tutorTagList?.isLoading} className="w-full h-fit" />
                    }
                    {!tutorTagList?.isLoading &&
                        <div className="w-full px-4 py-2 flex flex-wrap items-center gap-3 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400">
                            <SkillTag
                                skillList={tutorTagList?.data?.results?.map((skill) => ({
                                    label: skill?.masterTag?.tag,
                                    value: skill?.masterTag?.tag
                                }))}
                                isShowDeleteBtn={false}
                                isShowInput={true}
                                disabled={true}
                            />
                        </div>
                    }
                    {(!tutorTagList?.isLoading && tutorTagList?.message) &&
                        <div className={"w-full flex items-center justify-center"}>
                            <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                {tutorTagList?.message}
                            </span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ViewTutorDetail;