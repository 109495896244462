import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import Modal from 'components/modals/modal/Modal';
import RoleSwitchModal from 'components/modals/roleSwitchModal/RoleSwitchModal';
import PaymentMethodModal from 'components/modals/paymentMethodModal/PaymentMethodModal';
import AddCreditModal from 'components/modals/addCreditModal/AddCreditModal';
import ImagePickerModal from 'components/modals/imagePickerModal/ImagePickerModal';
import SubmitCommentModal from 'components/modals/submitCommentModal/SubmitCommentModal';
import SubmitCourseModal from 'components/modals/submitCourseModal/SubmitCourseModal';
import CompanyPeopleModal from 'components/modals/CompanyPeopleModal/CompanyPeopleModal';
import WithdrawMoneyModal from 'components/modals/withdrawMoneyModal/WithdrawMoneyModal';
import ConfirmPayoutModal from 'components/modals/confirmPayoutModal/ConfirmPayoutModal';
import WithdrawalRequestSubmitModal from 'components/modals/withdrawalRequestSubmitModal/WithdrawalRequestSubmitModal';
import ValidationBarEmailVerifyModal from 'components/modals/validationBarEmailVerifyModal/ValidationBarEmailVerifyModal';
import ValidationBarMobileVerifyModal from 'components/modals/validationBarMobileVerifyModal/ValidationBarMobileVerifyModal';
import StickyNoteModal from 'components/modals/stickyNoteModal/StickyNoteModal';
import UploadVideoModal from 'components/modals/uploadVideoModal/UploadVideoModal';
import UploadDocumentModal from 'components/modals/uploadDocumentModal/UploadDocumentModal';
import SearchPhotoModal from 'components/modals/SearchPhotoModal/SearchPhotoModal';
import AddCourseModal from 'components/modals/addCourseModal/AddCourseModal';
import AddSubscriptionPlanModal from 'components/modals/addSubscriptionPlanModal/AddSubscriptionPlanModal';
import AddBookingModal from 'components/modals/addBookingModal';
import BookingSummaryModal from 'components/modals/bookingSummaryModal/BookingSummaryModal';
import AddBookingSessionModal from 'components/modals/addBookingSessionModal/AddBookingSessionModal';
import RescheduleModal from 'components/modals/rescheduleTimeSlotModal/RescheduleModal';
import AddUserDetailModal from 'components/modals/addUserDetailModal/AddUserDetailModal';
import ViewUserAvailabilityModal from 'components/modals/viewUserAvailabilityModal/ViewUserAvailabilityModal';
import EditUserAvailabilityModal from 'components/modals/editUserAvailabilityModal/EditUserAvailabilityModal';
import AddNextCourseSessionModal from 'components/modals/addNextCourseSessionModal/AddNextCourseSessionModal';
import AddCourseTutorModal from 'components/modals/addCourseTutorModal/AddCourseTutorModal';
import AddSessionUserModal from 'components/modals/addSessionUserModal/AddSessionUserModal';
import ManualPaymentDetailModal from 'components/modals/manualPaymentDetailModal/ManualPaymentDetailModal';
import AddUserWalletCreditModal from 'components/modals/addUserWalletCreditModal/AddUserWalletCreditModal';
import WalletCreditSuccessModal from 'components/modals/walletCreditSuccessModal/WalletCreditSuccessModal';

const ModalContainer = () => {
    const { modal } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()

    const onSetModal = (stateKey, value) => {
        dispatcher(setModal({ ...modal, [stateKey]: value }))
    }

    const onBackModal = (stateKey) => {
        switch (stateKey) {
            case modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: false,
                    [modalConst.NO_SESSION_MODAL.stateKey]: true
                }))
                break;
            case modalConst.COURSE_CHECKOUT_MODAL.stateKey:
                // if (bookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value)
                //     dispatcher(setModal({
                //         ...modal,
                //         [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
                //         [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: true
                //     }))
                // if (bookingPayload?.course?.courseType === courseType.GROUP.value)
                //     dispatcher(setModal({
                //         ...modal,
                //         [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
                //         [modalConst.COURSE_SCHEDULE_MODAL.stateKey]: true
                //     }))
                break;
            case modalConst.MAKE_PAYMENT_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.MAKE_PAYMENT_MODAL.stateKey]: false,
                    [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true
                }))
                break;
            case modalConst.NEED_A_TUTOR_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.NEED_A_TUTOR_MODAL.stateKey]: false,
                    [modalConst.SPEAK_TO_US_MODAL.stateKey]: true
                }))
                break;
            case modalConst.CONFIRM_PAYOUT_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.CONFIRM_PAYOUT_MODAL.stateKey]: false,
                    [modalConst.WITHDRAW_MONEY_MODAL.stateKey]: true
                }))
                break;
            case modalConst.RESET_PASSWORD_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.RESET_PASSWORD_MODAL.stateKey]: false,
                    [modalConst.FORGET_PASSWORD_MODAL.stateKey]: true
                }))
                break;
            default:
                dispatcher(setModal({ ...modal }))
        }
    }

    return (
        <>
            <Modal
                title={"Select Role"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.ROLE_SWITCH_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ROLE_SWITCH_MODAL.stateKey, isShow)}
                children={<RoleSwitchModal />}
            />
            <Modal
                title={"Payment method"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.PAYMENT_METHOD_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PAYMENT_METHOD_MODAL.stateKey, isShow)}
                children={<PaymentMethodModal />}
            />
            <Modal
                title={"Add Credit"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.ADD_CREDIT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_CREDIT_MODAL.stateKey, isShow)}
                children={<AddCreditModal />}
            />
            <Modal
                title={"Submit Comment"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.SUBMIT_COMMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SUBMIT_COMMENT_MODAL.stateKey, isShow)}
                children={<SubmitCommentModal />}
            />
            <Modal
                title={"Submit Course"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.SUBMIT_COURSE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SUBMIT_COURSE_MODAL.stateKey, isShow)}
                children={<SubmitCourseModal />}
            />
            <Modal
                title={"Select Image"}
                maxWidth={"max-w-4xl"}
                openModal={modal[modalConst.IMAGE_PICKER_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.IMAGE_PICKER_MODAL.stateKey, isShow)}
                children={<ImagePickerModal />}
            />
            <Modal
                title={"Add People"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.COMPANY_PEOPLE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COMPANY_PEOPLE_MODAL.stateKey, isShow)}
                children={<CompanyPeopleModal />}
            />
            <Modal
                title={"Withdraw Money"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.WITHDRAW_MONEY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.WITHDRAW_MONEY_MODAL.stateKey, isShow)}
                children={<WithdrawMoneyModal />}
            />
            <Modal
                title={"Confirm Payout"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.CONFIRM_PAYOUT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CONFIRM_PAYOUT_MODAL.stateKey, isShow)}
                children={<ConfirmPayoutModal />}
            />
            <Modal
                title={""}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.WITHDRAWAL_REQUEST_SUBMIT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.WITHDRAWAL_REQUEST_SUBMIT_MODAL.stateKey, isShow)}
                children={<WithdrawalRequestSubmitModal />}
            />
            <Modal
                title={"Verify Email"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.VALIDATION_BAR_EMAIL_VERIFY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VALIDATION_BAR_EMAIL_VERIFY_MODAL.stateKey, isShow)}
                children={<ValidationBarEmailVerifyModal />}
            />
            <Modal
                title={"Verify Phone"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey, isShow)}
                children={<ValidationBarMobileVerifyModal />}
            />
            <Modal
                title={"Write Comments"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.STICKY_NOTE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.STICKY_NOTE_MODAL.stateKey, isShow)}
                children={<StickyNoteModal />}
            />
            <Modal
                title={"Upload Video"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.UPLOAD_VIDEO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPLOAD_VIDEO_MODAL.stateKey, isShow)}
                children={<UploadVideoModal />}
            />
            <Modal
                title={"Upload Document"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPLOAD_DOCUMENT_MODAL.stateKey, isShow)}
                children={<UploadDocumentModal />}
            />
            <Modal
                title={"Search Photo"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.SEARCH_PHOTO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SEARCH_PHOTO_MODAL.stateKey, isShow)}
                children={<SearchPhotoModal />}
            />
            <Modal
                title={"Add Class"}
                maxWidth={"max-w-3xl"}
                openModal={modal[modalConst.ADD_COURSE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_COURSE_MODAL.stateKey, isShow)}
                children={<AddCourseModal />}
            />
            <Modal
                title={"Create a new plan"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.ADD_SUBSCRIPTION_PLAN_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_SUBSCRIPTION_PLAN_MODAL.stateKey, isShow)}
                children={<AddSubscriptionPlanModal />}
            />
            <Modal
                title={"Add a new booking"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.ADD_BOOKING_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_BOOKING_MODAL.stateKey, isShow)}
                children={<AddBookingModal />}
            />
            <Modal
                title={"Booking Summary"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.BOOKING_SUMMARY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.BOOKING_SUMMARY_MODAL.stateKey, isShow)}
                children={<BookingSummaryModal />}
            />
            <Modal
                title={"Schedule a new session"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.ADD_BOOKING_SESSION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_BOOKING_SESSION_MODAL.stateKey, isShow)}
                children={<AddBookingSessionModal />}
            />
            <Modal
                title={"Reschedule Your Session"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey]}
                onBackModal={() => onBackModal(modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey)}
                onCloseModal={(isShow) => onSetModal(modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey, isShow)}
                children={<RescheduleModal />}
            />
            <Modal
                title={"Add User Detail"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.ADD_USER_DETAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_USER_DETAIL_MODAL.stateKey, isShow)}
                children={<AddUserDetailModal />}
            />
            <Modal
                title={"View Availability"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.VIEW_USER_AVAILABILITY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VIEW_USER_AVAILABILITY_MODAL.stateKey, isShow)}
                children={<ViewUserAvailabilityModal />}
            />
            <Modal
                title={"Edit Availability"}
                maxWidth={"max-w-4xl"}
                openModal={modal[modalConst.EDIT_USER_AVAILABILITY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.EDIT_USER_AVAILABILITY_MODAL.stateKey, isShow)}
                children={<EditUserAvailabilityModal />}
            />
            <Modal
                title={"Add Session"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.ADD_NEXT_COURSE_SESSION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_NEXT_COURSE_SESSION_MODAL.stateKey, isShow)}
                children={<AddNextCourseSessionModal />}
            />
            <Modal
                title={"Edit/Modify Tutor"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.ADD_COURSE_TUTOR_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_COURSE_TUTOR_MODAL.stateKey, isShow)}
                children={<AddCourseTutorModal />}
            />
            <Modal
                title={"Edit/Modify Session User"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.ADD_SESSION_USER_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_SESSION_USER_MODAL.stateKey, isShow)}
                children={<AddSessionUserModal />}
            />
            <Modal
                title={"Manually Confirm a Payment Intent ID"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.MANUAL_PAYMENT_DETAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.MANUAL_PAYMENT_DETAIL_MODAL.stateKey, isShow)}
                children={<ManualPaymentDetailModal />}
            />
            <Modal
                title={"Add Credit"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.ADD_USER_WALLET_CREDIT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_USER_WALLET_CREDIT_MODAL.stateKey, isShow)}
                children={<AddUserWalletCreditModal />}
            />
            <Modal
                title={"Success"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.WALLET_CREDIT_SUCCESS_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.WALLET_CREDIT_SUCCESS_MODAL.stateKey, isShow)}
                children={<WalletCreditSuccessModal />}
            />
        </>
    )
}

export default ModalContainer
