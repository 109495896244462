import { useEffect } from "react";
import { cn } from "utils/cn.utils";
import { useParams } from "react-router-dom";

import FullPageLoader from "components/loader/FullPageLoader";

import { documentTableHeader } from "pages/auth/viewTutorOnboard/data";
import { getOnboardingDocListByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingDocList } from "redux/onboarding/onboarding.slice";
import { documentStatus, docType, videoType } from "redux/onboarding/onboarding.const";

const DocumentVerification = () => {
    const { onboardingDocList } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const filteredDocumentList = onboardingDocList?.onboardingDocList?.filter((documents) => documents?.docType !== videoType?.INTRO_VIDEO?.value)

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingDocListByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingDocList())
        }
    }, [onboardingId])

    if (onboardingDocList?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingDocList?.isLoading} />
        )
    }

    return (
        <div className={"space-y-5 px-5 py-3"}>
            <div className={"flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg"}>
                {"Document Verification"}
            </div>

            {filteredDocumentList?.length > 0 &&
                <div className={cn(
                    " min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow overflow-x-auto",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>
                    <table className={"w-full border-separate rounded-lg"}>
                        <thead>
                            <tr>
                                {documentTableHeader?.map((head, index) => (
                                    <th
                                        key={index}
                                        className={cn(
                                            "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                            "font-bold whitespace-nowrap border border-gray-200"
                                        )}
                                    >
                                        {head}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={"divide-y divide-text-200"}>
                            {filteredDocumentList?.map((document, index) => (
                                <tr className={''} key={index}>
                                    <td className="border border-gray-200">
                                        <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                                            {docType[document?.docType?.toUpperCase()]?.label}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <a
                                            href={document?.docUrl}
                                            target={"_blank"}
                                            className='px-5 py-2 font-bodyPri font-medium text-primary-main text-base text-center truncate'>
                                            {document?.docName}
                                        </a>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className={`px-2 font-bodyPri font-normal text-base italic text-center text-${documentStatus[document?.status?.toUpperCase()]?.textColor}`}>
                                            {documentStatus[document?.status?.toUpperCase()]?.textName}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {filteredDocumentList?.length === 0 &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-text-700 text-base"}>
                    {"No records found!"}
                </div>
            }
            {onboardingDocList?.errorMsg &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingDocList?.errorMsg}
                </div>
            }
        </div>
    )
}

export default DocumentVerification