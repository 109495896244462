import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FaInfoCircle } from "react-icons/fa";

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

import ComponentLoader from "components/loader/ComponentLoader";
import FullPageLoader from "components/loader/FullPageLoader";
import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";
import { OptionSelector } from "components/common-components/Select";
import SaveAndCancelBtn from "components/buttons/SaveAndCancelBtn";
import DashedBorderButton from "components/buttons/dashedBorderButton";

import { profileEditBtnConst } from "pages/auth/editTutor/data";

import { getTutorDetail, updateTutorUserDetail } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { resetUploadFile, setUploadFilePayload } from "redux/storage/storage.slice";
import { setTutorDetailData, resetUpdateTutorDetail } from "redux/tutor/tutor.slice";
import { tutorVisibilityEnum } from "redux/tutor/tutor.const";

const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700

const EditTutorInfo = ({ editBtnConst, setEditBtnConst }) => {
    const { modal } = useAppState((state) => (state.local))
    const { uploadFile } = useAppState(s => s.storage)
    const { tutorDetail, updateTutorDetail } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const { tutorId } = useParams()

    const [isShowAddBtn, setIsShowAddBtn] = useState({
        elevatorPitch: false,
        bio: false,
        teachingStyleBio: false
    })

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0) {
            const requestDataPayload = {
                tutorId: Number(tutorId),
                body: {
                    videoUrl: uploadFile?.data[0]?.fileUrl
                }
            }
            dispatcher(updateTutorUserDetail(requestDataPayload))
            dispatcher(resetUploadFile())
        }

    }, [uploadFile?.data])

    useEffect(() => {
        if (updateTutorDetail?.data?.videoUrl) {
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: false
            }))
        }
    }, [updateTutorDetail?.data?.videoUrl])

    const handleUploadVideo = () => {
        dispatcher(resetUpdateTutorDetail())
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            userId: tutorDetail?.data?.user?.id
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const onHandleRefresh = () => {
        dispatcher(getTutorDetail(Number(tutorId)))
    }

    const onHandleSave = (key, value) => {

        if (!editBtnConst || editBtnConst !== key || updateTutorDetail?.isLoading || tutorDetail?.isLoading) return;

        if (tutorDetail?.isLoading || updateTutorDetail?.isLoading) return;

        const requestDatPayload = {
            tutorId: Number(tutorId),
            body: {
                [key]: value
            }
        }
        dispatcher(updateTutorUserDetail(requestDatPayload))
        setEditBtnConst(null)
    }

    const onHandleCancel = () => {
        setEditBtnConst(null)
    }

    const onHandleChangeText = (key, value) => {
        if (tutorDetail?.isLoading || updateTutorDetail?.isLoading) return;

        if (!editBtnConst || editBtnConst !== key) {
            setEditBtnConst(key)
        }
        dispatcher(setTutorDetailData({
            ...tutorDetail?.data,
            [key]: value
        }))
    }

    const onHandleAddBorderBtn = (key) => {
        if (!editBtnConst || editBtnConst !== key) {
            setEditBtnConst(key)
        }
        setIsShowAddBtn({
            ...isShowAddBtn,
            [key]: true
        })
    }

    if (tutorDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={tutorDetail?.isLoading} />
        )
    }

    if (tutorDetail?.message) {
        return (
            <div className={"w-screen h-screen flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                {tutorDetail?.message}
            </div>
        )
    }

    return (
        <>
            <div className={"space-y-5"}>

                {/* video section */}
                {updateTutorDetail?.isLoading &&
                    <ComponentLoader isLoading={updateTutorDetail?.isLoading} />
                }
                {!updateTutorDetail?.isLoading &&
                    <div className={"w-full md:w-[25rem] h-full rounded-lg space-y-1"}>
                        <MuxVideoPlayer
                            playbackId={tutorDetail?.data?.videoUrl ? tutorDetail?.data?.videoUrl : MUX_DEMO_PLAYBACK_ID}
                            isShowRefreshButton={true}
                            isShowButtons={true}
                            handleUploadVideo={handleUploadVideo}
                            onHandleRefresh={onHandleRefresh}
                        />
                    </div>

                }

                {/* one liner section */}
                <div className={"flex flex-col items-start justify-start gap-2"}>
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {"Me in one sentence"}
                        </span>
                        <div className={"relative has-tooltip cursor-pointer flex justify-end"}>
                            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                            <span
                                className={cn(
                                    "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -top-20 -right-3 md:left-1",
                                    "flex flex-col items-start justify-start overflow-hidden"
                                )}
                            >
                                {"For your public profile, have a snappy one-line for Me in a sentence field. It's like an elevator pitch with a few words. A few words to define you as an educator."}
                            </span>
                        </div>
                    </div>
                    <DashedBorderButton
                        isShowBtn={(!editBtnConst || editBtnConst !== profileEditBtnConst?.ELEVATOR_PITCH?.value) && (!tutorDetail?.data?.elevatorPitch || tutorDetail?.data?.elevatorPitch?.length === 0)}
                        btnTitle={"Add Elevator Pitch(s)"}
                        onHandleAdd={() => onHandleAddBorderBtn(profileEditBtnConst?.ELEVATOR_PITCH?.value)}
                    />

                    {((editBtnConst === profileEditBtnConst?.ELEVATOR_PITCH?.value) || (tutorDetail?.data?.elevatorPitch && tutorDetail?.data?.elevatorPitch?.length > 0)) &&
                        <input
                            type={"text"}
                            value={tutorDetail?.data?.elevatorPitch}
                            className={cn(
                                "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            onChange={(e) => onHandleChangeText(profileEditBtnConst?.ELEVATOR_PITCH?.value, e.target.value)}
                            disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.ELEVATOR_PITCH?.value}
                        />
                    }

                    <SaveAndCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.ELEVATOR_PITCH?.value)}
                        onHandleSave={() => onHandleSave(profileEditBtnConst?.ELEVATOR_PITCH?.value, tutorDetail?.data?.elevatorPitch)}
                        onHandleCancel={onHandleCancel}
                    />
                </div>

                {/* About me section */}
                <div className={"flex flex-col items-start justify-start gap-2"}>
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {"About"}
                        </span>
                        <div className={"relative has-tooltip cursor-pointer flex justify-end"}>
                            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                            <span
                                className={cn(
                                    "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -top-20 left-1",
                                    "flex flex-col items-start justify-start overflow-hidden"
                                )}
                            >
                                {"Include your compelling / aspirational story or background, what you teach and why in the About me field, as your potential learners will keenly go through it."}
                            </span>
                        </div>
                    </div>
                    <DashedBorderButton
                        isShowBtn={(!editBtnConst || editBtnConst !== profileEditBtnConst?.BIO?.value) && (!tutorDetail?.data?.bio || tutorDetail?.data?.bio?.length === 0)}
                        btnTitle={"Add About(s)"}
                        onHandleAdd={() => onHandleAddBorderBtn(profileEditBtnConst?.BIO?.value)}
                    />
                    {((editBtnConst === profileEditBtnConst?.BIO?.value) || (tutorDetail?.data?.bio && tutorDetail?.data?.bio?.length > 0)) &&
                        <textarea
                            className={cn(
                                "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-lg text-text-600 tracking-wide resize-y disabled:bg-text-100"
                            )}
                            rows={5}
                            minLength={MIN_CHARACTER_LENGTH}
                            maxLength={MAX_CHARACTER_LENGTH}
                            value={tutorDetail?.data?.bio
                                ? tutorDetail?.data?.bio?.length > 700
                                    ? tutorDetail?.data?.bio?.slice(0, 700)
                                    : tutorDetail?.data?.bio
                                : ""
                            }
                            onChange={(event) => onHandleChangeText(profileEditBtnConst?.BIO?.value, event?.target?.value)}
                            disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.BIO?.value}
                        />
                    }

                    <div className={"w-full flex justify-between"}>
                        {(tutorDetail?.data?.bio?.length < MIN_CHARACTER_LENGTH || tutorDetail?.data?.bio?.length > MAX_CHARACTER_LENGTH) &&
                            <span className="w-full font-bodyPri font-normal tracking-wide text-red-500 text-base">
                                {"Please enter between 50 and 700 characters"}
                            </span>
                        }
                        <span className="w-full flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                            {(tutorDetail?.data?.bio?.length > 700 ? 700 : tutorDetail?.data?.bio?.length) + "/" + MAX_CHARACTER_LENGTH}
                        </span>
                    </div>
                    <SaveAndCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.BIO?.value)}
                        onHandleSave={() => onHandleSave(profileEditBtnConst?.BIO?.value, tutorDetail?.data?.bio)}
                        onHandleCancel={onHandleCancel}
                    />
                </div>

                {/* teaching style */}
                <div className={"flex flex-col items-start justify-start gap-2"}>
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {"Teaching style"}
                        </span>
                        <div className={"relative has-tooltip cursor-pointer flex justify-end"}>
                            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                            <span
                                className={cn(
                                    "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip -top-8 -right-3 md:left-1",
                                    "flex flex-col items-start justify-start overflow-hidden"
                                )}
                            >
                                {"Write briefly about your creative, innovative teaching style."}
                            </span>
                        </div>
                    </div>
                    <DashedBorderButton
                        isShowBtn={(!editBtnConst || editBtnConst !== profileEditBtnConst?.TEACHING_STYLE_BIO?.value) && (!tutorDetail?.data?.teachingStyleBio || tutorDetail?.data?.teachingStyleBio?.length === 0)}
                        btnTitle={"Add About(s)"}
                        onHandleAdd={() => onHandleAddBorderBtn(profileEditBtnConst?.TEACHING_STYLE_BIO?.value)}
                    />

                    {((editBtnConst === profileEditBtnConst?.TEACHING_STYLE_BIO?.value) || (tutorDetail?.data?.teachingStyleBio && tutorDetail?.data?.teachingStyleBio?.length > 0)) &&
                        <textarea
                            className={cn(
                                "w-full px-3 py-2 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-lg text-text-600 tracking-wide resize-y disabled:bg-text-100"
                            )}
                            rows={5}
                            minLength={MIN_CHARACTER_LENGTH}
                            maxLength={MAX_CHARACTER_LENGTH}
                            value={tutorDetail?.data?.teachingStyleBio
                                ? tutorDetail?.data?.teachingStyleBio?.length > 700
                                    ? tutorDetail?.data?.teachingStyleBio?.slice(0, 700)
                                    : tutorDetail?.data?.teachingStyleBio
                                : ""
                            }
                            onChange={(event) => onHandleChangeText(profileEditBtnConst?.TEACHING_STYLE_BIO?.value, event?.target?.value)}
                            disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.TEACHING_STYLE_BIO?.value}
                        />
                    }
                    <div className={"w-full flex justify-between"}>
                        {(tutorDetail?.data?.teachingStyleBio?.length < MIN_CHARACTER_LENGTH || tutorDetail?.data?.teachingStyleBio?.length > MAX_CHARACTER_LENGTH) &&
                            <span className="w-full font-bodyPri font-normal tracking-wide text-red-500 text-base">
                                {"Please enter between 50 and 700 characters"}
                            </span>
                        }
                        <span className="w-full flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                            {(tutorDetail?.data?.teachingStyleBio?.length > 700 ? 700 : tutorDetail?.data?.teachingStyleBio?.length) + "/" + MAX_CHARACTER_LENGTH}
                        </span>
                    </div>
                    <SaveAndCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.TEACHING_STYLE_BIO?.value)}
                        onHandleSave={() => onHandleSave(profileEditBtnConst?.TEACHING_STYLE_BIO?.value, tutorDetail?.data?.teachingStyleBio)}
                        onHandleCancel={onHandleCancel}
                    />
                </div>

                {/* Visibility container */}
                <div className={"col-start-1 col-span-12 flex flex-col items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide whitespace-nowrap"}>
                        {"Visibility"}
                    </span>
                    <div className={"w-60"}>
                        <OptionSelector
                            options={Object.values(tutorVisibilityEnum)}
                            className={"w-full"}
                            value={tutorDetail?.data?.visibility}
                            onChange={(option) => onHandleChangeText(profileEditBtnConst?.VISIBILITY?.value, option?.value)}
                            disabled={editBtnConst && editBtnConst !== profileEditBtnConst?.VISIBILITY?.value}
                        />
                    </div>
                    <SaveAndCancelBtn
                        isShowBtn={(editBtnConst === profileEditBtnConst?.VISIBILITY?.value)}
                        onHandleSave={() => onHandleSave(profileEditBtnConst?.VISIBILITY?.value, tutorDetail?.data?.visibility)}
                        onHandleCancel={onHandleCancel}
                    />
                </div>
            </div>
        </>
    )
}

export default EditTutorInfo;