import { createSlice } from "@reduxjs/toolkit";

import { EVENT_INITIAL_STATE } from "redux/event/event.initialState";

const event = createSlice({
    name: "event",
    initialState: EVENT_INITIAL_STATE,
    reducers: {},
    extraReducers: {}
})

export const {} = event.actions;

export default event.reducer;