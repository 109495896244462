import { useState } from 'react';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

const CurrentDateTime = () => {
    const [current, setCurrent] = useState(dayjs().tz(timeZone))

    return (
        <div className={"w-full flex flex-col justify-end space-y-1"}>
            <p className="font-bodyPri font-normal text-sm text-text-700 text-right">
                {current.format("ddd, DD MMM YY hh:mm:ss A")} - [{timeZone} ({getTimeZoneOffset(timeZone)})]
            </p>
            <div className={"h-0.5 bg-divider-medium w-full"}></div>
        </div>
    )
}

export default CurrentDateTime