import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import lmsQuizService from 'redux/edulyteLms/lmsQuiz/lmsQuiz.service';
import {
    setLmsQuizListLoading,
    setLmsQuizListData,
    setLmsQuizListMessage,

    setDestroyLmsQuizDetailLoading,
    setDestroyLmsQuizDetailMessage,
} from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { lmsQuizRedux } from './lmsQuiz.const';


export const getLmsQuizList = createAsyncThunk(
    `${lmsQuizRedux?.sliceName}/${lmsQuizRedux?.lmsQuizList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setLmsQuizListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await lmsQuizService.getLmsQuizList(requestData)
            if (response.status === 200) {
                dispatch(setLmsQuizListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data?.error)
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
            dispatch(setLmsQuizListMessage(error?.response?.data?.message || error?.response?.data?.error))
        } finally {
            dispatch(setLmsQuizListLoading(false))
        }
    }
)

// delete lms quiz detail
export const deleteLmsQuizDetail = createAsyncThunk(
    `${lmsQuizRedux?.sliceName}/${lmsQuizRedux?.lmsQuizList?.requestName}`,
    async (quizId, { dispatch }) => {
        dispatch(setDestroyLmsQuizDetailLoading(true))

        try {
            const requestData = {
                params: { quizId: quizId }
            }

            const response = await lmsQuizService.deleteLmsQuizDetail(requestData)
            if (response.status === 204) {
                dispatch(getLmsQuizList({ page: 1, records: 25 }))
                toast.success(response.data.message || "Deleted Successfully")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setDestroyLmsQuizDetailMessage(error.response.data.message || error.response.data.error || error))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setDestroyLmsQuizDetailLoading(false))
        }
    }
)