import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/badge/badge.const";

class BadgeService {

    getBadgeList = async () => {
        const response = await baseAuthApiInstance().get(
            url.BADGE_LIST
        )

        return response;
    }

    getUserBadgeListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_BADGE_LIST, params)
        )

        return response;
    }

    createUserBadgeDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.USER_BADGE_LIST, params),
            body
        )

        return response;
    }

    updateUserBadgeDetailByUserBadgeId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_BADGE_DETAIL, params),
            body
        )

        return response;
    }

    updateUserBadgeListOrderByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ORDER_USER_BADGE_LIST, params),
            body
        )

        return response;
    }

    deleteUserBadgeDetailByUserBadgeId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.USER_BADGE_DETAIL, params)
        )

        return response;
    }
}
export default new BadgeService()