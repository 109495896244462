import { useState } from "react";

import { IoIosArrowBack } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa';

import RescheduleComment from "./components/RescheduleComment";
import RescheduleTimeSlots from "./components/RescheduleTimeSlots";
import ConfirmReschedule from "./components/ConfirmReschedule";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetModifySessionDetailPayload } from "redux/session/session.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";

const RescheduleModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { modifyRescheduleSessionDetail } = useAppState((state) => state.session)

    const [stepCount, setStepCount] = useState(1)

    const dispatcher = useAppDispatcher()

    const onHandleSubmit = () => {
        dispatcher(resetModifySessionDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey]: false
        }))
    }


    if (modifyRescheduleSessionDetail?.data?.result) {
        return (
            <div className={"space-y-5 overflow-hidden"}>
                <div className={"min-h-[24rem] block"}>
                    <ConfirmReschedule />
                </div>
                <div className={"w-full flex justify-end px-5"}>
                    <span onClick={onHandleSubmit} className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}>
                        {"Done"}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className={"space-y-5 overflow-hidden"}>
            <div className={"min-h-[24rem] block"}>
                {(stepCount === 1) &&
                    <RescheduleComment setStepCount={setStepCount} />
                }
                {(stepCount === 2) &&
                    <RescheduleTimeSlots setStepCount={setStepCount} />
                }
            </div>
            {(stepCount === 2) &&
                <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                    {modifyRescheduleSessionDetail?.isLoading &&
                        <div className={"w-full flex items-center justify-start gap-5"}>
                            <FaSpinner className={"animate-spin text-lg text-primary-main"} />
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                {"Please Wait ..."}
                            </span>
                        </div>
                    }
                    {(!modifyRescheduleSessionDetail?.isLoading && modifyRescheduleSessionDetail?.message) &&
                        <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {modifyRescheduleSessionDetail?.message}
                        </div>
                    }
                    <div
                        className={"flex justify-start gap-0.5 text-primary-dark cursor-pointer"}
                        onClick={() => setStepCount(1)}
                    >
                        <IoIosArrowBack className={"text-2xl"} />
                        <span className={"font-buttons font-medium text-base"}>
                            {"Back"}
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}
export default RescheduleModal;