import { cn } from "utils/cn.utils";


const SaveAndCancelBtn = ({
    isShowBtn = false,
    btn1Title = "Save",
    btn2Title = "Cancel",
    onHandleCancel,
    onHandleSave,
    isDisabled = false
}) => {
    return isShowBtn ? (
        <div className={"flex items-center justify-center gap-3"}>
            <div onClick={onHandleCancel} className={cn(
                "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                "font-buttons font-normal text-base",
                !isDisabled && "text-text-800 bg-background-darkLight hover:bg-background-medium hover:text-text-900",
                isDisabled && "bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed"
            )}>
                <span>
                    {btn2Title}
                </span>
            </div>
            <div onClick={onHandleSave} className={cn(
                "flex items-center justify-center w-20 py-1 rounded-md cursor-pointer",
                "font-buttons font-normal text-base",
                !isDisabled && "text-text-50 bg-primary-main hover:bg-primary-dark",
                isDisabled && "bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed"
            )}>
                <span>
                    {btn1Title}
                </span>
            </div>
        </div>
    ) : null
}

export default SaveAndCancelBtn;