import { useState, useEffect } from "react";

import { cn } from "utils/cn.utils";

import { FiSearch } from "react-icons/fi";

import ButtonLoader from "components/loader/ButtonLoader";
import ComponentLoader from "components/loader/ComponentLoader";

import { getUnsplashThumbnailList } from "redux/other/other.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearThumbnailList, setThumbnailDetailPayload } from "redux/other/other.slice";
import { orientationTypeConst } from "redux/other/other.const";


const unsplashLicense = "https://unsplash.com/?utm_source=trello&utm_medium=referral&utm_campaign=api-credit"

const SearchPhotoModal = () => {
    const { thumbnailList, thumbnailDetail } = useAppState((state) => state.other)

    const dispatcher = useAppDispatcher()

    const [isShakeSearch, setIsShakeSearch] = useState(false)
    const [thumbnailUrl, setThumbnailUrl] = useState(null)

    useEffect(() => {
        if (thumbnailDetail?.thumbnailDetailPayload?.query) {
            dispatcher(getUnsplashThumbnailList())
        }

        return () => {
            dispatcher(setClearThumbnailList())
        }

    }, [])

    useEffect(() => {
        if (isShakeSearch) {
            setTimeout(() => {
                setIsShakeSearch(false)
            }, 2000)
        }
    }, [isShakeSearch])

    const onHandleSearch = (event) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            dispatcher(getUnsplashThumbnailList())
        }
    }

    const handleSearch = () => {
        dispatcher(getUnsplashThumbnailList())
    }

    const onHandleSkills = async (skill) => {
        // setIsShakeSearch(true)
        dispatcher(setThumbnailDetailPayload({
            ...thumbnailDetail?.thumbnailDetailPayload,
            query: skill
        }))
        await dispatcher(getUnsplashThumbnailList())
    }

    const handleSelectThumbnail = (unsplashThumbnailUrl) => {
        setThumbnailUrl(unsplashThumbnailUrl)
    }

    const handleSubmit = () => {
        dispatcher(setThumbnailDetailPayload({
            ...thumbnailDetail?.thumbnailDetailPayload,
            url: thumbnailUrl?.urls?.regular
        }))
    }

    return (
        <div className="space-y-5 overflow-x-hidden">
            <div className={"min-h-[16rem] block w-full space-y-5"}>
                <div className={"flex items-center justify-center gap-3"}>
                    <div className={cn(
                        "w-full px-3 py-1.5 flex items-center justify-start gap-1 rounded-lg hover:outline-none group",
                        !isShakeSearch && "border-2 border-divider-medium focus:border-primary-dark",
                        isShakeSearch && "border-2 border-secondary-dark focus:border-secondary-dark"
                    )}>
                        <input
                            type={"text"}
                            className={cn(
                                "w-full focus:outline-none group-hover:outline-none font-bodyPri font-normal text-text-900 text-base",
                                "placeholder:text-text-700 placeholder:text-sm placeholder:font-bodyPri"
                            )}
                            placeholder={"e.g: Write keyword..."}
                            value={thumbnailDetail?.thumbnailDetailPayload?.query}
                            onChange={(event) => {
                                setIsShakeSearch(true)
                                dispatcher(setThumbnailDetailPayload({
                                    ...thumbnailDetail?.thumbnailDetailPayload,
                                    query: event.target.value
                                }))
                            }
                            }
                            onKeyDown={onHandleSearch}
                        />
                        <span
                            className={cn(
                                "py-0.5 flex items-center justify-center gap-1 rounded-full px-2",
                                "text-text-50 font-bodyPri font-light text-base cursor-pointer",
                                !isShakeSearch && "bg-primary-dark",
                                isShakeSearch && "bg-secondary-dark transition scale-105 duration-300 delay-150 ease-in-out animate-bounce"
                            )}
                            onClick={handleSearch}
                        >
                            <FiSearch className={"text-text-50 text-base"} />
                            <span className={"text-sm"}>
                                {"Search"}
                            </span>
                        </span>
                    </div>
                </div>
                {thumbnailDetail?.thumbnailDetailPayload?.keywords?.length > 0 &&
                    <div className={"space-y-1"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                            {"Suggested Keywords"}
                        </span>
                        <div className={"flex flex-wrap items-center justify-start gap-3 border border-text-200 px-2 py-1.5 rounded-lg"}>
                            {thumbnailDetail?.thumbnailDetailPayload?.keywords?.map((keyword, index) => (
                                <span key={index}
                                    className={'bg-primary-light py-0.5 md:py-1 px-3 rounded-lg text-text-900 font-bodyPri font-normal text-sm cursor-pointer'}
                                    onClick={() => onHandleSkills(keyword?.skill)}
                                >
                                    {keyword?.skill}
                                </span>
                            ))}
                        </div>
                    </div>
                }

                <div className={"space-y-1.5"}>
                    <div className={"font-bodyPri font-normal text-text-900 text-sm"}>
                        {"Results"}
                    </div>

                    <div className={cn(
                        "flex flex-wrap items-enter justify-start gap-1.5 max-h-80 overflow-auto",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {thumbnailList?.isLoading &&
                            <ComponentLoader isLoading={thumbnailList?.isLoading} />
                        }
                        {!thumbnailList?.isLoading && thumbnailList?.thumbnailList?.results?.map((thumbnail) => (
                            <div
                                className={cn(
                                    "relative rounded-lg cursor-pointer border-2 border-white p-0.5",
                                    "hover:border-primary-dark group",
                                    (thumbnailUrl?.urls?.regular === thumbnail?.urls?.regular) && "border-secondary-dark"
                                )}
                                onClick={() => handleSelectThumbnail(thumbnail)}
                            >
                                <img
                                    className={"overflow-hidden rounded-lg object-cover"}
                                    width={orientationTypeConst[thumbnailDetail?.thumbnailDetailPayload?.orientation?.toUpperCase()]?.dimension?.width * thumbnailDetail?.thumbnailDetailPayload?.dimensionUnit}
                                    height={orientationTypeConst[thumbnailDetail?.thumbnailDetailPayload?.orientation?.toUpperCase()]?.dimension?.height * thumbnailDetail?.thumbnailDetailPayload?.dimensionUnit}
                                    src={thumbnail?.urls?.regular}
                                    alt={"thumbnail-picture"}
                                />
                                <a
                                    href={`${thumbnail?.user?.links?.html}?utm_source=Edulyte&utm_medium=referral`}
                                    className={cn(
                                        "hidden group-hover:block absolute mx-0.5 mb-0.5 px-2 py-0.5 font-bodyPri font-light text-xs text-left rounded shadow-lg bg-text-800 bg-opacity-75 text-text-50 bottom-0",
                                        "group-hover:transition group-hover:ease-in-out group-hover:duration-300 group-hover:delay-300"
                                    )}
                                    target={"_blank"}
                                >
                                    {thumbnail?.user?.first_name + " "}
                                    {thumbnail?.user?.last_name && thumbnail?.user?.last_name}
                                </a>
                            </div>
                        ))}
                        {thumbnailList?.errorMsg &&
                            <div className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                {thumbnailList?.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={"flex justify-between items-center"}>
                <div className={"font-bodyPri font-normal text-text-700 text-sm"}>
                    {"Photos from "}
                    <a
                        className="cursor-pointer text-text-700 text-sm underline"
                        href={unsplashLicense}
                        target={"_blank"}
                    >
                        {"Unsplash"}
                    </a>
                </div>
                <div
                    className={cn(
                        "w-28 py-1 focus:outline-none flex justify-center items-center rounded-full",
                        "font-bodyPri font-normal text-base",
                        "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        thumbnailDetail?.isLoading && "bg-secondary-main"
                    )}
                    onClick={handleSubmit}
                >
                    {thumbnailDetail?.isLoading &&
                        <ButtonLoader isLoading={thumbnailDetail?.isLoading} />
                    }
                    {!thumbnailDetail?.isLoading && "Submit"}
                </div>
            </div>
        </div>
    )
}

export default SearchPhotoModal