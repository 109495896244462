import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import appointmentService from "./appointment.service";

import {
    setAppointmentListLoading,
    setAppointmentListData,
    setAppointmentListMessage,
    
    setAppointmentDetailLoading,
    setAppointmentDetailData,
    setAppointmentDetailMessage,

    setModifyAppointmentDetailLoading,
    setModifyAppointmentDetailData,
    setModifyAppointmentDetailMessage,
    
    setDestroyAppointmentDetailLoading,
    setDestroyAppointmentDetailMessage,
} from "redux/appointment/appointment.slice";
import { appointmentRedux } from "redux/appointment/appointment.const";

export const getAppointmentList = createAsyncThunk(
    `${appointmentRedux?.sliceName}/${appointmentRedux?.appointmentList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setAppointmentListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await appointmentService.getAppointmentList(requestData)
            if (response.status === 200) {
                dispatch(setAppointmentListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAppointmentListMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAppointmentListLoading(false))
        }
    }
)

// appointment detail
export const getAppointmentDetail = createAsyncThunk(
    `${appointmentRedux?.sliceName}/${appointmentRedux.appointmentDetail.requestName}`,
    async (appointmentDetail, { dispatch }) => {
        dispatch(setAppointmentDetailLoading(true))

        try {
            const requestData = {
                params: { appointmentId: appointmentDetail?.appointmentId }
            }
            const response = await appointmentService.getAppointmentDetail(requestData)
            if (response.status === 200) {
                dispatch(setAppointmentDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAppointmentDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAppointmentDetailLoading(false))
        }
    }
)

// update appointment detail
export const updateAppointmentDetail = createAsyncThunk(
    `${appointmentRedux?.sliceName}/${appointmentRedux?.appointmentDetail?.requestName}`,
    async (payload, {dispatch}) => {
        dispatch(setModifyAppointmentDetailLoading(true))

        try {
            const requestData = {
                params: { appointmentId: payload.appointmentId },
                body: payload.body
            }
            const response = await appointmentService.updateAppointmentDetail(requestData)
            if (response.status === 200) {
                dispatch(setModifyAppointmentDetailData(response.data.data))
                toast.success(response.data.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            dispatch(setModifyAppointmentDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            dispatch(setModifyAppointmentDetailLoading(false))
        }
    }
)

// destroy appointment detail
export const deleteAppointmentDetail = createAsyncThunk(
    `${appointmentRedux?.sliceName}/${appointmentRedux?.getAppointmentList?.requestName}`,
    async (appointmentId, { dispatch, getState }) => {
        dispatch(setDestroyAppointmentDetailLoading(true))

        try {
            const { appointmentList } = getState().appointment
            const requestData = {
                params: { appointmentId: appointmentId }
            }

            const response = await appointmentService.deleteAppointmentDetail(requestData)
            if (response.status === 204) {
                dispatch(setAppointmentListData({
                    ...appointmentList?.data,
                    result: appointmentList?.data?.result?.filter((item) => item?.id !== appointmentId),
                    pagination: {
                        ...appointmentList?.data?.pagination,
                        records: appointmentList?.data?.pagination?.records - 1,
                        totalRecords: appointmentList?.data?.pagination?.totalRecords - 1
                    },
                }))
                toast.success(response.data.data.message || "Deleted Successfully")
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setDestroyAppointmentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setDestroyAppointmentDetailLoading(false))
        }
    }
)