import { configureStore } from '@reduxjs/toolkit';
import mainRootReducer from 'redux/rootReducer';

const store = configureStore({
    reducer: mainRootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck:false,
    }),
});

export default store;