import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader";

import { searchParamsInfo } from "pages/auth/sessions/data";
import SessionBasicDetail from "pages/auth/sessions/editDetail/components/SessionBasicDetail";
import SessionDetailCountDown from "pages/auth/sessions/editDetail/components/SessionDetailCountDown";
import SessionDefaultLocation from "pages/auth/sessions/editDetail/components/SessionDefaultLocation";
import SessionDetailCourseType from "pages/auth/sessions/editDetail/components/SessionDetailCourseType";
import SessionDetailStatus from "pages/auth/sessions/editDetail/components/SessionDetailStatus";
import SessionDetailLocation from "pages/auth/sessions/editDetail/components/SessionDetailLocation";
import SessionDetailParticipants from "pages/auth/sessions/editDetail/components/SessionDetailParticipants";
// import SessionDetailBookings from "pages/auth/sessions/editDetail/components/SessionDetailBookings";
import SessionDetailCourse from "pages/auth/sessions/editDetail/components/SessionDetailCourse";
import SessionDetailInterview from "pages/auth/sessions/editDetail/components/SessionDetailInterview";
import SessionDetailEventLogs from "pages/auth/sessions/editDetail/components/SessionDetailEventLogs";

import { getSessionDetail } from 'redux/session/session.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetSessionDetail, resetModifyStartSessionDetail, setSessionDetailData, resetAddSessionUserDetail, resetModifySessionUserDetail, resetModifySessionUserDetailPayload } from "redux/session/session.slice";

export const EditSessionSidePanelDetail = () => {
    const { sessionDetail, modifyStartSessionDetail, addSessionUserDetail, modifySessionUserDetail } = useAppState((s) => s.session)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (!!modifyStartSessionDetail?.data?.result) {
            dispatcher(getSessionDetail({ sessionId: modifyStartSessionDetail?.data?.result?.id || searchQueryParams?.get(searchParamsInfo?.sessionId?.key) }))
            dispatcher(resetModifyStartSessionDetail())
        }
    }, [modifyStartSessionDetail?.data?.result])

    useEffect(() => {
        if (!!addSessionUserDetail?.data?.result) {
            dispatcher(setSessionDetailData({
                ...sessionDetail?.data,
                result: {
                    ...sessionDetail?.data?.result,
                    session_users: [addSessionUserDetail?.data?.result, ...sessionDetail?.data?.result?.session_users]
                }
            }))
            dispatcher(resetAddSessionUserDetail())
        }
    }, [addSessionUserDetail?.data?.result])

    useEffect(() => {
        if (!!modifySessionUserDetail?.data?.result) {
            dispatcher(setSessionDetailData({
                ...sessionDetail?.data,
                result: {
                    ...sessionDetail?.data?.result,
                    session_users: sessionDetail?.data?.result?.session_users?.map((sessionUser) => (
                        (sessionUser?.id === modifySessionUserDetail?.data?.result?.id) ? { ...modifySessionUserDetail?.data?.result } : sessionUser
                    ))
                }
            }))
            dispatcher(resetModifySessionUserDetail())
            dispatcher(resetModifySessionUserDetailPayload())
        }
    }, [modifySessionUserDetail?.data?.result])

    useEffect(() => {
        if (!!searchQueryParams?.get(searchParamsInfo?.sessionId?.key)) {
            dispatcher(getSessionDetail({ sessionId: searchQueryParams?.get(searchParamsInfo?.sessionId?.key) }))
        }

        return () => {
            dispatcher(resetSessionDetail())
        }
    }, [searchQueryParams?.get(searchParamsInfo?.sessionId?.key)])

    return (
        <div className={"p-5 h-screen sm:px-10 max-w-2xl md:max-w-xl lg:max-w-lg"}>
            <ComponentLoader isLoading={sessionDetail?.isLoading} className={"w-full"} />
            {(!sessionDetail?.isLoading && sessionDetail?.message) &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wde"}>
                        {sessionDetail?.message}
                    </span>
                </div>
            }
            {(!sessionDetail?.isLoading && !!sessionDetail?.data?.result) && (
                <div className={"w-full md:w-[25rem] mx-auto space-y-5 pb-5"}>
                    <SessionBasicDetail />

                    <SessionDetailCountDown />

                    {!!sessionDetail?.data?.result?.session_locations?.length &&
                        <SessionDefaultLocation sessionLocationInfo={sessionDetail?.data?.result?.session_locations[0]} />
                    }

                    <SessionDetailCourseType />

                    <SessionDetailStatus />

                    {sessionDetail?.data?.result && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    <SessionDetailLocation />

                    {(sessionDetail?.data?.result && !!sessionDetail?.data?.result?.session_users) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    <SessionDetailParticipants />

                    {(sessionDetail?.data?.result && !!sessionDetail?.data?.result?.session_course) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    {/* <SessionDetailBookings /> */}
                    <SessionDetailCourse />

                    {(sessionDetail?.data?.result && !!sessionDetail?.data?.result?.session_interview) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    <SessionDetailInterview />

                    {(sessionDetail?.data?.result && !!sessionDetail?.data?.result?.session_logs) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    <SessionDetailEventLogs />
                </div>
            )}
        </div>
    );
}

export default EditSessionSidePanelDetail;