import { useState, useEffect } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import { getUserItemListByUserId, getItemPriceList } from 'redux/chargeBee/chargeBee.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearUserItemList, setClearItemPriceList } from "redux/chargeBee/chargeBee.slice";
import { periodUnit } from "redux/chargeBee/chargeBee.const";
import { priceType } from 'redux/course/course.const';

const PlanPriceTypeContainer = () => {
    const { userItemList, itemPriceList } = useAppState((s) => s.chargeBee)
    const { courseDetail, coursePriceDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [planPriceType, setPlanPriceType] = useState({
        course: courseDetail?.courseDetail?.id,
        type: priceType?.PLAN?.value,
        selectedPlan: null,
        item: null,
        itemPrices: [],
        unitType: {
            week: true,
            month: true,
            year: true
        }
    })

    useEffect(() => {
        dispatcher(getUserItemListByUserId({ userId: courseDetail?.courseDetail?.tutor?.user?.id }))

        return () => {
            dispatcher(setClearUserItemList())
        }
    }, [courseDetail?.courseDetail?.tutor?.user?.id])

    useEffect(() => {
        if (planPriceType?.selectedPlan) {
            dispatcher(getItemPriceList({ userItemId: planPriceType?.selectedPlan }))
        }

        return () => {
            dispatcher(setClearItemPriceList())
        }
    }, [planPriceType?.selectedPlan])

    useEffect(() => {
        if (userItemList?.filteredUserItemList && coursePriceDetail?.coursePriceDetail) {
            const filterItemId = userItemList?.filteredUserItemList?.find((userItem) => userItem?.item?.id === coursePriceDetail?.coursePriceDetail?.item?.data?.id)
            const filteredItemPrices = coursePriceDetail?.coursePriceDetail?.itemPrices?.data?.map((items) => ({
                itemPrice: items?.id
            }))
            setPlanPriceType({
                ...planPriceType,
                selectedPlan: filterItemId?.userItemId,
                item: coursePriceDetail?.coursePriceDetail?.item?.data?.id,
                itemPrices: filteredItemPrices,
                // unitType: {
                //     week: coursePriceDetail?.coursePriceDetail?.itemPrices?.data?.filter((item) => item?.period_unit === periodUnit?.WEEK?.value)?.length > 0 ? true : false,
                //     month: coursePriceDetail?.coursePriceDetail?.itemPrices?.data?.filter((item) => item?.period_unit === periodUnit?.MONTH?.value)?.length > 0 ? true : false,
                //     year: coursePriceDetail?.coursePriceDetail?.itemPrices?.data?.filter((item) => item?.period_unit === periodUnit?.YEAR?.value)?.length > 0 ? true : false
                // }
            })
        }
    }, [userItemList?.filteredUserItemList, coursePriceDetail?.coursePriceDetail])

    // const onToggleChange = (isToggle, itemPrice) => {
    //     if (isToggle) {
    //         setPlanPriceType({
    //             ...planPriceType,
    //             unitType: {
    //                 ...planPriceType?.unitType,
    //                 [itemPrice?.period_unit]: isToggle
    //             },
    //             itemPrices: [
    //                 ...planPriceType?.itemPrices,
    //                 { itemPrice: itemPrice?.id }
    //             ]
    //         })
    //     }
    //     if (!isToggle) {
    //         const filteredItemPrices = planPriceType?.itemPrices?.filter((item) => item?.itemPrice !== itemPrice?.id)
    //         setPlanPriceType({
    //             ...planPriceType,
    //             unitType: {
    //                 ...planPriceType?.unitType,
    //                 [itemPrice?.period_unit]: isToggle
    //             },
    //             itemPrices: filteredItemPrices
    //         })
    //     }
    // }

    return (
        <div className={'col-start-1 col-span-full grid grid-cols-12 gap-y-5 gap-x-3'}>
            <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3 font-bodyPri font-normal text-text-700 tracking-wide whitespace-nowrap">
                {"Plan Name"}
                <span className={"text-red-500 text-lg"}>{"*"}</span>
            </span>
            {userItemList?.isLoading &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 md:w-48"}>
                    <ComponentLoader isLoading={userItemList.isLoading} />
                </div>
            }
            {!userItemList?.isLoading && userItemList?.filteredUserItemList?.length > 0 &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full flex items-center justify-start gap-3"}>
                    <div className={"w-full md:w-80"}>
                        <OptionSelector
                            options={userItemList?.filteredUserItemList?.map((userItem) => ({
                                label: userItem?.item?.name,
                                value: userItem?.userItemId
                            }))}
                            className={"w-full"}
                            value={planPriceType?.selectedPlan}
                        />
                    </div>
                </div>
            }

            {/* Price container */}
            {itemPriceList?.isLoading &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 md:w-48"}>
                    <ComponentLoader isLoading={itemPriceList?.isLoading} />
                </div>
            }
            {(itemPriceList?.filteredItemPriceList?.length > 0) &&
                itemPriceList?.filteredItemPriceList?.slice(0, 1).map((itemPrice, index) => (
                    <div
                        key={index}
                        className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 md:w-80 border border-text-400 rounded-lg px-3 py-1"}
                    >
                        <div className="flex flex-col items-start justify-start gap-0.5">
                            <p className="font-bodyPri font-semibold text-text-900 text-base">
                                {periodUnit[itemPrice?.period_unit?.toUpperCase()]?.label}
                            </p>
                            <div className={"w-full flex items-center justify-between gap-3"}>
                                <div className={"flex items-center justify-start gap-2"}>
                                    <p className="font-bodyPri font-medium text-text-600 text-base">
                                        {"USD " + parseFloat("" + (itemPrice?.price / 100)).toFixed(2)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            {itemPriceList?.errorMsg &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 font-bodyPri font-normal text-red-500 text-base"}>
                    {itemPriceList?.errorMsg}
                </div>
            }
        </div>
    )
}

export default PlanPriceTypeContainer;