export const url = {
    COUNTRY_LIST: "/masters/countries",
    COUNTRY_DETAIL: "/masters/countries/{masterCountryId}",
    MASTER_TIMEZONE_DETAIL: "/masters/timezones/{timeZone}",
    STATE_LIST: "/masters/states/{masterCountryId}",
    CITY_LIST: "/masters/cities/{masterStateId}",
    CURRENCY_LIST: "/masters/currencies",
    CURRENCY_RATE_LIST: "/masters/currency-rates",
    TIMEZONE_LIST: "/masters/timezones",
    LANGUAGE_LIST: "/masters/languages",
    LANGUAGE_LEVEL_LIST: "/masters/language-levels",
    PROFICIENCY_LIST: "/masters/proficiencies",
    TAG_LIST: "/masters/tags",
    GET_MASTER_SOCIAL_LIST: "/masters/socials",
}

export const masterRedux = {
    sliceName: "master",
    masterCountryList: {
        requestName: "getMasterCountryList"
    },
    masterStateList: {
        requestName: "getMasterStateList"
    },
    masterCityList: {
        requestName: "getMasterCityList"
    },
    masterLanguageList: {
        requestName: "getMasterLanguageList"
    },
    masterProficiencyList: {
        requestName: "getMasterProficiencyList"
    },
    masterSocialList: {
        requestName: "getMasterSocialList"
    }
}

export const languageLevelConst = {
    A1_BEGINNER: {
        label: "Beginner",
        value: "a1_beginner"
    },
    A2_ELEMENTARY: {
        label: "Elementary",
        value: "a2_elementary"
    },
    B1_INTERMEDIATE: {
        label: "Intermediate",
        value: "b1_intermediate"
    },
    B2_UPPER_INTERMEDIATE: {
        label: "Upper Intermediate",
        value: "b2_upper_intermediate"
    },
    C1_ADVANCE: {
        label: "Advance",
        value: "c1_advance"
    },
    C2_PROFICIENT: {
        label: "Proficient",
        value: "c2_proficiency"
    },
    NATIVE: {
        label: "Native",
        value: "native"
    }
}

export const timeFormatConst = {
    HRS_12: {
        label: "12H",
        value: "hrs_12"
    },
    HRS_24: {
        label: "24H",
        value: "hrs_24"
    }
}

export const masterCurrencyCodeEnum = {
    USD: {
        label: "USD",
        value: "USD"
    },
    INR: {
        label: "INR",
        value: "INR"
    },
    AUD: {
        label: "AUD",
        value: "AUD"
    }
}