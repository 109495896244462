import { lmsQuestionFilterProps } from "./lmsQuestion.const";

export const LMS_QUESTION_INITIAL_STATE = {
    lmsQuestionList: {
        isLoading: false,
        data: null,
        message: null
    },

    destroyLmsQuestionDetail: {
        isLoading: false,
        message: null
    },

    filterProps: lmsQuestionFilterProps
}