import { createSlice } from "@reduxjs/toolkit";
import { AUTH_INITIAL_STATE } from "redux/auth/auth.initalState";


const auth = createSlice({
    name: "auth",
    initialState: AUTH_INITIAL_STATE,
    reducers: {
        // reducers for token
        setAccessToken: (state, { payload }) => {
            state.session.accessToken = payload
            state.session.message = AUTH_INITIAL_STATE.session.message
        },

        // reducers for session
        setSessionLoading: (state, { payload }) => {
            state.session.isLoading = payload
        },
        setSessionLoggedIn: (state) => {
            state.session.isLoading = false
            state.session.isLoggedIn = true
            state.session.message = AUTH_INITIAL_STATE.session.message
        },
        setSessionMessage: (state, { payload }) => {
            state.session.message = payload
            state.session.isLoggedIn = AUTH_INITIAL_STATE.session.isLoggedIn
            state.session.accessToken = AUTH_INITIAL_STATE.session.accessToken
        },
        resetSession: (state) => {
            state.session = AUTH_INITIAL_STATE.session
        },

        // reducers for log in
        setLogInLoading: (state, { payload }) => {
            state.logIn.isLoading = payload
        },
        setLogInData: (state, { payload }) => {
            state.logIn.data = payload
            state.logIn.message = AUTH_INITIAL_STATE.logIn.message
        },
        setLogInMessage: (state, { payload }) => {
            state.logIn.message = payload
            state.logIn.data = AUTH_INITIAL_STATE.logIn.data
        },
        resetLogIn: (state) => {
            state.logIn = AUTH_INITIAL_STATE.logIn
        },
        setLogInPayload: (state, { payload }) => {
            state.logIn.payload = payload
        },
        setClearLogInPayload: (state) => {
            state.logIn.payload = AUTH_INITIAL_STATE.logIn.payload
        },

        // reducers for verify referral
        setVerifyReferralLoading: (state, { payload }) => {
            state.verifyReferral.isLoading = payload
        },
        setVerifyReferralData: (state, { payload }) => {
            state.verifyReferral.data = payload
            state.verifyReferral.message = AUTH_INITIAL_STATE.verifyReferral.message
        },
        setVerifyReferralMessage: (state, { payload }) => {
            state.verifyReferral.message = payload
            state.verifyReferral.data = AUTH_INITIAL_STATE.verifyReferral.data
        },
        setClearVerifyReferral: (state) => {
            state.verifyReferral = AUTH_INITIAL_STATE.verifyReferral
        },

        // reducers for send forget password link
        setForgetPasswordLoading: (state, { payload }) => {
            state.forgetPassword.isLoading = payload
        },
        setForgetPasswordMessage: (state, { payload }) => {
            state.forgetPassword.message = payload
        },
        resetForgetPassword: (state) => {
            state.forgetPassword = AUTH_INITIAL_STATE.forgetPassword
        },

        // reducers for verify user
        setAddVerifyUserLoading: (state, { payload }) => {
            state.verifyUser.isLoading = payload
        },
        setAddVerifyUserData: (state, { payload }) => {
            state.verifyUser.data = payload
            state.verifyUser.message = AUTH_INITIAL_STATE.verifyUser.message
        },
        setAddVerifyUserMessage: (state, { payload }) => {
            state.verifyUser.message = payload
            state.verifyUser.data = AUTH_INITIAL_STATE.verifyUser.data
        },
        resetAddVerifyUser: (state) => {
            state.verifyUser = AUTH_INITIAL_STATE.verifyUser
        },

        // reducers for admin-login-as 
        setAdminLoginAsLoading: (state, { payload }) => {
            state.adminLoginAs.isLoading = payload
        },
        setAdminLoginAsData: (state, { payload }) => {
            state.adminLoginAs.data = payload
            state.adminLoginAs.message = AUTH_INITIAL_STATE.adminLoginAs.message
        },
        setAdminLoginAsMessage: (state, { payload }) => {
            state.adminLoginAs.message = payload
            state.adminLoginAs.data = AUTH_INITIAL_STATE.adminLoginAs.data
        },
        resetAdminLoginAs: (state) => {
            state.adminLoginAs = AUTH_INITIAL_STATE.adminLoginAs
        },

    }
})

export const {
    setSessionLoading,
    setSessionLoggedIn,
    setAccessToken,
    setSessionMessage,
    resetSession,

    setLogInLoading,
    setLogInMessage,
    resetLogIn,

    setLogoutLoading,
    setLogout,
    setLogoutErrorMsg,
    setClearLogout,

    setVerifyReferralLoading,
    setVerifyReferralData,
    setVerifyReferralMessage,
    setClearVerifyReferral,

    setForgetPasswordLoading,
    setForgetPasswordMessage,
    resetForgetPassword,

    setAddVerifyUserLoading,
    setAddVerifyUserData,
    setAddVerifyUserMessage,
    resetAddVerifyUser,

    setAdminLoginAsData,
    setAdminLoginAsLoading,
    setAdminLoginAsMessage,
    resetAdminLoginAs
} = auth.actions

export default auth.reducer