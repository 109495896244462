import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearTxnDetailPayload, setTxnDetailPayload } from "redux/withdrawal/withdrawal.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

const WithdrawalActionButton = () => {
    const { modal } = useAppState((state) => state.local)
    const { withdrawalDetail, txnDetail } = useAppState((state) => state.withdrawal)

    const dispatcher = useAppDispatcher()

    const [isDisableApproval, setIsDisableApproval] = useState(false)

    useEffect(() => {
        if ((withdrawalDetail?.withdrawalDetail?.user?.netWalletCredit / 100) < (withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.requestedAmount / 100)) {
            setIsDisableApproval(true)
        }
    }, [withdrawalDetail?.withdrawalDetail?.user?.netWalletCredit / 100])

    const handleApproval = () => {
        if ((withdrawalDetail?.withdrawalDetail?.user?.netWalletCredit / 100) < (withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.requestedAmount / 100)) return;
        else {
            dispatcher(setModal({
                ...modal,
                [modalConst.SUBMIT_COMMENT_MODAL.stateKey]: true
            }))
            dispatcher(setClearTxnDetailPayload())
            dispatcher(setTxnDetailPayload({
                ...txnDetail?.txnDetailPayload,
                isApproved: true,
                isRejected: false
            }))
        }
    }

    const handleRejection = () => {
        dispatcher(setClearTxnDetailPayload())
        dispatcher(setTxnDetailPayload({
            ...txnDetail?.txnDetailPayload,
            isRejected: true,
            isApproved: false
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SUBMIT_COMMENT_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"flex flex-col justify-center items-center gap-5"}>
            {isDisableApproval &&
                <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                    {"Requested Amount Exceeds"}
                </span>
            }
            <div className="w-full flex items-center justify-center gap-8">
                <span
                    className={cn(
                        "w-28 py-2 flex justify-center items-center rounded-md",
                        "font-buttons font-normal text-sm text-text-50",
                        !isDisableApproval && "cursor-pointer bg-primary-main hover:bg-primary-dark",
                        isDisableApproval && "bg-secondary-light"
                    )}
                    onClick={handleApproval}
                >
                    {"Approve"}
                </span>
                <span
                    className={cn(
                        "w-28 py-2 flex justify-center items-center rounded-md cursor-pointer",
                        "font-buttons font-normal text-sm",
                        "bg-red-500 text-text-50 hover:bg-red-700",
                    )}
                    onClick={handleRejection}
                >
                    {"Reject"}
                </span>
            </div>
            <div className={"w-full flex flex-col justify-center items-center gap-3 md:flex-row lg:flex-row"}>
                <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                    {"Available Balance (if Approved):"}
                </span>
                <span className={"font-bodyPri font-medium text-text-900 text-lg whitespace-nowrap"}>
                    {"USD " + ((withdrawalDetail?.withdrawalDetail?.user?.netWalletCredit - withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.requestedAmount) / 100).toFixed(2)}
                </span>
            </div>
        </div>
    )
}

export default WithdrawalActionButton