import { baseApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/category/category.const"

class CategoryService {
    getAllCategoryList = async () => {
        const response = await baseApiInstance().get(
            url.GET_CATEGORY_LIST
        )
        return response
    }

}
export default new CategoryService();