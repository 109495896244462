import { dayjs, timeZone } from "utils/dateTime.utils";

export const url = {
    GET_AVAILABILITY_LIST: "availabilities/availabilities",
    CREATE_AVAILABILITY_DETAIL: "availabilities/availabilities",
    GET_AVAILABILITY_DETAIL: "availabilities/availabilities/{availabilityId}",
    UPDATE_AVAILABILITY_DETAIL: "availabilities/availabilities/{availabilityId}",
    DELETE_AVAILABILITY_DETAIL: "availabilities/availabilities/{availabilityId}",
    
    GET_AVAILABLE_SLOT_DETAIL: "/availabilities/availabilities/{availabilityId}/slots",
}

export const availabilityRedux = {
    sliceName: "availability",
    availabilityList: {
        requestName: "getAvailabilityList"
    },
    availabilityDetail: {
        requestName: "getAvailabilityDetail"
    },
    availableSlotDetail: {
        requestName: "getAvailableSlotDetail"
    }
}

export const availabilityTypeEnum = {
    ONE_ON_ONE: {
        label: "One on One",
        value: "one_on_one"
    },
    GROUP: {
        label: "Group",
        value: "group"
    }
}

export const availabilityIsRecurringEnum = {
    RECURRING: {
        value: "recurring",
        label: "Repeat"
    },
    NON_RECURRING: {
        value: "non_recurring",
        label: "Doesn't Repeat"
    },
}

export const availabilityEndTypeEnum = {
    FOREVER: {
        value: "forever",
        label: "Forever"
    },
    ON_DATE: {
        value: "on_date",
        label: "On Date"
    },
    WEEKLY_CYCLE: {
        value: "weekly_cycle",
        label: "Weekly Cycle"
    },
}

export const weekAvailTimeSlots = {
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: []
}

export const START_DATE_TIME = dayjs(new Date().setDate(new Date().getDate() + 1))?.format("YYYY-MM-DD HH:mm:ss")

export const recurringAvailabilityPayload = {
    userId: null,
    name: null,
    description: null,
    type: null,
    duration: 30,
    minTimeBeforeAvailStart: 720,
    advanceSlotPeriodDays: 30,
    isRecurring: null,
    timeZone: null,
    weeklyTimeSlots: weekAvailTimeSlots,
    startDateTime: START_DATE_TIME,
    endType: availabilityEndTypeEnum?.FOREVER?.value
}

export const nonRecurringAvailabilityPayload = {
    userId: null,
    name: null,
    description: null,
    type: null,
    duration: 30,
    isRecurring: null,
    timeZone: null,
    dateTimeSlots: null
}


export const addAvailabilityDetailPayload = {
    name: null,
    description: null,
    type: availabilityTypeEnum.ONE_ON_ONE.value,
    minTimeBeforeAvailStart: 720,
    advanceSlotPeriodDays: 30,
    isRecurring: availabilityIsRecurringEnum.RECURRING.value,
    timeZone: timeZone,
    weeklyTimeSlots: weekAvailTimeSlots,
    dateTimeSlots: [],
    startDateTime: dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss"),
    endType: availabilityEndTypeEnum?.FOREVER?.value,
    weeklyCycle: null,
    endDateTime: null
}

export const modifyAvailabilityDetailPayload = {
    userId: null,
    name: null,
    description: null,
    type: null,
    minTimeBeforeAvailStart: 720,
    advanceSlotPeriodDays: 30,
    isRecurring: null,
    timeZone: null,
    weeklyTimeSlots: weekAvailTimeSlots,
    startDateTime: dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss"),
    endType: availabilityEndTypeEnum?.FOREVER?.value,
    weeklyCycle: null,
    endDateTime: null
}

export const DEFAULT_AVAILABILITY_LIST_PAGE = 1;
export const DEFAULT_AVAILABILITY_LIST_RECORDS = 5;

export const availabilityFilterProps = {
    searchText: "",
    id: "",
    name: "",
    isRecurring: "",
    minCreatedAt: "",
    maxCreatedAt: "",
    timeZone: "",
    page: DEFAULT_AVAILABILITY_LIST_PAGE,
    records: DEFAULT_AVAILABILITY_LIST_RECORDS
}