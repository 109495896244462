import { useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getCompanyOnboardingFilteredList } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { dateRangesLabels } from "redux/local/local.const";
import { setClearFilterProps, setFilterProps } from "redux/onboarding/onboarding.slice";
import { filterName, companyOnboardingStatus } from "redux/company/company.const";

import { dayjs } from "utils/dateTime.utils";

export const SearchCompanyId = () => {
    const { companyOnboardingList, filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"userId"}
            value={filterProps?.companyId}
            placeholder={"Search User ID"}
            className={cn(
                "w-full px-2 py-1 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, companyId: e.target.value },
                list: companyOnboardingList?.companyOnboardingList,
                filterName: filterName.COMPANY_ONBOARDING.value
            }))}
        />
    )
}

export const SearchCompanyName = () => {
    const { companyOnboardingList, filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"Search Name"}
            value={filterProps.companyName}
            placeholder={"Search Name"}
            className={cn(
                "w-full px-2 py-1 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, companyName: e.target.value },
                list: companyOnboardingList?.companyOnboardingList,
                filterName: filterName.ALL_USERS.value
            }))}
        />
    )
}

export const SearchMobileNo = () => {
    const { companyOnboardingList, filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            placeholder={"Search Phone"}
            value={filterProps?.mobileNo}
            className={cn(
                "w-full px-2 py-1 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, mobileNo: e.target.value },
                list: companyOnboardingList?.companyOnboardingList,
                filterName: filterName.COMPANY_ONBOARDING.value
            }))}
        />
    )
}

export const SearchCompanyEmail = () => {
    const { companyOnboardingList, filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            placeholder={"Search Email"}
            value={filterProps?.email}
            className={cn(
                "w-full px-2 py-1 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, email: e.target.value },
                list: companyOnboardingList?.companyOnboardingList,
                filterName: filterName.COMPANY_ONBOARDING.value
            }))}
        />
    )
}

export const FilterCompanyOnboardingStatus = () => {
    const { companyOnboardingList, filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(companyOnboardingStatus)}
            value={filterProps.companyOnboardingStatus}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, companyOnboardingStatus: option.value },
                list: companyOnboardingList?.companyOnboardingList,
                filterName: filterName.ALL_USERS.value
            }))}
        />
    )
}

export const SearchApplyDate = () => {
    const { filterProps } = useAppState((state) => state.course)
    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "px-1 py-1 flex items-center justify-center gap-1 bg-white",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${selectedDateRange?.startDate}-${selectedDateRange?.endDate}`}
            />
        </DateRangePicker>
    )
}

export const CompanyOnboardingButtonAction = () => {
    const { filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    const handleApply = () => {
        dispatcher(getCompanyOnboardingFilteredList(filterProps))
    }

    const handleClear = () => {
        dispatcher(setClearFilterProps())
    }

    return (
        <div className="w-full flex items-center justify-center gap-1">
            <span
                className={cn(
                    "px-2 py-1 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-primary-main text-text-50 hover:bg-primary-dark",
                )}
                onClick={handleApply}
            >
                {"Apply"}
            </span>
            <span
                className={cn(
                    "px-2 py-1 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-background-lightDark text-text-50 hover:bg-background-dark",
                )}
                onClick={handleClear}
            >
                {"Clear"}
            </span>
        </div>
    )
}