import {  lmsCourseFilterProps, modifyLmsCourseDetailPayload } from "./lmsCourse.const";

export const LMS_COURSE_INITIAL_STATE = {
    lmsCourseList: {
        isLoading: false,
        data: null,
        message: null,
    },
    lmsCourseDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    modifyLmsCourseDetail: {
        isLoading: false,
        payload: modifyLmsCourseDetailPayload,
        message: null
    },
    destroyLmsCourseDetail: {
        isLoading: false,
        message: null
    },

    filterProps: lmsCourseFilterProps
}