import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/payment/payment.const";


class PaymentService {

    addMakePayment = async ({ body }) => {
        const response = await baseApiInstance().post(
            url.MAKE_PAYMENT,
            body
        )

        return response
    }

    manualPaymentIntentStripe = async ({body}) => {
        const response = await baseAuthApiInstance().post(
            url.MANUAL_PAYMENT_STRIPE_INTENT,
            body
        )

        return response;
    }
}

export default new PaymentService()