import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import Table from 'components/table/Table';
import Pagination from 'components/pagination/Pagination';

import { lmsLectureHeaderConst, sortLectureHighToLow, sortLectureLowToHigh, subRowHeader } from 'pages/auth/edulyteLms/lmsVideoLecture/lmsVideoLecture.data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { deleteLmsLectureDetail, getLmsLectureList } from 'redux/edulyteLms/lmsLecture/lmsLecture.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { resetFilterProps, resetLmsLectureList } from 'redux/edulyteLms/lmsLecture/lmsLecture.slice';
import { lmsLectureStatusEnum, lmsLectureVisibilityEnum } from 'redux/edulyteLms/lmsLecture/lmsLecture.const';

import { timeZone, dayjs } from 'utils/dateTime.utils';
import { edulyteAppPagesInfo, pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

let CURRENT_LECTURE_ID = null;

const OwnerTutorContainer = ({ lecture }) => {
    return lecture?.lecture_setting?.default_owner?.tutor ? (
        <div className={"flex items-center justify-start gap-0.5"}>
            <Link to={`${pagesInfo?.VIEW_TUTOR?.pagePath}/${lecture?.lecture_setting?.default_owner?.tutor?.tutorId}`}
                className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
            >
                {lecture?.lecture_setting?.default_owner?.tutor?.tutorId + ". "}
            </Link>
            <div className={""}>
                {`${lecture?.lecture_setting?.default_owner?.tutor?.user?.first_name} ${lecture?.lecture_setting?.default_owner?.tutor?.user?.last_name?.charAt(0)}.` || "-"}
            </div>
        </div>
    ) : "-"
}
const DisplayLmsVideoLecture = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { lmsLectureList, destroyLmsLectureDetail, filterProps } = useAppState((state) => state.lms.lmsLecture)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        return () => {
            dispatcher(resetLmsLectureList())
            dispatcher(resetFilterProps())
            dispatcher(resetAdminLoginAs())
        }
    }, [])

    useEffect(() => {
        const query = {
            page: filterProps?.page || 1,
            records: Number(filterProps?.records) || 25
        }
        dispatcher(getLmsLectureList(query))

    }, [filterProps?.records])

    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo?.EDIT_LMS_LECTURE?.pagePath}/${CURRENT_LECTURE_ID}/edit`, "_self")
        }
    }, [adminLoginAs?.data])

    const onHandleEditLecture = (lectureId, userId) => {
        CURRENT_LECTURE_ID = lectureId
        dispatcher(createAdminLoginAsDetail({ user_id: userId, source_url: window.location.href }))
    }

    const onHandleDeleteLecture = (lectureId) => {
        if (destroyLmsLectureDetail?.isLoading) return;

        dispatcher(deleteLmsLectureDetail(lectureId))
    }

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortLectureList = (lecture1, lecture2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortLectureLowToHigh(activeSortHeader, lecture1, lecture2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortLectureHighToLow(activeSortHeader, lecture1, lecture2)
        }
    }

    const tableRowsConst = lmsLectureList?.data?.results?.slice(0)?.sort(sortLectureList)?.map((lecture) => ([
        lecture?.lecture_id ? lecture?.lecture_id : "-",
        lecture?.title
            ? (lecture?.title?.length > 30)
                ? lecture?.title?.slice(0, 30) + "...."
                : lecture?.title
            : "-",
        <OwnerTutorContainer lecture={lecture} />,
        lecture?.lecture_setting?.status
            ? lmsLectureStatusEnum[lecture?.lecture_setting?.status?.toUpperCase()]?.label
            : "-",
        lecture?.lecture_setting?.visibility
            ? lmsLectureVisibilityEnum[lecture?.lecture_setting?.visibility?.toUpperCase()]?.label
            : "-",
        dayjs(lecture?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",

        <div className={"flex items-center w-full"}>
            <button
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
                onClick={() => onHandleEditLecture(lecture?.lecture_id, lecture?.lecture_setting?.default_owner?.user)}
                title={"Edit lecture"}
            >
                <FiEdit size={20} className={"text-xl hover:scale-110"} />
            </button>
            <button
                className={"px-2 py-1 bg-red-500 text-white rounded-r-md"}
                onClick={() => onHandleDeleteLecture(lecture?.lecture_id)}
                title={"Delete lecture"}
            >
                <AiOutlineDelete size={20} className={"text-xl hover:scale-110"} />
            </button>
        </div>,
    ]))

    return (
        <div className={'w-full flex flex-col gap-5'}>
            <Table
                headers={Object.values(lmsLectureHeaderConst)}
                subRowHeader={subRowHeader}
                rows={tableRowsConst}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={lmsLectureList?.isLoading}
                message={(lmsLectureList?.message || (lmsLectureList?.data?.records === 0)) && (lmsLectureList?.message || "No lectures found")}
            />
            {(!lmsLectureList?.isLoading && (lmsLectureList?.data?.totalPages > 1)) &&
                <div className={'w-full flex items-center justify-center'}>
                    <Pagination
                        page={lmsLectureList?.data?.page}
                        totalPages={lmsLectureList?.data?.totalPages}
                        onChangePage={(page) => dispatcher(getLmsLectureList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </div>
    )
}

export default DisplayLmsVideoLecture;