import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader";

import { searchParamsInfo } from "pages/auth/sessions/data";
import SessionBasicDetail from "pages/auth/sessions/viewDetail/components/SessionBasicDetail";
import SessionDetailCountDown from "pages/auth/sessions/viewDetail/components/SessionDetailCountDown";
import SessionDefaultLocation from "pages/auth/sessions/viewDetail/components/SessionDefaultLocation";
import SessionDetailStatus from "pages/auth/sessions/viewDetail/components/SessionDetailStatus";
import SessionDetailLocation from "pages/auth/sessions/viewDetail/components/SessionDetailLocation";
import SessionDetailParticipants from "pages/auth/sessions/viewDetail/components/SessionDetailParticipants";
// import SessionDetailBookings from "pages/auth/sessions/viewDetail/components/SessionDetailBookings";
import SessionDetailCourse from "pages/auth/sessions/viewDetail/components/SessionDetailCourse";
import SessionDetailInterview from "pages/auth/sessions/viewDetail/components/SessionDetailInterview";
import SessionDetailEventLogs from "pages/auth/sessions/viewDetail/components/SessionDetailEventLogs";

import { getSessionDetail } from 'redux/session/session.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetSessionDetail } from "redux/session/session.slice";

const ViewSessionSidePanelDetail = () => {
    const { sessionDetail } = useAppState((s) => s.session)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (!!searchQueryParams?.get(searchParamsInfo?.sessionId?.key)) {
            const requestDataPayload = {
                sessionId: searchQueryParams?.get(searchParamsInfo?.sessionId?.key)
            }
            dispatcher(getSessionDetail(requestDataPayload))
        }

        return () => {
            dispatcher(resetSessionDetail())
        }
    }, [searchQueryParams?.get(searchParamsInfo?.sessionId?.key)])

    return (
        <div className={"p-5 h-screen sm:px-10 max-w-2xl md:max-w-xl lg:max-w-lg"}>
            <ComponentLoader isLoading={sessionDetail?.isLoading} className={"w-full"} />
            {(!sessionDetail?.isLoading && sessionDetail?.message) &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wde"}>
                        {sessionDetail?.message}
                    </span>
                </div>
            }
            {(!sessionDetail?.isLoading && !!sessionDetail?.data?.result) && (
                <div className={"w-full md:w-[25rem] mx-auto space-y-5 pb-5"}>
                    <SessionBasicDetail />

                    <SessionDetailCountDown />

                    {!!sessionDetail?.data?.result?.session_locations?.length &&
                        <SessionDefaultLocation sessionLocationInfo={sessionDetail?.data?.result?.session_locations[0]} />
                    }
                    
                    <SessionDetailStatus />

                    {sessionDetail?.data?.result && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    <SessionDetailLocation />

                    {(sessionDetail?.data?.result && !!sessionDetail?.data?.result?.session_users) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    <SessionDetailParticipants />

                    {(sessionDetail?.data?.result && !!sessionDetail?.data?.result?.session_course) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    {/* <SessionDetailBookings /> */}
                    <SessionDetailCourse />

                    {(sessionDetail?.data?.result && !!sessionDetail?.data?.result?.session_interview) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    <SessionDetailInterview />

                    {(sessionDetail?.data?.result && !!sessionDetail?.data?.result?.session_logs) && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                    <SessionDetailEventLogs />
                </div>
            )}
        </div>
    );
}

export default ViewSessionSidePanelDetail;