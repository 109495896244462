import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import LeadService from "redux/lead/lead.service";

import {
    setAddLeadDetailLoading,
    setAddLeadDetail,
    setAddLeadDetailErrorMsg
} from "redux/lead/lead.slice";
import { leadRedux } from "redux/lead/lead.const";


export const getLeadList = createAsyncThunk(
    `${leadRedux?.sliceName}/${leadRedux?.leadList?.requestName}`,
    async (query, { rejectWithValue }) => {

        try {
            const requestData = {
                query: { ...query }
            }
            const response = await LeadService.getLeadList(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const getLeadDetailByLeadId = createAsyncThunk(
    `${leadRedux?.sliceName}/${leadRedux?.leadDetail?.requestName}`,
    async (leadId, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { leadId: leadId }
            }
            const response = await LeadService.getLeadDetailByLeadId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const updateLeadDetailByLeadId = (leadId, body) => async (dispatch) => {
    dispatch(setAddLeadDetailLoading(true))

    try {
        const requestData = {
            params: { leadId: leadId },
            body: body
        }
        const response = await LeadService.updateLeadDetailByLeadId(requestData)
        if (response.status === 200) {
            dispatch(setAddLeadDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddLeadDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddLeadDetailLoading(false))
    }
}

export const deleteLeadDetailByLeadId = createAsyncThunk(
    `${leadRedux?.sliceName}/${leadRedux?.leadList?.requestName}`,
    async (leadId, { rejectWithValue }) => {

        try {
            const requestData = {
                params: { leadId: leadId }
            }
            const response = await LeadService.deleteLeadDetailByLeadId(requestData)
            if (response.status === 200) {
                toast.success(response.data.message)
                return rejectWithValue(response.data.data)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)