import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
import { OptionSelector } from 'components/common-components/Select';

import { createCreditToWallet } from 'redux/wallet/wallet.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddCreditPayload } from 'redux/wallet/wallet.slice';
import { modalConst } from 'redux/local/local.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { gatewayNameConst } from 'redux/withdrawal/withdrawal.const';

const AddUserWalletCreditModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { addCreditToWallet } = useAppState((state) => state.wallet)

    const dispatcher = useAppDispatcher()

    const totalCreditedAmount = addCreditToWallet?.payload?.requiredAmount + addCreditToWallet?.payload?.processingFee

    const isFormValid = addCreditToWallet?.payload?.gatewayName && addCreditToWallet?.payload?.requiredAmount && addCreditToWallet?.payload?.processingFee && totalCreditedAmount && addCreditToWallet?.payload?.gatewayPaymentId

    useEffect(() => {
        if (addCreditToWallet?.data) {
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_USER_WALLET_CREDIT_MODAL.stateKey]: false,
                [modalConst.WALLET_CREDIT_SUCCESS_MODAL.stateKey]: true
            }))
        }
    }, [addCreditToWallet?.data])


    const onHandleSubmit = (event) => {
        event.preventDefault()

        if (addCreditToWallet?.isLoading || !isFormValid) return;

        const requestDataPayload = {
            userId: addCreditToWallet?.payload?.userDetail?.userId,
            body: {
                gatewayName: addCreditToWallet?.payload?.gatewayName,
                currencyCode: addCreditToWallet?.payload?.currencyCode,
                requiredAmount: addCreditToWallet?.payload?.requiredAmount,
                processingFee: addCreditToWallet?.payload?.processingFee,
                amount: totalCreditedAmount,
                gatewayPaymentId: addCreditToWallet?.payload?.gatewayPaymentId,
            }
        }
        dispatcher(createCreditToWallet(requestDataPayload))
    }

    return (
        <form className={"space-y-5 overflow-x-hidden"} onSubmit={onHandleSubmit}>
            <div className={"min-h-[32rem] block w-full border border-text-400 space-y-3"}>
                <div className={"space-y-1"}>
                    <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                        {"User Detail"}
                    </div>
                    <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
                        {"(Marked with * is mandatory field)"}
                    </span>
                </div>

                <div className="grid grid-cols-2 gap-5 px-2">

                    <div className={"col-span-full border border-divider-medium p-3 rounded-lg shadow"}>
                        <div className={"flex items-center justify-start gap-3"}>
                            <div className={"w-14 h-14 rounded-full overflow-hidden"}>
                                <img
                                    src={addCreditToWallet?.payload?.userDetail?.profilePicUrl}
                                    className={"w-full h--full object-cover"}
                                    alt={"profileImg"}
                                />
                            </div>
                            <div className={"flex flex-col items-start justify-start gap-2"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                    {`${addCreditToWallet?.payload?.userDetail?.firstName} ${addCreditToWallet?.payload?.userDetail?.lastName?.charAt(0)}.`}
                                </span>
                                <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide italic"}>
                                    {`${addCreditToWallet?.payload?.userDetail?.email}`}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* course id container */}
                    <div className={'col-start-1 col-span-full sm:col-start-1 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Gateway Name"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            placeholder={"e.g. stripe"}
                            value={gatewayNameConst[addCreditToWallet?.payload?.gatewayName?.toUpperCase()]?.label}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            disabled={true}
                        />
                    </div>

                    {/* currency code container */}
                    <div className={'col-start-1 col-span-full sm:col-start-2 sm:col-span-1'}>
                        <span className={'font-bodyPri font-normal text-text-900 text-sm'}>
                            {"Currency code"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(masterCurrencyCodeEnum)}
                            className={"w-full"}
                            value={addCreditToWallet?.payload?.currencyCode}
                            onChange={(option) => dispatcher(setAddCreditPayload({
                                ...addCreditToWallet?.payload,
                                currencyCode: option?.value
                            }))}
                        />
                    </div>

                    {/* required amount */}
                    <div className={'col-start-1 col-span-full sm:col-start-1 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Required Amount"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <div className={"w-full px-3 py-1 flex items-center justify-start gap-2 border-2 border-divider-medium rounded-md focus:outline-none"}>
                            {addCreditToWallet?.payload?.currencyCode &&
                                <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                    {masterCurrencyCodeEnum[addCreditToWallet?.payload?.currencyCode?.toUpperCase()]?.label}
                                </span>
                            }
                            <input
                                type={"number"}
                                placeholder={"e.g. 123"}
                                value={(addCreditToWallet?.payload?.requiredAmount / 100)?.toString()}
                                className={cn(
                                    "w-full px-1.5 input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                onChange={(event) => dispatcher(setAddCreditPayload({
                                    ...addCreditToWallet?.payload,
                                    requiredAmount: Number(event.target.value) * 100
                                }))}
                                required={true}
                            />
                        </div>
                    </div>

                    {/* processing fee */}
                    <div className={'col-start-1 col-span-full sm:col-start-2 sm:col-span-1'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Processing Fee"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <div className={"w-full px-3 py-1 flex items-center justify-start gap-2 border-2 border-divider-medium rounded-md focus:outline-none"}>
                            {addCreditToWallet?.payload?.currencyCode &&
                                <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                    {masterCurrencyCodeEnum[addCreditToWallet?.payload?.currencyCode?.toUpperCase()]?.label}
                                </span>
                            }
                            <input
                                type={"number"}
                                placeholder={"e.g. 123"}
                                value={(addCreditToWallet?.payload?.processingFee / 100)?.toString()}
                                maxLength={3}
                                className={cn(
                                    "w-full px-1.5 input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                onChange={(event) => {
                                    const safe_number = Number(event.target.value)
                                    dispatcher(setAddCreditPayload({
                                        ...addCreditToWallet?.payload,
                                        processingFee: Number(safe_number) * 100
                                    }))
                                }}
                                required={true}
                            />
                        </div>
                    </div>

                    {/* amount container */}
                    <div className={'col-start-1 col-span-full'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Amount"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <div className={"w-full px-3 py-1 flex items-center justify-start gap-2 border-2 border-divider-medium rounded-md focus:outline-none"}>
                            {addCreditToWallet?.payload?.currencyCode &&
                                <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                    {masterCurrencyCodeEnum[addCreditToWallet?.payload?.currencyCode?.toUpperCase()]?.label}
                                </span>
                            }
                            <input
                                type={"number"}
                                placeholder={"e.g. 123"}
                                value={totalCreditedAmount ? (totalCreditedAmount / 100)?.toString() : 0}
                                className={cn(
                                    "w-full px-1.5 input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                required={true}
                                disabled={true}
                            />
                        </div>
                    </div>

                    {/* payment intent id */}
                    <div className={'col-start-1 col-span-full'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Gateway Payment ID"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            placeholder={"e.g. pi_manual_01"}
                            value={addCreditToWallet?.payload?.gatewayPaymentId}
                            className={cn(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            onChange={(event) => dispatcher(setAddCreditPayload({
                                ...addCreditToWallet?.payload,
                                gatewayPaymentId: event.target.value
                            }))}
                            required={true}
                        />
                    </div>
                </div>
            </div>
            <div className={"flex items-center justify-between gap-3"}>
                <div className={""}>
                    {addCreditToWallet?.message &&
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {addCreditToWallet?.message}
                        </span>
                    }
                </div>
                <button
                    type={"submit"}
                    disabled={!isFormValid}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        !isFormValid && "bg-text-300 text-white cursor-not-allowed",
                        (!addCreditToWallet?.isLoading && isFormValid) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        (addCreditToWallet?.isLoading && isFormValid) && "bg-secondary-main"
                    )}>
                    {addCreditToWallet?.isLoading
                        && <ButtonLoader isLoading={addCreditToWallet?.isLoading} />
                    }
                    {!addCreditToWallet?.isLoading && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default AddUserWalletCreditModal