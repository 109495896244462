import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    /* lecture list */
    GET_LMS_LECTURE_LIST: "lms/lectures/lectures",
    DELETE_LMS_LECTURE_DETAIL: "lms/lectures/lectures/{lectureId}"
}

export const lmsLectureRedux = {
    sliceName: "lmsLecture",
    getLmsLectureList: {
        requestName: "getLmsLectureList"
    },
}

export const lmsLectureStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const lmsLectureVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsLectureInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }

}

export const DEFAULT_LMS_LECTURE_PAGE = 1

export const DEFAULT_LMS_LECTURE_RECORDS = dataEntriesConst.TWENTY_FIVE.value

export const lmsLectureFilterProps = {
    page: DEFAULT_LMS_LECTURE_PAGE,
    records: DEFAULT_LMS_LECTURE_RECORDS,
    searchText: "",
    sortBy: "",
    lectureId: "",
    lectureTitle: "",
    lectureStatus: "",
    ownerTutor: "",
    date: ""
}