import { useState } from "react";
import { cn } from "utils/cn.utils";

import { IoIosArrowForward } from "react-icons/io";

import { useAppState } from "hooks/useStore";

const SessionDetailInterview = () => {
    const { sessionDetail } = useAppState((state) => state.session)

    const [isShow, setIsShow] = useState(false)

    return !!sessionDetail?.data?.result?.session_interview && (
        <div className={"space-y-3"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Interview"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col items-start justify-start gap-3 transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>

                {/* interview id container */}
                <div className={"flex items-center justify-start gap-3"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Interview ID:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_interview?.id || "-"}
                    </span>
                </div>

                {/* interview title container */}
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"interview Title:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_interview?.title || "-"}
                    </span>
                </div>

                {/* interview description container */}
                <div className={"flex items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Interview Description:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_interview?.description || "-"}
                    </span>
                </div>

                {/* duration container */}
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Duration:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_interview?.duration || "-"}
                    </span>
                </div>

                {/* slot period days container */}
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Slot Period Days:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {sessionDetail?.data?.result?.session_interview?.slotPeriodDays || "-"}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default SessionDetailInterview;