export const url = {
    GET_CATEGORY_LIST: "/categories"
}

export const categoryRedux = {
    sliceName: "category",
    requestName: "getAllCategoryList"
}

export const categoryFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    courseType: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: ""
}

export const filterName = {
    CATEGORY: {
        value: "category"
    }
}