import { createSlice } from "@reduxjs/toolkit";

import { TUTOR_INITIAL_STATE } from "redux/tutor/tutor.initialState";

import { getTutorList } from "./tutor.request";

const tutor = createSlice({
    name: "tutor",
    initialState: TUTOR_INITIAL_STATE,
    reducers: {
        // reducers for tutor list
        setClearTutorList: (state, { payload }) => {
            state.tutorList = TUTOR_INITIAL_STATE.tutorList
        },

        // reducers for tutor profile
        setTutorUserPublicProfileLoading: (state, { payload }) => {
            state.tutorUserProfile.isLoading = payload
        },
        setTutorUserPublicProfile: (state, { payload }) => {
            state.tutorUserProfile.tutorUserProfile = payload
            state.tutorUserProfile.errorMsg = TUTOR_INITIAL_STATE.tutorUserProfile.errorMsg
        },
        setTutorUserPublicProfileErrorMsg: (state, { payload }) => {
            state.tutorUserProfile.errorMsg = payload
            state.tutorUserProfile.tutorUserProfile = TUTOR_INITIAL_STATE.tutorUserProfile.tutorUserProfile
        },
        setClearTutorUserProfile: (state) => {
            state.tutorUserProfile = TUTOR_INITIAL_STATE.tutorUserProfile
        },

        // reducers for tutor user detail
        setTutorDetailLoading: (state, { payload }) => {
            state.tutorDetail.isLoading = payload
        },
        setTutorDetailData: (state, { payload }) => {
            state.tutorDetail.data = payload
            state.tutorDetail.message = TUTOR_INITIAL_STATE.tutorDetail.message
        },
        setTutorDetailMessage: (state, { payload }) => {
            state.tutorDetail.message = payload
            state.tutorDetail.data = TUTOR_INITIAL_STATE.tutorDetail.data
        },
        resetTutorDetail: (state) => {
            state.tutorDetail = TUTOR_INITIAL_STATE.tutorDetail
        },

        // reducers for update tutor user detail
        setUpdateTutorDetailLoading: (state, { payload }) => {
            state.updateTutorDetail.isLoading = payload
        },
        setUpdateTutorDetailData: (state, { payload }) => {
            state.updateTutorDetail.data = payload
            state.updateTutorDetail.message = TUTOR_INITIAL_STATE.updateTutorDetail.message
        },
        setUpdateTutorDetailMessage: (state, { payload }) => {
            state.updateTutorDetail.message = payload
        },
        resetUpdateTutorDetail: (state) => {
            state.updateTutorDetail = TUTOR_INITIAL_STATE.updateTutorDetail
        },

        // reducers for upload document type
        setProfilePageDocumentPayload: (state, { payload }) => {
            state.profilePageDocumentUpload.profilePageDocumentUploadPayload = payload
        },
        setClearProfilePageDocumentPayload: (state) => {
            state.profilePageDocumentUpload.profilePageDocumentUploadPayload = TUTOR_INITIAL_STATE.profilePageDocumentUpload.profilePageDocumentUploadPayload
        },

        // reducers for tutor user price
        setTutorUserPriceLoading: (state, { payload }) => {
            state.tutorUserPrice.isLoading = payload
        },
        setTutorUserPrice: (state, { payload }) => {
            state.tutorUserPrice.tutorUserPrice = payload
            state.tutorUserPrice.errorMsg = TUTOR_INITIAL_STATE.tutorUserPrice.errorMsg
        },
        setTutorUserPriceErrorMsg: (state, { payload }) => {
            state.tutorUserPrice.errorMsg = payload
            state.tutorUserPrice.tutorUserPrice = TUTOR_INITIAL_STATE.tutorUserPrice.tutorUserPrice
        },
        setClearTutorUserPrice: (state) => {
            state.tutorUserPrice = TUTOR_INITIAL_STATE.tutorUserPrice
        },

        // reducers for update tutor user price
        setAddTutorUserPriceLoading: (state, { payload }) => {
            state.addTutorUserPrice.isLoading = payload
        },
        setAddTutorUserPriceErrorMsg: (state, { payload }) => {
            state.addTutorUserPrice.errorMsg = payload
        },
        setClearAddTutorUserPrice: (state) => {
            state.addTutorUserPrice = TUTOR_INITIAL_STATE.addTutorUserPrice
        },

        // reducers for tutor user lesson
        setTutorUserLessonLoading: (state, { payload }) => {
            state.tutorUserLesson.isLoading = payload
        },
        setTutorUserLesson: (state, { payload }) => {
            state.tutorUserLesson.tutorUserLesson = payload
            state.tutorUserLesson.errorMsg = TUTOR_INITIAL_STATE.tutorUserLesson.errorMsg
        },
        setTutorUserLessonErrorMsg: (state, { payload }) => {
            state.tutorUserLesson.errorMsg = payload
            state.tutorUserLesson.tutorUserLesson = TUTOR_INITIAL_STATE.tutorUserLesson.tutorUserLesson
        },
        setClearTutorUserLesson: (state) => {
            state.tutorUserLesson = TUTOR_INITIAL_STATE.tutorUserLesson
        },

        // reducers for update tutor user lesson
        setAddTutorUserLessonLoading: (state, { payload }) => {
            state.addTutorUserLesson.isLoading = payload
        },
        setAddTutorUserLessonErrorMsg: (state, { payload }) => {
            state.addTutorUserLesson.errorMsg = payload
        },
        setClearAddTutorUserLesson: (state) => {
            state.addTutorUserLesson = TUTOR_INITIAL_STATE.addTutorUserLesson
        },

        // reducers for new modified tutor user detail apis


        // new tutor language apis
        setTutorLanguageListLoading: (state, { payload }) => {
            state.tutorLanguageList.isLoading = payload
        },
        setTutorLanguageListData: (state, { payload }) => {
            state.tutorLanguageList.data = payload
            state.tutorLanguageList.message = TUTOR_INITIAL_STATE.message
        },
        setTutorLanguageListMessage: (state, { payload }) => {
            state.tutorLanguageList.message = payload
        },
        resetTutorLanguageList: (state) => {
            state.tutorLanguageList = TUTOR_INITIAL_STATE.tutorLanguageList
        },

        // add tutor language detail
        setAddTutorLanguageDetailLoading: (state, { payload }) => {
            state.addTutorLanguageDetail.isLoading = payload
        },
        setAddTutorLanguageDetailMessage: (state, { payload }) => {
            state.addTutorLanguageDetail.message = payload
        },
        resetAddTutorLanguageDetail: (state) => {
            state.addTutorLanguageDetail = TUTOR_INITIAL_STATE.addTutorLanguageDetail
        },

        // update tutor category detail
        setUpdateTutorLanguageDetailLoading: (state, { payload }) => {
            state.updateTutorLanguage.isLoading = payload
        },
        setUpdateTutorLanguageDetailMessage: (state, { payload }) => {
            state.updateTutorLanguage.message = payload
        },
        resetUpdateTutorLanguageDetail: (state) => {
            state.updateTutorLanguage = TUTOR_INITIAL_STATE.updateTutorLanguage
        },

        // update tutor category list order
        setUpdateTutorLanguageListOrderLoading: (state, { payload }) => {
            state.updateTutorLanguageOrder.isLoading = payload
        },
        setUpdateTutorLanguageListOrderMessage: (state, { payload }) => {
            state.updateTutorLanguageOrder.message = payload
        },
        resetUpdateTutorLanguageListOrder: (state) => {
            state.updateTutorLanguageOrder = TUTOR_INITIAL_STATE.updateTutorLanguageOrder
        },

        // delete tutor category detail
        setDeleteTutorLanguageDetailLoading: (state, { payload }) => {
            state.deleteTutorLanguage.isLoading = payload
        },
        setDeleteTutorLanguageDetailMessage: (state, { payload }) => {
            state.deleteTutorLanguage.message = payload
        },
        resetDeleteTutorLanguageDetail: (state) => {
            state.deleteTutorLanguage = TUTOR_INITIAL_STATE.deleteTutorLanguage
        },

        // new tutor modified apis
        // reducers for new tutor tag apis
        setTutorTagListLoading: (state, { payload }) => {
            state.tutorTagList.isLoading = payload
        },
        setTutorTagListData: (state, { payload }) => {
            state.tutorTagList.data = payload
            state.tutorTagList.message = TUTOR_INITIAL_STATE.tutorTagList.message
        },
        setTutorTagListMessage: (state, { payload }) => {
            state.tutorTagList.message = payload
            state.tutorTagList.data = TUTOR_INITIAL_STATE.tutorTagList.data
        },
        resetTutorTagList: (state) => {
            state.tutorTagList = TUTOR_INITIAL_STATE.tutorTagList
        },

        // reducers for add tutor tag
        setAddTutorTagDetailLoading: (state, { payload }) => {
            state.addTutorTagDetail.isLoading = payload
        },
        setAddTutorTagDetailMessage: (state, { payload }) => {
            state.addTutorTagDetail.message = payload
        },
        resetAddTutorTagDetail: (state) => {
            state.addTutorTagDetail = TUTOR_INITIAL_STATE.addTutorTagDetail
        },

        // reducers for update tutor tag
        setUpdateTutorTagListOrderLoading: (state, { payload }) => {
            state.updateTutorTagOrder.isLoading = payload
        },
        setUpdateTutorTagListOrderMessage: (state, { payload }) => {
            state.updateTutorTagOrder.message = payload
        },
        resetUpdateTutorTagListOrder: (state) => {
            state.updateTutorTagOrder = TUTOR_INITIAL_STATE.updateTutorTagOrder
        },

        // reducers for delete tutor tag
        setDeleteTutorTagDetailLoading: (state, { payload }) => {
            state.deleteTutorTag.isLoading = payload
        },
        setDeleteTutorTagDetailMessage: (state, { payload }) => {
            state.deleteTutorTag.message = payload
        },
        resetDeleteTutorTagDetail: (state) => {
            state.deleteTutorTag = TUTOR_INITIAL_STATE.deleteTutorTag
        },

        // new tutor category apis
        setTutorCategoryListLoading: (state, { payload }) => {
            state.tutorCategoryList.isLoading = payload
        },
        setTutorCategoryListData: (state, { payload }) => {
            state.tutorCategoryList.data = payload
            state.tutorCategoryList.message = TUTOR_INITIAL_STATE.message
        },
        setTutorCategoryListMessage: (state, { payload }) => {
            state.tutorCategoryList.message = payload
        },
        resetTutorCategoryList: (state) => {
            state.tutorCategoryList = TUTOR_INITIAL_STATE.tutorCategoryList
        },

        // add tutor category detail
        setAddTutorCategoryDetailLoading: (state, {payload}) => {
            state.addTutorCategoryDetail.isLoading = payload
        },
        setAddTutorCategoryDetailMessage: (state, { payload }) => {
            state.addTutorCategoryDetail.message = payload
        },
        resetAddTutorCategoryDetail: (state) => {
            state.addTutorCategoryDetail = TUTOR_INITIAL_STATE.addTutorCategoryDetail
        },

        // update tutor category detail
        setUpdateTutorCategoryDetailLoading: (state, { payload }) => {
            state.updateTutorCategory.isLoading = payload
        },
        setUpdateTutorCategoryDetailMessage: (state, { payload }) => {
            state.updateTutorCategory.message = payload
        },
        resetUpdateTutorCategoryDetail: (state) => {
            state.updateTutorCategory = TUTOR_INITIAL_STATE.updateTutorCategory
        },

        // update tutor category list order
        setUpdateTutorCategoryListOrderLoading: (state, { payload }) => {
            state.updateTutorCategoryOrder.isLoading = payload
        },
        setUpdateTutorCategoryListOrderMessage: (state, { payload }) => {
            state.updateTutorCategoryOrder.message = payload
        },
        resetUpdateTutorCategoryListOrder: (state) => {
            state.updateTutorCategoryOrder = TUTOR_INITIAL_STATE.updateTutorCategoryOrder
        },

        // delete tutor category detail
        setDeleteTutorCategoryDetailLoading: (state, { payload }) => {
            state.deleteTutorCategory.isLoading = payload
        },
        setDeleteTutorCategoryDetailMessage: (state, { payload }) => {
            state.deleteTutorCategory.message = payload
        },
        resetDeleteTutorCategoryDetail: (state) => {
            state.deleteTutorCategory = TUTOR_INITIAL_STATE.deleteTutorCategory
        },

        // reducers for tutor config detail
        setTutorConfigDetailLoading: (state, { payload }) => {
            state.tutorConfigDetail.isLoading = payload
        },
        setTutorConfigDetailData: (state, {payload}) => {
            state.tutorConfigDetail.data = payload
            state.tutorConfigDetail.message = TUTOR_INITIAL_STATE.tutorConfigDetail.message
        },
        setTutorConfigDetailMessage: (state, {payload}) => {
            state.tutorConfigDetail.message = payload
            state.tutorConfigDetail.data = TUTOR_INITIAL_STATE.tutorConfigDetail.data
        },
        resetTutorConfigDetail: (state) => {
            state.tutorConfigDetail = TUTOR_INITIAL_STATE.tutorConfigDetail
        },

        // reducers for update tutor config detail
        setUpdateTutorConfigDetailLoading: (state, {payload}) => {
            state.updateTutorConfig.isLoading = payload
        },
        setUpdateTutorConfigDetailMessage: (state, {payload}) => {
            state.updateTutorConfig.message = payload
        },
        resetUpdateTutorConfigDetail: (state) => {
            state.updateTutorConfig = TUTOR_INITIAL_STATE.updateTutorConfig
        },

        // reducers for filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },

        setClearFilterProps: (state) => {
            state.filterProps = TUTOR_INITIAL_STATE.filterProps
            state.tutorList.filteredTutorList = state.tutorList.tutorList
        }
    },
    extraReducers: {
        // extra reducers for tutor list
        [getTutorList.pending]: (state) => {
            state.tutorList.isLoading = true
        },
        [getTutorList.fulfilled]: (state, { payload }) => {
            state.tutorList.isLoading = false
            state.tutorList.tutorList = payload
            state.tutorList.filteredTutorList = payload
        },
        [getTutorList.rejected]: (state, { payload }) => {
            state.isLoading = false
            state.tutorList.errorMsg = payload
            state.tutorList.tutorList = TUTOR_INITIAL_STATE.tutorList.tutorList
            state.tutorList.filteredTutorList = TUTOR_INITIAL_STATE.tutorList.filteredTutorList
        }
    }
})
export const {
    setClearTutorList,

    setTutorUserPublicProfileLoading,
    setTutorUserPublicProfile,
    setTutorUserPublicProfileErrorMsg,
    setClearTutorUserProfile,

    setTutorDetailLoading,
    setTutorDetailData,
    setTutorDetailMessage,
    resetTutorDetail,

    setUpdateTutorDetailLoading,
    setUpdateTutorDetailData,
    setUpdateTutorDetailMessage,
    resetUpdateTutorDetail,

    setTutorUserPriceLoading,
    setTutorUserPrice,
    setTutorUserPriceErrorMsg,
    setClearTutorUserPrice,

    setAddTutorUserPriceLoading,
    setAddTutorUserPriceErrorMsg,
    setClearAddTutorUserPrice,

    setTutorUserLessonLoading,
    setTutorUserLesson,
    setTutorUserLessonErrorMsg,
    setClearTutorUserLesson,

    setAddTutorUserLessonLoading,
    setAddTutorUserLessonErrorMsg,
    setClearAddTutorUserLesson,

    // new tutor apis
    // new modified apis
    setTutorLanguageListLoading,
    setTutorLanguageListData,
    setTutorLanguageListMessage,
    resetTutorLanguageList,

    setAddTutorLanguageDetailLoading,
    setAddTutorLanguageDetailMessage,
    resetAddTutorLanguageDetail,

    setUpdateTutorLanguageDetailLoading,
    setUpdateTutorLanguageDetailMessage,
    resetUpdateTutorLanguageDetail,

    setUpdateTutorLanguageListOrderLoading,
    setUpdateTutorLanguageListOrderMessage,
    resetUpdateTutorLanguageListOrder,

    setDeleteTutorLanguageDetailLoading,
    setDeleteTutorLanguageDetailMessage,
    resetDeleteTutorLanguageDetail,


    setTutorTagListLoading,
    setTutorTagListData,
    setTutorTagListMessage,
    resetTutorTagList,

    setAddTutorTagDetailLoading,
    setAddTutorTagDetailMessage,
    resetAddTutorTagDetail,

    setUpdateTutorTagListOrderLoading,
    setUpdateTutorTagListOrderMessage,
    resetUpdateTutorTagListOrder,

    setDeleteTutorTagDetailLoading,
    setDeleteTutorTagDetailMessage,
    resetDeleteTutorTagDetail,

    setTutorCategoryListLoading,
    setTutorCategoryListData,
    setTutorCategoryListMessage,
    resetTutorCategoryList,

    setAddTutorCategoryDetailLoading,
    setAddTutorCategoryDetailMessage,
    resetAddTutorCategoryDetail,

    setUpdateTutorCategoryDetailLoading,
    setUpdateTutorCategoryDetailMessage,
    resetUpdateTutorCategoryDetail,

    setUpdateTutorCategoryListOrderLoading,
    setUpdateTutorCategoryListOrderMessage,
    resetUpdateTutorCategoryListOrder,

    setDeleteTutorCategoryDetailLoading,
    setDeleteTutorCategoryDetailMessage,
    resetDeleteTutorCategoryDetail,

    setTutorConfigDetailLoading,
    setTutorConfigDetailData,
    setTutorConfigDetailMessage,
    resetTutorConfigDetail,

    setUpdateTutorConfigDetailLoading,
    setUpdateTutorConfigDetailMessage,
    resetUpdateTutorConfigDetail,

    setFilterProps,
    setClearFilterProps
} = tutor.actions

export default tutor.reducer