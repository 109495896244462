import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { cn } from "utils/cn.utils";

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/viewCourseDetail/viewCourse.style';
import { inputSections } from "pages/auth/viewCourseDetail/data";

import { useAppState, useAppDispatcher } from 'hooks/useStore';

const CourseDescriptionSection = ({ clickedItem, setClickedItem }) => {
    const { courseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const isOpen = inputSections?.COURSE_DESCRIPTION?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const Editor = {
        modules: {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                [{ 'script': 'sub' }, { 'script': 'super' }],
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image', 'video'],
                [{ 'color': [] }, { 'background': [] }],
                ['clean']
            ],
            clipboard: {
                matchVisual: false,
            }
        },
        theme: 'snow',
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video',
            'color', 'background'
        ]
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_DESCRIPTION?.value)) {
            setClickedItem(inputSections?.COURSE_DESCRIPTION?.value)
        } else {
            setClickedItem(null)
        }
    }

    return (
        <div className={"w-full h-full select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Description"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                <div className={cn(
                    "w-full min-h-[24rem] border border-dashed border-text-500 rounded-lg my-3",
                    "font-bodyPri font-normal text-text-900"
                )}>
                    <ReactQuill
                        id={"editor"}
                        placeholder={"Write something..."}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme="snow"
                        value={courseDetail?.courseDetail?.description || ""}
                        // onChange={setContent}
                        disable={true}
                    />
                </div>
            </CollapsableContainer>
        </div>
    )
}

export default CourseDescriptionSection;