import { useState } from 'react';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';

import { createNextCourseSessionDetail } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

const AddNextCourseSessionModal = () => {
    const { nextCourseSessionDetail } = useAppState((state) => state.course)
    const [newWeekCount, setNewWeekCount] = useState()

    const dispatcher = useAppDispatcher()

    const onHandleSubmit = () => {
        if (nextCourseSessionDetail?.isLoading) return;

        if (newWeekCount <= 0 || newWeekCount > 5) {
            alert("Session count cannot be less than 0 or more than 5")
            return;
        }

        if (!nextCourseSessionDetail?.payload?.courseId) {
            alert("Something Went Wrong!")
            return;
        }

        if (newWeekCount > 0) {
            const requestDataPayload = {
                courseId: nextCourseSessionDetail?.payload?.courseId,
                body: { weekCount: newWeekCount }
            }
            dispatcher(createNextCourseSessionDetail(requestDataPayload))
        }
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[8rem] block w-full space-y-5 pt-3"}>

                <div className={cn(
                    "relative w-full group flex items-center justify-start"
                )}>
                    <input
                        id={"weeklyCycles"}
                        name={"weeklyCycles"}
                        type={"number"}
                        className={cn(
                            "relative overflow-hidden w-full input-number-spin-none px-5 py-1.5 focus:outline-none focus:border-primary-main",
                            "transition ease-in-out duration-300",
                            "border-2 border-text-500 rounded-md",
                            "font-bodyPri font-normal text-text-800 text-base"
                        )}
                        value={newWeekCount?.toString()}
                        onChange={(event) => setNewWeekCount(Number(event?.target?.value))}
                    />
                    <label for={"weeklyCycles"} className={cn(
                        "absolute z-1 left-3 whitespace-nowrap cursor-text",
                        "transition ease-in-out duration-300",
                        "font-bodyPri font-normal text-base text-text-500",
                        "group-focus-within:-translate-y-5 group-focus-within:-translate-x-4 group-focus-within:scale-75 group-focus-within:text-primary-main",
                        "group-focus-within:bg-white group-focus-within:px-1 group-focus-within:py-0 group-focus-within:cursor-default",
                        newWeekCount && "-translate-y-5 -translate-x-4 scale-75 !text-primary-main",
                        newWeekCount && "bg-white px-1 py-0 cursor-default"
                    )}>
                        {"Weekly Cycles (1 to 5)"}
                    </label>
                </div>
                {nextCourseSessionDetail?.message &&
                    <div className={"w-full flex items-center justify-center"}>
                        <span className={"font-bodyPri font-normal text-red-500 tracking-wide"}>
                            {nextCourseSessionDetail?.message}
                        </span>
                    </div>

                }
            </div>

            <div className={"flex justify-end px-5"}>
                <span onClick={onHandleSubmit} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full font-buttons font-normal text-base",
                    newWeekCount && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                    !newWeekCount && "border border-text-300 text-text-300",
                )}>
                    {nextCourseSessionDetail?.isLoading && <ButtonLoader isLoading={nextCourseSessionDetail?.isLoading} />}
                    {!nextCourseSessionDetail?.isLoading && "Submit"}
                </span>
            </div>
        </div>
    )
}

export default AddNextCourseSessionModal