import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/message/message.data';

import PageHeader from 'components/pageHeader/PageHeader';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';


const Message = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.MESSAGES))
  }, [dispatcher, currentPageInfo])

  return (
      <div className='height[100vh] w-full flex flex-col gap-3 p-5'>
        <PageHeader pageHeading={pageHeading} />
      </div>
  );
}

export default Message;