import format from 'string-format';

import { baseAuthApiInstance } from 'apiInstances/axios.apiInstance';

import { url } from './lmsQuiz.const';

class LmsQuizService {

    getLmsQuizList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_QUIZ_LIST),
            { params: query }
        )
        return response;
    }

    deleteLmsQuizDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_QUIZ_DETAIL, params)
        )

        return response;
    }
}

export default new LmsQuizService();