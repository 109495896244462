import { memo } from 'react';

import { bookingScheduleIsRecurringEnum, bookingStatusEnum } from 'redux/booking/booking.const';
import { courseType } from 'redux/course/course.const';

const BookingCourse = memo(({ bookingUserDetail }) => {

    return (
        <div className="p-2 shadow-sm">
            <span className="text-lg font-semibold font-bodyPri tracking-wide text-text-900 text-left line-clamp-1">
                {bookingUserDetail?.course?.title}
            </span>
            <div className="grid gap-3 pt-4 mt-2 text-sm md:grid-cols-2 opacity-70 place-items-start">
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"CATEGORY:"}</p>
                    <p className="font-bold truncate">
                        {bookingUserDetail?.course?.category
                            ? bookingUserDetail?.course?.category?.length > 17
                                ? bookingUserDetail?.course?.category?.slice(0, 17) + "..."
                                : bookingUserDetail?.course?.category
                            : "-"
                        }
                    </p>
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"LEVEL:"}</p>
                    {(bookingUserDetail?.course?.courseProficiencies?.length > 0) &&
                        <p className="truncate font-bold">
                            {(bookingUserDetail?.course?.courseProficiencies?.length > 2)
                                ? bookingUserDetail?.course?.courseProficiencies?.slice(0, 2)?.map((proficiency) => proficiency?.masterProficiency?.proficiency)?.join(" | ") + (bookingUserDetail?.course?.courseProficiencies?.length - 2)
                                : bookingUserDetail?.course?.courseProficiencies?.map((proficiency) => proficiency?.masterProficiency?.proficiency)?.join(" | ")
                            }
                        </p>
                    }
                    {(!bookingUserDetail?.course?.courseProficiencies || (bookingUserDetail?.course?.courseProficiencies?.length === 0)) &&
                        <p className="truncate font-bold">{"-"}</p>
                    }
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"CLASS TYPE:"}</p>
                    <p className="font-bold capitalize">
                        {courseType[bookingUserDetail?.course?.type?.toUpperCase()]?.label}
                    </p>
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"SESSION(S):"}</p>
                    {(bookingUserDetail?.course?.type === courseType?.ONE_ON_ONE.value) &&
                        <p className="font-bold">
                            {bookingUserDetail?.bookingPrice?.sessionCount}
                        </p>
                    }
                    {(bookingUserDetail?.course?.type === courseType?.GROUP.value) &&
                        <p className="font-bold truncate">
                            {`${bookingUserDetail?.course?.basicDetail?.sessionCount} session(s) ${(bookingUserDetail?.bookingSchedule?.isRecurring === bookingScheduleIsRecurringEnum.RECURRING.value) ? "per w..eek" : ""}`}
                        </p>
                    }
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"LANGUAGE:"}</p>
                    {(bookingUserDetail?.course?.courseLanguages?.length > 0) &&
                        <p className="font-bold">
                            {(bookingUserDetail?.course?.courseLanguages?.length > 2)
                                ? bookingUserDetail?.course?.courseLanguages?.slice(0, 2)?.map((language) => language?.masterLanguage?.language)?.join(" | ") + (bookingUserDetail?.course?.courseLanguages?.length - 2)
                                : bookingUserDetail?.course?.courseLanguages?.map((language) => language?.masterLanguage?.language)?.join(" | ")
                            }
                        </p>
                    }
                    {(!bookingUserDetail?.course?.courseLanguages || (bookingUserDetail?.course?.courseLanguages?.length === 0)) &&
                        <p className="font-bold">{"-"}</p>
                    }
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"DURATION:"}</p>
                    {(bookingUserDetail?.course?.type === courseType?.ONE_ON_ONE.value) &&
                        <p className="font-bold">
                            {bookingUserDetail?.bookingPrice?.duration + " min."}
                        </p>
                    }
                    {(bookingUserDetail?.course?.type === courseType?.GROUP.value) &&
                        <p className="font-bold">
                            {bookingUserDetail?.bookingPrice?.duration + " min."}
                        </p>
                    }
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"STATUS:"}</p>
                    <p className={`font-bold text-${bookingStatusEnum[bookingUserDetail?.status?.toUpperCase()]?.darkColor}`}>
                        {bookingStatusEnum[bookingUserDetail?.status?.toUpperCase()]?.label}
                    </p>
                </div>
            </div>
        </div>
    )
})

export default BookingCourse;