import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/wallet/wallet.const";


class WalletService {

    getWalletTxnList = async () => {
        const response = await baseAuthApiInstance().get(
            url.GET_WALLET_TXN_LIST
        )

        return response
    }

    createCreditToWallet = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADD_CREDIT_TO_WALLET, params),
            body
        )

        return response
    }

    getWalletConfirmationByVirtualTxnId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_WALLET_CONFIRMATION_BY_VIRTUAL_TXN_ID, params)
        )

        return response
    }

    getUserWithdrawalListByUserId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_WITHDRAWAL_LIST, params),
            { params: query }
        )

        return response;
    }

    createUserWithdrawalDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.USER_WITHDRAWAL_LIST, params),
            body
        )

        return response;
    }
}

export default new WalletService()