import dayjs from "dayjs";

import { walletTxnActions } from 'redux/wallet/wallet.const';

const CURRENCY = "USD"


const WalletTxnItem = ({ walletTxnItem }) => {
    let amount = '';
    let color = null;

    if (walletTxnItem.amountImpact === 'debit') {
        amount = "- " + CURRENCY + " " + Math.abs(parseFloat(walletTxnItem.amount / 100)).toFixed(2)
        color = 'text-primary-main'
    } else if (walletTxnItem.amountImpact === 'credit') {
        amount = "+ " + CURRENCY + " " + Math.abs(parseFloat(walletTxnItem.amount / 100)).toFixed(2)
        color = 'text-green-600'
    } else {
        amount = "(+/-) " + CURRENCY + " " + Math.abs(parseFloat(walletTxnItem.amount / 100)).toFixed(2)
        color = 'text-text-700'
    }

    return (
        <div className={"space-y-1"}>
            <div className="flex flex-row items-center justify-between">
                {walletTxnItem?.action &&
                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                        {walletTxnActions[walletTxnItem?.action?.toUpperCase()]?.label}
                    </span>
                }
                <span className={`font-bodyPri font-base text-sm text-text-900 ${color}`}>{amount}</span>
            </div>
            <div className={"flex flex-row items-center justify-between"}>
                <span className={"font-bodyPri font-base text-sm text-text-900"}>
                    {dayjs(walletTxnItem.createdAt).local().format('DD/MM/YY hh:mm a').toString()}
                </span>
                <span className={'font-bodyPri font-normal text-sm text-text-900 capitalize'}>
                    {walletTxnItem.txnType}
                </span>
            </div>
        </div>
    )
}

export default WalletTxnItem;