import { env } from "const/env.const";
import { ENVIRONMENT } from "const/default.const";

import { userRoles } from 'redux/auth/auth.const';

export const editOnboardingHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    ONBOARDING_ID: {
        label: "Onboarding ID",
        value: "onboarding_id"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    PHONE: {
        label: "Phone",
        value: "phone"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTION: {
        label: "Action",
        value: "action"
    }
}

export const genderOptions = {
    MALE: {
        label: "Male",
        value: "male"
    },
    FEMALE: {
        label: "Female",
        value: "female"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const titleOptions = {
    MR: {
        label: "Mr",
        value: "Mr",
    },
    MS: {
        label: "Ms",
        value: "Ms"
    },
    MRS: {
        label: "Mrs",
        value: "Mrs"
    },
    MISS: {
        label: "Miss",
        value: "Miss"
    },
    Dr: {
        label: "Dr",
        value: "Dr"
    },
    SIR: {
        label: "Sir",
        value: "Sir"
    }
}

export const addressTypeConst = {
    HOME: {
        label: "Home",
        value: "home"
    },
    WORK: {
        label: "Work",
        value: "work"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const userSocialMediaLinksConst = {
    FACEBOOK: {
        label: "Facebook",
        value: "facebook",
        placeholder: "https://www.facebook.com/your_facebook_name",
        demoKey1: "https://www.facebook.com/your_facebook_name",
        demoKey2: "",
        socialLinkUrl: "facebook.com/",
        profileLinkType: "facebookProfileLink"
    },
    INSTAGRAM: {
        label: "Instagram",
        value: "instagram",
        placeholder: "https://www.instagram.com/your_instagram_name",
        demoKey1: "https://www.instagram.com/your_instagram_name",
        demoKey2: "or @your_instagram_name",
        socialLinkUrl: "instagram.com/",
        profileLinkType: "instagramProfileLink"
    },
    TWITTER: {
        label: "Twitter",
        value: "twitter",
        placeholder: "https://www.twitter.com/your_twitter_name",
        demoKey1: "https://www.twitter.com/your_twitter_name",
        demoKey2: "@Your_Twitter_Name",
        socialLinkUrl: "twitter.com/",
        profileLinkType: "twitterProfileLink"
    },
    LINKEDIN: {
        label: "Linkedin",
        value: "linkedin",
        placeholder: "https://www.linkedin.com/yourlinkedinname",
        demoKey1: "https://www.linkedin.com/yourlinkedinname",
        demoKey2: "",
        socialLinkUrl: "linkedin.com/",
        profileLinkType: "linkedinProfileLink"
    }
}

export const editDocumentTableHeader = [
    "Category",
    "File Name",
    "Status",
    "Edit",
    "Delete"
]

export const videoCallTableHeaders = [
    "Interviewer",
    "Date",
    "Start Time",
    "Session Status",
    // "View",
    // "Delete"
]
let interviewersList = []
if (ENVIRONMENT === env.LOCAL.value) {
    interviewersList = [...interviewersList, 
        {
            label: "1000. Gary Jaiswal",
            value: 1
        },
        {
            label: "1001. Deepmala Sharma",
            value: 2
        },
        {
            label: "1005. Geet Mahna",
            value: 3
        },
        {
            label: "1183. Vajinder Kumar",
            value: 4
        },
    ]
} else if (ENVIRONMENT === env.DEV.value) {
    interviewersList = [...interviewersList,
        {
            label: "Gary Jaiswal",
            value: 1
        },
    ]
} else if (ENVIRONMENT === env.STAGE.value) {
    interviewersList = [...interviewersList,
        {
            label: "Gary Jaiswal",
            value: 1
        },
    ]
} else if (ENVIRONMENT === env.PROD.value) {
    interviewersList = [...interviewersList,
        {
            label: "1000. Gary Jaiswal",
            value: 1
        },
        {
            label: "1001. Deepmala Sharma",
            value: 2
        },
        {
            label: "1005. Geet Mahna",
            value: 3
        },
        {
            label: "1183. Vajinder Kumar",
            value: 4
        },
    ]
}

export {interviewersList}

export const monthsOptions = {
    JANUARY: {
        label: "0",
        value: "0"
    },
    FEBURARY: {
        label: "1",
        value: "1"
    },
    MARCH: {
        label: "2",
        value: "2"
    },
    APRIL: {
        label: "3",
        value: "3"
    },
    MAY: {
        label: "4",
        value: "4"
    },
    JUNE: {
        label: "5",
        value: "5"
    },
    JULY: {
        label: "6",
        value: "6"
    },
    AUGUST: {
        label: "7",
        value: "7"
    },
    SEPTEMBER: {
        label: "8",
        value: "8"
    },
    OCTOBER: {
        label: "9",
        value: "9"
    },
    NOVEMBER: {
        label: "10",
        value: "10"
    },
    DECEMBER: {
        label: "11",
        value: "11"
    },
}

export const videoCallStatusConst = {
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    SCHEDULED: {
        label: "Scheduled",
        value: "scheduled"
    },
    APPROVED: {
        label: "Approved",
        value: "approved"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected"
    }
}

export const slotPeriodConst = {
    FOURTEEN: {
        label: 14,
        value: 14
    }
}

export const videoCallDurationConst = {
    FIFTEEN: {
        label: 15,
        value: 15
    },
    THIRTY: {
        label: 30,
        value: 30
    },
    FORTY_FIVE: {
        label: 45,
        value: 45
    }
}

export const personalDetailStatus = {
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted"
    },
    APPROVED: {
        label: "Verified",
        value: "approved"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected"
    }
}

export const questionAnswerStatusConst = {
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted"
    },
    APPROVED: {
        label: "Verified",
        value: "approved"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected"
    }
}

export const questionType = {
    SINGLE_TEXT: {
        value: "single_text"
    },
    MULTIPLE_TEXT: {
        value: "multiple_text"
    },
    SINGLE_CHOICE: {
        value: "single_choice"
    },
    MULTIPLE_CHOICE: {
        value: "multiple_choice"
    }
}

export const tutorPrefStatusConst = {
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted"
    },
    APPROVED: {
        label: "Verified",
        value: "approved"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected"
    }
}

export const trialClassStatusConst = {
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted"
    },
    APPROVED: {
        label: "Verified",
        value: "approved"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected"
    }
}

export const verifyTutorStatusConst = {
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review"
    },
    APPROVED: {
        label: "verified",
        value: "approved"
    },
    WAITING: {
        label: "Waiting",
        value: "waiting"
    },
    BLOCKED: {
        label: "Blocked",
        value: "blocked"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected"
    }
}

export const fixedRateConst = {
    TEN: {
        label: "10",
        value: "10"
    },
    TWENTY: {
        label: "20",
        value: "20"
    },
    THIRTY: {
        label: "30",
        value: "30"
    },
    FORTY: {
        label: "40",
        value: "40"
    }
}

export const variableRateConst = {
    TEN: {
        label: "10",
        value: "10"
    },
    TWENTY: {
        label: "20",
        value: "20"
    },
    THIRTY: {
        label: "30",
        value: "30"
    },
    FORTY: {
        label: "40",
        value: "40"
    }
}

export const editOnboardingNavigationOptions = [
    { label: "User Details", to: "/user-details", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Relevant Questions", to: "/relevant-questions", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Intro Video", to: "/intro-video", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Documents", to: "/verify-documents", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Tutor Preferences", to: "/tutor-pref", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Video Call", to: "/video-call", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Approve Tutor", to: "/verify-tutor", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
    { label: "Comments", to: "/", roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value] },
];