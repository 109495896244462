import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import CompanyCourseCard from "components/companyCourseCard/CompanyCourseCard";

import { getCourseListByCompanyId } from 'redux/onboarding/onboarding.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCompanyCourseList } from "redux/company/company.slice";

const EditCourses = () => {
    const { companyCourseList } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { companyId } = useParams()

    useEffect(() => {
        if (companyId) {
            dispatcher(getCourseListByCompanyId(Number(companyId)))
        }

        return () => {
            dispatcher(setClearCompanyCourseList())
        }
    }, [companyId])

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"COURSES"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <div className={"flex items-center justify-start gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"Courses"}
                        </span>
                    </div>
                    <div className={cn(
                        "grid grid-flow-col gap-5 p-5 overflow-hidden overflow-x-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {companyCourseList?.companyCourseList?.length > 0 &&
                            companyCourseList?.companyCourseList?.map((course, index) => (
                                <CompanyCourseCard
                                    key={index}
                                    course={course}
                                />
                            ))}
                        {companyCourseList?.companyCourseList?.length === 0 &&
                            <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                                {"No Course Found!"}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCourses;