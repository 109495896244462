import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import {
    HamburgerMenuContainer,
    HamburgerHeader,
    HamburgerHeaderContent,
    HamburgerContent,
    LogInIcon,
    HamburgerMenuContent,
    Item,
    ProfileImageContainer,
    ItemIcon,
    ItemName,
    RoleSwitchIcon,
} from './NavBarStyle';

import { IoIosArrowForward } from 'react-icons/io';

import { HamburgerMenuItems } from "components/navbar/HamburgerMenu.data"
import HamburgerSubMenu from './HamburgerSubMenu';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { userRoles } from 'redux/auth/auth.const';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const HamburgerMenu = ({ setIsMediumHamburger, visible = true }) => {
    const { modal, locals } = useAppState((s) => s.local)
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const rotateIconVariants = { student: { rotate: 0 }, teacher: { rotate: 180 } }

    const itemNameVariants = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }

    const handleOnClickItem = (dataItem) => {
        if (dataItem?.value === "switch_to_app") {
            window.open(`${EDULYTE_APP_DOMAIN_URL}/dashboard`, "_self")
            return;
        }
        if (!dataItem?.subMenu || (dataItem?.subMenu?.length === 0)) {
            navigate(dataItem.path)
            setIsMediumHamburger(false)
        }
        if (dataItem?.subMenu?.length > 0) {
            if (!activeMenuItem || (activeMenuItem !== dataItem?.value)) {
                setActiveMenuItem(dataItem?.value)
            } else {
                setActiveMenuItem(null)
            }
        }
    }

    const handleRoleSwitch = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
        }))
    }

    const onHandleLogIn = () => {
        window.open(`${EDULYTE_APP_DOMAIN_URL}/log-in?app_name=edulyte-admin&redirect_url=${window.location.href}`, "_self")
    }

    return (
        <HamburgerMenuContainer>
            <HamburgerHeader>
                {!session?.isLoggedIn &&
                    <HamburgerHeaderContent onClick={() => setIsMediumHamburger(false)}>
                        <div
                            className={"flex items-center gap-2 hover:!text-secondary-main"}
                            onClick={onHandleLogIn}
                        >
                            <LogInIcon />
                            {'Log in'}
                        </div>
                    </HamburgerHeaderContent>
                }
                {session?.isLoggedIn &&
                    <Item
                        className={"py-2 px-3 flex items-center gap-3"}
                        onClick={() => {
                            navigate(pagesInfo?.PROFILE?.pagePath)
                            setIsMediumHamburger(false)
                        }}
                    >
                        <ProfileImageContainer>
                            <img src={user?.user?.profilePicUrl} alt={"profile"} />
                        </ProfileImageContainer>
                        <ItemName>
                            <div className={"w-full h-full flex flex-col justify-start items-start"}>
                                <span>{user?.user?.firstName + " " + user?.user?.lastName.charAt(0)}</span>
                                <span className={"text-xs text-primary-main"}>{"My Profile"}</span>
                            </div>
                        </ItemName>
                    </Item>
                }
            </HamburgerHeader>
            <hr />
            <HamburgerContent>
                {session?.isLoggedIn &&
                    <HamburgerMenuContent>
                        <Item onClick={handleRoleSwitch}>
                            <ItemIcon>
                                <RoleSwitchIcon
                                    variants={rotateIconVariants}
                                    initial={userRoles.ADMIN.value}
                                    animate={(locals.userRole === userRoles.SUPER_ADMIN.value) ? userRoles.SUPER_ADMIN.value : userRoles.ADMIN.value}
                                    transition={transition}
                                />
                            </ItemIcon>
                            {locals &&
                                <ItemName
                                    variants={itemNameVariants}
                                    initial={'open'}
                                    animate={visible ? 'open' : 'close'}
                                    transition={transition}
                                >
                                    {userRoles[locals?.userRole?.toUpperCase()].label}
                                </ItemName>
                            }
                        </Item>
                        {HamburgerMenuItems?.map((dataItem, index) => dataItem?.roles?.includes(locals.userRole) && (
                            <>
                                <Item key={index} onClick={() => handleOnClickItem(dataItem)}>
                                    <ItemIcon>
                                        <dataItem.icon />
                                    </ItemIcon>
                                    <ItemName>
                                        {dataItem.name}
                                    </ItemName>
                                    {(dataItem?.subMenu?.length > 0) &&
                                        <div className={"pl-5 flex justify-end"}>
                                            <IoIosArrowForward className={cn(
                                                "font-bold text-2xl text-text-600 transition ease-in-out delay-50 duration-100",
                                                (activeMenuItem && (activeMenuItem === dataItem?.value)) && "rotate-90",
                                                (!activeMenuItem || (activeMenuItem && (activeMenuItem !== dataItem?.value))) && "rotate-0",
                                            )}
                                            />
                                        </div>
                                    }
                                </Item>
                                {((dataItem?.subMenu?.length > 0) && (activeMenuItem === dataItem?.value)) &&
                                    <>
                                        {dataItem?.subMenu?.map((menuItem, index) => menuItem?.roles?.includes(locals.userRole) && (
                                            <HamburgerSubMenu
                                                key={index}
                                                menuItem={menuItem}
                                                setIsMediumHamburger={setIsMediumHamburger}
                                            />
                                        ))}
                                    </>
                                }
                            </>
                        ))}
                    </HamburgerMenuContent>
                }
            </HamburgerContent>
        </HamburgerMenuContainer>
    )
}

export default HamburgerMenu;