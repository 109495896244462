import { useEffect } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';

import LeadBasicDetail from './components/LeadBasicDetail';

import { getLeadDetailByLeadId } from 'redux/lead/lead.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import LeadQuestionAnswerContainer from './components/LeadQuestionAnswerContainer';

const ViewLeadSideOverPanel = () => {
    const { leadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (leadDetail?.payload?.leadId) {
            dispatcher(getLeadDetailByLeadId(leadDetail?.payload?.leadId))
        }
    }, [leadDetail?.payload?.leadId])

    return (
        <div className={"p-5 h-screen sm:px-10 max-w-2xl md:max-w-xl lg:max-w-lg"}>
            <ComponentLoader isLoading={leadDetail?.isLoading} className={"w-full md:w-[25rem]"} />
            {(!leadDetail?.isLoading && leadDetail?.errorMsg) &&
                <div className={"w-full h-full md:w-[25rem] flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wde"}>
                        {leadDetail?.errorMsg}
                    </span>
                </div>
            }

            {(!leadDetail?.isLoading && leadDetail?.leadDetail) &&
                <div className={"w-full md:w-[25rem] mx-auto space-y-5 pb-5"}>
                    <LeadBasicDetail />
                    <LeadQuestionAnswerContainer />
                </div>
            }
        </div>
    )
}

export default ViewLeadSideOverPanel