import { createSlice } from "@reduxjs/toolkit";

import { PAYOUT_INITIAL_STATE } from "redux/payout/payout.initialState";
import { getUserPayoutListByUserId, createUserPayoutDetailByUserId } from "redux/payout/payout.request";

const payout = createSlice({
    name: "payout",
    initialState: PAYOUT_INITIAL_STATE,
    reducers: {
        // reducers for get user payout list
        setUserPayoutList: (state, { payload }) => {
            state.addUserPayoutDetail.isLoading = false
            state.userPayoutList.userPayoutList = payload
        },
        setClearUserPayoutList: (state) => {
            state.userPayoutList = PAYOUT_INITIAL_STATE.userPayoutList
        },

        // reducers for add user payout details
        setClearAddUserPayoutDetail: (state) => {
            state.addUserPayoutDetail = PAYOUT_INITIAL_STATE.addUserPayoutDetail
        }
    },

    extraReducers: {
        // extra reducers for user payout list
        [getUserPayoutListByUserId.pending]: (state) => {
            state.userPayoutList.isLoading = true
        },
        [getUserPayoutListByUserId.fulfilled]: (state, { payload }) => {
            state.userPayoutList.isLoading = false
            state.userPayoutList.userPayoutList = payload
            state.userPayoutList.errorMsg = PAYOUT_INITIAL_STATE.userPayoutList.errorMsg

        },
        [getUserPayoutListByUserId.rejected]: (state, { payload }) => {
            state.userPayoutList.isLoading = false
            state.userPayoutList.errorMsg = payload
            state.userPayoutList.userPayoutList = PAYOUT_INITIAL_STATE.userPayoutList.userPayoutList

        },

        // reducers for add user payout details
        [createUserPayoutDetailByUserId.pending]: (state) => {
            state.addUserPayoutDetail.isLoading = true
        },
        [createUserPayoutDetailByUserId.rejected]: (state, { payload }) => {
            state.addUserPayoutDetail.isLoading = false
            state.addUserPayoutDetail.errorMsg = payload
        }
    }
})

export const {
    setUserPayoutList,
    setClearUserPayoutList,
    setClearAddUserPayoutDetail
} = payout.actions

export default payout.reducer;