import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    GET_LMS_QUESTION_LIST: "lms/questions/questions",
    DELETE_LMS_QUESTION_DETAIL: "lms/questions/questions/{questionId}"
}

export const lmsQuestionRedux = {
    sliceName: "lmsQuestion",
    lmsQuestionList: {
        requestName: "getLmsQuestionList"
    }
}

export const lmsQuestionTypeEnum = {
    SINGLE_CHOICE: {
        label: "Single Choice",
        value: "single_choice"
    },
    MULTIPLE_CHOICE: {
        label: "Multiple Choice",
        value: "multiple_choice"
    },
    // SHORT_ANSWER: {
    //     label: "Short Answer",
    //     value: "short_answer"
    // },
    // PARAGRAPH: {
    //     label: "Paragraph",
    //     value: "paragraph"
    // }
}

export const lmsQuestionStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const lmsQuestionShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsQuestionVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsQuestionInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const DEFAULT_LMS_QUESTION_PAGE = 1

export const DEFAULT_LMS_QUESTION_RECORDS = dataEntriesConst.TWENTY_FIVE.value

export const lmsQuestionFilterProps = {
    page: DEFAULT_LMS_QUESTION_PAGE,
    records: DEFAULT_LMS_QUESTION_RECORDS,
    searchText: "",
    sortBy: "",
    questionId: "",
    questionTitle: "",
    questionType: "",
    questionStatus: "",
    ownerTutor: "",
    date: ""
}