import { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import Table from 'components/table/Table';
import Pagination from 'components/pagination/Pagination';

import { lmsQuizHeaderConst, sortQuizHighToLow, sortQuizLowToHigh, subRowHeader } from 'pages/auth/edulyteLms/lmsQuiz/lmsQuiz.data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { deleteLmsQuizDetail, getLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { resetLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { lmsQuizStatusEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { edulyteAppPagesInfo, pagesInfo } from 'utils/pagesInfo';
import { timeZone, dayjs } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

import { EDULYTE_APP_DOMAIN_URL, EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

let CURRENT_QUIZ_ID = null;

const QuizTitleContainer = memo(({ quiz }) => {

    const onHandleNavigateToQuiz = useCallback(() => {
        if (quiz?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value) {
            window.open(`${EDULYTE_WEB_DOMAIN_URL}/quiz/${quiz?.active_slug}`, "_blank")
        }
    }, [quiz])

    return (
        <span
            className={cn(
                'font-bodyPri font-normal tracking-wide',
                (quiz?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
            )}
            title={quiz?.title && quiz?.title}
            onClick={onHandleNavigateToQuiz}
        >
            {quiz?.title
                ? (quiz?.title?.length > 30)
                    ? quiz?.title?.slice(0, 30) + " ..."
                    : quiz?.title
                : "-"
            }
        </span>
    )
})

const OwnerTutorContainer = ({ quiz }) => {
    return quiz?.quiz_setting?.default_owner?.tutor ? (
        <div className={"flex items-center justify-start gap-0.5"}>
            <Link to={`${pagesInfo?.VIEW_TUTOR?.pagePath}/${quiz?.quiz_setting?.default_owner?.tutor?.tutorId}`}
                className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
            >
                {quiz?.quiz_setting?.default_owner?.tutor?.tutorId + ". "}
            </Link>
            <div className={""}>
                {`${quiz?.quiz_setting?.default_owner?.tutor?.user?.first_name} ${quiz?.quiz_setting?.default_owner?.tutor?.user?.last_name?.charAt(0)}.` || "-"}
            </div>
        </div>
    ) : "-"
}

const DisplayLmsQuiz = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { lmsQuizList, destroyLmsQuizDetail, filterProps } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {

        return () => {
            dispatcher(resetLmsQuizList())
            dispatcher(resetAdminLoginAs())
        }
    }, [])

    useEffect(() => {
        const query = {
            page: filterProps?.page || 1,
            records: Number(filterProps?.records) || 25
        }
        dispatcher(getLmsQuizList(query))

    }, [filterProps?.records])

    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${CURRENT_QUIZ_ID}/edit`, "_self")
        }
    }, [adminLoginAs?.data])

    const onHandleEditQuiz = (quizId, userId) => {
        CURRENT_QUIZ_ID = quizId
        dispatcher(createAdminLoginAsDetail({ user_id: userId, source_url: window.location.href }))
    }

    const onHandleDeleteQuiz = (quizId) => {
        if (destroyLmsQuizDetail?.isLoading) return;

        dispatcher(deleteLmsQuizDetail(quizId))
    }

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortQuizList = (quiz1, quiz2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortQuizLowToHigh(activeSortHeader, quiz1, quiz2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortQuizHighToLow(activeSortHeader, quiz1, quiz2)
        }
    }

    const tableRowsConst = lmsQuizList?.data?.results?.slice(0)?.sort(sortQuizList)?.map((quiz) => ([
        quiz?.id,
        <QuizTitleContainer quiz={quiz} />,
        quiz?.active_slug
            ? (quiz?.active_slug?.length > 30)
                ? quiz?.active_slug?.slice(0, 30) + "...."
                : quiz?.active_slug
            : "-",
        <OwnerTutorContainer quiz={quiz} />,
        quiz?.quiz_setting?.status
            ? lmsQuizStatusEnum[quiz?.quiz_setting?.status?.toUpperCase()]?.label
            : "-",
        dayjs(quiz?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",

        <div className={"flex items-center w-full"}>
            <button
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
                onClick={() => onHandleEditQuiz(quiz?.id, quiz?.quiz_setting?.default_owner?.tutor?.user?.userId)}
                title={"Edit Quiz"}
            >
                <FiEdit size={20} className={"text-xl hover:scale-110"} />
            </button>
            <button
                className={"px-2 py-1 bg-red-500 text-white rounded-r-md"}
                onClick={() => onHandleDeleteQuiz(quiz?.id)}
                title={"Delete Quiz"}
            >
                <AiOutlineDelete size={20} className={"text-xl hover:scale-110"} />
            </button>
        </div>,
    ]))

    return (
        <div className={'w-full flex flex-col gap-5'}>
            <Table
                headers={Object.values(lmsQuizHeaderConst)}
                subRowHeader={subRowHeader}
                rows={tableRowsConst}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={lmsQuizList?.isLoading}
                message={(lmsQuizList?.message || (lmsQuizList?.data?.records === 0)) && (lmsQuizList?.message || "No quizzes found")}
            />
            {(!lmsQuizList?.isLoading && (lmsQuizList?.data?.totalPages > 1)) &&
                <div className={'w-full flex items-center justify-center'}>
                    <Pagination
                        page={lmsQuizList?.data?.page}
                        totalPages={lmsQuizList?.data?.totalPages}
                        onChangePage={(page) => dispatcher(getLmsQuizList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </div>
    )
}

export default DisplayLmsQuiz;