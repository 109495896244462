import { useEffect } from 'react';
import {useLocation, Navigate, Outlet } from 'react-router-dom';

import PageHeader from 'components/pageHeader/PageHeader';

import PageNavigationOptions from 'components/navigationOptions/PageNavigationOptions';
import { otherSettingsNavigationOptions, pageHeading } from 'pages/auth/otherSettings/data';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

export default function OtherSettingsFeatures({ allowedRoles }) {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((state) => state.local)
    const { session } = useAppState((s) => s.auth)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.OTHER_SETTINGS))
    }, [dispatcher, currentPageInfo])

    return (
        <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
            <PageHeader pageHeading={pageHeading} />

            <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-3 md:flex-row">
                <PageNavigationOptions options={Object.values(otherSettingsNavigationOptions)} containerClassName={""} />
                <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
                    {session?.isLoggedIn && user?.user?.roles?.find((role) => allowedRoles?.includes(role.role) && role.role === locals.userRole)
                        ? <Outlet />
                        : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: location }} replace />
                    }
                </div>
            </div>
        </div>
    );
}