import { useEffect } from "react";
import { cn } from "utils/cn.utils";

import { BsFillCheckCircleFill } from "react-icons/bs";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

const WithdrawalRequestSubmitModal = () => {
  const { modal } = useAppState((state) => state.local)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setModal({
      ...modal,
      [modalConst.CONFIRM_PAYOUT_MODAL.stateKey]: false
    }))
  }, [])

  const handleCloseButton = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.WITHDRAWAL_REQUEST_SUBMIT_MODAL.stateKey]: false
    }))
  }

  return (

    <div className={"space-y-5 overflow-x-hidden"}>
      <div className={"min-h-[16rem] block space-y-5 pt-5"}>
        <div className={"flex items-center justify-center"}>
          <BsFillCheckCircleFill className={"text-green-500 text-6xl"} />
        </div>
        <div className={"w-full font-bodyPri font-medium text-text-900 text-lg text-center"}>
          {"Withdrawal request submitted"}
        </div>
        <div className={"w-full font-bodyPri font-normal text-text-700 text-sm text-center"}>
          {"Money will be sent to your nominated account after approval, it may take 24-48 hours to reach your account depending on bank."}
        </div>
      </div>
      <div className={"flex justify-end"}>
        <span
          className={cn(
            "px-5 py-1 flex justify-center items-center rounded-full cursor-pointer",
            "font-bodyPri font-normal text-base text-secondary-main",
            "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
          )}
          onClick={handleCloseButton}
        >
          {"Close"}
        </span>
      </div>
    </div>
  )
}

export default WithdrawalRequestSubmitModal;