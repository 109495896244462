import { useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getLmsQuestionList } from "redux/edulyteLms/lmsQuestion/lmsQuestion.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetFilterProps, setFilterProps } from "redux/edulyteLms/lmsQuestion/lmsQuestion.slice";
import { dateRangesLabels } from "redux/local/local.const";
import { lmsQuestionStatusEnum, lmsQuestionTypeEnum } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";

import { dayjs } from "utils/dateTime.utils";

export const SearchQuestionId = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"questionId"}
            value={filterProps?.questionId}
            placeholder={"Search Question ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, questionId: e.target.value }
            }))}
        />
    )
}

export const SearchSegment = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"segment"}
            value={filterProps.segment}
            placeholder={"Search Segments"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, segment: e.target.value }
            }))}
        />
    )
}

export const SearchCategory = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"category"}
            value={filterProps?.category}
            placeholder={"Search Category"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, category: e.target.value }
            }))}
        />
    )
}

export const SearchTutor = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"tutor"}
            value={filterProps?.ownerTutor}
            placeholder={"Search Tutor"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, ownerTutor: e.target.value }
            }))}
        />
    )
}

export const SearchQuestionTitle = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"questionTitle"}
            value={filterProps?.questionTitle}
            placeholder={"Search Question Title"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, questionTitle: e.target.value }
            }))}
        />
    )
}

export const SearchQuestionSlug = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"questionSlug"}
            value={filterProps?.questionSlug}
            placeholder={"Search Question Slug"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-200"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, questionSlug: e.target.value }
            }))}
            disabled={true}
        />
    )
}

export const FilterQuestionType = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(lmsQuestionTypeEnum)}
            value={filterProps.questionType}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, questionType: option.value }
            }))}
        />
    )
}

export const FilterQuestionStatus = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(lmsQuestionStatusEnum)}
            value={filterProps?.questionStatus}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, questionStatus: option.value }
            }))}
        />
    )
}

export const SearchDate = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            // onCallback={(start, end, label) => console.log(start?._d, end?._d, label)}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "px-1 py-0.5 flex items-center justify-center text-center gap-1 bg-text-300",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
                disabled={true}
            />
        </DateRangePicker>
    )
}


export const SearchPublishedDate = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)
    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            // onCallback={(start, end, label) => console.log(start?._d, end?._d, label)}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "px-1 py-0.5 flex items-center justify-center text-center gap-1 bg-text-300",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
                disabled={true}
            />
        </DateRangePicker>
    )
}

export const ButtonAction = () => {
    const { filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const handleApply = () => {
        const requestDataPayload = {
            page: filterProps?.page || 1,
            records: filterProps?.records || 25
        }
        if (filterProps?.questionId) {
            requestDataPayload["id"] = filterProps?.questionId
        }
        if (filterProps?.questionTitle) {
            requestDataPayload["title"] = filterProps?.questionTitle
        }
        if (filterProps?.questionSlug) {
            requestDataPayload["active_slug"] = filterProps?.questionSlug
        }
        if (filterProps?.questionType) {
            requestDataPayload["type"] = filterProps?.questionType
        }
        if (filterProps?.ownerTutor) {
            if (isNaN(filterProps?.ownerTutor)) {
                requestDataPayload["tutor_name"] = filterProps?.ownerTutor?.trim()
            } else {
                requestDataPayload["default_owner_id"] = filterProps?.ownerTutor
            }
        }
        if (filterProps?.questionStatus) {
            requestDataPayload["status"] = filterProps?.questionStatus
        }
        dispatcher(getLmsQuestionList(requestDataPayload))
    }

    const handleClear = () => {
        dispatcher(resetFilterProps())
        dispatcher(getLmsQuestionList({ page: 1, records: 25 }))
    }

    return (
        <div className="w-full flex items-center justify-center gap-1">
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-primary-main text-text-50 hover:bg-primary-dark",
                )}
                onClick={handleApply}
            >
                {"Apply"}
            </span>
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-background-lightDark text-text-50 hover:bg-background-dark",
                )}
                onClick={handleClear}
            >
                {"Clear"}
            </span>
        </div>
    )
}