import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { Draggable } from "react-drag-reorder";
import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';
import { FaTimes } from 'react-icons/fa';

import { OptionSelector } from 'components/common-components/Select';

import ComponentLoader from 'components/loader/ComponentLoader';

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/editCourseDetail/editCourseDetail.style';
import { inputSections, proficiencyTooltip } from "pages/auth/editCourseDetail/data";

import { getMasterProficiencyList } from 'redux/master/master.request';
import { getCourseProficiencyList, createCourseProficiencyDetail, deleteCourseProficiencyDetail, updateCourseProficiencyListOrder } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearProfList } from 'redux/master/master.slice';
import { setClearAddCourseProfDetail, setClearCourseProficiencyList } from 'redux/course/course.slice';

const CourseProficiencySection = ({ clickedItem, setClickedItem }) => {
    const { profList } = useAppState((state) => state.master)
    const { courseDetail, courseProfList, addCourseProfDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [isAddCourseProficiencyEnabled, setIsAddCourseProficiencyEnabled] = useState(false)
    const [courseProficiencyList, setCourseProficiencyList] = useState([])


    useEffect(() => {
        if (inputSections?.COURSE_PROFICIENCY?.value === clickedItem) {
            dispatcher(getMasterProficiencyList())
            dispatcher(getCourseProficiencyList({ course: courseDetail?.courseDetail?.id }))
        }

        return () => {
            dispatcher(setClearProfList())
            dispatcher(setClearCourseProficiencyList())
            dispatcher(setClearAddCourseProfDetail())
        }
    }, [clickedItem])

    useEffect(() => {
        if (courseProfList?.courseProfList?.results?.length > 0) {
            setCourseProficiencyList(courseProfList?.courseProfList?.results)
            setIsAddCourseProficiencyEnabled(true)
        }
    }, [courseProfList?.courseProfList])

    const isProficiencyListSimilar = JSON.stringify(courseProficiencyList) === JSON.stringify(courseProfList?.courseProfList?.results)

    const isOpen = inputSections?.COURSE_PROFICIENCY?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_PROFICIENCY?.value)) {
            setClickedItem(inputSections?.COURSE_PROFICIENCY?.value)
        } else {
            setIsAddCourseProficiencyEnabled(false)
            setClickedItem(null)
        }
    }

    const onHandleSelectProficiency = (option) => {
        const filteredProficiencyList = courseProficiencyList?.filter((courseProficiency) => courseProficiency?.masterProficiency?.proficiency === option)

        if (filteredProficiencyList?.length > 0) {
            toast.warn("Duplicate Proficiency are not allowed!")
            return;
        }
        dispatcher(createCourseProficiencyDetail({ course: courseDetail?.courseDetail?.id, proficiency: option }))
    }

    const onRemoveProficiency = (courseProfId) => {
        dispatcher(deleteCourseProficiencyDetail(courseProfId))
    }

    const getChangedPos = (currentPos, newPos) => {
        var updatedList = [...courseProficiencyList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State
        setCourseProficiencyList(updatedList);
    };

    const onHandleUpdateOrder = () => {
        if (isProficiencyListSimilar || addCourseProfDetail?.isLoading) return;

        if ((courseProfList?.courseProfList?.results?.length <= 1)) return;

        const requestDataPayload = {
            query: { course: courseDetail?.courseDetail?.id },
            body: {
                order: courseProficiencyList?.map((proficiency) => ({
                    id: proficiency?.id
                }))
            }
        }


        dispatcher(updateCourseProficiencyListOrder(requestDataPayload))
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Level of difficulty"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {proficiencyTooltip}
                    </span>
                </div>
                {courseProfList?.isLoading &&
                    <ComponentLoader isLoading={courseProfList?.isLoading} />
                }
                {courseProfList?.ErrorMsg &&
                    <div className={"flex items-center justify-start font-bodyPri font-normal text-red-500 text-base"}>
                        {courseProfList?.errorMsg}
                    </div>
                }
                {(!isAddCourseProficiencyEnabled && !courseProfList?.isLoading) &&
                    <div className={cn(
                        "w-full px-3 py-3 my-2 flex items-center justify-center border border-dashed border-text-500 rounded-lg hover:shadow-all",
                        "font-bodyPri font-normal text-text-900 cursor-pointer"
                    )}
                        onClick={() => setIsAddCourseProficiencyEnabled(true)}
                    >
                        <MdAdd className={"text-xl text-text-900"} />
                        <span className={"text-xl"}>
                            {"Add level of difficulty"}
                        </span>
                    </div>
                }
                {(isAddCourseProficiencyEnabled && !courseProfList?.isLoading) &&
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <div className={cn(
                            "w-full px-3 py-3 my-2 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
                            "font-bodyPri font-normal text-text-900"
                        )}>
                            {(profList?.isLoading || addCourseProfDetail?.isLoading) &&
                                <ComponentLoader isLoading={profList?.isLoading || addCourseProfDetail?.isLoading} />
                            }
                            {(!courseProfList?.isLoading && !addCourseProfDetail?.isLoading) &&
                                <div className={"flex flex-wrap items-start justify-start gap-3"}>
                                    <Draggable onPosChange={getChangedPos}>
                                        {courseProfList?.courseProfList?.results?.length > 0 &&
                                            courseProfList?.courseProfList?.results?.map((courseProficiency, idx) => (
                                                <div
                                                    key={idx}
                                                    className={'w-max px-3 py-1 border-2 border-text-300 flex items-center justify-start gap-1.5 rounded-xl'}
                                                >
                                                    <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                                        {courseProficiency?.masterProficiency?.proficiency}
                                                    </span>
                                                    <button className="pl-3 cursor-pointer">
                                                        <FaTimes className="font-light text-secondary-dark" onClick={() => onRemoveProficiency(courseProficiency?.id)} />
                                                    </button>
                                                </div>
                                            ))}
                                    </Draggable>
                                    {(!profList?.isLoading && profList?.profList) &&
                                        <div className={cn(
                                            "w-52 h-32 md:h-48 z-[500] overflow-y-scroll rounded-lg",
                                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                        )}>
                                            <OptionSelector
                                                options={profList?.profList?.map((proficiency) => ({
                                                    label: proficiency?.proficiency,
                                                    value: proficiency?.proficiency
                                                }))}
                                                className={""}
                                                value={""}
                                                onChange={(option) => onHandleSelectProficiency(option?.value)}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            {addCourseProfDetail?.errorMsg &&
                                <div className={"font-bodyPri font-normal text-red-500 text-base"}>
                                    {addCourseProfDetail?.errorMsg}
                                </div>
                            }
                        </div>

                        <div className={"flex items-center justify-start gap-1"}>
                            <div className={cn(
                                "w-fit px-3 py-0.5 rounded-lg",
                                "font-bodyPri font-normal text-text-800 text-sm",
                                isProficiencyListSimilar && "border border-text-300 bg-text-300 cursor-not-allowed",
                                !isProficiencyListSimilar && "border border-green-500 text-green-500 hover:bg-green-700 hover:text-text-50 cursor-pointer"
                            )}
                                onClick={onHandleUpdateOrder}
                            >
                                {"Save Display Order"}
                            </div>
                        </div>
                    </div>
                }
            </CollapsableContainer>
        </div>
    )
}

export default CourseProficiencySection