import { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";

import { FaInfoCircle } from 'react-icons/fa';
import { IoMdArrowRoundBack } from 'react-icons/io';

import ToolTipView from 'components/toolTip/index';

import { weeklyScheduleToolTip } from "pages/auth/editUser/data";

import { cn } from 'utils/cn.utils';
import { timeZone, getTimeZoneOffset, dayjs } from 'utils/dateTime.utils';

const AvailabilityHeader = memo(({ headerText = "Create Availability" }) => {
    const navigate = useNavigate()

    const [current, setCurrent] = useState(dayjs().tz(timeZone))

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(dayjs().tz(timeZone))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    return (
        <div className={"w-full py-3 space-y-5"}>
            <div className={"w-full flex flex-col gap-5 lg:flex-row lg:items-center lg:justify-between lg:gap-5"}>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                    className={cn(
                        "w-fit flex items-center justify-start gap-1.5 py-1 px-3 text-secondary-dark text-sm md:text-base border border-secondary-dark group hover:bg-secondary-dark rounded",
                        "font-bodyPri font-normal text-sm sm:text-base ease-in-out duration-200"
                    )}
                    onClick={() => navigate(-1)}
                >
                    <IoMdArrowRoundBack className={"text-lg group-hover:text-text-50"} />
                    <span className={"group-hover:text-text-50"}>
                        {"Back"}
                    </span>
                </motion.button>
                <div className={"w-full flex flex-col justify-start gap-5 md:flex-row md:items-center md:justify-between md:gap-3"}>
                    <div className={"flex-1 flex items-center justify-start lg:justify-center gap-1.5"}>
                        <span className={"font-subHeading font-medium text-lg md:text-xl lg:text-2xl text-text-800 line-clamp-1"}>
                            {headerText}
                        </span>
                        <ToolTipView content={weeklyScheduleToolTip}>
                            <button className={"cursor-pointer"}>
                                <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                            </button>
                        </ToolTipView>
                    </div>
                    <span className={"font-bodyPri font-normal text-xs sm:text-sm text-text-700 line-clamp-1"}>
                        {current.format("ddd, DD MMM YY hh:mm:ss A")} - [{timeZone} ({getTimeZoneOffset(timeZone)})]
                    </span>
                </div>
            </div>
            <div className={"h-0.5 bg-gray-300 w-full"}></div>
        </div>
    );
})

export default AvailabilityHeader;