import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import chargeBeeService from "redux/chargeBee/chargeBee.service";
import {
    setUserItemListLoading,
    setUserItemList,
    setUserItemListErrorMsg,

    setAddUserItemDetailLoading,
    setAddUserItemDetail,
    setAddUserItemDetailErrorMsg,

    setUserItemDetailLoading,
    setUserItemDetail,
    setUserItemDetailErrorMsg,

    setItemPriceListLoading,
    setItemPriceList,
    setItemPriceListErrorMsg,

    setItemPriceDetailLoading,
    setItemPriceDetail,
    setItemPriceDetailErrorMsg,

    setAddItemPriceDetailLoading,
    setAddItemPriceDetail,
    setAddItemPriceDetailErrorMsg
} from "redux/chargeBee/chargeBee.slice";
import { chargeBeeRedux } from "./chargeBee.const";

export const getUserItemListByUserId = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.userItemList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setUserItemListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await chargeBeeService.getUserItemListByUserId(requestData)
            if (response.status === 200) {
                dispatch(setUserItemList(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setUserItemListErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setUserItemListLoading(false))
        }
    }
)

export const getUserItemDetailByUserItemId = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.userItemDetail?.requestName}`,
    async (userItemId, { dispatch }) => {
        dispatch(setUserItemDetailLoading(true))

        try {
            const requestData = {
                params: { userItemId: userItemId }
            }
            const response = await chargeBeeService.getUserItemDetailByUserItemId(requestData)
            if (response.status === 200) {
                dispatch(setUserItemDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setUserItemDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setUserItemDetailLoading(false))
        }
    }
)


export const createUserItemDetail = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.userItemDetail?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddUserItemDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await chargeBeeService.createUserItemDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddUserItemDetail(response.data.data))
                dispatch(getUserItemListByUserId({ userId: body?.userId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddUserItemDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddUserItemDetailLoading(false))
        }
    }
)

export const updateUserItemDetailByUserItemId = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.userItemDetail?.requestName}`,
    async (userItemDetail, { dispatch, getState }) => {
        dispatch(setAddUserItemDetailLoading(true))

        try {
            const { user } = getState().user
            const requestData = {
                params: { userItemId: userItemDetail?.userItemId },
                body: userItemDetail?.body
            }
            const response = await chargeBeeService.updateUserItemDetailByUserItemId(requestData)
            if (response.status === 200) {
                dispatch(setAddUserItemDetail(response.data.data))
                dispatch(getUserItemDetailByUserItemId(userItemDetail?.userItemId))
                dispatch(getUserItemListByUserId({ userId: user?.user?.userId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddUserItemDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddUserItemDetailLoading(false))
        }
    }
)

export const deleteUserItemDetailByUserItemId = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.userItemDetail?.requestName}`,
    async (userItemId, { dispatch, getState }) => {
        dispatch(setAddUserItemDetailLoading(true))

        try {
            const { user } = getState().user
            const requestData = {
                params: { userItemId: userItemId }
            }
            const response = await chargeBeeService.deleteUserItemDetailByUserItemId(requestData)
            if (response.status === 200) {
                // dispatch(setAddUserItemDetail(response.data.data))
                dispatch(getUserItemListByUserId({ userId: user?.user?.userId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddUserItemDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddUserItemDetailLoading(false))
        }
    }
)

export const getItemPriceList = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.itemPriceList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setItemPriceListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await chargeBeeService.getItemPriceList(requestData)
            if (response.status === 200) {
                dispatch(setItemPriceList(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setItemPriceListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setItemPriceListLoading(false))
        }
    }
)

export const getItemPriceDetailByItemPriceId = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.itemPriceDetail?.requestName}`,
    async (itemPriceId, { dispatch }) => {
        dispatch(setItemPriceDetailLoading(true))

        try {
            const requestData = {
                params: { itemPriceId: itemPriceId }
            }
            const response = await chargeBeeService.getItemPriceDetailByItemPriceId(requestData)
            if (response.status === 200) {
                dispatch(setItemPriceDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setItemPriceDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setItemPriceDetailLoading(false))
        }
    }
)

export const createItemPriceDetail = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.itemPriceDetail?.requestName}`,
    async (body, { dispatch, getState }) => {
        dispatch(setAddItemPriceDetailLoading(true))

        try {
            const { user } = getState().user
            const requestData = {
                body: body
            }
            const response = await chargeBeeService.createItemPriceDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddItemPriceDetail(response.data.data))
                dispatch(getItemPriceList({ userId: user?.user?.userId }))
                toast.success(response.data.message)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddItemPriceDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setAddItemPriceDetailLoading(false))
        }
    }
)

export const updateItemPriceDetailByItemPriceId = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.itemPriceDetail?.requestName}`,
    async (itemPriceDetail, { dispatch, getState }) => {
        dispatch(setAddItemPriceDetailLoading(true))

        try {
            const { user } = getState().user
            const requestData = {
                params: { itemPriceId: itemPriceDetail?.itemPriceId },
                body: itemPriceDetail?.body
            }
            const response = await chargeBeeService.updateItemPriceDetailByItemPriceId(requestData)
            if (response.status === 200) {
                dispatch(setAddItemPriceDetail(response.data.data))
                dispatch(getItemPriceList({ userId: user?.user?.userId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddItemPriceDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddItemPriceDetailLoading(false))
        }
    }  
)

export const deleteItemPriceDetailByItemPriceId = createAsyncThunk(
    `${chargeBeeRedux?.sliceName}/${chargeBeeRedux?.itemPriceDetail?.requestName}`,
    async (itemPriceId, { dispatch, getState }) => {
        dispatch(setAddItemPriceDetailLoading(true))

        try {
            const { user } = getState().user
            const requestData = {
                params: { itemPriceId: itemPriceId }
            }
            const response = await chargeBeeService.deleteItemPriceDetailByItemPriceId(requestData)
            if (response.status === 200) {
                dispatch(getItemPriceList({ userId: user?.user?.userId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddUserItemDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddUserItemDetailLoading(false))
        }
    }
)