import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./lmsArticle.const";

class LmsArticleService {

    static lmsArticleService = new LmsArticleService()

    getLmsArticleList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LMS_ARTICLE_LIST),
            { params: query }
        )

        return response;
    }

    deleteLmsArticleDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LMS_ARTICLE_DETAIL, params)
        )

        return response;
    }
}

export default LmsArticleService.lmsArticleService;