import actionTypes from 'redux/pageInfo/pageInfo.action-type';

const setCurrentPageInfo = (currentPageInfo) => ({
    type: actionTypes.SET_CURRENT_PAGE_INFO,
    payload: currentPageInfo,
});

const setPreviousPageInfo = (previouspageInfo) => ({
    type: actionTypes.SET_PREVIOUS_PAGE_INFO,
    payload: previouspageInfo,
});


const PageInfoAction = {
    setCurrentPageInfo,
    setPreviousPageInfo,
};

export default PageInfoAction