import { useState } from 'react';
import {
    AccordionContainer,
    Question,
    QuestionText,
    ArrowToggleIcon,
    Answer,
} from './AccordionStyle';

const AccordionItem = ({ index, itemFAQ }) => {
    const [clickedItemIndex, setClickedItemIndex] = useState(null);

    const isOpen = clickedItemIndex === index;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    return (
        <AccordionContainer>
            <Question
                onClick={() => setClickedItemIndex(isOpen ? null : index)}
            >
                <QuestionText>{itemFAQ.question}</QuestionText>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </Question>
            <Answer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {itemFAQ.answer}
            </Answer>
        </AccordionContainer>
    )
}

export default AccordionItem;
