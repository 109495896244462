import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    GET_LMS_ARTICLE_LIST: "lms/articles/articles",
    DELETE_LMS_ARTICLE_DETAIL: "lms/articles/articles/{articleId}"
}

export const lmsArticleRedux = {
    sliceName: "lmsArticle",
    getLmsArticleList: {
        requestName: "getLmsArticleList"
    }
}

export const lmsArticleStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const lmsArticleShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsArticleVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsArticleInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const DEFAULT_LMS_ARTICLE_PAGE = 1

export const DEFAULT_LMS_ARTICLE_RECORDS = dataEntriesConst.TWENTY_FIVE.value

export const lmsArticleFilterProps = {
    page: DEFAULT_LMS_ARTICLE_PAGE,
    records: DEFAULT_LMS_ARTICLE_RECORDS,
    searchText: "",
    sortBy: "",
    articleId: "",
    articleTitle: "",
    articleStatus: "",
    articleVisibility: "",
    ownerTutor: "",
    date: ""
}