import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    PUBLIC_COURSE_DETAIL: "/courses/courses/{slug}/public",

    COURSE_TEMPLATE_LIST: "/courses/templates",
    COURSE_TEMPLATE_DETAIL: "/courses/templates/{courseTemplateId}",

    COURSE_DETAIL: "/courses/courses/{courseId}",
    CREATE_COURSE_DETAIL: "/courses/courses",

    COURSE_TAG_LIST: "/courses/tags",
    COURSE_TAG_DETAIL: "/courses/tags/{courseTagId}",
    COURSE_TAG_LIST_ORDER: "/courses/tags/order",

    COURSE_PROFICIENCY_LIST: "/courses/proficiencies",
    COURSE_PROFICIENCY_DETAIL: "/courses/proficiencies/{courseProficiencyId}",
    COURSE_PROFICIENCY_LIST_ORDER: "/courses/proficiencies/order",

    COURSE_LANGUAGE_LIST: "/courses/languages",
    COURSE_LANGUAGE_DETAIL: "/courses/languages/{courseLanguageId}",
    COURSE_LANGUAGE_LIST_ORDER: "/courses/languages/order",

    COURSE_AVAIL_DETAIL: "/courses/availabilities/{courseAvailabilityId}",
    CREATE_COURSE_AVAIL_DETAIL: "/courses/availabilities",

    COURSE_PRICE_LIST: "/courses/prices",
    COURSE_PRICE_DETAIL: "/courses/prices/{coursePriceId}",

    GET_COURSE_COMMENT_LIST: "/courses/comments/",
    CREATE_COURSE_COMMENT_DETAIL: "/courses/comments/",

    GET_COURSE_PREVIEW_DETAIL: "/courses/courses/{slug}/preview",

    CREATE_NEXT_COURSE_SESSION_DETAIL: "/courses/courses/{courseId}/session",

    GET_COURSE_TUTOR_LIST: "/courses/tutors",
    GET_COURSE_TUTOR_DETAIL: "/courses/tutors/{courseTutorId}",
    CREATE_COURSE_TUTOR_DETAIL: "/courses/tutors",
    UPDATE_COURSE_TUTOR_DETAIL: "/courses/tutors/{courseTutorId}",
    DELETE_COURSE_TUTOR_DETAIL: "/courses/tutors/{courseTutorId}",

    GET_COURSE_CONFIG_DETAIL: "/courses/configs/{courseConfigId}",
    CREATE_COURSE_CONFIG_DETAIL: "/courses/configs",
    UPDATE_COURSE_CONFIG_DETAIL: "/courses/configs/{courseConfigId}",
    DELETE_COURSE_CONFIG_DETAIL: "/courses/configs/{courseConfigId}",

    COURSE_LIST: "/courses/courses",

    CLONE_COURSE_DETAIL: "/courses/courses/{courseId}/clone",

    // OLD
    ADD_COURSE: "offerings/add/tutor/{tutorId}",

}

export const courseRedux = {
    sliceName: "course",
    courseList: {
        requestName: "getCoursePublicList"
    },
    createCourse: {
        requestName: "createCourse"
    },
    courseFilteredList: {
        requestName: "getCourseFilteredList"
    },

    publicCourseDetail: {
        requestName: "getPublicCourseDetail"
    },
    selectedPublicCourseDetail: {
        requestName: "getSelectedPublicCourseDetail"
    },
    courseTemplateList: {
        requestName: "getCourseTemplateList"
    },
    courseTemplateDetail: {
        requestName: "getCourseTemplateDetail"
    },
    courseDetail: {
        requestName: "getCourseDetail"
    },
    courseTagList: {
        requestName: "getCourseTagList"
    },
    courseLanguageList: {
        requestName: "getCourseLanguageList"
    },
    courseProfList: {
        requestName: "getCourseProficiencyList"
    },
    courseAvailDetail: {
        requestName: "getCourseAvailabilityDetail"
    },
    coursePriceDetail: {
        requestName: "getCoursePriceDetail"
    },
    courseCommentList: {
        requestName: "getCourseCommentList"
    },
    coursePreviewDetail: {
        requestName: "getCoursePreviewDetail"
    },
    courseTutorList: {
        requestName: "getCourseTutorList"
    },
    courseTutorDetail: {
        requestName: "getCourseTutorDetail"
    },
    courseConfigDetail: {
        requestName: "getCourseConfigDetail"
    },
    cloneCourseDetail: {
        requestName: "createCloneCourseDetail"
    }
}

export const tempCourseType = {
    ALL: {
        value: "all",
        label: "All"
    },
    ONE_ON_ONE: {
        value: "one_on_one",
        label: "One on One"
    },
    GROUP: {
        value: "group",
        label: "Group"
    }
}

export const courseStatus = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    UNDER_REVIEW: {
        value: 'under_review',
        label: 'Under Review',
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
}

export const courseType = {
    ALL: {
        label: "All",
        value: "all"
    },
    ONE_ON_ONE: {
        label: "One on One",
        value: "one_on_one"
    },
    GROUP: {
        label: "Group",
        value: "group"
    }
}

export const coursePriceType = {
    REGULAR: {
        label: "Regular",
        plan: "regular"
    },
    PLAN: {
        label: "Plan",
        value: "plan"
    }
}

export const bookingType = {
    REGULAR: {
        label: 'Regular',
        value: 'regular'
    },
    TRIAL: {
        label: 'Trial',
        value: 'trial'
    },
    ON_CHAT: {
        label: 'On Chat',
        value: 'on_chat',
    }
}

export const visibilityType = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    },
    HIDDEN: {
        label: "Hidden",
        value: "hidden"
    }
}

export const pricingModelType = {
    FLAT_FEE: {
        label: "Flat Fee",
        value: "flat_fee"
    },
    PER_HOUR: {
        label: "Per Hour",
        value: "per_hour"
    },
    PER_SESSION: {
        label: "Per Session",
        value: "per_session"
    }
}

export const courseSortBy = {
    RELEVANCE: {
        label: "Relevance",
        value: "relevance"
    },
    PRICELOWFIRST: {
        label: "Price lowest first",
        value: "priceLowFirst"
    },
    PRICEHIGHFIRST: {
        label: "Price highest first",
        value: "priceHighFirst"
    }
}

export const priceType = {
    REGULAR: {
        label: "Regular",
        value: "regular"
    },
    PLAN: {
        label: "Subscription plan",
        value: "plan"
    }
}

export const weekOptions = {
    SUNDAY: {
        label: "Sun",
        value: "Sunday"
    },
    MONDAY: {
        label: "Mon",
        value: "Monday"
    },
    TUESDAY: {
        label: "Tue",
        value: "Tuesday"
    },
    WEDNESDAY: {
        label: "Wed",
        value: "Wednesday"
    },
    THURSDAY: {
        label: "Thu",
        value: "Thursday"
    },
    FRIDAY: {
        label: "Fri",
        value: "Friday"
    },
    SATURDAY: {
        label: "Sat",
        value: "Saturday"
    }
}

export const repeatSessionConst = {
    RECURRING: {
        label: "Repeat weekly",
        value: "recurring"
    },
    NON_RECURRING: {
        label: "Doesn't repeat",
        value: "non_recurring"
    }
}

export const weekAvailTimeSlots = {
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: []
}

export const endType = {
    FOREVER: {
        label: "Never",
        value: "forever"
    },
    ON_DATE: {
        label: "Select a Date",
        value: "on_date"
    },
    WEEKLY_CYClE: {
        label: "Weekly Cycles",
        value: "weekly_cycle"
    }
}

export const courseIsRecurringEnum = {
    RECURRING: {
        label: "Repeat weekly",
        value: "recurring"
    },
    NON_RECURRING: {
        label: "Doesn't repeat",
        value: "non_recurring"
    }
}

export const courseEndTypeEnum = {
    FOREVER: {
        label: "Never",
        value: "forever"
    },
    ON_DATE: {
        label: "Select a Date",
        value: "on_date"
    },
    WEEKLY_CYClE: {
        label: "Weekly Cycles",
        value: "weekly_cycle"
    }
}

export const coursePriceTypeEnum = {
    REGULAR: {
        label: "Regular",
        value: "regular"
    },
    PLAN: {
        label: "Subscription plan",
        value: "plan"
    }
}

export const coursePricingModelTypeEnum = {
    FLAT_FEE: {
        label: "Flat Fee",
        value: "flat_fee"
    },
    PER_HOUR: {
        label: "Per Hour",
        value: "per_hour"
    },
    PER_SESSION: {
        label: "Per Session",
        value: "per_session"
    }
}

export const courseTutorVisibilityTypeEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    },
    HIDDEN: {
        label: "Hidden",
        value: "hidden"
    }
}

export const courseTutorStatus = {
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    IN_ACTIVE: {
        label: "Inactive",
        value: "in_active"
    }
}

export const courseFeeTypeEnum = {
    FIXED: {
        label: "Fixed",
        value: "fixed"
    },
    PERCENTAGE: {
        label: "Percentage",
        value: "percentage"
    }
}

export const courseTutorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const courseTutorRoleEnum = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    CO_TUTOR: {
        label: "Co Tutor",
        value: "co_tutor"
    },
    CONTRACT: {
        label: "Contract",
        value: "contract"
    }
}

export const courseTutorFeeModelEnum = {
    PER_SESSION: {
        label: "Per Session",
        value: "per_session"
    },
    PER_HOUR: {
        label: "Per Hour",
        value: "per_hour"
    }
}

export const courseTutorAutoPayEnum = {
    YES: {
        label: "yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const addCourseTutorDetailPayload = {
    courseTutorId: null,
    courseId: null,
    tutorId: null,
    visibility: "",
    permission: "",
    tutorRole: "",
    status: "",
    feeModel: "",
    isAutoPay: "",
    feeType: "",
    currencyCode: "",
    amount: 0,
    percentage: 0,
    isEditCourseTutorEnabled: false
}

export const filterName = {
    FIND_COURSES: {
        value: "find_courses"
    },
    ALL_COURSES: {
        value: "all_courses"
    },
    FAV_COURSES: {
        value: "fav_courses"
    }
}

export const courseActionRequired = {
    NA: {
        label: "NA",
        value: "na",
    },
    USER: {
        label: "USER",
        value: "user",
    },
    ADMIN: {
        label: "ADMIN",
        value: "admin",
    },
}

export const courseFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    courseType: "",
    bookingType: "",
    status: "",
    courseId: "",
    segment: "",
    category: "",
    tutor: "",
    courseTitle: "",
    price: "",
    startDate: "",
    endDate: "",
    actionRequired: courseActionRequired.NA.value,
    page: 1,
    records: dataEntriesConst?.TWENTY_FIVE?.value
}