import { dayjs } from 'utils/dateTime.utils';

import {
    UserProfileImage,
    SearchOnboardingId,
    SearchUserId,
    SearchUserName,
    SearchEmail,
    SearchPhone,
    FilterTutorOnboardingStatus,
    SearchDate,
    TutorOnboardingButtonAction,
    FilterActionRequired,
    SearchModifiedDate
} from "pages/auth/onboarding/components/tutorOnboarding/FilterTutorOnboardingStatistics";

import {
    SearchCompanyId,
    SearchCompanyName,
    SearchCompanyEmail,
    FilterCompanyOnboardingStatus,
    SearchApplyDate,
    CompanyOnboardingButtonAction
} from "pages/auth/onboarding/components/companyOnboarding/FilterCompanyOnboardingStatistics";

export const pageHeading = {
    heading: "Onboarding",
    subHeading: "Find the list of tutors and company!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-tutors.svg"
}

export const onboardingTabs = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    COMPANY: {
        label: "Company",
        value: "company"
    }
}

export const tutorOnboardingHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    ONB_ID: {
        label: "Onb ID",
        value: "onb_id"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    PHONE: {
        label: "Phone",
        value: "phone"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    ACTION: {
        label: "Action",
        value: "status"
    },
    MODIFIED_DATE: {
        label: "Modified Date",
        value: "modified_date"
    }
}

export const subRowHeader = [
    <UserProfileImage />,
    <SearchOnboardingId />,
    <SearchUserId />,
    <SearchUserName />,
    <SearchEmail />,
    <SearchPhone />,
    <FilterTutorOnboardingStatus />,
    <SearchDate />,
    <TutorOnboardingButtonAction />,
    <FilterActionRequired />,
    <SearchModifiedDate />
]

export const sortTutorOnboardingLowToHigh = (activeSortHeader, tutor1, tutor2) => {
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.ONB_ID?.value) {
        return (Number(tutor1?.onboardingId) - Number(tutor2?.onboardingId))
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.USER_ID?.value) {
        return (Number(tutor1?.user?.userId) - Number(tutor2?.user?.userId))
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.NAME?.value) {
        return tutor1?.user?.firstName?.toLowerCase()?.localeCompare(tutor2?.user?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.EMAIL?.value) {
        return tutor1?.user?.email?.toLowerCase()?.localeCompare(tutor2?.user?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.PHONE?.value) {
        return tutor1?.user?.mobileNo?.toLowerCase()?.localeCompare(tutor2?.user?.mobileNo?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.STATUS?.value) {
        return tutor1?.status?.toLowerCase()?.localeCompare(tutor2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.DATE?.value) {
        return dayjs(tutor2?.createdAt).isSameOrBefore(tutor1?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(tutor2?.updatedAt).isSameOrBefore(tutor1?.updatedAt) ? 1 : -1
    }
}

export const sortTutorOnboardingHighToLow = (activeSortHeader, tutor1, tutor2) => {
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.ONB_ID?.value) {
        return (Number(tutor2?.onboardingId) - Number(tutor1?.onboardingId))
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.USER_ID?.value) {
        return (Number(tutor2?.user?.userId) - Number(tutor1?.user?.userId))
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.NAME?.value) {
        return tutor2?.user?.firstName?.toLowerCase()?.localeCompare(tutor1?.user?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.EMAIL?.value) {
        return tutor2?.user?.email?.toLowerCase()?.localeCompare(tutor1?.user?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.PHONE?.value) {
        return tutor2?.user?.mobileNo?.toLowerCase()?.localeCompare(tutor1?.user?.mobileNo?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.STATUS?.value) {
        return tutor2?.status?.toLowerCase()?.localeCompare(tutor1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.DATE?.value) {
        return dayjs(tutor1?.createdAt).isSameOrBefore(tutor2?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === tutorOnboardingHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(tutor1?.updatedAt).isSameOrBefore(tutor2?.updatedAt) ? 1 : -1
    }
}

export const tutorOnboardingList = [
    {
        userId: 226,
        firstName: "Geet",
        lastName: "Mahana",
        profilePicUrl: "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
        email: "geet@edulyte.com",
        isEmailVerified: true,
        isMobileVerified: false,
        countryCode: "91",
        mobileNo: "0123456789",
        status: "submitted",
        countryDomain: "in",
        createdAt: "2022-02-08T11:22:09.277252Z"
    },
    {
        userId: 227,
        firstName: "Virat",
        lastName: "Kohli",
        profilePicUrl: "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
        email: "virat@edulyte.com",
        countryCode: "91",
        mobileNo: "9876543210",
        status: "verified",
        countryDomain: "in",
        isEmailVerified: true,
        isMobileVerified: false,
        createdAt: "2022-02-14T11:22:09.277252Z"
    },
    {
        userId: 228,
        firstName: "Neil",
        lastName: "Dhar",
        profilePicUrl: "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
        email: "neil@edulyte.com",
        countryCode: "1",
        mobileNo: "4567891230",
        status: "under_review",
        countryDomain: "in",
        isEmailVerified: true,
        isMobileVerified: false,
        createdAt: "2022-02-02T11:22:09.277252Z"
    },
    {
        userId: 229,
        firstName: "vajindar",
        lastName: "kumar",
        profilePicUrl: "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
        email: "vajindar@edulyte.com",
        countryCode: "91",
        mobileNo: "1597864320",
        status: "submitted",
        countryDomain: "in",
        isEmailVerified: true,
        isMobileVerified: false,
        createdAt: "2022-02-21T11:22:09.277252Z"
    },
]

export const personalDetailList = [
    {
        userId: 226,
        title: "Mrs",
        firstName: "geet",
        lastName: "mahana",
        preferredName: "geet",
        gender: "female",
        dateOfBirth: "2022-02-21T11:22:09.277252Z",
        email: "geet@edulyte.com",
        mobileNo: "0123456789",
        countryCode: "91",
        homePhone: "123456789123123",
        workPhone: "0123457896523101",
        address: {
            streetAddress: "142, Palm Avenue",
            country: "Australia",
            state: "victoria",
            city: "melbourne",
            zipCode: "123456",
            postalCode: "07894561"
        }
    }
]

export const QuestionList = [
    {
        questionId: 1,
        question: "What are the top three subjects / skills / categories / topics you can teach?",
        questionType: "text"
    },
    {
        questionId: 2,
        question: "Would you like to create self-paced courses or teach Live?",
        questionType: "radio",
        options: [
            {
                optionId: 1,
                label: "I'll create self-paced courses",
            },
            {
                optionId: 2,
                label: "I'll teach live",
            },
            {
                optionId: 3,
                label: "I can do both",
            }
        ]
    },
    {
        questionId: 3,
        question: "Do you have a computer/laptop with a high-speed internet connection?",
        questionType: "radio",
        options: [
            {
                optionId: 4,
                label: "Yes"
            },
            {
                optionId: 5,
                label: "No"
            }
        ]
    },
    {
        questionId: 4,
        question: 'Do you have any experience in teaching online?',
        questionType: "radio",
        options: [
            {
                optionId: 6,
                label: "Yes",
            },
            {
                optionId: 7,
                label: "No",
            },
            {
                optionId: 8,
                label: "Not Sure, I can learn quickly"
            }
        ]
    },
    {
        questionId: 5,
        question: "How many hours per week can you commit? suggested weekly availability",
        questionType: "checkbox",
        options: [
            {
                optionId: 9,
                label: "Full Time",
            },
            {
                optionId: 10,
                label: "10 hours per week",
            },
            {
                optionId: 11,
                label: "20 hours per week",
            },
            {
                optionId: 12,
                label: "30 hours per week",
            },
            {
                optionId: 13,
                label: "Weekends Only (Min 5 hours)",
            }
        ]
    },
]

export const AnswersList = [
    {
        userId: 226,
        answers: [
            {
                questionId: 1,
                answer: "I can teach hindi, english, french"
            },
        ]
    }
]

export const professionalReferencesList = [
    {
        userId: 226,
        references: [
            {
                referenceId: 2,
                name: "vajinder kumar",
                relationship: "super_visor",
                knownYears: 3,
                knownMonths: 5,
                countryCode: "91",
                mobileNo: "0123456789",
                email: "vajinder@edulyte.com",
                isEmailSent: true,
                isPrivacyEnabled: false,
                status: "under_review"
            },
            {
                referenceId: 3,
                name: "virat kohli",
                relationship: "manager",
                knownYears: 1,
                knownMonths: 8,
                countryCode: "91",
                mobileNo: "1234567890",
                email: "virat@edulyte.com",
                isEmailSent: false,
                isPrivacyEnabled: true,
                status: "submitted"
            },
        ],
    },
    {
        userId: 227,
        references: [
            {
                referenceId: 4,
                name: "geet mahana",
                relationship: "manager",
                knownYears: 7,
                knownMonths: 8,
                countryCode: "1",
                mobileNo: "7455916166",
                email: "geet@edulyte.com",
                isEmailSent: true,
                isPrivacyEnabled: false,
                status: "verified"
            }
        ]
    },
]

export const documentList = [
    {
        userId: 226,
        documents: [
            {
                fileCategory: 'resume',
                fileName: 'Tanuj_negi.pdf',
                fileType: 'pdf',
                fileUrl: "",
                status: "submitted"
            },
            {
                fileCategory: 'pan_card',
                fileName: 'Tanuj_negi.pdf',
                fileType: 'pdf',
                fileUrl: "",
                status: "verified"
            },
            {
                fileCategory: 'aadhar_card',
                fileName: 'Aadhar_card.pdf',
                fileType: 'pdf',
                fileUrl: "",
                status: "not_submitted"
            },
            {
                fileCategory: 'aadhar_card',
                fileName: 'Aadhar_card.pdf',
                fileType: 'pdf',
                fileUrl: "",
                status: "not_submitted"
            },
        ]
    },
]

export const videoInterviewList = [
    {
        userId: 226,
        interviewList: [
            {
                firstName: "virat",
                lastName: "kohli",
                createdAt: "2022-02-15T11:22:09.277252Z",
                startTime: "2022-02-15T10:22:09.277252Z",
                status: "scheduled"
            },
            {
                firstName: "Geet",
                lastName: "Mahana",
                createdAt: "2022-02-18T11:22:09.277252Z",
                startTime: "2022-02-15T01:22:09.277252Z",
                status: "scheduled"
            },
            {
                firstName: "vajinder",
                lastName: "kumar",
                createdAt: "2022-02-21T11:22:09.277252Z",
                startTime: "2022-02-15T02:22:09.277252Z",
                status: "scheduled"
            },
            {
                firstName: "deepmala",
                lastName: "sharma",
                createdAt: "2022-02-30T11:22:09.277252Z",
                startTime: "2022-02-15T06:22:09.277252Z",
                status: "scheduled"
            },
        ]
    },
    {
        userId: 227,
        interviewList: [
            {
                firstName: "virat",
                lastName: "kohli",
                createdAt: "2022-02-10T11:22:09.277252Z",
                startTime: "2022-02-15T29:22:09.277252Z",
                status: "scheduled"
            },
            {
                firstName: "Geet",
                lastName: "Mahana",
                createdAt: "2022-02-09T11:22:09.277252Z",
                startTime: "2022-02-15T06:22:09.277252Z",
                status: "scheduled"
            },
            {
                firstName: "vajinder",
                lastName: "kumar",
                createdAt: "2022-02-15T11:22:09.277252Z",
                startTime: "2022-02-15T08:22:09.277252Z",
                status: "scheduled"
            },
            {
                firstName: "deepmala",
                lastName: "sharma",
                createdAt: "2022-02-18T11:22:09.277252Z",
                startTime: "2022-02-15T08:22:09.277252Z",
                status: "scheduled"
            },
        ]
    },
]

export const backgroundDetailList = []

export const companyOnboardingHeaderConst = {
    COMPANY_ID: {
        label: "Company ID",
        value: "company_id"
    },
    COMPANY_NAME: {
        label: "Company Name",
        value: "company_name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTION: {
        label: "Action",
        value: "action"
    }
}

export const companyOnboardingSubRowHeader = [
    <SearchCompanyId />,
    <SearchCompanyName />,
    <SearchCompanyEmail />,
    <FilterCompanyOnboardingStatus />,
    <SearchApplyDate />,
    <CompanyOnboardingButtonAction />
]

export const sortCompanyOnboardingLowToHigh = (activeSortHeader, company1, company2) => {
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.COMPANY_ID?.value) {
        return (Number(company2?.companyId) - Number(company1?.companyId))
    }
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.COMPANY_NAME?.value) {
        return company2?.firstName?.toLowerCase()?.localeCompare(company1?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.EMAIL?.value) {
        return company2?.email?.toLowerCase()?.localeCompare(company1?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.STATUS?.value) {
        return company2?.status?.toLowerCase()?.localeCompare(company1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.DATE?.value) {
        return;
    }
}

export const sortCompanyOnboardingHighToLow = (activeSortHeader, company1, company2) => {
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.COMPANY_ID?.value) {
        return (Number(company1?.companyId) - Number(company2?.companyId))
    }
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.COMPANY_NAME?.value) {
        return company1?.firstName?.toLowerCase()?.localeCompare(company2?.firstName?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.EMAIL?.value) {
        return company1?.email?.toLowerCase()?.localeCompare(company2?.email?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.STATUS?.value) {
        return company1?.status?.toLowerCase()?.localeCompare(company2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === companyOnboardingHeaderConst?.DATE?.value) {
        return;
    }
}

export const generateCompanyList = (count) => {
    const companies = [];
    for (let i = 0; i < count; i++) {
        companies.push({
            currentPage: i + 1,
            totalNoOfPages: 80,
            list: [
                {
                    companyId: 300 + i,
                    firstName: `company ${i + 1}`,
                    lastName: "1",
                    email: `company${i + 1}@gmail.com`,
                    status: "not_submitted",
                    createdAt: "2022-02-18T11:22:09.277252Z"
                },
                {
                    companyId: 301 + i,
                    firstName: `company ${i + 2}`,
                    lastName: "1",
                    email: `company${i + 2}@gmail.com`,
                    status: "submitted",
                    createdAt: "2022-02-15T08:22:09.277252Z"
                },
                {
                    companyId: 302 + i,
                    firstName: `company ${i + 3}`,
                    lastName: "1",
                    email: `company${i + 3}@gmail.com`,
                    status: "verified",
                    createdAt: "2022-02-18T11:22:09.277252Z"
                },
                {
                    companyId: 303 + i,
                    firstName: `company ${i + 4}`,
                    lastName: "1",
                    email: `company${i + 4}@gmail.com`,
                    status: "under_review",
                    createdAt: "2022-02-15T08:22:09.277252Z"
                },
                {
                    companyId: 303 + i,
                    firstName: `company ${i + 4}`,
                    lastName: "1",
                    email: `company${i + 4}@gmail.com`,
                    status: "under_review",
                    createdAt: "2022-02-15T08:22:09.277252Z"
                },
            ]
        })
    };

    return companies;
};

export const companyOnboardingList = generateCompanyList(80)

export const companyOnboardingData = []