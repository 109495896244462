import { useState, useEffect } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import RegularPriceTypeContainer from './RegularPriceTypeContainer';
import PlanPriceTypeContainer from './PlanPriceTypeContainer';

import { getCourseAvailabilityDetail, getCoursePriceDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { priceType } from "redux/course/course.const";
import { setClearCourseAvailDetail, setClearCoursePriceDetail } from 'redux/course/course.slice';

import { timeZone } from 'utils/dateTime.utils';

const SetPriceDetailsSection = () => {
    const { courseDetail, courseAvailDetail, coursePriceDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [coursePriceType, setCoursePriceType] = useState('')

    useEffect(() => {
        if (courseDetail?.courseDetail?.courseAvailability?.id) {
            const requestDataPayload = {
                courseAvailabilityId: courseDetail?.courseDetail?.courseAvailability?.id,
                query: { timeZone: timeZone }
            }
            dispatcher(getCourseAvailabilityDetail(requestDataPayload))
        }

        return () => {
            dispatcher(setClearCourseAvailDetail())
        }
    }, [courseDetail?.courseDetail?.courseAvailability])

    useEffect(() => {
        if (courseDetail?.courseDetail?.coursePrice) {
            dispatcher(getCoursePriceDetail(courseDetail?.courseDetail?.coursePrice))
        }

        return () => {
            dispatcher(setClearCoursePriceDetail())
        }
    }, [courseDetail?.courseDetail?.coursePrice])

    useEffect(() => {
        if (coursePriceDetail?.coursePriceDetail) {
            setCoursePriceType(coursePriceDetail?.coursePriceDetail?.type)
        }
    }, [coursePriceDetail?.coursePriceDetail])

    return (
        <div className={"w-full h-full min-h-[60vh] bg-white rounded-lg select-none"}>
            <div className={"w-full px-5 py-3 bg-background-darkLight rounded-lg"}>
                <span className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Price Details"}
                </span>
            </div>
            {(courseAvailDetail?.isLoading || coursePriceDetail?.isLoading)
                && <ComponentLoader isLoading={courseAvailDetail?.isLoading || coursePriceDetail?.isLoading} />
            }
            {(courseAvailDetail?.errorMsg || coursePriceDetail?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {courseAvailDetail?.errorMsg || coursePriceDetail?.errorMsg}
                </div>
            }

            {!coursePriceDetail?.isLoading &&
                <div className={"w-full grid grid-cols-12 gap-5 md:gap-y-8 md:gap-x-3 p-5 md:px-10 md:py-5"}>

                    {/* type according to pricing */}
                    <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3 font-bodyPri font-normal text-text-900 text-base tracking-wide whitespace-nowrap">
                        {"Type:"}
                        <span className={"text-red-500 text-lg"}>{"*"}</span>
                    </span>
                    <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 w-full md:w-80"}>
                        <OptionSelector
                            options={Object.values(priceType)}
                            className={"w-full"}
                            value={coursePriceType}
                        />
                    </div>

                    {(coursePriceType === priceType?.REGULAR?.value) &&
                        <RegularPriceTypeContainer />
                    }
                    {(coursePriceType === priceType?.PLAN?.value) &&
                        <PlanPriceTypeContainer />
                    }
                </div>
            }
        </div>
    )
}

export default SetPriceDetailsSection;