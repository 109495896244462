import { useEffect } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';

import OrderItemProfileSection from './components/OrderItemProfileSection';
import OrderItemProductItemsSection from './components/OrderItemProductItemsSection';
import OrderItemDetailSection from './components/OrderItemDetailSection';

import { getOrderDetail } from 'redux/order/order.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetOrderDetail, resetOrderDetailPayload } from 'redux/order/order.slice';

const OrderSideOverContent = () => {
    const { orderDetail } = useAppState((state) => state.order)
    console.log(orderDetail?.data)

    const dispatcher = useAppDispatcher()

    useEffect(() => {

        return () => {
            dispatcher(resetOrderDetail())
            dispatcher(resetOrderDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (orderDetail?.payload?.orderId) {
            dispatcher(getOrderDetail(orderDetail?.payload?.orderId))
        }
    }, [orderDetail?.payload?.orderId])

    return (
        <div className={"p-5 h-screen sm:px-10 max-w-2xl md:max-w-xl lg:max-w-lg"}>
            <ComponentLoader isLoading={orderDetail?.isLoading} />
            {(orderDetail?.data && !orderDetail?.isLoading) && (
                <div className={"w-full flex flex-col gap-8"}>
                    <OrderItemProfileSection />
                    {/* {(orderDetail?.data?.order_item_product_prices?.length > 0) &&
                        <div className={"w-full flex flex-col gap-3"}>
                            {orderDetail?.data?.order_item_product_prices?.map((orderItemProductPrice, index) => (
                                <OrderItemDetailSection
                                    key={index}
                                    orderItemProductPrice={orderItemProductPrice}
                                />
                            ))}
                        </div>
                    } */}
                    {(orderDetail?.data?.order_items?.length > 0) &&
                        <div className={"w-full flex flex-col gap-3"}>
                            {orderDetail?.data?.order_items?.map((orderProductItem, index) => (
                                <OrderItemProductItemsSection key={index} orderProductItem={orderProductItem} />
                            ))}
                        </div>
                    }
                </div>
            )}
            {(orderDetail?.message) &&
                <div className={"w-full h-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                        {orderDetail?.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default OrderSideOverContent;