import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";
import { leadHeaderConst } from "../data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/lead/lead.slice";
import { JourneyConst, contactTime, leadStatus } from "redux/lead/lead.const";
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const SearchLeads = ({ showEntries, setShowEntries }) => {
    const { leadList, filterProps } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()

    const header = Object.values(leadHeaderConst)
        .filter((headers) => headers?.value !== leadHeaderConst.ACTIVITY.value)
        .map((head) => head?.label)

    const body = leadList?.filteredLeadList?.results?.map((lead) => ({
        [leadHeaderConst?.LEAD_ID?.label]: lead?.leadId,
        [leadHeaderConst?.USER_NAME?.label]: lead?.firstName + " " + lead?.lastName?.charAt(0),
        [leadHeaderConst?.EMAIL?.label]: lead?.email,
        [leadHeaderConst?.MOBILE_NO?.label]: "+" + lead?.isdCode + lead?.mobileNo,
        [leadHeaderConst?.LEAD_TYPE?.label]: JourneyConst[lead?.classFor?.toUpperCase()]?.label,
        [leadHeaderConst?.CONTACT_TIME?.label]: `${contactTime[lead?.contactTime?.toUpperCase()]?.label} (${lead?.timeZone})`,
        [leadHeaderConst?.STATUS?.label]: leadStatus[lead?.status?.toUpperCase()]?.label,
        [leadHeaderConst?.DATE?.label]: dayjs(lead?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        [leadHeaderConst?.ACTION?.label]: "-"
    }))
    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-leads",
            sheet: "Leads",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value },
        }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={showEntries}
                    onChange={(option) => setShowEntries(option.value)}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-leads"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"search here..."}
                        value={""}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={(event) => handleFilterSearch(event)}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchLeads