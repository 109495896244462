import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/referral/referral.const"

class ReferralServices {

    getReferralList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_REFERRAL_LIST),
            { params: query }
        )

        return response;
    }

}
export default new ReferralServices();