import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { PeopleAlt } from '@mui/icons-material';

import {
    ToggleMenuContent,
    Item,
    ItemIcon,
    ItemName
} from './NavBarStyle';

import { IoIosArrowForward } from 'react-icons/io';

import RightLoggedInToggleSubMenu from "./RightLoggedInToggleSubMenu";
import { RightLoggedInMenuItems } from 'components/navbar/RightLoggedInToggleMenu.data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from "utils/pagesInfo";

const RightLoggedInToggleMenu = ({ setIsClick }) => {
    const { locals } = useAppState((s) => s.local)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const handleOnClickItem = (dataItem) => {
        if (!dataItem?.subMenu || dataItem?.subMenu?.length === 0) {
            navigate(dataItem.path)
            setIsClick(false)
        }
        if (dataItem?.subMenu?.length > 0) {
            if (!activeMenuItem || (activeMenuItem !== dataItem?.value)) {
                setActiveMenuItem(dataItem?.value)
            } else {
                setActiveMenuItem(null)
            }
        }
    }

    return (
        <ToggleMenuContent>
            <Item
                className={"pt-2"}
                onClick={() => {
                    navigate(`${pagesInfo?.PROFILE?.pagePath}`)
                    setIsClick(false)
                }}
            >
                <ItemIcon>
                    <PeopleAlt />
                </ItemIcon>
                <ItemName>
                    <div className={"w-full h-full flex flex-col justify-start items-start capitalize"}>
                        <span>
                            {user?.user?.firstName + " " + user?.user?.lastName.charAt(0)}
                        </span>
                        <span className={"text-xs text-primary-main"}>
                            {"My Profile"}
                        </span>
                    </div>
                </ItemName>
            </Item>
            {RightLoggedInMenuItems?.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && (
                <>
                    <Item key={index} onClick={() => handleOnClickItem(dataItem)}>
                        <ItemIcon>
                            <dataItem.icon />
                        </ItemIcon>
                        <ItemName>
                            {dataItem?.name}
                        </ItemName>
                        {(dataItem?.subMenu?.length > 0) &&
                            <div className={"pl-5 flex justify-end"}>
                                <IoIosArrowForward className={cn(
                                    "font-bold text-2xl text-text-600 transition ease-in-out delay-50 duration-100",
                                    (activeMenuItem && (activeMenuItem === dataItem?.value)) && "rotate-90",
                                    (!activeMenuItem || (activeMenuItem && (activeMenuItem !== dataItem?.value))) && "rotate-0",
                                )}
                                />
                            </div>
                        }
                    </Item>
                    {((dataItem?.subMenu?.length > 0) && (activeMenuItem === dataItem?.value)) &&
                        <>
                            {dataItem?.subMenu?.map((menuItem, index) => menuItem?.roles?.includes(locals.userRole) && (
                                <RightLoggedInToggleSubMenu
                                    key={index}
                                    menuItem={menuItem}
                                    setIsClick={setIsClick}
                                />
                            ))}
                        </>
                    }
                </>
            ))}
        </ToggleMenuContent>
    )
}

export default RightLoggedInToggleMenu;