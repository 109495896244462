import { useEffect } from "react";

import { setGetLessonPref, lessonPreferenceToolTip } from "pages/auth/editTutor/data";
import EditTutorHeader from "pages/auth/editTutor/commonComponents/EditTutorHeader";

import { ToggleWithText } from "components/common-components/Toggle";
import ComponentLoader from "components/loader/ComponentLoader";

import { getTutorUserLessonByUserId, updateTutorUserLessonByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearTutorUserLesson } from "redux/tutor/tutor.slice";

export default function EditTutorLesson() {
  const { tutorUserLesson, addTutorUserLesson, tutorDetail } = useAppState((s) => s.tutor)

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    if (tutorDetail?.data?.user?.id) {
      dispatcher(getTutorUserLessonByUserId(tutorDetail?.data?.user?.id))
    }

    return () => {
      dispatcher(setClearTutorUserLesson())
    }
  }, [tutorDetail?.data?.user?.id])


  const updateLessonStatus = (key, value) => {
    const lessonPref = { [key]: value }
    const requestData = {
      userId: tutorDetail?.data?.user?.id,
      body: lessonPref
    }
    dispatcher(updateTutorUserLessonByUserId(requestData))
  }

  return (
    <div className="">
      <EditTutorHeader title={"Lesson Preferences"} tooltipText={lessonPreferenceToolTip} />
      <div className={"p-5 space-y-2 divide-y divide-blue-50"}>
        {(tutorUserLesson?.isLoading || addTutorUserLesson?.isLoading) &&
          <ComponentLoader isLoading={tutorUserLesson?.isLoading || addTutorUserLesson?.isLoading} />
        }
        {(!(tutorUserLesson?.isLoading || addTutorUserLesson?.isLoading) && tutorUserLesson?.tutorUserLesson) &&
          setGetLessonPref(tutorUserLesson?.tutorUserLesson)?.map((lesson, index) => (
            <ToggleWithText
              key={index}
              description={lesson.description}
              onChange={(isToggle) => updateLessonStatus(lesson.key, isToggle)}
              title={lesson.label}
              value={lesson.value}
            />
          ))}
        {(tutorUserLesson?.errorMsg || addTutorUserLesson?.errorMsg) &&
          <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
            {tutorUserLesson?.errorMsg || addTutorUserLesson?.errorMsg}
          </div>
        }
      </div>
    </div>
  );
}
