import { useState, useEffect } from 'react';

import { AnimatePresence } from 'framer-motion';

import { AiOutlineClose } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import ComponentLoader from 'components/loader/ComponentLoader';

import UserAppointmentForm from './UserAppointmentForm';
import { setLocalAppointmentDetail } from 'pages/auth/editTutor/data';

import { getAppointmentDetail } from 'redux/appointment/appointment.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetModifyAppointmentDetail, resetModifyAppointmentDetailPayload, setModifyAppointmentDetailPayload, setAppointmentDetailData, resetAppointmentDetail } from "redux/appointment/appointment.slice";
import { appointmentPriceModelEnum, modifyAppointmentDetailPayload } from "redux/appointment/appointment.const";

import { cn } from "utils/cn.utils";

const AppointmentDetailCard = () => {
    const { tutorDetail } = useAppState((state) => state.tutor)
    const { appointmentDetail, modifyAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)

    useEffect(() => {
        return () => {
            dispatcher(resetAppointmentDetail())
            dispatcher(resetModifyAppointmentDetail())
            dispatcher(resetModifyAppointmentDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (tutorDetail?.data?.appointment?.id) {
            dispatcher(getAppointmentDetail({ appointmentId: tutorDetail?.data?.appointment?.id }))
        }
    }, [tutorDetail?.data?.appointment?.id])

    useEffect(() => {
        if (modifyAppointmentDetail?.data?.result) {
            dispatcher(setAppointmentDetailData(modifyAppointmentDetail?.data))
            setIsEditModeEnabled(false)
            dispatcher(resetModifyAppointmentDetail())
        }
    }, [modifyAppointmentDetail?.data?.result])

    const onHandleEdit = async () => {
        dispatcher(resetModifyAppointmentDetailPayload())
        if (!isEditModeEnabled) {
            const payload = await setLocalAppointmentDetail(appointmentDetail?.data?.result)
            dispatcher(setModifyAppointmentDetailPayload({
                ...modifyAppointmentDetailPayload,
                ...payload
            }))
        }
        setIsEditModeEnabled(!isEditModeEnabled)
    }

    if (appointmentDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={appointmentDetail?.isLoading} />
        )
    }

    return (
        <div className={""}>
            <div className={"w-full p-4 bg-blue-100 rounded-lg shadow-md"}>
                <div className="w-full flex items-center justify-between">
                    <p className="font-bodyPri font-medium text-lg text-text-900 line-clamp-1">
                        {appointmentDetail?.data?.result?.title}
                    </p>
                    <button onClick={onHandleEdit} className={cn(
                        "inline-flex items-center justify-center p-2",
                        "transform rounded-full hover:scale-105"
                    )}>
                        {isEditModeEnabled
                            ? <AiOutlineClose className={"text-xl text-red-500 hover:text-red-700 hover:scale-110"} title={"Close"} />
                            : <FiEdit className={"text-xl text-blue-400 hover:text-blue-600 hover:scale-110"} title={"Edit"} />
                        }
                    </button>
                </div>

                <div className={"font-bodyPri font-normal text-text-800 text-sm truncate line-clamp-2"}>
                    {appointmentDetail?.data?.result?.subtitle}
                </div>
            </div>
            <div className="px-2 divide-y divide-blue-200 bg-blue-50">

                {/* Edit/View existing schedules */}
                <div className={"w-full flex flex-col items-start justify-start gap-3 p-4"}>

                    {/* appointment price and type */}
                    {!isEditModeEnabled &&
                        <div className={"w-full flex items-center justify-start gap-5"}>

                            {(appointmentDetail?.data?.result?.appointment_price?.price_model === appointmentPriceModelEnum.FLAT_FEE.value) && (
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"Price:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                        {appointmentDetail?.data?.result?.appointment_price?.masterCurrency?.code + " " + parseFloat((appointmentDetail?.data?.result?.appointment_price?.price - ((appointmentDetail?.data?.result?.appointment_price?.price * appointmentDetail?.data?.result?.appointment_price?.discount_pct) / 100)) / 100).toFixed(2)}
                                    </span>

                                    <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                        ({(appointmentDetail?.data?.result?.appointment_availability?.duration < 60)
                                            ? appointmentDetail?.data?.result?.appointment_availability?.duration + " min"
                                            : (appointmentDetail?.data?.result?.appointment_availability?.duration / 60)?.toFixed(2) + " hour(s)"
                                        })
                                    </span>
                                </div>
                            )}
                            {(appointmentDetail?.data?.result?.appointment_price?.price_model === appointmentPriceModelEnum.FREE.value) && (
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                        {"Duration:"}
                                    </span>
                                    <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                        {(appointmentDetail?.data?.result?.appointment_availability?.duration < 60)
                                            ? appointmentDetail?.data?.result?.appointment_availability?.duration + " min"
                                            : (appointmentDetail?.data?.result?.appointment_availability?.duration / 60)?.toFixed(2) + " hour(s)"
                                        }
                                    </span>
                                </div>
                            )}
                            <div className={"flex items-center justify-start gap-0.5"}>
                                <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                    {"Price Model:"}
                                </span>
                                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                    {appointmentPriceModelEnum[appointmentDetail?.data?.result?.appointment_price?.price_model?.toUpperCase()]?.label}
                                </span>
                            </div>
                        </div>
                    }

                    {/* on edit enabled */}
                    <AnimatePresence initial={isEditModeEnabled}>
                        {isEditModeEnabled &&
                            <UserAppointmentForm
                                isEditModeEnabled={isEditModeEnabled}
                                setIsEditModeEnabled={setIsEditModeEnabled}
                            />
                        }
                    </AnimatePresence>
                </div>
            </div>
        </div>
    )
}

export default AppointmentDetailCard;