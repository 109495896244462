import { dayjs } from "utils/dateTime.utils";
import {
    SearchAppointmentId,
    SearchAppointmentTitle,
    SearchAppointmentSlug,
    FilterAppointmentPriceModel,
    SearchAppointmentUser,
    SearchAppointmentAvailability,
    FilterAppointmentStatus,
    SearchDate,
    ButtonAction,
    SearchPublishedDate,
} from "./components/AppointmentFilterStatistics";

export const pageHeading = {
    heading: "Appointments",
    subHeading: "Find the list of appointments!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const appointmentsHeaderConst = {
    ID: {
        label: "ID",
        value: "id"
    },
    TITLE: {
        label: "Title",
        value: "title"
    },
    SLUG: {
        label: "Slug",
        value: "slug"
    },
    PRICE_MODEL: {
        label: "Price Model",
        value: "price_model"
    },
    USER: {
        label: "User",
        value: "user"
    },
    AVAILABILITY_ID: {
        label: "Availability ID",
        value: "availability_id"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    MODIFIED_DATE: {
        label: "Modified Date",
        value: "modified_date"
    },
}

export const subRowHeader = [
    <SearchAppointmentId />,
    <SearchAppointmentTitle />,
    <SearchAppointmentSlug />,
    <FilterAppointmentPriceModel />,
    <SearchAppointmentUser />,
    <SearchAppointmentAvailability />,
    <FilterAppointmentStatus />,
    <SearchDate />,
    <ButtonAction />,
    <SearchPublishedDate />
]

export const sortAppointmentLowToHigh = (activeSortHeader, appointment1, appointment2) => {
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.ID.value) {
        return Number(appointment1?.id) - Number(appointment2?.id)
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.TITLE.value) {
        return appointment1?.title?.toLowerCase()?.localeCompare(appointment2?.title?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.SLUG.value) {
        return appointment1?.slug?.toLowerCase()?.localeCompare(appointment2?.slug?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.PRICE_MODEL.value) {
        return appointment1?.price_model?.toLowerCase()?.localeCompare(appointment2?.price_model?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.USER.value) {
        return Number(appointment1?.user?.id) - Number(appointment2?.user?.id)
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.AVAILABILITY_ID.value) {
        return Number(appointment1?.availability_id) - Number(appointment2?.availability_id)
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.STATUS.value) {
        return appointment1?.status?.toLowerCase()?.localeCompare(appointment2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst?.DATE?.value) {
        return dayjs(appointment2?.createdAt).isSameOrBefore(appointment1?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(appointment2?.updatedAt).isSameOrBefore(appointment1?.updatedAt) ? 1 : -1
    }
}

export const sortAppointmentHighToLow = (activeSortHeader, appointment1, appointment2) => {
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.ID.value) {
        return Number(appointment2?.id) - Number(appointment1?.id)
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.TITLE.value) {
        return appointment2?.title?.toLowerCase()?.localeCompare(appointment1?.title?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.SLUG.value) {
        return appointment2?.slug?.toLowerCase()?.localeCompare(appointment1?.slug?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.PRICE_MODEL.value) {
        return appointment2?.price_model?.toLowerCase()?.localeCompare(appointment1?.price_model?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.USER.value) {
        return Number(appointment2?.user?.id) - Number(appointment1?.user?.id)
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.AVAILABILITY_ID.value) {
        return Number(appointment2?.availability_id) - Number(appointment1?.availability_id)
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst.STATUS.value) {
        return appointment2?.status?.toLowerCase()?.localeCompare(appointment1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst?.DATE?.value) {
        return dayjs(appointment1?.createdAt).isSameOrBefore(appointment2?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === appointmentsHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(appointment1?.updatedAt).isSameOrBefore(appointment2?.updatedAt) ? 1 : -1
    }
}