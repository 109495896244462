import { useAppState } from 'hooks/useStore';
import { masterCurrencyCodeEnum } from "redux/master/master.const";

const UserWalletDetail = () => {
    const { withdrawalDetail } = useAppState((state) => state.withdrawal)

    return withdrawalDetail?.withdrawalDetail && (
        <div className={"space-y-3 py-2"}>
            <div className={"w-full font-bodyPri font-medium text-text-900 text-lg"}>
                {"User Wallet Details"}
            </div>
            <div className={"w-full flex items-center justify-center gap-5"}>
                <div className={"p-3 flex flex-col items-center justify-start border border-primary-main rounded-lg shadow-md"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Available Balance"}
                    </span>
                    <span className={"font-bodyPri font-medium text-text-900 text-lg whitespace-nowrap"}>
                        {masterCurrencyCodeEnum[withdrawalDetail?.withdrawalDetail?.masterCurrency?.code?.toUpperCase()]?.label + " " + (withdrawalDetail?.withdrawalDetail?.user?.netWalletCredit / 100).toFixed(2)}
                    </span>
                </div>
                <div className={"p-3 flex flex-col items-center justify-start border border-primary-main rounded-lg shadow-md"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Pending Balance"}
                    </span>
                    <span className={"font-bodyPri font-medium text-text-900 text-lg whitespace-nowrap"}>
                        {masterCurrencyCodeEnum[withdrawalDetail?.withdrawalDetail?.masterCurrency?.code?.toUpperCase()]?.label + " " + ((withdrawalDetail?.withdrawalDetail?.user?.netPendingCredit) / 100).toFixed(2)}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default UserWalletDetail