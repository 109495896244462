import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/viewCourseDetail/viewCourse.style';
import { inputSections } from "pages/auth/viewCourseDetail/data";

import { getCourseDetail } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const CourseVideoSection = ({ clickedItem, setClickedItem }) => {
    const { courseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const isOpen = inputSections?.COURSE_VIDEO?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_VIDEO?.value)) {
            setClickedItem(inputSections?.COURSE_VIDEO?.value)
        } else {
            setClickedItem(null)
        }
    }

    const onHandleRefresh = () => {
        dispatcher(getCourseDetail(courseDetail?.courseDetail?.id))
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Video"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                <div className={"w-full flex items-center justify-center my-3"}>
                    <div className={"w-full md:w-[25rem]"}>
                        <MuxVideoPlayer
                            playbackId={courseDetail?.courseDetail?.videoUrl || MUX_DEMO_PLAYBACK_ID}
                            isShowButtons={false}
                            isShowRefreshButton={true}
                            onHandleRefresh={onHandleRefresh}
                        />
                    </div>
                </div>
            </CollapsableContainer>
        </div>
    )
}

export default CourseVideoSection;