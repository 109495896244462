import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from 'pages/auth/appointments/data';
import AppointmentsSearch from 'pages/auth/appointments/components/AppointmentsSearch';
import DisplayAppointments from 'pages/auth/appointments/components/DisplayAppointments';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const AppointmentsPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.APPOINTMENTS))
    }, [dispatcher, currentPageInfo])

    return (
        <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3'>
            <PageHeader pageHeading={pageHeading} />
            <AppointmentsSearch />
            <DisplayAppointments />
        </div>
    );
};

export default AppointmentsPage;