import { walletTxnFilterProps } from "redux/walletTxn/walletTxn.const"

export const WALLET_TXN_INITIAL_STATE = {
    walletTxnList: {
        isLoading: false,
        walletTxnList: null,
        filteredWalletTxnList: null,
        errorMsg: null,
    },
    walletTxnDetail: {
        isLoading: false,
        walletTxnDetail: null,
        walletTxnDetailPayload: null,
        errorMsg: null
    },
    filterProps: walletTxnFilterProps
}