import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import Table from 'components/table/Table';
import Pagination from 'components/pagination/Pagination';

import { lmsArticleHeaderConst, sortArticleHighToLow, sortArticleLowToHigh, subRowHeader } from 'pages/auth/edulyteLms/lmsArticle/lmsArticle.data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { deleteLmsArticleDetail, getLmsArticleList } from 'redux/edulyteLms/lmsArticle/lmsArticle.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { resetFilterProps, resetLmsArticleList } from 'redux/edulyteLms/lmsArticle/lmsArticle.slice';
import { lmsArticleStatusEnum, lmsArticleVisibilityEnum } from 'redux/edulyteLms/lmsArticle/lmsArticle.const';

import { timeZone, dayjs } from 'utils/dateTime.utils';
import { edulyteAppPagesInfo, pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

let CURRENT_ARTICLE_ID = null;

const OwnerTutorContainer = ({ article }) => {
    return article?.article_setting?.default_owner?.tutor ? (
        <div className={"flex items-center justify-start gap-0.5"}>
            <Link to={`${pagesInfo?.VIEW_TUTOR?.pagePath}/${article?.article_setting?.default_owner?.tutor?.tutorId}`}
                className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
            >
                {article?.article_setting?.default_owner?.tutor?.tutorId + ". "}
            </Link>
            <div className={""}>
                {`${article?.article_setting?.default_owner?.tutor?.user?.first_name} ${article?.article_setting?.default_owner?.tutor?.user?.last_name?.charAt(0)}.` || "-"}
            </div>
        </div>
    ) : "-"
}
const DisplayLmsArticle = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { lmsArticleList, destroyLmsArticleDetail, filterProps } = useAppState((state) => state.lms.lmsArticle)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        return () => {
            dispatcher(resetLmsArticleList())
            dispatcher(resetFilterProps())
            dispatcher(resetAdminLoginAs())
        }
    }, [])

    useEffect(() => {
        const query = {
            page: filterProps?.page || 1,
            records: Number(filterProps?.records) || 25
        }
        dispatcher(getLmsArticleList(query))

    }, [filterProps?.records])

    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo?.EDIT_LMS_ARTICLE?.pagePath}/${CURRENT_ARTICLE_ID}/edit`, "_self")
        }
    }, [adminLoginAs?.data])

    const onHandleEditArticle = (articleId, userId) => {
        CURRENT_ARTICLE_ID = articleId
        dispatcher(createAdminLoginAsDetail({ user_id: userId, source_url: window.location.href  }))
    }

    const onHandleDeleteArticle = (articleId) => {
        if (destroyLmsArticleDetail?.isLoading) return;

        dispatcher(deleteLmsArticleDetail(articleId))
    }

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortArticleList = (article1, article2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortArticleLowToHigh(activeSortHeader, article1, article2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortArticleHighToLow(activeSortHeader, article1, article2)
        }
    }

    const tableRowsConst = lmsArticleList?.data?.results?.slice(0)?.sort(sortArticleList)?.map((article) => ([
        article?.article_id ? article?.article_id : "-",
        article?.title
            ? (article?.title?.length > 30)
                ? article?.title?.slice(0, 30) + "...."
                : article?.title
            : "-",
        <OwnerTutorContainer article={article} />,
        article?.article_setting?.status
            ? lmsArticleStatusEnum[article?.article_setting?.status?.toUpperCase()]?.label
            : "-",
        article?.article_setting?.visibility
            ? lmsArticleVisibilityEnum[article?.article_setting?.visibility?.toUpperCase()]?.label
            : "-",
        dayjs(article?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",

        <div className={"flex items-center w-full"}>
            <button
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
                onClick={() => onHandleEditArticle(article?.article_id, article?.article_setting?.default_owner?.user)}
                title={"Edit Article"}
            >
                <FiEdit size={20} className={"text-xl hover:scale-110"} />
            </button>
            <button
                className={"px-2 py-1 bg-red-500 text-white rounded-r-md"}
                onClick={() => onHandleDeleteArticle(article?.article_id)}
                title={"Delete Article"}
            >
                <AiOutlineDelete size={20} className={"text-xl hover:scale-110"} />
            </button>
        </div>,
    ]))

    return (
        <div className={'w-full flex flex-col gap-5'}>
            <Table
                headers={Object.values(lmsArticleHeaderConst)}
                subRowHeader={subRowHeader}
                rows={tableRowsConst}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={lmsArticleList?.isLoading}
                message={(lmsArticleList?.message || (lmsArticleList?.data?.records === 0)) && (lmsArticleList?.message || "No articles found")}
            />
            {(!lmsArticleList?.isLoading && (lmsArticleList?.data?.totalPages > 1)) &&
                <div className={'w-full flex items-center justify-center'}>
                    <Pagination
                        page={lmsArticleList?.data?.page}
                        totalPages={lmsArticleList?.data?.totalPages}
                        onChangePage={(page) => dispatcher(getLmsArticleList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </div>
    )
}

export default DisplayLmsArticle;