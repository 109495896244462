import { FaInfoCircle } from 'react-icons/fa';

import { cn } from "utils/cn.utils";

const ViewCourseTooltip = ({ tooltip, width = 60, translateX = 50, translateY = 100 }) => {
    return (
        <div className={"relative has-tooltip cursor-pointer flex items-start justify-start"}>
            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
            <span
                className={cn(
                    `w-fit md:w-${width} !z-[500] px-3 py-0.5 md:py-1 font-bodyPri font-normal text-sm md:text-md rounded shadow-lg bg-text-900 text-text-50 tooltip -translate-x-[${translateX}%] -translate-y-[${translateY}%]`,
                    "flex flex-col items-start justify-start overflow-hidden"
                )}
            >
                {tooltip}
            </span>
        </div>
    )
}

export default ViewCourseTooltip