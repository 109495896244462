import { createSlice } from "@reduxjs/toolkit";

import { COMPANY_INITIAL_STATE } from "redux/company/company.initialState";
import { filterName } from "redux/company/company.const";

const company = createSlice({
    name: "company",
    initialState: COMPANY_INITIAL_STATE,
    reducers: {
        // reducers for company onboarding list
        setCompanyOnboardingLoading: (state, { payload }) => {
            state.companyOnboardingList.isLoading = payload
        },
        setCompanyOnboarding: (state, { payload }) => {
            state.companyOnboardingList.companyOnboardingList = payload
            state.companyOnboardingList.filteredCompanyOnboardingList = payload
            state.companyOnboardingList.errorMsg = COMPANY_INITIAL_STATE.companyOnboardingList.errorMsg
        },
        setCompanyOnboardingErrorMsg: (state, { payload }) => {
            state.companyOnboardingList.errorMsg = payload
            state.companyOnboardingList.companyOnboardingList = COMPANY_INITIAL_STATE.companyOnboardingList.errorMsg
        },
        setClearCompanyOnboarding: (state) => {
            state.companyOnboardingList = COMPANY_INITIAL_STATE.companyOnboardingList
        },

        // reducers for company public profile
        setCompanyPublicProfileLoading: (state, { payload }) => {
            state.companyPublicProfile.isLoading = payload
        },
        setCompanyPublicProfile: (state, { payload }) => {
            state.companyPublicProfile.companyPublicProfile = payload
            state.companyPublicProfile.errorMsg = COMPANY_INITIAL_STATE.companyPublicProfile.errorMsg
        },
        setCompanyPublicProfileErrorMsg: (state, { payload }) => {
            state.companyPublicProfile.errorMsg = payload
            state.companyPublicProfile.companyPublicProfile = COMPANY_INITIAL_STATE.companyPublicProfile.companyPublicProfile
        },
        setClearCompanyPublicProfile: (state) => {
            state.companyPublicProfile = COMPANY_INITIAL_STATE.companyPublicProfile
        },

        // reducers for company course list
        setCompanyCourseListLoading: (state, { payload }) => {
            state.companyCourseList.isLoading = payload
        },
        setCompanyCourseList: (state, { payload }) => {
            state.companyCourseList.companyCourseList = payload
            state.companyCourseList.errorMsg = COMPANY_INITIAL_STATE.companyCourseList.errorMsg
        },
        setCompanyCourseListErrorMsg: (state, { payload }) => {
            state.companyCourseList.errorMsg = payload
            state.companyCourseList.companyCourseList = COMPANY_INITIAL_STATE.companyCourseList.companyCourseList
        },
        setClearCompanyCourseList: (state) => {
            state.companyCourseList = COMPANY_INITIAL_STATE.companyCourseList
        },

        // reducers for employee list
        setEmployeeListLoading: (state, { payload }) => {
            state.employeeList.isLoading = payload
        },
        setEmployeeList: (state, { payload }) => {
            state.employeeList.employeeList = payload
            state.employeeList.errorMsg = COMPANY_INITIAL_STATE.employeeList.errorMsg
        },
        setEmployeeListErrorMsg: (state, { payload }) => {
            state.employeeList.errorMsg = payload
            state.employeeList.employeeList = COMPANY_INITIAL_STATE.employeeList.employeeList
        },
        setClearEmployeeList: (state) => {
            state.employeeList = COMPANY_INITIAL_STATE.employeeList
        },

        // reducers for company review list
        setCompanyReviewListLoading: (state, { payload }) => {
            state.companyReviewList.isLoading = payload
        },
        setCompanyReviewList: (state, { payload }) => {
            state.companyReviewList.companyReviewList = payload
            state.companyReviewList.errorMsg = COMPANY_INITIAL_STATE.companyReviewList.errorMsg
        },
        setCompanyReviewListErrorMsg: (state, { payload }) => {
            state.companyReviewList.errorMsg = payload
            state.companyReviewList.companyReviewList = COMPANY_INITIAL_STATE.companyReviewList.companyReviewList
        },
        setClearCompanyReviewList: (state) => {
            state.companyReviewList = COMPANY_INITIAL_STATE.companyReviewList
        },

        // reducers for similar company list
        setSimilarCompanyListLoading: (state, { payload }) => {
            state.similarCompanyList.isLoading = payload
        },
        setSimilarCompanyList: (state, { payload }) => {
            state.similarCompanyList.similarCompanyList = payload
            state.similarCompanyList.errorMsg = COMPANY_INITIAL_STATE.similarCompanyList.errorMsg
        },
        setSimilarCompanyListErrorMsg: (state, { payload }) => {
            state.similarCompanyList.errorMsg = payload
            state.similarCompanyList.similarCompanyList = COMPANY_INITIAL_STATE.similarCompanyList.similarCompanyList
        },
        setClearSimilarCompanyList: (state) => {
            state.similarCompanyList = COMPANY_INITIAL_STATE.similarCompanyList
        },

        // reducers for filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
            const filteredList = payload.list
                .filter((company) => (
                    (!payload.filterProps.searchText
                        || (company.firstName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase()) || company.lastName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase())))
                ))

            switch (payload.filterName) {
                case filterName.COMPANY_ONBOARDING.value:
                    state.tutorOnboardingList.filteredTutorOnboardingList = filteredList
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = COMPANY_INITIAL_STATE.filterProps
        }
    }
})


export const {

    setCompanyOnboardingLoading,
    setCompanyOnboarding,
    setCompanyOnboardingErrorMsg,
    setClearCompanyOnboarding,

    setCompanyPublicProfileLoading,
    setCompanyPublicProfile,
    setCompanyPublicProfileErrorMsg,
    setClearCompanyPublicProfile,

    setCompanyCourseListLoading,
    setCompanyCourseList,
    setCompanyCourseListErrorMsg,
    setClearCompanyCourseList,

    setEmployeeListLoading,
    setEmployeeList,
    setEmployeeListErrorMsg,
    setClearEmployeeList,

    setCompanyReviewListLoading,
    setCompanyReviewList,
    setCompanyReviewListErrorMsg,
    setClearCompanyReviewList,

    setSimilarCompanyListLoading,
    setSimilarCompanyList,
    setSimilarCompanyListErrorMsg,
    setClearSimilarCompanyList,

    setFilterProps,
    setClearFilterProps
} = company.actions

export default company.reducer