import { createSlice } from "@reduxjs/toolkit";
import { AVAILABILITY_INITIAL_STATE } from "redux/availability/availability.initialState";


const auth = createSlice({
    name: "availability",
    initialState: AVAILABILITY_INITIAL_STATE,
    reducers: {
        // Reducers for availability list
        setAvailabilityListLoading: (state, { payload }) => {
            state.availabilityList.isLoading = payload
        },
        setAvailabilityListData: (state, { payload }) => {
            state.availabilityList.data = payload
            state.availabilityList.message = AVAILABILITY_INITIAL_STATE.availabilityList.message
        },
        setAvailabilityListMessage: (state, { payload }) => {
            state.availabilityList.message = payload
            state.availabilityList.data = AVAILABILITY_INITIAL_STATE.availabilityList.data
        },
        resetAvailabilityList: (state) => {
            state.availabilityList = AVAILABILITY_INITIAL_STATE.availabilityList
        },

        // reducers for availability detail
        setAvailabilityDetailLoading: (state, { payload }) => {
            state.availabilityDetail.isLoading = payload
        },
        setAvailabilityDetailData: (state, { payload }) => {
            state.availabilityDetail.data = payload
            state.availabilityDetail.message = AVAILABILITY_INITIAL_STATE.availabilityDetail.message
        },
        setAvailabilityDetailMessage: (state, { payload }) => {
            state.availabilityDetail.message = payload
            state.availabilityDetail.data = AVAILABILITY_INITIAL_STATE.availabilityDetail.data
        },
        resetAvailabilityDetail: (state) => {
            state.availabilityDetail = AVAILABILITY_INITIAL_STATE.availabilityDetail
        },

        //reducers for add availability detail
        setAddAvailabilityDetailLoading: (state, { payload }) => {
            state.addAvailabilityDetail.isLoading = payload
        },
        setAddAvailabilityDetailData: (state, { payload }) => {
            state.addAvailabilityDetail.data = payload
            state.addAvailabilityDetail.message = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail.message
        },
        setAddAvailabilityDetailMessage: (state, { payload }) => {
            state.addAvailabilityDetail.message = payload
            state.addAvailabilityDetail.data = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail.data
        },
        resetAddAvailabilityDetail: (state, { payload }) => {
            state.addAvailabilityDetail = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail
        },

        // reducers for add availability detail payload
        setAddAvailabilityDetailPayload: (state, { payload }) => {
            state.addAvailabilityDetail.payload = payload
        },
        resetAddAvailabilityDetailPayload: (state) => {
            state.addAvailabilityDetail.payload = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail.payload
        },

        // update availability detail
        setModifyAvailabilityDetailLoading: (state, {payload}) => {
            state.modifyAvailabilityDetail.isLoading = payload
        },
        setModifyAvailabilityDetailData: (state, {payload}) => {
            state.modifyAvailabilityDetail.data = payload
            state.modifyAvailabilityDetail.message = AVAILABILITY_INITIAL_STATE.modifyAvailabilityDetail.message
        },
        setModifyAvailabilityDetailMessage: (state, {payload}) => {
            state.modifyAvailabilityDetail.message = payload
            state.modifyAvailabilityDetail.data = AVAILABILITY_INITIAL_STATE.modifyAvailabilityDetail.data
        },
        resetModifyAvailabilityDetail: (state) => {
            state.modifyAvailabilityDetail = AVAILABILITY_INITIAL_STATE.modifyAvailabilityDetail
        },

        // update availability detail payload
        setModifyAvailabilityDetailPayload: (state, { payload }) => {
            state.modifyAvailabilityDetail.payload = payload
        },
        resetModifyAvailabilityDetailPayload: (state) => {
            state.modifyAvailabilityDetail.payload = AVAILABILITY_INITIAL_STATE.modifyAvailabilityDetail.payload
        },

        // delete availability detail
        setDestroyAvailabilityDetailLoading: (state, {payload}) => {
            state.destroyAvailabilityDetail.isLoading = payload
        },
        setDestroyAvailabilityDetailMessage: (state, {payload}) => {
            state.destroyAvailabilityDetail.message = payload
        },
        resetDestroyAvailabilityDetail: (state) => {
            state.destroyAvailabilityDetail = AVAILABILITY_INITIAL_STATE.destroyAvailabilityDetail
        },

        // reducers for available Slot detail
        setAvailableSlotDetailLoading: (state, { payload }) => {
            state.availableSlotDetail.isLoading = payload
        },
        setAvailableSlotDetailData: (state, { payload }) => {
            state.availableSlotDetail.data = payload
            state.availableSlotDetail.message = AVAILABILITY_INITIAL_STATE.availableSlotDetail.message
        },
        setAvailableSlotDetailMessage: (state, { payload }) => {
            state.availableSlotDetail.message = payload
            state.availableSlotDetail.data = AVAILABILITY_INITIAL_STATE.availableSlotDetail.data
        },
        resetAvailableSlotDetail: (state) => {
            state.availableSlotDetail = AVAILABILITY_INITIAL_STATE.availableSlotDetail
        },

        // filter props
        setFilterProps: (state, {payload}) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = AVAILABILITY_INITIAL_STATE.filterProps
        },

        /////////////////////////////////////////////////////////////////////

        // (new) reducers for add availability detail payload (specifically used for availability modal, will removed later when modal gets deleted)
        setAddAvailabilityDetail_Payload: (state, { payload }) => {
            state.addAvailabilityDetail.payload = payload
        },
        resetAddAvailabilityDetail_Payload: (state) => {
            state.addAvailabilityDetail.payload = AVAILABILITY_INITIAL_STATE.addAvailabilityDetail.payload
        },

        // reducers for off day list
        setOffDayListLoading: (state, { payload }) => {
            state.offDayList.isLoading = payload
        },
        setOffDayListData: (state, { payload }) => {
            state.offDayList.data = payload
            state.offDayList.message = AVAILABILITY_INITIAL_STATE.offDayList.message
        },
        setOffDayListMessage: (state, { payload }) => {
            state.offDayList.message = payload
            state.offDayList.data = AVAILABILITY_INITIAL_STATE.offDayList.data
        },
        resetOffDayList: (state) => {
            state.offDayList = AVAILABILITY_INITIAL_STATE.offDayList
        },

        // reducers for off day detail
        setOffDayDetailLoading: (state, { payload }) => {
            state.offDayDetail.isLoading = payload
        },
        setOffDayDetailData: (state, { payload }) => {
            state.offDayDetail.data = payload
            state.offDayDetail.message = AVAILABILITY_INITIAL_STATE.offDayDetail.message
        },
        setOffDayDetailMessage: (state, { payload }) => {
            state.offDayDetail.message = payload
            state.offDayDetail.data = AVAILABILITY_INITIAL_STATE.offDayDetail.data
        },
        resetOffDayDetail: (state) => {
            state.offDayDetail = AVAILABILITY_INITIAL_STATE.offDayDetail
        },

        // reducers for update off day detail
        setAddOffDayDetailLoading: (state, { payload }) => {
            state.addOffDayDetail.isLoading = payload
        },
        setAddOffDayDetailData: (state, { payload }) => {
            state.addOffDayDetail.data = payload
            state.addOffDayDetail.message = AVAILABILITY_INITIAL_STATE.addOffDayDetail.message
        },
        setAddOffDayDetailMessage: (state, { payload }) => {
            state.addOffDayDetail.message = payload
            state.addOffDayDetail.data = AVAILABILITY_INITIAL_STATE.addOffDayDetail.data
        },
        resetAddOffDayDetail: (state, { payload }) => {
            state.addOffDayDetail = AVAILABILITY_INITIAL_STATE.addOffDayDetail
        },

        // reducers for add off day detail payload
        setAddOffDayDetailPayload: (state, { payload }) => {
            state.addOffDayDetail.payload = payload
        },
        resetAddOffDayDetailPayload: (state) => {
            state.addOffDayDetail.payload = AVAILABILITY_INITIAL_STATE.addOffDayDetail.payload
        },
    }
})

export const {
    setAvailabilityListLoading,
    setAvailabilityListData,
    setAvailabilityListMessage,
    resetAvailabilityList,

    setAddAvailabilityDetailLoading,
    setAddAvailabilityDetailData,
    setAddAvailabilityDetailMessage,
    resetAddAvailabilityDetail,

    setAddAvailabilityDetailPayload,
    resetAddAvailabilityDetailPayload,

    setAvailabilityDetailLoading,
    setAvailabilityDetailData,
    setAvailabilityDetailMessage,
    resetAvailabilityDetail,

    setModifyAvailabilityDetailLoading,
    setModifyAvailabilityDetailData,
    setModifyAvailabilityDetailMessage,
    resetModifyAvailabilityDetail,

    setModifyAvailabilityDetailPayload,
    resetModifyAvailabilityDetailPayload,

    setDestroyAvailabilityDetailLoading,
    setDestroyAvailabilityDetailMessage,
    resetDestroyAvailabilityDetail,

    setAvailableSlotDetailLoading,
    setAvailableSlotDetailData,
    setAvailableSlotDetailMessage,
    resetAvailableSlotDetail,

    setFilterProps,
    resetFilterProps,

    //////////////////////////////////////////////////////////////

    // new
    setAddAvailabilityDetail_Payload,
    resetAddAvailabilityDetail_Payload,
} = auth.actions

export default auth.reducer