// import {useState} from 'react';

import AddScheduleDetail from "./components/AddScheduleDetail";

const ConfirmSchedulePage = () => {
    return (
        <div className={"space-y-5"}>
            <AddScheduleDetail />
        </div>
    )
}

export default ConfirmSchedulePage;