import { dayjs } from "utils/dateTime.utils";
import {
    SearchProductId,
    SearchProductTitle,
    SearchProductSlug,
    FilterProductType,
    SearchDate,
    ButtonAction,
    SearchModifiedDate,
} from "./components/ProductFilterStatistics";

export const pageHeading = {
    heading: "Products",
    subHeading: "Find the list of products!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-courses.svg"
}

export const productsHeaderConst = {
    PRODUCT_ID: {
        label: "Product ID",
        value: "product_id"
    },
    PRODUCT_TITLE: {
        label: "Product Title",
        value: "product_title"
    },
    PRODUCT_SLUG: {
        label: "Product Slug",
        value: "product_slug"
    },
    PRODUCT_TYPE: {
        label: "Product Type",
        value: "product_type"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    MODIFIED_DATE: {
        label: "Modified Date",
        value: "modified_date"
    }
}

export const subRowHeader = [
    <SearchProductId />,
    <SearchProductTitle />,
    <SearchProductSlug />,
    <FilterProductType />,
    <SearchDate />,
    <ButtonAction />,
    <SearchModifiedDate />
]

export const sortUserLowToHigh = (activeSortHeader, product1, product2) => {
    if (activeSortHeader?.activeSortKey === productsHeaderConst.PRODUCT_ID.value) {
        return Number(product1?.id) - Number(product2?.id)
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst.PRODUCT_TITLE.value) {
        return product1?.title?.toLowerCase()?.localeCompare(product2?.title?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst.PRODUCT_SLUG.value) {
        return product1?.active_slug?.toLowerCase()?.localeCompare(product2?.active_slug?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst.PRODUCT_TYPE.value) {
        return product1?.type?.toLowerCase()?.localeCompare(product2?.type?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst?.DATE?.value) {
        return dayjs(product2?.createdAt).isSameOrBefore(product1?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(product2?.createdAt).isSameOrBefore(product1?.createdAt) ? 1 : -1
    }
}

export const sortUserHighToLow = (activeSortHeader, product1, product2) => {
    if (activeSortHeader?.activeSortKey === productsHeaderConst.PRODUCT_ID.value) {
        return Number(product2?.id) - Number(product1?.id)
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst.PRODUCT_TITLE.value) {
        return product2?.title?.toLowerCase()?.localeCompare(product1?.title?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst.PRODUCT_SLUG.value) {
        return product2?.active_slug?.toLowerCase()?.localeCompare(product1?.active_slug?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst.PRODUCT_TYPE.value) {
        return product2?.type?.toLowerCase()?.localeCompare(product1?.type?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst?.DATE?.value) {
        return dayjs(product1?.createdAt).isSameOrBefore(product2?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === productsHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(product1?.createdAt).isSameOrBefore(product2?.createdAt) ? 1 : -1
    }
}