import { useState, useEffect } from 'react';

import ComponentLoader from "components/loader/ComponentLoader";
// import SkillTag from 'components/skillTag/SkillTag';

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/viewCourseDetail/viewCourse.style';
import { inputSections } from "pages/auth/viewCourseDetail/data";

import { getCourseTagList } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearAddCourseTagDetail, setClearCourseTagList } from "redux/course/course.slice";

const CourseSkillsSection = ({ clickedItem, setClickedItem }) => {
    const { courseDetail, courseTagList, addCourseTagDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (clickedItem === inputSections?.SKILLS?.value) {
            dispatcher(getCourseTagList({ course: courseDetail?.courseDetail?.id }))
        }

        return () => {
            dispatcher(setClearCourseTagList())
            dispatcher(setClearAddCourseTagDetail())
        }
    }, [clickedItem])

    const isOpen = inputSections?.SKILLS?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.SKILLS?.value)) {
            setClickedItem(inputSections?.SKILLS?.value)
        } else {
            setClickedItem(null)
        }
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Skills to be covered"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {courseTagList?.isLoading &&
                    <ComponentLoader isLoading={courseTagList?.isLoading} />
                }
                {courseTagList?.ErrorMsg &&
                    <div className={"flex items-center justify-start font-bodyPri font-normal text-red-500 text-base"}>
                        {courseTagList?.errorMsg}
                    </div>
                }
                {!courseTagList?.isLoading && courseTagList?.courseTagList?.results?.length > 0 &&
                    <div className={"flex flex-col items-start justify-start gap-2 my-2"}>
                        <div className={"flex flex-wrap gap-2"}>
                            {courseTagList?.courseTagList?.results?.length > 0
                                && courseTagList?.courseTagList?.results?.map((skill) => (
                                    <div className="w-fit flex items-center px-3 py-1 bg-primary-light rounded-lg font-bodyPri font-normal text-text-900 text-sm">
                                        <span className="">
                                            {skill?.masterTag?.tag}
                                        </span>
                                    </div>
                                ))}
                        </div>
                        {addCourseTagDetail?.ErrorMsg &&
                            <div className={"flex items-center justify-start font-bodyPri font-normal text-red-500 text-base"}>
                                {addCourseTagDetail?.errorMsg}
                            </div>
                        }
                    </div>
                }

                {(!courseTagList?.isLoading && (courseTagList?.courseTagList?.results?.length === 0 || !courseTagList?.courseTagList)) &&
                    <div className={"font-bodyPri font-normal text-text-900 text-lg tracking-wide"}>
                        {"No Skills Found!"}
                    </div>
                }
            </CollapsableContainer>
        </div>
    )
}

export default CourseSkillsSection