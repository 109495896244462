import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { usersHeaderConst } from "pages/auth/users/data";

import { getUserList } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from "redux/local/local.slice";
import { resetAddNewUserDetail, setFilterProps } from 'redux/user/user.slice';
import { dataEntriesConst, modalConst } from "redux/local/local.const";
import { userRoles } from "redux/auth/auth.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const UsersSearch = () => {
    const { modal } = useAppState((state) => state.local)
    const { userList, filterProps } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const header = Object.values(usersHeaderConst)
        .filter((header) => header?.value !== usersHeaderConst.ACTIVITY.value && header?.value !== usersHeaderConst?.IMAGE?.value)
        .map((head) => head?.label)

    const body = userList?.userList?.results?.map((user) => ({
        [usersHeaderConst?.USER_ID?.label]: user?.userId,
        [usersHeaderConst?.NAME?.label]: user?.firstName + " " + user?.lastName,
        [usersHeaderConst?.EMAIL?.label]: user?.email?.toLowerCase(),
        [usersHeaderConst?.PHONE?.label]: (user?.isdCode && user?.mobileNo) && "+" + user?.isdCode + " - " + user?.mobileNo,
        [usersHeaderConst?.DATE?.label]: dayjs(user?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        [usersHeaderConst?.MODIFIED_DATE?.label]: dayjs(user?.updatedAt).tz(timeZone).format('DD MMM, YYYY')
    }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-users",
            sheet: "users",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const onHandleCreateUser = () => {
        dispatcher(resetAddNewUserDetail())
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_USER_DETAIL_MODAL.stateKey]: true
        }))
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value }
        }))
    }

    const onHandleSearch = () => {
        if (!filterProps?.searchText || userList?.isLoading) return;

        dispatcher(getUserList({ search: filterProps?.searchText, page: filterProps?.page || 1, records: filterProps?.records || 25 }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"w-full flex items-center justify-center gap-5"}>
                <div className="w-fit md:w-64 flex items-center justify-center">
                    <OptionSelector
                        options={Object.values(userRoles)}
                        className={"w-full"}
                        value={filterProps?.userRole}
                        onChange={(option) => dispatcher(setFilterProps({
                            filterProps: { ...filterProps, userRole: option?.value }
                        }))}
                    />
                </div>
                <div
                    className={"px-2 py-1 flex items-center justify-center border border-primary-dark text-primary-dark rounded-lg hover:bg-primary-dark hover:text-text-50 cursor-pointer"}
                    onClick={onHandleCreateUser}
                >
                    <span className={"font-bodyPri font-normal text-base tracking-wide"}>
                        {"Add User"}
                    </span>
                </div>
            </div>
            {/* <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-users"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div> */}

            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here..."}
                        value={filterProps.searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={onHandleSearch}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UsersSearch;
