import { useCallback, useMemo } from 'react';

import { IoIosCheckmark } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';

import FloatingLabelSelect from 'components/floating/floatingLabelSelect';
import MuiDatePicker from 'components/MUIComponents/MuiDatePicker';

import { availabilityDetailBtnConst, availabilityTimeZoneConst, getSelectedTimeZone } from "pages/auth/editUser/data";
import { InputTextWithLabel } from "pages/auth/editUser/commonComponents/InputTextWithLabel";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAddAvailabilityDetail, setAddAvailabilityDetailPayload } from 'redux/availability/availability.slice';
import { addAvailabilityDetailPayload, availabilityEndTypeEnum, availabilityIsRecurringEnum, weekAvailTimeSlots } from 'redux/availability/availability.const';

import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const BasicInformation = () => {
    const { userDetail } = useAppState((state) => state.user)
    const { addAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const timeZoneOffset = useMemo(() => getTimeZoneOffset(addAvailabilityDetail?.payload?.timeZone), [addAvailabilityDetail?.payload?.timeZone])
    
    const onHandleRecurringAvailability = useCallback((key, value) => {
        let payload = {
            [key]: value
        }

        if (value === availabilityIsRecurringEnum?.RECURRING?.value) {
            payload = {
                ...payload,
                weeklyTimeSlots: weekAvailTimeSlots,
                dateTimeSlots: [],
                endType: availabilityEndTypeEnum.FOREVER.value,
                startDateTime: dayjs()?.tz(addAvailabilityDetail?.payload?.timeZone)?.format("YYYY-MM-DD HH:mm:ss"),
                endDateTime: null,
                weeklyCycle: null
            }
        } else {
            payload = {
                ...payload,
                dateTimeSlots: [],
                weeklyTimeSlots: weekAvailTimeSlots,
                endType: null,
                startDateTime: null,
                endDateTime: null,
                weeklyCycle: null
            }
        }
        dispatcher(setAddAvailabilityDetailPayload({
            ...addAvailabilityDetail?.payload,
            ...payload
        }))
    }, [addAvailabilityDetail?.payload])

    const onHandleChangeText = useCallback((key, value) => {
        if (addAvailabilityDetail?.isLoading) return;

        dispatcher(setAddAvailabilityDetailPayload({
            ...addAvailabilityDetail?.payload,
            [key]: value
        }))
    }, [addAvailabilityDetail])

    const onHandleSelectTimeZone = useCallback((key, value) => {
        if (addAvailabilityDetail?.isLoading) return;

        if (!window.confirm("Updating timezone will reset your current availability. Are you sure?")) return;

        dispatcher(resetAddAvailabilityDetail())
        dispatcher(setAddAvailabilityDetailPayload({
            ...addAvailabilityDetailPayload,
            [key]: value
        }))
    }, [addAvailabilityDetail])

    return (
        <div className={"w-full grid grid-cols-12 gap-y-10 sm:gap-y-10 sm:gap-x-10"}>

            {/* name container */}
            <div className={"col-span-full"}>
                <InputTextWithLabel
                    isRequired={true}
                    inputLabel={availabilityDetailBtnConst.name.label}
                    id={availabilityDetailBtnConst.name.key}
                    inputLabelContent={availabilityDetailBtnConst.name.key}
                    placeholder={"Enter name here ..."}
                    inputName={availabilityDetailBtnConst.name.key}
                    value={addAvailabilityDetail?.payload?.name}
                    onChange={(event) => onHandleChangeText(availabilityDetailBtnConst.name.key, event?.target?.value)}
                    required={true}
                    maxLength={100}
                />
                <div className={"w-full flex justify-end font-bodyPri font-normal text-text-800 text-sm"}>
                    {`${addAvailabilityDetail?.payload?.name ? addAvailabilityDetail?.payload?.name?.length : 0}/${100}`}
                </div>
            </div>

            {/* isRecurring */}
            <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-6"}>
                <FloatingLabelSelect
                    labelItem={availabilityDetailBtnConst.isRecurring.key}
                    options={Object.values(availabilityIsRecurringEnum)}
                    onHandleSelect={(selectedOption) => onHandleRecurringAvailability(availabilityDetailBtnConst.isRecurring.key, selectedOption?.value)}
                    value={availabilityIsRecurringEnum[addAvailabilityDetail?.payload?.isRecurring?.toUpperCase()]?.label}
                    OptionChild={({ option }) => (
                        <div className={"bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between"}>
                            {option?.label}
                            {(option?.value === addAvailabilityDetail?.payload?.isRecurring)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    label={availabilityDetailBtnConst.isRecurring.label}
                    dropdownWrapperClassName={"w-full"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                    labelClassName={"font-medium text-base !text-gray-900"}
                    customBtnStyle={"!h-12"}
                    isDisabled={addAvailabilityDetail?.isLoading}
                />
            </div>

            {/* start date time */}
            {[availabilityIsRecurringEnum.RECURRING.value]?.includes(addAvailabilityDetail?.payload?.isRecurring) &&
                <div className={"w-full col-start-1 col-span-full md:col-start-7 md:col-span-6 space-y-2"}>
                    <MuiDatePicker
                        label={availabilityDetailBtnConst.startDateTime.label}
                        value={addAvailabilityDetail?.payload?.startDateTime ? dayjs(addAvailabilityDetail?.payload?.startDateTime + timeZoneOffset, "YYYY-MM-DDZ").tz(addAvailabilityDetail?.payload?.timeZone) : null}
                        onChange={(date) => onHandleChangeText(availabilityDetailBtnConst.startDateTime.key, dayjs(date.format("YYYY-MM-DD") + timeZoneOffset, "YYYY-MM-DDZ").tz(addAvailabilityDetail?.payload?.timeZone).format("YYYY-MM-DD"))}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#9e9e9e",
                                    height: 50,
                                },
                                "&:hover fieldset": {
                                    borderColor: "#2196f3",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                fontWeight: '700',
                                color: '#212121',
                            },
                        }}
                        format={"DD-MM-YYYY"}
                        color={"primary"}
                        disablePast={true}
                        helperText={"SelectDate"}
                        margin={"dense"}
                        minDate={dayjs().tz(addAvailabilityDetail?.payload?.timeZone)}
                    />
                    <div className={"flex items-center justify-start gap-2"}>
                        <BsInfoCircle className={"text-text-700 text-lg"} />
                        <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                            {"You must have a session on the start date."}
                        </span>
                    </div>
                </div>
            }

            {/* timeZone */}
            <div className={cn(
                [availabilityIsRecurringEnum?.RECURRING.value]?.includes(addAvailabilityDetail?.payload?.isRecurring)
                    ? "col-start-1 col-span-full md:col-start-1 md:col-span-6"
                    : "col-start-1 col-span-full md:col-start-7 md:col-span-full"
            )}>
                <FloatingLabelSelect
                    labelItem={availabilityDetailBtnConst.timeZone.key}
                    options={Object.values(availabilityTimeZoneConst)?.map((timeZoneConst) => {
                        let optionObject = {
                            value: timeZoneConst?.value
                        }
                        if ([availabilityTimeZoneConst.LOCAL.value]?.includes(timeZoneConst?.value)) {
                            optionObject["label"] = `${timeZone} (${timeZoneConst?.label})`
                            optionObject["timeZone"] = timeZone
                        } else {
                            optionObject["label"] = `${userDetail?.userDetail?.userCulture?.timeZone?.timeZone} (${timeZoneConst?.label})`
                            optionObject["timeZone"] = userDetail?.userDetail?.userCulture?.timeZone?.timeZone
                        }
                        return optionObject;
                    })}
                    onHandleSelect={(selectedOption) => onHandleSelectTimeZone(availabilityDetailBtnConst.timeZone.key, selectedOption?.timeZone)}
                    value={`${addAvailabilityDetail?.payload?.timeZone} (${getSelectedTimeZone(addAvailabilityDetail?.payload?.timeZone, userDetail?.userDetail?.userCulture?.timeZone?.timeZone)?.label})`}
                    OptionChild={({ option }) => (
                        <div className={"bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between"}>
                            {option?.label}
                            {(option?.value === getSelectedTimeZone(addAvailabilityDetail?.payload?.timeZone, userDetail?.userDetail?.userCulture?.timeZone?.timeZone)?.value)
                                && <IoIosCheckmark size={20} color="green" />
                            }
                        </div>
                    )}
                    label={availabilityDetailBtnConst.timeZone.label}
                    dropdownWrapperClassName={"w-full"}
                    dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                    labelClassName={"font-medium text-base !text-gray-900"}
                    customBtnStyle={"!h-12"}
                    isDisabled={addAvailabilityDetail?.isLoading}
                />
            </div>
        </div>
    )
}

export default BasicInformation;