import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { tutorHeaderConst } from "pages/auth/tutors/data";

import { getTutorList } from "redux/tutor/tutor.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/tutor/tutor.slice";
import { tutorStatus } from "redux/tutor/tutor.const";
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const Tutors = () => {
    const { tutorList, filterProps } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()

    const header = Object.values(tutorHeaderConst)
        .filter((headerConst) => headerConst?.value !== tutorHeaderConst?.ACTIVITY?.value && headerConst?.value !== tutorHeaderConst?.IMAGE?.value)
        .map((header) => header?.label)

    const body = tutorList?.tutorList?.results?.map((tutor) => ({
        [tutorHeaderConst?.TUTOR_ID.label]: tutor?.tutorId,
        [tutorHeaderConst.USER_ID.label]: tutor?.userId,
        [tutorHeaderConst.TUTOR_NAME.label]: tutor?.firstName + " " + tutor?.lastName,
        [tutorHeaderConst.EMAIL.label]: tutor?.email?.toLowerCase(),
        [tutorHeaderConst.STATUS.label]: tutorStatus[tutor?.status?.toUpperCase()]?.label,
        [tutorHeaderConst.DATE.label]: dayjs(tutor?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A')
    }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-tutors",
            sheet: "tutor",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value }
        }))
    }

    const onHandleSearch = () => {
        if (tutorList?.isLoading || !filterProps?.searchText) return;

        dispatcher(getTutorList({ search: filterProps?.searchText, page: filterProps?.page || 1, records: filterProps?.records || 25 }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-tutors"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here..."}
                        value={filterProps.searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={onHandleSearch}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Tutors;
