import { dataEntriesConst } from "redux/local/local.const";

export const url = {
    GET_LMS_QUIZ_LIST: 'lms/quizzes/quizzes',
    DELETE_LMS_QUIZ_DETAIL: 'lms/quizzes/quizzes/{quizId}',
}

export const lmsQuizRedux = {
    sliceName: "lmsQuiz",
    lmsQuizList: {
        requestName: "getLmsQuizList"
    }
}


export const lmsQuizStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const lmsQuizCategoryEnum = {
    QUESTION: {
        label: "Question",
        value: "question"
    },
    ARTICLE: {
        label: "Article",
        value: "article"
    },
    IMAGE: {
        label: "Image",
        value: "resource"
    },
    VIDEO: {
        label: "Video",
        value: "resource"
    }
}

export const lmsQuizShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsQuizVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsQuizInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const DEFAULT_LMS_QUIZ_PAGE = 1

export const DEFAULT_LMS_QUIZ_RECORDS = dataEntriesConst.TWENTY_FIVE.value

export const lmsQuizFilterProps = {
    page: DEFAULT_LMS_QUIZ_PAGE,
    records: DEFAULT_LMS_QUIZ_RECORDS,
    searchText: "",
    sortBy: "",
    quizId: "",
    quizTitle: "",
    quizType: "",
    quizStatus: "",
    ownerTutor: "",
    date: ""
}