import img1 from './images/One.png';
import img2 from './images/Two.png';
import img3 from './images/Three.png';
import img4 from './images/Four.png';


const featureItems = [
  {
    title: 'Live Real Teacher',
    imageUrl: img1,
  },
  {
    title: 'Secure & Convenient',
    imageUrl: img2,
  },
  {
    title: 'Personalised Learning',
    imageUrl: img3,
  },
  {
    title: 'Verified Teacher',
    imageUrl: img4,
  },
]
const EditCourseImageBar = () => {

  return (
    <div className="flex items-center justify-between bg-white p-5">
      <div className="flex flex-col justify-center items-center">
        <img src={img1} alt="" className="w-12 h-12 lg:h-16 lg:w-16  rounded-full" />
        <span className="lg:font-bold lg:text-sm text-xs text-gray-500">Live Real Tutor</span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <img src={img2} alt="" className="w-12 h-12 lg:h-16 lg:w-16  rounded-full" />
        <span className="lg:font-bold lg:text-sm text-xs text-gray-500">Secure & Convenient</span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <img src={img3} alt="" className="w-12 h-12 lg:h-16 lg:w-16  rounded-full" />
        <span className="lg:font-bold lg:text-sm text-xs text-gray-500">Personalised Learning</span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <img src={img4} alt="" className="w-12 h-12 lg:h-16 lg:w-16  rounded-full" />
        <span className="lg:font-bold lg:text-sm text-xs text-gray-500">Verified Teacher</span>
      </div>
    </div>
  )
}

export default EditCourseImageBar