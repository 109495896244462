import { toast } from "react-toastify";
import { useState } from 'react';

import { BsCheck2Square } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { MdOutlineContentCopy } from 'react-icons/md';

import { useAppState } from 'hooks/useStore';
import { courseStatus } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_ADMIN_DOMAIN_URL, EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const PrivateCourseLink = () => {
    const { locals } = useAppState((state) => state.local)
    const { courseDetail } = useAppState((state) => state.course)

    const [isLinkCopiedAsAdmin, setIsLinkCopiedAsAdmin] = useState(false)
    const [isLinkCopiedAsTutor, setIsLinkCopiedAsTutor] = useState(false)

    const PUBLISHED_COURSE_URL = `${EDULYTE_APP_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${courseDetail?.courseDetail?.slug}`

    const PRIVATE_COURSE_URL_AS_ADMIN = `${EDULYTE_ADMIN_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${courseDetail?.courseDetail?.slug}/preview?previewAs=${locals.userRole}`
    const PRIVATE_COURSE_URL_AS_TUTOR = `${EDULYTE_APP_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${courseDetail?.courseDetail?.slug}/preview?previewAs=tutor`

    const onHandleCopyLinkAsAdmin = () => {
        if (courseDetail?.courseDetail?.status === courseStatus?.PUBLISHED?.value) {
            navigator.clipboard.writeText(PUBLISHED_COURSE_URL);
        }
        if ([courseStatus?.DRAFT?.value, courseStatus?.UNDER_REVIEW?.value]?.includes(courseDetail?.courseDetail?.status)) {
            navigator.clipboard.writeText(`${PRIVATE_COURSE_URL_AS_ADMIN}`);
        }
        setIsLinkCopiedAsAdmin(true)
        toast.success("Link Copied")
        setTimeout(() => {
            setIsLinkCopiedAsAdmin(false)
        }, 2000)
    }

    const onHandleCopyLinkAsTutor = () => {
        if (courseDetail?.courseDetail?.status === courseStatus?.PUBLISHED?.value) {
            navigator.clipboard.writeText(PUBLISHED_COURSE_URL);
        }
        if ([courseStatus?.DRAFT?.value, courseStatus?.UNDER_REVIEW?.value]?.includes(courseDetail?.courseDetail?.status)) {
            navigator.clipboard.writeText(`${PRIVATE_COURSE_URL_AS_TUTOR}`);
        }
        setIsLinkCopiedAsTutor(true)
        toast.success("Link Copied")
        setTimeout(() => {
            setIsLinkCopiedAsTutor(false)
        }, 2000)
    }

    const onHandleAsAdmin = () => {
        if (courseDetail?.courseDetail?.status === courseStatus?.PUBLISHED?.value) {
            window.open(PUBLISHED_COURSE_URL, "_blank")
            return;
        }
        if ([courseStatus?.DRAFT?.value, courseStatus?.UNDER_REVIEW?.value]?.includes(courseDetail?.courseDetail?.status)) {
            window.open(PRIVATE_COURSE_URL_AS_ADMIN, "_blank")
        }
    }

    const onHandleAsTutor = () => {
        if (courseDetail?.courseDetail?.status === courseStatus?.PUBLISHED?.value) {
            window.open(PUBLISHED_COURSE_URL, "_blank")
            return;
        }
        if ([courseStatus?.DRAFT?.value, courseStatus?.UNDER_REVIEW?.value]?.includes(courseDetail?.courseDetail?.status)) {
            window.open(PRIVATE_COURSE_URL_AS_TUTOR, "_blank")
        }
    }

    return (
        <div className={"w-full flex flex-col items-center justify-center gap-2 whitespace-normal sm:whitespace-nowrap"}>

            {/* as admin */}
            <div className={"w-full flex items-center justify-center gap-2"}>
                <div className={"w-full flex items-center justify-between gap-3 border border-primary-dark px-2 py-1 rounded-lg"}>
                    <a
                        href={[courseStatus?.PUBLISHED?.value]?.includes(courseDetail?.courseDetail?.status)
                            ? PUBLISHED_COURSE_URL
                            : PRIVATE_COURSE_URL_AS_ADMIN
                        }
                        target={"_blank"}
                        className={"font-bodyPri font-normal text-primary-dark hover:underline hover:text-primary-dark text-base tracking-wide cursor-pointer"}>
                        {`${courseDetail?.courseDetail?.slug}`}
                    </a>

                    <div className={"flex items-center justify-center gap-3"}>
                        <span className={""} onClick={onHandleAsAdmin}>
                            <FiExternalLink className={"text-lg text-primary-dark cursor-pointer"} />
                        </span>
                        <span className={""} onClick={onHandleCopyLinkAsAdmin}>
                            {isLinkCopiedAsAdmin
                                ? <BsCheck2Square className={"text-lg text-primary-dark"} />
                                : <MdOutlineContentCopy className={"text-lg text-text-900 cursor-pointer"} />
                            }
                        </span>
                    </div>
                </div>
                <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                    {"As Admin"}
                </span>
            </div>

            {/* as tutor */}
            <div className={"w-full flex items-center justify-center gap-2"}>
                <div className={"w-full flex items-center justify-between gap-3 border border-primary-dark px-2 py-1 rounded-lg"}>
                    <a
                        href={[courseStatus?.PUBLISHED?.value]?.includes(courseDetail?.courseDetail?.status)
                            ? PUBLISHED_COURSE_URL
                            : PRIVATE_COURSE_URL_AS_TUTOR
                        }
                        target={"_blank"}
                        className={"font-bodyPri font-normal text-primary-dark hover:underline hover:text-primary-dark text-base tracking-wide cursor-pointer"}>
                        {`${courseDetail?.courseDetail?.slug}`}
                    </a>

                    <div className={"flex items-center justify-center gap-3"}>
                        <span className={""} onClick={onHandleAsTutor}>
                            <FiExternalLink className={"text-lg text-primary-dark cursor-pointer"} />
                        </span>
                        <span className={""} onClick={onHandleCopyLinkAsTutor}>
                            {isLinkCopiedAsTutor
                                ? <BsCheck2Square className={"text-lg text-primary-dark"} />
                                : <MdOutlineContentCopy className={"text-lg text-text-900 cursor-pointer"} />
                            }
                        </span>
                    </div>
                </div>
                <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                    {"As Tutor"}
                </span>
            </div>
        </div>
    )
}

export default PrivateCourseLink