import { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { Reorder } from "framer-motion";

import image from 'assets/images/no-item-found.png';

import ComponentLoader from "components/loader/ComponentLoader";
import Pagination from 'components/pagination/Pagination';
import DashedBorderButton from "components/buttons/dashedBorderButton";

import EditUserHeader from "pages/auth/editUser/commonComponents/header/EditUserHeader";

import LinkTreeCard, { LinkTreeCardLoader } from './components/linkTreeCard';
import AddLink from './components/addLink';

import { getMasterSocialList } from 'redux/master/master.request';
import linkTreeService from "redux/linkTree/linkTree.service";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { DEFAULT_LINK_IN_BIO_LIST_PAGE, DEFAULT_LINK_IN_BIO_LIST_RECORDS } from "redux/linkTree/linkTree.const";

import { debounce } from "utils/generators.utils";

const EditUserSocial = () => {
    const { socialList } = useAppState((state) => state.master);
    const { userDetail } = useAppState((state) => state.user);

    const dispatcher = useAppDispatcher()

    const [linkList, setLinkList] = useState({
        isLoading: false,
        data: [],
        activePage: 1,
        message: null
    });
    const [linkOrderList, setLinkOrderList] = useState({
        isLoading: false,
        data: [],
        message: null
    })

    useEffect(() => {
        if (userDetail?.userDetail?.username) {
            const requestPayload = {
                slug: userDetail?.userDetail?.username,
                page: linkList?.activePage || DEFAULT_LINK_IN_BIO_LIST_PAGE,
                records: DEFAULT_LINK_IN_BIO_LIST_RECORDS
            }
            dispatcher(getMasterSocialList())
            getLinkInBioList(requestPayload)
        }
    }, [userDetail?.userDetail?.username])

    // const sortLink = useMemo(() => (link1, link2) => link1.order < link2.order ? -1 : 1, []);

    const getLinkInBioList = async (query) => {
        setLinkList((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                query: query
            }
            const response = await linkTreeService.getLinkInBioList(requestData)
            if (response.status === 200) {
                // setLinkList((prevState) => ({ ...prevState, data: response?.data?.data?.links?.slice(0)?.sort(sortLink) }))
                setLinkList((prevState) => ({ ...prevState, data: response?.data?.data }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
            setLinkList((prevState) => ({ ...prevState, message: error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!' }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
        } finally {
            setLinkList((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    const onHandleReorderLink = (newOrder) => {
        setLinkList((prevState) => ({
            ...prevState,
            data: {
                ...prevState?.data,
                result: prevState?.data?.result?.map((item) => ({ ...item, links: newOrder }))
            }
        }));
        const reorderedPayload = { order: newOrder?.map((link) => ({ id: link?.id })) };
        const delayFunction = debounce.debounce(reorderLinkOrderList, 1000)
        delayFunction(reorderedPayload)
    };

    const reorderLinkOrderList = async (reorderedPayload) => {
        if (linkOrderList?.isLoading) return;

        setLinkOrderList((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { linkTreeId: linkList?.data?.result[0]?.id },
                body: reorderedPayload
            }
            const response = await linkTreeService.reorderLink(requestData)
            if (response.status === 200) {
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
            setLinkOrderList((prevState) => ({ ...prevState, message: error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!' }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
        } finally {
            setLinkOrderList((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    const onHandleAddLinkInBio = async () => {
        setLinkList((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                body: { slug: userDetail?.userDetail?.username }
            }
            const response = await linkTreeService.createLinkInBio(requestData)
            if (response.status === 201) {
                setLinkList((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState?.data,
                        result: [response?.data?.data?.result]
                    }
                }))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
            setLinkList((prevState) => ({ ...prevState, message: error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!' }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong!')
        } finally {
            setLinkList((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    if (socialList?.message) {
        return (
            <div className={"w-full h-full flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    {socialList?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={"w-full space-y-3"}>
            <EditUserHeader title={"Social Media Presence"} />

            {linkList?.isLoading || socialList?.isLoading &&
                <ComponentLoader isLoading={true} />
            }
            <div className={"w-full space-y-5 p-0 md:p-5 border md:border-none border-background-light rounded shadow-none md:shadow"}>
                {linkList?.isLoading && (
                    <div className={'space-y-5'}>
                        {new Array(DEFAULT_LINK_IN_BIO_LIST_RECORDS)?.fill("")?.map((_, index) => (
                            <LinkTreeCardLoader key={index} />
                        ))}
                    </div>
                )}
                {(!linkList?.isLoading && !!linkList?.data?.result?.length) &&
                    <>
                        <AddLink isLoading={linkOrderList?.isLoading} linkTreeItem={linkList?.data?.result[0]} linkList={linkList} setLinkList={setLinkList} />
                        {linkList?.data?.result?.map((item) => (
                            <Reorder.Group
                                key={item?.id}
                                className={'space-y-5'}
                                axis={"y"}
                                values={item?.links}
                                onReorder={onHandleReorderLink}
                            >
                                {item?.links?.map((linkItem) => (
                                    <Reorder.Item key={linkItem?.id} value={linkItem}>
                                        <LinkTreeCard treeItem={item} linkItem={linkItem} setLinkList={setLinkList} />
                                    </Reorder.Item>
                                ))}
                            </Reorder.Group>
                        ))}
                        {(!!linkList?.data?.result && (linkList?.data?.pagination?.totalPages > 1)) &&
                            <div className={"w-full flex justify-center items-center py-3"}>
                                <Pagination
                                    page={linkList?.data?.pagination?.page}
                                    totalPages={linkList?.data?.pagination?.totalPages}
                                    onChangePage={(page) => setLinkList((prevState) => ({ ...prevState, activePage: page }))}
                                />
                            </div>
                        }
                    </>
                }
                {(!linkList?.isLoading && (linkList?.data?.result && (linkList?.data?.result?.length === 0))) && (
                    <div className={'w-full flex flex-col items-center justify-center space-y-5 text-sm text-text-600'}>
                        <img
                            className={'h-32 aspect-video'}
                            alt={'no item found'}
                            src={image}
                        />
                        <p>{"No Link Added Yet"}</p>
                        <DashedBorderButton
                            isShowBtn={true}
                            btnTitle={"Add link(s)"}
                            onHandleAdd={onHandleAddLinkInBio}
                        />
                    </div>
                )}
                {(!linkList?.isLoading && linkList?.message) &&
                    <div className={"w-full h-full flex items-center justify-center"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {linkList?.message}
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default EditUserSocial;