import { cn } from "utils/cn.utils";

import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { useAppState } from 'hooks/useStore'


const PaymentMethodSummary = () => {
    const { makePayment } = useAppState(s => s.payment)
    const makePaymentPayload = makePayment?.makePaymentPayload

    const processingFee = parseInt(Math.ceil(makePaymentPayload?.totalAmountToPay * (makePaymentPayload?.processingPctFee / 100)))

    return (
        <div className={"space-y-2"}>
            <div className={"w-full flex items-center justify-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-800 text-center"}>
                    {"Summary"}
                </span>
            </div>
            <div className={"flex itmes-center justify-between font-bodyPri font-normal text-base text-text-900"}>
                <span>{"Amount:"}</span>
                <span>{"USD"} {parseFloat(makePaymentPayload?.amountToPay / 100).toFixed(2)}</span>
            </div>
            <div className={"flex itmes-center justify-between font-bodyPri font-normal text-base text-text-900"}>
                <div className={"flex items-center justify-satart gap-1"}>
                    <span>{"Processing fee:"}</span>
                    <span className={"relative has-tooltip cursor-pointer"}>
                        <AiOutlineQuestionCircle className={"text-text-700"} />
                        <span className={cn(
                            'tooltip z-50! bottom-5 -left-28 px-2 py-1 w-80 leading-0.5 text-center',
                            'bg-black/90 text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide',
                        )}>
                            {"Processing fees are charged by the third party payment providers, we recommend purchasing more sessions at one time to reduce the processing fee"}
                        </span>
                    </span>
                </div>
                <span>{"USD"} {parseFloat(processingFee / 100).toFixed(2)}</span>
            </div>
            <div className={"flex items-start justify-between font-bodyPri font-normal text-base text-text-900"}>
                <span>{"Total amount:"}</span>
                <div className={"flex flex-col items-end justify-start"}>
                    <span>{"USD"} {parseFloat(makePaymentPayload?.totalAmountToPay / 100).toFixed(2)}</span>
                    <span className={"text-sm text-text-800"}>
                        {"Approx"} {"INR"} {parseFloat(makePaymentPayload?.totalAmountToPay / 100).toFixed(2)}
                    </span>
                </div>
            </div>

        </div>
    )
}

export default PaymentMethodSummary