export const filterName = {
    COMPANY_ONBOARDING: {
        value: "company_onboarding"
    }
}

export const companyOnboardingStatus = {
    NOT_SUBMITTED: {
        label: "Not Submitted",
        value: "not_submitted",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        lightColor: 'secondary-light',
        darkColor: 'secondary-main',
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        lightColor: 'yellow-100',
        darkColor: 'yellow-500',
    },
    VERIFIED: {
        label: "Verified",
        value: "verified",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    RE_UPLOAD: {
        label: "Re Upload",
        value: "re_upload",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
    }
}

export const companyOnboardingFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    userId:"",
    userName: "",
    email: "",
    phone: "",
    status: companyOnboardingStatus.NOT_SUBMITTED.value,
    date: "" 
}