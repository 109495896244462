import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import courseService from "redux/course/course.service";

import {
    setPublicCourseDetailLoading,
    setPublicCourseDetailData,
    setPublicCourseDetailMessage,

    setSelectedPublicCourseDetailLoading,
    setSelectedPublicCourseDetail,
    setSelectedPublicCourseDetailMessage,

    setCourseTemplateListLoading,
    setCourseTemplateList,
    setCourseTemplateListErrorMsg,

    setCourseTemplateDetailLoading,
    setCourseTemplateDetail,
    setCourseTemplateDetailErrorMsg,

    setCourseDetailLoading,
    setCourseDetail,
    setCourseDetailErrorMsg,

    setAddCourseDetailLoading,
    setAddCourseDetail,
    setAddCourseDetailErrorMsg,

    setCourseTagListLoading,
    setCourseTagList,
    setCourseTagListErrorMsg,

    setAddCourseTagDetailLoading,
    setAddCourseTagDetail,
    setAddCourseTagDetailErrorMsg,

    setCourseLanguageListLoading,
    setCourseLanguageList,
    setCourseLanguageListErrorMsg,

    setAddCourseLanguageDetailLoading,
    setAddCourseLanguageDetail,
    setAddCourseLanguageDetailErrorMsg,

    setCourseProfListLoading,
    setCourseProfList,
    setCourseProfListErrorMsg,

    setAddCourseProfDetailLoading,
    setAddCourseProfDetail,
    setAddCourseProfDetailErrorMsg,

    setCourseAvailDetailLoading,
    setCourseAvailDetail,
    setCourseAvailErrorMsg,

    setAddCourseAvailDetailLoading,
    setAddCourseAvailDetail,
    setAddCourseAvailDetailErrorMsg,

    setCoursePriceDetailLoading,
    setCoursePriceDetail,
    setCoursePriceDetailErrorMsg,

    setAddCoursePriceDetailLoading,
    setAddCoursePriceDetail,
    setAddCoursePriceDetailErrorMsg,

    setCourseCommentListLoading,
    setCourseCommentListData,
    setCourseCommentListMessage,

    setAddCourseCommentDetailLoading,
    setAddCourseCommentDetailMessage,

    setCoursePreviewDetailLoading,
    setCoursePreviewDetailData,
    setCoursePreviewDetailMessage,

    setNextCourseSessionDetailLoading,
    setNextCourseSessionDetailData,
    setNextCourseSessionDetailMessage,

    setCourseTutorDetailLoading,
    setCourseTutorDetailData,
    setCourseTutorDetailMessage,

    setAddCourseTutorDetailLoading,
    setAddCourseTutorDetailData,
    setAddCourseTutorDetailMessage,

    setUpdateCourseTutorDetailLoading,
    setUpdateCourseTutorDetailData,
    setUpdateCourseTutorDetailMessage,

    setDeleteCourseTutorDetailLoading,
    setDeleteCourseTutorDetailMessage,

    setCourseConfigDetailLoading,
    setCourseConfigDetailData,
    setCourseConfigDetailMessage,

    setAddCourseConfigDetailLoading,
    setAddCourseConfigDetailMessage,

    setUpdateCourseConfigDetailLoading,
    setUpdateCourseConfigDetailMessage,

    setDeleteCourseConfigDetailLoading,
    setDeleteCourseConfigDetailMessage,

    setCourseTutorListLoading,
    setCourseTutorListData,
    setCourseTutorListMessage,
    
    setCloneCourseDetailLoading,
    setCloneCourseDetailData,
    setCloneCourseDetailMessage,
} from "redux/course/course.slice";
import { courseRedux } from "redux/course/course.const";

import { timeZone } from "utils/dateTime.utils";
import { getSessionCourseList } from "redux/session/session.request";

export const getCourseList = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseList?.requestName}`,
    async (query, { rejectWithValue }) => {

        try {
            const requestData = {
                query: { ...query }
            }
            const response = await courseService.getCourseList(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const getCourseDetailByCourseId = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseDetail?.requestName}`,
    async (courseId, { dispatch }) => {
        dispatch(setCourseDetailLoading(true))

        try {
            const requestData = {
                params: { courseId: courseId }
            }
            const response = await courseService.getCourseDetailByCourseId(requestData)
            if (response.status === 200) {
                dispatch(setCourseDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setCourseDetailErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setCourseDetailLoading(false))
        }
    }
)

export const updateCourseListByCourseId = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseList?.requestName}`,
    async (courseDetail, { getState, rejectWithValue }) => {

        try {
            const { courseList } = getState()?.course
            const requestData = {
                params: { courseId: courseDetail?.courseId },
                body: courseDetail?.body
            }
            const response = await courseService.updateCourseDetailByCourseId(requestData)
            if (response.status === 200) {
                toast.success(response.data.message)
                const results = courseList?.data?.results?.map(course => course?.courseId === courseDetail?.courseId ? response.data.data : course)
                return { ...courseList?.data, results }
            }
            else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
        }
    }
)

export const updateCourseDetailByCourseId = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseDetail?.requestName}`,
    async (courseDetail, { dispatch }) => {
        dispatch(setAddCourseDetailLoading(true))

        try {
            const requestData = {
                params: { courseId: courseDetail?.courseId },
                body: courseDetail?.body
            }
            const response = await courseService.updateCourseDetailByCourseId(requestData)
            if (response.status === 200) {
                dispatch(setCourseDetail(response.data.data))
                dispatch(setAddCourseDetail(response.data.data))
                toast.success(response.data.message)
            }
            else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddCourseDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
        } finally {
            dispatch(setAddCourseDetailLoading(false))
        }
    }
)

export const deleteCourseByCourseId = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.deleteCourse?.requestName}`,
    async (courseId, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { courseId: courseId }
            }
            const response = await courseService.deleteCourseByCourseId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

// new course modification

export const getPublicCourseDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.publicCourseDetail?.requestName}`,
    async (publicCourseDetail, { dispatch }) => {
        dispatch(setPublicCourseDetailLoading(true))

        try {
            const requestData = {
                params: { slug: publicCourseDetail?.courseId }
            }
            const response = await courseService.getPublicCourseDetail(requestData)
            if (response.status === 200) {
                dispatch(setPublicCourseDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setPublicCourseDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setPublicCourseDetailLoading(false))
        }
    }
)


// Get Selected Public Course Detail Request
export const getSelectedPublicCourseDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.selectedPublicCourseDetail?.requestName}`,
    async (slug, { dispatch }) => {
        dispatch(setSelectedPublicCourseDetailLoading(true))

        try {
            const requestData = {
                params: { slug: slug }
            }
            const response = await courseService.getPublicCourseDetail(requestData)
            if (response.status === 200) {
                dispatch(setSelectedPublicCourseDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setSelectedPublicCourseDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setSelectedPublicCourseDetailLoading(false))
        }
    }
)

// course template list
export const getCourseTemplateList = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTemplateList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setCourseTemplateListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await courseService.getCourseTemplateList(requestData)
            if (response.status === 200) {
                dispatch(setCourseTemplateList(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseTemplateListErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseTemplateListLoading(false))
        }
    }
)

// course template detail
export const getCourseTemplateDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTemplateDetail?.requestName}`,
    async (courseTemplateId, { dispatch }) => {
        dispatch(setCourseTemplateDetailLoading(true))

        try {
            const requestData = {
                params: { courseTemplateId: courseTemplateId }
            }
            const response = await courseService.getCourseTemplateDetail(requestData)
            if (response.status === 200) {
                dispatch(setCourseTemplateDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseTemplateDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseTemplateDetailLoading(false))
        }
    }
)

// get course detail
export const getCourseDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseDetail?.requestName}`,
    async (courseId, { dispatch }) => {
        dispatch(setCourseDetailLoading(true))

        try {
            const requestData = {
                params: { courseId: courseId }
            }
            const response = await courseService.getCourseDetail(requestData)
            if (response.status === 200) {
                dispatch(setCourseDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseDetailLoading(false))
        }
    }
)

// create course detail
export const createCourseDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseDetail?.sliceName}`,
    async (body, { dispatch }) => {
        dispatch(setAddCourseDetailLoading(true))
        try {
            const requestData = {
                body: body
            }
            const response = await courseService.createCourseDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseDetail(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseDetailLoading(false))
        }
    }
)

// update course detail
export const updateCourseDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseDetail?.sliceName}`,
    async (courseDetail, { dispatch }) => {
        dispatch(setAddCourseDetailLoading(true))

        try {
            const requestData = {
                params: { courseId: courseDetail?.id },
                body: courseDetail?.body
            }
            const response = await courseService.updateCourseDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseDetail(response.data.data))
                dispatch(setCourseDetail(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseDetailLoading(false))
        }
    }
)

// delete course detail
export const deleteCourseDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseDetail?.sliceName}`,
    async (courseDetail, { dispatch }) => {
        try {
            const requestData = {
                params: { courseId: courseDetail?.courseId }
            }
            const response = await courseService.deleteCourseDetail(requestData)
            if (response.status === 200) {
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseDetailLoading(false))
        }
    }
)

// course tag list
export const getCourseTagList = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTagList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setCourseTagListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await courseService.getCourseTagList(requestData)
            if (response.status === 200) {
                dispatch(setCourseTagList(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseTagListErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseTagListLoading(false))
        }
    }
)

// create course tag detail
export const createCourseTagDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTagList?.requestName}`,
    async (body, { dispatch, getState }) => {
        dispatch(setAddCourseTagDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                body: body
            }
            const response = await courseService.createCourseTagDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseTagDetail(response.data.data))
                // dispatch(setCourseTagList([...courseTagList?.courseTagList?.results, response.data.data]))
                dispatch(getCourseTagList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseTagDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseTagDetailLoading(false))
        }
    }
)

// update course tag list by order
export const updateCourseTagListOrder = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTagList?.requestName}`,
    async (courseTagOrderPayload, { dispatch, getState }) => {
        dispatch(setAddCourseTagDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                query: courseTagOrderPayload?.query,
                body: courseTagOrderPayload?.body
            }
            const response = await courseService.updateCourseTagListOrder(requestData)
            if (response.status === 200) {
                dispatch(getCourseTagList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseTagDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseTagDetailLoading(false))
        }
    }
)

// delete course tag detail
export const deleteCourseTagDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTagList?.requestName}`,
    async (courseTagId, { dispatch, getState }) => {
        dispatch(setAddCourseTagDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                params: { courseTagId: courseTagId }
            }
            const response = await courseService.deleteCourseTagDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseTagDetail(response.data.data))
                dispatch(getCourseTagList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseTagDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseTagDetailLoading(false))
        }
    }
)

// course language list
export const getCourseLanguageList = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseLanguageList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setCourseLanguageListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await courseService.getCourseLanguageList(requestData)
            if (response.status === 200) {
                dispatch(setCourseLanguageList(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseLanguageListErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseLanguageListLoading(false))
        }
    }
)

// create course language detail
export const createCourseLanguageDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseLanguageList?.requestName}`,
    async (body, { dispatch, getState }) => {
        dispatch(setAddCourseLanguageDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                body: body
            }
            const response = await courseService.createCourseLanguageDetail(requestData)
            if (response.status === 200) {
                dispatch(setCourseLanguageList(response.data.data))
                // dispatch(setAddCourseLanguageDetail(response.data.data))
                dispatch(getCourseLanguageList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseLanguageDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseLanguageDetailLoading(false))
        }
    }
)

// update course language list order
export const updateCourseLanguageListOrder = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseLanguageList?.requestName}`,
    async (languageOrderListPayload, { dispatch, getState }) => {
        dispatch(setAddCourseLanguageDetailLoading(true))
        try {
            const { courseDetail } = getState().course
            const requestData = {
                query: languageOrderListPayload?.query,
                body: languageOrderListPayload?.body,
            }
            const response = await courseService.updateCourseLanguageListOrder(requestData)
            if (response.status === 200) {
                // dispatch(setCourseLanguageList(response.data.data))
                dispatch(getCourseLanguageList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseLanguageDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseLanguageDetailLoading(false))
        }
    }
)

// delete course language detail
export const deleteCourseLanguageDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseLanguageList?.requestName}`,
    async (courseLanguageId, { dispatch, getState }) => {
        dispatch(setAddCourseLanguageDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                params: { courseLanguageId: courseLanguageId }
            }
            const response = await courseService.deleteCourseLanguageDetail(requestData)
            if (response.status === 200) {
                dispatch(setCourseLanguageList(response.data.data))
                dispatch(setAddCourseLanguageDetail(response.data.data))
                dispatch(getCourseLanguageList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseLanguageDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseLanguageDetailLoading(false))
        }
    }
)

// course proficiency list
export const getCourseProficiencyList = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseProfList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setCourseProfListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await courseService.getCourseProficiencyList(requestData)
            if (response.status === 200) {
                dispatch(setCourseProfList(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseProfListErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseProfListLoading(false))
        }
    }
)

// create course prof detail
export const createCourseProficiencyDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseProfList.requestName}`,
    async (body, { dispatch, getState }) => {
        dispatch(setAddCourseProfDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                body: body
            }
            const response = await courseService.createCourseProficiencyDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseProfDetail(response.data.data))
                dispatch(getCourseProficiencyList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseProfDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseProfDetailLoading(false))
        }
    }
)

// update course prof order
export const updateCourseProficiencyListOrder = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseProfList.requestName}`,
    async (proficiencyListOrder, { dispatch, getState }) => {
        dispatch(setAddCourseProfDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                query: proficiencyListOrder?.query,
                body: proficiencyListOrder?.body
            }
            const response = await courseService.updateCourseProficiencyListOrder(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseProfDetail(response.data.data))
                dispatch(getCourseProficiencyList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseProfDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseProfDetailLoading(false))
        }
    }
)

// delete course prof detail
export const deleteCourseProficiencyDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseProfList?.requestName}`,
    async (courseProficiencyId, { dispatch, getState }) => {
        dispatch(setAddCourseProfDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                params: { courseProficiencyId: courseProficiencyId }
            }
            const response = await courseService.deleteCourseProficiencyDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseProfDetail(response.data.data))
                dispatch(getCourseProficiencyList({ course: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseProfDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseProfDetailLoading(false))
        }
    }
)

// course availability detail
export const getCourseAvailabilityDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseAvailDetail?.requestName}`,
    async (courseAvailDetail, { dispatch }) => {
        dispatch(setCourseAvailDetailLoading(true))

        try {
            const requestData = {
                params: { courseAvailabilityId: courseAvailDetail?.courseAvailabilityId },
                query: courseAvailDetail?.query
            }
            const response = await courseService.getCourseAvailabilityDetail(requestData)
            if (response.status === 200) {
                dispatch(setCourseAvailDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseAvailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseAvailDetailLoading(false))
        }
    }
)

// create course availability detail
export const createCourseAvailabilityDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseAvailDetail?.requestName}`,
    async (body, { dispatch, getState }) => {
        dispatch(setAddCourseAvailDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                body: body
            }
            const response = await courseService.createCourseAvailabilityDetail(requestData)
            if (response.status === 200) {
                dispatch(getCourseDetail(courseDetail?.courseDetail?.id))
                dispatch(setAddCourseAvailDetail(response.data.data))
                dispatch(setCourseAvailDetail(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseAvailDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseAvailDetailLoading(false))
        }
    }
)

// update course availability detail
export const updateCourseAvailabilityDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseAvailDetail?.requestName}`,
    async (courseAvailability, { dispatch }) => {
        dispatch(setAddCourseAvailDetailLoading(true))

        try {
            const requestData = {
                params: { courseAvailabilityId: courseAvailability?.courseAvailabilityId },
                body: courseAvailability?.body
            }
            const response = await courseService.updateCourseAvailabilityDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseAvailDetail(response.data.data))
                dispatch(setCourseAvailDetail(response.data.data))
                dispatch(getCourseAvailabilityDetail({ courseAvailabilityId: courseAvailability?.courseAvailabilityId, query: { timeZone: timeZone } }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseAvailDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseAvailDetailLoading(false))
        }
    }
)

// delete course availability detail
export const deleteCourseAvailabilityDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseAvailDetail?.requestName}`,
    async (courseAvailabilityId, { dispatch }) => {
        dispatch(setAddCourseAvailDetailLoading(true))

        try {
            const requestData = {
                params: { courseAvailabilityId: courseAvailabilityId }
            }
            const response = await courseService.deleteCourseAvailabilityDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseAvailDetail(response.data.data))
                dispatch(getCourseAvailabilityDetail({ courseAvailabilityId: courseAvailabilityId, query: { timeZone: timeZone } }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseAvailDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseAvailDetailLoading(false))
        }
    }
)

// course price detail
export const getCoursePriceDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.coursePriceDetail?.requestName}`,
    async (coursePriceId, { dispatch }) => {
        dispatch(setCoursePriceDetailLoading(true))

        try {
            const requestData = {
                params: { coursePriceId: coursePriceId }
            }
            const response = await courseService.getCoursePriceDetail(requestData)
            if (response.status === 200) {
                dispatch(setCoursePriceDetail(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCoursePriceDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCoursePriceDetailLoading(false))
        }
    }
)

// create course price detail
export const createCoursePriceDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.coursePriceDetail?.requestName}`,
    async (body, { dispatch, getState }) => {
        dispatch(setAddCoursePriceDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                body: body
            }
            const response = await courseService.createCoursePriceDetail(requestData)
            if (response.status === 200) {
                dispatch(setCoursePriceDetail(response.data.data))
                dispatch(getCourseDetail(courseDetail?.courseDetail?.id))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCoursePriceDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCoursePriceDetailLoading(false))
        }
    }
)

// update course price detail
export const updateCoursePriceDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.coursePriceDetail?.requestName}`,
    async (coursePriceDetail, { dispatch, getState }) => {
        dispatch(setAddCoursePriceDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                params: { coursePriceId: coursePriceDetail?.coursePriceId },
                body: coursePriceDetail?.body
            }
            const response = await courseService.updateCoursePriceDetail(requestData)
            if (response.status === 200) {
                dispatch(setCoursePriceDetail(response.data.data))
                dispatch(getCourseDetail(courseDetail?.courseDetail?.id))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCoursePriceDetail(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCoursePriceDetailLoading(false))
        }
    }
)

// delete course price detail
export const deleteCoursePriceDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseAvailDetail?.requestName}`,
    async (coursePriceId, { dispatch }) => {
        dispatch(setAddCoursePriceDetailLoading(true))

        try {
            const requestData = {
                params: { coursePriceId: coursePriceId }
            }
            const response = await courseService.deleteCoursePriceDetail(requestData)
            if (response.status === 200) {
                dispatch(setCoursePriceDetail(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCoursePriceDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCoursePriceDetailLoading(false))
        }
    }
)

// get course comment list
export const getCourseCommentList = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseCommentList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setCourseCommentListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await courseService.getCourseCommentList(requestData)
            if (response.status === 200) {
                dispatch(setCourseCommentListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseCommentListMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseCommentListLoading(false))
        }
    }
)

// create course comment detail
export const createCourseCommentDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseCommentList?.requestName}`,
    async (body, { dispatch, getState }) => {
        dispatch(setAddCourseCommentDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                body: body
            }
            const response = await courseService.createCourseCommentDetail(requestData)
            if (response.status === 200) {
                dispatch(getCourseCommentList({ courseId: courseDetail?.courseDetail?.id }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseCommentDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setAddCourseCommentDetailLoading(false))
        }
    }
)

// course preview detail
export const getCoursePreviewDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.coursePreviewDetail?.requestName}`,
    async (slug, { dispatch }) => {
        dispatch(setCoursePreviewDetailLoading(true))

        try {
            const requestData = {
                params: { slug: slug }
            }
            const response = await courseService.getCoursePreviewDetail(requestData)
            if (response.status === 200) {
                dispatch(setCoursePreviewDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCoursePreviewDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCoursePreviewDetailLoading(false))
        }
    }
)

// create next course session detail
export const createNextCourseSessionDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.nextCourseSessionDetail?.requestName}`,
    async (nextSessionDetailPayload, { dispatch }) => {
        dispatch(setNextCourseSessionDetailLoading(true))

        try {
            const requestData = {
                params: { courseId: nextSessionDetailPayload?.courseId },
                body: nextSessionDetailPayload?.body
            }
            const response = await courseService.createNextCourseSessionDetail(requestData)
            if (response.status === 200) {
                dispatch(setNextCourseSessionDetailData(response.data.message))
                dispatch(getSessionCourseList({ courseId: nextSessionDetailPayload?.courseId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setNextCourseSessionDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setNextCourseSessionDetailLoading(false))
        }
    }
)

// get course tutor list
export const getCourseTutorList = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTutorList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setCourseTutorListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await courseService.getCourseTutorList(requestData)
            if (response.status === 200) {
                dispatch(setCourseTutorListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseTutorListMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseTutorListLoading(false))
        }
    }
)

// get course tutor detail
export const getCourseTutorDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTutorDetail?.requestName}`,
    async (courseTutorId, { dispatch }) => {
        setCourseTutorDetailLoading(true)

        try {
            const requestData = {
                params: { courseTutorId: courseTutorId }
            }
            const response = await courseService.getCourseTutorDetail(requestData)
            if (response.status === 200) {
                dispatch(setCourseTutorDetailData(response.data.data))
                toast.message(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setCourseTutorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setCourseTutorDetailLoading(false))
        }
    }
)

// create course tutor detail
export const createCourseTutorDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTutorDetail?.requestData}`,
    async (body, { dispatch }) => {
        dispatch(setAddCourseTutorDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await courseService.createCourseTutorDetail(requestData)
            if (response.status === 200) {
                dispatch(setAddCourseTutorDetailData(response.data.data))
                const query = { courseId: body?.courseId }
                dispatch(getCourseTutorList(query))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddCourseTutorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddCourseTutorDetailLoading(false))
        }
    }
)

// update course tutor detail
export const updateCourseTutorDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTutorDetail.requestName}`,
    async (courseTutorDetailPayload, { dispatch, getState }) => {
        dispatch(setUpdateCourseTutorDetailLoading(true))

        try {
            const { courseDetail } = getState().course
            const requestData = {
                params: { courseTutorId: courseTutorDetailPayload?.courseTutorId },
                body: courseTutorDetailPayload?.body
            }
            const response = await courseService.updateCourseTutorDetail(requestData)
            if (response.status === 200) {
                dispatch(setUpdateCourseTutorDetailData(response.data.data))
                dispatch(getCourseTutorList({ courseId: courseDetail?.courseDetail?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setUpdateCourseTutorDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setUpdateCourseTutorDetailLoading(false))
        }
    }
)

// delete course tutor detail
export const deleteCourseTutorDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseTutorDetail?.requestName}`,
    async (courseTutorId, { dispatch }) => {
        dispatch(setDeleteCourseTutorDetailLoading(true))

        try {
            const requestData = {
                params: { courseTutorId: courseTutorId }
            }
            const response = await courseService.deleteCourseTutorDetail(requestData)
            if (response.status === 200) {
                dispatch(getCourseTutorDetail(courseTutorId))
                dispatch(getCourseTutorList({ courseTutorId: courseTutorId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setDeleteCourseTutorDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setDeleteCourseTutorDetailLoading(false))
        }
    }
)

// get course config detail
export const getCourseConfigDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseConfigDetail?.requestName}`,
    async (courseConfigId, { dispatch }) => {
        dispatch(setCourseConfigDetailLoading(true))

        try {
            const requestData = {
                params: { courseConfigId: courseConfigId }
            }
            const response = await courseService.getCourseConfigDetail(requestData)
            if (response.status === 200) {
                dispatch(setCourseConfigDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCourseConfigDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setCourseConfigDetailLoading(false))
        }
    }
)

// create course config detail
export const createCourseConfigDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseConfigDetail?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddCourseConfigDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await courseService.createCourseConfigDetail(requestData)
            if (response.status === 200) {
                dispatch(getCourseConfigDetail(response.data.data.id))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddCourseConfigDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddCourseConfigDetailLoading(false))
        }
    }
)

// update course config detail
export const updateCourseConfigDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseConfigDetail.requestName}`,
    async (courseConfigDetailPayload, { dispatch }) => {
        dispatch(setUpdateCourseConfigDetailLoading(true))

        try {
            const requestData = {
                params: { courseConfigId: courseConfigDetailPayload?.courseConfigId },
                body: courseConfigDetailPayload?.body
            }
            const response = await courseService.updateCourseConfigDetail(requestData)
            if (response.status === 200) {
                dispatch(getCourseConfigDetail(courseConfigDetailPayload?.courseConfigId))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setUpdateCourseConfigDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setUpdateCourseConfigDetailLoading(false))
        }
    }
)

// delete course config detail
export const deleteCourseConfigDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.courseConfigDetail?.requestName}`,
    async (courseConfigId, { dispatch }) => {
        dispatch(setDeleteCourseConfigDetailLoading(true))

        try {
            const requestData = {
                params: { courseConfigId: courseConfigId }
            }
            const response = await courseService / deleteCourseConfigDetail(requestData)
            if (response.status == 200) {
                dispatch(getCourseConfigDetail(courseConfigId))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setDeleteCourseConfigDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setDeleteCourseConfigDetailLoading(false))
        }
    }
)


// clone course detail
export const createCloneCourseDetail = createAsyncThunk(
    `${courseRedux?.sliceName}/${courseRedux?.cloneCourseDetail?.requestName}`,
    async (payload, { dispatch }) => {
        dispatch(setCloneCourseDetailLoading(true))

        try {
            const requestData = {
                params: { courseId: payload?.courseId },
                body: payload?.body
            }
            const response = await courseService.createCloneCourseDetail(requestData)
            if (response.status === 200) {
                dispatch(setCloneCourseDetailData(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setCloneCourseDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setCloneCourseDetailLoading(false))
        }
    }
)

// createAsyncThunk(
//     `${courseRedux?.sliceName}/${courseRedux?.courseAvailDetail?.requestName}`,
//     async (query, { rejectWithValue }) => {
//         try {
//             const requestData = {
//                 query: query
//             }
//         } catch (error) {
//             console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
//             rejectWithValue(error.response.data.message || error.response.data.error || "Something Went Wrong!")
//         }
//     }
// )