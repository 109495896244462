import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { MdOutlineFileCopy } from "react-icons/md";
import { RiCheckDoubleLine } from "react-icons/ri";
import { HiBadgeCheck } from "react-icons/hi";
import { BsPatchExclamationFill } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
    amountImpact,
    transactionStatus,
    txnType,
    walletTxnActions
} from "redux/walletTxn/walletTxn.const";
import {
    setClearWalletTxnDetailPayload,
    setClearWalletTxnDetail
} from "redux/walletTxn/walletTxn.slice";

import { dayjs, timeZone } from 'utils/dateTime.utils';

const WalletTxnDetail = () => {
    const { walletTxnDetail } = useAppState((state) => state.walletTxn)

    const [copiedTxnId, setCopiedTxnId] = useState(false)

    const handleCopyTxnId = () => {
        navigator.clipboard.writeText(walletTxnDetail?.walletTxnDetail?.virtualTxnId);
        setCopiedTxnId(true);
        toast.success("Copied!")
        setTimeout(() => {
            setCopiedTxnId(false);
        }, 2000);
    }

    return (
        <div className="w-full flex flex-col justify-center items-center gap-5">
            <div className={"w-full flex items-center gap-3"}>
                <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                    {"Wallet Txn ID:"}
                </span>
                <span className={"font-bodyPri font-medium text-text-800 text-medium"}>
                    {walletTxnDetail?.walletTxnDetail?.walletTxnId}
                </span>
            </div>
            <div className={"w-full flex items-center justify-between gap-5"}>
                <div className={"flex items-center justify-center gap-3"}>
                    <div className="relative w-16 h-16 rounded-full">
                        <img
                            src={walletTxnDetail?.walletTxnDetail?.user?.profilePicUrl}
                            alt={"profileImg"}
                            className="w-full h-full rounded-full object-cover"
                        />
                        <div className='absolute bottom-0 right-0 w-1/4 h-1/4 rounded-full overflow-hidden border-2 border-white'>
                            <img src={`https://flagcdn.com/64x48/${walletTxnDetail?.walletTxnDetail?.user?.countryDomain}.png`.toLowerCase()} alt={"country-flag"}
                                className='w-full h-full object-cover'
                            />
                        </div>
                    </div>
                    <div className={"flex flex-col items-start justify-center"}>
                        <span
                            className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap capitalize"}>
                            {walletTxnDetail?.walletTxnDetail?.user?.firstName + " " + walletTxnDetail?.walletTxnDetail?.user?.lastName.charAt(0) + "."}
                        </span>
                        <div className="flex flex-row gap-2">
                            <span className={"w-full font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                                {"User ID:"}
                            </span>
                            <span
                                className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                                {walletTxnDetail?.walletTxnDetail?.user?.userId}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"flex items-center justify-center gap-3"}>
                    <div className="flex flex-col justify-start items-center">
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Amount"}
                        </span>
                        <div className={"flex items-center gap-1"}>
                            <span
                                className={"w-full font-bodyPri font-semibold text-text-900 text-lg whitespace-nowrap"}
                            >{walletTxnDetail?.walletTxnDetail?.amountImpact === amountImpact.CREDIT.value ? " + " : " - "}
                                {" USD " + (walletTxnDetail?.walletTxnDetail?.amount / 100).toFixed(2)}
                            </span>
                            {transactionStatus.SUCCESS.value === walletTxnDetail?.walletTxnDetail?.status &&
                                <HiBadgeCheck className={cn("inline-flex text-2xl", transactionStatus.SUCCESS.darkColor)} />
                            }
                            {transactionStatus.PENDING.value === walletTxnDetail?.walletTxnDetail?.status &&
                                <BsPatchExclamationFill className={cn("inline-flex text-2xl", transactionStatus.PENDING.darkColor)} />
                            }
                            {transactionStatus.FAILED.value === walletTxnDetail?.walletTxnDetail?.status &&
                                <TiDelete className={cn("inline-flex text-2xl", transactionStatus.FAILED.darkColor)} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <hr className={"w-full border border-text-300"} />
            <div className={"w-full font-bodyPri font-medium text-text-900 text-lg"}>
                {"Transaction Details"}
            </div>
            <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                <div className={"flex flex-col justify-start items-center gap-3 md:flex-row lg:flex-row"}>
                    <span className={"w-full font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Virtual Txn ID:"}
                    </span>
                    <div className="w-full flex items-center justify-between">
                        <span
                            className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {walletTxnDetail?.walletTxnDetail?.virtualTxnId}
                        </span>
                        {!copiedTxnId &&
                            <MdOutlineFileCopy
                                className={"pl-2 text-3xl text-text-500 hover:text-text-700 cursor-pointer"}
                                onClick={handleCopyTxnId}
                            />
                        }
                        {copiedTxnId &&
                            <RiCheckDoubleLine
                                className={"pl-2 text-3xl cursor-pointer text-green-500"}
                                onClick={handleCopyTxnId}
                            />
                        }
                    </div>
                </div>
                <div className={"flex flex-col justify-start items-center gap-3 md:flex-row lg:flex-row"}>
                    <span className={"w-full font-bodyPri font-normal text-text-600 text-base"}>
                        {"Amount:"}
                    </span>
                    <span
                        className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                        {"USD " + (walletTxnDetail?.walletTxnDetail?.amount / 100).toFixed(2)}
                    </span>
                </div>
                <div className={"flex flex-col justify-start items-center gap-3 md:flex-row lg:flex-row"}>
                    <span className={"w-full font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Amount Impact:"}
                    </span>
                    {Object.keys(amountImpact).map((item) => amountImpact[item].value === walletTxnDetail?.walletTxnDetail?.amountImpact &&
                        <span
                            className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {amountImpact[item].label}
                        </span>
                    )}
                </div>
                <div className={"flex flex-col justify-start items-center gap-3 md:flex-row lg:flex-row"}>
                    <span className={"w-full font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Transaction Status:"}
                    </span>
                    {Object.keys(txnType).map((item) => txnType[item].value === walletTxnDetail?.walletTxnDetail?.txnType &&
                        <span
                            className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {txnType[item].label}
                        </span>
                    )}
                </div>
                <div className={"flex flex-col justify-start items-center gap-3 md:flex-row lg:flex-row"}>
                    <span className={"w-full font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Transaction Type:"}
                    </span>
                    {Object.keys(txnType).map((item) => txnType[item].value === walletTxnDetail?.walletTxnDetail?.txnType &&
                        <span
                            className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {txnType[item].label}
                        </span>
                    )}
                </div>
                <div className={"flex flex-col justify-start items-center gap-3 md:flex-row lg:flex-row"}>
                    <span className={"w-full font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Wallet Action:"}
                    </span>
                    {Object.keys(walletTxnActions).map((item) => walletTxnActions[item].value === walletTxnDetail?.walletTxnDetail?.action &&
                        <span
                            className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                            {walletTxnActions[item].label}
                        </span>
                    )}
                </div>
                <div className={"flex flex-col justify-start items-center gap-3 md:flex-row lg:flex-row"}>
                    <span className={"w-full font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                        {"Txn Date:"}
                    </span>
                    <span
                        className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                        {dayjs(walletTxnDetail?.walletTxnDetail?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A')}
                    </span>
                </div>
            </div>
        </div>
    )
}

const WalletTxnSideOverContent = () => {
    const { walletTxnDetail } = useAppState((state) => state.walletTxn)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (walletTxnDetail?.walletTxnDetailPayload?.walletTxnId) {
            // dispatcher(getWalletTxnDetail(walletTxnDetail?.walletTxnDetailPayload?.walletTxnId))
        }

        return () => {
            setClearWalletTxnDetailPayload()
            setClearWalletTxnDetail()
        }
    }, [walletTxnDetail?.walletTxnDetailPayload?.walletTxnId])

    return walletTxnDetail?.walletTxnDetail && (
        <div className="px-4 py-3 max-w-2xl sm:px-10 md:max-w-xl lg:max-w-xl">
            <WalletTxnDetail />
        </div>
    )
}

export default WalletTxnSideOverContent