import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import ButtonLoader from "components/loader/ButtonLoader";
import { OptionSelector } from "components/common-components/Select";

import {
    approveWithdrawalByWithdrawalId,
    rejectWithdrawalByWithdrawalId
} from "redux/withdrawal/withdrawal.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetUpdateWithdrawalDetail } from "redux/withdrawal/withdrawal.slice";
import { gatewayNameConst } from "redux/withdrawal/withdrawal.const";

const SubmitCommentModal = () => {
    const { withdrawalDetail, txnDetail, updateWithdrawalDetail } = useAppState((state) => state.withdrawal)

    const dispatcher = useAppDispatcher()

    const [withdrawalGateWayDetail, setWithdrawalGatewayDetail] = useState({
        gatewayPaymentId: "",
        gatewayName: gatewayNameConst?.BANK?.value,
        comments: ""
    })
    const [characterLength, setCharacterLength] = useState(0)
    const [maxCharacterLength] = useState(100)
    const [isApproved, setIsApproved] = useState(false)
    const [isRejected, setIsRejected] = useState(false)

    useEffect(() => {
        return () => {
            dispatcher(resetUpdateWithdrawalDetail())
        }
    }, [])

    useEffect(() => {
        if (txnDetail?.txnDetailPayload?.isApproved) {
            setIsApproved(true)
        }
        if (txnDetail?.txnDetailPayload?.isRejected) {
            setIsRejected(true)
        }
    }, [txnDetail?.txnDetailPayload])

    const handleOnChange = (event) => {
        const myText = event.target.value
        setWithdrawalGatewayDetail({
            ...withdrawalGateWayDetail,
            comments: myText
        })
        setCharacterLength(myText?.length)
    }

    const onSubmit = (event) => {
        event.preventDefault()

        if (updateWithdrawalDetail?.isLoading || withdrawalDetail?.isLoading) return;

        const withdrawalId = withdrawalDetail?.withdrawalDetailPayload?.withdrawalId
        const addWithdrawalDetailPayload = { withdrawalId, withdrawalGateWayDetail }
        if (isRejected && characterLength >= 20) {
            dispatcher(rejectWithdrawalByWithdrawalId(addWithdrawalDetailPayload))
        }
        if (isApproved && characterLength >= 20) {
            dispatcher(approveWithdrawalByWithdrawalId(addWithdrawalDetailPayload))
        }
    }
    return (
        <form className={"space-y-5 overflow-x-hidden"} onSubmit={onSubmit}>
            <div className={"min-h-[24rem] block space-y-5 pt-5"}>
                <div className={"flex flex-col items-start justify-start gap-3"}>
                    {isApproved &&
                        <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-base whitespace-nowrap"}>
                                {"Gateway Payment ID"}
                            </span>
                            <input
                                type={"text"}
                                className={cn(
                                    "w-full px-2 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                placeholder={"e.g. pi_3Kj1s9D3hA6KTc2J2AiDDLhm"}
                                value={withdrawalGateWayDetail?.gatewayPaymentId}
                                onChange={(event) => setWithdrawalGatewayDetail({
                                    ...withdrawalGateWayDetail,
                                    gatewayPaymentId: event?.target?.value
                                })}
                                autoFocus={true}
                                required
                            />
                        </div>
                    }
                    {isApproved &&
                        <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-base whitespace-nowrap"}>
                                {"Gateway Name"}
                            </span>
                            <OptionSelector
                                options={Object?.values(gatewayNameConst)}
                                className={"w-full"}
                                value={withdrawalGateWayDetail?.gatewayName}
                                onChange={(option) => setWithdrawalGatewayDetail({
                                    ...withdrawalGateWayDetail,
                                    gatewayName: option?.value
                                })}
                            />
                        </div>
                    }
                    <div className={cn(
                        "font-bodyPri font-normal text-text-700 text-base whitespace-nowrap",
                        isRejected && "font-bodyPri font-semibold text-text-700 text-base whitespace-nowrap"
                    )}>
                        {isApproved ? "Comment" : "Are you sure?"}
                    </div>
                    <div className={"w-full font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                        <span>
                            {"Please write your comments/requests."}
                        </span>
                        <span className={"text-xs"}>
                            {" (Required)"}
                        </span>
                    </div>
                    <div className={"w-full"}>
                        <textarea
                            className={cn(
                                "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                                "outline-none border-2 border-secondary-main focus:shadow-all-md focus:shadow-secondary-light focus:border-secondary-main",
                                "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                            )}
                            type={"text"}
                            placeholder="Write your comments ...."
                            value={withdrawalGateWayDetail?.comments}
                            onChange={(event) => handleOnChange(event)}
                            rows={4}
                            minLength={20}
                            maxLength={100}
                            required
                        />
                        <div className={cn("flex justify-end",
                            characterLength === maxCharacterLength && "w-full flex justify-between items-center"
                        )}>
                            {characterLength === maxCharacterLength &&
                                <span className={"font-bodyPri font-normal text-sm text-red-500"}>
                                    {"Maximum character reached"}
                                </span>
                            }
                            <span className={"font-bodyPri font-normal text-text-800 text-xs"}>
                                {characterLength + "/" + maxCharacterLength}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {updateWithdrawalDetail?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"w-full text-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {withdrawalDetail?.message}
                    </span>
                </div>
            }

            <div className={"flex justify-end"}>
                <button
                    type={"submit"}
                    className={cn(
                        "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        (updateWithdrawalDetail?.isLoading || withdrawalDetail?.isLoading) && "bg-primary-dark",
                        (!updateWithdrawalDetail?.isLoading && ((isRejected || isApproved) && characterLength < 20)) && "border border-primary-light text-primary-light",
                        (!updateWithdrawalDetail?.isLoading && ((isRejected || isApproved) && characterLength >= 20)) && "border border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50",
                    )}
                >
                    {(updateWithdrawalDetail?.isLoading || withdrawalDetail?.isLoading)
                        && <ButtonLoader
                            isLoading={updateWithdrawalDetail?.isLoading || withdrawalDetail?.isLoading}
                        />
                    }
                    {(!updateWithdrawalDetail?.isLoading && !withdrawalDetail?.isLoading) && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default SubmitCommentModal