import { memo, useMemo, useState, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import { IoIosArrowForward } from 'react-icons/io';
import { FaInfoCircle } from 'react-icons/fa';

import ToolTipView from 'components/toolTip';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { resetSessionDetail } from 'redux/session/session.slice';
import { modalConst } from 'redux/local/local.const';
import { courseType } from 'redux/course/course.const';
import { addBookingDetailPayload, bookingOfferingTypeEnum, bookingStatusEnum } from 'redux/booking/booking.const';

import { cn } from 'utils/cn.utils';
import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';
import { pagesInfo } from 'utils/pagesInfo';

const MINIMUM_EVENT_LENGTH = 5

const BookingSchedule = memo(({ bookingUserDetail }) => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const timeZoneOffset = useMemo(() => getTimeZoneOffset(timeZone), [timeZone])

    const [visible, setVisible] = useState(true);
    const [lastIndex, setLastIndex] = useState(MINIMUM_EVENT_LENGTH)

    const sortEvent = useCallback((event1, event2) => {
        const event1StartTime = dayjs(event1.startDateTime).utc()
        const event2StartTime = dayjs(event2.startDateTime).utc()
        return event2StartTime.isSameOrBefore(event1StartTime) ? 1 : -1
    }, [])

    const onEventSlotSelect = (sessionId) => {
        dispatcher(resetSessionDetail())
        navigate(`${pagesInfo.SESSIONS.pagePath}?sessionId=${sessionId}`)
    };

    const onHandleAddSessions = () => {
        if (bookingUserDetail?.offeringType === bookingOfferingTypeEnum?.APPOINTMENT?.value) {
            dispatcher(setAddBookingDetailPayload({
                ...addBookingDetailPayload,
                offeringType: bookingOfferingTypeEnum.APPOINTMENT.value,
                bookingId: bookingUserDetail?.id,
                appointmentId: bookingUserDetail?.appointment?.id
            }))
        }
        if (bookingUserDetail?.course?.type === courseType?.ONE_ON_ONE?.value) {
            dispatcher(setAddBookingDetailPayload({
                ...addBookingDetailPayload,
                offeringType: bookingOfferingTypeEnum.COURSE.value,
                bookingId: bookingUserDetail?.id,
                courseId: bookingUserDetail?.course?.id,
                slug: bookingUserDetail?.course?.slug
            }))
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_BOOKING_SESSION_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"flex flex-col gap-1"}>
            <div className={"w-full flex items-center justify-between cursor-pointer"}
                onClick={() => setVisible(!visible)}
            >
                <div className="flex justify-between items-center space-x-5">
                    <span className="text-lg font-bold font-bodyPri">{"Schedule"}</span>
                    <span className="text-xs text-text-700 font-bodyPri font-normal tracking-wide">
                        {`Your local timezone:  ${timeZone} ${timeZoneOffset}`}
                    </span>
                </div>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    visible
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            {(((bookingUserDetail?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)
                || (bookingUserDetail?.offeringType === bookingOfferingTypeEnum.COURSE.value
                    && (bookingUserDetail?.course?.type === courseType?.ONE_ON_ONE?.value)))
                && (bookingUserDetail?.status === bookingStatusEnum?.CONFIRMED?.value)
                && (bookingUserDetail?.userSessions?.length < bookingUserDetail?.bookingPrice?.sessionCount)) &&
                <div className={"flex items-center justify-start gap-1 mt-5"}>
                    <div className={cn(
                        "w-fit px-2 py-1.5 flex items-center justify-center gap-1 rounded-lg border border-primary-dark bg-primary-light",
                        "font-bodyPri font-normal text-primary-dark text-base whitespace-nowrap cursor-pointer",
                        "hover:bg-primary-dark hover:text-text-50"
                    )}
                        onClick={onHandleAddSessions}
                    >
                        <span className={""}>
                            {"Add a Session"}
                        </span>
                        <span className={"font-bodyPri font-semibold"}>
                            {`(${bookingUserDetail?.bookingPrice?.sessionCount - bookingUserDetail?.userSessions?.length} left)`}
                        </span>
                        <ToolTipView content={"Multi session pack: Book your remaining session(s) with your tutor. Add a session and choose a suitable slot. You may message your tutor to open more slots."}>
                            <button className={""}>
                                <FaInfoCircle className={"inline text-md text-text-500 hover:text-text-700"} />
                            </button>
                        </ToolTipView>
                    </div>
                </div>
            }
            <AnimatePresence initial={visible}>
                {visible && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        className={"w-full pt-4 space-y-3 divide-y sm:divide-y-0 transition ease-in-out delay-200 duration-300"}
                    >
                        {!bookingUserDetail?.userSessions || (bookingUserDetail?.userSessions?.length === 0) &&
                            <div className={"w-full flex items-center justify-center"}>
                                <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                    {"No Schedule Added"}
                                </span>
                            </div>
                        }
                        {!!bookingUserDetail?.userSessions?.length &&
                            bookingUserDetail?.userSessions?.slice(0, lastIndex)?.sort(sortEvent)?.map((event, index) => (
                                <div key={index} className={"w-full flex items-center justify-center md:justify-between group font-bodyPri"}>
                                    <span
                                        className="text-xs md:text-base sm:flex-row sm:w-9/12 cursor-pointer group-hover:text-primary-dark group-hover:underline"
                                        onClick={() => onEventSlotSelect(event?.session?.id)}
                                    >
                                        {dayjs(event?.session?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").format('ddd, MMM DD, YYYY')}
                                    </span>
                                    <div
                                        className="w-full space-x-1 cursor-pointer text-slate-600 group-hover:text-primary-dark group-hover:underline"
                                        onClick={() => onEventSlotSelect(event?.session?.id)}
                                    >
                                        <span className="">{dayjs(event?.session?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").format('hh:mm A')}</span>
                                        <span>-</span>
                                        <span className="">
                                            {dayjs(event?.session?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").format('hh:mm A')}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        {bookingUserDetail?.userSessions?.length > lastIndex &&
                            <div className={"flex items-center justify-center font-bodyPri font-normal text-primary-main text-base cursor-pointer hover:underline"}
                                onClick={() => setLastIndex(bookingUserDetail?.userSessions?.length)}
                            >
                                {"See More"}
                            </div>
                        }
                        {(bookingUserDetail?.userSessions?.length > 5 && bookingUserDetail?.userSessions?.length <= lastIndex) &&
                            <div className={"flex items-center justify-center font-bodyPri font-normal text-primary-main text-base cursor-pointer hover:underline"}
                                onClick={() => setLastIndex(MINIMUM_EVENT_LENGTH)}
                            >
                                {"See Less"}
                            </div>
                        }
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
});

export default BookingSchedule;