export const viewLeadHeaderConst = {
    LEAD_ID: {
        label: "Lead ID",
        value: "lead_id"
    },
    USER_NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    MOBILE_NO: {
        label: "Mobile No",
        value: "mobile_no"
    },
    LEAD_TYPE: {
        label: "Lead Type",
        value: "lead_type"
    },
    CONTACT_TIME: {
        label: "Contact Time",
        value: "contact_time"
    },
    LEAD_STRENGTH: {
        label: "Lead Strength",
        value: "lead_strength"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTION: {
        label: "Action",
        value: "action"
    }
}