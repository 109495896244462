export const pageHeading = {
    heading: "My Profile",
    subHeading: "View and update your professional profile!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/profile.svg"
}

export const userProfile = [
    {
        "userId": 2,
        "email": "deepmala@edulyte.com",
        "isdCode": "91",
        "mobileNo": "9876543210",
        "isEmailVerified": false,
        "isMobileNoVerified": false,
        "firstName": "deepmala",
        "lastName": "sharma",
        "gender": "female",
        "dateOfBirth": "2022-03-07",
        "profilePicUrl": "https://api.multiavatar.com/2.png",
        "referralCode": "deepmala",
        "stripePk": "pk_test_51JA6dtD3hA6KTc2Ntp9V9pJIlUNCZAZr0gWnnJMpwb64m8GqY9zmB3LdBgdEeomqUZMuFgOkc9oZnnNPYaXEj8e7002LmCeZr0",
        "netWalletCredit": 916257.0,
        "netPendingCredit": 20060.0,
        "minWithdrawalAmount": 2500,
        "defaultPayoutFundId": "",
        "onlineStatus": "online",
        "lastActiveTime": "2022-02-25T11:22:09.277252Z",
        "roles": [
            {
                "role": "admin",
                "status": "active"
            },
            {
                "role": "super_admin",
                "status": "active"
            }
        ],
        "myTutors": [
            {
                "tutorId": 5
            },
            {
                "tutorId": 2
            },
            {
                "tutorId": 4
            }
        ],
        "myStudents": [
            {
                "studentId": 5
            },
            {
                "studentId": 2
            },
            {
                "studentId": 9
            }
        ],
        "myCourses": [
            {
                "courseId": 6
            },
            {
                "courseId": 9
            },
            {
                "courseId": 2
            }
        ],
        "favTutors": [],
        "favStudents": [],
        "favCourses": [],
        "student": {
            "studentId": 1,
            "myTutorCount": 11,
            "bookingCount": 11,
            "noOfSessionTaken": 3,
            "noOfSessionCancelled": 4,
            "learningHours": 0,
            "learningScores": 0,
            "avgRating": 0.0,
            "ratingCount": 0,
            "isVerified": "not_verified",
            "status": "active"
        },
        "tutor": {
            "tutorId": 1,
            "myStudentCount": 14,
            "bookingCount": 14,
            "noOfSessionTaken": 0,
            "noOfSessionCancelled": 49,
            "taughtHours": 0,
            "teachingScores": 0,
            "avgRating": 4.3,
            "ratingCount": 2,
            "isVerified": "not_verified",
            "status": "active"
        }
    },
]