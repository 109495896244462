export const url = {
    USER_ITEM_LIST: "/chargebees/user-items",
    USER_ITEM_DETAIL: "chargebees/user-items/{userItemId}",
    ITEM_PRICE_LIST: "/chargebees/item-prices",
    ITEM_PRICE_DETAIL: "/chargebees/item-prices/{itemPriceId}"
}

export const chargeBeeRedux = {
    sliceName: "chargeBee",
    userItemList: {
        requestName: "getUserItemListByUserId"
    },
    userItemDetail: {
        requestName: "getUserItemDetailByUserItemId"
    },
    itemPriceList: {
        requestName: "getItemPriceList"
    },
    itemPriceDetail: {
        requestName: "getItemPriceDetailByItemPriceId"
    }
}

export const status = {
    ACTIVE: {
        label: "Active",
        value: "active",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    ARCHIVED: {
        label: "Archived",
        value: "archived",
        lightColor: "text-100",
        darkColor: "text-800"
    },
    DELETED: {
        label: "Delete",
        value: "deleted",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const productFamily = {
    ONE_ON_ONE: {
        label: "One On One",
        value: "one_on_one"
    },
    GROUP: {
        label: "Group",
        value: "group"
    },
    EDULYTE: {
        label: "Edulyte",
        value: "edulyte"
    }
}

export const subscriptionType = {
    PLAN: {
        label: "Plan",
        value: "plan"
    },
    ADD_ON: {
        label: "Add On",
        value: "add_on"
    },
    CHARGE: {
        label: "Charge",
        value: "charge"
    }
}

export const periodUnit = {
    // DAILY: {
    //     label: "Daily",
    //     value: "day"
    // },
    WEEK: {
        label: "Weekly",
        value: "week"
    },
    MONTH: {
        label: "Monthly",
        value: "month"
    },
    YEAR: {
        label: "Yearly",
        value: "year"
    }
}

export const priceMode = {
    FLAT_FEE: {
        label: "Flat Fee",
        value: "flat_fee"
    },
    PER_UNIT: {
        label: "Per Unit",
        value: "per_unit"
    },
    TIERED: {
        label: "Tiered",
        value: "tiered"
    },
    VOLUME: {
        label: "Volume",
        value: "volume"
    },
    STAIRSTEP: {
        label: "StairStep",
        value: "stair_step"
    }
}

export const billingCycles = {
    FOREVER: {
        label: "Forever",
        value: "forever"
    },
    FIXED: {
        label: "Fixed",
        value: "fixed"
    }
}