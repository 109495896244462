import MUITabs from "components/MUIComponents/MUITabs";

const Tabs = (props) => {

  return (
    <div className={"flex items-center justify-center sticky top-[4rem]"}>
      <div className={"max-w-full px-5 md:max-w-3xl md:px-0 lg:max-w-5xl w-full bg-white"}>
        <MUITabs tabs={props?.tabsList?.tabs} containerIDs={props.containerIDs} />
      </div>
    </div>
  );
};

export default Tabs;
