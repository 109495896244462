import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import FullPageLoader from "components/loader/FullPageLoader";

import QuestionsAnswersContainer from "./components/QuestionsAnswersContainer";

import { getUserOnboardingQuestionDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserOnboardingQuestionDetail } from "redux/onboarding/onboarding.slice";
import { relevantQuestionStatus } from "redux/onboarding/onboarding.const";

const ViewRelevantQuestions = () => {
    const { userOnboardingQuestionDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getUserOnboardingQuestionDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearUserOnboardingQuestionDetail())
        }
    }, [onboardingId])

    if (userOnboardingQuestionDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={userOnboardingQuestionDetail?.isLoading} />
        )
    }

    return (
        <div className={""}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-3 py-3",
                `bg-${relevantQuestionStatus[userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Relevant Question Answers"}
            </div>
            {userOnboardingQuestionDetail?.userOnboardingQuestionDetail &&
                <div className={"w-full space-y-8 px-3 py-5"}>
                    <div className={"w-full flex items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-base"}>
                            {"Status:"}
                        </span>
                        <span
                            className={cn(
                                `font-bodyPri font-normal text-base text-${relevantQuestionStatus[userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status?.toUpperCase()]?.darkColor}`
                            )}>
                            {relevantQuestionStatus[userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.status?.toUpperCase()]?.label}
                        </span>
                    </div>
                    {userOnboardingQuestionDetail?.userOnboardingQuestionDetail?.questions?.map((questionDetail, index) => (
                        <QuestionsAnswersContainer questionDetail={questionDetail} key={index} />
                    ))}
                </div>
            }
            {userOnboardingQuestionDetail?.errorMsg &&
                <div className={"w-full h-full flex justify-center items-center font-bodyPri font-normal text-center text-red-500 text-sm"}>
                    {userOnboardingQuestionDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default ViewRelevantQuestions