import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FaEye } from 'react-icons/fa';

import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";
import SideOverPanel from "components/common-components/SideOverPanel";

import { walletTxnHeaderConst, subRowHeader, sortWalletTxnLowToHigh, sortWalletTxnHighToLow, txnCurrencyCodeConst } from 'pages/auth/walletTxn/data';
import WalletTxnSideOverContent from "pages/auth/walletTxn/components/WalletTxnSideOverContent";

import { getWalletTransactionList } from 'redux/walletTxn/walletTxn.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setFilterProps, setClearWalletTxnList } from "redux/walletTxn/walletTxn.slice";
import { amountImpact, txnType, walletTxnActions } from "redux/walletTxn/walletTxn.const";

import { pagesInfo } from "utils/pagesInfo";
import { dayjs, timeZone } from 'utils/dateTime.utils';

export const UserContainer = ({ walletTxn }) => {

  return walletTxn?.user ? (
    <div className={"flex items-center justify-start gap-0.5"}>
      <Link to={`${pagesInfo?.VIEW_USER?.pagePath}/${walletTxn?.user?.userId}`}
        className={"text-primary-main hover:underline"}
      >
        {walletTxn?.user?.userId + ". "}
      </Link>
      <div className={""}>
        {walletTxn?.user?.firstName + " " + walletTxn?.user?.lastName?.charAt(0)}
      </div>
    </div>
  ) : "-"
}
const DisplayWalletTxnTable = () => {
  const { walletTxnList, filterProps } = useAppState((state) => state.walletTxn)

  const dispatcher = useAppDispatcher()

  const [sideOverPanel, setSideOverPanel] = useState(false)
  const [activeSortHeader, setActiveSortHeader] = useState({
    activeSortKey: null,
    sortType: null
  })

  useEffect(() => {
    dispatcher(getWalletTransactionList({ page: filterProps?.page, records: filterProps?.records }))

    return () => {
      dispatcher(setClearWalletTxnList())
    }
  }, [filterProps])

  const sortHeader = (activeSort, upDownArrow) => {
    setActiveSortHeader({
      activeSortKey: activeSort,
      sortType: upDownArrow
    })
  }

  const sortWalletTxnList = (walletTxn1, walletTxn2) => {
    if (activeSortHeader?.sortType?.upArrow) {
      return sortWalletTxnLowToHigh(activeSortHeader, walletTxn1, walletTxn2)
    }
    if (activeSortHeader?.sortType?.downArrow) {
      return sortWalletTxnHighToLow(activeSortHeader, walletTxn1, walletTxn2)
    }
  }

  const rows = walletTxnList?.filteredWalletTxnList?.results?.slice(0)?.sort(sortWalletTxnList)?.map((walletTxn, index) => [
    walletTxn?.walletTxnId || "-",
    walletTxn?.virtualTxnId || "-",
    <UserContainer walletTxn={walletTxn} />,
    `${txnCurrencyCodeConst[walletTxn?.txnType?.toUpperCase()]?.label} ${(walletTxn?.amount / 100).toFixed(2)}` || "-",
    amountImpact[walletTxn?.amountImpact?.toUpperCase()]?.label || "-",
    txnType[walletTxn?.txnType?.toUpperCase()]?.label || "-",
    walletTxnActions[walletTxn?.action?.toUpperCase()].label || "-",
    dayjs(walletTxn?.createdAt)?.tz(timeZone)?.format('DD MMM, YYYY, hh:mm a') || "-",
    <button
      key={index}
      className="px-2 py-1 bg-primary-main text-text-50 rounded-md"
    // onClick={() => {
    //   dispatcher(setWalletTxnDetailPayload({ walletTxnId: walletTxn?.walletTxnId }));
    //   setSideOverPanel(true)
    // }}
    >
      <FaEye className="text-xl hover:scale-110" />
    </button>
  ]);

  return (
    <div>
      <Table
        headers={Object.values(walletTxnHeaderConst)}
        subRowHeader={subRowHeader}
        rows={rows}
        rowHeaderColor={"bg-background-medium"}
        alternateRowColor={"bg-primary-light"}
        sortHeader={sortHeader}
        isLoading={walletTxnList?.isLoading}
        message={((walletTxnList?.errorMsg || walletTxnList?.filteredWalletTxnList?.records === 0)) && (walletTxnList?.errorMsg || "No transactions Found!")}
      />
      {(walletTxnList?.filteredWalletTxnList && walletTxnList?.filteredWalletTxnList?.totalPages > 1) &&
        <div className={"w-full flex justify-center items-center py-3"}>
          <Pagination
            page={walletTxnList?.filteredWalletTxnList?.page}
            totalPages={walletTxnList?.filteredWalletTxnList?.totalPages}
            onChangePage={(page) => dispatcher(setFilterProps({
              filterProps: { ...filterProps, page: page, records: filterProps?.records }
            }))}
          />
        </div>
      }
      <SideOverPanel
        child={<WalletTxnSideOverContent />}
        isOpen={sideOverPanel}
        onClose={(isShow) => setSideOverPanel(isShow)}
      />
    </div>
  )
}

export default DisplayWalletTxnTable;