import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import Table from 'components/table/Table';
import Pagination from 'components/pagination/Pagination';

import { lmsQuestionHeaderConst, sortQuestionHighToLow, sortQuestionLowToHigh, subRowHeader } from 'pages/auth/edulyteLms/lmsQuestion/lmsQuestion.data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { deleteLmsQuestionDetail, getLmsQuestionList } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { resetFilterProps, resetLmsQuestionList } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.slice';
import { lmsQuestionTypeEnum } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.const';

import { edulyteAppPagesInfo, pagesInfo } from 'utils/pagesInfo';
import { timeZone, dayjs } from 'utils/dateTime.utils';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

let CURRENT_QUESTION_ID = null;

const OwnerTutorContainer = ({ question }) => {
    return question?.question_setting?.default_owner?.tutor ? (
        <div className={"flex items-center justify-start gap-0.5"}>
            <Link to={`${pagesInfo?.VIEW_TUTOR?.pagePath}/${question?.question_setting?.default_owner?.tutor?.tutorId}`}
                className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
            >
                {question?.question_setting?.default_owner?.tutor?.tutorId + ". "}
            </Link>
            <div className={""}>
                {`${question?.question_setting?.default_owner?.tutor?.user?.first_name} ${question?.question_setting?.default_owner?.tutor?.user?.last_name?.charAt(0)}.` || "-"}
            </div>
        </div>
    ) : "-"
}

const DisplayLmsQuestion = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { lmsQuestionList, destroyLmsQuestionDetail, filterProps } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {

        return () => {
            dispatcher(resetLmsQuestionList())
            dispatcher(resetFilterProps())
            dispatcher(resetAdminLoginAs())
        }
    }, [])

    useEffect(() => {
        const query = {
            page: filterProps?.page || 1,
            records: Number(filterProps?.records) || 25
        }
        dispatcher(getLmsQuestionList(query))

        return () => {
            dispatcher(resetLmsQuestionList())
        }
    }, [filterProps?.records])


    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo?.EDIT_LMS_QUESTION?.pagePath}/${CURRENT_QUESTION_ID}/edit`, "_self")
        }
    }, [adminLoginAs?.data])

    const onHandleOpenQuestion = (questionId, userId) => {
        CURRENT_QUESTION_ID = questionId
        dispatcher(createAdminLoginAsDetail({ user_id: userId, source_url: window.location.href }))
    }

    const onHandleDeleteQuestion = (questionId) => {
        if (destroyLmsQuestionDetail?.isLoading) return;

        dispatcher(deleteLmsQuestionDetail(questionId))
    }

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortQuestionList = (lesson1, lesson2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortQuestionLowToHigh(activeSortHeader, lesson1, lesson2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortQuestionHighToLow(activeSortHeader, lesson1, lesson2)
        }
    }

    const tableRowsConst = lmsQuestionList?.data?.results?.slice(0)?.sort(sortQuestionList)?.map((question) => ([
        question?.id,
        question?.title
            ? (question?.title?.length > 30)
                ? question?.title?.slice(0, 30) + "...."
                : question?.title
            : "-",
        lmsQuestionTypeEnum[question?.type?.toUpperCase()]?.label || "-",
        <OwnerTutorContainer question={question} />,
        dayjs(question?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",

        <div className={"flex items-center w-full"}>
            <button
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
                onClick={() => onHandleOpenQuestion(question?.id, question?.question_setting?.default_owner?.tutor?.user?.userId)}
                title={"Edit Question"}
            >
                <FiEdit size={20} className={"text-xl hover:scale-110"} />
            </button>
            <button
                className={"px-2 py-1 bg-red-500 text-white rounded-r-md"}
                onClick={() => onHandleDeleteQuestion(question?.id)}
                title={"Delete Question"}
            >
                <AiOutlineDelete size={20} className={"text-xl hover:scale-110"} />
            </button>
        </div>,
    ]))

    return (
        <div className={'w-full flex flex-col gap-5'}>
            <Table
                headers={Object.values(lmsQuestionHeaderConst)}
                subRowHeader={subRowHeader}
                rows={tableRowsConst}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={lmsQuestionList?.isLoading}
                message={(lmsQuestionList?.message || (lmsQuestionList?.data?.records === 0)) && (lmsQuestionList?.message || "No questions found")}
            />
            {(!lmsQuestionList?.isLoading && (lmsQuestionList?.data?.totalPages > 1)) &&
                <div className={'w-full flex items-center justify-center'}>
                    <Pagination
                        page={lmsQuestionList?.data?.page}
                        totalPages={lmsQuestionList?.data?.totalPages}
                        onChangePage={(page) => dispatcher(getLmsQuestionList({ page: page, records: filterProps?.records }))}

                    />
                </div>
            }
        </div>
    )
}

export default DisplayLmsQuestion;