import { cn } from "utils/cn.utils";

import { GrAddCircle } from "react-icons/gr";
import { AiOutlineEnter } from "react-icons/ai";

import ScheduleTimeRange from 'pages/auth/viewCourseDetail/components/confirmSchedulePage/commonComponents/ScheduleTimeRange';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const ScheduleCard = ({ localDay, weeklyAvailability }) => {

    const sortTimeSlot = (timeSlot1, timeSlot2) => {
        return timeSlot2?.startTime < timeSlot1?.startTime ? 1 : -1
    }

    return (
        <div className={"grid grid-cols-4 w-full gap-5"}>

            <div className={cn(
                "pt-2.5",
                weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0 && "col-start-1 col-span-full sm:col-start-1 sm:col-span-1",
                (!weeklyAvailability || (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length < 1)) && "col-start-1 col-span-1"
            )}>
                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                    {localDay}
                </span>
            </div>

            {weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0 &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 space-y-3"}>
                    {weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlot)?.map((timeSlot, index) => (
                        <ScheduleTimeRange
                            key={index}
                            timeSlot={timeSlot}
                        />
                    ))}
                </div>
            }

            {(!weeklyAvailability || (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length < 1)) &&
                <div className={"col-start-2 col-span-1 mt-2.5 flex items-center justify-center"}>
                    <div className={"w-full font-bodyPri font-bold text-base text-text-900 tracking-wide text-center"}>
                        {"-"}
                    </div>
                </div>
            }

            <div className={"col-start-4 col-span-full flex items-start justify-start mt-2.5"}>
                <div className={"flex flex-row items-center justify-start space-x-2 sm:space-x-5"}>
                    <span className={cn("relative has-tooltip inline-flex items-center justify-center cursor-pointer")}                    >
                        <GrAddCircle className={"font-bodyPri font-medium text-xl text-text-400 hover:text-text-800"} />
                        <span
                            className={cn(
                                "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip absolute -top-5",
                                "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap"
                            )}
                        >
                            {"Add a session"}
                        </span>
                    </span>

                    {weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0 &&
                        <span className={"relative has-tooltip inline-flex items-center justify-center cursor-pointer"}>
                            <AiOutlineEnter className={"font-bodyPri font-medium text-xl text-text-700 hover:text-text-900"} />
                            <span
                                className={cn(
                                    "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip absolute -top-5",
                                    "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap"
                                )}
                            >
                                {"Apply to All"}
                            </span>
                        </span>
                    }
                </div>
            </div>
            <div className={"col-start-1 col-span-full h-0.5 w-full border border-divider-medium"}></div>
        </div>
    )
}

export default ScheduleCard