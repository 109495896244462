import { dayjs } from "utils/dateTime.utils";
import {
  SearchLectureId,
  SearchLectureTitle,
  SearchTutor,
  FilterLectureStatus,
  FilterLectureVisibility,
  SearchDate,
  ButtonAction,
} from "./components/LmsVideoLectureFilterStatistics";

export const pageHeading = {
  heading: "Video Lectures",
  subHeading: "Find the list of video lectures!",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const lmsLectureHeaderConst = {
  LECTURE_ID: {
    label: "Lecture ID",
    value: "lecture_id"
  },
  LECTURE_TITLE: {
    label: "Lecture Title",
    value: "lecture_title"
  },
  OWNER_TUTOR: {
    label: "Owner Tutor",
    value: "owner_tutor"
  },
  STATUS: {
    label: "Status",
    value: "status"
  },
  VISIBILITY: {
    label: "Visibility",
    value: "visibility"
  },
  DATE: {
    label: "Date",
    value: "date"
  },
  ACTIVITY: {
    label: "Activity",
    value: "activity"
  },
}

export const subRowHeader = [
  <SearchLectureId />,
  <SearchLectureTitle />,
  <SearchTutor />,
  <FilterLectureStatus />,
  <FilterLectureVisibility />,
  <SearchDate />,
  <ButtonAction />,
]

export const sortLectureLowToHigh = (activeSortHeader, lecture1, lecture2) => {
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.LECTURE_ID?.value) {
    return (Number(lecture1?.lecture_id) - Number(lecture2?.lecture_id))
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.LECTURE_TITLE?.value) {
    return lecture1?.title?.toLowerCase()?.localeCompare(lecture2?.title?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.OWNER_TUTOR?.value) {
    return lecture1?.lecture_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(lecture2?.lecture_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.STATUS?.value) {
    return lecture1?.lecture_setting?.status?.toLowerCase()?.localeCompare(lecture2?.lecture_setting?.status?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.VISIBILITY?.value) {
    return lecture1?.lecture_setting?.visibility?.toLowerCase()?.localeCompare(lecture2?.lecture_setting?.visibility?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.DATE?.value) {
    return dayjs(lecture1?.createdAt).isSameOrBefore(lecture2?.createdAt) ? 1 : -1
  }
}

export const sortLectureHighToLow = (activeSortHeader, lecture1, lecture2) => {
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.LECTURE_ID?.value) {
    return (Number(lecture2?.lecture_id) - Number(lecture1?.lecture_id))
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.LECTURE_TITLE?.value) {
    return lecture2?.title?.toLowerCase()?.localeCompare(lecture1?.title?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.OWNER_TUTOR?.value) {
    return lecture2?.lecture_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(lecture1?.lecture_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.STATUS?.value) {
    return lecture2?.lecture_setting?.status?.toLowerCase()?.localeCompare(lecture1?.lecture_setting?.status?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.VISIBILITY?.value) {
    return lecture2?.lecture_setting?.visibility?.toLowerCase()?.localeCompare(lecture1?.lecture_setting?.visibility?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsLectureHeaderConst?.DATE?.value) {
    return dayjs(lecture2?.createdAt).isSameOrBefore(lecture1?.createdAt) ? 1 : -1
  }
}