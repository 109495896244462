import { createSlice } from "@reduxjs/toolkit";
import { OTHER_INITIAL_STATE } from "redux/other/other.initialState";

export const other = createSlice({
    name: "other",
    initialState: OTHER_INITIAL_STATE,
    reducers: {
         // reducers for thumbnail list
        setUnsplashThumbnailListLoading: (state, {payload}) => {
            state.thumbnailList.isLoading = payload
        },
        setUnsplashThumbnailList: (state, {payload}) => {
            state.thumbnailList.thumbnailList = payload
            state.thumbnailList.errorMsg = OTHER_INITIAL_STATE.thumbnailList.thumbnailList
        },
        setThumbnailListErrorMsg: (state, {payload}) => {
            state.thumbnailList.errorMsg = payload
            state.thumbnailList.thumbnailList = OTHER_INITIAL_STATE.thumbnailList.thumbnailList
        },
        setClearThumbnailList: (state) => {
            state.thumbnailList = OTHER_INITIAL_STATE.thumbnailList
        },

        setThumbnailDetailPayloadLoading: (state, {payload}) => {
            state.thumbnailDetail.isLoading = payload
        },
        setThumbnailDetailPayload: (state, {payload}) => {
            state.thumbnailDetail.thumbnailDetailPayload = payload
        },
        setClearThumbnailDetailPayload: (state) => {
            state.thumbnailDetail = OTHER_INITIAL_STATE.thumbnailDetail
        }
       
    }
})

export const {
    setUnsplashThumbnailListLoading,
    setUnsplashThumbnailList,
    setUnsplashThumbnailListErrorMsg,
    setClearThumbnailList,

    setThumbnailDetailPayloadLoading,
    setThumbnailDetailPayload,
    setClearThumbnailDetailPayload
} = other.actions
export default other.reducer;