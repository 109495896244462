import { toast } from "react-toastify";
import { useState } from 'react';

import { BsCheck2Square } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { MdOutlineContentCopy } from 'react-icons/md';

import { useAppState } from 'hooks/useStore';
import { courseStatus } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_ADMIN_DOMAIN_URL, EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const PublishedCourseLink = () => {
    const { locals } = useAppState((state) => state.local)
    const { courseDetail } = useAppState((state) => state.course)

    const [isLinkCopied, setIsLinkCopied] = useState(false)

    const PUBLISHED_COURSE_URL = `${EDULYTE_APP_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${courseDetail?.courseDetail?.slug}`
    const PRIVATE_COURSE_URL = `${EDULYTE_ADMIN_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${courseDetail?.courseDetail?.slug}/preview?previewAs=${locals.userRole}`

    const onHandleCopyLink = () => {
        if (courseDetail?.courseDetail?.status === courseStatus?.PUBLISHED?.value) {
            navigator.clipboard.writeText(PUBLISHED_COURSE_URL);
        }
        if ([courseStatus?.DRAFT?.value, courseStatus?.UNDER_REVIEW?.value]?.includes(courseDetail?.courseDetail?.status)) {
            navigator.clipboard.writeText(`${PRIVATE_COURSE_URL}`);
        }
        setIsLinkCopied(true)
        toast.success("Link Copied")
        setTimeout(() => {
            setIsLinkCopied(false)
        }, 2000)
    }

    const onHandleOpenExternalLink = () => {
        if (courseDetail?.courseDetail?.status === courseStatus?.PUBLISHED?.value) {
            window.open(PUBLISHED_COURSE_URL, "_blank")
            return;
        }
        if ([courseStatus?.DRAFT?.value, courseStatus?.UNDER_REVIEW?.value]?.includes(courseDetail?.courseDetail?.status)) {
            window.open(PRIVATE_COURSE_URL, "_blank")
        }
    }

    return (
        <div className={"w-full flex items-center justify-between gap-3 border border-primary-dark px-2 py-1 rounded-lg whitespace-normal sm:whitespace-nowrap"}>
            <a
                href={[courseStatus?.PUBLISHED?.value]?.includes(courseDetail?.courseDetail?.status)
                    ? PUBLISHED_COURSE_URL
                    : PRIVATE_COURSE_URL
                }
                target={"_blank"}
                className={"font-bodyPri font-normal text-primary-dark hover:underline hover:text-primary-dark text-base tracking-wide cursor-pointer"}>
                {`${courseDetail?.courseDetail?.slug}`}
            </a>

            <div className={"flex items-center justify-center gap-3"}>
                <span className={""} onClick={onHandleOpenExternalLink}>
                    <FiExternalLink className={"text-lg text-primary-dark cursor-pointer"} />
                </span>
                <span className={""} onClick={onHandleCopyLink}>
                    {isLinkCopied
                        ? <BsCheck2Square className={"text-lg text-primary-dark"} />
                        : <MdOutlineContentCopy className={"text-lg text-text-900 cursor-pointer"} />
                    }
                </span>
            </div>
        </div>
    )
}

export default PublishedCourseLink