const setters = new WeakMap();
const values = new WeakMap();
export class AttributeTemplatePart {
    constructor(setter, expression) {
        this.expression = expression;
        setters.set(this, setter);
        setter.updateParent('');
    }
    get attributeName() {
        return setters.get(this).attr.name;
    }
    get attributeNamespace() {
        return setters.get(this).attr.namespaceURI;
    }
    get value() {
        return values.get(this);
    }
    set value(value) {
        values.set(this, value || '');
        setters.get(this).updateParent(value);
    }
    get element() {
        return setters.get(this).element;
    }
    get booleanValue() {
        return setters.get(this).booleanValue;
    }
    set booleanValue(value) {
        setters.get(this).booleanValue = value;
    }
}
export class AttributeValueSetter {
    constructor(element, attr) {
        this.element = element;
        this.attr = attr;
        this.partList = [];
    }
    get booleanValue() {
        return this.element.hasAttributeNS(this.attr.namespaceURI, this.attr.name);
    }
    set booleanValue(value) {
        if (this.partList.length !== 1) {
            throw new DOMException('Operation not supported', 'NotSupportedError');
        }
        ;
        this.partList[0].value = value ? '' : null;
    }
    append(part) {
        this.partList.push(part);
    }
    updateParent(partValue) {
        if (this.partList.length === 1 && partValue === null) {
            this.element.removeAttributeNS(this.attr.namespaceURI, this.attr.name);
        }
        else {
            const str = this.partList.map(s => (typeof s === 'string' ? s : s.value)).join('');
            this.element.setAttributeNS(this.attr.namespaceURI, this.attr.name, str);
        }
    }
}
