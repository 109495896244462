import { useState, useEffect, memo, useMemo, useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import { IoIosCheckmark } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import { BsInfoCircle } from "react-icons/bs";
import { MdEdit } from "react-icons/md";

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from "components/loader/ComponentLoader";
import ToolTipView from "components/toolTip/index";
import QuillEditor from "components/textEditor/QuillEditor";

import { appointmentDetailTooltips, similarAppointmentDetail, validateAppointmentDetailPayload } from 'pages/auth/editTutor/data';
import AppointmentDropdown from "./AppointmentDropdown";
import AppointmentPriceDetail from "./AppointmentPriceDetail";

import { getAvailabilityList } from "redux/availability/availability.request";
import { updateAppointmentDetail } from 'redux/appointment/appointment.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModifyAppointmentDetailPayload } from "redux/appointment/appointment.slice";
import { resetAvailabilityList } from "redux/availability/availability.slice";
import { appointmentPriceModelEnum } from "redux/appointment/appointment.const";
import { availabilityIsRecurringEnum } from "redux/availability/availability.const";

import { pagesInfo } from "utils/pagesInfo";
import { cn } from "utils/cn.utils";
import { debounce } from "utils/generators.utils";

const Editor = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    formats: [
        'bold', 'italic', 'underline', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
    ]
}

const UserAppointmentForm = memo(({ isEditModeEnabled, setIsEditModeEnabled }) => {
    const { tutorDetail } = useAppState((state) => state.tutor)
    const { appointmentDetail, modifyAppointmentDetail } = useAppState((state) => state.appointment)
    const { availabilityList } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        if (tutorDetail?.data?.user?.id) {
            const requestPayload = {
                userId: tutorDetail?.data?.user?.id,
                page: 1,
                records: 5
            }
            dispatcher(getAvailabilityList(requestPayload))
        }

        return () => {
            dispatcher(resetAvailabilityList())
        }
    }, [tutorDetail?.data?.user?.id])

    const isAppointmentDetailSimilar = useMemo(() => {
        const isDetailSimilarOrValid = similarAppointmentDetail(appointmentDetail?.data?.result, modifyAppointmentDetail?.payload)

        return isDetailSimilarOrValid;
    }, [appointmentDetail?.data?.result, modifyAppointmentDetail?.payload])

    const isFlatFeePriceModel = useMemo(() => [appointmentPriceModelEnum?.FLAT_FEE?.value]?.includes(modifyAppointmentDetail?.payload?.price_model), [modifyAppointmentDetail?.payload?.price_model])

    const onHandleNavigateToAvailability = useCallback(() => {
        navigate(`${pagesInfo.EDIT_USER.pagePath}/${tutorDetail?.data?.user?.id}/edit/user-availability/create`)
    }, [tutorDetail?.data?.user?.id])

    const onHandleSearch = useCallback((searchText) => {
        if (searchText) {
            dispatcher(getAvailabilityList(tutorDetail?.data?.user?.id, { name: searchText }))
        } else {
            dispatcher(getAvailabilityList(tutorDetail?.data?.user?.id))
        }
    }, [tutorDetail?.data?.user?.id])

    const onHandleSearchAvailability = useCallback((searchText) => {
        setSearchValue(searchText)
        const delayFunction = debounce.debounce(onHandleSearch)
        delayFunction(searchText)
    }, [searchValue])

    const onHandleChangeText = (key, value) => {
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            [key]: value
        }))
    }

    const onHandleUpdateAvailability = useCallback((event, selectedOption) => {
        event.stopPropagation()
        navigate(`${pagesInfo.EDIT_USER.pagePath}/${tutorDetail?.data?.user?.id}/edit/user-availability/${selectedOption?.value}/edit`)
    }, [availabilityList?.data, tutorDetail?.data?.user?.id])

    const onHandleSave = async () => {
        if (modifyAppointmentDetail?.isLoading || isAppointmentDetailSimilar) return;

        const { requestPayload, errorMsg } = await validateAppointmentDetailPayload(modifyAppointmentDetail?.payload, isFlatFeePriceModel)
        if (errorMsg) {
            toast.error(errorMsg)
            return;
        }

        if (requestPayload) {
            const payload = {
                appointmentId: appointmentDetail?.data?.result?.id,
                body: requestPayload
            }
            dispatcher(updateAppointmentDetail(payload))
        }
    }

    return (
        <AnimatePresence initial={isEditModeEnabled}>
            <motion.div
                className={"w-full space-y-5"}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
            >
                <div className={'w-full space-y-2'}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={'font-bodyPri font-medium text-text-900 text-base'}>
                            {"Title *"}
                        </span>
                        <ToolTipView content={appointmentDetailTooltips.title}>
                            <button className={""}>
                                <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                            </button>
                        </ToolTipView>
                    </div>
                    <input
                        type={"text"}
                        placeholder={"e.g. Fun Mathematics"}
                        minLength={1}
                        maxLength={100}
                        value={modifyAppointmentDetail?.payload?.title}
                        className={cn(
                            "w-full h-10 px-3 text-text-900 bg-white border rounded border-divider-lightDark",
                            "hover:border-primary-main focus:outline-none focus:ring-2 focus:ring-primary-main focus:border-none",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(event) => dispatcher(setModifyAppointmentDetailPayload({
                            ...modifyAppointmentDetail?.payload,
                            title: event?.target?.value
                        }))}
                        required={true}
                    />
                </div>

                <div className={'w-full space-y-2'}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={'font-bodyPri font-medium text-text-900 text-base'}>
                            {"Sub Header"}
                        </span>
                        <ToolTipView content={appointmentDetailTooltips.subtitle}>
                            <button className={""}>
                                <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                            </button>
                        </ToolTipView>
                    </div>
                    <input
                        type={"text"}
                        placeholder={"e.g. Learn fun math"}
                        value={modifyAppointmentDetail?.payload?.subtitle}
                        className={cn(
                            "w-full py-2 px-3 text-text-900 bg-white border rounded border-divider-lightDark",
                            "hover:border-primary-main focus:outline-none focus:ring-2 focus:ring-primary-main focus:border-none",
                            "placeholder:text-text-500 placeholder:text-sm"
                        )}
                        onChange={(event) => dispatcher(setModifyAppointmentDetailPayload({
                            ...modifyAppointmentDetail?.payload,
                            subtitle: event?.target?.value
                        }))}
                        required={true}
                    />
                </div>

                {/* schedule */}
                <div className={"w-full space-y-2"}>
                    <div className={"w-full flex items-center justify-between gap-3"}>
                        <div className={"flex items-center justify-start gap-2"}>
                            <span className={'font-bodyPri font-medium text-text-900 text-base'}>
                                {"Schedule *"}
                            </span>
                            <ToolTipView content={appointmentDetailTooltips.schedule}>
                                <button className={""}>
                                    <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                                </button>
                            </ToolTipView>
                        </div>
                        <motion.button
                            key={"add_new_schedule"}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.3 }}
                            className={cn(
                                "w-fit py-1 px-2 flex items-center justify-center gap-1.5 text-primary-dark ease-in-out duration-200 border border-primary-dark rounded",
                                "hover:bg-primary-dark hover:text-text-50 font-bodyPri font-normal cursor-pointer whitespace-nowrap group",
                            )}
                            disabled={modifyAppointmentDetail?.isLoading || availabilityList?.isLoading}
                            onClick={onHandleNavigateToAvailability}
                        >
                            <GoPlus className={"text-lg group-hover:text-text-50"} />
                            <span className={"text-sm line-clamp-1"}>
                                {"Add New Schedule"}
                            </span>
                        </motion.button>
                    </div>
                    <AppointmentDropdown
                        isLoading={availabilityList?.isLoading}
                        searchable={true}
                        labelItem={`appointment_schedule`}
                        showLabel={false}
                        isShowEmptyContent={(!availabilityList?.data?.result || (availabilityList?.data?.result?.length === 0)) && true}
                        options={availabilityList?.data?.result?.map((item) => ({
                            label: `${item?.name} (${availabilityIsRecurringEnum[item?.isRecurring?.toUpperCase()]?.label})`,
                            value: item?.id,
                            availability: item
                        }))}
                        value={<span className={"line-clamp-1"}>
                            {modifyAppointmentDetail?.payload?.availability ? `${modifyAppointmentDetail?.payload?.availability?.name} (${availabilityIsRecurringEnum[modifyAppointmentDetail?.payload?.availability?.isRecurring?.toUpperCase()]?.label})` : "Select Schedule"}
                        </span>}
                        searchValue={searchValue}
                        setSearchValue={onHandleSearchAvailability}
                        EmptyContentContainer={() => {
                            return (
                                <>
                                    {availabilityList?.isLoading &&
                                        <ComponentLoader isLoading={availabilityList?.isLoading} />
                                    }
                                    {(!availabilityList?.isLoading && availabilityList?.data && !availabilityList?.data?.result) &&
                                        <div className={"py-4 flex items-center justify-center gap-1.5"}>
                                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                                {"No Schedule Added!"}
                                            </span>
                                            <span
                                                className={"font-bodyPri font-normal text-text-800 text-sm cursor-pointer hover:underline hover:text-primary-dark"}
                                                onClick={onHandleNavigateToAvailability}
                                            >
                                                {"Add Schedule"}
                                            </span>
                                        </div>
                                    }
                                </>
                            )
                        }}
                        onHandleSelect={(option) => onHandleChangeText("availability", option?.availability)}
                        OptionChild={({ option }) => (
                            <div className={"bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide line-clamp-1"}>
                                    {option?.label}
                                </span>
                                <div className={"flex items-center justify-start gap-1.5"}>
                                    <button>
                                        {(option?.value === modifyAppointmentDetail?.payload?.availability?.id)
                                            && <IoIosCheckmark size={20} color="green" />
                                        }
                                    </button>
                                    <button
                                        className={""}
                                        onClick={(event) => onHandleUpdateAvailability(event, option)}
                                    >
                                        <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                                    </button>
                                </div>
                            </div>
                        )}
                        dropdownWrapperClassName={"w-full"}
                        dropDownContainerClassName={"w-full max-h-44 overflow-y-auto scrollbar-thin"}
                    />
                </div>

                <AppointmentPriceDetail appointmentDuration={modifyAppointmentDetail?.payload?.duration} />

                {/* Description */}
                <div className={"w-full space-y-2"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={'font-bodyPri font-medium text-text-900 text-base'}>
                            {"Description / Instructions"}
                        </span>
                        <ToolTipView content={appointmentDetailTooltips.description}>
                            <button className={""}>
                                <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                            </button>
                        </ToolTipView>
                    </div>
                    <QuillEditor
                        editorToolModules={Editor?.modules}
                        editorToolFormats={Editor?.formats}
                        placeholder={"Write a summary and any details your invitee should know about the appointment."}
                        className={"w-full focus:outline-none focus-within:border-primary-main border-1 bg-white"}
                        editorText={modifyAppointmentDetail?.payload?.description}
                        onHandleChangeText={(content) => onHandleChangeText("description", content)}
                    />
                </div>

                {modifyAppointmentDetail?.message &&
                    <div className={"col-start-1 col-span-full"}>
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {modifyAppointmentDetail?.message}
                        </span>
                    </div>
                }

                <div className={"col-start-1 col-span-full flex items-center justify-center gap-5 mt-5"}>
                    <div
                        onClick={() => setIsEditModeEnabled(false)}
                        className={cn(
                            "flex item-center justify-center px-3 py-1 rounded-md",
                            "font-bodyPri font-normal text-base",
                            "border border-divider-lightDark text-divider-lightDark hover:bg-divider-lightDark hover:text-text-50 cursor-pointer"
                        )}>
                        <span className={"whitespace-nowrap"}>
                            {"Cancel"}
                        </span>
                    </div>

                    <div
                        onClick={onHandleSave}
                        className={cn(
                            "flex item-center justify-center px-3 py-1 rounded-md",
                            "font-bodyPri font-normal text-base",
                            isAppointmentDetailSimilar && "border border-text-300 bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed",
                            (!isAppointmentDetailSimilar && !modifyAppointmentDetail?.isLoading) && "border border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50 cursor-pointer",
                            modifyAppointmentDetail?.isLoading && "bg-primary-dark"
                        )}>
                        {modifyAppointmentDetail?.isLoading && <ButtonLoader isLoading={modifyAppointmentDetail?.isLoading} />}
                        {!modifyAppointmentDetail?.isLoading &&
                            <span className={""}>
                                {"Save"}
                            </span>
                        }
                    </div>
                </div>

            </motion.div>
        </AnimatePresence>
    )
})

export default UserAppointmentForm;