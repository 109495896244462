import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";
import format from 'string-format';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { FaSpinner } from 'react-icons/fa';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMakePaymentErrorMsg, setMakePaymentConfirm, setMakePaymentLoading, setClearMakePayment } from 'redux/payment/payment.slice';
import { addMakePayment } from 'redux/payment/payment.request';
import { setModal, setClearModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { paymentMethodStatus, paymentType } from 'redux/payment/payment.const';
import { setAddCreditPayload } from 'redux/wallet/wallet.slice';

import UserInfoForm from 'components/modals/paymentMethodModal/UserInfoForm';

const paymentConfirmationUrl = "/payment/confirmation?clientSecret={clientSecret}"


const PaymentForm = () => {
    const { modal } = useAppState(s => s.local)
    const { addCreditToWallet } = useAppState(s => s.wallet)
    const { makePayment } = useAppState(s => s.payment)
    const makePaymentPayload = makePayment?.makePaymentPayload

    const [error, setError] = useState({ emailError: '', cardError: '' })
    const [isPayDisable, setIsPayDisable] = useState(true)

    const dispatcher = useAppDispatcher();
    const navigate = useNavigate()
    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        if (makePayment?.makePayment) {
            handlePayment()
        }
    }, [makePayment?.makePayment])

    useEffect(() => {
        setIsPayDisable(
            error.cardError.length !== 0 ||
            error.emailError.length !== 0 ||
            !makePaymentPayload?.email ||
            !makePaymentPayload?.firstName ||
            !makePaymentPayload?.lastName
        )
    }, [error, makePaymentPayload?.firstName, makePaymentPayload?.lastName, makePaymentPayload?.email])

    const handleCardOnChange = (event) => {
        if (event.error) {
            setError({
                ...error,
                cardError: event.error.message
            })
            setIsPayDisable(false)
        }
        else {
            setError({ ...error, cardError: '' });
        }
    }

    const handleNext = async (paymentIntent) => {
        if (paymentIntent.status === paymentMethodStatus.SUCCEEDED.value) {
            dispatcher(setAddCreditPayload({
                ...addCreditToWallet?.addCreditPayload,
                totalAmount: parseInt(makePayment?.makePaymentPayload?.totalAmountToPay),
                gatewayPaymentId: paymentIntent?.id,
                gatewayName: makePayment?.makePaymentPayload?.gatewayName
            }))
            dispatcher(setMakePaymentConfirm(paymentIntent))
            if ([paymentType.ADD_CREDIT_TO_WALLET.type].includes(makePayment?.makePaymentPayload?.paymentType)) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PAYMENT_METHOD_MODAL.stateKey]: false,
                    [modalConst.ADD_CREDIT_MODAL.stateKey]: true
                }))
            } else if ([paymentType.BUY_COURSE.type, paymentType.BUY_COURSE_AS_GUEST.type].includes(makePayment?.makePaymentPayload?.paymentType)) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PAYMENT_METHOD_MODAL.stateKey]: false,
                    [modalConst.MAKE_PAYMENT_MODAL.stateKey]: true
                }))
            }
        } else {
            const query = {
                clientSecret: paymentIntent.client_secret
            }
            dispatcher(setClearMakePayment())
            dispatcher(setClearModal())
            navigate(format(paymentConfirmationUrl.split('&')[0], query))
        }
    }

    const handlePayment = async () => {
        dispatcher(setMakePaymentLoading(true))
        try {
            const cardElement = elements.getElement(CardElement)
            const paymentIntentConfirmation = await stripe.confirmCardPayment(makePayment?.makePayment?.clientSecret, {
                payment_method: {
                    card: cardElement
                }
            })
            if (paymentIntentConfirmation.paymentIntent) {
                await handleNext(paymentIntentConfirmation.paymentIntent)
            } else if (paymentIntentConfirmation.error) {
                dispatcher(setMakePaymentErrorMsg(paymentIntentConfirmation.error.message))
            } else {
                dispatcher(setMakePaymentErrorMsg("Something went wrong!"))
            }
        } catch (error) {
            console.error(error)
            dispatcher(setMakePaymentErrorMsg("Something went wrong!"))
        }
        dispatcher(setMakePaymentLoading(false))
    }

    const handlePay = async () => {
        if (isPayDisable || makePayment?.isLoading) return;

        dispatcher(addMakePayment())
    }

    return (
        <div className={"block space-y-16"}>

            <div className={"block space-y-2"}>
                <span className={"text-start font-bodyPri font-medium text-base text-text-800"}>
                    {"Fill your details:"}
                </span>
                <div>
                    <UserInfoForm error={error} setError={setError} />
                </div>
                <div className={"w-full space-y-1"}>
                    <CardElement onChange={handleCardOnChange} />
                    <span className={"font-bodyPri font-normal text-xs text-red-500"}>
                        {error.cardError}
                    </span>
                </div>
                <div className={"w-full h-10"}>
                    {makePayment?.errorMsg &&
                        <div className={"flex items-center justify-start"}>
                            <span className={"font-bodyPri font-medium text-xs text-red-500 text-center"}>
                                {makePayment?.errorMsg}
                            </span>
                        </div>
                    }
                    {makePayment?.isLoading &&
                        <div className={"flex items-center justify-start gap-2 whitespace-nowrap"}>
                            <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                                {"Please wait while processing ..."}
                            </span>
                            <FaSpinner className="inline-flex text-primary-main animate-spin" />
                        </div>
                    }
                </div>
            </div>

            <div className={"flex justify-end"}>
                <span onClick={handlePay} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    (isPayDisable || makePayment?.isLoading) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                )}>
                    {"Pay"}
                </span>
            </div>
        </div>
    )
}

export default PaymentForm;

// <CardElement onChange={handleCardOnChange} options={CARD_ELEMENT_OPTIONS} />
// const CARD_ELEMENT_OPTIONS = {
//     style: {
//         base: {
//             color: "#32325d",
//             fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//             fontSmoothing: "antialiased",
//             fontSize: "16px",
//             "::placeholder": {
//                 color: "#aab7c4",
//             },
//         },
//         invalid: {
//             color: "#fa755a",
//             iconColor: "#fa755a",
//         },
//     },
// };