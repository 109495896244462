import { dayjs } from "utils/dateTime.utils";
import {
  ButtonAction,
  FilterCourseStatus,
  SearchCategory,
  SearchCourseId,
  SearchCourseSlug,
  SearchCourseTitle,
  SearchDate,
  SearchTutor
} from "./components/LmsCourseFilterStatistics";

export const pageHeading = {
  heading: "Courses",
  subHeading: "Find the list of courses!",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const lmsCourseHeaderConst = {
  COURSE_ID: {
    label: "Course ID",
    value: "course_id"
  },
  COURSE_TITLE: {
    label: "Course Title",
    value: "course_title"
  },
  COURSE_SLUG: {
    label: "Course Slug",
    value: "course_slug"
  },
  OWNER_TUTOR: {
    label: "Owner Tutor",
    value: "owner_tutor"
  },
  // SEGMENT: {
  //     label: "Segment",
  //     value: "segment"
  // },
  CATEGORY: {
    label: "Category",
    value: "category"
  },
  STATUS: {
    label: "Status",
    value: "status"
  },
  DATE: {
    label: "Date",
    value: "date"
  },
  ACTIVITY: {
    label: "Activity",
    value: "activity"
  },
}

export const subRowHeader = [
  <SearchCourseId />,
  <SearchCourseTitle />,
  <SearchCourseSlug />,
  <SearchTutor />,
  <SearchCategory />,
  <FilterCourseStatus />,
  <SearchDate />,
  <ButtonAction />,
]

export const sortCourseLowToHigh = (activeSortHeader, course1, course2) => {
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.COURSE_ID?.value) {
    return (Number(course1?.id) - Number(course2?.id))
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.COURSE_TITLE?.value) {
    return course1?.title?.toLowerCase()?.localeCompare(course2?.title?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.COURSE_SLUG?.value) {
    return course1?.active_slug?.toLowerCase()?.localeCompare(course2?.active_slug?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.OWNER_TUTOR?.value) {
    return course1?.course_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(course2?.course_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.CATEGORY?.value) {
    return course1?.category?.category?.toLowerCase()?.localeCompare(course2?.category?.category?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.STATUS?.value) {
    return course1?.course_setting?.status?.toLowerCase()?.localeCompare(course2?.course_setting?.status?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.DATE?.value) {
    return dayjs(course1?.createdAt).isSameOrBefore(course2?.createdAt) ? 1 : -1
  }
}

export const sortCourseHighToLow = (activeSortHeader, course1, course2) => {
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.COURSE_ID?.value) {
    return (Number(course2?.id) - Number(course1?.id))
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.COURSE_TITLE?.value) {
    return course2?.title?.toLowerCase()?.localeCompare(course1?.title?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.COURSE_SLUG?.value) {
    return dayjs(course1?.startDateTime).isSameOrBefore(course2?.startDateTime) ? 1 : -1
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.OWNER_TUTOR?.value) {
    return course2?.course_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(course1?.course_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.CATEGORY?.value) {
    return course2?.category?.category?.toLowerCase()?.localeCompare(course1?.category?.category?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.STATUS?.value) {
    return course2?.course_setting?.status?.toLowerCase()?.localeCompare(course1?.course_setting?.status?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsCourseHeaderConst?.DATE?.value) {
    return dayjs(course2?.createdAt).isSameOrBefore(course1?.createdAt) ? 1 : -1
  }
}