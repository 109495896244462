import { cn } from "utils/cn.utils";

import { buttonFeature } from 'pages/auth/wallet/data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { modalConst } from "redux/local/local.const";
import { setModal } from 'redux/local/local.slice';
import { resetAddCreditPayload } from "redux/wallet/wallet.slice";


const WalletFeatureButton = ({ featureItem }) => {
  const { modal } = useAppState(s => s.local)

  const dispatcher = useAppDispatcher();

  const handleButton = () => {
    if (featureItem.value === buttonFeature.WITHDRAW.value) {
      dispatcher(setModal({
        ...modal,
        [modalConst.WITHDRAW_MONEY_MODAL.stateKey]: true
      }))
    }
    if (featureItem.value === buttonFeature.ADD_CREDIT.value) {
      dispatcher(resetAddCreditPayload())
      dispatcher(setModal({
        ...modal,
        [modalConst.ADD_CREDIT_MODAL.stateKey]: true
      }))
    }
    if (featureItem.value === buttonFeature.REEDEM_GIFT_CARD.value) {
      dispatcher(setModal({
        ...modal,
        [modalConst.COMING_SOON_MODAL.stateKey]: true
      }))
    }
  }

  return (
    <div onClick={handleButton}
      className={cn(
        "flex flex-col items-center justify-center gap-2 cursor-pointer",
        "font-semibold text-normal md:text-xl text-primary-main group hover:text-primary-dark"
      )}>
      {featureItem.image}
      <span className="font-bodyPri font-medium text-md text-text-700 group-hover:text-primary-dark">
        {featureItem.label}
      </span>
    </div>
  );
}

export default WalletFeatureButton;