import { cn } from "utils/cn.utils";

import { useAppState } from 'hooks/useStore';
import { courseStatus } from 'redux/course/course.const';

const ClassStatusButton = ({ statusBtn, onHandleCourseStatus }) => {
    const { courseDetail } = useAppState((state) => state.course)

    let borderAsPerStatus = ''
    let bgAsPerStatus = ''
    let textColorAsPerStatus = ''
    let hoverBgColorAsPerStatus = ''
    let hoverBorderAsPerStatus = ''

    switch (statusBtn?.value) {
        case courseStatus.DRAFT.value:
            borderAsPerStatus = courseStatus.DRAFT.darkColor
            bgAsPerStatus = courseStatus.DRAFT.lightColor
            textColorAsPerStatus = courseStatus.DRAFT.darkColor
            hoverBgColorAsPerStatus = courseStatus.DRAFT.darkColor
            hoverBorderAsPerStatus = courseStatus.DRAFT.darkColor
            break;
        case courseStatus.UNDER_REVIEW.value:
            borderAsPerStatus = courseStatus.UNDER_REVIEW.darkColor
            bgAsPerStatus = courseStatus.UNDER_REVIEW.lightColor
            textColorAsPerStatus = courseStatus.UNDER_REVIEW.darkColor
            hoverBgColorAsPerStatus = courseStatus.UNDER_REVIEW.darkColor
            hoverBorderAsPerStatus = courseStatus.UNDER_REVIEW.darkColor
            break;
        case courseStatus.PUBLISHED.value:
            borderAsPerStatus = courseStatus.PUBLISHED.darkColor
            bgAsPerStatus = courseStatus.PUBLISHED.lightColor
            textColorAsPerStatus = courseStatus.PUBLISHED.darkColor
            hoverBgColorAsPerStatus = courseStatus.PUBLISHED.darkColor
            hoverBorderAsPerStatus = courseStatus.PUBLISHED.darkColor
            break;
        case courseStatus.INACTIVE.value:
            borderAsPerStatus = courseStatus.INACTIVE.darkColor
            bgAsPerStatus = courseStatus.INACTIVE.lightColor
            textColorAsPerStatus = courseStatus.INACTIVE.darkColor
            hoverBgColorAsPerStatus = courseStatus.INACTIVE.darkColor
            hoverBorderAsPerStatus = courseStatus.INACTIVE.darkColor
            break;
        default:
            break;
    }

    return (
        <div
            className={cn(
                "font-medium font-bodyPri tracking-wide leading-6 py-1.5 w-32 flex justify-center items-center rounded-lg",
                (statusBtn?.value !== courseDetail?.courseDetail?.status)
                    ? `border border-${borderAsPerStatus} bg-${bgAsPerStatus} text-${textColorAsPerStatus} hover:border hover:border-${hoverBorderAsPerStatus} hover:bg-${hoverBgColorAsPerStatus} hover:text-text-50 cursor-pointer`
                    : "bg-text-300 text-text-50 cursor-not-allowed",
            )}
            onClick={() => onHandleCourseStatus(statusBtn)}
        >
            {statusBtn?.label}
        </div>
    )
}

export default ClassStatusButton