import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/booking/booking.const"


class BookingService {

    getBookingList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.BOOKING_LIST),
            { params: query }
        )
        return response
    }

    getBookingDetail = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_BOOKING_DETAIL, params),
            { params: query }
        )

        return response;
    }

    createBookingDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.ADD_BOOKING_DETAIL,
            body
        )

        return response;
    }

    createBookingSessionDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.ADD_BOOKING_SESSION_DETAIL,
            body
        )

        return response;
    }
}
export default new BookingService();