import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/profile/data';

import Profile from "pages/auth/profile/Profile";

import PageHeader from "components/pageHeader/PageHeader";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';;


const ProfilePage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.PROFILE))
  }, [dispatcher, currentPageInfo])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
      <Profile />
    </div>
  );
};

export default ProfilePage;