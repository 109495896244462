import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "./order.const";

class OrderService {

    static orderService = new OrderService()

    getOrderList = async ({query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_ORDER_LIST),
            { params: query }
        )

        return response;
    }

    getOrderDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_ORDER_DETAIL, params)
        )

        return response;
    }
}

export default OrderService.orderService;