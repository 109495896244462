import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";

import lmsLectureService from "redux/edulyteLms/lmsLecture/lmsLecture.service";
import {
    setLmsLectureListLoading,
    setLmsLectureListData,
    setLmsLectureListMessage,

    setDestroyLmsLectureDetailLoading,
    setDestroyLmsLectureDetailMessage,
} from "redux/edulyteLms/lmsLecture/lmsLecture.slice";

import { lmsLectureRedux } from "./lmsLecture.const";

/* tutor lms lecture request */
export const getLmsLectureList = createAsyncThunk(
    `${lmsLectureRedux?.sliceName}/${lmsLectureRedux?.getLmsLectureList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setLmsLectureListLoading(true))
        try {
            const requestData = {
                query: query
            }
            const response = await lmsLectureService.getLmsLectureList(requestData)
            if (response.status === 200) {
                dispatch(setLmsLectureListData(response.data.data))
            } else {
                throw new Error(response)
            }
        }
        catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setLmsLectureListMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
        }
        finally {
            dispatch(setLmsLectureListLoading(false))
        }
    }
)

export const deleteLmsLectureDetail = createAsyncThunk(
    `${lmsLectureRedux?.sliceName}/${lmsLectureRedux?.getLmsLectureList?.requestName}`,
    async (lectureId, { dispatch }) => {
        dispatch(setDestroyLmsLectureDetailLoading(true))

        try {
            const requestData = {
                params: { lectureId: lectureId }
            }
            const response = await lmsLectureService.deleteLmsLecture(requestData)
            if (response.status === 204) {
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setDestroyLmsLectureDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong'))
            toast.error(error.response.data.message || error.response.data.error || 'Something went wrong')
        } finally {
            dispatch(setDestroyLmsLectureDetailLoading(false))
        }
    }
)