import { Outlet } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';

import UnauthorizedPage from 'pages/global/unauthorized/UnauthorizedPage';

import { useAppState } from 'hooks/useStore';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const PrivateRouter = ({ allowedRoles }) => {
    const { session } = useAppState(s => s.auth);
    const { user } = useAppState(s => s.user);
    const { locals } = useAppState(s => s.local)

    return (
        session?.isLoggedIn && user?.user?.roles?.find(role => allowedRoles?.includes(role.role) && (role.role === locals.userRole))
            ? <Outlet />
            : session?.isLoggedIn
                ? <UnauthorizedPage />
                : !(user?.isLoading || session?.isLoggedIn)
                    ? window.open(`${EDULYTE_APP_DOMAIN_URL}/log-in?app_name=edulyte-admin&redirect_url=${window.location.href}`, "_self")
                    : <FullPageLoader isLoading={true} />
    );
}

export default PrivateRouter;