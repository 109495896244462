import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import ButtonLoader from "components/loader/ButtonLoader";
import { OptionSelector } from "components/common-components/Select";

import { interviewersList, videoCallDurationConst, slotPeriodConst } from "pages/auth/editTutorOnboard/data";

import { createOnboardingInterviewDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearAddOnboardingInterviewDetail } from "redux/onboarding/onboarding.slice";

const AddUserSchedule = ({ setIsOpenAddScheduleContainer, setIsAddSchedule }) => {
    const { addOnboardingInterviewDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [comments, setComments] = useState("")
    const [videoCallDetails, setVideoCallDetails] = useState({
        recruiterId: interviewersList[0]?.value,
        interviewTitle: "Meet and Greet.",
        description: "Onboarding meeting.We would like to know more about your background and experience.Please ask any questions you may have.",
        duration: videoCallDurationConst?.THIRTY?.value,
        slotPeriodDays: slotPeriodConst?.FOURTEEN?.value,
        isInterviewSkip: false
    })

    useEffect(() => {
        if (addOnboardingInterviewDetail?.addOnboardingInterviewDetail) {
            setIsOpenAddScheduleContainer(false)
            setIsAddSchedule(true)
            dispatcher(setClearAddOnboardingInterviewDetail())
        }
    }, [addOnboardingInterviewDetail?.addOnboardingInterviewDetail])

    const onSubmitDocument = (event) => {
        event.preventDefault()

        if (addOnboardingInterviewDetail?.isLoading) return;

        const requestData = {
            onboardingId: Number(onboardingId),
            body: videoCallDetails
        }
        if (comments) {
            videoCallDetails["comments"] = comments
        }
        dispatcher(createOnboardingInterviewDetailByOnboardingId(requestData))
    }

    return (
        <form
            onSubmit={onSubmitDocument}
            className={"space-y-5 w-full px-5 py-3"}>
            <div className='grid grid-cols-12 gap-5'>
                <div className='col-start-1 col-span-full lg:col-start-1 lg:col-span-5 space-y-2'>
                    <label className='font-bodyPri font-normal text-text-900 text-sm'>
                        {"Interviewer Name"}
                        <span className="text-red-500 text-lg">{"*"}
                        </span>
                    </label>
                    <OptionSelector
                        options={interviewersList}
                        placeholder={"Please pick a type"}
                        value={videoCallDetails?.recruiterId}
                        onChange={(option) => setVideoCallDetails({
                            ...videoCallDetails,
                            recruiterId: option?.value
                        })}
                        required={true}
                    />
                </div>
                <div className='col-start-1 col-span-full lg:col-start-1 lg:col-span-5 space-y-2'>
                    <label className='font-bodyPri font-normal text-text-900 text-sm'>
                        {"Interview Title"}
                        <span className="text-red-500 text-lg">{"*"}
                        </span>
                    </label>
                    <div>
                        <input
                            className={"w-full px-3 py-2 rounded-lg border-2 border-divider-medium focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                            maxLength={50}
                            onChange={(event) => setVideoCallDetails({
                                ...videoCallDetails,
                                interviewTitle: event.target.value
                            })}
                            value={videoCallDetails?.interviewTitle}
                            required={true}
                        />
                    </div>
                </div>
                <div className='col-start-1 col-span-full lg:col-start-1 lg:col-span-5 space-y-2'>
                    <label className='font-bodyPri font-normal text-text-900 text-sm'>
                        {"Interview Description"}
                        <span className="text-red-500 text-lg">{"*"}
                        </span>
                    </label>
                    <div>
                        <textarea
                            className={"w-full px-3 py-2 resize-none rounded-lg shadow border-2 border-divider-medium focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                            rows={5}
                            cols={38}
                            maxLength={200}
                            onChange={(event) => setVideoCallDetails({
                                ...videoCallDetails,
                                description: event.target.value
                            })}
                            value={videoCallDetails?.description}
                            required={true}
                        />
                    </div>
                </div>
                <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-5 space-y-2"}>
                    <label className='font-bodyPri font-normal text-text-900 text-sm'>
                        {"Duration (min.)"}
                        <span className="text-red-500 text-lg">
                            {"*"}
                        </span>
                    </label>
                    <OptionSelector
                        options={Object.values(videoCallDurationConst)}
                        className={"w-full"}
                        onChange={(option) => setVideoCallDetails({
                            ...videoCallDetails,
                            duration: option?.value
                        })}
                        value={videoCallDetails?.duration}
                        required={true}
                    />
                </div>
                <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-5 space-y-2"}>
                    <label className='font-bodyPri font-normal text-text-900 text-sm'>
                        {"Slot Period (days)"}
                        <span className="text-red-500 text-lg">
                            {"*"}
                        </span>
                    </label>
                    <OptionSelector
                        options={Object.values(slotPeriodConst)}
                        className={"w-full"}
                        onChange={(option) => setVideoCallDetails({
                            ...videoCallDetails,
                            slotPeriodDays: option?.value
                        })}
                        value={videoCallDetails?.slotPeriodDays}
                    />
                </div>
                <div className={'col-start-1 col-span-full lg:col-start-1 lg:col-span-5 space-y-2'}>
                    <label className='font-bodyPri font-normal text-text-900 text-sm'>
                        {"Comment (if any)"}
                    </label>
                    <div>
                        <textarea
                            className={"w-full px-3 py-2 resize-none rounded-lg shadow border-2 border-divider-medium focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                            rows={3}
                            cols={38}
                            maxLength={200}
                            onChange={(event) => setComments(event?.target?.value)}
                            value={comments}
                        />
                    </div>
                </div>
            </div>
            <div className={"flex items-start justify-start gap-5"}>
                <button
                    type={"submit"}
                    className={cn(
                        "w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                        "border border-secondary-main text-secondary-main",
                        "hover:bg-secondary-main hover:text-text-50",
                        addOnboardingInterviewDetail?.isLoading && "bg-secondary-main"
                    )}
                >
                    {addOnboardingInterviewDetail?.isLoading &&
                        <ButtonLoader isLoading={addOnboardingInterviewDetail?.isLoading} />
                    }
                    {!addOnboardingInterviewDetail?.isLoading && "Save"}
                </button>
                <button
                    className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                        "border border-primary-main text-primary-main",
                        "hover:bg-primary-main hover:text-text-50"
                    )}
                    onClick={() => {
                        setIsOpenAddScheduleContainer(false)
                        setIsAddSchedule(true)
                    }}
                >
                    {"Cancel"}
                </button>
            </div>
        </form>
    )
}

export default AddUserSchedule