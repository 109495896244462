import { BsPerson } from "react-icons/bs";
import { IoIosFlash } from "react-icons/io";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineLibraryBooks,
  MdOutlineSchool,
  MdOutlineSubscriptions
} from "react-icons/md";
import { FiUsers, FiUserPlus, FiUserCheck } from "react-icons/fi";
import { RiWalletLine, RiLuggageDepositLine } from "react-icons/ri";

export const pageHeading = {
  heading: "Dashboard",
  subHeading: "",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/dashboard.svg"
}

export const heroCard = {
  title: "Welcome back, {0}",
  studentText: "You completed {0} session! Your current learning score is {1}",
  tutorText: "You completed {0} session! Your current teaching score is {1}",
}

export const dashboardStats = {
  USERS: {
    value: 'USERS',
    label: "Users",
    data: 0,
    icon: <FiUsers className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  },
  TUTORS: {
    value: 'TUTORS',
    label: "Tutors",
    data: 0,
    icon: <BsPerson className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  },
  BOOKINGS: {
    value: 'BOOKINGS',
    label: "Bookings",
    data: 0,
    icon: <IoIosFlash className="text-3xl text-secondary-main" />,
    unit: "Confirmed",
    roles: []
  },
  DEPOSITS: {
    value: 'DEPOSITS',
    label: "Deposits",
    data: 0,
    icon: <RiLuggageDepositLine className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  },
  REFERRALS: {
    value: "REFERRALS",
    label: "Referrals",
    data: 0,
    icon: <MdOutlineSchool className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  },
  COURSES: {
    value: "COURSES",
    label: "Courses",
    data: 0,
    icon: <MdOutlineLibraryBooks className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  },
  SUBSCRIPTIONS: {
    value: "SUBSCRIPTIONS",
    label: "Subscriptions",
    data: 0,
    icon: <MdOutlineSubscriptions className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  },
  PENDING_WITHDRAWALS: {
    value: "PENDING_WITHDRAWALS",
    label: "Pending Withdrawals",
    data: 0,
    icon: <RiWalletLine className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  },
  APPROVED_WITHDRAWALS: {
    value: "APPROVED_WITHDRAWALS",
    label: "Approved Withdrawals",
    data: 0,
    icon: <MdOutlineAccountBalanceWallet className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  }
}

export const usersStats = {
  NEW_USERS_JOINED: {
    value: "NEW_USERS_JOINED",
    label: "New Users Joined",
    data: 0,
    icon: <FiUserPlus className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  },
  VERIFIED_USERS: {
    value: "VERIFIED_USERS",
    label: "Verified Users",
    data: 0,
    icon: <FiUserCheck className="text-3xl text-secondary-main" />,
    unit: "",
    roles: []
  }
}

export const listOfTimeZones = [
  {
    name: "Sydney",
    zone: "Australia/Sydney",
  },
  {
    name: "Adelaide",
    zone: "Australia/Adelaide",
  },
  {
    name: "Mumbai",
    zone: "Asia/Kolkata",
  },
  {
    name: "New York",
    zone: "America/New_York",
  },
  {
    name: "Singapore",
    zone: "Asia/Singapore",
  },
  {
    name: "Beijing",
    zone: "Asia/Shanghai",
  },
];