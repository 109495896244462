import { createSlice } from "@reduxjs/toolkit";
import { PAYMENT_INITIAL_STATE } from "redux/payment/payment.initialState";


const payment = createSlice({
    name: "payment",
    initialState: PAYMENT_INITIAL_STATE,
    reducers: {
        // Actions for make payment
        setMakePaymentLoading: (state, { payload }) => {
            if (payload) {
                state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
            }
            state.makePayment.isLoading = payload
        },
        setMakePayment: (state, { payload }) => {
            state.makePayment.makePayment = payload
            state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
        },
        setMakePaymentErrorMsg: (state, { payload }) => {
            state.makePayment.errorMsg = payload
            state.makePayment.makePayment = PAYMENT_INITIAL_STATE.makePayment.makePayment
            state.makePayment.makePaymentConfirm = PAYMENT_INITIAL_STATE.makePayment.makePaymentConfirm
        },
        setClearMakePayment: (state) => {
            state.makePayment.isLoading = PAYMENT_INITIAL_STATE.makePayment.isLoading
            state.makePayment.makePayment = PAYMENT_INITIAL_STATE.makePayment.makePayment
            state.makePayment.makePaymentConfirm = PAYMENT_INITIAL_STATE.makePayment.makePaymentConfirm
            state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
        },
        setMakePaymentPayload: (state, { payload }) => {
            state.makePayment.makePaymentPayload = payload
        },
        setClearMakePaymentPayload: (state) => {
            state.makePayment.makePaymentPayload = PAYMENT_INITIAL_STATE.makePayment.makePaymentPayload
        },
        setMakePaymentConfirm: (state, { payload }) => {
            state.makePayment.makePaymentConfirm = payload
            state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
        },
        setClearMakePaymentConfirm: (state) => {
            state.makePayment.makePaymentConfirm = PAYMENT_INITIAL_STATE.makePayment.makePaymentConfirm
        },

        // reducers for manual stripe payment
        setManualStripePaymentLoading: (state, {payload}) => {
            state.manualStripePayment.isLoading = payload
        },
        setManualStripePaymentData: (state, {payload}) => {
            state.manualStripePayment.data = payload
            state.manualStripePayment.message = PAYMENT_INITIAL_STATE.manualStripePayment.message
        },
        setManualStripePaymentMessage: (state, {payload}) => {
            state.manualStripePayment.message = payload
            state.manualStripePayment.data = PAYMENT_INITIAL_STATE.manualStripePayment.data
        },
        resetManualStripePayment: (state) => {
            state.manualStripePayment = PAYMENT_INITIAL_STATE.manualStripePayment
        },

        // reducers for manual stripe payment payload
        setManualStripePaymentPayload: (state, {payload}) => {
            state.manualStripePayment.payload = payload
        },
        resetManualStripePaymentPayload: (state) => {
            state.manualStripePayment.payload = PAYMENT_INITIAL_STATE.manualStripePayment.payload
        },
    }
})

export const {
    setMakePaymentLoading,
    setMakePayment,
    setMakePaymentErrorMsg,
    setClearMakePayment,
    setMakePaymentPayload,
    setClearMakePaymentPayload,
    setMakePaymentConfirm,
    setClearMakePaymentConfirm,

    setManualStripePaymentLoading,
    setManualStripePaymentData,
    setManualStripePaymentMessage,
    resetManualStripePayment,

    setManualStripePaymentPayload,
    resetManualStripePaymentPayload,
} = payment.actions

export default payment.reducer