import { createSlice } from "@reduxjs/toolkit";

import { getBookingList } from "redux/booking/booking.request";

import { BOOKING_INITIAL_STATE } from "redux/booking/booking.initialState";

const booking = createSlice({
    name: "booking",
    initialState: BOOKING_INITIAL_STATE,
    reducers: {
        // reducers for booking list
        setBookingListLoading: (state, { payload }) => {
            state.bookingList.isLoading = payload
        },
        setBookingListData: (state, { payload }) => {
            state.bookingList.data = payload
            state.bookingList.message = BOOKING_INITIAL_STATE.bookingList.message
        },
        setBookingListMessage: (state, { payload }) => {
            state.bookingList.message = payload
            state.bookingList.data = BOOKING_INITIAL_STATE.bookingList.data
        },
        resetBookingList: (state) => {
            state.bookingList = BOOKING_INITIAL_STATE.bookingList
        },

        // reducers for booking detail
        setBookingDetailLoading: (state, { payload }) => {
            state.bookingDetail.isLoading = payload
        },
        setBookingDetailData: (state, { payload }) => {
            state.bookingDetail.data = payload
            state.bookingDetail.message = BOOKING_INITIAL_STATE.bookingDetail.message
        },
        setBookingDetailMessage: (state, { payload }) => {
            state.bookingDetail.message = payload
            state.bookingDetail.data = BOOKING_INITIAL_STATE.bookingDetail.data
        },
        resetBookingDetail: (state) => {
            state.bookingDetail = BOOKING_INITIAL_STATE.bookingDetail
        },

        // reducers for add booking
        setAddBookingLoading: (state, { payload }) => {
            state.addBooking.isLoading = payload
            if (payload) state.addBooking.errorMsg = null
        },
        setAddBooking: (state, { payload }) => {
            state.addBooking.addBooking = payload
            state.addBooking.errorMsg = BOOKING_INITIAL_STATE.addBooking.errorMsg
        },
        setAddBookingErrorMsg: (state, { payload }) => {
            state.addBooking.errorMsg = payload
            state.addBooking.addBooking = BOOKING_INITIAL_STATE.addBooking.addBooking
        },
        setClearAddBooking: (state) => {
            state.addBooking = BOOKING_INITIAL_STATE.addBooking
        },
        setAddBookingPayload: (state, { payload }) => {
            state.addBooking.addBookingPayload = payload
        },
        setClearAddBookingPayload: (state) => {
            state.addBooking.addBookingPayload = BOOKING_INITIAL_STATE.addBooking.addBookingPayload
        },
        setChooseTimeSlotPayload: (state, { payload }) => {
            state.addBooking.chooseTimeSlotPayload = payload
        },
        setClearChooseTimeSlotPayload: (state) => {
            state.addBooking.chooseTimeSlotPayload = BOOKING_INITIAL_STATE.addBooking.chooseTimeSlotPayload
        },

        //reducers for add booking detail
        setAddBookingDetailLoading: (state, { payload }) => {
            state.addBookingDetail.isLoading = payload
        },
        setAddBookingDetailData: (state, { payload }) => {
            state.addBookingDetail.data = payload
            state.addBookingDetail.message = BOOKING_INITIAL_STATE.addBookingDetail.message
        },
        setAddBookingDetailMessage: (state, { payload }) => {
            state.addBookingDetail.message = payload
            state.addBookingDetail.data = BOOKING_INITIAL_STATE.addBookingDetail.data
        },
        resetAddBookingDetail: (state) => {
            state.addBookingDetail = BOOKING_INITIAL_STATE.addBookingDetail
        },

        // reducers for add  booking detail payload
        setAddBookingDetailPayload: (state, { payload }) => {
            state.addBookingDetail.payload = payload
        },
        resetAddBookingDetailPayload: (state) => {
            state.addBookingDetail.payload = BOOKING_INITIAL_STATE.addBookingDetail.payload
        },

        // add booking session detail
        setAddBookingSessionDetailLoading: (state, { payload }) => {
            state.addBookingSessionDetail.isLoading = payload
        },
        setAddBookingSessionDetailData: (state, { payload }) => {
            state.addBookingSessionDetail.data = payload
            state.addBookingSessionDetail.message = BOOKING_INITIAL_STATE.addBookingSessionDetail.message
        },
        setAddBookingSessionDetailMessage: (state, { payload }) => {
            state.addBookingSessionDetail.message = payload
            state.addBookingSessionDetail.data = BOOKING_INITIAL_STATE.addBookingSessionDetail.data
        },
        resetAddBookingSessionDetail: (state) => {
            state.addBookingSessionDetail = BOOKING_INITIAL_STATE.addBookingSessionDetail
        },

        // reducers for filter booking list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        setClearFilterProps: (state) => {
            state.filterProps = BOOKING_INITIAL_STATE.filterProps
            state.bookingList.filteredBookingList = state.bookingList.bookingList
        }

    },
    extraReducers: {
        [getBookingList.pending]: (state) => {
            state.bookingList.isLoading = true
        },
        [getBookingList.fulfilled]: (state, { payload }) => {
            state.bookingList.isLoading = false
            state.bookingList.data = payload
            state.bookingList.message = BOOKING_INITIAL_STATE.bookingList.message
        },
        [getBookingList.rejected]: (state, { payload }) => {
            state.bookingList.isLoading = false
            state.bookingList.message = payload
            state.bookingList.data = BOOKING_INITIAL_STATE.bookingList.data
        }
    }
})

export const {
    setBookingListLoading,
    setBookingListData,
    setBookingListMessage,
    resetBookingList,

    setBookingDetailLoading,
    setBookingDetailData,
    setBookingDetailMessage,
    resetBookingDetail,

    setAddBookingDetailLoading,
    setAddBookingDetailData,
    setAddBookingDetailMessage,
    resetAddBookingDetail,

    setAddBookingDetailPayload,
    resetAddBookingDetailPayload,

    setAddBookingSessionDetailLoading,
    setAddBookingSessionDetailData,
    setAddBookingSessionDetailMessage,
    resetAddBookingSessionDetail,

    // OLD
    setAddBookingLoading,
    setAddBooking,
    setAddBookingErrorMsg,
    setClearAddBooking,
    setAddBookingPayload,
    setClearAddBookingPayload,
    setChooseTimeSlotPayload,
    setClearChooseTimeSlotPayload,

    setFilterProps,
    setClearFilterProps
} = booking.actions

export default booking.reducer