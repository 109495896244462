import format from "string-format";
import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "./product.const";

class ProductService {

    static productService = new ProductService()

    getProductList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_PRODUCT_LIST),
            { params: query }
        )

        return response;
    }

    deleteProductDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_PRODUCT_DETAIL, params)
        )

        return response;
    }
}

export default new ProductService()