import {
    SearchWithdrawalId,
    SearchUserId,
    SearchGatewayPaymentId,
    SearchVirtualPaymentId,
    FilterPayoutMethod,
    SearchWithdrawalAmount,
    FilterWithdrawalStatus,
    SearchDate,
    ButtonAction
} from "pages/auth/withdrawal/components/WithdrawalFilterStatistics";

import { withdrawalStatus } from "redux/withdrawal/withdrawal.const";

import { dayjs } from "utils/dateTime.utils";

export const pageHeading = {
    heading: "Withdrawals",
    subHeading: "See your balance, transactions and withdraw money!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/wallet.svg"
}

export const withdrawalHeaderConst = {
    WITHDRAWAL_ID: {
        label: "Withdrawal ID",
        value: "withdrawal_id"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    GATEWAY_PAYMENT_ID: {
        label: "Gateway Payment ID",
        value: "gateway_payment_id"
    },
    VIRTUAL_PAYMENT_ID: {
        label: "Virtual Payment ID",
        value: "virtual_payment_id"
    },
    PAYOUT_TYPE: {
        label: "Payout Type",
        value: "payout_type"
    },
    WITHDRAWAL_AMOUNT: {
        label: "Withdrawal Amount",
        value: "withdrawal_amount"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const subRowHeader = [
    <SearchWithdrawalId />,
    <SearchUserId />,
    <SearchGatewayPaymentId />,
    <SearchVirtualPaymentId />,
    <FilterPayoutMethod />,
    <SearchWithdrawalAmount />,
    <FilterWithdrawalStatus />,
    <SearchDate />,
    <ButtonAction />
]

export const sortWithdrawalLowToHigh = (activeSortHeader, withdrawal1, withdrawal2) => {
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.USER_ID?.value) {
        return (Number(withdrawal2?.user?.userId) - Number(withdrawal1?.user?.userId))
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.WITHDRAWAL_ID?.value) {
        return (Number(withdrawal2?.withdrawalId) - Number(withdrawal1?.withdrawalId))
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.GATEWAY_PAYMENT_ID?.value) {
        return withdrawal2?.gatewayPaymentId?.toLowerCase()?.localeCompare(withdrawal1?.gatewayPaymentId?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.VIRTUAL_PAYMENT_ID?.value) {
        return withdrawal2?.virtualTxnId?.toLowerCase()?.localeCompare(withdrawal1?.virtualTxnId?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.PAYOUT_TYPE?.value) {
        return withdrawal2?.payoutType?.toLowerCase()?.localeCompare(withdrawal1?.payoutType?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.WITHDRAWAL_AMOUNT?.value) {
        return (Number(withdrawal2?.amount / 100) - Number(withdrawal1?.amount / 100))
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.STATUS?.value) {
        return withdrawal2?.status?.toLowerCase()?.localeCompare(withdrawal1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.DATE?.value) {
        let dateTime1 = dayjs(withdrawal1?.createdAt)
        let dateTime2 = dayjs(withdrawal2?.createdAt)
        return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
    }
}

export const sortWithdrawalHighToLow = (activeSortHeader, withdrawal1, withdrawal2) => {
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.USER_ID?.value) {
        return (Number(withdrawal1?.user?.userId) - Number(withdrawal2?.user?.userId))
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.WITHDRAWAL_ID?.value) {
        return (Number(withdrawal1?.withdrawalId) - Number(withdrawal2?.withdrawalId))
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.GATEWAY_PAYMENT_ID?.value) {
        return withdrawal1?.gatewayPaymentId?.toLowerCase()?.localeCompare(withdrawal2?.gatewayPaymentId?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.VIRTUAL_PAYMENT_ID?.value) {
        return withdrawal1?.virtualTxnId?.toLowerCase()?.localeCompare(withdrawal2?.virtualTxnId?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.PAYOUT_TYPE?.value) {
        return withdrawal1?.payoutType?.toLowerCase()?.localeCompare(withdrawal2?.payoutType?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.WITHDRAWAL_AMOUNT?.value) {
        return (Number(withdrawal1?.amount / 100) - Number(withdrawal2?.amount / 100))
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.STATUS?.value) {
        return withdrawal1?.status?.toLowerCase()?.localeCompare(withdrawal2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === withdrawalHeaderConst?.DATE?.value) {
        let dateTime1 = dayjs(withdrawal1?.createdAt)
        let dateTime2 = dayjs(withdrawal2?.createdAt)
        return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
    }
}

export const withdrawalLogConst = {
    PENDING: {
        title: "Pending",
    },
    APPROVED: {
        title: "Approved",
    },
    CANCELLED: {
        title: "Cancelled",
    },
    REJECTED: {
        title: "Rejected",
    }
}

export const createWithdrawalsLogs = (withdrawalLogs) => {
    return withdrawalLogs?.map((withdrawal) => {
        return {
            ...withdrawalStatus[withdrawal?.status?.toUpperCase()],
            ...withdrawal,
            title: withdrawalLogConst[withdrawal.status.toUpperCase()]?.title
        }
    })
}