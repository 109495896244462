import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { cn } from "utils/cn.utils";

import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';


const Modal = ({
    openModal = false,
    onCloseModal,
    onBackModal,
    maxWidth = "max-w-5xl",
    bgColor = "bg-white",
    title = '',
    children
}) => {
    const closeModal = () => {
        onCloseModal(false)
    }

    const backModal = () => {
        onBackModal()
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full p-8 shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Title as="div">
                                <div className={"w-full flex items-center justify-between gap-5"}>
                                    <div onClick={backModal} className="flex items-center justify-center">
                                        {onBackModal !== undefined && <IoIosArrowBack className={"text-2xl text-text-700 hover:text-text-900 cursor-pointer"} />}
                                    </div>
                                    <div className={"w-full flex justify-center"}>
                                        <span className={"text-2xl font-normal font-bodyPri leading-6 text-center tracking-wider text-primary-dark truncate"}>
                                            {title}
                                        </span>
                                    </div>
                                    <div onClick={closeModal} className="flex items-center justify-center">
                                        <AiOutlineCloseCircle className={"text-2xl text-text-700 hover:text-text-900 cursor-pointer"} />
                                    </div>
                                </div>
                            </Dialog.Title>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full max-h-[90vh] overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    {children}
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}

export default Modal