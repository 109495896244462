import { createSlice } from "@reduxjs/toolkit";

import { REFERRAL_INITIAL_STATE } from "redux/referral/referral.initialState";

export const referral = createSlice({
    name: "referral",
    initialState: REFERRAL_INITIAL_STATE,
    reducers: {
        // reducers for refer and earn table
        setReferralListLoading: (state, { payload }) => {
            state.referralList.isLoading = payload
        },
        setReferralListData: (state, {payload}) =>{
            state.referralList.data = payload
            state.referralList.message = REFERRAL_INITIAL_STATE.referralList.message
        },
        setReferralListMessage: (state, {payload}) => {
            state.referralList.message = payload
            state.referralList.data = REFERRAL_INITIAL_STATE.referralList.data
        },
        resetReferralList: (state) => {
            state.referralList = REFERRAL_INITIAL_STATE.referralList
        },

        // filter list for referral
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
        },
        resetFilterProps: (state) => {
            state.filterProps = REFERRAL_INITIAL_STATE.filterProps
        }
    }
})
export const {
    setReferralListLoading,
    setReferralListData,
    setReferralListMessage,
    resetReferralList,

    setFilterProps,
    resetFilterProps,
} = referral.actions
export default referral.reducer;