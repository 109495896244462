import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";

import badgeService from "redux/badge/badge.service";
import {
    setUserBadgeList,

    setAddUserBadgeDetailLoading,
    setAddUserBadgeDetail,
    setAddUserBadgeDetailErrorMsg
} from 'redux/badge/badge.slice';
import { badgeRedux } from "redux/badge/badge.const";

export const getBadgeList = createAsyncThunk(
    `${badgeRedux?.sliceName}/${badgeRedux?.badgeList?.requestName}`,
    async (_, { rejectWithValue }) => {

        try {
            const response = await badgeService.getBadgeList()
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
            rejectWithValue(error.response.data.message || error.response.data.error || "Something Went Wrong")
        }
    }
)

export const getUserBadgeListByUserId = createAsyncThunk(
    `${badgeRedux?.sliceName}/${badgeRedux?.userBadgeList?.requestName}`,
    async (userId, { rejectWithValue }) => {

        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await badgeService.getUserBadgeListByUserId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
            rejectWithValue(error.response.data.message || error.response.data.error || "Something Went Wrong")
        }
    }
)

export const createUserBadgeDetailByUserId = createAsyncThunk(
    `${badgeRedux?.sliceName}/${badgeRedux?.userBadgeDetail?.requestName}`,
    async (userBadgeDetail, { dispatch }) => {
        dispatch(setAddUserBadgeDetailLoading(true))

        try {
            const requestData = {
                params: { userId: userBadgeDetail?.userId },
                body: userBadgeDetail?.body
            }
            const response = await badgeService.createUserBadgeDetailByUserId(requestData)
            if (response.status === 200) {
                dispatch(getUserBadgeListByUserId(userBadgeDetail?.userId))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
            dispatch(setAddUserBadgeDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
        } finally {
            dispatch(setAddUserBadgeDetailLoading(false))
        }
    }
)

export const updateUserBadgeDetailByUserBadgeId = createAsyncThunk(
    `${badgeRedux?.sliceName}/${badgeRedux?.updateUserBadgeDetail?.requestName}`,
    async (userBadgeDetail, { dispatch, getState }) => {
        dispatch(setAddUserBadgeDetailLoading(true))

        try {
            const { userBadgeList } = getState().badge
            const requestData = {
                params: { userBadgeId: userBadgeDetail?.userBadgeId },
                body: userBadgeDetail?.body
            }
            const response = await badgeService.updateUserBadgeDetailByUserBadgeId(requestData)
            if (response.status === 200) {
                const results = userBadgeList?.userBadgeList?.map((userBadge) => userBadge?.userBadgeId === userBadgeDetail?.userBadgeId ? response.data.data : userBadge)
                // const badgeList = { ...userBadgeList?.userBadgeList, results }
                dispatch(setUserBadgeList(results))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
            dispatch(setAddUserBadgeDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
        } finally {
            dispatch(setAddUserBadgeDetailLoading(false))
        }
    }
)

export const updateUserBadgeListOrderByUserId = createAsyncThunk(
    `${badgeRedux?.sliceName}/${badgeRedux?.orderUserBadgeList?.requestName}`,
    async (userBadgeListDetail, { dispatch }) => {
        dispatch(setAddUserBadgeDetailLoading(true))

        try {
            const requestData = {
                params: { userId: userBadgeListDetail?.userId },
                body: userBadgeListDetail?.body
            }
            const response = await badgeService.updateUserBadgeListOrderByUserId(requestData)
            if (response.status === 200) {
                dispatch(getUserBadgeListByUserId(userBadgeListDetail?.userId))
                toast.success(response.data.message)
                // return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
            dispatch(setAddUserBadgeDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
        } finally {
            dispatch(setAddUserBadgeDetailLoading(false))
        }
    }
)

export const deleteUserBadgeDetailByUserBadgeId = createAsyncThunk(
    `${badgeRedux?.sliceName}/${badgeRedux?.deleteUserBadgeDetail?.requestName}`,
    async (userBadgeDetail, { dispatch }) => {
        dispatch(setAddUserBadgeDetailLoading(true))

        try {
            const requestData = {
                params: { userBadgeId: userBadgeDetail?.userBadgeId },
            }
            const response = await badgeService.deleteUserBadgeDetailByUserBadgeId(requestData)
            if (response.status === 200) {
                dispatch(setUserBadgeList(response.data.data))
                toast.success(response.data.message)
                // return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
            dispatch(setAddUserBadgeDetailErrorMsg(error.response.data.message || error.response.data.error || "Something Went Wrong"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong")
        } finally {
            dispatch(setAddUserBadgeDetailLoading(false))
        }
    }
)