import { useEffect } from "react";

import { setGetLessonPref, lessonPreferenceToolTip } from "pages/auth/viewTutor/data";

import ComponentLoader from "components/loader/ComponentLoader";
import { ToggleWithText } from "components/common-components/Toggle";

import ViewTutorHeader from "pages/auth/viewTutor/commonComponents/ViewTutorHeader";

import { getTutorUserLessonByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearTutorUserLesson } from "redux/tutor/tutor.slice";

export default function ViewTutorLesson() {
    const { tutorDetail, tutorUserLesson } = useAppState((s) => s.tutor)

    const dispatcher = useAppDispatcher();

    useEffect(() => {
        if (tutorDetail?.data?.user?.id) {
            dispatcher(getTutorUserLessonByUserId(tutorDetail?.data?.user?.id))
        }

        return () => {
            dispatcher(setClearTutorUserLesson())
        }
    }, [tutorDetail?.data?.user?.id])


    const updateLessonStatus = (key, value) => {
        return;
    }

    return (
        <div className="">
            <ViewTutorHeader title={"Lesson Preferences"} tooltipText={lessonPreferenceToolTip} />

            <div className={"p-5 space-y-2 divide-y divide-blue-50"}>
                {tutorUserLesson?.isLoading &&
                    <ComponentLoader isLoading={tutorUserLesson?.isLoading} />
                }
                {(!tutorUserLesson?.isLoading && tutorUserLesson?.tutorUserLesson) &&
                    setGetLessonPref(tutorUserLesson?.tutorUserLesson)?.map((lesson, index) => (
                        <ToggleWithText
                            key={index}
                            description={lesson.description}
                            onChange={(isToggle) => updateLessonStatus(lesson.key, isToggle)}
                            title={lesson.label}
                            value={lesson.value}
                        />
                    ))}
                {(!tutorUserLesson?.isLoading && tutorUserLesson?.errorMsg) &&
                    <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                        {tutorUserLesson?.errorMsg}
                    </div>
                }
            </div>
        </div>
    );
}
