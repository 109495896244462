import { createSlice } from "@reduxjs/toolkit";

import { LMS_ARTICLE_INITIAL_STATE } from "./lmsArticle.initialState";

const lmsArticle = createSlice({
    name: "lms article",
    initialState: LMS_ARTICLE_INITIAL_STATE,
    reducers: {
        // lms article list
        setLmsArticleListLoading: (state, { payload }) => {
            state.lmsArticleList.isLoading = payload
        },
        setLmsArticleListData: (state, { payload }) => {
            state.lmsArticleList.data = payload
            state.lmsArticleList.message = LMS_ARTICLE_INITIAL_STATE.lmsArticleList.message
        },
        setLmsArticleListMessage: (state, { payload }) => {
            state.lmsArticleList.message = payload
            state.lmsArticleList.data = LMS_ARTICLE_INITIAL_STATE.lmsArticleList.data
        },
        resetLmsArticleList: (state) => {
            state.lmsArticleList = LMS_ARTICLE_INITIAL_STATE.lmsArticleList
        },

        // delete lms article detail
        setDeleteLmsArticleDetailLoading: (state, { payload }) => {
            state.deleteLmsArticleDetail.isLoading = payload
        },
        setDeleteLmsArticleDetailMessage: (state, { payload }) => {
            state.deleteLmsArticleDetail.message = payload
        },
        resetDeleteLmsArticleDetail: (state) => {
            state.deleteLmsArticleDetail = LMS_ARTICLE_INITIAL_STATE.deleteLmsArticleDetail
        },

        // reducers for filter article list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = LMS_ARTICLE_INITIAL_STATE.filterProps
        }
    }
})
export const {
    setLmsArticleListLoading,
    setLmsArticleListData,
    setLmsArticleListMessage,
    resetLmsArticleList,

    setDeleteLmsArticleDetailLoading,
    setDeleteLmsArticleDetailMessage,
    resetDeleteLmsArticleDetail,

    setFilterProps,
    resetFilterProps
} = lmsArticle.actions;

export default lmsArticle.reducer;