import { useEffect } from "react";
import { toast } from "react-toastify";

import { FaDollarSign, FaPercent } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";

import ButtonLoader from 'components/loader/ButtonLoader';
import FullPageLoader from "components/loader/FullPageLoader";
import ToolTipView from "components/toolTip";
import { Button } from "components/common-components/Buttons";
import { InputFieldWithPrefix } from "components/common-components/Form";
import { OptionSelector } from "components/common-components/Select";

import EditTutorHeader from "pages/auth/editTutor/commonComponents/EditTutorHeader";
import { pricingPrefDiscountConst, pricingPreferenceToolTip } from "pages/auth/viewTutor/data";

import { getTutorUserPriceByUserId, updateTutorUserPriceByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setTutorUserPrice, setClearTutorUserPrice } from "redux/tutor/tutor.slice";
import AppointmentDetailCard from "./appointmentDetail";

export default function EditTutorPrice() {
    const { tutorUserPrice, addTutorUserPrice, tutorDetail } = useAppState((s) => s.tutor)

    const dispatcher = useAppDispatcher();

    useEffect(() => {
        if (tutorDetail?.data?.user?.id) {
            dispatcher(getTutorUserPriceByUserId(tutorDetail?.data?.user?.id))
        }

        return () => {
            dispatcher(setClearTutorUserPrice())
        }
    }, [tutorDetail?.data?.user?.id])

    const generatePricingPref = async () => {
        const pricing = {
            trialCourseDuration: tutorUserPrice?.tutorUserPrice?.trialCourseDuration,
            minTrialCoursePrice: tutorUserPrice?.tutorUserPrice?.minTrialCoursePrice,
            minOneToOneCoursePrice: tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice,
            minGroupCoursePrice: tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice,
            pctDisOnFiveOneToOneSession: tutorUserPrice?.tutorUserPrice?.pctDisOnFiveOneToOneSession
        }
        return pricing
    }

    const onHandleReset = () => {
        if (tutorDetail?.data?.user?.id) {
            dispatcher(getTutorUserPriceByUserId(tutorDetail?.data?.user?.id))
        }
    }

    const onConfirm = async () => {
        if (tutorUserPrice?.tutorUserPrice?.minTrialCoursePrice === 0) {
            toast.warn("Trial class Price cannot be zero!")
            return;
        }
        if (tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice === 0) {
            toast.warn("One on One session Price cannot be zero!")
            return;
        }
        if (tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice === 0) {
            toast.warn("Group session Price cannot be zero!")
            return;

        }
        const pricingPreference = await generatePricingPref()
        const requestData = {
            userId: tutorDetail?.data?.user?.id,
            body: pricingPreference
        }
        dispatcher(updateTutorUserPriceByUserId(requestData))
    }

    if (tutorUserPrice?.isLoading || addTutorUserPrice?.isLoading) {
        return (
            <FullPageLoader isLoading={tutorUserPrice?.isLoading || addTutorUserPrice?.isLoading} />
        )
    }

    return (
        <div className="">
            <EditTutorHeader title={"Pricing Preferences"} tooltipText={pricingPreferenceToolTip} />
            <div className={"p-5 space-y-8"}>

                {/* Trail lesson - Time and Duration */}
                <AppointmentDetailCard />

                {/* Minimum hoursly charge - 1-to-1 */}
                {tutorUserPrice?.tutorUserPrice &&
                    <>
                        <div className={""}>
                            <div className={"flex items-center justify-start gap-1"}>
                                <div className={"flex items-center justify-start gap-1"}>
                                    <FaDollarSign size={15} className={"font-semibold text-gray-700"} />
                                    <p className="text-base font-semibold text-gray-700 tracking-wide">
                                        {"Minimum hourly charges for One-on-One sessions"}
                                    </p>
                                </div>
                                <ToolTipView content={"Set your default hourly fee you are willing to accept after platform fee and taxes. You will be able to set fees for your classes while creating them. This input will be used for internal decision making."}>
                                    <button className={""}>
                                        <BsInfoCircle className="inline text-md text-text-900 hover:text-text-900" />
                                    </button>
                                </ToolTipView>
                            </div>
                            <div className={"flex my-4 ml-5 space-x-4"}>
                                <div>
                                    <p className="text-sm font-medium">
                                        {"Price"}
                                    </p>
                                    <InputFieldWithPrefix
                                        onChange={(e) => {
                                            let safe_number = Number(e.target.value)
                                            if (isNaN(safe_number)) {
                                                safe_number = 0
                                            }
                                            dispatcher(setTutorUserPrice({
                                                ...tutorUserPrice?.tutorUserPrice,
                                                minOneToOneCoursePrice: (safe_number * 100)
                                            }))
                                        }}
                                        placeholder="0.0"
                                        prefix={"USD"}
                                        value={(tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice / 100)?.toString()}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Minimum hoursly charge - group session */}
                        <div>
                            <div className={"flex items-center justify-start gap-1"}>
                                <div className={"flex items-center justify-start gap-1"}>
                                    <FaDollarSign size={15} className={"font-semibold text-gray-700"} />
                                    <p className="text-base font-semibold text-gray-700 tracking-wide">
                                        {"Minimum charges for group sessions"}
                                    </p>
                                </div>
                                <ToolTipView content={"Default per session fee for 2 or more students in a group. This is the fee you are willing to accept after platform fee and taxes. You will be able to set fees for your classes while creating them. This input will be used for internal decision making."}>
                                    <button className={""}>
                                        <BsInfoCircle className="inline text-md text-text-900 hover:text-text-900" />
                                    </button>
                                </ToolTipView>
                            </div>
                            <div className={"flex my-4 ml-5 space-x-4"}>
                                <div>
                                    <p className="text-sm font-medium">
                                        {"Price"}
                                    </p>
                                    <InputFieldWithPrefix
                                        onChange={(e) => {
                                            let safe_number = Number(e.target.value)
                                            if (isNaN(safe_number)) {
                                                safe_number = 0
                                            }
                                            dispatcher(setTutorUserPrice({
                                                ...tutorUserPrice?.tutorUserPrice,
                                                minGroupCoursePrice: (safe_number * 100)
                                            }))
                                        }}
                                        placeholder="0.0"
                                        prefix={"USD"}
                                        value={(tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice / 100)?.toString()}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* % discount */}
                        <div>
                            <div className={"flex items-center justify-start gap-0.5"}>
                                <div className={"flex items-center justify-start gap-1"}>
                                    <FaPercent size={15} className={"font-semibold text-gray-700"} />
                                    <p className="px-1 text-base font-semibold text-gray-700 tracking-wide">
                                        {"Discounts for bulk booking"}
                                    </p>
                                </div>
                                <ToolTipView content={"Offer discounts to learners who book 5 sessions or more in one order (package of lessons). This input will be used for all packaged classes."}>
                                    <button className={""}>
                                        <BsInfoCircle className="inline text-md text-text-900 hover:text-text-900" />
                                    </button>
                                </ToolTipView>
                            </div>
                            <div className="flex my-4 ml-5 space-x-4">
                                <div className="w-52">
                                    <p className="text-sm font-medium">
                                        {"Discount"}
                                    </p>
                                    <OptionSelector
                                        onChange={(e) => {
                                            dispatcher(setTutorUserPrice({
                                                ...tutorUserPrice?.tutorUserPrice,
                                                pctDisOnFiveOneToOneSession: Number(e.value)
                                            }))
                                        }}
                                        options={Object.values(pricingPrefDiscountConst)}
                                        value={tutorUserPrice?.tutorUserPrice?.pctDisOnFiveOneToOneSession}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            {(tutorUserPrice?.errorMsg || addTutorUserPrice?.errorMsg) &&
                <div className={"w-full h-full flex justify-center items-center p-5 font-bodyPri font-semibold text-text-800 text-md"}>
                    {tutorUserPrice?.errorMsg || addTutorUserPrice?.errorMsg}
                </div>
            }

            <div className="border-t shadow-sm">
                <div className="flex justify-center py-6 space-x-4">
                    <Button name="Cancel" style="outline" size="medium" onClick={onHandleReset} />
                    {addTutorUserPrice?.isLoading &&
                        <div className={"w-12 h-10 flex items-center justify-center bg-primary-dark rounded-md"}>
                            <ButtonLoader isLoading={addTutorUserPrice?.isLoading} />
                        </div>
                    }
                    {!addTutorUserPrice?.isLoading &&
                        <Button name="Save" style="primary" size="medium" onClick={onConfirm} />
                    }
                </div>
            </div>
        </div>
    );
}