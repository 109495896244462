import { useState } from 'react';

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/viewCourseDetail/viewCourse.style';
import { inputSections } from "pages/auth/viewCourseDetail/data";

import { useAppState } from 'hooks/useStore';

const CourseThumbnailSection = ({ clickedItem, setClickedItem }) => {
    const { courseDetail } = useAppState((state) => state.course)

    const [courseThumbnailDetail, setCourseThumbnailDetail] = useState(
        {
            thumbnailUrl: courseDetail?.courseDetail?.thumbnailPicUrl,
            category: courseDetail?.courseDetail?.category
        }
    )

    const isOpen = inputSections?.COURSE_THUMBNAIL?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }


    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_THUMBNAIL?.value)) {
            setClickedItem(inputSections?.COURSE_THUMBNAIL?.value)
        } else {
            setClickedItem(null)
        }
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Thumbnail"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                <div className={"flex flex-col items-start justify-start gap-5 my-3"}>
                    <div className={"w-full space-y-3"}>
                        <div className={"w-full font-bodyPri font-normal text-text-900 text-base text-center"}>
                            {"Current Image"}
                        </div>
                        <div className={"mx-auto w-full md:w-[50%] aspect-video rounded-lg overflow-hidden shadow-all-md"}>
                            <img
                                src={courseThumbnailDetail?.thumbnailUrl}
                                className={"w-full h-full object-cover"}
                                alt={"Thumbnail-photo"}
                            />
                        </div>
                    </div>
                    {(courseDetail?.errorMsg) &&
                        <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                            {courseDetail?.errorMsg}
                        </div>
                    }
                </div>

            </CollapsableContainer>
        </div>
    )
}

export default CourseThumbnailSection;