export const url = {
    SEND_OTP: "/notifications/send-otp",
    VERIFY_OTP: "/notifications/verify-otp",
}

export const notificationRedux = {
    sliceName: "notification",
    sendOtp: {
        requestName: "createSendOtp"
    },
    verifyOtp: {
        requestName: "createVerifyOtp"
    }
}

export const otpStatus = {
    SUBMITTED: {
        label: "Submitted",
        value: "submitted"
    },
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    EXPIRED: {
        label: "Expired",
        value: "expired"
    }
}

export const otpDeliveryMethod = {
    EMAIL: {
        label: "Email",
        value: "email"
    },
    SMS: {
        label: "Sms",
        value: "sms"
    }
}