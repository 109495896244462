import { useState, useCallback } from "react";
import {  useNavigate } from "react-router-dom";

import { MdOutlineClose } from "react-icons/md";

import { motion, AnimatePresence } from "framer-motion";

import FilterIcon from "components/pageFilter/FilterIcon";
import RefreshButton from "components/common-components/RefreshButton";
import ClearFilter from "components/pageFilter/ClearFilter";

import { availabilityFilters, getAvailabilityListPayload, searchParamsInfo } from "pages/auth/editUser/data";
import AvailabilityIdFilter from "./AvailabilityIdFilter";
import AvailabilityNameFilter from "./AvailabilityNameFilter";
import AvailabilityRecurringFilter from "./AvailabilityRecurringFilter";
import AvailabilityDateFilter from "./AvailabilityDateFilter";
// import AvailabilityTimeZoneFilter from "./AvailabilityTimeZoneFilter";

import { getAvailabilityList } from "redux/availability/availability.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetFilterProps } from "redux/availability/availability.slice";

import { pagesInfo } from "utils/pagesInfo";
import { cn } from "utils/cn.utils";

const AvailabilityFilters = () => {
    const { userDetail } = useAppState((state) => state.user)
    const { filterProps } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher();
    const navigate = useNavigate();

    const [activeFilter, setActiveFilter] = useState(null)
    const [mobileFilter, setMobileFilter] = useState(false)

    const onHandleClearAllFilters = useCallback(() => {
        dispatcher(resetFilterProps())
    }, [])

    const getUserAvailabilityList = async () => {
        let requestDataPayload = await getAvailabilityListPayload(filterProps)
        requestDataPayload["userId"] = userDetail?.userDetail?.userId
        dispatcher(getAvailabilityList({ ...requestDataPayload }))
    }

    const onHandleCreateAvailability = useCallback(() => {
        navigate(`${pagesInfo.EDIT_USER.pagePath}/${userDetail?.userDetail?.userId}/edit/user-availability/create`)
    }, [userDetail?.userDetail?.userId])

    const FilterComponent = () => {
        return (
            <>
                {Object?.values(availabilityFilters)?.map((filterItem, index) => (
                    <div key={index}>
                        {(filterItem.key === searchParamsInfo.id.key) &&
                            <AvailabilityIdFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.name.key) &&
                            <AvailabilityNameFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.isRecurring.key) &&
                            <AvailabilityRecurringFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {(filterItem.key === searchParamsInfo.date.key) &&
                            <AvailabilityDateFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                        {/* {(filterItem.key === searchParamsInfo.timeZone.key) &&
                            <AvailabilityTimeZoneFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        } */}
                    </div>
                ))}
                <ClearFilter onHandleClearAllFilters={onHandleClearAllFilters} />
            </>
        )
    }

    return (
        <div className={"w-full px-6 py-3 bg-white"}>
            <div className={"flex flex-col items-center justify-center gap-3 mt-0 sm:mt-1 sm:flex-row sm:items-start sm:justify-between sm:gap-5"}>
                <AnimatePresence initial={mobileFilter}>
                    {mobileFilter && (
                        <motion.div
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            exit={{ x: -200 }}
                            transition={{ duration: 0.3 }}
                            className='absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'
                        >
                            <div className='flex justify-between items-center'>
                                <h3 className='text-lg font-bodyPri font-semibold text-text-900'>{"Filter"}</h3>
                                <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className='cursor-pointer' />
                            </div>
                            <FilterComponent />
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className='hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'>
                    <FilterIcon iconTooltip={"Filter Availability"} />
                    <FilterComponent />
                </div>
                <div className={"flex w-full lg:w-fit items-center justify-between lg:justify-end gap-3"}>
                    <div className='flex items-center gap-3'>
                        <div onClick={() => setMobileFilter(!mobileFilter)} className='block lg:hidden text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'>
                            {'Filter'}
                        </div>
                        <RefreshButton onHandleRefreshButton={getUserAvailabilityList} />
                    </div>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.3 }}
                        className={cn(
                            "py-2 px-3 md:px-5 text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:bg-secondary-dark rounded",
                            "font-bodyPri font-normal text-sm sm:text-base"
                        )}
                        onClick={onHandleCreateAvailability}
                    >
                        <span className={"whitespace-normal sm:whitespace-nowrap"}>
                            {"Create New Availability"}
                        </span>
                    </motion.button>
                </div>
            </div>
        </div>
    );
};

export default AvailabilityFilters;