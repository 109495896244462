import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import CourseConfiguration from './components/CourseConfiguration';

import { getCourseConfigDetail, getCourseTutorList } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetCourseConfigDetail, resetCourseTutorList } from 'redux/course/course.slice';

const EditCourseConfigurationPage = () => {
    const { courseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    useEffect(() => {
        if (courseId) {
            dispatcher(getCourseTutorList({ courseId: courseId }))
            dispatcher(getCourseConfigDetail(courseDetail?.courseDetail?.courseConfig))
        }

        return () => {
            dispatcher(resetCourseTutorList())
            dispatcher(resetCourseConfigDetail())
        }
    }, [courseId])


    return (
        <div className={cn(
            "w-full h-full bg-white rounded-lg select-none",
            "min-h-[60vh]"
        )}>
            <div className={"w-full px-5 py-3 bg-background-darkLight rounded-lg"}>
                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Config"}
                </div>
            </div>

            <CourseConfiguration />
        </div>
    )
}

export default EditCourseConfigurationPage