
const ClearFilter = ({ onHandleClearAllFilters }) => {

    return (
        <div 
        className={""} 
        onClick={onHandleClearAllFilters}
        >
            <span className={"font-bodyPri font-medium text-primary-dark hover:opacity-90 cursor-pointer whitespace-nowrap"}>
                {"Clear Filters"}
            </span>
        </div>
    )
}

export default ClearFilter;