import { createSlice } from "@reduxjs/toolkit";

import { WALLET_TXN_INITIAL_STATE } from "redux/walletTxn/walletTxn.initialState";
import { filterName } from "redux/walletTxn/walletTxn.const";

import { getWalletTransactionList } from "./walletTxn.request";

const walletTxn = createSlice({
    name: "walletTxn",
    initialState: WALLET_TXN_INITIAL_STATE,
    reducers: {
        // reducers for wallet txn list
        setClearWalletTxnList: (state) => {
            state.walletTxnList = WALLET_TXN_INITIAL_STATE.walletTxnList
        },
        setWalletTxnListPayload: (state, { payload }) => {
            state.walletTxnList.walletTxnList = payload
            state.walletTxnList.filteredWalletTxnList = payload
        },

        // reducers for wallet txn details
        setWalletTxnDetail: (state, { payload }) => {
            state.walletTxnDetail.walletTxnDetail = payload
        },
        setClearWalletTxnDetail: (state) => {
            state.walletTxnDetail = WALLET_TXN_INITIAL_STATE.walletTxnDetail
        },
        setWalletTxnDetailPayload: (state, { payload }) => {
            state.walletTxnDetail.walletTxnDetailPayload = payload
        },
        setClearWalletTxnDetailPayload: (state) => {
            state.walletTxnDetail.walletTxnDetailPayload = WALLET_TXN_INITIAL_STATE.walletTxnDetail.walletTxnDetailPayload
        },

        // reducer for filtering the list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        setClearFilterProps: (state) => {
            state.filterProps = WALLET_TXN_INITIAL_STATE.filterProps
        }
    },
    extraReducers: {
        [getWalletTransactionList.pending]: (state) => {
            state.walletTxnList.isLoading = true
        },
        [getWalletTransactionList.fulfilled]: (state, { payload }) => {
            state.walletTxnList.isLoading = false
            state.walletTxnList.walletTxnList = payload
            state.walletTxnList.filteredWalletTxnList = payload
            state.walletTxnList.errorMsg = WALLET_TXN_INITIAL_STATE.walletTxnList.errorMsg
        },
        [getWalletTransactionList.rejected]: (state, { payload }) => {
            state.walletTxnList.isLoading = false
            state.walletTxnList.errorMsg = payload
            state.walletTxnList.walletTxnList = WALLET_TXN_INITIAL_STATE.walletTxnList.walletTxnList
            state.walletTxnList.filteredWalletTxnList = WALLET_TXN_INITIAL_STATE.walletTxnList.filteredWalletTxnList
        }
    }
})

export const {
    setClearWalletTxnList,
    setWalletTxnListPayload,

    setWalletTxnDetail,
    setClearWalletTxnDetail,

    setWalletTxnDetailPayload,
    setClearWalletTxnDetailPayload,

    setFilterProps,
    setClearFilterProps,
} = walletTxn.actions

export default walletTxn.reducer