import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import {
    ListItem,
    ItemLink,
    ItemIcon,
    ItemName,
    ListItems,
} from './SideNavBarStyle';

import { IoIosArrowForward } from "react-icons/io";

import SideBarSubMenuItem from "./SideBarSubMenuItem";

import { useAppState } from 'hooks/useStore';

import { edulyteAppPagesInfo } from "utils/pagesInfo";

import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const SideBarItem = ({ dataItem, visible }) => {
    const { locals } = useAppState((s) => s.local)

    const navigate = useNavigate()
    const location = useLocation();

    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const itemNameVariants = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
        const topLevelPath = pathSegments[0];
        if (dataItem?.subRoutes?.includes(topLevelPath)) {
            setActiveMenuItem(dataItem?.value);
            return;
        }
        if (topLevelPath) {
            setActiveMenuItem(topLevelPath)
        }

    }, [location.pathname]);

    const onHandleGroupedMenu = (event) => {
        if (dataItem?.value === "switch_to_app") {
            event.preventDefault();
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo.DASHBOARD.pagePath}`, "_self")
            return;
        }
        if (!dataItem?.subMenu || (dataItem?.subMenu?.length === 0)) {
            navigate(dataItem?.path)
        } else {
            if (!activeMenuItem || (activeMenuItem !== dataItem?.value)) {
                setActiveMenuItem(dataItem?.value)
            } else {
                setActiveMenuItem(null)
            }
        }
        event.preventDefault();
    }

    const isActive = dataItem?.subRoutes?.includes(location?.pathname?.replace(/^\/+/g, ''));

    return (
        <>
            <ItemLink to={dataItem?.path}
                onClick={onHandleGroupedMenu}
                className={cn(
                    "relative has-tooltip group",
                    ((activeMenuItem && (activeMenuItem === dataItem?.value)) && isActive) && "bg-white text-primary-dark shadow-xl"
                )}
            >
                <ItemIcon>
                    <dataItem.icon />
                </ItemIcon>
                <ItemName
                    variants={itemNameVariants}
                    initial={'open'}
                    animate={visible ? 'open' : 'close'}
                    transition={transition}
                >
                    {dataItem.name}
                </ItemName>
                {(dataItem?.subMenu?.length > 0) &&
                    <div className={cn(
                        visible ? "pl-5 flex justify-end" : "hidden"
                    )}>
                        <IoIosArrowForward className={cn(
                            "font-bold text-2xl transition ease-in-out delay-50 duration-100 group-hover:bg-primary-light group-hover:text-primary-dark group-hover:scale-110",
                            (activeMenuItem && (activeMenuItem === dataItem?.value)) && "rotate-90",
                            (!activeMenuItem || (activeMenuItem && (activeMenuItem !== dataItem?.value))) && "rotate-0",
                        )}
                        />
                    </div>
                }
                {!visible &&
                    <span
                        className={cn(
                            "w-fit px-2 py-1 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -translate-y-8",
                            "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap",
                            visible ? "translate-x-[100%]" : "translate-x-5"
                        )}
                    >
                        {dataItem?.name}
                    </span>
                }
            </ItemLink>
            {((dataItem?.subMenu?.length > 0) && (activeMenuItem === dataItem?.value)) &&
                <ListItems>
                    {dataItem?.subMenu?.map((menuItem, index) => menuItem?.roles?.includes(locals.userRole) && (
                        <SideBarSubMenuItem
                            key={index}
                            menuItem={menuItem}
                            visible={visible}
                        />
                    ))}
                </ListItems>
            }
        </>
    )
}

export default SideBarItem;
