export const url = {
    ONBOARDING_LIST: "/onboardings",
    USER_ONBOARDING_LIST: "/onboardings/users/{userId}",
    ONBOARDING_DETAIL: "/onboardings/{onboardingId}",
    ONBOARDING_USER_DETAIL: "/onboardings/{onboardingId}/user-details",
    USER_ONBOARDING_QUESTION_DETAIL: "/onboardings/{onboardingId}/onboarding-questions",
    ONBOARDING_DOC_LIST: "/onboardings/{onboardingId}/onboarding-docs",
    ONBOARDING_DOC_DETAIL: "/onboardings/onboarding-docs/{onboardingDocId}",
    ONBOARDING_INTERVIEW_DETAIL: "/onboardings/{onboardingId}/onboarding-interviews",
    ONBOARDING_TUTOR_PREF_DETAIL: "/onboardings/{onboardingId}/onboarding-tutor-pref",
    ONBOARDING_TRIAL_COURSE_DETAIL: "/onboardings/{onboardingId}/onboarding-tutor-trial-course",
    GET_ONBOARDING_COMMENT_LIST: "/onboardings/comments/",
    ADD_ONBOARDING_COMMENT_DETAIL: "/onboardings/comments/"
}

export const onboardingRedux = {
    sliceName: "onboarding",
    onboardingList: {
        requestName: "getOnboardingList"
    },
    onboardingDetail: {
        requestName: "getOnboardingDetailByOnboardingId"
    },
    userOnboardingList: {
        requestName: "getUserOnboardingListByUserId"
    },
    onboardingUserDetail: {
        requestName: "updateOnboardingUserDetailByOnboardingId"
    },
    userOnboardingQuestionDetail: {
        requestName: "getUserOnboardingQuestionDetailByOnboardingId"
    },
    onboardingDocList: {
        requestName: "getOnboardingDocListByOnboardingId"
    },
    onboardingInterviewDetail: {
        requestName: "getOnboardingInterviewDetailByOnboardingId"
    },
    onboardingTutorPrefDetail: {
        requestName: "getOnboardingTutorPrefDetailByOnboardingId"
    },
    onboardingTutorTrialCourseDetail: {
        requestName: "getOnboardingTutorTrialCourseDetailByOnboardingId"
    },
    onboardingCommentList: {
        requestName: "getOnboardingCommentList"
    },
    onboardingCommentDetail: {
        requestName: "createOnboardingCommentDetail"
    },

    tutorOnboardingFilteredList: {
        requestName: "getTutorOnboardingFilteredList"
    },
    companyOnboardingFilteredList: {
        requestName: "getCompanyOnboardingFilteredList"
    }
}

export const filterName = {
    TUTOR_ONBOARDING: {
        value: "tutor_onboarding"
    },
    COMPANY_ONBOARDING: {
        value: "company_onboarding"
    }
}

export const onboardingTypeConst = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    COMPANY: {
        label: "Company",
        value: "company"
    }
}

export const onboardingActionRequired = {
    NA: {
        label: "NA",
        value: "na",
    },
    USER: {
        label: "USER",
        value: "user",
    },
    ADMIN: {
        label: "ADMIN",
        value: "admin",
    },
}

export const onboardingNoteAction = {
    ADD_NOTE: {
        label: "Add Note",
        value: "add_note"
    },
    UPDATE_NOTE: {
        label: "Update Note",
        value: "update_note"
    }
}

export const companyDocumentsCategory = {
    DIGITAL_SIGNATURE_CERTIFICATE: {
        label: "Digital Signature Certificate",
        value: "digital_signature_certificate"
    },
    DIRECTOR_IDENTIFICATION_NUMBER: {
        label: "Director Identification Number",
        value: "director_identification_number"
    },
    CERTIFICATION_OF_INCORPORATION: {
        label: "Certification of Incorporation",
        value: "certification_of_incorporation"
    },
    RENTAL_AGREEMENT_CERTIFICATE: {
        label: "Rental Agreement Certificate",
        value: "rental_agreement_certificate"
    }
}

export const tutorOnboardingStatus = {
    // NOT_SUBMITTED: {
    //     label: "Not Submitted",
    //     value: "not_submitted",
    //     lightColor: 'orange-100',
    //     darkColor: 'orange-500',
    // },
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    // SUBMITTED: {
    //     label: "Submitted",
    //     value: "submitted",
    //     lightColor: 'secondary-light',
    //     darkColor: 'secondary-main',
    // },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
    },
    APPROVED: {
        label: "Approved",
        value: "approved",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    // RE_UPLOAD: {
    //     label: "Re Upload",
    //     value: "re_upload",
    //     lightColor: 'pink-100',
    //     darkColor: 'pink-500',
    // },
    WAITING: {
        label: "Waiting",
        value: "waiting",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    BLOCKED: {
        label: "Blocked",
        value: "blocked",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
}

export const companyOnboardingStatus = {
    NOT_SUBMITTED: {
        label: "Not Submitted",
        value: "not_submitted",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        lightColor: 'secondary-light',
        darkColor: 'secondary-main',
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        lightColor: 'yellow-100',
        darkColor: 'yellow-500',
    },
    VERIFIED: {
        label: "Approved",
        value: "approved",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    RE_UPLOAD: {
        label: "Re Upload",
        value: "re_upload",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
    }
}

export const docType = {
    RESUME: {
        label: "Resume",
        value: "resume"
    },
    IDENTITY: {
        label: "Identity",
        value: "identity"
    },
    ACADEMIC: {
        label: "Academic",
        value: "academic"
    },
    CERTIFICATE: {
        label: "Certificate",
        value: "certificate"
    },
    WORK_EXP: {
        label: "Work Experience",
        value: "work_exp"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }

}

export const videoType = {
    INTRO_VIDEO: {
        label: "Intro Video",
        value: "intro_video"
    }
}

export const userDetailStatus = {
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    // UNDER_REVIEW: {
    //     label: "Under Review",
    //     value: "under_review",
    //     lightColor: "pink-100",
    //     darkColor: "pink-500"
    // },
    APPROVED: {
        label: "Approved",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const relevantQuestionStatus = {
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    // UNDER_REVIEW: {
    //     label: "Submitted",
    //     value: "under_review",
    //     lightColor: "pink-100",
    //     darkColor: "pink-500"
    // },
    APPROVED: {
        label: "Approved",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const childDocumentStatus = {
    PENDING: {
        label: "Pending",
        value: "pending",
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
    },
    APPROVED: {
        label: "Approved",
        value: "approved",
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
    },
}

export const documentStatus = {
    PENDING: {
        label: "Pending",
        value: "pending",
        textName: "Pending",
        textColor: "yellow-500",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        textName: "Submitted",
        textColor: "blue-500",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    // UNDER_REVIEW: {
    //     label: "Under Review",
    //     value: "under_review",
    //     textName: "Under Review",
    //     textColor: "pink-500",
    //     lightColor: "pink-100",
    //     darkColor: "pink-500"
    // },
    APPROVED: {
        label: "Approved",
        value: "approved",
        textName: "Approved",
        textColor: "green-500",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    // RE_UPLOAD: {
    //     label: "Re Upload",
    //     value: "re_upload",
    //     textName: "Upload Again",
    //     textColor: "orange-500",
    //     lightColor: "orange-100",
    //     darkColor: "orange-500"
    // },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        textName: "Rejected",
        textColor: "red-500",
        lightColor: "red-100",
        darkColor: "red-500"
    },
}

export const interviewCallStatus = {
    PENDING: {
        label: "Select a slot",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500",
    },
    SCHEDULED: {
        label: "Scheduled",
        value: "scheduled",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    APPROVED: {
        label: "Approved",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    },
    RESCHEDULED: {
        label: "Rescheduled",
        value: "rescheduled",
        lightColor: "sky-100",
        darkColor: "sky-500"
    },
    ON_GOING: {
        label: "On Going",
        value: "on_going",
        lightColor: "gray-100",
        darkColor: "gray-500"
    }
}

export const TutorPreferenceConst = {
    TUTOR_PROFILE: {
        label: "Tutor Profile",
        value: "isTutorProfileDone",
        btnName: "Update",
        link: `/edit-tutors/{userId}/edit/general`
    },
    AVAILABILITY: {
        label: "Availability",
        value: "isAvailabilityDone",
        btnName: "Update",
        link: `/edit-tutors/{userId}/edit/availability`
    },
    PRICING_PREFERENCE: {
        label: "Pricing Preference",
        value: "isPricePrefDone",
        btnName: "Update",
        link: `/edit-tutors/{userId}/edit/price`
    },
    LESSON_PREFERENCE: {
        label: "Lesson Preference",
        value: "isLessonPrefDone",
        btnName: "Update",
        link: `/edit-tutors/{userId}/edit/lesson`
    },
    SUBJECT_PREFERENCE: {
        label: "Subject Preference",
        value: "isSubjectPrefDone",
        btnName: "Update",
        link: `/edit-tutors/{userId}/edit/subject`
    }
}

export const tutorPrefStatus = {
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: 'submitted',
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    // UNDER_REVIEW: {
    //     label: "Under Review",
    //     value: "under_review",
    //     lightColor: "pink-100",
    //     darkColor: "pink-500"
    // },
    APPROVED: {
        label: "Approved",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const trialClassStatus = {
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    // UNDER_REVIEW: {
    //     label: "Under Review",
    //     value: "under_review",
    //     lightColor: "pink-100",
    //     darkColor: "pink-500"
    // },
    APPROVED: {
        label: "Approved",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const onBoardingStatus = {
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        lightColor: "pink-100",
        darkColor: "pink-500"
    },
    WAITING: {
        label: "Waiting",
        value: "waiting",
        lightColor: "orange-100",
        darkColor: "orange-500"
    },
    APPROVED: {
        label: "Approved",
        value: "approved",
        lightColor: "green-100",
        darkColor: "green-500"
    },
    REJECTED: {
        label: "Rejected",
        value: "rejected",
        lightColor: "red-100",
        darkColor: "red-500"
    },
    BLOCKED: {
        label: "Blocked",
        value: "blocked",
        lightColor: "gray-100",
        darkColor: "gray-500"
    }
}

export const onBoardingEventStatus = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    NOT_SUBMITTED: {
        label: "Not Submitted",
        value: "not_submitted",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    DECLINED: {
        label: "Declined",
        value: "declined",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    RESCHEDULED: {
        label: "Rescheduled",
        value: "rescheduled",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
    },
    SCHEDULED: {
        label: "Scheduled",
        value: "scheduled",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    ON_GOING: {
        label: "On Going",
        value: "on_going",
        lightColor: 'yellow-100',
        darkColor: 'yellow-500',
    },
    COMPLETED: {
        label: "Completed",
        value: "completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const tutorOnboardingFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    onboardingId: "",
    userId: "",
    userName: "",
    email: "",
    phone: "",
    onboardingType: onboardingTypeConst?.TUTOR?.value,
    status: "",
    actionRequired: "",
    date: ""
}