import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import FullPageLoader from "components/loader/FullPageLoader";


import { getOnboardingTutorPrefDetailByOnboardingId } from 'redux/onboarding/onboarding.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingTutorPrefDetail } from "redux/onboarding/onboarding.slice";
import { tutorPrefStatus } from "redux/onboarding/onboarding.const";
import { TutorPreferenceConst } from "redux/onboarding/onboarding.const";

const ViewTutorPreferences = () => {
    const { onboardingTutorPrefDetail, addOnboardingTutorPrefDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingTutorPrefDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingTutorPrefDetail())
        }
    }, [onboardingId])

    if (onboardingTutorPrefDetail?.isLoading || addOnboardingTutorPrefDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingTutorPrefDetail?.isLoading || addOnboardingTutorPrefDetail?.isLoading} />
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-5 py-3",
                !onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status && "bg-yellow-100",
                `bg-${tutorPrefStatus[onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Tutor Preferences"}
            </div>
            {onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status &&
                <div className="px-5 flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-3 md:flex-row md:items-center md:justify-start">
                    <span className="text-text-600">
                        {"Status:"}
                    </span>
                    <span className={cn(
                        "font-bodyPri font-normal text-base",
                        `text-${tutorPrefStatus[onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status?.toUpperCase()]?.darkColor}`
                    )}>
                        {tutorPrefStatus[onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status?.toUpperCase()]?.label}
                    </span>
                </div>
            }

            {onboardingTutorPrefDetail?.onboardingTutorPrefDetail && Object?.values(TutorPreferenceConst)?.map((preference) => (
                <div className={"w-full flex items-center justify-between gap-5 border border-text-300 rounded-lg px-5 py-3"}>
                    <div className={"flex justify-start items-center gap-5"}>
                        <span className={cn(
                            "w-4 h-4 rounded-full",
                            !onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && " border border-text-500 bg-text-50",
                            onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && "bg-green-500"
                        )}>
                            {""}
                        </span>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {preference?.label}
                        </span>
                    </div>
                    <div
                        className={cn(
                            "font-bodyPri font-normal text-base",
                            !onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && "text-yellow-500",
                            onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && "text-green-500"
                        )}
                    >
                        {onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value]
                            ? "Updated"
                            : "Pending"
                        }
                    </div>

                </div>
            ))}
            {(onboardingTutorPrefDetail?.errorMsg || addOnboardingTutorPrefDetail?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm text-center"}>
                    {onboardingTutorPrefDetail?.errorMsg || addOnboardingTutorPrefDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default ViewTutorPreferences;