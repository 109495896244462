import { productFilterProps } from "./product.const";

export const PRODUCT_INITIAL_STATE = {
    productList: {
        isLoading: false,
        data: null,
        message: null
    },

    // destroy product detail
    destroyProductDetail: {
        isLoading: false,
        message: null
    },
    
    filterProps: productFilterProps
}