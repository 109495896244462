import { createAsyncThunk } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import lmsCourseService from 'redux/edulyteLms/lmsCourse/lmsCourse.service';

import {
    setLmsCourseListLoading,
    setLmsCourseListData,
    setLmsCourseListMessage,

    setLmsCourseDetailLoading,
    setLmsCourseDetailData,
    setLmsCourseDetailMessage,

    setModifyLmsCourseDetailLoading,
    setModifyLmsCourseDetailMessage,

    setDestroyLmsCourseDetailLoading,
    setDestroyLmsCourseDetailMessage,
} from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { lmsCourseRedux } from './lmsCourse.const';


export const getLmsCourseList = createAsyncThunk(
    `${lmsCourseRedux?.sliceName}/${lmsCourseRedux?.lmsCourseList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setLmsCourseListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await lmsCourseService.getLmsCourseList(requestData)
            if (response.status === 200) {
                dispatch(setLmsCourseListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || 'Something went wrong.')
            dispatch(setLmsCourseListMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
        }
        finally {
            dispatch(setLmsCourseListLoading(false))
        }
    }
)

export const getLmsCourseDetail = createAsyncThunk(
    `${lmsCourseRedux?.sliceName}/${lmsCourseRedux?.lmsCourseDetail?.requestName}`,
    async (courseId, { dispatch }) => {
        dispatch(setLmsCourseDetailLoading(true))

        try {
            const requestData = {
                params: { courseId: courseId }
            }
            const response = await lmsCourseService.getTutorLmsCourseDetail(requestData)
            if (response.status === 200) {
                dispatch(setLmsCourseDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong.")
            dispatch(setLmsCourseDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong."))
        } finally {
            dispatch(setLmsCourseDetailLoading(false))
        }
    }
)

export const updateLmsCourseDetail = createAsyncThunk(
    `${lmsCourseRedux?.sliceName}/${lmsCourseRedux?.lmsCourseDetail?.requestName}`,
    async (payload, { dispatch }) => {
        dispatch(setModifyLmsCourseDetailLoading(true))

        try {

            const requestData = {
                params: { courseId: payload?.courseId },
                body: payload?.body
            }
            const response = await lmsCourseService.updateLmsCourseDetail(requestData)
            if (response.status === 200) {
                dispatch(setLmsCourseDetailData(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong.")
            dispatch(setModifyLmsCourseDetailMessage(error.response.data.message || error.response.data.error || 'Something went wrong.'))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong.")
        } finally {
            dispatch(setModifyLmsCourseDetailLoading(false))
        }
    }
)

export const deleteLmsCourseDetail = createAsyncThunk(
    `${lmsCourseRedux?.sliceName}/${lmsCourseRedux?.lmsCourseDetail?.requestName}`,
    async (courseId, { dispatch }) => {
        dispatch(setDestroyLmsCourseDetailLoading(false))

        try {
            const requestData = {
                params: { courseId: courseId }
            }
            const response = await lmsCourseService.deleteLmsCourseDetail(requestData)
            if (response.status === 204) {
                dispatch(getLmsCourseList({ page: 1, records: 25 }))
                toast.success(response.data.message || "Deleted Successfully")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setDestroyLmsCourseDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setDestroyLmsCourseDetailLoading(false))
        }
    }
)