import {
    DashboardCustomize,
    EventNoteTwoTone,
    AccountBalanceWallet,
    Message,
    People,
    LocalOffer,
    School,
    Subscriptions,
    Settings,
} from '@mui/icons-material';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';

import { userRoles } from 'redux/auth/auth.const';

export const HamburgerMenuItems = [
    {
        icon: AppsOutlinedIcon,
        name: 'Switch To App',
        value: 'switch_to_app',
        path: '/switch-to-app',
        subRoutes: [],
        pathType: 'href',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: []
    },
    {
        icon: DashboardCustomize,
        name: 'Dashboard',
        value: 'dashboard',
        path: '/dashboard',
        subRoutes: [],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: []
    },
    {
        icon: Message,
        name: 'Live Chat',
        value: 'live_chat',
        path: '/message',
        subRoutes: [],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: []
    },
    {
        icon: EventNoteTwoTone,
        name: 'Schedule',
        value: 'schedule',
        path: '/schedule',
        subRoutes: [ 'sessions'],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: [
            {
                icon: "",
                name: 'Sessions',
                value: 'sessions',
                path: '/sessions',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
            },
        ]
    },
    {
        icon: People,
        name: 'CRM',
        value: 'crm',
        path: '/crm',
        subRoutes: ['leads', 'onboarding'],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: [
            {
                icon: "",
                name: 'Leads',
                value: 'leads',
                path: '/leads',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
            },
            {
                icon: "",
                name: 'Onboarding',
                value: 'onboarding',
                path: '/onboarding',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
            },
        ]
    },
    {
        icon: People,
        name: 'Profiles',
        value: 'profiles',
        path: '/profiles',
        subRoutes: ['users', 'tutors'],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: [
            {
                icon: "",
                name: 'Users',
                value: 'users',
                path: '/users',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
            },
            {
                icon: "",
                name: 'Tutors',
                value: 'tutors',
                path: '/tutors',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
            },
        ]
    },
    {
        icon: LocalOffer,
        name: 'Products',
        value: 'products',
        path: '/products',
        subRoutes: ["product", "lms-courses", "lms-quizzes", 'classes', "appointments", 'buy-gift-card'],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: [
            {
                icon: "",
                name: 'Product',
                value: 'product',
                path: '/product',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Courses',
                value: 'lms-courses',
                path: '/lms-courses',
                pathType: 'to',
                roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
            },
            {
                icon: "",
                name: 'Classes',
                value: 'classes',
                path: '/classes',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Appointments',
                value: 'appointments',
                path: '/appointments',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Quizzes',
                value: 'lms-quizzes',
                path: '/lms-quizzes',
                pathType: 'to',
                roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
            },
            {
                icon: "",
                name: 'Buy Gift Card',
                value: 'buy_gift_card',
                path: '/buy-gift-card',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
        ]
    },
    {
        icon: AccountBalanceWallet,
        name: 'Finance',
        value: 'finance',
        path: '/finance',
        subRoutes: ['wallet-txns', 'withdrawals', 'bookings', 'orders', 'subscriptions'],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: [
            {
                icon: "",
                name: 'Wallet Txn',
                value: 'wallet_txn',
                path: '/wallet-txns',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Withdrawals',
                value: 'withdrawals',
                path: '/withdrawals',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Booking',
                value: 'booking',
                path: '/bookings',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Orders',
                value: 'orders',
                path: '/orders',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Subscriptions',
                value: 'subscriptions',
                path: '/subscriptions',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
        ]
    },
    {
        icon: School,
        name: 'Marketing',
        value: 'marketing',
        path: '/marketing',
        subRoutes: ['referral'],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: [
            {
                icon: "",
                name: 'Referral',
                value: 'referral',
                path: '/referral',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Marketing Services',
                value: 'marketing-services',
                path: 'https://www.edulyte.com/exclusive-marketing-solutions-for-educators',
                pathType: 'href',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
        ]
    },
    {
        icon: Subscriptions,
        name: 'Resources',
        value: 'resources',
        path: '/resources',
        subRoutes: ["lms-questions", "lms-articles", "lms-lectures", "tools"],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: [
            {
                icon: "",
                name: 'Questions',
                value: 'lms-questions',
                path: '/lms-questions',
                pathType: 'to',
                roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
            },
            {
                icon: "",
                name: 'Articles',
                value: 'lms-articles',
                path: '/lms-articles',
                pathType: 'to',
                roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
            },
            {
                icon: "",
                name: 'Video Lectures',
                value: 'lms-lectures',
                path: '/lms-lectures',
                pathType: 'to',
                roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
            },
            {
                icon: "",
                name: 'Tools',
                value: 'tools',
                path: '/tools',
                pathType: 'to',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'English Teaching',
                value: 'english-teaching',
                path: 'https://www.edulyte.com/english-teaching-resources/',
                pathType: 'href',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
            {
                icon: "",
                name: 'Knowledge Base',
                value: 'knowledge-base',
                path: 'https://support.edulyte.com/portal/en/kb/student-helpdesk',
                pathType: 'href',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            },
        ]
    },
    {
        icon: Settings,
        name: 'Settings',
        value: 'settings',
        path: '/settings',
        subRoutes: ["platform-settings"],
        pathType: 'to',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
        subMenu: [
            {
                icon: "",
                name: 'Platform Settings',
                value: 'platform-settings',
                path: '/platform-settings',
                roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value],
            }
        ]
    }
]