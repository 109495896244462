import { useNavigate, useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { BsCheckAll } from "react-icons/bs";

import { OptionSelector } from "components/common-components/Select";

import { updateCourseDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";


export default function CourseNavigationOptions({
    options,
}) {
    const { courseDetail, addCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const params = useParams();
    const pathParam = params["*"];
    const selectOptions = options?.map((option) => ({
        label: option.label,
        value: option.to,
        roles: option.roles
    }));
    const navigate = useNavigate();

    const onHandleNavigation = (option) => {
        if (addCourseDetail?.isLoading) return;

        // if (courseTagList?.courseTagList?.results?.length < 3) {
        //     alert("Please add at least 3 skills!")
        //     return;
        // }
        if (option?.stepNo === 4 && !courseDetail?.courseDetail?.courseAvailability?.id) {
            alert("Please submit your class schedule!")
            return;
        }

        if (option?.stepNo === 5 && !courseDetail?.courseDetail?.coursePrice) {
            alert("Please submit your class price!")
            return;
        }

        // setStepCount(option?.stepNo)
        navigate(`.${option.to}`)
        if (option?.stepNo >= 6) return;

        if (courseDetail?.courseDetail?.step < option?.stepNo) {
            const requestDataPayload = {
                id: courseDetail?.courseDetail?.id,
                body: {
                    step: courseDetail?.courseDetail?.step + 1
                }
            }
            dispatcher(updateCourseDetail(requestDataPayload))
            return;
        }
    }

    return (
        <div className="w-full h-fit p-5 bg-white rounded-lg shadow-sm md:w-60">
            <div className="md:hidden">
                <OptionSelector
                    options={selectOptions}
                    onChange={(op) => {
                        navigate(`.${op.value}`);
                    }}
                    value={`/${pathParam}`}
                />
            </div>

            <div className="w-full flex-col hidden gap-3 md:flex">
                {options.map((option, index) => (
                    <div
                        key={index}
                        className={cn(
                            "w-full flex items-center justify-center px-3 py-2 font-bodyPri font-normal text-base text-center rounded-md cursor-pointer",
                            pathParam === option.to.slice(1)
                                ? "bg-primary-dark text-text-50 hover:bg-primary-dark hover:text-text-50"
                                : "text-text-900 hover:bg-primary-light hover:text-primary-dark group"
                        )}
                        onClick={() => onHandleNavigation(option)}
                    >
                        <div className={"w-full flex items-center justify-start gap-2"}>
                            {(courseDetail?.courseDetail?.step <= option?.stepNo) &&
                                <span className={cn(
                                    "px-2.5 py-0.5 border-2 rounded-full",
                                    "font-bodyPri font-medium text-lg",
                                    pathParam === option.to.slice(1)
                                        ? "border-text-50 group-hover:border-primary-dark"
                                        : "border-text-500 group-hover:border-primary-dark"
                                )}>
                                    {`${option?.stepNo}`}
                                </span>
                            }
                            {(courseDetail?.courseDetail?.step > option?.stepNo) &&
                                <div className={"flex items-center justify-start gap-1"}>
                                    <BsCheckAll className={"text-green-600 text-2xl"} />
                                    {/* <span className={"font-bodyPri font-normal text-sm text-green-600"}>
                                        {"Done"}
                                    </span> */}
                                </div>
                            }
                            <span className={"font-bodyPri font-normal text-base text-center"}>
                                {option.label}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
