import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';

import { manualPaymentIntentStripe } from 'redux/payment/payment.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetManualStripePayment, setManualStripePaymentPayload } from "redux/payment/payment.slice";
import { modalConst } from 'redux/local/local.const';

const ManualPaymentDetailModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { manualStripePayment } = useAppState((state) => state.payment)

    const dispatcher = useAppDispatcher()

    const isFormValid = manualStripePayment?.payload?.paymentIntentId

    useEffect(() => {
        return () => {
            dispatcher(resetManualStripePayment())
        }
    }, [])

    useEffect(() => {
        if (manualStripePayment?.data) {
            dispatcher(resetManualStripePayment())
            dispatcher(setModal({
                ...modal,
                [modalConst.MANUAL_PAYMENT_DETAIL_MODAL.stateKey]: false
            }))
        }
    }, [manualStripePayment?.data])

    const onHandleManualPaymentForm = (event) => {
        event.preventDefault();
        if (!isFormValid) return;

        const body = {
            paymentIntentId: manualStripePayment?.payload?.paymentIntentId
        }
        dispatcher(manualPaymentIntentStripe(body))
    }

    return (
        <form onSubmit={onHandleManualPaymentForm} className={"space-y-5 overflow-hidden"}>
            <div className={"min-h-[12rem] block w-full border border-text-400 space-y-3"}>
                <div className={"space-y-1"}>
                    <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                        {"Payment Detail"}
                    </div>
                    <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
                        {"(Marked with * is mandatory field)"}
                    </span>
                </div>

                <div className={"grid grid-cols-2 gap-5 px-2"}>
                    {/* payment intent id container */}
                    <div className={'col-start-1 col-span-full'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Payment Intent ID"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            placeholder={"e.g. pi_3MzFW2D3hA6KTc2N2ZfYMOjO"}
                            value={manualStripePayment?.payload?.paymentIntentId}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                            )}
                            onChange={(event) => dispatcher(setManualStripePaymentPayload({
                                ...manualStripePayment?.payload,
                                paymentIntentId: event?.target?.value
                            }))}
                            required
                        />
                    </div>
                </div>
            </div>
            {manualStripePayment?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {manualStripePayment?.message}
                    </span>
                </div>
            }
            <div className={"flex justify-end px-5"}>
                <button
                    type={"submit"}
                    disabled={!isFormValid}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        (!isFormValid) && "bg-text-300 text-white cursor-not-allowed",
                        (!manualStripePayment?.isLoading && isFormValid) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        (manualStripePayment?.isLoading && isFormValid) && "bg-secondary-main"
                    )}>
                    {manualStripePayment?.isLoading
                        && <ButtonLoader isLoading={manualStripePayment?.isLoading} />
                    }
                    {!manualStripePayment?.isLoading && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default ManualPaymentDetailModal;

// const ManualPaymentDetailModal = () => {
//     const { modal } = useAppState((state) => state.local)
//     const { userDetail } = useAppState((state) => state.user)
//     const { bookingDetail } = useAppState((state) => state.booking)
//     const { manualStripePayment } = useAppState((state) => state.payment)

//     const dispatcher = useAppDispatcher()

//     const [isUserVerified, setIsUserVerified] = useState(false)
//     const [isBookingVerified, setIsBookingVerified] = useState(false)

//     const isPaymentTypeBooking = [manualStripePaymentTypeEnum?.BOOKING?.value]?.includes(manualStripePayment?.payload?.paymentType)
//     const isFormValid = manualStripePayment?.payload?.userId && manualStripePayment?.payload?.amount && manualStripePayment?.payload?.paymentIntentId && manualStripePayment?.payload?.paymentType

//     useEffect(() => {

//         return () => {
//             dispatcher(setClearUserDetail())
//             dispatcher(resetBookingDetail())
//             dispatcher(resetManualStripePayment())
//         }
//     }, [])

//     useEffect(() => {
//         if (manualStripePayment?.data) {
//             dispatcher(resetManualStripePayment())
//             dispatcher(setModal({
//                 ...modal,
//                 [modalConst.MANUAL_PAYMENT_DETAIL_MODAL.stateKey]: false
//             }))
//         }
//     }, [manualStripePayment?.data])

//     useEffect(() => {
//         if (userDetail?.userDetail) {
//             setIsUserVerified(true)
//         }
//     }, [userDetail?.userDetail])

//     useEffect(() => {
//         if (bookingDetail?.data) {
//             setIsBookingVerified(true)
//         }
//     }, [bookingDetail?.data])

//     const onHandleVerifyUserId = () => {
//         if (userDetail?.isLoading || manualStripePayment?.isLoading) return;

//         if (!manualStripePayment?.payload?.userId) {
//             alert("Please enter user ID")
//             return;
//         }
//         dispatcher(getUserDetailByUserId(manualStripePayment?.payload?.userId))
//     }

//     const onHandleVerifyBookingId = () => {
//         if (bookingDetail?.isLoading || manualStripePayment?.isLoading) return;

//         if (!manualStripePayment?.payload?.bookingId) {
//             alert("Please enter booking ID")
//             return;
//         }
//         const requestDataPayload = {
//             bookingId: manualStripePayment?.payload?.bookingId
//         }
//         dispatcher(getBookingDetail(requestDataPayload))
//     }

//     const onHandleManualPaymentForm = (event) => {
//         event.preventDefault();

//         if (!isFormValid) return;

//         if (!manualStripePayment?.payload?.userId) {
//             alert("Please enter user ID!")
//             return;
//         }
//         if (!isUserVerified) {
//             alert("Please verify user ID!")
//             return;
//         }
//         if (!manualStripePayment?.payload?.amount) {
//             alert("Please enter valid amount!")
//             return;
//         }
//         if (!manualStripePayment?.payload?.paymentIntentId) {
//             alert("Please enter payment ID!")
//             return;
//         }
//         if (!manualStripePayment?.payload?.paymentType) {
//             alert("Please select payment type!")
//             return;
//         }
//         if (isPaymentTypeBooking) {
//             if (!manualStripePayment?.payload?.bookingId) {
//                 alert("Please enter booking ID!")
//                 return;
//             }
//             if (!isBookingVerified) {
//                 alert("Please verify booking ID!")
//                 return;
//             }
//         }

//         const body = {
//             userId: manualStripePayment?.payload?.userId,
//             paymentType: manualStripePayment?.payload?.paymentType,
//             amount: manualStripePayment?.payload?.amount,
//             paymentIntentId: manualStripePayment?.payload?.paymentIntentId
//         }
//         if (isPaymentTypeBooking) {
//             body["bookingId"] = manualStripePayment?.payload?.bookingId
//         }
//         dispatcher(manualPaymentIntentStripe(body))
//     }

//     return (
//         <form onSubmit={onHandleManualPaymentForm} className={"space-y-5 overflow-hidden"}>
//             <div className={"min-h-[25rem] block w-full border border-text-400 space-y-3 pb-2"}>
//                 <div className={"space-y-1"}>
//                     <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
//                         {"Payment Detail"}
//                     </div>
//                     <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
//                         {"(Marked with * is mandatory field)"}
//                     </span>
//                 </div>

//                 <div className={"grid grid-cols-2 gap-5 px-2"}>

//                     {/* user id container */}
//                     <div className={'col-start-1 col-span-full sm:col-start-1 sm:col-span-1'}>
//                         <span className='font-bodyPri font-normal text-text-900 text-sm'>
//                             {"User ID"}
//                             <span className="text-red-500 text-lg">{"*"}
//                             </span>
//                         </span>
//                         <div className={cn(
//                             "w-full px-3 py-1 border-2 border-divider-medium rounded-md flex items-center justify-between gap-3",
//                             "font-bodyPri font-normal text-text-800 text-base",
//                         )}>
//                             <input
//                                 type={"number"}
//                                 placeholder={"e.g. 123"}
//                                 value={manualStripePayment?.payload?.userId?.toString()}
//                                 className={cn(
//                                     "w-full px-1.5 input-number-spin-none focus:outline-none",
//                                     "font-bodyPri font-normal text-text-800 text-base",
//                                     "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
//                                 )}
//                                 onChange={(e) => {
//                                     if (isUserVerified) {
//                                         setIsUserVerified(false)
//                                     }
//                                     dispatcher(setManualStripePaymentPayload({
//                                         ...manualStripePayment?.payload,
//                                         userId: Number(e.target.value),
//                                     }))
//                                 }}
//                                 required
//                             />
//                             {userDetail?.isLoading &&
//                                 <FaSpinner className={"animate-spin text-primary-dark text-base"} />
//                             }
//                             {(!userDetail?.isLoading && isUserVerified) &&
//                                 <span className={"px-1 font-bodyPri font-normal text-xs text-green-500"}>
//                                     {"Verified"}
//                                 </span>
//                             }
//                             {(!userDetail?.isLoading && !isUserVerified) &&
//                                 <span
//                                     className={cn(
//                                         "px-1 py-0.5 border border-secondary-dark hover:bg-secondary-dark hover:text-text-50 rounded-md",
//                                         "font-bodyPri font-normal text-xs text-secondary-dark cursor-pointer"
//                                     )}
//                                     onClick={onHandleVerifyUserId}
//                                 >
//                                     {"Verify"}
//                                 </span>
//                             }
//                         </div>
//                         {(!userDetail?.isLoading && isUserVerified) &&
//                             <div className={"w-full border border-divider-medium shadow-lg rounded-lg px-3 mt-1.5 py-2 flex items-start justify-start gap-3"}>
//                                 <div className={"relative w-10 h-10 overflow-hidden"}>
//                                     <img
//                                         src={userDetail?.userDetail?.profilePicUrl}
//                                         alt={"tutor-profile-pic"}
//                                         className={"w-full h-full rounded-full"}
//                                     />
//                                 </div>
//                                 <div className={"flex flex-col items-start justify-start gap-1"}>
//                                     <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
//                                         {`${userDetail?.userDetail?.firstName} ${userDetail?.userDetail?.lastName?.charAt(0)}.`}
//                                     </span>
//                                     <span className={"max-w-[16rem] font-bodyPri font-normal text-text-800 text-sm tracking-wide truncate"}>
//                                         {userDetail?.userDetail?.email}
//                                     </span>
//                                 </div>
//                             </div>
//                         }
//                         {userDetail?.errorMsg &&
//                             <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
//                                 {"Invalid User ID"}
//                             </span>
//                         }
//                     </div>

//                     {/* amount container */}
//                     <div className={'col-start-1 col-span-full sm:col-start-2 sm:col-span-1'}>
//                         <span className='font-bodyPri font-normal text-text-900 text-sm'>
//                             {"Amount"}
//                             <span className="text-red-500 text-lg">{"*"}
//                             </span>
//                         </span>
//                         <div className={cn(
//                             "w-full px-3 py-1 border-2 border-divider-medium rounded-md flex items-center justify-between gap-3",
//                             "font-bodyPri font-normal text-text-800 text-base",
//                         )}>
//                             <span className={""}>
//                                 {"USD"}
//                             </span>
//                             <input
//                                 type={"number"}
//                                 placeholder={"e.g. 123"}
//                                 value={parseFloat(manualStripePayment?.payload?.amount / 100)?.toString()}
//                                 className={cn(
//                                     "w-full px-1.5 input-number-spin-none focus:outline-none",
//                                     "font-bodyPri font-normal text-text-800 text-base",
//                                     "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
//                                 )}
//                                 onChange={(event) => dispatcher(setManualStripePaymentPayload({
//                                     ...manualStripePayment?.payload,
//                                     amount: Number(event?.target?.value * 100)
//                                 }))}
//                                 required
//                             />
//                         </div>
//                     </div>

//                     {/* payment intent id container */}
//                     <div className={'col-start-1 col-span-full'}>
//                         <span className='font-bodyPri font-normal text-text-900 text-sm'>
//                             {"Payment Intent ID"}
//                             <span className="text-red-500 text-lg">{"*"}
//                             </span>
//                         </span>
//                         <input
//                             type={"text"}
//                             placeholder={"e.g. pi_3MzFW2D3hA6KTc2N2ZfYMOjO"}
//                             value={manualStripePayment?.payload?.paymentIntentId}
//                             className={cn(
//                                 "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
//                                 "font-bodyPri font-normal text-text-800 text-base",
//                                 "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
//                             )}
//                             onChange={(event) => dispatcher(setManualStripePaymentPayload({
//                                 ...manualStripePayment?.payload,
//                                 paymentIntentId: event?.target?.value
//                             }))}
//                             required
//                         />
//                     </div>

//                     {/* payment type container */}
//                     <div className={'col-start-1 col-span-full sm:col-start-1 sm:col-span-1'}>
//                         <span className='font-bodyPri font-normal text-text-900 text-sm'>
//                             {"Payment Type"}
//                             <span className="text-red-500 text-lg">{"*"}
//                             </span>
//                         </span>
//                         <OptionSelector
//                             options={Object.values(manualStripePaymentTypeEnum)}
//                             className={"w-full"}
//                             value={manualStripePayment?.payload?.paymentType}
//                             onChange={(option) => dispatcher(setManualStripePaymentPayload({
//                                 ...manualStripePayment?.payload,
//                                 paymentType: option?.value
//                             }))}
//                         />
//                     </div>

//                     {/* booking id container */}
//                     {isPaymentTypeBooking &&
//                         <div className={'col-start-1 col-span-full sm:col-start-2 sm:col-span-1'}>
//                             <span className='font-bodyPri font-normal text-text-900 text-sm'>
//                                 {"Booking ID"}
//                                 <span className="text-red-500 text-lg">{"*"}
//                                 </span>
//                             </span>
//                             <div className={cn(
//                                 "w-full px-3 py-1 border-2 border-divider-medium rounded-md flex items-center justify-between gap-3",
//                                 "font-bodyPri font-normal text-text-800 text-base",
//                             )}>
//                                 <input
//                                     type={"number"}
//                                     placeholder={"e.g. 123"}
//                                     value={manualStripePayment?.payload?.bookingId?.toString()}
//                                     className={cn(
//                                         "w-full px-1.5 input-number-spin-none focus:outline-none",
//                                         "font-bodyPri font-normal text-text-800 text-base",
//                                         "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
//                                     )}
//                                     onChange={(e) => {
//                                         if (isBookingVerified) {
//                                             setIsBookingVerified(false)
//                                         }
//                                         dispatcher(setManualStripePaymentPayload({
//                                             ...manualStripePayment?.payload,
//                                             bookingId: Number(e.target.value),
//                                         }))
//                                     }}
//                                     required
//                                 />
//                                 {bookingDetail?.isLoading &&
//                                     <FaSpinner className={"animate-spin text-primary-dark text-base"} />
//                                 }
//                                 {(!bookingDetail?.isLoading && isBookingVerified) &&
//                                     <span className={"px-1 font-bodyPri font-normal text-xs text-green-500"}>
//                                         {"Verified"}
//                                     </span>
//                                 }
//                                 {(!bookingDetail?.isLoading && !isBookingVerified) &&
//                                     <span
//                                         className={cn(
//                                             "px-1 py-0.5 border border-secondary-dark hover:bg-secondary-dark hover:text-text-50 rounded-md",
//                                             "font-bodyPri font-normal text-xs text-secondary-dark cursor-pointer"
//                                         )}
//                                         onClick={onHandleVerifyBookingId}
//                                     >
//                                         {"Verify"}
//                                     </span>
//                                 }
//                             </div>
//                             {(!bookingDetail?.isLoading && isBookingVerified) &&
//                                 <div className={"w-full border border-divider-medium shadow-lg rounded-lg px-3 mt-1.5 py-2 flex items-start justify-start gap-1"}>
//                                     <div className={"w-full flex items-center justify-start gap-1"}>
//                                         <div className={"relative w-10 h-10 overflow-hidden"}>
//                                             <img
//                                                 src={bookingDetail?.data?.user?.profilePicUrl}
//                                                 alt={"tutor-profile-pic"}
//                                                 className={"w-full h-full rounded-full"}
//                                             />
//                                         </div>

//                                     </div>
//                                     <div className={"flex flex-col items-start justify-start gap-1"}>
//                                         <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
//                                             {`${bookingDetail?.data?.user?.firstName} ${bookingDetail?.data?.user?.lastName?.charAt(0)}.`}
//                                         </span>
//                                         <div className={"flex items-center justify-start gap-1"}>
//                                             <span className={"font-bodyPri font-normal text-text-600 text-sm"}>
//                                                 {"Status: "}
//                                             </span>
//                                             <span className={cn(
//                                                 `max-w-[16rem] font-bodyPri font-normal text-sm truncate`,
//                                                 `text-${bookingStatusEnum[bookingDetail?.data?.status?.toUpperCase()]?.darkColor}`
//                                             )}>
//                                                 {bookingStatusEnum[bookingDetail?.data?.status?.toUpperCase()]?.label}
//                                             </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             }
//                             {bookingDetail?.message &&
//                                 <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
//                                     {"Invalid Booking ID"}
//                                 </span>
//                             }
//                         </div>
//                     }

//                 </div>
//             </div>
//             {manualStripePayment?.message &&
//                 <div className={"w-full flex items-center justify-center"}>
//                     <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
//                         {manualStripePayment?.message}
//                     </span>
//                 </div>
//             }
//             <div className={"flex justify-end px-5"}>
//                 <button
//                     type={"submit"}
//                     disabled={!isFormValid}
//                     className={cn(
//                         "w-28 py-1 flex justify-center items-center rounded-full",
//                         "font-buttons font-normal text-base",
//                         (!isFormValid || !isUserVerified || (isPaymentTypeBooking && !isBookingVerified)) && "bg-text-300 text-white cursor-not-allowed",
//                         (!manualStripePayment?.isLoading && isFormValid && isUserVerified && isPaymentTypeBooking && isBookingVerified) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
//                         (manualStripePayment?.isLoading && isFormValid && isUserVerified && isBookingVerified) && "bg-secondary-main"
//                     )}>
//                     {manualStripePayment?.isLoading
//                         && <ButtonLoader isLoading={manualStripePayment?.isLoading} />
//                     }
//                     {!manualStripePayment?.isLoading && "Submit"}
//                 </button>
//             </div>
//         </form>
//     )
// }

// export default ManualPaymentDetailModal