import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import WalletTxnSearch from 'pages/auth/walletTxn/components/WalletTxnSearch';
import { pageHeading } from "pages/auth/wallet/data";
import DisplayWalletTxnTable from 'pages/auth/walletTxn/components/DisplayWalletTxnTable';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const WalletPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.WALLET_TXNS))
  }, [dispatcher, currentPageInfo])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
      <WalletTxnSearch />
      <DisplayWalletTxnTable />
    </div>
  )
};

export default WalletPage;
