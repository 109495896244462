import { createAsyncThunk } from "@reduxjs/toolkit";

import notificationService from "redux/notification/notification.service";

import { notificationRedux } from "redux/notification/notification.const";

export const createSendOtp = createAsyncThunk(
    `${notificationRedux?.sliceName}/${notificationRedux?.sendOtp?.requestName}`,
    async (body, { rejectWithValue }) => {

        try {
            const requestData = {
                body: body
            }
            const response = await notificationService?.createSendOtp(requestData)
            if (response?.status === 200) {
                return response.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const createVerifyOtp = createAsyncThunk(
    `${notificationRedux?.sliceName}/${notificationRedux?.verifyOtp?.requestName}`,
    async (body, { rejectWithValue }) => {
        try {
            const requestData = {
                body: body
            }
            const response = await notificationService.createVerifyOtp(requestData)
            if (response.status === 200) {
                return response.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)