import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';

import { getToken } from 'redux/auth/auth.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

const PersistLoginRouter = () => {
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [persist] = useState(JSON.parse(localStorage.getItem("persist")) || true)

    useEffect(() => {
        if (!session?.isLoggedIn && persist) {
            dispatcher(getToken())
        }
    }, [])

    return (
        <>
            {!persist
                ? <Outlet />
                : session?.isLoading
                    ? <FullPageLoader isLoading={true} />
                    : <Outlet />
            }
        </>
    )
}

export default PersistLoginRouter;