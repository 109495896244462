import { createSlice } from "@reduxjs/toolkit";

import { MASTER_INITIAL_STATE } from "redux/master/master.initialState";

import {
    getMasterCountryList,
    getMasterStateList,
    getMasterCityList,
    getMasterLanguageList,
    getMasterProficiencyList,
    getMasterSocialList
} from "redux/master/master.request";

const master = createSlice({
    name: "master",
    initialState: MASTER_INITIAL_STATE,
    reducers: {
        // reducers for country list
        setClearCountryList: (state) => {
            state.countryList = MASTER_INITIAL_STATE.countryList
        },

        // reducers for country Detail
        setCountryDetailLoading: (state, { payload }) => {
            state.countryDetail.isLoading = payload
        },
        setCountryDetail: (state, { payload }) => {
            state.countryDetail.countryDetail = payload
            state.countryDetail.errorMsg = MASTER_INITIAL_STATE.countryDetail.errorMsg
        },
        setCountryDetailErrorMsg: (state, { payload }) => {
            state.countryDetail.errorMessage = payload
            state.countryDetail.countryDetail = MASTER_INITIAL_STATE.countryDetail.countryDetail
        },
        setClearCountryDetail: (state) => {
            state.countryDetail = MASTER_INITIAL_STATE.countryDetail
        },

        // reducers for timeZone country Detail
        setTimeZoneCountryDetailLoading: (state, { payload }) => {
            state.timeZoneCountryDetail.isLoading = payload
        },
        setTimeZoneCountryDetail: (state, { payload }) => {
            state.timeZoneCountryDetail.timeZoneCountryDetail = payload
            state.timeZoneCountryDetail.errorMsg = MASTER_INITIAL_STATE.timeZoneCountryDetail.errorMsg
        },
        setTimeZoneCountryDetailErrorMsg: (state, { payload }) => {
            state.timeZoneCountryDetail.errorMessage = payload
            state.timeZoneCountryDetail.timeZoneCountryDetail = MASTER_INITIAL_STATE.timeZoneCountryDetail.timeZoneCountryDetail
        },
        setClearTimeZoneCountryDetail: (state) => {
            state.timeZoneCountryDetail = MASTER_INITIAL_STATE.timeZoneCountryDetail
        },

        // reducers for state list
        setClearStateList: (state) => {
            state.stateList = MASTER_INITIAL_STATE.stateList
        },

        // reducers for city list
        setClearCityList: (state) => {
            state.cityList = MASTER_INITIAL_STATE.cityList
        },

        // reducers for currency list
        setCurrencyListLoading: (state, { payload }) => {
            state.currencyList.isLoading = payload
        },
        setCurrencyList: (state, { payload }) => {
            state.currencyList.currencyList = payload
            state.currencyList.errorMsg = MASTER_INITIAL_STATE.currencyList.errorMsg
        },
        setCurrencyListErrorMsg: (state, { payload }) => {
            state.currencyList.errorMsg = payload
            state.currencyList.currencyList = MASTER_INITIAL_STATE.currencyList.currencyList
        },
        setClearCurrencyList: (state) => {
            state.currencyList = MASTER_INITIAL_STATE.currencyList
        },

        // reducers for currency rate list
        setCurrencyRateListLoading: (state, { payload }) => {
            state.currencyRateList.isLoading = payload
        },
        setCurrencyRateList: (state, { payload }) => {
            state.currencyRateList.currencyRateList = payload
            state.currencyRateList.errorMsg = MASTER_INITIAL_STATE.currencyRateList.errorMsg
        },
        setCurrencyRateListErrorMsg: (state, { payload }) => {
            state.currencyRateList.errorMsg = payload
            state.currencyRateList.currencyRateList = MASTER_INITIAL_STATE.currencyRateList.currencyRateList
        },
        setClearCurrencyRateList: (state) => {
            state.currencyRateList = MASTER_INITIAL_STATE.currencyRateList
        },

        // reducers for timeZone list
        setTimeZoneListLoading: (state, { payload }) => {
            state.timeZoneList.isLoading = payload
        },
        setTimeZoneList: (state, { payload }) => {
            state.timeZoneList.timeZoneList = payload
            state.timeZoneList.errorMsg = MASTER_INITIAL_STATE.timeZoneList.errorMsg
        },
        setTimeZoneListErrorMsg: (state, { payload }) => {
            state.timeZoneList.errorMsg = payload
            state.timeZoneList.timeZoneList = MASTER_INITIAL_STATE.timeZoneList.timeZoneList
        },
        setClearTimeZoneList: (state) => {
            state.timeZoneList = MASTER_INITIAL_STATE.timeZoneList
        },

        // reducers for language list
        setClearLanguageList: (state) => {
            state.languageList = MASTER_INITIAL_STATE.languageList
        },

        // reducers for language level list
        setLanguageLevelListLoading: (state, { payload }) => {
            state.languageLevelList.isLoading = payload
        },
        setLanguageLevelList: (state, { payload }) => {
            state.languageLevelList.languageLevelList = payload
            state.languageLevelList.errorMsg = MASTER_INITIAL_STATE.languageLevelList.errorMsg
        },
        setLanguageLevelListErrorMsg: (state, { payload }) => {
            state.languageLevelList.errorMsg = payload
            state.languageLevelList.languageLevelList = MASTER_INITIAL_STATE.languageLevelList.languageLevelList
        },
        setClearLanguageLevelList: (state) => {
            state.languageLevelList = MASTER_INITIAL_STATE.languageLevelList
        },

        // prof List
        setClearProfList: (state) => {
            state.profList = MASTER_INITIAL_STATE.profList
        },

        // reducers for tag list
        setTagListLoading: (state, { payload }) => {
            state.tagList.isLoading = payload
        },
        setTagList: (state, { payload }) => {
            state.tagList.tagList = payload
            state.tagList.errorMsg = MASTER_INITIAL_STATE.tagList.errorMsg
        },
        setTagListErrorMsg: (state, { payload }) => {
            state.tagList.errorMsg = payload
            state.tagList.tagList = MASTER_INITIAL_STATE.tagList.tagList
        },
        setClearTagList: (state) => {
            state.tagList = MASTER_INITIAL_STATE.tagList
        },
    },

    extraReducers: {
        // extra reducers for master country list
        [getMasterCountryList.pending]: (state) => {
            state.countryList.isLoading = true
        },
        [getMasterCountryList.fulfilled]: (state, { payload }) => {
            state.countryList.isLoading = false
            state.countryList.countryList = payload.data.data
        },
        [getMasterCountryList.rejected]: (state, { payload }) => {
            state.countryList.isLoading = false
            state.countryList.errorMsg = payload
        },

        // extra reducers for master state list
        [getMasterStateList.pending]: (state) => {
            state.stateList.isLoading = true
        },
        [getMasterStateList.fulfilled]: (state, { payload }) => {
            state.stateList.isLoading = false
            state.stateList.stateList = payload.data.data
        },
        [getMasterStateList.rejected]: (state, { payload }) => {
            state.stateList.isLoading = false
            state.stateList.isLoading = payload
        },

        // extra reducers for city list
        [getMasterCityList.pending]: (state) => {
            state.cityList.isLoading = true
        },
        [getMasterCityList.fulfilled]: (state, { payload }) => {
            state.cityList.isLoading = false
            state.cityList.cityList = payload.data.data
        },
        [getMasterCityList.rejected]: (state, { payload }) => {
            state.cityList.isLoading = false
            state.cityList.errorMsg = payload
        },

        // extra reducers for master languageList
        [getMasterLanguageList.pending]: (state) => {
            state.languageList.isLoading = true
        },
        [getMasterLanguageList.fulfilled]: (state, { payload }) => {
            state.languageList.isLoading = false
            state.languageList.languageList = payload.data.data
        },
        [getMasterLanguageList.rejected]: (state, { payload }) => {
            state.languageList.isLoading = false
            state.languageList.errorMsg = payload
        },

        // extra reducers for proficiency list
        [getMasterProficiencyList.pending]: (state) => {
            state.profList.isLoading = true
        },
        [getMasterProficiencyList.fulfilled]: (state, { payload }) => {
            state.profList.isLoading = false
            state.profList.profList = payload.data.data
        },
        [getMasterProficiencyList.rejected]: (state, { payload }) => {
            state.profList.isLoading = false
            state.profList.errorMsg = payload
        },

        // extra reducers for social list
        [getMasterSocialList.pending]: (state) => {
            state.socialList.isLoading = true
        },
        [getMasterSocialList.fulfilled]: (state, { payload }) => {
            state.socialList.isLoading = false
            state.socialList.data = payload
            state.socialList.message = MASTER_INITIAL_STATE.socialList.message
        },
        [getMasterSocialList.rejected]: (state, { payload }) => {
            state.socialList.isLoading = false
            state.socialList.message = payload
            state.socialList.data = MASTER_INITIAL_STATE.socialList.data
        }
    }
})

export const {
    setCountryListLoading,
    setCountryList,
    setCountryListErrorMsg,
    setClearCountryList,

    setCountryDetailLoading,
    setCountryDetail,
    setCountryDetailErrorMsg,
    setClearCountryDetail,

    setTimeZoneCountryDetailLoading,
    setTimeZoneCountryDetail,
    setTimeZoneCountryDetailErrorMsg,
    setClearTimeZoneCountryDetail,

    setStateListLoading,
    setStateList,
    setStateListErrorMsg,
    setClearStateList,

    setCityListLoading,
    setCityList,
    setCityListErrorMsg,
    setClearCityList,

    setCurrencyListLoading,
    setCurrencyList,
    setCurrencyListErrorMsg,
    setClearCurrencyList,

    setCurrencyRateListLoading,
    setCurrencyRateList,
    setCurrencyRateListErrorMsg,
    setClearCurrencyRateList,

    setTimeZoneListLoading,
    setTimeZoneList,
    setTimeZoneListErrorMsg,
    setClearTimeZoneList,

    setClearLanguageList,

    setLanguageLevelListLoading,
    setLanguageLevelList,
    setLanguageLevelListErrorMsg,
    setClearLanguageLevelList,

    setClearProfList,

    setTagListLoading,
    setTagList,
    setTagListErrorMsg,
    setClearTagList,
} = master.actions

export default master.reducer