import pageInfoReducer from 'redux/pageInfo/pageInfo.reducer';
import localSlice from 'redux/local/local.slice';

import appointmentSlice from 'redux/appointment/appointment.slice';
import authSlice from 'redux/auth/auth.slice';
import availabilitySlice from 'redux/availability/availability.slice';
import badgeSlice from 'redux/badge/badge.slice';
import bookingSlice from 'redux/booking/booking.slice';
import categorySlice from 'redux/category/category.slice';
import chargeBeeSlice from 'redux/chargeBee/chargeBee.slice';
import companySlice from 'redux/company/company.slice';
import courseSlice from "redux/course/course.slice";
import defaultSlice from 'redux/default/default.slice';
import documentSlice from 'redux/document/document.slice';
import eventSlice from 'redux/event/event.slice';
import leadSlice from 'redux/lead/lead.slice';
import linkTreeSlice from 'redux/linkTree/linkTree.slice';
import lmsSlice from './edulyteLms/edulyteLms.slice';
import masterSlice from 'redux//master/master.slice';
import notificationSlice from 'redux/notification/notification.slice';
import offeringSlice from 'redux/offering/offering.slice';
import onboardingSlice from 'redux/onboarding/onboarding.slice';
import orderSlice from './order/order.slice';
import otherSlice from 'redux/other/other.slice';
import paymentSlice from 'redux/payment/payment.slice';
import payoutSlice from 'redux/payout/payout.slice';
import productSlice from './product/product.slice';
import referralSlice from './referral/referral.slice';
import sessionSlice from 'redux/session/session.slice';
import studentSlice from 'redux/student/student.slice';
import storageSlice from 'redux/storage/storage.slice';
import templateSlice from 'redux/template/template.slice';
import tutorSlice from 'redux/tutor/tutor.slice';
import userSlice from 'redux/user/user.slice';
import walletSlice from 'redux/wallet/wallet.slice';
import walletTxnSlice from 'redux/walletTxn/walletTxn.slice';
import withdrawalSlice from 'redux/withdrawal/withdrawal.slice';

const mainRootReducer = {
    pageInfo: pageInfoReducer,
    local: localSlice,

    appointment: appointmentSlice,
    auth: authSlice,
    availability: availabilitySlice,
    badge: badgeSlice,
    booking: bookingSlice,
    category: categorySlice,
    chargeBee: chargeBeeSlice,
    company: companySlice,
    course: courseSlice,
    document: documentSlice,
    event: eventSlice,
    linkTree: linkTreeSlice,
    lead: leadSlice,
    lms: lmsSlice,
    master: masterSlice,
    notification: notificationSlice,
    offering: offeringSlice,
    onboarding: onboardingSlice,
    order: orderSlice,
    other: otherSlice,
    payment: paymentSlice,
    payout: payoutSlice,
    product: productSlice,
    referral: referralSlice,
    session: sessionSlice,
    student: studentSlice,
    storage: storageSlice,
    template: templateSlice,
    tutor: tutorSlice,
    user: userSlice,
    userDefault: defaultSlice,
    wallet: walletSlice,
    walletTxn: walletTxnSlice,
    withdrawal: withdrawalSlice,
};

export default mainRootReducer;