import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    GET_REFERRAL_LIST: "/referrals/referrals",
}

export const referralStatus = {
    ALL: {
        label: 'All',
        value: 'all',
    },
    PENDING: {
        label: 'Pending',
        value: 'pending',
    },
    PURCHASED: {
        label: 'Purchased',
        value: 'purchased',
    },
    APPROVED: {
        label: 'Approved',
        value: 'approved',
    },
    REJECTED: {
        label: 'Rejected',
        value: 'rejected',
    },
}

export const referralFilterProps = {
    searchText: "",
    sortBy: "",
    page: 1,
    records: dataEntriesConst.TWENTY_FIVE.value,
    referralId: "",
    refereeUser: "",
    beneficiaryUser: "",
    amount: "",
    referralStatus: referralStatus.ALL.value,
    date: null,
    publishedAt: null
}