import { masterCurrencyCodeEnum } from "redux/master/master.const";
import { gatewayNameConst } from "redux/withdrawal/withdrawal.const";

export const url = {
    GET_WALLET_TXN_LIST: "/wallets/txn/list",
    ADD_CREDIT_TO_WALLET: "/wallets/add/credit/{userId}",
    GET_WALLET_CONFIRMATION_BY_VIRTUAL_TXN_ID: "/wallets/confirmation/{virtualTxnId}",
    USER_WITHDRAWAL_LIST: "/withdrawals/users/{userId}"
}

export const walletRedux = {
    sliceName: "wallet",
    walletTxnList: {
        requestName: "getWalletTxnList"
    },
    addCreditToWallet: {
        requestName: "createCreditToWallet"
    },
    walletConfirmationByVirtualTxnId: {
        requestName: "getWalletConfirmationByVirtualId"
    },
    myWithdrawalList: {
        requestName: "getMyWithdrawalListByUserId"
    },
    userWithdrawalList: {
        requestName: "getUserWithdrawalListByUserId"
    },
    createUserWithdrawalDetail: {
        requestName: "createUserWithdrawalDetailByUserId"
    }
}

export const addCreditToWalletPayload = {
    userDetail: null,
    gatewayName: gatewayNameConst.STRIPE.value,
    currencyCode: masterCurrencyCodeEnum.USD.value,
    requiredAmount: 0,
    processingFee: 200,
    amount: 0,
    gatewayPaymentId: null,
}

export const walletTxnActions = {
    MONEY_ADDED: {
        label: "Money Added",
        value: 'money_added',
        action: 'money_added'
    },
    MONEY_WITHDRAWN: {
        label: 'Money Withdrawn',
        value: 'money_withdrawn',
        action: 'money_withdrawn'
    },
    PROCESSING_FEE: {
        label: 'Processing Fee',
        value: 'processing_fee',
        action: 'processing_fee'
    },
    COURSE_PURCHASED: {
        label: 'Course Purchased',
        value: 'course_purchased',
        action: 'course_purchased'
    },
    COURSE_REFUNDED: {
        label: 'Course Refund',
        value: 'course_refunded',
        action: 'course_refunded'
    },
    COURSE_COMPLETED: {
        label: 'Course Completed',
        value: 'course_completed',
        action: 'course_completed'
    },
    EARNING_FROM_COURSE: {
        label: 'Earning From Course',
        value: 'earning_from_course',
        action: 'earning_from_course'
    },
    SEND_FOR_REFERRAL: {
        label: 'Send for Referral',
        value: 'send_for_referral',
        action: 'send_for_referral'
    },
    EARNING_FROM_REFERRAL: {
        label: 'Earning From Referral',
        value: 'earning_from_referral',
        action: 'earning_from_referral'
    },
    GIFT_CARD_REDEEMED: {
        label: 'Gift Card Redeemed',
        value: 'gift_card_redeemed',
        action: 'gift_card_redeemed'
    },
    PROMOTIONAL_CREDIT: {
        label: 'Promotional Credit',
        value: 'promotional_credit',
        action: 'promotional_credit'
    },
    OTHER: {
        label: 'Other',
        value: 'other',
        action: 'other'
    },
}

export const walletTxnTypeEnum = {
    WALLET: {
        value: 'wallet',
        label: 'Wallet'
    },
    PENDING: {
        value: 'pending',
        label: 'Pending'
    },
    PROMO: {
        value: 'promo',
        label: 'Promo'
    },
    AUD_WALLET: {
        value: "aud_wallet",
        label: "AUD Wallet"
    },
    INR_WALLET: {
        value: "inr_wallet",
        label: "INR Wallet"
    }
}