import { cn } from "utils/cn.utils";

const DetailedView = ({
    headers,
    rows,
    rowHeaderColor,
    rowColor
}) => {
    return (
        <div className={cn(
            "min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow overflow-x-auto",
            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
        )}>
            <table className={"w-full divide-y divide-gray-200"}>
                <thead className={cn(
                    rowHeaderColor
                )}>
                    <tr className="">
                        {headers?.map((head, index) => (
                            <th
                                key={index}
                                scope="col"
                                className={cn(
                                    "px-5 py-5 text-xs tracking-wider text-left text-text-800 capitalize",
                                    "font-bold font-bodyPri whitespace-nowrap"
                                )}
                            >
                                <div className="w-full flex justify-center items-center gap-5 cursor-pointer">
                                    <div className="flex justify-start items-center">
                                        {head?.label}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={cn("bg-white divide-y divide-gray-200", rowColor)}>
                    {rows?.map((row, idx) => (
                        <td className={"px-6 py-4"} key={idx}>
                            <div className={"font-bodyPri font-normal text-sm text-text-800 text-center"}>
                                {row}
                            </div>
                        </td>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default DetailedView