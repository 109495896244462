import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { userRoles } from 'redux/auth/auth.const';

export const pageHeading = {
    heading: "Wallet & Transactions",
    subHeading: "See your balance, transactions and withdraw money!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/wallet.svg"
}

export const wallet = {
    WALLET_CREDIT: {
        title: "Available",
        value: 'wallet_credit',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    PENDING_CREDIT: {
        title: "Pending",
        value: 'pending_credit',
        roles: [userRoles.SUPER_ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
}

export const buttonFeature = {
    WITHDRAW: {
        label: 'Withdraw',
        value: 'withdraw',
        image: <AccountBalanceWalletIcon style={{ width: '60px', height: '60px' }} />,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    ADD_CREDIT: {
        label: 'Add Credit',
        value: 'add_credit',
        image: <MonetizationOnIcon style={{ width: '60px', height: '60px' }} />,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    },
    REEDEM_GIFT_CARD: {
        label: 'Gift Card',
        value: 'reedem_gift_card',
        image: <CardGiftcardIcon style={{ width: '60px', height: '60px' }} />,
        roles: [userRoles.SUPER_ADMIN.value, userRoles.ADMIN.value]
    }
}

export const txnCardTitles = {
    WALLET: {
        label: 'Wallet Transactions',
        value: 'wallet'
    },
    WITHDRAWAL: {
        label: 'My Withdrawals',
        value: 'withdrawal'
    }
}
