import { useState } from 'react';

import { cn } from "utils/cn.utils";

import { IoIosArrowForward } from 'react-icons/io';

import { useAppState } from 'hooks/useStore';
import { contactTime } from 'redux/lead/lead.const';

const LeadQuestionAnswerContainer = () => {
    const { leadDetail } = useAppState((state) => state.lead)

    const [isShow, setIsShow] = useState(false)

    const capitalizeFirstNameLetter = () => {
        const capitalizedName = leadDetail?.leadDetail?.firstName?.slice(0)?.charAt(0)?.toUpperCase() + leadDetail?.leadDetail?.firstName?.slice(1)
        return capitalizedName;
    }

    return (
        <div className={"space-y-1"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Details"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "py-3 flex flex-col gap-5 transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Which grade are you in?"}
                    </span>
                    <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                        {leadDetail?.leadDetail?.grade || "-"}
                    </span>
                </div>
                <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Which topic/subject would you like help with?"}
                    </span>
                    <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                        {leadDetail?.leadDetail?.topic || "-"}
                    </span>
                </div>
                <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"What is your name?"}
                    </span>
                    <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                        {`${leadDetail?.leadDetail?.firstName} ${leadDetail?.leadDetail?.lastName}` || "-"}
                    </span>
                </div>
                <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {`${capitalizeFirstNameLetter()}, where can we send your tutor details?`}
                    </span>
                    {leadDetail?.leadDetail?.email ?
                        <div className={"flex items-center justify-start gap-1.5"}>
                            <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                                {`${leadDetail?.leadDetail?.email}`}
                            </span>
                            {leadDetail?.leadDetail?.isEmailVerified &&
                                <span className={"font-bodyPri font-bold text-green-600 text-sm tracking-wide"}>
                                    {"(Verified)"}
                                </span>
                            }
                            {!leadDetail?.leadDetail?.isEmailVerified &&
                                <span className={"font-bodyPri font-bold text-red-500 text-sm tracking-wide"}>
                                    {"(Not Verified)"}
                                </span>
                            }
                        </div>
                        : "-"
                    }
                </div>
                <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"what is your phone number?"}
                    </span>
                    {(leadDetail?.leadDetail?.isdCode && leadDetail?.leadDetail?.mobileNo) ?
                        <div className={"flex items-center justify-start gap-1.5"}>
                            <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                                {`+${leadDetail?.leadDetail?.isdCode} - ${leadDetail?.leadDetail?.mobileNo}` || "-"}
                            </span>
                            {leadDetail?.leadDetail?.isMobileNoVerified &&
                                <span className={"font-bodyPri font-bold text-green-600 text-sm tracking-wide"}>
                                    {"(Verified)"}
                                </span>
                            }
                            {!leadDetail?.leadDetail?.isMobileNoVerified &&
                                <span className={"font-bodyPri font-bold text-red-500 text-sm tracking-wide"}>
                                    {"(Not Verified)"}
                                </span>
                            }
                        </div>
                        : "-"
                    }
                </div>
                <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {`${capitalizeFirstNameLetter()}, which city/locality are you from?`}
                    </span>
                    <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                        {`${leadDetail?.leadDetail?.city}` || "-"}
                    </span>
                </div>
                <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Finally, choose a convenient time to contact"}
                    </span>
                    <span className={"font-bodyPri font-bold text-text-900 text-base tracking-wide"}>
                        {`${contactTime[leadDetail?.leadDetail?.contactTime?.toUpperCase()]?.label}` || "-"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default LeadQuestionAnswerContainer