import { useNavigate, useLocation } from 'react-router-dom';

import { BsFillQuestionCircleFill } from 'react-icons/bs';

import { Container } from "pages/auth/walletConfirmation/WalletConfirmationPageStyle";

import { pagesInfo } from 'utils/pagesInfo';


const NotExistWalletConfirmation = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const from = location.state?.from?.pathname || pagesInfo.DASHBOARD.pagePath;

    const pageRedirect = () => {
        navigate(from, { replace: true })
    }

    return (
        <Container className={"!shadow-all-md"}>
            <BsFillQuestionCircleFill className={"text-8xl text-blue-500"} />
            <div className={"flex flex-col items-center gap-2"}>
                <span className={"font-bodyPri font-medium text-md text-text-800"}>
                    {"Wallet transaction does not exist"}
                </span>
                <span className={"font-bodyPri font-semibold text-lg text-blue-500 tracking-wider"}>
                    {"Doesn't Exist"}
                </span>
            </div>
            <div onClick={pageRedirect}>
                <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
                    {"Go Back"}
                </span>
            </div>
        </Container>
    )
}

export default NotExistWalletConfirmation