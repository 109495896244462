import MuxPlayer from "@mux/mux-player-react";
import { cn } from "utils/cn.utils";

import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoMdRefresh } from "react-icons/io";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineCloudDownload } from "react-icons/ai";
// import { BsRecordCircle } from "react-icons/bs";

import { MUX_ENV_KEY } from "const/default.const";

const MuxVideoPlayer = (
    {
        streamType = "on-demand",
        thumbnailTime = "10",
        disableCookies = true,
        isButtonDisabled = false,
        isShowRefreshButton = false,
        isShowDeleteButton = false,
        isShowButtons = true,
        isShowDownloadButton = true,
        playbackId,
        // handleRecordVideo,
        handleUploadVideo,
        onHandleRefresh,
        onHandleDeleteVideo
    }
) => {

    return (
        <div className={"flex flex-col justify-start items-start sm:items-center sm:justify-center gap-3"}>
            <div className={"rounded-lg overflow-hidden w-full sm:w-[25rem] md:w-full h-full aspect-video z-0"}>
                <MuxPlayer
                    streamType={streamType}
                    // ref={videoRef}
                    style={{ height: '100%', maxWidth: '100%' }}
                    // debug={true}
                    disableCookies={disableCookies}
                    playbackId={playbackId}
                    thumbnailTime={thumbnailTime}
                    controls
                    primaryColor={"#9e9e9e"}
                    forwardSeekOffset={"10"}
                    backwardSeekOffset={"10"}
                    muxOptions={{
                        application_name: "Edulyte"
                    }}
                    metadata={{
                        envKey: MUX_ENV_KEY,
                        video_id: playbackId,
                        video_title: playbackId,
                        player_name: 'Mux Video React',
                        viewer_user_id: "user-id-007",
                    }}
                />
            </div>

            <div className={"w-full flex flex-wrap flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center gap-1.5 sm:gap-3"}>
                {isShowRefreshButton &&
                    <div
                        className={cn(
                            "px-2 py-1 flex items-center justify-center font-bodyPri font-normal text-sm cursor-pointer rounded-lg gap-2",
                            !isButtonDisabled && "border border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50",
                            isButtonDisabled && "border border-text-500 text-text-500 cursor-not-allowed"
                        )}
                        onClick={onHandleRefresh}
                    >
                        <IoMdRefresh className={"text-base md:text-lg lg:text-xl"} />
                        <span className={"whitespace-nowrap"}>
                            {"Please Refresh"}
                        </span>
                    </div>
                }
                {/* <div className={"w-full flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center gap-1.5"}> */}
                {/* {isShowButtons &&
                    <div className={cn(
                        "px-2 py-1 flex items-center justify-center gap-2 rounded-lg font-bodyPri font-normal text-sm tracking-wide",
                        !isButtonDisabled && "border border-secondary-dark text-secondary-dark hover:bg-secondary-dark hover:text-text-50 cursor-pointer",
                        isButtonDisabled && "border border-text-500 text-text-500 cursor-not-allowed"
                    )}
                        onClick={handleRecordVideo}
                    >
                        <BsRecordCircle className={"text-base md:text-lg lg:text-xl"} />
                        <span className={"whitespace-nowrap"}>
                            {"Record Video"}
                        </span>
                    </div>
                } */}
                {isShowButtons &&
                    <div className={cn(
                        "px-2 py-1 flex items-center justify-center gap-2 rounded-lg font-bodyPri font-normal text-sm tracking-wide",
                        !isButtonDisabled && "border border-secondary-dark text-secondary-dark hover:bg-secondary-dark hover:text-text-50 cursor-pointer",
                        isButtonDisabled && "border border-text-500 text-text-500 cursor-not-allowed"
                    )}
                        onClick={handleUploadVideo}
                    >
                        <AiOutlineCloudUpload className={"text-base md:text-lg lg:text-xl"} />
                        <span className={"whitespace-nowrap"}>
                            {"Upload Video"}
                        </span>
                    </div>
                }
                {isShowDeleteButton &&
                    <div
                        className={cn(
                            "px-2 py-1 flex items-center justify-center font-bodyPri font-normal text-sm cursor-pointer rounded-lg gap-2",
                            !isButtonDisabled && "border border-red-500 text-red-500 hover:bg-red-700 hover:text-text-50",
                            isButtonDisabled && "border border-text-500 text-text-500 cursor-not-allowed"
                        )}
                        onClick={onHandleDeleteVideo}
                    >
                        <MdOutlineDelete className={"text-base md:text-lg lg:text-xl"} />
                        <span className={"whitespace-nowrap"}>
                            {"Delete Video"}
                        </span>
                    </div>
                }
                {isShowDownloadButton &&
                    <a
                        href={`https://stream.mux.com/${playbackId}.m3u8`}
                        className={cn(
                            "px-2 py-1 flex items-center justify-center font-bodyPri font-normal text-sm cursor-pointer rounded-lg gap-2",
                            !isButtonDisabled && "border border-primary-dark text-primary-dark hover:bg-primary-dark hover:text-text-50",
                            isButtonDisabled && "border border-text-500 text-text-500 cursor-not-allowed"
                        )}
                        download
                    >
                        <AiOutlineCloudDownload className={"text-base md:text-lg lg:text-xl"} />
                        <span className={"whitespace-nowrap"}>
                            {"Download Video"}
                        </span>
                    </a>
                }
                {/* </div> */}
            </div>
        </div>
    )
}

export default MuxVideoPlayer