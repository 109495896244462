import { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import Table from 'components/table/Table';
import Pagination from 'components/pagination/Pagination';

import { lmsCourseHeaderConst, sortCourseHighToLow, sortCourseLowToHigh, subRowHeader } from 'pages/auth/edulyteLms/lmsCourse/lmsCourse.data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { deleteLmsCourseDetail, getLmsCourseList } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { resetFilterProps, resetLmsCourseList } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { lmsCourseStatusEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { timeZone, dayjs } from 'utils/dateTime.utils';
import { edulyteAppPagesInfo, pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_APP_DOMAIN_URL, EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

let CURRENT_COURSE_ID = null;

const OwnerTutorContainer = ({ course }) => {
    return course?.course_setting?.default_owner?.tutor ? (
        <div className={"flex items-center justify-start gap-0.5"}>
            <Link to={`${pagesInfo?.VIEW_TUTOR?.pagePath}/${course?.course_setting?.default_owner?.tutor?.tutorId}`}
                className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
            >
                {course?.course_setting?.default_owner?.tutor?.tutorId + ". "}
            </Link>
            <div className={""}>
                {`${course?.course_setting?.default_owner?.tutor?.user?.first_name} ${course?.course_setting?.default_owner?.tutor?.user?.last_name?.charAt(0)}.` || "-"}
            </div>
        </div>
    ) : "-"
}
const DisplayLmsCourse = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { lmsCourseList, destroyLmsCourseDetail, filterProps } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        return () => {
            dispatcher(resetLmsCourseList())
            dispatcher(resetFilterProps())
            dispatcher(resetAdminLoginAs())
        }
    }, [])

    useEffect(() => {
        const query = {
            page: filterProps?.page || 1,
            records: Number(filterProps?.records) || 25
        }
        dispatcher(getLmsCourseList(query))

    }, [filterProps?.records])

    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo?.EDIT_LMS_COURSE?.pagePath}/${CURRENT_COURSE_ID}/edit`, "_self")
        }
    }, [adminLoginAs?.data])

    const onHandleEditCourse = (courseId, userId) => {
        CURRENT_COURSE_ID = courseId
        dispatcher(createAdminLoginAsDetail({ user_id: userId, source_url: window.location.href }))
    }

    const onHandleDeleteCourse = (courseId) => {
        if (destroyLmsCourseDetail?.isLoading) return;

        dispatcher(deleteLmsCourseDetail(courseId))
    }

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortCourseList = (course1, course2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortCourseLowToHigh(activeSortHeader, course1, course2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortCourseHighToLow(activeSortHeader, course1, course2)
        }
    }

    const onHandleNavigateToCourse = useCallback((courseItem) => {
        if (courseItem?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value) {
            window.open(`${EDULYTE_WEB_DOMAIN_URL}/course/${courseItem?.active_slug}`, "_blank")
        }
    }, [])

    const CourseTitleContainer = memo(({ course }) => {
        return (
            <span
                className={cn(
                    'font-bodyPri font-normal tracking-wide',
                    (course?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
                )}
                title={course?.title && course?.title}
                onClick={() => onHandleNavigateToCourse(course)}
            >
                {course?.title
                    ? (course?.title?.length > 30)
                        ? course?.title?.slice(0, 30) + " ..."
                        : course?.title
                    : "-"
                }
            </span>
        )
    })

    const tableRowsConst = lmsCourseList?.data?.results?.slice(0)?.sort(sortCourseList)?.map((course) => ([
        course?.id,
        <CourseTitleContainer course={course} />,
        course?.active_slug
            ? (course?.active_slug?.length > 30)
                ? course?.active_slug?.slice(0, 30) + "...."
                : course?.active_slug
            : "-",
        <OwnerTutorContainer course={course} />,
        course?.category?.category
            ? `${course?.category?.category} (${course?.category?.segment?.segment})`
            : "-",
        course?.course_setting?.status
            ? lmsCourseStatusEnum[course?.course_setting?.status?.toUpperCase()]?.label
            : "-",
        dayjs(course?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",

        <div className={"flex items-center w-full"}>
            <button
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
                onClick={() => onHandleEditCourse(course?.id, course?.course_setting?.default_owner?.tutor?.user?.userId)}
                title={"Edit Course"}
            >
                <FiEdit size={20} className={"text-xl hover:scale-110"} />
            </button>
            <button
                className={"px-2 py-1 bg-red-500 text-white rounded-r-md"}
                onClick={() => onHandleDeleteCourse(course?.id)}
                title={"Delete Course"}
            >
                <AiOutlineDelete size={20} className={"text-xl hover:scale-110"} />
            </button>
        </div>,
    ]))

    return (
        <div className={'w-full flex flex-col gap-5'}>
            <Table
                headers={Object.values(lmsCourseHeaderConst)}
                subRowHeader={subRowHeader}
                rows={tableRowsConst}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={lmsCourseList?.isLoading}
                message={(lmsCourseList?.message || (lmsCourseList?.data?.records === 0)) && (lmsCourseList?.message || "No courses found")}
            />
            {(!lmsCourseList?.isLoading && (lmsCourseList?.data?.totalPages > 1)) &&
                <div className={'w-full flex items-center justify-center'}>
                    <Pagination
                        page={lmsCourseList?.data?.page}
                        totalPages={lmsCourseList?.data?.totalPages}
                        onChangePage={(page) => dispatcher(getLmsCourseList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </div>
    )
}

export default DisplayLmsCourse;