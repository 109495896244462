import { useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";
import { OptionSelector } from "components/common-components/Select";

import { fixedRateConst, variableRateConst } from "pages/auth/editTutorOnboard/data";

import { updateOnboardingDetailByOnboardingId } from 'redux/onboarding/onboarding.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { onBoardingStatus, onboardingActionRequired } from "redux/onboarding/onboarding.const";


const VerifyTutor = () => {
    const { onboardingDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [pageData, setPageData] = useState({
        fixedRate: "",
        variableRate: "",
        status: onboardingDetail?.onboardingDetail?.status || "",
        comments: onboardingDetail?.onboardingDetail?.comments || "",
        actionRequired: onboardingDetail?.onboardingDetail?.actionRequired || "",
    })

    const onHandleSave = () => {
        let requestData = {
            onboardingId: Number(onboardingId),
            body: {}
        }

        if (pageData?.status) requestData.body["status"] = pageData?.status
        if (pageData?.comments) requestData.body["comments"] = pageData?.comments
        if (pageData?.actionRequired) requestData.body["actionRequired"] = pageData?.actionRequired

        dispatcher(updateOnboardingDetailByOnboardingId(requestData))
    }

    return (
        <div className={"space-y-8"}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-3 py-3",
                onboardingDetail?.onboardingDetail?.status && `bg-${onBoardingStatus[onboardingDetail?.onboardingDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Approve Tutor"}
            </div>
            {(onboardingDetail?.isLoading) &&
                <ComponentLoader isLoading={onboardingDetail?.isLoading} />
            }
            {(onboardingDetail?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingDetail?.errorMsg}
                </div>
            }
            {onboardingDetail?.onboardingDetail &&
                <>
                    <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-3 md:flex-row md:items-center md:justify-start px-3">
                        <span className="text-text-600">
                            {"Status:"}
                        </span>
                        <div className={"w-52"}>
                            <OptionSelector
                                options={Object.values(onBoardingStatus)}
                                className={"w-full"}
                                onChange={(option) => setPageData({ ...pageData, status: option?.value })}
                                value={pageData?.status}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-3 md:flex-row md:items-center md:justify-start px-3">
                        <span className="text-text-600">
                            {"Action Required By:"}
                        </span>
                        <div className={"w-52"}>
                            <OptionSelector
                                options={Object.values(onboardingActionRequired)}
                                className={"w-full"}
                                value={pageData?.actionRequired}
                                onChange={(option) => setPageData({ ...pageData, actionRequired: option?.value })}
                            />
                        </div>
                    </div>

                    <div className={"flex items-center justify-start gap-5 px-3"}>
                        <div
                            className={cn
                                ("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                    "border border-secondary-main text-secondary-main",
                                    "hover:bg-secondary-main hover:text-text-50"
                                )}
                            onClick={onHandleSave}
                        >
                            {"Save"}
                        </div>
                        <div
                            className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                "border border-primary-main text-primary-main",
                                "hover:bg-primary-main hover:text-text-50"
                            )}
                        // onClick={() => dispatcher(getPersonalDetailByUserId(Number(userId)))}
                        >
                            {"Cancel"}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default VerifyTutor