import { useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import PublishedCourseLink from './PublishedCourseLink';
import PrivateCourseLink from './PrivateCourseLink';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { courseStatus } from 'redux/course/course.const';
import { EDULYTE_ADMIN_DOMAIN_URL, EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

import { pagesInfo } from 'utils/pagesInfo';

const PreviewCourseDetails = () => {
    const { locals } = useAppState((state) => state.local)
    const { courseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const PUBLISHED_COURSE_URL = `${EDULYTE_APP_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${courseDetail?.courseDetail?.slug}`
    const PRIVATE_COURSE_URL = `${EDULYTE_ADMIN_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${courseDetail?.courseDetail?.slug}/preview?previewAs=${locals.userRole}`

    const onHandlePreviewCourse = () => {
        if (courseDetail?.courseDetail?.status === courseStatus?.PUBLISHED?.value) {
            window.open(PUBLISHED_COURSE_URL, "_blank")
            return;
        }
        if ([courseStatus?.DRAFT?.value, courseStatus?.UNDER_REVIEW?.value]?.includes(courseDetail?.courseDetail?.status)) {
            window.open(PRIVATE_COURSE_URL, "_blank")
        }
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div className={`w-full px-5 py-3 rounded-lg border-b border-text-400 bg-${courseStatus[courseDetail?.courseDetail?.status?.toUpperCase()]?.lightColor}`}>
                <span className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Class Preview"}
                </span>
            </div>
            <div className={"w-full min-h-[60vh] flex flex-col items-center justify-center gap-5"}>
                <div className={"w-full flex items-center justify-center gap-3"}>
                    <span className={"font-bodyPri font-normal text-text-700 text-xl"}>
                        {"Class Status:"}
                    </span>
                    <span className={`font-bodyPri font-normal text-xl text-${courseStatus[courseDetail?.courseDetail?.status?.toUpperCase()]?.darkColor}`}>
                        {courseStatus[courseDetail?.courseDetail?.status?.toUpperCase()]?.label}
                    </span>
                </div>
                <div className={cn(
                    "mx-auto w-full md:w-[25%] lg:w-[50%] relative flex flex-col items-center justify-center rounded-lg shadow-lg bg-black aspect-video cursor-pointer",
                    "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                    "ring-offset-2 ring-4 ring-white"
                )}>
                    <img
                        src={courseDetail?.courseDetail?.thumbnailPicUrl}
                        className="object-cover w-full h-full rounded-lg opacity-90"
                        alt="course-thumbnail"
                    />
                    <div
                        className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                        <span
                            className={"bg-black bg-opacity-50 px-4 py-2 rounded-sm text-text-50 font-bodyPri font-normal text-base"}
                            onClick={onHandlePreviewCourse}
                        >
                            {"Preview"}
                        </span>
                    </div>
                </div>

                {/* class links  */}
                {(courseDetail?.courseDetail?.status !== courseStatus?.INACTIVE?.value) &&
                    <div className={"w-fit flex items-center justify-center"}>
                        {(courseDetail?.courseDetail?.status === courseStatus?.PUBLISHED?.value) &&
                            <PublishedCourseLink />
                        }
                        {([courseStatus?.DRAFT?.value, courseStatus?.UNDER_REVIEW?.value]?.includes(courseDetail?.courseDetail?.status)) &&
                            <PrivateCourseLink />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default PreviewCourseDetails;