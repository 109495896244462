import { createAsyncThunk } from '@reduxjs/toolkit';

import masterService from 'redux/master/master.service';
import {
    setCountryDetailLoading,
    setCountryDetail,
    setCountryDetailErrorMsg,

    setTimeZoneCountryDetailLoading,
    setTimeZoneCountryDetail,
    setTimeZoneCountryDetailErrorMsg,

    setCurrencyListLoading,
    setCurrencyList,
    setCurrencyListErrorMsg,

    setCurrencyRateListLoading,
    setCurrencyRateList,
    setCurrencyRateListErrorMsg,

    setTimeZoneListLoading,
    setTimeZoneList,
    setTimeZoneListErrorMsg,

    setLanguageLevelListLoading,
    setLanguageLevelList,
    setLanguageLevelListErrorMsg,

    setTagListLoading,
    setTagList,
    setTagListErrorMsg,
} from 'redux/master/master.slice';

import { masterRedux } from './master.const';
import { setMasterLocals } from 'redux/master/master.utils';

export const getMasterCountryList = createAsyncThunk(
    `${masterRedux.sliceName}/${masterRedux.masterCountryList.requestName}`,
    async (_, { rejectWithValue }) => {

        try {
            const response = await masterService.getMasterCountryList()
            if (response.status === 200) {
                return response;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error)
            return rejectWithValue([], error?.response?.data?.message || error?.response?.data?.error)
        }
    }
)

export const getMasterCountryDetailByMasterCountryId = (masterCountryId) => async (dispatch) => {
    dispatch(setCountryDetailLoading(true));
    try {
        const requestData = {
            params: { masterCountryId: masterCountryId }
        }
        const response = await masterService.getMasterCountryDetailByMasterCountryId(requestData)
        if (response.status === 200) {
            dispatch(setCountryDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setCountryDetailErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setCountryDetailLoading(false));
    }
}


export const getMasterTimezoneDetailByTimeZone = (timeZone) => async (dispatch) => {
    dispatch(setTimeZoneCountryDetailLoading(true));
    try {
        const requestData = {
            params: { timeZone: timeZone.replace("/", "-") }
        }
        const response = await masterService.getMasterTimezoneDetailByTimeZone(requestData)
        if (response.status === 200) {
            dispatch(setTimeZoneCountryDetail(response.data.data))
            await setMasterLocals({
                currencyCode: response?.data?.data?.country?.currency?.code,
                timeZone: response?.data?.data?.timeZone
            })
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error)
        console.error(error?.response?.data?.message || error?.response?.data?.error)
        dispatch(setTimeZoneCountryDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error))
        await setMasterLocals({})
    } finally {
        dispatch(setTimeZoneCountryDetailLoading(false));
    }
}

export const getMasterStateList = createAsyncThunk(
    `${masterRedux.sliceName}/${masterRedux.masterStateList.requestName}`,
    async (masterCountryId, { rejectWithValue }) => {

        try {
            const requestData = {
                params: { masterCountryId: masterCountryId }
            }
            const response = await masterService.getMasterStateList(requestData)
            if (response.status === 200) {
                return response;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error)
            return rejectWithValue([], error?.response?.data?.message || error?.response?.data?.error)
        }
    }
)

export const getMasterCityList = createAsyncThunk(
    `${masterRedux.sliceName}/${masterRedux.masterCityList.requestName}`,
    async (masterStateId, { rejectWithValue }) => {

        try {
            const requestData = {
                params: { masterStateId: masterStateId }
            }
            const response = await masterService.getMasterCityList(requestData)
            if (response.status === 200) {
                return response;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error)
            return rejectWithValue([], error?.response?.data?.message || error?.response?.data?.error)
        }
    }
)

export const getMasterCurrencyList = () => async (dispatch) => {
    dispatch(setCurrencyListLoading(true))

    try {
        const response = await masterService.getMasterCurrencyList()
        if (response.status === 200) {
            dispatch(setCurrencyList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setCurrencyListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setCurrencyListLoading(false))
    }
}

export const getMasterCurrencyRateList = () => async (dispatch) => {
    dispatch(setCurrencyRateListLoading(true))

    try {
        const response = await masterService.getMasterCurrencyRateList()

        if (response.status === 200) {
            dispatch(setCurrencyRateList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setCurrencyRateListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setCurrencyRateListLoading(false))
    }
}

export const getMasterTimezoneList = () => async (dispatch) => {
    dispatch(setTimeZoneListLoading(true))

    try {
        const response = await masterService.getMasterTimezoneList()
        if (response.status === 200) {
            dispatch(setTimeZoneList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setTimeZoneListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setTimeZoneListLoading(false))
    }
}

export const getMasterLanguageLevelList = () => async (dispatch) => {
    dispatch(setLanguageLevelListLoading(true))

    try {
        const response = await masterService.getMasterLanguageLevelList()
        if (response.status === 200) {
            dispatch(setLanguageLevelList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setLanguageLevelListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setLanguageLevelListLoading(false))
    }
}

export const getMasterProficiencyList = createAsyncThunk(
    `${masterRedux.sliceName}/${masterRedux.masterProficiencyList.requestName}`,
    async (_, { rejectWithValue }) => {

        try {
            const response = await masterService.getMasterProficiencyList()
            if (response.status === 200) {
                return response;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error)
            return rejectWithValue([], error?.response?.data?.message || error?.response?.data?.error)
        }
    }
)

export const getMasterTagList = () => async (dispatch) => {
    dispatch(setTagListLoading(true))

    try {
        const response = await masterService.getMasterTagList()
        if (response.status === 200) {
            dispatch(setTagList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setTagListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setTagListLoading(false))
    }
}

export const getMasterLanguageList = createAsyncThunk(
    `${masterRedux.sliceName}/${masterRedux.masterLanguageList.requestName}`,
    async (_, { rejectWithValue }) => {

        try {
            const response = await masterService.getMasterLanguageList();
            if (response.status === 200) {
                return response;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error)
            return rejectWithValue([], error)
        } finally {
            // dispatch(setLanguageListLoading(false))
        }
    }
);

// master social list
export const getMasterSocialList = createAsyncThunk(
    `${masterRedux.sliceName}/${masterRedux.masterSocialList.requestName}`,
    async (_, { rejectWithValue }) => {

        try {
            const response = await masterService.getMasterSocialList()
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error)
            return rejectWithValue([], error?.response?.data?.message || error?.response?.data?.error)
        }
    }
)