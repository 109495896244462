import { createSlice } from "@reduxjs/toolkit";

import { USER_DEFAULT_INITIAL_STATE } from "redux/default/default.initialState";

import { getUserDefaultDetailsByUserId, updateUserDefaultDetailsByUserId } from "redux/default/default.request";

const userDefault = createSlice({
    name: "userDefault",
    initialState: USER_DEFAULT_INITIAL_STATE,
    reducers: {
        // reducers for get user default details
        setClearUserDefaultDetail: (state) => {
            state.userDefaultDetail = USER_DEFAULT_INITIAL_STATE.userDefaultDetail
        },

        // reducers for add user default details
        setClearAddUserPayoutDetail: (state) => {
            state.addUserDefaultDetail = USER_DEFAULT_INITIAL_STATE.addUserDefaultDetail
        }
    },

    extraReducers: {
        // extra-reducers for user default details
        [getUserDefaultDetailsByUserId.pending]: (state) => {
            state.userDefaultDetail.isLoading = true
        },
        [getUserDefaultDetailsByUserId.fulfilled]: (state, {payload}) => {
            state.userDefaultDetail.isLoading = false
            state.userDefaultDetail.userDefaultDetail = payload
            state.userDefaultDetail.errorMsg = USER_DEFAULT_INITIAL_STATE.userDefaultDetail.errorMsg
        },
        [getUserDefaultDetailsByUserId.rejected]: (state, {payload}) => {
            state.userDefaultDetail.isLoading = false
            state.userDefaultDetail.errorMsg = payload
            state.userDefaultDetail.userDefaultDetail = USER_DEFAULT_INITIAL_STATE.userDefaultDetail.userDefaultDetail
        },

        // extra-reducers for update extra reducers details
        [updateUserDefaultDetailsByUserId.pending]: (state) => {
            state.addUserDefaultDetail.isLoading = true
        },
        [updateUserDefaultDetailsByUserId.rejected]: (state, { payload }) => {
            state.addUserDefaultDetail.isLoading = false
            state.addUserDefaultDetail.errorMsg = payload
        }
    }
})

export const {
    setClearUserDefaultDetail,
    setClearAddUserDefaultDetail
} = userDefault.actions

export default userDefault.reducer;