import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ToggleWithText } from "components/common-components/Toggle";
import ComponentLoader from "components/loader/ComponentLoader";

import { setGetOtherPref } from "pages/auth/editUser/data";
import EditUserHeader from "pages/auth/editUser/commonComponents/header/EditUserHeader";

import { getUserOtherPrefDetailByUserId, updateUserOtherPrefDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserOtherPref } from "redux/user/user.slice";

const EditOtherPreferences = () => {
    const { userOtherPref, addUserOtherPref } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher();
    const { userId } = useParams()

    useEffect(() => {
        dispatcher(getUserOtherPrefDetailByUserId(Number(userId)))

        return () => {
            dispatcher(setClearUserOtherPref())
        }
    }, [userId])

    const updateOtherStatus = (key, value) => {
        const otherPref = { [key]: value }
        const requestDataPayload = {
            userId: Number(userId),
            body: otherPref
        }
        dispatcher(updateUserOtherPrefDetailByUserId(requestDataPayload))
    }

    return (
        <div className="w-full">
            <EditUserHeader title={"Other Preferences"} />

            {(userOtherPref?.isLoading || addUserOtherPref?.isLoading) &&
                <ComponentLoader isLoading={userOtherPref?.isLoading || addUserOtherPref?.isLoading} />
            }

            {(!userOtherPref?.isLoading && !addUserOtherPref?.isLoading) &&
                <div className={"w-full p-5 space-y-2 divide-y divide-blue-50"}>
                    {userOtherPref?.userOtherPref &&
                        setGetOtherPref(userOtherPref?.userOtherPref).map((other, index) => (
                            <ToggleWithText
                                key={index}
                                description={other.description}
                                onChange={(isToggle) => updateOtherStatus(other.key, isToggle)}
                                title={other.label}
                                value={other.value}
                            />
                        ))}
                    {(userOtherPref?.errorMsg || addUserOtherPref?.errorMsg) &&
                        <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                            {userOtherPref?.errorMsg || addUserOtherPref?.errorMsg}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default EditOtherPreferences;