import { Listbox, Transition } from "@headlessui/react";
import { cn } from "utils/cn.utils";
import { Fragment } from "react";

import { FaSortDown } from "react-icons/fa";


const SelectDropDown = ({
    onChange,
    showCheckMark = false,
    options,
    value,
    className
}) => {
    const selectedOption = options?.find((o) => o.value === value) || { label: "Select a badge", value: null, image: null };

    return (
        <div className={cn("", className)}>
            <Listbox value={selectedOption.value} onChange={(value) => {
                const selected = options.find((option) => option.value === value)
                onChange(selected)
            }}>
                <div className="relative w-full">
                    <Listbox.Button className={cn(
                        "relative w-full py-2 pl-3 pr-10 text-left sm:text-sm bg-white rounded-md cursor-pointer",
                        "border-2 border-text-300 focus:outline-none focus:border-2 focus:border-text-400"
                    )}>
                        <div className={"flex items-center justify-start gap-1.5"}>
                            <div className={"w-5 h-5 rounded-sm overflow-hidden"}>
                                <img src={selectedOption?.image} className={"w-full h-full object-cover"} />
                            </div>
                            <span className="block truncate">
                                {selectedOption.label}
                            </span>
                        </div>
                        <span className="absolute inset-y-0 right-0 flex items-center pb-2 pr-2 pointer-events-none">
                            <FaSortDown
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className={cn(
                            "absolute z-20 w-full py-1 mt-1 overflow-auto max-h-60 rounded-md",
                            "text-base bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            {[{ label: "Select a badge", value: null, image: null }, ...options].map((option, optionIdx) => (
                                <Listbox.Option
                                    key={optionIdx}
                                    value={option.value}
                                    className={({ active, selected }) =>
                                        cn(
                                            `cursor-pointer select-none relative py-2 pr-4 ${showCheckMark ? "pl-10" : "pl-5"
                                            }`,
                                            selected && "text-white bg-blue-500",
                                            active && !selected && "text-blue-500 "
                                        )
                                    }
                                >
                                    {({ selected }) => (
                                        <div className={"flex items-center justify-start gap-3"}>
                                            <div className={"w-12 h-12 overflow-hidden rounded-full"}>
                                                <img
                                                    src={option?.image}
                                                    className={"w-full h-full object-cover"}
                                                />
                                            </div>
                                            <span
                                                className={`${selected ? "font-medium text-white" : "font-normal "
                                                    } block truncate`}
                                            >
                                                {option.label}
                                            </span>
                                        </div>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};

export default SelectDropDown