import { useEffect } from "react";
import { cn } from "utils/cn.utils";
import { BsFillCheckCircleFill } from 'react-icons/bs';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAddCreditPayload, resetAddCreditToWallet } from "redux/wallet/wallet.slice";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { txnType } from "redux/walletTxn/walletTxn.const";

import { currencyRateConversion } from 'utils/generators.utils';

const WalletCreditSuccessModal = () => {
    const { modal, locals } = useAppState((state) => state.local)
    const { addCreditToWallet } = useAppState((state) => state.wallet)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        return () => {
            dispatcher(resetAddCreditPayload())
            dispatcher(resetAddCreditToWallet())
        }
    }, [])


    let currency = "USD"
    switch (addCreditToWallet?.data?.txnType) {
        case txnType?.INR_WALLET?.value:
            currency = "INR"
            break;
        case txnType?.AUD_WALLET?.value:
            currency = "AUD"
            break;
        default:
            currency = "USD"
            break;
    }

    const onHandleCloseModal = () => {
        dispatcher(resetAddCreditPayload())
        dispatcher(resetAddCreditToWallet())
        dispatcher(setModal({
            ...modal,
            [modalConst.WALLET_CREDIT_SUCCESS_MODAL.stateKey]: false
        }))
    }
    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block w-full space-y-3"}>
                <div className={"w-full flex flex-col items-center justify-center gap-5 px-5 py-8"}>
                    <BsFillCheckCircleFill className={"text-8xl text-green-500"} />
                    <div className={"flex items-center justify-center gap-2 font-bodyPri font-semibold text-2xl text-primary-dark"}>
                        <span>
                            {currency}
                        </span>
                        <span>
                            {parseFloat(addCreditToWallet?.data?.amount / 100).toFixed(2)}
                        </span>
                    </div>
                    <div className={"flex items-center justify-center gap-2 font-bodyPri font-medium text-lg text-text-800"}>
                        <span>
                            {currencyRateConversion(locals?.currencyCode, addCreditToWallet?.data?.amount)}
                        </span>
                    </div>
                    <div className={"flex flex-col items-center gap-2"}>
                        <span className={""}>
                            <span className={"font-bodyPri font-medium text-md text-text-800"}>
                                {`You have added credit to `}
                            </span>
                            {addCreditToWallet?.payload?.userDetail ?
                                <span className={"font-bodyPri font-bold text-md text-text-900 tracking-wide"}>
                                    {`${addCreditToWallet?.payload?.userDetail?.firstName} ${addCreditToWallet?.payload?.userDetail?.lastName?.charAt(0)}.`}
                                </span>
                                : <span className={"font-bodyPri font-medium text-md text-text-800"}>{"user's"}</span>
                            }

                            <span className={"font-bodyPri font-medium text-md text-text-800"}>
                                {" wallet."}
                            </span>
                        </span>
                        <span className={"font-bodyPri font-semibold text-lg text-green-500 tracking-wider"}>
                            {"Successfully"}
                        </span>
                    </div>
                    <div className={"flex items-center justify-center gap-2"}>
                        <span className={"font-bodyPri font-normal text-base text-800"}>
                            {"Transaction ID:"}
                        </span>
                        <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
                            {addCreditToWallet?.data?.virtualTxnId}
                        </span>
                    </div>
                    {/* <div onClick={pageRedirect}>
                        <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
                            {"Buy a Lesson"}
                        </span>
                    </div> */}
                </div>
            </div>
            <div className="flex justify-end px-5">
                <span
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                    )}
                    onClick={onHandleCloseModal}
                >
                    {"Close"}
                </span>
            </div>
        </div>
    )
}

export default WalletCreditSuccessModal