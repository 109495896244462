import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Tabs from 'pages/auth/viewCompanyOnboarding/components/tabs/Tabs';

import { PageContentStyle, CompanySection } from "pages/auth/viewCompanyOnboarding/CompanyPage.Style";

import { tabsList } from 'pages/auth/viewCompanyOnboarding/data';
import Hero from 'pages/auth/viewCompanyOnboarding/components/hero/Hero';
import CompanyInformation from 'pages/auth/viewCompanyOnboarding/components/companyInformation/CompanyInformation';
import About from 'pages/auth/viewCompanyOnboarding/components/about/About';
import Courses from 'pages/auth/viewCompanyOnboarding/components/courses/Courses';
import People from "pages/auth/viewCompanyOnboarding/components/people/People";
import Reviews from 'pages/auth/viewCompanyOnboarding/components/reviews/Reviews';
import ImportantLinks from "pages/auth/viewCompanyOnboarding/components/importantLinks/ImportantLinks";
import DocumentVerification from 'pages/auth/viewCompanyOnboarding/components/documentVerification/DocumentVerification';
import CompanyBackgroundCheck from 'pages/auth/viewCompanyOnboarding/components/companyBackgroundCheck/CompanyBackgroundCheck';
import CompanyFAQ from 'pages/auth/viewCompanyOnboarding/components/companyFaq/CompanyFAQ';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const ViewCompanyOnboardingPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  const containerIDs = ["HOME", "ABOUT", "COURSES", "PEOPLE", "REVIEWS", "LINKS", "DOCUMENTS", "BACKGROUND_CHECK"]

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.VIEW_COMPANY_ONBOARDING))
  }, [dispatcher, currentPageInfo])

  return (
    <PageContentStyle>
      <Hero />
      <CompanyInformation />
      <CompanySection>
        <Tabs tabsList={tabsList} containerIDs={containerIDs} />
        <About />
        <Courses />
        <People />
        <Reviews />
        <ImportantLinks />
        <DocumentVerification />
        <CompanyBackgroundCheck />
        <CompanyFAQ />
      </CompanySection>
    </PageContentStyle>
  )
}

export default ViewCompanyOnboardingPage;