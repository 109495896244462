import { lmsLectureFilterProps } from "./lmsLecture.const";

export const LMS_LECTURE_INITIAL_STATE = {

    /* tutor lms lecture */
    lmsLectureList: {
        isLoading: false,
        data: null,
        message: null
    },
    destroyLmsLectureDetail: {
        isLoading: false,
        message: null
    },

    filterProps: lmsLectureFilterProps
}