import { createSlice } from "@reduxjs/toolkit";
import { CATEGORY_INITIAL_STATE } from "redux/category/category.initialState";
import { filterName } from "redux/category/category.const";

import { getAllCategoryList } from "redux/category/category.request";

const category = createSlice({
    name: "category",
    initialState: CATEGORY_INITIAL_STATE,
    reducers: {
        setClearCategoryList: (state) => {
            state.categoryList = CATEGORY_INITIAL_STATE.categoryList
        },

        // filter props for category list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
            const filteredList = payload.list
                .filter((category) => (
                    !payload.filterProps.searchText || category.category.includes(payload.filterProps.searchText)
                ))
            switch (payload.filterName) {
                case filterName.CATEGORY.value:
                    state.categoryList.filteredCategoryList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = CATEGORY_INITIAL_STATE.filterProps
        },
    },

    // extra reducers for category list
    extraReducers: {
        [getAllCategoryList.pending]: (state) => {
            state.categoryList.isLoading = true
        },
        [getAllCategoryList.fulfilled]: (state, { payload }) => {
            state.categoryList.isLoading = false
            state.categoryList.categoryList = payload.data.data
            state.categoryList.filteredCategoryList = payload.data.data
        },
        [getAllCategoryList.rejected]: (state, { payload }) => {
            state.categoryList.isLoading = false
            state.categoryList.errorMsg = payload
        }
    }
})

export const {
    setCategoryListLoading,
    setCategoryList,
    setCategoryListErrorMsg,
    setClearCategoryList,

    setFilterProps,
    setClearFilterProps
} = category.actions

export default category.reducer