import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/onboarding/onboarding.const";

class OnboardingService {
    getOnboardingList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ONBOARDING_LIST),
            { params: query }
        )

        return response;
    }

    getUserOnboardingListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_ONBOARDING_LIST, params)
        )

        return response;
    }

    getOnboardingDetailByOnboardingId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ONBOARDING_DETAIL, params)
        )

        return response;
    }

    updateOnboardingDetailByOnboardingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ONBOARDING_DETAIL, params),
            body
        )

        return response;
    }

    getOnboardingUserDetailByOnboardingId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ONBOARDING_USER_DETAIL, params)
        )

        return response;
    }

    updateOnboardingUserDetailByOnboardingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ONBOARDING_USER_DETAIL, params),
            body
        )

        return response;
    }

    getUserOnboardingQuestionDetailByOnboardingId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_ONBOARDING_QUESTION_DETAIL, params)
        )

        return response;
    }

    updateUserOnboardingQuestionDetailByOnboardingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_ONBOARDING_QUESTION_DETAIL, params),
            body
        )

        return response;
    }

    getOnboardingDocListByOnboardingId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ONBOARDING_DOC_LIST, params),
            { params: query }
        )

        return response;
    }

    createOnboardingDocDetailByOnboardingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ONBOARDING_DOC_LIST, params),
            body
        )

        return response;
    }

    updateOnboardingDocDetailByOnboardingDocId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ONBOARDING_DOC_DETAIL, params),
            body
        )

        return response;
    }

    deleteOnboardingDocDetailByOnboardingDocId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.ONBOARDING_DOC_DETAIL, params)
        )

        return response;
    }

    getOnboardingInterviewDetailByOnboardingId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ONBOARDING_INTERVIEW_DETAIL, params)
        )

        return response;
    }

    createOnboardingInterviewDetailByOnboardingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ONBOARDING_INTERVIEW_DETAIL, params),
            body
        )

        return response;
    }

    updateOnboardingInterviewDetailByOnboardingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ONBOARDING_INTERVIEW_DETAIL, params),
            body
        )

        return response;
    }

    getOnboardingTutorPrefDetailByOnboardingId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ONBOARDING_TUTOR_PREF_DETAIL, params)
        )

        return response;
    }

    updateOnboardingTutorPrefDetailByOnboardingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ONBOARDING_TUTOR_PREF_DETAIL, params),
            body
        )

        return response;
    }

    getOnboardingTutorTrialCourseDetailByOnboardingId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ONBOARDING_TRIAL_COURSE_DETAIL, params)
        )

        return response;
    }

    updateOnboardingTutorTrialCourseDetailByOnboardingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ONBOARDING_TRIAL_COURSE_DETAIL, params),
            body
        )

        return response;
    }

    getOnboardingCommentList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_ONBOARDING_COMMENT_LIST),
            { params: query }
        )

        return response;
    }

    createOnboardingCommentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADD_ONBOARDING_COMMENT_DETAIL),
            body
        )

        return response;
    }
}

export default new OnboardingService();