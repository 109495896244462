import { createSlice } from "@reduxjs/toolkit";

import { SESSION_INITIAL_STATE } from "./session.initialState";

const session = createSlice({
    name: "session",
    initialState: SESSION_INITIAL_STATE,
    reducers: {
        // reducers for session list
        setSessionListLoading: (state, { payload }) => {
            state.sessionList.isLoading = payload
        },
        setSessionListData: (state, { payload }) => {
            state.sessionList.data = payload
            state.sessionList.message = SESSION_INITIAL_STATE.sessionList.message
        },
        setSessionListMessage: (state, { payload }) => {
            state.sessionList.message = payload
            state.sessionList.data = SESSION_INITIAL_STATE.sessionList.data
        },
        resetSessionList: (state) => {
            state.sessionList = SESSION_INITIAL_STATE.sessionList
        },

        // reducers for session detail
        setSessionDetailLoading: (state, { payload }) => {
            state.sessionDetail.isLoading = payload
        },
        setSessionDetailData: (state, { payload }) => {
            state.sessionDetail.data = payload
            state.sessionDetail.message = SESSION_INITIAL_STATE.sessionDetail.message
        },
        setSessionDetailMessage: (state, { payload }) => {
            state.sessionDetail.message = payload
            state.sessionDetail.data = SESSION_INITIAL_STATE.sessionDetail.data
        },
        resetSessionDetail: (state) => {
            state.sessionDetail = SESSION_INITIAL_STATE.sessionDetail
        },

        // modify session detail payload
        setModifySessionDetailPayload: (state, { payload }) => {
            state.modifySessionDetail.payload = payload
        },
        resetModifySessionDetailPayload: (state) => {
            state.modifySessionDetail.payload = SESSION_INITIAL_STATE.modifySessionDetail.payload
        },

        // reducers for modify start session
        setModifyStartSessionDetailLoading: (state, { payload }) => {
            state.modifyStartSessionDetail.isLoading = payload
        },
        setModifyStartSessionDetailData: (state, { payload }) => {
            state.modifyStartSessionDetail.data = payload
            state.modifyStartSessionDetail.message = SESSION_INITIAL_STATE.modifyStartSessionDetail.message
        },
        setModifyStartSessionDetailMessage: (state, { payload }) => {
            state.modifyStartSessionDetail.message = payload
            state.modifyStartSessionDetail.data = SESSION_INITIAL_STATE.modifyStartSessionDetail.data
        },
        resetModifyStartSessionDetail: (state) => {
            state.modifyStartSessionDetail = SESSION_INITIAL_STATE.modifyStartSessionDetail
        },

        // reducers for modify schedule session
        setModifyScheduleSessionDetailLoading: (state, { payload }) => {
            state.modifyScheduleSessionDetail.isLoading = payload
        },
        setModifyScheduleSessionDetailData: (state, { payload }) => {
            state.modifyScheduleSessionDetail.data = payload
            state.modifyScheduleSessionDetail.message = SESSION_INITIAL_STATE.modifyScheduleSessionDetail.message
        },
        setModifyScheduleSessionDetailMessage: (state, { payload }) => {
            state.modifyScheduleSessionDetail.message = payload
            state.modifyScheduleSessionDetail.data = SESSION_INITIAL_STATE.modifyScheduleSessionDetail.data
        },
        resetModifyScheduleSessionDetail: (state) => {
            state.modifyScheduleSessionDetail = SESSION_INITIAL_STATE.modifyScheduleSessionDetail
        },

        // reducers for update decline session
        setModifyDeclineSessionDetailLoading: (state, { payload }) => {
            state.modifyDeclineSessionDetail.isLoading = payload
        },
        setModifyDeclineSessionDetailData: (state, { payload }) => {
            state.modifyDeclineSessionDetail.data = payload
            state.modifyDeclineSessionDetail.message = SESSION_INITIAL_STATE.modifyDeclineSessionDetail.message
        },
        setModifyDeclineSessionDetailMessage: (state, { payload }) => {
            state.modifyDeclineSessionDetail.message = payload
            state.modifyDeclineSessionDetail.data = SESSION_INITIAL_STATE.modifyDeclineSessionDetail.data
        },
        resetModifyDeclineSessionDetail: (state) => {
            state.modifyDeclineSessionDetail = SESSION_INITIAL_STATE.modifyDeclineSessionDetail
        },

        // reducers for update reschedule session
        setModifyRescheduleSessionDetailLoading: (state, { payload }) => {
            state.modifyRescheduleSessionDetail.isLoading = payload
        },
        setModifyRescheduleSessionDetailData: (state, { payload }) => {
            state.modifyRescheduleSessionDetail.data = payload
            state.modifyRescheduleSessionDetail.message = SESSION_INITIAL_STATE.modifyRescheduleSessionDetail.message
        },
        setModifyRescheduleSessionDetailMessage: (state, { payload }) => {
            state.modifyRescheduleSessionDetail.message = payload
            state.modifyRescheduleSessionDetail.data = SESSION_INITIAL_STATE.modifyRescheduleSessionDetail.data
        },
        resetModifyRescheduleSessionDetail: (state) => {
            state.modifyRescheduleSessionDetail = SESSION_INITIAL_STATE.modifyRescheduleSessionDetail
        },

        // reducers for complete session
        setModifyCompleteSessionDetailLoading: (state, { payload }) => {
            state.modifyCompleteSessionDetail.isLoading = payload
        },
        setModifyCompleteSessionDetailData: (state, { payload }) => {
            state.modifyCompleteSessionDetail.data = payload
            state.modifyCompleteSessionDetail.message = SESSION_INITIAL_STATE.modifyCompleteSessionDetail.message
        },
        setModifyCompleteSessionDetailMessage: (state, { payload }) => {
            state.modifyCompleteSessionDetail.message = payload
            state.modifyCompleteSessionDetail.data = SESSION_INITIAL_STATE.modifyCompleteSessionDetail.data
        },
        resetModifyCompleteSessionDetail: (state) => {
            state.modifyCompleteSessionDetail = SESSION_INITIAL_STATE.modifyCompleteSessionDetail
        },

        // reducers for session course list
        setSessionCourseListLoading: (state, { payload }) => {
            state.sessionCourseList.isLoading = payload
        },
        setSessionCourseListData: (state, { payload }) => {
            state.sessionCourseList.data = payload
            state.sessionCourseList.message = SESSION_INITIAL_STATE.sessionCourseList.message
        },
        setSessionCourseListMessage: (state, { payload }) => {
            state.sessionCourseList.message = payload
            state.sessionCourseList.data = SESSION_INITIAL_STATE.sessionCourseList.data
        },
        resetSessionCourseList: (state) => {
            state.sessionCourseList = SESSION_INITIAL_STATE.sessionCourseList
        },

        // reducers for session appointment list
        setSessionAppointmentListLoading: (state, { payload }) => {
            state.sessionAppointmentList.isLoading = payload
        },
        setSessionAppointmentListData: (state, { payload }) => {
            state.sessionAppointmentList.data = payload
            state.sessionAppointmentList.message = SESSION_INITIAL_STATE.sessionCourseList.message
        },
        setSessionAppointmentListMessage: (state, { payload }) => {
            state.sessionAppointmentList.message = payload
            state.sessionAppointmentList.data = SESSION_INITIAL_STATE.sessionCourseList.data
        },
        resetSessionAppointmentList: (state) => {
            state.sessionAppointmentList = SESSION_INITIAL_STATE.sessionCourseList
        },

        // add session user detail
        setAddSessionUserDetailLoading: (state, { payload }) => {
            state.addSessionUserDetail.isLoading = payload
        },
        setAddSessionUserDetailData: (state, { payload }) => {
            state.addSessionUserDetail.data = payload
            state.addSessionUserDetail.message = SESSION_INITIAL_STATE.addSessionUserDetail.message
        },
        setAddSessionUserDetailMessage: (state, { payload }) => {
            state.addSessionUserDetail.message = payload
            state.addSessionUserDetail.data = SESSION_INITIAL_STATE.addSessionUserDetail.data
        },
        resetAddSessionUserDetail: (state) => {
            state.addSessionUserDetail = SESSION_INITIAL_STATE.addSessionUserDetail
        },

        // update session user detail payload (logged in users session payload)
        setAddSessionUserDetailPayload: (state, { payload }) => {
            state.addSessionUserDetail.payload = payload
        },
        resetAddSessionUserDetailPayload: (state) => {
            state.addSessionUserDetail.payload = SESSION_INITIAL_STATE.addSessionUserDetail.payload
        },

        // reducers for update session user detail
        setModifySessionUserDetailLoading: (state, { payload }) => {
            state.modifySessionUserDetail.isLoading = payload
        },
        setModifySessionUserDetailData: (state, { payload }) => {
            state.modifySessionUserDetail.data = payload
            state.modifySessionUserDetail.message = SESSION_INITIAL_STATE.modifySessionUserDetail.message
        },
        setModifySessionUserDetailMessage: (state, { payload }) => {
            state.modifySessionUserDetail.message = payload
            state.modifySessionUserDetail.data = SESSION_INITIAL_STATE.modifySessionUserDetail.data
        },
        resetModifySessionUserDetail: (state) => {
            state.modifySessionUserDetail = SESSION_INITIAL_STATE.modifySessionUserDetail
        },

        // reducers for update session user detail payload (add participants in session detail panel)
        setModifySessionUserDetailPayload: (state, { payload }) => {
            state.modifySessionUserDetail.payload = payload
        },
        resetModifySessionUserDetailPayload: (state) => {
            state.modifySessionUserDetail.payload = SESSION_INITIAL_STATE.modifySessionUserDetail.payload
        },

        // reducers for session filters
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = SESSION_INITIAL_STATE.filterProps
        },
    },
})

export const {
    setSessionListLoading,
    setSessionListData,
    setSessionListMessage,
    resetSessionList,

    setSessionDetailLoading,
    setSessionDetailData,
    setSessionDetailMessage,
    resetSessionDetail,

    setModifySessionDetailPayload,
    resetModifySessionDetailPayload,

    setModifyStartSessionDetailLoading,
    setModifyStartSessionDetailData,
    setModifyStartSessionDetailMessage,
    resetModifyStartSessionDetail,

    setModifyScheduleSessionDetailLoading,
    setModifyScheduleSessionDetailData,
    setModifyScheduleSessionDetailMessage,
    resetModifyScheduleSessionDetail,

    setModifyDeclineSessionDetailLoading,
    setModifyDeclineSessionDetailData,
    setModifyDeclineSessionDetailMessage,
    resetModifyDeclineSessionDetail,

    setModifyRescheduleSessionDetailLoading,
    setModifyRescheduleSessionDetailData,
    setModifyRescheduleSessionDetailMessage,
    resetModifyRescheduleSessionDetail,

    setModifyCompleteSessionDetailLoading,
    setModifyCompleteSessionDetailData,
    setModifyCompleteSessionDetailMessage,
    resetModifyCompleteSessionDetail,

    setSessionCourseListLoading,
    setSessionCourseListData,
    setSessionCourseListMessage,
    resetSessionCourseList,

    setSessionAppointmentListLoading,
    setSessionAppointmentListData,
    setSessionAppointmentListMessage,
    resetSessionAppointmentList,

    setAddSessionUserDetailLoading,
    setAddSessionUserDetailData,
    setAddSessionUserDetailMessage,
    resetAddSessionUserDetail,

    setAddSessionUserDetailPayload,
    resetAddSessionUserDetailPayload,

    setModifySessionUserDetailLoading,
    setModifySessionUserDetailData,
    setModifySessionUserDetailMessage,
    resetModifySessionUserDetail,

    setModifySessionUserDetailPayload,
    resetModifySessionUserDetailPayload,

    setFilterProps,
    resetFilterProps,
} = session.actions;
export default session.reducer;