import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from "pages/auth/wallet/data";
import WithdrawalSearch from 'pages/auth/withdrawal/components/WithdrawalSearch';
import DisplayWithdrawalTable from 'pages/auth/withdrawal/components/DisplayWithdrawalTable';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const WithdrawalPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.WITHDRAWALS))
  }, [dispatcher, currentPageInfo])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
      <WithdrawalSearch />
      <DisplayWithdrawalTable />
    </div>
  )
};

export default WithdrawalPage;
