import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { MdAdd } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";

import FullPageLoader from "components/loader/FullPageLoader";
// import { OptionSelector } from "components/common-components/Select";

import { editDocumentTableHeader } from "pages/auth/editTutorOnboard/data";

import {
    getOnboardingDocListByOnboardingId,
    createOnboardingDocDetailByOnboardingId,
    // updateOnboardingDocDetailByOnboardingDocId,
    deleteOnboardingDocDetailByOnboardingDocId
} from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddOnboardingDocDetailPayload, setClearAddOnboardingDocDetailPayload, setClearOnboardingDocList } from "redux/onboarding/onboarding.slice";
import { docType, documentStatus, videoType } from "redux/onboarding/onboarding.const";
import { setUploadFilePayload, resetUploadFile } from "redux/storage/storage.slice";
// import { setClearUploadDocument } from "redux/document/document.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

const EditUploadDocuments = () => {
    const { modal } = useAppState((state) => state.local)
    // const { uploadDocument } = useAppState((state) => state.document)
    const { uploadFile } = useAppState((state) => state.storage)
    const { onboardingDocList, addOnboardingDocDetail, onboardingDetail } = useAppState((s) => s.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    // const [docStatus, setDocStatus] = useState(documentStatus?.PENDING?.value)

    const filteredDocumentList = onboardingDocList?.onboardingDocList?.filter((document) => document?.docType !== videoType?.INTRO_VIDEO?.value)

    useEffect(() => {
        if (onboardingId) {
            const requestPayload = {
                onboardingId: Number(onboardingId),
                query: {}
            }
            dispatcher(getOnboardingDocListByOnboardingId(requestPayload))
        }

        return () => {
            dispatcher(setClearOnboardingDocList())
        }
    }, [onboardingId])

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0) {
            const requestData = {
                onboardingId: Number(onboardingId),
                body: {
                    docType: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileCategory || Object.values(docType)[0]?.value,
                    docName: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName,
                    docUrl: uploadFile?.data[0]?.fileUrl,
                }
            }
            dispatcher(createOnboardingDocDetailByOnboardingId(requestData))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    // const onHandleSave = () => {
    // }

    const handleDeleteDocument = (onboardingDocId) => {
        dispatcher(deleteOnboardingDocDetailByOnboardingDocId(onboardingDocId))
    }

    const handleAddDocument = () => {
        dispatcher(setClearAddOnboardingDocDetailPayload())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            userId: onboardingDetail?.onboardingDetail?.user?.userId
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true
        }))
    }

    const handleEditDocument = (document) => {
        dispatcher(setClearAddOnboardingDocDetailPayload())
        dispatcher(setAddOnboardingDocDetailPayload({
            documentId: document?.onboardingDocId,
            fileCategory: document?.docType,
            fileName: document?.docName,
            fileUrl: document?.docUrl,
            categoryList: Object.values(docType),
            status: document?.status
        }))
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            userId: onboardingDetail?.onboardingDetail?.user?.userId
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true
        }))
        // const requestData = {
        //     onboardingDocId: onboardingDocId,
        //     body: {
        //         status: "approved"
        //     }
        // }
        // dispatcher(updateOnboardingDocDetailByOnboardingDocId(requestData))
    }

    if (onboardingDocList?.isLoading || addOnboardingDocDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingDocList?.isLoading || addOnboardingDocDetail?.isLoading} />
        )
    }

    if (onboardingDocList?.errorMsg || addOnboardingDocDetail?.errorMsg) {
        return (
            <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-center text-red-500 text-sm"}>
                {onboardingDocList?.errorMsg || addOnboardingDocDetail?.errorMsg}
            </div>
        )
    }

    return (
        <div className={"space-y-5 px-5 py-3"}>
            <div className={"flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg"}>
                {"Document Verification"}
            </div>
            {filteredDocumentList?.length > 0 &&
                <div className={cn(
                    " min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow overflow-x-auto",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>
                    <table className={"w-full border-separate rounded-lg"}>
                        <thead>
                            <tr>
                                {editDocumentTableHeader?.map((head, index) => (
                                    <th
                                        key={index}
                                        className={cn(
                                            "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                            "font-bold whitespace-nowrap border border-gray-200"
                                        )}
                                    >
                                        {head}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={"divide-y divide-text-200"}>
                            {filteredDocumentList?.map((document, index) => (
                                <tr className={''} key={index}>
                                    <td className="border border-gray-200">
                                        <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                                            {docType[document?.docType?.toUpperCase()]?.label}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <a
                                            href={document?.docUrl}
                                            target={"_blank"}
                                            className='px-5 py-2 font-bodyPri font-medium text-primary-main text-base text-center truncate'>
                                            {document?.docName}
                                        </a>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className={`px-2 font-bodyPri font-normal text-base italic text-center text-${documentStatus[document?.status?.toUpperCase()]?.textColor}`}>
                                            {documentStatus[document?.status?.toUpperCase()]?.textName}
                                        </span>
                                    </td>
                                    <td className={"px-5 py-2 border border-gray-200"}>
                                        <FaEdit
                                            className={"text-text-500 text-xl cursor-pointer"}
                                            onClick={() => handleEditDocument(document)}
                                        />
                                    </td>
                                    <td className={"px-5 py-2 border border-gray-200"}>
                                        <GiCancel
                                            className={"text-red-500 text-xl cursor-pointer"}
                                            onClick={() => handleDeleteDocument(document?.onboardingDocId)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {(filteredDocumentList?.length === 0) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-text-700 text-base"}>
                    {"No records found!"}
                </div>
            }
            <div
                className={"w-fit flex items-center justify-start rounded-lg cursor-pointer group"}
                onClick={handleAddDocument}
            >
                <MdAdd className={"text-lg text-text-700 group-hover:text-text-900"} />
                <span className={"font-bodyPri font-normal text-base text-text-700 group-hover:text-text-900"}>
                    {"Add Document"}
                </span>
            </div>
        </div>
    )
}

export default EditUploadDocuments;