import { createSlice } from "@reduxjs/toolkit";
import { LINK_TREE_INITIAL_STATE } from "./linkTree.initialState";

const linkInBio = createSlice({
    name: "linkInBio",
    initialState: LINK_TREE_INITIAL_STATE,
    reducers: {}
})

export const {
    setAddLinkInBioLoading,
    setAddLinkInBioData,
    seAddLinkInBioMessage,
    setClearAddLinkInBio,

    setUpdateLinkLoading,
    setUpdateLinkData,
    seUpdateLinkMessage,
    setClearUpdateLink,

    setDeleteLinkLoading,
    seDeleteLinkMessage,
    setClearDeleteLink,

    setAddLinkLoading,
    setAddLinkData,
    seAddLinkMessage,
    setClearAddLink,

    setReorderLinkLoading,
    seReorderLinkMessage,
    setClearReorderLink,
} = linkInBio.actions

export default linkInBio.reducer