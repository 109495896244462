import {
    PageContentStyle,
    Container,
    UnauthorizedCard,
    UnauthorizedCardHeader,
    UnauthorizedContentContainer,
    UnAuthorizedContent,
    Heading,
    SubHeading,
    HomeButtons,
    BackButton,
} from "pages/global/unauthorized/UnauthorizedPageStyle";

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const UnauthorizedPage = ({ errorMsg = "Unauthorized Access" }) => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()
    // const navigate = useNavigate();

    // const goBack = () => navigate(-1);

    const handleRoleSwitch = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
        }))
    }

    return (
        <PageContentStyle>
            <Container>
                <UnauthorizedCard>
                    <UnauthorizedCardHeader>
                        <ReportProblemOutlinedIcon className={"text-yellow-400 drop-shadow-lg rounded-full"} sx={{ fontSize: 60 }} />
                        <UnauthorizedContentContainer>
                            <UnAuthorizedContent>
                                <Heading>
                                    {errorMsg}
                                </Heading>
                                <SubHeading>
                                    {"Your Account does not have access to this page. Please switch or login your account"}
                                </SubHeading>
                            </UnAuthorizedContent>
                            <HomeButtons>
                                {/* <BackButton onClick={goBack}>
                                    {"Go Back"}
                                </BackButton> */}
                                <BackButton onClick={handleRoleSwitch}>
                                    {"Switch Role"}
                                </BackButton>
                                {/* <HomePage>
                                    <Link to={"/"}>{"Visit Our Homepage"}</Link>
                                </HomePage> */}
                            </HomeButtons>
                        </UnauthorizedContentContainer>
                    </UnauthorizedCardHeader>
                </UnauthorizedCard>
            </Container>
        </PageContentStyle>
    )
}

export default UnauthorizedPage
