import { createSlice } from "@reduxjs/toolkit";

import { COURSE_INITIAL_STATE } from "redux/course/course.initialState";

import {
    getCourseList,
    getCourseTutorList,
} from "redux/course/course.request";

const course = createSlice({
    name: "course",
    initialState: COURSE_INITIAL_STATE,
    reducers: {
        // reducers for course list
        setCourseListLoading: (state, { payload }) => {
            state.courseList.isLoading = payload
        },
        setCourseListErrorMsg: (state, { payload }) => {
            state.courseList.message = payload
            state.courseList.data = COURSE_INITIAL_STATE.courseList.data
        },
        setClearCourseList: (state) => {
            state.courseList = COURSE_INITIAL_STATE.courseList
        },

        // reducers for public course detail
        setPublicCourseDetailLoading: (state, { payload }) => {
            state.publicCourseDetail.isLoading = payload
        },
        setPublicCourseDetailData: (state, { payload }) => {
            state.publicCourseDetail.data = payload
            state.publicCourseDetail.message = COURSE_INITIAL_STATE.publicCourseDetail.message
        },
        setPublicCourseDetailMessage: (state, { payload }) => {
            state.publicCourseDetail.message = payload
            state.publicCourseDetail.data = COURSE_INITIAL_STATE.publicCourseDetail.data
        },
        setClearPublicCourseDetail: (state) => {
            state.publicCourseDetail = COURSE_INITIAL_STATE.publicCourseDetail
        },

        // Selected Public Course Detail
        setSelectedPublicCourseDetailLoading: (state, { payload }) => {
            state.selectedPublicCourseDetail.isLoading = payload
        },
        setSelectedPublicCourseDetail: (state, { payload }) => {
            state.selectedPublicCourseDetail.data = payload
            state.selectedPublicCourseDetail.message = COURSE_INITIAL_STATE.selectedPublicCourseDetail.message
        },
        setSelectedPublicCourseDetailMessage: (state, { payload }) => {
            state.selectedPublicCourseDetail.message = payload
            state.selectedPublicCourseDetail.data = COURSE_INITIAL_STATE.selectedPublicCourseDetail.data
        },
        resetSelectedPublicCourseDetail: (state) => {
            state.selectedPublicCourseDetail = COURSE_INITIAL_STATE.selectedPublicCourseDetail
        },

        // template course list
        setCourseTemplateListLoading: (state, { payload }) => {
            state.courseTemplateList.isLoading = payload
        },
        setCourseTemplateList: (state, { payload }) => {
            state.courseTemplateList.data = payload
        },
        setCourseTemplateListErrorMsg: (state, { payload }) => {
            state.courseTemplateList.message = payload
            state.courseTemplateList.data = COURSE_INITIAL_STATE.courseTemplateList.data
        },
        setClearCourseTemplateList: (state) => {
            state.courseTemplateList = COURSE_INITIAL_STATE.courseTemplateList
        },

        // template course detail
        setCourseTemplateDetailLoading: (state, { payload }) => {
            state.courseTemplateDetail.isLoading = payload
        },
        setCourseTemplateDetail: (state, { payload }) => {
            state.courseTemplateDetail.data = payload
            state.courseTemplateDetail.message = COURSE_INITIAL_STATE.courseTemplateDetail.message
        },
        setCourseTemplateDetailErrorMsg: (state, { payload }) => {
            state.courseTemplateDetail.message = payload
            state.courseTemplateDetail.data = COURSE_INITIAL_STATE.courseTemplateDetail.data
        },
        setClearCourseTemplateDetail: (state) => {
            state.courseTemplateDetail = COURSE_INITIAL_STATE.courseTemplateDetail
        },

        // template course detail payload
        setCourseTemplateDetailPayload: (state, { payload }) => {
            state.courseTemplateDetail.payload = payload
        },
        setClearCourseTemplateDetailPayload: (state) => {
            state.courseTemplateDetail.payload = COURSE_INITIAL_STATE.courseTemplateDetail.payload
        },

        // reducers for course details
        setCourseDetailLoading: (state, { payload }) => {
            state.courseDetail.isLoading = payload
        },
        setCourseDetail: (state, { payload }) => {
            state.courseDetail.courseDetail = payload
            state.courseDetail.errorMsg = COURSE_INITIAL_STATE.courseDetail.errorMsg
        },
        setCourseDetailErrorMsg: (state, { payload }) => {
            state.courseDetail.errorMsg = payload
            state.courseDetail.courseDetail = COURSE_INITIAL_STATE.courseDetail.courseDetail
        },
        setClearCourseDetail: (state) => {
            state.courseDetail = COURSE_INITIAL_STATE.courseDetail
        },

        // reducers for update course Detail
        setAddCourseDetailLoading: (state, { payload }) => {
            state.addCourseDetail.isLoading = payload
        },
        setAddCourseDetail: (state, { payload }) => {
            state.addCourseDetail.addCourseDetail = payload
        },
        setAddCourseDetailErrorMsg: (state, { payload }) => {
            state.addCourseDetail.errorMsg = payload
            state.addCourseDetail.addCourseDetail = COURSE_INITIAL_STATE.addCourseDetail.addCourseDetail
        },
        setClearAddCourseDetail: (state) => {
            state.addCourseDetail = COURSE_INITIAL_STATE.addCourseDetail
        },

        // reducers for course detail payload
        setCourseDetailPayload: (state, { payload }) => {
            state.courseDetail.courseDetailPayload = payload
        },
        setClearCourseDetailPayload: (state) => {
            state.courseDetail.courseDetailPayload = COURSE_INITIAL_STATE.courseDetail.courseDetailPayload
        },

        // course tag list
        setCourseTagListLoading: (state, { payload }) => {
            state.courseTagList.isLoading = payload
        },
        setCourseTagList: (state, { payload }) => {
            state.courseTagList.courseTagList = payload
            state.courseTagList.errorMsg = COURSE_INITIAL_STATE.courseTagList.errorMsg
        },
        setCourseTagListErrorMsg: (state, { payload }) => {
            state.courseTagList.errorMsg = payload
            state.courseTagList.courseTagList = COURSE_INITIAL_STATE.courseTagList.courseTagList
        },
        setClearCourseTagList: (state) => {
            state.courseTagList = COURSE_INITIAL_STATE.courseTagList
        },

        // add course tag detail
        setAddCourseTagDetailLoading: (state, { payload }) => {
            state.addCourseTagDetail.isLoading = payload
        },
        setAddCourseTagDetail: (state, { payload }) => {
            state.addCourseTagDetail.addCourseTagDetail = payload
            state.addCourseTagDetail.errorMsg = COURSE_INITIAL_STATE.addCourseTagDetail.errorMsg
        },
        setAddCourseTagDetailErrorMsg: (state, { payload }) => {
            state.addCourseTagDetail.errorMsg = payload
            state.addCourseTagDetail.addCourseTagDetail = COURSE_INITIAL_STATE.addCourseTagDetail.addCourseTagDetail
        },
        setClearAddCourseTagDetail: (state) => {
            state.addCourseTagDetail = COURSE_INITIAL_STATE.addCourseTagDetail
        },

        //course language list
        setCourseLanguageListLoading: (state, { payload }) => {
            state.courseLanguageList.isLoading = payload
        },
        setCourseLanguageList: (state, { payload }) => {
            state.courseLanguageList.courseLanguageList = payload
            state.courseLanguageList.errorMsg = COURSE_INITIAL_STATE.courseLanguageList.errorMsg
        },
        setCourseLanguageListErrorMsg: (state, { payload }) => {
            state.courseLanguageList.errorMsg = payload
            state.courseLanguageList.courseLanguageList = COURSE_INITIAL_STATE.courseLanguageList.courseLanguageList
        },
        setClearCourseLanguageList: (state) => {
            state.courseLanguageList = COURSE_INITIAL_STATE.courseLanguageList
        },

        // add course language
        setAddCourseLanguageDetailLoading: (state, { payload }) => {
            state.addCourseLanguageDetail.isLoading = payload
        },
        setAddCourseLanguageDetail: (state, { payload }) => {
            state.addCourseLanguageDetail.addCourseLanguageDetail = payload
            state.addCourseLanguageDetail.errorMsg = COURSE_INITIAL_STATE.addCourseLanguageDetail.errorMsg
        },
        setAddCourseLanguageDetailErrorMsg: (state, { payload }) => {
            state.addCourseLanguageDetail.errorMsg = payload
            state.addCourseLanguageDetail.addCourseLanguageDetail = COURSE_INITIAL_STATE.addCourseLanguageDetail.addCourseLanguageDetail
        },
        setClearAddCourseLanguageDetail: (state) => {
            state.addCourseLanguageDetail = COURSE_INITIAL_STATE.addCourseLanguageDetail
        },

        // course prof list 
        setCourseProfListLoading: (state, { payload }) => {
            state.courseProfList.isLoading = payload
        },
        setCourseProfList: (state, { payload }) => {
            state.courseProfList.courseProfList = payload
            state.courseProfList.errorMsg = COURSE_INITIAL_STATE.courseProfList.errorMsg
        },
        setCourseProfListErrorMsg: (state, { payload }) => {
            state.courseProfList.errorMsg = payload
            state.courseProfList.courseProfList = COURSE_INITIAL_STATE.courseProfList.courseProfList
        },
        setClearCourseProficiencyList: (state) => {
            state.courseProfList = COURSE_INITIAL_STATE.courseProfList
        },

        // add course proficiency
        setAddCourseProfDetailLoading: (state, { payload }) => {
            state.addCourseProfDetail.isLoading = payload
        },
        setAddCourseProfDetail: (state, { payload }) => {
            state.addCourseProfDetail.addCourseProfDetail = payload
            state.addCourseProfDetail.errorMsg = COURSE_INITIAL_STATE.addCourseProfDetail.errorMsg
        },
        setAddCourseProfDetailErrorMsg: (state, { payload }) => {
            state.addCourseProfDetail.errorMsg = payload
            state.addCourseProfDetail.addCourseProfDetail = COURSE_INITIAL_STATE.addCourseProfDetail.addCourseProfDetail
        },
        setClearAddCourseProfDetail: (state) => {
            state.addCourseProfDetail = COURSE_INITIAL_STATE.addCourseProfDetail
        },

        //course availability detail
        setCourseAvailDetailLoading: (state, { payload }) => {
            state.courseAvailDetail.isLoading = payload
        },
        setCourseAvailDetail: (state, { payload }) => {
            state.courseAvailDetail.courseAvailDetail = payload
            state.courseAvailDetail.errorMsg = COURSE_INITIAL_STATE.courseAvailDetail.errorMsg
        },
        setCourseAvailErrorMsg: (state, { payload }) => {
            state.courseAvailDetail.errorMsg = payload
            state.courseAvailDetail.courseAvailDetail = COURSE_INITIAL_STATE.courseAvailDetail.courseAvailDetail
        },
        setClearCourseAvailDetail: (state) => {
            state.courseAvailDetail = COURSE_INITIAL_STATE.courseAvailDetail
        },

        // add course availability detail
        setAddCourseAvailDetailLoading: (state, { payload }) => {
            state.addCourseAvailDetail.isLoading = payload
        },
        setAddCourseAvailDetail: (state, { payload }) => {
            state.addCourseAvailDetail.addCourseAvailDetail = payload
            state.addCourseAvailDetail.errorMsg = COURSE_INITIAL_STATE.addCourseAvailDetail.errorMsg
        },
        setAddCourseAvailDetailErrorMsg: (state, { payload }) => {
            state.addCourseAvailDetail.errorMsg = payload
            state.addCourseAvailDetail.addCourseAvailDetail = COURSE_INITIAL_STATE.addCourseAvailDetail.addCourseAvailDetail
        },
        setClearAddCourseAvailDetail: (state) => {
            state.addCourseAvailDetail = COURSE_INITIAL_STATE.addCourseAvailDetail
        },

        //course avail detail payload
        setAddCourseAvailDetailPayload: (state, { payload }) => {
            state.addCourseAvailDetail.addCourseAvailDetailPayload = payload
        },
        setClearAddCourseAvailDetailPayload: (state) => {
            state.addCourseAvailDetail.addCourseAvailDetailPayload = COURSE_INITIAL_STATE.addCourseAvailDetail.addCourseAvailDetailPayload
        },

        // course price detail
        setCoursePriceDetailLoading: (state, { payload }) => {
            state.coursePriceDetail.isLoading = payload
        },
        setCoursePriceDetail: (state, { payload }) => {
            state.coursePriceDetail.coursePriceDetail = payload
            state.coursePriceDetail.errorMsg = COURSE_INITIAL_STATE.coursePriceDetail.errorMsg
        },
        setCoursePriceDetailErrorMsg: (state, { payload }) => {
            state.coursePriceDetail.coursePriceDetail = payload
            state.coursePriceDetail.coursePriceDetail = COURSE_INITIAL_STATE.coursePriceDetail.coursePriceDetail
        },
        setClearCoursePriceDetail: (state) => {
            state.coursePriceDetail = COURSE_INITIAL_STATE.coursePriceDetail
        },

        // add course price detail
        setAddCoursePriceDetailLoading: (state, { payload }) => {
            state.addCoursePriceDetail.isLoading = payload
        },
        setAddCoursePriceDetail: (state, { payload }) => {
            state.addCoursePriceDetail.addCoursePriceDetail = payload
            state.addCoursePriceDetail.errorMsg = COURSE_INITIAL_STATE.addCoursePriceDetail.errorMsg
        },
        setAddCoursePriceDetailErrorMsg: (state, { payload }) => {
            state.addCoursePriceDetail.errorMsg = payload
            state.addCoursePriceDetail.addCoursePriceDetail = COURSE_INITIAL_STATE.addCoursePriceDetail.addCoursePriceDetail
        },
        setClearAddCoursePriceDetail: (state) => {
            state.addCoursePriceDetail = COURSE_INITIAL_STATE.addCoursePriceDetail
        },

        // course comment list
        setCourseCommentListLoading: (state, { payload }) => {
            state.courseCommentList.isLoading = payload
        },
        setCourseCommentListData: (state, { payload }) => {
            state.courseCommentList.data = payload
            state.courseCommentList.message = COURSE_INITIAL_STATE.courseCommentList.message
        },
        setCourseCommentListMessage: (state, { payload }) => {
            state.courseCommentList.message = payload
            state.courseCommentList.data = COURSE_INITIAL_STATE.courseCommentList.data
        },
        resetCourseCommentList: (state) => {
            state.courseCommentList = COURSE_INITIAL_STATE.courseCommentList
        },
        // add course comment detail
        setAddCourseCommentDetailLoading: (state, { payload }) => {
            state.addCourseCommentDetail.isLoading = payload
        },
        setAddCourseCommentDetailMessage: (state, { payload }) => {
            state.addCourseCommentDetail.message = payload
        },
        resetAddCourseCommentDetail: (state) => {
            state.addCourseCommentDetail = COURSE_INITIAL_STATE.addCourseCommentDetail
        },

        // reducers for course preview detail
        setCoursePreviewDetailLoading: (state, { payload }) => {
            state.coursePreviewDetail.isLoading = payload
        },
        setCoursePreviewDetailData: (state, { payload }) => {
            state.coursePreviewDetail.data = payload
            state.coursePreviewDetail.message = COURSE_INITIAL_STATE.coursePreviewDetail.message
        },
        setCoursePreviewDetailMessage: (state, { payload }) => {
            state.coursePreviewDetail.message = payload
            state.coursePreviewDetail.data = COURSE_INITIAL_STATE.coursePreviewDetail.data
        },
        resetCoursePreviewDetail: (state) => {
            state.coursePreviewDetail = COURSE_INITIAL_STATE.coursePreviewDetail
        },

        // reducers for create next course session detail
        setNextCourseSessionDetailLoading: (state, { payload }) => {
            state.nextCourseSessionDetail.isLoading = payload
        },
        setNextCourseSessionDetailData: (state, { payload }) => {
            state.nextCourseSessionDetail.data = payload
            state.nextCourseSessionDetail.message = COURSE_INITIAL_STATE.nextCourseSessionDetail.message
        },
        setNextCourseSessionDetailMessage: (state, { payload }) => {
            state.nextCourseSessionDetail.message = payload
            state.nextCourseSessionDetail.data = COURSE_INITIAL_STATE.nextCourseSessionDetail.data
        },
        resetNextCourseSessionDetail: (state) => {
            state.nextCourseSessionDetail = COURSE_INITIAL_STATE.nextCourseSessionDetail
        },

        // reducers for create next course session payload
        setNextCourseSessionDetailPayload: (state, { payload }) => {
            state.nextCourseSessionDetail.payload = payload
        },
        resetNextCourseSessionDetailPayload: (state) => {
            state.nextCourseSessionDetail.payload = COURSE_INITIAL_STATE.nextCourseSessionDetail.payload
        },

        // reducers for course tutor list
        setCourseTutorListLoading: (state, { payload }) => {
            state.courseTutorList.isLoading = payload
        },
        setCourseTutorListData: (state, { payload }) => {
            state.courseTutorList.data = payload
            state.courseTutorList.message = COURSE_INITIAL_STATE.courseTutorList.message
        },
        setCourseTutorListMessage: (state, { payload }) => {
            state.courseTutorList.message = payload
            state.courseTutorList.data = COURSE_INITIAL_STATE.courseTutorList.data
        },
        resetCourseTutorList: (state) => {
            state.courseTutorList = COURSE_INITIAL_STATE.courseTutorList
        },

        // reducers for course tutor detail
        setCourseTutorDetailLoading: (state, { payload }) => {
            state.courseTutorDetail.isLoading = payload
        },
        setCourseTutorDetailData: (state, { payload }) => {
            state.courseTutorDetail.data = payload
            state.courseTutorDetail.message = COURSE_INITIAL_STATE.courseTutorDetail.message
        },
        setCourseTutorDetailMessage: (state, { payload }) => {
            state.courseTutorDetail.message = payload
            state.courseTutorDetail.data = COURSE_INITIAL_STATE.courseTutorDetail.data
        },
        resetCourseTutorDetail: (state) => {
            state.courseTutorDetail = COURSE_INITIAL_STATE.courseTutorDetail
        },

        // reducers for add course tutor detail
        setAddCourseTutorDetailLoading: (state, { payload }) => {
            state.addCourseTutorDetail.isLoading = payload
        },
        setAddCourseTutorDetailData: (state, { payload }) => {
            state.addCourseTutorDetail.data = payload
            state.addCourseTutorDetail.message = COURSE_INITIAL_STATE.addCourseTutorDetail.message
        },
        setAddCourseTutorDetailMessage: (state, { payload }) => {
            state.addCourseTutorDetail.message = payload
            state.addCourseTutorDetail.data = COURSE_INITIAL_STATE.addCourseTutorDetail.data
        },
        resetAddCourseTutorDetail: (state) => {
            state.addCourseTutorDetail = COURSE_INITIAL_STATE.addCourseTutorDetail
        },

        // reducers for add course tutor detail payload
        setAddCourseTutorDetailPayload: (state, { payload }) => {
            state.addCourseTutorDetail.payload = payload
        },
        resetAddCourseTutorDetailPayload: (state) => {
            state.addCourseTutorDetail.payload = COURSE_INITIAL_STATE.addCourseTutorDetail.payload
        },

        // reducers for update course tutor detail
        setUpdateCourseTutorDetailLoading: (state, { payload }) => {
            state.updateCourseTutor.isLoading = payload
        },
        setUpdateCourseTutorDetailData: (state, { payload }) => {
            state.updateCourseTutor.data = payload
            state.updateCourseTutor.message = COURSE_INITIAL_STATE.updateCourseTutor.message
        },
        setUpdateCourseTutorDetailMessage: (state, { payload }) => {
            state.updateCourseTutor.message = payload
            state.updateCourseTutor.data = COURSE_INITIAL_STATE.updateCourseTutor.data
        },
        resetUpdateCourseTutorDetail: (state) => {
            state.updateCourseTutor = COURSE_INITIAL_STATE.updateCourseTutor
        },

        // reducers for delete course tutor detail
        setDeleteCourseTutorDetailLoading: (state, { payload }) => {
            state.deleteCourseTutor.isLoading = payload
        },
        setDeleteCourseTutorDetailMessage: (state, { payload }) => {
            state.deleteCourseTutor.message = payload
        },
        resetDeleteCourseTutorDetail: (state) => {
            state.deleteCourseTutor = COURSE_INITIAL_STATE.deleteCourseTutor
        },

        // reducers for course config detail
        setCourseConfigDetailLoading: (state, { payload }) => {
            state.courseConfigDetail.isLoading = payload
        },
        setCourseConfigDetailData: (state, { payload }) => {
            state.courseConfigDetail.data = payload
            state.courseConfigDetail.message = COURSE_INITIAL_STATE.courseConfigDetail.message
        },
        setCourseConfigDetailMessage: (state, { payload }) => {
            state.courseConfigDetail.message = payload
            state.courseConfigDetail.data = COURSE_INITIAL_STATE.courseConfigDetail.data
        },
        resetCourseConfigDetail: (state) => {
            state.courseConfigDetail = COURSE_INITIAL_STATE.courseConfigDetail
        },

        // reducers for add course config detail
        setAddCourseConfigDetailLoading: (state, { payload }) => {
            state.addCourseConfig.isLoading = payload
        },
        // setAddCourseConfigDetailData: (state, { payload }) => {
        //     state.addCourseConfigDetail.data = payload
        //     state.addCourseConfigDetail.message = COURSE_INITIAL_STATE.courseConfigDetail.message
        // },
        setAddCourseConfigDetailMessage: (state, { payload }) => {
            state.addCourseConfig.message = payload
        },
        resetAddCourseConfigDetail: (state) => {
            state.addCourseConfig = COURSE_INITIAL_STATE.addCourseConfig
        },

        // reducers for update course config detail
        setUpdateCourseConfigDetailLoading: (state, { payload }) => {
            state.updateCourseConfig.isLoading = payload
        },
        setUpdateCourseConfigDetailMessage: (state, { payload }) => {
            state.updateCourseConfig.message = payload
        },
        resetUpdateCourseConfigDetail: (state) => {
            state.updateCourseConfig = COURSE_INITIAL_STATE.updateCourseConfig
        },

        // reducers for delete course config detail
        setDeleteCourseConfigDetailLoading: (state, { payload }) => {
            state.deleteCourseConfig.isLoading = payload
        },
        setDeleteCourseConfigDetailMessage: (state, { payload }) => {
            state.deleteCourseConfig.message = payload
        },
        resetDeleteCourseConfigDetail: (state) => {
            state.deleteCourseConfig = COURSE_INITIAL_STATE.deleteCourseConfig
        },

        // clone course detail
        setCloneCourseDetailLoading: (state, { payload }) => {
            state.cloneCourseDetail.isLoading = payload
        },
        setCloneCourseDetailData: (state, { payload }) => {
            state.cloneCourseDetail.data = payload
            state.cloneCourseDetail.message = COURSE_INITIAL_STATE.cloneCourseDetail.message
        },
        setCloneCourseDetailMessage: (state, { payload }) => {
            state.cloneCourseDetail.message = payload
            state.cloneCourseDetail.data = COURSE_INITIAL_STATE.cloneCourseDetail.data
        },
        resetCloneCourseDetail: (state) => {
            state.cloneCourseDetail = COURSE_INITIAL_STATE.cloneCourseDetail
        },

        // reducers for filtering the list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
        },
        setClearFilterProps: (state) => {
            state.filterProps = COURSE_INITIAL_STATE.filterProps
        }
    },
    extraReducers: {
        // reducers for course list
        [getCourseList.pending]: (state) => {
            state.courseList.isLoading = true
        },
        [getCourseList.fulfilled]: (state, { payload }) => {
            state.courseList.isLoading = false
            state.courseList.data = payload
            state.courseList.message = COURSE_INITIAL_STATE.courseList.message
        },
        [getCourseList.rejected]: (state, { payload }) => {
            state.courseList.isLoading = false
            state.courseList.message = payload
            state.courseList.data = COURSE_INITIAL_STATE.courseList.data
        },
    }
})

export const {
    setCourseListLoading,
    setCourseListPayload,
    setCourseListErrorMsg,
    setClearCourseList,

    setCourseDetailLoading,
    setCourseDetail,
    setCourseDetailErrorMsg,
    setClearCourseDetail,

    setAddCourseDetailLoading,
    setAddCourseDetail,
    setAddCourseDetailErrorMsg,
    setClearAddCourseDetail,

    setCourseDetailPayload,
    setClearCourseDetailPayload,

    // new course slice
    setPublicCourseDetailLoading,
    setPublicCourseDetailData,
    setPublicCourseDetailMessage,
    setClearPublicCourseDetail,

    setSelectedPublicCourseDetailLoading,
    setSelectedPublicCourseDetail,
    setSelectedPublicCourseDetailMessage,
    resetSelectedPublicCourseDetail,

    setCourseTemplateListLoading,
    setCourseTemplateList,
    setCourseTemplateListErrorMsg,
    setClearCourseTemplateList,

    setCourseTemplateDetailLoading,
    setCourseTemplateDetail,
    setCourseTemplateDetailErrorMsg,
    setClearCourseTemplateDetail,

    setCourseTemplateDetailPayload,
    setClearCourseTemplateDetailPayload,

    setCourseTagListLoading,
    setCourseTagList,
    setCourseTagListErrorMsg,
    setClearCourseTagList,

    setAddCourseTagDetailLoading,
    setAddCourseTagDetail,
    setAddCourseTagDetailErrorMsg,
    setClearAddCourseTagDetail,

    setCourseLanguageListLoading,
    setCourseLanguageList,
    setCourseLanguageListErrorMsg,
    setClearCourseLanguageList,

    setAddCourseLanguageDetailLoading,
    setAddCourseLanguageDetail,
    setAddCourseLanguageDetailErrorMsg,
    setClearAddCourseLanguageDetail,

    setCourseProfListLoading,
    setCourseProfList,
    setCourseProfListErrorMsg,
    setClearCourseProficiencyList,

    setAddCourseProfDetailLoading,
    setAddCourseProfDetail,
    setAddCourseProfDetailErrorMsg,
    setClearAddCourseProfDetail,

    setCourseAvailDetailLoading,
    setCourseAvailDetail,
    setCourseAvailErrorMsg,
    setClearCourseAvailDetail,

    setAddCourseAvailDetailLoading,
    setAddCourseAvailDetail,
    setAddCourseAvailDetailErrorMsg,
    setClearAddCourseAvailDetail,

    setAddCourseAvailDetailPayload,
    setClearAddCourseAvailDetailPayload,

    setCoursePriceDetailLoading,
    setCoursePriceDetail,
    setCoursePriceDetailErrorMsg,
    setClearCoursePriceDetail,

    setAddCoursePriceDetailLoading,
    setAddCoursePriceDetail,
    setAddCoursePriceDetailErrorMsg,
    setClearAddCoursePriceDetail,

    setCourseCommentListLoading,
    setCourseCommentListData,
    setCourseCommentListMessage,
    resetCourseCommentList,

    setAddCourseCommentDetailLoading,
    setAddCourseCommentDetailMessage,
    resetAddCourseCommentDetail,

    setCoursePreviewDetailLoading,
    setCoursePreviewDetailData,
    setCoursePreviewDetailMessage,
    resetCoursePreviewDetail,

    setNextCourseSessionDetailLoading,
    setNextCourseSessionDetailData,
    setNextCourseSessionDetailMessage,
    resetNextCourseSessionDetail,

    setNextCourseSessionDetailPayload,
    resetNextCourseSessionDetailPayload,

    setCourseTutorListLoading,
    setCourseTutorListData,
    setCourseTutorListMessage,
    resetCourseTutorList,

    setCourseTutorDetailLoading,
    setCourseTutorDetailData,
    setCourseTutorDetailMessage,
    resetCourseTutorDetail,

    setAddCourseTutorDetailLoading,
    setAddCourseTutorDetailData,
    setAddCourseTutorDetailMessage,
    resetAddCourseTutorDetail,

    setAddCourseTutorDetailPayload,
    resetAddCourseTutorDetailPayload,

    setUpdateCourseTutorDetailLoading,
    setUpdateCourseTutorDetailData,
    setUpdateCourseTutorDetailMessage,
    resetUpdateCourseTutorDetail,

    setDeleteCourseTutorDetailLoading,
    setDeleteCourseTutorDetailMessage,
    resetDeleteCourseTutorDetail,

    setCourseConfigDetailLoading,
    setCourseConfigDetailData,
    setCourseConfigDetailMessage,
    resetCourseConfigDetail,

    setAddCourseConfigDetailLoading,
    setAddCourseConfigDetailMessage,
    resetAddCourseConfigDetail,

    setUpdateCourseConfigDetailLoading,
    setUpdateCourseConfigDetailMessage,
    resetUpdateCourseConfigDetail,

    setDeleteCourseConfigDetailLoading,
    setDeleteCourseConfigDetailMessage,
    resetDeleteCourseConfigDetail,

    setCloneCourseDetailLoading,
    setCloneCourseDetailData,
    setCloneCourseDetailMessage,
    resetCloneCourseDetail,

    setFilterProps,
    setClearFilterProps
} = course.actions

export default course.reducer