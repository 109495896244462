import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import RecurringSchedule from "components/availabilitySchedule/RecurringSchedule";

import { validateUserAvailabilityDetail } from "pages/auth/editUser/data";
import AvailabilityHeader from "pages/auth/editUser/commonComponents/header/AvailabilityHeader";
import SaveAndResetButtons from "pages/auth/editUser/commonComponents/buttons/SaveAndResetButtons";

import BasicInformation from "./BasicInformation";
import NonRecurringSchedule from "./NonRecurringSchedule";
import StartEndDateSchedule from "./StartEndDateSchedule";
import MoreAvailabilityDetail from "./MoreAvailabilityDetail";

import { createAvailabilityDetail } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetAddAvailabilityDetail, resetAddAvailabilityDetailPayload, setAddAvailabilityDetailPayload, setModifyAvailabilityDetailPayload } from "redux/availability/availability.slice";
import { addAvailabilityDetailPayload, availabilityIsRecurringEnum, modifyAvailabilityDetailPayload } from "redux/availability/availability.const";

import { weekdays } from 'utils/dateTime.utils';
import { pagesInfo } from "utils/pagesInfo";

const CreateAvailabilityDetailPage = () => {
    const { userDetail } = useAppState((state) => state.user)
    const { addAvailabilityDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        if (userDetail?.userDetail?.userCulture) {
            dispatcher(setAddAvailabilityDetailPayload({
                ...addAvailabilityDetailPayload,
                timeZone: userDetail?.userDetail?.userCulture?.timeZone?.timeZone
            }))
        }

        return () => {
            dispatcher(resetAddAvailabilityDetail())
            dispatcher(resetAddAvailabilityDetailPayload())
        }
    }, [userDetail?.userDetail?.userCulture])

    useEffect(() => {
        if (addAvailabilityDetail?.data) {
            const availabilityId = addAvailabilityDetail?.data?.result?.id
            dispatcher(resetAddAvailabilityDetail())
            dispatcher(resetAddAvailabilityDetailPayload())
            navigate(`${pagesInfo.EDIT_USER.pagePath}/${userDetail?.userDetail?.userId}/edit/user-availability/${availabilityId}/edit`, { replace: true })
        }
    }, [addAvailabilityDetail?.data])

    const saveWeeklyAvail = async (localWeeklyAvail) => {
        if (addAvailabilityDetail?.isLoading) return;

        dispatcher(setAddAvailabilityDetailPayload({
            ...addAvailabilityDetail?.payload,
            weeklyTimeSlots: localWeeklyAvail
        }))
    }

    const onHandleResetAvailabilityDetail = useCallback(() => {
        if (addAvailabilityDetail?.isLoading) return;

        dispatcher(resetAddAvailabilityDetail())
        dispatcher(resetAddAvailabilityDetailPayload())
    }, [addAvailabilityDetail?.isLoading])

    const onHandleSaveAvailabilityDetail = async () => {
        if (addAvailabilityDetail?.isLoading) return;

        const { requestPayload, errorMsg } = await validateUserAvailabilityDetail(addAvailabilityDetail?.payload)

        if (errorMsg) {
            toast.warn(errorMsg)
            return;
        } if (requestPayload) {
            dispatcher(setModifyAvailabilityDetailPayload({
                ...modifyAvailabilityDetailPayload,
                timeZone: requestPayload?.timeZone
            }))
            let requestDataPayload = { userId: userDetail?.userDetail?.userId, ...requestPayload }
            dispatcher(createAvailabilityDetail(requestDataPayload))
        }
    }

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <AvailabilityHeader headerText={"Create Availability"} />
            <div className={"w-full flex flex-col mx-auto container max-w-6xl gap-5 sm:gap-10"}>
                <BasicInformation />
                <div className={"h-0.5 bg-divider-medium w-full"}></div>

                <div className={"w-full flex flex-col max-w-6xl gap-10"}>
                    {([availabilityIsRecurringEnum.RECURRING.value]?.includes(addAvailabilityDetail?.payload?.isRecurring) && addAvailabilityDetail?.payload?.weeklyTimeSlots) &&
                        <>
                            {weekdays.map((day, index) => (
                                <RecurringSchedule
                                    key={index}
                                    isLoading={addAvailabilityDetail?.isLoading}
                                    localDay={day}
                                    selectedTimeZone={addAvailabilityDetail?.payload?.timeZone}
                                    weeklyAvailability={addAvailabilityDetail?.payload?.weeklyTimeSlots}
                                    saveWeeklyAvail={saveWeeklyAvail}
                                />
                            ))}
                            <div className={"h-0.5 bg-divider-medium w-full"}></div>

                            <StartEndDateSchedule />
                        </>
                    }
                    {[availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(addAvailabilityDetail?.payload?.isRecurring) &&
                        <NonRecurringSchedule />
                    }
                    <MoreAvailabilityDetail />
                </div>

                {addAvailabilityDetail?.message &&
                    <div className={"flex items-center justify-center"}>
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {addAvailabilityDetail?.message || "No Availability Found!"}
                        </span>
                    </div>
                }
                <SaveAndResetButtons
                    isSaveBtnLoading={addAvailabilityDetail?.isLoading}
                    saveBtnDisabled={!addAvailabilityDetail?.payload?.name}
                    resetBtnDisabled={addAvailabilityDetail?.isLoading}
                    onHandleSave={onHandleSaveAvailabilityDetail}
                    onHandleReset={onHandleResetAvailabilityDetail}
                />
            </div>
        </div>
    );
}

export default CreateAvailabilityDetailPage;