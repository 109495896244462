import { useEffect } from "react";
import { Outlet, useParams, Link } from "react-router-dom";

import { FiEdit } from "react-icons/fi";

import FullPageLoader from "components/loader/FullPageLoader";
import DetailedView from "components/detailedView/DetailedView";

import { PageContentStyle } from "pages/auth/viewLeads/viewLeadPage.style";
import { viewLeadHeaderConst } from "pages/auth/viewLeads/data";

import { setPageInfo } from "redux/pageInfo/pageInfo.request";
import { getLeadDetailByLeadId } from "redux/lead/lead.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearLeadDetail } from "redux/lead/lead.slice";
import { leadStatus, JourneyConst, contactTime, leadStrength } from "redux/lead/lead.const";

import { pagesInfo } from "utils/pagesInfo";
import { dayjs, timeZone } from "utils/dateTime.utils";

const ViewLeadPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { leadDetail } = useAppState((state) => state.lead)

  const dispatcher = useAppDispatcher()
  const { leadId } = useParams()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.VIEW_LEAD))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    if (leadId) {
      dispatcher(getLeadDetailByLeadId(Number(leadId)))
    }

    return () => {
      dispatcher(setClearLeadDetail())
    }
  }, [leadId])

  const rows = [
    leadDetail?.leadDetail?.leadId || "-",
    leadDetail?.leadDetail?.firstName + " " + leadDetail?.leadDetail?.lastName?.charAt(0) || "-",
    leadDetail?.leadDetail?.email || "-",
    (leadDetail?.leadDetail?.isdCode && leadDetail?.leadDetail?.mobileNo) ? "+" + leadDetail?.leadDetail?.isdCode + " - " + leadDetail?.leadDetail?.mobileNo : "-",
    JourneyConst[leadDetail?.leadDetail?.classFor?.toUpperCase()]?.label || "-",
    contactTime[leadDetail?.leadDetail?.contactTime?.toUpperCase()]?.label || "-",
    leadStrength[leadDetail?.leadDetail?.leadStrength?.toUpperCase()]?.label || "-",
    leadStatus[leadDetail?.leadDetail?.status?.toUpperCase()]?.label || "-",
    dayjs(leadDetail?.leadDetail?.createdAt).tz(timeZone).format('DD MMM, YYYY') || "-",
    <Link
      to={`${pagesInfo.EDIT_TUTOR.pagePath}/${leadDetail?.leadDetail?.userId}/edit`}
      className={"px-2 py-1 text-text-700"}
    >
      <FiEdit className={"text-xl hover:scale-110"} />
    </Link>
  ]

  if (leadDetail?.isLoading) {
    return (
      <FullPageLoader isLoading={leadDetail?.isLoading} />
    )
  }

  return (
    <PageContentStyle>
      <div className={"w-full h-full min-h-screen mx-auto p-5 space-y-3"}>
        <DetailedView
          headers={Object.values(viewLeadHeaderConst)}
          rows={rows}
          rowHeaderColor={"bg-background-medium"}
          rowColor={`bg-${leadStatus[leadDetail?.leadDetail?.status?.toUpperCase()]?.lightColor}`}
        />
        {/* <div className={"w-full flex flex-col items-start justify-start space-y-3 md:space-y-0 md:space-x-3 md:flex-row"}>
          <div className={"sticky top-[5rem] w-full md:w-fit"}>
            <NavigationOptions options={viewLeadNavigationOptions} />
          </div>
          <div className={"w-full rounded-lg p-5 bg-white"}>
            <Outlet />
          </div>
        </div> */}
      </div>
    </PageContentStyle>
  )
}

export default ViewLeadPage