import { useEffect } from "react";

import { ToggleWithText } from "components/common-components/Toggle";
import ComponentLoader from "components/loader/ComponentLoader";

import { setGetOtherPref, tutorOtherPreferenceToolTip } from "pages/auth/editTutor/data";
import EditTutorHeader from "pages/auth/editTutor/commonComponents/EditTutorHeader";

import { getUserOtherPrefDetailByUserId, updateUserOtherPrefDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserOtherPref } from "redux/user/user.slice";

export default function EditTutorOther() {
  const { userOtherPref, addUserOtherPref } = useAppState((s) => s.user)
  const { tutorDetail } = useAppState((state) => state.tutor)

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    if (tutorDetail?.data?.user?.id) {
      dispatcher(getUserOtherPrefDetailByUserId(tutorDetail?.data?.user?.id))
    }

    return () => {
      dispatcher(setClearUserOtherPref())
    }
  }, [tutorDetail?.data])

  const updateOtherStatus = (key, value) => {
    const otherPref = { [key]: value }
    const requestData = {
      userId: tutorDetail?.data?.user?.id,
      body: otherPref
    }
    dispatcher(updateUserOtherPrefDetailByUserId(requestData))
  }

  return (
    <div className="">
      <EditTutorHeader title={"Other Preferences"} tooltipText={tutorOtherPreferenceToolTip} />
      <div className="px-4 py-10 space-y-2 divide-y divide-blue-50">
        {userOtherPref?.isLoading &&
          <ComponentLoader isLoading={userOtherPref?.isLoading} />
        }
        {(!userOtherPref?.isLoading && userOtherPref?.userOtherPref) &&
          setGetOtherPref(userOtherPref?.userOtherPref).map((other, index) => (
            <ToggleWithText
              key={index}
              description={other.description}
              onChange={(isToggle) => updateOtherStatus(other.key, isToggle)}
              title={other.label}
              value={other.value}
            />
          ))}
        {(userOtherPref?.errorMsg || addUserOtherPref?.errorMsg) &&
          <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
            {userOtherPref?.errorMsg || addUserOtherPref?.errorMsg}
          </div>
        }
      </div>
    </div>
  );
}
