import { cn } from "utils/cn.utils";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

const SkillTag = ({
    skillList = [],
    inputSkill,
    onChange,
    onRemove,
    onKeyboardSave,
    onHandleAddSkill,
    isShowDeleteBtn = false,
    isShowInput = false,
    disabled = false,
    className
}) => {
    return (
        <div className={"flex flex-wrap gap-2"}>
            {skillList?.map((skill) => (
                <div className="flex items-center px-3 py-1 bg-primary-light rounded-lg font-bodyPri font-normal text-text-900 text-sm">
                    <span className="">
                        {skill?.label}
                    </span>
                    {isShowDeleteBtn &&
                        <button className="pl-3 cursor-pointer">
                            <FaTimes className="font-light text-secondary-dark" onClick={() => onRemove(skill?.label)} />
                        </button>
                    }
                </div>
            ))}
            {isShowInput &&
                <div className="flex items-center gap-3">
                    <input
                        className={cn(
                            "w-40 px-3 py-0.5 border-2 border-text-100 rounded-lg focus:outline-none focus:border-2 focus:border-text-300",
                            "font-bodyPri font-normal text-text-700 text-sm placeholder:text-text-400 placeholder:text-sm"
                        )}
                        type={"text"}
                        placeholder={"Skill name"}
                        value={inputSkill}
                        disabled={disabled}
                        onChange={(event) => onChange(event.target.value)}
                        onKeyDown={(event) => onKeyboardSave(event)}
                    />
                    <AiOutlineCheckCircle className={"text-2xl text-text-800 cursor-pointer"} onClick={() => onHandleAddSkill(inputSkill)} />
                </div>
            }
        </div>
    )
}

export default SkillTag