import { useState } from "react";

import EditTutorHeader from "pages/auth/editTutor/commonComponents/EditTutorHeader";

import EditTutorInfo from './components/EditTutorInfo';
import EditTutorSkills from './components/EditTutorSkills';

const EditTutorDetail = () => {
    const [editBtnConst, setEditBtnConst] = useState(null)

    return (
        <div className={""}>
            <EditTutorHeader title={"Tutor Details"} />
            <div className={"p-5 space-y-8"}>
                <EditTutorInfo editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
                <EditTutorSkills editBtnConst={editBtnConst} setEditBtnConst={setEditBtnConst} />
            </div>
        </div>
    )
}

export default EditTutorDetail