import { useState } from 'react';

import Tabs from 'components/tabs/Tabs';

import { waitListTabs } from '../../data';

import TutorsWaitList from 'pages/auth/otherSettings/components/generalSettings/components/TutorsWaitList';
import LearnersWaitList from 'pages/auth/otherSettings/components/generalSettings/components/LearnersWaitList';
import CompaniesWaitList from 'pages/auth/otherSettings/components/generalSettings/components/CompaniesWaitList';

const GeneralSettings = () => {

    const [activeTab, setActiveTab] = useState(waitListTabs.TUTORS.value)

    const handleTabs = (activeTab) => {
        setActiveTab(activeTab.value)
    }

    return (
        <div className={"divide-y-2 space-y-3"}>
            <Tabs tabs={Object.values(waitListTabs)} handleTabs={handleTabs} />

            {activeTab === waitListTabs?.TUTORS?.value &&
                <TutorsWaitList />
            }

            {activeTab === waitListTabs?.LEARNERS?.value &&
                <LearnersWaitList />
            }
            {activeTab === waitListTabs?.COMPANIES?.value &&
                <CompaniesWaitList />
            }
            </div>
    )
}

export default GeneralSettings;