import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_INITIAL_STATE } from "redux/local/local.initialState";
import { setMyLocals } from "./local.utils";


const local = createSlice({
    name: "local",
    initialState: LOCAL_INITIAL_STATE,
    reducers: {
        // Reducers for locals
        setLocals: (state, { payload }) => {
            state.locals = payload
            setMyLocals(payload)
        },
        setClearLocals: (state) => {
            state.locals = LOCAL_INITIAL_STATE.locals
        },

        // Reducers for madal
        setModal: (state, { payload }) => {
            state.modal = payload
        },
        setClearModal: (state) => {
            state.modal = LOCAL_INITIAL_STATE.modal
        },

        // Reducers for pages
        setPages: (state, { payload }) => {
            state.pages = payload
        },
        setClearPages: (state) => {
            state.pages = LOCAL_INITIAL_STATE.pages
        },

        // Reducers for share social
        setShareSocial: (state, { payload }) => {
            state.shareSocial = payload
        },
        setClearShareSocial: (state) => {
            state.shareSocial = LOCAL_INITIAL_STATE.shareSocial
        },

        // Reducers for sticky note
        setStickyNotePayload: (state, { payload }) => {
            state.stickyNotePayload = payload
        },
        setClearStickyNotePayload: (state) => {
            state.stickyNotePayload = LOCAL_INITIAL_STATE.stickyNotePayload
        },
    }
})

export const {
    setLocals,
    setClearLocals,

    setModal,
    setClearModal,

    setPages,
    setClearPages,

    setShareSocial,
    setClearShareSocial,

    setStickyNotePayload,
    setClearStickyNotePayload
} = local.actions

export default local.reducer