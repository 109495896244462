import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { MdAdd } from 'react-icons/md';
import { MdOutlineRefresh } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';
// import { Toggle } from "components/common-components/Toggle";

import SaveAndCancelBtn from 'pages/auth/editCourseDetail/SaveAndCancelBtn';
import EditCourseTooltip from 'pages/auth/editCourseDetail/EditCourseTooltip';
import { planNameTooltip } from 'pages/auth/editCourseDetail/data';

import { getUserItemListByUserId, getItemPriceList } from 'redux/chargeBee/chargeBee.request';
import { getCoursePriceDetail, createCoursePriceDetail, updateCoursePriceDetail, deleteCoursePriceDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearUserItemList, setClearItemPriceList } from "redux/chargeBee/chargeBee.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from 'redux/local/local.const';
import { periodUnit } from "redux/chargeBee/chargeBee.const";
import { coursePriceTypeEnum } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";

const validateSubscriptionPlanPrice = ({ startDate, endDate, weeklyCycle = 0, period }) => {
    let startDayjs = dayjs(startDate)?.tz(timeZone)
    let endDayjs = dayjs(endDate)?.tz(timeZone)

    const weeklyDiff = endDayjs.diff(startDayjs, 'day')
    const monthlyDiff = (endDayjs.diff(startDayjs, 'month') * 30)
    const yearlyDiff = (endDayjs.diff(startDayjs, 'year') * 365)

    let periodDays = 7
    let totalWeekCycleDays = weeklyCycle * 7
    if (period === periodUnit?.WEEK?.value) {
        if (weeklyDiff >= periodDays || totalWeekCycleDays >= periodDays) {
            return true
        }
    }

    if (period === periodUnit?.MONTH?.value) {
        periodDays = 30
        if (monthlyDiff >= periodDays || totalWeekCycleDays >= periodDays) {
            return true;
        }
    }

    if (period === periodUnit?.YEAR?.value) {
        periodDays = 365
        if (yearlyDiff >= periodDays || totalWeekCycleDays >= periodDays)
            return true;
    }
}

const PlanPriceTypeContainer = ({ setIsAddCoursePriceEnabled }) => {
    const { modal } = useAppState((state) => state.local)
    const { userItemList, itemPriceList } = useAppState((s) => s.chargeBee)
    const { courseDetail, courseAvailDetail, coursePriceDetail, addCoursePriceDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [planPriceType, setPlanPriceType] = useState({
        course: courseDetail?.courseDetail?.id,
        type: coursePriceTypeEnum?.PLAN?.value,
        selectedPlan: null,
        item: null,
        itemPrices: [],
        unitType: {
            week: true,
            month: true,
            year: true
        }
    })

    const isPlanPriceTypeSimilar = JSON.stringify({ course: coursePriceDetail?.coursePriceDetail?.course, type: coursePriceDetail?.coursePriceDetail?.type, item: coursePriceDetail?.coursePriceDetail?.item?.id }) === JSON.stringify({ course: courseDetail?.courseDetail?.id, type: coursePriceTypeEnum?.PLAN?.value, item: planPriceType?.item })

    useEffect(() => {
        dispatcher(getUserItemListByUserId({ userId: courseDetail?.courseDetail?.tutor?.user?.idseDetail?.tutor?.user?.id }))

        return () => {
            dispatcher(setClearUserItemList())
        }
    }, [courseDetail?.courseDetail])

    useEffect(() => {
        if (planPriceType?.selectedPlan) {
            dispatcher(getItemPriceList({ userItemId: planPriceType?.selectedPlan }))
        }

        return () => {
            dispatcher(setClearItemPriceList())
        }
    }, [planPriceType?.selectedPlan])

    useEffect(() => {
        if (userItemList?.filteredUserItemList && coursePriceDetail?.coursePriceDetail) {
            const filterItemId = userItemList?.filteredUserItemList?.find((userItem) => userItem?.item?.id === coursePriceDetail?.coursePriceDetail?.item?.id)
            const filteredItemPrices = coursePriceDetail?.coursePriceDetail?.itemPrices?.map((items) => ({
                itemPrice: items?.id
            }))
            setPlanPriceType({
                ...planPriceType,
                selectedPlan: filterItemId?.userItemId,
                item: coursePriceDetail?.coursePriceDetail?.item?.id,
                itemPrices: filteredItemPrices,
                // unitType: {
                //     week: coursePriceDetail?.coursePriceDetail?.itemPrices?.filter((item) => item?.period_unit === periodUnit?.WEEK?.value)?.length > 0 ? true : false,
                //     month: coursePriceDetail?.coursePriceDetail?.itemPrices?.filter((item) => item?.period_unit === periodUnit?.MONTH?.value)?.length > 0 ? true : false,
                //     year: coursePriceDetail?.coursePriceDetail?.itemPrices?.filter((item) => item?.period_unit === periodUnit?.YEAR?.value)?.length > 0 ? true : false
                // }
            })
        }
    }, [userItemList?.filteredUserItemList, coursePriceDetail?.coursePriceDetail])

    const onHandleRefreshPlanList = () => {
        dispatcher(getUserItemListByUserId({ userId: courseDetail?.courseDetail?.tutor?.user?.id }))
    }

    const onHandleCreatePlan = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_SUBSCRIPTION_PLAN_MODAL.stateKey]: true
        }))
    }

    const onHandlesSelectPlan = (option) => {
        const filterItemId = userItemList?.filteredUserItemList?.find((userItem) => userItem?.userItemId === option?.value)
        setPlanPriceType({
            ...planPriceType,
            selectedPlan: option?.value,
            item: filterItemId?.item?.id
        })
    }

    // const onToggleChange = (isToggle, itemPrice) => {
    //     if (isToggle) {
    //         setPlanPriceType({
    //             ...planPriceType,
    //             unitType: {
    //                 ...planPriceType?.unitType,
    //                 [itemPrice?.period_unit]: isToggle
    //             },
    //             itemPrices: [
    //                 ...planPriceType?.itemPrices,
    //                 { itemPrice: itemPrice?.id }
    //             ]
    //         })
    //     }
    //     if (!isToggle) {
    //         const filteredItemPrices = planPriceType?.itemPrices?.filter((item) => item?.itemPrice !== itemPrice?.id)
    //         setPlanPriceType({
    //             ...planPriceType,
    //             unitType: {
    //                 ...planPriceType?.unitType,
    //                 [itemPrice?.period_unit]: isToggle
    //             },
    //             itemPrices: filteredItemPrices
    //         })
    //     }
    // }

    const onHandleCancelPlan = () => {
        if (isPlanPriceTypeSimilar || !planPriceType?.item || !itemPriceList?.filteredItemPriceList || itemPriceList?.filteredItemPriceList?.length === 0) return;
        if (coursePriceDetail?.coursePriceDetail) {
            dispatcher(getCoursePriceDetail(coursePriceDetail?.coursePriceDetail?.id))
        } else {
            setIsAddCoursePriceEnabled(false)
            setPlanPriceType({
                ...planPriceType,
                selectedPlan: null,
                item: null,
                itemPrices: []
            })
        }
    }

    const onHandleDeletePlanPrice = () => {
        if (!courseDetail?.courseDetail?.coursePrice || coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.REGULAR?.value) return;

        dispatcher(deleteCoursePriceDetail(coursePriceDetail?.coursePriceDetail?.id))
    }

    const onHandleSave = () => {
        if (addCoursePriceDetail?.isLoading || isPlanPriceTypeSimilar) return;

        if (!planPriceType?.item) {
            toast.warn("Please create or select a plan.")
            return;
        }
        if (!itemPriceList?.filteredItemPriceList || itemPriceList?.filteredItemPriceList?.length === 0) {
            toast.warn("Please create or select a plan price!")
            return;
        }

        if (courseAvailDetail?.courseAvailDetail?.availability?.endDate || courseAvailDetail?.courseAvailDetail?.availability?.weeklyCycle) {
            const startDate = courseAvailDetail?.courseAvailDetail?.availability?.startDate
            const endDate = courseAvailDetail?.courseAvailDetail?.availability?.endDate
            const weeklyCycle = courseAvailDetail?.courseAvailDetail?.availability?.weeklyCycle
            const period = itemPriceList?.filteredItemPriceList[0]?.period_unit
            if (!validateSubscriptionPlanPrice({ startDate, endDate, weeklyCycle, period })) {
                alert("Invalid pricing for class cycle!")
                return;
            }
        }

        const body = {
            course: courseDetail?.courseDetail?.id,
            type: coursePriceTypeEnum?.PLAN?.value,
            item: planPriceType?.item,
            itemPrices: itemPriceList?.filteredItemPriceList?.map((itemPrice) => ({
                itemPrice: itemPrice?.id
            }))
        }

        if (!coursePriceDetail?.coursePriceDetail?.id) {
            dispatcher(createCoursePriceDetail(body))
            return;
        }
        if (coursePriceDetail?.coursePriceDetail?.id) {
            const requestDataPayload = {
                coursePriceId: coursePriceDetail?.coursePriceDetail?.id,
                body: body
            }
            dispatcher(updateCoursePriceDetail(requestDataPayload))
            return;
        }
    }

    if (addCoursePriceDetail?.isLoading) {
        return (
            <div className={"w-full flex items-center justify-start"}>
                <ComponentLoader isLoading={addCoursePriceDetail?.isLoading} />
            </div>
        )
    }

    return (
        <div className={'grid grid-cols-12 gap-y-5 gap-x-3'}>
            <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3">
                <div className={"flex items-center justify-start gap-1"}>
                    <EditCourseTooltip tooltip={planNameTooltip} />
                    <span className={"font-bodyPri font-normal text-text-700 tracking-wide whitespace-nowrap"}>
                        {"Plan Name"}
                        <span className={"text-red-500 text-lg"}>{"*"}</span>
                    </span>
                </div>
            </span>
            {userItemList?.isLoading &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 md:w-48"}>
                    <ComponentLoader isLoading={userItemList.isLoading} />
                </div>
            }
            {(!userItemList?.isLoading && userItemList?.filteredUserItemList && userItemList?.filteredUserItemList?.length === 0) &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 flex items-center justify-start gap-3"}>
                    <div
                        className={"w-fit px-2 py-1 flex items-center justify-center gap-1 border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"}
                        onClick={onHandleCreatePlan}
                    >
                        <MdAdd className={"text-lg group-hover:text-text-50"} />
                        <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                            {"Create plan"}
                        </span>
                    </div>
                    <div
                        className={"w-fit px-2 py-1 flex items-center justify-center gap-1 border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"}
                        onClick={onHandleRefreshPlanList}
                    >
                        <MdOutlineRefresh className={"text-lg group-hover:text-text-50"} />
                        <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                            {"Refresh Plan List"}
                        </span>
                    </div>
                </div>

            }
            {!userItemList?.isLoading && userItemList?.filteredUserItemList?.length > 0 &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full flex items-center justify-start gap-3"}>
                    <div className={"w-full md:w-80"}>
                        <OptionSelector
                            options={userItemList?.filteredUserItemList?.map((userItem) => ({
                                label: userItem?.item?.name,
                                value: userItem?.userItemId
                            }))}
                            className={"w-full"}
                            value={planPriceType?.selectedPlan}
                            onChange={(option) => onHandlesSelectPlan(option)}
                        />
                    </div>
                    <div className={"flex items-center justify-start gap-3"}>
                        <div
                            className={"p-1 border border-text-700 hover:border-primary-dark hover:bg-primary-dark rounded-lg group cursor-pointer"}
                            title={"Refresh Plan List"}
                            onClick={onHandleRefreshPlanList}
                        >
                            <MdOutlineRefresh className={"text-xl text-text-900 group-hover:text-text-50"} />
                        </div>
                        <div
                            className={"p-1 border border-text-700 hover:border-secondary-dark hover:bg-secondary-dark rounded-lg group cursor-pointer"}
                            title={"Add Plan"}
                            onClick={onHandleCreatePlan}
                        >
                            <MdAdd className={"text-xl text-text-900 group-hover:text-text-50"} />
                        </div>
                    </div>
                </div>
            }

            {/* Price container */}
            {itemPriceList?.isLoading &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 md:w-48"}>
                    <ComponentLoader isLoading={itemPriceList?.isLoading} />
                </div>
            }
            {(!itemPriceList?.isLoading && itemPriceList?.filteredItemPriceList && itemPriceList?.filteredItemPriceList?.length === 0) &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 flex items-center justify-start gap-3"}>
                    <a
                        href={`${pagesInfo?.PREFERENCES?.pagePath}/my-plans/${planPriceType?.selectedPlan}`}
                        className={"w-fit px-2 py-1 flex items-center justify-center gap-1 border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"}
                        target={"_blank"}
                    >
                        <MdAdd className={"text-lg group-hover:text-text-50"} />
                        <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                            {"Set Plan Price"}
                        </span>
                    </a>
                    <div
                        className={"w-fit px-2 py-1 flex items-center justify-center gap-1 border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"}
                        onClick={() => dispatcher(getItemPriceList({ userItemId: planPriceType?.selectedPlan }))}
                    >
                        <MdOutlineRefresh className={"text-lg group-hover:text-text-50"} />
                        <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                            {"Refresh Plan Price"}
                        </span>
                    </div>
                </div>
            }
            {(itemPriceList?.filteredItemPriceList?.length > 0) &&
                itemPriceList?.filteredItemPriceList?.slice(0, 1).map((itemPrice, index) => (
                    <div
                        key={index}
                        className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 md:w-80 border border-text-400 rounded-lg px-3 py-1"}
                    >
                        <div className="flex flex-col items-start justify-start gap-0.5">
                            <p className="font-bodyPri font-semibold text-text-900 text-base">
                                {periodUnit[itemPrice?.period_unit?.toUpperCase()]?.label}
                            </p>
                            <div className={"w-full flex items-center justify-between gap-3"}>
                                <div className={"flex items-center justify-start gap-2"}>
                                    <p className="font-bodyPri font-medium text-text-600 text-base">
                                        {"USD " + parseFloat("" + (itemPrice?.price / 100)).toFixed(2)}
                                    </p>
                                    <a
                                        className={""}
                                        href={`${pagesInfo?.PREFERENCES?.pagePath}/my-plans/${planPriceType?.selectedPlan}`}
                                        target={"_blank"}
                                    >
                                        <FiEdit className={"text-text-700 text-base"} />
                                    </a>
                                </div>
                                {/* <div className={""}>
                                    <Toggle onChange={(isToggle) => onToggleChange(isToggle, itemPrice)} value={planPriceType?.unitType[itemPrice?.period_unit]} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                ))
            }
            {itemPriceList?.errorMsg &&
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 font-bodyPri font-normal text-red-500 text-base"}>
                    {itemPriceList?.errorMsg}
                </div>
            }
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full flex items-center justify-start py-3"}>
                <SaveAndCancelBtn
                    isLoading={addCoursePriceDetail?.isLoading}
                    isShowCancelBtn={true}
                    isShowDeleteBtn={true}
                    isSaveBtnDisabled={!planPriceType?.item || !planPriceType?.selectedPlan || isPlanPriceTypeSimilar}
                    isCancelBtnDisabled={!planPriceType?.item || !planPriceType?.selectedPlan || isPlanPriceTypeSimilar}
                    isDeleteBtnDisabled={!courseDetail?.courseDetail?.coursePrice || coursePriceDetail?.coursePriceDetail?.type === coursePriceTypeEnum?.REGULAR?.value}
                    onSave={onHandleSave}
                    onCancel={onHandleCancelPlan}
                    onDelete={onHandleDeletePlanPrice}
                />
            </div>
        </div>
    )
}

export default PlanPriceTypeContainer;