import { useAppState } from "hooks/useStore";
import { sessionTypeEnum } from "redux/session/session.const";

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';
import { cn } from "utils/cn.utils";

const SessionBasicDetail = () => {
    const { sessionDetail } = useAppState(s => s.session)

    return (
        <>
            <div className={"flex items-center justify-between gap-3"}>
                <div className={"flex items-center justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Session ID:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {sessionDetail?.data?.result?.id}
                    </span>
                </div>
                <div className={"flex items-center justify-start gap-1.5"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                        {"Session Type:"}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {sessionDetail?.data?.result?.type
                            ? sessionTypeEnum[sessionDetail?.data?.result?.type?.toUpperCase()]?.label
                            : "-"
                        }
                    </span>
                </div>
            </div>

            {sessionDetail?.data?.result?.name &&
                <div className={"w-full flex flex-col items-center justify-center gap-1 overflow-hidden"}>
                    <span className={"font-bodyPri font-semibold text-lg text-text-900 text-center truncate"}>
                        {sessionDetail?.data?.result?.name}
                    </span>
                    {sessionDetail?.data?.result?.type &&
                        <span className={"font-bodyPri font-medium text-md text-primary-dark"}>
                            ({sessionTypeEnum[sessionDetail?.data?.result?.type?.toUpperCase()].label + " Meeting"})
                        </span>
                    }
                </div>
            }

            <div className={cn(
                "flex flex-col items-center justify-center gap-2 p-3",
                "bg-primary-light rounded-lg border-2 border-divider-medium",
                "font-bodyPri font-medium text-base text-primary-dark",
            )}>
                <span className={""}>
                    {dayjs(sessionDetail?.data?.result?.startDateTime)?.tz(timeZone)?.format('dddd, DD MMM YYYY')}
                </span>
                <span className={""}>
                    {dayjs(sessionDetail?.data?.result?.startDateTime)?.tz(timeZone)?.format('hh:mm A') + " - " + dayjs(sessionDetail?.data?.result?.endDateTime)?.tz(timeZone)?.format('hh:mm A')}
                </span>
                <span className={"text-xs"}>
                    {`${timeZone} ${getTimeZoneOffset(timeZone)}`}
                </span>
            </div>
        </>
    )
}

export default SessionBasicDetail;