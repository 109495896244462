import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { MdAdd } from "react-icons/md";
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from "react-icons/md";

import ComponentLoader from 'components/loader/ComponentLoader';
import DashedBorderButton from 'components/buttons/dashedBorderButton';
import SearchSelector from 'components/searchSelector/SearchSelector';

import EditUserHeader from 'pages/auth/editUser/commonComponents/header/EditUserHeader';
import SaveAndCancelBtn from "pages/auth/editUser/commonComponents/buttons/SaveAndCancelBtn";

import { getMasterCountryList } from 'redux/master/master.request';
import { createUserAddressDetailByUserId, deleteAddressDetailByUserAddressId, getUserAddressListByUserId, updateAddressDetailByUserAddressId } from 'redux/user/user.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearCountryList } from 'redux/master/master.slice';
import { setClearUserAddressList } from 'redux/user/user.slice';
import { addressTypeConst } from 'redux/user/user.const';

const EditUserAddress = () => {
  const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
  const { userAddressList, addUserAddressDetail } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()
  const { userId } = useParams()

  const [isAddUserAddress, setIsAddUserAddress] = useState(true)
  const [isOpenUserAddressFrom, setIsOpenUserAddressFrom] = useState(false)
  const [userAddress, setUserAddress] = useState({
    isUpdateUserAddress: false,
    userAddressId: null,
    addressType: "home",
    street: "",
    zipCode: ""
  })
  const [myCountry, setMyCountry] = useState({
    masterCountryId: 0,
    country: "",
    countryDomain: "",
    isdCode: ""
  })

  useEffect(() => {
    dispatcher(getMasterCountryList())

    return () => {
      dispatcher(setClearCountryList())
    }
  }, [])

  useEffect(() => {
    if (userId) {
      dispatcher(getUserAddressListByUserId(Number(userId)))
    }

    return () => {
      dispatcher(setClearUserAddressList())
    }
  }, [userId])


  useEffect(() => {
    if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
      let myLocalCountryList = countryList?.countryList?.filter((country) => (
        country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
      ))

      if (myLocalCountryList.length === 0) {
        myLocalCountryList = countryList?.countryList?.filter((country) => (
          country.countryDomain === "US"
        ))
      }
      setMyCountry(myLocalCountryList[0])
    }
  }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

  const onSelectCountry = (option) => {
    const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option)
    setMyCountry(selectedCountry)
  }

  const onHandleAddUserAddress = () => {
    setIsAddUserAddress(false)
    setIsOpenUserAddressFrom(true)
    setUserAddress({
      isUpdateUserAddress: false,
      street: "",
      userAddressId: null,
      zipCode: null,
      addressType: addressTypeConst?.HOME?.value
    })
  }

  const removeUserAddress = (address) => {
    dispatcher(deleteAddressDetailByUserAddressId(address?.userAddressId))
  }

  const onHandleUpdateAddress = () => {

    const body = {
      addressType: userAddress?.addressType,
      street: userAddress?.street,
      masterCountryId: myCountry?.masterCountryId,
      // masterStateIid: myCountry?.masterStateIid,
      // masterCityId: myCountry?.masterCityId,
      zipCode: userAddress?.zipCode
    }
    dispatcher(updateAddressDetailByUserAddressId(userAddress?.userAddressId, body))
    setIsOpenUserAddressFrom(false)
    setIsAddUserAddress(true)
  }

  const onSaveNewAddress = () => {
    if (userAddress?.isUpdateUserAddress) {
      onHandleUpdateAddress()
      return;
    }
    if (userAddress?.street?.length > 0) {
      const filteredUserAddressList = userAddressList?.userAddressList?.filter((address) => (address?.street === userAddress?.street))
      if (filteredUserAddressList?.length > 0) {
        alert("Duplicate user address is not allowed!")
        return;
      }

      const body = {
        addressType: userAddress?.addressType,
        street: userAddress?.street,
        masterCountryId: myCountry?.masterCountryId,
        // masterStateIid: myCountry?.masterStateIid,
        // masterCityId: myCountry?.masterCityId,
        zipCode: userAddress?.zipCode
      }

      dispatcher(createUserAddressDetailByUserId(Number(userId), body))
      setIsOpenUserAddressFrom(false)
      setIsAddUserAddress(true)
    } else {
      alert("Please enter your address")
    }
  }

  return (
    <div className={""}>

      <EditUserHeader title={"User Address"} />

      {(userAddressList?.isLoading || addUserAddressDetail?.isLoading) &&
        <ComponentLoader isLoading={userAddressList?.isLoading || addUserAddressDetail?.isLoading} />
      }

      {(!userAddressList?.isLoading && !addUserAddressDetail?.isLoading) &&
        <div className={"p-5 space-y-5"}>
          {(userAddressList?.userAddressList?.length > 0) && userAddressList?.userAddressList?.map((userAddress) => (
            <div className={"w-full flex flex-col items-start justify-start gap-5 border border-text-400 rounded-lg p-5"}>
              <div className={"w-full flex items-end justify-end gap-1.5"}>
                <FiEdit
                  className={"text-lg text-text-500 cursor-pointer"}
                  onClick={() => {
                    setUserAddress({
                      isUpdateUserAddress: true,
                      userAddressId: userAddress?.userAddressId,
                      addressType: userAddress?.addressType,
                      street: userAddress?.street,
                    })
                    setMyCountry({
                      masterCountryId: userAddress?.country?.masterCountryId,
                      country: userAddress?.country?.country,
                      countryDomain: userAddress?.country?.countryDomain
                    })
                    setIsAddUserAddress(false)
                    setIsOpenUserAddressFrom(true)
                  }}
                />
                <MdOutlineDeleteOutline
                  className={"text-xl text-text-500 cursor-pointer"}
                  onClick={() => removeUserAddress(userAddress)}
                />
              </div>
              <div className={"flex items-center justify-start gap-3"}>
                <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                  {"Address Type:"}
                </span>
                <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                  {addressTypeConst[userAddress?.addressType?.toUpperCase()]?.label}
                </span>
              </div>
              <div className={"flex items-center justify-start gap-3"}>
                <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                  {"Address:"}
                </span>
                <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                  {userAddress?.street}
                </span>
              </div>
              <div className={"flex items-center justify-start gap-3"}>
                <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                  {"Country:"}
                </span>
                <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                  {userAddress?.country?.country}
                </span>
              </div>
              <div className={"flex items-center justify-start gap-3"}>
                <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                  {"Zip Code:"}
                </span>
                <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                  {userAddress?.zipCode}
                </span>
              </div>
            </div>
          ))}

          {(userAddressList?.userAddressList?.length === 0) &&
            <span className={"font-bodyPri font-normal text-text-800 text-base"}>
              {"No Address Added!"}
            </span>
          }
          <DashedBorderButton
            isShowBtn={isAddUserAddress && (!userAddressList?.userAddressList || userAddressList?.userAddressList?.length === 0)}
            btnTitle={"Add Address(s)"}
            onHandleAdd={onHandleAddUserAddress}
          />
          {(isAddUserAddress && userAddressList?.userAddressList && userAddressList?.userAddressList?.length >= 1) &&
            <div
              className={cn(
                "flex item-center justify-start cursor-pointer",
                "font-buttons font-normal text-base text-text-700",
                "hover:text-text-800"
              )}
              onClick={onHandleAddUserAddress}
            >
              <MdAdd className={"text-xl"} />
              <span className={"whitespace-nowrap"}>
                {"Add Address"}
              </span>
            </div>
          }
          {isOpenUserAddressFrom &&
            <div className={"space-y-5"}>
              <div className='flex flex-col items-start justify-start gap-8'>
                <div className={"flex items-center justify-start gap-8"}>
                  {Object?.values(addressTypeConst)?.map((addressType) => (
                    <div className={"flex items-center justify-start gap-1.5"}>
                      <input
                        type={"radio"}
                        name={"address"}
                        className={"border border-text-300 hover:border hover:border-text-700 rounded-full cursor-pointer"}
                        defaultValue={addressType?.value}
                        defaultChecked={addressType?.value === "home" && true}
                        checked={addressType?.value === userAddress?.addressType && true}
                        onChange={(event) => setUserAddress({
                          ...userAddress,
                          addressType: event?.target?.value
                        })}
                      />
                      <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                        {addressType?.label}
                      </span>
                    </div>
                  ))}
                </div>
                <div className={'w-full flex flex-col items-start justify-start sm:flex-row sm:items-center sm:justify-start gap-3'}>
                  <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                    {"Street Address"}
                    <span className={"text-red-500 text-lg"}>
                      {"*"}
                    </span>
                  </span>
                  <input
                    type={"text"}
                    placeholder={"e.g. 142 Palm Avenue"}
                    value={userAddress?.street}
                    className={cn(
                      "w-full md:w-80 px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                      "font-bodyPri font-normal text-text-800 text-base",
                      "placeholder:text-text-500 placeholder:text-sm"
                    )}
                    onChange={(e) => setUserAddress({
                      ...userAddress,
                      street: e.target.value
                    })}
                  />
                </div>
                <div className={'w-full flex flex-col items-start justify-start sm:flex-row sm:items-start sm:justify-start gap-3'}>
                  <span className={"w-24 font-bodyPri font-normal text-text-900 text-sm"}>
                    {"Country"}
                    <span className={"text-red-500 text-lg"}>
                      {"*"}
                    </span>
                  </span>
                  <SearchSelector
                    id={"countryList"}
                    isLoading={countryList?.isLoading}
                    options={countryList?.countryList?.map((country) => ({
                      label: country?.country,
                      value: country?.masterCountryId,
                    })) || []}
                    showGroupedOptions={true}
                    placeholder={"Select Country"}
                    selectedValue={{ label: myCountry?.country || "", value: myCountry?.masterCountryId }}
                    onHandleSelect={(option) => {
                      if (!option || countryList?.isLoading || addUserAddressDetail?.isLoading) return;
                      onSelectCountry(option?.value)
                    }}
                  />
                </div>

                {/* edit zip code */}
                <div className={'w-full flex flex-col items-start justify-start sm:flex-row sm:items-center sm:justify-start gap-3'}>
                  <span className={"w-24 font-bodyPri font-normal text-text-900 text-sm"}>
                    {"Zip Code"}
                    <span className={"text-red-500 text-lg"}>
                      {"*"}
                    </span>
                  </span>
                  <input
                    type={"text"}
                    placeholder={"e.g. AX0123456"}
                    value={userAddress?.zipCode}
                    className={cn(
                      "w-full md:w-80 px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                      "font-bodyPri font-normal text-text-800 text-base",
                      "placeholder:text-text-500 placeholder:text-sm"
                    )}
                    onChange={(e) => setUserAddress({
                      ...userAddress,
                      zipCode: e.target.value
                    })}
                  />
                </div>
              </div>
              <div className={"flex items-center justify-start"}>
                <SaveAndCancelBtn
                  onSave={onSaveNewAddress}
                  onCancel={() => {
                    setIsOpenUserAddressFrom(false)
                    setIsAddUserAddress(true)
                    dispatcher(getUserAddressListByUserId(Number(userId)))
                  }
                  } />
              </div>
            </div>
          }
          {addUserAddressDetail?.errorMsg &&
            <span className={"font-bodyPri font-normal text-red-500 text-base"}>
              {addUserAddressDetail?.errorMsg}
            </span>
          }
        </div>
      }
    </div>
  )
}

export default EditUserAddress