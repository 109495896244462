import { useEffect } from 'react';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMakePaymentPayload } from 'redux/payment/payment.slice';

import PaymentMethodSummery from 'components/modals/paymentMethodModal/PaymentMethodSummary';
import PaymentForm from 'components/modals/paymentMethodModal/PaymentForm';

const GATEWAY_NAME = "stripe"
const PROCESSING_PCT_FEE = 2.5
const CURRENCY = "usd"


const PaymentMethodModal = () => {
    const { user } = useAppState(s => s.user)
    const { makePayment } = useAppState(s => s.payment)
    const makePaymentPayload = makePayment?.makePaymentPayload

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        const totalAmount = Math.ceil((makePaymentPayload?.amountToPay * 100) / (100 - PROCESSING_PCT_FEE))
        dispatcher(setMakePaymentPayload({
            ...makePaymentPayload,
            firstName: user?.user?.firstName || '',
            lastName: user?.user?.lastName || '',
            email: user?.user?.email || '',
            totalAmountToPay: totalAmount,
            gatewayName: GATEWAY_NAME,
            processingPctFee: PROCESSING_PCT_FEE,
            currency: CURRENCY
        }))
    }, [user?.user])

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block space-y-5"}>
                <div>
                    <PaymentMethodSummery />
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"self-center w-full"}>
                    <PaymentForm />
                </div>
            </div>
        </div>
    )
}

export default PaymentMethodModal