import { useEffect } from 'react';
import { cn } from "utils/cn.utils";
import { useLocation } from 'react-router-dom';

import ComponentLoader from 'components/loader/ComponentLoader';

import { txnCardTitles } from 'pages/auth/wallet/data';
import WithdrawalItem from 'pages/auth/wallet/components/withdrawal/WithdrawalItem';

import { getMyWithdrawalListByUserId } from 'redux/wallet/wallet.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearMyWithdrawalList } from 'redux/wallet/wallet.slice';

import { dayjs } from "utils/dateTime.utils";
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 10

const WithdrawalListCard = () => {
  const { user } = useAppState((state) => state.user)
  const { myWithdrawalList } = useAppState((state) => state.wallet)

  const dispatcher = useAppDispatcher()
  const location = useLocation()

  useEffect(() => {
    const query = generateQueryParams(location.search)

    const requestData = {
      userId: user?.user?.userId,
      query: { page: query.pn || 1, records: RECORDS }
    }

    dispatcher(getMyWithdrawalListByUserId(requestData))

    return () => {
      dispatcher(setClearMyWithdrawalList())
    }
  }, [])

  const sortWithdrawalList = (txn1, txn2) => {
    let dateTime1 = dayjs(txn1?.createdAt)
    let dateTime2 = dayjs(txn2?.createdAt)
    return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
  }

  return (
    <div className="bg-white rounded-lg py-5 px-8 flex flex-col gap-5">
      <div className="flex justify-center items-center">
        {/* <Link to={pagesInfo.BOOKINGS.pagePath} className="font-bodyPri font-sm text-base text-text-700 cursor-pointer hover:text-text-900">
          {'View All'}
        </Link> */}
        <span className="font-bodyPri font-medium text-lg text-text-900">
          {txnCardTitles.WITHDRAWAL.label}
        </span>
        {/* <Icon type="filter" /> */}
        <span></span>
      </div>
      <div className={cn(
        "flex flex-col gap-2 divide-y-2 h-[30rem] px-5 overflow-hidden overflow-y-auto",
        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
      )}>
        <ComponentLoader isLoading={myWithdrawalList?.isLoading} />
        {myWithdrawalList?.filteredMyWithdrawalList?.results?.slice()?.sort(sortWithdrawalList)?.map((withdrawalItem, index) => (
          <WithdrawalItem key={index} withdrawalItem={withdrawalItem} />
        ))}
        {(!myWithdrawalList?.filteredMyWithdrawalList || myWithdrawalList?.filteredMyWithdrawalList?.records === 0) &&
          <span className={"h-full flex items-center justify-center font-bodyPri font-medium text-lg text-text-500"}>
            {myWithdrawalList?.errorMsg}
          </span>
        }
      </div>
    </div>
  )
};

export default WithdrawalListCard;