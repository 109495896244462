import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/course/course.const";

class CourseService {
    getCourseList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.COURSE_LIST,
            { params: query }
        )

        return response;
    }

    // createCourseDetail = async ({ params, body }) => {
    //     const response = await baseAuthApiInstance().post(
    //         format(url.CREATE_COURSE, params),
    //         body
    //     )

    //     return response;
    // }

    getCourseDetailByCourseId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_DETAIL, params)
        )

        return response;
    }

    updateCourseDetailByCourseId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COURSE_DETAIL, params),
            body
        )

        return response;
    }

    deleteCourseByCourseId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.COURSE_DETAIL, params)
        )

        return response;
    }

    // new course modifications

    getPublicCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.PUBLIC_COURSE_DETAIL, params)
        )

        return response;
    }

    getCourseTemplateList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_TEMPLATE_LIST),
            { params: query }
        )

        return response;
    }

    getCourseTemplateDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_TEMPLATE_DETAIL, params)
        )

        return response;
    }

    getCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_DETAIL, params)
        )

        return response;
    }

    createCourseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_DETAIL),
            body
        )

        return response;
    }

    updateCourseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COURSE_DETAIL, params),
            body
        )

        return response;
    }

    deleteCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.COURSE_DETAIL, params)
        )

        return response;
    }

    getCourseTagList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_TAG_LIST),
            { params: query }
        )

        return response;
    }

    createCourseTagDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.COURSE_TAG_LIST),
            body
        )

        return response;
    }

    updateCourseTagListOrder = async ({ query, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COURSE_TAG_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }

    deleteCourseTagDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.COURSE_TAG_DETAIL, params)
        )

        return response;
    }

    getCourseProficiencyList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_PROFICIENCY_LIST),
            { params: query }
        )

        return response;
    }

    createCourseProficiencyDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.COURSE_PROFICIENCY_LIST),
            body
        )

        return response;
    }

    updateCourseProficiencyListOrder = async ({ query, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COURSE_PROFICIENCY_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }

    deleteCourseProficiencyDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.COURSE_PROFICIENCY_DETAIL, params)
        )

        return response;
    }

    getCourseLanguageList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_LANGUAGE_LIST),
            { params: query }
        )

        return response;
    }

    createCourseLanguageDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.COURSE_LANGUAGE_LIST),
            body
        )

        return response;
    }

    updateCourseLanguageListOrder = async ({ query, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COURSE_LANGUAGE_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }

    deleteCourseLanguageDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.COURSE_LANGUAGE_DETAIL, params)
        )

        return response;
    }

    getCourseAvailabilityDetail = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_AVAIL_DETAIL, params),
            { params: query }
        )

        return response;
    }

    createCourseAvailabilityDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_AVAIL_DETAIL),
            body
        )

        return response;
    }

    updateCourseAvailabilityDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COURSE_AVAIL_DETAIL, params),
            body
        )

        return response;
    }

    deleteCourseAvailabilityDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.COURSE_AVAIL_DETAIL, params)
        )

        return response;
    }

    getCoursePriceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.COURSE_PRICE_DETAIL, params)
        )

        return response;
    }

    createCoursePriceDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.COURSE_PRICE_LIST),
            body
        )

        return response;
    }

    updateCoursePriceDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COURSE_PRICE_DETAIL, params),
            body
        )

        return response;
    }

    deleteCoursePriceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.COURSE_PRICE_DETAIL, params)
        )

        return response;
    }

    // get course comment list
    getCourseCommentList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_COMMENT_LIST),
            { params: query }
        )

        return response;
    }
    createCourseCommentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_COMMENT_DETAIL),
            body
        )

        return response;
    }
    getCoursePreviewDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_PREVIEW_DETAIL, params)
        )

        return response;
    }

    createNextCourseSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_NEXT_COURSE_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    getCourseTutorList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TUTOR_LIST),
            { params: query }
        )

        return response;
    }

    getCourseTutorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TUTOR_DETAIL, params)
        )

        return response;
    }

    createCourseTutorDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_TUTOR_DETAIL),
            body
        )

        return response;
    }
    updateCourseTutorDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_TUTOR_DETAIL, params),
            body
        )

        return response;
    }

    deleteCourseTutorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_TUTOR_DETAIL, params)
        )

        return response;
    }

    // course config detail services
    getCourseConfigDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_CONFIG_DETAIL, params)
        )

        return response;
    }

    createCourseConfigDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_CONFIG_DETAIL),
            body
        )

        return response;
    }

    updateCourseConfigDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_CONFIG_DETAIL, params),
            body
        )

        return response;
    }

    deleteCourseConfigDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_CONFIG_DETAIL, params)
        )

        return response;
    }

    // clone course detail
    createCloneCourseDetail = async ({ params, body }) => {
        console.log(params, body)
        const response = await baseAuthApiInstance().post(
            format(url.CLONE_COURSE_DETAIL, params),
            body
        )

        return response;
    }

}

export default new CourseService()