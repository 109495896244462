import { useState } from 'react';
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { MdCloudUpload } from "react-icons/md";

import ButtonLoader from 'components/loader/ButtonLoader';

// import { uploadDocumentToS3 } from "redux/document/document.request";
import { uploadFileToS3 } from 'redux/storage/storage.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
// import { setUploadDocumentPayload } from "redux/document/document.slice";

const UploadVideoModal = () => {
  // const { uploadDocument } = useAppState(s => s.document)
  const { uploadFile } = useAppState(s => s.storage)

  const dispatcher = useAppDispatcher()

  const [selectedFile, setSelectedFile] = useState(null)

  const onHandleFileUpload = (event) => {
    let file = event.target.files[0];
    if (!file || file.length === 0) {
      alert("file not selected!")
      return;
    }
    if (file?.size > 100 * 1048576) {
      toast.error("File size too large!")
      return;
    }
    if (file?.size < 100 * 1048576) {
      setSelectedFile({
        file: file,
        fileList: event?.target?.files,
        fileUrl: URL.createObjectURL(file)
      })
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    if (uploadFile?.isLoading) return;

    if (!selectedFile) {
      toast.warn("Please select a file to upload!")
      return;
    }
    // dispatcher(setUploadDocumentPayload({
    //   ...uploadDocument?.uploadDocumentPayload,
    //   fileCategory: "intro_video",
    //   fileName: selectedFile?.file.name,
    //   fileType: selectedFile?.file?.type,
    //   file: selectedFile?.file
    // }))

    const requestDatPayload = {
      fileDetail: selectedFile?.fileList,
      fileOwnerId: uploadFile?.payload?.userId
    }

    dispatcher(uploadFileToS3(requestDatPayload))

  }

  return (
    <form onSubmit={onSubmit} className={"space-y-5"}>
      <div className={"min-h-[12rem] block w-full space-y-12 py-5"}>
        {!selectedFile &&
          <div className={"flex flex-col items-center justify-center "}>
            <MdCloudUpload className={"text-9xl text-text-600"} />
            <label className='relative flex flex-col justify-center items-center overflow-hidden px-3 py-1 rounded-lg border-2 border-text-600 hover:bg-text-300 cursor-pointer'>
              <input
                className={'border border-yellow-300'}
                type={"file"}
                id={"file"}
                name={"image"}
                style={{ display: "none" }}
                accept={"video/mp4,video/x-m4v,video/*"}
                onChange={onHandleFileUpload}
              />
              <span className='font-bodyPri font-medium text-2xl text-text-600 text-wider'>
                {"Upload Video"}
              </span>
            </label>
          </div>
        }
        {selectedFile &&
          <div className={""}>
            <video src={selectedFile?.fileUrl} controls autoPlay />
          </div>
        }
      </div>

      {uploadFile?.message &&
        <div className={"w-full flex items-center justify-center"}>
          <div className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
            {uploadFile?.message}
          </div>
        </div>
      }

      <div className={"flex justify-end items-center gap-5"}>
        <div>
          {(uploadFile?.isLoading) &&
            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
              {"Processing ..."}
            </span>
          }
        </div>
        <button
          type={"submit"}
          className={cn(
            "w-28 py-1 flex justify-center items-center rounded-full focus:outline-none",
            "font-buttons font-normal text-base",
            (!selectedFile) && "text-text-300 border border-text-300 cursor-not-allowed",
            (selectedFile) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
            (uploadFile?.isLoading) && "border border-secondary-main bg-secondary-main text-text-50"
          )}>
          {(uploadFile?.isLoading) &&
            <ButtonLoader isLoading={uploadFile?.isLoading} />
          }
          {(!uploadFile?.isLoading) && "Submit"}
        </button>
      </div>
    </form>
  )
}

export default UploadVideoModal