import { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import { FiEdit } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';

import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { productsHeaderConst, subRowHeader, sortUserLowToHigh, sortUserHighToLow } from 'pages/auth/product/data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { deleteProductDetail, getProductList } from 'redux/product/product.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { resetFilterProps, resetProductList } from 'redux/product/product.slice';
import { productTypeEnum } from 'redux/product/product.const';

import { edulyteAppPagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { generateQueryParams } from 'utils/generators.utils';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

let CURRENT_PRODUCT_ID = null;

const ProductActivity = ({ product }) => {

    const dispatcher = useAppDispatcher()

    const onHandleEditProduct = (productId, userId) => {
        CURRENT_PRODUCT_ID = productId
        dispatcher(createAdminLoginAsDetail({ user_id: userId, source_url: window.location.href }))
    }

    const onHandleDeleteProduct = (productId) => {
        dispatcher(deleteProductDetail(productId))
    }

    return (
        <div className={"flex justify-center items-center"}>
            <button
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
                onClick={() => onHandleEditProduct(product?.id, product?.product_setting?.owner_user?.userId)}
                title={"Edit Product"}
            >
                <FiEdit size={20} className={"text-xl hover:scale-110"} />
            </button>
            <button
                className={"px-2 py-1 bg-red-500 text-white rounded-r-md"}
                onClick={() => onHandleDeleteProduct(product?.id)}
                title={"Delete Product"}
            >
                <AiOutlineDelete size={20} className={"text-xl hover:scale-110"} />
            </button>
        </div>
    )
}

const DisplayProducts = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { productList, filterProps } = useAppState((state) => state.product)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        return () => {
            dispatcher(resetProductList())
            dispatcher(resetFilterProps())
            dispatcher(resetAdminLoginAs())
        }
    }, [])

    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo?.EDIT_PRODUCT?.pagePath}/${CURRENT_PRODUCT_ID}/edit`, "_self")
        }
    }, [adminLoginAs?.data])

    useEffect(() => {
        const query = generateQueryParams(location.search)
        const requestDataPayload = {
            page: query.pn || 1,
            records: filterProps?.records || 25
        }
        dispatcher(getProductList(requestDataPayload))

    }, [location.search, filterProps?.records])

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortProductList = (user1, user2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortUserLowToHigh(activeSortHeader, user1, user2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortUserHighToLow(activeSortHeader, user1, user2)
        }
    }

    const onHandlePageChange = (page) => {
        dispatcher(getProductList({ page: page, records: filterProps?.records }))
    }

    const rows = productList?.data?.results?.slice(0)?.sort(sortProductList)?.map((product) => [
        product?.id,
        product?.title
            ? (product?.title?.length > 30)
                ? product?.title?.slice(0, 30) + " ...."
                : product?.title
            : "-",
        product?.active_slug
            ? (product?.active_slug?.length > 24)
                ? product?.active_slug?.slice(0, 24) + " ...."
                : product?.active_slug
            : "-",
        productTypeEnum[product?.type?.toUpperCase()]?.label || "-",
        dayjs(product?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        <ProductActivity product={product} />,
        dayjs(product?.updatedAt)?.tz(timeZone)?.format('DD MMM, YYYY')
    ]);

    return (
        <>
            <Table
                headers={Object.values(productsHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={productList?.isLoading}
                message={(productList?.message || (productList?.data?.records === 0)) && (productList?.message || "No products found")}
            />
            {(productList?.data && productList?.data?.totalPages > 1) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={productList?.data?.page}
                        totalPages={productList?.data?.totalPages}
                        onChangePage={onHandlePageChange}
                    />
                </div>
            }
        </>
    )
}

export default DisplayProducts;