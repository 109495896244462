import { memo } from 'react';

import LogTimeLine from "components/logTimeLine/LogTimeLine";

import { bookingLogConst } from 'pages/auth/bookings/data';

import { bookingStatusEnum } from 'redux/booking/booking.const';

import { dayjs } from 'utils/dateTime.utils';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const sortBookingLogs = (booking1, booking2) => {
    return dayjs(booking1?.createdAt).isSameOrBefore(booking2?.createdAt) ? 1 : -1
}

const createBookingLogs = (bookingLogs) => {
    return !!bookingLogs?.length && bookingLogs?.slice()?.sort(sortBookingLogs)?.map((booking) => {
        return {
            ...bookingStatusEnum[booking?.status?.toUpperCase()],
            ...booking,
            title: bookingLogConst[booking?.status?.toUpperCase()]?.title
        }
    })
}

const BookingLogs = memo(({ bookingUserDetail }) => {
    return (
        <div className={"mt-4"}>
            <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                <span className={"font-bodyPri font-bold text-lg text-text-900 tracking-wide"}>
                    {"Booking Logs"}
                </span>
                <LogTimeLine logList={createBookingLogs(bookingUserDetail?.bookingLogs)} />
            </div>
        </div>
    )
})

export default BookingLogs;