import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    TUTOR_LIST: "/tutors/tutors",
    TUTOR_USER_PUBLIC_PROFILE: "/tutors/users/{userId}/public",
    TUTOR_USER_PRICE: "/tutors/users/{userId}/prices",

    TUTOR_USER_LESSON: "/tutors/users/{userId}/lessons",

    // new tutor detail apis
    TUTOR_DETAIL: "/tutors/tutors/{tutorId}",
    UPDATE_TUTOR_DETAIL: "/tutors/tutors/{tutorId}",

    // new modified urls for language, tag and category
    GET_TUTOR_LANGUAGE_LIST: "/tutors/languages",
    ADD_TUTOR_LANGUAGE_DETAIL: "/tutors/languages",
    UPDATE_TUTOR_LANGUAGE_DETAIL: "/tutors/languages/{tutorLanguageId}",
    UPDATE_TUTOR_LANGUAGE_LIST_ORDER: "/tutors/languages/order",
    DELETE_TUTOR_LANGUAGE_DETAIL: "/tutors/languages/{tutorLanguageId}",

    GET_TUTOR_TAG_LIST: "/tutors/tags",
    ADD_TUTOR_TAG_DETAIL: "/tutors/tags",
    UPDATE_TUTOR_TAG_LIST_ORDER: "/tutors/tags/order",
    DELETE_TUTOR_TAG_DETAIL: "/tutors/tags/{tagId}",

    GET_TUTOR_CATEGORY_LIST: "/tutors/categories",
    ADD_TUTOR_CATEGORY_DETAIL: "/tutors/categories",
    UPDATE_TUTOR_CATEGORY_DETAIL: "/tutors/categories/{categoryId}",
    UPDATE_TUTOR_CATEGORY_LIST_ORDER: "/tutors/categories/order",
    DELETE_TUTOR_CATEGORY_DETAIL: "/tutors/categories/{categoryId}",

    GET_TUTOR_CONFIG_DETAIL: "/tutors/tutors/{tutorId}/config",
    UPDATE_TUTOR_CONFIG_DETAIL: "/tutors/tutors/{tutorId}/config"

}

export const tutorRedux = {
    sliceName: "tutor",
    tutorList: {
        requestName: "getTutorList"
    },
    updateTutorDetail: {
        requestName: "updateTutorDetail"
    },
    tutorUserPublicProfile: {
        requestName: "getTutorUserPublicProfileByUserId"
    },
    tutorUserPrice: {
        requestName: "getTutorUserPriceByUserId"
    },
    tutorUserLesson: {
        requestName: "getTutorUserLessonByUserId"
    },
    tutorUserCategoryList: {
        requestName: "getTutorUserCategoryListByUserId"
    },

    tutorLanguageList: {
        requestName: "getTutorLanguageList"
    },
    tutorCategoryList: {
        requestName: "getTutorCategoryList"
    },
    tutorTagList: {
        requestName: "getTutorTagList"
    },
    tutorConfigDetail: {
        requestName: "getTutorConfigDetail"
    }
}

export const filterName = {
    ALL_TUTORS: {
        value: "all_tutors"
    }
}

export const tutorStatus = {
    ALL: {
        label: "All",
        value: "all"
    },
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "Inactive",
        value: "inactive"
    },
    BANNED: {
        label: "Banned",
        value: "banned"
    }

}

export const tutorVisibilityEnum = {
    HIDDEN: {
        label: "Hidden",
        value: "hidden"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    },
    PUBLIC: {
        label: "Public",
        value: "public"
    }
}

export const tutorLanguageMessageType = {
    INFO: {
        label: "Info",
        value: "info",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    ERROR: {
        label: "Error",
        value: "error",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const tutorFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",

    tutorId: "",
    userId: "",
    tutorName: "",
    phone: "",
    email: "",
    status: "",
    visibility: "",
    date: "",
    page: 1,
    records: dataEntriesConst?.TWENTY_FIVE?.value
}