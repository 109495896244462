import { dayjs } from "utils/dateTime.utils";
import {
    ButtonAction,
    FilterQuizStatus,
    SearchDate,
    SearchQuizId,
    SearchQuizSlug,
    SearchQuizTitle,
    SearchTutor
} from "./components/LmsQuizFilterStatistics";

export const pageHeading = {
    heading: "Quizzes",
    subHeading: "Find the list of quizzes!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const lmsQuizHeaderConst = {
    QUIZ_ID: {
        label: "Quiz ID",
        value: "quiz_id"
    },
    QUIZ_TITLE: {
        label: "Quiz Title",
        value: "quiz_title"
    },
    QUIZ_SLUG: {
        label: "Quiz Slug",
        value: "quiz_slug"
    },
    OWNER_TUTOR: {
        label: "Owner Tutor",
        value: "owner_tutor"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
}

export const subRowHeader = [
    <SearchQuizId />,
    <SearchQuizTitle />,
    <SearchQuizSlug />,
    <SearchTutor />,
    <FilterQuizStatus />,
    <SearchDate />,
    <ButtonAction />,
]

export const sortQuizLowToHigh = (activeSortHeader, quiz1, quiz2) => {
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.QUIZ_ID?.value) {
        return (Number(quiz1?.id) - Number(quiz2?.id))
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.QUIZ_TITLE?.value) {
        return quiz1?.title?.toLowerCase()?.localeCompare(quiz2?.title?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.QUIZ_SLUG?.value) {
        return quiz1?.active_slug?.toLowerCase()?.localeCompare(quiz2?.active_slug?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.OWNER_TUTOR?.value) {
        return quiz1?.quiz_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(quiz2?.quiz_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.STATUS?.value) {
        return quiz1?.course_setting?.status?.toLowerCase()?.localeCompare(quiz2?.course_setting?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.DATE?.value) {
        return dayjs(quiz1?.createdAt).isSameOrBefore(quiz2?.createdAt) ? 1 : -1
    }
}

export const sortQuizHighToLow = (activeSortHeader, quiz1, quiz2) => {
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.QUIZ_ID?.value) {
        return (Number(quiz2?.id) - Number(quiz1?.id))
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.QUIZ_TITLE?.value) {
        return quiz2?.title?.toLowerCase()?.localeCompare(quiz1?.title?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.QUIZ_SLUG?.value) {
        return dayjs(quiz1?.startDateTime).isSameOrBefore(quiz2?.startDateTime) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.OWNER_TUTOR?.value) {
        return quiz2?.quiz_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase()?.localeCompare(quiz1?.quiz_setting?.default_owner?.tutor?.user?.first_name?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.STATUS?.value) {
        return quiz2?.course_setting?.status?.toLowerCase()?.localeCompare(quiz1?.course_setting?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === lmsQuizHeaderConst?.DATE?.value) {
        return dayjs(quiz2?.createdAt).isSameOrBefore(quiz1?.createdAt) ? 1 : -1
    }
}