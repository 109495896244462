import { cn } from "utils/cn.utils";

import { dashboardStats, usersStats } from 'pages/auth/dashboard/data';


const UserStastics = ({ statItem }) => {

  switch (statItem.value) {
    case dashboardStats.USERS.value:
      statItem.data = 0
      break;
    case dashboardStats.TUTORS.value:
      statItem.data = 0
      break;
    case dashboardStats.BOOKINGS.value:
      statItem.data = 0
      break;
    case dashboardStats.DEPOSITS.value:
      statItem.data = 0
      break;
    case dashboardStats.REFERRALS.value:
      statItem.data = 0
      break;
    case dashboardStats.COURSES.value:
      statItem.data = 0
      break;
    case dashboardStats.PENDING_WITHDRAWALS.value:
      statItem.data = 0
      break;
    case dashboardStats.APPROVED_WITHDRAWALS.value:
      statItem.data = 0
      break;
    case usersStats.NEW_USERS_JOINED.value:
      statItem.data = 0
      break;
    case usersStats.VERIFIED_USERS.value:
      statItem.data = 0
      break;
    default:
      statItem.data = 0
      break;
  }

  return (
    <div className={"flex flex-col bg-white rounded-lg px-5 py-3 gap-3"}>
      <div className={"flex justify-between"}>
        {statItem.icon}
        <span className={"font-bodyComp font-medium text-lg text-text-800"}>
          {statItem.label}
        </span>
      </div>
      <div className="flex justify-between items-end">
        <span className="font-bodyPri font-bold text-xl text-text-900">{statItem.data}</span>
        <span className="font-bodyPri font-normal text-sm text-text-500">{statItem.unit}</span>
      </div>
    </div>
  )
}

export const DashboardStatistics = () => {

  return (
    <div className={"space-y-3"}>
      <div className={cn("grid grid-cols-2 lg:grid-cols-3 gap-3 w-full"
      )}>
        {Object.keys(dashboardStats).map((item, idx) => (
          <UserStastics key={idx} statItem={dashboardStats[item]} />
        ))}
      </div>
      <div className={cn("grid grid-cols-2 gap-3 w-full"
      )}>
        {Object.keys(usersStats).map((item, idx) => (
          <UserStastics key={idx} statItem={usersStats[item]} />
        ))}
      </div>
    </div>
  );
};
