import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Draggable } from "react-drag-reorder";

import { cn } from "utils/cn.utils";

import { MdAdd } from "react-icons/md";
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { MdDragIndicator } from "react-icons/md";

import ComponentLoader from 'components/loader/ComponentLoader';
import DashedBorderButton from 'components/buttons/dashedBorderButton';
import SelectDropDown from 'components/optionSelector/selectDropDown';
import { OptionSelector } from 'components/common-components/Select';

import SaveAndCancelBtn from "pages/auth/editUser/commonComponents/buttons/SaveAndCancelBtn";
import EditUserHeader from 'pages/auth/editUser/commonComponents/header/EditUserHeader';

import {
    getUserBadgeListByUserId,
    getBadgeList,
    createUserBadgeDetailByUserId,
    updateUserBadgeDetailByUserBadgeId,
    updateUserBadgeListOrderByUserId,
    deleteUserBadgeDetailByUserBadgeId
} from "redux/badge/badge.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearAddUserBadgeDetail, setClearBadgeList, setClearUserBadgeList } from "redux/badge/badge.slice";
import { badgeVisibility } from 'redux/badge/badge.const';

const EditUserBadges = () => {
    const { userBadgeList, badgeList, addUserBadgeDetail } = useAppState((state) => state.badge)

    const dispatcher = useAppDispatcher()
    const { userId } = useParams()

    const [isAddNewUserBadge, setIsAddNewUserBadge] = useState(true)
    const [isOpenAddNewBadgeForm, setIsAddNewBadgeForm] = useState(false)
    const [userBadge, setUserBadge] = useState({
        isUpdateBadgeEnabled: false,
        userBadgeId: null,
        badgeId: null,
        badge: "",
        visibility: ""
    })
    const [userBadgesList, setUserBadgesList] = useState(userBadgeList?.filteredUserBadgeList)

    const isBadgesListOrderSimilar = JSON.stringify(userBadgesList) == JSON.stringify(userBadgeList?.filteredUserBadgeList)

    useEffect(() => {
        if (userId) {
            dispatcher(getUserBadgeListByUserId(Number(userId)))
        }

        return () => {
            dispatcher(setClearUserBadgeList())
            dispatcher(setClearAddUserBadgeDetail())
        }
    }, [userId])

    useEffect(() => {
        dispatcher(getBadgeList())

        return () => {
            dispatcher(setClearBadgeList())
        }
    }, [])

    useEffect(() => {
        if (userBadgeList?.filteredUserBadgeList?.length > 0) {
            setUserBadgesList([])
            setUserBadgesList(userBadgeList?.filteredUserBadgeList)
        }
    }, [userBadgeList?.filteredUserBadgeList || userBadgeList?.isLoading])

    const onHandleDeleteBadge = (userBadgeId) => {
        const requestDataPayload = {
            userBadgeId: userBadgeId,
        }
        dispatcher(deleteUserBadgeDetailByUserBadgeId(requestDataPayload))
    }

    const onHandleAddUserBadge = () => {
        dispatcher(setClearAddUserBadgeDetail())
        setUserBadge({
            isUpdateBadgeEnabled: false,
            userBadgeId: null,
            badgeId: null,
            badge: "",
            visibility: ""
        })
        setIsAddNewBadgeForm(true)
        setIsAddNewUserBadge(false)
    }

    const updateUserBadge = () => {
        const body = {}
        if (userBadge?.badgeId) {
            body["badgeId"] = userBadge?.badgeId
        }
        if (userBadge?.visibility) {
            body["visibility"] = userBadge?.visibility
        }
        const requestDataPayload = {
            userBadgeId: userBadge?.userBadgeId,
            body: body
        }
        dispatcher(updateUserBadgeDetailByUserBadgeId(requestDataPayload))
        setUserBadge({
            isUpdateBadgeEnabled: false,
            userBadgeId: null,
            badgeId: null,
            badge: "",
            visibility: null
        })
        setIsAddNewBadgeForm(false)
        setIsAddNewUserBadge(true)
    }

    const onHandleBadges = () => {
        if (userBadge?.isUpdateBadgeEnabled) {
            updateUserBadge()
            return;
        }
        const body = {
            badgeId: userBadge?.badgeId
        }
        if (userBadge?.visibility) {
            body["visibility"] = userBadge?.visibility
        }

        const requestDataPayload = {
            userId: Number(userId),
            body: body
        }

        dispatcher(createUserBadgeDetailByUserId(requestDataPayload))
        setUserBadge({
            isUpdateBadgeEnabled: false,
            userBadgeId: null,
            badgeId: null,
            badge: "",
            visibility: null
        })
        setIsAddNewBadgeForm(false)
        setIsAddNewUserBadge(true)
    }

    const getChangedPos = (currentPos, newPos) => {
        var updatedList = [...userBadgesList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State
        setUserBadgesList(updatedList);
    };

    const onHandleUpdateOrder = () => {
        if (isBadgesListOrderSimilar) return;

        const requestDataPayload = {
            userId: Number(userId),
            body: {
                order: userBadgesList?.map((userBadge) => ({
                    userBadgeId: userBadge?.userBadgeId
                }))
            }
        }
        dispatcher(updateUserBadgeListOrderByUserId(requestDataPayload))
    }

    if (userBadgeList?.errorMsg) {
        return (
            <div className={"w-screen h-screen flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                {userBadgeList?.errorMsg}
            </div>
        )
    }

    return (
        <div className={"w-full space-y-3"}>
            <EditUserHeader title={"User Badges"} />

            {(userBadgeList?.isLoading || addUserBadgeDetail?.isLoading) &&
                <ComponentLoader isLoading={userBadgeList?.isLoading || addUserBadgeDetail?.isLoading} />
            }
            {(!userBadgeList?.isLoading && !addUserBadgeDetail?.isLoading) &&
            <>
                <div className={"w-full sm:w-[60%] p-5 space-y-5"}>
                    {userBadgeList?.filteredUserBadgeList?.length > 0 &&
                        <div className={"flex items-center justify-end gap-5"}>
                            <div className={cn(
                                "px-3 py-0.5 rounded-lg",
                                "font-bodyPri font-normal text-text-800 text-sm cursor-pointer",
                                isBadgesListOrderSimilar && "border border-text-300 bg-text-300",
                                !isBadgesListOrderSimilar && "border border-secondary-dark text-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                            )}
                                onClick={onHandleUpdateOrder}
                            >
                                {"Save Display Order"}
                            </div>
                            <div className={cn(
                                "px-3 py-0.5 border border-text-300 bg-text-300 rounded-lg",
                                "font-bodyPri font-normal text-text-800 text-sm cursor-pointer"
                            )}
                                onClick={() => dispatcher(getUserBadgeListByUserId(Number(userId)))}
                            >
                                {"Reset"}
                            </div>
                        </div>
                    }

                    <Draggable onPosChange={getChangedPos}>
                        {userBadgeList?.filteredUserBadgeList?.length > 0 && userBadgeList?.filteredUserBadgeList?.map((badge, index) => (
                            <div className={"w--full flex items-center justify-start gap-2"}>
                                <MdDragIndicator className={"text-xl text-text-900"} />
                                <div
                                    key={index}
                                    className={"w-full flex flex-row items-start justify-between gap-5 border border-text-400 rounded-lg px-5 py-2"}>
                                    <div className={"flex items-center justify-start gap-5"}>
                                        <div className={"relative w-16 h-16 rounded-full sm:w-24 sm:h-24"}>
                                            <img
                                                src={badge?.badge?.badgePicUrl}
                                                alt={badge?.badge?.tooltip}
                                                className={"w-full h-full rounded-full p-2 object-cover"}
                                            />
                                        </div>

                                        <div className="flex flex-col justify-center items-center md:justify-start md:items-start gap-2">

                                            <div className={"flex items-center justify-start gap-2 font-bodyPri font-normal tracking-wide"}>
                                                <span className={"text-text-700 text-base"}>
                                                    {"Badge Name:"}
                                                </span>
                                                <span className="text-text-900 text-base capitalize">
                                                    {badge?.badge?.badge || "-"}
                                                </span>
                                            </div>

                                            <div className={"flex items-center justify-start gap-2 font-bodyPri font-normal tracking-wide"}>
                                                <span className="text-text-700 text-base">
                                                    {"badge Category:"}
                                                </span>
                                                <span className="text-text-900 text-base capitalize">
                                                    {badge?.badge?.badgeCategory?.badgeCategory}
                                                </span>
                                            </div>

                                            <div className={"flex items-center justify-start gap-2 font-bodyPri font-normal tracking-wide"}>
                                                <span className="text-text-700 text-base">
                                                    {"Visibility:"}
                                                </span>
                                                <span className={"text-text-900 text-base"}>
                                                    {badgeVisibility[badge?.visibility?.toUpperCase()]?.label}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"flex items-center justify-start gap-1.5"}>
                                        <FiEdit
                                            className={"text-xl text-text-500 cursor-pointer"}
                                            onClick={() => {
                                                dispatcher(setClearAddUserBadgeDetail())
                                                setUserBadge({
                                                    isUpdateBadgeEnabled: true,
                                                    userBadgeId: badge?.userBadgeId,
                                                    badgeId: badge?.badge?.badgeId,
                                                    badge: badge?.badge?.badge,
                                                    visibility: badge?.visibility
                                                })
                                                setIsAddNewBadgeForm(true)
                                                setIsAddNewUserBadge(false)
                                            }}
                                        />
                                        <MdOutlineDeleteOutline
                                            className={"text-xl text-text-500 cursor-pointer"}
                                            onClick={() => onHandleDeleteBadge(badge?.userBadgeId)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Draggable>
                    {(userBadgeList?.errorMsg || userBadgeList?.filteredUserBadgeList?.length === 0) &&
                        <div className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {userBadgeList?.errorMsg || "No Badges Added!"}
                        </div>
                    }
                </div>
                <DashedBorderButton
                    isShowBtn={isAddNewUserBadge && (!userBadgeList?.filteredUserBadgeList || userBadgeList?.filteredUserBadgeList?.length === 0)}
                    btnTitle={"Add Badge(s)"}
                    onHandleAdd={onHandleAddUserBadge}
                />
                {(isAddNewUserBadge && userBadgeList?.filteredUserBadgeList && userBadgeList?.filteredUserBadgeList?.length >= 1) &&
                    <div
                        className={cn(
                            "w-fit flex item-center justify-start px-5 cursor-pointer",
                            "font-buttons font-normal text-base text-text-700",
                            "hover:text-text-800"
                        )}
                        onClick={onHandleAddUserBadge}
                    >
                        <MdAdd className={"text-xl"} />
                        <span className={"whitespace-nowrap"}>
                            {"Add Badge"}
                        </span>
                    </div>
                }
                {addUserBadgeDetail?.errorMsg &&
                    <span className={"px-5 py-1 font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {addUserBadgeDetail?.errorMsg}
                    </span>
                }
                {isOpenAddNewBadgeForm &&
                    <div className='w-full sm:w-[60%] flex flex-col items-center justify-center gap-5 px-5'>
                        <div className={"w-full flex items-start justify-start gap-8"}>
                            <div className={"w-full flex flex-col items-center justify-start gap-3"}>
                                <div className={"font-bodyPri font-medium text-text-800 text-lg"}>
                                    {"Badge"}
                                </div>
                                {badgeList?.filteredBadgeList &&
                                    <SelectDropDown
                                        options={badgeList?.filteredBadgeList?.map((badge) => ({
                                            label: badge?.badge,
                                            value: badge?.badgeId,
                                            image: badge?.badgePicUrl
                                        }))}
                                        className={"w-full h-52"}
                                        value={userBadge?.badgeId}
                                        onChange={(option) => {
                                            if (!option?.value) {
                                                alert("Please Select any!")
                                                return;
                                            }
                                            setUserBadge({
                                                ...userBadge,
                                                badgeId: option?.value,
                                                badge: option?.label
                                            })
                                        }}
                                    />
                                }
                            </div>
                            <div className={"w-full flex flex-col items-center justify-start gap-3"}>
                                <div className={"font-bodyPri font-medium text-text-900 text-lg"}>
                                    {"Visibility"}
                                </div>
                                <OptionSelector
                                    options={Object.values(badgeVisibility)}
                                    className={"w-full h-52"}
                                    value={userBadge?.visibility}
                                    onChange={(option) => setUserBadge({
                                        ...userBadge,
                                        visibility: option.value
                                    })}
                                />
                            </div>
                        </div>
                        <SaveAndCancelBtn
                            onSave={onHandleBadges}
                            onCancel={() => {
                                setUserBadge({
                                    isUpdateBadgeEnabled: false,
                                    userBadgeId: null,
                                    badgeId: null,
                                    badge: "",
                                    visibility: null
                                })
                                setIsAddNewBadgeForm(false)
                                setIsAddNewUserBadge(true)
                            }}
                        />
                    </div>
                }
            </>
            }
        </div>
    )
}

export default EditUserBadges;