import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import tutorService from "redux/tutor/tutor.service";

import {
    setTutorUserPublicProfileLoading,
    setTutorUserPublicProfile,
    setTutorUserPublicProfileErrorMsg,

    setTutorUserPriceLoading,
    setTutorUserPrice,
    setTutorUserPriceErrorMsg,

    setTutorDetailLoading,
    setTutorDetailData,
    setTutorDetailMessage,

    setUpdateTutorDetailLoading,
    setUpdateTutorDetailData,
    setUpdateTutorDetailMessage,

    setAddTutorUserPriceLoading,
    setAddTutorUserPriceErrorMsg,

    setTutorUserLessonLoading,
    setTutorUserLesson,
    setTutorUserLessonErrorMsg,

    setAddTutorUserLessonLoading,
    setAddTutorUserLessonErrorMsg,

    setTutorLanguageListLoading,
    setTutorLanguageListData,
    setTutorLanguageListMessage,

    setAddTutorLanguageDetailLoading,
    setAddTutorLanguageDetailMessage,

    setUpdateTutorLanguageDetailLoading,
    setUpdateTutorLanguageDetailMessage,

    setUpdateTutorLanguageListOrderLoading,
    setUpdateTutorLanguageListOrderMessage,

    setDeleteTutorLanguageDetailLoading,
    setDeleteTutorLanguageDetailMessage,

    setTutorTagListLoading,
    setTutorTagListData,
    setTutorTagListMessage,

    setAddTutorTagDetailLoading,
    setAddTutorTagDetailMessage,

    setUpdateTutorTagListOrderLoading,
    setUpdateTutorTagListOrderMessage,

    setDeleteTutorTagDetailLoading,
    setDeleteTutorTagDetailMessage,

    setTutorCategoryListLoading,
    setTutorCategoryListData,
    setTutorCategoryListMessage,

    setAddTutorCategoryDetailLoading,
    setAddTutorCategoryDetailMessage,

    setUpdateTutorCategoryDetailLoading,
    setUpdateTutorCategoryDetailMessage,

    setUpdateTutorCategoryListOrderLoading,
    setUpdateTutorCategoryListOrderMessage,

    setDeleteTutorCategoryDetailLoading,
    setDeleteTutorCategoryDetailMessage,

    setTutorConfigDetailLoading,
    setTutorConfigDetailData,
    setTutorConfigDetailMessage,

    setUpdateTutorConfigDetailLoading,
    setUpdateTutorConfigDetailMessage,
} from "redux/tutor/tutor.slice";
import { tutorRedux } from "redux/tutor/tutor.const";

export const getTutorList = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorList?.requestName}`,
    async (query, { rejectWithValue }) => {
        try {
            const requestData = {
                query: query
            }
            const response = await tutorService.getTutorList(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

// request for tutor detail
export const getTutorDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorDetail?.requestName}`,
    async (tutorId, { dispatch }) => {
        dispatch(setTutorDetailLoading(true))

        try {
            const requestData = {
                params: { tutorId: tutorId }
            }

            const response = await tutorService.getTutorDetail(requestData)
            if (response.status === 200) {
                dispatch(setTutorDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setTutorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
        } finally {
            dispatch(setTutorDetailLoading(false))
        }
    }
)

// request for update tutor detail by user id
export const updateTutorUserDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.updateTutorDetail?.requestName}`,
    async (tutorDetailPayload, { dispatch }) => {
        dispatch(setUpdateTutorDetailLoading(true))

        try {
            const requestData = {
                params: { tutorId: tutorDetailPayload?.tutorId },
                body: tutorDetailPayload?.body
            }
            const response = await tutorService.updateTutorUserDetail(requestData)
            if (response.status === 200) {
                dispatch(setUpdateTutorDetailData(response.data.data))
                dispatch(setTutorDetailData(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setUpdateTutorDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
        } finally {
            dispatch(setUpdateTutorDetailLoading(false))
        }
    }
)


export const getTutorUserPublicProfileByUserId = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorUserPublicProfile?.requestName}`,
    async (userId, { dispatch }) => {
        dispatch(setTutorUserPublicProfileLoading(true))

        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await tutorService.getTutorUserPublicProfileByUserId(requestData)
            if (response.status === 200) {
                dispatch(setTutorUserPublicProfile(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setTutorUserPublicProfileErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setTutorUserPublicProfileLoading(false))
        }
    }
)


// request for tutor price by user id
export const getTutorUserPriceByUserId = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorUserPrice?.requestName}`,
    async (userId, { dispatch }) => {
        dispatch(setTutorUserPriceLoading(true))

        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await tutorService.getTutorUserPriceByUserId(requestData)
            if (response.status === 200) {
                dispatch(setTutorUserPrice(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setTutorUserPriceErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setTutorUserPriceLoading(false))
        }
    }
)

// request for update tutor price by user id
export const updateTutorUserPriceByUserId = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorUserPrice?.requestName}`,
    async (tutorPriceDetail, { dispatch }) => {
        dispatch(setAddTutorUserPriceLoading(true))

        try {
            const requestData = {
                params: { userId: tutorPriceDetail?.userId },
                body: tutorPriceDetail?.body
            }
            const response = await tutorService.updateTutorUserPriceByUserId(requestData)
            if (response.status === 200) {
                dispatch(setTutorUserPrice(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddTutorUserPriceErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setAddTutorUserPriceLoading(false))
        }
    }
)

// request for tutor lesson by user id
export const getTutorUserLessonByUserId = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorUserLesson?.requestName}`,
    async (userId, { dispatch }) => {
        dispatch(setTutorUserLessonLoading(true))

        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await tutorService.getTutorUserLessonByUserId(requestData)
            if (response.status === 200) {
                dispatch(setTutorUserLesson(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setTutorUserLessonErrorMsg(error.response.data.message || error.response.data.error))
        } finally {
            dispatch(setTutorUserLessonLoading(false))
        }
    }
)

// request for update tutor lesson by user id
export const updateTutorUserLessonByUserId = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorUserLesson?.requestName}`,
    async (tutorLessonDetail, { dispatch }) => {
        dispatch(setAddTutorUserLessonLoading(true))

        try {
            const requestData = {
                params: { userId: tutorLessonDetail?.userId },
                body: tutorLessonDetail?.body
            }
            const response = await tutorService.updateTutorUserLessonByUserId(requestData)
            if (response.status === 200) {
                dispatch(setTutorUserLesson(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            dispatch(setAddTutorUserLessonErrorMsg(error.response.data.message || error.response.data.error))
            toast.error(error.response.data.message || error.response.data.error)
        } finally {
            dispatch(setAddTutorUserLessonLoading(false))
        }
    }
)



/////////////////////////////////////////////////////// new tutor modified apis////////////////////////////////////////////////

// new modified tutor language apis
export const getTutorLanguageList = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorLanguageList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setTutorLanguageListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await tutorService.getTutorLanguageList(requestData)
            if (response.status === 200) {
                dispatch(setTutorLanguageListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setTutorLanguageListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setTutorLanguageListLoading(false))
        }
    }
)

export const createTutorLanguageDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorLanguageList?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddTutorLanguageDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await tutorService.createTutorLanguageDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorLanguageList({ tutorId: body?.tutorId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddTutorLanguageDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setAddTutorLanguageDetailLoading(false))
        }
    }
)

export const updateTutorLanguageDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorLanguageList?.requestName}`,
    async (tutorLanguageDetailPayload, { dispatch, getState }) => {
        dispatch(setUpdateTutorLanguageDetailLoading(true))

        try {
            const { tutorDetail } = getState().tutor
            const requestData = {
                params: { tutorLanguageId: tutorLanguageDetailPayload?.tutorLanguageId },
                body: tutorLanguageDetailPayload?.body
            }
            const response = await tutorService.updateTutorLanguageDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorLanguageList({ tutorId: tutorDetail?.data?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setUpdateTutorLanguageDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setUpdateTutorLanguageDetailLoading(false))
        }
    }
)

export const updateTutorLanguageListOrder = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorLanguageList?.requestName}`,
    async (tutorLanguageListPayload, { dispatch }) => {
        dispatch(setUpdateTutorLanguageListOrderLoading(true))

        try {
            const requestData = {
                body: tutorLanguageListPayload?.body,
                query: tutorLanguageListPayload?.query
            }
            const response = await tutorService.updateTutorLanguageListOrder(requestData)
            if (response.status === 200) {
                dispatch(getTutorLanguageList({ tutorId: tutorLanguageListPayload?.query?.tutorId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setUpdateTutorLanguageListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setUpdateTutorLanguageListOrderLoading(false))
        }
    }
)

export const deleteTutorLanguageDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorLanguageList?.requestName}`,
    async (tutorLanguageId, { dispatch, getState }) => {
        dispatch(setDeleteTutorLanguageDetailLoading(true))

        try {
            const { tutorDetail } = getState().tutor
            const requestData = {
                params: { tutorLanguageId: tutorLanguageId }
            }
            const response = await tutorService.deleteTutorLanguageDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorLanguageList({ tutorId: tutorDetail?.data?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setDeleteTutorLanguageDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setDeleteTutorLanguageDetailLoading(false))
        }
    }
)

// new modified tutor user tag api
export const getTutorTagList = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorTagList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setTutorTagListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await tutorService.getTutorTagList(requestData)
            if (response.status) {
                dispatch(setTutorTagListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setTutorTagListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setTutorTagListLoading(false))
        }
    }
)

export const createTutorTagDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorTagList?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddTutorTagDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await tutorService.createTutorTagDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorTagList({ tutorId: body?.tutorId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddTutorTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddTutorTagDetailLoading(false))
        }
    }
)

export const updateTutorTagListOrder = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorTagList?.requestName}`,
    async (tutorTagListPayload, { dispatch, getState }) => {
        dispatch(setUpdateTutorTagListOrderLoading(true))

        try {
            const { tutorDetail } = getState().tutor
            const requestData = {
                body: tutorTagListPayload?.body,
                query: tutorTagListPayload?.query
            }
            const response = await tutorService.updateTutorTagListOrder(requestData)
            if (response.status === 200) {
                dispatch(getTutorTagList({ tutorId: tutorDetail?.data?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setUpdateTutorTagListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setUpdateTutorTagListOrderLoading(false))
        }
    }
)

export const deleteTutorTagDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorTagList?.requestName}`,
    async (tagId, { dispatch, getState }) => {
        dispatch(setDeleteTutorTagDetailLoading(true))

        try {
            const { tutorDetail } = getState().tutor
            const requestData = {
                params: { tagId: tagId }
            }
            const response = await tutorService.deleteTutorTagDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorTagList({ tutorId: tutorDetail?.data?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setDeleteTutorTagDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setDeleteTutorTagDetailLoading(false))
        }
    }
)

// new modified tutor category apis
export const getTutorCategoryList = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorCategoryList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setTutorCategoryListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await tutorService.getTutorCategoryList(requestData)
            if (response.status === 200) {
                dispatch(setTutorCategoryListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setTutorCategoryListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setTutorCategoryListLoading(false))
        }
    }
)

export const createTutorCategoryDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorCategoryList?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddTutorCategoryDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await tutorService.createTutorCategoryDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorCategoryList({ tutorId: body?.tutorId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddTutorCategoryDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddTutorCategoryDetailLoading(false))
        }
    }
)


export const updateTutorCategoryDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorCategoryList?.requestName}`,
    async (tutorCategoryDetailPayload, { dispatch }) => {
        dispatch(setUpdateTutorCategoryDetailLoading(true))

        try {
            const requestData = {
                params: { categoryId: tutorCategoryDetailPayload?.categoryId },
                body: tutorCategoryDetailPayload?.body
            }
            const response = await tutorService.updateTutorCategoryDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorCategoryList({ tutorId: tutorCategoryDetailPayload?.body?.tutorId }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setUpdateTutorCategoryDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setUpdateTutorCategoryDetailLoading(false))
        }
    }
)

export const updateTutorCategoryListOrder = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorCategoryList?.requestName}`,
    async (tutorCategoryListPayload, { dispatch, getState }) => {
        dispatch(setUpdateTutorCategoryListOrderLoading(true))

        try {
            const { tutorDetail } = getState().tutor
            const requestData = {
                body: tutorCategoryListPayload?.body,
                query: tutorCategoryListPayload?.query
            }
            const response = await tutorService.updateTutorCategoryListOrder(requestData)
            if (response.status === 200) {
                dispatch(getTutorCategoryList({ tutorId: tutorDetail?.data?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setUpdateTutorCategoryListOrderMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setUpdateTutorCategoryListOrderLoading(false))
        }
    }
)


export const deleteTutorCategoryDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorCategoryList?.requestName}`,
    async (categoryId, { dispatch, getState }) => {
        dispatch(setDeleteTutorCategoryDetailLoading(true))

        try {
            const { tutorDetail } = getState().tutor
            const requestData = {
                params: { categoryId: categoryId }
            }
            const response = await tutorService.deleteTutorCategoryDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorCategoryList({ tutorId: tutorDetail?.data?.id }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setDeleteTutorCategoryDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setDeleteTutorCategoryDetailLoading(false))
        }
    }
)

// tutor config detail
export const getTutorConfigDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorConfigDetail?.requestName}`,
    async (tutorId, { dispatch }) => {
        dispatch(setTutorConfigDetailLoading(true))

        try {
            const requestData = {
                params: { tutorId: tutorId }
            }
            const response = await tutorService.getTutorConfigDetail(requestData)
            if (response.status === 200) {
                dispatch(setTutorConfigDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setTutorConfigDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
        } finally {
            dispatch(setTutorConfigDetailLoading(false))
        }
    }
)

export const updateTutorConfigDetail = createAsyncThunk(
    `${tutorRedux?.sliceName}/${tutorRedux?.tutorConfigDetail?.requestName}`,
    async (payload, { dispatch }) => {
        dispatch(setUpdateTutorConfigDetailLoading(true))

        try {
            const requestData = {
                params: { tutorId: payload?.tutorId },
                body: payload?.body
            }
            const response = await tutorService.updateTutorConfigDetail(requestData)
            if (response.status === 200) {
                dispatch(getTutorConfigDetail(payload?.tutorId))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setUpdateTutorConfigDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setUpdateTutorConfigDetailLoading(false))
        }
    }
)