import { memo } from 'react'
import { cn } from 'utils/cn.utils';

export const InputTextWithLabel = memo(({
    key = "",
    testId = "",
    isRequired = false,
    inputLabelContent = "",
    inputLabel = "",
    value = "",
    maxLength,
    placeholder = "Enter",
    disabled = false,
    containerClassName = "",
    wrapperClassName = "",
    labelClassName = "",
    inputClassName = "",
    error = null,
    info = null,
    inputProp,
    onChange,
}) => {
    return (
        <div className={`flex flex-col ${containerClassName}`}>
            <div
                className={cn(
                    "relative font-bodyPri px-3 py-2 border border-divider-lightDark rounded-md",
                    "hover:border-primary-main focus-within:ring-1 focus-within:ring-primary-main focus-within:border-primary-main",
                    wrapperClassName
                )}
            >
                <label
                    for={inputLabelContent}
                    className={cn(
                        "absolute inline-block px-1 -mt-px text-sm sm:text-base font-medium text-gray-900 bg-white -top-3 left-2 line-clamp-1",
                        labelClassName
                    )}
                >
                    {inputLabel}
                    {isRequired &&
                        <span className={"font-bodyPri font-normal text-red-500 text-sm sm:text-base"}>{"*"}</span>
                    }
                </label>
                <input
                    type={"text"}
                    name={inputLabelContent}
                    id={inputLabelContent}
                    className={cn(
                        "block w-full px-0 py-1.5 text-gray-900 placeholder-gray-500 ",
                        "border-0 focus:ring-0 text-sm sm:text-base placeholder:text-text-500 focus:outline-none",
                        inputClassName
                    )}
                    key={key}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    maxLength={maxLength}
                    {...inputProp}
                    data-testid={testId}
                />
            </div>

            {error && <p className="text-sm tracking-wide text-red-700">{error}</p>}
            {info && (
                <p className="mt-1 text-sm tracking-wide text-slate-500">{info}</p>
            )}
        </div>
    )
});

export const InputTextAreaWithLabel = memo(({
    key = "",
    testId = "",
    // cols = 2,
    rows = 2,
    isRequired = false,
    inputLabelContent = "",
    inputLabel = "",
    value = "",
    placeholder = "Enter",
    disabled = false,
    containerClassName = "",
    wrapperClassName = "",
    inputClassName = "",
    error = null,
    info = null,
    inputProp,
    onChange,
}) => {
    return (
        <div className={`flex flex-col ${containerClassName}`}>
            <div
                className={cn(
                    "relative font-bodyPri px-3 py-2 border border-divider-lightDark rounded-md",
                    "hover:border-primary-main focus-within:ring-1 focus-within:ring-primary-main focus-within:border-primary-main",
                    wrapperClassName
                )}
            >
                <label
                    for={inputLabelContent}
                    className={"absolute inline-block px-1 -mt-px text-sm sm:text-base font-medium text-gray-900 bg-white -top-3 left-2 line-clamp-1"}
                >
                    {inputLabel}
                    {isRequired &&
                        <span className={"font-bodyPri font-normal text-red-500 text-sm sm:text-base"}>{"*"}</span>
                    }
                </label>
                <textarea
                    type={"text"}
                    name={inputLabelContent}
                    id={inputLabelContent}
                    className={cn(
                        "block w-full px-0 py-1.5 text-gray-900 placeholder-gray-500 ",
                        "border-0 focus:ring-0 text-sm sm:text-base focus:outline-none",
                        inputClassName
                    )}
                    key={key}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value}
                    rows={rows}
                    onChange={onChange}
                    {...inputProp}
                    data-testid={testId}
                />
            </div>

            {error && <p className="text-sm tracking-wide text-red-700">{error}</p>}
            {info && (
                <p className="mt-1 text-sm tracking-wide text-slate-500">{info}</p>
            )}
        </div>
    )
})

export const InputTextWithEndAdornment = memo(({
    key = "",
    testId = "",
    isRequired = false,
    inputLabelContent = "",
    inputLabel = "",
    value = "",
    endAdornment = null,
    placeholder = "Enter",
    disabled = false,
    containerClassName = "",
    wrapperClassName = "",
    labelClassName = "",
    inputClassName = "",
    error = null,
    info = null,
    inputProp,
    onChange,
}) => {

    return (
        <div className={`flex flex-col ${containerClassName}`}>
            <div
                className={cn(
                    "relative font-bodyPri flex items-center justify-start gap-3 px-3 py-2 border border-divider-lightDark rounded-md",
                    "hover:border-primary-main focus-within:ring-1 focus-within:ring-primary-main focus-within:border-primary-main",
                    wrapperClassName
                )}
            >
                <label
                    for={inputLabelContent}
                    className={cn(
                        "absolute inline-block px-1 -mt-px text-sm sm:text-base font-medium text-gray-900 bg-white -top-3 left-2 line-clamp-1",
                        labelClassName,
                    )}
                >
                    {inputLabel}
                    {isRequired &&
                        <span className={"font-bodyPri font-normal text-red-500 text-sm sm:text-base"}>{"*"}</span>
                    }
                </label>
                <input
                    type={"number"}
                    name={inputLabelContent}
                    id={inputLabelContent}
                    className={cn(
                        "block flex-1 px-0 py-1.5 input-number-spin-none text-gray-900 placeholder-gray-500 ",
                        "border-0 focus:ring-0 text-sm sm:text-base placeholder:text-text-500 focus:outline-none",
                        inputClassName
                    )}
                    key={key}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    {...inputProp}
                    data-testid={testId}
                />
                {endAdornment}
            </div>

            {error && <p className="text-sm tracking-wide text-red-700">{error}</p>}
            {info && (
                <p className="mt-1 text-sm tracking-wide text-slate-500">{info}</p>
            )}
        </div>
    )
})