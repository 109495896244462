import { dataEntriesConst } from "redux/local/local.const"

export const url = {
    GET_LMS_RESOURCE_LIST: "lms/resources/resources",
    DELETE_LMS_RESOURCE_DETAIL: "lms/resources/resources/{resourceId}"
}

export const lmsResourceRedux = {
    sliceName: "lmsResource",
    getLmsResourceList: {
        requestName: "getLmsResourceList"
    }
}

export const lmsResourceTypeEnum = {
    FILE: {
        label: "File",
        value: "file"
    },
    YOUTUBE: {
        label: "Youtube",
        value: "youtube"
    },
    LINK: {
        label: "Link",
        value: "link"
    }
}

export const resourceFileTypeEnum = {
    VIDEO: {
        label: "Video",
        value: "video"
    },
    IMAGE: {
        label: "Image",
        value: "image"
    },
    SLIDE: {
        label: "Slide",
        value: "slide"
    },
    PDF: {
        label: "PDF",
        value: "pdf"
    },
    DOC: {
        label: "Doc",
        value: "doc"
    },
    SHEET: {
        label: "Sheet",
        value: "sheet"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}
export const lmsResourceStatusEnum = {

}

export const DEFAULT_RESOURCE_LIST_PAGE = 1

export const DEFAULT_RESOURCE_LIST_RECORDS = dataEntriesConst.TWENTY_FIVE.value

export const lmsResourceFilterProps = {
    page: DEFAULT_RESOURCE_LIST_PAGE,
    records: DEFAULT_RESOURCE_LIST_RECORDS,
    resourceId: null,
    resourceTitle: "",
    search: "",
    resourceType: "",
    resourceFileType: "",
}