import { useState, useEffect } from 'react';
import { useParams, Outlet, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FiEdit } from 'react-icons/fi';
import { FaRegComment } from "react-icons/fa";

import FullPageLoader from "components/loader/FullPageLoader";
import DetailedView from "components/detailedView/DetailedView";
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';
import SideOverPanel from 'components/common-components/SideOverPanel';

import { courseMenuData } from '../courses/data';
import CourseNavigationOptions from './CourseNavigationOptions';
import { viewCourseDetailsNavigationOptions, viewCourseHeaderConst } from "pages/auth/viewCourseDetail/data";
import ViewCourseDetailSideOverContent from './components/ViewCourseDetailSideOverContent';
import { PageContentStyle } from './viewCourse.style';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getCourseDetail } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCourseDetail } from 'redux/course/course.slice';
import { courseStatus, courseType } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const ViewCourseDetailPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { courseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const [sideOverPanel, setSideOverPanel] = useState(false)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.VIEW_COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (courseId) {
            dispatcher(getCourseDetail(courseId))
        }

        return () => {
            dispatcher(setClearCourseDetail())
        }
    }, [courseId])


    const onHandleComments = () => {
        setSideOverPanel(true)
    }

    const onHandleSelect = (option, course) => {
        switch (option.value) {
            case courseMenuData.PUBLIC_VIEW.value:
                if ([courseStatus?.PUBLISHED?.value]?.includes(course?.status)) {
                    window.open(`${EDULYTE_APP_DOMAIN_URL}${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${course?.slug}`)
                } else {
                    toast.warn("Course is not published yet!")
                }
            default:
                break;
        }
    }

    const rows = [
        courseDetail?.courseDetail?.id || "-",
        courseDetail?.courseDetail?.title || "-",
        courseDetail?.courseDetail?.slug || "-",
        courseDetail?.courseDetail?.tutor?.user?.firstName + " " + courseDetail?.courseDetail?.tutor?.user?.lastName?.charAt(0) || "-",
        `${courseDetail?.courseDetail?.category} (${courseDetail?.courseDetail?.segment})` || "-",
        courseType[courseDetail?.courseDetail?.type?.toUpperCase()]?.label || "-",
        courseStatus[courseDetail?.courseDetail?.status?.toUpperCase()]?.label || "-",
        dayjs(courseDetail?.courseDetail?.createdAt)?.tz(timeZone).format('DD MMM, YYYY') || "-",
        courseDetail?.courseDetail?.submittedAt ? dayjs(courseDetail?.courseDetail?.submittedAt)?.tz(timeZone).format('DD MMM, YYYY') : "-",
        courseDetail?.courseDetail?.publishedAt ? dayjs(courseDetail?.courseDetail?.publishedAt)?.tz(timeZone).format('DD MMM, YYYY') : "-",

        <div className={"flex items-center justify-center gap-1.5"}>
            <Link
                to={`${pagesInfo?.EDIT_COURSE?.pagePath}/${courseDetail?.courseDetail?.id}/edit`}
                className={"cursor-pointer text-text-700"}
            >
                <FiEdit className={"text-xl hover:scale-110"} />
            </Link>
            {[courseStatus.PUBLISHED.value]?.includes(courseDetail?.courseDetail?.status) &&
                <ThreeDotMenu menuItems={courseMenuData} onHandleSelect={(option) => onHandleSelect(option, courseDetail?.courseDetail)} />
            }
        </div>
    ]

    if (courseDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={courseDetail?.isLoading} />
        )
    }

    return (
        <PageContentStyle>
            <SideOverPanel
                child={<ViewCourseDetailSideOverContent />}
                isOpen={sideOverPanel}
                onClose={(isShow) => setSideOverPanel(isShow)}
            />
            <div className={"w-full h-full min-h-screen mx-auto p-5 space-y-3"}>
                <DetailedView
                    headers={Object.values(viewCourseHeaderConst)}
                    rows={rows}
                    rowHeaderColor={"bg-background-medium"}
                    rowColor={`bg-${courseStatus[courseDetail?.courseDetail?.status?.toUpperCase()]?.lightColor}`}
                />
                <div className={"w-full flex flex-col items-start justify-start space-y-3 md:space-y-0 md:space-x-3 md:flex-row"}>
                    <div className={"sticky top-[5rem] w-full md:w-fit"}>
                        <CourseNavigationOptions options={viewCourseDetailsNavigationOptions} />
                    </div>
                    <div className={"w-full rounded-lg p-5 bg-white"}>
                        <Outlet />
                    </div>
                </div>
                <div className='flex items-end justify-start p-2'>
                    <span
                        className="p-1.5 bg-complementary-light border border-complementary-light rounded-full shadow-md cursor-pointer"
                        title={"Comments"}
                        onClick={onHandleComments}
                    >
                        <FaRegComment className={"text-text-600 hover:text-text-800 text-3xl md:text-4xl"} />
                    </span>
                </div>
            </div>
        </PageContentStyle>
    )
}

export default ViewCourseDetailPage;