import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import PayoutService from "redux/payout/payout.service";

import {
    setUserPayoutList,
} from "redux/payout/payout.slice";
import { payoutRedux } from "redux/payout/payout.const";

export const getUserPayoutListByUserId = createAsyncThunk(
    `${payoutRedux?.sliceName}/${payoutRedux?.userPayoutList?.requestName}`,
    async (userId, { rejectWithValue }) => {
        try {
            const requestData = {
                params: { userId: userId }
            }
            const response = await PayoutService.getUserPayoutListByUserId(requestData)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)

export const createUserPayoutDetailByUserId = createAsyncThunk(
    `${payoutRedux?.sliceName}/${payoutRedux?.userPayoutDetail?.requestName}`,
    async (userPayoutData, { dispatch, getState, rejectWithValue }) => {

        try {
            const { userPayoutList } = getState().payout
            const requestData = {
                params: { userId: userPayoutData?.userId },
                body: userPayoutData?.body
            }
            const response = await PayoutService.createUserPayoutDetailByUserId(requestData)
            if (response.status === 200) {
                dispatch(setUserPayoutList([
                    ...userPayoutList?.userPayoutList,
                    response.data.data
                ]))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error)
            toast.error(error.response.data.message || error.response.data.error)
            rejectWithValue(error.response.data.message || error.response.data.error)
        }
    }
)