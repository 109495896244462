import { dayjs } from "utils/dateTime.utils";
import {
  SearchResourceId,
  SearchResourceTitle,
  FilterResourceType,
  FilterResourceStatus,
  SearchDate,
  ButtonAction,
} from "./components/LmsResourceFilterStatistics";

export const pageHeading = {
  heading: "Resources",
  subHeading: "Find the list of resources!",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const lmsResourceHeaderConst = {
  RESOURCE_ID: {
    label: "Resource ID",
    value: "resource_id"
  },
  RESOURCE_TITLE: {
    label: "Resource Title",
    value: "resource_title"
  },
  RESOURCE_TYPE: {
    label: "Resource Type",
    value: "resource_type"
  },
  STATUS: {
    label: "Status",
    value: "status"
  },
  DATE: {
    label: "Date",
    value: "date"
  },
  ACTIVITY: {
    label: "Activity",
    value: "activity"
  },
}

export const subRowHeader = [
  <SearchResourceId />,
  <SearchResourceTitle />,
  <FilterResourceType />,
  <FilterResourceStatus />,
  <SearchDate />,
  <ButtonAction />,
]

export const sortResourceLowToHigh = (activeSortHeader, resource1, resource2) => {
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.RESOURCE_ID?.value) {
    return (Number(resource1?.id) - Number(resource2?.id))
  }
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.RESOURCE_TITLE?.value) {
    return resource1?.title?.toLowerCase()?.localeCompare(resource2?.title?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.RESOURCE_TYPE?.value) {
    return resource1?.type?.toLowerCase()?.localeCompare(resource2?.type?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.STATUS?.value) {
    return resource1?.course_setting?.status?.toLowerCase()?.localeCompare(resource2?.course_setting?.status?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.DATE?.value) {
    return dayjs(resource1?.createdAt).isSameOrBefore(resource2?.createdAt) ? 1 : -1
  }
}

export const sortResourceHighToLow = (activeSortHeader, resource1, resource2) => {
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.RESOURCE_ID?.value) {
    return (Number(resource2?.id) - Number(resource1?.id))
  }
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.RESOURCE_TITLE?.value) {
    return resource2?.title?.toLowerCase()?.localeCompare(resource1?.title?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.RESOURCE_TYPE?.value) {
    return resource2?.type?.toLowerCase()?.localeCompare(resource1?.type?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.STATUS?.value) {
    return resource2?.course_setting?.status?.toLowerCase()?.localeCompare(resource1?.course_setting?.status?.toLowerCase())
  }
  if (activeSortHeader?.activeSortKey === lmsResourceHeaderConst?.DATE?.value) {
    return dayjs(resource2?.createdAt).isSameOrBefore(resource1?.createdAt) ? 1 : -1
  }
}