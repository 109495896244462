import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa'

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setStickyNotePayload, setClearStickyNotePayload } from "redux/local/local.slice";

const StickyNoteModal = () => {
    const { stickyNotePayload } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const [note, setNote] = useState(stickyNotePayload?.note)

    useEffect(() => {
        return () => {
            dispatcher(setClearStickyNotePayload())
        }
    }, [])

    const handleOnSubmit = () => {
        dispatcher(setStickyNotePayload({
            ...stickyNotePayload,
            isLoading: true,
            comments: note
        }))
    }

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[16rem] block space-y-2 pt-5 px-3'}>
                <div className={"flex items-center justify-start"}>
                    <span className={"font-bodyPri font-medium text-md text-text-900 text-center"}>
                        {stickyNotePayload?.title}
                    </span>
                </div>

                <div className={"flex flex-col items-start gap-3"}>
                    <textarea
                        className={cn(
                            "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                            "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                            "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                        )}
                        value={note}
                        onChange={(event) => setNote(event.target.value)}
                        name={"comments"}
                        placeholder={"Write your comments ...."}
                        rows={4}
                    />
                </div>

            </div>

            <div className={"flex justify-between"}>
                <div className={"flex items-center justify-center gap-2"}>
                    {stickyNotePayload?.isLoading &&
                        <>
                            <span className={"font-bodyPri font-normal text-base text-text-700"}>
                                {"Loading"}
                            </span>
                            <FaSpinner className="text-primary-main animate-spin" />
                        </>
                    }
                </div>
                <span onClick={handleOnSubmit} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    (stickyNotePayload?.note === note) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                )}>
                    {"Submit"}
                </span>
            </div>
        </div>
    )
}

export default StickyNoteModal;