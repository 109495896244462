import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from 'pages/auth/tutors/data';
import TutorsSearch from 'pages/auth/tutors/components/TutorsSearch';
import DisplayTutors from 'pages/auth/tutors/components/DisplayTutors';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const TutorsPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTORS))
    }, [dispatcher, currentPageInfo])

    return (
        <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3'>
            <PageHeader pageHeading={pageHeading} />
            <TutorsSearch />
            <DisplayTutors />
        </div>
    );
};

export default TutorsPage;
