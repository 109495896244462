import GeneralSettings from 'pages/auth/otherSettings/components/generalSettings/GeneralSettings';
import StripeSettings from 'pages/auth/otherSettings/components/stripeSettings/StripeSettings';

import { userRoles } from 'redux/auth/auth.const';

export const pageHeading = {
    heading: "Platform Settings",
    subHeading: "Choose or update all the settings of your apps on mobile and web!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/settings.svg"
}

export const otherSettingsNavigationOptions = {
    GENERAL: {
        label: "General",
        value: "general",
        pagePath: "/general",
        routePath: "general",
        component: GeneralSettings,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    },
    STRIPE_SETTINGS: {
        label: "Stripe Settings",
        value: "stripe-settings",
        pagePath: "/stripe-settings",
        routePath: "stripe-settings",
        component: StripeSettings,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value]
    }
}

export const waitListTabs = {
    TUTORS: {
        label: "Tutors",
        value: "tutors"
    },
    LEARNERS: {
        label: "Learners",
        value: "learners"
    },
    COMPANIES: {
        label: "Companies",
        value: "companies"
    }
}