import { useState, memo, useMemo, useCallback } from 'react';

import { IoIosArrowForward } from 'react-icons/io';
import { MdOutlineContentCopy } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';

import { AnimatePresence, motion } from 'framer-motion';

import ToolTipView from 'components/toolTip/index';

import { useAppState } from 'hooks/useStore';
import { sessionLocationPhoneCallTypeEnum, sessionLocationTypeEnum } from "redux/session/session.const";

import { cn } from "utils/cn.utils";

const SessionLocationInfo = memo(({ sessionLocationInfo }) => {

    const [isMeetingLinkCopied, setIsMeetingLinkCopied] = useState(false)
    const [isShow, setIsShow] = useState(false)

    const locationDetail = useMemo(() => {
        const locationType = sessionLocationInfo?.location_type
        const Icon = sessionLocationTypeEnum[sessionLocationInfo?.location_type]?.icon
        const isMeetingLinkEnabled = [sessionLocationTypeEnum.google_meet.key, sessionLocationTypeEnum.edulyte_meet.key]?.includes(locationType)

        const customLocation = [sessionLocationTypeEnum.custom.key]?.includes(locationType) ? sessionLocationInfo?.custom : null
        const inPersonLocation = [sessionLocationTypeEnum.in_person_meeting.key]?.includes(locationType) ? sessionLocationInfo?.in_person_meeting : null
        const phoneCallLocation = [sessionLocationTypeEnum.phone_call.key]?.includes(locationType) ? sessionLocationInfo?.phone_call : null
        const askInviteeLocation = [sessionLocationTypeEnum.invitee.key]?.includes(locationType) ? sessionLocationInfo?.invitee : null
        const meetingUrl = [sessionLocationTypeEnum.google_meet.key]?.includes(locationType)
            ? sessionLocationInfo?.google_meet?.meeting?.meeting_url
            : [sessionLocationTypeEnum.edulyte_meet.key]?.includes(locationType)
                ? sessionLocationInfo?.edulyte_meet?.meeting?.meeting_url
                : ""

        return { locationType, Icon, isMeetingLinkEnabled, customLocation, inPersonLocation, phoneCallLocation, askInviteeLocation, meetingUrl }
    }, [sessionLocationInfo])

    const onHandleLocationDetail = useCallback(() => {
        if (locationDetail?.isMeetingLinkEnabled) {
            window.open(locationDetail?.meetingUrl, "_blank")
        } else {
            setIsShow(!isShow)
        }
    }, [locationDetail, isShow])

    const handleCopyMeetingUrl = useCallback(() => {
        if (isMeetingLinkCopied || !locationDetail?.isMeetingLinkEnabled) return;

        navigator.clipboard.writeText(locationDetail?.meetingUrl)
        setIsMeetingLinkCopied(true)
        setTimeout(() => {
            setIsMeetingLinkCopied(false)
        }, 1000)
    }, [locationDetail, isMeetingLinkCopied])

    const onHandlePhoneDetail = useCallback(() => {
        if (locationDetail?.phoneCallLocation?.phone_number) {
            window.open(`tel:+${locationDetail?.phoneCallLocation?.isd_code} ${locationDetail?.phoneCallLocation?.phone_number}`, "_self")
        }
    }, [locationDetail?.phoneCallLocation])

    return (
        <div className={"w-full flex flex-col gap-3 p-2 border border-divider-medium bg-background-lightBlue"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <div className={"flex items-center justify-start gap-2.5"}>
                    <locationDetail.Icon className={"text-xl text-text-800"} />
                    <div className={"flex items-center justify-start gap-1.5 cursor-pointer group"} onClick={onHandleLocationDetail}>
                        <span className={cn(
                            "font-bodyPri font-normal text-text-900 text-sm tracking-wide",
                            locationDetail?.isMeetingLinkEnabled && "group-hover:text-primary-dark hover:underline"
                        )}>
                            {sessionLocationTypeEnum[locationDetail?.locationType]?.label}
                        </span>
                        {!locationDetail?.isMeetingLinkEnabled &&
                            <IoIosArrowForward className={cn(
                                "font-bold text-lg text-text-800 transition ease-in-out delay-50 duration-100 cursor-pointer",
                                isShow ? "rotate-0" : "rotate-90")}
                            />
                        }
                        {locationDetail?.isMeetingLinkEnabled &&
                            <FiExternalLink className={"font-bold text-lg text-text-800 group-hover:text-primary-dark cursor-pointer"} />
                        }
                    </div>
                </div>
                {!!locationDetail?.isMeetingLinkEnabled &&
                    <ToolTipView content={isMeetingLinkCopied ? "Copied" : "Copy Link"}>
                        <button className={"font-buttons font-normal text-sm"} onClick={handleCopyMeetingUrl}>
                            {!isMeetingLinkCopied && <MdOutlineContentCopy className={"text-xl text-text-800"} />}
                            {isMeetingLinkCopied && <AiOutlineCheckCircle className={"text-xl text-text-800"} />}
                        </button>
                    </ToolTipView>
                }
            </div>
            {!locationDetail?.isMeetingLinkEnabled &&
                <AnimatePresence initial={isShow}>
                    {isShow &&
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className={"w-full pl-2"}
                        >
                            {!!locationDetail?.customLocation &&
                                <span className={"font-bodyPri font-normal text-sm text-text-900"}>
                                    {locationDetail?.customLocation?.custom_text}
                                </span>
                            }
                            {!!locationDetail?.inPersonLocation &&
                                <div className={"space-y-0.5 font-bodyPri font-normal text-sm text-text-900"}>
                                    <p>{`${locationDetail?.inPersonLocation?.street_line_1}`}</p>
                                    <p>{locationDetail?.inPersonLocation?.street_line_2}</p>
                                    <p>{locationDetail?.inPersonLocation?.city}</p>
                                    <p>
                                        <span>{locationDetail?.inPersonLocation?.state}</span> &#x2022;
                                        <span>{` ${locationDetail?.inPersonLocation?.country} ${locationDetail?.inPersonLocation?.zip && " - " + locationDetail?.inPersonLocation?.zip}`}</span>
                                    </p>
                                </div>
                            }
                            {!!locationDetail?.phoneCallLocation &&
                                <div className={"w-full flex items-center justify-start gap-1"}>
                                    <span className={"font-bodyPri font-normal text-sm text-text-900 hover:text-primary-dark hover:underline cursor-pointer"} onClick={onHandlePhoneDetail}>
                                        {`+${locationDetail?.phoneCallLocation?.isd_code} - ${locationDetail?.phoneCallLocation?.phone_number}`}
                                    </span>
                                    <span className={"font-bodyPri font-normal text-sm text-text-900"}>
                                        {`(${sessionLocationPhoneCallTypeEnum[locationDetail?.phoneCallLocation?.location_phone_call_type]?.title})`}
                                    </span>
                                </div>
                            }
                            {!!locationDetail?.askInviteeLocation &&
                                <span className={"font-bodyPri font-normal text-sm text-text-900"}>
                                    {locationDetail?.askInviteeLocation?.custom_text}
                                </span>
                            }
                        </motion.div>
                    }
                </AnimatePresence>
            }
        </div>
    )
})


const SessionDetailLocation = () => {
    const { sessionDetail } = useAppState(s => s.session)

    const [isShow, setIsShow] = useState(false)

    return sessionDetail?.data?.result?.session_locations ? (
        <div className={"space-y-1 transition-transform duration-300 delay-150 ease-in-out"}>
            <div
                onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Locations"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col gap-3 transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                {sessionDetail?.data?.result?.session_locations?.map((sessionLocationInfo, index) => (
                    <SessionLocationInfo key={index} sessionLocationInfo={sessionLocationInfo} />
                ))}
            </div>
        </div>
    ) : null
}

export default SessionDetailLocation;