import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { referralHeaderConst, subRowHeader, sortReferralLowToHigh, sortReferralHighToLow } from '../data';

import { getReferralList } from "redux/referral/referral.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetReferralList } from "redux/referral/referral.slice";
import { referralStatus } from 'redux/referral/referral.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { currencyRateConversion } from 'utils/generators.utils';

const RefereeContainer = ({ referral }) => {
    return referral ? (
        <div className={"flex items-center justify-start gap-0.5"}>
            <Link to={`${pagesInfo?.VIEW_USER?.pagePath}/${referral?.userId}`}
                className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
            >
                {referral?.userId + ". "}
            </Link>
            <div className={""}>
                {referral?.firstName + " " + referral?.lastName?.charAt(0)}
            </div>
        </div>
    ) : "-"
}

const DisplayReferralTable = () => {
    const { locals } = useAppState((state) => state.local)
    const { referralList, filterProps } = useAppState((state) => state.referral)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        dispatcher(getReferralList({ page: filterProps?.page || 1, records: filterProps?.records }))

        return () => {
            dispatcher(resetReferralList())
        }
    }, [filterProps?.records])

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortCourseList = (course1, course2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortReferralLowToHigh(activeSortHeader, course1, course2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortReferralHighToLow(activeSortHeader, course1, course2)
        }
    }

    const rows = referralList?.data?.results?.slice(0)?.sort(sortCourseList)?.map((referral) => [
        referral?.id,
        <RefereeContainer referral={referral?.refereeUser} />,
        <RefereeContainer referral={referral?.beneficiaryUser} />,
        `${currencyRateConversion(locals?.currencyCode, referral?.amount)}` || "-",
        `${referralStatus[referral?.status?.toUpperCase()]?.label}` || "-",
        dayjs(referral?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        referral?.publishedAt ? dayjs(referral?.publishedAt).tz(timeZone).format('DD MMM, YYYY') : "-",
        "-"
    ]);

    return (
        <>
            <Table
                headers={Object.values(referralHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={referralList?.isLoading}
                message={(referralList?.message || (referralList?.data?.records === 0)) && (referralList?.message || "No referrals found")}
            />
            {!referralList?.isLoading && (referralList?.data && (referralList?.data?.totalPages > 1)) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={referralList?.data?.page}
                        totalPages={referralList?.data?.totalPages}
                        onChangePage={(page) => dispatcher(getReferralList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </>
    )
}

export default DisplayReferralTable;