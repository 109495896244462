import { userRoles } from 'redux/auth/auth.const';
import { localsConst } from 'redux/local/local.const';
import { timeFormatConst } from "redux/master/master.const";
import { timeZone } from 'utils/dateTime.utils';


const setDefaultLocalStorage = (key) => {
    switch (key) {
        case localsConst.USER_ROLE.value:
            localStorage.setItem(key, userRoles.ADMIN.value)
            break;
        case localsConst.DISPLAY_LANGUAGE.value:
            localStorage.setItem(key, "English")
            break;
        case localsConst.CURRENCY_CODE.value:
            localStorage.setItem(key, "USD")
            break;
        case localsConst.TIME_ZONE.value:
            localStorage.setItem(key, timeZone)
            break;
        case localsConst.TIME_FORMAT.value:
            localStorage.setItem(key, timeFormatConst.HRS_12.value)
            break;
        default:
            break;
    }
}

export const setAndGetLocalStorage = (key, value = null) => {
    let local = localStorage.getItem(key)
    if (!local) {
        setDefaultLocalStorage(key)
    }
    if (value) {
        localStorage.setItem(key, value)
    }
    local = localStorage.getItem(key)
    return local
}

export const setMyLocals = (locals) => {
    Object.keys(locals).forEach(localKey => {
        setAndGetLocalStorage(localKey, locals[localKey])
    })
}