import { useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getSessionList } from "redux/session/session.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { dateRangesLabels } from "redux/local/local.const";
import { resetFilterProps, setFilterProps } from "redux/session/session.slice";
import { sessionStatusEnum, sessionTypeEnum } from "redux/session/session.const";

import { dayjs } from "utils/dateTime.utils";

export const SearchSessionId = () => {
  const { filterProps } = useAppState((state) => state.session)

  const dispatcher = useAppDispatcher()

  return (
    <input
      type={"number"}
      value={filterProps?.sessionId}
      placeholder={"Search Session ID"}
      className={cn(
        "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
        "font-bodyPri font-normal text-text-800 text-base",
        "placeholder:text-text-500 placeholder:text-sm"
      )}
      onChange={(e) => dispatcher(setFilterProps({
        filterProps: { ...filterProps, sessionId: e.target.value }
      }))}
    />
  )
}

export const SearchSessionName = () => {
  const { filterProps } = useAppState((state) => state.session)

  const dispatcher = useAppDispatcher()

  return (
    <input
      type={"text"}
      value={filterProps.sessionName}
      placeholder={"Search Session Name"}
      className={cn(
        "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
        "font-bodyPri font-normal text-text-800 text-base",
        "placeholder:text-text-500 placeholder:text-sm"
      )}
      onChange={(e) => dispatcher(setFilterProps({
        filterProps: { ...filterProps, sessionName: e.target.value }
      }))}
    />
  )
}

export const SearchSessionStartDateTime = () => {
  const { filterProps } = useAppState((state) => state.session)

  const dispatcher = useAppDispatcher()

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
    endDate: dayjs()?.format("DD/MMM/YYYY")
  })

  const handleApply = (picker) => {
    const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
    const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
    setSelectedDateRange({
      startDate: startDate,
      endDate: endDate
    })
    dispatcher(setFilterProps({
      filterProps: { ...filterProps, minStartDateTime: startDate, maxStartDateTime: endDate },
    }))
  }

  return (
    <DateRangePicker
      initialSettings={{
        autoUpdateInput: false,
        locale: {
          cancelLabel: 'Cancel',
        },
        ranges: dateRangesLabels
      }}
      onApply={(event, picker) => handleApply(picker)}
      onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
    >
      <input
        type={"text"}
        defaultValue={""}
        className={cn(
          "px-1 py-0.5 flex items-center justify-center gap-1 bg-text-300",
          "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
          "font-bodyPri font-normal text-text-700 text-base"
        )}
        value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
        disabled={true}
      />
    </DateRangePicker>
  )
}

export const SearchSessionEndDateTime = () => {
  const { filterProps } = useAppState((state) => state.session)

  const dispatcher = useAppDispatcher()

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
    endDate: dayjs()?.format("DD/MMM/YYYY")
  })

  const handleApply = (picker) => {
    const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
    const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
    setSelectedDateRange({
      startDate: startDate,
      endDate: endDate
    })
    dispatcher(setFilterProps({
      filterProps: { ...filterProps, minEndDateTime: startDate, maxEndDateTime: endDate },
    }))
  }

  return (
    <DateRangePicker
      initialSettings={{
        autoUpdateInput: false,
        locale: {
          cancelLabel: 'Cancel',
        },
        ranges: dateRangesLabels
      }}
      onApply={(event, picker) => handleApply(picker)}
      onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
    >
      <input
        type={"text"}
        defaultValue={""}
        className={cn(
          "px-1 py-0.5 flex items-center justify-center gap-1 bg-text-300",
          "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
          "font-bodyPri font-normal text-text-700 text-base"
        )}
        value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
        disabled={true}
      />
    </DateRangePicker>
  )
}

export const FilterSessionType = () => {
  const { filterProps } = useAppState((state) => state.session)
  const dispatcher = useAppDispatcher()

  return (
    <OptionSelector
      className={"w-full"}
      options={Object.values(sessionTypeEnum)}
      value={filterProps.sessionType}
      onChange={(option) => dispatcher(setFilterProps({
        filterProps: { ...filterProps, sessionType: option.value }
      }))}
    />
  )
}

export const FilterStatus = () => {
  const { filterProps } = useAppState((state) => state.session)
  const dispatcher = useAppDispatcher()

  return (
    <OptionSelector
      className={"w-full"}
      options={Object.values(sessionStatusEnum)}
      value={filterProps.sessionStatus}
      onChange={(option) => dispatcher(setFilterProps({
        filterProps: { ...filterProps, sessionStatus: option.value }
      }))}
    />
  )
}

export const SearchDate = () => {
  const { filterProps } = useAppState((state) => state.session)

  const dispatcher = useAppDispatcher()

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
    endDate: dayjs()?.format("DD/MMM/YYYY")
  })

  const handleApply = (picker) => {
    const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
    const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
    setSelectedDateRange({
      startDate: startDate,
      endDate: endDate
    })
    dispatcher(setFilterProps({
      filterProps: { ...filterProps, minCreatedAt: startDate, maxCreatedAt: endDate },
    }))
  }

  return (
    <DateRangePicker
      initialSettings={{
        autoUpdateInput: false,
        locale: {
          cancelLabel: 'Cancel',
        },
        ranges: dateRangesLabels
      }}
      onApply={(event, picker) => handleApply(picker)}
      onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
    >
      <input
        type={"text"}
        defaultValue={""}
        className={cn(
          "px-1 py-0.5 flex items-center justify-center gap-1 bg-text-300",
          "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
          "font-bodyPri font-normal text-text-700 text-base"
        )}
        value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
        disabled={true}
      />
    </DateRangePicker>
  )
}

export const ButtonAction = () => {
  const { filterProps } = useAppState((state) => state.session)

  const dispatcher = useAppDispatcher()

  const handleApply = () => {
    const requestDataPayload = {
      page: filterProps?.page || 1,
      records: filterProps?.records || 25
    }
    if (filterProps?.sessionId) {
      requestDataPayload["id"] = filterProps?.sessionId
    }
    if (filterProps?.sessionName) {
      requestDataPayload["name"] = filterProps?.sessionName
    }
    if (filterProps?.sessionType) {
      requestDataPayload["type"] = filterProps?.sessionType
    }
    if (filterProps?.sessionStatus) {
      requestDataPayload["status"] = filterProps?.sessionStatus
    }
    dispatcher(getSessionList(requestDataPayload))
  }

  const handleClear = () => {
    dispatcher(getSessionList({ page: 1, records: 25 }))
    dispatcher(resetFilterProps())
  }

  return (
    <div className="w-full flex items-center justify-center gap-1">
      <span
        className={cn(
          "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
          "font-buttons font-normal text-sm",
          "bg-primary-main text-text-50 hover:bg-primary-dark",
        )}
        onClick={handleApply}
      >
        {"Apply"}
      </span>
      <span
        className={cn(
          "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
          "font-buttons font-normal text-sm",
          "bg-background-lightDark text-text-50 hover:bg-background-dark",
        )}
        onClick={handleClear}
      >
        {"Clear"}
      </span>
    </div>
  )
}

export const SearchUpdatedDate = () => {
  const { filterProps } = useAppState((state) => state.session)

  const dispatcher = useAppDispatcher()

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
    endDate: dayjs()?.format("DD/MMM/YYYY")
  })

  const handleApply = (picker) => {
    const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
    const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
    setSelectedDateRange({
      startDate: startDate,
      endDate: endDate
    })
    dispatcher(setFilterProps({
      filterProps: { ...filterProps, minUpdatedAt: startDate, maxUpdatedAt: endDate },
    }))
  }

  return (
    <DateRangePicker
      initialSettings={{
        autoUpdateInput: false,
        locale: {
          cancelLabel: 'Cancel',
        },
        ranges: dateRangesLabels
      }}
      onApply={(event, picker) => handleApply(picker)}
      onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
    >
      <input
        type={"text"}
        defaultValue={""}
        className={cn(
          "px-1 py-0.5 flex items-center justify-center gap-1 bg-text-300",
          "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
          "font-bodyPri font-normal text-text-700 text-base"
        )}
        value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
      />
    </DateRangePicker>
  )
}