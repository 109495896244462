import { AttributeTemplatePart } from './attribute-template-part.js';
export function createProcessor(processPart) {
    return {
        processCallback(_, parts, params) {
            var _a;
            if (typeof params !== 'object' || !params)
                return;
            for (const part of parts) {
                if (part.expression in params) {
                    const value = (_a = params[part.expression]) !== null && _a !== void 0 ? _a : '';
                    processPart(part, value);
                }
            }
        }
    };
}
export function processPropertyIdentity(part, value) {
    part.value = String(value);
}
export function processBooleanAttribute(part, value) {
    if (typeof value === 'boolean' &&
        part instanceof AttributeTemplatePart &&
        typeof part.element[part.attributeName] === 'boolean') {
        part.booleanValue = value;
        return true;
    }
    return false;
}
export const propertyIdentity = createProcessor(processPropertyIdentity);
export const propertyIdentityOrBooleanAttribute = createProcessor((part, value) => {
    processBooleanAttribute(part, value) || processPropertyIdentity(part, value);
});
