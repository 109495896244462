import { useState, useEffect } from "react";
import { cn } from "utils/cn.utils";

import { AiOutlineClose } from "react-icons/ai";

import { questionType } from "pages/auth/viewTutorOnboard/data";

const QuestionAnswerContainer = ({ questionDetail }) => {
    const [localResponse, setLocalResponse] = useState([])

    useEffect(() => {
        setLocalResponse(questionDetail?.response?.length > 0 ? questionDetail?.response?.split("|") : [])
    }, [questionDetail?.response])

    const onDeleteMultipleText = (selectedSkill) => {
    }

    return (
        <div className={"w-full flex flex-col justify-items-start gap-3"}>
            <div className="flex justify-start gap-1">
                <span className="text-red-400">
                    {"*"}
                </span>
                <span className="font-subHeading font-medium text-sm md:text-lg text-text-900">
                    {questionDetail?.question}
                </span>
            </div>
            {questionDetail?.questionType === questionType?.MULTIPLE_TEXT?.value &&
                <div className={cn(
                    "w-full py-2 px-4 flex flex-wrap gap-3 border-2 border-divider-medium rounded-md",
                    "focus:outline-none focus:border-2 focus:border-text-400"
                )}>
                    {localResponse.map((skill, index) => (
                        <div className={cn(
                            "w-max px-4 py-1 flex items-center gap-1 rounded-xl bg-blue-100",
                            "font-bodyPri font-medium text-sm md:text-base text-blue-600 tracking-wide"
                        )}
                            key={index}
                        >
                            <span>
                                {skill}
                            </span>
                            <AiOutlineClose
                                onClick={() => onDeleteMultipleText(skill)}
                                className={"text-lg cursor-pointer"}
                            />
                        </div>
                    ))}
                </div>
            }
            {questionDetail?.questionType === questionType?.SINGLE_CHOICE?.value &&
                questionDetail?.options?.map((option, index) => (
                    <div className={"flex flex-col items-start justify-start gap-3"} key={index}>
                        <div className={"flex items-center gap-3"}>
                            <input
                                type={"radio"}
                                className={"border-2 border-divider-medium hover:border-divider-dark cursor-pointer"}
                                name={questionDetail?.questionResponseId?.toString()}
                                defaultValue={option?.option}
                                checked={localResponse?.includes(option?.option) && true}
                                disabled={true}
                            />
                            <label className={"font-bodyPri font-normal text-sm md:text-base text-text-900"}>
                                {option?.option}
                            </label>
                        </div>
                    </div>
                ))}
            {questionDetail?.questionType === questionType.MULTIPLE_CHOICE.value &&
                questionDetail?.options?.map((option, index) => (
                    <div className={"flex flex-col items-start justify-start gap-3"} key={index}>
                        <div className={"flex items-center gap-3"}>
                            <input
                                type={"checkbox"}
                                className={"border-2 border-divider-medium hover:border-divider-dark cursor-pointer"}
                                name={questionDetail?.questionResponseId?.toString()}
                                defaultValue={option?.option}
                                checked={localResponse?.includes(option?.option) && true}
                                disabled={true}
                            />
                            <label className={"font-bodyPri font-normal text-sm md:text-base text-text-900"}>
                                {option?.option}
                            </label>
                        </div>
                    </div>
                ))}
        </div>
    )
}

export default QuestionAnswerContainer;