import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';
import { PaperAirplaneIcon } from "@heroicons/react/outline";

import ComponentLoader from 'components/loader/ComponentLoader';

import { getOnboardingCommentList, createOnboardingCommentDetail } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAddOnboardingCommentDetail, resetOnboardingCommentList } from "redux/onboarding/onboarding.slice";

import { dayjs } from "utils/dateTime.utils"


const ViewOnboardingSidePanel = () => {
    const { user } = useAppState((state) => state.user)
    const { onboardingCommentList, addOnboardingCommentDetail } = useAppState((state) => state.onboarding)

    const [comment, setComment] = useState("")

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingCommentList({ onboardingId: onboardingId }))
        }
        return () => {
            dispatcher(resetOnboardingCommentList())
            dispatcher(resetAddOnboardingCommentDetail())
        }
    }, [onboardingId])

    const onHandleSendComment = () => {
        if (comment?.length === 0 || addOnboardingCommentDetail?.isLoading) return;
        dispatcher(resetAddOnboardingCommentDetail())

        const body = {
            userId: user?.user?.userId,
            onboardingId: onboardingId,
            comment: comment,
        }
        dispatcher(createOnboardingCommentDetail(body))
        setComment("")
    }

    const onHandleKeyDown = (event) => {
        if ((event.keyCode === 9 || event.keyCode === 13) && comment?.trim()) {
            onHandleSendComment()
        }
    }

    const sortDateTime = (dateTime1, dateTime2) => {
        const comment1DateTime = dayjs(dateTime1?.createdAt).utc()
        const comment2DateTime = dayjs(dateTime2?.createdAt).utc()
        return comment1DateTime.isSameOrAfter(comment2DateTime) ? 1 : -1
    }

    if (onboardingCommentList?.message) {
        return (
            <div className={cn(
                "h-screen max-w-2xl md:max-w-xl lg:max-w-lg flex items-center justify-center",
                "font-bodyPri font-normal text-red-500 text-base"
            )}>
                {onboardingCommentList?.message}
            </div>
        )
    }

    return (
        <div className="p-5 h-screen sm:px-5 max-w-2xl md:max-w-xl lg:max-w-lg">
            <ComponentLoader isLoading={onboardingCommentList?.isLoading || addOnboardingCommentDetail?.isLoading} />
            {(!onboardingCommentList?.isLoading && !addOnboardingCommentDetail?.isLoading) &&
                <div className={"w-full h-full flex flex-col items-start justify-start space-y-5"}>
                    <div className={"w-full flex flex-col items-center justify-center space-y-3"}>
                        <div className={"w-full flex items-center justify-center"}>
                            <span className={"font-bodyPri font-semibold text-text-900 text-base tracking-wide"}>
                                {"Comments"}
                            </span>
                        </div>
                        <div className={"w-full text-center font-subHeading font-normal text-text-700 text-base tracking-wide leading-6"}>
                            {"Use this section to communicate with our support team, these comments are not visible to public."}
                        </div>
                    </div>

                    <div className={cn(
                        "flex flex-col w-full h-[80vh] overflow-hidden overflow-y-scroll space-y-3 transition ease-in-out delay-150 duration-300",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
                        (onboardingCommentList?.data?.length > 7) && "hover:pr-5"
                    )}>
                        {(!onboardingCommentList?.data || onboardingCommentList?.data?.length === 0) &&
                            <div className={"w-fit h-[80vh] flex items-center justify-center mx-auto"}>
                                <span className={"font-bodyPri font-normal text-base text-text-700 text-center"}>
                                    {"No comments added yet!"}
                                </span>
                            </div>
                        }
                        {onboardingCommentList?.data?.slice()?.sort(sortDateTime)?.map((comment, index) => (
                            <div key={index} className={"grid grid-cols-12 w-full gap-3"}>
                                <div className={"col-start-1 col-span-2 w-12 h-12 overflow-hidden rounded-full"}>
                                    <img
                                        src={comment?.user?.userMeta?.profilePicUrl}
                                        className={"w-full h-full object-cover"}
                                    />
                                </div>
                                <div className={"col-start-3 col-span-full px-3 py-2 flex flex-col items-start justify-start gap-1.5 bg-gray-100 rounded-lg"}>
                                    <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                        {comment?.user?.userMeta?.firstName + " " + comment?.user?.userMeta?.lastName?.charAt(0) + "."}
                                    </span>
                                    <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                        {comment?.comment}
                                    </span>
                                    <span className={"w-full flex justify-end font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                                        {dayjs(comment.createdAt).local().format("MMM D, YY HH:MM A").toString()}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>

                    {addOnboardingCommentDetail?.message &&
                        <div className={"flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                            {addOnboardingCommentDetail?.message}
                        </div>
                    }

                    <div className={"w-full flex items-center justify-start gap-3"}>
                        <textarea
                            type={"text"}
                            placeholder={"Type your comment here"}
                            value={comment}
                            className={cn(
                                "outline-none border-2 border-gray-300 rounded-md p-2 w-full resize-none overflow-auto",
                                "scrollbar scrollbar-track-background-light scrollbar-thumb-rounded-full",
                                "hover:scrollbar-thumb-divider-lightDark",
                                "focus:border-divider-medium focus:shadow-all focus:bg-background-light",
                                "hover:border-divider-medium hover:shadow-all hover:bg-background-light",
                                "font-bodyPri font-normal text-text-800 text-base placeholder:font-bodyPri"
                            )}
                            onChange={(e) => setComment(e.target.value)}
                            onKeyDown={(event) => onHandleKeyDown(event)}
                            required={true}
                            autoFocus={true}
                        />
                        <div className={"flex items-center justify-start gap-2"} onClick={onHandleSendComment}>
                            {addOnboardingCommentDetail?.isLoading &&
                                <FaSpinner className={"animate-spin text-primary-dark text-lg"} />
                            }
                            <PaperAirplaneIcon className={cn(
                                "rotate-90 h-8 w-8 text-text-500",
                                "!text-primary-dark hover:bg-primary-light hover:ring-4 hover:ring-primary-light hover:rounded-full cursor-pointer"
                            )} />
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ViewOnboardingSidePanel;