import { addUserDetailPayload, userFilterProps } from "redux/user/user.const";

const userGeneralDetailPayload = {
    email: "",
    password: "",
    mobileNo: "",
    isdCode: "",
    isOnlyVerifyEmail: false,
    isOnlyVerifyMobileNo: false,
}

export const USER_INITIAL_STATE = {
    user: {
        isLoading: false,
        user: null,
        errorMsg: null
    },

    userList: {
        isLoading: false,
        userList: null,
        filteredUserList: null,
        errorMsg: null
    },

    userDetail: {
        isLoading: false,
        userDetail: null,
        errorMsg: null
    },
    addUserDetail: {
        isLoading: null,
        errorMsg: null
    },

    userCultureDetail: {
        isLoading: false,
        userCultureDetail: null,
        errorMsg: null
    },
    addUserCultureDetail: {
        isLoading: false,
        errorMsg: null
    },

    userEducationList: {
        isLoading: false,
        userEducationList: null,
        errorMsg: null
    },
    addUserEducationDetail: {
        isLoading: false,
        errorMsg: null
    },

    userOtherPref: {
        isLoading: false,
        userOtherPref: null,
        errorMsg: null
    },
    addUserOtherPref: {
        isLoading: false,
        errorMsg: null
    },

    userSocialMediaLinks: {
        isLoading: false,
        userSocialMediaLinks: null,
        errorMsg: null
    },
    addUserSocialMediaLinks: {
        isLoading: false,
        errorMsg: null
    },

    userAddressList: {
        isLoading: false,
        userAddressList: null,
        errorMsg: null
    },
    addUserAddressDetail: {
        isLoading: false,
        errorMsg: null
    },

    // create new user detail
    addNewUserDetail: {
        isLoading: false,
        data: null,
        payload: addUserDetailPayload,
        message: null
    },

    userGeneralDetailPayload: {
        userGeneralDetailPayload: userGeneralDetailPayload
    },
    filterProps: userFilterProps
}