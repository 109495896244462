import { useMemo, useState } from "react";

import { MdAdd } from "react-icons/md";
import { IoIosArrowForward } from 'react-icons/io';
import { FiEdit } from "react-icons/fi";

import DashedBorderButton from "components/buttons/dashedBorderButton";

import { sortSessionUserList } from "pages/auth/sessions/data";

import { getUserDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearUserDetail } from "redux/user/user.slice";
import { resetModifySessionUserDetail, resetModifySessionUserDetailPayload, setModifySessionUserDetailPayload } from "redux/session/session.slice";
import { modalConst } from "redux/local/local.const";
import { sessionStatusEnum, sessionUserTypeEnum, modifySessionUserDetailPayload } from "redux/session/session.const";

import { cn } from "utils/cn.utils";

const SessionDetailParticipants = () => {
    const { modal } = useAppState((state) => state.local)
    const { sessionDetail } = useAppState(s => s.session)

    const dispatcher = useAppDispatcher()

    const [isShow, setIsShow] = useState(false)

    const ownerUser = useMemo(() => {
        if (!!sessionDetail?.data?.result?.owner_user && !!sessionDetail?.data?.result?.session_users?.length) {
            return sessionDetail?.data?.result?.session_users?.find((sessionUser) => sessionUser?.user?.id === sessionDetail?.data?.result?.owner_user?.id)
        }
    }, [sessionDetail?.data?.result?.owner_user, sessionDetail?.data?.result?.session_users])

    const sessionUserList = useMemo(() => {
        let filteredUserList = sessionDetail?.data?.result?.session_users || []

        if (sessionDetail?.data?.result?.session_users && ownerUser) {
            filteredUserList = filteredUserList.filter((sessionUser) => sessionUser?.user?.id !== ownerUser?.id)?.slice(0)?.sort(sortSessionUserList)
            filteredUserList = [ownerUser, ...filteredUserList]?.reduce((acc, user) => {
                const userExists = acc?.some(u => u?.user?.id === user?.user?.id)
                if (!userExists) {
                    acc?.push(user)
                }
                return acc;
            }, [])
        }

        return filteredUserList
    }, [sessionDetail?.data?.result?.session_users, ownerUser])

    const onHandleAddParticipant = () => {
        dispatcher(setClearUserDetail())
        dispatcher(resetModifySessionUserDetail())
        dispatcher(resetModifySessionUserDetailPayload())

        dispatcher(setModifySessionUserDetailPayload({
            ...modifySessionUserDetailPayload,
            sessionId: sessionDetail?.data?.result?.id,
            status: sessionDetail?.data?.result?.status,
            userType: sessionUserTypeEnum.PARTICIPANT.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_SESSION_USER_MODAL.stateKey]: true
        }))
    }

    const onHandleEditSessionUser = (participantDetail) => {
        dispatcher(setClearUserDetail())
        dispatcher(resetModifySessionUserDetail())
        dispatcher(resetModifySessionUserDetailPayload())
        dispatcher(getUserDetailByUserId(participantDetail?.user?.id))

        dispatcher(setModifySessionUserDetailPayload({
            ...modifySessionUserDetailPayload,
            sessionId: participantDetail?.sessionId,
            sessionUserId: participantDetail?.id,
            userId: participantDetail?.user?.id,
            userType: participantDetail?.userType,
            status: sessionDetail?.data?.result?.status,
            comment: participantDetail?.comment || "",
            isEditSessionUserEnabled: true
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_SESSION_USER_MODAL.stateKey]: true
        }))
    }

    return !!sessionDetail?.data?.result?.session_users?.length && (
        <div className={"space-y-1 transition-transform duration-300 delay-150 ease-in-out"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Participants"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                {((!sessionUserList || (sessionUserList?.length === 0))
                    && ![sessionStatusEnum?.COMPLETED?.value, sessionStatusEnum?.CANCELLED?.value]?.includes(sessionDetail?.data?.result?.status))
                    && <div className={"w-full flex justify-center"}>
                        {![sessionStatusEnum?.COMPLETED?.value, sessionStatusEnum?.CANCELLED?.value]?.includes(sessionDetail?.data?.result?.status) &&
                            <DashedBorderButton
                                isShowBtn={![sessionStatusEnum?.COMPLETED?.value, sessionStatusEnum?.CANCELLED?.value]?.includes(sessionDetail?.data?.result?.status)}
                                btnTitle={"Add Participants"}
                                onHandleAdd={onHandleAddParticipant}
                            />
                        }
                    </div>
                }
                {(!!sessionUserList?.length &&
                    ![sessionStatusEnum?.COMPLETED?.value, sessionStatusEnum?.CANCELLED?.value]?.includes(sessionDetail?.data?.result?.status))
                    && <div className={"flex justify-end"}>
                        <div
                            className={"flex items-center justify-center gap-1 py-1 px-5 border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"}
                            onClick={onHandleAddParticipant}
                        >
                            <MdAdd className={"text-lg group-hover:text-text-50"} />
                            <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                                {"Add participant"}
                            </span>
                        </div>
                    </div>
                }

                {!!sessionUserList?.length
                    && sessionUserList?.map((participant, index) => (
                        <div className={"flex items-center justify-between gap-3 py-2 px-5 rounded-md hover:bg-gray-50 cursor-pointer"} key={index}>
                            <div className={"flex items-center justify-start gap-3"}>
                                <img src={participant?.user?.profilePicUrl} alt="" className={"w-10 h-10 rounded-full"} />
                                <div className={"flex flex-col items-start justify-evenly h-full"}>
                                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                        {participant?.user?.firstName + " " + participant?.user?.lastName.charAt(0)}
                                    </span>
                                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                        {sessionUserTypeEnum[participant?.userType?.toUpperCase()]?.label}
                                    </span>
                                </div>
                            </div>
                            <div className={"flex items-end justify-start h-full"}>
                                {(sessionDetail?.data?.result?.owner_user?.id === participant?.user?.id) &&
                                    <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                                        {"Owner"}
                                    </span>
                                }
                            </div>
                            {![sessionStatusEnum?.COMPLETED?.value, sessionStatusEnum?.CANCELLED?.value]?.includes(sessionDetail?.data?.result?.status) &&
                                <FiEdit className={"text-lg text-text-700"} onClick={() => onHandleEditSessionUser(participant)} />
                            }
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default SessionDetailParticipants;