import { cn } from "utils/cn.utils";


export const StandardInputField = ({
  onChange,
  placeholder,
  value,
  className,
  disabled,
  inputProp,
  key,
  testId,
}) => {
  return (
    <input
      type="text"
      className={cn(
        "block w-full p-3 text-base placeholder-gray-500 border-2 border-gray-300 rounded-md",
        "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1",
        className
      )}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      key={key}
      data-testid={testId}
      onChange={onChange}
      {...inputProp}
    />
  );
};

export const InputWithLabel = ({
  label,
  className = "",
  placeholder = "Enter",
  disabled = false,
  key,
  error,
  info,
  inputProp,
  onChange,
  value,
  testId = "",
}) => (
  <div className={`flex flex-col ${className}`}>
    <div
      className={cn(
        "relative px-3 py-2 border border-gray-300 rounded-md",
        "focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600"
      )}
    >
      <label
        htmlFor="name"
        className="absolute inline-block px-1 -mt-px text-xs font-medium text-gray-900 bg-white -top-2 left-2"
      >
        {label}
      </label>
      <input
        type="text"
        name="name"
        id="name"
        className={cn(
          "block w-full p-0 text-gray-900 placeholder-gray-500 ",
          "border-0 focus:ring-0 sm:text-sm focus:outline-none"
        )}
        key={key}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        {...inputProp}
        data-testid={testId}
      />
    </div>

    {error && <p className="text-xs tracking-wide text-red-700">{error}</p>}
    {info && (
      <p className="mt-1 text-xs tracking-wide text-slate-500">{info}</p>
    )}
  </div>
);

export const InputFieldWithPrefix =
  ({ label, prefix, onChange, placeholder, value, disabled, key }) => {
    return (
      <div>
        {label && (
          <label
            htmlFor="price"
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        <div className="relative mt-1 border rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span className="text-gray-500 sm:text-sm pr-24">{prefix + " "}</span>{" "}
            {/* prefix ₹ */}
          </div>
          <input
            key={key}
            type="text"
            className={cn(
              "px-3 py-2",
              "block w-full pr-12 border-gray-300 rounded-md focus:ring-blue-300 focus:border-blue-400 pl-10 sm:text-sm"
            )}
            placeholder={placeholder}
            onChange={onChange}
            value={"  " + value}
            disabled={disabled}
          />
        </div>
      </div>
    );
  };

export const DatePicker = ({ date, onDateChange }) => {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          className="w-5 h-5 text-gray-500 dark:text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
      <input
        type="date"
        className={cn(
          "w-full pl-10 p-2.5 sm:text-sm rounded-lg border",
          "text-text-700 bg-white border-primary-main  focus:ring-blue-500 focus:border-blue-500",
          "dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        )}
        placeholder={"Select date"}
        autoFocus={true}
        value={date}
        onChange={(d) => {
          onDateChange(d.target.value);
        }}
      />
    </div>
  );
};
