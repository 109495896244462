import { cn } from "utils/cn.utils";
import ButtonLoader from 'components/loader/ButtonLoader';


const SaveAndCancelBtn = ({ onCancel, onSave, isLoading = false }) => {
    return (
        <div className={"flex items-center justify-center gap-3"}>
            <div onClick={onCancel} className={cn(
                "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                "font-buttons font-normal text-base text-text-800 bg-background-darkLight",
                "hover:bg-background-medium hover:text-text-900"
            )}>
                <span>
                    {"Cancel"}
                </span>
            </div>
            <div onClick={onSave} className={cn(
                "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                "font-buttons font-normal text-base text-text-50 bg-primary-main",
                "hover:bg-primary-dark"
            )}>
                {isLoading &&
                    <ButtonLoader isLoading={isLoading} />
                }
                {!isLoading &&
                    <span>
                        {"Save"}
                    </span>
                }
            </div>
        </div>
    )
}

export default SaveAndCancelBtn;