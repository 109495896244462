import { createSlice } from "@reduxjs/toolkit";
import { TEMPLATE_INITIAL_STATE } from "redux/template/template.initialState";
import { tempCourseType, filterName } from "redux/template/template.const";

export const tempCourse = createSlice({
    name: "template",
    initialState: TEMPLATE_INITIAL_STATE,
    reducers: {
        // reducers for template course list
        setTempCourseListLoading: (state, { payload }) => {
            state.tempCourseList.isLoading = payload
        },
        setTempCourseList: (state, { payload }) => {
            state.tempCourseList.tempCourseList = payload
            state.tempCourseList.filteredTempCourseList = payload
            state.tempCourseList.errorMsg = TEMPLATE_INITIAL_STATE.tempCourseList.errorMsg
        },
        setTempCourseListErrorMsg: (state, { payload }) => {
            state.tempCourseList.errorMsg = payload
            state.tempCourseList.tempCourseList = TEMPLATE_INITIAL_STATE.tempCourseList.tempCourseList
            state.tempCourseList.filteredTempCourseList = TEMPLATE_INITIAL_STATE.tempCourseList.filteredTempCourseList
        },
        setClearTempCourseList: (state) => {
            state.tempCourseList.isLoading = TEMPLATE_INITIAL_STATE.tempCourseList.isLoading
            state.tempCourseList.tempCourseList = TEMPLATE_INITIAL_STATE.tempCourseList.tempCourseList
            state.tempCourseList.filteredTempCourseList = TEMPLATE_INITIAL_STATE.tempCourseList.filteredTempCourseList
            state.tempCourseList.errorMsg = TEMPLATE_INITIAL_STATE.tempCourseList.errorMsg
        },

        // reducers for temp course detail payload
        setTempCoursePayload: (state, { payload }) => {
            state.tempCourse.tempCoursePayload = payload
        },
        setClearTempCoursePayload: (state) => {
            state.tempCourse.tempCoursePayload = state.tempCourse.tempCoursePayload
        },

        // reducers for filter Template List
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
            const filteredList = payload.list
                .filter((tempCourse) => (
                    payload.filterProps.tempCourseType === tempCourseType.ALL.value || tempCourse.tempCourseType === payload.filterProps.tempCourseType
                ))

            switch (payload.filterName) {
                case filterName.CREATE_COURSE.value:
                    state.tempCourseList.filteredTempCourseList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = TEMPLATE_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setTempCourseListLoading,
    setTempCourseList,
    setTempCourseListErrorMsg,
    setClearTempCourseList,

    setTempCoursePayload,
    setClearTempCoursePayload,

    setFilterProps,
    setClearFilterProps
} = tempCourse.actions
export default tempCourse.reducer