import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import format from "string-format";

import FullPageLoader from "components/loader/FullPageLoader";
import { OptionSelector } from "components/common-components/Select";

import { getOnboardingTutorPrefDetailByOnboardingId, updateOnboardingTutorPrefDetailByOnboardingId } from 'redux/onboarding/onboarding.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingTutorPrefDetail } from "redux/onboarding/onboarding.slice";
import { tutorPrefStatus } from "redux/onboarding/onboarding.const";
import { TutorPreferenceConst } from "redux/onboarding/onboarding.const";

import { pagesInfo } from "utils/pagesInfo";

const TutorPreferences = () => {
    const { onboardingDetail, onboardingTutorPrefDetail, addOnboardingTutorPrefDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const { onboardingId } = useParams()

    const [tutorPreferenceStatus, setTutorPreferenceStatus] = useState(tutorPrefStatus?.PENDING?.value)

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingTutorPrefDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingTutorPrefDetail())
        }
    }, [onboardingId])

    useEffect(() => {
        if (onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status) {
            setTutorPreferenceStatus(onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status)
        }
    }, [onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status])

    const onHandleSave = () => {
        // if (!onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status || (tutorPreferenceStatus === onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status)) return;

        const requestData = {
            onboardingId: Number(onboardingId),
            body: {
                isTutorProfileDone: true,
                isAvailabilityDone: true,
                isPricePrefDone: true,
                isLessonPrefDone: true,
                isSubjectPrefDone: true,
                status: tutorPreferenceStatus
            }
        }
        dispatcher(updateOnboardingTutorPrefDetailByOnboardingId(requestData))

    }

    const onHandleUpdate = (preference) => {
        if (!onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value]) {
            dispatcher(updateOnboardingTutorPrefDetailByOnboardingId(
                Number(onboardingId),
                {
                    [preference?.value]: true
                }
            ))
        }
        navigate(format(preference?.link, { userId: onboardingDetail?.onboardingDetail?.user?.userId }))
    }

    if (onboardingTutorPrefDetail?.isLoading || addOnboardingTutorPrefDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingTutorPrefDetail?.isLoading || addOnboardingTutorPrefDetail?.isLoading} />
        )
    }

    return (
        <div className={"space-y-3"}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-5 py-3",
                !onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status && "bg-yellow-100",
                onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status && `bg-${tutorPrefStatus[onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Tutor Preferences"}
            </div>
            {onboardingTutorPrefDetail?.onboardingTutorPrefDetail &&
                <div className={"space-y-5"}>
                    <Link
                        to={`${pagesInfo?.EDIT_TUTOR?.pagePath}/${onboardingDetail?.onboardingDetail?.user?.userId}/edit`}
                        className={"px-5 font-bodyPri font-normal text-base text-primary-main cursor-pointer hover:underline"}
                    >
                        {"Edit Tutor Preferences"}
                    </Link>
                    {Object?.values(TutorPreferenceConst)?.map((preference) => (
                        <div className={"w-full flex items-center justify-between gap-5 border border-text-300 rounded-lg px-5 py-3"}>
                            <div className={"flex justify-start items-center gap-5"}>
                                <span className={cn(
                                    "w-4 h-4 rounded-full",
                                    !onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && " border border-text-500 bg-text-50",
                                    onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && "bg-green-500"
                                )}>
                                    {""}
                                </span>
                                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                    {preference?.label}
                                </span>
                            </div>
                            <div
                                className={cn(
                                    "rounded-md px-2 py-0.5 cursor-pointer border border-primary-main bg-primary-main",
                                    "font-bodyPri font-normal text-text-50 text-sm text-center hover:text-text-50 hover:bg-secondary-main hover:border hover:border-secondary-main",
                                    !onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && "text-yellow-500",
                                    onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && "text-green-500"
                                )}
                                onClick={() => onHandleUpdate(preference)}
                            >
                                {onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value]
                                    ? "Updated"
                                    : "Pending"
                                }
                            </div>

                        </div>
                    ))}
                    {(onboardingTutorPrefDetail?.errorMsg || addOnboardingTutorPrefDetail?.errorMsg) &&
                        <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm text-center"}>
                            {onboardingTutorPrefDetail?.errorMsg || addOnboardingTutorPrefDetail?.errorMsg}
                        </div>
                    }
                    <div className="px-5 flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-3 md:flex-row md:items-start md:justify-start">
                        <span className="text-text-600">
                            {"Status:"}
                        </span>
                        <div className={"w-52 h-[7rem]"}>
                            <OptionSelector
                                options={Object.values(tutorPrefStatus)}
                                className={"w-full"}
                                onChange={(option) => setTutorPreferenceStatus(option?.value)}
                                value={tutorPreferenceStatus}
                            />
                        </div>
                    </div>
                    <div className={"flex items-center justify-start gap-5 px-5"}>
                        <div
                            className={cn(
                                "w-24 px-3 py-1 flex justify-center items-center rounded-lg",
                                "border border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                                // (!onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status || (tutorPreferenceStatus === onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.status)) && "border border-text-300 text-text-300"
                            )}
                            onClick={onHandleSave}
                        >
                            {"Save"}
                        </div>
                        <div
                            className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                "border border-primary-main text-primary-main",
                                "hover:bg-primary-main hover:text-text-50"
                            )}
                        // onClick={() => dispatcher(getPersonalDetailByUserId(Number(userId)))}
                        >
                            {"Cancel"}
                        </div>
                    </div>
                </div>
            }
            {(onboardingTutorPrefDetail?.errorMsg || addOnboardingTutorPrefDetail?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingTutorPrefDetail?.errorMsg || addOnboardingTutorPrefDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default TutorPreferences