export const url = {
    LOGIN: "/auth/login",
    SIGN_UP: "/auth/sign-up",
    GET_TOKEN: "/auth/token",
    LOGOUT: "/auth/logout",
    CREATE_VERIFY_REFERRAL: "/auth/verify-referral/{referralCode}",
    FORGET_PASSWORD: "/auth/forgot-password",
    ADD_VERIFY_USER: "/auth/verify-user",
    ADMIN_LOGIN_AS: '/auth/admin-login-as',
}

export const authRedux = {
    sliceName: "auth",
    logIn: {
        requestName: "login"
    },
    accessToken: {
        requestName: "getToken"
    },
    verifyReferral: {
        requestName: "createVerifyReferral"
    },
    forgetPassword: {
        requestName: "sendForgetPasswordLink"
    },
    verifyUser: {
        requestName: "createVerifyUser"
    },
    adminLoginAs: {
        requestName: "createAdminLoginAsDetail"
    }
}

export const logInWith = {
    EMAIL: {
        label: "Email",
        value: "email"
    },
    MOBILE_NO: {
        label: "MobileNo",
        value: "mobileNo"
    }
}

export const authenticateWith = {
    PASSWORD: {
        label: "Password",
        value: "password"
    },
    OTP: {
        label: "Code",
        value: "otp"
    }
}

export const logInPayload = {
    logInWith: logInWith.EMAIL.value,
    email: "",
    mobileNo: "",
    authenticateWith: authenticateWith.PASSWORD.value,
    otp: "",
    otpNotificationId: ""
}

export const signUpPayload = {
    companyId: 1,
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    isEmailVerified: false,
    isMobileNoVerified: false,
    country: null,
    referralCode: "",
    isPromotionActive: false,
}

export const userRoles = {
    ALL: {
        label: "All",
        value: "all"
    },
    VIEW_ONLY: {
        label: 'View Only',
        value: 'view_only'
    },
    TUTOR: {
        label: 'Tutor',
        value: 'tutor'
    },
    STUDENT: {
        label: 'Student',
        value: 'student'
    },
    SUPER_ADMIN: {
        label: 'Super Admin',
        value: 'super_admin'
    },
    ADMIN: {
        label: 'Admin',
        value: 'admin'
    },
    MANAGER: {
        label: 'Manager',
        value: 'manager',
    },
    SUPPORT: {
        label: 'Support',
        value: 'support',
    },
    COMPANY_SUPER_ADMIN: {
        label: 'Company Super Admin',
        value: 'company_super_admin',
    },
    COMPANY_ADMIN: {
        label: 'Company Admin',
        value: 'company_admin',
    },
    RECRUITER: {
        label: "Recruiter",
        value: "recruiter"
    }

}                                                                