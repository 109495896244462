import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { walletTxnHeaderConst } from "pages/auth//walletTxn/data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/walletTxn/walletTxn.slice";
import { amountImpact, transactionStatus, txnType, walletTxnActions } from "redux/walletTxn/walletTxn.const";
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";
import { getWalletTransactionList } from "redux/walletTxn/walletTxn.request";

const WalletTxnSearch = () => {
    const { walletTxnList, filterProps } = useAppState((state) => state.walletTxn)

    const dispatcher = useAppDispatcher()

    const header = Object.values(walletTxnHeaderConst)?.filter((header) => header?.value !== walletTxnHeaderConst?.ACTIVITY.value)?.map((head) => head?.label)

    const body = walletTxnList?.walletTxnList?.results?.map((walletTxn) => ({
        [walletTxnHeaderConst?.USER_ID?.label]: walletTxn?.user?.userId || "-",
        [walletTxnHeaderConst?.USER_NAME?.label]: walletTxn?.user?.firstName + " " + walletTxn?.lastName,
        [walletTxnHeaderConst?.AMOUNT?.label]: "USD " + (walletTxn?.amount / 100).toFixed(2) || "-",
        [walletTxnHeaderConst?.AMOUNT_IMPACT?.label]: amountImpact[walletTxn?.amountImpact?.toUpperCase()]?.label || "-",
        [walletTxnHeaderConst?.VIRTUAL_TXN_ID?.label]: walletTxn?.virtualTxnId || "-",
        [walletTxnHeaderConst?.TXN_TYPE?.label]: txnType[walletTxn?.txnType?.toUpperCase()]?.label || "-",
        [walletTxnHeaderConst?.WALLET_ACTION?.label]: walletTxn?.action ? walletTxnActions[walletTxn?.action?.toUpperCase()]?.label : "-",
        [walletTxnHeaderConst?.DATE?.label]: dayjs(walletTxn?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A') || "-"
    }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-wallet transactions",
            sheet: "wallet transaction",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const onHandleFilterSearch = () => {
        const requestDataPayload = {
            page: filterProps?.page,
            records: filterProps?.records
        }
        if (filterProps?.search) {
            requestDataPayload["search"] = filterProps?.search
        }
        dispatcher(getWalletTransactionList(requestDataPayload))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-wallet transactions"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here..."}
                        value={filterProps?.search}
                        onChange={(event) => dispatcher(setFilterProps({
                            filterProps: { ...filterProps, search: event.target.value }
                        }))}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={onHandleFilterSearch}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WalletTxnSearch;
