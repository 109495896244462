import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { BsCheck2All } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

import FullPageLoader from "components/loader/FullPageLoader";

import { getOnboardingUserDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingUserDetail } from "redux/onboarding/onboarding.slice";
import { userDetailStatus } from "redux/onboarding/onboarding.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const ViewUserDetails = () => {
    const { onboardingUserDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingUserDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingUserDetail())
        }
    }, [onboardingId])

    if (onboardingUserDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingUserDetail?.isLoading} />
        )
    }

    if (onboardingUserDetail?.errorMsg) {
        return (
            <div className={"w-full h-full flex items-center justify-center"}>
                {onboardingUserDetail?.errorMsg}
            </div>
        )
    }

    return (
        <div className={""}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-3 py-3",
                `bg-${userDetailStatus[onboardingUserDetail?.onboardingUserDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Personal Details"}
            </div>

            <div className={"space-y-3 px-5 py-3"}>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Status:"}
                    </span>
                    <span className={cn(
                        "text-medium font-medium capitalize",
                        `text-${userDetailStatus[onboardingUserDetail?.onboardingUserDetail?.status?.toUpperCase()]?.darkColor}`
                    )}>
                        {userDetailStatus[onboardingUserDetail?.onboardingUserDetail?.status?.toUpperCase()]?.label}
                    </span>
                </div>
                <div className="flex flex-col items-center justify-center gap-3 shadow-md rounded-lg border border-text-300 p-5 md:w-[18rem]">
                    <div className="relative w-20 h-20 rounded-full border-[0.3rem] border-primary-light sm:w-20 sm:h-20 md:w-36 md:h-36">
                        <img
                            src={onboardingUserDetail?.onboardingUserDetail?.profilePicUrl}
                            alt={"profileImg"}
                            className="w-full h-full rounded-full p-2 object-cover"
                        />
                        <div className='absolute bottom-3 right-3 w-[22%] h-[22%] rounded-full overflow-hidden border-2 border-white'>
                            <img src={`https://flagcdn.com/64x48/${onboardingUserDetail?.onboardingUserDetail?.fromCountry?.countryDomain}.png`.toLowerCase()} alt={"country-flag"}
                                className={'w-full h-full object-cover'}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-center items-center gap-2 px-3">
                        <div className="flex items-center justify-start gap-2 font-bodyPri font-normal text-text-800 text-lg">
                            {onboardingUserDetail?.onboardingUserDetail?.firstName + " " + onboardingUserDetail?.onboardingUserDetail?.lastName.charAt(0) + "."}
                            {onboardingUserDetail?.onboardingUserDetail?.onlineStatus == "online" &&
                                <div
                                    className={"w-3 h-3 rounded-full bg-green-600"}>
                                </div>
                            }
                        </div>
                        <div>
                            <span className="font-bodyPri font-normal text-sm text-text-700 tracking-wide">
                                {"Last Seen: "}
                            </span>
                            <span className="font-bodyPri font-normal text-text-800 text-sm">
                                {dayjs(onboardingUserDetail?.onboardingUserDetail?.lastActiveTime).tz(timeZone).format("MMM, D YYYY, hh:mm A")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Title:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.title}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"First Name:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.firstName}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Last Name:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.lastName}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Preferred Name:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.preferredName}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Gender:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.gender}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Date Of Birth:"}
                    </span>
                    <span className="text-medium font-medium">
                        {dayjs(onboardingUserDetail?.onboardingUserDetail?.dateOfBirth).tz(timeZone).format('dddd, MMM DD, YYYY')}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <div>
                        <span className="text-text-600">
                            {"Email:"}
                        </span>
                        <span className="text-medium font-medium">
                            {onboardingUserDetail?.onboardingUserDetail?.email}
                        </span>
                    </div>
                    <div className={""}>
                        {onboardingUserDetail?.onboardingUserDetail?.isEmailVerified
                            && <span className="flex items-center justify-center gap-1">
                                {"(Verified)"}
                                <BsCheck2All className={"text-green-500 text-lg"} />
                            </span>
                        }
                        {!onboardingUserDetail?.onboardingUserDetail?.isEmailVerified
                            && <span className="flex items-center justify-center gap-1 whitespace-nowrap">
                                {"(Not Verified)"}
                                <TiDeleteOutline className={"text-red-500 text-lg"} />
                            </span>
                        }
                    </div>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <div>
                        <span className="text-text-600">
                            {"Mobile Phone:"}
                        </span>
                        <span className="text-medium font-medium">
                            {"+" + onboardingUserDetail?.onboardingUserDetail?.isdCode + "-" + onboardingUserDetail?.onboardingUserDetail?.mobileNo}
                        </span>
                    </div>
                    <div className={""}>
                        {onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified
                            && <span className="flex items-center justify-center gap-1">
                                {"(Verified)"}
                                <BsCheck2All className={"text-green-500 text-lg"} />
                            </span>
                        }
                        {!onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified
                            && <span className="flex items-center justify-center gap-1 whitespace-nowrap">
                                {"(Not Verified)"}
                                <TiDeleteOutline className={"text-red-500 text-lg"} />
                            </span>
                        }
                    </div>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Home Phone:"}
                    </span>
                    <span className="text-medium font-medium">
                        {onboardingUserDetail?.onboardingUserDetail?.homePhone || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Work Phone:"}
                    </span>
                    <span className="text-medium font-medium">
                        {onboardingUserDetail?.onboardingUserDetail?.workPhone || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Address Type:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.addressType}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Street Address:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.street}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Country:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.country?.country || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"State:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.state || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"City:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {onboardingUserDetail?.onboardingUserDetail?.city || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Postal/ZIP Code:"}
                    </span>
                    <span className="text-medium font-medium">
                        {onboardingUserDetail?.onboardingUserDetail?.zipCode || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Facebook:"}
                    </span>
                    <span className="text-medium font-medium">
                        {onboardingUserDetail?.onboardingUserDetail?.facebookProfileLink || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Instagram:"}
                    </span>
                    <span className="text-medium font-medium">
                        {onboardingUserDetail?.onboardingUserDetail?.instagramProfileLink || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"Twitter:"}
                    </span>
                    <span className="text-medium font-medium">
                        {onboardingUserDetail?.onboardingUserDetail?.twitterProfileLink || "-"}
                    </span>
                </div>
                <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                    <span className="text-text-600">
                        {"LinkedIn:"}
                    </span>
                    <span className="text-medium font-medium">
                        {onboardingUserDetail?.onboardingUserDetail?.linkedinProfileLink || "-"}
                    </span>
                </div>
                {onboardingUserDetail?.onboardingUserDetail?.comments &&
                    <div className="flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Comments:"}
                        </span>
                        <span className="text-medium font-medium">
                            {onboardingUserDetail?.onboardingUserDetail?.comments}
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewUserDetails