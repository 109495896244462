import { useState } from 'react';
import tw from 'twin.macro';

import { cn } from "utils/cn.utils";

import { IoAppsSharp } from "react-icons/io5";

import DropDownMenu from 'components/dropdownMenu/DropDownMenu';

import {
    LoggedInNavLink,
    ProfileIcon
} from './NavBarStyle';
import RightLoggedInToggleMenu from './RightLoggedInToggleMenu';

import { useAppState } from 'hooks/useStore';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const RightLoggedInNavLinks = ({ isScrolled }) => {
    const { user } = useAppState((state) => state.user)

    const [isClick, setIsClick] = useState(false)

    const onHandleNavigateToApp = () => {
        window.open(`${EDULYTE_APP_DOMAIN_URL}/dashboard`, "_self")
    }

    return (
        <LoggedInNavLink>
            <div className={"relative has-tooltip"}>
                <IoAppsSharp
                    className={"text-2xl text-primary-dark cursor-pointer"}
                    onClick={onHandleNavigateToApp}
                />
                <span
                    className={cn(
                        "w-fit px-2 py-1 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -translate-y-8",
                        "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap translate-y-[60%]"
                    )}
                >
                    {"Go to App"}
                </span>
            </div>
            <ProfileIcon onClick={() => setIsClick(!isClick)} isScrolled={isScrolled}>
                <img src={user?.user?.profilePicUrl} alt={'profile-pic'} />
            </ProfileIcon>
            <DropDownMenu
                isClick={isClick}
                setIsClick={setIsClick}
                boxStyle={tw`bg-white right-0 max-h-[80vh]`}
                arrowStyle={tw`bg-white`}
            >
                <RightLoggedInToggleMenu setIsClick={setIsClick} />
            </DropDownMenu>
        </LoggedInNavLink>
    )
}

export default RightLoggedInNavLinks;