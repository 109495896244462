import { useMemo, useState } from "react";

import { IoIosArrowForward } from 'react-icons/io';

import { sortSessionUserList } from "../../data";

import { useAppState } from "hooks/useStore";
import { sessionUserTypeEnum } from "redux/session/session.const";

import { cn } from "utils/cn.utils";

const SessionDetailParticipants = () => {
    const { sessionDetail } = useAppState(s => s.session)

    const [isShow, setIsShow] = useState(false)

    const ownerUser = useMemo(() => {
        if (!!sessionDetail?.data?.result?.owner_user && !!sessionDetail?.data?.result?.session_users?.length) {
            return sessionDetail?.data?.result?.session_users?.find((sessionUser) => sessionUser?.user?.id === sessionDetail?.data?.result?.owner_user?.id)
        }
    }, [sessionDetail?.data?.result?.owner_user, sessionDetail?.data?.result?.session_users])

    const sessionUserList = useMemo(() => {
        let filteredUserList = sessionDetail?.data?.result?.session_users || []

        if (sessionDetail?.data?.result?.session_users && ownerUser) {
            filteredUserList = filteredUserList.filter((sessionUser) => sessionUser?.user?.id !== ownerUser?.id)?.slice(0)?.sort(sortSessionUserList)
            filteredUserList = [ownerUser, ...filteredUserList]?.reduce((acc, user) => {
                const userExists = acc?.some(u => u?.user?.id === user?.user?.id)
                if (!userExists) {
                    acc?.push(user)
                }
                return acc;
            }, [])
        }

        return filteredUserList
    }, [sessionDetail?.data?.result?.session_users, ownerUser])

    return !!sessionDetail?.data?.result?.session_users?.length && (
        <div className={"space-y-1 transition-transform duration-300 delay-150 ease-in-out"}>
            <div
                onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Participants"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                {(!sessionUserList || (sessionUserList?.length === 0)) &&
                    <div className={"w-full flex justify-center font-bodyPri font-normal text-text-800 text-base"}>
                        {"No participants added!"}
                    </div>
                }

                {(!!sessionUserList?.length) &&
                    sessionUserList?.map((participant, index) => (
                        <div className={"flex items-center justify-between gap-3 py-2 px-5 rounded-md hover:bg-gray-50 cursor-pointer"} key={index}>
                            <div className={"flex items-center justify-start gap-3"}>
                                <img src={participant?.user?.profilePicUrl} alt="" className={"w-10 h-10 rounded-full"} />
                                <div className={"flex flex-col items-start justify-evenly h-full"}>
                                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                        {participant?.user?.firstName + " " + participant?.user?.lastName.charAt(0)}
                                    </span>
                                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                        {sessionUserTypeEnum[participant?.userType?.toUpperCase()]?.label}
                                    </span>
                                </div>
                            </div>
                            {(sessionDetail?.data?.result?.owner_user?.id === participant?.user?.id) &&
                                <div className={"flex items-end justify-start h-full"}>
                                    <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                                        {"Owner"}
                                    </span>
                                </div>
                            }
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default SessionDetailParticipants;