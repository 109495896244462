import { dayjs, timeZone } from 'utils/dateTime.utils';

import {
    SearchOrderId,
    SearchUserName,
    FilterOrderType,
    SearchOrderAmount,
    FilterIsOrderPaid,
    FilterOrderStatus,
    SearchDate,
    ButtonAction,
    // SearchUpdatedDate,
} from "pages/auth/orderList/components/OrderFilterStatistics";

export const pageHeading = {
    heading: "My Orders",
    subHeading: "Browse all your orders and take actions!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/bookings.svg"
}

export const orderToolTip = "Click on eye icon in the last column to view details of a order. See Schedule under any order, send message to your student who made any given order."

export const orderHeaderConst = {
    ORDER_ID: {
        label: "Order ID",
        value: "order_id"
    },
    USER_NAME: {
        label: "User Name",
        value: "user_name"
    },
    ORDER_TYPE: {
        label: "Order Type",
        value: "order_type"
    },
    ORDER_AMOUNT: {
        label: "Order Amount",
        value: "order_amount"
    },
    ORDER_PAID: {
        label: "Is Paid",
        value: "is_paid"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    // MODIFIED_DATE: {
    //     label: "Modified Date",
    //     value: "modified_date"
    // },
};

export const subRowHeader = [
    <SearchOrderId />,
    <SearchUserName />,
    <FilterOrderType />,
    <SearchOrderAmount />,
    <FilterIsOrderPaid />,
    <FilterOrderStatus />,
    <SearchDate />,
    <ButtonAction />,
    // <SearchUpdatedDate />
]

export const orderItemLogConst = {
    PENDING: {
        title: "Pending",
    },
    PROCESSING: {
        title: "Processing"
    },
    CONFIRMED: {
        title: "Confirmed",
    },
    FAILED: {
        title: "Failed"
    },
    COMPLETED: {
        title: "Completed",
    },
    REFUNDED: {
        title: "Refunded",
    },
    CANCELLED: {
        title: "Cancelled",
    },
}


export const sortOrderLowToHigh = (activeSortHeader, order1, order2) => {
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.ORDER_ID?.value) {
        return (Number(order1?.id) - Number(order2?.id))
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.USER_NAME?.value) {
        return order1?.user?.first_name?.toLowerCase()?.localeCompare(order2?.user?.first_name?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.ORDER_TYPE?.value) {
        return order1?.type?.toLowerCase()?.localeCompare(order2?.type?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.ORDER_AMOUNT?.value) {
        return (Number(order1?.total_amount) - Number(order2?.total_amount))
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.ORDER_PAID?.value) {
        return order1?.is_paid?.toLowerCase()?.localeCompare(order2?.is_paid?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.STATUS?.value) {
        return order1?.status?.toLowerCase()?.localeCompare(order2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.DATE?.value) {
        return dayjs(order1?.createdAt).isSameOrBefore(order2?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(order1?.updatedAt).isSameOrBefore(order2?.updatedAt) ? 1 : -1
    }
}

export const sortOrderHighToLow = (activeSortHeader, order1, order2) => {
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.ORDER_ID?.value) {
        return (Number(order2?.id) - Number(order1?.id))
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.USER_NAME?.value) {
        return order2?.user?.first_name?.toLowerCase()?.localeCompare(order1?.user?.first_name?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.ORDER_TYPE?.value) {
        return order2?.type?.toLowerCase()?.localeCompare(order1?.type?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.ORDER_AMOUNT?.value) {
        return (Number(order2?.total_amount) - Number(order1?.total_amount))
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.ORDER_PAID?.value) {
        return order2?.is_paid?.toLowerCase()?.localeCompare(order1?.is_paid?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.STATUS?.value) {
        return order2?.status?.toLowerCase()?.localeCompare(order1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.DATE?.value) {
        return dayjs(order2?.createdAt).isSameOrBefore(order1?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === orderHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(order2?.updatedAt).isSameOrBefore(order1?.updatedAt) ? 1 : -1
    }
}