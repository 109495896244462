import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import { getUserAddressListByUserId } from 'redux/user/user.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearUserAddressList } from 'redux/user/user.slice';

const ViewUserAddress = () => {
  const { userAddressList } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()
  const { userId } = useParams()

  useEffect(() => {
    if (userId) {
      dispatcher(getUserAddressListByUserId(Number(userId)))
    }

    return () => {
      dispatcher(setClearUserAddressList())
    }
  }, [userId])

  return (
    <div>
      <div className={"py-3 space-y-3"}>
        <div className="w-full flex item-start justify-center gap-1">
          <span className="font-subHeading font-medium text-2xl text-text--800">
            {"User Address"}
          </span>
        </div>
        <div className="h-0.5 bg-gray-300 w-full"></div>
      </div>

      <ComponentLoader isLoading={userAddressList?.isLoading} />

      <div className={"px-4 py-10 space-y-5"}>
        {userAddressList?.userAddressList?.length > 0 && userAddressList?.userAddressList?.map((userAddress) => (
          <div className={"flex flex-col items-start justify-start gap-5"}>
            <div className={"space-y-3"}>
              <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                {"Address Type"}
              </span>
              <OptionSelector
                className={"w-full"}
                value={userAddress?.addressType}
              // onChange={(option) => dispatcher(setUserAddressList([...userAddressList?.userAddressList, { addressType: option?.value }]))}
              />
            </div>
            <div className={"space-y-3"}>
              <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                {"Address"}
              </span>
              <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                {userAddress?.street}
              </span>
            </div>
          </div>
        ))}

        {(userAddressList?.errorMsg || !userAddressList?.userAddressList || userAddressList?.userAddressList?.length === 0) &&
          <span className={"font-bodyPri font-normal text-text-800 text-base"}>
            {userAddressList?.errorMsg || "No Address Added!"}
          </span>
        }
      </div>
    </div >
  )
}

export default ViewUserAddress;