import { dayjs, timeZone } from "utils/dateTime.utils";


export const getDateTimeSlotFromDay = async (weeklyAvailTimeSlots) => {
    let firstSlot = null
    let newStartDateTimeSlot = dayjs().tz(timeZone)

    for (let index = 0; index <= 7; index++) {
        const localDay = newStartDateTimeSlot?.format('dddd')
        const weeklyAvailSlots = weeklyAvailTimeSlots[localDay?.slice(0, 3)?.toUpperCase()]
        if (weeklyAvailSlots?.length > 0) {
            firstSlot = {
                startTime: weeklyAvailSlots[0]?.startTime
            }
            break;
        } else {
            firstSlot = {
                startTime: newStartDateTimeSlot?.format("HH:mm:ss")
            }
            newStartDateTimeSlot = newStartDateTimeSlot.add(1, "day")
        }
    }
    let startDate = newStartDateTimeSlot?.format("YYYY-MM-DD")
    newStartDateTimeSlot = startDate + " " + firstSlot?.startTime

    return newStartDateTimeSlot
}

export const validateDateTimeSlots = async (dateTimeAvailTimeSlots) => {
    let newDateTimeSlots = []
    dateTimeAvailTimeSlots?.forEach(dateTime => {
        if (dateTime?.timeSlots?.length === 0) {
            newDateTimeSlots?.push(dateTime?.date)
        }
    })

    if (newDateTimeSlots?.length > 0) {
        return false
    } else {
        return true
    }
}