import { lmsQuizFilterProps } from "./lmsQuiz.const";

export const LMS_QUIZ_INITIAL_STATE = {
    lmsQuizList: {
        isLoading: false,
        payload: null,
        data: null,
        message: null,
    },

    destroyLmsQuizDetail: {
        isLoading: false,
        message: null
    },

    filterProps: lmsQuizFilterProps
}