import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { MdAdd } from "react-icons/md";

import EdiPeopleCard from "pages/auth/editCompanyOnboarding/components/editPeople/EditPeopleCard";

import { getEmployeeListByCompanyId } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearEmployeeList, setEmployeeDetailPayload } from "redux/onboarding/onboarding.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

const itemsPerPage = 4
const profilePictureUrl = "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw"

const EditPeople = () => {
    const { modal } = useAppState((state) => state.local)
    const { employeeList } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { companyId } = useParams()

    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        if (companyId) {
            dispatcher(getEmployeeListByCompanyId({ activePage: activePage, itemsPerPage: itemsPerPage }, Number(companyId)))
        }

        // return () => {
        //     dispatcher(setClearEmployeeList())
        // }
    }, [activePage, companyId])

    const handleAddPeople = () => {
        dispatcher(setEmployeeDetailPayload({
            employeeId: null,
            firstName: "",
            lastName: "",
            profilePicUrl: profilePictureUrl,
            about: "",
            designation: ""
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.COMPANY_PEOPLE_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"PEOPLE"}>
            <div className={"relative max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <div className={"flex justify-start items-center gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"People"}
                        </span>
                        {
                            <div className={"flex justify-center items-center gap-5"}>
                                <div
                                    className={cn(
                                        "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                        "font-buttons font-normal text-base text-text-50 bg-primary-main",
                                        "hover:bg-primary-dark"
                                    )}
                                    onClick={handleAddPeople}
                                >
                                    <MdAdd className={"text-xl"} />
                                    <span className={"whitespace-nowrap"}>
                                        {"Add People"}
                                    </span>
                                </div>
                                <div
                                    className={cn(
                                        "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                        "font-buttons font-normal text-base text-text-800 bg-background-darkLight",
                                        "hover:bg-background-medium hover:text-text-900"
                                    )}
                                // onClick={() => setEditBtnConst(null)}
                                >
                                    <span className={""}>
                                        {"Cancel"}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={cn(
                        "w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-8 p-5"
                    )}>
                        {employeeList?.employeeList?.list?.length > 0 &&
                            employeeList?.employeeList?.list?.map((employee, index) => (
                                <div className={"col-span-1"} key={index}>
                                    <EdiPeopleCard
                                        employee={employee}
                                    />
                                </div>
                            ))}
                    </div>
                    {(employeeList?.employeeList?.list?.length > 0 && employeeList?.employeeList?.totalPages !== activePage) &&
                        <div
                            className={cn(
                                "w-full flex justify-center items-center border border-text-500 p-2 rounded",
                                "font-bodyPri font-medium text-text-800 text-base cursor-pointer",
                                "hover:bg-background-medium"
                            )}
                            onClick={() => setActivePage(activePage + 1)}
                        >
                            {"Show more"}
                        </div>
                    }
                    {employeeList?.employeeList?.list?.length === 0 &&
                        <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                            {"No People added yet!"}
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditPeople;