export const url = {
    BOOKING_LIST: "bookings/bookings",
    GET_BOOKING_DETAIL: "bookings/bookings/{bookingId}",

    ADD_BOOKING_DETAIL: "bookings/bookings",
    
    ADD_BOOKING_SESSION_DETAIL: "bookings/bookings/{bookingId}/sessions",
}

export const bookingRedux = {
    sliceName: "booking",
    BookingList: {
        requestName: 'getBookingList'
    },
    bookingDetail: {
        requestName: "getBookingDetail"
    },
    addBookingDetail: {
        requestName: "createBookingDetail"
    },
}

export const addBookingDetailPayload = {
    offering: null,
    slug: null,
    userId: null,
    offeringType: null,
    bookingId: null,
    courseId: null,
    appointmentId: null,
    type: null,
    timeZone: null,
    duration: 60,
    sessionCount: 5,
    schedule: [],
    itemPriceId: null
}

export const bookingOfferingTypeEnum = {
    COURSE: {
        value: 'course',
        label: 'Class'
    },
    APPOINTMENT: {
        value: 'appointment',
        label: 'Appointment'
    }
}

export const bookingTypeEnum = {
    REGULAR: {
        value: 'regular',
        label: 'Regular'
    },
    PLAN: {
        value: 'plan',
        label: 'PLAN',
    }
}

export const bookingStatusEnum = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    PENDING: {
        value: "pending",
        label: "Pending",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PROCESSING: {
        value: "processing",
        label: "Processing",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    CONFIRMED: {
        value: "confirmed",
        label: "Confirmed",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    COMPLETED: {
        value: "completed",
        label: "Completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    REFUNDED: {
        value: "refunded",
        label: "Refunded",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    FAILED: {
        value: "failed",
        label: "Failed",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    CANCELLED: {
        value: "cancelled",
        label: "Cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const bookingScheduleTypeEnum = {
    ONE_ON_ONE: {
        value: 'one_on_one',
        label: 'One on One'
    },
    GROUP: {
        value: 'group',
        label: 'Group'
    },
}

export const bookingScheduleIsRecurringEnum = {
    RECURRING: {
        value: 'recurring',
        label: 'Recurring'
    },
    NON_RECURRING: {
        value: 'non_recurring',
        label: 'Non Recurring'
    },
}

export const bookingScheduleEndTypeEnum = {
    FOREVER: {
        value: 'forever',
        label: 'Forever'
    },
    ON_DATE: {
        value: 'on_date',
        label: 'On Date'
    },
    WEEKLY_CYCLE: {
        value: 'weekly_cycle',
        label: 'Weekly Cycle'
    }
}

export const bookingRegularPriceModelEnum = {
    FREE: {
        value: 'free',
        label: 'Free'
    },
    FLAT_FEE: {
        value: 'flat_fee',
        label: 'Flat Fee'
    },
    PER_HOUR: {
        value: 'per_hour',
        label: 'Per Hour'
    },
    PER_SESSION: {
        value: 'per_session',
        label: 'Per Session'
    }
}

export const bookingPlanPeriodUnitEnum = {
    DAY: {
        value: 'day',
        label: 'Day'
    },
    WEEK: {
        value: 'week',
        label: 'Week'
    },
    MONTH: {
        value: 'month',
        label: 'Month'
    },
    YEAR: {
        value: 'year',
        label: 'Year'
    }
}

export const bookingPlanPriceModelEnum = {
    FLAT_FEE: {
        value: 'flat_fee',
        label: 'Flat Fee'
    },
    PER_UNIT: {
        value: 'per_unit',
        label: 'Per Unit'
    },
    TIERED: {
        value: 'tired',
        label: 'Tiered'
    },
    VOLUME: {
        value: 'volume',
        label: 'Volume'
    },
    STAIRSTEP: {
        value: 'stairstep',
        label: 'Stairstep'
    }
}

export const bookingIsPaidEnum = {
    PAID: {
        value: 'paid',
        label: 'Paid'
    },
    UNPAID: {
        value: 'unpaid',
        label: 'Unpaid'
    }
}

export const bookingSessionEnum = {
    MAPPED: {
        value: 'mapped',
        label: 'Mapped'
    },
    IS_MAPPED: {
        value: 'is_mapped',
        label: 'Is Mapped'
    }
}


export const filterName = {
    BOOKING: {
        value: "booking"
    }
}

export const weeklyTimeSlot = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
}

export const bookingType = {
    REGULAR: {
        label: 'Regular',
        value: 'regular'
    },
    TRIAL: {
        label: 'Trial',
        value: 'trial'
    },
    ON_CHAT: {
        label: 'On Chat',
        value: 'on_chat',
    }
}

export const chooseTimeSlotAction = {
    BOOKING: {
        value: 'booking',
        label: 'Booking',
    },
    RESCHEDULED: {
        value: 'rescheduled',
        label: 'Rescheduled',
    },
    CHOOSE_BOOKED_SLOT: {
        value: 'choose_booked_slot',
        label: 'Choose Booked Slot',
    },
}

export const bookingPaidConst = {
    PAID: {
        label: "Paid",
        value: "paid"
    },
    UN_PAID: {
        label: "Un Paid",
        value: "un_paid"
    }
}

export const bookingOfferingType = {
    COURSE: {
        label: "Class",
        value: "course"
    },
    APPOINTMENT: {
        label: "Appointment",
        value: "appointment"
    }
}

export const bookingPriceType = {
    REGULAR: {
        label: "Regular",
        value: "regular"
    },
    PLAN: {
        label: "Plan",
        value: "plan"
    }
}

export const userBookingTimeZoneConst = {
    USER_TIMEZONE: {
        label: "User TimeZone",
        value: "user_timezone"
    },
    YOUR_TIMEZONE: {
        label: "Your TimeZone",
        value: "your_timezone"
    }
}

export const bookingFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    isPaid: "",
    courseType: "",
    bookingType: "",
    bookingId: "",
    courseId: "",
    userId: "",
    price: "",
    offeringType: "",
    status: "",
    startDate: "",
    endDate: ""
}