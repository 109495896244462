import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { getProductList } from "redux/product/product.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setFilterProps } from 'redux/product/product.slice';
import { dataEntriesConst } from "redux/local/local.const";

const ProductSearch = () => {
    const { productList, filterProps } = useAppState((state) => state.product)

    const dispatcher = useAppDispatcher()

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value }
        }))
    }

    const onHandleSearch = () => {
        if (!filterProps?.searchText || productList?.isLoading) return;

        dispatcher(getProductList({ search: filterProps?.searchText, page: filterProps?.page || 1, records: filterProps?.records || 25 }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-start gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>

            <div className={"inline-flex shadow-sm"}>
                <input
                    type={"text"}
                    className={cn(
                        "px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                        "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                    )}
                    placeholder={"Search here..."}
                    value={filterProps.searchText}
                    onChange={(event) => handleFilterSearch(event)}
                />
                <button
                    className={cn(
                        "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                        "font-bodyPri font-normal text-base"
                    )}
                    onClick={onHandleSearch}
                >
                    {"Search"}
                </button>
            </div>
        </div>
    );
};
export default ProductSearch;