import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { courseHeaderConst } from "pages/auth/courses/data";

import { getCourseList } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setFilterProps } from "redux/course/course.slice";
import { courseType, courseStatus, coursePriceType } from "redux/course/course.const";
import { dataEntriesConst, modalConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const CoursesSearch = () => {
    const { modal } = useAppState((state) => state.local)
    const { courseList, filterProps } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const header = Object.values(courseHeaderConst)?.filter((headerConst) => headerConst?.value !== courseHeaderConst?.ACTIVITY?.value)?.map((header) => header?.label)

    const body = courseList?.data?.results?.map((course) => ({
        [courseHeaderConst?.CLASS_ID.label]: course?.id,
        // [courseHeaderConst.SEGMENT.label]: course?.segment,
        [courseHeaderConst.CATEGORY.label]: course?.category,
        [courseHeaderConst.TUTOR.label]: course?.tutor?.user?.firstName + " " + course?.tutor?.user?.lastName,
        [courseHeaderConst.CLASS_TITLE.label]: course?.title,
        [courseHeaderConst.CLASS_TYPE.label]: courseType[course?.courseType?.toUpperCase()]?.label,
        // [courseHeaderConst.PRICE_TYPE.label]: coursePriceType[course?.coursePrice?.type?.toUpperCase()]?.label,
        [courseHeaderConst.STATUS.label]: courseStatus[course?.status?.toUpperCase()]?.label,
        [courseHeaderConst.DATE.label]: dayjs(course?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A')
    }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-courses",
            sheet: "course",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const handleAddCourse = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_COURSE_MODAL.stateKey]: true
        }))
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value }
        }));
    }

    const onHandleSearch = () => {
        if (courseList?.isLoading || !filterProps?.searchText) return;

        dispatcher(getCourseList({ search: filterProps?.searchText, page: filterProps?.page || 1, records: filterProps?.records || 25 }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-courses"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={handleAddCourse}
                >
                    {"Add Class"}
                </span>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here..."}
                        value={filterProps.searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <button
                        className={cn(
                            "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                            "font-bodyPri font-normal text-base"
                        )}
                        onClick={onHandleSearch}
                    >
                        {"Search"}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CoursesSearch;
