import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader";

import { searchParamsInfo } from "pages/auth/bookings/data";
import AppointmentProfile from "pages/auth/bookings/detail/AppointmentProfile";
import CourseProfile from "pages/auth/bookings/detail/CourseProfile";
import BookingAppointment from "pages/auth/bookings/detail/BookingAppointment";
import BookingCourse from "pages/auth/bookings/detail/BookingCourse";
import BookingSchedule from "pages/auth/bookings/detail/BookingSchedule";
import BookingLogs from "pages/auth/bookings/detail/BookingLogs";

import { getBookingDetail } from "redux/booking/booking.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetBookingDetail } from "redux/booking/booking.slice";
import { bookingOfferingTypeEnum } from 'redux/booking/booking.const';

import { timeZone } from 'utils/dateTime.utils';
import { cn } from "utils/cn.utils";

const BookingSideOverContent = () => {
  const { bookingDetail } = useAppState((state) => state.booking)

  const dispatcher = useAppDispatcher()
  const location = useLocation()

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  useEffect(() => {
    if (!!searchQueryParams?.get(searchParamsInfo.bookingId.key)) {
      const requestDataPayload = {
        bookingId: searchQueryParams?.get(searchParamsInfo.bookingId.key),
        query: { timeZone: timeZone }
      }
      dispatcher(getBookingDetail(requestDataPayload))
    }

    return () => {
      dispatcher(resetBookingDetail())
    }
  }, [searchQueryParams?.get(searchParamsInfo.bookingId.key)])

  return (
    <div className={"p-5 h-screen sm:px-10 max-w-2xl md:max-w-xl lg:max-w-lg"}>
      <ComponentLoader isLoading={bookingDetail?.isLoading} />
      {bookingDetail?.message &&
        <span className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
          {bookingDetail?.message}
        </span>
      }
      {(bookingDetail?.data?.result && !bookingDetail?.isLoading) && (
        <div className={cn(
          "flex flex-col w-full min-h-[80vh] overflow-hidden overflow-x-hidden overflow-y-scroll space-y-3 transition ease-in-out delay-150 duration-300",
          "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
          "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
        )}>
          {(bookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum?.APPOINTMENT?.value) &&
            <AppointmentProfile bookingUserDetail={bookingDetail?.data?.result} />
          }
          {(bookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum?.COURSE?.value) &&
            <CourseProfile bookingUserDetail={bookingDetail?.data?.result} />
          }
          {(bookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum?.APPOINTMENT?.value) &&
            <BookingAppointment bookingUserDetail={bookingDetail?.data?.result} />
          }
          {(bookingDetail?.data?.result?.offeringType === bookingOfferingTypeEnum?.COURSE?.value) &&
            <BookingCourse bookingUserDetail={bookingDetail?.data?.result} />
          }
          <div className={"w-full bg-text-400 h-0.5 mx-auto mb-3"} />
          <BookingSchedule bookingUserDetail={bookingDetail?.data?.result} />
          <div className={"w-full bg-text-400 h-0.5 mx-auto my-3"} />
          <BookingLogs bookingUserDetail={bookingDetail?.data?.result} />
        </div>
      )}
    </div>
  )
}

export default BookingSideOverContent;