export const url = {
    USER_PAYOUTS: "/payouts/users/{userId}",
}

export const payoutRedux = {
    sliceName: "payout",
    userPayoutList: {
        requestName: "getUserPayoutListByUserId"
    },
    userPayoutDetail: {
        requestName: "createUserPayoutDetailByUserId"
    }
}
export const payoutType = {
    BANK: {
        label: "Bank",
        value: "bank"
    },
    PAY: {
        label: "Pay ID",
        value: "pay"
    },
    UPI: {
        label: "UPI",
        value: "upi"
    },
    PAYPAL: {
        label: "PayPal",
        value: "paypal"
    }
}