import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';

import { getTutorDetail } from 'redux/tutor/tutor.request';
import { getCourseTemplateList, createCourseDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetTutorDetail } from 'redux/tutor/tutor.slice';
import {
    setClearAddCourseDetail,
    setCourseTemplateDetailPayload,
    setClearCourseTemplateDetailPayload,
    setClearCourseTemplateList
} from "redux/course/course.slice";
import { modalConst } from 'redux/local/local.const';
import { tempCourseType, visibilityType } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';

const AddCourseModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { tutorDetail } = useAppState((state) => state.tutor)
    const { courseTemplateDetail, courseTemplateList, addCourseDetail } = useAppState((state) => state.course)
    const filteredTempCourseList = courseTemplateList?.data

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathName || `/classes/${addCourseDetail?.addCourseDetail?.id}/edit?step=${addCourseDetail?.addCourseDetail?.step}`

    useEffect(() => {
        return () => {
            dispatcher(resetTutorDetail())
            dispatcher(setClearCourseTemplateList())
            dispatcher(setClearCourseTemplateDetailPayload())
            dispatcher(setClearAddCourseDetail())
        }
    }, [])

    useEffect(() => {
        dispatcher(getCourseTemplateList())
    }, [])


    useEffect(() => {
        if (filteredTempCourseList) {
            dispatcher(setCourseTemplateDetailPayload({
                ...courseTemplateDetail?.payload,
                templateId: filteredTempCourseList[0]?.id
            }))
        }
    }, [filteredTempCourseList])

    useEffect(() => {
        if (addCourseDetail.addCourseDetail && tutorDetail?.data) {
            dispatcher(setClearAddCourseDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_COURSE_MODAL.stateKey]: false
            }))
            navigate(from, { replace: true })
        }
    }, [addCourseDetail.addCourseDetail, tutorDetail?.data])

    const handleOnChangeTempCourseType = (courseType) => {
        dispatcher(setCourseTemplateDetailPayload({
            type: courseType
        }))

        if (courseType === tempCourseType?.ALL?.value) {
            dispatcher(getCourseTemplateList())
            return;
        }

        dispatcher(getCourseTemplateList({ type: courseType }))
    }

    const handleTemplate = (template) => {
        dispatcher(setCourseTemplateDetailPayload({
            ...courseTemplateDetail?.payload,
            templateId: template?.id
        }))
    }

    const onHandleValidateTutor = () => {
        if (addCourseDetail?.isLoading || tutorDetail?.isLoading) return;
        dispatcher(resetTutorDetail())
        dispatcher(getTutorDetail(courseTemplateDetail?.payload?.tutorId))
    }

    const onHandleNavigateToUserDetail = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_COURSE_MODAL.stateKey]: false
        }))
        navigate(`${pagesInfo?.VIEW_USER?.pagePath}/${tutorDetail?.data?.user?.id}`)
    }

    const onHandleSubmit = () => {
        if (!courseTemplateDetail?.payload?.tutorId || !courseTemplateDetail?.payload?.templateId || !tutorDetail?.data) return;
        if (addCourseDetail?.isLoading) return;

        const requestDataPayload = {
            courseTemplate: courseTemplateDetail?.payload?.templateId,
            tutor: courseTemplateDetail?.payload?.tutorId
        }
        dispatcher(createCourseDetail(requestDataPayload))
    }

    if (courseTemplateList?.isLoading) {
        return (
            <ComponentLoader isLoading={courseTemplateList?.isLoading} className={"min-h-[24rem] w-full"} />
        )
    }

    if (courseTemplateList?.message) {
        return (
            <div className={"w-full h-[24rem] text-center font-bodyPri fnt-normal text-red-500 text-base"}>
                {courseTemplateList?.message}
            </div>
        )
    }


    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block w-full space-y-5"}>
                <div className="w-full px-4 mt-1.5 space-y-5">
                    <div className={"flex items-center justify-center gap-3"}>
                        {Object.keys(tempCourseType).map((key, index) => {
                            return (
                                <div key={index} onClick={() => handleOnChangeTempCourseType(tempCourseType[key].value)}
                                    className={cn(
                                        "w-24 py-1 font-buttons font-normal text-base",
                                        "flex items-center justify-center rounded-full cursor-pointer",
                                        tempCourseType[key].value === courseTemplateDetail?.payload?.type ?
                                            "bg-primary-main text-text-50" :
                                            "bg-primary-light text-primary-main hover:bg-primary-main hover:text-text-50"
                                    )}
                                >
                                    {tempCourseType[key].label}
                                </div>
                            )
                        })}
                    </div>
                    {courseTemplateDetail?.payload?.type === tempCourseType?.ALL?.value &&
                        <p className="text-base font-normal text-text-700 text-center self-centre">
                            {"Select & customise a template to create classes."}
                        </p>
                    }
                    {courseTemplateDetail?.payload?.type === tempCourseType?.ONE_ON_ONE?.value &&
                        <p className="text-base font-normal text-text-700 text-center self-centre">
                            {`Select & customise a template to create ${tempCourseType?.ONE_ON_ONE?.label} classes.`}
                        </p>
                    }
                    {courseTemplateDetail?.payload?.type === tempCourseType?.GROUP?.value &&
                        <p className="text-base font-normal text-text-700 text-center self-centre">
                            {`Select & customise a template to create ${tempCourseType?.GROUP?.label} classes.`}
                        </p>
                    }
                    <div className={cn(
                        "grid grid-flow-col-dense gap-8 px-4 py-4 overflow-hidden overflow-x-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {courseTemplateList?.data?.map((templateItem, idx) => (
                            <div key={idx} onClick={() => handleTemplate(templateItem)} className={cn(
                                "relative flex flex-col content-between justify-between rounded-lg shadow-lg bg-black w-44 aspect-video cursor-pointer",
                                "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                                templateItem?.id === courseTemplateDetail?.payload?.templateId ?
                                    "ring-offset-2 ring-4 ring-secondary-dark" :
                                    "ring-offset-2 ring-4 ring-white"
                            )}>
                                <img src={templateItem?.thumbnailPicUrl} alt="template-thumbnail"
                                    className="object-cover w-full h-full rounded-lg opacity-90"
                                />
                                <div className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                                    <span className={"bg-black bg-opacity-50 px-4 py-2 rounded-sm text-text-50 font-bodyPri font-normal text-base"}>
                                        {templateItem?.title}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={"flex flex-col items-start justify-start gap-2"}>
                        <div className={"flex items-center justify-start gap-5 m-5"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-sm whitespace-nowrap"}>
                                {"Tutor ID:"}
                            </span>
                            <input
                                type={"number"}
                                value={courseTemplateDetail?.payload?.tutorId?.toString()}
                                placeholder={"Enter Tutor ID"}
                                className={cn(
                                    "px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => {
                                    if (tutorDetail?.data) {
                                        dispatcher(resetTutorDetail())
                                    }
                                    dispatcher(setCourseTemplateDetailPayload({
                                        ...courseTemplateDetail?.payload,
                                        tutorId: Number(e.target.value)
                                    }))
                                }}
                            />

                            <span className={cn(
                                "w-fit px-3 py-0.5 border border-secondary-dark hover:bg-secondary-dark hover:text-text-50 rounded-lg",
                                "font-bodyPri font-normal text-secondary-dark text-base tracking-wide cursor-pointer",
                                tutorDetail?.isLoading && "bg-secondary-dark hover:bg-secondary-dark"
                            )}
                                onClick={onHandleValidateTutor}
                            >
                                {tutorDetail?.isLoading && <ButtonLoader isLoading={tutorDetail?.isLoading} />}
                                {!tutorDetail?.isLoading && "Validate"}
                            </span>
                        </div>
                        {(!tutorDetail?.isLoading && tutorDetail?.message) &&
                            <span className={"mx-5 flex items-center justify-start font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                {tutorDetail?.message}
                            </span>
                        }
                        {(!tutorDetail?.isLoading && tutorDetail?.data) &&
                            <div className={cn(
                                "border border-text-400 rounded-lg p-5 mx-5 flex items-start justify-start gap-x-5"
                            )}>
                                <div
                                    className={"relative w-20 h-20 overflow-hidden rounded-full cursor-pointer"}
                                    onClick={onHandleNavigateToUserDetail}
                                >
                                    <img
                                        src={tutorDetail?.data?.user?.profilePicUrl}
                                        alt={"tutor-profile-picture"}
                                        className={"w-full h-full object-cover"}
                                    />
                                </div>
                                <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                                    <div className={"flex items-center justify-start gap-2"}>
                                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                            {"User ID: "}
                                        </span>
                                        <div
                                            className={"font-bodyPri font-normal text-primary-dark text-base tracking-wide hover:underline cursor-pointer"}
                                            onClick={onHandleNavigateToUserDetail}
                                        >
                                            {tutorDetail?.data?.user?.id || "-"}
                                        </div>
                                    </div>
                                    <div className={"flex items-center justify-start gap-2"}>
                                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                            {"Name: "}
                                        </span>
                                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                            {`${tutorDetail?.data?.user?.firstName} ${tutorDetail?.data?.user?.lastName?.charAt(0)?.toUpperCase()}.` || "-"}
                                        </span>
                                    </div>
                                    <div className={"flex items-center justify-start gap-2"}>
                                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                            {"Elevator Pitch: "}
                                        </span>
                                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide line-clamp-1"}>
                                            {tutorDetail?.data?.elevatorPitch?.length > 0
                                                ? tutorDetail?.data?.elevatorPitch?.length > 80
                                                    ? tutorDetail?.data?.elevatorPitch?.slice(0, 80) + " ..."
                                                    : tutorDetail?.data?.elevatorPitch
                                                : "-"
                                            }
                                        </span>
                                    </div>
                                    <div className={"flex items-center justify-start gap-2"}>
                                        <span className={"font-bodyPri font-normal text-text-600 text-base tracking-wide"}>
                                            {"Visibility: "}
                                        </span>
                                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                            {`${visibilityType[tutorDetail?.data?.visibility?.toUpperCase()]?.label}` || "-"}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>
            {addCourseDetail?.errorMsg &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className='font-bodyPri font-normal text-red-500 text-sm'>
                        {addCourseDetail?.errorMsg}
                    </span>
                </div>
            }
            <div className={"flex justify-end px-5"}>
                <span onClick={onHandleSubmit} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full font-buttons font-normal text-base",
                    (courseTemplateDetail?.payload?.templateId && courseTemplateDetail?.payload?.tutorId && tutorDetail?.data) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                    (!courseTemplateDetail?.payload?.templateId || !courseTemplateDetail?.payload?.tutorId || !tutorDetail?.data || tutorDetail?.message) && "border border-text-300 text-text-300",
                )}>
                    {addCourseDetail?.isLoading && <ButtonLoader isLoading={addCourseDetail?.isLoading} />}
                    {!addCourseDetail?.isLoading && "Submit"}
                </span>
            </div>
        </div>
    )
}

export default AddCourseModal