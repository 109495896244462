import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { MdContentCopy } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetAddBookingDetail } from "redux/booking/booking.slice";

import { cn } from "utils/cn.utils";

import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

const BookingSummaryModal = () => {
    const { addBookingDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    const [isUrlCopied, setIsURLCopied] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState({
        emailAddress: "",
        emailSubject: "",
        emailContent: ""
    })

    useEffect(() => {
        return () => {
            dispatcher(resetAddBookingDetail())
        }
    }, [])

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${EDULYTE_APP_DOMAIN_URL}/booking-summary/${addBookingDetail?.data?.result?.id}`);
        setIsURLCopied(true);
        toast.success("Copied!")
        setTimeout(() => {
            setIsURLCopied(false);
        }, 2000);
    }

    const onHandleSubmit = () => {
        // dispatcher(resetAddBookingDetail())
    }

    return (

        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block w-full space-y-5"}>
                <div className={"w-full flex items-center justify-center gap-3"}>
                    <a
                        href={`${EDULYTE_APP_DOMAIN_URL}/booking-summary/${addBookingDetail?.data?.result?.id}`}
                        target={"_blank"}
                        className={"font-bodyPri font-normal text-primary-main text-base tracking-wide"}
                    >
                        {`${EDULYTE_APP_DOMAIN_URL}/booking-summary/${addBookingDetail?.data?.result?.id}`}
                    </a>
                    <div
                        className={"px-2 py-1.5 flex items-center justify-center bg-primary-dark cursor-pointer"}
                        onClick={handleCopyLink}
                        title={"Copy Link"}
                    >
                        {!isUrlCopied
                            ? <MdContentCopy className={"text-2xl text-text-50"} />
                            : <AiOutlineCheckCircle className={"text-2xl text-text-50"} />
                        }
                    </div>
                </div>

                {/* <div className="w-full space-y-3">
                    <span className={"w-full flex justify-center items-center font-bodyPri font-semibold text-lg text-text-800 text-center truncate tracking-wide"}>
                        {"Send Email"}
                    </span>
                    <div className={cn(
                        "w-full flex flex-col items-start justify-start p-5 pb-2 gap-3",
                        "border-2 border-primary-main rounded-lg focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main"
                    )}>
                        <div className="w-full space-y-1">
                            <div className="flex justify-start items-start gap-3">
                                <span className="font-bodyPri font-normal text-text-600 text-base">
                                    {"To"}
                                </span>
                                <input
                                    type={"text"}
                                    className={cn(
                                        "w-full outline-none",
                                        "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                                    )}
                                    autoFocus={true}
                                    onChange={(e) => setEmailTemplate({
                                        ...emailTemplate,
                                        emailAddress: e.target.value
                                    })}
                                    value={emailTemplate?.emailAddress}
                                />
                            </div>
                            <hr className="w-full border border-text-200" />
                        </div>
                        <div className="w-full space-y-1">
                            <div className="flex justify-start items-start gap-3">
                                <span className="font-bodyPri font-normal text-text-600 text-base">
                                    {"Subject"}
                                </span>
                                <input
                                    typeof={"text"}
                                    className={cn(
                                        "w-full outline-none",
                                        "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                                    )}
                                    onChange={(e) => setEmailTemplate({
                                        ...emailTemplate,
                                        emailSubject: e.target.value
                                    })}
                                    value={emailTemplate?.emailSubject}
                                />
                            </div>
                            <hr className="w-full border border-text-200" />
                        </div>
                        <textarea
                            className={cn(
                                "w-full resize-none",
                                "outline-none",
                                "font-bodyPri font-normal text-base text-text-800 tracking-wide",
                                "placeholder:text-text-500 text-base font-bodyPri font-normal tracking-wide"
                            )}
                            type={"text"}
                            placeholder={"Write your email ..."}
                            value={emailTemplate?.emailContent}
                            onChange={(event) => setEmailTemplate({
                                ...emailTemplate,
                                emailContent: event.target.value
                            })}
                            rows={8}
                            maxLength={700}
                        />
                    </div>
                </div> */}
            </div>

            <div className={"flex justify-end px-5"}>
                <span onClick={onHandleSubmit} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full font-buttons font-normal text-base",
                    (emailTemplate?.emailAddress && emailTemplate?.emailContent && emailTemplate?.emailSubject) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                    (!emailTemplate?.emailAddress || !emailTemplate?.emailContent || !emailTemplate?.emailSubject) && "border border-text-300 text-text-50 bg-text-300 cursor-not-allowed"
                )}>
                    {/* {addCourseDetail?.isLoading && <ButtonLoader isLoading={addCourseDetail?.isLoading} />} */}
                    {"Send Email"}
                </span>
            </div>
        </div>
    )
}

export default BookingSummaryModal;