import { useAppState } from 'hooks/useStore';

import { currencyRateConversion } from 'utils/generators.utils';

const OrderItemProfileSection = () => {
    const { locals } = useAppState((state) => state.local)
    const { orderDetail } = useAppState((state) => state.order)

    return (
        <div className={"w-full flex flex-col gap-3"}>
            <div className={"w-full flex justify-between items-center gap-5"}>
                <span className={"font-bodyPri tracking-wide text-text-800 truncate"}>
                    {"Order No :"} {orderDetail?.data?.order_no
                        ? (orderDetail?.data?.order_no?.length > 18)
                            ? orderDetail?.data?.order_no?.slice(0, 18) + " ..."
                            : orderDetail?.data?.order_no
                        : "-"
                    }
                </span>
                <span className={"font-bodyPri tracking-wide text-text-800 truncate"}>
                    {"Price : "} {(currencyRateConversion(locals?.currencyCode, orderDetail?.data?.total_amount))}
                </span>
            </div>

            {/* Profile */}
            {/* <div className={"flex flex-col items-center gap-3 sm:gap-5 sm:flex-row"}>
                <div className={"w-14 h-14 rounded-full overflow-hidden"}>
                    <img
                        src={orderDetail?.data?.user?.profile_pic_url}
                        alt={"profile"}
                        className={"w-full h-full object-cover"}
                    />
                </div>
                <div className={""}>
                    <span className={"text-lg font-semibold font-bodyPri tracking-wide text-text-900 capitalize"}>
                        {orderDetail?.data?.user?.first_name + " " + orderDetail?.data?.user?.last_name.charAt(0) + "."}
                    </span>
                    <span></span>
                </div>
            </div> */}
        </div>
    )
}

export default OrderItemProfileSection;