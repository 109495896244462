import { memo } from 'react';

import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { userRoles } from 'redux/auth/auth.const';
import { bookingRegularPriceModelEnum } from 'redux/booking/booking.const';

const CourseProfile = memo(({ bookingUserDetail }) => {

    return (
        <div className="px-2 rounded-lg shadow-sm">
            <div className={"flex justify-between items-center text-slate-500 text-sm md:text-base"}>
                <p className={"font-bodyPri tracking-wide text-text-800"}>
                    {"Booking ID :"} {bookingUserDetail?.id}
                </p>
                <p className={"font-bodyPri tracking-wide text-text-800"}>
                    {"Price : "} {(bookingUserDetail?.bookingPrice?.discountPct === 100
                        || bookingUserDetail?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FREE.value)
                        ? bookingRegularPriceModelEnum.FREE.label
                        : (`${masterCurrencyCodeEnum.USD.label} ${parseFloat(bookingUserDetail?.bookingPrice?.totalAmount / 100).toFixed(2)}`
                        )}
                </p>
            </div>
            <div className="flex flex-col items-center justify-between sm:flex-row">
                {/* Profile */}
                <div className="inline-flex flex-col items-center justify-center my-4 space-y-1 sm:flex-row">
                    <img
                        src={bookingUserDetail?.user?.profilePicUrl}
                        alt={"profile"}
                        className="object-cover rounded-full w-14 h-14"
                    />
                    <div className="pl-2">
                        <p className="text-lg font-semibold font-bodyPri tracking-wide text-text-900 capitalize">
                            {bookingUserDetail?.user?.firstName + " " + bookingUserDetail?.user?.lastName.charAt(0) + "."}
                        </p>
                        <p className="text-sm text-center text-opacity-40 md:text-left">
                            {userRoles?.STUDENT?.label}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default CourseProfile;