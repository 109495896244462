import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getUserEducationListByUserId } from 'redux/user/user.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearUserEducationList } from 'redux/user/user.slice';
import ComponentLoader from 'components/loader/ComponentLoader';

const ViewUserEducation = () => {
    const { userEducationList } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const { userId } = useParams()

    useEffect(() => {
        dispatcher(getUserEducationListByUserId(Number(userId)))

        return () => {
            dispatcher(setClearUserEducationList())
        }
    }, [])

    return (
        <div>
            <div className={"py-3 space-y-3"}>
                <div className="w-full flex item-start justify-center gap-1">
                    <span className="font-subHeading font-medium text-2xl text-text--800">
                        {"User Qualifications"}
                    </span>
                </div>
                <div className="h-0.5 bg-gray-300 w-full"></div>
            </div>
            <div className={"px-4 py-10"}>
                <ComponentLoader isLoading={userEducationList?.isLoading} />
                {(userEducationList?.errorMsg || !userEducationList?.userEducationList || userEducationList?.userEducationList?.length === 0) &&
                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        {userEducationList?.errorMsg || "No Qualifications Added"}
                    </span>
                }

                <div className={"flex flex-col space-y-3 flex-wrap justify-start items-start"}>
                    {userEducationList?.userEducationList?.length > 0
                        && userEducationList?.userEducationList?.map((education, idx) => (
                            <div className={"flex items-center gap-5"} key={idx}>
                                <span className="w-max px-4 py-1 border-2 border-text-300 rounded-xl text-text-900 font-medium font-bodyPri">
                                    {education?.education}
                                </span>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default ViewUserEducation