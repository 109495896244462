import { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import ComponentLoader from "components/loader/ComponentLoader";
import RecurringSchedule from "components/availabilitySchedule/RecurringSchedule";

import { setUserAvailabilityDetailPayload, validateAvailabilityDetail, validateUserAvailabilityDetail } from "pages/auth/editUser/data";
import AvailabilityHeader from "pages/auth/editUser/commonComponents/header/AvailabilityHeader";
import SaveAndResetButtons from "pages/auth/editUser/commonComponents/buttons/SaveAndResetButtons";

import BasicInformation from "./BasicInformation";
import NonRecurringSchedule from "./NonRecurringSchedule";
import StartEndDateSchedule from "./StartEndDateSchedule";
import MoreAvailabilityDetail from "./MoreAvailabilityDetail";

import { getAvailabilityDetail, updateAvailabilityDetail } from "redux/availability/availability.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetModifyAvailabilityDetail, resetModifyAvailabilityDetailPayload, resetAvailabilityDetail, setModifyAvailabilityDetailPayload } from "redux/availability/availability.slice";
import { availabilityIsRecurringEnum, modifyAvailabilityDetailPayload } from "redux/availability/availability.const";

import { timeZone, weekdays } from 'utils/dateTime.utils';

const AvailabilityDetailPage = () => {
    const { userDetail } = useAppState((state) => state.user)
    const { availabilityDetail, modifyAvailabilityDetail } = useAppState((s) => s.availability)

    const { availabilityId } = useParams()
    const dispatcher = useAppDispatcher()

    const isSimilarOrValidAvailability = useMemo(() => {
        if (availabilityDetail?.data?.result && modifyAvailabilityDetail?.payload) {
            const isDataSimilar = validateAvailabilityDetail(availabilityDetail?.data?.result, modifyAvailabilityDetail?.payload)
            return isDataSimilar;
        }
    }, [availabilityDetail?.data?.result, modifyAvailabilityDetail?.payload])

    useEffect(() => {
        return () => {
            dispatcher(resetAvailabilityDetail())
            dispatcher(resetModifyAvailabilityDetail())
            dispatcher(resetModifyAvailabilityDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (availabilityId) {
            const localTimeZone = modifyAvailabilityDetail?.payload?.timeZone || userDetail?.userDetail?.userCulture?.timeZone?.timeZone

            const requestPayload = {
                availabilityId: availabilityId,
                query: { timeZone: localTimeZone }
            }
            dispatcher(getAvailabilityDetail(requestPayload))
        }
    }, [availabilityId, userDetail?.userDetail?.userCulture])

    useEffect(() => {
        if (availabilityDetail?.data?.result) {
            setUserAvailabilityDetail()
        }
    }, [availabilityDetail?.data?.result])

    const setUserAvailabilityDetail = async () => {
        const localTimeZone = modifyAvailabilityDetail?.payload?.timeZone || userDetail?.userDetail?.userCulture?.timeZone?.timeZone || timeZone
        const requestPayload = await setUserAvailabilityDetailPayload(availabilityDetail?.data?.result, localTimeZone)

        dispatcher(setModifyAvailabilityDetailPayload({
            ...modifyAvailabilityDetailPayload,
            ...requestPayload
        }))
    }

    const saveWeeklyAvail = async (localWeeklyAvail) => {
        if (modifyAvailabilityDetail?.isLoading) return;

        dispatcher(setModifyAvailabilityDetailPayload({
            ...modifyAvailabilityDetail?.payload,
            weeklyTimeSlots: localWeeklyAvail
        }))
    }

    const onHandleResetAvailabilityDetail = useCallback(() => {
        if (modifyAvailabilityDetail?.isLoading) return;

        const requestPayload = {
            availabilityId: availabilityId,
            query: { timeZone: userDetail?.userDetail?.userCulture?.timeZone?.timeZone }
        }
        dispatcher(getAvailabilityDetail(requestPayload))

    }, [modifyAvailabilityDetail?.isLoading])

    const onHandleSaveAvailabilityDetail = async () => {
        if (modifyAvailabilityDetail?.isLoading || isSimilarOrValidAvailability) return;

        const { requestPayload, errorMsg } = await validateUserAvailabilityDetail(modifyAvailabilityDetail?.payload)

        if (errorMsg) {
            toast.warn(errorMsg)
            return;
        } if (requestPayload) {
            const requestDataPayload = {
                availabilityId: availabilityDetail?.data?.result?.id,
                body: { ...requestPayload }
            }
            dispatcher(updateAvailabilityDetail(requestDataPayload))
        }
    }

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <AvailabilityHeader headerText={"Update Availability"} />
            {availabilityDetail?.isLoading &&
                <ComponentLoader isLoading={availabilityDetail?.isLoading} />
            }
            {availabilityDetail?.message &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                        {availabilityDetail?.message}
                    </span>
                </div>
            }
            <AnimatePresence initial={availabilityDetail?.isLoading || modifyAvailabilityDetail?.payload}>
                {(!availabilityDetail?.isLoading && availabilityDetail?.data && modifyAvailabilityDetail?.payload?.timeZone) &&
                    <div className={"w-full flex flex-col mx-auto container max-w-6xl gap-5 sm:gap-10"}>
                        <BasicInformation />
                        <div className={"h-0.5 bg-divider-medium w-full"}></div>

                        <div className={"w-full flex flex-col gap-10"}>
                            {([availabilityIsRecurringEnum.RECURRING.value]?.includes(modifyAvailabilityDetail?.payload?.isRecurring) && modifyAvailabilityDetail?.payload?.weeklyTimeSlots) &&
                                <>
                                    {weekdays.map((day, index) => (
                                        <RecurringSchedule
                                            key={index}
                                            isLoading={modifyAvailabilityDetail?.isLoading}
                                            selectedTimeZone={modifyAvailabilityDetail?.payload?.timeZone}
                                            localDay={day}
                                            weeklyAvailability={modifyAvailabilityDetail?.payload?.weeklyTimeSlots}
                                            saveWeeklyAvail={saveWeeklyAvail}
                                        />
                                    ))}
                                    <div className={"h-0.5 bg-divider-medium w-full"}></div>

                                    <StartEndDateSchedule />
                                </>
                            }
                            {[availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(modifyAvailabilityDetail?.payload?.isRecurring) &&
                                <NonRecurringSchedule />
                            }

                            <div className={"h-0.5 bg-divider-medium w-full"}></div>
                            <MoreAvailabilityDetail />
                        </div>
                        {(!availabilityDetail?.isLoading && availabilityDetail?.message) &&
                            <div className={"flex items-center justify-center"}>
                                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                    {availabilityDetail?.message || "No Availability Found!"}
                                </span>
                            </div>
                        }
                        <SaveAndResetButtons
                            isSaveBtnLoading={modifyAvailabilityDetail?.isLoading}
                            isResetBtnLoading={availabilityDetail?.isLoading}
                            saveBtnDisabled={isSimilarOrValidAvailability}
                            resetBtnDisabled={modifyAvailabilityDetail?.isLoading || availabilityDetail?.isLoading}
                            onHandleSave={onHandleSaveAvailabilityDetail}
                            onHandleReset={onHandleResetAvailabilityDetail}
                        />
                    </div>
                }
            </AnimatePresence>
        </div>
    );
}

export default AvailabilityDetailPage;