import { FaEye } from "react-icons/fa";

import ViewUserInformation from "pages/auth/viewUser/components/ViewUserInformation";
import ViewUserAddress from "pages/auth/viewUser/components/ViewUserAddress";
import ViewUserCulture from "pages/auth/viewUser/components/ViewUserCulture";
import ViewUserEducation from "pages/auth/viewUser/components/ViewUserEducation";
import ViewUserSocial from "pages/auth/viewUser/components/viewUserSocial/index";
import ViewUserBadges from "pages/auth/viewUser/components/ViewUserBadges";
import AvailabilityDetailPage from "pages/auth/viewUser/components/availability/detail";
import ViewUserAvailabilityListPage from "pages/auth/viewUser/components/availability/list";
// import ViewUserWallet from "pages/auth/viewUser/components/viewUserWallet/ViewUserWallet";
import ViewUserOtherPref from "pages/auth/viewUser/components/ViewUserOtherPref";

import { userRoles } from "redux/auth/auth.const";
import { DEFAULT_AVAILABILITY_LIST_PAGE, DEFAULT_AVAILABILITY_LIST_RECORDS, availabilityEndTypeEnum, availabilityIsRecurringEnum } from "redux/availability/availability.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

export const viewUserHeaderConst = {
    IMAGE: {
        label: "Image",
        value: "image"
    },
    USER_ID: {
        label: "User ID",
        value: "user_id"
    },
    NAME: {
        label: "Name",
        value: "name"
    },
    EMAIL: {
        label: "Email",
        value: "email"
    },
    PHONE: {
        label: "Phone",
        value: "phone"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

const otherPrefConst = {
    contentCreation: {
        label: "Count me in for content creation work",
        description: "You will create your own content."
    },
    paidInfluencer: {
        label: "Count me in for paid influencer work",
        description: "We will get in touch for any influencer work."
    },
    personalisedReco: {
        label: "Personal recommendation",
        description: "Allow personal recommendation."
    }
}

export const setGetOtherPref = (otherPref) => (
    Object.keys(otherPref).map((other) => ({
        key: other,
        label: otherPrefConst[other].label,
        description: otherPrefConst[other].description,
        value: otherPref[other]
    }))
)


export const userSocialMediaLinksConst = {
    FACEBOOK: {
        label: "Facebook",
        value: "facebook",
        placeholder: "https://www.facebook.com/your_facebook_name",
        demoKey1: "https://www.facebook.com/your_facebook_name",
        demoKey2: "",
        socialLinkUrl: "facebook.com/"
    },
    INSTAGRAM: {
        label: "Instagram",
        value: "instagram",
        placeholder: "https://www.instagram.com/your_instagram_name",
        demoKey1: "https://www.instagram.com/your_instagram_name",
        demoKey2: "or @your_instagram_name",
        socialLinkUrl: "instagram.com/"
    },
    TWITTER: {
        label: "Twitter",
        value: "twitter",
        placeholder: "https://www.twitter.com/your_twitter_name",
        demoKey1: "https://www.twitter.com/your_twitter_name",
        demoKey2: "@Your_Twitter_Name",
        socialLinkUrl: "twitter.com/"
    },
    LINKEDIN: {
        label: "Linkedin",
        value: "linkedin",
        placeholder: "https://www.linkedin.com/yourlinkedinname",
        demoKey1: "https://www.linkedin.com/yourlinkedinname",
        demoKey2: "",
        socialLinkUrl: "linkedin.com/"
    }
}


// new
export const weeklyScheduleToolTip = "Add or update your general weekly schedule for seven days by the hour.Use plus sign to add hours for any given day.Click on pencil icon to modify time table/hours on any day.Click on red cross icon to delete hours.Please don't forget to save after making changes."

export const availabilityIconButtons = {
    VIEW: {
        value: "view",
        tooltip: "View",
        icon: <FaEye className={"text-xl text-text-700 hover:text-primary-dark cursor-pointer"} />
    }
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    id: {
        key: "id"
    },
    name: {
        key: "name"
    },
    isRecurring: {
        key: "isRecurring"
    },
    date: {
        key: "date"
    },
    minCreatedAt: {
        key: "minCreatedAt"
    },
    maxCreatedAt: {
        key: "maxCreatedAt"
    },
    timeZone: {
        key: "timeZone"
    }
}

export const availabilityFilters = {
    [searchParamsInfo.id.key]: {
        key: "id",
        label: "ID"
    },
    [searchParamsInfo.name.key]: {
        key: "name",
        label: "Name"
    },
    [searchParamsInfo.isRecurring.key]: {
        key: "isRecurring",
        label: "Recurring"
    },
    [searchParamsInfo.date.key]: {
        key: "date",
        label: "Created On"
    },
    [searchParamsInfo.timeZone.key]: {
        key: "timeZone",
        label: "Time Zone"
    },
}

export const getAvailabilityListPayload = async (filterPayload) => {

    let requestDataPayload = {
        page: filterPayload?.page || DEFAULT_AVAILABILITY_LIST_PAGE,
        records: DEFAULT_AVAILABILITY_LIST_RECORDS,
        timeZone: timeZone
    }

    if (filterPayload?.id) {
        requestDataPayload[searchParamsInfo.id.key] = Number(filterPayload?.id)
    }
    if (filterPayload?.name) {
        requestDataPayload[searchParamsInfo.name.key] = filterPayload?.name.replaceAll("-", " ")
    }
    if (filterPayload?.isRecurring) {
        requestDataPayload[searchParamsInfo.isRecurring.key] = filterPayload?.isRecurring.replaceAll("-", "_")
    }
    if (filterPayload?.minCreatedAt) {
        requestDataPayload[searchParamsInfo.minCreatedAt.key] = dayjs(filterPayload?.minCreatedAt).tz(timeZone).format("YYYY-MM-DD")
    }
    if (filterPayload?.maxCreatedAt) {
        requestDataPayload[searchParamsInfo.maxCreatedAt.key] = dayjs(filterPayload?.maxCreatedAt).tz(timeZone).format("YYYY-MM-DD")
    }

    return requestDataPayload;
}

export const setUserAvailabilityDetailPayload = async (availabilityData) => {
    let requestPayload = {}

    if (availabilityData?.user?.userId) {
        requestPayload["userId"] = availabilityData?.user?.userId
    }
    if (availabilityData?.name) {
        requestPayload["name"] = availabilityData?.name
    }
    if (availabilityData?.description) {
        requestPayload["description"] = availabilityData?.description
    }
    if (availabilityData?.minTimeBeforeAvailStart) {
        requestPayload["minTimeBeforeAvailStart"] = availabilityData?.minTimeBeforeAvailStart
    }
    if (availabilityData?.advanceSlotPeriodDays) {
        requestPayload["advanceSlotPeriodDays"] = availabilityData?.advanceSlotPeriodDays
    }
    if (availabilityData?.type) {
        requestPayload["type"] = availabilityData?.type
    }
    if (availabilityData?.isRecurring) {
        requestPayload["isRecurring"] = availabilityData?.isRecurring
    }
    if ([availabilityIsRecurringEnum.NON_RECURRING.value]?.includes(availabilityData?.isRecurring)) {
        requestPayload["dateTimeSlots"] = availabilityData?.dateTimeSlots
        requestPayload["endType"] = null
        requestPayload["startDateTime"] = null
        requestPayload["endDateTime"] = null
        requestPayload["weeklyCycle"] = null
    }
    if ([availabilityIsRecurringEnum.RECURRING.value]?.includes(availabilityData?.isRecurring)) {
        if (availabilityData?.weeklyTimeSlots) {
            requestPayload["weeklyTimeSlots"] = availabilityData?.weeklyTimeSlots
        }
        if (availabilityData?.startDateTime) {
            requestPayload["startDateTime"] = availabilityData?.startDateTime || dayjs()?.tz(timeZone)?.format("YYYY-MM-DD HH:mm:ss")
        }
        if (availabilityData?.endType) {
            requestPayload["endType"] = availabilityData?.endType || availabilityEndTypeEnum.FOREVER.value
        }
        if ([availabilityEndTypeEnum.ON_DATE.value]?.includes(availabilityData?.endType)) {
            requestPayload["endDateTime"] = availabilityData?.endDateTime || null
        }
        if ([availabilityEndTypeEnum.WEEKLY_CYCLE.value]?.includes(availabilityData?.endType)) {
            requestPayload["weeklyCycle"] = availabilityData?.weeklyCycle || null
        }
    }
    return requestPayload;
}

export const viewUserNavigationOptions = {
    GENERAL: {
        label: "General",
        value: "general",
        pagePath: "/user-details",
        routePath: "user-details",
        component: ViewUserInformation,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    ADDRESS: {
        label: "Address",
        value: "address",
        pagePath: "/user-address",
        routePath: "user-address",
        component: ViewUserAddress,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    CULTURE: {
        label: "Culture",
        value: "culture",
        pagePath: "/user-culture",
        routePath: "user-culture",
        component: ViewUserCulture,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    EDUCATION: {
        label: "Education",
        value: "education",
        pagePath: "/user-education",
        routePath: "user-education",
        component: ViewUserEducation,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    SOCIAL: {
        label: "Social",
        value: "social",
        pagePath: "/user-social",
        routePath: "user-social",
        component: ViewUserSocial,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    BADGES: {
        label: "Badges",
        value: "badges",
        pagePath: "/user-badges",
        routePath: "user-badges",
        component: ViewUserBadges,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    AVAILABILITY_LIST: {
        label: "Availability",
        value: "availability",
        pagePath: "/user-availability",
        routePath: "user-availability",
        component: ViewUserAvailabilityListPage,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
    AVAILABILITY_DETAIL: {
        label: "Availability",
        value: "availability",
        pagePath: "/user-availability",
        routePath: "user-availability/:availabilityId/view",
        component: AvailabilityDetailPage,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: false
    },
    // WALLET: {
    //     label: "Wallet",
    //     value: "wallet",
    //     pagePath: "/wallet",
    //     routePath: "wallet",
    //     component: ViewUserWallet,
    //     roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
    //     isShowNavigation: true
    // },
    OTHER_PREFERENCES: {
        label: "Other Preferences",
        value: "other-preferences",
        pagePath: "/other-preferences",
        routePath: "other-preferences",
        component: ViewUserOtherPref,
        roles: [userRoles.ADMIN.value, userRoles.SUPER_ADMIN.value],
        isShowNavigation: true
    },
}