import OtherService from "redux/other/other.service";

import {
    setUnsplashThumbnailListLoading,
    setUnsplashThumbnailList,
    setUnsplashThumbnailListErrorMsg
} from "redux/other/other.slice";

export const getUnsplashThumbnailList = () => async (dispatch, getState) => {
    dispatch(setUnsplashThumbnailListLoading(true))

    try {
        const { thumbnailDetail } = getState().other
        const requestData = {
            query: thumbnailDetail?.thumbnailDetailPayload?.query || "Education",
            orientation: thumbnailDetail?.thumbnailDetailPayload?.orientation,
            page: thumbnailDetail?.thumbnailDetailPayload?.page,
            perPage: thumbnailDetail?.thumbnailDetailPayload?.perPage,
            orderBy: thumbnailDetail?.thumbnailDetailPayload?.orderby || "relevant"
        }
        const response = await OtherService.getUnsplashThumbnailList(requestData)
        if (response.status === 200) {
            dispatch(setUnsplashThumbnailList(response.response))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUnsplashThumbnailListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setUnsplashThumbnailListLoading(false))
    }
}