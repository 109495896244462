import { useAppState } from "hooks/useStore"

const Comment = () => {
    const { withdrawalDetail } = useAppState((state) => state.withdrawal)

    return (
        <div className={"pt-5 space-y-3"}>
            <div className={"w-full font-bodyPri font-medium text-text-900 text-lg"}>
                {"Comments:"}
            </div>
            <div className={"font-bodyPri font-normal text-text-600 text-base"}>
                {withdrawalDetail?.withdrawalDetail?.comments}
            </div>
        </div>
    )
}

export default Comment