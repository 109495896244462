import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/chargeBee/chargeBee.const";

class ChargeBee {
    getUserItemListByUserId = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.USER_ITEM_LIST,
            { params: query }
        )

        return response;
    }

    getUserItemDetailByUserItemId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.USER_ITEM_DETAIL, params)
        )

        return response;
    }

    createUserItemDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.USER_ITEM_LIST,
            body
        )

        return response;
    }

    updateUserItemDetailByUserItemId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_ITEM_DETAIL, params),
            body
        )

        return response;
    }

    deleteUserItemDetailByUserItemId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.USER_ITEM_DETAIL, params)
        )

        return response;
    }

    getItemPriceList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ITEM_PRICE_LIST),
            { params: query }
        )

        return response;
    }

    getItemPriceDetailByItemPriceId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.ITEM_PRICE_DETAIL, params)
        )

        return response;
    }

    createItemPriceDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ITEM_PRICE_LIST),
            body
        )

        return response;
    }

    updateItemPriceDetailByItemPriceId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ITEM_PRICE_DETAIL, params),
            body
        )

        return response;
    }

    deleteItemPriceDetailByItemPriceId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.ITEM_PRICE_DETAIL, params)
        )

        return response;
    }
}

export default new ChargeBee();