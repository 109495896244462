import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import EmployeeCard from "components/employeeCard/EmployeeCard";

import { getEmployeeListByCompanyId } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";

const itemsPerPage = 4

const People = () => {
    const { employeeList } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { companyId } = useParams()

    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        if (companyId) {
            dispatcher(getEmployeeListByCompanyId({ activePage: activePage, itemsPerPage: itemsPerPage }, Number(companyId)))
        }
    }, [activePage])

    return (
        <div className={"flex items-center justify-center"} id={"PEOPLE"}>
            <div className={"relative max-w-full px-5 md:max-w-3xl md:px-0 lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                        {"People"}
                    </span>
                    <div className={cn(
                        "w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 p-5"
                    )}>
                        {employeeList?.employeeList?.list?.length > 0 &&
                            employeeList?.employeeList?.list?.map((employee, index) => (
                                <div className={"col-span-1 flex items-center justify-center"}>
                                    <EmployeeCard
                                        key={index}
                                        employee={employee}
                                    />
                                </div>
                            ))}
                    </div>
                    {(employeeList?.employeeList?.list?.length > 0 && employeeList?.employeeList?.totalPages !== activePage) &&
                        <div
                            className={cn(
                                "w-full flex justify-center items-center border border-text-500 p-2 rounded",
                                "font-bodyPri font-medium text-text-800 text-base cursor-pointer",
                                "hover:bg-background-medium"
                            )}
                            onClick={() => setActivePage(activePage + 1)}
                        >
                            {"Show more"}
                        </div>
                    }
                    {employeeList?.employeeList?.list?.length === 0 &&
                        <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                            {"No People added yet!"}
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}

export default People;