import { useAppState } from 'hooks/useStore';
import { masterCurrencyCodeEnum } from "redux/master/master.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const TransactionDetail = () => {
  const { withdrawalDetail } = useAppState((state) => state.withdrawal)

  return (
    <div className={"pt-5 space-y-3"}>
      <div className={"w-full font-bodyPri font-medium text-text-900 text-lg"}>
        {"Transaction Details:"}
      </div>
      <div className={"w-full flex flex-col items-start justify-start gap-3"}>
        <div className={"flex justify-start items-start gap-2"}>
          <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
            {"Approved Amount:"}
          </span>
          <span
            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
            {masterCurrencyCodeEnum[withdrawalDetail?.withdrawalDetail?.masterCurrency?.code?.toUpperCase()]?.label + " "  + (withdrawalDetail?.withdrawalDetail?.transactionDetails?.approvedAmount / 100).toFixed(2)}
          </span>
        </div>
        <div className={"flex justify-start items-start gap-2"}>
          <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
            {"Processing Fee:"}
          </span>
          <span
            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
            {masterCurrencyCodeEnum[withdrawalDetail?.withdrawalDetail?.masterCurrency?.code?.toUpperCase()]?.label + " "  + (withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.processingFee?.toFixed(2))}
          </span>
        </div>
        <div className={"flex justify-start items-start gap-2"}>
          <span className={"font-bodyPri font-normal text-text-600 text-base"}>
            {"Gateway Name:"}
          </span>
          <span
            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
            {withdrawalDetail?.withdrawalDetail?.transactionDetails?.gatewayName}
          </span>
        </div>
        <div className={"flex justify-start items-start gap-2"}>
          <span className={"font-bodyPri font-normal text-text-600 text-base"}>
            {"Gateway Reference ID:"}
          </span>
          <span
            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
            {withdrawalDetail?.withdrawalDetail?.transactionDetails?.gatewayPaymentId}
          </span>
        </div>
        <div className={"flex justify-start items-start gap-2"}>
          <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
            {"Virtual Payment ID:"}
          </span>
          <span
            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
            {withdrawalDetail?.withdrawalDetail?.transactionDetails?.virtualTxnId}
          </span>
        </div>
        <div className={"flex justify-start items-start gap-2"}>
          <span className={"font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
            {"Txn Date:"}
          </span>
          <span
            className={"font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
            {dayjs(withdrawalDetail?.withdrawalDetail?.transactionDetails?.transactionAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default TransactionDetail