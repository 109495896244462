import { dayjs } from 'utils/dateTime.utils';

import {
    SearchSessionId,
    SearchSessionName,
    SearchSessionStartDateTime,
    SearchSessionEndDateTime,
    FilterSessionType,
    FilterStatus,
    SearchDate,
    ButtonAction,
    SearchUpdatedDate
} from "pages/auth/sessions/commonComponents/SessionsFilterStatistics";

import { sessionUserTypeEnum } from 'redux/session/session.const';

export const pageHeading = {
    heading: "Sessions",
    subHeading: "Find the list of sessions!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const sessionHeaderConst = {
    SESSION_ID: {
        label: "Session ID",
        value: "session_id"
    },
    SESSION_NAME: {
        label: "Session Name",
        value: "session_name"
    },
    SESSION_START_DATE: {
        label: "Session Start Date",
        value: "session_start_date"
    },
    SESSION_END_DATE: {
        label: "Session End Date",
        value: "session_end_date"
    },
    SESSION_TYPE: {
        label: "Session Type",
        value: "session_type"
    },
    STATUS: {
        label: "Status",
        value: "status"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    },
    MODIFIED_DATE: {
        label: "Modified Date",
        value: "modified_date"
    }
}

export const subRowHeader = [
    <SearchSessionId />,
    <SearchSessionName />,
    <SearchSessionStartDateTime />,
    <SearchSessionEndDateTime />,
    <FilterSessionType />,
    <FilterStatus />,
    <SearchDate />,
    <ButtonAction />,
    <SearchUpdatedDate />
]

export const searchParamsInfo = {
    sessionId: {
        key: "sessionId"
    },
    mode: {
        key: "mode"
    }
}

export const sessionModeConst = {
    view: {
        key: "view"
    },
    edit: {
        key: "edit"
    }
}

export const sessionsMenuData ={
    LOGIN_AS: {
        label: "Login As",
        value: "login_as"
    }
}

export const sortSessionLowToHigh = (activeSortHeader, session1, session2) => {
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_ID?.value) {
        return (Number(session1?.id) - Number(session2?.id))
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_NAME?.value) {
        return session1?.name?.toLowerCase()?.localeCompare(session2?.name?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_START_DATE?.value) {
        return dayjs(session2?.startDateTime).isSameOrBefore(session1?.startDateTime) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_END_DATE?.value) {
        return dayjs(session2?.endDateTime).isSameOrBefore(session1?.endDateTime) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_TYPE?.value) {
        return session1?.type?.toLowerCase()?.localeCompare(session2?.type?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.STATUS?.value) {
        return session1?.status?.toLowerCase()?.localeCompare(session2?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.DATE?.value) {
        return dayjs(session2?.createdAt).isSameOrBefore(session1?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(session2?.updatedAt).isSameOrBefore(session1?.updatedAt) ? 1 : -1
    }
}

export const sortSessionHighToLow = (activeSortHeader, session1, session2) => {
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_ID?.value) {
        return (Number(session2?.id) - Number(session1?.id))
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_NAME?.value) {
        return session2?.name?.toLowerCase()?.localeCompare(session1?.name?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_START_DATE?.value) {
        return dayjs(session1?.startDateTime).isSameOrBefore(session2?.startDateTime) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_END_DATE?.value) {
        return dayjs(session1?.endDateTime).isSameOrBefore(session2?.endDateTime) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.SESSION_TYPE?.value) {
        return session2?.type?.toLowerCase()?.localeCompare(session1?.type?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.STATUS?.value) {
        return session2?.status?.toLowerCase()?.localeCompare(session1?.status?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.DATE?.value) {
        return dayjs(session1?.createdAt).isSameOrBefore(session2?.createdAt) ? 1 : -1
    }
    if (activeSortHeader?.activeSortKey === sessionHeaderConst?.MODIFIED_DATE?.value) {
        return dayjs(session1?.updatedAt).isSameOrBefore(session2?.updatedAt) ? 1 : -1
    }
}


export const sessionLogConst = {
    NOT_MAPPED: {
        title: "Not Mapped",
    },
    PENDING: {
        title: "Pending",
    },
    SCHEDULED: {
        title: "Schedule",
    },
    ON_GOING: {
        title: "On Going",
    },
    COMPLETED: {
        title: "Completed",
    },
    DECLINED: {
        title: "Declined",
    },
    RESCHEDULED: {
        title: "Rescheduled",
    },
    CANCELLED: {
        title: "Cancelled",
    },
}

export const statusToolTip = {
    PENDING: "confirmation pending from moderator / organisor.",
    SCHEDULED: "confirmed appointment",
    ON_GOING: "Session is occuring now",
    CANCELLED: "Session has been cancelled",
    COMPLETED: "marked as completed",
    DECLINED: "meeting request declined"
}

export const sortSessionUserList = (user1, user2) => {
    const priority = {
        [sessionUserTypeEnum.MODERATOR.value]: 1,
        [sessionUserTypeEnum.PARTICIPANT.value]: 2
    };
    
    const userType1 = user1?.userType || sessionUserTypeEnum.PARTICIPANT.value;
    const userType2 = user2?.userType || sessionUserTypeEnum.PARTICIPANT.value;
    
    if (priority[userType1] < priority[userType2]) {
        return -1;
    } else if (priority[userType1] > priority[userType2]) {
        return 1;
    }
    return 0;
};