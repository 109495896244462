import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import FullPageLoader from "components/loader/FullPageLoader";

import { videoCallTableHeaders } from "pages/auth/viewTutorOnboard/data";

import { getOnboardingInterviewDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingInterviewDetail } from "redux/onboarding/onboarding.slice";
import { interviewCallStatus } from "redux/onboarding/onboarding.const";
import { eventStatus } from "redux/event/event.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const AttendVideoCall = () => {
    const { onboardingInterviewDetail, addOnboardingInterviewDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    useEffect(() => {
        if (onboardingId) {
            const requestData = {
                onboardingId: Number(onboardingId)
            }
            dispatcher(getOnboardingInterviewDetailByOnboardingId(requestData))
        }

        return () => {
            dispatcher(setClearOnboardingInterviewDetail())
        }
    }, [onboardingId])

    if (onboardingInterviewDetail?.isLoading || addOnboardingInterviewDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingInterviewDetail?.isLoading || addOnboardingInterviewDetail?.isLoading} />
        )
    }

    return (
        <div className="space-y-5">
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-5 py-3",
                !onboardingInterviewDetail?.onboardingInterviewDetail?.status && "bg-yellow-100",
                `bg-${interviewCallStatus[onboardingInterviewDetail?.onboardingInterviewDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Attend Video Call"}
            </div>
            {onboardingInterviewDetail?.onboardingInterviewDetail?.status &&
                <div className="px-5 flex flex-col items-start justify-start text-base font-bodyPri tracking-wide gap-3 md:flex-row md:items-center md:justify-start">
                    <span className="text-text-600">
                        {"Status:"}
                    </span>
                    <span className={cn(
                        "font-bodyPri font-normal text-base",
                        `text-${interviewCallStatus[onboardingInterviewDetail?.onboardingInterviewDetail?.status?.toUpperCase()]?.darkColor}`
                    )}>
                        {interviewCallStatus[onboardingInterviewDetail?.onboardingInterviewDetail?.status?.toUpperCase()]?.label}
                    </span>
                </div>
            }

            {onboardingInterviewDetail?.onboardingInterviewDetail?.interviews?.length > 0 &&
                <div className={cn(
                    "min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow overflow-x-auto overflow-y-hidden",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>
                    <table className={"w-full border-separate rounded-lg"}>
                        <thead>
                            <tr>
                                {videoCallTableHeaders?.map((head, index) => (
                                    <th
                                        key={index}
                                        className={cn(
                                            "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                            "font-bold whitespace-nowrap border border-gray-200"
                                        )}
                                    >
                                        {head}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="">
                            {onboardingInterviewDetail?.onboardingInterviewDetail?.interviews?.map((interview, index) => (
                                <tr
                                    className={''}
                                    key={index}>
                                    <td className="border border-gray-200">
                                        <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate capitalize'>
                                            {interview?.recruiter?.firstName + " " + interview?.recruiter?.lastName.charAt(0)}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className='px-5 py-2 font-bodyPri font-normal text-text-900 text-base text-center truncate'>
                                            {interview?.event ? dayjs(interview?.event?.startDateTime)?.tz(timeZone)?.format("ddd, DD MMM YYYY") : "-"}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className='px-5 py-2 font-bodyPri font-normal text-text-900 text-base text-center truncate'>
                                            {interview?.event ? dayjs(interview?.event?.startDateTime)?.tz(timeZone).format("HH:MM A") : "-"}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className={`px-5 py-2 font-bodyPri font-normal text-base text-${eventStatus[interview?.event?.status?.toUpperCase()]?.darkColor}`}>
                                            {interview?.event?.status ? eventStatus[interview?.event?.status?.toUpperCase()]?.label : "-"}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {(onboardingInterviewDetail?.errorMsg || addOnboardingInterviewDetail?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingInterviewDetail?.errorMsg || addOnboardingInterviewDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default AttendVideoCall;