import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FaEye } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

import SideOverPanel from "components/common-components/SideOverPanel";
import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import ViewSessionSidePanelDetail from "pages/auth/sessions/viewDetail";
import EditSessionSidePanelDetail from "pages/auth/sessions/editDetail";
import { sessionHeaderConst, subRowHeader, sortSessionLowToHigh, sortSessionHighToLow, searchParamsInfo, sessionModeConst } from "pages/auth/sessions/data";

import { getSessionList } from "redux/session/session.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetSessionList } from "redux/session/session.slice";
import { sessionTypeEnum, sessionStatusEnum } from "redux/session/session.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const DisplaySessions = () => {
  const { sessionList, modifyStartSessionDetail, filterProps } = useAppState((state) => state.session)

  const dispatcher = useAppDispatcher()
  const location = useLocation()
  const navigate = useNavigate()

  const [viewSideOverPanel, setViewSideOverPanel] = useState(false)
  const [editSideOverPanel, setEditSideOverPanel] = useState(false)
  const [activeSortHeader, setActiveSortHeader] = useState({
    activeSortKey: null,
    sortType: null
  })

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()))

  useEffect(() => {
    if (filterProps?.records || modifyStartSessionDetail?.data?.result) {
      dispatcher(getSessionList({ page: filterProps?.page || "1", records: filterProps?.records }))
    }

    return () => {
      dispatcher(resetSessionList())
    }
  }, [filterProps?.records, modifyStartSessionDetail?.data?.result])

  useEffect(() => {
    if (!!searchQueryParams.get(searchParamsInfo?.sessionId?.key)) {
      if (!!searchQueryParams.get(searchParamsInfo?.mode?.key)) {
        if ([sessionModeConst?.edit?.key]?.includes(searchQueryParams.get(searchParamsInfo?.mode?.key))) {
          setEditSideOverPanel(true)
        } else {
          setViewSideOverPanel(true)
        }
      } else {
        setViewSideOverPanel(true)
        searchQueryParams.set(searchParamsInfo?.mode?.key, sessionModeConst?.view?.key)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
      }
    }
  }, [searchQueryParams.get(searchParamsInfo?.sessionId?.key)])

  const sortHeader = (activeSort, upDownArrow) => {
    setActiveSortHeader({
      activeSortKey: activeSort,
      sortType: upDownArrow
    })
  }

  const sortSessionList = (session1, session2) => {
    if (activeSortHeader?.sortType?.upArrow) {
      return sortSessionLowToHigh(activeSortHeader, session1, session2)
    }
    if (activeSortHeader?.sortType?.downArrow) {
      return sortSessionHighToLow(activeSortHeader, session1, session2)
    }
  }

  const onHandleOpenSidePanel = (key, value) => {
    if (key === sessionModeConst?.edit?.key) {
      setEditSideOverPanel(true)
      searchQueryParams.set(searchParamsInfo?.sessionId?.key, value)
      searchQueryParams.set(searchParamsInfo?.mode?.key, sessionModeConst?.edit?.key)
    } else {
      setViewSideOverPanel(true)
      searchQueryParams.set(searchParamsInfo?.sessionId?.key, value)
      searchQueryParams.set(searchParamsInfo?.mode?.key, sessionModeConst?.view?.key)
    }
    navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
  }

  const onHandleCloseSidePanel = (key, value) => {
    if (key === sessionModeConst?.edit?.key) {
      setEditSideOverPanel(value)
    } else {
      setViewSideOverPanel(value)
    }
    searchQueryParams.delete(searchParamsInfo?.sessionId?.key)
    searchQueryParams.delete(searchParamsInfo?.mode?.key)
    navigate(location.pathname)
  }

  const rows = useMemo(() => {
    if (!!sessionList?.data?.result) {
      return sessionList?.data?.result?.slice(0)?.sort(sortSessionList)?.map((session, index) => [
        session?.id || "-",
        session?.name
          ? (session?.name?.length > 24)
            ? session?.name?.slice(0, 24) + " ..."
            : session?.name
          : "-",
        dayjs(session?.startDateTime)?.tz(timeZone)?.format("DD MMM, YYYY"),
        dayjs(session?.endDateTime)?.tz(timeZone)?.format("DD MMM, YYYY"),
        sessionTypeEnum[session?.type?.toUpperCase()]?.label || "-",
        sessionStatusEnum[session?.status?.toUpperCase()]?.label || "-",
        dayjs(session?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        <div
          key={index}
          className={"flex justify-start items-center"}
        >
          <button
            className={"px-2 py-1 bg-primary-main text-white rounded-l-md"}
            onClick={() => onHandleOpenSidePanel(sessionModeConst?.view?.key, session?.id)}
          >
            <FaEye className="text-xl hover:scale-110" />
          </button>
          <button
            className={"px-2 py-1 bg-background-lightDark text-white rounded-r-md"}
            onClick={() => onHandleOpenSidePanel("edit", session?.id)}
          >
            <FiEdit className="text-xl hover:scale-110" />
          </button>
        </div>,
        dayjs(session?.updatedAt).tz(timeZone).format('DD MMM, YYYY'),
      ])
    } else return [];
  }, [sessionList?.data?.result])

  return (
    <div className={""}>
      {viewSideOverPanel &&
        <SideOverPanel
          child={<ViewSessionSidePanelDetail />}
          isOpen={viewSideOverPanel}
          onClose={(isShow) => onHandleCloseSidePanel(sessionModeConst?.view?.key, isShow)}
        />
      }
      {editSideOverPanel &&
        <SideOverPanel
          child={<EditSessionSidePanelDetail />}
          isOpen={editSideOverPanel}
        onClose={(isShow) => onHandleCloseSidePanel(sessionModeConst?.edit?.key, isShow)}
        />
      }

      <Table
        headers={Object.values(sessionHeaderConst)}
        subRowHeader={subRowHeader}
        rows={rows}
        rowHeaderColor={"bg-background-medium"}
        alternateRowColor={"bg-primary-light"}
        sortHeader={sortHeader}
        isLoading={sessionList?.isLoading}
        message={(sessionList?.message || (sessionList?.data?.pagination?.records === 0)) && (sessionList?.message || "No sessions found")}
      />
      {!sessionList?.isLoading && (sessionList?.data?.result && (sessionList?.data?.pagination?.totalPages > 1)) &&
        <div className={"w-full flex justify-center items-center py-3"}>
          <Pagination
            page={sessionList?.data?.pagination?.page}
            totalPages={sessionList?.data?.pagination?.totalPages}
            onChangePage={(page) => dispatcher(getSessionList({ page: page, records: filterProps?.records }))}
          />
        </div>
      }
    </div>
  )
}

export default DisplaySessions;