import { RightNavLinksContainer,QuestionIcon} from './NavBarStyle';
import RightLoggedInNavLinks from './RightLoggedInNavLinks';

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

const RightNavLinks = ({ isScrolled }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;
    const { session } = useAppState((state) => state.auth);

    const onHandleLogIn = () => {
        window.open(`${EDULYTE_APP_DOMAIN_URL}/log-in?app_name=edulyte-admin&redirect_url=${window.location.href}`, "_self")
    }

    if (session?.isLoggedIn) {
        return (
            <RightNavLinksContainer>
                <RightLoggedInNavLinks isScrolled={isScrolled} />
            </RightNavLinksContainer>
        )
    }

    return (
        <RightNavLinksContainer>
            {(pageName === pagesInfo.HOME.pageName) &&
                <span
                    className={"font-normal tracking-wide text-base cursor-pointer hover:text-complementary-light"}
                    onClick={onHandleLogIn}
                >
                    {'Log in'}
                </span>
            }
            {(pageName !== pagesInfo.HOME.pageName) &&
                <QuestionIcon />
            }
        </RightNavLinksContainer>
    )
}

export default RightNavLinks;