import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FaEye } from "react-icons/fa";
import { MdOutlineStickyNote2 } from 'react-icons/md';

import SideOverPanel from "components/common-components/SideOverPanel";
import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { leadHeaderConst, subRowHeader, sortLeadLowToHigh, sortLeadHighToLow } from "pages/auth/leads/data";
import ViewLeadSideOverPanel from "pages/auth/leads/components/viewLeadSideOverPanel/ViewLeadSideOverPanel";

import { getLeadList } from "redux/lead/lead.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
// import { setModal } from 'redux/local/local.slice';
import { setClearLeadList, setLeadDetailPayload } from "redux/lead/lead.slice";
import { JourneyConst, contactTime, leadStatus, leadStrength } from "redux/lead/lead.const";
// import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from "utils/pagesInfo";
import { generateQueryParams } from 'utils/generators.utils';
import { dayjs, timeZone } from "utils/dateTime.utils";

const LeadActivity = ({ lead, onHandleViewLead }) => {

    const toolTipText = "lorem ipseum text isko"

    return (
        <div className={"flex justify-start items-center"}>
            <div
                className={"px-2 py-1 bg-primary-main text-white rounded-l-md cursor-pointer"}
                onClick={onHandleViewLead}
            >
                <FaEye className="text-xl hover:scale-110" />
            </div>
            <div className={"px-2 py-1 bg-secondary-main text-white rounded-r-md cursor-pointer"}>
                {toolTipText &&
                    <div className={"relative has-tooltip cursor-pointer"}>
                        {/* <MdOutlineStickyNote2 className={"text-xl hover:scale-110"} /> */}
                        <span
                            className={cn(
                                "w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip top-6.5",
                                "flex flex-col items-start justify-start overflow-hidden"
                            )}
                        >
                            {toolTipText}
                        </span>
                    </div>
                }
                <MdOutlineStickyNote2 className={"text-xl hover:scale-110"} />
            </div>
        </div>

    )
}


const DisplayLeads = ({ showEntries }) => {
    const { leadList, leadDetail } = useAppState((state) => state.lead)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })
    const [isShowSideOverPanel, setIsShowSideOverPanel] = useState(false)

    useEffect(() => {
        const query = generateQueryParams(location.search)
        dispatcher(getLeadList({ page: query.pn || 1, records: showEntries }))

        return () => {
            dispatcher(setClearLeadList())
        }
    }, [location.search, showEntries])

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortLeadList = (event1, event2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortLeadLowToHigh(activeSortHeader, event1, event2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortLeadHighToLow(activeSortHeader, event1, event2)
        }
    }

    const onHandleViewLead = (leadId) => {
        dispatcher(setLeadDetailPayload({
            ...leadDetail?.payload,
            leadId: leadId
        }))
        setIsShowSideOverPanel(true)
    }

    const rows = leadList?.filteredLeadList?.results?.slice(0, showEntries)?.sort(sortLeadList)?.map((lead, index) => [
        lead?.leadId || "-",
        lead?.firstName + " " + lead?.lastName?.charAt(0) || "-",
        lead?.email
            ? (lead?.email?.length > 24)
                ? lead?.email?.slice(0, 24) + " ..."
                : lead?.email
            : "-",
        (lead?.isdCode && lead?.mobileNo) ? "+" + lead?.isdCode + lead?.mobileNo : "-",
        JourneyConst[lead?.classFor?.toUpperCase()]?.label || "-",
        (lead?.contactTime && lead?.timeZone) ? `${contactTime[lead?.contactTime?.toUpperCase()]?.label} (${lead?.timeZone})` : "-",
        leadStatus[lead?.status?.toUpperCase()]?.label || "-",
        dayjs(lead?.createdAt).tz(timeZone).format('DD MMM, YYYY') || "-",
        <LeadActivity lead={lead} onHandleViewLead={() => onHandleViewLead(lead?.leadId)} />,
        lead?.lead || "-"
    ]);

    return (
        <>
            {isShowSideOverPanel &&
                <SideOverPanel
                    child={<ViewLeadSideOverPanel />}
                    isOpen={isShowSideOverPanel}
                    onClose={(isShow) => setIsShowSideOverPanel(isShow)}
                />
            }
            <Table
                headers={Object.values(leadHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={leadList?.isLoading}
                message={(leadList?.errorMsg || (leadList?.data?.records === 0)) && (leadList?.errorMsg || "No leads found")}
            />
            {(leadList?.filteredLeadList && (leadList?.filteredLeadList?.totalPages > 1)) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={leadList?.filteredLeadList?.page}
                        totalPages={leadList?.filteredLeadList?.totalPages}
                        onChangePage={(page) => navigate(pagesInfo.LEADS.pagePath + "?pn=" + page)}
                    />
                </div>
            }
        </>
    )
}

export default DisplayLeads;