import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';

import { getAvailabilityDetail } from 'redux/availability/availability.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAddAvailabilityDetail_Payload, resetAvailabilityDetail } from 'redux/availability/availability.slice';
import { modalConst } from 'redux/local/local.const';
import { availabilityIsRecurringEnum, availabilityEndTypeEnum, availabilityTypeEnum } from 'redux/availability/availability.const';

import { getTimeZoneOffset, dayjs, timeZone } from 'utils/dateTime.utils';

const DayItems = ({ localDay, timeSlots }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {localDay}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {timeSlots?.length > 0 && timeSlots?.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dayjs().tz(timeZone).format("YYYY-MM-DD") + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const DateTimeItems = ({ dateTimeSlot }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return dateTimeSlot?.timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {dayjs(dateTimeSlot?.date + " " + dateTimeSlot?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("ddd, DD MMM YY")}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {dateTimeSlot?.timeSlots.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dateTimeSlot?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}


const ViewUserAvailabilityModal = () => {
    const { modal } = useAppState((s) => s.local)
    const { availabilityDetail, addAvailabilityDetail } = useAppState(s => s.availability)

    const dispatcher = useAppDispatcher()
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    useEffect(() => {
        return () => {
            dispatcher(resetAddAvailabilityDetail_Payload())
            dispatcher(resetAvailabilityDetail())
        }
    }, [])

    useEffect(() => {
        if (addAvailabilityDetail?.payload?.availabilityId) {
            const requestDataPayload = {
                availabilityId: addAvailabilityDetail?.payload?.availabilityId,
                query: { timeZone: timeZone }
            }
            dispatcher(getAvailabilityDetail(requestDataPayload))
        }
    }, [addAvailabilityDetail?.payload?.availabilityId])

    const handleNext = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.VIEW_USER_AVAILABILITY_MODAL.stateKey]: false,
        }))
    }

    if (availabilityDetail?.isLoading) {
        return (
            <ComponentLoader
                isLoading={availabilityDetail?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (availabilityDetail?.message) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {availabilityDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] flex flex-col gap-5 overflow-auto"}>
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"truncate text-center font-bodyPri font-medium text-lg text-text-900"}>
                        {availabilityDetail?.data?.result?.name.substring(0, 50) + (availabilityDetail?.data?.result?.name.length > 50 ? "..." : "")}
                    </span>
                </div>
                <div className={"self-center gap-1 p-3 rounded-lg bg-primary-light border-2 border-divider-medium"}>
                    <div className={cn(
                        "flex items-center justify-evenly gap-1",
                        "font-bodyPri font-normal text-sm text-primary-dark"
                    )}>
                        <span>{"Starts on"}</span>
                        <span>{"  "}</span>
                        <span>{"Ends on"}</span>
                    </div>
                    <div className={cn(
                        "flex items-center justify-evenly gap-1",
                        "font-bodyPri font-medium text-base text-primary-dark"
                    )}>
                        {availabilityDetail?.data?.result?.startDateTime &&
                            <div className={"flex flex-row items-start justify-start gap-2"}>
                                <span className={"font-bodyPri font-medium text-base"}>
                                    {dayjs(availabilityDetail?.data?.result?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                </span>
                                {((availabilityDetail?.data?.result?.endType === availabilityEndTypeEnum.ON_DATE.value)
                                    && availabilityDetail?.data?.result?.endDateTime) &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base"}>
                                            {dayjs(availabilityDetail?.data?.result?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                        </span>
                                    </>
                                }
                                {(availabilityDetail?.data?.result?.endType === availabilityEndTypeEnum.WEEKLY_CYCLE.value) &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base"}>
                                            {`Repeat ${availabilityDetail?.data?.result?.weeklyCycle} weeks`}
                                        </span>
                                    </>
                                }
                                {(availabilityDetail?.data?.result?.endType === availabilityEndTypeEnum.FOREVER.value) &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base"}>
                                            {"Never Ends"}
                                        </span>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>

                {(availabilityDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.NON_RECURRING?.value) &&
                    <div className={cn(
                        "grid grid-cols-2 gap-2 px-1 pb-3 overflow-hidden overflow-y-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {availabilityDetail?.data?.result?.dateTimeSlots?.map((dateTimeSlot, index) => (
                            <DateTimeItems key={index} dateTimeSlot={dateTimeSlot} />
                        ))}
                    </div>
                }
                {(availabilityDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.RECURRING?.value) &&
                    <div className={cn(
                        "grid grid-cols-2 gap-2 px-1 pb-3 overflow-hidden overflow-y-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {Object.keys(availabilityDetail?.data?.result?.weeklyTimeSlots).map((localDay, index) => (
                            <DayItems key={index} localDay={localDay} timeSlots={availabilityDetail?.data?.result?.weeklyTimeSlots[localDay]} />
                        ))}
                    </div>
                }
            </div>

            <div className={"flex justify-end"}>
                <span onClick={handleNext} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                )}>
                    {"Close"}
                </span>
            </div>
        </div>
    )
}

export default ViewUserAvailabilityModal;