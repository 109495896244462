import { dataEntriesConst } from "redux/local/local.const";

export const url = {
    // lms course
    GET_LMS_COURSE_LIST: "lms/lms-courses/lms-courses",
    UPDATE_LMS_COURSE_DETAIL: 'lms/lms-courses/lms-courses/{courseId}',
    DELETE_LMS_COURSE_DETAIL: 'lms/lms-courses/lms-courses/{courseId}',
}

export const lmsCourseRedux = {
    sliceName: "lmsCourse",
    lmsCourseList: {
        requestName: "getLmsCourseList"
    },
    lmsCourseDetail: {
        requestName: "getLmsCourseDetail"
    }
}


export const lmsCourseSectionLessonTypeEnum = {
    LECTURE: {
        value: "lecture",
        label: "Lecture"
    },
    QUIZ: {
        value: 'quiz',
        label: 'Quiz',
    },
    ASSIGNMENT: {
        value: 'assignment',
        label: 'Assignment',
    },
    TOPIC: {
        value: 'topic',
        label: 'Topic',
    },
    CODING_EXERCISE: {
        value: 'coding_exercise',
        label: 'Coding Exercise',
    },
    PRACTICE_TEST: {
        value: 'practice_test',
        label: 'Practice Test',
    },
}

export const lmsCourseStatusEnum = {
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const lmsCourseShareableEnum = {
    YES: {
        label: "Yes",
        value: "yes"
    },
    NO: {
        label: "No",
        value: "no"
    }
}

export const lmsCourseVisibilityEnum = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const lmsCourseInstructorPermissionEnum = {
    OWNER: {
        label: "Owner",
        value: "owner"
    },
    EDITOR: {
        label: "Editor",
        value: "editor"
    },
    VIEWER: {
        label: "Viewer",
        value: "viewer"
    }
}

export const lmsCourseStudentStatusEnum = {
    PENDING: {
        label: "pending",
        value: "pending"
    },
    JOINED: {
        label: "Joined",
        value: "joined"
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled"
    }
}

export const lmsCourseStudentIsActiveEnum = {
    ACTIVE: {
        label: "Active",
        value: "active"
    },
    INACTIVE: {
        label: "In Active",
        value: "inactive"
    },
    BLOCKED: {
        label: "Block",
        value: "blocked"
    }
}

export const lmsCourseInviteToEnum = {
    TUTOR: {
        label: "Tutor",
        value: "tutor"
    },
    STUDENT: {
        label: "Student",
        value: "student"
    }
}

export const lmsCourseJoiningFlowEnum = {
    CHECKOUT: {
        label: "Checkout",
        value: "checkout"
    },
    INVITE: {
        label: "Invite",
        value: "invite"
    },
    MANUAL: {
        label: "Manual",
        value: "manual"
    },
}

export const lmsCourseInviteStatusEnum = {
    INVITED: {
        label: "Invited",
        value: "invited"
    },
    RE_INVITED: {
        label: "Re Invited",
        value: "re_invited"
    },
    ACCEPTED: {
        label: "Accepted",
        value: "accepted"
    },
    DECLINED: {
        label: "Declined",
        value: "declined"
    }
}

export const modifyLmsCourseDetailPayload = {
    title: "",
    sub_title: "",
    category: "",
    // courseDetailActiveTab: "general",
    // courseSettingsActiveTab: "general"
}

export const DEFAULT_LMS_COURSE_PAGE = 1

export const DEFAULT_LMS_COURSE_RECORDS = dataEntriesConst.TWENTY_FIVE.value

export const lmsCourseFilterProps = {
    page: DEFAULT_LMS_COURSE_PAGE,
    records: DEFAULT_LMS_COURSE_RECORDS,
    searchText: "",
    sortBy: "",
    courseId: "",
    courseTitle: "",
    courseType: "",
    courseStatus: "",
    ownerTutor: "",
    date: ""
}