import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

import authService from 'redux/auth/auth.service';

import {
    setSessionLoading,
    setAccessToken,

    setVerifyReferralLoading,
    setVerifyReferralData,
    setVerifyReferralMessage,

    setForgetPasswordLoading,
    setForgetPasswordMessage,

    setAddVerifyUserLoading,
    setAddVerifyUserData,
    setAddVerifyUserMessage,

    setAdminLoginAsLoading,
    setAdminLoginAsData,
    setAdminLoginAsMessage,
} from 'redux/auth/auth.slice';
import { authRedux } from "redux/auth/auth.const";

import { getMyProfile } from 'redux/user/user.request';

export const getToken = createAsyncThunk(
    `${authRedux?.sliceName}/${authRedux?.accessToken?.requestName}`,
    async (_, { dispatch }) => {
        dispatch(setSessionLoading(true))

        try {
            const response = await authService.getToken()
            if (response.status === 200) {
                dispatch(setAccessToken(response.data.data.accessToken))
                dispatch(getMyProfile())
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.data.message.error)
        } finally {
            dispatch(setSessionLoading(false))
        }
    }
)

// verify referral
export const createVerifyReferral = createAsyncThunk(
    `${authRedux?.sliceName}/${authRedux?.verifyReferral?.requestName}`,
    async (referralCode, { dispatch }) => {
        dispatch(setVerifyReferralLoading(true))

        try {
            const requestData = {
                params: { referralCode: referralCode }
            }
            const response = await authService.createVerifyReferral(requestData)
            if (response.status === 200) {
                dispatch(setVerifyReferralData(response.data.data))
                // toast.success(response.data.message)
            }
            else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error || "Something went wrong")
            dispatch(setVerifyReferralMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
        } finally {
            dispatch(setVerifyReferralLoading(false))
        }
    }
)

// send forget password link
export const sendForgetPasswordLink = createAsyncThunk(
    `${authRedux?.sliceName}/${authRedux?.forgetPassword?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setForgetPasswordLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await authService.sendForgetPasswordLink(requestData)
            if (response.status === 200) {
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setForgetPasswordMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setForgetPasswordLoading(false))
        }
    }
)

// verify user request
export const createVerifyUser = createAsyncThunk(
    `${authRedux?.sliceName}/${authRedux?.verifyUser?.requestName}`,
    async (payload, { dispatch }) => {
        dispatch(setAddVerifyUserLoading(true))

        try {
            const requestData = {
                body: payload
            }
            const response = await authService.createVerifyUser(requestData)
            if (response.status === 200) {
                dispatch(setAddVerifyUserData(response.data.data))
                // toast.message(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            dispatch(setAddVerifyUserMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
            // toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        } finally {
            dispatch(setAddVerifyUserLoading(false))
        }
    }
)

export const createAdminLoginAsDetail = createAsyncThunk(
    `${authRedux?.sliceName}/${authRedux?.adminLoginAs?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAdminLoginAsLoading(true));

        try {
            const requestData = {
                body: body
            };
            const response = await authService.createAdminLoginAsDetail(requestData);
            if (response.status === 200) {
                dispatch(setAdminLoginAsData(response.data.data.message))
                toast.success(response.data.data.message);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error);
            dispatch(setAdminLoginAsMessage(error.response.data.message || error.response.data.error || "Something went wrong"));
            toast.error(error.response.data.message || error.response.data.error || "Something went wrong");
        } finally {
            dispatch(setAdminLoginAsLoading(false));
        }
    }
);

// const requestData = {
//     headers: {token: userAuth.tokens.accessToken},
//     params: {tutorId: "1234"},
//     query: {status: "active", verified: "true"},
//     body: {
//         email: "",
//         password: ""
//     }
//    for a delete request body should be inside an object with key data. {data: body }
// }