import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getOnboardingList } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { dateRangesLabels } from "redux/local/local.const";
import { setClearFilterProps, setClearOnboardingList, setFilterProps } from "redux/onboarding/onboarding.slice";
import { filterName, tutorOnboardingStatus, onboardingActionRequired } from "redux/onboarding/onboarding.const";

import { dayjs } from "utils/dateTime.utils";

export const UserProfileImage = () => {
    return (
        <div className="w-full h-full flex items-center justify-center font-bodyPri font-semibold text-text-900 text-base">
            {"#"}
        </div>
    );
}

export const SearchOnboardingId = () => {
    const { filterProps } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"onboardingId"}
            value={filterProps?.onboardingId}
            placeholder={"ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, onboardingId: e.target.value }
            }))}
        />
    )
}

export const SearchUserId = () => {
    const { filterProps } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"userId"}
            value={filterProps?.userId}
            placeholder={"User ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, userId: e.target.value }
            }))}
        />
    )
}

export const SearchUserName = () => {
    const { filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"Name"}
            value={filterProps.userName}
            placeholder={"Name"}
            disabled={true}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, userName: e.target.value }
            }))}
        />
    )
}

export const SearchPhone = () => {
    const { filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"Phone"}
            value={filterProps?.phone}
            disabled={true}
            placeholder={"Phone"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, phone: e.target.value }
            }))}
        />
    )
}

export const SearchEmail = () => {
    const { filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"Email"}
            value={filterProps?.email}
            placeholder={"Email"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, email: e.target.value }
            }))}
        />
    )
}

export const FilterTutorOnboardingStatus = () => {
    const { filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(tutorOnboardingStatus)}
            value={filterProps.status}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, status: option.value }
            }))}
        />
    )
}

export const SearchDate = () => {
    const { filterProps } = useAppState((state) => state.course)
    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "px-1 py-0.5 flex items-center justify-center gap-1 bg-text-300",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                disabled={true}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
            />
        </DateRangePicker>
    )
}

export const TutorOnboardingButtonAction = () => {
    const { filterProps } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const handleApply = () => {
        const requestDataPayload = {
            page: 1,
            records: 25
        }
        if (filterProps?.onboardingId) {
            requestDataPayload["onboardingId"] = filterProps?.onboardingId
        }
        if (filterProps?.userId) {
            requestDataPayload["userId"] = filterProps?.userId
        }
        if (filterProps?.email) {
            requestDataPayload["email"] = filterProps?.email
        }
        if (filterProps?.onboardingType) {
            requestDataPayload["onboardingType"] = filterProps?.onboardingType
        }
        if (filterProps?.status) {
            requestDataPayload["status"] = filterProps?.status
        }
        if (filterProps?.actionRequired) {
            requestDataPayload["actionRequired"] = filterProps?.actionRequired
        }
        dispatcher(getOnboardingList(requestDataPayload))
    }

    const handleClear = () => {
        dispatcher(setClearFilterProps())
        dispatcher(getOnboardingList({ page: 1, records: 25 }))
        navigate("/onboarding" + "?pn=" + 1)
    }

    return (
        <div className="w-full flex items-center justify-center gap-1">
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-primary-main text-text-50 hover:bg-primary-dark",
                )}
                onClick={handleApply}
            >
                {"Apply"}
            </span>
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-background-lightDark text-text-50 hover:bg-background-dark",
                )}
                onClick={handleClear}
            >
                {"Clear"}
            </span>
        </div>
    )
}

export const FilterActionRequired = () => {
    const { tutorOnboardingList, filterProps } = useAppState((state) => state.onboarding)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(onboardingActionRequired)}
            value={filterProps.actionRequired}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, actionRequired: option.value },
                list: tutorOnboardingList?.tutorOnboardingList,
                filterName: filterName.TUTOR_ONBOARDING.value
            }))}
        />
    )
}

export const SearchModifiedDate = () => {
    const { filterProps } = useAppState((state) => state.course)
    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "px-1 py-0.5 flex items-center justify-center gap-1 bg-text-300",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                disabled={true}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
            />
        </DateRangePicker>
    )
}