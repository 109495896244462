import { useEffect } from "react";
import { cn } from "utils/cn.utils";
import { useParams } from "react-router-dom";

import FullPageLoader from "components/loader/FullPageLoader";

import { getOnboardingTutorTrialCourseDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOnboardingTutorTrialCourseDetail } from "redux/onboarding/onboarding.slice";
import { trialClassStatus } from "redux/onboarding/onboarding.const";
import { courseStatus, courseType } from "redux/course/course.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const CreateTrialClass = () => {
    const { onboardingTutorTrialCourseDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingTutorTrialCourseDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingTutorTrialCourseDetail())
        }
    }, [onboardingId])

    if (onboardingTutorTrialCourseDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingTutorTrialCourseDetail?.isLoading} />
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-5 py-3",
                !onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status && "bg-yellow-100",
                `bg-${trialClassStatus[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Trial Class Details"}
            </div>

            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail &&
                <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                    <div className="w-full flex flex-col items-center justify-start text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Course Status:"}
                        </span>
                        <span className={cn(
                            "text-medium font-medium capitalize",
                            `text-${courseStatus[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.status?.toUpperCase()]?.darkColor}`
                        )}>
                            {courseStatus[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.status?.toUpperCase()]?.label}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Course Id:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseId}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Course Title:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseTitle || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Course Description:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseDescription || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Course Type:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {courseType[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseType?.toUpperCase()]?.label || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Booking Type:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.bookingType || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Language:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.language || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Segment:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.segment || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Category:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.category || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Proficiency:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.proficiency || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Price:"}
                        </span>
                        {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.price &&
                            <span className="text-medium font-medium capitalize">
                                {"USD " + parseFloat(onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.price / 100)?.toFixed(2)}
                            </span>
                        }
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"No of sessions:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.noOfSessions || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Session Duration:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.sessionDuration || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Student Capacity:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.studentCapacity || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Thumbnail URL:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.thumbnailUrl || "-"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-base font-bodyPri tracking-wide gap-2 md:flex-row lg:flex-row">
                        <span className="text-text-600">
                            {"Starts On:"}
                        </span>
                        <span className="text-medium font-medium capitalize">
                            {dayjs(onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.createdAt)?.tz(timeZone)?.format("ddd, DD MMM YYYY") || "-"}
                        </span>
                    </div>
                </div>
            }
            {onboardingTutorTrialCourseDetail?.errorMsg &&
                <span className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingTutorTrialCourseDetail?.errorMsg}
                </span>
            }
        </div>
    )
}

export default CreateTrialClass;