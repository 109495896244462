import { dayjs } from "utils/dateTime.utils";

import {
    SearchUserId,
    FilterAmountImpact,
    SearchWalletTxnId,
    SearchVirtualTxnId,
    SearchAmount,
    FilterTxnType,
    FilterWalletAction,
    SearchDate,
    ButtonAction
} from "pages/auth/walletTxn/components/WalletTxnFilterStatistics";

export const pageHeading = {
    heading: "Wallet & Transactions",
    subHeading: "See your balance, transactions and withdraw money!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/wallet.svg"
}

export const walletTxnHeaderConst = {
    WALLET_TXN_ID: {
        label: "Wallet Txn ID",
        value: "wallet_txn-id"
    },
    VIRTUAL_TXN_ID: {
        label: "Virtual Txn ID",
        value: "virtual_txn_id"
    },
    USER: {
        label: "User",
        value: "user"
    },
    AMOUNT: {
        label: "Amount",
        value: "amount"
    },
    AMOUNT_IMPACT: {
        label: "Amount Impact",
        value: "amount_impact"
    },
    TXN_TYPE: {
        label: "Txn Type",
        value: "txn_type"
    },
    WALLET_ACTION: {
        label: "Wallet Action",
        value: "wallet_action"
    },
    DATE: {
        label: "Date",
        value: "date"
    },
    ACTIVITY: {
        label: "Activity",
        value: "activity"
    }
}

export const subRowHeader = [
    <SearchWalletTxnId />,
    <SearchVirtualTxnId />,
    <SearchUserId />,
    <SearchAmount />,
    <FilterAmountImpact />,
    <FilterTxnType />,
    <FilterWalletAction />,
    <SearchDate />,
    <ButtonAction />
]

export const sortWalletTxnLowToHigh = (activeSortHeader, walletTxn1, walletTxn2) => {
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.WALLET_TXN_ID?.value) {
        return (Number(walletTxn1?.walletTxnId) - Number(walletTxn2?.walletTxnId))
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.VIRTUAL_TXN_ID?.value) {
        return walletTxn1?.virtualTxnId?.toLowerCase()?.localeCompare(walletTxn2?.virtualTxnId)
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.USER?.value) {
        return (Number(walletTxn1?.user?.userId) - Number(walletTxn2?.user?.userId))
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.AMOUNT?.value) {
        return (Number(walletTxn1?.amount / 100) - Number(walletTxn2?.amount / 100))
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.AMOUNT_IMPACT?.value) {
        return walletTxn1?.amountImpact?.toLowerCase()?.localeCompare(walletTxn2?.amountImpact?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.TXN_TYPE?.value) {
        return walletTxn1?.txnType?.toLowerCase()?.localeCompare(walletTxn2?.txnType?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.WALLET_ACTION?.value) {
        return walletTxn1?.action?.toLowerCase()?.localeCompare(walletTxn2?.action?.toLowerCase())
    }
    // if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.STATUS?.value) {
    //     return walletTxn2?.status?.toLowerCase()?.localeCompare(walletTxn1?.status?.toLowerCase())
    // }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.DATE?.value) {
        return dayjs(walletTxn2?.createdAt).isSameOrBefore(walletTxn1?.createdAt) ? 1 : -1
    }
}

export const sortWalletTxnHighToLow = (activeSortHeader, walletTxn1, walletTxn2) => {
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.WALLET_TXN_ID?.value) {
        return (Number(walletTxn2?.walletTxnId) - Number(walletTxn1?.walletTxnId))
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.VIRTUAL_TXN_ID?.value) {
        return walletTxn2?.virtualTxnId?.toLowerCase()?.localeCompare(walletTxn1?.virtualTxnId)
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.USER?.value) {
        return (Number(walletTxn2?.user?.userId) - Number(walletTxn1?.user?.userId))
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.AMOUNT?.value) {
        return (Number(walletTxn2?.amount / 100) - Number(walletTxn1?.amount / 100))
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.AMOUNT_IMPACT?.value) {
        return walletTxn2?.amountImpact?.toLowerCase()?.localeCompare(walletTxn1?.amountImpact?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.TXN_TYPE?.value) {
        return walletTxn2?.txnType?.toLowerCase()?.localeCompare(walletTxn1?.txnType?.toLowerCase())
    }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.WALLET_ACTION?.value) {
        return walletTxn2?.action?.toLowerCase()?.localeCompare(walletTxn1?.action?.toLowerCase())
    }
    // if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.STATUS?.value) {
    //     return walletTxn2?.status?.toLowerCase()?.localeCompare(walletTxn1?.status?.toLowerCase())
    // }
    if (activeSortHeader?.activeSortKey === walletTxnHeaderConst?.DATE?.value) {
        return dayjs(walletTxn1?.createdAt).isSameOrBefore(walletTxn2?.createdAt) ? 1 : -1
    }
}

export const txnCurrencyCodeConst = {
    WALLET: {
        value: 'USD',
        label: 'USD'
    },
    PENDING: {
        value: 'USD',
        label: 'USD'
    },
    PROMO: {
        value: 'USD',
        label: 'USD'
    },
    AUD_WALLET: {
        value: "AUD",
        label: "AUD"
    },
    INR_WALLET: {
        value: "INR",
        label: "INR"
    }
}