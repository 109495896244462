import { memo } from 'react';

import { useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { bookingRegularPriceModelEnum } from 'redux/booking/booking.const';

import { currencyRateConversion } from 'utils/generators.utils';

const AppointmentProfile = memo(({ bookingUserDetail }) => {
    const { locals } = useAppState((state) => state.local)

    return (
        <div className="px-2 rounded-lg shadow-sm">
            <div className={"flex justify-between items-center text-slate-500 text-sm md:text-base"}>
                <p className={"font-bodyPri tracking-wide text-text-800"}>
                    {"Booking ID :"} {bookingUserDetail?.id}
                </p>
                <div className={"flex font-bodyPri tracking-wide text-text-800"}>
                    <span className={"font-bodyPri font-normal"}>
                        {"Price : "}
                    </span>
                    <div className={"flex flex-col gap-0.5"}>
                        {((bookingUserDetail?.bookingPrice?.discount_pct === 100)
                            || (bookingUserDetail?.bookingPrice?.price_model === bookingRegularPriceModelEnum.FREE.value))
                            ? <span className={"font-bodyPri font-normal"}>
                                {bookingRegularPriceModelEnum.FREE.label}
                            </span>
                            : <span className={"font-bodyPri font-normal"}>
                                {`${bookingUserDetail?.bookingPrice?.masterCurrency?.code} ${parseFloat(bookingUserDetail?.bookingPrice?.totalAmount / 100).toFixed(2)}`}
                            </span>
                        }
                    </div>
                </div>
            </div>
            {([masterCurrencyCodeEnum.USD.value]?.includes(bookingUserDetail?.bookingPrice?.masterCurrency?.code) && (locals.currencyCode !== masterCurrencyCodeEnum.USD.value)) &&
                <div className={"w-full flex justify-end"}>
                    <span className={"font-bodyPri font-normal text-xs"}>
                        {`(Approx. ${(currencyRateConversion(locals?.currencyCode, bookingUserDetail?.bookingPrice?.totalAmount))})`}
                    </span>
                </div>
            }
            <div className="flex flex-col items-center justify-between sm:flex-row">
                {/* Profile */}
                <div className="inline-flex flex-col items-center justify-center my-4 space-y-1 sm:flex-row">
                    <img
                        src={bookingUserDetail?.user?.profilePicUrl}
                        alt={"profile"}
                        className="object-cover rounded-full w-14 h-14"
                    />
                    <div className="pl-2">
                        <p className="text-lg font-semibold font-bodyPri tracking-wide text-text-900 capitalize">
                            {bookingUserDetail?.user?.firstName + " " + bookingUserDetail?.user?.lastName.charAt(0) + "."}
                        </p>
                        <p className="text-sm text-center text-opacity-40 md:text-left">
                            {userRoles?.STUDENT?.label}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default AppointmentProfile;