import { cn } from "utils/cn.utils";
import { useAppState } from "hooks/useStore";
import { masterCurrencyCodeEnum } from "redux/master/master.const";
import { withdrawalStatus } from "redux/withdrawal/withdrawal.const";

const UserDetail = () => {
    const { withdrawalDetail } = useAppState((state) => state.withdrawal)

    return withdrawalDetail?.withdrawalDetail && (
        <div className="w-full flex flex-col justify-center items-center gap-10">
            <div className={"w-full flex items-center justify-between"}>
                <div className={"flex justify-center vitems-center gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Withdrawal ID:"}
                    </span>
                    <span className={"font-bodyPri font-medium text-text-800 text-medium"}>
                        {withdrawalDetail?.withdrawalDetail?.withdrawalId}
                    </span>
                </div>
                <div className={"flex justify-center items-center gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                        {"Status:"}
                    </span>
                    {Object.keys(withdrawalStatus).map((item, index) => withdrawalStatus[item].value === withdrawalDetail?.withdrawalDetail?.status &&
                        <span
                            key={index}
                            className={cn("font-bodyPri font-medium text-medium whitespace-nowrap", withdrawalStatus[item].color)}>
                            {withdrawalStatus[item].label}
                        </span>
                    )}
                </div>
            </div>
            <div className={"w-full flex items-center justify-between gap-5"}>
                <div className="flex items-center justify-center gap-3">
                    <div className="relative w-16 h-16 rounded-full">
                        <img
                            src={withdrawalDetail?.withdrawalDetail?.user?.profilePicUrl}
                            alt={"profileImg"}
                            className="w-full h-full rounded-full object-cover"
                        />
                        <div className='absolute bottom-0 right-0 w-1/4 h-1/4 rounded-full overflow-hidden border-2 border-white'>
                            <img src={`https://flagcdn.com/64x48/${withdrawalDetail?.withdrawalDetail?.user?.fromCountry?.countryDomain}.png`.toLowerCase()} alt={"country-flag"}
                                className='w-full h-full object-cover'
                            />
                        </div>
                    </div>
                    <div className={"flex flex-col items-start justify-center"}>
                        <span
                            className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap capitalize"}>
                            {withdrawalDetail?.withdrawalDetail?.user?.firstName + " " + withdrawalDetail?.withdrawalDetail?.user?.lastName.charAt(0) + "."}
                        </span>
                        <div className="flex flex-row gap-2">
                            <span className={"w-full font-bodyPri font-normal text-text-600 text-base whitespace-nowrap"}>
                                {"User ID:"}
                            </span>
                            <span
                                className={"w-full font-bodyPri font-medium text-text-800 text-medium whitespace-nowrap"}>
                                {withdrawalDetail?.withdrawalDetail?.user?.userId}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"flex items-center justify-center gap-3"}>
                    <div className="flex flex-col justify-start items-center">
                        <span className={"font-bodyPri font-normal text-text-900 text-base whitespace-nowrap"}>
                            {"Net Payout"}
                        </span>
                        <span
                            className={"font-bodyPri font-medium text-text-900 text-lg whitespace-nowrap"}
                        >
                            {`${masterCurrencyCodeEnum[withdrawalDetail?.withdrawalDetail?.masterCurrency?.code?.toUpperCase()]?.label} ${(withdrawalDetail?.withdrawalDetail?.withdrawalPayout?.effectiveAmount / 100).toFixed(2)}`}
                        </span>
                    </div>
                </div>
            </div>
            <hr className={"w-full border border-text-300"} />
        </div>
    )
}

export default UserDetail