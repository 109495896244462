import { useState } from "react";

import { IoIosArrowForward } from "react-icons/io";

import LogTimeLine from "components/logTimeLine/LogTimeLine";

import { sessionLogConst } from "pages/auth/sessions/data";

import { useAppState } from "hooks/useStore";
import { sessionStatusEnum } from "redux/session/session.const";

import { cn } from "utils/cn.utils";

export const createEventLog = (sessionLogs) => {
    return !!sessionLogs?.length && sessionLogs?.map(session => {
        return {
            ...sessionStatusEnum[session.status.toUpperCase()],
            ...session,
            title: sessionLogConst[session.status.toUpperCase()].title
        }
    })
}

const SessionDetailEventLogs = () => {
    const { sessionDetail } = useAppState(s => s.session)

    const [isShow, setIsShow] = useState(false)

    return !!sessionDetail?.data?.result?.session_logs ? (
        <div className={"space-y-3"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Session Logs"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col gap-3 px-5 transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                <div className={""}>
                    <LogTimeLine logList={createEventLog(sessionDetail?.data?.result?.session_logs)} />
                </div>
            </div>
        </div>
    ) : null
}
export default SessionDetailEventLogs;