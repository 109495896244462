import { useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { OptionSelector } from "components/common-components/Select";

import { getWithdrawalFilteredList } from "redux/withdrawal/withdrawal.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearFilterProps, setFilterProps } from "redux/withdrawal/withdrawal.slice";
import { filterName, payoutType, withdrawalStatus } from "redux/withdrawal/withdrawal.const";
import { dateRangesLabels } from "redux/local/local.const";

import { dayjs } from "utils/dateTime.utils";

export const SearchUserId = () => {
    const { withdrawalList, filterProps } = useAppState((state) => state.withdrawal)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"UserId"}
            value={filterProps?.userId}
            placeholder={"Search User ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, userId: e.target.value },
                list: withdrawalList?.withdrawalList,
                filterName: filterName.WITHDRAWAL.value
            }))}
        />
    )
}

export const SearchWithdrawalId = () => {
    const { withdrawalList, filterProps } = useAppState((state) => state.withdrawal)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"WithdrawalId"}
            value={filterProps.withdrawalId}
            placeholder={"Search Withdrawal ID"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, withdrawalId: e.target.value },
                list: withdrawalList?.withdrawalList,
                filterName: filterName.WITHDRAWAL.value
            }))}
        />
    )
}

export const SearchGatewayPaymentId = () => {
    const { withdrawalList, filterProps } = useAppState((state) => state.withdrawal)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"PaymentId"}
            value={filterProps.gatewayPaymentId}
            placeholder={"Search Gateway Payment ID"}
            className={cn(
                "w-full px-2 py-0.5 border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, gatewayPaymentId: e.target.value },
                list: withdrawalList?.withdrawalList,
                filterName: filterName.WITHDRAWAL.value
            }))}
        />
    )
}

export const SearchVirtualPaymentId = () => {
    const { withdrawalList, filterProps } = useAppState((state) => state.withdrawal)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"text"}
            name={"Payment ID"}
            value={filterProps?.virtualPaymentId}
            placeholder={"Search Virtual Payment ID"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, virtualPaymentId: e.target.value },
                list: withdrawalList?.withdrawalList,
                filterName: filterName.WITHDRAWAL.value
            }))}
        />
    )
}

export const FilterPayoutMethod = () => {
    const { withdrawalList, filterProps } = useAppState((state) => state.withdrawal)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(payoutType)}
            value={filterProps.payoutType}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, payoutType: option.value },
                list: withdrawalList?.withdrawalList,
                filterName: filterName.WITHDRAWAL.value
            }))}
        />
    )
}

export const SearchWithdrawalAmount = () => {
    const { withdrawalList, filterProps } = useAppState((state) => state.withdrawal)
    const dispatcher = useAppDispatcher()

    return (
        <input
            type={"number"}
            name={"Search Amount"}
            value={filterProps?.withdrawalAmount}
            placeholder={"Search Amount"}
            className={cn(
                "w-full px-2 py-0.5 input-number-spin-none border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
            )}
            onChange={(e) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, withdrawalAmount: e.target.value },
                list: withdrawalList?.withdrawalList,
                filterName: filterName.WITHDRAWAL.value
            }))}
        />
    )
}

export const FilterWithdrawalStatus = () => {
    const { withdrawalList, filterProps } = useAppState((state) => state.withdrawal)
    const dispatcher = useAppDispatcher()

    return (
        <OptionSelector
            className={"w-full"}
            options={Object.values(withdrawalStatus)}
            value={filterProps.withdrawalStatus}
            onChange={(option) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, withdrawalStatus: option.value },
                list: withdrawalList?.withdrawalList,
                filterName: filterName.WITHDRAWAL.value
            }))}
        />
    )
}

export const SearchDate = () => {
    const { filterProps } = useAppState((state) => state.course)
    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, startDate: startDate, endDate: endDate },
        }))
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Cancel',
                },
                ranges: dateRangesLabels
            }}
            onApply={(event, picker) => handleApply(picker)}
            onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
        >
            <input
                type={"text"}
                defaultValue={""}
                className={cn(
                    "w-full py-0.5 flex items-center justify-center text-center gap-1 bg-white",
                    "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                    "font-bodyPri font-normal text-text-700 text-base"
                )}
                value={`${dayjs(selectedDateRange?.startDate)?.format("DD MMM YY")}-${dayjs(selectedDateRange?.endDate)?.format("DD MMM YY")}`}
            />
        </DateRangePicker>
    )
}

export const ButtonAction = () => {
    const { filterProps } = useAppState((state) => state.withdrawal)

    const dispatcher = useAppDispatcher()

    const handleApply = () => {
        dispatcher(getWithdrawalFilteredList(filterProps))
    }

    const handleClear = () => {
        dispatcher(setClearFilterProps())
    }

    return (
        <div className="w-full flex items-center justify-center gap-1">
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-primary-main text-text-50 hover:bg-primary-dark",
                )}
                onClick={handleApply}
            >
                {"Apply"}
            </span>
            <span
                className={cn(
                    "px-2 py-0.5 flex justify-center items-center rounded-md cursor-pointer",
                    "font-buttons font-normal text-sm",
                    "bg-background-lightDark text-text-50 hover:bg-background-dark",
                )}
                onClick={handleClear}
            >
                {"Clear"}
            </span>
        </div>
    )
}