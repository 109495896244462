import { MdAdd } from 'react-icons/md';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const StripeSettings = () => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const onHandleManualPayment = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.MANUAL_PAYMENT_DETAIL_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={""}>
            <div className={"py-2 mx-8 border-b"}>
                <p className="font-bodyPri font-medium text-lg text-text-900 tracking-wide text-center">
                    {"Stripe Settings"}
                </p>
            </div>
            <div className={"flex justify-start items-center m-5"}>
                <div
                    className={"flex items-center justify-center gap-1 py-1 px-5 border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"}
                    onClick={onHandleManualPayment}
                >
                    <MdAdd className={"text-lg group-hover:text-text-50"} />
                    <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                        {"Manually Confirm a Payment Intent ID"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default StripeSettings;