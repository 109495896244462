import { useEffect, useState } from 'react';

import { AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';

import FullPageLoader from 'components/loader/FullPageLoader';
import Table from 'components/table/Table';
import Pagination from 'components/pagination/Pagination';

import { lmsResourceHeaderConst, sortResourceHighToLow, sortResourceLowToHigh, subRowHeader } from 'pages/auth/edulyteLms/lmsResource/lmsResource.data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { deleteLmsResourceDetail, getLmsResourceList } from 'redux/edulyteLms/lmsResource/lmsResource.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { resetFilterProps, resetLmsResourceList } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { lmsResourceStatusEnum, lmsResourceTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

import { edulyteAppPagesInfo } from 'utils/pagesInfo';
import { timeZone, dayjs } from 'utils/dateTime.utils';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';

let CURRENT_RESOURCE_ID = null;

const DisplayLmsResource = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { lmsResourceList, destroyLmsResourceDetail, filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {

        return () => {
            dispatcher(resetLmsResourceList())
            dispatcher(resetFilterProps())
            dispatcher(resetAdminLoginAs())
        }
    }, [])

    useEffect(() => {
        const query = {
            page: filterProps?.page || 1,
            records: Number(filterProps?.records) || 25
        }
        dispatcher(getLmsResourceList(query))

    }, [filterProps?.records])

    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo?.EDIT_LMS_RESOURCE?.pagePath}/${CURRENT_RESOURCE_ID}/edit`, "_self")
        }
    }, [adminLoginAs?.data])

    const onHandleEditResource = (resourceId, userId) => {
        CURRENT_RESOURCE_ID = resourceId
        dispatcher(createAdminLoginAsDetail({ user_id: userId, source_url: window.location.href }))
    }

    const onHandleDeleteResource = (resourceId) => {
        if (destroyLmsResourceDetail?.isLoading) return;

        dispatcher(deleteLmsResourceDetail(resourceId))
    }

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortResourceList = (resource1, resource2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortResourceLowToHigh(activeSortHeader, resource1, resource2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortResourceHighToLow(activeSortHeader, resource1, resource2)
        }
    }

    const tableRowsConst = lmsResourceList?.data?.results?.slice(0)?.sort(sortResourceList)?.map((resource) => ([
        resource?.id,
        resource?.title
            ? (resource?.title?.length > 30)
                ? resource?.title?.slice(0, 30) + "...."
                : resource?.title
            : "-",
        lmsResourceTypeEnum[resource?.type?.toUpperCase()]?.label || "-",
        resource?.resource_setting?.status
            ? lmsResourceStatusEnum[resource?.resource_setting?.status?.toUpperCase()]?.label
            : "-",
        dayjs(resource?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",

        <div className={"flex items-center w-full"}>
            <button
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
                onClick={() => onHandleEditResource(resource?.id, resource?.resource_setting?.default_owner?.tutor?.user?.userId)}
                title={"Edit Resource"}
            >
                <FiEdit size={20} className={"text-xl hover:scale-110"} />
            </button>
            <button
                className={"px-2 py-1 bg-red-500 text-white rounded-r-md"}
                onClick={() => onHandleDeleteResource(resource?.id)}
                title={"Delete Resource"}
            >
                <AiOutlineDelete size={20} className={"text-xl hover:scale-110"} />
            </button>
        </div>,
    ]))

    if (lmsResourceList?.isLoading) {
        return (
            <FullPageLoader isLoading={lmsResourceList?.isLoading} />
        )
    }

    return (
        <div className={'w-full flex flex-col gap-5'}>
            <Table
                headers={Object.values(lmsResourceHeaderConst)}
                subRowHeader={subRowHeader}
                rows={tableRowsConst}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
            />
            {(!lmsResourceList?.isLoading && (lmsResourceList?.data?.records > 0)) &&
                <div className={'w-full flex items-center justify-center'}>
                    <Pagination
                        page={lmsResourceList?.data?.page}
                        totalPages={lmsResourceList?.data?.totalPages}
                        onChangePage={(page) => dispatcher(getLmsResourceList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
            {(!lmsResourceList?.isLoading && lmsResourceList?.message) &&
                <div className={'w-full flex items-center justify-center'}>
                    <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
                        {lmsResourceList.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default DisplayLmsResource;