import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import SearchSelector from 'components/searchSelector/SearchSelector';

import EditUserHeader from 'pages/auth/editUser/commonComponents/header/EditUserHeader';

import { getMasterCountryList, getMasterCurrencyList, getMasterLanguageList, getMasterTimezoneList } from "redux/master/master.request";
import { updateUserCultureDetailByUserId } from "redux/user/user.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCountryList, setClearCurrencyList, setClearLanguageList, setClearTimeZoneList } from 'redux/master/master.slice';
import { timeFormatConst } from "redux/master/master.const";

const EditUserCulture = () => {
    const { languageList, timeZoneList, currencyList, countryList } = useAppState((state) => state.master)
    const { userDetail, addUserCultureDetail } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const { userId } = useParams()

    useEffect(() => {
        dispatcher(getMasterLanguageList())
        dispatcher(getMasterTimezoneList())
        dispatcher(getMasterCurrencyList())
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearLanguageList())
            dispatcher(setClearTimeZoneList())
            dispatcher(setClearCurrencyList())
            dispatcher(setClearCountryList())
        }
    }, [])


    return (
        <div className={""}>
            <EditUserHeader title={"User Culture"} />

            <div className={"grid grid-cols-12 gap-3 md:gap-5 lg:gap-8 p-5"}>

                {/* language container */}
                <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base lg:whitespace-nowrap"}>
                    {"Display Language"}
                </span>
                <SearchSelector
                    id={"languageList"}
                    isLoading={languageList?.isLoading || addUserCultureDetail?.isLoading}
                    options={languageList?.languageList?.map((language) => ({
                        label: language?.language,
                        value: language?.masterLanguageId
                    }))?.filter((language) => language?.label === "English") || []}
                    showGroupedOptions={false}
                    placeholder={"Select Language"}
                    selectedValue={{ label: userDetail?.userDetail?.userCulture?.displayLanguage?.displayLanguage || "", value: userDetail?.userDetail?.userCulture?.displayLanguage?.masterLanguageId }}
                    onHandleSelect={(option) => {
                        if (!option || addUserCultureDetail.isLoading || userDetail?.isLoading) return;
                        dispatcher(updateUserCultureDetailByUserId(Number(userId), { masterLanguageId: option?.value }))
                    }}
                />

                {/* timezone container */}
                <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
                    {"Timezone"}
                </span>

                <SearchSelector
                    id={"timezoneList"}
                    isLoading={timeZoneList?.isLoading || addUserCultureDetail?.isLoading}
                    options={timeZoneList?.timeZoneList?.map((timezone) => ({
                        label: `${timezone?.timeZone} (${timezone?.country})`,
                        value: timezone?.masterTimeZoneId
                    })) || []}
                    showGroupedOptions={true}
                    placeholder={"Select Timezone"}
                    selectedValue={{ label: userDetail?.userDetail?.userCulture?.timeZone?.timeZone || "", value: userDetail?.userDetail?.userCulture?.timeZone?.masterTimeZoneId }}
                    onHandleSelect={(option) => {
                        if (!option || addUserCultureDetail.isLoading || userDetail?.isLoading) return;
                        dispatcher(updateUserCultureDetailByUserId(Number(userId), { masterTimeZoneId: option?.value }))
                    }}
                />

                {/* currency container */}
                <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
                    {"Currency"}
                </span>

                <SearchSelector
                    id={"currencyList"}
                    isLoading={currencyList?.isLoading || addUserCultureDetail?.isLoading}
                    options={currencyList?.currencyList?.map((currency) => ({
                        label: `${currency?.code} (${currency?.country})`,
                        value: currency?.masterCurrencyId
                    })) || []}
                    showGroupedOptions={true}
                    placeholder={"Select Currency"}
                    selectedValue={{ label: userDetail?.userDetail?.userCulture?.currency?.currency || "", value: userDetail?.userDetail?.userCulture?.currency?.masterCurrencyId }}
                    onHandleSelect={(option) => {
                        if (!option || addUserCultureDetail.isLoading || userDetail?.isLoading) return;
                        dispatcher(updateUserCultureDetailByUserId(Number(userId), { masterCurrencyId: option?.value }))
                        // dispatcher(setLocals({
                        //     ...locals,
                        //     [localsConst.CURRENCY_CODE.value]: currencyList?.currencyList?.find(currency => currency?.masterCurrencyId === option?.value)?.code
                        // }))
                    }}
                />

                {/* time format container */}
                <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
                    {"Time Format"}
                </span>
                <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 flex items-center justify-start gap-8"}>
                    <div
                        className={"flex items-center justify-center gap-1 cursor-pointer"}
                        onClick={() => {
                            dispatcher(updateUserCultureDetailByUserId(Number(userId), { timeFormat: timeFormatConst.HRS_12.value }))
                        }}
                    >
                        <input
                            type={"radio"}
                            className={"border-2 border-text-100 hover:border-text-700 cursor-pointer"}
                            id={"time_format1"}
                            name={"time_format"}
                            checked={userDetail?.userDetail?.userCulture?.timeFormat === timeFormatConst.HRS_12.value && true}
                        />
                        <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wider"}>
                            {timeFormatConst.HRS_12.label}
                        </span>
                    </div>
                    <div
                        className={"flex items-center justify-center gap-1 cursor-pointer"}
                        onClick={() => {
                            dispatcher(updateUserCultureDetailByUserId(Number(userId), { timeFormat: timeFormatConst.HRS_24.value }))
                        }}
                    >
                        <input
                            type={"radio"}
                            className={"border-2 border-text-100 hover:border-text-700 cursor-pointer"}
                            id={"time_format2"}
                            name={"time_format"}
                            checked={userDetail?.userDetail?.userCulture?.timeFormat === timeFormatConst.HRS_24.value && true}
                        />
                        <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wider"}>
                            {timeFormatConst.HRS_24.label}
                        </span>
                    </div>
                </div>

                {/* edit from country section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"From:"}
                </span>

                <SearchSelector
                    id={"fromCountryList"}
                    isLoading={countryList?.isLoading || addUserCultureDetail.isLoading}
                    options={countryList?.countryList?.map((country) => ({
                        label: country?.country,
                        value: country?.masterCountryId,
                    })) || []}
                    showGroupedOptions={true}
                    placeholder={"Select Country"}
                    selectedValue={{ label: userDetail?.userDetail?.userCulture?.fromCountry?.country || "", value: userDetail?.userDetail?.userCulture?.fromCountry?.masterCountryId }}
                    onHandleSelect={(option) => {
                        if (!option || addUserCultureDetail.isLoading || userDetail?.isLoading) return;
                        dispatcher(updateUserCultureDetailByUserId(Number(userId), { fromMasterCountryId: option?.value }))
                    }}
                />

                {/* edit living in country section */}
                <span className={"col-start-1 col-span-2 font-bodyPri font-normal text-base text-text-700 tracking-wide whitespace-nowrap"}>
                    {"Living In:"}
                </span>

                <SearchSelector
                    id={"livingInCountryList"}
                    isLoading={countryList?.isLoading || addUserCultureDetail.isLoading}
                    options={countryList?.countryList?.map((country) => ({
                        label: country?.country,
                        value: country?.masterCountryId,
                    })) || []}
                    showGroupedOptions={true}
                    placeholder={"Select Country"}
                    selectedValue={{ label: userDetail?.userDetail?.userCulture?.liveInCountry?.country || "", value: userDetail?.userDetail?.userCulture?.liveInCountry?.masterCountryId }}
                    onHandleSelect={(option) => {
                        if (!option || addUserCultureDetail.isLoading || userDetail?.isLoading) return;
                        dispatcher(updateUserCultureDetailByUserId(Number(userId), { liveInMasterCountryId: option?.value }))
                    }}
                />
            </div>
        </div>
    )
}

export default EditUserCulture;