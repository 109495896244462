import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { referralHeaderConst } from "pages/auth/referral/data";

import { getReferralList } from "redux/referral/referral.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/referral/referral.slice";
import { referralStatus } from "redux/referral/referral.const";
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";
import { currencyRateConversion } from "utils/generators.utils";

const ReferralSearch = () => {
    const { locals } = useAppState((state) => state.local)
    const { referralList, filterProps } = useAppState((state) => state.referral)

    const dispatcher = useAppDispatcher()

    const header = Object.values(referralHeaderConst)?.filter((headerConst) => headerConst?.value !== referralHeaderConst?.ACTIVITY?.value)?.map((header) => header?.label)

    const body = referralList?.data?.results?.map((referralItem) => ({
        [referralHeaderConst.REFERRAL_ID.label]: referralItem?.id,
        [referralHeaderConst.REFEREE_USER.label]: referralItem?.refereeUser?.firstName + " " + referralItem?.refereeUser?.lastName?.charAt(0),
        [referralHeaderConst.BENEFICIARY_USER.label]: referralItem?.beneficiaryUser?.firstName + " " + referralItem?.beneficiaryUser?.lastName?.charAt(0),
        [referralHeaderConst.AMOUNT.label]: currencyRateConversion(locals?.currencyCode, referralItem?.amount),
        [referralHeaderConst.STATUS.label]: referralStatus[referralItem?.status?.toUpperCase()]?.label,
        [referralHeaderConst.DATE.label]: dayjs(referralItem?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A'),
        [referralHeaderConst.PUBLISHED_DATE.label]: dayjs(referralItem?.publishedAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A'),
    }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-referrals",
            sheet: "referral",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }

    const handleFilterSearch = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value }
        }));
    }

    const onHandleSearch = () => {
        if (referralList?.isLoading || !filterProps?.searchText) return;

        dispatcher(getReferralList({
            search: filterProps?.searchText,
            page: filterProps?.page || 1,
            records: filterProps?.records || 25
        }))
    }

    return (
        <div className={"w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between"}>
            <div className={"flex items-center justify-center gap-1.5"}>
                <span className={"font-bodyPri font-normal text-base text-text-700"}>
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className={"font-bodyPri font-normal text-base text-text-700"}>
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-courses"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className={"inline-flex mx-auto shadow-sm"}>
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here..."}
                        value={filterProps.searchText}
                        onChange={(event) => handleFilterSearch(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={onHandleSearch}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReferralSearch;