export const env = {
    LOCAL: {
        label: "Local",
        value: "local"
    },
    DEV: {
        label: "Dev",
        value: "dev"
    },
    STAGE: {
        label: "Stage",
        value: "stage"
    },
    PROD: {
        label: "Prod",
        value: "prod"
    },
}