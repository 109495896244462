import { createSlice } from "@reduxjs/toolkit";

import { WITHDRAWAL_INITIAL_STATE } from "redux/withdrawal/withdrawal.initialState";

import { getWithdrawalList, getWithdrawalDetailByWithdrawalId } from "redux/withdrawal/withdrawal.request";

const withdrawal = createSlice({
    name: "withdrawal",
    initialState: WITHDRAWAL_INITIAL_STATE,
    reducers: {
        // reducers for withdrawal list
        setClearWithdrawalList: (state) => {
            state.withdrawalList = WITHDRAWAL_INITIAL_STATE.withdrawalList
        },
        // reducers for withdrawal details
        setClearWithdrawalDetail: (state) => {
            state.withdrawalDetail = WITHDRAWAL_INITIAL_STATE.withdrawalDetail
        },

        setUpdateWithdrawalDetail: (state, {payload}) => {
            state.updateWithdrawalDetail.data = payload
        },
        resetUpdateWithdrawalDetail: (state) => {
            state.updateWithdrawalDetail = WITHDRAWAL_INITIAL_STATE.updateWithdrawalDetail
        },

        // reducers for  withdrawal details payload
        setWithdrawalDetailPayload: (state, { payload }) => {
            state.withdrawalDetail.withdrawalDetailPayload = payload
        },
        setClearWithdrawalDetailPayload: (state) => {
            state.withdrawalDetail.withdrawalDetailPayload = WITHDRAWAL_INITIAL_STATE.withdrawalDetail.withdrawalDetailPayload
        },

        // reducers for Txn Detail Payload
        setTxnDetailPayload: (state, { payload }) => {
            state.txnDetail.txnDetailPayload = payload
        },
        setClearTxnDetailPayload: (state) => {
            state.txnDetail.txnDetailPayload = WITHDRAWAL_INITIAL_STATE.txnDetail.txnDetailPayload
        },

        // reducer for filter props
        setClearFilterProps: (state) => {
            state.withdrawalList.filteredWithdrawalList = state.withdrawalList.withdrawalList
        }
    },

    extraReducers: {
        // reducers for withdrawal list
        [getWithdrawalList.pending]: (state) => {
            state.withdrawalList.isLoading = true
        },
        [getWithdrawalList.fulfilled]: (state, { payload }) => {
            state.withdrawalList.isLoading = false
            state.withdrawalList.withdrawalList = payload
            state.withdrawalList.filteredWithdrawalList = payload
            state.withdrawalList.errorMsg = WITHDRAWAL_INITIAL_STATE.withdrawalList.errorMsg
        },
        [getWithdrawalList.rejected]: (state, { payload }) => {
            state.withdrawalList.isLoading = false
            state.withdrawalList.errorMsg = payload
            state.withdrawalList.withdrawalList = WITHDRAWAL_INITIAL_STATE.withdrawalList.withdrawalList
            state.withdrawalList.filteredWithdrawalList = WITHDRAWAL_INITIAL_STATE.withdrawalList.filteredWithdrawalList
        },

        // reducers for withdrawal details
        [getWithdrawalDetailByWithdrawalId.pending]: (state) => {
            state.withdrawalDetail.isLoading = true
        },
        [getWithdrawalDetailByWithdrawalId.fulfilled]: (state, {payload}) => {
            state.withdrawalDetail.isLoading = false
            state.withdrawalDetail.withdrawalDetail = payload
            state.withdrawalDetail.errorMsg = WITHDRAWAL_INITIAL_STATE.withdrawalDetail.errorMsg
        },
        [getWithdrawalDetailByWithdrawalId.rejected]: (state, {payload}) => {
            state.withdrawalDetail.isLoading = false
            state.withdrawalDetail.errorMsg = payload
            state.withdrawalDetail.withdrawalDetail = WITHDRAWAL_INITIAL_STATE.withdrawalDetail.withdrawalDetail
        }
    }
})

export const {
    setClearWithdrawalList,
    
    setClearWithdrawalDetail,

    setUpdateWithdrawalDetail,
    resetUpdateWithdrawalDetail,
    
    setWithdrawalDetailPayload,
    setClearWithdrawalDetailPayload,

    setTxnDetailPayload,
    setClearTxnDetailPayload,

    setFilterProps,
    setClearFilterProps

} = withdrawal.actions

export default withdrawal.reducer;