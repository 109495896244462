import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import { OptionSelector } from 'components/common-components/Select';

import { oneOnOnePricingModelType, groupPricingModelType } from "pages/auth/viewCourseDetail/data";

import { useAppState } from 'hooks/useStore';
import { courseType, priceType } from "redux/course/course.const";

const RegularPriceTypeContainer = () => {
    const { courseDetail, coursePriceDetail } = useAppState((state) => state.course)

    const [regularPriceType, setRegularPriceType] = useState({
        course: courseDetail?.courseDetail?.id,
        type: priceType?.REGULAR?.value,
        priceModel: null,
        sessionCount: null,
        price: null,
        discountPct: 0
    })

    useEffect(() => {
        if (coursePriceDetail?.coursePriceDetail) {
            setRegularPriceType({
                ...regularPriceType,
                priceModel: coursePriceDetail?.coursePriceDetail?.priceModel,
                sessionCount: coursePriceDetail?.coursePriceDetail?.sessionCount,
                price: coursePriceDetail?.coursePriceDetail?.price,
                discountPct: coursePriceDetail?.coursePriceDetail?.discountPct
            })
        }
    }, [coursePriceDetail?.coursePriceDetail])

    const pricingModelType = (courseDetail?.courseDetail?.type === courseType?.ONE_ON_ONE?.value) ? oneOnOnePricingModelType : groupPricingModelType

    return (
        <div className={"col-start-1 col-span-full grid grid-cols-12 gap-5"}>
            <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3 font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap">
                {"Pricing Model"}
                <span className={"text-red-500 text-lg"}>{"*"}</span>
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full md:w-80"}>
                <OptionSelector
                    options={Object.values(pricingModelType)}
                    className={""}
                    value={regularPriceType?.priceModel}
                    disabled={true}
                />
            </div>

            {/* no of sessions container */}
            <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3 font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap">
                {"No of sessions"}
                <span className={"text-red-500 text-lg"}>{"*"}</span>
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full"}>
                <input
                    type={"number"}
                    className={cn(
                        "w-full md:w-80 px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-base",
                        "placeholder:text-text-500 placeholder:text-sm"
                    )}
                    placeholder={"Enter session count"}
                    disabled={true}
                />
            </div>

            {/* Price container */}
            <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3 font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap">
                {"Price"}
                <span className={"text-red-500 text-lg"}>{"*"}</span>
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full w-full md:w-80 inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                <span className={cn(
                    "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                )}>
                    {"USD"}
                </span>
                <input
                    autoFocus={true}
                    type="number"
                    className={cn(
                        "w-full px-1 py-1 bg-transparent input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                    )}
                    placeholder={"Price..."}
                    value={(regularPriceType?.price / 100)?.toFixed(2)?.toString()}
                    disabled={true}
                />
                <p className={cn("whitespace-nowrap font-bodyPri tracking-wide")}>
                    {regularPriceType?.priceModel === pricingModelType?.FLAT_FEE?.value && "flat fee"}
                    {regularPriceType?.priceModel === pricingModelType?.PER_HOUR?.value && "per hour"}
                    {regularPriceType?.priceModel === pricingModelType?.PER_SESSION?.value && "per session"}
                </p>
            </div>
            <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3 font-bodyPri font-normal text-text-700 text-base tracking-wide whitespace-nowrap">
                {"Discount"}
                <span className={"text-red-500 text-lg"}>{"*"}</span>
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-full w-full md:w-80 flex items-center justify-start gap-1 border-2 border-text-300 px-2 rounded-md"}>
                <input
                    type={"number"}
                    className={cn(
                        "w-full px-1 py-1 input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-2 font-bodyPri tracking-wide"
                    )}
                    value={regularPriceType?.discountPct?.toString()}
                    disabled={true}
                />
                <span className={cn("whitespace-nowrap")}>
                    {"%"}
                </span>
            </div>
        </div>
    )
}

export default RegularPriceTypeContainer;