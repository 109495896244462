import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/session/session.const";

class SessionService {
    getSessionList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_LIST),
            { params: query }
        )

        return response;
    }

    getSessionDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_DETAIL, params)
        )

        return response;
    }

    updateStartSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_START_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    updateScheduleSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_SCHEDULE_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    updateDeclineSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_DECLINE_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    updateRescheduleSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_RESCHEDULE_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    updateCompleteSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COMPLETE_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    getSessionCourseList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_COURSE_LIST),
            { params: query }
        )

        return response;
    }

    getSessionAppointmentList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_APPOINTMENT_LIST),
            { params: query }
        )

        return response;
    }

    createSessionUserDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADD_SESSION_USER_DETAIL, params),
            body
        )

        return response;
    }

    updateSessionUserDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_SESSION_USER_DETAIL, params),
            body
        )

        return response;
    }
}

export default new SessionService()