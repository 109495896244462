import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from 'pages/auth/sessions/data';
import SearchSessions from 'pages/auth/sessions/commonComponents/SearchSessions';
import DisplaySessions from "pages/auth/sessions/list/DisplaySessions";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const SessionsPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.SESSIONS))
    }, [dispatcher, currentPageInfo])

    return (
        <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3'>
            <PageHeader pageHeading={pageHeading} />
            <SearchSessions />
            <DisplaySessions />
        </div>
    )
}

export default SessionsPage;