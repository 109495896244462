
import { BsCheck2All } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

import ViewUserHeader from "pages/auth/viewUser/commonComponents/header/ViewUserHeader";

import { useAppState } from "hooks/useStore";

import { dayjs, timeZone } from "utils/dateTime.utils";

const ViewUserInformation = () => {
    const { userDetail } = useAppState((state) => state.user)

    return (
        <div className={"w-full space-y-5"}>
            <ViewUserHeader title={"User Information"} />

            <div className={"w-fit flex flex-col items-center justify-center gap-3 shadow-md rounded-lg border border-text-300 p-5"}>
                <div className={"relative w-20 h-20 rounded-full overflow-hidden border-[0.3rem] border-primary-light sm:w-20 sm:h-20 md:w-36 md:h-36"}>
                    <img
                        src={userDetail?.userDetail?.profilePicUrl}
                        alt={"profileImg"}
                        className="w-full h-full rounded-full p-2 object-cover"
                    />
                </div>
                <div className="w-full flex flex-col justify-center items-center gap-2 px-3">
                    <div className="flex items-center justify-start gap-2 font-bodyPri font-normal text-text-800 text-lg">
                        {userDetail?.userDetail?.firstName + " " + userDetail?.userDetail?.lastName.charAt(0) + "."}
                        {userDetail?.userDetail?.onlineStatus == "online" &&
                            <div
                                className={"w-3 h-3 rounded-full bg-green-600"}>
                            </div>
                        }
                    </div>
                    <div>
                        <span className="font-bodyPri font-normal text-sm text-text-700 tracking-wide">
                            {"Last Seen: "}
                        </span>
                        <span className="font-bodyPri font-normal text-text-800 text-sm">
                            {dayjs(userDetail?.userDetail?.lastActiveTime).tz(timeZone).format("MMM, D YYYY, hh:mm A")}
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-5 p-5">
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"User ID:"}
                    </span>
                    <span className="text-medium font-medium">
                        {userDetail?.userDetail?.userId || "-"}
                    </span>
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"Title:"}
                    </span>
                    <span className="text-medium font-medium">
                        {userDetail?.userDetail?.title || "-"}
                    </span>
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"First Name:"}
                    </span>
                    <span className="text-medium font-medium">
                        {userDetail?.userDetail?.firstName || "-"}
                    </span>
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"Last Name:"}
                    </span>
                    <span className="text-medium font-medium">
                        {userDetail?.userDetail?.lastName || "-"}
                    </span>
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"Preferred Name:"}
                    </span>
                    <span className="text-medium font-medium">
                        {userDetail?.userDetail?.preferredName || "-"}
                    </span>
                </div>
                <div className="flex items-center text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"Email:"}
                    </span>
                    <div className="flex items-center justify-center gap-1.5">
                        <span className="text-medium font-medium">
                            {userDetail?.userDetail?.email || "-"}
                        </span>
                        <div className={""}>
                            {userDetail?.userDetail?.isEmailVerified
                                && <span className="flex items-center justify-center gap-1">
                                    {"(Verified)"}
                                    <BsCheck2All className={"text-green-500 text-lg"} />
                                </span>
                            }
                            {!userDetail?.userDetail?.isEmailVerified
                                && <span className="flex items-center justify-center gap-1 whitespace-nowrap">
                                    {"(Not Verified)"}
                                    <TiDeleteOutline className={"text-red-500 text-lg"} />
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2 md:whitespace-nowrap">
                    <span className="text-text-600">
                        {"Mobile No.:"}
                    </span>
                    {(userDetail?.userDetail?.isdCode && userDetail?.userDetail?.mobileNo)
                        ? <div className="flex items-center justify-center gap-1.5">
                            <span className="text-medium font-medium">
                                {"+" + userDetail?.userDetail?.isdCode + "-" + userDetail?.userDetail?.mobileNo}
                            </span>
                            <div className={""}>
                                {userDetail?.userDetail?.isMobileNoVerified
                                    && <span className="flex items-center justify-center gap-1">
                                        {"(Verified)"}
                                        <BsCheck2All className={"text-green-500 text-lg"} />
                                    </span>
                                }
                                {!userDetail?.userDetail?.isMobileNoVerified
                                    && <span className="flex items-center justify-center gap-1 whitespace-nowrap">
                                        {"(Not Verified)"}
                                        <TiDeleteOutline className={"text-red-500 text-lg"} />
                                    </span>
                                }
                            </div>
                        </div>
                        : "-"
                    }
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"Gender:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {userDetail?.userDetail?.gender || "-"}
                    </span>
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"Date of Birth:"}
                    </span>
                    <span className="text-medium font-medium capitalize">
                        {userDetail?.userDetail?.dateOfBirth ?
                            dayjs(userDetail?.userDetail?.dateOfBirth)?.format("DD/MM/YYYY")
                            : "-"
                        }
                    </span>
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"Referral Code:"}
                    </span>
                    <span className="text-medium font-medium">
                        {userDetail?.userDetail?.referralCode || "-"}
                    </span>
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                    <span className="text-text-600">
                        {"Source URL:"}
                    </span>
                    <span className="text-medium font-medium">
                        {userDetail?.userDetail?.source_url || "-"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ViewUserInformation;