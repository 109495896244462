import { dayjs } from "utils/dateTime.utils";

export const weeklyTimeSlotObject = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
}

export const localsConst = {
    USER_ROLE: {
        label: "User Role",
        value: "userRole"
    },
    DISPLAY_LANGUAGE: {
        label: "Display Language",
        value: "displayLanguage"
    },
    CURRENCY_CODE: {
        label: "Currency Code",
        value: "currencyCode"
    },
    TIME_ZONE: {
        label: "Time zone",
        value: "timeZone"
    },
    TIME_FORMAT: {
        label: "Time Format",
        value: "timeFormat"
    }
}

export const socialAction = {
    SHARE_TUTOR_PROFILE: {
        label: "Share My Profile",
        value: "share_tutor_profile"
    }
}

export const stickyNoteCommentsType = {
    ONBOARDING: {
        value: 'onboarding',
        title: 'Onboarding Comments'
    },
    LEAD: {
        value: 'lead',
        title: 'Lead Comments'
    },
    COURSE: {
        value: 'course',
        title: 'Course Comments'
    }
}

export const modalConst = {
    ROLE_SWITCH_MODAL: {
        stateKey: 'isShowRoleSwitchModal'
    },
    SUBMIT_COMMENT_MODAL: {
        stateKey: 'isShowSubmitCommentModal'
    },
    SUBMIT_COURSE_MODAL: {
        stateKey: 'isShowSubmitCourseModal'
    },
    IMAGE_PICKER_MODAL: {
        stateKey: 'isShowImagePickerModal'
    },
    COMPANY_PEOPLE_MODAL: {
        stateKey: 'isShowCompanyPeopleModal'
    },
    PAYMENT_METHOD_MODAL: {
        stateKey: 'isShowPaymentMethodModal'
    },
    ADD_CREDIT_MODAL: {
        stateKey: 'isShowAddCreditModal'
    },
    WITHDRAW_MONEY_MODAL: {
        stateKey: 'isShowWithdrawMoneyModal'
    },
    CONFIRM_PAYOUT_MODAL: {
        stateKey: 'isShowConfirmPayoutModal'
    },
    WITHDRAWAL_REQUEST_SUBMIT_MODAL: {
        stateKey: 'isShowWithdrawalRequestSubmitModal'
    },
    VALIDATION_BAR_EMAIL_VERIFY_MODAL: {
        stateKey: 'isShowValidationBarEmailVerifyModal'
    },
    VALIDATION_BAR_MOBILE_VERIFY_MODAL: {
        stateKey: 'isShowValidationBarMobileVerifyModal'
    },
    STICKY_NOTE_MODAL: {
        stateKey: 'isShowStickyNoteModal'
    },
    UPLOAD_VIDEO_MODAL: {
        stateKey: 'isShowUploadVideoModal'
    },
    UPLOAD_DOCUMENT_MODAL: {
        stateKey: 'isShowUploadDocumentModal'
    },
    SEARCH_PHOTO_MODAL: {
        stateKey: 'isShowSearchPhotoModal'
    },
    ADD_COURSE_MODAL: {
        stateKey: 'isShowAddCourseModal'
    },
    ADD_SUBSCRIPTION_PLAN_MODAL: {
        stateKey: 'isShowAddSubscriptionPlanModal'
    },
    ADD_BOOKING_MODAL: {
        stateKey: 'isShowAddBookingModal'
    },
    BOOKING_SUMMARY_MODAL: {
        stateKey: 'isShowBookingSummaryModal'
    },
    ADD_BOOKING_SESSION_MODAL: {
        stateKey: 'isShowAddBookingSessionModal'
    },
    RESCHEDULE_TIME_SLOT_MODAL: {
        stateKey: 'isShowRescheduleTimeSlotModal'
    },
    ADD_USER_DETAIL_MODAL: {
        stateKey: 'isShowAddUserDetailModal'
    },
    VIEW_USER_AVAILABILITY_MODAL: {
        stateKey: 'isShowViewUserAvailabilityModal'
    },
    EDIT_USER_AVAILABILITY_MODAL: {
        stateKey: 'isShowEditUserAvailabilityModal'
    },
    ADD_NEXT_COURSE_SESSION_MODAL: {
        stateKey: 'isShowAddNextCourseSessionModal'
    },
    ADD_COURSE_TUTOR_MODAL: {
        stateKey: 'isShowAddCourseTutorModal'
    },
    ADD_SESSION_USER_MODAL: {
        stateKey: 'isShowAddSessionUserModal'
    },
    MANUAL_PAYMENT_DETAIL_MODAL: {
        stateKey: 'isShowManualPaymentDetailModal'
    },
    ADD_USER_WALLET_CREDIT_MODAL: {
        stateKey: 'isShowAddUserWalletCreditModal'
    },
    WALLET_CREDIT_SUCCESS_MODAL: {
        stateKey: 'isShowWalletCreditSuccessModal'
    }
}

export const dateRangeConst = {
    TODAY: {
        label: "Today"
    },
    YESTERDAY: {
        label: "Yesterday"
    },
    LAST_SEVEN_DAYS: {
        label: "Last 7 Days"
    },
    LAST_THIRTY_DAYS: {
        label: "Last 30 Days"
    },
    THIS_MONTH: {
        label: "This Month"
    },
    LAST_MONTH: {
        label: "Last Month"
    },
}

export const dataEntriesConst = {
    TWENTY_FIVE: {
        label: "25",
        value: "25"
    },
    FIFTY: {
        label: "50",
        value: "50"
    },
    SEVENTY_FIVE: {
        label: "75",
        value: "75"
    },
    HUNDRED: {
        label: "100",
        value: "100"
    }
}

export const dateRangesLabels = {
    [dateRangeConst.TODAY.label]: [dayjs().toDate(), dayjs().toDate()],
    [dateRangeConst.YESTERDAY.label]: [
        dayjs().subtract(1, 'days').toDate(),
        dayjs().subtract(1, 'days').toDate(),
    ],
    [dateRangeConst.LAST_SEVEN_DAYS.label]: [
        dayjs().subtract(6, 'days').toDate(),
        dayjs().toDate()
    ],
    [dateRangeConst.LAST_THIRTY_DAYS.label]: [
        dayjs().subtract(29, 'days').toDate(),
        dayjs().toDate()
    ],
    [dateRangeConst.THIS_MONTH.label]: [
        dayjs().startOf('month').toDate(),
        dayjs().endOf('month').toDate()
    ],
    [dateRangeConst.LAST_MONTH.label]: [
        dayjs().subtract(1, 'month').startOf('month').toDate(),
        dayjs().subtract(1, 'month').endOf('month').toDate(),
    ]
}

export const redirectionUrl = `?app_name=edulyte-admin&redirect_url=${window.location.href}`