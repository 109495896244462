import { useCallback, useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import MuiDatePicker from 'components/MUIComponents/MuiDatePicker';

import { actionButtons, sortDateTimeSlots } from 'components/availabilitySchedule/data';
import NonRecurringScheduleCard from 'components/availabilitySchedule/NonRecurringSchedule';
import ActionIconButtons from 'components/availabilitySchedule/ActionIconButtons';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddAvailabilityDetailPayload } from 'redux/availability/availability.slice';

import { dayjs, getTimeZoneOffset } from 'utils/dateTime.utils';

const NonRecurringSchedule = () => {
    const { addAvailabilityDetail } = useAppState((state) => state.availability)

    const dispatcher = useAppDispatcher()

    const timeZoneOffset = useMemo(() => getTimeZoneOffset(addAvailabilityDetail?.payload?.timeZone), [addAvailabilityDetail?.payload?.timeZone])

    const [selectedDateTimeSlot] = useState({
        date: dayjs()?.tz(addAvailabilityDetail?.payload?.timeZone)?.format("YYYY-MM-DD"),
        timeSlots: []
    })
    const [isShowDatePicker, setIsShowDatePicker] = useState(false)

    useEffect(() => {
        if (addAvailabilityDetail?.payload?.dateTimeSlots?.length === 0) {
            dispatcher(setAddAvailabilityDetailPayload({
                ...addAvailabilityDetail?.payload,
                dateTimeSlots: [selectedDateTimeSlot]
            }))
        }
    }, [addAvailabilityDetail?.payload?.dateTimeSlots])

    const onHandleAddDate = useCallback((date) => {
        if (addAvailabilityDetail?.isLoading) return;

        const selectedDayjs = dayjs(date?.format("YYYY-MM-DD") + timeZoneOffset, "YYYY-MM-DDZ")?.tz(addAvailabilityDetail?.payload?.timeZone)
        if (addAvailabilityDetail?.payload?.dateTimeSlots?.length > 0) {
            const filteredDateTimeSlots = addAvailabilityDetail?.payload?.dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.filter((dateTimeSlot) => {
                const slotDayjs = dayjs(dateTimeSlot?.date + timeZoneOffset, "YYYY-MM-DDZ")?.tz(addAvailabilityDetail?.payload?.timeZone)
                return slotDayjs.isSame(selectedDayjs, "day")
            })
            if (filteredDateTimeSlots?.length > 0) {
                toast.warn("Same dates cannot be added more than once!")
                return;
            }
        }

        const updatedDate = selectedDayjs?.format("YYYY-MM-DD")
        dispatcher(setAddAvailabilityDetailPayload({
            ...addAvailabilityDetail?.payload,
            dateTimeSlots: [
                ...addAvailabilityDetail?.payload?.dateTimeSlots,
                {
                    date: updatedDate, timeSlots: []
                }
            ]
        }))
        setIsShowDatePicker(false)
    }, [isShowDatePicker, addAvailabilityDetail?.payload])

    const saveDateTimeSlots = async (localDateTimeSlots) => {
        if (addAvailabilityDetail?.isLoading) return;

        dispatcher(setAddAvailabilityDetailPayload({
            ...addAvailabilityDetail?.payload,
            dateTimeSlots: localDateTimeSlots
        }))
    }

    return (
        <div className={"w-full flex flex-col gap-5 md:gap-8"}>
            {(!!addAvailabilityDetail?.payload?.dateTimeSlots?.length) &&
                addAvailabilityDetail?.payload?.dateTimeSlots?.slice()?.sort(sortDateTimeSlots)?.map((dateTimeSlot, index) => (
                    <NonRecurringScheduleCard
                        key={index}
                        isLoading={addAvailabilityDetail?.isLoading}
                        selectedTimeZone={addAvailabilityDetail?.payload?.timeZone}
                        dateTimeSlots={addAvailabilityDetail?.payload?.dateTimeSlots}
                        dateTimeSlot={dateTimeSlot}
                        saveDateTimeSlots={saveDateTimeSlots}
                    />
                ))
            }
            {((addAvailabilityDetail?.payload?.dateTimeSlots?.length === 0) || !addAvailabilityDetail?.payload?.dateTimeSlots) &&
                <div className={"space-y-3"}>
                    <NonRecurringScheduleCard
                        isLoading={addAvailabilityDetail?.isLoading}
                        selectedTimeZone={addAvailabilityDetail?.payload?.timeZone}
                        dateTimeSlots={addAvailabilityDetail?.payload?.dateTimeSlots}
                        dateTimeSlot={selectedDateTimeSlot}
                        saveDateTimeSlots={saveDateTimeSlots}
                    />
                </div>
            }

            <div className={"h-0.5 bg-divider-medium w-full"}></div>

            {!isShowDatePicker &&
                <span
                    className={"w-fit font-bodyPri font-normal text-primary-dark text-md cursor-pointer"}
                    onClick={() => setIsShowDatePicker(true)}
                >
                    {"Add a date"}
                </span>
            }
            {isShowDatePicker &&
                <div className={"w-full grid grid-cols-4 gap-5"}>
                    <div className={"col-start-1 col-span-1"}>
                        <MuiDatePicker
                            label={"Select Date"}
                            onChange={(date) => onHandleAddDate(date)}
                            slotProps={{ textField: { size: "small" } }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#9e9e9e",
                                        height: 50,
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#2196f3",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    fontWeight: '700',
                                    color: '#212121',
                                },
                            }}
                            autoFocus={true}
                            format={"DD-MM-YYYY"}
                            color={"primary"}
                            disablePast={true}
                            focused={true}
                            helperText={"SelectDate"}
                            margin={"dense"}
                            minDate={dayjs().tz(addAvailabilityDetail?.payload?.timeZone)}
                            name={"date"}
                        />
                    </div>
                    <div className={"col-start-2 col-span-2 invisible"}></div>
                    <div className={"col-start-4 col-span-full"}>
                        <ActionIconButtons
                            isLoading={addAvailabilityDetail?.isLoading}
                            iconButtonList={Object.values(actionButtons)?.filter((iconButton) => iconButton?.value === actionButtons.DELETE.value)}
                            onHandleIconButton={() => setIsShowDatePicker(false)}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default NonRecurringSchedule;