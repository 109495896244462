import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import SelectSearch, { fuzzySearch } from 'react-select-search';

import { OptionSelector } from "components/common-components/Select";
import FullPageLoader from "components/loader/FullPageLoader";

import { getAllCategoryList } from "redux/category/category.request";
import { getMasterLanguageList, getMasterProficiencyList } from "redux/master/master.request";
import { getOnboardingTutorTrialCourseDetailByOnboardingId, updateOnboardingTutorTrialCourseDetailByOnboardingId } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearLanguageList, setClearProfList } from "redux/master/master.slice";
import { setClearCategoryList } from "redux/category/category.slice";
import { setClearOnboardingTutorTrialCourseDetail, setOnboardingTutorTrialCourseDetail } from "redux/onboarding/onboarding.slice";
import { trialClassStatus } from "redux/onboarding/onboarding.const";
import { courseStatus, courseType } from "redux/course/course.const";
import { bookingType } from "redux/course/course.const";

// import { dayjs, timeZone } from "utils/dateTime.utils";

const EditTrialClass = () => {
    const { categoryList } = useAppState((s) => s.category)
    const { languageList, profList } = useAppState((s) => s.master)
    const { onboardingTutorTrialCourseDetail, addOnboardingTutorTrialCourseDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [trialCourseStatus, setTrialCourseStatus] = useState()
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedLanguageProf, setSelectedLanguageProf] = useState(null)

    useEffect(() => {
        dispatcher(getAllCategoryList())
        dispatcher(getMasterProficiencyList())
        dispatcher(getMasterLanguageList())

        return () => {
            dispatcher(setClearCategoryList())
            dispatcher(setClearProfList())
            dispatcher(setClearLanguageList())
        }
    }, [])

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingTutorTrialCourseDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingTutorTrialCourseDetail())
        }
    }, [onboardingId])

    useEffect(() => {
        if (onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.category) {
            setSelectedCategory(onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.category)
        }

        if (onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course) {
            setSelectedLanguageProf({
                language: onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.language,
                proficiency: onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.proficiency
            })
        }
    }, [onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail])

    useEffect(() => {
        if (onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status) {
            setTrialCourseStatus(onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status)
        }
    }, [onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status])


    const onHandleSubmit = (event) => {
        event.preventDefault()

        // if (trialCourseStatus === onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status) return;

        if (!selectedCategory) {
            toast.error("Please select Category!")
            return;
        }

        if (!selectedLanguageProf?.language) {
            toast.error("Please select language!")
            return;
        }
        if (!selectedLanguageProf?.proficiency) {
            toast.error("Please select proficiency!")
            return;
        }

        const body = {
            status: trialCourseStatus,
            course: {
                courseTitle: onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseTitle,
                courseTitleDescription: onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseTitleDescription,
                courseDescription: onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseDescription,
                category: selectedCategory,
                masterProficiencyId: profList?.profList?.filter((prof) => (
                    prof?.proficiency === selectedLanguageProf?.proficiency
                ))[0]?.masterProficiencyId,
                masterLanguageId: languageList?.languageList?.filter((language) => (
                    language?.language === selectedLanguageProf?.language
                ))[0]?.masterLanguageId,
            }
        }
        const requestData = {
            onboardingId: Number(onboardingId),
            body: body
        }
        dispatcher(updateOnboardingTutorTrialCourseDetailByOnboardingId(requestData))
    }

    if (onboardingTutorTrialCourseDetail?.isLoading || addOnboardingTutorTrialCourseDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingTutorTrialCourseDetail?.isLoading || addOnboardingTutorTrialCourseDetail?.isLoading} />
        )
    }

    return (
        <div className={"space-y-3 overflow-auto"}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-5 py-3",
                !onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status && "bg-yellow-100",
                onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status && `bg-${trialClassStatus[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status?.toUpperCase()]?.lightColor}`
            )}>
                {"Trial Class Details"}
            </div>
            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail &&
                <form className={"px-5 py-1.5"} onSubmit={onHandleSubmit}>
                    <div className={"grid grid-cols-12 gap-5 w-full p-5"}>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Status:"}
                        </div>
                        <div className={cn(
                            "col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4",
                            "font-bodyPri font-medium text-medium tracking-wide capitalize",
                            `text-${trialClassStatus[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status?.toUpperCase()]?.darkColor}`
                        )}>
                            {trialClassStatus[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status?.toUpperCase()]?.label}
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Class Id:"}
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4 text-medium font-medium tracking-wide capitalize"}>
                            {onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseId}
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Class Title:"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            <input
                                type={"text"}
                                placeholder={"e.g. Fun Mathematics"}
                                maxLength={50}
                                value={onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseTitle}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base capitalize",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => {
                                    dispatcher(setOnboardingTutorTrialCourseDetail({
                                        ...onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail,
                                        course: {
                                            ...onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course,
                                            courseTitle: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                                        }
                                    }))
                                }}
                                required={true}
                            />
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Sub Title"}
                            <span className={"text-red-500 text-lg"}>{"*"}
                            </span>
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            <input
                                type={"text"}
                                placeholder={"e.g. Learn fun math"}
                                maxLength={150}
                                value={onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseTitleDescription}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(event) => dispatcher(setOnboardingTutorTrialCourseDetail({
                                    ...onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail,
                                    course: { ...onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course, courseTitleDescription: event?.target?.value }
                                }))}
                                required={true}
                            />
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide lg:whitespace-nowrap"}>
                            {"Class Description:"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            <textarea
                                type={"text"}
                                placeholder={"Enter text here..."}
                                rows={5}
                                maxLength={700}
                                value={onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseDescription}
                                className={cn(
                                    "w-full px-3 py-1 resize-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(event) => dispatcher(setOnboardingTutorTrialCourseDetail({
                                    ...onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail,
                                    course: { ...onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course, courseDescription: event?.target?.value }
                                }))}
                                required={true}
                            />
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Class Type:"}
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            <input
                                type={"text"}
                                placeholder={""}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={courseType[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.courseType?.toUpperCase()]?.label}
                                required={true}
                                disabled={true}
                            />
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Booking Type:"}
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            <input
                                type={"text"}
                                placeholder={""}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={bookingType[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.bookingType?.toUpperCase()]?.label}
                                required={true}
                                disabled={true}
                            />
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Category:"}
                            <span className={"text-red-500 text-lg"}>{"*"}
                            </span>
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            {categoryList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {categoryList?.categoryList &&
                                <div className={cn(
                                    "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}>
                                    <SelectSearch
                                        options={categoryList?.categoryList?.map((category) => ({
                                            name: category.category,
                                            value: category.category,
                                        }))}
                                        search
                                        autoFocus={false}
                                        placeholder={"Select Category"}
                                        filterOptions={fuzzySearch}
                                        value={selectedCategory}
                                        onChange={(option) => setSelectedCategory(option)
                                        }
                                        required={true}
                                    />
                                </div>
                            }
                            {categoryList?.errorMsg &&
                                <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                    {categoryList?.errorMsg}
                                </div>
                            }
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Language:"}
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            {languageList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {languageList?.languageList &&
                                <div className={""}>
                                    <OptionSelector
                                        options={languageList?.languageList?.map((language) => ({
                                            label: language?.language,
                                            value: language?.language
                                        }))}
                                        value={selectedLanguageProf?.language}
                                        onChange={(option) => {
                                            if (!option?.value) {
                                                alert("Please select any!")
                                                return;
                                            }
                                            setSelectedLanguageProf({
                                                ...selectedLanguageProf,
                                                language: option?.value
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {languageList?.errorMsg &&
                                <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                    {languageList?.errorMsg}
                                </div>
                            }
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Proficiency:"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            {profList?.isLoading &&
                                <div className={"flex items-center justify-start gap-0.5"}>
                                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                                        {"Please wait ..."}
                                    </span>
                                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                                </div>
                            }
                            {profList?.profList &&
                                <div className={""}>
                                    <OptionSelector
                                        options={profList?.profList?.map((proficiency) => ({
                                            label: proficiency?.proficiency,
                                            value: proficiency?.proficiency
                                        }))}
                                        value={selectedLanguageProf?.proficiency}
                                        onChange={(option) => {
                                            if (!option?.value) {
                                                alert("Please select any!")
                                                return;
                                            }
                                            setSelectedLanguageProf({
                                                ...selectedLanguageProf,
                                                proficiency: option?.value
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {profList?.errorMsg &&
                                <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                    {profList?.errorMsg}
                                </div>
                            }
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide lg:whitespace-nowrap"}>
                            {"Session Duration:"}
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            <input
                                type={"text"}
                                placeholder={""}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.sessionDuration}
                                required={true}
                                disabled={true}
                            />
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Price:"}
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4"}>
                            <input
                                type={"number"}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={parseFloat(onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.price / 100)?.toFixed(2)}
                                required={true}
                                disabled={true}
                            />
                        </div>

                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Course Status:"}
                        </div>
                        <div className={cn(
                            "col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4",
                            "font-bodyPri font-medium text-medium tracking-wide capitalize",
                            `text-${courseStatus[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.status?.toUpperCase()]?.darkColor}`
                        )}>
                            {courseStatus[onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.course?.status?.toUpperCase()]?.label}
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-base text-text-600 tracking-wide"}>
                            {"Status:"}
                        </div>
                        <div className={"col-start-1 col-span-full md:col-start-5 md:col-span-full lg:col-start-3 lg:col-span-4 h-36"}>
                            <OptionSelector
                                options={Object.values(trialClassStatus)}
                                className={"w-full"}
                                onChange={(option) => setTrialCourseStatus(option?.value)}
                                value={trialCourseStatus}
                            />
                        </div>
                    </div>
                    <div className={"flex items-center justify-start gap-5"}>
                        <button
                            type={"submit"}
                            disabled={trialCourseStatus === onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status}
                            className={cn(
                                "w-24 px-3 py-1 flex justify-center items-center rounded-lg",
                                "border border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                                // trialCourseStatus === onboardingTutorTrialCourseDetail?.onboardingTutorTrialCourseDetail?.status && "border border-text-300 text-text-500"
                            )}
                        >
                            {"Save"}
                        </button>
                        <div
                            className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                "border border-primary-main text-primary-main",
                                "hover:bg-primary-main hover:text-text-50"
                            )}
                        // onClick={() => dispatcher(getPersonalDetailByUserId(Number(userId)))}
                        >
                            {"Cancel"}
                        </div>
                    </div>
                </form>
            }
            {(onboardingTutorTrialCourseDetail?.errorMsg || addOnboardingTutorTrialCourseDetail?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingTutorTrialCourseDetail?.errorMsg || addOnboardingTutorTrialCourseDetail?.errorMsg}
                </div>
            }
        </div>
    )
}

export default EditTrialClass