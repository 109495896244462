import { memo } from 'react';

import { bookingStatusEnum } from 'redux/booking/booking.const';
import { courseType } from 'redux/course/course.const';

const BookingAppointment = memo(({ bookingUserDetail }) => {

    return (
        <div className="p-2 shadow-sm">
            <span className="text-lg font-semibold font-bodyPri tracking-wide text-text-900 text-left text-clip">
                {bookingUserDetail?.appointment?.title}
            </span>
            <div className="grid gap-3 pt-4 mt-2 text-sm md:grid-cols-2 opacity-70 place-items-start">
                {/* <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
            <p>{"CATEGORY:"}</p>
            <p className="font-bold truncate">
              {bookingUserDetail?.course?.category?.length > 17
                ? bookingUserDetail?.course?.category?.slice(0, 17) + "..."
                : bookingUserDetail?.course?.category
              }
            </p>
          </div> */}
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"SESSION TYPE:"}</p>
                    <p className="font-bold capitalize">{courseType?.ONE_ON_ONE?.label}</p>
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"SESSION(S):"}</p>
                    <p className="font-bold">{bookingUserDetail?.bookingPrice?.sessionCount}</p>
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"DURATION:"}</p>
                    <p className="font-bold">
                        {bookingUserDetail?.bookingPrice?.duration + " min."}
                    </p>
                </div>
                <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"STATUS:"}</p>
                    <p className={`font-bold text-${bookingStatusEnum[bookingUserDetail?.status?.toUpperCase()]?.darkColor}`}>
                        {bookingStatusEnum[bookingUserDetail?.status?.toUpperCase()]?.label}
                    </p>
                </div>
            </div>
        </div>
    )
})

export default BookingAppointment;