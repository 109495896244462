import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { cn } from "utils/cn.utils";

import { OptionSelector } from "components/common-components/Select";

import { withdrawalHeaderConst } from "pages/auth/withdrawal/data";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/withdrawal/withdrawal.slice";
import { payoutType, withdrawalStatus } from "redux/withdrawal/withdrawal.const";
import { dataEntriesConst } from "redux/local/local.const";

import { dayjs, timeZone } from "utils/dateTime.utils";
import { getWithdrawalList } from "redux/withdrawal/withdrawal.request";

const WithdrawalSearch = () => {
    const { withdrawalList, filterProps } = useAppState((state) => state.withdrawal)

    const dispatcher = useAppDispatcher()

    const header = Object.values(withdrawalHeaderConst).filter((headerConst) => headerConst?.value !== withdrawalHeaderConst?.ACTIVITY?.value).map((header) => header?.label)

    const body = withdrawalList?.withdrawalList?.results?.length > 0
        && withdrawalList?.withdrawalList?.results?.map((withdrawal) => ({
            [withdrawalHeaderConst?.USER_ID?.label]: withdrawal?.user?.userId || "-",
            [withdrawalHeaderConst?.WITHDRAWAL_ID?.label]: withdrawal?.withdrawalId || "-",
            [withdrawalHeaderConst?.GATEWAY_PAYMENT_ID?.label]: withdrawal?.gatewayPaymentId || "-",
            [withdrawalHeaderConst?.VIRTUAL_PAYMENT_ID?.label]: withdrawal?.virtualTxnId || "-",
            [withdrawalHeaderConst?.PAYOUT_TYPE?.label]: payoutType[withdrawal?.payoutType?.toUpperCase()]?.label || "-",
            [withdrawalHeaderConst?.WITHDRAWAL_AMOUNT?.label]: "USD " + (withdrawal?.amount / 100).toFixed(2) || "-",
            [withdrawalHeaderConst?.STATUS?.label]: withdrawalStatus[withdrawal?.status?.toUpperCase()]?.label || "-",
            [withdrawalHeaderConst?.DATE?.label]: dayjs(withdrawal?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A') || "-"
        }))

    const downloadExcelSheet = () => {
        downloadExcel({
            fileName: "Admin Edulyte Panel-withdrawals",
            sheet: "withdrawal",
            tablePayload: {
                header,
                body: body || []
            }
        })
    }
    const onHandleChangeText = (event) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, searchText: event.target.value }
        }))
    }

    const onHandleSearchFilter = () => {
        dispatcher(getWithdrawalList({ page: filterProps?.page, records: filterProps?.records, searchText: filterProps?.searchText }))
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5 px-5 py-3 bg-white rounded-lg md:flex-row md:justify-between">
            <div className="flex items-center justify-center gap-1.5">
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Show"}
                </span>
                <OptionSelector
                    options={Object.values(dataEntriesConst)}
                    className={"w-full"}
                    value={filterProps?.records}
                    onChange={(option) => dispatcher(setFilterProps({
                        filterProps: { ...filterProps, records: option?.value }
                    }))}
                />
                <span className="font-bodyPri font-normal text-base text-text-700">
                    {"Entries"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-5"}>
                <span
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                    onClick={downloadExcelSheet}
                >
                    {"Export Excel"}
                </span>
                <CSVLink
                    data={body || []}
                    filename={"Admin Edulyte Panel-withdrawals"}
                    className={cn(
                        "px-3 py-1 bg-primary-light text-primary-main hover:bg-primary-main hover:text-white rounded-md cursor-pointer",
                        "font-bodyPri font-normal text-base whitespace-nowrap"
                    )}
                >
                    {"Export CSV"}
                </CSVLink>
            </div>
            <div>
                <div className="inline-flex mx-auto shadow-sm">
                    <input
                        type={"text"}
                        className={cn(
                            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                            "border-sky-200 w-full sm:w-80 md:w-full lg:w-80"
                        )}
                        placeholder={"Search here..."}
                        value={filterProps?.searchText}
                        onChange={(event) => onHandleChangeText(event)}
                    />
                    <div>
                        <button
                            className={cn(
                                "px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white",
                                "font-bodyPri font-normal text-base"
                            )}
                            onClick={onHandleSearchFilter}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WithdrawalSearch;
