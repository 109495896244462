import { useState } from "react";
import { cn } from "utils/cn.utils";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { dateRangesLabels } from "redux/local/local.const";

import { dayjs } from "utils/dateTime.utils";

const SelectDate = () => {
    const dispatcher = useAppDispatcher()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: dayjs(dayjs().subtract(29, 'days'))?.format("DD/MMM/YYYY"),
        endDate: dayjs()?.format("DD/MMM/YYYY")
    })

    const handleApply = (picker) => {
        const startDate = dayjs(picker?.startDate?._d)?.format("DD/MMM/YYYY")
        const endDate = dayjs(picker?.endDate?._d)?.format("DD/MMM/YYYY")
        setSelectedDateRange({
            startDate: startDate,
            endDate: endDate
        })
    }

    return (
        <div className={"w-full flex justify-end"}>
            <DateRangePicker
                initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                        cancelLabel: 'Cancel',
                    },
                    ranges: dateRangesLabels
                }}
                onApply={(event, picker) => handleApply(picker)}
                onCancel={(event, picker) => setSelectedDateRange(selectedDateRange)}
            >
                <input
                    type={"text"}
                    defaultValue={""}
                    className={cn(
                        "w-52 px-1 py-1 flex items-center justify-center gap-1 bg-white cursor-pointer",
                        "border-2 border-text-400 rounded-md focus:outline-none focus:border-2 focus:border-text-500",
                        "font-bodyPri font-normal text-text-700 text-base"
                    )}
                    value={`${selectedDateRange?.startDate}-${selectedDateRange?.endDate}`}
                />
            </DateRangePicker>
        </div>
    )
}

export default SelectDate