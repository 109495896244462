import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { FiEdit } from 'react-icons/fi';
import { MdAdd } from "react-icons/md";
import { MdOutlineDeleteOutline } from 'react-icons/md';

import ComponentLoader from "components/loader/ComponentLoader";
import DashedBorderButton from "components/buttons/dashedBorderButton";

import SaveAndCancelBtn from "pages/auth/editUser/commonComponents/buttons/SaveAndCancelBtn";
import EditUserHeader from "pages/auth/editUser/commonComponents/header/EditUserHeader";

import {
    createUserEducationDetailByUserId,
    updateEducationDetailByUserEducationId,
    deleteEducationDetailByUserEducationId,
    getUserEducationListByUserId
} from "redux/user/user.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearUserEducationList } from "redux/user/user.slice";

import { cn } from "utils/cn.utils";

const EditUserEducation = () => {
    const { userEducationList, addUserEducationDetail } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const { userId } = useParams()

    useEffect(() => {
        if (userId) {
            dispatcher(getUserEducationListByUserId(Number(userId)))
        }

        return () => {
            dispatcher(setClearUserEducationList())
        }
    }, [userId])

    const [isShowAddButton, setIsShowAddButton] = useState(true)
    const [isAddNewEducationEnabled, setIsAddNewEducationEnabled] = useState(false)
    const [userEducation, setUserEducation] = useState({
        isUpdateUserEducation: false,
        userEducation: null,
        userEducationId: null
    })

    const onHandleAddEducation = () => {
        setIsShowAddButton(false)
        setIsAddNewEducationEnabled(true)
        setUserEducation({
            isUpdateUserEducation: false,
            userEducationId: null,
            userEducation: null
        })
    }

    const removeUserEducation = (education) => {
        dispatcher(deleteEducationDetailByUserEducationId(education?.userEducationId))
    }

    const onHandleUpdateEducation = () => {
        const requestDataPayload = {
            userEducationId: userEducation?.userEducationId,
            body: {
                education: userEducation?.userEducation
            }
        }
        dispatcher(updateEducationDetailByUserEducationId(requestDataPayload))
        setIsAddNewEducationEnabled(false)
        setIsShowAddButton(true)
    }

    const onSaveNewQualification = () => {
        if (userEducation?.isUpdateUserEducation) {
            onHandleUpdateEducation()
            return;
        }
        if (userEducation?.userEducation?.length > 0) {
            const filteredEducationList = userEducationList?.userEducationList?.filter((education) => (education?.education === userEducation?.userEducation))
            if (filteredEducationList?.length > 0) {
                alert("Duplicate education name is not allowed!")
                return;
            }

            const requestDataPayload = {
                userId: Number(userId),
                body: {
                    education: userEducation?.userEducation
                }
            }

            dispatcher(createUserEducationDetailByUserId(requestDataPayload))
            setIsAddNewEducationEnabled(false)
            setIsShowAddButton(true)
        } else {
            alert("Please enter your qualification")
        }
    }

    return (
        <div className={"space-y-3"}>
            <EditUserHeader title={"User Education"} />

            <div className={"p-5 space-y-5"}>
                {(userEducationList?.isLoading || addUserEducationDetail?.isLoading) &&
                    <ComponentLoader isLoading={userEducationList?.isLoading || addUserEducationDetail?.isLoading} />
                }
                {(!userEducationList?.isLoading && !addUserEducationDetail?.isLoading) &&
                    <>
                        <div className={"flex flex-col space-y-3 flex-wrap justify-start items-start"}>
                            {(userEducationList?.userEducationList?.length > 0)
                                && userEducationList?.userEducationList?.map((education, idx) => (
                                    <div className={"flex items-center gap-5"} key={idx}>
                                        <span className="w-max px-4 py-1 border-2 border-text-300 rounded-xl text-text-900 font-medium font-bodyPri">
                                            {education?.education}
                                        </span>

                                        <div className={"flex items-center justify-start gap-1.5"}>
                                            <FiEdit
                                                className={"text-lg text-text-500 cursor-pointer"}
                                                onClick={() => {
                                                    setUserEducation({
                                                        isUpdateUserEducation: true,
                                                        userEducationId: education?.userEducationId,
                                                        userEducation: education?.education,
                                                    })
                                                    setIsShowAddButton(false)
                                                    setIsAddNewEducationEnabled(true)
                                                }}
                                            />
                                            <MdOutlineDeleteOutline
                                                className={"text-xl text-text-500 cursor-pointer"}
                                                onClick={() => removeUserEducation(education)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {(userEducationList?.userEducationList?.length === 0) &&
                                <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                    {"No Qualifications Added"}
                                </span>
                            }
                            {(userEducationList?.errorMsg || addUserEducationDetail?.errorMsg) &&
                                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                    {userEducationList?.errorMsg || addUserEducationDetail?.errorMsg || "No Qualifications Added"}
                                </span>
                            }
                            <DashedBorderButton
                                isShowBtn={isShowAddButton && (!userEducationList?.userEducationList || userEducationList?.userEducationList?.length === 0)}
                                btnTitle={"Add Education(s)"}
                                onHandleAdd={onHandleAddEducation}
                            />
                            {(isShowAddButton && userEducationList?.userEducationList && userEducationList?.userEducationList?.length >= 1) &&
                                <div
                                    className={cn(
                                        "flex item-center justify-center cursor-pointer",
                                        "font-buttons font-normal text-base text-text-700",
                                        "hover:text-text-800"
                                    )}
                                    onClick={onHandleAddEducation}
                                >
                                    <MdAdd className={"text-xl"} />
                                    <span className={"whitespace-nowrap"}>
                                        {"Add Education"}
                                    </span>
                                </div>
                            }
                        </div>
                        {isAddNewEducationEnabled &&
                            <div className={"pt-3 flex flex-col justify-start items-start gap-3"}>
                                <input
                                    type={"text"}
                                    className={cn(
                                        "px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                        "font-bodyPri font-normal text-text-800 text-base",
                                        "placeholder:text-text-500 placeholder:text-sm"
                                    )}
                                    autoFocus={true}
                                    placeholder={"e.g. Masters, Graduate"}
                                    value={userEducation?.userEducation && userEducation?.userEducation?.charAt(0)?.toUpperCase() + (userEducation?.userEducation?.slice(1))}
                                    onChange={(e) => setUserEducation({
                                        ...userEducation,
                                        userEducation: e.target.value
                                    })}
                                />
                                <SaveAndCancelBtn
                                    onSave={() => onSaveNewQualification()}
                                    onCancel={() => {
                                        setUserEducation({
                                            isUpdateUserEducation: false,
                                            userEducationId: null,
                                            userEducation: null
                                        })
                                        setIsAddNewEducationEnabled(false)
                                        setIsShowAddButton(true)
                                    }}
                                />
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default EditUserEducation;
