import { addCreditToWalletPayload } from "./wallet.const";

const userWithdrawalDetailPayload = {
    amount: null,
    withdrawMethod: null
}

export const WALLET_INITIAL_STATE = {
    addCreditToWallet: {
        isLoading: false,
        payload: addCreditToWalletPayload,
        data: null,
        message: null,
    },

    walletConfirmation: {
        isLoading: false,
        walletConfirmation: null,
        errorMsg: null
    },

    walletTxnList: {
        isLoading: false,
        walletTxnList: null,
        errorMsg: null,
    },

    myWithdrawalList: {
        isLoading: true,
        myWithdrawalList: null,
        filteredMyWithdrawalList: null,
        errorMsg: null
    },
    userWithdrawalList: {
        isLoading: false,
        userWithdrawalList: null,
        filteredUserWithdrawalList: null,
        errorMsg: null
    },
    addUserWithdrawalDetail: {
        isLoading: false,
        userWithdrawalDetailPayload: userWithdrawalDetailPayload,
        addUserWithdrawalDetail: null,
        errorMsg: null
    },
}