import { useEffect } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';

import { getAppointmentDetail } from 'redux/appointment/appointment.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetAppointmentDetail } from "redux/appointment/appointment.slice";
import { appointmentPriceModelEnum } from "redux/appointment/appointment.const";

const AppointmentDetailCard = () => {
    const { tutorDetail } = useAppState((state) => state.tutor)
    const { appointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (tutorDetail?.data?.appointment?.id) {
            dispatcher(getAppointmentDetail({ appointmentId: tutorDetail?.data?.appointment?.id }))
        }

        return () => {
            dispatcher(resetAppointmentDetail())
        }
    }, [tutorDetail?.data?.appointment?.id])

    if (appointmentDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={appointmentDetail?.isLoading} />
        )
    }

    return (
        <div className={""}>
            <div className={"w-full p-4 bg-blue-100 rounded-lg shadow-md"}>
                <div className="font-bodyPri font-medium text-lg text-text-900 line-clamp-1">
                    {appointmentDetail?.data?.result?.title}
                </div>
                <div className={"font-bodyPri font-normal text-text-800 text-sm truncate line-clamp-2"}>
                    {appointmentDetail?.data?.result?.subtitle}
                </div>
            </div>
            <div className="px-2 divide-y divide-blue-200 bg-blue-50">

                <div className={"w-full flex flex-col items-start justify-start gap-3 p-4"}>
                    <div className={"w-full flex items-center justify-start gap-5"}>

                        {(appointmentDetail?.data?.result?.appointment_price?.price_model === appointmentPriceModelEnum.FLAT_FEE.value) && (
                            <div className={"flex items-center justify-start gap-0.5"}>
                                <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                    {"Price:"}
                                </span>
                                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                    {appointmentDetail?.data?.result?.appointment_price?.masterCurrency?.code + " " + parseFloat((appointmentDetail?.data?.result?.appointment_price?.price - ((appointmentDetail?.data?.result?.appointment_price?.price * appointmentDetail?.data?.result?.appointment_price?.discount_pct) / 100)) / 100).toFixed(2)}
                                </span>

                                <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                    ({(appointmentDetail?.data?.result?.appointment_availability?.duration < 60)
                                        ? appointmentDetail?.data?.result?.appointment_availability?.duration + " min"
                                        : (appointmentDetail?.data?.result?.appointment_availability?.duration / 60)?.toFixed(2) + " hour(s)"
                                    })
                                </span>
                            </div>
                        )}
                        {(appointmentDetail?.data?.result?.appointment_price?.price_model === appointmentPriceModelEnum.FREE.value) && (
                            <div className={"flex items-center justify-start gap-0.5"}>
                                <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                    {"Duration:"}
                                </span>
                                <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                                    {(appointmentDetail?.data?.result?.appointment_availability?.duration < 60)
                                        ? appointmentDetail?.data?.result?.appointment_availability?.duration + " min"
                                        : (appointmentDetail?.data?.result?.appointment_availability?.duration / 60)?.toFixed(2) + " hour(s)"
                                    }
                                </span>
                            </div>
                        )}
                        <div className={"flex items-center justify-start gap-0.5"}>
                            <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                {"Price Model:"}
                            </span>
                            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                {appointmentPriceModelEnum[appointmentDetail?.data?.result?.appointment_price?.price_model?.toUpperCase()]?.label}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentDetailCard;