import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FaEye } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

import FullPageLoader from "components/loader/FullPageLoader";
import Table from "components/table/Table";
import Pagination from "components/pagination/Pagination";

import {
  companyOnboardingHeaderConst,
  companyOnboardingSubRowHeader,
  sortCompanyOnboardingLowToHigh,
  sortCompanyOnboardingHighToLow
} from "pages/auth/onboarding/data";

import { getCompanyOnboardingList } from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";

import { setClearCompanyOnboardingList, setClearFilterProps } from "redux/onboarding/onboarding.slice";
import { companyOnboardingStatus } from "redux/company/company.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

const CompanyOnboardingTable = ({ showEntries }) => {
  const { companyOnboardingList } = useAppState((state) => state.onboarding)
  const dispatcher = useAppDispatcher()

  const [activePage, setActivePage] = useState(1)
  const [totalNoOfPages, setTotalNoOfPages] = useState(companyOnboardingList?.filteredCompanyOnboardingList?.totalNoOfPages)
  const [activeSortHeader, setActiveSortHeader] = useState({
    activeSortKey: null,
    sortType: null
  })

  useEffect(() => {
    dispatcher(getCompanyOnboardingList(activePage))

    return () => {
      dispatcher(setClearCompanyOnboardingList())
      dispatcher(setClearFilterProps())
    }
  }, [activePage])

  useEffect(() => {
    if (companyOnboardingList?.filteredCompanyOnboardingList) {
      setActivePage(companyOnboardingList?.filteredCompanyOnboardingList?.currentPage)
      setTotalNoOfPages(companyOnboardingList?.filteredCompanyOnboardingList?.totalNoOfPages)
    }
  }, [companyOnboardingList?.filteredCompanyOnboardingList?.currentPage, companyOnboardingList?.filteredCompanyOnboardingList?.totalNoOfPages])

  const sortHeader = (activeSort, upDownArrow) => {
    setActiveSortHeader({
      activeSortKey: activeSort,
      sortType: upDownArrow
    })
  }

  const sortCompanyOnboardingList = (company1, company2) => {
    if (activeSortHeader?.sortType?.upArrow) {
      return sortCompanyOnboardingLowToHigh(activeSortHeader, company1, company2)
    }
    if (activeSortHeader?.sortType?.downArrow) {
      return sortCompanyOnboardingHighToLow(activeSortHeader, company1, company2)
    }
  }

  const rows = companyOnboardingList?.filteredCompanyOnboardingList?.list?.slice(0, showEntries)?.sort(sortCompanyOnboardingList)?.map((company, index) => [
    company?.companyId,
    company?.firstName,
    company?.email,
    companyOnboardingStatus[company?.status?.toUpperCase()].label,
    dayjs(company?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A'),
    <div
      key={index}
      className="flex justify-start items-center"
    >
      <Link
        to={`/onboarding/company/${company?.companyId}`}
        className="px-2 py-1 bg-primary-main text-white rounded-l-md"
      >
        <FaEye className="text-xl hover:scale-110" />
      </Link>
      <Link
        to={`/company-onboarding/${company?.companyId}/edit`}
        className="px-2 py-1 bg-background-lightDark text-white rounded-r-md"
      >
        <FiEdit className="text-xl hover:scale-110" />
      </Link>
    </div>,
  ]);

  return (
    <div>
      {companyOnboardingList?.isLoading &&
        <FullPageLoader isLoading={companyOnboardingList?.isLoading} />
      }
      {(companyOnboardingList?.filteredCompanyOnboardingList?.list && companyOnboardingList?.filteredCompanyOnboardingList?.list?.length > 0) &&
        <Table
          headers={Object.values(companyOnboardingHeaderConst)}
          subRowHeader={companyOnboardingSubRowHeader}
          rows={rows}
          rowHeaderColor={"bg-background-medium"}
          alternateRowColor={"bg-primary-light"}
          sortHeader={sortHeader}
        />
      }
      {(companyOnboardingList?.filteredCompanyOnboardingList?.list && companyOnboardingList?.filteredCompanyOnboardingList?.list?.length > 0) &&
        <div className={"w-full flex justify-center items-center py-3"}>
          <Pagination
            activePage={activePage}
            totalNoOfPages={18}
            onChangePage={(pageNumber) => setActivePage(pageNumber)}
          />
        </div>
      }
      {companyOnboardingList?.errorMsg || companyOnboardingList?.filteredCompanyOnboardingList?.list?.length === 0 &&
        <div className={"flex justify-center items-center font-bodyPri font-normal text-text-800 text-base"}>
          {companyOnboardingList?.errorMsg || "No data Found!"}
        </div>
      }
    </div>
  )
}

export default CompanyOnboardingTable;