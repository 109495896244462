import { IoMdPeople } from 'react-icons/io';
import { MdGroups } from 'react-icons/md';

import { useAppState } from 'hooks/useStore';
import { sessionCourseTypeEnum } from 'redux/session/session.const';

const SessionDetailCourseType = () => {
    const { sessionDetail } = useAppState(s => s.session)

    return sessionDetail?.data?.result?.session_course?.type ? (
        <div className={"flex items-center justify-start gap-2"}>
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
                {"Course Type: "}
            </span>
            <div className={"flex items-center justify-center gap-1"}>
                {(sessionCourseTypeEnum[sessionDetail?.data?.result?.session_course?.type.toUpperCase()].value === sessionCourseTypeEnum.ONE_ON_ONE.value) &&
                    <IoMdPeople className={"text-lg text-text-900"} />
                }
                {(sessionCourseTypeEnum[sessionDetail?.data?.result?.session_course?.type.toUpperCase()].value === sessionCourseTypeEnum.GROUP.value) &&
                    <MdGroups className={"text-lg text-text-900"} />
                }
                <span className={"font-bodyPri font-medium text-md text-text-900"}>
                    {sessionCourseTypeEnum[sessionDetail?.data?.result?.session_course?.type.toUpperCase()].label}
                </span>
            </div>
        </div>
    ) : null
}

export default SessionDetailCourseType