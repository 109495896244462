import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";

import availabilityService from 'redux/availability/availability.service';
import {
    setAvailabilityListLoading,
    setAvailabilityListData,
    setAvailabilityListMessage,

    setAddAvailabilityDetailLoading,
    setAddAvailabilityDetailData,
    setAddAvailabilityDetailMessage,

    setAvailabilityDetailLoading,
    setAvailabilityDetailData,
    setAvailabilityDetailMessage,

    setModifyAvailabilityDetailLoading,
    setModifyAvailabilityDetailData,
    setModifyAvailabilityDetailMessage,

    setDestroyAvailabilityDetailLoading,
    setDestroyAvailabilityDetailMessage,

    setAvailableSlotDetailLoading,
    setAvailableSlotDetailData,
    setAvailableSlotDetailMessage,
} from 'redux/availability/availability.slice';
import { availabilityRedux } from "./availability.const";

export const getAvailabilityList = createAsyncThunk(
    `${availabilityRedux?.sliceName}/${availabilityRedux?.availabilityList?.requestName}`,
    async (query, { dispatch }) => {
        dispatch(setAvailabilityListLoading(true))

        try {
            const requestData = {
                query: query
            }
            const response = await availabilityService.getAvailabilityList(requestData)
            if (response.status === 200) {
                dispatch(setAvailabilityListData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAvailabilityListMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setAvailabilityListLoading(false))
        }
    }
)

export const createAvailabilityDetail = createAsyncThunk(
    `${availabilityRedux?.sliceName}/${availabilityRedux?.availabilityDetail?.requestName}`,
    async (body, { dispatch }) => {
        dispatch(setAddAvailabilityDetailLoading(true))

        try {
            const requestData = {
                body: body
            }
            const response = await availabilityService.createAvailabilityDetail(requestData)
            if (response.status === 201) {
                dispatch(setAddAvailabilityDetailData(response.data.data))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAddAvailabilityDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setAddAvailabilityDetailLoading(false))
        }
    }
)

export const getAvailabilityDetail = createAsyncThunk(
    `${availabilityRedux?.sliceName}/${availabilityRedux?.availabilityDetail?.requestName}`,
    async (availabilityDetail, { dispatch }) => {
        dispatch(setAvailabilityDetailLoading(true))

        try {
            const requestData = {
                params: { availabilityId: availabilityDetail?.availabilityId },
                query: availabilityDetail?.query
            }
            const response = await availabilityService.getAvailabilityDetail(requestData)
            if (response.status === 200) {
                dispatch(setAvailabilityDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAvailabilityDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setAvailabilityDetailLoading(false))
        }
    }
)

export const updateAvailabilityDetail = createAsyncThunk(
    `${availabilityRedux?.sliceName}/${availabilityRedux?.availabilityDetail?.requestName}`,
    async (availabilityDetail, { dispatch }) => {
        dispatch(setModifyAvailabilityDetailLoading(true))

        try {
            const requestData = {
                params: { availabilityId: availabilityDetail?.availabilityId },
                body: availabilityDetail?.body
            }
            const response = await availabilityService.updateAvailabilityDetail(requestData)
            if (response.status === 200) {
                dispatch(setModifyAvailabilityDetailData(response.data.data))
                dispatch(setAvailabilityDetailData(response.data.data))
                toast.success(response.data.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setModifyAvailabilityDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setModifyAvailabilityDetailLoading(false))
        }
    }
)

export const deleteAvailabilityDetail = createAsyncThunk(
    `${availabilityRedux?.sliceName}/${availabilityRedux?.availabilityDetail?.requestName}`,
    async (availabilityId, { dispatch, getState }) => {
        dispatch(setDestroyAvailabilityDetailLoading(true))

        try {
            const { availabilityList } = getState().availability
            const requestData = {
                params: { availabilityId: availabilityId }
            }
            const response = await availabilityService.deleteAvailabilityDetail(requestData)
            if (response.status === 204) {
                dispatch(setAvailabilityListData({
                    ...availabilityList?.data,
                    results: availabilityList?.data?.result?.filter((item) => item?.id !== availabilityId),
                    pagination: {
                        ...availabilityList?.data?.pagination,
                        records: availabilityList?.data?.pagination?.records - 1,
                        totalRecords: availabilityList?.data?.pagination?.totalRecords - 1
                    },
                }))
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || error)
            dispatch(setDestroyAvailabilityDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
            toast.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
        } finally {
            dispatch(setDestroyAvailabilityDetailLoading(false))
        }
    }
)

// get available slot detail
export const getAvailableSlotDetail = createAsyncThunk(
    `${availabilityRedux?.sliceName}/${availabilityRedux?.availableSlotDetail?.requestName}`,
    async (availableSlotDetail, { dispatch }) => {
        dispatch(setAvailableSlotDetailLoading(true))

        try {
            const requestData = {
                params: { availabilityId: availableSlotDetail?.availabilityId },
                query: availableSlotDetail?.query
            }
            const response = await availabilityService.getAvailableSlotDetail(requestData)
            if (response.status === 200) {
                dispatch(setAvailableSlotDetailData(response.data.data))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
            dispatch(setAvailableSlotDetailMessage(error.response.data.message || error.response.data.error || "Something Went Wrong!"))
        } finally {
            dispatch(setAvailableSlotDetailLoading(false))
        }
    }
)