import { useReducer, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import ButtonLoader from "components/loader/ButtonLoader";
import { OptionSelector } from 'components/common-components/Select';
import IsdCodeSelector from "components/optionSelector/IsdCodeSelector";

import { getMasterCountryList, getMasterTimezoneList } from 'redux/master/master.request';
import { createVerifyReferral, createVerifyUser } from 'redux/auth/auth.request';
import { createUserDetail } from "redux/user/user.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetAddVerifyUser, setClearVerifyReferral } from "redux/auth/auth.slice";
import { resetAddNewUserDetail, setAddNewUserDetailPayload } from "redux/user/user.slice";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";
import { validateEmail, validateMobileNo, validatePassword } from 'utils/validation-functions';
import { setClearCountryList, setClearTimeZoneList } from "redux/master/master.slice";

const userFormReduce = (state, action) => {
    switch (action.type) {
        case "EMAIL_ERROR":
            return { ...state, emailErrorText: action.payload }
        case "MOBILE_ERROR":
            return { ...state, mobileErrorText: action.payload }
        case "PASSWORD_ERROR":
            return { ...state, passwordErrorText: action.payload }
        case "REFERRAL_CODE":
            return { ...state, isReferralCodeEntered: action.payload }

        default:
            return state
    }
}

const pattern = /[a-zA-z]{1}/;

const AddUserDetailModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { countryList, timeZoneList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { verifyReferral, verifyUser } = useAppState((state) => state.auth)
    const { addNewUserDetail } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [state, dispatch] = useReducer(userFormReduce, {
        emailErrorText: "",
        mobileErrorText: "",
        passwordErrorText: "",
        isReferralCodeEntered: false,
    })
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })
    const [isEmailValid, setIsEmailValid] = useState(false)

    const isFormValid = addNewUserDetail?.payload?.firstName && addNewUserDetail?.payload?.lastName && addNewUserDetail?.payload?.email && addNewUserDetail?.payload?.timeZone && isEmailValid

    useEffect(() => {
        if (addNewUserDetail?.data) {
            dispatcher(resetAddNewUserDetail())
            navigate(`${pagesInfo?.USERS?.pagePath}/${addNewUserDetail?.data?.userId}`)
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_USER_DETAIL_MODAL.stateKey]: false
            }))
        }
    }, [addNewUserDetail?.data])

    useEffect(() => {
        if (verifyUser?.message) {
            setIsEmailValid(true)
        }
    }, [verifyUser?.message])

    useEffect(() => {
        dispatcher(getMasterCountryList())
        dispatcher(getMasterTimezoneList())

        return () => {
            dispatcher(setClearCountryList())
            dispatcher(setClearTimeZoneList())
            dispatcher(resetAddVerifyUser())
            dispatcher(resetAddNewUserDetail())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    useEffect(() => {
        if (addNewUserDetail?.payload?.email && !validateEmail(addNewUserDetail?.payload?.email)) dispatch({ type: "EMAIL_ERROR", payload: "Enter valid Email!" });
        else dispatch({ type: "EMAIL_ERROR", payload: "" });

        if (addNewUserDetail?.payload?.mobileNo && !validateMobileNo(addNewUserDetail?.payload?.mobileNo)) dispatch({ type: "MOBILE_ERROR", payload: "Enter valid MobileNo!" });
        else dispatch({ type: "MOBILE_ERROR", payload: "" });

        if (addNewUserDetail?.payload?.password && !validatePassword(addNewUserDetail?.payload?.password)) dispatch({ type: "PASSWORD_ERROR", payload: "Enter valid MobileNo!" });
        else dispatch({ type: "PASSWORD_ERROR", payload: "" });

    }, [addNewUserDetail?.payload?.mobileNo, addNewUserDetail?.payload?.email, addNewUserDetail?.payload?.password])

    const onHandleVerifyEmail = () => {
        if (verifyUser?.isLoading || !validateEmail(addNewUserDetail?.payload?.email)) return;

        const requestDataPayload = {
            email: addNewUserDetail?.payload?.email
        }
        dispatcher(createVerifyUser(requestDataPayload))
    }


    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    async function generateRandomPassword() {
        var length = 10,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@$%&*",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const onHandleGeneratePassword = async () => {
        const generatedPassword = await generateRandomPassword()
        if (validatePassword(generatedPassword)) {
            dispatcher(setAddNewUserDetailPayload({
                ...addNewUserDetail?.payload,
                password: generatedPassword
            }))
        } else {
            onHandleGeneratePassword()
        }
    }

    const onHandleReferralCode = (referralCodeText) => {
        if (verifyReferral?.isLoading) return;

        if (verifyReferral?.data || verifyReferral?.message) {
            dispatcher(setClearVerifyReferral())
        }
        dispatcher(setAddNewUserDetailPayload({
            ...addNewUserDetail?.payload,
            referralCode: referralCodeText
        }))
        if (referralCodeText?.length > 0) {
            dispatch({ type: "REFERRAL_CODE", payload: true })
        }
        else {
            dispatch({ type: "REFERRAL_CODE", payload: false })
            dispatcher(setClearVerifyReferral())
        }
    }

    const onHandleUserDetailForm = (event) => {
        event.preventDefault();

        if (state.emailErrorText) {
            alert("Please enter valid email address!")
            return;
        }

        if (state.mobileErrorText) {
            alert("Please enter valid mobile number!")
            return;
        }

        if (state.passwordErrorText) {
            alert("Please enter valid password!")
            return;
        }

        if (!addNewUserDetail?.payload?.timeZone) {
            alert("Please select timeZone!")
            return;
        }

        if (addNewUserDetail?.payload?.referralCode && verifyReferral?.message) {
            alert("Please enter valid referral code!")
            return;
        }

        const body = {
            firstName: addNewUserDetail?.payload?.firstName,
            lastName: addNewUserDetail?.payload?.lastName,
            email: addNewUserDetail?.payload?.email,
            isEmailVerified: addNewUserDetail?.payload?.isEmailVerified,
            timeZone: addNewUserDetail?.payload?.timeZone
        }

        if (addNewUserDetail?.payload?.mobileNo) {
            body["mobileNo"] = myCountry?.isdCode + "-" + addNewUserDetail?.payload?.mobileNo
            // body["isMobileNoVerified"] = addNewUserDetail?.payload?.isMobileNoVerified
        }
        if (addNewUserDetail?.payload?.password) {
            body["password"] = addNewUserDetail?.payload?.password
        }
        if (addNewUserDetail?.payload?.referralCode) {
            body["referralCode"] = addNewUserDetail?.payload?.referralCode
        }
        if (addNewUserDetail?.payload?.promoCode) {
            body["promoCode"] = addNewUserDetail?.payload?.promoCode
        }
        if (addNewUserDetail?.payload?.isPromotionActive) {
            body["isPromotionActive"] = addNewUserDetail?.payload?.isPromotionActive
        }
        if (addNewUserDetail?.payload?.isSendWelcomeEmail) {
            body["isSendWelcomeEmail"] = addNewUserDetail?.payload?.isSendWelcomeEmail
        }

        dispatcher(createUserDetail(body))

    }

    return (
        <form onSubmit={onHandleUserDetailForm} className="space-y-5 overflow-x-hidden">
            <div className={"min-h-[24rem] block w-full border border-text-400 space-y-3"}>
                <div className={"space-y-1"}>
                    <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                        {"User Details"}
                    </div>
                    <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
                        {"(Marked with * is mandatory field)"}
                    </span>
                </div>

                <div className="grid grid-cols-2 gap-5 px-2">

                    {/* first name container */}
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"First Name"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            placeholder={"e.g. John"}
                            value={addNewUserDetail?.payload?.firstName}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )} onChange={(e) => dispatcher(setAddNewUserDetailPayload({
                                ...addNewUserDetail?.payload,
                                firstName: (e.target.value).charAt(0).toUpperCase() + (e.target.value).slice(1)
                            }))}
                            required
                        />
                    </div>

                    {/* last name container */}
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Last Name "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            required
                            placeholder={"e.g. Doe"}
                            value={addNewUserDetail?.payload?.lastName}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => dispatcher(setAddNewUserDetailPayload({
                                ...addNewUserDetail?.payload,
                                lastName: (e.target.value).charAt(0).toUpperCase() + (e.target.value).slice(1)
                            }))}
                        />
                    </div>

                    {/* email container */}
                    <div className='col-start-1 col-span-full'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Email "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <div
                            className={cn(
                                "w-full px-3 py-1 flex items-center justify-between gap-3 border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                            )}>
                            <input
                                type={"text"}
                                required
                                value={addNewUserDetail?.payload?.email}
                                placeholder={"e.g. johndoe@example.com"}
                                className={cn(
                                    "w-[90%] px-3 focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => {
                                    if (isEmailValid) {
                                        setIsEmailValid(false)
                                    }
                                    dispatcher(setAddNewUserDetailPayload({
                                        ...addNewUserDetail?.payload,
                                        email: e.target.value
                                    }))
                                }}
                            />
                            {verifyUser?.isLoading &&
                                <FaSpinner className={"animate-spin text-primary-dark text-base"} />
                            }
                            {(!verifyUser?.isLoading && isEmailValid) &&
                                <span className={"px-1 font-bodyPri font-normal text-xs text-green-500"}>
                                    {"Verified"}
                                </span>
                            }
                            {(!verifyUser?.isLoading && !isEmailValid) &&
                                <span
                                    className={cn(
                                        "px-1 py-0.5 border border-secondary-dark hover:bg-secondary-dark hover:text-text-50 rounded-md",
                                        "font-bodyPri font-normal text-xs text-secondary-dark cursor-pointer whitespace-normal sm:whitespace-nowrap"
                                    )}
                                    onClick={onHandleVerifyEmail}
                                >
                                    {"Validate Email"}
                                </span>
                            }

                        </div>
                        <span className="font-bodyPri font-normal text-xs text-red-500">
                            {state.emailErrorText}
                        </span>
                        {verifyUser?.data &&
                            <span className="font-bodyPri font-normal text-xs text-red-500">
                                {"Duplicate entry, user already exists"}
                            </span>
                        }
                        {isEmailValid &&
                            <div className={"flex items-center justify-start gap-3 mt-3"}>
                                <input
                                    type={"checkbox"}
                                    required
                                    value={addNewUserDetail?.payload?.isEmailVerified}
                                    className={"border border-divider-medium rounded-md focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                                    checked={addNewUserDetail?.payload?.isEmailVerified && true}
                                    onChange={(e) => {
                                        if (validateEmail(addNewUserDetail?.payload?.email)) {
                                            dispatcher(setAddNewUserDetailPayload({
                                                ...addNewUserDetail?.payload,
                                                isEmailVerified: e.target.checked
                                            }))
                                        } else {
                                            alert("Please enter valid email!")
                                        }
                                    }}
                                />
                                {addNewUserDetail?.payload?.isEmailVerified &&
                                    <span className={"font-bodyPri font-medium text-green-500 text-sm"}>
                                        {"Email Verified"}
                                    </span>
                                }
                                {!addNewUserDetail?.payload?.isEmailVerified &&
                                    <span className={"font-bodyPri font-medium text-text-800 text-sm"}>
                                        {"Verify Email"}
                                    </span>
                                }
                            </div>
                        }
                    </div>

                    {/* mobile no container */}
                    <div className='col-start-1 col-span-full'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Mobile No."}
                        </span>
                        <div className={cn(
                            "border-2 border-divider-medium bg-white rounded-md px-2 py-1 focus:outline-none flex items-center gap-3",
                            "text-text-900 text-base font-bodyPri w-full"
                        )}>
                            {!pattern.test(addNewUserDetail?.payload?.mobileNo) && countryList?.countryList &&
                                <IsdCodeSelector
                                    options={countryList?.countryList?.map((country) => ({
                                        label: country?.isdCode,
                                        value: country?.masterCountryId,
                                        country: country
                                    }))}
                                    value={myCountry?.masterCountryId}
                                    onChange={(option) => onSelectIsdCode(option)}
                                />
                            }
                            <input
                                type={"number"}
                                placeholder={"e.g. 0123456789"}
                                value={addNewUserDetail?.payload?.mobileNo}
                                className={cn(
                                    "w-full input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => dispatcher(setAddNewUserDetailPayload({
                                    ...addNewUserDetail?.payload,
                                    mobileNo: e.target.value
                                }))}
                            />
                        </div>
                        <span className="font-bodyPri font-normal text-xs text-red-500">
                            {state.mobileErrorText}
                        </span>

                        <div className={"flex items-center justify-start gap-3 mt-3"}>
                            <input
                                type={"checkbox"}
                                value={addNewUserDetail?.payload?.isMobileNoVerified}
                                className={"border border-divider-medium rounded-md focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                                checked={addNewUserDetail?.payload?.isMobileNoVerified && true}
                                onChange={(e) => {
                                    if (validateMobileNo(addNewUserDetail?.payload?.mobileNo)) {
                                        dispatcher(setAddNewUserDetailPayload({
                                            ...addNewUserDetail?.payload,
                                            isMobileNoVerified: e.target.checked
                                        }))
                                    } else {
                                        alert("Please enter valid mobile number!")
                                    }
                                }}
                            />
                            {addNewUserDetail?.payload?.isMobileNoVerified &&
                                <span className={"font-bodyPri font-medium text-green-500 text-sm"}>
                                    {"Mobile No. Verified"}
                                </span>
                            }
                            {!addNewUserDetail?.payload?.isMobileNoVerified &&
                                <span className={"font-bodyPri font-medium text-text-800 text-sm"}>
                                    {"Verify Mobile No."}
                                </span>
                            }
                        </div>
                    </div>

                    {/* password container */}
                    <div className='space-y-1'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Password"}
                        </span>
                        <input
                            type={"text"}
                            required
                            value={addNewUserDetail?.payload?.password}
                            placeholder={"JohnDoe@123"}
                            className={cn(
                                "w-full px-3 py-1.5 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => dispatcher(setAddNewUserDetailPayload({
                                ...addNewUserDetail?.payload,
                                password: e.target.value
                            }))}
                        />
                        <span className="font-bodyPri font-normal text-xs text-red-500">
                            {state.passwordErrorText}
                        </span>

                        <span
                            className={"font-bodyPri font-medium text-xs text-primary-dark cursor-pointer"}
                            onClick={onHandleGeneratePassword}
                        >
                            {"Generate Password"}
                        </span>
                    </div>

                    {/* timeZone container */}
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"User TimeZone"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        {timeZoneList?.isLoading &&
                            <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                        }
                        {(!timeZoneList?.isLoading && timeZoneList?.timeZoneList) &&
                            <OptionSelector
                                options={timeZoneList?.timeZoneList?.map((timezone) => ({
                                    label: `${timezone?.timeZone} (${timezone?.country})`,
                                    value: timezone?.timeZone
                                }))}
                                className={"w-full"}
                                value={addNewUserDetail?.payload?.timeZone}
                                onChange={(option) => dispatcher(setAddNewUserDetailPayload({
                                    ...addNewUserDetail?.payload,
                                    timeZone: option?.value
                                }))}
                            />
                        }
                        {timeZoneList?.errorMsg &&
                            <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                                {timeZoneList?.errorMsg}
                            </span>
                        }
                    </div>

                    {/* referral code */}
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Referral Code"}
                        </span>
                        <input
                            type={"text"}
                            placeholder={"e.g. edulyte"}
                            value={addNewUserDetail?.payload?.referralCode}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => onHandleReferralCode(e.target.value)}
                        />

                        {state.isReferralCodeEntered &&
                            <div className={cn(
                                "flex justify-start gap-3 mt-3",
                                verifyReferral?.message ? "items-start" : "items-center"
                            )}>
                                {verifyReferral?.isLoading &&
                                    <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                                }
                                {!verifyReferral?.isLoading &&
                                    <input
                                        type={"checkbox"}
                                        className={"border border-divider-medium rounded-md focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                                        checked={verifyReferral?.data ? true : false}
                                        onChange={(e) => {
                                            if (verifyReferral?.data) {
                                                dispatcher(setClearVerifyReferral())
                                                return;
                                            }
                                            if (verifyReferral?.message) {
                                                alert("Please enter valid referral code!")
                                                return;
                                            } else {
                                                dispatcher(createVerifyReferral(addNewUserDetail?.payload?.referralCode))
                                            }
                                        }}
                                    />
                                }
                                {verifyReferral?.data &&
                                    <span className={"font-bodyPri font-medium text-green-500 text-sm"}>
                                        {"Referral Code Applied"}
                                    </span>
                                }
                                <div className={"flex flex-col items-start justify-start gap-2"}>
                                    {!verifyReferral?.data &&
                                        <span className={"font-bodyPri font-medium text-text-800 text-sm"}>
                                            {"Apply Referral Code"}
                                        </span>
                                    }
                                    {verifyReferral?.message &&
                                        <span className={"font-bodyPri font-medium text-red-500 text-sm"}>
                                            {verifyReferral?.message}
                                        </span>
                                    }
                                </div>

                            </div>
                        }
                    </div>

                    {/* promo code container */}
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Promo Code"}
                        </span>
                        <input
                            type={"text"}
                            placeholder={"e.g. Edulyte"}
                            value={addNewUserDetail?.payload?.promoCode}
                            className={cn(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => dispatcher(setAddNewUserDetailPayload({
                                ...addNewUserDetail?.payload,
                                promoCode: e.target.value
                            }))}
                        />
                    </div>
                </div>

                <div className="px-2 py-3 space-y-3 rounded-md bg-primary-light">
                    <div className="flex items-center justify-start gap-3">
                        <input
                            type={"checkbox"}
                            checked={addNewUserDetail?.payload?.isSendWelcomeEmail}
                            className={"border border-divider-medium rounded-md focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                            onChange={(e) => dispatcher(setAddNewUserDetailPayload({
                                ...addNewUserDetail?.payload,
                                isSendWelcomeEmail: e.target.checked
                            }))}
                        />
                        <span className='font-bodyPri font-medium text-text-900 text-sm text-start w-full'>
                            {"Send welcome email to user."}
                        </span>
                    </div>

                    <div className="flex items-center justify-start gap-3">
                        <input
                            type={"checkbox"}
                            checked={addNewUserDetail?.payload?.isPromotionActive}
                            className={"border border-divider-medium rounded-md focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                            onChange={(e) => dispatcher(setAddNewUserDetailPayload({
                                ...addNewUserDetail?.payload,
                                isPromotionActive: e.target.checked
                            }))}
                        />
                        <span className='font-bodyPri font-medium text-text-900 text-sm text-start w-full'>
                            {"Please don't send me tips or marketing via email or SMS."}
                        </span>
                    </div>


                </div>
            </div>
            <div className={"flex items-center justify-between gap-3"}>
                <div className={""}>
                    {addNewUserDetail?.message &&
                        <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {addNewUserDetail?.message}
                        </span>
                    }
                </div>
                <button
                    type={"submit"}
                    disabled={!isFormValid}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        !isFormValid && "bg-text-300 text-white cursor-not-allowed",
                        (isFormValid && !addNewUserDetail?.isLoading) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        addNewUserDetail?.isLoading && "bg-secondary-main"
                    )}>
                    {addNewUserDetail?.isLoading && <ButtonLoader isLoading={addNewUserDetail?.isLoading} />}
                    {!addNewUserDetail?.isLoading && "Submit"}
                </button>
            </div>
        </form >
    )
}

export default AddUserDetailModal;
