import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { cn } from "utils/cn.utils";

import { MdAdd } from "react-icons/md";

import FullPageLoader from "components/loader/FullPageLoader";
import { OptionSelector } from "components/common-components/Select";
import MuxVideoPlayer from "components/muxVideoPlayer/MuxVideoPlayer";

import {
    getOnboardingDocListByOnboardingId,
    createOnboardingDocDetailByOnboardingId,
    updateOnboardingDocDetailByOnboardingDocId,
    deleteOnboardingDocDetailByOnboardingDocId,
} from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
// import { setClearUploadDocument } from "redux/document/document.slice";
import { resetUploadFile, setUploadFilePayload } from "redux/storage/storage.slice";
import {
    setClearOnboardingDocList,
    setAddOnboardingDocDetailPayload,
    setClearAddOnboardingDocDetailPayload,
    setClearAddOnboardingDocDetail
} from "redux/onboarding/onboarding.slice";
import { videoType, childDocumentStatus, documentStatus } from "redux/onboarding/onboarding.const";
import { modalConst } from "redux/local/local.const";

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const EditIntroVideo = () => {
    const { modal } = useAppState((state) => state.local)
    // const { uploadDocument } = useAppState((state) => state.document)
    const { uploadFile } = useAppState((state) => state.storage)
    const { onboardingDocList, addOnboardingDocDetail, onboardingDetail } = useAppState((s) => s.onboarding)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()

    const [introVideoPlayBackId, setIntroVideoPlayBackId] = useState(MUX_DEMO_PLAYBACK_ID)
    const [introVideoStatus, setIntroVideoStatus] = useState(null)
    const [comment, setComment] = useState('')


    useEffect(() => {
        if (onboardingId) {
            const requestPayload = {
                onboardingId: Number(onboardingId),
                query: { docType: videoType?.INTRO_VIDEO?.value }
            }
            dispatcher(getOnboardingDocListByOnboardingId(requestPayload))
        }

        return () => {
            dispatcher(setClearOnboardingDocList())
        }
    }, [onboardingId])

    useEffect(() => {
        if (onboardingDocList?.onboardingDocList) {
            setIntroVideoStatus(onboardingDocList?.onboardingDocList[0]?.status)
        }
    }, [onboardingDocList?.onboardingDocList])

    useEffect(() => {
        if (addOnboardingDocDetail.addOnboardingDocDetail) {
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: false
            }))
            dispatcher(setClearAddOnboardingDocDetail())
        }
    }, [addOnboardingDocDetail.addOnboardingDocDetail])

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0 && !addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId) {
            const requestData = {
                onboardingId: Number(onboardingId),
                body: {
                    docType: videoType?.INTRO_VIDEO?.value,
                    docName: "intro video",
                    docUrl: uploadFile?.data[0]?.fileUrl
                }
            }
            dispatcher(createOnboardingDocDetailByOnboardingId(requestData))
            dispatcher(resetUploadFile())
        }
        if (uploadFile?.data && uploadFile?.data?.length > 0 && addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId) {
            const requestData = {
                onboardingDocId: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.documentId,
                body: {
                    docType: videoType?.INTRO_VIDEO?.value,
                    docName: addOnboardingDocDetail?.addOnboardingDocDetailPayload?.fileName,
                    docUrl: uploadFile?.data[0]?.fileUrl
                }
            }
            dispatcher(updateOnboardingDocDetailByOnboardingDocId(requestData))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    const handleUploadVideo = () => {
        dispatcher(resetUploadFile())
        if (onboardingDocList?.onboardingDocList?.length > 0) {
            dispatcher(setClearAddOnboardingDocDetailPayload())
            dispatcher(setAddOnboardingDocDetailPayload({
                documentId: onboardingDocList?.onboardingDocList[0]?.onboardingDocId,
                fileCategory: onboardingDocList?.onboardingDocList[0]?.docType,
                fileName: onboardingDocList?.onboardingDocList[0]?.docName,
                fileUrl: onboardingDocList?.onboardingDocList[0]?.docUrl
            }))
        }
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            userId: onboardingDetail?.onboardingDetail?.user?.userId
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const handleRefreshButton = () => {
        const requestPayload = {
            onboardingId: Number(onboardingId),
            query: { docType: videoType?.INTRO_VIDEO?.value }
        }
        dispatcher(getOnboardingDocListByOnboardingId(requestPayload))
    }

    const handleDeleteVideo = () => {
        dispatcher(deleteOnboardingDocDetailByOnboardingDocId(onboardingDocList?.onboardingDocList[0]?.onboardingDocId))
    }

    const onHandlePastePlaybackId = (event) => {
        const clipboardData = event?.clipboardData?.getData('text/plain');
        setIntroVideoPlayBackId(clipboardData)
    }

    const onHandleSave = () => {
        // if ((introVideoStatus === onboardingDocList?.onboardingDocList[0]?.status) || !onboardingDocList?.onboardingDocList[0]?.status) return;

        if (onboardingDocList?.onboardingDocList?.length === 0) {
            toast.warn("Please upload video!")
            return;
        }
        if (introVideoStatus === childDocumentStatus?.REJECTED?.value && comment?.length === 0) {
            alert("Please add comment!")
            return;
        }
        const requestData = {
            onboardingDocId: onboardingDocList?.onboardingDocList[0]?.onboardingDocId,
            body: {
                status: introVideoStatus
            }
        }
        dispatcher(updateOnboardingDocDetailByOnboardingDocId(requestData))
    }

    if (onboardingDocList?.isLoading || addOnboardingDocDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={onboardingDocList?.isLoading || addOnboardingDocDetail?.isLoading} />
        )
    }

    if (onboardingDocList?.errorMsg) {
        return (
            <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-center text-red-500 text-sm"}>
                {onboardingDocList?.errorMsg}
            </div>
        )
    }

    return onboardingDocList?.onboardingDocList ? (
        <div className={"space-y-5"}>
            <div className={cn(
                "flex items-start justify-start font-bodyPri font-medium text-text-900 text-xl rounded-lg px-3 py-3",
                onboardingDocList?.onboardingDocList && `bg-${documentStatus[onboardingDocList?.onboardingDocList[0]?.status?.toUpperCase()]?.lightColor}`,
                (onboardingDocList?.onboardingDocList?.length === 0) && "bg-yellow-100"
            )}>
                {"Intro Video Verification"}
            </div>
            <div className={"w-full flex items-start justify-start gap-2 px-3"}>
                <span className={"font-bodyPri font-normal text-base"}>
                    {"Status:"}
                </span>
                <span
                    className={cn(
                        `font-bodyPri font-normal text-base`,
                        onboardingDocList?.onboardingDocList && `text-${documentStatus[onboardingDocList?.onboardingDocList[0]?.status?.toUpperCase()]?.darkColor}`,
                        onboardingDocList?.onboardingDocList?.length === 0 && "text-yellow-500"
                    )}>
                    {onboardingDocList?.onboardingDocList?.length > 0
                        ? documentStatus[onboardingDocList?.onboardingDocList[0]?.status?.toUpperCase()]?.label
                        : "Pending"
                    }
                </span>
            </div>
            {onboardingDocList?.onboardingDocList?.length > 0 &&
                <div className={"w-full sm:w-[28rem] md:w-[30rem] h-full overflow-hidden rounded-lg px-3"}>
                    <MuxVideoPlayer
                        playbackId={onboardingDocList?.onboardingDocList ? onboardingDocList?.onboardingDocList[0]?.docUrl : MUX_DEMO_PLAYBACK_ID}
                        handleUploadVideo={handleUploadVideo}
                        onHandleRefresh={handleRefreshButton}
                        onHandleDeleteVideo={handleDeleteVideo}
                        isShowRefreshButton={true}
                        isShowDeleteButton={true}
                    />
                </div>
            }
            {(!onboardingDocList?.onboardingDocList || onboardingDocList?.onboardingDocList?.length === 0) &&
                <div
                    className={"flex items-center justify-start rounded-lg cursor-pointer group px-3"}
                    onClick={handleUploadVideo}
                >
                    <MdAdd className={"text-lg text-text-700 group-hover:text-text-900"} />
                    <span className={"font-bodyPri font-normal text-base text-text-700 group-hover:text-text-900"}>
                        {"Upload Video"}
                    </span>
                </div>
            }
            {onboardingDocList?.onboardingDocList?.length > 0 &&
                <div className={cn(
                    "w-full flex flex-col items-start justify-start gap-5 px-3 py-2",
                    (introVideoStatus !== childDocumentStatus?.REJECTED?.value) && "h-52"
                )}>
                    {/* <div className={"w-full flex flex-col items-start justify-start sm:flex-row sm:items-center sm:justify-start gap-3"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base whitespace-nowrap"}>
                            {"Playback ID:"}
                        </span>
                        <input
                            type={"text"}
                            value={introVideoPlayBackId}
                            className={cn(
                                "w-fit px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base capitalize",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => setIntroVideoPlayBackId(e.target.value)}
                            onPaste={onHandlePastePlaybackId}
                            required={true}
                        />
                    </div> */}
                    <div className={"flex flex-col items-start justify-start sm:flex-row sm:items-center sm:justify-start gap-3"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                            {"Status:"}
                        </span>
                        <div className={"w-52"}>
                            <OptionSelector
                                options={Object.values(childDocumentStatus)}
                                value={introVideoStatus}
                                onChange={(option) => setIntroVideoStatus(option.value)}
                            />
                        </div>
                    </div>
                    {(introVideoStatus === childDocumentStatus?.REJECTED?.value) &&
                        <div className={"w-full flex flex-col items-start justify-start sm:flex-row sm:items-center sm:justify-start gap-3"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                {"Comment:"}
                                <span className={"text-red-500"}>
                                    {"*"}
                                </span>
                            </span>
                            <div>
                                <textarea
                                    className={"w-full px-3 py-2 resize-y rounded-lg shadow border-2 border-divider-medium focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                                    rows={3}
                                    cols={38}
                                    maxLength={200}
                                    onChange={(event) => setComment(event?.target?.value)}
                                    value={comment}
                                />
                            </div>
                        </div>
                    }
                    <div className={"flex items-center justify-start gap-5"}>
                        <div
                            className={cn(
                                "w-24 px-3 py-1 flex justify-center items-center rounded-lg",
                                "border border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                                // (!onboardingDocList?.onboardingDocList[0]?.status || (introVideoStatus === onboardingDocList?.onboardingDocList[0]?.status)) && "border border-text-300 text-text-300"
                            )}
                            onClick={onHandleSave}
                        >
                            {"Save"}
                        </div>
                        <div
                            className={cn("w-24 px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                "border border-primary-main text-primary-main",
                                "hover:bg-primary-main hover:text-text-50"
                            )}
                        // onClick={() => dispatcher(getPersonalDetailByUserId(Number(userId)))}
                        >
                            {"Cancel"}
                        </div>
                    </div>
                </div>
            }
            {onboardingDocList?.errorMsg &&
                <div className={"w-full flex items-center justify-start font-bodyPri font-normal text-red-500 text-sm"}>
                    {onboardingDocList?.errorMsg}
                </div>
            }
        </div>
    ) : null
}

export default EditIntroVideo