import { createSlice } from "@reduxjs/toolkit";

import { CHARGEBEE_INITIAL_STATE } from "./chargeBee.initialState";

const chargeBee = createSlice({
    name: "chargeBee",
    initialState: CHARGEBEE_INITIAL_STATE,
    reducers: {

        // reducers for user item list
        setUserItemListLoading: (state, { payload }) => {
            state.userItemList.isLoading = payload
        },
        setUserItemList: (state, { payload }) => {
            state.userItemList.userItemList = payload
            state.userItemList.filteredUserItemList = payload
            state.userItemList.errorMsg = CHARGEBEE_INITIAL_STATE.userItemList.errorMsg
        },
        setUserItemListErrorMsg: (state, { payload }) => {
            state.userItemList.errorMsg = payload
            state.userItemList.userItemList = CHARGEBEE_INITIAL_STATE.userItemList.userItemList
            state.userItemList.filteredUserItemList = CHARGEBEE_INITIAL_STATE.userItemList.filteredUserItemList
        },
        setClearUserItemList: (state) => {
            state.userItemList = CHARGEBEE_INITIAL_STATE.userItemList
        },

        // reducers for user item list
        setUserItemDetailLoading: (state, { payload }) => {
            state.userItemDetail.isLoading = payload
        },
        setUserItemDetail: (state, { payload }) => {
            state.userItemDetail.userItemDetail = payload
            state.userItemDetail.errorMsg = CHARGEBEE_INITIAL_STATE.userItemDetail.errorMsg
        },
        setUserItemDetailErrorMsg: (state, { payload }) => {
            state.userItemDetail.errorMsg = payload
            state.userItemDetail.userItemDetail = CHARGEBEE_INITIAL_STATE.userItemDetail.userItemDetail
        },
        setClearUserItemDetail: (state, { payload }) => {
            state.userItemDetail = CHARGEBEE_INITIAL_STATE.userItemDetail
        },

        // reducers for add user item detail
        setAddUserItemDetailLoading: (state, { payload }) => {
            state.addUserItemDetail.isLoading = payload
        },
        setAddUserItemDetail: (state, { payload }) => {
            state.addUserItemDetail.addUserItemDetail = payload
            state.addUserItemDetail.errorMsg = CHARGEBEE_INITIAL_STATE.addUserItemDetail.errorMsg
        },
        setAddUserItemDetailErrorMsg: (state, { payload }) => {
            state.addUserItemDetail.errorMsg = payload
            state.addUserItemDetail.addUserItemDetail = CHARGEBEE_INITIAL_STATE.addUserItemDetail.addUserItemDetail
        },
        setClearAddUserItemDetail: (state) => {
            state.addUserItemDetail = CHARGEBEE_INITIAL_STATE.addUserItemDetail
        },

        // reducers for user item detail payload
        setAddUserItemDetailPayload: (state, { payload }) => {
            state.addUserItemDetail.addUserItemDetailPayload = payload
        },
        setClearAddUserItemDetailPayload: (state) => {
            state.addUserItemDetail.addUserItemDetailPayload = CHARGEBEE_INITIAL_STATE.addUserItemDetail.addUserItemDetailPayload
        },

        // reducers for item price list
        setItemPriceListLoading: (state, { payload }) => {
            state.itemPriceList.isLoading = payload
        },
        setItemPriceList: (state, { payload }) => {
            state.itemPriceList.itemPriceList = payload
            state.itemPriceList.filteredItemPriceList = payload
            state.itemPriceList.errorMsg = CHARGEBEE_INITIAL_STATE.itemPriceList.errorMsg
        },
        setItemPriceListErrorMsg: (state, { payload }) => {
            state.itemPriceList.errorMsg = payload
            state.itemPriceList.itemPriceList = CHARGEBEE_INITIAL_STATE.itemPriceList.itemPriceList
            state.itemPriceList.filteredItemPriceList = CHARGEBEE_INITIAL_STATE.itemPriceList.filteredItemPriceList
        },
        setClearItemPriceList: (state) => {
            state.itemPriceList = CHARGEBEE_INITIAL_STATE.itemPriceList
        },

        // reducers for item price detail
        setItemPriceDetailLoading: (state, { payload }) => {
            state.itemPriceDetail.isLoading = payload
        },
        setItemPriceDetail: (state, { payload }) => {
            state.itemPriceDetail.itemPriceDetail = payload
            state.itemPriceDetail.errorMsg = CHARGEBEE_INITIAL_STATE.itemPriceDetail.errorMsg
        },
        setItemPriceDetailErrorMsg: (state, { payload }) => {
            state.itemPriceDetail.errorMsg = payload
            state.itemPriceDetail.itemPriceDetail = CHARGEBEE_INITIAL_STATE.itemPriceDetail.itemPriceDetail
        },
        setClearItemPriceDetail: (state) => {
            state.itemPriceDetail = CHARGEBEE_INITIAL_STATE.itemPriceDetail
        },

        // reducers for add item price detail
        setAddItemPriceDetailLoading: (state, { payload }) => {
            state.addItemPriceDetail.isLoading = payload
        },
        setAddItemPriceDetail: (state, { payload }) => {
            state.addItemPriceDetail.addItemPriceDetail = payload
            state.addItemPriceDetail.errorMsg = CHARGEBEE_INITIAL_STATE.addItemPriceDetail.errorMsg
        },
        setAddItemPriceDetailErrorMsg: (state, { payload }) => {
            state.addItemPriceDetail.errorMsg = payload
            state.addItemPriceDetail.addItemPriceDetail = CHARGEBEE_INITIAL_STATE.addItemPriceDetail.addItemPriceDetail
        },
        setClearAddItemPriceDetail: (state) => {
            state.addItemPriceDetail = CHARGEBEE_INITIAL_STATE.addItemPriceDetail
        },

        // reducers for item price detail payload
        setAddItemPriceDetailPayload: (state, { payload }) => {
            state.addItemPriceDetail.addItemPriceDetailPayload = payload
        },
        setClearAddItemPriceDetailPayload: (state) => {
            state.addItemPriceDetail.addItemPriceDetailPayload = CHARGEBEE_INITIAL_STATE.addItemPriceDetail.addItemPriceDetailPayload
        }
    }
})

export const {
    setUserItemListLoading,
    setUserItemList,
    setUserItemListErrorMsg,
    setClearUserItemList,

    setUserItemDetailLoading,
    setUserItemDetail,
    setUserItemDetailErrorMsg,
    setClearUserItemDetail,

    setAddUserItemDetailLoading,
    setAddUserItemDetail,
    setAddUserItemDetailErrorMsg,
    setClearAddUserItemDetail,

    setAddUserItemDetailPayload,
    setClearAddUserItemDetailPayload,

    setItemPriceListLoading,
    setItemPriceList,
    setItemPriceListErrorMsg,
    setClearItemPriceList,

    setItemPriceDetailLoading,
    setItemPriceDetail,
    setItemPriceDetailErrorMsg,
    setClearItemPriceDetail,

    setAddItemPriceDetailLoading,
    setAddItemPriceDetail,
    setAddItemPriceDetailErrorMsg,
    setClearAddItemPriceDetail,

    setAddItemPriceDetailPayload,
    setClearAddItemPriceDetailPayload
} = chargeBee.actions

export default chargeBee.reducer