import { useEffect } from 'react';

import ReviewCard from 'components/ReviewCard';

import { getCompanyReviewList } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';

const Reviews = () => {
    const { companyReviewList } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getCompanyReviewList())
    }, [])

    return (
        <div className={"flex items-center justify-center"} id={"REVIEWS"}>
            <div className={"max-w-full px-5 md:max-w-3xl md:px-0 lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                        {"Reviews"}
                    </span>
                    <div className={"grid grid-cols-2 gap-3 grid-flow-row auto-cols-max"}>
                        {companyReviewList?.companyReviewList?.reviews?.length > 0 &&
                            companyReviewList?.companyReviewList?.reviews?.map((reviews, idx) => (
                                <ReviewCard
                                    key={idx}
                                    rating={reviews?.rating}
                                    content={reviews?.review}
                                    profileImg={reviews?.provider?.profilePicUrl}
                                    fName={reviews?.provider?.firstName}
                                    lName={reviews?.provider?.lastName}
                                    lessonCount={reviews?.provider?.noOfLesson}
                                    createdAt={reviews?.provider?.createdAt}
                                />
                            ))}
                        {companyReviewList?.companyReviewList?.reviews?.length === 0 &&
                            <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                                {"No Reviews Found"}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reviews