import { useState, useEffect, useMemo, memo, useCallback } from 'react';

import { Link, useLocation } from "react-router-dom";

import format from "string-format";

import { FiEdit } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';

import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { appointmentsHeaderConst, subRowHeader, sortAppointmentLowToHigh, sortAppointmentHighToLow } from 'pages/auth/appointments/data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { getAppointmentList, deleteAppointmentDetail } from 'redux/appointment/appointment.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { resetAppointmentList } from 'redux/appointment/appointment.slice';
import { appointmentPriceModelEnum, appointmentStatusEnum } from 'redux/appointment/appointment.const';

import { pagesInfo, edulyteAppPagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { generateQueryParams } from 'utils/generators.utils';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';
import { cn } from 'utils/cn.utils';

let CURRENT_APPOINTMENT_ID = null;

const AppointmentTitleContainer = memo(({ appointment }) => {

    const onHandleNavigateToAppointment = useCallback(() => {
        if (appointment?.status === appointmentStatusEnum?.PUBLISHED?.value) {
            window.open(`${EDULYTE_APP_DOMAIN_URL}${format(edulyteAppPagesInfo.PUBLIC_APPOINTMENT.pagePath, { slug: appointment?.slug })}`, "_blank")
        }
    }, [appointment])

    return appointment ? (
        <span
            className={cn(
                'font-bodyPri font-normal tracking-wide',
                (appointment?.status === appointmentStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
            )}
            title={appointment?.title && appointment?.title}
            onClick={onHandleNavigateToAppointment}
        >
            {appointment?.title
                ? (appointment?.title?.length > 30)
                    ? appointment?.title?.slice(0, 30) + " ..."
                    : appointment?.title
                : "-"
            }
        </span>
    ) : "-"
})


const AppointmentSlugContainer = memo(({ appointment }) => {

    const onHandleNavigateToAppointment = useCallback(() => {
        if (appointment?.status === appointmentStatusEnum?.PUBLISHED?.value) {
            window.open(`${EDULYTE_APP_DOMAIN_URL}${format(edulyteAppPagesInfo.PUBLIC_APPOINTMENT.pagePath, { slug: appointment?.slug })}`, "_blank")
        }
    }, [appointment])

    return appointment ? (
        <span
            className={cn(
                'font-bodyPri font-normal tracking-wide',
                (appointment?.status === appointmentStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
            )}
            title={appointment?.slug && appointment?.slug}
            onClick={onHandleNavigateToAppointment}
        >
            {appointment?.slug
                ? (appointment?.slug?.length > 30)
                    ? appointment?.slug?.slice(0, 30) + " ..."
                    : appointment?.slug
                : "-"
            }
        </span>
    ) : "-"
})

const AppointmentUser = memo(({ appointment }) => {
    return appointment ? (
        <div className={"flex items-center justify-start gap-0.5"}>
            <Link to={`${pagesInfo?.VIEW_USER?.pagePath}/${appointment?.user?.id}`}
                className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
            >

                {appointment?.user?.id + ". "}
            </Link>
            <div className={""}>
                {appointment?.user?.firstName + " " + appointment?.user?.lastName?.charAt(0)}
            </div>
        </div>
    ) : "-"
})

const AppointmentAvailability = memo(({ appointment }) => {
    return appointment ? (
        <Link to={`${pagesInfo?.VIEW_USER?.pagePath}/${appointment?.user?.id}`}
            className={"font--bodyPri font-normal text-primary-main text-base hover:underline hover:text-primary-dark"}
        >
            {appointment?.availability_id}
        </Link>
    ) : "-"
})

const AppointmentActivity = memo(({ appointment }) => {
    const { destroyAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const onHandleEditAppointment = () => {
        CURRENT_APPOINTMENT_ID = appointment?.id
        dispatcher(createAdminLoginAsDetail({ user_id: appointment?.user?.id, source_url: window.location.href }))
    }

    const onHandleDeleteAppointment = () => {
        if (destroyAppointmentDetail?.isLoading) return;

        dispatcher(deleteAppointmentDetail(appointment?.id))
    }

    return (
        <div className={"flex items-center w-full"}>
            <button
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
                onClick={onHandleEditAppointment}
                title={"Edit Appointment"}
            >
                <FiEdit size={20} className={"text-xl hover:scale-110"} />
            </button>
            <button
                className={"px-2 py-1 bg-red-500 text-white rounded-r-md"}
                onClick={onHandleDeleteAppointment}
                title={"Delete Appointment"}
            >
                <AiOutlineDelete size={20} className={"text-xl hover:scale-110"} />
            </button>
        </div>
    )
});

const DisplayAppointments = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { appointmentList, filterProps } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        const query = generateQueryParams(location.search)
        dispatcher(getAppointmentList({ page: query.pn || 1, records: filterProps?.records }))

        return () => {
            dispatcher(resetAppointmentList())
        }
    }, [location.search, filterProps?.records])

    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${format(edulyteAppPagesInfo.EDIT_APPOINTMENT.pagePath, { appointmentId: CURRENT_APPOINTMENT_ID })}`, "_self")
        }
    }, [adminLoginAs?.data])

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortAppointmentList = (appointment1, appointment2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortAppointmentLowToHigh(activeSortHeader, appointment1, appointment2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortAppointmentHighToLow(activeSortHeader, appointment1, appointment2)
        }
    }

    const rows = useMemo(() => {
        if (!!appointmentList?.data?.result?.length) {
            return (
                appointmentList?.data?.result?.slice(0)?.sort(sortAppointmentList)?.map((appointment) => [
                    appointment?.id || "-",
                    <AppointmentTitleContainer appointment={appointment} />,
                    <AppointmentSlugContainer appointment={appointment} />,
                    appointmentPriceModelEnum[appointment?.price_model?.toUpperCase()]?.label,
                    <AppointmentUser appointment={appointment} />,
                    <AppointmentAvailability appointment={appointment} />,
                    appointmentStatusEnum[appointment?.status?.toUpperCase()]?.label,
                    dayjs(appointment?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
                    <AppointmentActivity appointment={appointment} />,
                    dayjs(appointment?.updatedAt).tz(timeZone).format('DD MMM, YYYY')
                ])
            )
        } else return []
    }, [appointmentList?.data, activeSortHeader])

    return (
        <>
            <Table
                isLoading={appointmentList?.isLoading}
                headers={Object.values(appointmentsHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                message={(appointmentList?.message || (appointmentList?.data?.pagination?.records === 0)) && (appointmentList?.message || "No appointments found")}
            />
            {(!appointmentList?.isLoading && (appointmentList?.data && (appointmentList?.data?.pagination?.totalPages > 1))) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={appointmentList?.data?.pagination?.page}
                        totalPages={appointmentList?.data?.pagination?.totalPages}
                        onChangePage={(page) => dispatcher(getAppointmentList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </>
    )
}

export default DisplayAppointments;