import { useState } from "react";
import { cn } from "utils/cn.utils";

import { GrAddCircle } from "react-icons/gr";
import { AiOutlineEnter } from "react-icons/ai";

import { sortTimeSlots } from "pages/auth/editCourseDetail/data";
import ScheduleTimeRange from 'pages/auth/editCourseDetail/components/confirmSchedulePage/commonComponents/ScheduleTimeRange';

import { useAppState } from 'hooks/useStore';
import { courseType } from "redux/course/course.const";

import {
    getTimeSlotWithDuration,
    getGroupTimeSlotWithDuration,
    validateIsStartBeforeEnd,
    validateIsTimeSlotExist
} from 'utils/availability.utils';
import { dayjs, timeZone } from 'utils/dateTime.utils';

const ScheduleCard = ({ localDay, weeklyAvailability, saveWeeklyAvail }) => {
    const { addAvailabilityDetail, modifyAvailabilityDetail } = useAppState((state) => state.availability)
    const { courseDetail, addCourseAvailDetail } = useAppState((state) => state.course)

    const [selectedTimeSlot, setSelectedTimeSlot] = useState({
        startTime: dayjs().tz(timeZone).format('09:[00:00]'),
        endTime: dayjs().tz(timeZone).format('09:[30:00]')
    });

    const isGroupCourseType = courseDetail?.courseDetail?.type === courseType?.GROUP?.value
    // const underReviewOrPublishedStatus = [courseStatus?.UNDER_REVIEW?.value, courseStatus?.PUBLISHED?.value]?.includes(courseDetail?.courseDetail?.status)

    const handleOnAdd = async () => {
        if (addAvailabilityDetail?.isLoading || modifyAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;

        // if (isGroupCourseType && underReviewOrPublishedStatus) return;

        let newSelectedTimeSlot = selectedTimeSlot
        let payloadDuration = addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15
        if (courseDetail?.courseDetail?.type === courseType?.ONE_ON_ONE?.value) {
            payloadDuration = 60
        }
        if (!weeklyAvailability || weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length == 0) {
            let startEndTimeSlot = selectedTimeSlot
            const totalTimeSlots = 0
            newSelectedTimeSlot = getTimeSlotWithDuration({ timeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: payloadDuration, totalTimeSlots })
        }
        if (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0) {
            let startEndTimeSlot = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlots)?.slice(-1)[0]
            const totalTimeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length
            newSelectedTimeSlot = getTimeSlotWithDuration({ timeZone, localDay, timeSlot: startEndTimeSlot, sessionDuration: payloadDuration, totalTimeSlots })
        }

        if (!validateIsStartBeforeEnd({ timeZone, localDay, selectedTimeSlot: newSelectedTimeSlot })) {
            alert("End time should be after the start time!")
            return;
        }

        const timeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]
        if (validateIsTimeSlotExist({ timeZone, localDay, selectedTimeSlot: newSelectedTimeSlot, timeSlots })) {
            alert("Slot is already exist!")
            return;
        }

        let newAvailSlots = { ...weeklyAvailability, [localDay?.slice(0, 3)?.toUpperCase()]: [...timeSlots, newSelectedTimeSlot] }

        await saveWeeklyAvail(newAvailSlots)
    }

    const handleOnUpdate = async (oldTimeSlot, updatedTimeSlot) => {
        if (addAvailabilityDetail?.isLoading || modifyAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;
        // if (isGroupCourseType && underReviewOrPublishedStatus) return;

        let newTimeSlot = updatedTimeSlot
        if (isGroupCourseType) {
            newTimeSlot = getGroupTimeSlotWithDuration({ timeZone, localDay, timeSlot: updatedTimeSlot, sessionDuration: addCourseAvailDetail?.addCourseAvailDetailPayload?.duration * 15, totalTimeSlots: 1 })
        }

        if (!validateIsStartBeforeEnd({ timeZone, localDay, selectedTimeSlot: newTimeSlot })) {
            alert("End time should be after the start time!")
            return;
        }

        let timeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()].filter(slot => oldTimeSlot.startTime !== slot.startTime)
        if (validateIsTimeSlotExist({ timeZone, localDay, selectedTimeSlot: newTimeSlot, timeSlots })) {
            alert("Slot already exist!")
            return;
        }

        timeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlots)?.map(slot => (
            oldTimeSlot.startTime === slot.startTime && oldTimeSlot.endTime === slot.endTime ? newTimeSlot : slot
        ))

        let newAvailSlots = { ...weeklyAvailability, [localDay?.slice(0, 3)?.toUpperCase()]: [...timeSlots] }

        await saveWeeklyAvail(newAvailSlots)
    }

    const handleOnDelete = async (selectedSlot) => {
        if (addAvailabilityDetail?.isLoading || modifyAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;
        // if (isGroupCourseType && underReviewOrPublishedStatus) return;

        const timeSlots = weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlots)?.filter(slot => slot.startTime !== selectedSlot.startTime)

        let newAvailSlots = { ...weeklyAvailability, [localDay?.slice(0, 3)?.toUpperCase()]: [...timeSlots] }

        await saveWeeklyAvail(newAvailSlots)
    }

    const handleOnApplyAll = async () => {
        if (addAvailabilityDetail?.isLoading || modifyAvailabilityDetail?.isLoading || addCourseAvailDetail?.isLoading) return;
        // if (isGroupCourseType && underReviewOrPublishedStatus) return;

        let newAvailSlots = {
            ...weeklyAvailability,
            MON: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            TUE: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            WED: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            THU: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            FRI: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            SAT: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
            SUN: [...weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]],
        }

        await saveWeeklyAvail(newAvailSlots)
    }

    const sortTimeSlot = (timeSlot1, timeSlot2) => {
        return timeSlot2?.startTime < timeSlot1?.startTime ? 1 : -1
    }

    return (
        <div className={"grid grid-cols-4 w-full gap-5"}>

            <div className={cn(
                "pt-2.5",
                weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0 && "col-start-1 col-span-full sm:col-start-1 sm:col-span-1",
                (!weeklyAvailability || (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length < 1)) && "col-start-1 col-span-1"
            )}>
                <span className={"font-bodyPri font-normal text-base text-text-900"}>
                    {localDay}
                </span>
            </div>

            {weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0 &&
                <div className={"col-start-1 col-span-3 sm:col-start-2 sm:col-span-2 space-y-3"}>
                    {weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.slice()?.sort(sortTimeSlot)?.map((timeSlot, index) => (
                        <ScheduleTimeRange
                            key={index}
                            timeSlot={timeSlot}
                            updateTimeSlot={handleOnUpdate}
                            deleteTimeSlot={handleOnDelete}
                        />
                    ))}
                </div>
            }

            {(!weeklyAvailability || (weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length < 1)) &&
                <div className={"col-start-2 col-span-1 mt-2.5 flex items-center justify-center"}>
                    <div className={"w-full font-bodyPri font-bold text-base text-text-900 tracking-wide text-center"}>
                        {"-"}
                    </div>
                </div>
            }

            <div className={"col-start-4 col-span-full flex items-start justify-start mt-2.5"}>
                <div className={"flex flex-row items-center justify-start space-x-2 sm:space-x-5"}>
                    <span className={cn("relative has-tooltip inline-flex items-center justify-center cursor-pointer")}
                        onClick={handleOnAdd}
                    >
                        <GrAddCircle className={"font-bodyPri font-medium text-xl text-text-400 hover:text-text-800"} />
                        <span
                            className={cn(
                                "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip absolute -top-5",
                                "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap"
                            )}
                        >
                            {"Add a session"}
                        </span>
                    </span>

                    {weeklyAvailability[localDay?.slice(0, 3)?.toUpperCase()]?.length > 0 &&
                        <span
                            className={"relative has-tooltip inline-flex items-center justify-center cursor-pointer"}
                            onClick={handleOnApplyAll}
                        >
                            <AiOutlineEnter className={"font-bodyPri font-medium text-xl text-text-700 hover:text-text-900"} />
                            <span
                                className={cn(
                                    "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip absolute -top-5",
                                    "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap"
                                )}
                            >
                                {"Apply to All"}
                            </span>
                        </span>
                    }
                </div>
            </div>
            <div className={"col-start-1 col-span-full h-0.5 w-full border border-divider-medium"}></div>
        </div>
    )
}

export default ScheduleCard