import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { MdAdd } from "react-icons/md";

import ComponentLoader from 'components/loader/ComponentLoader';

import CourseTutorCard from "./components/CourseTutorCard";

import { getCourseTutorList } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from "redux/local/local.slice";
import { resetTutorDetail } from "redux/tutor/tutor.slice";
import { resetAddCourseTutorDetail, resetCourseTutorList, setAddCourseTutorDetailPayload } from "redux/course/course.slice";
import { modalConst } from "redux/local/local.const";
import { addCourseTutorDetailPayload } from "redux/course/course.const";

const ViewCourseTutorPage = () => {
    const { modal } = useAppState((state) => state.local)
    const { courseTutorList, courseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    useEffect(() => {
        if (courseId) {
            dispatcher(getCourseTutorList({ courseId: courseId }))
        }

        return () => {
            dispatcher(resetCourseTutorList())
        }
    }, [courseId])

    return (
        <div className={cn(
            "w-full h-full bg-white rounded-lg select-none",
            "min-h-[60vh]"
        )}>
            <div className={"w-full px-5 py-3 bg-background-darkLight rounded-lg"}>
                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Tutors"}
                </div>
            </div>

            <div className={"w-full lg:w-[60vw] p-5 space-y-4"}>

                {courseTutorList?.isLoading && <ComponentLoader isLoading={courseTutorList?.isLoading} />}
                {(!courseTutorList?.isLoading && courseTutorList?.message) &&
                    <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                        {courseTutorList?.message}
                    </div>
                }
                {(!courseTutorList?.isLoading && courseTutorList?.data) &&
                    <div className={"flex flex-col w-full space-y-4"}>
                        {courseTutorList?.data?.slice(0)?.sort()?.map((courseTutor, index) => (
                            <CourseTutorCard
                                key={index}
                                courseTutor={courseTutor}
                            />
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewCourseTutorPage;