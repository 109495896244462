import { useState, useEffect } from 'react';

import { Link, useLocation } from "react-router-dom";

import { FiEdit } from 'react-icons/fi';
import { FaEye } from "react-icons/fa";

import MyMenu from 'components/threeDotMenu/ThreeDotMenu';
import Table from 'components/table/Table';
import Pagination from "components/pagination/Pagination";

import { usersHeaderConst, subRowHeader, sortUserLowToHigh, sortUserHighToLow, usersMenuData } from 'pages/auth/users/data';

import { createAdminLoginAsDetail } from 'redux/auth/auth.request';
import { getUserList } from 'redux/user/user.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAdminLoginAs } from 'redux/auth/auth.slice';
import { setClearUserList } from 'redux/user/user.slice';
import { setAddBookingDetailPayload, resetAddBookingDetailPayload, resetAddBookingDetail } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo, edulyteAppPagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { generateQueryParams } from 'utils/generators.utils';

import { EDULYTE_APP_DOMAIN_URL } from 'const/default.const';
import { BsCheck2All } from 'react-icons/bs';
import { TiDeleteOutline } from 'react-icons/ti';

const UserImageContainer = ({ profilePicUrl }) => {

    return (
        <div className={"w-14 h-14 overflow-hidden rounded-full"}>
            <img
                src={profilePicUrl}
                className={"w-full h-full object-cover"}
            />
        </div>
    )
}

const UserEmailContainer = ({ email, isEmailVerified }) => {
    return (
        <div className={"flex flex-col items-start justify-start sm:flex-row sm:justify-center sm:items-center gap-1"}>
            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                {email}
            </span>
            {isEmailVerified &&
                <BsCheck2All className={"text-green-500 text-base"} />
            }
            {!isEmailVerified &&
                <TiDeleteOutline className={"text-red-500 text-base"} />
            }
        </div>
    )
}

const UserMobileNoContainer = ({ mobileNo, isdCode, isMobileNoVerified }) => {
    return (isdCode && mobileNo) ? (
        <div className={"flex flex-col items-start justify-start sm:flex-row sm:justify-center sm:items-center gap-1"}>
            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                {"+" + isdCode + " - " + mobileNo}
            </span>
            {isMobileNoVerified &&
                <BsCheck2All className={"text-green-500 text-sm"} />
            }
            {!isMobileNoVerified &&
                <TiDeleteOutline className={"text-red-500 text-sm"} />
            }
        </div>
    ) : "-"
}

const UserActivity = ({ user }) => {
    const { modal } = useAppState((state) => state.local)
    const { addBookingDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    const onHandleSelect = (option) => {
        switch (option.value) {
            // case usersMenuData.EDIT_USER.value:
            //     break;
            case usersMenuData.ADD_BOOKING.value:
                dispatcher(resetAddBookingDetail())
                dispatcher(resetAddBookingDetailPayload())
                dispatcher(setAddBookingDetailPayload({
                    ...addBookingDetail?.payload,
                    courseId: null,
                    appointmentId: null,
                    userId: user?.userId
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.ADD_BOOKING_MODAL.stateKey]: true
                }))
                break;
            case usersMenuData.LOGIN_AS.value:
                dispatcher(createAdminLoginAsDetail({ user_id: user?.userId, source_url: window.location.href }));
                break;
            default:
        }
    }

    return (
        <div className={"flex justify-center items-center"}>
            <Link
                to={`${pagesInfo?.VIEW_USER?.pagePath}/${user?.userId}`}
                className="px-2 py-1 bg-primary-main text-white rounded-l-md"
            >
                <FaEye className={"text-xl hover:scale-110"} />
            </Link>
            <Link
                to={`${pagesInfo?.EDIT_USER?.pagePath}/${user?.userId}/edit`}
                className={"px-2 py-1 bg-background-lightDark text-white rounded-r-md"}
            >
                <FiEdit className={"text-xl hover:scale-110"} />
            </Link>
            <MyMenu menuItems={usersMenuData} onHandleSelect={(option) => onHandleSelect(option)} />
        </div>
    )
}

const DisplayUsers = () => {
    const { adminLoginAs } = useAppState((state) => state.auth)
    const { userList, filterProps } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const [activeSortHeader, setActiveSortHeader] = useState({
        activeSortKey: null,
        sortType: null
    })

    useEffect(() => {
        const query = generateQueryParams(location.search)
        dispatcher(getUserList({ page: query.pn || 1, records: filterProps?.records }))

        return () => {
            dispatcher(setClearUserList())
        }
    }, [location.search, filterProps?.records])


    useEffect(() => {
        if (adminLoginAs?.data) {
            dispatcher(resetAdminLoginAs())
            window.open(`${EDULYTE_APP_DOMAIN_URL}${edulyteAppPagesInfo.DASHBOARD.pagePath}`, "_self")
        }
    }, [adminLoginAs?.data])

    const sortHeader = (activeSort, upDownArrow) => {
        setActiveSortHeader({
            activeSortKey: activeSort,
            sortType: upDownArrow
        })
    }

    const sortUserList = (user1, user2) => {
        if (activeSortHeader?.sortType?.upArrow) {
            return sortUserLowToHigh(activeSortHeader, user1, user2)
        }
        if (activeSortHeader?.sortType?.downArrow) {
            return sortUserHighToLow(activeSortHeader, user1, user2)
        }
    }

    const rows = userList?.filteredUserList?.results?.slice(0)?.sort(sortUserList)?.map((user) => [
        <UserImageContainer profilePicUrl={user?.profilePicUrl} />,
        user?.userId,
        user?.firstName + " " + user?.lastName.charAt(0),
        <UserEmailContainer
            email={user?.email}
            isEmailVerified={user?.isEmailVerified}
        />,
        <UserMobileNoContainer
            mobileNo={user?.mobileNo}
            isdCode={user?.isdCode}
            isMobileNoVerified={user?.isMobileNoVerified}
        />,
        dayjs(user?.createdAt).tz(timeZone).format('DD MMM, YYYY'),
        <UserActivity user={user} />,
        dayjs(user?.updatedAt).tz(timeZone).format('DD MMM, YYYY')
    ]);

    return (
        <>
            <Table
                headers={Object.values(usersHeaderConst)}
                subRowHeader={subRowHeader}
                rows={rows}
                rowHeaderColor={"bg-background-medium"}
                alternateRowColor={"bg-primary-light"}
                sortHeader={sortHeader}
                isLoading={userList?.isLoading}
                message={(userList?.errorMsg || (userList?.filteredUserList?.records === 0)) && (userList?.errorMsg || "No users found")}
            />
            {(!userList?.isLoading && (userList?.filteredUserList && (userList?.filteredUserList?.totalPages > 1))) &&
                <div className={"w-full flex justify-center items-center py-3"}>
                    <Pagination
                        page={userList?.filteredUserList?.page}
                        totalPages={userList?.filteredUserList?.totalPages}
                        onChangePage={(page) => dispatcher(getUserList({ page: page, records: filterProps?.records }))}
                    />
                </div>
            }
        </>
    )
}

export default DisplayUsers