import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";
import LogTimeLine from "components/logTimeLine/LogTimeLine";

import { createWithdrawalsLogs } from "pages/auth/withdrawal/data";

import UserDetail from "pages/auth/withdrawal/components/withdrawalSideOverPanel/UserDetail";
import UserWalletDetails from "pages/auth/withdrawal/components/withdrawalSideOverPanel/UserWalletDetail";
import WithdrawalActionButton from "pages/auth/withdrawal/components/withdrawalSideOverPanel/WithdrawalActionButton";
import WithdrawalRequestDetail from "pages/auth/withdrawal/components/withdrawalSideOverPanel/WithdrawalRequestDetail";
import TransactionDetail from "pages/auth/withdrawal/components/withdrawalSideOverPanel/TransactionDetail";
import Comment from "pages/auth/withdrawal/components/withdrawalSideOverPanel/Comment"

import { getWithdrawalDetailByWithdrawalId } from "redux/withdrawal/withdrawal.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { withdrawalStatus } from "redux/withdrawal/withdrawal.const";

const WithdrawalSideOverContent = () => {
    const { withdrawalDetail } = useAppState((state) => state.withdrawal)

    const dispatcher = useAppDispatcher()

    const [isShowTxn, setIsShowTxn] = useState(false)

    useEffect(() => {
        if (withdrawalDetail?.withdrawalDetailPayload?.withdrawalId) {
            dispatcher(getWithdrawalDetailByWithdrawalId(withdrawalDetail?.withdrawalDetailPayload?.withdrawalId))
        }
    }, [withdrawalDetail?.withdrawalDetailPayload?.withdrawalId])

    useEffect(() => {
        if (withdrawalDetail?.withdrawalDetail?.status === withdrawalStatus.APPROVED.value) {
            setIsShowTxn(true)
        }
    }, [withdrawalDetail?.withdrawalDetail?.status])

    return (
        <div className={"p-5 h-screen sm:px-10 max-w-2xl md:max-w-xl lg:max-w-lg"}>
            <ComponentLoader isLoading={withdrawalDetail?.isLoading} className={"min-h-screen"} />
            {withdrawalDetail?.errorMsg &&
                <div className={"w-full h-full flex items-center justify-center gap-3"}>
                    {withdrawalDetail?.errorMsg}
                </div>
            }
            {withdrawalDetail?.withdrawalDetail &&
                <div className={cn(
                    "flex flex-col w-full min-h-[80vh] pb-10 overflow-hidden overflow-x-hidden overflow-y-scroll space-y-3 transition ease-in-out delay-150 duration-300",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
                )}>
                    <UserDetail />
                    <div className={"space-y-8"}>
                        <UserWalletDetails />
                        {withdrawalDetail?.withdrawalDetail?.status === withdrawalStatus.PENDING.value &&
                            <WithdrawalActionButton />
                        }
                    </div>
                    <hr className={"w-full border border-text-300"} />

                    <WithdrawalRequestDetail />

                    <hr className={"w-full border border-text-300"} />

                    {isShowTxn &&
                        <TransactionDetail />
                    }

                    {isShowTxn &&
                        <hr className={"w-full border border-text-300"} />
                    }

                    {withdrawalDetail?.withdrawalDetail?.comments &&
                        <Comment />
                    }

                    {withdrawalDetail?.withdrawalDetail?.comments &&
                        <hr className={"w-full border border-text-300"} />
                    }

                    <LogTimeLine logList={createWithdrawalsLogs(withdrawalDetail?.withdrawalDetail?.withdrawalLogs)} />
                </div>
            }

        </div>
    )
}

export default WithdrawalSideOverContent;